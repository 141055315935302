import { url } from '../constants'
import adminInstance from '../components/instances/admininstance'
import {convertToFormData} from '../reuseFunctions/objectToFormData';

export const getPOsWithoutFilters = (params = {page: 0,limit: 25}) => {
    return adminInstance.get(`${url}/purchase_order/offers`,{
        params: params
    })
}

export const getPosForPallets = (params = {page: 0,limit: 25}) => {
    return adminInstance.get(`${url}/purchase_order/pallets`,{
        params: params
    })
}

export const getActiveWorkPlace = (id) => {
    return adminInstance.get(`${url}/purchase_order/${id}/workplace`)
}

export const setActiveWorkPlace = (id,workPlaceId) => {
    return adminInstance.put(`${url}/purchase_order/${id}/workplace`,convertToFormData({
        workPlaceId: workPlaceId
    }))
}

export const getSnapshotByPo = (idPo,key) => {
    return adminInstance.get(`${url}/purchase_order/${idPo}/serialize`,{
        params: {
            key: key
        }
    })
}

export const editPurchaseDetails = (idPo, data) => {
    return adminInstance.put(`${url}/purchase_order/${idPo}`, convertToFormData(data))
}

export const getPoInboundingTypesService = () => {
    return adminInstance.get(`${url}/purchase_order/inbounding_type_list`)
}