import React, { PureComponent } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import SummerNoteEditor from '../../../reuseComponent/SummerNoteEditor'
import AsyncSelect from '../../../reuseComponent/Select/async-select'
import { history } from "../../../../history"
import { url } from '../../../../constants'
import adminInstance from '../../../instances/admininstance'


class NotificationInnerNewsletter extends PureComponent {

    state = {
        showSendConfirm: false
    }

    editor = React.createRef();

    handleAddVariable = (value) => {
        // console.log(value,this.editor.current)
        this.editor.current.editor.insertHTML(`<span>${value}</span>`,false);
        this.props.setFieldValue('content', this.editor.current.editor.getContents());
    }

    getUserEmails = inputValue => 
    new Promise((resolve, reject) => {
        adminInstance
          .get(`${url}/user/emails`, {
            params: {
              page: 0,
              search: inputValue,
              limit: 2000
            }
          })
          .then(res => {
            const recipients = res.data.data.map(item => ({
              label: item,
              value: item
            }));
            resolve(recipients);
          });
      });
        

    render() {
        const {
            handleSubmit,
            handleChange,
            errors,
            touched,
            setFieldValue,
            values,
            variables,
            initialValues
        } = this.props;

        return (
            <Card>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                                type="text"
                                name="subject"
                                value={values.subject}
                                onChange={handleChange}
                                isInvalid={touched.subject && errors.subject}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.subject}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Row>
                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label>Content</Form.Label>
                                    <SummerNoteEditor
                                        ref={this.editor}
                                        value={initialValues.content}
                                        onChange={(value) => {
                                            setFieldValue('content',value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="variables-block">
                                {variables.map(item => <Button
                                    key={item.variable}
                                    onClick={() => this.handleAddVariable(item.variable)}
                                    variant="primary">{item.displayName}</Button>)}
                            </Col>
                        </Row>
                        <Form.Group>
                            <Form.Label>Recipents</Form.Label>
                            <AsyncSelect
                                isMulti
                                name="email"
                                loadOptions={this.getUserEmails}
                                defaultOptions={true}
                                isInvalid={errors.recipients && touched.recipients}
                                errorLabel={errors.recipients}
                                onChange={recipientObj => {  setFieldValue('recipients', recipientObj ? recipientObj : []) }}
                            />
                        </Form.Group>
                        <Form.Group className="footer-form footer-form-custom">
                            <Button variant="secondary"
                                    onClick={() => history.replace(`/atw/settings/notifications`)}>Cancel</Button>
                            <Button variant="primary" type="submit">Send</Button>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        ); 
    }
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(NotificationInnerNewsletter);