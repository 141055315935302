import React, {PureComponent} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import SuppliersModule from './suppliers'
import HomePage from './homepage'
import AccessDenied from './errors/403'
import {getRolesAct} from '../../actions/roles'
import {getCurrentUser, setUsersCustomersCount} from '../../actions/users'
import DetailsPO from './purchaseOrders/details'
import ListPurchaseOrders from './purchaseOrders/list'
import AdminHeader from '../reuseComponent/Header/index'
import {refreshRequestUser} from '../../actions/users'
import {history} from '../../history'
import Dictionaries from './dictionaries'
import ReleaseNotesModule from './release_notes';
import ReleaseNotesViewCompoenent from './release_notes/view-component'
import ProtectedRoute from '../reuseComponent/Routes/protected-route'
import {permEditSuppliers, permViewSuppliers} from '../../permissions/suppliers';
import Settings from './settings'
import Tasks from './tasks'
import AnalysisTool from './analysisTool';
import Offers from './offers'
import ServiceDeskModule from './serviceDesk'
import CustomersModule from './customers'
import ProfileSettings from './profileSettings'
import {client, decoratorSocket} from '../../socketClient'
import InventoryModule from './inventory'
import {changeRoute} from '../../reuseFunctions/changeRoute';
import {notify} from '../reuseComponent/toast';
import { SessionTimeout } from '../../reuseFunctions/sessionTimeout'
import { getTasksAction, getActiveInboxAction } from '../../actions/tasks'

const timeConstRefresh = 300000
const session = new SessionTimeout()

class AdminPanel extends PureComponent {

    subscribeRole = null
    subscribeDeactivateUser = null
    timeout = null
    subscribeCredentials = null
    subscribeCustomersChange = null

    refreshTimeoutToken = (debounce = timeConstRefresh) => {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            const user = JSON.parse(window.localStorage.getItem('userWCS'))
            if (user) {
                this.props.refreshToken(user)
                    .then(res => {
                        let user = JSON.parse(window.localStorage.getItem('userWCS'))
                        let newUser = {
                            ...user,
                            ...res.data
                        }
                        newUser.createData = (new Date()).getTime()
                        window.localStorage.removeItem('userWCS')
                        window.localStorage.setItem('userWCS', JSON.stringify(newUser))
                        this.refreshTimeoutToken(newUser.expires_in * 1000 - timeConstRefresh)
                    })
                    .catch(err => {

                    })
            }
        }, debounce)
    }

    subscribeDeactivate = (client) => {
        const {currUser} = this.props
        if (currUser.info)
            this.subscribeDeactivateUser = client.subscribe(`/user_should_logout/${currUser.info.id}`, data => {
                if(data.body === 'true'){
                    window.localStorage.removeItem('userWCS')
                    changeRoute('/login',history)
                }
            })
        else
            setTimeout(() => this.subscribeDeactivate(client), 500)
    }

    subscribeDoubleUsedCredentials = (client) => {
        const {currUser} = this.props
        if (currUser.info)
            this.subscribeCredentials = client.subscribe(`/auto_logout_diff_source/${currUser.info.id}`, data => {
                if(data.body === 'true'){
                    window.localStorage.removeItem('userWCS')
                    changeRoute('/login',history)
                    notify('error','Your session was interrupted entering via different device or browser')
                }
            })
        else
            setTimeout(() => this.subscribeDoubleUsedCredentials(client), 500)
    }

    handleValidateToken = e => {
        clearTimeout(this.timeout)
        if (!document.hidden) {
            const user = JSON.parse(window.localStorage.getItem('userWCS'))
            if (user) {
                let currDateSeconds = (new Date()).getTime()
                if (currDateSeconds > user.createData + user.expires_in * 1000 - timeConstRefresh)
                // if(true)
                    this.props.refreshToken(user)
                        .then(res => {
                            console.log('refresh after sleep')
                            let user = JSON.parse(window.localStorage.getItem('userWCS'))
                            let newUser = {
                                ...user,
                                ...res.data
                            }
                            newUser.createData = (new Date()).getTime()
                            this.refreshTimeoutToken(newUser.expires_in * 1000 - timeConstRefresh)
                            window.localStorage.removeItem('userWCS')
                            window.localStorage.setItem('userWCS', JSON.stringify(newUser))
                        })
                        .catch(err => history.push('/login'))
                else {
                    this.refreshTimeoutToken(user.createData + user.expires_in * 1000 - timeConstRefresh - currDateSeconds)
                }
            }
            else
                history.push('/login')
        }
    }

    handleConnectToSocket = () => {
        const {changeCustomersCount} = this.props;

        this.subscribeRole = client.subscribe('/role', data => {
            this.props.getRoles()
        })
        this.subscribeCustomersChange = client.subscribe('/customers_view', data => {
            const currUserDetails = this.props.currUser.userDetails;
            const res = JSON.parse(data.body);

            if(currUserDetails && res && Array.isArray(res)){
                const localChanges = res.find(item => item.id === currUserDetails.id);
                if(localChanges){
                    changeCustomersCount(localChanges.customers);
                }
            }
        })
        this.subscribeDeactivate(client)
        this.subscribeDoubleUsedCredentials(client)
    }

    componentDidMount() {
        const user = JSON.parse(window.localStorage.getItem('userWCS'))
        if (user) {
            let currDateSeconds = (new Date()).getTime()
            if (currDateSeconds > user.createData + user.expires_in * 1000 - timeConstRefresh)
                this.props.refreshToken(user)
                    .then(res => {
                        let user = JSON.parse(window.localStorage.getItem('userWCS'))
                        let newUser = {
                            ...user,
                            ...res.data
                        }
                        newUser.createData = (new Date()).getTime()
                        this.refreshTimeoutToken(newUser.expires_in * 1000 - timeConstRefresh)
                        window.localStorage.removeItem('userWCS')
                        window.localStorage.setItem('userWCS', JSON.stringify(newUser))
                        this.props.getCurrUser()
                        this.props.getRoles()
                    })
                    .catch(err => history.push('/login'))
            else {
                this.refreshTimeoutToken(user.createData + user.expires_in * 1000 - timeConstRefresh - currDateSeconds)
                this.props.getCurrUser()
                this.props.getRoles()
            }

            this.props.getActiveTasks();
            this.props.getActiveInbox();
        }
        else {
            history.push('/login')
        }

        document.addEventListener('visibilitychange', this.handleValidateToken)

        decoratorSocket(this.handleConnectToSocket)

    }

    componentWillUnmount() {
        if (this.subscribeRole)
            this.subscribeRole.unsubscribe()
        if (this.subscribeDeactivateUser)
            this.subscribeDeactivateUser.unsubscribe()
        if(this.subscribeCredentials)
            this.subscribeCredentials.unsubscribe()
        if(this.subscribeCustomersChange)
            this.subscribeCustomersChange.unsubscribe()
        clearTimeout(this.timeout)
        session.destroy()
        document.removeEventListener('visibilitychange', this.handleValidateToken)

        // session.destroy()
    }

    render() {
        const {rolesLoaded, loadingCurrUser, isFull} = this.props
        return (
            <div className={`wrapper-body material-style-custom ${isFull ? '' : 'disable-scroll'}`}>
                {rolesLoaded && !loadingCurrUser && <div className="content">
                    <AdminHeader/>
                    <Switch>
                        <Route exact path="/atw/homepage" component={HomePage}/>
                        <ProtectedRoute
                            permission={[permViewSuppliers, permEditSuppliers]}
                            path="/atw/suppliers"
                            component={SuppliersModule}
                        />
                        <Route exact path="/atw/purchase-orders/:id" component={DetailsPO}/>
                        <Route
                            exact path="/atw/purchase-orders" 
                            component={ListPurchaseOrders}
                        />
                        <Route path={["/atw/access_denied/:message", "/atw/access_denied"]} component={AccessDenied}/>
                        <Route path="/atw/dictionaries/:alias" component={Dictionaries}/>
                        <Route path="/atw/private/release-notes" component={ReleaseNotesModule}/>
                        <Route path="/atw/release-notes" component={ReleaseNotesViewCompoenent}/>
                        <Route path="/atw/settings/:alias" component={Settings}/>
                        <Route path="/atw/profile/:id" component={ProfileSettings}/>
                        <Route path={["/atw/offers/:id", "/atw/offers"]} component={Offers}/>
                        <Route path={["/atw/service_desk/:id","/atw/service_desk"]} component={ServiceDeskModule} />
                        <Route path={["/atw/customer/:id","/atw/customer"]} component={CustomersModule} />
                        <Route path="/atw/inventory" component={InventoryModule} />
                        {/*<Route path={"/atw/sales-orders"} component={SalesOrders} />*/}
                        <Route path="/atw/tasks/:alias" component={Tasks} />
                        <Route
                            path="/atw/analysis-tool"
                            component={AnalysisTool}
                        />
                        <Redirect to="/atw/homepage"/>
                    </Switch>
                </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        rolesLoaded: state.rolesReducer.isLoaded,
        loadingCurrUser: state.usersReducer.loadingCurrUser,
        isFull: state.purchaseOrdersReducer.fullView,
        currUser: state.usersReducer.currUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRoles: () => getRolesAct(dispatch),
        getCurrUser: () => dispatch(getCurrentUser()),
        refreshToken: user => dispatch(refreshRequestUser(user)),
        changeCustomersCount: count => dispatch(setUsersCustomersCount(count)),
        getActiveTasks: () => getTasksAction(dispatch),
        getActiveInbox: () => getActiveInboxAction(dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel)