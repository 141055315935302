import React, { PureComponent } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import adminInstance from "../../../../../../instances/admininstance";
import { url } from "../../../../../../../constants";
import { FormControlComponent } from "../../bid-info/bids-table/total-row-with-products";
import SingleDatePicker from "../../../../../../reuseComponent/DatePicker/datepicker-with-children";
import moment from "moment";


class ReceivedRow extends PureComponent {
  state = {
    unitOptions: [],
    scrollPosition: 0
  };

  datePicker = null;

  componentDidMount() {
    adminInstance
      .get(`${url}/simple_parameter/quantityUnit`)
      .then(res => {
        this.setState({
          unitOptions: res.data.map(item => ({
            label: item.value,
            value: item.value
          }))
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  getScrollPosition = e => {
    const currentPosition = this.props.detailsWrapper.scrollTop;
    if (this.state.scrollPosition - currentPosition !== 0) {
      this.setState({ scrollPosition: currentPosition });
      this.datePicker.hide();
      this.props.detailsWrapper.removeEventListener(
        "scroll",
        this.getScrollPosition
      );
    }
  };

  handleAddListener = () => {
    this.props.detailsWrapper.addEventListener(
      "scroll",
      this.getScrollPosition
    );
  };

  handleRemoveListener = (event, picker) => {
    this.props.detailsWrapper.removeEventListener(
      "scroll",
      this.getScrollPosition
    );
  };

  render() {
    const {
      details,
      isEditInbound,
      changeValues,
      values,
      quantityError,
      packsError,
      palletsError,
      grossKgError,
      netKgError,
      arrivalDateError,
      handleChangeArrivalDate,
      handleChangeQuantity,
      handleChangePackCount,
      handleChangePallets,
      handleChangeGrossKg,
      handleChangeNetKg,
      touched,
      inboundReportPackage,
    } = this.props;

    // console.log({
    //   packsError,
    //   quantityError,
    //   touched
    // })
    // const { unitOptions } = this.state;

    return (
      <>
        <td>
          {isEditInbound ? (
            <Form.Group>
            <div className="wrapper-calendar-reveived">
              <SingleDatePicker
                startDate={values.actualArrivalDate}
                onApply={(event, picker) => {
                  handleChangeArrivalDate("actualArrivalDate",
                    picker.startDate.format("DD.MM.YYYY"))
                  // changeValues(
                  //   "actualArrivalDate",
                  //   picker.startDate.format("DD.MM.YYYY")
                  // );
                }}
                onShow={(event, picker) => {
                  this.datePicker = picker;
                  this.handleAddListener();
                }}
                onHide={this.handleRemoveListener}
                parentEl=".details"
              >
                <>
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="#000000"
                      d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z"
                    />
                  </svg>
                  <Form.Control
                    name="actualArrivalDate"
                    onChange={() => {}}
                    readOnly={true}
                    value={
                      values.actualArrivalDate
                        ? values.actualArrivalDate
                        : "No received date"
                    }
                  />
                </>
              </SingleDatePicker>
              {values.actualArrivalDate && (
                <svg
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    // changeValues("actualArrivalDate", "");
                    handleChangeArrivalDate("actualArrivalDate", "")
                    document.body.click();
                  }}
                  className="circle-close"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000000"
                    d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                  />
                </svg>
              )}
            </div>
              {touched && arrivalDateError && <span className="error-label">{arrivalDateError}</span>}
            </Form.Group>
          ) : details.actualArrivalDate ? (
            <span className="arrival-date-span">
              {moment(details.actualArrivalDate).format("DD.MM.YYYY")}
            </span>
          ) : null}
        </td>
        {/*<td>*/}
          {/*{isEditInbound && unitOptions.length > 0 ? (*/}
            {/*<QuantityComponent*/}
              {/*name="actualQuantity"*/}
              {/*units={unitOptions}*/}
              {/*initValue={details.actualQuantity}*/}
              {/*handleChange={changeValues}*/}
            {/*/>*/}
          {/*) : (*/}
            {/*renderQuantity(details.actualQuantity)*/}
          {/*)}*/}
        {/*</td>*/}
          <td >{isEditInbound ?
            // <Form.Group>
            //   <FormControlComponent
            //     name="actualQuantity"
            //     handleChange={handleChangeQuantity}
            //     isInt={true}
            //     value={values.actualQuantity}
            //     isInvalid={touched && quantityError}
            //   />
            //   {touched && quantityError && <Form.Control.Feedback type="invalid">{quantityError}</Form.Control.Feedback>}
            // </Form.Group>

              (
                  <Form.Group>
            <FormControlComponent
              name="actualQuantity"
              handleChange={handleChangeQuantity}
              isInvalid={touched && quantityError}
              isInt={true}
              value={values.actualQuantity}
            />
            {touched && quantityError && <Form.Control.Feedback type="invalid">{quantityError}</Form.Control.Feedback>}
                  </Form.Group>
              )
              : <>
              {details.actualQuantity && parseInt(details.actualQuantity) && inboundReportPackage
              && inboundReportPackage.actual_items ?
                `${inboundReportPackage.actual_items} / ${parseInt(details.actualQuantity)}` : null}
              {inboundReportPackage && inboundReportPackage.actual_items && (!details.actualQuantity
                || !parseInt(details.actualQuantity)) ? inboundReportPackage.actual_items : null}
              {details.actualQuantity && parseInt(details.actualQuantity) && (!inboundReportPackage
                || !inboundReportPackage.actual_items) ? parseInt(details.actualQuantity) : null}
              {(details.actualQuantity > 0 || (inboundReportPackage && inboundReportPackage.actual_items)) ? ' pcs' : null}
              </>}
          </td>
        <td>
          {isEditInbound ? (
              <Form.Group>
                <FormControlComponent
                  name="actualPackagesCount"
                  handleChange={handleChangePackCount}
                  isInt={true}
                  value={values.actualPackagesCount}
                  isInvalid={touched && packsError}
                />
                 {touched && packsError && <Form.Control.Feedback type="invalid">{packsError}</Form.Control.Feedback>}
               </Form.Group>
          ) : (
            details.actualPackagesCount
          )}
        </td>
        <td>
          {isEditInbound ? (
              <Form.Group>
            <FormControlComponent
              name="actualPalletsCount"
              handleChange={handleChangePallets}
              isInt={true}
              value={values.actualPalletsCount}
              isInvalid={touched && palletsError}
            />
                {touched && palletsError && <Form.Control.Feedback type="invalid">{palletsError}</Form.Control.Feedback>}
              </Form.Group>
          ) : (
            details.actualPalletsCount
          )}
        </td>
        <td>
          {isEditInbound ? (
              <Form.Group>
                <FormControlComponent
                    name="actualGrossWeight"
                    handleChange={handleChangeGrossKg}
                    isInt={true}
                    value={values.actualGrossWeight}
                    isInvalid={touched && grossKgError}
                />
                {touched && grossKgError &&
                <Form.Control.Feedback type="invalid">{grossKgError}</Form.Control.Feedback>}
              </Form.Group>

          ) : (
              details.actualGrossWeight
          )}
        </td>
        <td>
          {isEditInbound ? (
              <Form.Group>
            <FormControlComponent
              name="actualNetWeight"
              handleChange={handleChangeNetKg}
              isInt={true}
              value={values.actualNetWeight}
              isInvalid={touched && netKgError}
            />
                {touched && netKgError &&
                <Form.Control.Feedback type="invalid">{netKgError}</Form.Control.Feedback>}
              </Form.Group>
          ) : (
            details.actualNetWeight
          )}
        </td>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    details: state.purchaseOrdersReducer.detailsPurchase,
    roles: state.rolesReducer.roles,
    isEditInbound: state.poDetailsReducer.isEditInbound,
    inboundReportPackage: state.inboundingReportReducer.packages.find(item => item.shortName !== 'REST')
  };
};



export default connect(mapStateToProps, null)(ReceivedRow);
