/* eslint-disable no-unused-vars */
import * as React from "react";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import Tooltip from "../../../../reuseComponent/Tooltips";
/* eslint-enable no-unused-vars */
import ActionMenu from "./ActionMenu";
import { convertToFormDataWithArray } from "../../../../../reuseFunctions/objectToFormData";
import adminInstance from "../../../../../components/instances/admininstance";
import { url } from "../../../../../constants";
import Modal from "../../../../reuseComponent/modal";
import { notify } from "../../../../reuseComponent/toast";
import { createBrand } from "../../../../../services/brands";
import {createCategoryInDictionary} from '../../../../../actions/commodity-groups'
import { checkAlias } from "../../../../../reuseFunctions/checkAlias";
import FormPut from "./form";
import {
  getParamsByAlias
} from "../../../../../actions/detailsPo";
import CustomModalWithChildren from "../../../../reuseComponent/modal/modalWithChildren";
import { ConfirmModalContent } from "../../../../reuseComponent/confirmModalComponent";

const checkError = (row, alias) => {
  return row.errors.find(item => item.field === alias)
}

export default class MTableCell extends React.Component {
  state = { modalPut: false, modalConfirm: false, commodityList: [], genderList: [] };

 
  getRenderValue() {
    if (
      this.props.columnDef.emptyValue !== undefined &&
      (this.props.value === undefined || this.props.value === null)
    ) {
      return this.getEmptyValue(this.props.columnDef.emptyValue);
    }
    if (this.props.columnDef.render) {
      if (this.props.rowData) {
        return this.props.columnDef.render(this.props.rowData, "row");
      } else {
        return this.props.columnDef.render(this.props.value, "group");
      }
    } else if (this.props.columnDef.type === "boolean") {
      const style = { textAlign: "left", verticalAlign: "middle", width: 48 };
      if (this.props.value) {
        return <this.props.icons.Check style={style} />;
      } else {
        return <this.props.icons.ThirdStateCheck style={style} />;
      }
    } else if (this.props.columnDef.type === "date") {
      if (this.props.value instanceof Date) {
        return this.props.value.toLocaleDateString();
      } else {
        return this.props.value;
      }
    } else if (this.props.columnDef.type === "time") {
      if (this.props.value instanceof Date) {
        return this.props.value.toLocaleTimeString();
      } else {
        return this.props.value;
      }
    } else if (this.props.columnDef.type === "datetime") {
      if (this.props.value instanceof Date) {
        return this.props.value.toLocaleString();
      } else {
        return this.props.value;
      }
    } else if (this.props.columnDef.type === "currency") {
      return this.getCurrencyValue(
        this.props.columnDef.currencySetting,
        this.props.value
      );
    }

    return this.props.value;
  }

  getEmptyValue(emptyValue) {
    if (typeof emptyValue === "function") {
      return this.props.columnDef.emptyValue(this.props.rowData);
    } else {
      return emptyValue;
    }
  }

  getCurrencyValue(currencySetting, value) {
    if (currencySetting !== undefined) {
      return new Intl.NumberFormat(
        currencySetting.locale !== undefined ? currencySetting.locale : "en-US",
        {
          style: "currency",
          currency:
            currencySetting.currencyCode !== undefined
              ? currencySetting.currencyCode
              : "USD",
          minimumFractionDigits:
            currencySetting.minimumFractionDigits !== undefined
              ? currencySetting.minimumFractionDigits
              : 2,
          maximumFractionDigits:
            currencySetting.maximumFractionDigits !== undefined
              ? currencySetting.maximumFractionDigits
              : 2
        }
      ).format(value !== undefined ? value : 0);
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(value !== undefined ? value : 0);
    }
  }

  handleClickCell = e => {
    if (this.props.columnDef.disableClick) {
      e.stopPropagation();
    }
  };

  getStyle = () => {
    let cellStyle = {
      color: "inherit"
    };

    if (typeof this.props.columnDef.cellStyle === "function") {
      cellStyle = {
        ...cellStyle,
        ...this.props.columnDef.cellStyle(this.props.value, this.props.rowData)
      };
    } else {
      cellStyle = { ...cellStyle, ...this.props.columnDef.cellStyle };
    }

    if (this.props.columnDef.disableClick) {
      cellStyle.cursor = "default";
    }

    return { ...this.props.style, ...cellStyle };
  };

  handleOpenConfirm = (field) => {
    if(field === 'cg3') {
      getParamsByAlias('commodity').then(res => this.setState({commodityList: res.data}))
      getParamsByAlias('gender').then(res => this.setState({genderList: res.data}))
    }
    this.setState({ modalConfirm: true });
  };

  handleOpenPutModal = () => {
    this.setState({ modalPut: true });
  };

  handleAddDictionaryValues = (alias, value) => {
    const { columnDef, rowData } = this.props;
    const { commodityList, genderList} = this.state
    const commodity = commodityList.find(item => item.value === rowData.cg1)
    const gender = genderList.find(item => item.value === rowData.cg2)
    let formatedAlias = checkAlias(alias);
    const data = { value };
    if (alias === "brand") {
      formatedAlias = "brands";
      createBrand({ name: value, inputs: [] })
        .then(res => {
          if (typeof res.data !== "string") {
            notify("success", `${columnDef.title} successfully created`);
          } else {
            notify("error", "Value already exists");
          }
        })
        .catch(err => {
          notify("error", "Oops something went wrong");
        })
        .finally(() => this.setState({ modalConfirm: false }));
    } else if (alias === 'cg3') {
      if(checkError(rowData, 'cg1')) {
        notify("error", "CG1 must be validate");
        this.setState({ modalConfirm: false })
        return
      }
      if(checkError(rowData, 'cg2')) {
        notify("error", "CG2 must be validate");
        this.setState({ modalConfirm: false })
        return
      } 
      createCategoryInDictionary({value, inputs: [], related: [{commodity: commodity.id, gender: gender.id}]}).then(res => {
        if (typeof res.data !== "string") {
          notify("success", `${columnDef.title} successfully created`);
        } else {
          notify("error", "Value already exists");
        }
      }).catch(err =>  notify("error", "Oops something went wrong")).finally(() => this.setState({ modalConfirm: false }));
    } else {
      adminInstance
        .post(
          `${url}/simple_parameter/${formatedAlias ? formatedAlias : alias}`,
          convertToFormDataWithArray(data)
        )
        .then(res => {
          if (res.data.data) {
            notify("success", `${columnDef.title} successfully created`);
          } else {
            notify("error", "Value already exists");
          }
        })
        .catch(err => {
          notify("error", "Oops something went wrong");
        })
        .finally(() => this.setState({ modalConfirm: false }));
    }
  };


  render() {
    const { icons, columnDef, rowData, ...cellProps } = this.props;
    const { modalPut, modalConfirm } = this.state;
    const errorsObj = rowData.errors
      ? rowData.errors.find(error => error.field === columnDef.field)
      : null;
    return errorsObj ? (
      <>
        <Tooltip text={errorsObj.description}>
          <TableCell
            size={this.props.size}
            {...cellProps}
            style={{
              ...this.getStyle(),
              border: "2px solid red",
              // display: "flex",
              // justifyContent: "space-between"
            }}
            align={
              ["numeric", "currency"].indexOf(this.props.columnDef.type) !== -1
                ? "right"
                : "left"
            }
            onClick={this.handleClickCell}
          >
            {this.props.children}
            {this.getRenderValue()}
            <ActionMenu
              handleOpenConfirm={this.handleOpenConfirm}
              handleOpenPutModal={this.handleOpenPutModal}
              errorsObj={errorsObj}
            />
          </TableCell>
        </Tooltip>

        <Modal
          open={modalPut}
          isFooter={false}
          Content={() => (
            <FormPut
              alias={errorsObj.field}
              name={this.getRenderValue()}
              handleCancel={() =>
                this.setState({
                  modalPut: false
                })
              }
            />
          )}
          handleClose={() =>
            this.setState({
              modalPut: false
            })
          }
          options={{
            centered: true
          }}
        />

        <CustomModalWithChildren
          open={modalConfirm}
          handleCancel={() =>
            this.setState({
              modalConfirm: false
            })
          }
          handleClose={() =>
            this.setState({
              modalConfirm: false
            })
          }
          handleConfirm={() =>
            this.handleAddDictionaryValues(
              errorsObj.field,
              this.getRenderValue()
            )
          }
          options={{
            centered: true
          }}
        >
          <ConfirmModalContent/>
          </CustomModalWithChildren>
      </>
    ) : (
      <TableCell
        size={this.props.size}
        {...cellProps}
        style={this.getStyle()}
        align={
          ["numeric", "currency"].indexOf(this.props.columnDef.type) !== -1
            ? "right"
            : "left"
        }
        onClick={this.handleClickCell}
      >
        {this.props.children}
        {this.getRenderValue()}
      </TableCell>
    );
  }
}

MTableCell.defaultProps = {
  columnDef: {},
  value: undefined
};

MTableCell.propTypes = {
  columnDef: PropTypes.object.isRequired,
  value: PropTypes.any,
  rowData: PropTypes.object
};
