import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {getUserById, editUserById, getRolesAction, getLanguagesList} from '../../../../actions/users'
import Preloader from '../../../reuseComponent/preloader/preloader'
import { Formik } from 'formik'
import BodyForm from './bodyForm'
import {setData} from '../../../../actions/admin-header'
import { schema } from './schema'
import { convertToFormData } from '../../../../reuseFunctions/objectToFormData'
import { withRouter } from 'react-router-dom'
import {getCountries} from "../../../../actions/customers";

class EditUser extends PureComponent{

    state = {
        initValues: null,
        loading: false,
    };

    componentDidMount(){
        const { id } = this.props.match.params
        const { getUser,getRoles, getLanguagesList, getCountriesList } = this.props
        id && getUser && getUser(id)
        getRoles && getRoles()
        getLanguagesList && getLanguagesList()
        getCountriesList && getCountriesList()
    }

    componentDidUpdate(prevProps){
        const { id } = this.props.match.params
        const { getUser } = this.props
        if(id !== prevProps.match.params.id){
            this.setState({
                initValues: null
            }, () => {
                id && getUser && getUser(id)
            })
        }
    }

    handleSubmit = values => {
        const { editUser } = this.props
        const { id } = this.props.match.params
        this.setState({
            initValues : values
        },() => {
            const {
                author,
                department,
                languages,
                ...sendData
            } = values;
            editUser(id,convertToFormData({
                ...sendData,
                departmentId: values.department.value,
                langIds: values.languages.map(item => item.value),
                responsibleCountry: values.responsibleCountry && values.responsibleCountry.length > 0 ?
                    values.responsibleCountry.map(item => item.value).join(',') : '',
            }))
                .then(res => {
                    
                })
        })
    }

    render(){
        const { isLoading,user } = this.props
        const { initValues } = this.state
        return isLoading ? <Preloader/> : <Formik
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
            initialValues={initValues ? initValues :{
                id: user.id,
                name: user.name,
                roleId: user.role ? user.role.id : '',
                secondName: user.secondName,
                initials: user.initials,
                initialsColor: user.initialsColor,
                departmentId: user.department ? user.department.id : '',
                department: user.department ? {
                    value: user.department.id,
                    label: user.department.name
                } : null,
                author: user.author,
                invited: user.invited,
                languages: user.language ? user.language.map(item => ({
                    value: item.id,
                    label: item.name,
                })) : [],
                responsibleCountry: user.responsibleCountry ?
                    user.responsibleCountry.split(',').map(item => ({
                        value: item, label: item
                    })) : [],
            }}
            validationSchema={schema}
            render={props => <BodyForm {...props}/>}

        />
    }
}

const mapStateToProps = state => {
    return {
        user: state.usersReducer.userById,
        isLoading: state.usersReducer.isLoadingUserById || state.usersReducer.isLoadingRoles
            || state.usersReducer.loadingLanguagesList || state.customersReducer.isLoadingCountries,
        currUser : state.usersReducer.currUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: id => dispatch(getUserById(id)),
        setHeaderData: (data) => dispatch(setData(data)),
        editUser : (id,values) => dispatch(editUserById(id,values)),
        getRoles: () => dispatch(getRolesAction()),
        getLanguagesList: () => dispatch(getLanguagesList()),
        getCountriesList: () => dispatch(getCountries())
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditUser))