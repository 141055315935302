import React , { PureComponent } from 'react'
import { Form,Button } from 'react-bootstrap'
import AutosizeInput from "react-input-autosize";


class BodyForm extends PureComponent{
    render(){
        const {
            handleSubmit,
            handleCancel,
            values,
            errors,
            touched,
            setFieldValue
        } = this.props

        return (
            <Form
                noValidate
                onSubmit={handleSubmit}
                className="workspaces__form"
            >
                <Form.Group className="horizontal">
                    <Form.Label>Title</Form.Label>
                    <div className="input-item">
                        <AutosizeInput
                            type="text"
                            placeholder="Enter title"
                            className="auto-resize-wrapper"
                            name="name"
                            value={values.name}
                            onBlur={e => setFieldValue('name',e.target.value.trim())}
                            onChange={event => {
                                setFieldValue("name", event.target.value);
                            }}
                        />
                        <span className="error-label">{touched.name && errors.name}</span>
                    </div>
                </Form.Group>
                <Form.Group className="footer-form footer-form-custom">
                    <Button type="button" onClick={handleCancel}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </Form.Group>
            </Form>
        )
    }
}

export default BodyForm