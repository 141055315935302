export const checkIfObjectEmptyValues = (object,typesValues=['',null,undefined]) => {
    let acceptedValues = 0
    if(typeof object === 'object')
        // console.log(object)
        Object.values(object).forEach(val => {
            if(typeof val === 'object' && val !== null && val !== undefined){
                if(Object.keys(val).length !== 0)
                    acceptedValues +=1
            }
            else if(!typesValues.includes(val)){
                acceptedValues +=1
            }
        })
    return acceptedValues === 0 ? true : false
}