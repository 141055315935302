import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BodyForm } from "./BodyForm"
import { Formik } from 'formik';
import { Row,Card,Col,Container } from 'react-bootstrap'
import Preloader from '../reuseComponent/preloader/preloader'
import { schemaLogin } from './loginSchema'
import Logo from '../../assets/images/logo-login.png'
// import './style.scss'



import * as loginActions from '../../actions/login'

class LoginPage extends Component  {

    state = {
        initValues : {
            username: '',
            password: ''
        }
    }

    render() {
        const { initValues } = this.state
        const { loading, loginRequest } = this.props
        return (
            <Container >
                <Row className="rowForm">
                    {!loading ? <Col md="6">
                            <Card>
                                <Card.Img src={Logo} alt='logo' />
                                <Card.Body>
                                    <Formik
                                        initialValues={initValues}
                                        validationSchema={schemaLogin}
                                        component={BodyForm}
                                        onSubmit={(values) => loginRequest(values)}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    : <Preloader/>
                    }
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    const loading = state.loginReducer.loading
    return {
        loading
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        loginRequest:(formData) => loginActions.loginRequest(dispatch, formData)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)