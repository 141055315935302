import React from 'react'
import style from './preloaderWithText.module.scss'

const PreloaderWithText = (props) => {

    const { text = 'Loading', desc = "", global = false } = props

    let custStyle = {}
    if(props.style){
        custStyle = {...props.style}
    }
    return(
        <div className={`${style['preloader-wrap']} preloader-wrap ${global ? style['global-preloader'] : ''}`} style={custStyle}>
            <div></div>
            <div className={`${style["centerText"]}`}>
                <span className={style.text}>
                {text}
            </span>
                <div className={style.spinner}>
                    <div className={style.bounce1}></div>
                    <div className={style.bounce2}></div>
                    <div className={style.bounce3}></div>
                </div>
            </div>
            <div className={`${style["desc"]}`}>{desc}</div>
        </div>
    )
}

export {
    PreloaderWithText
}
