import React, { PureComponent } from 'react'
import {Form} from 'react-bootstrap';
import { connect } from 'react-redux'
import AsyncSelect from "../../../reuseComponent/Select/async-select";
import adminInstance from "../../../../components/instances/admininstance";
import { url } from "../../../../constants";
import AutosizeInput from "react-input-autosize";
import Select from '../../../reuseComponent/Select/select-form'
import { components } from 'react-select'
import { LocationAPI } from '../../../../services/location'
import { formatCoordinatesToView } from '../../../../reuseFunctions/mapUtils'
import { getPaginatableSimpleParameter } from '../../../../services/dictionaries'
import {customPalletMethod, inboundMethod, inventoryMethod} from "../create/choseMethodCreate";

const Option = ({children, ...props}) => {
  return (
    <components.Option {...props} className="packs-type-option">
      <div className="packs-type-option__icon-wrapper" dangerouslySetInnerHTML={{__html: props.data.icon}}></div>
      {children}
    </components.Option>
  )
}

const OptionStatus = ({children, ...props}) => {
  return (
    <components.Option {...props} className="packs-type-option">
      <div className="packs-status-option__icon-wrapper" dangerouslySetInnerHTML={{__html: props.data.icon}}></div>
      {children}
    </components.Option>
  )
}

class ThirdStepCreatePallet extends PureComponent {
  state = {
    freeLocationOptions: [],
  }

    filterNames = (names, inputValue) => {
        return names.filter(name =>
          name.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      };
      getProductNames = inputValue =>
        new Promise((resolve, reject) => {
          adminInstance
            .get(`${url}/simple_parameter/productName`, {
              params: {
                page: 0,
                search: inputValue,
                limit: 20
              }
            })
            .then(res => {
              const productNameOptions = res.data.map(item => ({
                label: item.value,
                value: item.value
              }));
              resolve(this.filterNames(productNameOptions, inputValue));
            });
        });

  getFreeLocations = inputValue =>
    new Promise((resolve, reject) => {
      const { plan } = this.props;
      LocationAPI.getFreeLocationByPlan(plan.id, {
        page: 0,
        limit: 6000,
        term: inputValue ? inputValue : null,
      })
        .then(res => {
          const freeLocationOptions = res.data.data.content.map(item => ({
            value: item.id,
            label: item.name
          }));
          // resolve(this.filterNames(freeLocationOptions, inputValue));

          this.setState({freeLocationOptions});
          resolve(freeLocationOptions, inputValue);
        });
    });

    getCg1 = inputValue =>
      new Promise((resolve, reject) => {
        getPaginatableSimpleParameter('commodity', 0, 20, inputValue ? inputValue : null)
          .then(res => {
            const cg1Options = res.data.parameters.map(item => ({
              value: item.value,
              label: item.value,
            }));
            resolve(cg1Options, inputValue);
          });
      });

    getUsers = inputValue =>
        new Promise((resolve, reject) => {
            adminInstance.get(`${url}/user/all`, {})
                .then(res => {
                    console.log(res.data);

                    const salesManagerOptions = res.data.users.map(item => ({
                        value: item.id,
                        label: item.name + ' ' + item.secondName,
                    }));
                    resolve(this.filterNames(salesManagerOptions, inputValue));
                });
        });

    getCg2 = inputValue =>
      new Promise((resolve, reject) => {
        getPaginatableSimpleParameter('gender', 0, 20, inputValue ? inputValue : null)
          .then(res => {
            const cg2Options = res.data.parameters.map(item => ({
              value: item.value,
              label: item.value,
            }));
            resolve(cg2Options, inputValue);
          });
      });

    getQuality = inputValue =>
        new Promise((resolve, reject) => {
            getPaginatableSimpleParameter('quality', 0, 20, inputValue ? inputValue : null)
                .then(res => {
                    const qualityOptions = res.data.parameters.map(item => ({
                        value: item.value,
                        label: item.value,
                    }));
                    resolve(qualityOptions, inputValue);
                });
        });

      
      render(){
        const {
          method,
          weight,
          season,
          note,
          orderNumber,
          salesManager,
          bid,
          netWeight,
          handleChange,
          isTouched,
          weightError,
          netWeightError,
          productName,
          productNameError,
          statusId,
          packTypeId,
          statusIdError,
          packTypeIdError,
          statusesOptions,
          packTypesOptions,
          locationId,
          locationIdError,
          cg1,
          cg2,
          quality,
          itemsCount,
          cg1Error,
          noteError,
          orderNumberError,
          salesManagerError,
          cg2Error,
          qualityError,
        } = this.props

        const isInbound = method === inboundMethod
        const isInventory = method === inventoryMethod
        const isCustom = method === customPalletMethod

        const statusesOptionsStaged = statusesOptions.reduce((status, current) => {
          const x = status.find(item => item.value === current.value);
          if (!x) {
            return status.concat([current]);
          } else {
            return status;
          }
        }, []);

        return (
            <div className="step third-step edit-inventory">
              <Form.Group className="horizontal">
                <Form.Label className="with-select">Net weight, kg</Form.Label>
                <div className="input-item">
                  <AutosizeInput
                    placeholder="Enter net weight"
                    className="auto-resize-wrapper"
                    value={netWeight}
                    name='netWeight'
                    onChange={e => handleChange('netWeight', e)}
                    onBlur={() => {
                      if(netWeight){
                        handleChange('netWeight', {
                          target: {
                            value: parseFloat(netWeight).toFixed(2)
                          }
                        })
                      }
                    }}
                  />
                  <span className="error-label">{isTouched && netWeightError}</span>
                </div>
              </Form.Group>
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Gross weight, kg</Form.Label>
                    <div className="input-item">
                        <AutosizeInput
                            placeholder="Enter gross weight"
                            className="auto-resize-wrapper"
                            value={weight}
                            name='weight'
                            onChange={e => handleChange('weight', e)}
                            onBlur={() => {
                              if(weight){
                                handleChange('weight', {
                                  target: {
                                    value: parseFloat(weight).toFixed(2)
                                  }
                                })
                              }
                            }}
                        />
                        <span className="error-label">{isTouched && weightError}</span>
                    </div>
                </Form.Group>
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Season</Form.Label>
                    <div className="input-item" >
                        <Form.Control type="text"
                                      name="season"
                                      onChange={e => handleChange('season', e)}
                                      value={season}>
                        </Form.Control>
                    </div>
                </Form.Group>
                {bid !== "invalid"  && <>
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Bid</Form.Label>
                    <div className="input-item" >
                        <Form.Control type="text"
                                      name="bid"
                                      onChange={e => handleChange('bid', e)}
                                      value={bid}>
                        </Form.Control>
                    </div>
                </Form.Group>
                </>}
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Product name</Form.Label>
                    <div className="input-item">
                        <AsyncSelect
                            name="productName"
                            loadOptions={this.getProductNames}
                            defaultOptions={true}
                            value={productName}
                            onChange={obj => handleChange('productName', obj)}
                            isInvalid={isTouched && productNameError}
                            errorLabel={productNameError}
                        />
                    </div>
                </Form.Group>
              <Form.Group className="horizontal">
                <Form.Label className="with-select">Status</Form.Label>
                <div className="input-item">
                  <Select
                    name="statusId"
                    value={statusId ? statusesOptionsStaged.find(item => item.value === statusId) : null}
                    onChange={obj => handleChange('statusId', obj.value)}
                    options={statusesOptionsStaged}
                    isInvalid={isTouched && statusIdError}
                    errorLabel={statusIdError}
                    components={{Option: OptionStatus}}
                  />
                </div>
              </Form.Group>
              <Form.Group className="horizontal">
                <Form.Label className="with-select">Packs type</Form.Label>
                <div className="input-item">
                  <Select
                    name="packTypeId"
                    value={packTypeId ? packTypesOptions.find(item => item.value === packTypeId) : null}
                    onChange={obj => handleChange('packTypeId', obj)}
                    options={packTypesOptions}
                    isInvalid={(packTypeId == null || packTypeId == undefined) && isTouched && packTypeIdError}
                    errorLabel={packTypeIdError}
                    components={{Option}}
                  />
                </div>
              </Form.Group>
              <Form.Group className="horizontal">
                <Form.Label className="with-select">Location</Form.Label>
                <div className="input-item">
                  <AsyncSelect
                    name="locationId"
                    value={locationId ? this.state.freeLocationOptions.find(item => item.value === locationId.value) : null}
                    value={locationId}
                    onChange={obj => {
                      return handleChange('locationId', obj)}}
                    // options={freeLocations}
                    loadOptions={this.getFreeLocations}
                    defaultOptions={true}
                    isInvalid={isTouched && locationIdError}
                    errorLabel={locationIdError}
                  />
                </div>
              </Form.Group>
              {!itemsCount && <>
                <Form.Group className="horizontal">
                  <Form.Label className="with-select">CG1</Form.Label>
                  <div className="input-item">
                    <AsyncSelect
                      name="cg1"
                      isMulti
                      // value={locationId ? freeLocations.find(item => item.value === locationId) : null}
                      value={cg1}
                      onChange={obj => handleChange('cg1', obj ? obj : [])}
                      loadOptions={this.getCg1}
                      defaultOptions={true}
                      isInvalid={isTouched && cg1Error}
                      errorLabel={cg1Error}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="horizontal">
                  <Form.Label className="with-select">CG2</Form.Label>
                  <div className="input-item">
                    <AsyncSelect
                      name="cg2"
                      isMulti
                      // value={locationId ? freeLocations.find(item => item.value === locationId) : null}
                      value={cg2}
                      onChange={obj => handleChange('cg2', obj ? obj : [])}
                      loadOptions={this.getCg2}
                      defaultOptions={true}
                      isInvalid={isTouched && cg2Error}
                      errorLabel={cg2Error}
                    />
                  </div>
                </Form.Group>
              </>}
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Quality</Form.Label>
                    <div className="input-item">
                        <AsyncSelect name="quality"
                                     value={{
                                         label: quality,
                                         value: quality
                                     }}
                                     onChange={obj => handleChange('quality', obj ? obj : [])}
                                     loadOptions={this.getQuality}
                                     defaultOptions={true}
                                     isInvalid={isTouched && qualityError}
                                     errorLabel={qualityError}
                        />
                    </div>
                </Form.Group>

                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Note</Form.Label>
                    <div className="input-item" >
                        <Form.Control type="text"
                                      name="note"
                                      onChange={e => handleChange('note', e)}
                                      value={note}>
                        </Form.Control>
                    </div>
                </Form.Group>

                {statusId && (statusId == 1 || statusId == 2) && <>
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Order#</Form.Label>
                    <div className="input-item" >
                        <Form.Control type="text"
                                      name="orderNumber"
                                      onChange={e => handleChange('orderNumber', e)}
                                      value={orderNumber}
                                      isInvalid={isTouched && statusId && statusId == 1 && orderNumberError  && (!orderNumber || orderNumber == undefined || orderNumber == null || orderNumber == '')}
                                      errorLabel={orderNumberError}>
                        </Form.Control>
                        <span className="error-label">{isTouched && statusId && statusId == 1
                            && (!orderNumber || orderNumber == undefined || orderNumber == null || orderNumber == '') && 'Required field'}</span>
                    </div>
                </Form.Group>
                </>}

                {statusId && (statusId == 1 || statusId == 2) && <>
                    <Form.Group className="horizontal">
                        <Form.Label>Sales Manager</Form.Label>
                            <div className="input-item">
                            <AsyncSelect
                                name="salesManager"
                                value= {{
                                    label: salesManager ? (typeof salesManager == "string" ? salesManager : salesManager.label) : "",
                                    value: salesManager ? (typeof salesManager == "string" ? salesManager : salesManager.label) : ""
                                }}
                                onChange={obj => handleChange('salesManager', obj ? obj : [])}
                                loadOptions={this.getUsers}
                                defaultOptions={true}
                                isInvalid={isTouched && statusId && (statusId == 1 || statusId == 2) && (!salesManager || salesManager == undefined || salesManager == null || salesManager == '')}
                                errorLabel={salesManagerError}
                            />
                            <span className="error-label">{isTouched && statusId && (statusId == 1 || statusId == 2)
                                && (!salesManager || salesManager == undefined || salesManager == null || salesManager == '') && 'Required field'}</span>
                        </div>
                    </Form.Group>
                </>}

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
  statusesOptions: state.inventoryReducer.statuses.map(item => ({
    value: item.id,
    label: item.name,
    icon: item.icon,
  })),
  packTypesOptions: state.inventoryReducer.packTypes.map(item => ({
    value: item.id,
    label: item.name,
    icon: item.icon,
  })),
  plan: state.inventoryReducer.plan,
})

export default connect(mapStateToProps)(ThirdStepCreatePallet)