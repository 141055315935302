import { DIVIDING } from '../constants'

const initState = {
    isLoadingDividngResult: false,
    conditionSets: [],
    fieldsForCondition: {},
    activeSet: null,
    isProductsExist : false,
    isLoadingProducts: false,
    dividingStatus: false,
    isLoadingStatus: false,
    isChangingSet: false
}

const transformConditions = condition => {
    return {
        id: condition.id,
        fieldAlias: condition.alias,
        fieldName: condition.name,
        values: condition.selectedValues ? condition.selectedValues.map(val => ({
            label: val,
            value: val
        })) : [],
        valuesList: condition.vals ? condition.vals.map(val => ({
            label: val,
            value: val
        })) : null
    }
}

export const dividingReducer = (state = initState,action) => {
    const { type,payload } = action
    switch(type){
        case DIVIDING.IS_CHANGING_SET_LOADING: {
            return {
                ...state,
                isChangingSet: payload
            }
        }
        case DIVIDING.RESET_DIVIDING_STATE: {
            return {
                ...state,
                ...payload
            }
        }
        case DIVIDING.IS_LOADING_STATUS: {
            return {
                ...state,
                isLoadingStatus: payload
            }
        }
        case DIVIDING.SET_DIVIDING_STATUS: {
            return {
                ...state,
                dividingStatus: payload
            }
        }
        case DIVIDING.IS_LOADING_PRODUCTS: {
            return {
                ...state,
                isLoadingProducts: payload
            }
        }
        case DIVIDING.IS_PRODUCTS_EXIST: {
            return {
                ...state,
                isProductsExist: payload
            }
        }
        case DIVIDING.REMOVE_CONDITION: {
            return {
                ...state,
                conditionSets: state.conditionSets.map(set => {
                    if(set.id === action.setId){
                        return {
                            ...set,
                            conditions: set.conditions.filter(condition => condition.id !== payload)
                        }
                    }
                    else{
                        return {
                            ...set
                        }
                    }
                })
            }
        }
        case DIVIDING.CHANGE_ACTIVE_SET: {
            return {
                ...state,
                activeSet: payload
            }
        }
        case DIVIDING.CHANGE_PACKAGE_NAME: {
            return {
                ...state,
                conditionSets: state.conditionSets.map(set => {
                    if(set.id === action.setId){
                        return {
                            ...set,
                            packages: set.packages.map(pack => {
                                if(pack.id === action.packId){
                                    return {
                                        ...pack,
                                        fullName: action.payload
                                    }
                                }
                                else{
                                    return {
                                        ...pack
                                    }
                                }
                            })
                        }
                    }
                    else{
                        return {
                            ...set
                        }
                    }
                })
            }
        }
        case DIVIDING.EDIT_CONDITION_IN_SET: {
            return {
                ...state,
                conditionSets: state.conditionSets.map(set => {
                    if(set.id === action.setId){
                        return {
                            ...set,
                            conditions: set.conditions.map(condition => {
                                if(condition.id === action.payload.id){
                                    return {
                                        ...condition,
                                        ...action.payload
                                    }
                                }
                                else{
                                    return {
                                        ...condition
                                    }
                                }
                            })
                        }
                    }
                    else{
                        return {
                            ...set
                        }
                    }
                })
            }
        }
        case DIVIDING.ADD_CONDITION_TO_SET: {
            return {
                ...state,
                conditionSets: state.conditionSets.map(set => {
                    if(set.id === action.setId){
                        return {
                            ...set,
                            conditions: set.conditions ? set.conditions.concat({
                                id: payload,
                                fieldAlias: '',
                                fieldName: '',
                                values: []
                            })
                                : [{
                                    id: payload,
                                    fieldAlias: '',
                                    fieldName: '',
                                    values: []
                                }]
                        }
                    }
                    else{
                        return {
                            ...set
                        }
                    }
                })
            }
        }
        case DIVIDING.CHANGE_CONDITION_SET_DATA: {
            return {
                ...state,
                conditionSets: state.conditionSets.map(set => {
                    if(set.id === action.id){
                        return {
                            ...set,
                            ...payload
                        }
                    }
                    else
                        return {
                            ...set
                        }
                })
            }
        }
        case DIVIDING.SET_FIELDS_FOR_CONDITION: {
            return {
                ...state,
                fieldsForCondition: payload
            }
        }
        case DIVIDING.SET_CONDITIONS_SETS: {
            return {
                ...state,
                conditionSets: payload && payload ? payload.map(set => ({
                    ...set,
                    conditions: set.conditions ? set.conditions.map(condition => transformConditions(condition)) : []
                })) : [],
                // fields: payload && payload.fields ? payload.fields : []
            }
        }
        case DIVIDING.ADD_CONDITION_SET: {
            return {
                ...state,
                conditionSets: state.conditionSets.concat(payload),
                activeSet: payload.id
            }
        }
        case DIVIDING.IS_LOADING_DIVIDING_RESULTS:{
            return {
                ...state,
                isLoadingDividngResult: payload
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}