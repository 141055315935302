import React, {PureComponent} from 'react';
import { connect } from 'react-redux'
import {Button, Form} from 'react-bootstrap';
import adminInstance from '../../../../components/instances/admininstance';
import {url} from '../../../../constants';
import AsyncSelect from '../../../reuseComponent/Select/async-select';
import Select from '../../../reuseComponent/Select/select-form'
import AutosizeInput from "react-input-autosize/lib/AutosizeInput";
import { components } from 'react-select'

import {ReactComponent as ArrowLeftIcon} from '../../../../assets/images/arrow-left.svg';
import {ReactComponent as SaveIcon} from '../../../../assets/images/save.svg';
import {ReactComponent as PrinterIcon} from '../../../../assets/images/printer.svg';
import { LocationAPI } from '../../../../services/location'
import { formatCoordinatesToView } from '../../../../reuseFunctions/mapUtils'
import { getPaginatableSimpleParameter } from '../../../../services/dictionaries'
import {customPalletMethod, inboundMethod, inventoryMethod} from "./choseMethodCreate";

const Option = ({children, ...props}) => {
  return (
    <components.Option {...props} className="packs-type-option">
      <div className="packs-type-option__icon-wrapper" dangerouslySetInnerHTML={{__html: props.data.icon}}></div>
      {children}
    </components.Option>
  )
}

const OptionStatus = ({children, ...props}) => {
  return (
    <components.Option {...props} className="packs-type-option">
      <div className="packs-status-option__icon-wrapper" dangerouslySetInnerHTML={{__html: props.data.icon}}></div>
      {children}
    </components.Option>
  )
}

class ThirdStepCreatePallet extends PureComponent {
    filterNames = (names, inputValue) => {
        return names.filter(name =>
            name.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };
    getProductNames = inputValue =>
        new Promise((resolve, reject) => {
            adminInstance
                .get(`${url}/simple_parameter/productName`, {
                    params: {
                        page: 0,
                        search: inputValue,
                        limit: 20
                    }
                })
                .then(res => {
                    const productNameOptions = res.data.map(item => ({
                        label: item.value,
                        value: item.value
                    }));
                    resolve(this.filterNames(productNameOptions, inputValue));
                });
        });

    getFreeLocations = inputValue =>
      new Promise((resolve, reject) => {
        const { plan } = this.props;
        LocationAPI.getFreeLocationByPlan(plan.id, {
          page: 0,
          limit: 6000,
          term: inputValue ? inputValue : null,
        })
          .then(res => {
            const freeLocationOptions = res.data.data.content.map(item => ({
              value: item.id,
              label: item.name
            }));
            // resolve(this.filterNames(freeLocationOptions, inputValue));
            resolve(freeLocationOptions, inputValue);
          });
      });

  getCg1 = inputValue =>
    new Promise((resolve, reject) => {
      getPaginatableSimpleParameter('commodity', 0, 20, inputValue ? inputValue : null)
        .then(res => {
          const cg1Options = res.data.parameters.map(item => ({
            value: item.value,
            label: item.value,
          }));
          resolve(cg1Options, inputValue);
        });
    });

    getUsers = inputValue =>
        new Promise((resolve, reject) => {
            adminInstance.get(`${url}/user/all`, {})
                .then(res => {
                    console.log(res.data);

                    const salesManagerOptions = res.data.users.map(item => ({
                        value: item.id,
                        label: item.name + ' ' + item.secondName,
                    }));
                    resolve(this.filterNames(salesManagerOptions, inputValue));
                });
        });

  getCg2 = inputValue =>
    new Promise((resolve, reject) => {
      getPaginatableSimpleParameter('gender', 0, 20, inputValue ? inputValue : null)
        .then(res => {
          const cg2Options = res.data.parameters.map(item => ({
            value: item.value,
            label: item.value,
          }));
          resolve(cg2Options, inputValue);
        });
    });

    getQuality = inputValue =>
        new Promise((resolve, reject) => {
            getPaginatableSimpleParameter('quality', 0, 20, inputValue ? inputValue : null)
                .then(res => {
                    const qualityOptions = res.data.parameters.map(item => ({
                        value: item.value,
                        label: item.value,
                    }));
                    resolve(qualityOptions, inputValue);
                });
        });

    render() {
        const {
            method,
            changeStep,
            handleCancel,
            handleCreate,
            handleChangeNetWeight,
            handleCreateAndPrint,
            weight,
            salesManager,
            salesManagerError,
            season,
            note,
            orderNumber,
            bid,
            handleChangeSeason,
            handleChangeNote,
            handleChangeOrderNumber,
            handleChangeBid,
            netWeight,
            handleChange,
            statusId,
            packTypeId,
            handleChangeProductName,
            weightError,
            netWeightError,
            productName,
            statusIdError,
            packTypeIdError,
            handleChangeStatusId,
            handleChanngePackTypeId,
            locationId,
            locationIdError,
            orderNumberError,
            handleChangeLocation,
            productNameError,
            isTouched,
            statusesOptions,
            packTypesOptions,
            cg1,
            cg2,
            quality,
            handleChangeCg,
            handleChangeQuality,
            handleChangeSalesManager,
            itemsCount,
        } = this.props;

        const isInbound = method === inboundMethod
        const isInventory = method === inventoryMethod
        const isCustom = method === customPalletMethod

        const statusesOptionsStaged = statusesOptions.reduce((status, current) => {
          const x = status.find(item => item.value === current.value);
          if (!x) {
            return status.concat([current]);
          } else {
            return status;
          }
        }, []);

        return (
            <div className="step third-step">
                <div className="title-step">
                    {/*<span>{method === 'customPallet' ? 'Step 2' : 'Step 3'}</span>*/}
                    <span>Step 3</span>
                    <div className="footer-step">
                        <Button onClick={() => changeStep(2)}>
                            <ArrowLeftIcon className="standart-svg"/> Back
                        </Button>
                        <Button className="cancel" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button onClick={handleCreateAndPrint} disabled={true}>
                            <PrinterIcon className="standart-svg"/> Save and print label
                        </Button>

                        <Button
                          onClick={handleCreate}
                          disabled={!netWeight || !weight || !productName || !statusId || !packTypeId || !locationId || !quality
                          || (itemsCount ? false : (cg1.length === 0 || cg2.length === 0))}
                        >
                            <SaveIcon className="standart-svg"/> Save
                        </Button>


                    </div>
                </div>
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Net weight, kg</Form.Label>
                    <div className="input-item">
                        <AutosizeInput
                          placeholder="Enter net weight"
                          className="auto-resize-wrapper"
                          value={netWeight}
                          name='netWeight'
                          onChange={handleChangeNetWeight}
                          onBlur={() => {
                              if(netWeight){
                                  handleChangeNetWeight({
                                      target: {
                                          value: parseFloat(netWeight).toFixed(2)
                                      }
                                  })
                              }
                          }}
                        />
                        <span className="error-label">{isTouched && netWeightError}</span>
                    </div>
                </Form.Group>
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Gross weight, kg</Form.Label>
                    <div className="input-item">
                        <AutosizeInput
                                placeholder="Enter gross weigth"
                                className="auto-resize-wrapper"
                                value={weight}
                                name='weight'
                                onChange={handleChange}
                                onBlur={() => {
                                    if(weight){
                                        handleChange({
                                            target: {
                                                value: parseFloat(weight).toFixed(2)
                                            }
                                        })
                                    }
                                }}
                            />
                        <span className="error-label">{isTouched && weightError}</span>
                    </div>
                </Form.Group>
                {method === "customPallet"  && <>
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Bid</Form.Label>
                    <div className="input-item" >
                        <Form.Control type="text"
                                      name="bid"
                                      onChange={handleChangeBid}
                                      value={bid}>
                        </Form.Control>
                    </div>
                </Form.Group>
                </>}
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Season</Form.Label>
                    <div className="input-item" >
                        <Form.Control type="text"
                                      name="season"
                                      onChange={handleChangeSeason}
                                      value={season}>
                        </Form.Control>
                    </div>
                </Form.Group>
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Product name</Form.Label>
                    <div className="input-item">
                        <AsyncSelect
                            name="productName"
                            loadOptions={this.getProductNames}
                            defaultOptions={true}
                            value={productName}
                            onChange={obj => handleChangeProductName(obj)}
                            isInvalid={isTouched && productNameError}
                            errorLabel={productNameError}
                        />
                    </div>
                </Form.Group>
              <Form.Group className="horizontal">
                <Form.Label className="with-select">Status</Form.Label>
                <div className="input-item">
                  <Select
                    name="statusId"
                    value={statusId ? statusesOptionsStaged.find(item => item.value === statusId) : null}
                    onChange={obj => handleChangeStatusId(obj)}
                    options={statusesOptionsStaged}
                    isInvalid={isTouched && statusIdError}
                    errorLabel={statusIdError}
                    components={{Option: OptionStatus}}
                  />
                </div>
              </Form.Group>
              <Form.Group className="horizontal">
                <Form.Label className="with-select">Packs type</Form.Label>
                <div className="input-item">
                  <Select
                    name="packTypeId"
                    value={packTypeId ? packTypesOptions.find(item => item.value === packTypeId) : null}
                    onChange={obj => handleChanngePackTypeId(obj)}
                    options={packTypesOptions}
                    isInvalid={isTouched && packTypeIdError}
                    errorLabel={packTypeIdError}
                    components={{Option}}
                  />
                </div>
              </Form.Group>
              <Form.Group className="horizontal">
                <Form.Label className="with-select">Location</Form.Label>
                <div className="input-item">
                  <AsyncSelect
                    name="locationId"
                    // value={locationId ? freeLocations.find(item => item.value === locationId) : null}
                    value={locationId}
                    onChange={obj => handleChangeLocation(obj)}
                    loadOptions={this.getFreeLocations}
                    defaultOptions={true}
                    isInvalid={isTouched && locationIdError}
                    errorLabel={locationIdError}
                  />
                </div>
              </Form.Group>
              {!itemsCount && <>
              <Form.Group className="horizontal">
                <Form.Label className="with-select">CG1</Form.Label>
                <div className="input-item">
                  <AsyncSelect
                    name="cg1"
                    isMulti
                    // value={locationId ? freeLocations.find(item => item.value === locationId) : null}
                    value={cg1}
                    onChange={obj => handleChangeCg('cg1', obj ? obj : [])}
                    loadOptions={this.getCg1}
                    defaultOptions={true}
                    // isInvalid={isTouched && locationIdError}
                    // errorLabel={locationIdError}
                  />
                </div>
              </Form.Group>
              <Form.Group className="horizontal">
                <Form.Label className="with-select">CG2</Form.Label>
                <div className="input-item">
                  <AsyncSelect
                    name="cg2"
                    isMulti
                    // value={locationId ? freeLocations.find(item => item.value === locationId) : null}
                    value={cg2}
                    onChange={obj => handleChangeCg('cg2', obj ? obj : [])}
                    loadOptions={this.getCg2}
                    defaultOptions={true}
                    // isInvalid={isTouched && locationIdError}
                    // errorLabel={locationIdError}
                  />
                </div>
              </Form.Group>
              </>}
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Quality</Form.Label>
                    <div className="input-item">
                        <AsyncSelect
                            name="quality"
                            value={quality}
                            onChange={obj => handleChangeQuality('quality', obj ? obj : [])}
                            loadOptions={this.getQuality}
                            defaultOptions={true}
                        />
                    </div>
                </Form.Group>
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Note</Form.Label>
                    <div className="input-item" >
                        <Form.Control type="text"
                                      name="note"
                                      onChange={handleChangeNote}
                                      value={note}>
                        </Form.Control>
                    </div>
                </Form.Group>

                {statusId && (statusId == 1 || statusId == 2) && <>
                <Form.Group className="horizontal">
                    <Form.Label className="with-select">Order#</Form.Label>
                    <div className="input-item" >
                        <Form.Control type="text"
                                      name="orderNumber"
                                      onChange={handleChangeOrderNumber}
                                      value={orderNumber}
                                      isInvalid={isTouched && statusId && statusId == 1 && orderNumberError  && (!orderNumber || orderNumber == undefined || orderNumber == null || orderNumber == '')}
                                      errorLabel={orderNumberError}>
                        </Form.Control>
                        <span className="error-label">{isTouched && statusId && statusId == 1
                        && (!orderNumber || orderNumber == undefined || orderNumber == null || orderNumber == '') && 'Required field'}</span>
                    </div>
                </Form.Group>
                </>}

                {statusId && (statusId == 1 || statusId == 2) && <>
                    <Form.Group className="horizontal">
                        <Form.Label className="with-select">Sales Manager</Form.Label>
                        <div className="input-item">
                            <AsyncSelect
                                name="salesManager"
                                value= {salesManager}
                                onChange={obj => handleChangeSalesManager('salesManager', obj ? obj : [])}
                                loadOptions={this.getUsers}
                                defaultOptions={true}
                                isInvalid={isTouched && statusId && (statusId == 1 || statusId == 2) && (!salesManager || salesManager == undefined || salesManager == null || salesManager == '')}
                                errorLabel={salesManagerError}
                            />
                        </div>
                        <span className="error-label">{isTouched && statusId && (statusId == 1 || statusId == 2)
                        && (!salesManager || salesManager == undefined || salesManager == null || salesManager == '') && 'Required field'}</span>
                    </Form.Group>
                </>}

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
  statusesOptions: state.inventoryReducer.statuses.map(item => {
    const statusesStaged = {
      value: item.id,
      label: item.name,
      icon: item.icon,
    }

    return statusesStaged;
  }),
  packTypesOptions: state.inventoryReducer.packTypes.map(item => ({
    value: item.id,
    label: item.name,
    icon: item.icon,
  })),
  plan: state.inventoryReducer.plan,
})

export default connect(mapStateToProps)(ThirdStepCreatePallet);
