import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { schema } from './schema'
import BodyForm from './bodyForm'
import Preloader from '../../../reuseComponent/preloader/preloader'
import { letterTemplate } from '../../../../reuseFunctions/letters'
import adminInstance from '../../../instances/admininstance'
import {setData} from "../../../../actions/admin-header"
// import { sendEmail } from "../../../../actions/notifications"
import { notify } from '../../../reuseComponent/toast'
import { url } from '../../../../constants'
import CustomModalWithChildren from '../../../reuseComponent/modal/modalWithChildren'
import { ConfirmModalContent } from '../../../reuseComponent/confirmModalComponent'
import TabsSettings from '../../settings/tabs';
import {convertToFormData} from '../../../../reuseFunctions/objectToFormData';
import {defaultServerError} from '../../../../reuseFunctions/toasts';
import {changeRoute} from '../../../../reuseFunctions/changeRoute';
import { history } from '../../../../history'

class NotificationsLetterForm extends PureComponent {
    state = {
        initValues: {
            subject: '',
            content: letterTemplate,
            recipients: []
        },
        loading: true,
        sendConfirm: false,
        variables: []
    };

    componentDidMount() {
        this.props.setHeaderData({
            LeftPannel: TabsSettings,
            leftPannelData: {
                alias: 'notifications'
            },
            handleSearch: null,
            handleAdd: null
        })
        adminInstance.get(`${url}/notify/variable/email`)
            .then(res => {
                this.setState({
                    loading: false,
                    variables: res.data.data
                });
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    };

    showHideModal = (state, callback) => {
        this.setState({
            ...state
        }, () => { callback && callback()})
    }

    handleSubmit = values => {
        values.recipients = values.recipients.map(recipientObj => recipientObj.value);
        this.setState({formValues: values, sendConfirm: true});
    };

    handleSendEmail = () => {
        const { formValues } = this.state
        this.setState({
            loading: true,
            sendConfirm: false
        },() => {
            adminInstance.post(`${url}/notify/send/email`,convertToFormData(formValues))
                .then(res => {
                    // console.log(res.data)
                    changeRoute('/atw/settings/notifications',history)
                    notify('success','Newsletter successfully sent')
                })
                .catch(err => {
                    this.setState({
                        loading: false
                    })
                    defaultServerError()
                })
        })

    }

    render() {
        const { loading } = this.state
        return (
            <div>
                { !loading ? <Formik
                    initialValues={this.state.initValues}
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    render={props => <BodyForm {...props}  variables={this.state.variables}/>}
                /> : <Preloader /> }
                <CustomModalWithChildren
                        open={this.state.sendConfirm}
                        handleClose={() => this.setState({
                            sendConfirm: false,
                        })}
                        handleCancel={() => this.setState({
                            sendConfirm: false,
                        })}
                        handleConfirm={this.handleSendEmail}
                        options={{
                            centered: true
                        }}
                    >
                        <ConfirmModalContent text={`send a letter`}/>
                </CustomModalWithChildren>    
            </div>
        );  
    };
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
    setHeaderData: obj => dispatch(setData(obj)),
    // sendEmail: obj => dispatch(sendEmail(obj))
});

export default connect(mapStateToProps,mapDispatchToProps)(NotificationsLetterForm)