import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import Header from '../../../../reuseComponent/Table/table-head'
import Pagination from '../../../../reuseComponent/Table/Pagination'
import ActionsMenu from './actions-menu'
import { setData } from '../../../../../actions/admin-header'
import { changeRoutePush } from '../../../../../reuseFunctions/changeRoute'
import { history } from '../../../../../history'
import CustomModalWithChildren from "../../../../reuseComponent/modal/modalWithChildren";
import {getPaginatableSimpleParameter, removeDictionaryValue} from "../../../../../services/dictionaries";
import {notify} from "../../../../reuseComponent/toast";
import {findPermInArray} from "../../../../../reuseFunctions/checkPermission";
import {permEditCg3Dictionary} from "../../../../../permissions/dictionaries";
import StandartLeftPanner from "../../../../reuseComponent/Header/standartLeftPannel";
import Toolbar from '../Toolbar'
import Table from '../../../../reuseComponent/Table/MaterialTable'
import {ConfirmModalContent} from '../../../../reuseComponent/confirmModalComponent';

class ListCategories extends PureComponent{

    state = {
        isOpenRemoModal : false,
        removedTitle: false,
        idCategory: null
    }

    showHideModal = ({modalName,isOpen,idCategory,callback}) => {
        this.setState({
            idCategory,
            [modalName] : isOpen
        },() => callback && callback())
    }

    tableRef = React.createRef()

    columns = [
        {
            title: 'Output',
            field: 'value',
            sorting: false
        },
        {
            title: 'Inputs',
            field: 'inputs',
            sorting: false,
            render: rowData => {
                return rowData.synonyms ? rowData.synonyms.map(val => <div key={val}>{val}</div>) : null
            }
        },
        {
            title: 'Dependencies',
            field: 'relatedFrom',
            sorting: false,
            render: rowData => {
                return rowData.relatedTextFormat ? rowData.relatedTextFormat.split(' ').map(value => <div key={value}>{value}</div>) : null
            }
        },
        {
            title: '',
            field: 'actions',
            sorting: false,
            headerStyle: {
                width: 32
            },
            render: rowData => {
                return (
                    <ActionsMenu
                        handleDelete={() => {
                            this.setState({
                                removedTitle: rowData.value,
                            });
                            this.showHideModal({
                                modalName: 'isOpenRemoModal',
                                isOpen: true,
                                idCategory: rowData.id
                            })
                        }}
                        rowData={rowData}
                    />
                )
            }
        }
    ]

    componentDidMount(){
        const { setHeaderData } = this.props
        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'CG3',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#795548" d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z" />
                </svg>)),
            },
            textAddButton: 'Add',
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: (value) => {
                this.tableRef.current && this.tableRef.current.onSearchChange(value)
            }
        })
    }

    componentWillUnmount() {
        const { setHeaderData } = this.props
        setHeaderData && setHeaderData({title: null, handleAdd: null, handleSearch: null})
    }

    getCg3List = query => {
        const { page, pageSize, search } = query;
        return new Promise((resolve,reject) => {
            getPaginatableSimpleParameter('category', page, pageSize, search)
                .then(res => {
                    resolve({
                        totalCount: res.data.count,
                        data: res.data.parameters,
                        page: query.page
                    })
                })
        })
    };

    refetchTable = () => {
        this.tableRef.current && this.tableRef.current.onQueryChange()
    };

    render(){
        const { isLoading,roles } = this.props
        const { idCategory,isOpenRemoModal, removedTitle } = this.state
        return (
            <div className="wrapper-table">
                <Table
                    components={{
                        Toolbar: props => <Toolbar
                            {...props}
                            handleAdd={findPermInArray(roles, permEditCg3Dictionary) ? () => changeRoutePush('/atw/dictionaries/cg3/create',history) : null}
                        />,
                        Header: Header,
                        Pagination: Pagination
                    }}
                    paginationPosition="toolbar"
                    isLoading={isLoading}
                    tableRef={this.tableRef}
                    columns={this.columns}
                    data={this.getCg3List}
                    options={{
                        maxBodyHeight: 'calc(100vh - 150px)',
                        showTitle: false,
                        search: true,
                        emptyRowsWhenPaging: false,
                        pageSize: 50,
                        pageSizeOptions: [10, 25, 50, 100],
                    }}
                />
                {findPermInArray(roles, permEditCg3Dictionary) && <CustomModalWithChildren
                    open={isOpenRemoModal}
                    handleCancel={() => this.showHideModal({
                        modalName: 'isOpenRemoModal',
                        isOpen: false
                    })
                    }
                    handleClose={() => this.showHideModal({
                        modalName: 'isOpenRemoModal',
                        isOpen: false
                    })
                    }
                    handleConfirm={() => this.showHideModal({
                        modalName: 'isOpenRemoModal',
                        isOpen: false,
                        callback: () => {
                            removeDictionaryValue('category', idCategory)
                                .then(res => {
                                    this.refetchTable();
                                    notify('success','Category successfully deleted')
                                })
                                .catch(err => {
                                    notify('error','Oops something went wrong')
                                })
                        }
                    })}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent text={`delete "${removedTitle}" value`}/>
                </CustomModalWithChildren>}
                
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.commodityGroupsReducer.isLoadingCategories,
        roles: state.rolesReducer.roles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setHeaderData: (data) => dispatch(setData(data)),
        // removeCategory: (id) => dispatch(removeCategory(id))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ListCategories)