import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { changeActiveSet } from '../../../../../../actions/dividing'
import Tooltip from '../../../../../reuseComponent/Tooltips'

class RestComponent extends PureComponent{

    componentDidMount(){
        const { activeSet,changeActiveSet,rest = {} } = this.props
        !activeSet && changeActiveSet && changeActiveSet(rest.id)
    }

    render(){
        const {rest = {},activeSet,changeActiveSet} = this.props
        return (
            <div className={`rest ${activeSet === rest.id ? 'active' : ''}`} onClick={() => changeActiveSet(rest.id)}>
                <span className="name">Not divided</span>
                <div className="info-rest">
                    <span className="total-rrp">€ {rest.totalRRP.toFixed(2)}</span>
                    <span className="items">
                    <Tooltip
                            text="Total items"
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M20.5,11H19V7C19,5.89 18.1,5 17,5H13V3.5A2.5,2.5 0 0,0 10.5,1A2.5,2.5 0 0,0 8,3.5V5H4A2,2 0 0,0 2,7V10.8H3.5C5,10.8 6.2,12 6.2,13.5C6.2,15 5,16.2 3.5,16.2H2V20A2,2 0 0,0 4,22H7.8V20.5C7.8,19 9,17.8 10.5,17.8C12,17.8 13.2,19 13.2,20.5V22H17A2,2 0 0,0 19,20V16H20.5A2.5,2.5 0 0,0 23,13.5A2.5,2.5 0 0,0 20.5,11Z"></path></svg>
                        </Tooltip>
                        {rest.totalCount}
                    </span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeSet : state.dividingReducer.activeSet
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeActiveSet: setId => dispatch(changeActiveSet(setId))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RestComponent)