import {PRINTERS} from '../constants'

const initialState = {
    selected_device: null,
    devices: [],
    loading: false,
    loadingLocationConfiguration: false,
};

export const printersReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case PRINTERS.SET_SELECTED_PRINTER:{
            return {
                ...state,
                selected_device: payload
            }
        }
        case PRINTERS.SET_AVAILABLE_PRINTERS:{
            return {
                ...state,
                devices: [...state.devices, ...payload],
            }
        }
        case PRINTERS.SET_LOADING_CONNECTION_TO_PRINTERS: {
            return {
                ...state,
                loading: payload
            }
        }
        case PRINTERS.SET_LOADING_LOCATION_CONFIGURATION: {
            return {
                ...state,
                loadingLocationConfiguration: payload
            }
        }
        default:
            return state;
    }
};