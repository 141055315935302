import React, { useState, useEffect, useCallback } from 'react'
import { ColorPicker } from './ColorPicker'
import Switcher from '../../../../reuseComponent/Switcher'
import AutosizeInput from 'react-input-autosize'
import isEqual from 'react-fast-compare'

import { ReactComponent as SaveIcon } from '../../../../../assets/images/save.svg'
import { ReactComponent as ClearIcon } from '../../../../../assets/images/clear.svg'
import CustomTooltip from '../../../../reuseComponent/Tooltips'
import { LocationTypeAPI } from '../../../../../services/location-type'
import { defaultServerError } from '../../../../../reuseFunctions/toasts'
import { notify } from '../../../../reuseComponent/toast'

const EditableTableItem = ({
                             handleCancel,
                              editCallback,
                              instrument,
                             setLoading,
                           }) => {

  const [values, setValues] = useState(null)
  const [hasChanges, setHasChanges] = useState(false)
  const [touched, setTouched] = useState(false)

  const setFieldValue = useCallback((field, value) => {
    setValues(prevState => ({
      ...prevState,
      [field]: value
    }))
  }, [])

  const handleSubmit = useCallback((values) => {
    if(!values.name.trim()){
      setTouched(true)
    }else{
      setLoading(true)
      const {
        id,
        clear,
        deleted,
        plan,
        ...sendData
      } = values;
      LocationTypeAPI.updateLocationType(id, sendData)
        .then(res => {
          if(res.data.data){
            editCallback(res.data.data)
            handleCancel()
            notify('success', 'Space successfully edited')
          }else{
            defaultServerError()
          }
        })
        .catch(defaultServerError)
        .finally(() => setLoading(false))
    }
  }, [setLoading, editCallback, handleCancel])

  useEffect(() => {
    setValues(instrument)
  }, [instrument])

  useEffect(() => {
    setHasChanges(!isEqual(values, instrument))
  }, [instrument, values])

  return values ? <>
    <td>
      <div style={{display: 'flex', flexDirection: 'column', maxWidth: '137px'}}>
        <AutosizeInput
          disabled={!instrument.deleted}
          placeholder="Enter space name"
          className="auto-resize-wrapper"
          value={values.name}
          name='name'
          onChange={e => {
            setFieldValue('name', e.target.value)
          }}
        />
        {touched && !values.name.trim() && <span className="error-label">Required field</span>}
      </div>
    </td>
    <td>
      <ColorPicker
        value={values.color}
        handleChange={(val) => setFieldValue('color', val)}
      />
    </td>
    <td>
      <Switcher
        disabled={!instrument.deleted}
        checked={values.slot}
        onChange={e => setFieldValue('slot', e)}
      />
    </td>
    <td className="actions-cell with-form">
      {hasChanges && <CustomTooltip text="Save">
        <SaveIcon className="standard-svg" onClick={() => handleSubmit(values)}/>
      </CustomTooltip>}
      <CustomTooltip text="Cancel">
        <ClearIcon className="standard-svg" onClick={handleCancel}/>
      </CustomTooltip>
    </td>
  </> : null
}

export { EditableTableItem }