import { url } from '../constants'
import adminInstance from '../components/instances/admininstance'

export const getPoFilters = () => {
    return adminInstance.get(`${url}/filter`,{
        params: {
            view: 'order'
        }
    })
}

export const getPoFiltersFields = (view='objects') => {
    return adminInstance.get(`${url}/filter/field/${view}`)
}

export const createFilter = (formDataValues) => {
    return adminInstance.post(`${url}/filter`,formDataValues,{
        params: {
            view: 'order'
        }
    })
}

export const editPoFilter = (id,formDataValues) => {
    return adminInstance.put(`${url}/filter/${id}`,formDataValues)
}

export const removePoFilter = id => {
    return adminInstance.delete(`${url}/filter/${id}`)
}

export const getPoFilterById = id => {
    return adminInstance.get(`${url}/filter/${id}`)
}

export const setActiveFilter = (id = 'no') => {
    let formData = new FormData()
    formData.append('activate',id)
    formData.append('view','order')
    return adminInstance.post(`${url}/filter/activate`,formData)
}