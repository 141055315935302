import React, { PureComponent } from 'react'
import  Switch  from 'react-switch'
import './style.scss'

class Switcher extends PureComponent{

    static defaultProps = {
        text: '',
        checked: false,
        onChange : () => {},
        disabled: false,
        options : {
            offColor: '#888',
            onColor: '#55c1e9',
            height: 14,
            width: 28,
            uncheckedIcon: false,
            checkedIcon: false
        }

    }

    render(){
        const {
            options,
            checked,
            onChange,
            text,
            disabled
        } = this.props
        return (
            <label onClick={e => e.stopPropagation()}  className="switch-label">
                <span>{text}</span>
                <Switch onChange={onChange} disabled={disabled} checked={checked} {...options}/>
            </label>
        )
    }
}

export default Switcher