import adminInstance from '../components/instances/admininstance'
import { url } from '../constants'

export const getFiltersList = (alias) => {
    return adminInstance.get(`${url}/filter`,{
        params: {
            view: alias
        }
    })
}

export const createOfferFilter = (values,alias) => {
    return adminInstance.post(`${url}/filter`,values,{
        params: {
            view: alias
        }
    })
}

export const getFieldsForFilterByAlias = (alias) => {
    return adminInstance.get(`${url}/filter/field/${alias}`)
}

export const setActiveFilter = (id,alias) => {
    let formData = new FormData()
    formData.append('activate',id)
    formData.append('view',alias)
    return adminInstance.post(`${url}/filter/activate`,formData)
}

export const getFilterInfo = (id) => {
    return adminInstance.get(`${url}/filter/${id}`)
}

export const editFilterById = (id,values) => {
    return adminInstance.put(`${url}/filter/${id}`,values)
}

export const deleteFilterById = (id) => {
    return adminInstance.delete(`${url}/filter/${id}`)
}