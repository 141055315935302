import React , { PureComponent } from 'react'
import TablesAnalysis from '../../products/analysis/tables';


class Analysis extends PureComponent{
    render(){
        return (
            <div className="po-tab-content">
                <div className="content-po-tab">
                    <TablesAnalysis/>
                </div>
            </div>
        )
    }
}

export default Analysis