import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import { Route } from 'react-router-dom'
import {setData} from '../../../actions/admin-header'
import Table from '../../reuseComponent/Table/MaterialTable'
import Pagination from '../../reuseComponent/Table/Pagination'
import adminsInstance from '../../instances/admininstance'
import {url} from '../../../constants'
import Header from '../../reuseComponent/Table/table-head'
import Toolbar from './Toolbar'
import StandartLeftPanner from '../../reuseComponent/Header/standartLeftPannel'
import Container from '../../reuseComponent/Table/container'
import moment from 'moment'
import IconButtonMaterial from '../../reuseComponent/Buttons/ButtonWithMaterialIcon'
import Modal from '../../reuseComponent/modal/modalWithChildren'
import {notify} from '../../reuseComponent/toast';
import {ConfirmModalContent} from '../../reuseComponent/confirmModalComponent';
import CreateOfferForm from './create'
import {ReactComponent as ClearIcon} from '../../../assets/images/clear.svg'
import {OfferApi} from '../../../services/offers'
import {defaultServerError} from '../../../reuseFunctions/toasts';
import withTracker from '../../reuseComponent/GoogleAnalytics';
import SingleDatePicker from '../../reuseComponent/DatePicker/datepicker-with-children';
import DetailsOffer from './details'
import {changeRoutePush} from '../../../reuseFunctions/changeRoute';
import { history } from '../../../history';
import {setReservationData} from "../../../actions/offersActions";

class OffersComponent extends PureComponent {
    state = {
        currentOffer: null,
        modalRemoveOffer: false,
        modalCreateOffer: false,
        modalEditOffer: false,
        isExporting: false,
        reservDate: '',
        confirmReservation: false,
        cancelReservModal: false
    };

    handleHideRemoveOfferModal = () =>
        this.setState({currentOffer: null, modalRemoveOffer: false});

    handleShowRemoveOfferModal = offerId =>
        this.setState({currentOffer: offerId, modalRemoveOffer: true});

    handleShowCreateOfferModal = () => this.setState({modalCreateOffer: true});

    handleHideCreateOfferModal = () => this.setState({modalCreateOffer: false});



    handleShowConfirmReservation = (id, reservationdDate) =>
        this.setState({
            currentOffer: id,
            reservationdDate,
            confirmReservation: true
        });
    handleHideConfirmReservation = () =>
        this.setState({confirmReservation: false, currentOffer: null});

    handleShowCancelReserv = id =>
        this.setState({cancelReservModal: true, currentOffer: id});
    handleHideCancelReserv = () =>
        this.setState({cancelReservModal: false, currentOffer: null});

    tableRef = React.createRef();

    generateColumns = () => {
        return [
            {
                title: 'Title',
                field: 'title',
                render: rowData => {
                    return <span>{rowData.title}</span>;
                }
            },
            {
                title: 'Created',
                field: 'createdAt',
                headerStyle: {
					textAlign: "right"
				},
				cellStyle: {
					textAlign: "right"
				},
                render: rowData => {
                    return (
                        <span>
              {moment(rowData.createdAt, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD.MM.YYYY HH:mm'
              )}
            </span>
                    );
                }
            },
            {
                title: 'Author',
                field: 'author',
                render: rowData => {
                    return (
                        <span>{`${rowData.author.name} ${rowData.author.secondName}`}</span>
                    );
                }
            },
            {
                title: 'Side',
                field: 'side',
                render: rowData => {
                    return <span>{rowData.side}</span>;
                }
            },
            {
                title: 'Unit',
                field: 'unit',
                render: rowData => {
                    return <span>{rowData.unit}</span>;
                }
            },
            {
                title: 'Pallets',
                field: 'pallets',
                render: rowData => {
                    
                return <span>{rowData.pallets.map(item => item.id).join(', ')}</span>;
                }
            },
            {
                title: 'Price, €',
                field: 'price',
                headerStyle: {
					textAlign: "right"
				},
				cellStyle: {
					textAlign: "right"
				},
                render: rowData => {
                    return <span>{rowData.price ? Number(rowData.price).toFixed(2) : rowData.price}</span>;
                }
            },
            {
                title: 'MOQ',
                field: 'moq',
                headerStyle: {
					textAlign: "right"
				},
				cellStyle: {
					textAlign: "right"
				},
                render: rowData => {
                    return <span>{rowData.moq}</span>;
                }
            },
            {
                title: 'Availability',
                field: 'availability',
                render: rowData => {
                    return <span>{rowData.availability}</span>;
                }
            },
            {
                title: 'Delivery time',
                field: 'deliveryTime',
                render: rowData => {
                    return <span>{rowData.deliveryTime}</span>;
                }
            },
            {
                title: 'Views',
                field: 'viewsCount',
                headerStyle: {
					textAlign: "right"
				},
				cellStyle: {
					textAlign: "right"
				},
                render: rowData => {
                    return <span>{rowData.viewsCount}</span>;
                }
            },
            {
                title: 'Comment',
                field: 'comment',
                render: rowData => {
                    return <span>{rowData.comment}</span>;
                }
            },
            {
                title: 'Picture',
                field: 'picture',
                render: rowData => {
                    return (
                        <span>
              {rowData.picture ? (
                  <img
                      src={rowData.picture}
                      className="offer-pic"
                      alt="offer-pic"
                  />
              ) : null}
            </span>
                    );
                }
            },
            {
                title: 'Reservation',
                field: 'reservationEndDate',
                cellStyle: {
                    minWidth: '190px',
                    // paddingRight: '0px'
                },
                headerStyle: {
                    // paddingRight: '0px'
                },
                render: rowData => {
                    const isBookedUser =
                        rowData.previousUserReservation &&
                        rowData.previousUserReservation.id === this.props.userInfo.info.id;
                    const isAdmin = this.props.userInfo.role.alias === 'admin';
                    return rowData.reservationEndDate ? (
                        <span className="reservation-info">
              {rowData.reservationStartDate} - {rowData.reservationEndDate}{' '}
                            {`${rowData.usersReservation.name} ${rowData.usersReservation.secondName}`}
                            {(isAdmin ||
                                rowData.usersReservation.id === this.props.userInfo.info.id) && (
                                <IconButtonMaterial
                                    customClass="show-on-hover show-on-hover-custom"
                                    title="Cancel reservation"
                                    onClick={e => {
                                        e.stopPropagation();
                                        this.handleShowCancelReserv(rowData.id);
                                    }}
                                >
                                    <ClearIcon/>
                                </IconButtonMaterial>
                            )}
            </span>
                    ) : (
                        <div
                            style={
                                isBookedUser ? {pointerEvents: 'none', opacity: '0.5'} : {}
                            }
                            className="wrapper-calendar-reserv"
                            onClick={e => e.stopPropagation()}
                        >
                            <SingleDatePicker
                                startDate={moment()}
                                minDate={moment()}
                                maxDate={moment().add(13, 'day')}
                                onApply={(event, picker) => {
                                    this.handleShowConfirmReservation(
                                        rowData.id,
                                        moment(picker.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
                                    );
                                }}
                            >
                                <svg viewBox="0 0 24 24">
                                    <path
                                        fill="#000000"
                                        d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z"
                                    />
                                </svg>
                                <span className="date-text">
                  {!isBookedUser
                      ? 'No reservation date'
                      : 'Unable to book twice'}
                </span>
                            </SingleDatePicker>
                        </div>
                    );
                }
            },
            // {
            //     title: '',
            //     field: 'actions',
            //     render: rowData => {
            //         return (
            //             <>
            //                 <IconButtonMaterial
            //                     customClass="show-on-hover"
            //                     title="Export to pdf"
            //                     onClick={e => {
            //                         e.stopPropagation();
            //                         this.handleExportToPdf(rowData.id);
            //                         // this.handleShowRemoveOfferModal(rowData.id)
            //                     }}
            //                 >
            //                     <PdfIcon/>
            //                 </IconButtonMaterial>
            //                 <IconButtonMaterial
            //                     customClass="show-on-hover"
            //                     title="Delete"
            //                     onClick={e => {
            //                         e.stopPropagation();
            //                         this.handleShowRemoveOfferModal(rowData.id);
            //                     }}
            //                 >
            //                     <DeleteIcon/>
            //                 </IconButtonMaterial>
            //             </>
            //         );
            //     }
            // }
        ];
    };

    handleRevervation = (id, reservationdDate) => {
        const {userInfo} = this.props;
        const {params} = this.props.match;
        const {setReservationData} = this.props;
        OfferApi.reservationOffer(id, reservationdDate, userInfo.info.id)
            .then(res => {
                if (res.data.data) {
                    this.handleHideConfirmReservation();
                    this.tableRef.current.onQueryChange();
                    if(parseInt(id) === parseInt(params.id)){
                        setReservationData({
                            reservationEndDate: res.data.data.reservationEndDate,
                            reservationStartDate: res.data.data.reservationStartDate,
                            usersReservation: res.data.data.usersReservation,
                            previousUserReservation: res.data.data.previousUserReservation,
                        });
                    }
                    notify('success', 'Offer successfully reserved');
                } else {
                    defaultServerError();
                }
            })
            .catch(err => defaultServerError())
            .finally(() => this.handleHideConfirmReservation());
    };

    handleDeleteRecervation = id => {
        const {params} = this.props.match;
        const {setReservationData} = this.props;
        OfferApi.cancelReservation(id)
            .then(res => {
                if (res.data.data) {
                    this.tableRef.current.onQueryChange();
                    if(parseInt(id) === parseInt(params.id)){
                        setReservationData(null);
                    }
                    notify('success', 'Offer reservation successfully canceled');
                } else {
                    defaultServerError();
                }
            })
            .catch(err => defaultServerError())
            .finally(() => this.handleHideCancelReserv());
    };

    componentDidMount() {
        this.props.setHeaderData({
            leftPannelData: {
                title: 'Offers',
                logo: (
                    <svg className="action-icon header-icon" viewBox="0 0 24 24">
                        <path
                            fill="#673AB7"
                            d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z"
                        />
                    </svg>
                )
            },
            textAddButton: 'Add',
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: value => this.tableRef.current.onSearchChange(value)
        });
    }

    handleExportToPdf = id => {
        this.setState(
            {
                isExporting: true
            },
            () => {
                OfferApi.exportToPdf(id)
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `Offer_#${id}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        this.setState({
                            isExporting: false
                        });
                    })
                    .catch(err => defaultServerError());
            }
        );
    };

    handleDeleteOffer = () => {
        const {currentOffer} = this.state;
        if (currentOffer) {
            adminsInstance
                .delete(`${url}/offers/${currentOffer}`)
                .then(res => {
                    notify('success', 'Offer successfully deleted');
                    this.tableRef.current && this.tableRef.current.onQueryChange();
                    changeRoutePush(`/atw/offers`, history)
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong');
                });
        }
        this.handleHideRemoveOfferModal();
    };

    getOffers = query => {
        return new Promise(resolve => {
            adminsInstance
                .get(`${url}/offers`, {
                    params: {
                        page: query.page ? query.page : 0,
                        limit: query.pageSize,
                        search: `s:${query.search};`
                    }
                })
                .then(res => {
                    resolve({
                        data: this.generateRow(res.data.offers),
                        // data: [],
                        page: query.page,
                        totalCount: res.data.count
                    });
                })
                .catch(err => {
                    resolve({
                        data: [],
                        page: 0,
                        totalCount: 0
                    });
                });
        });
    };

    generateRow = data => {
        return data.map(offer => {
            return {
                id: offer.id,
                title: offer.title,
                createdAt: offer.createdAt,
                author: offer.author,
                side: offer.side,
                unit: offer.unit,
                pallets: offer.pallets,
                price: offer.price,
                moq: offer.moq,
                availability: offer.availability,
                deliveryTime: offer.deliveryTime,
                viewsCount: offer.viewsCount,
                comment: offer.comment,
                picture: offer.picture,
                usersReservation: offer.usersReservation,
                reservationEndDate: offer.reservationEndDate,
                previousUserReservation: offer.previousUserReservation,
                reservationStartDate: offer.reservationStartDate
            };
        });
    };

    render() {
        const {id} = this.props.match.params;
        const {
            modalRemoveOffer,
            modalCreateOffer,
            currentOffer,
            isExporting,
            confirmReservation,
            reservationdDate,
            cancelReservModal
        } = this.state;

        console.log(id);
        console.log(this.props.match.params);
        return (
            <div
                // style={{ minHeight: "calc(100vh - 70px)" }}
                className={`wrap-table-with-details-panel ${!id ? '' : 'with-details'}`}
            >
                <div className="table-block wrapper-table offers-table">
                    <Table
                        components={{
                            Header: Header,
                            Toolbar: Toolbar,
                            Container: Container,
                            Pagination: Pagination
                        }}
                        toolbarData={{
                            handleAdd: this.handleShowCreateOfferModal
                        }}
                        isLoading={isExporting}
                        tableRef={this.tableRef}
                        paginationPosition="toolbar"
                        columns={this.generateColumns()}
                        data={this.getOffers}
                        onRowClick={(event, rowData) => {
                            changeRoutePush(`/atw/offers/${rowData.id}`,history)
                            // this.handleShowEditOfferModal(rowData.id);
                        }}
                        options={{
                            emptyRowsWhenPaging: false,
                            pageSize: 50,
                            pageSizeOptions: [10, 25, 50],
                            rowStyle: props => {
                                if (parseInt(id) === props.id || id === props.id)
                                    return {
                                        backgroundColor: '#f2f2f2'
                                    };
                                else {
                                    return {};
                                }
                            },
                            maxBodyHeight: 'calc(100vh - 130px)'
                        }}
                    />
                </div>
                <Route exact path="/atw/offers/:id" render={props =>
                    <DetailsOffer
                        {...props}
                        handleExportToPdf={this.handleExportToPdf}
                        handleShowRemoveOfferModal={this.handleShowRemoveOfferModal}
                        handleShowCancelReservation={this.handleShowCancelReserv}
                        handleShowConfirmReservation={this.handleShowConfirmReservation}
                    />
                }/>
                <Modal
                    open={modalRemoveOffer}
                    handleConfirm={this.handleDeleteOffer}
                    handleCancel={this.handleHideRemoveOfferModal}
                    handleClose={this.handleHideRemoveOfferModal}
                    options={{
                        centered: true,
                        animation: false
                    }}
                >
                    <ConfirmModalContent text="delete offer"/>
                </Modal>
                <Modal
                    classNameModal="offer-create-modal"
                    open={modalCreateOffer}
                    // handleClose={this.handleHideCreateOfferModal}
                    isFooter={false}
                    options={{
                        centered: true,
                        animation: false
                    }}
                >
                    <CreateOfferForm
                        updateListOffers={
                            this.tableRef.current ? this.tableRef.current.onQueryChange : null
                        }
                        handleCancel={this.handleHideCreateOfferModal}
                    />
                </Modal>


                <Modal
                    open={confirmReservation}
                    handleConfirm={() =>
                        this.handleRevervation(currentOffer, reservationdDate)
                    }
                    handleCancel={this.handleHideConfirmReservation}
                    handleClose={this.handleHideConfirmReservation}
                    options={{
                        centered: true,
                        animation: false
                    }}
                >
                    <ConfirmModalContent text={`reserve offer #${currentOffer}`}/>
                </Modal>

                <Modal
                    open={cancelReservModal}
                    handleConfirm={() => this.handleDeleteRecervation(currentOffer)}
                    handleCancel={this.handleHideCancelReserv}
                    handleClose={this.handleHideCancelReserv}
                    options={{
                        centered: true,
                        animation: false
                    }}
                >
                    <ConfirmModalContent
                        text={`cancel reservation offer #${currentOffer}`}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userInfo: state.usersReducer.currUser
});

const mapDispatchToProps = dispatch => ({
    setHeaderData: obj => dispatch(setData(obj)),
    setReservationData: data => dispatch(setReservationData(data))
})

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(OffersComponent))


