import React, {PureComponent} from 'react';
import ActionComponent from './action';
import {connect} from 'react-redux'
import moment from 'moment';
import CustomModalWithChildren from '../modal/modalWithChildren';
import Preloader from '../preloader/preloader';
import Diff from './diff';

class HistoryList extends PureComponent {

    state = {
        modalDiff: false,
        diff: null,
        loadHistory: true,
        history: [],
    }

    componentDidMount() {
        const {getHistory, id} = this.props;
        getHistory && getHistory(id, '')
        .then(res => {
            this.setState({
                history: res.data.data,
                loadHistory: false
            });
        });
    }

    showHideModal = (open, diff, callback) => {
        this.setState({
            modalDiff: open,
            diff: diff
        }, () => callback && callback())
    }

    handleCloseModal = () => this.showHideModal(false, null);

    checkIfContainsType = (type) => {
        const types = ['ACTION', 'FILE', 'TASK_ACTION', 'TRANSPORT_NOTE']
        return types.includes(type);
    }

    render() {
        const {diff, modalDiff, loadHistory, history} = this.state;
        const {chronologyFiledsConverting} = this.props;
        let lastDate = null;
        let lastType = null;

        return (
            <>
                <div className="list-history">
                    <div className="list-history-title">
                        <h6>
                            <svg className="action-icon header-icon" viewBox="0 0 24 24"><path fill="#B0BEC5" d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3"></path></svg>
                            Chronology
                        </h6>
                    </div>
                    {
                        loadHistory ? <Preloader /> : history.map(item => {
                            console.log('itemOffer',item)
                            const isShowDate = !lastDate || !lastType || (this.checkIfContainsType(item.type) && lastDate && !moment(item.createdAt).isSame(lastDate, 'day'));
                            lastDate = item.createdAt;
                            lastType = item.type;

                            switch(item.type) {
                                case 'ACTION' : {
                                    return <ActionComponent 
                                        showDiff={this.showHideModal}
                                        key={item.id}
                                        action={item}
                                        isShowDate={isShowDate} />                                
                                }
                                default: 
                                    return null;
                            }
                        })
                    }
                </div>
                <CustomModalWithChildren
                    open={modalDiff}
                    isFooter={false}
                    options={{
                        centered: true
                    }}
                    handleClose={() => this.showHideModal(false, null)}
                >
                    <Diff 
                        diff={diff} 
                        handleCloseModal={this.handleCloseModal} 
                        chronologyFiledsConverting={chronologyFiledsConverting} />
                </CustomModalWithChildren>
            </>
        );
    }
}

export default connect(null, null)(HistoryList);