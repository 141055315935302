import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import Select from '../../../reuseComponent/Select/async-select';
import {getPallets} from '../../../../services/inventory';
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import CreatePalletForm from '../create'

class MoveBoxesModal extends PureComponent {
    state = {
        isTouched: false,
        isCreateNewPallet: false,
        selectedPallet: null
    };

    getPalletsForSelect = (inputValue) => {
        return new Promise(resolve => {
            getPallets({
                limit: 100,
                page: 0,
                search: `s:${inputValue}`
            }).then(res => {
                resolve([{
                    label: 'Create pallet',
                    value: 'new'
                }, ...res.data.data.content.map(pallet => ({
                    value: pallet.id,
                    label: (
                        <div className="label-pallet">
                            <div className="label">Pallet #{pallet.id}</div>
                            {pallet.info.brands.length > 0 && (
                                <div>Brands: {pallet.info.brands.join(',')}</div>
                            )}
                            {pallet.info.cg1.length > 0 && (
                                <div>Cg1: {pallet.info.cg1.join(',')}</div>
                            )}
                            {pallet.info.cg2.length > 0 && (
                                <div>Cg2: {pallet.info.cg2.join(',')}</div>
                            )}
                            {pallet.info.cg3.length > 0 && (
                                <div>Cg3: {pallet.info.cg3.join(',')}</div>
                            )}
                        </div>
                    )
                })).filter(pallet => pallet.value !== +this.props.match.params.id)])
            })
        })
    }

    handleSubmit = () => {
        const {selectedPallet, handleConfirm} = this.props;
        this.setState({
            isTouched: true
        });
        if (selectedPallet) {
            handleConfirm(selectedPallet);
        }
    };

    callbackCreatePallet = data => {
        const { handleChangePallet, callbackCreatePallet } = this.props
        this.setState({
            isCreateNewPallet: false,
            selectedPallet: {
                label: `Pallet #${data.id}`,
                value: data.id
            }
        },() => {
            callbackCreatePallet && callbackCreatePallet(data)
            handleChangePallet(data.id)
        })
    }

    render() {
        const {isTouched, isCreateNewPallet,selectedPallet} = this.state;
        const {
            handleClose,
            handleChangePallet,
            selectedBoxes,
            isConfirm,
            refetchTable
        } = this.props;
        return !isConfirm ? (
            <div className="move-boxes-modal-wrapper">
                {isCreateNewPallet ? <CreatePalletForm
                        callback={this.callbackCreatePallet}
                        isCreateCustom={true}
                        refetchTable={refetchTable}
                        handleCancel={handleClose}
                    /> :
                    <>
                        <p className="move-action-title">
                            You are going to move {selectedBoxes.length}{' '}
                            {`${selectedBoxes.length > 1 ? 'packs' : 'pack'}`} from pallet #
                            {this.props.match.params.id}
                        </p>
                        <div>
                            <Select
                                value={selectedPallet}
                                defaultOptions={true}
                                loadOptions={this.getPalletsForSelect}
                                onChange={obj => {
                                    if (obj.value === 'new') {
                                        this.setState({
                                            isCreateNewPallet: true
                                        })
                                    }
                                    else {
                                        this.setState({
                                            selectedPallet: obj
                                        })
                                        handleChangePallet(obj.value)
                                    }
                                }}
                                placeholder="Select pallet to move into"
                                isInvalid={isTouched && !selectedPallet}
                                errorLabel={'required field'}
                            />
                        </div>
                        <div className="footer-form footer-form-custom">
                            <Button className="cancel" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button onClick={this.handleSubmit}>
                                Save
                            </Button>
                        </div>
                    </>
                }
            </div>
        ) : (
            <div>
                <ConfirmModalContent
                    text={`move ${selectedBoxes.length} ${selectedBoxes.length > 1 ? 'packs' : 'pack'} to pallet #${selectedPallet.value}`}
                />
            </div>
        );
    }
}

export default withRouter(MoveBoxesModal);
