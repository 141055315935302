import React from 'react'
import { useSelector } from 'react-redux'
import CustomCheckBox from '../../../reuseComponent/checkbox'
import Tooltip from '../../../reuseComponent/Tooltips'
import { ReactComponent as ItemsIcon } from '../../../../assets/images/item.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg'
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'
import { permEditInventory } from '../../../../permissions/inventory'

const PackItem = ({
                    box,
                    method,
                    removeBox,
                    isScanned,
                    isSelected,
                    handleSelect,
                  }) => {

  const roles = useSelector(state => state.rolesReducer.roles)

  const hasPermToEdit = findPermInArray(roles, permEditInventory)

  return (
    <div className="box__row">
      <div className="row__header">
        <div
          className={'toggle'}
        >
        </div>
        <div className={`info__header pack-type ${isScanned ? 'scanned-box' : ''}`}>
          {hasPermToEdit && <div className="checkbox-box-wrapper">
            <CustomCheckBox
              checked={isSelected}
              handleChange={() => handleSelect(box.id)}
              options={{
                className: 'checkbox-material',
              }}
            />
          </div>
          }
          <div className="title__box">
            {box.pack ? `${box.pack.orderNumber} > ${box.pack.shortName} > ${method !== 'INVENTORY' && box.getPoInboundType !== 'Totals' && box.workplace ? box.workplace + '-' + box.name + ' >' : ''}` : null} #{box.id}
          </div>
            <div className={`total-count ${box.item === 0 ? 'empty-box-error' : ''}`}>
              <Tooltip text="Items">
                <ItemsIcon className="standart-svg"/>
              </Tooltip> {box.item}
            </div>
          {hasPermToEdit && <div className="delete-icon" >
              {box.item === 0 && <Tooltip text="Delete">
                <DeleteIcon onClick={removeBox} className="standart-svg"/>
              </Tooltip>
              }
            </div>}
        </div>
      </div>
    </div>
  )
}

export { PackItem }