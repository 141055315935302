import React , { PureComponent } from 'react'
import FirstStep from './firstStep'
import SecondStep from './secondStep'
import ThirdStep from './thirdStep'
import { createPallet,createCustomPallets,createPalletFromInventory } from '../../../../services/inventory'
import { notify } from '../../../reuseComponent/toast'
import ChooseMethodComponent from './choseMethodCreate'
import * as yup from 'yup'
import * as uniqId from 'uniqid'
import { customPalletMethod, inventoryMethod, inboundMethod } from './choseMethodCreate';
import { send } from 'react-ga'

const schema = yup.object().shape({
    weight: yup.number()
        .required('required field')
        .positive('positive numbers only')
        .typeError('positive numbers only')   
})

class CreatePallet extends PureComponent{

    indexCreatedBox = 0

    state = {
        isTouched: false,
        currentStep: this.props.isCreateCustom ? 2 : 1,
        poOptions: [],
        weightError: '',
        netWeightError: '',
        statusIdError: '',
        productNameError: '',
        packTypeIdError: '',
        locationIdError: '',
        orderNumberError: '',
        salesManagerError: '',
        method: this.props.isCreateCustom ? customPalletMethod : 'inbound',
        values: {
            boxesId: [],
            poIds: [], // Pallet ids or PoIds related from method creation pallet
            weight: '',
            netWeight: '',
            productName: '',
            newBoxes: [],
            salesManager: '',
            statusId: '',
            packTypeId: '',
            locationId: this.props.locationId ? this.props.locationId : null,
            cg1: [],
            cg2: [],
            season: '',
            note: '',
            orderNumber: '',
            quality: [],
            bid: '',
        },
        itemsCount: 0,
    }

    handleSubmit = () => {
        let {
            values,
            weightError,
            orderNumberError,
            productNameError,
            method,
            netWeightError,
            statusIdError,
            packTypeIdError,
            locationIdError,
            itemsCount,
            salesManagerError,
        } = this.state

        const {
            handleCancel,
            isCreateCustom,
            successCallback,
        } = this.props

        if(values.statusId == 1 && !values.orderNumber){
            orderNumberValidation = false;
        }

        if(!orderNumberValidation) {
            this.setState({
                    orderNumberError: 'required field'
                }
            )
        }

        let orderNumberValidation = true;
        let salesManagerValidation = true;

        if(values.statusId == 1 && !values.orderNumber){
            orderNumberValidation = false;
            this.setState({
                orderNumberError:  'Required field'
            })
        }

        if((values.statusId == 1 || values.statusId == 2) && !values.salesManager){
            salesManagerValidation = false;
            this.setState({
                salesManagerError:  'Required field'
            })
        }

        values.countBox = values.newBoxes.length
        this.setState({
            isTouched: true
        },() => {
            if(!netWeightError && !weightError && orderNumberValidation && salesManagerValidation && !productNameError && !statusIdError && !packTypeIdError && !locationIdError){
                !isCreateCustom && handleCancel()

                //format data before send
                let {
                    newBoxes,
                    ...sendData
                } = values
                sendData = {
                    ...sendData,
                    cg1: sendData.cg1.length > 0 ? values.cg1.map(item => item.value).join(',') : null,
                    cg2: sendData.cg2.length > 0 ? values.cg2.map(item => item.value).join(',') : null,
                    quality: sendData.quality.value,
                    locationId: sendData.locationId.value,
                    productName: sendData.productName.value,
                    countOfBoxes: sendData.countBox,
                }

                if(itemsCount){
                    delete sendData.cg1
                    delete sendData.cg2
                }

                //select method for pallet creation
                let promise = null
                if(method === inboundMethod)
                    promise = createPallet({...sendData})
                else if(method === inventoryMethod)
                    promise = createPalletFromInventory({
                        ...sendData
                    })
                else
                    promise = createCustomPallets({
                        ...sendData,
                    })

                promise
                    .then(res => {
                        successCallback && successCallback(res.data.data)
                })
                    .catch(err => {
                        notify('error','Oops something went wrong')
                    })
            }

        })

    }

    handleChangeItemCount = (newItemCount) => {
        this.setState(prevState => ({
            itemsCount: newItemCount,
            values: {
                ...prevState.values,
                cg1: newItemCount ? prevState.values.cg1 : [],
                cg2: newItemCount ? prevState.values.cg2 : [],
            }
        }))
    }

    handleChooseMethod = (method) => {
        this.setState(prevState => ({
            method: method,
            // currentStep: method !== customPalletMethod ? 1 : 2,
            poOptions: [],
            itemsCount: 0,
            values: {
                ...prevState.values,
                boxesId: [],
                poIds: [], // Pallet ids or PoIds related from method creation pallet
                newBoxes: [],
            },
        }))
    }

    handleChangeStep = step => this.setState({currentStep: step})

    handleChangePoValues = options => {
        this.setState({
            poOptions: options ? options : [],
            values: {
                ...this.state.values,
                poIds: options ? options.map(option => option.value) : []
            }
        })
    }

    replaceBoxes = boxesId => {
        this.setState({
            values: {
                ...this.state.values,
                boxesId: boxesId
            }
        })
    }

    handleChangeBoxes = box => {
        const isRemove =  this.state.values.boxesId.includes(box.id)
        const boxItemCount = box.poInboundType === 'Totals' ? box.item : box.productsCount
        const newItemsCount = isRemove ? this.state.itemsCount - boxItemCount : this.state.itemsCount + boxItemCount

        this.setState({
            values: {
                ...this.state.values,
                boxesId: isRemove
                    ? this.state.values.boxesId.filter(bId => bId !== box.id)
                    : this.state.values.boxesId.concat(box.id),
                cg1: newItemsCount ? this.state.values.cg1 : [],
                cg2: newItemsCount ? this.state.values.cg2 : [],
            },
            itemsCount: newItemsCount,
        },() => {
            if(this.state.values.boxesId.length > 10)
                notify('warning','Over 10 packs selected!')
        })
    }

    handleChange = e => {
        schema.validate({'weight': e.target.value})
            .then(res => {
                this.setState({
                    weightError: '',
                })
            })
            .catch(err => {
                this.setState({
                    weightError: err.errors[0],
                })
            })
        this.setState({
            values: {
                ...this.state.values,
                weight: e.target.value
            }
        })
    }

    handleChangeSalesManager = (fieldName, e) => {
            schema.validate({'salesManager': e.value})
                .then(res => {
                    this.setState({
                        salesManagerError: '',
                    })
                })
                .catch(err => {
                    this.setState({
                        salesManagerError: err.errors[0],
                    })
                })
            this.setState({
                values: {
                    ...this.state.values,
                    salesManager: e.value
                }
            })
        }

    handleChangeSeason= e => {
        schema.validate({'season': e.target.value})
            .then(res => {
                this.setState({
                    seasonError: '',
                })
            })
            .catch(err => {
                this.setState({
                    seasonError: err.errors[0],
                })
            })
        this.setState({
            values: {
                ...this.state.values,
                season: e.target.value
            }
        })
    }

    handleChangeNote= e => {
        schema.validate({'note': e.target.value})
            .then(res => {
                this.setState({
                    noteError: '',
                })
            })
            .catch(err => {
                this.setState({
                    noteError: err.errors[0],
                })
            })
        this.setState({
            values: {
                ...this.state.values,
                note: e.target.value
            }
        })
    }

    handleChangeOrderNumber= e => {
        schema.validate({'orderNumber': e.target.value})
            .then(res => {
                this.setState({
                    orderNumberError: '',
                })
            })
            .catch(err => {
                this.setState({
                    orderNumberError: err.errors[0],
                })
            })
        this.setState({
            values: {
                ...this.state.values,
                orderNumber: e.target.value
            }
        })
    }

    handleChangeBid = e => {
        schema.validate({'bid': e.target.value})
            .then(res => {
                this.setState({
                    bidError: '',
                })
            })
            .catch(err => {
                this.setState({
                    bidError: err.errors[0],
                })
            })
        this.setState({
            values: {
                ...this.state.values,
                bid: e.target.value
            }
        })
    }

    handleChangeNetWeight = e => {
        schema.validate({'weight': e.target.value})
          .then(res => {
              this.setState({
                  netWeightError: '',
              })
          })
          .catch(err => {
              this.setState({
                  netWeightError: err.errors[0],
              })
          })
        this.setState({
            values: {
                ...this.state.values,
                netWeight: e.target.value
            }
        })
    }

    handleChangeProductName = productName => {
        this.setState({ values: {
            ...this.state.values,
            productName
        }})
    }

    handleChangeStatusId = obj => {
        this.setState({
            values: {
                ...this.state.values,
                statusId: obj.value
            }
        })
    }

    handleChangePackTypeId = obj => {
        this.setState({
            values: {
                ...this.state.values,
                packTypeId: obj.value
            }
        })
    }

    handleChangeLocation = obj => {
        this.setState({
            values: {
                ...this.state.values,
                locationId: obj
            }
        })
    }

    handleChangeCg = (name, val) => {
        this.setState({
            values: {
                ...this.state.values,
                [name]: val,
            }
        })
    }

    handleChangeQuality = (name, val) => {
        this.setState({
            values: {
                ...this.state.values,
                [name]: val,
            }
        })
    }


    handleCreateBox = () => {
        this.indexCreatedBox++
        this.setState(state => ({
            values: {
                ...state.values,
                newBoxes: state.values.newBoxes.concat({
                    id: uniqId(),
                    name: `#${this.indexCreatedBox}`,
                })
            },

        }))
    }

    handleRemoveBox = (id) => {
        this.setState(state => ({
            values: {
                ...state.values,
                newBoxes: state.values.newBoxes.filter(box => box.id !== id)
            },
        }))
    }

    render(){
        const {
            currentStep,
            values,
            poOptions,
            weightError,
            isTouched,
            method,
            netWeightError,
            statusIdError,
            packTypeIdError,
            locationIdError,
            orderNumberError,
          itemsCount,
        } = this.state

        const { handleCancel } = this.props

        return (
            <div className="create-pallet__wrapper">
                {currentStep === 0 && <ChooseMethodComponent
                    chooseMethod={this.handleChooseMethod}
                    method={method}
                    handleCancel={handleCancel}
                />}
                {currentStep === 1 && <FirstStep
                    method={method}
                    pos={poOptions}
                    handleChange={this.handleChangePoValues}
                    changeStep={this.handleChangeStep}
                    handleCancel={handleCancel}
                    chooseMethod={this.handleChooseMethod}
                />}
                {currentStep === 2 && <SecondStep
                    method={method}
                    newBoxes={values.newBoxes}
                    createBox={this.handleCreateBox}
                    removeBox={this.handleRemoveBox}
                    replaceBoxes={this.replaceBoxes}
                    ids={values.poIds}
                    boxesId={values.boxesId}
                    handleChange={this.handleChangeBoxes}
                    changeStep={this.handleChangeStep}
                    handleCancel={handleCancel}
                    isCustom={this.props.isCreateCustom}
                />}
                {currentStep === 3 && <ThirdStep
                    method={method}
                    isTouched={isTouched}
                    weightError={weightError}
                    orderNumberError={orderNumberError}
                    netWeightError={netWeightError}
                    statusIdError={statusIdError}
                    packTypeIdError={packTypeIdError}
                    handleCreate={this.handleSubmit}
                    weight={values.weight}
                    netWeight={values.netWeight}
                    productName={values.productName}
                    statusId={values.statusId}
                    packTypeId={values.packTypeId}
                    locationId={values.locationId}
                    cg1={values.cg1}
                    cg2={values.cg2}
                    quality={values.quality}
                    season={values.season}
                    note={values.note}
                    orderNumber={values.orderNumber}
                    bid={values.bid}
                    handleChangeCg={this.handleChangeCg}
                    handleChangeQuality={this.handleChangeQuality}
                    handleChange={this.handleChange}
                    handleChangeNetWeight={this.handleChangeNetWeight}
                    handleChangeSeason={this.handleChangeSeason}
                    handleChangeNote={this.handleChangeNote}
                    handleChangeOrderNumber={this.handleChangeOrderNumber}
                    handleChangeBid={this.handleChangeBid}
                    handleChangeProductName={this.handleChangeProductName}
                    handleChangeStatusId={this.handleChangeStatusId}
                    handleChangeSalesManager={this.handleChangeSalesManager}
                    handleChanngePackTypeId={this.handleChangePackTypeId}
                    changeStep={this.handleChangeStep}
                    handleCancel={handleCancel}
                    locationIdError={locationIdError}
                    handleChangeLocation={this.handleChangeLocation}
                    itemsCount={itemsCount}
                />}
            </div>
        )
    }
}

export default CreatePallet