import * as yup from 'yup'

export const schema = yup.object().shape({
    name: yup.string().required('required field'),
    roleId: yup.string().required('required field'),
    initialsColor: yup.string().required('required field'),
    initials: yup.string().required('required field'),
    secondName: yup.string().required('required field'),
    responsibleCountry: yup.array(),
    languages: yup.array(),
});