import React from 'react'
import TableChart from './table-analysis-component'
import TableSkuBrand from './table-analysis-component/tableSkuBrand'
import TableRrpBrand from './table-analysis-component/tableRrpBrand';

const TablesAnalysis = () => {
    return (
        <div className="tables-chart">
            <TableChart param1="cg2" param2="cg3" label="Gender & CG3"/>
            <TableChart param1="cg2" param2="size" label="Gender & Sizes"/>
            <TableSkuBrand/>
            <TableRrpBrand/>
        </div>
    )
}

export default TablesAnalysis