import React, {PureComponent} from 'react'
import {Button, Row, Col, Form} from 'react-bootstrap'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Select from '../../../reuseComponent/Select/select-form'
import {FieldArray} from 'formik'
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute'
import {history} from '../../../../history'
import ContactBlock from './contact-row-item'
import WarehouseAddressRow from './warehouse-address-row'
import moment from 'moment'
import {ReactComponent as InfoIcon} from "../../../../assets/images/info.svg";
import AutosizeInput from "react-input-autosize";
import TextareaAutosize from "react-textarea-autosize";
import {disabledSelectStyle} from "../../../reuseComponent/Select/disabledSelectStyling";
import {findPermInArray} from "../../../../reuseFunctions/checkPermission";
import {ReactComponent as DeleteIcon} from "../../../../assets/images/delete.svg";
import {permEditSuppliers} from "../../../../permissions/suppliers";

class BodyEditForm extends PureComponent {

    static defaultProps = {
        isChangeHeader: true,
        cancelHandler: () => changeRoutePush('/atw/suppliers', history)
    }

    checkIfChangeData = () => {
        const {values, initialValues} = this.props
        let res = false
        Object.keys(initialValues).forEach(key => {
            try {
                if (JSON.stringify(values[key]) !== JSON.stringify(initialValues[key])) {
                    res = true
                }
            }
            catch (err) {

            }
        })
        return res
    }

    render() {
        const {
            handleSubmit,
            touched,
            values,
            errors,
            setFieldValue,
            users,
            cancelHandler,
            isView,
            qualities,
            handleShowRemoveModal,
            initialValues,
        } = this.props
        const {params} = this.props.match;
        const usersOptions = users.map(user => ({
            value: user.id,
            label: `${user.name} ${user.secondName}`
        }))
        const selectedBuyer = usersOptions.find(user => user.value === values.buyersId)
        const {createdAt, author} = this.props.values
        // const isView = true;

        return (
            <Form className="supplier-form" autoComplete='new-password' noValidate onSubmit={handleSubmit}>
                <div className="form-header">
                    <div className="left-part">
                        {/*<Button*/}
                        {/*    variant="primary"*/}
                        {/*    className="standard"*/}
                        {/*    onClick={() => {*/}
                        {/*        changeRoutePush(`/atw/suppliers`, history)*/}
                        {/*        cancelHandler && cancelHandler()*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <BackIcon/>*/}
                        {/*    Back*/}
                        {/*</Button>*/}
                    </div>
                    <div className="right-part">
                        {findPermInArray(this.props.roles, permEditSuppliers) && params.id !== 'new' && <Button
                            variant="primary"
                            className="standard"
                            onClick={() => handleShowRemoveModal(params.id, initialValues.title)}
                        >
                            <DeleteIcon/>
                            Delete
                        </Button>}
                    </div>
                </div>
                <Row>
                    <Col xs="12">
                        <h6 className="form-title">
                            <InfoIcon/>
                            Main info
                        </h6>
                    </Col>
                    {!isView || values.title ? <Col xs="6">
                        <Form.Group className="horizontal">
                            <Form.Label>Company</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter company name' : ''}
                                    className="auto-resize-wrapper"
                                    name="title"
                                    value={values.title}
                                    onChange={event => {
                                        setFieldValue("title", event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.title && errors.title}</span>
                            </div>
                        </Form.Group>
                    </Col> : null}

                    {!isView || values.camouflaging ? <Col xs="6">
                        <Form.Group className="horizontal">
                            <Form.Label>Camouflage</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter camouflage' : ''}
                                    className="auto-resize-wrapper"
                                    name="camouflaging"
                                    value={values.camouflaging}
                                    onChange={event => {
                                        setFieldValue("camouflaging", event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.camouflaging && errors.camouflaging}</span>
                            </div>
                        </Form.Group>
                    </Col> : null}
                    {!isView || values.address ? <Col xs="6">
                        <Form.Group className="horizontal">
                            <Form.Label>Office address</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter office address' : ''}
                                    className="auto-resize-wrapper"
                                    name="address"
                                    value={values.address}
                                    onChange={event => {
                                        setFieldValue("address", event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.address && errors.address}</span>
                            </div>
                        </Form.Group>
                    </Col> : null}
                    {!isView || values.email ? <Col xs="6">
                        <Form.Group className="horizontal">
                            <Form.Label>Email</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter email' : ''}
                                    className="auto-resize-wrapper"
                                    name="email"
                                    value={values.email}
                                    onChange={event => {
                                        setFieldValue("email", event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.email && errors.email}</span>
                            </div>
                        </Form.Group>
                    </Col> : null}
                    {!isView || selectedBuyer ? <Col xs="6">
                        <Form.Group className="horizontal">
                            <Form.Label>Buyer</Form.Label>
                            <div className="input-item">
                            <Select
                                isDisabled={isView}
                                errorLabel={errors.buyersId}
                                isInvalid={touched.buyersId && errors.buyersId}
                                name="buyersId"
                                placeholder={!isView ? 'Select buyer' : ''}
                                value={selectedBuyer}
                                options={usersOptions}
                                onChange={obj => {
                                    setFieldValue('buyersId', obj.value)
                                }}
                                styles={!isView ? {
                                    container: (provided, state) => ({
                                        ...provided,
                                        width: selectedBuyer ? selectedBuyer.label.length * 6 + 35 : 100
                                    }),
                                    control: (provided, state) => ({
                                        ...provided,
                                        height: 22
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        margin: 0
                                    }),
                                    menu: (provided, state) => ({
                                        ...provided,
                                        minWidth: 150
                                    }),
                                } : disabledSelectStyle}
                            />
                            </div>
                        </Form.Group>
                    </Col> : null}

                </Row>
                {!isView || values.contacts.length > 0 ? <Row>
                    <Col xs="12">
                        <h6 className="contacts-title">
                            <svg viewBox="0 0 24 24">
                                <path fill="currentColor"
                                      d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"/>
                            </svg>
                            Contacts
                        </h6>
                    </Col>
                    <FieldArray
                        name="contacts"
                        render={arrayHelpers => {
                            return <>
                                {values.contacts && values.contacts.length > 0 &&
                                values.contacts.map((contact, index) => {
                                    return <Col xs="12" lg={12} key={index}>
                                        <ContactBlock
                                            isView={isView}
                                            index={index}
                                            values={values}
                                            touched={touched}
                                            errors={errors}
                                            setFieldValue={setFieldValue}
                                            arrayHelpers={arrayHelpers}
                                        />
                                    </Col>
                                })
                                }
                                <Col xs="12">
                                    {!isView &&
                                    <Button variant="primary" className="add-button-suppliers add-supplier-contact"
                                            onClick={() => arrayHelpers.push({
                                                name: '',
                                                secondName: '',
                                                phone: undefined,
                                                email: '',
                                                position: ''
                                            })}>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                                        </svg>
                                        Add contact
                                    </Button>
                                    }
                                </Col>
                            </>
                        }}
                    />

                </Row> : null}
                {!isView || values.warehouseAddress.length > 0 ? <Row>
                    <Col xs="12">
                        <h6 className="warehouse-title">
                            <svg viewBox="0 0 24 24">
                                <path fill="currentColor" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z"/>
                            </svg>
                            Warehouse addresses
                        </h6>
                    </Col>
                    <FieldArray
                        name="warehouseAddress"
                        render={arrayHelpers => {
                            return <>
                                {values.warehouseAddress && values.warehouseAddress.length > 0 &&
                                values.warehouseAddress.map((contact, index) => {
                                    return <Col xs="12" key={index}>
                                        <WarehouseAddressRow
                                            isView={isView}
                                            index={index}
                                            values={values}
                                            touched={touched}
                                            errors={errors}
                                            setFieldValue={setFieldValue}
                                            arrayHelpers={arrayHelpers}
                                        />
                                    </Col>
                                })}
                                {!isView &&
                                <Col xs="12">
                                    <Button
                                        variant="primary"
                                        onClick={() => arrayHelpers.push('')}
                                        className="add-supplier-warehouse-address"
                                    >
                                        <svg viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                                        </svg>
                                        Add address
                                    </Button>
                                </Col>
                                }
                            </>
                        }}
                    />
                </Row> : null}

                <Row>
                    <Col xs="12">
                        <h6 className="camouflage-title">
                            <svg viewBox="0 0 24 24">
                                <path fill="currentColor" d="M12 1C16.69 1 20.5 5.93 20.5 12C20.5 18.08 16.69 23 12 23C7.31 23 3.5 18.08 3.5 12C3.5 5.93 7.31 1 12 1M12 3C8.41 3 5.5 7.03 5.5 12C5.5 16.97 8.41 21 12 21C15.59 21 18.5 16.97 18.5 12C18.5 7.03 15.59 3 12 3M8.29 10.28L11.53 7.03L12.59 8.09L9.35 11.34L8.29 10.28M8.7 14.61L14.36 8.95L15.42 10L9.76 15.67L8.7 14.61Z" />
                            </svg>
                            Quality camouflage
                        </h6>
                    </Col>
                    {qualities.map(q => !isView || values.qualities[q] ? <Col xs="6" key={q}>
                        <Form.Group className="wrap-quality-camouflage horizontal">
                            <Form.Label className="quality-native">{q}</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter quality camouflage' : ''}
                                    className="auto-resize-wrapper"
                                    value={values.qualities[q] ? values.qualities[q] : ''}
                                    onChange={event => {
                                        const qualities = {...values.qualities}
                                        qualities[q] = event.target.value
                                        setFieldValue('qualities',qualities)
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </Col> : null)}
                </Row>

                {!isView || values.additionalInfo ? <Form.Group className="horizontal">
                    <Form.Label>Note</Form.Label>
                    <div className="input-item">
                        <TextareaAutosize
                            disabled={isView}
                            style={{resize: 'none', height: 'auto'}}
                            minRows={2}
                            placeholder={!isView ? 'Type smth...' : ''}
                            type="text"
                            className="auto-resize-wrapper"
                            as="textarea"
                            name="additionalInfo"
                            value={values.additionalInfo}
                            onChange={event => {
                                setFieldValue('additionalInfo', event.target.value)
                            }}
                        />
                        {/*<Form.Control*/}
                        {/*    disabled={isView}*/}
                        {/*    style={{resize: 'none', height: 'auto'}}*/}
                        {/*    placeholder="Type smth..."*/}
                        {/*    type="text"*/}
                        {/*    as="textarea"*/}
                        {/*    name="additionalInfo"*/}
                        {/*    value={values.additionalInfo}*/}
                        {/*    onChange={event => {*/}
                        {/*        setFieldValue('additionalInfo', event.target.value)*/}
                        {/*    }}*/}
                        {/*    />*/}
                        <span className="error-span">{errors.additionalInfo && touched.additionalInfo}</span>
                    </div>
                    {/*<Form.Control.Feedback type="invalid">*/}
                    {/*    {errors.additionalInfo}*/}
                    {/*</Form.Control.Feedback>*/}
                </Form.Group> : null}


                {!isView && this.checkIfChangeData() && <Form.Group className="footer-form footer-form-custom">
                    <Button variant="secondary"
                            onClick={cancelHandler}>Cancel</Button>
                    <Button variant="primary" type="submit">Save</Button>
                </Form.Group>
                }

                {author
                && createdAt
                &&
                <p className="action-supp-row">Created <b>{moment(createdAt, 'YYYY-MM-DDTHH:mm').format('MMM DD, YYYY')}</b> by <b>{`${author.name} ${author.secondName}`}</b>
                </p>}
            </Form>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles,
        userInfo: state.usersReducer.currUser,
        qualities: state.supplierReducer.qualities
    }
}

export default withRouter(connect(mapStateToProps, null)(BodyEditForm));