import React, {PureComponent} from 'react'
import { Switch } from 'react-router-dom'

import NotificationsList from "./list"
import NotificationForm from './create'
import {permEditNotifications, permViewNotifications, permSendNotifications} from "../../../permissions/settings";
import ProtectedRoute from "../../reuseComponent/Routes/protected-route";
import NotificationEmailForm from "./inner-newsletter";

class NotificationsModule extends PureComponent{
    render(){
        return(
            <Switch>
                <ProtectedRoute permission={permViewNotifications} exact path={`/atw/settings/notifications`} component={NotificationsList}/>
                <ProtectedRoute permission={permEditNotifications} exact path={`/atw/settings/notifications/edit/:id`} component={NotificationForm}/>
                <ProtectedRoute permission={permEditNotifications} exact path={`/atw/settings/notifications/create`} component={NotificationForm}/>
                <ProtectedRoute permission={permSendNotifications} exact path={`/atw/settings/notifications/inner-newsletter`} component={NotificationEmailForm}/>
            </Switch>
        )
    }
}

export default NotificationsModule;