import React, {PureComponent} from 'react'
import {Button} from 'react-bootstrap'


class ChoseMethodComponent extends PureComponent {
    render() {
        const {chooseMethod,handleCancel} = this.props
        return (
            <div className="step zero-step choose-method-wrapper">
                <div className="title-step">
                    <span>Choose way to create pallet</span>
                    <div className="footer-step">
                        <Button className="cancel" onClick={handleCancel}>Cancel</Button>
                    </div>
                </div>
                <div className="content-step">
                    <Button onClick={() => chooseMethod(inboundMethod)}>
                        Inbound
                    </Button>
                    <Button onClick={() => chooseMethod(inventoryMethod)}>
                        Inventory
                    </Button>
                    <Button onClick={() => chooseMethod(customPalletMethod)}>
                        Custom pallet
                    </Button>
                </div>
            </div>
        )
    }
}

export const customPalletMethod = 'customPallet'
export const inventoryMethod = 'inventory'
export const inboundMethod = 'inbound'

export default ChoseMethodComponent