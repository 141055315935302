import {OFFERS} from "../constants";

const initialState = {
    reservationData: null,
}

export const offersReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case OFFERS.SET_RESERVATION_DATA: {
            return {
                ...state,
                reservationData: payload
            }
        }
        default:
            return state;
    }
}