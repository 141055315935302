import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Table from '../../../reuseComponent/Table/MaterialTable'
import Pagination from '../../../reuseComponent/Table/Pagination'
import moment from 'moment'
import {Route} from 'react-router-dom'
import TableHeader from '../../../reuseComponent/Table/table-head'
import adminInstance from '../../../instances/admininstance'
import {url} from '../../../../constants'
import {changeFiltersAction, setData} from '../../../../actions/admin-header'
import Modal from '../../../reuseComponent/modal'
import {createTask, editTask, deleteTask, markCompleteTask} from '../../../../services/tasks'
import DatePickerFilter from './datePickerFilter'
import EditTask from '../form/edit'
import {notify} from '../../../reuseComponent/toast'
import Tooltip from '../../../reuseComponent/Tooltips'
import StandartLeftPanel from '../../../reuseComponent/Header/standartLeftPannel'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission'
import {permEditTasks} from '../../../../permissions/tasks'
import {getUsers} from '../../../../actions/users'
import {getDepartments} from '../../../../services/dictionaries'
import TaskResponsibleComponent from './taskResponsibleComponent/taskResponsibleComponent'
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute'
import {history} from '../../../../history'
import DetailsTasks from '../details'
import Toolbar from './Toolbar'
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import withTracker from "../../../reuseComponent/GoogleAnalytics";
import UndoList from '../undo-component';
import { closeOpenInbound, closeOpenSection, editTaskAction } from '../../../../actions/purchase-orders'
import {getTasksAction, setHistory, setSelectedTask} from '../../../../actions/tasks'
import TabsTickets from '../ticketsTabs';

export const defaultTaskOption = {
    value: 'custom',
    label: 'Custom ticket'
}

class TasksList extends PureComponent {

    state = {
        createModalOpen: false,
        editModalOpen: false,
        deleteTaskModal: false,
        currTask: null,
        departments: [],
        isCompleteTask: false,
        deletedTask: [],
        currentTask: {},
        isLoading: false,
    }

    tableRef = React.createRef();

    columns = [{
        title: '',
        field: 'complete',
        sorting: false,
        headerStyle: {
            width: 32
        },
        cellStyle: (e, rowData) => {
            return rowData.id === 'new' ? {
                whiteSpace: 'nowrap'
            } : {}
        },
        render: rowData => {
            const {roles, currUser} = this.props;
            const userId = currUser && currUser.info && currUser.info.id ? currUser.info.id : -1;
            const isAssignedToUser = rowData.executor && rowData.executor.id === userId;
            const hasPermToComplete = findPermInArray(roles, permEditTasks) || isAssignedToUser;

            if (rowData.id === 'new' && !findPermInArray(roles, permEditTasks)) {
                changeRoutePush(`/atw/tasks/tickets`, history)
                this.removeEmptyRow()
            }

            return rowData.complete !== undefined ? (hasPermToComplete && ((rowData && rowData.inbound && !rowData.complete &&
                rowData.order && rowData.order.actualPackagesCount == null) ? false : true)) ? <Tooltip
                    text={rowData.complete ? 'Mark ticket incomplete' : 'Mark ticket complete'}
                >
                    <>
                        {
                            this.state.isLoading ? (
                                <span onClick={e => {
                                    e.stopPropagation()
                                }}
                                    className={`complete-icon ${rowData.complete ? 'complete' : ''} with-hover`}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="#000000"
                                            d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"/>
                                    </svg>
                                </span>
                            ) : (
                                <span onClick={e => {
                                    e.stopPropagation()
                                    this.handleMarkCompleteTask(rowData)
                                }}
                                    className={`complete-icon ${rowData.complete ? 'complete' : ''} with-hover`}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="#000000"
                                            d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"/>
                                    </svg>
                                </span>
                            )
                        }
                    </>
                </Tooltip> :
                <span
                    className={`complete-icon ${rowData.complete ? 'complete' : ''}`}>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"/>
                                        </svg>
                                    </span>
                : 'New task creation'
        }
    },
        {
            title: '#',
            field: 'id',
            sorting: false,
            headerStyle: {
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            render: rowData => rowData.id !== 'new' ? rowData.id : null
        },
        {
            title: 'Created',
            field: 'createdAt',
            sorting: false,
            headerStyle: {
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            filterComponent: props => {
                return <DatePickerFilter
                    {...props}
                    maxDate={moment()}
                    filters={this.props.filters}
                    filterChange={(column, value) => {
                        this.tableRef.current.onFilterChange(column, value)
                    }}
                />
            },
            render: rowData => {
                return rowData.createdAt ?
                    <span>{moment(rowData.createdAt, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm')}</span> : null
            }
        },
        {
            title: 'Title',
            field: 'title',
            sorting: false
        }, {
            title: 'Related element',
            field: 'order',
            sorting: false,
            render: rowData => {
                return rowData.order
                    ? <a target="_blank" onClick={e => e.stopPropagation()} rel="noopener noreferrer"
                         href={`${window.location.origin}/atw/purchase-orders/${rowData.order.id}`}>PO#{rowData.order.orderNumber}</a>
                    : null
            }
        }, {
            title: 'Due date',
            field: 'dueDate',
            sorting: false,
            headerStyle: {
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            filterComponent: props => {

                return <DatePickerFilter
                    {...props}
                    maxDate={moment('20.12.2060', 'DD.MM.YYYY')}
                    filters={this.props.filters}
                    filterChange={(column, value) => {
                        this.tableRef.current.onFilterChange(column, value)
                    }}
                />
            },
            render: rowData => {
                return <span>{rowData.dueDate ? moment(rowData.dueDate).format('DD.MM.YYYY') : null}</span>
            }
        }, {
            title: 'Responsible',
            field: 'executor',
            sorting: false,
            render: rowData => {
                const {roles} = this.props;
                const hasPermToComplete = findPermInArray(roles, permEditTasks);

                return <TaskResponsibleComponent
                    rowData={rowData}
                    hasPermToComplete={hasPermToComplete}
                    departments={this.state.departments}
                    tableRef={this.tableRef}
                    roles={roles}
                    setActiveTasks={this.props.setActiveTasks}
                />
            }
        },
        // {
        //     title: '',
        //     field: 'actions',
        //     sorting: false,
        //     headerStyle: {
        //         width: 32,
        //     },
        //     render: rowData => {
        //         return findPermInArray(this.props.roles, permEditTasks) ? <IconButtonMaterial
        //                 customClass="show-on-hover"
        //                 title="Delete"
        //                 onClick={e => {
        //                     e.stopPropagation()
        //                     this.showHideModal({
        //                         deleteTaskModal: true,
        //                         currTask: rowData.id
        //                     })
        //                 }}>
        //                 <svg viewBox="0 0 24 24">
        //                     <path fill="#000000"
        //                           d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
        //                 </svg>
        //             </IconButtonMaterial>
        //             : null
        //     }
        // }
        ]

    showHideModal = (state, callback) => {
        this.setState({
            ...state
        }, () => callback && callback())
    }

    componentDidUpdate(prevProps,nextProps) {
        // const aliasPrev = prevProps.match.params.alias
        // const alias = this.props.match.params.alias
        // const {changeFilters} = this.props
        // if (aliasPrev !== alias) {
        //     this.tableRef.current && this.tableRef.current.setState(state => ({
        //         query: {
        //             ...state.query,
        //             filters: [],
        //             search: ''
        //         }
        //     }), () => {
        //         changeFilters([])
        //         this.tableRef.current && this.tableRef.current.onQueryChange()
        //     })
        //
        // }

        const {setHeaderData, roles} = this.props
        if (JSON.stringify(roles) !== JSON.stringify(prevProps.roles))
            setHeaderData && setHeaderData({
                leftPannelData: {
                    title: 'Tickets',
                    logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                        <path fill="#FF9800"
                              d="M19,4H18V2H16V4H8V2H6V4H5A2,2 0 0,0 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M5,8V6H19V8H5M10.56,18.46L16.5,12.53L15.43,11.47L10.56,16.34L8.45,14.23L7.39,15.29L10.56,18.46Z"/>
                    </svg>)
                    )
                },
                textAddButton: 'Add',
                LeftPannel: StandartLeftPanel,
                handleAdd: findPermInArray(roles, permEditTasks) ? () => {
                    changeRoutePush(`/atw/tasks/tickets/new`, history)
                    this.addEmptyRowToTable()
                } : null,
                handleSearch: (value) => this.tableRef.current.onSearchChange(value),
                tableRef: this.tableRef.current
            })
    }

    addEmptyRowToTable = () => {
        const data = this.tableRef.current ? this.tableRef.current.state.data : []
        if (data.find(task => task.id === 'new') === undefined) {
            const newTask = {
                id: 'new',
            }
            this.tableRef.current && this.tableRef.current.dataManager.setData([newTask, ...data])
            this.tableRef.current.setState({...this.tableRef.current.dataManager.getRenderState()})
        }
    }

    removeEmptyRow = () => {
        let data = this.tableRef.current ? this.tableRef.current.state.data : []
        data = data.filter(supplier => supplier.id !== 'new')
        this.tableRef.current && this.tableRef.current.dataManager.setData(data)
        this.tableRef.current.setState({...this.tableRef.current.dataManager.getRenderState()})
    }

    componentDidMount() {
        const {setHeaderData, getUsers} = this.props
        setHeaderData && setHeaderData({
            LeftPannel: TabsTickets,
            leftPannelData: {
                alias: 'tickets'
            },
            handleAdd: null,
            handleSearch: (value) => this.tableRef.current.onSearchChange(value),
            tableRef: this.tableRef.current
        });

        getUsers && getUsers();
        getDepartments()
            .then(res => {
                this.setState({
                    departments: res.data.departments
                })
            })
    }

    // componentWillUnmount() {
    //     const {setHeaderData} = this.props
    //     setHeaderData && setHeaderData(null)
    // }


    generateRows = data => {
        return data
    }

    getTasksOfCurrentUser = (query) => {
        const {changeFilters} = this.props
        changeFilters && changeFilters(query.filters)
        return new Promise((resolve, reject) => {
            adminInstance.get(`${url}/tasks`, {
                params: {
                    page: query.page ? query.page : 0,
                    limit: query.pageSize,
                    search: query.search,
                    ...this.convertFiltersToParamsObject(query.filters)
                }
            })
            .then(res => {
                resolve({
                    data: this.generateRows(res.data.tasks),
                    page: query.page,
                    totalCount: res.data.count
                })
            })
        })
    }

    convertFiltersToParamsObject = filters => {
        let res = {}
        filters.forEach(filter => {
            res[filter.column.field] = filter.value
        })
        return res
    }

    getTasks = (query) => {
        const {changeFilters} = this.props
        changeFilters && changeFilters(query.filters)
        return new Promise((resolve, reject) => {
            adminInstance.get(`${url}/tasks/list`, {
                params: {
                    page: query.page ? query.page : 0,
                    limit: query.pageSize,
                    search: query.search,
                    complete: false,
                    ...this.convertFiltersToParamsObject(query.filters)
                }
            })
                .then(res => {
                    resolve({
                        data: this.generateRows(res.data.tasks),
                        page: query.page,
                        totalCount: res.data.count
                    })
                })
        })
    }

    handleCreateTask = (values) => {
        values.poUrl = window.location.origin + '/atw/purchase-orders'
        values.soUrl = window.location.origin + '/atw/sales-orders'
        values.taskUrl = window.location.origin + '/atw/tasks/tickets'
        delete values.responsible
        delete values.order
        this.showHideModal({
            createModalOpen: false
        }, () => {
            createTask(values)
                .then(res => {
                    notify('success', 'Ticket successfully created')
                    this.tableRef.current && this.tableRef.current.onQueryChange();
                    this.props.setActiveTasks();
                    changeRoutePush(`/atw/tasks/tickets/${res.data.task.id}`, history)
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })

    }

    handleEditTask = (values, callback) => {
        const {currTask} = this.state
        values.poUrl = window.location.origin + '/atw/purchase-orders'
        values.soUrl = window.location.origin + '/atw/sales-orders'
        delete values.responsible
        delete values.order
        this.showHideModal({
            editModalOpen: false,
            currTask: null
        }, () => {
            editTask(values, currTask)
                .then(res => {
                    callback && callback()
                    notify('success', 'Ticket successfully edited')
                    this.changeTaskInTable(res.data.task ? res.data.task : {})
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })
    }

    handleDeleteTask = () => {
        const {currTask} = this.state
        const {id} = this.props.match.params
        this.showHideModal({
            deleteTaskModal: false,
            currTask: null
        }, () => {
            deleteTask(currTask)
                .then(res => {
                    if (id && parseInt(id) === parseInt(currTask)) {
                        changeRoutePush(`/atw/tasks/tickets`, history)
                    }
                    let query = {
                        ...this.tableRef.current.state.query,
                        page: 0
                    }
                    this.tableRef.current && this.tableRef.current.onQueryChange(query)
                    notify('success', 'Ticket successfully deleted')
                    // this.tableRef.current && this.tableRef.current.onQueryChange()
                })
        })
    }

    handleRemoveUndoItem = (idTask, callback) => {
        clearTimeout(this.state.deletedTask.find(ticket => ticket.id === idTask).timeout)
        this.setState(state => ({
            deletedTask: state.deletedTask.filter(ticket => ticket.id !== idTask)
        }), () => {
            callback && callback()
        })
    }

    // Transport
    markCompleteAccountingTask = (poId) => {
        console.log('markCompleteTransportTask: ');
        const { closeOpenSection } = this.props;
        closeOpenSection("accounting", poId);
    }

    // Transport
    markCompleteTransportTask = (poId) => {
        console.log('markCompleteTransportTask: ');
        const { closeOpenSection } = this.props;
        closeOpenSection("transport", poId);
    }

    // Inbound
    markCompleteInboundTask = (poId) => {
        console.log('markCompleteInboundTask: ');
        const { closeOpenInbound } = this.props;
        closeOpenInbound(window.location.origin + '/atw/purchase-orders', poId)
    }

    handleMarkCompleteTask = task => {
        const { editTaskAction, setActiveTasks } = this.props;
        this.setState({
            currentTask: task,
            isLoading: true,
        });
        let orderId = task && task.order && task.order.id;
        markCompleteTask(orderId, task.id, task)
            .then(res => {
                if (task.complete) {
                    notify('success', 'Ticket incompleted')
                }
                else {
                    notify('success', 'Ticket completed')
                }
                const {id} = this.props.match.params
                let query = {
                    ...this.tableRef.current.state.query,
                    page: 0
                }
                if (id && parseInt(id) === task.id) {
                    this.setState({
                        isCompleteTask: true,
                        isLoading: false,
                    }, () => {
                        this.setState({
                            isCompleteTask: false,
                            isLoading: false,
                        })
                    })
                }
                setActiveTasks();
                this.setState({
                    isLoading: false,
                });
                if (task.transport) {
                    this.markCompleteTransportTask(task.order.id);
                }
                if (task.accounting) {
                    this.markCompleteAccountingTask(task.order.id);
                }

                console.log('res complete task: ', res);
                editTaskAction(res.data[1] ? res.data[1] : {});

                if (!task.complete) {
                    this.tableRef.current.onQueryChange(query, () => {
                        this.setState({
                            deletedTask: this.state.deletedTask.concat({
                                ...task,
                                timeout: setTimeout(() => {
                                    this.handleRemoveUndoItem(task.id)
                                }, 10000),
                                actionId: res.data.id,
                            })
                        })
                    })
                }
                this.changeTaskInTable(task);
            }).catch(err => {
            notify('error', 'Inbound report contains undefined BID!');
        })
    }

    handleUndoTask = (idTask, actionId) => {
        this.handleRemoveUndoItem(idTask, () => {
            adminInstance.post(`${url}/tasks/undo_complete/${idTask}`)
                .then(res => {
                    if (this.state.currentTask && this.state.currentTask.inbound) {
                        this.markCompleteInboundTask(this.state.currentTask.order.id);
                    } else if (this.state.currentTask && this.state.currentTask.transport) {
                        this.markCompleteTransportTask(this.state.currentTask.order.id);
                    } else if (this.state.currentTask && this.state.currentTask.accounting) {
                        this.markCompleteAccountingTask(this.state.currentTask.order.id);
                    }
                    this.tableRef.current && this.tableRef.current.onQueryChange();
                    this.props.setActiveTasks();
                })
        })
    }

    changeTaskInTable = task => {
        const columnsData = this.tableRef.current.dataManager.data.map(row => {
            if (row.id === task.id) {
                row = {
                    ...task,
                    complete: !task.complete
                }
            }
            return row
        })

        this.tableRef.current.dataManager.setData(columnsData)
        this.tableRef.current.setState(this.tableRef.current.dataManager.getRenderState())
    }

    removeTaskFromTable = id => {
        const columnsData = this.tableRef.current.dataManager.data.find(row => row.id === id)
        this.tableRef.current.dataManager.setData(columnsData)
        this.tableRef.current.setState({
            ...this.tableRef.current.dataManager.getRenderState(),
            query: {
                ...this.tableRef.current.state.query,
                totalCount: this.tableRef.current.state.query.totalCount - 1
            }
        })

    }

    render() {
        const {id} = this.props.match.params
        const {editModalOpen, currTask, deleteTaskModal, isCompleteTask, deletedTask} = this.state
        const {roles} = this.props;

        return (
            <div className={`wrap-table-with-details-panel ${!id ? '' : 'with-details'}`}>
                <div className="table-block wrapper-table tasks-table">
                    <Table
                        components={{
                            Toolbar: props => <Toolbar
                                {...props}
                                handleAdd={findPermInArray(roles, permEditTasks) ? () => {
                                    changeRoutePush(`/atw/tasks/tickets/new`, history)
                                    this.addEmptyRowToTable()
                                } : null}
                            />,
                            Header: TableHeader,
                            Pagination: Pagination
                        }}
                        paginationPosition="toolbar"
                        columns={this.columns}
                        data={this.getTasks}
                        tableRef={this.tableRef}
                        onRowClick={(event, rowData) => {
                            if (rowData.id !== 'new') {
                                changeRoutePush(`/atw/tasks/tickets/${rowData.id}`, history)
                                this.removeEmptyRow()
                            }
                            this.props.setSelectedTask(rowData);
                        }}
                        options={{
                            maxBodyHeight: 'calc(100vh - 130px)',
                            showTitle: false,
                            search: true,
                            emptyRowsWhenPaging: false,
                            pageSize: 50,
                            pageSizeOptions: [10, 25, 50],
                            searchFieldStyle: {display: 'none'},
                            rowStyle: props => {
                                if (parseInt(id) === props.id || id === props.id)
                                    return {
                                        backgroundColor: '#f2f2f2'
                                    }
                                else {
                                    return {}
                                }
                            }
                        }}
                    />
                </div>
                <Route
                    exact
                    path="/atw/tasks/tickets/:id"
                    render={props => <DetailsTasks
                        {...props}
                        handleShowRemoveModal={(taskId) => {
                            this.showHideModal({
                                deleteTaskModal: true,
                                currTask: taskId
                            })
                        }}
                        isCompleteTask={isCompleteTask}
                        handleCreateTask={this.handleCreateTask}
                        cancelCreate={this.removeEmptyRow}
                        changeTaskInTable={this.changeTaskInTable}
                    />}
                />
                {findPermInArray(roles, permEditTasks) && <Modal
                    Content={() => <EditTask
                        idTask={currTask}
                        handleSubmit={this.handleEditTask}
                        handleCancel={() => this.showHideModal({
                            editModalOpen: false
                        })}
                    />}
                    open={editModalOpen}
                    isFooter={false}
                    handleClose={() => this.showHideModal({
                        editModalOpen: false
                    })}
                    options={{
                        centered: true
                    }}
                />
                }
                {findPermInArray(roles, permEditTasks) && <CustomModalWithChildren
                    open={deleteTaskModal}
                    handleConfirm={this.handleDeleteTask}
                    handleCancel={() => this.showHideModal({
                        deleteTaskModal: false,
                        currTask: null
                    })}
                    handleClose={() => this.showHideModal({
                        deleteTaskModal: false,
                        currTask: null
                    })}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`delete task #${currTask}`}
                    />
                </CustomModalWithChildren>
                }
                {deletedTask.length > 0 && <UndoList
                    orders={deletedTask}
                    handleUndo={this.handleUndoTask}
                    removeItem={this.handleRemoveUndoItem}

                />}
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {
        filters: state.adminHeaderReducer.filters,
        roles: state.rolesReducer.roles,
        currUser: state.usersReducer.currUser,
        // users: state.usersReducer.users,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(getUsers()),
        setHeaderData: data => dispatch(setData(data)),
        changeFilters: filters => dispatch(changeFiltersAction(filters)),
        closeOpenInbound: (url, poId) => dispatch(closeOpenInbound(url, poId)),
        closeOpenSection: (section, poId) => dispatch(closeOpenSection(section, poId)),
        editTaskAction: data => dispatch(editTaskAction(data)),
        setActiveTasks: () => getTasksAction(dispatch),
        setSelectedTask: task => dispatch(setSelectedTask(task))
    }
}

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(TasksList))
