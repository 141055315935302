import React, { PureComponent } from 'react'
import './iconButton.scss'

class IconButton extends PureComponent{


    static defaultProps = {
        handlerClick : () => {}, // handler for button
        iconClass : 'fa fa-sm fa-ellipsis-v'
    }


    render(){
        const { iconClass,handlerClick } = this.props
        return(
            <button className="icon-button" onClick={handlerClick}>
                <i className={iconClass}></i>
            </button>
        )
    }
}

export default IconButton