import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Table from '../../../reuseComponent/Table/MaterialTable'
import Pagination from '../../../reuseComponent/Table/Pagination'
import ContainerTable from '../../../reuseComponent/Table/container'
import {
    getDetail,
    resetState,
    getListOfParametrs,
    setAvgTotalPo,
    getPoStatuses, setPoTableRef
} from '../../../../actions/purchase-orders'
import {getUsers} from '../../../../actions/users'
import Header from './Header'
import Modal from '../../../reuseComponent/modal'
import adminInstance from '../../../instances/admininstance';
import {url} from '../../../../constants';
import {convertToFormData} from '../../../../reuseFunctions/objectToFormData'
import {notify} from '../../../reuseComponent/toast'
import {setData} from '../../../../actions/admin-header'
import ToolTip from '../../../reuseComponent/Tooltips'
import DateRangePicker from '../../../reuseComponent/date-range-picker'
import moment from 'moment'
import {changeFiltersAction, resetHeaderStateAction} from '../../../../actions/admin-header'
import DropDown from '../../../reuseComponent/DropDown/DropDownWithChildren'
import IconButtonMaterial from '../../../reuseComponent/Buttons/ButtonWithMaterialIcon'
import Select from '../../../reuseComponent/Select/select-form'
import ModalWithInput from '../../../reuseComponent/modal/modalWithInputConfirm'
import ResponsibleList from './responsibleComponent'
import axios from 'axios'
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute'
import {history} from '../../../../history'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission'
import {calculateActualTotalPrice} from '../../../../reuseFunctions/calculateTotalBids'
import {getDepartments} from '../../../../services/dictionaries'
import StandartLeftPanner from '../../../reuseComponent/Header/standartLeftPannel'
import {
    permRemovePO,
    permDownloadXlsxPO,
    // permFinanceInfoOverview
} from '../../../../permissions/productOverview'
import {permViewSuppliers} from '../../../../permissions/suppliers'
import ToolbarPo from './ToolbarPo'
import StatusComponent from './statusComponent'
import ModalChangeStatusWithReason from './statusComponent/modalBody'
import {saveOverviewPo} from '../../../../actions/purchase-orders'
import UndoList from './undo-component'
import SrcInvoiceIcon from '../../img/icons8-receipt-euro-2.svg'
import DownloadXlsxPOComponent from './undo-component/DownloadXlsxPOComponent'
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent'
import Tooltip from '../../../reuseComponent/Tooltips'
import { permViewPO } from '../../../../permissions/productOverview'
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import withTracker from "../../../reuseComponent/GoogleAnalytics";
import { formatPrice, thousandSeparator } from '../../../../reuseFunctions/formatValue'

class ListPurchaseOrders extends PureComponent {

    tableRef = React.createRef()

    inputText = ''

    commentToDelete = ''

    state = {
        createPurchaseOrderModal: false,
        modalRemovePurchase: false,
        currentPurchase: null,
        channelValues: [],
        commoditiesValues: [],
        gendersList: [],
        qualitiesList: [],
        search: '',
        filters: [],
        departments: [],
        confirmExportXlsModal: false,
        modalChangeStatus: false,
        modalChangeStatusWithReason: false,
        currentStatus: null,
        deletedPo: [],
        isExporting: false
    }

    handleChangeStatus = (reason) => {
        const {currentPurchase, currentStatus} = this.state
        this.setState({
            currentPurchase: null,
            currentStatus: null,
            modalChangeStatus: false,
            modalChangeStatusWithReason: false
        }, () => {
            adminInstance.put(`${url}/purchase_order/${currentPurchase.id}/set_status`, convertToFormData({
                statusId: currentStatus.id,
                reason: reason ? reason : '',
                poUrl: window.location.origin + '/atw/purchase-orders'
            }))
                .then(res => {
                    this.changeStatusInRow(currentPurchase.id, res.data)
                    notify('success', 'Status successfully changed')
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })

    }

    changeStatusInRow = (id, data) => {
        const columnsData = this.tableRef.current.dataManager.data.map(row => {
            if (row.id === id) {
                row = {
                    ...row,
                    status: data.status
                }
            }
            return row
        })

        this.tableRef.current.dataManager.setData(columnsData)
        this.tableRef.current.setState(this.tableRef.current.dataManager.getRenderState())
    }

    generateColumns = () => {
        const {roles} = this.props
        return [
            {
                title: 'Status',
                field: 'statusPO',
                minWidth: 67,
                isResizable: false,
                sorting: false,
                render: rowData => {
                    return <div className="status-placement-date-wrapper">
                        <StatusComponent
                            showConfirm={(status) => this.setState({
                                currentStatus: status,
                                currentPurchase: rowData,
                                modalChangeStatus: true
                            })}
                            activeStatus={rowData.status}
                            showConfirmWithReason={status => this.setState({
                                currentStatus: status,
                                currentPurchase: rowData,
                                modalChangeStatusWithReason: true
                            })}
                        />
                    </div>
                }
            },
            {
                title: 'Responsible',
                field: 'status',
                minWidth: 103,
                sorting: false,
                isResizable: true,
                render: rowData => {
                    return (

                        <ResponsibleList
                            departments={this.state.departments}
                            idPurchase={rowData.id}
                            purchaseOrderNumber={rowData.orderNumber}
                            department={rowData.department}
                            responsibleUser={rowData.responsiblePerson}
                            changeDepartInRow={this.changeDepartmentInRow}
                            changeRow={this.changeResponsibleInRow}
                        />
                    )
                }
            },
            {
                title: '#',
                field: 'orderNumber',
                sorting: false,
                minWidth: 58,
                isResizable: true,
                render: rowData => {
                    return <span>{rowData.orderNumber}</span>
                }
            },
            {
                title: 'Created',
                field: 'createdAt',
                isResizable: true,
                headerStyle: {

                    textAlign: 'right'
                 },
                cellStyle: {
                    textAlign: 'right',
                    minWidth: 130
                },
                sorting: false,
                filterComponent: (props) => {
                    const {filters} = this.props
                    const {column} = props
                    const activeFilter = filters.find(filter => filter.column.tableData.id === column.tableData.id)
                    return <div className="filter-wrap">
                        <DateRangePicker
                            singleDatePicker={false}
                            onApply={(event, picker) => this.tableRef.current.onFilterChange(column.tableData.id, `${picker.startDate.format('DD.MM.YYYY 00:00')}-${picker.endDate.format('DD.MM.YYYY 23:59')}`)}
                            maxDate={moment()}
                            startDate={activeFilter ? activeFilter.value.split('-')[0] : null}
                            endDate={activeFilter ? activeFilter.value.split('-')[1] : null}
                        >
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000" d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"/>
                            </svg>
                        </DateRangePicker>
                    </div>
                },
                render: rowData => {
                    return <span>{moment(rowData.createdAt, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm')}</span>
                }
            },
            {
                title: 'Supplier',
                field: 'supplier',
                isResizable: true,
                minWidth: 111,
                sorting: false,
                // filterComponent: props => <SupplierFilter
                //     {...props}
                //     filters={this.props.filters}
                //     tableRef={this.tableRef.current}
                // />,
                render: rowData => {
                    return <p>{rowData.supplier ? rowData.supplier.title : null}</p>
                }
            },
            {
                title: 'Buyer',
                field: 'buyer',
                isResizable: true,
                minWidth: 98,
                sorting: false,
                render: rowData => {
                    return rowData.buyer ? <span>{`${rowData.buyer.name} ${rowData.buyer.secondName}`}</span> : null
                }
            },
            {
                title: 'Offer',
                field: 'offerNumber',
                minWidth: 99,
                isResizable: true,
                sorting: false,
                render: rowData => {
                    return <span>{rowData.offerNumber}</span>
                }
            },
            {
                title: 'Article',
                field: 'article',
                minWidth: 120,
                isResizable: true,
                sorting: false,
                render: rowData => {
                 return rowData.bids.map(bid => {
                        return (
                            <React.Fragment key={bid.id}>
                                <div>
                                    <span>{bid.articleEan}</span>
                                    <br></br>
                                    <span>{bid.articleDescription}</span>
                                </div>
                                <br></br>
                            </React.Fragment>
                        )
                    })
                }
            },
            {
                title: 'Product names',
                field: 'productName',
                minWidth: 120,
                isResizable: true,
                sorting: false,
                render: rowData => {
                    return rowData.bids.map(bid => {
                        return (
                            <div key={bid.id}>
                                <span>{bid.productName}</span>
                            </div>
                        )
                    })
                }
            },
            {
                title: 'Placement',
                field: 'placementDate',
                isResizable: true,
                headerStyle: {

                    textAlign: 'right'
                 },
                cellStyle: {
                    textAlign: 'right',
                    minWidth: 110,
                },
                sorting: false,
                render: rowData => {
                    return <span>{rowData.placementDate ? moment(rowData.placementDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : null}</span>
                }
            },
            {
                title: 'Channel',
                field: 'bids.channel',
                minWidth: 89,
                isResizable: true,
                sorting: false,
                render: rowData => {
                    return rowData.bids.map(bid => {
                        return (
                            <div key={bid.id}>
                                <span>{bid.channel}</span>
                            </div>
                        )
                    })
                }
            },
            {
                title: 'Pcs',
                field: 'bids.quantity',
                minWidth: 89,
                isResizable: true,
                sorting: false,
                headerStyle: {

                    textAlign: 'right'
                 },
                cellStyle: {
                    textAlign: 'right'
                },
                render: rowData => {
                    return rowData.bids.map(bid => {
                        return (
                            <React.Fragment key={bid.id}>
                                <span>{bid.quantity}</span><br></br>
                            </React.Fragment>
                        )
                    })
                }
            },
            {
                title: 'Unit',
                field: 'bids.unit',
                minWidth: 50,
                headerStyle: {

                    textAlign: 'right'
                 },
                cellStyle: {
                    textAlign: 'right'
                },
                isResizable: false,
                sorting: false,
                render: rowData => {
                    return rowData.bids.map(bid => {
                        return (
                            <React.Fragment key={bid.id}>
                                <span>{bid.unit}</span><br></br>
                            </React.Fragment>
                        )
                    })
                }
            },
            {
                title: 'Negotiated € / unit',
                field: 'pricePerUnit',
                minWidth: 100,
                headerStyle: {

                    textAlign: 'right'
                 },
                cellStyle: {
                    textAlign: 'right'
                },
                isResizable: true,
                sorting: false,
                render: rowData => {
                    return <div>
                        {rowData.bids.map(bid => {
                            return <div key={bid.id}>
                                <span>{bid.pricePerUnit !== null ? thousandSeparator(formatPrice(bid.pricePerUnit)) + ' €' : null}</span>
                            </div>
                        })}
                    </div>
                }
            },
            {
                title: 'Total negotiated €',
                field: 'totalPrice',
                isResizable: true,
                sorting: false,
                minWidth: 100,
                headerStyle: {
                    textAlign: 'right'
                 },
                cellStyle: {
                    textAlign: 'right'
                },
                render: rowData => {
                    const value = rowData.bids.reduce((acc, current) => {
                        if (acc !== null && current.totalPrice)
                            return acc + current.totalPrice
                        else if (current.totalPrice >= 0 && acc === null)
                            return current.totalPrice
                        else if (acc !== null)
                            return acc
                        return null
                    }, null)
                    return <div>
                        {value !== null ? thousandSeparator(formatPrice(value)) + ' €' : null}
                    </div>
                }
            },
            {
                title: 'Total €',
                field: 'actualTotalPrice',
                isResizable: true,
                sorting: false,
                minWidth: 100,
                headerStyle: {
                    textAlign: 'right'
                 },
                cellStyle: {
                    textAlign: 'right'
                },
                render: rowData => {
                    const value = calculateActualTotalPrice(rowData.bids, rowData.addressPrice)

                    return <div>
                        {value ? thousandSeparator(formatPrice(value)) + ' €' : null}
                    </div>
                }
            },
            {
                title: 'Commodity',
                field: 'bids.commodity',
                isResizable: true,
                minWidth: 106,
                sorting: false,
                render: rowData => {
                    return rowData.bids.map(bid => {
                        return (
                            <div key={bid.id}>
                                <span>{bid.commodity}</span>
                            </div>
                        )
                    })
                }
            },
            {
                title: 'Gender',
                field: 'bids.gender',
                minWidth: 107,
                isResizable: true,
                sorting: false,
                render: rowData => {
                    return rowData.bids.map(bid => {
                        return (
                            <div key={bid.id}>
                                <span>{bid.gender}</span>
                            </div>
                        )
                    })
                }
            },
            {
                title: 'Quality',
                field: 'bids.quality',
                isResizable: true,
                minWidth: 103,
                sorting: false,
                render: rowData => {
                    return rowData.bids.map(bid => {
                        return (
                            <div key={bid.id}>
                                <span>{bid.quality}</span>
                            </div>
                        )
                    })
                }
            },
            {
                title: 'Author',
                field: 'author.name',
                isResizable: true,
                minWidth: 103,
                sorthing: false,
                hidden: true,
                render: rowData => {
                    return rowData.author ? <div key={rowData.author.id}>
                            <span>{rowData.author.name} {rowData.author.secondName}</span>
                        </div> :
                        null

                },
            },
            {
                title: 'Ref. ID',
                field: 'referenceNumber',
                isResizable: true,
                sorting: false,
                minWidth: 73,
                render: rowData => {
                    return <span>{rowData.referenceNumber}</span>
                }
            },
            {
                title: 'Level',
                field: 'inboundType',
                sorting: false,
                minWidth: 73,
                filterComponent: (props) => this.FilterSelect(props),
                render: rowData => {
                    return <span>{rowData.inboundType}</span>
                }
            },
            {
                title: '',
                field: 'actions',
                sorting: false,
                isResizable: false,
                minWidth: 100,
                headerStyle: {
                    textAlign: 'center'
                },
                render: rowData => {
                    const {roles} = this.props
                    return <div className='icons-cell'>
                        <div className='icons-cell-item'>
                        {!rowData.placementDate && <Tooltip text="No placement date">
                            <svg className="alert-palcement-date" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z" />
                            </svg>

                            </Tooltip>}
                        </div>
                        <div className='icons-cell-item'>
                            {rowData.existInvoice &&
                            <ToolTip text="Invoice">
                            <img alt="invoice" src={SrcInvoiceIcon}/>
                        </ToolTip>
                    }
                        </div>
                        <div className='icons-cell-item'>
                        {findPermInArray(roles, permRemovePO) && <IconButtonMaterial
                            customClass="show-on-hover"
                            title="Delete"
                            onClick={e => {
                                e.stopPropagation()
                                this.setState({
                                    currentPurchase: rowData,
                                    modalRemovePurchase: true
                                })
                            }}>
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000"
                                      d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
                            </svg>
                        </IconButtonMaterial>}
                        </div>
                    </div>
                }
            }
        ]
    }

    componentDidUpdate(prevProps) {
        const {currUser} = this.props;
        const defaultColumns = this.generateColumns();
        if (currUser && currUser.parts && this.props && this.props.roles) {
            const rolesFiltered = this.props.roles.filter(role => typeof role === 'object');

            if (currUser.parts.length !== rolesFiltered.length) {
                window.location.reload();
            }
        }
        if (currUser.userDetails.setting !== prevProps.currUser.userDetails.setting && currUser.userDetails.setting && this.tableRef.current) {
            let resColumns = []
            try {
                const savingColumns = JSON.parse(currUser.userDetails.setting)
                const newColumns = defaultColumns.filter(column => !!!savingColumns.find(saveColumn => saveColumn.field === column.field))
                resColumns = [...newColumns, ...savingColumns.map(savedColumn => {
                    const defaultColumn = defaultColumns.find(defaultColumn => defaultColumn.field === savedColumn.field)
                    if (defaultColumn) {
                        return {
                            ...defaultColumn,
                            ...savedColumn,
                            title: defaultColumn.title,
                            isResizable: defaultColumn.isResizable,
                            headerStyle: savedColumn.headerStyle && defaultColumn.isResizable ? savedColumn.headerStyle : {
                                ...defaultColumn.headerStyle,
                                minWidth: defaultColumn.minWidth ? defaultColumn.minWidth : 50,
                                maxWidth: defaultColumn.field === 'actions' ? 32 : 'inherit'
                            },
                            hidden: savedColumn.hidden ? savedColumn.hidden : false
                        }
                    }
                    return null
                })].filter(resColumn => resColumn !== null)
            }
            catch (err) {
                resColumns = defaultColumns
            }
            this.tableRef.current.dataManager.setColumns(resColumns)
            this.tableRef.current.setState(this.tableRef.current.dataManager.getRenderState());
        }
    }

    // Полный список колонок
    fullColumnsList = () => {
        const {currUser} = this.props
        const defaultColumns = this.generateColumns()


        if (this.tableRef.current) {
            return this.tableRef.current.state.columns
        }

        if (currUser.userDetails.setting) {
            let resColumns = []
            try {
                const savingColumns = JSON.parse(currUser.userDetails.setting)
                const newColumns = defaultColumns.filter(column => !!!savingColumns.find(saveColumn => saveColumn.field === column.field))
                resColumns = [...newColumns, ...savingColumns.map(savedColumn => {
                    const defaultColumn = defaultColumns.find(defaultColumn => defaultColumn.field === savedColumn.field)
                    if (defaultColumn) {
                        return {
                            ...defaultColumn,
                            ...savedColumn,
                            title: defaultColumn.title,
                            cellStyle: defaultColumn.field === 'actions' ? {
                                textAlign: 'right'
                            } : defaultColumn.cellStyle,
                            isResizable: defaultColumn.isResizable,
                            headerStyle: savedColumn.headerStyle && defaultColumn.isResizable ? {
                                ...defaultColumn.headerStyle,
                                ...savedColumn.headerStyle
                            } : {
                                minWidth: defaultColumn.minWidth ? defaultColumn.minWidth : 50
                            },
                            hidden: savedColumn.hidden ? savedColumn.hidden : false
                        }
                    }
                    return null
                })].filter(resColumn => resColumn !== null)
            }
            catch (err) {
                resColumns = defaultColumns
            }
            return resColumns
        }
        return defaultColumns
    }

    changeDepartmentInRow = (id, dep) => {
        const columnsData = this.tableRef.current.dataManager.data.map(row => {
            if (row.id === id) {
                row = {
                    ...row,
                    department: {...dep},
                    responsiblePerson: null
                }
            }
            return row
        })

        this.tableRef.current.dataManager.setData(columnsData)
        this.tableRef.current.setState(this.tableRef.current.dataManager.getRenderState())
    }

    changeResponsibleInRow = (id, user) => {
        const columnsData = this.tableRef.current.dataManager.data.map(row => {
            if (row.id === id) {
                row = {
                    ...row,
                    responsiblePerson: {...user}
                }
            }
            return row
        })

        this.tableRef.current.dataManager.setData(columnsData)
        this.tableRef.current.setState(this.tableRef.current.dataManager.getRenderState())
    }

    // Компонент фильтра в ячейке таблицы
    FilterSelect = (props) => {
        const {filters} = this.props;
        const {column, values} = props;
        const activeFilter = filters.find(filter => filter.column.tableData.id === column.tableData.id)
        let value = null
        if (activeFilter) {
            let tmp = ['Totals', 'Deep', 'Custom'].find(item => item === activeFilter.value);
            value = tmp ? { value: tmp, label: tmp} : null;
        }

        return <DropDown
            ToggleButton={IconButtonMaterial}
            className="select-filter-dropdown"
            ToggleContent={() => <svg viewBox="0 0 24 24">
                <path fill="#000000" d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"/>
            </svg>}
        >
            <Select
                options={['-', 'Totals', 'Deep', 'Custom'].map(item => ({
                    value: item === '-' ? null : item,
                    label: item
                }))}
                value={value}
                onChange={valueObj => {
                    this.tableRef.current.onFilterChange(column.tableData.id, valueObj.value)
                }}
            />
        </DropDown>
    }

    // Метод для управления состояниями модалок
    showHideModal = (modalName, open, callback) => {
        this.setState({
            [modalName]: open,
            inputText: '',
            commentToDelete: ''
        }, () => callback && callback())

    }

    //Обработчик удаления
    handleDeletePurchase = ({inputText, commentToDelete}) => {
        const currentPurchase = {...this.state.currentPurchase}

        const idPoProps = this.props.match.params.id
        this.setState({
            modalRemovePurchase: false,
            currentPurchase: null,
        }, () => {
            adminInstance.delete(`${url}/purchase_order/${currentPurchase.id}`, {
                params: {
                    orderNumber: inputText,
                    text: commentToDelete
                }
            })
                .then(res => {
                    if (idPoProps && parseInt(idPoProps) === parseInt(currentPurchase.id)) {
                        changeRoutePush('/atw/purchase-orders', history)
                    }
                    let query = {
                        ...this.tableRef.current.state.query,
                        page: 0
                    }
                    this.tableRef.current.onQueryChange(query, () => {
                        this.setState({
                            deletedPo: this.state.deletedPo.concat({
                                ...currentPurchase,
                                timeout: setTimeout(() => {
                                    this.handleRemoveUndoItem(currentPurchase.id)
                                }, 10000),
                                actionId: res.data
                            })
                        })
                    })
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })
    }

    handleUndoPo = (idPo, actionId) => {
        this.handleRemoveUndoItem(idPo, () => {
            adminInstance.post(`${url}/purchase_order/${idPo}/undo`, convertToFormData({
                actionId: actionId
            }))
                .then(res => {
                    this.tableRef.current && this.tableRef.current.onQueryChange()
                })
        })

    }

    handleRemoveUndoItem = (idPo, callback) => {
        clearTimeout(this.state.deletedPo.find(po => po.id === idPo).timeout)
        this.setState(state => ({
            deletedPo: state.deletedPo.filter(po => po.id !== idPo)
        }), () => {
            callback && callback()
        })
    }

    generateQueryString = (query) => {
        let queryString = '?'
        queryString += `page= ${query.page ? query.page : '0'}`
        queryString += `&limit=${query.pageSize}`
        queryString += this.generateSearchString({
            search: query.search,
            filters: query.filters
        })
        // queryString += this.generateQueryStringFromObject(query.filters)
        return queryString
    }

    // Метод для создания строки поиска
    generateSearchString = (obj) => {
        const {search, filters} = obj
        let result = '&search='
        result += `s:${search};`
        filters.forEach((filter) => {
            if (filter.column.field === 'createdAt' && filter.value) {
                const dates = filter.value.split('-')
                result += `createdAt>:${dates[0]};createdAt<:${dates[1]};`
            }
            if (filter.column.field === 'inboundType' && filter.value) {
                result += `inboundType:${filter.value};`
            }
        })
        return result
    }


    // Промис для получения списка
    getPurchaseOrders = query => {
        const urlToGetPo = findPermInArray(this.props.roles, permViewPO) ? `${url}/purchase_order` : `${url}/purchase_order/user/view`
        const {changeFilters, setAvgTotalPo} = this.props
        changeFilters && changeFilters(query.filters)
        const {search, filters} = this.state
        const isResetPage = search !== query.search || filters.length !== query.filters.length
        query.page = query.page && !isResetPage ? query.page : 0;
        query.search = this.props.searchText;
        return new Promise((resolve, reject) => {
            adminInstance.get(`${urlToGetPo}${this.generateQueryString(query)}`)
                .then(res => {
                    setAvgTotalPo({
                        avg: res.data.avg,
                        total: res.data.total, // Total e
                        totalQuantity: res.data.totalQuantity, // Total pcs
                        avgPcsEuro: res.data.avgPcsEuro, // AVG/pcs e
                        totalPcsEuro: res.data.totalPcsEuro, // Total pcs e
                        totalNetKg: res.data.totalNetKg, // Total Net kg
                        avgKgEuro: res.data.avgKgEuro, // Total kg e
                        totalKgEuro: res.data.totalKgEuro, // Total net kg
                    })
                    this.setState({
                        searchText: query.search,
                        filters: [...query.filters]
                    }, () => {

                        resolve({
                            data: this.generateRows(res.data.purchaseOrders),
                            page: parseInt(res.data.count / query.pageSize) >= query.page ? query.page : 0,
                            totalCount: res.data.count,
                            pageSize: query.pageSize
                        })
                    })

                })
        })
    }

    // Создания массива строк таблицы
    generateRows = data => {
        return data.map(item => ({
            author: item.po.author,
            existInvoice: item.po.existInvoice,
            bids: item.bids,
            id: item.po.id,
            status: item.po.status,
            orderNumber: item.po.orderNumber,
            createdAt: item.po.createdAt,
            supplier: item.po.supplier,
            placementDate: item.po.placementDate,
            referenceNumber: item.po.referenceNumber,
            inboundType: item.po.inboundType,
            responsiblePerson: item.po.responsiblePerson,
            buyer: item.po.buyer,
            offerNumber: item.po.offerNumber,
            department: item.po.department,
            addressPrice: item.po.addressPrice
        }))
    }
    handleConfirmExport = () => {
        this.setState({confirmExportXlsModal: true})
    }
    handleExportPO = () => {
        if (!this.state.isExporting) {
            const query = this.tableRef.current ? this.tableRef.current.state.query : {}
            const user = JSON.parse(window.localStorage.getItem('userWCS'))
            if (user) {
                this.setState({
                    confirmExportXlsModal: false,
                    isExporting: true
                }, () => {
                    axios({
                        url: `${url}/purchase_order/export_PO${this.generateQueryString(query)}`,
                        method: 'GET',
                        responseType: 'blob',
                        headers: {
                            'Authorization': `Bearer ${user.access_token}`
                        }
                    })
                        .then(response => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            const currDate = moment().format('YYYY-MM-DDTHH:mm')
                            link.setAttribute('download', `Purchase_orders_${currDate}.xls`);
                            document.body.appendChild(link);
                            link.click();
                            this.setState({
                                isExporting: false
                            })
                        })
                })

            }
        }


    }

    // Обновлям хедер и получаем необходимые данные
    componentDidMount() {
        const {getUsers, getStatuses, setTableRef} = this.props
        this.props.setHeaderData({
            leftPannelData: {
                title: 'Purchase Orders',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#1565C0"
                          d="M19,3H14.82C14.25,1.44 12.53,0.64 11,1.2C10.14,1.5 9.5,2.16 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M7,7H17V5H19V19H5V5H7V7M17,11H7V9H17V11M15,15H7V13H15V15Z"/>
                </svg>)),
            },
            handleAdd: null,
            LeftPannel: StandartLeftPanner,
            // handleAdd: findPermInArray(roles, permCreatePO) ? () => this.showHideModal('createPurchaseOrderModal', true) : null,
            handleSearch: (value) => this.tableRef.current.onSearchChange(value),
            tableRef: this.tableRef.current,
            Actions: () => <DownloadXlsxPOComponent handleConfirmExport={this.handleConfirmExport}/>,
        })
        setTableRef && setTableRef(this.tableRef.current);
        getUsers()
        getStatuses()
        // this.getFiltersValues()
        getDepartments()
            .then(res => {
                this.setState({
                    departments: res.data.departments
                })
            })

    }

    // сброс состояния
    componentWillUnmount() {
        this.props.setHeaderData({title: null, handleAdd: null, handleSearch: null, Actions: null})
        const {resetState, resetHeaderState} = this.props
        resetState && resetState()
        resetHeaderState && resetHeaderState()
    }

    // changeDataInRowTable = (id, data) => {
    //     let newData = {
    //         bids: data.bids,
    //         status: data.status,
    //         orderNumber: data.orderNumber,
    //         createdAt: data.createdAt,
    //         supplier: data.supplier,
    //         placementDate: data.placementDate,
    //         channel: data.channel,
    //         totalPrice: data.totalPrice,
    //         commodity: data.commodity,
    //         gender: data.gender,
    //         quality: data.quality,
    //         author: data.author,
    //         referenceNumber: data.referenceNumber,
    //         responsiblePerson: data.responsiblePerson,
    //         buyer: data.buyer,
    //         offerNumber: data.offerNumber,
    //         department: data.department,
    //         existInvoice: data.existInvoice
    //     }
    //     const columnsData = this.tableRef.current.dataManager.data.map(row => {
    //         if (row.id === parseInt(id)) {
    //             row = {
    //                 id: parseInt(id),
    //                 ...newData
    //             }
    //         }
    //         return row
    //     })

    //     this.tableRef.current.dataManager.setData(columnsData)
    //     this.tableRef.current.setState(this.tableRef.current.dataManager.getRenderState())

    // }

    render() {
        const {
            isLoading,
            setLoading,
            saveOverviewPo,
            roles
        } = this.props
        const {id} = this.props.match.params
        const {
            modalRemovePurchase,
            currentPurchase,
            // isLoadingFiltersValue,
            confirmExportXlsModal,
            modalChangeStatus,
            modalChangeStatusWithReason,
            deletedPo,
            isExporting,
            currentStatus,
        } = this.state
        const purchaseNumber = this.tableRef.current ? this.tableRef.current.dataManager.data.find(item => currentPurchase && item.id === currentPurchase.id) : null

        return (
            <div className={`wrap-table-with-details-panel po-wrapper ${!id ? '' : 'with-details'}`}>

                <div className="table-block wrapper-table po-table">
                    <Table
                        components={{
                            Container: ContainerTable,
                            Toolbar: ToolbarPo,
                            Header: Header,
                            Pagination: Pagination
                        }}
                        roles={roles}
                        textLoading={isExporting ? 'Downloading' : 'Loading'}
                        paginationPosition="toolbar"
                        saveOverviewPo={saveOverviewPo}
                        tableRef={this.tableRef}
                        // columns={findPermInArray(roles, permFinanceInfoOverview) ? this.fullColumnsList() : this.fullColumnsList().filter(item => item.field !== 'pricePerUnit' && item.field !== 'totalPrice')}
                        columns={this.fullColumnsList()}
                        data={this.getPurchaseOrders}
                        onRowClick={(event, rowData) => {
                            if (this.props.match.params.id && rowData.id !== parseInt(this.props.match.params.id))
                                setLoading(true)
                            changeRoutePush(`/atw/purchase-orders/${rowData.id}`, history)
                        }}
                        title="Purchase Orders"
                        isLoading={isLoading || isExporting}
                        options={{
                            maxBodyHeight: 'calc(100vh - 130px)',
                            draggable: true,
                            debounceInterval: 500,
                            search: true,
                            emptyRowsWhenPaging: false,
                            pageSize: 50,
                            pageSizeOptions: [12, 25, 50],
                            rowStyle: props => {
                                if (parseInt(id) === props.id)
                                    return {
                                        backgroundColor: '#f2f2f2'
                                    }
                                else {
                                    return {}
                                }
                            }
                        }}
                    />
                </div>
                {deletedPo.length > 0 && <UndoList
                    orders={deletedPo}
                    handleUndo={this.handleUndoPo}
                    removeItem={this.handleRemoveUndoItem}

                />}
                {modalRemovePurchase && findPermInArray(roles, permRemovePO) && <ModalWithInput
                    textToCompare={purchaseNumber ? purchaseNumber.orderNumber : ''}
                    open={modalRemovePurchase}
                    TextComponent={() => <>
                        <div className="wrap-text">
                            <p>You're going to delete purchase order. Deleted order can not be restored! Are you
                                ABSOLUTELY sure?</p>
                            <p>The action can lead to data loss. Please enter the <span
                                className="text-to-repeat-for-delete">{purchaseNumber ? purchaseNumber.orderNumber : ''}</span> to
                                confirm deleting</p>
                        </div>
                    </>}
                    withReason={true}
                    handleConfirm={this.handleDeletePurchase}
                    handleCancel={e => this.showHideModal('modalRemovePurchase', false)}
                    handleClose={e => this.showHideModal('modalRemovePurchase', false)}
                    options={{
                        centered: true
                    }}
                />
                }
                {confirmExportXlsModal && findPermInArray(roles, permDownloadXlsxPO) && <CustomModalWithChildren
                    open={confirmExportXlsModal}
                    handleConfirm={this.handleExportPO}
                    handleCancel={() => {
                        this.setState({
                            confirmExportXlsModal: false
                        })
                    }}
                    handleClose={() => {
                        this.setState({
                            confirmExportXlsModal: false
                        })
                    }}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text="export POs data"
                    />
                </CustomModalWithChildren>}
                <CustomModalWithChildren
                    open={modalChangeStatus}
                    handleCancel={() => this.setState({
                        modalChangeStatus: false,
                    })}
                    handleClose={() => this.setState({
                        modalChangeStatus: false
                    })}
                    handleConfirm={() => this.handleChangeStatus()}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`change #${currentPurchase ? currentPurchase.id : ''} PO's status to "${currentStatus ? currentStatus.name : ''}"`}
                    />
                </CustomModalWithChildren>
                <Modal
                    open={modalChangeStatusWithReason}
                    Content={() => <ModalChangeStatusWithReason
                        handleCancel={() => {
                            this.setState({
                                modalChangeStatusWithReason: false
                            })
                        }}
                        handleSubmit={(reason) => {
                            this.handleChangeStatus(reason)
                        }}
                    />}
                    handleClose={() => this.setState({
                        modalChangeStatusWithReason: false
                    })}
                    isFooter={false}
                    options={{
                        centered: true
                    }}
                />

            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        isLoading: state.purchaseOrdersReducer.isLoading,
        isLoadingDetails: state.purchaseOrdersReducer.isLoadingDetails,
        filters: state.adminHeaderReducer.filters,
        channels: state.purchaseOrdersReducer.channel,
        commodities: state.purchaseOrdersReducer.commodity,
        genders: state.purchaseOrdersReducer.gender,
        qualities: state.purchaseOrdersReducer.quality,
        statuses: state.purchaseOrdersReducer.statuses,
        users: state.usersReducer.users,
        roles: state.rolesReducer.roles,
        currUser: state.usersReducer.currUser,
        searchText: state.adminHeaderReducer.searchText,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDetails: id => dispatch(getDetail(id)),
        resetState: () => dispatch(resetState()),
        setHeaderData: (data) => dispatch(setData(data)),
        changeFilters: filters => dispatch(changeFiltersAction(filters)),
        getListOfParametrs: alias => dispatch(getListOfParametrs(alias)),
        resetHeaderState: () => dispatch(resetHeaderStateAction()),
        getUsers: () => dispatch(getUsers()),
        setAvgTotalPo: data => dispatch(setAvgTotalPo(data)),
        getStatuses: () => dispatch(getPoStatuses()),
        saveOverviewPo: columns => dispatch(saveOverviewPo(columns)),
        setTableRef: ref => dispatch(setPoTableRef(ref)),
        // changeWrapperStyle: styles => dispatch(changeWrapperStyleAction(styles))
    }
}
export default withTracker(connect(mapStateToProps, mapDispatchToProps)(ListPurchaseOrders))