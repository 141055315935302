import * as yup from 'yup'

export const boxSchema = yup.object().shape({
  item: yup.number()
    .typeError('Should be positive number')
    .min(0, 'Should be positive number')
    .integer('Should be positive number')
    .test('less then total', 'Cannot be over than total received', function (val){
      const {actualQuantity, actual_items, prevCount} = this.parent;
      const act = parseInt(actualQuantity)

      if(val && (act || act === 0)){
        return act >= actual_items - prevCount + val
      }
      return true
    })
    .required('Required field')
})