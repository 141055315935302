import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Button , DropdownItem} from 'react-bootstrap'
import { findPermInArray } from '../../../../../../reuseFunctions/checkPermission'
import {
    showHideModalProducts,
    closeOpenInbound,
    setDetailsPurchaseAction, setSnapshotAction
} from '../../../../../../actions/purchase-orders'
import { isEditInboundAction, getInboundReport } from '../../../../../../actions/detailsPo'
import { Table } from 'react-bootstrap'
import InboundTableCellValue from './inbound-table-cell-value'
import { permCompleteInbound, permInboundType, permQualityCheck, permTaskChronology } from '../../../../../../permissions/productOverview'
import ReceivedTable from './tableReceived/index'
import moment from 'moment'
import Tooltip from '../../../../../reuseComponent/Tooltips/index'
import { setInboundReport } from '../../../../../../actions/detailsPo'
// import PackageIcon from '../../../../../assets/images/packageIcon.png'
import { isChangeDividingStatus } from '../../../../../../actions/stowing'
import { client, decoratorSocket } from '../../../../../../socketClient'
import Switcher from '../../../../../reuseComponent/Switcher'
import { editPurchaseDetails, getSnapshotByPo } from '../../../../../../services/purchaseOrders'
import { defaultServerError } from '../../../../../../reuseFunctions/toasts'
import { notify } from '../../../../../reuseComponent/toast'
import CustomSelect from '../../../../../reuseComponent/Select/select-form'
import ModalWithInput from '../../../../../reuseComponent/modal/modalWithInputConfirm'
import { checkIfObjectEmptyValues } from '../../../../../../reuseFunctions/objectFunctions'
import { getPackagesRed } from '../../../../../../actions/inboundingReport'
import { ReactComponent as ItemIcon } from '../../../../../../assets/images/item.svg'
import isEqual from 'react-fast-compare'
import { addTaskAction, changeDetailsAction, editTaskAction } from "../../../../../../actions/purchase-orders";
import { createTask, createInboundTask, changeCompleteStatus } from '../../../../../../services/tasks';
import FormikWrapper from './createResponsible';
import Modal from '../../../../../reuseComponent/modal/index'
import { getTasksAction } from '../../../../../../actions/tasks';
import DropdownMenu from '../../../../../reuseComponent/DropDown/DropDownWithChildren';
import IconButtonMaterial from '../../../../../reuseComponent/Buttons/ButtonWithMaterialIcon';


class InputSectionComponent extends PureComponent {

    subscribeInbound = null

    state = {
        values: {
            actualQuantity: this.props.details.actualQuantity ? JSON.parse(this.props.details.actualQuantity) : null,
            actualPackagesCount: this.props.details.actualPackagesCount,
            actualPalletsCount: this.props.details.actualPalletsCount,
            actualNetWeight: this.props.details.actualNetWeight,
            actualGrossWeight: this.props.details.actualGrossWeight,
            actualArrivalDate: this.props.details.actualArrivalDate ? moment(this.props.details.actualArrivalDate).format('DD.MM.YYYY') : '',
        },
        modalChangeInboundType: false,
        selectedType: null,
        createModalOpen: false,

    }

    handleCreateTask = values => {
        const { addTaskAction, activeTab, getHistory, details, changeDetailsAction } = this.props
        values.poUrl = window.location.origin + '/atw/purchase-orders'
        values.taskUrl = window.location.origin + '/atw/tasks/tickets'
        values.title = `Responsibility for Inbound of PO ${details.orderNumber}`;
        values.inbound = true;
        values.inboundDueDate = values.dueDate;
        values.inboundDescription = values.description;

        delete values.responsible
        delete values.order
        this.setState({
            createModalOpen: false
        }, () => {
            createTask(values)
                .then(res => {
                    addTaskAction(res.data.task ? res.data.task : {});
                    const id = details.id;
                    values.userId = values.executorId;
                    values.inboundDueDate = values.dueDate;
                    values.inboundDescription = values.description;
                    createInboundTask(values, id)
                        .then(res => {
                            changeDetailsAction(res.data ? res.data : {});
                            notify('success', 'Ticket successfully created');
                            this.props.setActiveTasks();
                            activeTab === '' && getHistory('')
                        })
                        .catch(err => {
                            notify('error', 'Oops something went wrong')
                        })
                })
                .catch(err => {
                })
        })
    }

    openChangeInboundTypeModal = (type) => {
        this.setState({
            modalChangeInboundType: true,
            selectedType: type
        })
    }

    closeChangeInboundTypeModal = () => {
        this.setState({
            modalChangeInboundType: false,
            selectedType: null,
        })
    }

    getTotalItems = totals => Object.keys(totals).reduce((acc, val) => totals[val].items ? acc + totals[val].items : acc, 0);

    changeValues = (nameField, value) => {
        this.setState(state => {
            return {
                values: {
                    ...state.values,
                    [nameField]: value
                }

            }
        })
    }

    handleChangeMode = (callback) => {
        this.setState({
            values: {
                actualQuantity: this.props.details.actualQuantity ? JSON.parse(this.props.details.actualQuantity) : null,
                actualPackagesCount: this.props.details.actualPackagesCount,
                actualPalletsCount: this.props.details.actualPalletsCount,
                actualNetWeight: this.props.details.actualNetWeight,
                actualGrossWeight: this.props.details.actualGrossWeight,
                actualArrivalDate: this.props.details.actualArrivalDate ?
                    moment(this.props.details.actualArrivalDate).format('DD.MM.YYYY') : '',
            }
        }, () => callback && callback())
    }

    handleSubmit = () => {
        const { values } = this.state
        const { handleChangePurchase, setEditStatus, details, getPackages } = this.props
        values.actualQuantity = JSON.stringify(values.actualQuantity)

        let f = {
            actualArrivalDate: values.actualArrivalDate,
            actualQuantity: parseInt(values.actualQuantity),
            actualPackagesCount: parseInt(values.actualPackagesCount),
            actualPalletsCount: parseInt(values.actualPalletsCount),
            actualNetWeight: parseFloat(values.actualNetWeight) ? parseFloat(values.actualNetWeight).toFixed(2) : null,
            actualGrossWeight: parseFloat(values.actualGrossWeight) ? parseFloat(values.actualGrossWeight).toFixed(2) : null,
        }
        let p = {
            actualQuantity: this.props.details.actualQuantity ? parseInt(JSON.parse(this.props.details.actualQuantity)) : null,
            actualPackagesCount: parseInt(this.props.details.actualPackagesCount),
            actualPalletsCount: parseInt(this.props.details.actualPalletsCount),
            actualNetWeight: this.props.details.actualNetWeight ? this.props.details.actualNetWeight.toFixed(2) : null,
            actualGrossWeight: this.props.details.actualGrossWeight ? this.props.details.actualGrossWeight.toFixed(2) : null,
            actualArrivalDate: this.props.details.actualArrivalDate ? moment(this.props.details.actualArrivalDate).format('DD.MM.YYYY') : '',
        }

        // console.log(f);
        // console.log(p)
        // console.log(isEqual(f, p))

        const {
            actualPackagesCount,
            ...sendData
        } = values

        if (parseInt(values.actualPackagesCount) !== parseInt(details.actualPackagesCount)) {
            sendData.actualPackagesCount = actualPackagesCount
        }

        const callback = parseInt(values.actualPackagesCount) !== parseInt(details.actualPackagesCount) ? () => {
            notify('success', 'Packs generated');
            details.id && getPackages && getPackages(details.id);
        } : isEqual(f, p) ? () => { } : null
        handleChangePurchase(sendData, () => setEditStatus(false), callback)

    }

    handleChangeQualityCheck = () => {
        const { setDetailsPurchase, details } = this.props;

        editPurchaseDetails(details.id, {
            qualityCheck: !details.qualityCheck
        })
            .then(res => {
                setDetailsPurchase({
                    qualityCheck: !details.qualityCheck
                })
                notify('success', `'Quality check' mode ${details.qualityCheck ? 'deactivated' : 'activated'}`)
            })
            .catch(err => {
                notify('error','Inappropriate Quality info at Product list!')
            })
    }

    handleChangeInboundType = (type, callback) => {
        const { setDetailsPurchase, details, getInboundReport, idOrder, setSnapShot } = this.props;

        editPurchaseDetails(details.id, {
            inboundType: type
        })
            .then(res => {
                getSnapshotByPo(details.id, '')
                    .then(res => {
                        setSnapShot(res.data.data)
                    })
                getInboundReport && getInboundReport(idOrder)
                setDetailsPurchase({
                    totalItems: 0,
                    inboundType: type,
                    actualQuantity: null,
                    actualPackagesCount: null,
                    actualPalletsCount: null,
                    actualNetWeight: null,
                    actualGrossWeight: null,
                    actualArrivalDate: null,
                    qualityCheck: false
                })
                this.setState({
                    values: {
                        actualQuantity: null,
                        actualPackagesCount: null,
                        actualPalletsCount: null,
                        actualNetWeight: null,
                        actualGrossWeight: null,
                        actualArrivalDate: null,
                    }
                })
                notify('success', `Inbound level changed to "${type}"`)
                callback && callback()
            })
            .catch(defaultServerError)
    }

    componentDidMount() {
        const { getInboundReport, details, getNewStowing, idOrder } = this.props
        getInboundReport && getInboundReport(idOrder)
        decoratorSocket(() => {
            client.onConnect = (frame) => {
                this.subscribeInbound = client.subscribe(`/purchase_order/${details.id}`, data => {
                    try {
                        if (data.body === 'true') {
                            getNewStowing(data.body)
                        }
                        else {
                            const { setInboundReport } = this.props
                            const res = JSON.parse(data.body)
                            setInboundReport(res)
                        }

                    }
                    catch {


                    }
                })
            }
        })
    }

    componentWillUnmount() {
        if (this.subscribeInbound)
            this.subscribeInbound.unsubscribe()
    }

    render() {
        const {
            roles,
            showHideProducts,
            details,
            closeOpenInbound,
            isEditInbound,
            inboundReport,
            detailsWrapper,
            status,
            snapshot,
            inboundingTypes,
            tasksList,
            editTask,
            deleteTask
        } = this.props

        const hasPermToEdit = (findPermInArray(roles, permTaskChronology));
        const task = tasksList.filter(task => task.inbound === true)[0];

        const json = snapshot ? JSON.parse(snapshot.inbound) : null
        // console.log(json)
        const inboundInfo = json ? { ...json } : { ...inboundReport }
        // console.log(inboundInfo, inboundReport)
        // const inboundInfo = {...inboundReport}
        const { values, modalChangeInboundType, selectedType, createModalOpen } = this.state
        const { total_boxes, underDelivered, ...restTotal } = inboundReport;

        const getDueDate = () => {
            const inboundTask = tasksList && tasksList.filter(task => task.inbound === true)[0];
            if (
                inboundTask &&
                inboundTask.dueDate &&
                moment(inboundTask.dueDate).isValid()
            ) {
                return moment(inboundTask.dueDate).format('MMM D');
            }

            return "No due date";
        }

        const markComplete = () => {
            const inboundTask = tasksList && tasksList.filter(task => task.inbound === true)[0];
            closeOpenInbound(window.location.origin + '/atw/purchase-orders', inboundTask.id)
        };

        return (
            <>
                <div className="inbound-wrapper">
                    <div className="title-inbound">
                        <div className="name-block">
                            <p className="title">Inbound</p>
                        </div>
                        <div className={`actions-block ${details.bids.length === 0 ? 'disabled' : ''}`}>
                            {task != null && task != undefined && task.executor && task.executor.name ?
                                <div className="d-flex align-center">
                                    <span className="deadline-value">
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
                                        </svg>
                                        {
                                            getDueDate()
                                        }
                                    </span>
                                    {task.executor.department && <Tooltip text={task.executor.department.name}>
                                        <span className="icon-department"
                                            dangerouslySetInnerHTML={{ __html: task.executor.department.icon }}></span>
                                    </Tooltip>}
                                    <Tooltip
                                        text={`${task.executor.name} ${task.executor.secondName}`}
                                    >
                                        <span className={`executor-value`} style={{
                                            color: task.executor.initialsColor
                                        }}>{task.executor.initials}</span>
                                    </Tooltip>
                                </div> : (
                                    <Button
                                        type="button"
                                        onClick={() => this.setState({
                                            createModalOpen: true
                                        })}
                                        className={'with-icon'}
                                    >
                                        <svg viewBox="0 0 24 24">
                                            <path
                                                fill="#FF9800"
                                                d="M19,4H18V2H16V4H8V2H6V4H5A2,2 0 0,0 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M5,8V6H19V8H5M10.56,18.46L16.5,12.53L15.43,11.47L10.56,16.34L8.45,14.23L7.39,15.29L10.56,18.46Z"
                                            />
                                        </svg>
                                        Responsible
                                    </Button>
                                )
                            }

                            {task != null && task != undefined && task.executor != null && hasPermToEdit &&
                            <div className="margin-left-center">
                                <DropdownMenu

                                    ToggleContent={() => <svg
                                        viewBox="0 0 24 24">
                                        <path fill="#000000" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                                    </svg>}
                                >
                                    <>
                                        <DropdownItem onClick={() => editTask(task.id, false)}>Edit</DropdownItem>
                                        <DropdownItem onClick={() => deleteTask(task.id, task.title)}>Delete</DropdownItem>
                                    </>
                                </DropdownMenu>
                            </div>
                            }

                            <span className="with-icon">

                                <CustomSelect
                                    value={details.inboundType ? inboundingTypes.find(item => item.value === details.inboundType) : null}
                                    onChange={obj => {
                                        details.inboundType ?
                                            this.openChangeInboundTypeModal(obj.value) :
                                            this.handleChangeInboundType(obj.value)
                                    }}
                                    isDisabled={!findPermInArray(roles, permInboundType) || details.completeInbound || details.bids.length === 0}
                                    placeholder={details.bids.length === 0 ? 'BID creation required' : 'Select inbound level'}
                                    options={inboundingTypes}
                                    className={
                                        details.inboundType ?
                                            (details.inboundType === 'Deep' ?
                                                'deep' :
                                                details.inboundType === 'Custom' ?
                                                    'custom' :
                                                    'totals') :
                                            'empty'
                                    }
                                />
                            </span>
                            {findPermInArray(roles, permQualityCheck) && !details.completeInbound && details.inboundType === 'Deep' &&
                                <div className="quality-check">
                                    Quality check:
                            <Switcher
                                        checked={details.qualityCheck}
                                        onChange={this.handleChangeQualityCheck}
                                    />
                                </div>}
                            {findPermInArray(roles, permCompleteInbound)
                                && !isEditInbound
                                && details.responsibleInboundPerson != null
                                && details.inboundType
                                && (details.inboundType === 'Totals' ? !checkIfObjectEmptyValues(values, ["", null, undefined, "{}"])
                                    : (details.inboundType === 'Deep' ? !!this.getTotalItems(restTotal) : !!inboundReport.items))
                                && <>
                                    <Button
                                        onClick={() => markComplete()}
                                        className={`with-icon ${details.completeInbound ? 'complete' : ''}`}
                                    >
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#000000" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                        </svg>
                                        {details.completeInbound ? 'Completed' : 'Mark Complete'}
                                    </Button>
                                </>}

                        </div>
                    </div>
                    {details.inboundType === 'Totals' && <ReceivedTable
                        changeValues={this.changeValues}
                        values={values}
                        handleSubmit={this.handleSubmit}
                        handleChangeMode={this.handleChangeMode}
                        detailsWrapper={detailsWrapper}
                    />}
                    {details.inboundType === 'Custom' && <div>
                        <div className="inbound-table-wrapper table-for-custom">
                            <Table size="sm" responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <div className='inbound-cell-value'>
                                                <Tooltip text="Inbounded">
                                                    <ItemIcon className="standard-svg" />
                                                    {/*<svg style={{*/}
                                                    {/*    width: 20,*/}
                                                    {/*    height: 20*/}
                                                    {/*}} viewBox="0 0 24 24">*/}
                                                    {/*    <path fill="#B0BEC5"*/}
                                                    {/*          d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z"/>*/}
                                                    {/*</svg>*/}
                                                </Tooltip>
                                            </div>
                                        </th>
                                        <th>
                                            <div className='inbound-cell-value'>
                                                <Tooltip text="Boxes">
                                                    <svg style={{ width: 20, height: 20 }} viewBox="0 0 24 24">
                                                        <path fill="#B0BEC5"
                                                            d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z" />
                                                    </svg>
                                                </Tooltip>
                                            </div>
                                        </th>
                                        <th>
                                            <div className='inbound-cell-value'>
                                                <Tooltip text="Packages">
                                                    <svg style={{ width: 20, height: 20 }} viewBox="0 0 24 24">
                                                        <path fill="#B0BEC5"
                                                            d="M3 5V21H19V23H3C1.9 23 1 22.1 1 21V5H3M21 1H7C5.89 1 5 1.89 5 3V17C5 18.1 5.9 19 7 19H21C22.11 19 23 18.11 23 17V3C23 1.9 22.1 1 21 1M21 17H7V3H21V17M11 14H8V16H11V14M15 14H12V16H15V14M11 11H8V13H11V11M15 11H12V13H15V11M11 8H8V10H11V8M15 8H12V10H15V8Z" />
                                                    </svg>
                                                </Tooltip>
                                            </div>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tr>
                                    <td>
                                        <div className="inbound-cell-value">
                                            {inboundReport.items === null ? 0 : inboundReport.items}
                                        </div>
                                        {/*<InboundTableCellValue*/}
                                        {/*  value={inboundReport.items}*/}
                                        {/*/>*/}
                                    </td>
                                    <td>
                                        <InboundTableCellValue
                                            value={inboundReport.boxes}
                                        />
                                    </td>
                                    <td>
                                        <InboundTableCellValue
                                            value={inboundReport.packs}
                                        />
                                    </td>
                                </tr>
                            </Table>
                        </div>
                    </div>}
                    {details.inboundType === 'Deep' && <div className="inbound-table-wrapper">
                        <Table size="sm" responsive>
                            <thead>
                                <tr>
                                    <th className="inbound-show-cell"></th>
                                    <th>Ordered</th>
                                    <th>Overdelivered</th>
                                    <th>Unordered</th>
                                    <th>Total</th>
                                    <th>Not delivered</th>
                                    {/*<th className="inbound-show-cell">*/}
                                    {/*    Ordered*/}
                                    {/*    /!*<div className={`${!status && 'disabled-cell'}`} onClick={() => showHideProducts({*!/*/}
                                    {/*    /!*    isShow: status,*!/*/}
                                    {/*    /!*    activeTabProducts: 'inbound-tab',*!/*/}
                                    {/*    /!*    idSection: 'ordered'*!/*/}
                                    {/*    /!*})}>Ordered*!/*/}
                                    {/*    /!*</div>*!/*/}
                                    {/*</th>*/}
                                    {/*<th className="inbound-show-cell">*/}
                                    {/*    <div className={`${!status && 'disabled-cell'}`} onClick={() => showHideProducts({*/}
                                    {/*        isShow: status,*/}
                                    {/*        activeTabProducts: 'inbound-tab',*/}
                                    {/*        idSection: 'over'*/}
                                    {/*    })}>Overdelivered*/}
                                    {/*    </div>*/}
                                    {/*</th>*/}

                                    {/*<th className="inbound-show-cell">*/}
                                    {/*    <div className={`${!status && 'disabled-cell'}`} onClick={() => showHideProducts({*/}
                                    {/*        isShow: status,*/}
                                    {/*        activeTabProducts: 'inbound-tab',*/}
                                    {/*        idSection: 'unordered'*/}
                                    {/*    })}>Unordered*/}
                                    {/*    </div>*/}
                                    {/*</th>*/}
                                    {/*<th>Total</th>*/}
                                    {/*<th className="inbound-show-cell">*/}
                                    {/*    <div className={`${!status && 'disabled-cell'}`} onClick={() => showHideProducts({*/}
                                    {/*        isShow: status,*/}
                                    {/*        activeTabProducts: 'inbound-tab',*/}
                                    {/*        idSection: 'under'*/}
                                    {/*    })}>Not delivered*/}
                                    {/*    </div>*/}
                                    {/*</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {inboundInfo ?
                                        <>
                                            <td>
                                                <div className='inbound-cell-value'>
                                                    <Tooltip text="Inbounded">
                                                        <ItemIcon className="standard-svg" />
                                                        {/*<svg style={{*/}
                                                        {/*    width: 20,*/}
                                                        {/*    height: 20*/}
                                                        {/*}} viewBox="0 0 24 24">*/}
                                                        {/*    <path fill="#B0BEC5"*/}
                                                        {/*        d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z"/>*/}
                                                        {/*</svg>*/}
                                                    </Tooltip>
                                                </div>
                                                <div className='inbound-cell-value'>
                                                    <Tooltip text="Boxes">
                                                        <svg style={{ width: 20, height: 20 }} viewBox="0 0 24 24">
                                                            <path fill="#B0BEC5"
                                                                d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z" />
                                                        </svg>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                            <td>
                                                <InboundTableCellValue
                                                    value={inboundInfo.ordered && inboundInfo.ordered.items}
                                                />
                                                <InboundTableCellValue
                                                    value={inboundInfo.ordered && inboundInfo.ordered.boxes}
                                                />
                                            </td>
                                            <td>
                                                <InboundTableCellValue
                                                    value={inboundInfo.overdelivered && inboundInfo.overdelivered.items}
                                                />
                                                <InboundTableCellValue
                                                    value={inboundInfo.overdelivered && inboundInfo.overdelivered.boxes}
                                                />
                                            </td>

                                            <td>
                                                <InboundTableCellValue
                                                    value={inboundInfo.unordered && inboundInfo.unordered.items}
                                                />
                                                <InboundTableCellValue
                                                    value={inboundInfo.unordered && inboundInfo.unordered.boxes}
                                                />
                                            </td>
                                            <td>
                                                <div className='inbound-cell-value'>
                                                    {this.getTotalItems(restTotal)}
                                                </div>
                                                <InboundTableCellValue
                                                    value={inboundInfo && inboundInfo.total_boxes}
                                                />
                                            </td>
                                            <td>
                                                <InboundTableCellValue
                                                    value={details.completeInbound ? inboundInfo.underDelivered && inboundInfo.underDelivered.items : <span>No data to display</span>}
                                                />
                                            </td>
                                        </>
                                        : <td colSpan={7}>
                                            <div className="no-data-to-display">
                                                No data to display...
                                    </div>
                                        </td>
                                    }
                                </tr>
                            </tbody>
                        </Table>
                    </div>}
                </div>
                {findPermInArray(roles, permInboundType) && <ModalWithInput
                    textToCompare={details ? details.orderNumber : ''}
                    open={modalChangeInboundType}
                    TextComponent={() => <>
                        <div className="wrap-text">
                            <p><span className="warn-text">Note! </span></p>
                            <p>Inbound level changing leads to data loss, are you sure you want to change?
                            <br />
                            Please enter the "<span
                                    className="text-to-repeat-for-delete">{details ? details.orderNumber : ''}</span>" to
                            confirm changing
                        </p>
                            {/*<p>Please enter the "<span*/}
                            {/*  className="text-to-repeat-for-delete">{details ? details.orderNumber : ''}</span>" to*/}
                            {/*    confirm changing</p>*/}
                        </div>
                    </>}
                    withReason={false}
                    handleConfirm={() => {
                        this.handleChangeInboundType(selectedType, () => {
                            this.closeChangeInboundTypeModal()
                        })
                    }}
                    handleCancel={e => this.closeChangeInboundTypeModal()}
                    handleClose={e => this.closeChangeInboundTypeModal()}
                    options={{
                        centered: true
                    }}
                />
                }
                <Modal
                    Content={() => <FormikWrapper
                        createFromPo={true}
                        handleSubmit={this.handleCreateTask}
                        handleCancel={() => this.setState({
                            createModalOpen: false
                        })}
                        initValues={{
                            dueDate: '',
                            poId: details.id,
                            executorId: '',
                            departmentId: '',
                            description: '',
                            title: '',
                            responsible: null,
                            order: null
                        }}
                    />}
                    open={createModalOpen}
                    isFooter={false}
                    handleClose={() => this.setState({
                        createModalOpen: false
                    })}
                    options={{
                        centered: true
                    }}
                    classNameModal="modal-800"
                />
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        status: state.dividingReducer.dividingStatus,
        roles: state.rolesReducer.roles,
        details: state.purchaseOrdersReducer.detailsPurchase,
        isEditInbound: state.poDetailsReducer.isEditInbound,
        inboundReport: state.poDetailsReducer.inboundReport,
        snapshot: state.purchaseOrdersReducer.snapshot,
        inboundingTypes: state.purchaseOrdersReducer.inboundingTypes.map(item => ({
            value: item,
            label: item,
        })),
        inboundingPackages: state.inboundingReportReducer.packages,
        tasksList: state.purchaseOrdersReducer.tasksList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showHideProducts: (data) => dispatch(showHideModalProducts(data)),
        closeOpenInbound: (url, taskId) => dispatch(closeOpenInbound(url, null, taskId)),
        setEditStatus: isEdit => dispatch(isEditInboundAction(isEdit)),
        getInboundReport: (idOrder) => dispatch(getInboundReport(idOrder)),
        setInboundReport: data => dispatch(setInboundReport(data)),
        getNewStowing: isChangeData => dispatch(isChangeDividingStatus(isChangeData)),
        setDetailsPurchase: data => dispatch(setDetailsPurchaseAction(data)),
        setSnapShot: snapShot => dispatch(setSnapshotAction(snapShot)),
        getPackages: id => dispatch(getPackagesRed(id)),
        addTaskAction: task => dispatch(addTaskAction(task)),
        changeDetailsAction: po => dispatch(changeDetailsAction(po)),
        editTaskAction: data => dispatch(editTaskAction(data)),
        setActiveTasks: () => getTasksAction(dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputSectionComponent)