import React from 'react'
import { Route, Switch } from 'react-router-dom';
// import Tabs from './tabs'
import TaskList from './list'
import InboxList from './inbox'


const TasksModule = () => {
    return (
        <>
            <Switch>
                <Route exact path={["/atw/tasks/tickets/:id" , "/atw/tasks/tickets"]} component={TaskList} />
                <Route exact path={"/atw/tasks/inbox"} component={InboxList} />
            </Switch>
        </>
    )
}

export default TasksModule