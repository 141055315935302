import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Tooltip from '../../../../../../../reuseComponent/Tooltips'

class CentralPartBidsHeader extends PureComponent {
    render() {
        const {details: {comment}} = this.props
        return (
            <div className="central-part">
                {comment && <>
                    <Tooltip
                        text="Notes"
                    >
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M13,10H11V6H13V10M13,12H11V14H13V12M22,4V16A2,2 0 0,1 20,18H6L2,22V4A2,2 0 0,1 4,2H20A2,2 0 0,1 22,4M20,4H4V17.2L5.2,16H20V4Z"/>
                        </svg>
                    </Tooltip>
                    <p>{comment}</p>
                </>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isEditMain: state.poDetailsReducer.isEditMain,
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

export default connect(mapStateToProps, null)(CentralPartBidsHeader)