import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import TypeCell from '../type-cell'
import {createValueRequest, editValueRequest} from '../../../../../actions/size-dictionary'
import Tooltip from '../../../../reuseComponent/Tooltips'


class TypeRow extends PureComponent {
    render() {
        const {idRow, sizeValues, sizeTypes, createValue, editValue, removeRow, hasPermToEdit} = this.props
        const euType = sizeTypes.find(type => type.value === 'EU')
        const types = sizeTypes.filter(type => type.value !== 'EU')
        const size = sizeValues.find(val => val.sizeType.id === euType.id)
        return (

                <>
                    {types.map(type => {
                        const size = sizeValues.find(val => val.sizeType.id === type.id)
                        return <td className={`types-row-cell`} key={type.id}>
                            <TypeCell
                                handleConfirm={value => {
                                    if (size)
                                        editValue(value, size.id)
                                    else
                                        createValue(idRow, type.id, value)
                                }}
                                type={{
                                    value: size ? size.value : ''
                                }}
                                hasPermToEdit={hasPermToEdit}
                            />
                        </td>
                    })}
                    <td className="types-row-cell eu-column">
                        <TypeCell
                            handleConfirm={value => {
                                if (size)
                                    editValue(value, size.id)
                                else
                                    createValue(idRow, euType.id, value)
                            }}
                            type={{
                                value: size ? size.value : ''
                            }}
                            hasPermToEdit={hasPermToEdit}
                        />
                    </td>
                    <td className="types-row-cell remove-row">
                        {hasPermToEdit &&
                        <Tooltip
                            text="Delete"
                        >
                            <button onClick={() => removeRow()} className="remove-set-row">
                                <svg viewBox="0 0 24 24">
                                    <path fill="#000000"
                                          d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
                                </svg>
                            </button>
                        </Tooltip>}
                    </td>
                </>
        )
    }
}

const mapStateToProps = state => {
    return {
        sizeTypes: state.sizeDictionaryReducer.sizeTypes,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createValue: (setId, sizeTypeId, value) => dispatch(createValueRequest(setId, sizeTypeId, value)),
        editValue: (value, valueId) => dispatch(editValueRequest(value, valueId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TypeRow)