import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { schema } from './schema'
import { setDetailsPurchaseAction } from '../../../../../../../actions/purchase-orders'
import BodyForm from './bodyForm'
import adminInstance from '../../../../../../instances/admininstance'
import { url } from '../../../../../../../constants'
import { convertToFormData } from '../../../../../../../reuseFunctions/objectToFormData'
import {showHideCreateContact} from '../../../../../../../actions/purchase-orders'


class FormCreateContact extends PureComponent{

    initValues = {
        email: '',
        name: '',
        phone: '',
        secondName: ''
    }

    handleSubmit = (values) => {
        const { details,changeDetails,openHideContact,setContactId } = this.props
        const data = convertToFormData(values)
        adminInstance.put(`${url}/supplier/${details.supplier.id}/add_contact`,data)
            .then(res => {
                setContactId(res.data.contacts[res.data.contacts.length - 1].id)
                changeDetails({
                    supplier : {
                        ...details.supplier,
                        contacts: res.data.contacts
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                openHideContact(false)
            })

    }

    render(){
        return (
            <Formik
                initialValues={this.initValues}
                validationSchema={schema}
                onSubmit={this.handleSubmit}
                render={props => <BodyForm {...props} handleCancel={() => {}}/>}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeDetails: values => dispatch(setDetailsPurchaseAction(values)),
        openHideContact: isShow => dispatch(showHideCreateContact(isShow))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(FormCreateContact)