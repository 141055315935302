import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import {findPermInArray} from '../../../../../reuseFunctions/checkPermission';
import {
    permEditInfo,
    permTaskChronology,
    permViewChronology,
    permViewInbound,
    permFinanceInfo,
    permViewInfo,
    permViewTransport,
    permFilesChronology,
    permInvoiceChronology
} from '../../../../../permissions/productOverview';
import BidsInfoComponent from './bid-info';
import History from './history';
import InboundSection from './inbound-section';
import Transport from './transport';
import AccountingSection from './accounting';
import RemoveBidModal from './bid-info/removeBidModal';
import {convertToFormData} from '../../../../../reuseFunctions/objectToFormData';
import {removeEmptyValues} from '../../../../../reuseFunctions/removeEmptyValues';
import adminInstance from '../../../../instances/admininstance';
import {url} from '../../../../../constants';
import {notify} from '../../../../reuseComponent/toast';
import {
    changeDetailsAction,
    getDetail,
    getHistory,
    setPoInboundingTypes,
    getTasksAction,
    loadingDetailAction,
    setActiveRow,
    setProductsList, editTaskAction, deleteTaskAction
} from '../../../../../actions/purchase-orders';
import Preloader from '../../../../reuseComponent/preloader/preloader';
import {getDividingStatus} from '../../../../../actions/dividing';
import {resetDetailsState} from '../../../../../actions/detailsPo';
import {isChangeDividingStatus} from '../../../../../actions/stowing';
import {setData} from '../../../../../actions/admin-header';

import './style.scss'
import { defaultServerError } from '../../../../../reuseFunctions/toasts'
import { getPoInboundingTypesService } from '../../../../../services/purchaseOrders'
import adminInstanse from '../../../../instances/admininstance'
import TaskItem from "./tasks/task-item";
import {createInboundTask, createTransportTask, createAccountingTask, deleteTask, editTask} from "../../../../../services/tasks";
import {ConfirmModalContent} from '../../../../reuseComponent/confirmModalComponent/index';
import CustomModalWithChildren from "../../../../reuseComponent/modal/modalWithChildren";
import EditTask from '../../../tasks/form/edit/index'
import Modal from "../../../../reuseComponent/modal/index"

class InfoOrder extends PureComponent {

    state = {
        currTask: null,
        editModalOpen: false,
        deleteTaskModal: false,
        removedTitle: '',
        onlyChangeResponsive: false,
        departments: [],
    }

    componentDidMount() {
        const {getTasks, idOrder, details} = this.props;
        const { changeActiveRow, setPoInboundingTypes } = this.props;
        idOrder && changeActiveRow && changeActiveRow(idOrder);

        if (idOrder === 'new') {
            return
        } else {
            getTasks &&
            getTasks({
                poId: idOrder,
                currUser: !this.checkIfHasPermToTasks()
            });
            getPoInboundingTypesService()
              .then(res => {
                  console.log('resInbaund',res.data)
                  if(res.data.data){
                      setPoInboundingTypes(res.data.data)
                  }
              })
              .catch(defaultServerError)
            // idOrder && getDetails && getDetails(idOrder);
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const {getTasks, setHeaderData, details, setProductsList} = this.props;

        if (JSON.stringify(this.props.roles) !== JSON.stringify(prevProps.roles)) {
            getTasks &&
            getTasks({
                poId: this.props.match.params.id,
                currUser: !this.checkIfHasPermToTasks()
            });
        }
        if (JSON.stringify(this.props.details !== prevProps.details)) {
            setHeaderData({
                leftPannelData: {
                    title: `Purchase Order ${this.props.details && this.props.details.orderNumber ?
                        '> ' + this.props.details.orderNumber : ''}`,
                    logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                        <path fill="#3F51B5"
                              d="M19,3H14.82C14.25,1.44 12.53,0.64 11,1.2C10.14,1.5 9.5,2.16 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M7,7H17V5H19V19H5V5H7V7M17,11H7V9H17V11M15,15H7V13H15V15Z"/>
                    </svg>)),
                }
            })
        }

        if (details && details.id && !details.productList) {
            adminInstanse.get(`${url}/product/list/${details.id}`, {
                params: {
                    page: 0,
                    limit: 100,
                    search: 's:;',
                }
            })
                .then(res => {
                    setProductsList(res.data);
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        }
    }

    checkIfHasPermToTasks = () => {
        const {roles} = this.props;
        return (
            findPermInArray(roles, permViewChronology) &&
            findPermInArray(roles, permTaskChronology)
        );
    };

    hasPermToSections = () => {
        const {roles} = this.props;
        return (
            findPermInArray(roles, permViewInfo) ||
            findPermInArray(roles, permViewChronology) ||
            findPermInArray(roles, permViewTransport) ||
            findPermInArray(roles, permViewInbound)
        );
    };

    handleEditingPurchase = (values, callback, customNotif) => {
        const {details} = this.props;
        const data = convertToFormData(removeEmptyValues(values));
        const {changeDetails, activeTab, getHistory} = this.props;
        adminInstance
            .put(`${url}/purchase_order/${details.id}`, data)
            .then(res => {
                changeDetails(res.data);
                callback && callback();
                activeTab === '' && getHistory && getHistory();
                if(customNotif){
                    customNotif()
                }else{
                    notify('success', 'Changes successfully saved');
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    render() {
        const { editModalOpen,currTask,deleteTaskModal, onlyChangeResponsive, departments, removedTitle } = this.state
        const {
            roles,
            tasksList,
            detailsWrapper,
            isLoadingDetails,
            isLoadingTasks,
            idOrder
        } = this.props;
        const hasPermToSeeDetails =
            this.hasPermToSections() ||
            (!this.checkIfHasPermToTasks() && tasksList.length > 0);
        const hasPermToHistory =
            findPermInArray(roles, permViewChronology) ||
            findPermInArray(roles, permTaskChronology) ||
            findPermInArray(roles, permFilesChronology) ||
            findPermInArray(roles, permInvoiceChronology) ||
            (!this.checkIfHasPermToTasks() && tasksList.length > 0);
        const hasOwnTasks = !this.checkIfHasPermToTasks() && tasksList.length > 0;
        return isLoadingDetails || isLoadingTasks ? (
            <Preloader/>
        ) : hasPermToSeeDetails ? (
            <>
                {findPermInArray(roles, permViewInfo) && (
                    <BidsInfoComponent
                        handleChangePurchase={this.handleEditingPurchase}
                    />
                )}

                <div className="transport-chronology">
                    <div className="left-column">
                        {hasPermToHistory && !isLoadingDetails && <History hasOwnTasks={hasOwnTasks}/>}
                    </div>
                    <div className="right-column">
                        {findPermInArray(roles, permViewTransport) && (
                            <Transport handleSubmit={this.handleEditingPurchase}
                                       deleteTask={(id, removedTitle) => this.setState({
                                           currTask: id,
                                           removedTitle,
                                           deleteTaskModal: true
                                       })}
                                       editTask={(id, onlyChangeResponsive) => this.setState({
                                           currTask: id,
                                           onlyChangeResponsive: onlyChangeResponsive,
                                           editModalOpen: true,
                                       })}/>
                        )}
                        {findPermInArray(roles, permViewInbound) && (
                            <InboundSection
                                idOrder={idOrder}
                                handleChangePurchase={this.handleEditingPurchase}
                                detailsWrapper={detailsWrapper}
                                deleteTask={(id, removedTitle) => this.setState({
                                    currTask: id,
                                    removedTitle,
                                    deleteTaskModal: true
                                })}
                                editTask={(id, onlyChangeResponsive) => this.setState({
                                    currTask: id,
                                    onlyChangeResponsive: onlyChangeResponsive,
                                    editModalOpen: true,
                                })}
                            />
                        )}

                        {findPermInArray(roles, permFinanceInfo) && (
                            <AccountingSection
                                handleSubmit={this.handleEditingPurchase}
                                deleteTask={(id, removedTitle) => this.setState({
                                    currTask: id,
                                    removedTitle,
                                    deleteTaskModal: true
                                })}
                                editTask={(id, onlyChangeResponsive) => this.setState({
                                    currTask: id,
                                    onlyChangeResponsive: onlyChangeResponsive,
                                    editModalOpen: true,
                                })}
                            />
                        )}

                        <Modal
                            Content={() => <EditTask
                                createFromPo={true}
                                idTask={currTask}
                                onlyChangeResponsive={onlyChangeResponsive}
                                handleSubmit={this.handleEditTask}
                                handleCancel={() => this.setState({
                                    editModalOpen: false
                                })}
                            />}
                            open={editModalOpen}
                            isFooter={false}
                            handleClose={() => this.setState({
                                editModalOpen: false
                            })}
                            options={{
                                centered: true
                            }}
                            classNameModal="modal-800"
                        />
                        <CustomModalWithChildren
                            open={deleteTaskModal}
                            handleConfirm={this.handleDeleteTask}
                            handleCancel={() => this.setState({
                                deleteTaskModal: false,
                                currTask: null
                            })}
                            handleClose={() => this.setState({
                                deleteTaskModal: false,
                                currTask: null
                            })}
                            options={{
                                centered: true
                            }}
                        >
                            <ConfirmModalContent
                                text={`delete "${removedTitle}" ticket`}
                            />
                        </CustomModalWithChildren>
                    </div>
                </div>

                {findPermInArray(roles, permEditInfo) && <RemoveBidModal/>}
                {/*<ProductList/>*/}
            </>
        ) : (
            <div className="no-access-details">Access denied</div>
        );
    }

    handleDeleteTask = () => {
        const { deleteTaskAction } = this.props
        const {currTask} = this.state
        this.setState({
            deleteTaskModal: false,
            currTask: null
        }, () => {
            deleteTask(currTask)
                .then(res => {
                    notify('success', 'Ticket successfully deleted')
                    deleteTaskAction(currTask)
                })
        })
    }

    handleEditTask = values => {
        const { currTask } = this.state
        const { editTaskAction, changeDetailsAction, activeTab, getHistory } = this.props;
        values.poUrl = window.location.origin + '/atw/purchase-orders';

        if (values.inbound) {
            values.inboundDueDate = values.dueDate;
            values.inboundDescription = values.description;
        } else if (values.transport) {
            values.transportDueDate = values.dueDate;
            values.transportDescription = values.description;
        } else if (values.accounting) {
            values.accountingDueDate = values.dueDate;
            values.accountingDescription = values.description;
        }

        delete values.responsible
        delete values.order

        console.log('values: ', values);
        this.setState({
            editModalOpen: false,

            currTask: null
        }, () => {
            editTask(values, currTask)
                .then(res => {
                    notify('success', 'Ticket successfully edited');
                    editTaskAction(res.data.task ? res.data.task: {});
                    if (values.inbound) {
                        const id = values.poId;
                        values.userId = values.executorId;
                        values.inboundDueDate = values.dueDate;
                        values.inboundDescription = values.description;
                        createInboundTask(values, id)
                            .then(res => {
                                changeDetailsAction(res.data ? res.data : {});
                                activeTab === '' && getHistory('')
                            })
                            .catch(err => {
                                notify('error', 'Oops something went wrong')
                            })
                    } else if (values.transport) {
                        const id = values.poId;
                        values.transportDueDate = values.dueDate;
                        values.transportDescription = values.description;
                        values.userId = values.executorId;
                        createTransportTask(values, id)
                            .then(res => {
                                changeDetailsAction(res.data ? res.data : {});
                                activeTab === '' && getHistory('')
                            })
                            .catch(err => {

                                notify('error', 'Oops something went wrong')
                            })
                    } else if (values.accounting) {
                        const id = values.poId;
                        values.accountingDueDate = values.dueDate;
                        values.accountingDescription = values.description;
                        values.userId = values.executorId;
                        createAccountingTask(values, id)
                            .then(res => {
                                changeDetailsAction(res.data ? res.data : {});
                                activeTab === '' && getHistory('')
                            })
                            .catch(err => {

                                notify('error', 'Oops something went wrong')
                            })
                    }
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles,
        tasksList: state.purchaseOrdersReducer.tasksList,
        activeTab: state.purchaseOrdersReducer.activeTabHistory,
        details: state.purchaseOrdersReducer.detailsPurchase,
        isLoadingTasks: state.purchaseOrdersReducer.isLoadingTasks,
        isLoadingDetails: state.purchaseOrdersReducer.isLoadingDetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editTaskAction: obj => dispatch(editTaskAction(obj)),
        deleteTaskAction: id => dispatch(deleteTaskAction(id)),
        getTasks: obj => dispatch(getTasksAction(obj)),
        changeDetailsAction: po => dispatch(changeDetailsAction(po)),
        changeDetails: values => dispatch(changeDetailsAction(values)),
        getHistory: type => dispatch(getHistory(type)),
        getStatus: id => dispatch(getDividingStatus(id)),
        getDetails: id => dispatch(getDetail(id)),
        changeActiveRow: idRow => dispatch(setActiveRow(idRow)),
        resetDetailsState: () => dispatch(resetDetailsState()),
        isChangeDividingStatus: isChangeStatus =>
            dispatch(isChangeDividingStatus(isChangeStatus)),
        setLoading: isLoad => dispatch(loadingDetailAction(isLoad)),
        setHeaderData: obj => dispatch(setData(obj)),
        setPoInboundingTypes: list => dispatch(setPoInboundingTypes(list)),
        setProductsList: pl => dispatch(setProductsList(pl)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoOrder));
