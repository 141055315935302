export const formatNumber = num => {
    return !isNaN(num) ? parseFloat(num).toFixed(2) : '0.00'
}

export const formatInteger = num => {
    return num ? !isNaN(num) ? parseInt(num) : '0' : '0'
}

export const formatIntegerWithEmptyString = num => {
    return num!== '' && num !== null ? !isNaN(num) ? parseInt(num) : '' : ''
}

export const formatPrice = num => {
    return num ? !isNaN(num) ? parseFloat(num).toFixed(2) : '0.00' : '0.00'
}

export const formatPriceWithEmptyString = num => {
    return num!=='' && num !==null ? !isNaN(num) && num >= 0 ? parseFloat(num).toFixed(2) : '' : ''
}

export const formatPercent = num => {
    return !isNaN(num) ? parseFloat(num).toFixed(2) > 100 ? '100.00' : parseFloat(num).toFixed(2) : '0.00'
}
export const formatPercentWithEmptyString = num => {
    return num !== '' ? !isNaN(num) ? parseFloat(num).toFixed(2) > 100 ? '100.00' : parseFloat(num).toFixed(2) : '' : ''
}

export const toCapitalize = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatIntegerPlus = num => {
    return num!== '' && num !== null ? !isNaN(num) && num >= 0 ? parseInt(num) : '' : ''
}


export const convertPOInboundReportSocketData = data => {
    return data.map(pack => ({
            ...pack,
            boxes: !pack.boxes ? [] : pack.boxes.map(box => ({
                ...box,
                itemCount: box.count,
                productsCount: box.count,
                closed: box.status,
                products: !box.products ? [] : box.products.map(product => ({
                    product: {
                        ...product,
                        styleNumber: product.sku
                    },
                    itemsCount: product.items
                }))
            }))
        }))
}

export const thousandSeparator = (num, separator = ',') => {
    return num ? !isNaN(num) ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator) : '0.00' : '0.00';
};
