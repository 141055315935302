import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {Route} from 'react-router-dom';
import {setData} from '../../../../actions/admin-header';
import Table from '../../../reuseComponent/Table/MaterialTable';
import Header from '../../../reuseComponent/Table/table-head';
import Pagination from '../../../reuseComponent/Table/Pagination'
import Container from '../../../reuseComponent/Table/container';
import { changeRoute, changeRoutePush } from '../../../../reuseFunctions/changeRoute';
import {ReactComponent as ClosedBox} from '../../../../assets/images/closed_box.svg';
import {history} from '../../../../history';
import adminsInstance from '../../../instances/admininstance';
import {url} from '../../../../constants';
import Toolbar from './Toolbar';
import Details from '../details';
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import IconButtonMaterial from '../../../reuseComponent/Buttons/ButtonWithMaterialIcon';
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import Tooltip from '../../../reuseComponent/Tooltips';
import moment from 'moment'
import Modal from '../../../reuseComponent/modal/modalWithChildren'
import CreatePallet from '../create'
import { deletePallet } from '../../../../services/inventory'
import { notify } from '../../../reuseComponent/toast'
import {permEditInventory} from '../../../../permissions/inventory';
import {setup} from "../../../../actions/printerActions";
import withTracker from "../../../reuseComponent/GoogleAnalytics";
import axios from 'axios'
import { setStatusesListAction, setPackTypesListAction, setPlanAction } from '../../../../actions/inventory'
import { InventoryTabs } from '../InventoryTabs'
import { INVENTORY_TABS } from '../constants'
import { PlanAPI } from '../../../../services/plan'
import { defaultServerError } from '../../../../reuseFunctions/toasts'
import qs from 'qs'
import {listenScanning} from '../scanning';

class InventoryList extends PureComponent {

    scanningListener = null

    state = {
        // currentStatus: "",
        currentPallet: '',
        modalRemovePallet: false,
        modalCreate: null,
        isLoading: false,
        barcode: '',
        termCondition: [],
        prevTermCondition: [],
    };

    tableRef = React.createRef();

    generateColumns = () => {
        const {roles} = this.props;
        return [
            // {
            //     title: 'Status',
            //     filed: 'palletStatus',
            //     sorting: false,
            //     headerStyle: {
            //         width: '50px',
            //     },
            //     render: rowData => rowData.palletStatus ?
            //       <Tooltip text={rowData.palletStatus.name}>
            //           <div className="pack-status-icon__wrapper" dangerouslySetInnerHTML={{__html: rowData.palletStatus.icon}}></div>
            //       </Tooltip>

            //       : null
            // },
            {
                title: '#',
                fiels: 'palletId',
                sorting: false,
                render: rowData => {
                    return <div className="table-cell-th">{rowData.id === 'new' ? '' : rowData.id}</div>;
                }
            },
            {
                title: 'Created',
                fiels: 'createdAt',
                sorting: false,
                render: rowData => {
                    return <span>{rowData.createdAt ? moment(rowData.createdAt, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm') : 'New pallet'}</span>
                }
            },
            {
                title: (
                    <Tooltip text="Closed boxes">
                        <ClosedBox/>
                    </Tooltip>
                ),
                fiels: 'amountOfBoxes',
                sorting: false,
                headerStyle: {
                    textAlign: 'right'
                },
                cellStyle: {
                    textAlign: 'right'
                },
                render: rowData => {
                    return <div className="table-cell-th" style={{
                        paddingRight:7
                    }}>{rowData.amountOfBoxes}</div>;
                }
            },
            {
                title: (
                    <Tooltip text="Total items">
                        <svg style={{width: '21px', height: '21px'}} viewBox="0 0 24 24">
                            <path
                                fill="currentColor"
                                d="M20.5,11H19V7C19,5.89 18.1,5 17,5H13V3.5A2.5,2.5 0 0,0 10.5,1A2.5,2.5 0 0,0 8,3.5V5H4A2,2 0 0,0 2,7V10.8H3.5C5,10.8 6.2,12 6.2,13.5C6.2,15 5,16.2 3.5,16.2H2V20A2,2 0 0,0 4,22H7.8V20.5C7.8,19 9,17.8 10.5,17.8C12,17.8 13.2,19 13.2,20.5V22H17A2,2 0 0,0 19,20V16H20.5A2.5,2.5 0 0,0 23,13.5A2.5,2.5 0 0,0 20.5,11Z"
                            ></path>
                        </svg>
                    </Tooltip>
                ),
                fiels: 'amountOfItems',
                sorting: false,
                headerStyle: {
                    textAlign: 'right'
                },
                cellStyle: {
                    textAlign: 'right'
                },
                render: rowData => {
                    return <div className="table-cell-th" style={{
                        paddingRight:7
                    }}>{rowData.amountOfItems}</div>;
                }
            },
            {
                title: 'CG1',
                field: 'cg1',
                sorting: false,
                render: rowData => {
                    return <div>{rowData.cg1 && rowData.cg1.filter(cg1 => cg1 !== '').join(', ')}</div>;
                }
            },
            {
                title: 'CG2',
                field: 'cg2',
                sorting: false,
                render: rowData => {
                    return <div>{rowData.cg2 && rowData.cg2.filter(cg2 => cg2 !== '').join(', ')}</div>;
                }
            },
            {
                title: 'PO#',
                field: 'orders',
                sorting: false,
                render: rowData => {
                    return rowData.orders
                        && rowData.orders.map(order => <div className="link-po" key={order.id}>
                            <Link
                                onClick={e => e.stopPropagation()}
                                target="_blank"
                                to={`/atw/purchase-orders/${order.id}`}
                            >{order.orderNumber}</Link>
                        </div>)
                }
            },
            {
                title: 'Packs type',
                field: 'palletPackType',
                sorting: false,
                headerStyle: {
                    width: '90px'
                },
                render: rowData => rowData.palletPackType ? <Tooltip text={rowData.palletPackType.name}>
                    <div className="pack-type-icon__wrapper" dangerouslySetInnerHTML={{__html: rowData.palletPackType.icon}}></div>
                </Tooltip> : null
            },
            {
                title: '',
                headerStyle: {
                  width: 32
                },
                field: 'actions',
                sorting: false,
                render: rowData => {
                    return findPermInArray(roles, permEditInventory) &&
                    rowData.id !== 'new' ? (
                        <IconButtonMaterial
                            customClass="show-on-hover"
                            title="Delete"
                            onClick={e => {
                                e.stopPropagation();
                                this.setState({
                                    currentPallet: rowData.id,
                                    modalRemovePallet: true
                                });
                            }}
                        >
                            <svg viewBox="0 0 24 24">
                                <path
                                    fill="#000000"
                                    d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"
                                />
                            </svg>
                        </IconButtonMaterial>
                    ) : null;
                }
            }
        ];
    };


    handleDeletePallet = () => {
        const { currentPallet } = this.state
        this.setState({
            modalRemovePallet: false,
            isLoading: true
        },() => {
            deletePallet(currentPallet)
                .then(res => {
                    this.tableRef.current && this.tableRef.current.onQueryChange()
                    if(this.props.match.params.id && parseInt(this.props.match.params.id) === currentPallet)
                        changeRoutePush('/atw/inventory/archive',history)
                    this.setState({
                        isLoading: false
                    })
                    notify('success','Pallet successfully deleted')
                })
                .catch(err => notify('error','Oops something went wrong'))
        })

    }

    handleShowModalCreate = () => this.setState({modalCreate: true})

    handleHideModalCreate = () => this.setState({modalCreate: false})

    handleSearch = (criterias, value) => {
        if(criterias && criterias.length == 5){
            criterias[5] = "bid"
        } else if(criterias && criterias.length == 6){
            criterias[6] = "bid"
        }
        this.setState(prevState => ({
            termCondition: criterias,
            prevTermCondition: prevState.termCondition
        }), () => {
            this.tableRef.current.onSearchChange(value)
        })
    }

    getIsLoading = () => {
        return this.state.isLoading
    }

    setIsLoading = (isLoading) => {
        this.setState({
            isLoading: isLoading
        })
    }

    componentDidMount() {
        const {setHeaderData, printerSetup, setStatusesListAction, setPackTypesListAction} = this.props;
        setHeaderData({
            LeftPannel: InventoryTabs,
            leftPannelData: {
                alias: INVENTORY_TABS.ARCHIVE
            },
            textAddButton: 'Add',
            handleAdd: null,
            handleSearch: this.handleSearch,
            handleChangeSearchCriterias: this.handleSearch,
            // handleSearch: null
        });

        this.scanningListener = listenScanning('list', this.getIsLoading, this.setIsLoading);

        printerSetup && printerSetup();

        PlanAPI.getPlans({
            page: 0,
            limit: 20,
        })
          .then(res => {
              if (res.data.data) {
                  if (res.data.data.content.length > 0) {
                      PlanAPI.getPlanById(res.data.data.content[res.data.data.content.length - 1].id)
                        .then(planRes => {
                            this.props.setPlanAction(planRes.data.plan)
                        })
                        .catch(defaultServerError)
                  }
              }
          })


        axios.all([adminsInstance.get(`${url}/pallet/status`), adminsInstance.get(`${url}/pallet/pack-type`)])
          .then(axios.spread((statusRes, packTypeRes) => {

              if(statusRes.data){
                  setStatusesListAction(statusRes.data)
              }

              if(packTypeRes){
                  setPackTypesListAction(packTypeRes.data)
              }
          }))
    }

    componentWillUnmount() {
        this.scanningListener();
        this.props.setHeaderData({
            title: null,
            handleAdd: null,
            handleSearch: null,
            handleChangeSearchCriterias: null,
        });
    }

    generateRows = data => {
        return data.map(pallet => {
            return {
                id: pallet.id,
                palletId: pallet.palletId,
                createdAt: pallet.createdAt,
                amountOfBoxes: pallet.amountOfBoxes,
                amountOfItems: pallet.amountOfItems,
                orders: pallet.orders,
                cg1: pallet.info && pallet.info.cg1,
                cg2: pallet.info && pallet.info.cg2,
                palletStatus: pallet.palletStatus,
                palletPackType: pallet.palletPackType
            };
        });
    };

    showHideModal = (modalName, open) => {
        this.setState({
            [modalName]: open
        });
    };

    getPalletList = query => {
        const { termCondition } = this.state
        // if(query.search || termCondition.length === 0 || (termCondition.length !== prevTermCondition.length
        //   || termCondition[0] !== prevTermCondition[0])){
            return new Promise(resolve => {
                adminsInstance
                  .get(`${url}/pallet/archived`, {
                      params: {
                          page: query.page ? query.page : 0,
                          limit: query.pageSize,
                          search: `s:${query.search}`,
                          termCondition: termCondition,
                        //   status: 6
                      },
                      paramsSerializer: (params) =>
                        qs.stringify(params, { arrayFormat: 'repeat' }),
                  })
                  .then(res => {
                      resolve({
                          // data: [],
                          data: this.generateRows(res.data.data.content),
                          page: query.page,
                          totalCount: res.data.data.totalElements
                          // totalCount: 0
                      });
                  });
            });
        // }
    };

    cancelHandler = () => {
        changeRoutePush('/atw/inventory/archive',history)
    }

    render() {
        const {id} = this.props.match.params;
        const { roles, loadingPrinters } = this.props
        const {modalRemovePallet, modalCreate,isLoading, currentPallet} = this.state;
        return (
            <>
                <div
                    className={`wrap-table-with-details-panel inventory-table ${
                        !id ? '' : 'with-details'
                        }`}
                >
                    <div className="table-block wrapper-table">
                        <Table
                            components={{
                                Header: Header,
                                Container: Container,
                                Pagination: Pagination,
                                Toolbar: props => (
                                    <Toolbar
                                        {...props}
                                        handleAdd={findPermInArray(roles,permEditInventory) ? this.handleShowModalCreate : null}
                                    />
                                )
                            }}
                            isLoading={isLoading || loadingPrinters}
                            tableRef={this.tableRef}
                            paginationPosition="toolbar"
                            columns={this.generateColumns()}
                            data={this.getPalletList}
                            onRowClick={(event, rowData) => {
                                event.preventDefault();
                                // eslint-disable-next-line
                                id != rowData.id && changeRoutePush(`/atw/inventory/archive/${rowData.id}`, history);
                            }}
                            options={{
                                maxBodyHeight: 'calc(100vh - 130px)',
                                search: true,
                                searchFieldStyle: {display: 'none'},
                                emptyRowsWhenPaging: false,
                                pageSize: 50,
                                pageSizeOptions: [10, 25, 50],
                                rowStyle: props => {
                                    if (parseInt(id) === props.id || id === props.id)
                                        return {
                                            backgroundColor: '#f2f2f2'
                                        };
                                    else {
                                        return {};
                                    }
                                }
                            }}
                        />
                    </div>
                    <Route
                        exact
                        path="/atw/inventory/archive/:id"
                        render={props => (
                            <Details
                                {...props}
                                cancelCreate={this.cancelHandler}
                                callbackAfterCreation={this.callbackAfterCreation}
                                refetchData={() => this.tableRef.current.onQueryChange()}
                            />
                        )}
                    />
                </div>
                {findPermInArray(roles, permEditInventory) && <Modal
                    open={modalRemovePallet}
                    handleClose={e => this.showHideModal('modalRemovePallet', false)}
                    handleCancel={e => this.showHideModal('modalRemovePallet', false)}
                    handleConfirm={this.handleDeletePallet}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`delete pallet #${currentPallet}`}
                    />
                </Modal>
                }
                {findPermInArray(roles, permEditInventory) && <Modal
                    open={modalCreate}
                    isFooter={false}
                    // handleClose={this.handleHideModalCreate}
                    options={{
                        centered: true,
                        enforceFocus: false,
                        autoFocus: false
                    }}
                >
                    <CreatePallet
                      successCallback={(data) => {
                          if(this.tableRef.current){
                              this.tableRef.current.onQueryChange()
                          }
                          changeRoute(`/atw/inventory/archive/${data.id}`,history)
                          notify('success','Pallet successfully created')
                      }}
                        refetchTable={this.tableRef.current ? this.tableRef.current.onQueryChange : () => {
                        }}
                        handleCancel={this.handleHideModalCreate}
                    />
                </Modal>
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    roles: state.rolesReducer.roles,
    loadingPrinters: state.printersReducer.loading || state.printersReducer.loadingLocationConfiguration
});

export default withTracker(connect(mapStateToProps, {
    setHeaderData: setData,
    printerSetup: setup,
    setStatusesListAction: setStatusesListAction,
    setPackTypesListAction: setPackTypesListAction,
    setPlanAction: setPlanAction,
})(InventoryList));
