import { PURCHASE_ORDERS } from '../constants'


const initState = {
    isLoadingDetails: true,
    fullView: true,
    detailsPurchase: {},
    channel: [],
    commodity: [],
    gender: [],
    quality: [],
    statuses: [],
    financeStatus: [],
    activeRowId: null,
    isUploadingProducts: false,
    isProductShow: false,
    activeTabProducts: null,
    isLoadingHistory: true,
    history: [],
    typeHistory: '',
    isShowCreateContact: false,
    activeTabHistory: 'TASK',
    isShowUploadingSchema: false,
    isLoadingExcelSchema: false,
    schemaExcelUploading: {},
    productsErrors: 0,
    conditionsFilter: [],
    tasksList: [],
    idSection: null,
    isLoadingOverview: false,
    columnsPo: [],
    avg: 0,
    total: 0, // Total e
    totalQuantity: 0, // Total pcs
    avgPcsEuro: 0, // AVG/pcs e
    totalPcsEuro: 0, // Total pcs e
    totalNetKg: 0, // Total Net kg
    avgKgEuro: 0, // Total kg e
    totalKgEuro: 0, // Total net kg
    isLoadingTasks: false,
    tasks: {
        openModalCreate: false,
        openModalEdit: false,
        openModalRemove: false,
        idTask : null
    },
    tableRef: null,
    snapshot: null,
    selectedPackege: null,
    inboundingTypes: [],
}

export const purchaseOrdersReducer = (state = initState,action) => {
    switch (action.type) {
        case PURCHASE_ORDERS.SELECTED_PACKEGE: {
            return {
                ...state,
                selectedPackege: action.payload
            }
        }
        case PURCHASE_ORDERS.SET_SNAPSHOT: {
            return {
                ...state,
                snapshot: action.payload
            }
        }
        case PURCHASE_ORDERS.LOADING_PO_TASKS: {
            return {
                ...state,
                isLoadingTasks: action.payload
            }
        }
        case PURCHASE_ORDERS.SET_COLUMNS_PO:{
            return {
                ...state,
                columnsPo: action.payload
            }
        }
        case PURCHASE_ORDERS.IS_LOADING_PO_OVERVIEW: {
            return {
                ...state,
                isLoadingOverview: action.payload
            }
        }
        case PURCHASE_ORDERS.SET_PO_STATUSES: {
            return {
                ...state,
                statuses: action.payload
            }
        }
        case PURCHASE_ORDERS.SET_TOTAL_AVARAGE_PO: {
            return {
                ...state,
                avg: action.payload.avg,
                total: action.payload.total, // Total e
                totalQuantity: action.payload.totalQuantity, // Total pcs
                avgPcsEuro: action.payload.avgPcsEuro, // AVG/pcs e
                totalPcsEuro: action.payload.totalPcsEuro, // Total pcs e
                totalNetKg: action.payload.totalNetKg, // Total Net kg
                avgKgEuro: action.payload.avgKgEuro, // Total kg e
                totalKgEuro: action.payload.totalKgEuro, // Total net kg

            }
        }
        case PURCHASE_ORDERS.ADD_TASK_TO_PO: {
            return {
                ...state,
                tasksList: [action.payload,...state.tasksList]
            }
        }
        case PURCHASE_ORDERS.EDIT_PO: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case PURCHASE_ORDERS.REMOVE_PO_TASK: {
            return {
                ...state,
                tasksList: state.tasksList.filter(task => task.id !== action.payload)
            }
        }
        case PURCHASE_ORDERS.EDIT_PO_TASK: {
            return {
                ...state,
                tasksList: state.tasksList.map(task => {
                    if(task.id === action.payload.id)
                        return {
                            ...action.payload
                        }
                    return task
                })
            }
        }
        case PURCHASE_ORDERS.SET_TASKS_LIST: {
            return {
                ...state,
                tasksList: action.payload
            }
        }
        case PURCHASE_ORDERS.RESET_FILTER_PRODUCT_LIST: {
            return {
                ...state,
                conditionsFilter: []
            }
        }
        case PURCHASE_ORDERS.SET_FILTER_PRODUCT_LIST: {
            return {
                ...state,
                conditionsFilter: action.payload
            }
        }
        case PURCHASE_ORDERS.REMOVE_PRODUCTS_DELETED_BID: {
            const bid = action.payload
            const newProducts = state.detailsPurchase.productList.products && state.detailsPurchase.productList.products.filter(product => product.bid !== bid)
            const newTotalItem = newProducts.length
            console.log('newProducts',newProducts)
            console.log('bidbidbid',bid)
            const result = {
                ...state,
                detailsPurchase: {
                    ...state.detailsPurchase,
                    totalItems: newTotalItem,
                    productList: {
                        ...state.productList,
                        products: newProducts
                    },
                },
            }
            
            console.log('result',result)
            return result 
        }
        case PURCHASE_ORDERS.SET_PRODUCTS_LIST: {
            console.log('list bid',action.payload)
            return {
                ...state,
                detailsPurchase: {
                    ...state.detailsPurchase,
                    productList: {
                        ...action.payload,
                    },
                },
            }
        }
        case PURCHASE_ORDERS.SET_PRODUCTS_ERRORS: {
            return {
                ...state,
                productsErrors: action.payload
            }
        }
        case PURCHASE_ORDERS.SET_UPLOADING_EXCEL_SCHEMA: {
            return {
                ...state,
                schemaExcelUploading: action.payload
            }
        }
        case PURCHASE_ORDERS.IS_LOADING_UPLOADING_EXCEL_SCHEMA: {
            return {
                ...state,
                isLoadingExcelSchema: action.payload
            }
        }
        case PURCHASE_ORDERS.SHOW_HIDE_UPLOADING_SCHEMA: {
            return {
                ...state,
                isShowUploadingSchema: action.payload
            }
        }
        case PURCHASE_ORDERS.CHANGE_ACTIVE_HISTORY_TAB: {
            return {
                ...state,
                activeTabHistory: action.payload
            }
        }
        case PURCHASE_ORDERS.CHANGE_TASKS_DATA: {
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    ...action.payload
                }
            }
        }
        case PURCHASE_ORDERS.SHOW_HIDE_CREATE_CONTACT_MODAL: {
            return {
                ...state,
                isShowCreateContact: action.payload
            }
        }
        case PURCHASE_ORDERS.CHANGE_FILTER_HISTORY: {
            return {
                ...state,
                typeHistory: action.payload
            }
        }
        case PURCHASE_ORDERS.REMOVE_HISTORY_COMMENT: {
            return {
                ...state,
                history: state.history.filter(item => item.id !== action.payload)
            }
        }
        case PURCHASE_ORDERS.EDIT_HISTORY_COMMENT:{
            return {
                ...state,
                history: state.history.map(item => {
                    if(item.id === action.payload.id){
                        return action.payload
                    }
                    return item
                })
            }
        }
        case PURCHASE_ORDERS.ADD_HISTORY_COMMENT: {
            return {
                ...state,
                history: state.history.concat(action.payload)
            }
        }
        case PURCHASE_ORDERS.ADD_HISTORY_ITEM: {
            return {
                ...state,
                history: state.history.concat(action.payload)
            }
        }
        case PURCHASE_ORDERS.SET_HISTORY_PO: {
            return{
                ...state,
                history: action.payload
            }
        }
        case PURCHASE_ORDERS.IS_LOADING_HISTORY: {
            return {
                ...state,
                isLoadingHistory: action.payload
            }
        }
        case PURCHASE_ORDERS.IS_SHOWING_PRODUCTS_MODAL: {
            return {
                ...state,
                isProductShow: action.payload.isShow,
                activeTabProducts: action.payload.activeTabProducts,
                idSection: action.payload.idSection
            }
        }
        case PURCHASE_ORDERS.IS_UPLOADING_PRODUCTS:{
            return {
                ...state,
                isUploadingProducts: action.payload
            }
        }
        case PURCHASE_ORDERS.SET_ACTIVE_ROW: {
            return {
                ...state,
                activeRowId: action.payload
            }
        }
        case PURCHASE_ORDERS.SET_LIST_WITH_PARAMETRS: {
            return {
                ...state,
                [action.payload.name] : action.payload.values
            }
        }
        case PURCHASE_ORDERS.CHANGE_VIEW_TABLE: {
            return {
                ...state,
                fullView: action.payload,
                activeRowId : action.payload ? null : state.activeRowId
            }
        }
        case PURCHASE_ORDERS.CHANGE_PURCHASE: {
            return {
                ...state,
                detailsPurchase: {
                    ...action.payload
                }
            }
        }
        case PURCHASE_ORDERS.SET_DETAIL: {
            console.log('action.payload',action.payload)
            return {
                ...state,
                detailsPurchase: {
                    ...state.detailsPurchase,
                    ...action.payload
                }
            }
        }
        case PURCHASE_ORDERS.IS_LOADING_DETAIL: {
            return {
                ...state,
                isLoadingDetails: action.payload
            }
        }
        case PURCHASE_ORDERS.RESET_ALL: {
            return {
                ...initState
            }
        }
        case PURCHASE_ORDERS.ADD_DELIVERY_NOTE: {
            return {
                ...state,
                detailsPurchase: {...state.detailsPurchase, transportFile: [...state.detailsPurchase.transportFile, ...action.payload]}
            }
        }
        case PURCHASE_ORDERS.REMOVE_DELIVERY_NOTE: {
            return {
                ...state,
                detailsPurchase: {
                    ...state.detailsPurchase, transportFile: state.detailsPurchase.transportFile.filter(item => item.id !== action.payload)
                }
            }
        }
        case PURCHASE_ORDERS.SET_TABLE_REF: {
            return {
                ...state,
                tableRef: action.payload
            }
        }
        case PURCHASE_ORDERS.SET_PO_INBOUNDING_TYPES: {
            return {
                ...state,
                inboundingTypes: action.payload
            }
        }
        case PURCHASE_ORDERS.ADD_NOTIFICATION: {
            return state
        }
        default:
            return state
    }
}