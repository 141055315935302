import React from 'react'
import {connect} from 'react-redux'
import {Nav} from 'react-bootstrap'
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute'
import {history} from '../../../../history'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission'
import Tooltip from '../../../reuseComponent/Tooltips';
import {
    permEditRoles,
    permEditUsers,
    permViewHistory, permViewNotifications,
    permViewRoles,
    permViewUsers,
    permViewDepartmentsDictionary,
    permEditDepartmentsDictionary,
} from '../../../../permissions/settings'

import './style.scss';


const TabsSettings = ({roles, alias}) => {
    return (
        <Nav className="settings-tabs" variant="tabs" activeKey={alias}>
            {(findPermInArray(roles, permViewUsers) || findPermInArray(roles, permEditUsers)) &&
            <Nav.Item>
                <Nav.Link onClick={() => changeRoutePush('/atw/settings/users', history)} eventKey="users">
                    {alias === 'users' ? <>
                        <svg className="action-icon header-icon" viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"/>
                        </svg>
                        <h3 className="title-text m-10">Users</h3>

                    </> : <Tooltip
                        text="Users"
                        placement="bottom"
                    >
                        <svg className="action-icon header-icon" viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"/>
                        </svg>
                    </Tooltip>}
                </Nav.Link>
            </Nav.Item>
            }
            {(findPermInArray(roles, permViewRoles) || findPermInArray(roles, permEditRoles)) &&
            <Nav.Item>
                <Nav.Link onClick={() => changeRoutePush('/atw/settings/roles', history)} eventKey="roles">
                    {alias === 'roles' ? <>
                        <svg className="action-icon header-icon" viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,7C13.4,7 14.8,8.1 14.8,9.5V11C15.4,11 16,11.6 16,12.3V15.8C16,16.4 15.4,17 14.7,17H9.2C8.6,17 8,16.4 8,15.7V12.2C8,11.6 8.6,11 9.2,11V9.5C9.2,8.1 10.6,7 12,7M12,8.2C11.2,8.2 10.5,8.7 10.5,9.5V11H13.5V9.5C13.5,8.7 12.8,8.2 12,8.2Z"/>
                        </svg>
                        <h3 className="title-text m-10">Roles</h3>

                    </> : <Tooltip
                        text="Roles"
                        placement="bottom"
                    >
                        <svg className="action-icon header-icon" viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,7C13.4,7 14.8,8.1 14.8,9.5V11C15.4,11 16,11.6 16,12.3V15.8C16,16.4 15.4,17 14.7,17H9.2C8.6,17 8,16.4 8,15.7V12.2C8,11.6 8.6,11 9.2,11V9.5C9.2,8.1 10.6,7 12,7M12,8.2C11.2,8.2 10.5,8.7 10.5,9.5V11H13.5V9.5C13.5,8.7 12.8,8.2 12,8.2Z"/>
                        </svg>
                    </Tooltip>}
                </Nav.Link>
            </Nav.Item>
            }
            {findPermInArray(roles, permViewHistory) && <Nav.Item>
                <Nav.Link onClick={() => changeRoutePush('/atw/settings/activity', history)} eventKey="activity">
                    {alias === 'activity' ? <>
                        <svg className="action-icon header-icon" viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3"/>
                        </svg>
                        <h3 className="title-text m-10">Activity</h3>
                    </> : <Tooltip
                        text="Activity"
                        placement="bottom"
                    >
                        <svg className="action-icon header-icon" viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3"/>
                        </svg>
                    </Tooltip>}
                </Nav.Link>
            </Nav.Item>
            }
            {/*<Nav.Item>*/}
                {/*<Nav.Link*/}
                    {/*onClick={() => changeRoutePush('/atw/settings/app-activity', history)}*/}
                    {/*eventKey="app-activity"*/}
                {/*>*/}
                    {/*{alias === 'app-activity' ? <>*/}
                        {/*<svg className="action-icon header-icon" viewBox="0 0 24 24">*/}
                            {/*<path fill="currentColor"*/}
                                  {/*d="M10.63,14.1C12.23,10.58 16.38,9.03 19.9,10.63C23.42,12.23 24.97,16.38 23.37,19.9C22.24,22.4 19.75,24 17,24C14.3,24 11.83,22.44 10.67,20H1V18C1.06,16.86 1.84,15.93 3.34,15.18C4.84,14.43 6.72,14.04 9,14C9.57,14 10.11,14.05 10.63,14.1V14.1M9,4C10.12,4.03 11.06,4.42 11.81,5.17C12.56,5.92 12.93,6.86 12.93,8C12.93,9.14 12.56,10.08 11.81,10.83C11.06,11.58 10.12,11.95 9,11.95C7.88,11.95 6.94,11.58 6.19,10.83C5.44,10.08 5.07,9.14 5.07,8C5.07,6.86 5.44,5.92 6.19,5.17C6.94,4.42 7.88,4.03 9,4M17,22A5,5 0 0,0 22,17A5,5 0 0,0 17,12A5,5 0 0,0 12,17A5,5 0 0,0 17,22M16,14H17.5V16.82L19.94,18.23L19.19,19.53L16,17.69V14Z"/>*/}
                        {/*</svg>*/}
                        {/*<h3 className="title-text m-10">App activity</h3>*/}
                    {/*</> : <Tooltip*/}
                        {/*text="App activity"*/}
                        {/*placement="bottom"*/}
                    {/*>*/}
                        {/*<svg className="action-icon header-icon" viewBox="0 0 24 24">*/}
                            {/*<path fill="currentColor"*/}
                                  {/*d="M10.63,14.1C12.23,10.58 16.38,9.03 19.9,10.63C23.42,12.23 24.97,16.38 23.37,19.9C22.24,22.4 19.75,24 17,24C14.3,24 11.83,22.44 10.67,20H1V18C1.06,16.86 1.84,15.93 3.34,15.18C4.84,14.43 6.72,14.04 9,14C9.57,14 10.11,14.05 10.63,14.1V14.1M9,4C10.12,4.03 11.06,4.42 11.81,5.17C12.56,5.92 12.93,6.86 12.93,8C12.93,9.14 12.56,10.08 11.81,10.83C11.06,11.58 10.12,11.95 9,11.95C7.88,11.95 6.94,11.58 6.19,10.83C5.44,10.08 5.07,9.14 5.07,8C5.07,6.86 5.44,5.92 6.19,5.17C6.94,4.42 7.88,4.03 9,4M17,22A5,5 0 0,0 22,17A5,5 0 0,0 17,12A5,5 0 0,0 12,17A5,5 0 0,0 17,22M16,14H17.5V16.82L19.94,18.23L19.19,19.53L16,17.69V14Z"/>*/}
                        {/*</svg>*/}
                    {/*</Tooltip>}*/}
                {/*</Nav.Link>*/}
            {/*</Nav.Item>*/}
            {findPermInArray(roles, permViewNotifications) &&
            <Nav.Item>
                <Nav.Link onClick={() => changeRoutePush('/atw/settings/notifications', history)}
                          eventKey="notifications">
                    {alias === 'notifications' ? <>
                        <svg className="action-icon header-icon" viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"/>
                        </svg>
                        <h3 className="title-text m-10">Notifications</h3>
                    </> : <Tooltip
                        text="Notifications"
                        placement="bottom"
                    >
                        <svg className="action-icon header-icon" viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"/>
                        </svg>
                    </Tooltip>}
                </Nav.Link>
            </Nav.Item>}
            {(findPermInArray(roles, permViewDepartmentsDictionary) || findPermInArray(roles, permEditDepartmentsDictionary)) &&
                <Nav.Item>
                    <Nav.Link onClick={() => changeRoutePush('/atw/settings/departments', history)} eventKey="departments">
                        {alias === 'departments' ? <>
                        <svg className="action-icon header-icon" viewBox="0 0 24 24">
                            <path fill="#000000" d="M12,3L2,12H5V20H19V12H22L12,3M12,8.75A2.25,2.25 0 0,1 14.25,11A2.25,2.25 0 0,1 12,13.25A2.25,2.25 0 0,1 9.75,11A2.25,2.25 0 0,1 12,8.75M12,15C13.5,15 16.5,15.75 16.5,17.25V18H7.5V17.25C7.5,15.75 10.5,15 12,15Z" />
                        </svg>
                            <h3 className="title-text m-10">Departments</h3>

                        </> : <Tooltip
                            text="Departments"
                            placement="bottom"
                        >
                            <svg className="action-icon header-icon" viewBox="0 0 24 24">
                                <path fill="#000000" d="M12,3L2,12H5V20H19V12H22L12,3M12,8.75A2.25,2.25 0 0,1 14.25,11A2.25,2.25 0 0,1 12,13.25A2.25,2.25 0 0,1 9.75,11A2.25,2.25 0 0,1 12,8.75M12,15C13.5,15 16.5,15.75 16.5,17.25V18H7.5V17.25C7.5,15.75 10.5,15 12,15Z" />
                            </svg>
                        </Tooltip>}
                    </Nav.Link>
                </Nav.Item>}    
        </Nav>
    )
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps, null)(TabsSettings)