import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import UndoItem from './undo-item'


class UndoList extends PureComponent{
    render(){

        const {
            orders,
            removeItem,
            handleUndo
        } = this.props
        return (
            <div className="wrapper-undo-list">
                {orders.map(order => <UndoItem
                    handleUndo={handleUndo}
                    key={order.id}
                    order={order}
                    removeItem={removeItem}
                />)}
            </div>
        )
    }
}

export default connect(null,null)(UndoList)