import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import { editTaskAction, deleteTaskAction, changeDetailsAction } from '../../../../../../actions/purchase-orders'
import TaskItem from './task-item/index'
import EditTask from '../../../../tasks/form/edit/index'
import Modal from '../../../../../reuseComponent/modal/index'
import { deleteTask, editTask, createInboundTask, createTransportTask, createAccountingTask } from '../../../../../../services/tasks'
import {notify} from '../../../../../reuseComponent/toast/index'
import {getDepartments} from "../../../../../../services/dictionaries";
import {getUsers} from '../../../../../../actions/users';
import {ConfirmModalContent} from '../../../../../reuseComponent/confirmModalComponent/index';
import CustomModalWithChildren from "../../../../../reuseComponent/modal/modalWithChildren";

class TaskList extends PureComponent{

    state = {
        currTask: null,
        editModalOpen: false,
        deleteTaskModal: false,
        removedTitle: '',
        onlyChangeResponsive: false,
        departments: [],
    }

    handleDeleteTask = () => {
        const { deleteTaskAction } = this.props
        const {currTask} = this.state
        this.setState({
            deleteTaskModal: false,
            currTask: null
        }, () => {
            deleteTask(currTask)
                .then(res => {
                    notify('success', 'Ticket successfully deleted')
                    deleteTaskAction(currTask)
                })
        })
    }

    handleEditTask = values => {
        const { currTask } = this.state
        const { editTaskAction, changeDetailsAction, activeTab, getHistory } = this.props;
        values.poUrl = window.location.origin + '/atw/purchase-orders';

        if (values.inbound) {
            values.inboundDueDate = values.dueDate;
            values.inboundDescription = values.description;
        } else if (values.transport) {
            values.transportDueDate = values.dueDate;
            values.transportDescription = values.description;
        } else if (values.accounting) {
            values.accountingDueDate = values.dueDate;
            values.accountingDescription = values.description;
        }

        delete values.responsible
        delete values.order

        console.log('values: ', values);
        this.setState({
            editModalOpen: false,

            currTask: null
        }, () => {
            editTask(values, currTask)
                .then(res => {
                    notify('success', 'Ticket successfully edited');
                    editTaskAction(res.data.task ? res.data.task: {});
                    if (values.inbound) {
                        const id = values.poId;
                        values.userId = values.executorId;
                        values.inboundDueDate = values.dueDate;
                        values.inboundDescription = values.description;
                        createInboundTask(values, id)
                            .then(res => {
                                changeDetailsAction(res.data ? res.data : {});
                                activeTab === '' && getHistory('')
                            })
                            .catch(err => {
                                notify('error', 'Oops something went wrong')
                            })
                    } else if (values.transport) {
                        const id = values.poId;
                        values.transportDueDate = values.dueDate;
                        values.transportDescription = values.description;
                        values.userId = values.executorId;
                        createTransportTask(values, id)
                            .then(res => {
                                changeDetailsAction(res.data ? res.data : {});
                                activeTab === '' && getHistory('')
                            })
                            .catch(err => {
                                notify('error', 'Oops something went wrong')
                            })
                    } else if (values.accounting) {
                        const id = values.poId;
                        values.accountingDueDate = values.dueDate;
                        values.accountingDescription = values.description;
                        values.userId = values.executorId;
                        createAccountingTask(values, id)
                            .then(res => {
                                changeDetailsAction(res.data ? res.data : {});
                                activeTab === '' && getHistory('')
                            })
                            .catch(err => {

                                notify('error', 'Oops something went wrong')
                            })
                    }
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })
    }

    componentDidMount(){
        getDepartments()
            .then(res => {
                this.setState({
                    departments: res.data.departments
                })
            })
    }

    render(){
        const { tasksList } = this.props
        const { editModalOpen,currTask,deleteTaskModal, onlyChangeResponsive, departments, removedTitle } = this.state;
        return (
            <div className="tasks-wrapper">

                {tasksList.map(task => <TaskItem
                    departments={departments}

                    deleteTask={(id,removedTitle) => this.setState({
                        currTask: id,
                        removedTitle,
                        deleteTaskModal: true
                    })}
                    editTask={(id, onlyChangeResponsive) => this.setState({
                        currTask: id,
                        onlyChangeResponsive : onlyChangeResponsive,
                        editModalOpen: true,
                })} key={task.id} task={task}/>)}
                <Modal
                    Content={() => <EditTask
                        createFromPo={true}
                        idTask={currTask}
                        onlyChangeResponsive={onlyChangeResponsive}
                        handleSubmit={this.handleEditTask}
                        handleCancel={() => this.setState({
                            editModalOpen: false
                        })}
                    />}
                    open={editModalOpen}
                    isFooter={false}
                    handleClose={() => this.setState({
                        editModalOpen: false
                    })}
                    options={{
                        centered: true
                    }}
                    classNameModal="modal-800"
                />
                <CustomModalWithChildren
                    open={deleteTaskModal}
                    handleConfirm={this.handleDeleteTask}
                    handleCancel={() => this.setState({
                        deleteTaskModal: false,
                        currTask: null
                    })}
                    handleClose={() => this.setState({
                        deleteTaskModal: false,
                        currTask: null
                    })}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`delete "${removedTitle}" ticket`}
                    />
                </CustomModalWithChildren>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        tasksList : state.purchaseOrdersReducer.tasksList,
        details: state.purchaseOrdersReducer.detailsPurchase,
        roles: state.rolesReducer.roles,
    }
}

const mapDispatchToProps = dispatch => ({
    editTaskAction: obj => dispatch(editTaskAction(obj)),
    deleteTaskAction: id => dispatch(deleteTaskAction(id)),
    getUsers: () => dispatch(getUsers()),
    changeDetailsAction: po => dispatch(changeDetailsAction(po)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskList)
