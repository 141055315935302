import React from 'react'
import Slider from 'rc-slider'

import 'rc-slider/assets/index.css';

import CustomTooltip from '../../../reuseComponent/Tooltips'

import { ReactComponent as ZoomInIcon } from '../../../../assets/images/ZoomInIcon.svg'
import { ReactComponent as ZoomOutIcon } from '../../../../assets/images/ZoomOutIcon.svg'
import { ReactComponent as InfoIcon } from '../../../../assets/images/info-outline.svg'

const step = 0.25;

const ScalePanel = ({scale, setScale, width}) => {
  return (
    <div className="scale-panel" style={{width: width}}>
      <CustomTooltip text={'Hold "Ctrl" and scroll to scale'}>
        <InfoIcon className="standard-svg" style={{marginRight: '5px'}}/>
      </CustomTooltip>
      <CustomTooltip text="Zoom out">
        <ZoomOutIcon className="standard-svg" onClick={() => {
          setScale(prevState => prevState > 1 ? prevState - step : prevState)
        }}/>
      </CustomTooltip>
      <Slider
        min={1}
        max={5}
        step={step}
        value={scale}
        onChange={setScale}
        railStyle={{
          background: "#f2f2f2"
        }}
        handleStyle={{
          // height: 16,
          // width: 16,
          // marginLeft: -14,
          // marginTop: -14,
          // backgroundColor: "red",
          border: '2px solid #B0BEC5',
          boxShadow: 'none'
        }}
        trackStyle={{
          background: "#f2f2f2"
        }}
      />
      <CustomTooltip text="Zoom in">
        <ZoomInIcon className="standard-svg" onClick={() => {
          setScale(prevState => prevState < 5 ? prevState + step : prevState)
        }}/>
      </CustomTooltip>
    </div>
  )
}

export { ScalePanel }