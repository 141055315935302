import adminInstance from '../components/instances/admininstance'
import { url } from '../constants'
import { convertToFormData } from '../reuseFunctions/objectToFormData'
import { send } from 'react-ga'

export const getProductByEanFromOrder = (orderId,ean) => {
    return adminInstance.get(`${url}/product/android/get_package_product`,{
        params: {
            ean,
            orderId
        }
    })
}

export const createProduct = (orderId,values) => {
    const data = convertToFormData(values)
    return adminInstance.post(`${url}/product/${orderId}`,data)
}

export const getBoxes = (packageId) => {
    return adminInstance.get(`${url}/package/boxes/${packageId}`)
}

export const getOpenBoxes = (packageId) => {
    return adminInstance.get(`${url}/package/open_boxes/${packageId}`)
}

export const getOpenBoxesByWorkplace = (packageId,workplaceId) => {
    return adminInstance.get(`${url}/package/open_boxes_for_workplace/${packageId}`,{
        params: {
            workplaceId: workplaceId
        }
    })
}

export const getPackages = (orderId) => {
    return adminInstance.get(`${url}/package/info/${orderId}`)
}

export const createCustomPackage = (orderId, values) => {
    const data = convertToFormData(values)
    return adminInstance.post(`${url}/package/${orderId}`, data)
}

export const createCustomPackageWithAmountOfPacks = (orderId, values) => {
    const {
        countPack,
        ...sendData
    } = values
    const data = convertToFormData(sendData)
    return adminInstance.post(`${url}/package/${orderId}/custom_amount`, data, {
        params: {
            countPack
        }
    })
}

export const editCustomPackage = (orderId, packId, values) => {
    const data = convertToFormData(values)
    return adminInstance.put(`${url}/package/${orderId}/${packId}`, data)
}

export const editCustomPackageWithAmountOfPacks = (orderId, packId, values) => {
    const {
        countPack,
        ...sendData
    } = values
    const data = convertToFormData(sendData)
    return adminInstance.put(`${url}/package/${orderId}/custom_amount/${packId}`, data, {
        params: {
            countPack
        }
    })
}

export const removeCustomPackage = (packageId) => {
    return adminInstance.delete(`${url}/package/${packageId}`)
}

export const putProductIntoBox = (values) => {
    const data = convertToFormData(values)
    return adminInstance.post(`${url}/product/android/put_package_product`,data)
}

export const getProductsFromBox = (boxId) => {
    return adminInstance.get(`${url}/product/android/get_products_from_box`, {
        params: {
            boxId: boxId
        }
    })
}

export const editProductInBox = (values) => {
    const data = convertToFormData(values)
    return adminInstance.post(`${url}/product/android/edit_product_in_package`,data)
}

export const closeBox = (boxId) => {
    const data = convertToFormData({boxId: boxId})
    return adminInstance.put(`${url}/box/${boxId}/close`,data)
}

export const openBox = (boxId) => {
    const data = convertToFormData({boxId: boxId})
    return adminInstance.put(`${url}/box/${boxId}/open`,data)
}

export const getScanningHistory = (orderId,params) => {
    return adminInstance.get(`${url}/app_log/${orderId}`,{
        params: params
    })
}

export const getLastScanned = orderId => {
    return adminInstance.get(`${url}/app_log/${orderId}/get_last`)
}

export const scanProduct = ({ean,orderId,isConfirmed, quality}) => {
    return adminInstance.get(`${url}/product/scan_product/${ean}`, {
        params: {
            orderId,
            isConfirmed,
            quality,
        }
    })
}

export const checkScanProduct = ({ean,orderId}) => {
    return adminInstance.get(`${url}/product/scan_product/${ean}/exist`, {
        params: {
            orderId,
        }
    })
}

export const putProductToNewBox = ({idHistoryItem,newBoxId}) => {
    const data = new FormData()
    data.append('newBoxId',newBoxId)
    return adminInstance.put(`${url}/scanning_history/${idHistoryItem}`,data)
}

export const removeScanningHistoryItem = scanningId => {
    return adminInstance.delete(`${url}/scanning_history/${scanningId}`)
}

export const searchProductsInInboundReport = ({ean,orderId}) => {
    return adminInstance.get(`${url}/package/info/by_ean/${orderId}`,{
        params: {
            ean: ean
        }
    })
}

export const editProductsInBox = (data,boxId) => {
    return adminInstance.put(`${url}/product/bulk_edit_in_box/${boxId}`,data)
}

export const checkEditProduct = (values) => {
    const data = convertToFormData(values)
    return adminInstance.post(`${url}/product/android/check_edit_product_in_package`,data)
}