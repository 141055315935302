import React, { useCallback} from 'react'
import {withRouter} from 'react-router-dom'
import Tooltip from '../../../../../reuseComponent/Tooltips'
import { ReactComponent as ClosedBox } from '../../../../../../assets/images/closed_box.svg'
import { checkPackageName } from '../../../../../../reuseFunctions/checkPackageName'
import { ReactComponent as PrinterIcon } from '../../../../../../assets/images/printer.svg'
import { ReactComponent as Items } from '../../../../../../assets/images/item.svg'
import {ReactComponent as PackIcon} from '../../../../../../assets/images/packIcon.svg'
import { getProductsFromBox } from '../../../../../../services/boxes'
import { boxLabel, sendDataToPrinter } from '../../../../../../reuseFunctions/labels'
import { defaultServerError } from '../../../../../../reuseFunctions/toasts'
import { useSelector } from 'react-redux'

const PackItem = withRouter(({box, match, openEditModal}) => {

  if(box != null && box.bid != null && box.bid.number != null && box.bid.number != undefined && box.bid.number != 'undefined'){
    box.bid = box.bid.number
  }

  const printer = useSelector(state => state.printersReducer.selected_device)
  const activePackage = useSelector(state => state.inboundingReportReducer.activePackage)
  const details = useSelector(state => state.purchaseOrdersReducer.detailsPurchase)

  const printBoxLabel = useCallback(() => {
    getProductsFromBox(box.id)
      .then(res => {
        const products = res.data;
        sendDataToPrinter(printer, boxLabel(box, products))
      })
      .catch(err => {
        console.log(err);
        defaultServerError()
      })
  }, [printer, match, box.id])

  return (
    <div className="box__row pack__row">
      <div className="row__header">
        <div
          className={`toggle`}
        >
        </div>
        <div
          className={`info`}
        >
          <div className="left">
            Pack{box.name} | {box.bid ? ((box.bid.number != null && box.bid.number != undefined && box.bid.number != 'undefined') ? box.bid.number : box.bid) : 'No BID selected'}
          </div>

          <div className="right">
            <button disabled={!printer || !box.closed}
                    className="btn btn-primary"
                    onClick={e => {
                      e.stopPropagation();
                      printBoxLabel()
                    }}
            >
              <PrinterIcon className="printer__icon"/>
              Print label
            </button>
            {((details.inboundType === 'Totals' && !details.completeInbound) || (details.inboundType !== 'Totals'))
            && <button
              className="btn btn-primary with-icon"
              onClick={e => {
                e.stopPropagation();
                if(box.bid != null && box.bid.number != null && box.bid.number != undefined && box.bid.number != 'undefined'){
                  box.bid = box.bid.number
                }
                openEditModal(box)
              }}
            >
              <svg viewBox="0 0 24 24">
                <path fill="#000000"
                      d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"/>
              </svg>
              Edit
            </button>}
            <div className="items package-icon">
              <Tooltip text="Items">
                <Items/>
              </Tooltip>
            </div>
            <span className="items-count">
                            {box.item}
                        </span>

          </div>
        </div>
      </div>
    </div>
  )
})

export { PackItem }