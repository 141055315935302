import React, {PureComponent} from 'react';
import {Button} from 'react-bootstrap';

class Diff extends PureComponent {

    render() {
        const {diff, handleCloseModal, chronologyFiledsConverting} = this.props;
        const diffObj = diff ? JSON.parse(diff) : null

        return (
            <>
                {diffObj && <div className="wrap-diff-modal">
                <div className="diff-row header">
                    <div className="field-name">
                        Name
                    </div>
                    <div className="diff-old">
                        Old value
                    </div>
                    <div className="diff-new">
                        New value
                    </div>
                </div>
                {Object.keys(diffObj).map(key => (
                    <div className="diff-row" key={key}>
                        <div className="field-name">
                            {chronologyFiledsConverting[key]}
                        </div>
                        <div className="diff-old">
                            {diffObj[key].old}
                        </div>
                        <div className="diff-new">
                            {diffObj[key].new}
                        </div>
                    </div>
                ))}

                </div>
                }
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
            </>         
        );
    }
}

export default Diff;