import {createBrowserHistory} from "history";

window.history.replaceState(null,'')

export const history = createBrowserHistory()

history.listen((location,action) => {
    window.scrollTo(0,0)
})

