import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {
    getSizeTypes,
    createSizeType,
    editSizeType,
    removeSizeType,
    addRowToSizeSetRequest,
    removeSizeRowRequest
} from '../../../../../actions/size-dictionary'
import Preloader from '../../../../reuseComponent/preloader/preloader'
import Tooltip from '../../../../reuseComponent/Tooltips'
import TypeCell from '../type-cell'
import {Button} from 'react-bootstrap'
import TypeRow from '../type-row'
import Modal from '../../../../reuseComponent/modal';
import CustomModalWithChildren from "../../../../reuseComponent/modal/modalWithChildren";
import {findPermInArray} from '../../../../../reuseFunctions/checkPermission';
import {permEditSizesDictionary} from '../../../../../permissions/dictionaries';
import RemoveModalBodyContent from './modalContent'
import {ConfirmModalContent} from "../../../../reuseComponent/confirmModalComponent";


class SizeTypeComponent extends PureComponent {

    state = {
        modalRemoveType: false,
        currType: null,
        modalRemoveRow: false,
        currRow: null
    }

    handleDeleteType = () => {
        const {currType} = this.state
        const {removeSizeType} = this.props
        this.setState({
            modalRemoveType: false,
            currType: null
        }, () => removeSizeType(currType))

    }
    handleDeleteSizeRow = () => {
        const {currRow} = this.state
        const {removeRow} = this.props
        this.setState({
            modalRemoveRow: false,
            currRow: null
        }, () => removeRow(currRow))
    }

    componentDidMount() {
        const {getTypes} = this.props
        getTypes && getTypes()
    }

    render() {
        const {
            sizeTypes,
            isLoading,
            createSizeType,
            editSizeType,
            activeSet,
            addRow,
            sizeSets,
            roles,
        } = this.props
        const {modalRemoveType, modalRemoveRow} = this.state
        const euType = sizeTypes.find(type => type.value === 'EU')
        const types = sizeTypes.filter(type => type.value !== 'EU')
        const selectedSet = sizeSets.find(set => set.id === activeSet)

        const hasPermToEdit = findPermInArray(roles, permEditSizesDictionary);
        return (

                <div className="types-wrapper">
                    {hasPermToEdit &&

                    <div className="types-row-cell">
                        <Button onClick={() => addRow(activeSet)} className="button-with-icon">
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                            </svg>
                            Add size row
                        </Button>
                    </div>}
                    {isLoading ? <Preloader/> :
                        <div className="wrapper-table-types">
                            <table className="table-types">
                                <thead>
                                <tr className="types-row header-row">
                                    {types.map(type => <th style={{
                                        minWidth: `${100 / types.length}%`,
                                        maxWidth: `${100 / types.length}%`,
                                        width: `${100 / types.length}%`,
                                    }} key={type.id}>
                                        <div className="types-row-cell">
                                            <TypeCell
                                                hasPermToEdit={hasPermToEdit}
                                                type={type}
                                                handleConfirm={(values) => editSizeType(type.id, values)}
                                                removeSizeType={() => {
                                                    this.setState({
                                                        currType: type.id,
                                                        modalRemoveType: true
                                                    })
                                                }}
                                                isType={true}
                                            />
                                        </div>
                                    </th>)}
                                    {euType && <th className="types-row-cell eu-column">
                                        {hasPermToEdit &&
                                        <Tooltip
                                            text="Create size type"
                                        >
                                            <button className="create-size-type-button" onClick={createSizeType}>
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                                                </svg>
                                            </button>
                                        </Tooltip>}
                                        {euType.value}
                                    </th>
                                    }
                                    <th className="types-row-cell remove-row">
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="body-types">
                                {activeSet && String(activeSet).indexOf('set-id') < 0 && <>
                                    {selectedSet ? selectedSet.sizeRow.map(sizeRow => {
                                        return <tr key={sizeRow.id} className="types-row">
                                            <TypeRow
                                                removeRow={() => {
                                                    this.setState({
                                                        modalRemoveRow: true,
                                                        currRow: sizeRow.id
                                                    })
                                                }}
                                                hasPermToEdit={hasPermToEdit}

                                                idRow={sizeRow.id}
                                                sizeValues={sizeRow.sizeValues}
                                            />
                                        </tr>
                                    }) : null}
                                    {/*{hasPermToEdit &&*/}
                                    {/*<tr className="types-row">*/}
                                    {/*<td className="types-row-cell">*/}
                                    {/*<Button onClick={() => addRow(activeSet)} className="button-with-icon">*/}
                                    {/*<svg viewBox="0 0 24 24">*/}
                                    {/*<path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>*/}
                                    {/*</svg>*/}
                                    {/*Add Row*/}
                                    {/*</Button>*/}
                                    {/*</td>*/}
                                    {/*</tr>}*/}

                                </>
                                }
                                </tbody>
                            </table>
                        </div>
                    }
                    {/*: <>
                    <div className="types-row header-row">
                        {types.map(type => <div className="types-row-cell" key={type.id}>
                            <TypeCell
                                hasPermToEdit={hasPermToEdit}
                                type={type}
                                handleConfirm={(values) => editSizeType(type.id, values)}
                                removeSizeType={() => {
                                    this.setState({
                                        currType: type.id,
                                        modalRemoveType: true
                                    })
                                }}
                                isType={true}
                            />
                        </div>)}
                        {euType && <div className="types-row-cell eu-column">
                            {hasPermToEdit &&
                            <Tooltip
                                text="Create size type"
                            >
                                <button className="create-size-type-button" onClick={createSizeType}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                                    </svg>
                                </button>
                            </Tooltip>}
                            {euType.value}
                        </div>
                        }
                        <div className="types-row-cell remove-row">
                        </div>
                    </div>
                    {activeSet && String(activeSet).indexOf('set-id') < 0 && <>
                        <div className="body-types">
                            {selectedSet ? selectedSet.sizeRow.map(sizeRow => {
                                return <TypeRow
                                    removeRow ={() => {
                                        this.setState({
                                            modalRemoveRow: true,
                                            currRow: sizeRow.id
                                        })
                                    }}
                                    hasPermToEdit={hasPermToEdit}
                                    key={sizeRow.id}
                                    idRow={sizeRow.id}
                                    sizeValues={sizeRow.sizeValues}
                                />
                            }) : null}
                            {hasPermToEdit &&
                            <div className="types-row">
                                <div className="types-row-cell">
                                    <Button onClick={() => addRow(activeSet)} className="button-with-icon">
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                                        </svg>
                                        Add Row
                                    </Button>
                                </div>
                            </div>}
                        </div>

                    </>
                    }

                </>}*/}
                    <Modal
                        open={modalRemoveType}
                        Content={RemoveModalBodyContent}
                        handleConfirm={this.handleDeleteType}
                        handleClose={() => {
                            this.setState({
                                modalRemoveType: false,
                                currType: null
                            })
                        }}
                        handleCancel={() => {
                            this.setState({
                                modalRemoveType: false,
                                currType: null
                            })
                        }}
                        options={{
                            centered: true
                        }}
                    />
                    {findPermInArray(roles, permEditSizesDictionary) && <CustomModalWithChildren
                        open={modalRemoveRow}
                        handleConfirm={this.handleDeleteSizeRow}
                        handleClose={() => {
                            this.setState({
                                modalRemoveRow: false,
                                currRow: null
                            })
                        }}
                        handleCancel={() => {
                            this.setState({
                                modalRemoveRow: false,
                                currRow: null
                            })
                        }}
                        options={{
                            centered: true
                        }}
                    >
                        <ConfirmModalContent
                            text={`delete size row`}
                        />
                    </CustomModalWithChildren>}
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.sizeDictionaryReducer.isLoadingSizeTypes,
        sizeTypes: state.sizeDictionaryReducer.sizeTypes,
        activeSet: state.sizeDictionaryReducer.activeSet,
        sizeSets: state.sizeDictionaryReducer.sizeSets,
        roles: state.rolesReducer.roles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTypes: () => dispatch(getSizeTypes()),
        createSizeType: () => dispatch(createSizeType()),
        editSizeType: (id, name) => dispatch(editSizeType(id, name)),
        removeSizeType: id => dispatch(removeSizeType(id)),
        addRow: setId => dispatch(addRowToSizeSetRequest(setId)),
        removeRow: rowId => dispatch(removeSizeRowRequest(rowId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SizeTypeComponent)