import { changeRoutePush } from './changeRoute'
import { history } from '../history'

class SessionTimeout{
    constructor(){
        this.warnTimeout = null
        this.logoutTimeout = null
        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];

        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);
        this.clearTimeout = this.clearTimeout.bind(this)
        this.setTimeout = this.setTimeout.bind(this)
        this.destroy = this.destroy.bind(this)

        for (let i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }
    }

    clearTimeout() {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);

        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    }

    setTimeout() {
        this.warnTimeout = setTimeout(this.warn, 3600 * 1000);

        this.logoutTimeout = setTimeout(this.logout, 3600 * 1000);
    }

    resetTimeout() {
        this.clearTimeout();
        this.setTimeout();
    }

    warn() {
        console.log("You will be logged out");
    }

    logout() {
        window.localStorage.removeItem('userWCS')
        changeRoutePush('/login',history)
    }

    destroy() {
        this.clearTimeout();

        for (let i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }
}

export { SessionTimeout }