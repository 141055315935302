import * as yup from 'yup'

export const schema = yup.object().shape({
    brandColumn: yup.string().required('required field').length(1,'no more than one character'),
    cg1Column: yup.string().required('required field').length(1,'no more than one character'),
    cg2Column: yup.string().required('required field').length(1,'no more than one character'),
    cg3Column: yup.string().required('required field').length(1,'no more than one character'),
    cg4Column: yup.string().required('required field').length(1,'no more than one character'),
    eanColumn: yup.string().required('required field').length(1,'no more than one character'),
    incomeColumn: yup.string().required('required field').length(1,'no more than one character'),
    rrpColumn: yup.string().required('required field').length(1,'no more than one character'),
    seasonColumn: yup.string().required('required field').length(1,'no more than one character'),
    sizeColumn: yup.string().required('required field').length(1,'no more than one character'),
    sizeType: yup.string().required('required field').length(1,'no more than one character'),
    skuColumn: yup.string().required('required field').length(1,'no more than one character'),
    channelColumn: yup.string().required('required field').length(1,'no more than one character'),
    qualityColumn: yup.string().required('required field').length(1,'no more than one character'),
    file: yup.mixed().required('required field')
})