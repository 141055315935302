export const checkPerm = permission => {
    let user = JSON.parse(window.localStorage.getItem('userWCS'))
    if(user)
        return user.privileges.includes(permission) || user.role === 'admin'
    else
        return false
}

export const findPermInArray = (arr,perm)  => {
    const partsPermissions = arr.filter(item => typeof item === 'object')
    const permissions = arr.filter(item => typeof item === 'string')
    let user = JSON.parse(window.localStorage.getItem('userWCS'))
    if(typeof perm === 'string')
        return permissions.includes(perm) || user.role === 'admin'
    else if(typeof perm === 'object')
        return partsPermissions.find(item => item.access === perm.access && item.part === perm.part ) || user.role === 'admin' ? true : false
    else
        return false
}