import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import moment from 'moment'

import Prealoder from '../../../../../../../reuseComponent/preloader/preloader'
import { schema } from './schema'
import BodyForm from './bodyForm'
import adminInstance from '../../../../../../../instances/admininstance'
import { url } from '../../../../../../../../constants'
import { convertToFormData } from '../../../../../../../../reuseFunctions/objectToFormData'
import { notify } from '../../../../../../../reuseComponent/toast/index'
import { addCommentAction,changeTasksData } from '../../../../../../../../actions/purchase-orders'

class FormTask extends PureComponent{

    state = {
        isLoading: false,
        task: {
            deadline: '',
            executorId: '',
            name: '',
            text: ''
        }
    }

    componentDidMount(){
        const { tasks } = this.props
        if(tasks.idTask){
            this.setState({
                isLoading: true
            },() => {
                adminInstance.get(`${url}/task/${tasks.idTask}`)
                    .then(res => {
                        this.setState({
                            isLoading: false,
                            task: {
                                deadline: moment(res.data.deadline).format('DD.MM.YYYY'),
                                executorId: res.data.executor.id,
                                name: res.data.name,
                                text: res.data.text
                            }
                        })
                    })
                    .catch(err => {
                        notify('error','Oops something went wrong')
                    })
            })

        }
    }

    handleSubmit = values => {
        const { details,tasks } = this.props
        if(tasks.idTask){
            this.handleEdit(values)
        }
        else{
            values.poId = details.id
            this.handleCreate(values)
        }
    }

    handleCreate = (values) => {
        const { addItemsToHistory,changeTasksData } = this.props
        values.poUrl = window.location.origin + '/atw/purchase-orders'
        values.taskUrl = window.location.origin + '/atw/tasks/tickets'
        adminInstance.post(`${url}/task`,convertToFormData(values))
            .then(res => {
                addItemsToHistory(res.data)
                changeTasksData({
                    openModalCreate: false,
                    openModalEdit: false,
                    openModalRemove: false,
                    idTask: null
                })
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }

    handleEdit = values => {
        const { tasks,addItemsToHistory,changeTasksData } = this.props
        values.poUrl = window.location.origin + '/atw/purchase-orders'
        values.taskUrl = window.location.origin + '/atw/tasks/tickets'
        adminInstance.put(`${url}/task/${tasks.idTask}`,convertToFormData(values))
            .then(res => {
                addItemsToHistory(res.data)
                changeTasksData({
                    openModalCreate: false,
                    openModalEdit: false,
                    openModalRemove: false,
                    idTask: null
                })
            })
            .catch(err => {
                notify('erorr','Oops something went wrong')
            })
    }



    render(){
        const { isLoading,task } = this.state
        return (
            isLoading ? <Prealoder /> : <Formik
                initialValues={task}
                validationSchema={schema}
                onSubmit={this.handleSubmit}
                render={props => <BodyForm  {...props} />}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        tasks: state.purchaseOrdersReducer.tasks
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addItemsToHistory : (items) => dispatch(addCommentAction(items)),
        changeTasksData: data => dispatch(changeTasksData(data))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(FormTask)

