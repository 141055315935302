import React , { PureComponent } from 'react'
import {ReactComponent as MenuRigth} from '../../../../assets/images/menu-rigth.svg';
import CustomCheckBox from '../../../reuseComponent/checkbox';
import { Collapse} from 'react-bootstrap';
import Tooltip from '../../../reuseComponent/Tooltips';
import {ReactComponent as ItemsIcon} from '../../../../assets/images/item.svg';
import Preloader from '../../../reuseComponent/preloader/preloader';
import PalletProductsTable from './productsTable';
import { OfferApi } from '../../../../services/offers'

class PalletCollapse extends PureComponent{

    state = {
        open: false,
        isLoading: false,
        products: []
    }

    headerRef = React.createRef()

    handleOpenCloseCollapse = (e) => {
        const checkBox = this.headerRef.current ? this.headerRef.current.querySelector('.checkbox-material') : null
        if (checkBox) {
            if (checkBox && !checkBox.contains(e.target))
                this.setState(state => ({
                    open: !state.open
                }))
        }
        else
            this.setState(state => ({
                open: !state.open
            }))
    }

    getProducts = () => {
        const { pallet } = this.props
        this.setState({
            isLoading: true
        },() => {
            OfferApi.getProductsByPallet(pallet.id)
                .then(res => {
                    // console.log(res.data.data)
                    this.setState({
                        isLoading: false,
                        products: res.data.data.filter(product => product !== null).map(product => ({
                            ...product.product,
                            actualCount: product.itemsCount
                        }))
                    })

                })
        })

    }

    render(){
        const { open,isLoading,products } = this.state
        const { isSelected,handleSelect,pallet,isView } = this.props
        // console.log(pallet)
        return <div className="pallet__row">
            <div className="row__header" ref={this.headerRef} onClick={this.handleOpenCloseCollapse}>
                <div
                    className={`toggle ${open ? 'opened' : ''}`}
                    // onClick={this.handleOpenCloseCollapse}
                >
                    <MenuRigth/>
                </div>
                <div className={`info__header ${open ? 'opened' : ''}`}>
                    {!isView && <div className="checkbox-pallet-wrapper">
                        <CustomCheckBox
                            id={pallet.id}
                            checked={isSelected}
                            handleChange={() => handleSelect(pallet.id)}
                            options={{
                                className: 'checkbox-material',
                            }}
                        />
                    </div>
                    }
                    <div className="title__pallet">
                        Pallet #{pallet.id}
                    </div>
                    <div className="actions">

                    </div>
                    <div className="total-count">
                        <Tooltip text="Items">
                            <ItemsIcon className="standart-svg"/>
                        </Tooltip> {pallet.amountOfItems}
                    </div>
                </div>
            </div>
            <Collapse
                onEntered={this.getProducts}
                onExited={() => this.setState({isLoading: true})}
                in={open}
            >
                <div>
                    <div className="row__content">
                        <div className="details__line">
                            <div className="line"></div>
                        </div>
                        <div className="content">
                            {isLoading
                                ? <Preloader/>
                                : <PalletProductsTable
                                    handleSelect={this.handleSelectUnselectProduct}
                                    products={products}
                                />
                            }
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    }
}

export default PalletCollapse