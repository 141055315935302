import React from 'react'
import Tooltip from '../../../reuseComponent/Tooltips'
import {Form} from "react-bootstrap";
import AutosizeInput from "react-input-autosize";

const checkIfErrorExist = (errors,touched,index,field) => {
    return errors.contacts
        && errors.contacts[index]
        && errors.contacts[index][field]
        && touched.contacts
        && touched.contacts[index]
        && touched.contacts[index][field]
}

const getErrorLabel = (errors,touched,index,field) => {
    return errors.contacts
    && errors.contacts[index]
    && errors.contacts[index][field] ? errors.contacts[index][field] : ''
}

const ContactRow = props => {
    const {
        index,
        values,
        touched,
        errors,
        setFieldValue,
        arrayHelpers,
        isView
    } = props
    return (
        <div className="contact-customer-block">
            <Form.Group className="horizontal customer-element-form-wrapper">
                <Form.Label>First name</Form.Label>
                <div className="input-item">
                    <AutosizeInput
                        disabled={isView}
                        type="text"
                        placeholder={!isView ? 'Enter first name' : ''}
                        className="auto-resize-wrapper"
                        name={`contacts[${index}].name`}
                        value={values['contacts'][index].name}
                        onChange={e => setFieldValue(`contacts[${index}].name`, e.target.value)}
                    />
                    <span className="error-label">{checkIfErrorExist(errors,touched,index,'name') && getErrorLabel(errors,touched,index,'name')}</span>
                </div>
            </Form.Group>
            <Form.Group className="horizontal customer-element-form-wrapper">
                <Form.Label>Last name</Form.Label>
                <div className="input-item">
                    <AutosizeInput
                        disabled={isView}
                        type="text"
                        placeholder={!isView ? 'Enter last name' : ''}
                        className="auto-resize-wrapper"
                        name={`contacts[${index}].secondName`}
                        value={values['contacts'][index].secondName}
                        onChange={e => setFieldValue(`contacts[${index}].secondName`, e.target.value)}
                    />
                    <span className="error-label">{checkIfErrorExist(errors,touched,index,'secondName') && getErrorLabel(errors,touched,index,'secondName')}</span>
                </div>
            </Form.Group>
            <Form.Group className="horizontal customer-element-form-wrapper">
                <Form.Label>Phone number</Form.Label>
                <div className="input-item">
                    <AutosizeInput
                        disabled={isView}
                        type="text"
                        placeholder={!isView ? 'Enter phone number' : ''}
                        className="auto-resize-wrapper"
                        name={`contacts[${index}].phone`}
                        value={values['contacts'][index].phone}
                        onChange={e => setFieldValue(`contacts[${index}].phone`, e.target.value)}
                    />
                    <span className="error-label">{checkIfErrorExist(errors,touched,index,'phone') && getErrorLabel(errors,touched,index,'phone')}</span>
                </div>
            </Form.Group>
            <Form.Group className="horizontal customer-element-form-wrapper">
                <Form.Label>Email</Form.Label>
                <div className="input-item">
                    <AutosizeInput
                        disabled={isView}
                        type="email"
                        placeholder={!isView ? 'Enter email' : ''}
                        className="auto-resize-wrapper"
                        name={`contacts[${index}].email`}
                        value={values['contacts'][index].email}
                        onChange={e => setFieldValue(`contacts[${index}].email`, e.target.value)}
                    />
                    <span className="error-label">{checkIfErrorExist(errors,touched,index,'email') &&
                    getErrorLabel(errors,touched,index,'email')}</span>
                </div>
            </Form.Group>
            <Form.Group className="horizontal customer-element-form-wrapper">
                <Form.Label>Position</Form.Label>
                <div className="input-item">
                    <AutosizeInput
                        disabled={isView}
                        type="text"
                        placeholder={!isView ? 'Enter position' : ''}
                        className="auto-resize-wrapper"
                        name={`contacts[${index}].position`}
                        value={values.contacts[index].position}
                        onChange={e => setFieldValue(`contacts[${index}].position`, e.target.value)}
                    />
                    <span className="error-label">{checkIfErrorExist(errors,touched,index,'position') &&
                    getErrorLabel(errors,touched,index,'position')}</span>
                </div>
            </Form.Group>
            {!isView && <Tooltip
                text="Delete"
            >
                <svg
                    onClick={() => arrayHelpers.remove(index)}
                    className="delete-customer-icon"
                    viewBox="0 0 24 24"
                >
                    <path fill="#000000"
                          d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
                </svg>
            </Tooltip>
            }
        </div>
    )
}

export default ContactRow