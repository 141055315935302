import React from 'react';
import {connect} from 'react-redux';
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute';
import {history} from '../../../../history';
import {Nav} from 'react-bootstrap';
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import {
    permEditCg1Dictionary,
    permEditCg2Dictionary,
    permEditCg3Dictionary,
    permEditChannelsDictionary,
    permEditSizesDictionary,
    permViewCg1Dictionary,
    permViewCg2Dictionary,
    permViewCg3Dictionary,
    permViewChannelsDictionary,
    permViewSizesDictionary,
    permViewTaskTemplateDictionary,
    permViewBrandsDictionary,
    permEditBrandsDictionary,
    permViewArticles,
    permEditArticles,
    permViewProductNamesDictionary,
    permEditProductNamesDictionary,
    permEditQualitiesDictionary,
    permViewQualitiesDictionary,
} from '../../../../permissions/dictionaries';

const TabsDictionariesComponent = ({alias, roles}) => {
    return (
        <Nav variant="tabs" activeKey={alias}>
            {(findPermInArray(roles, permViewCg1Dictionary) ||
                findPermInArray(roles, permEditCg1Dictionary)) && (
                <Nav.Item>
                    <Nav.Link
                        eventKey="cg1"
                        onClick={() => changeRoutePush('/atw/dictionaries/cg1', history)}
                    >
                        CG1
                    </Nav.Link>
                </Nav.Item>
            )}
            {(findPermInArray(roles, permViewCg2Dictionary) ||
                findPermInArray(roles, permEditCg2Dictionary)) && (
                <Nav.Item>
                    <Nav.Link
                        eventKey="cg2"
                        onClick={() => changeRoutePush('/atw/dictionaries/cg2', history)}
                    >
                        CG2
                    </Nav.Link>
                </Nav.Item>
            )}
            {(findPermInArray(roles, permViewCg3Dictionary) ||
                findPermInArray(roles, permEditCg3Dictionary)) && (
                <Nav.Item>
                    <Nav.Link
                        eventKey="cg3"
                        onClick={() => changeRoutePush('/atw/dictionaries/cg3', history)}
                    >
                        CG3
                    </Nav.Link>
                </Nav.Item>
            )}
            {(findPermInArray(roles, permViewSizesDictionary) ||
                findPermInArray(roles, permEditSizesDictionary)) && (
                <Nav.Item>
                    <Nav.Link
                        eventKey="sizes"
                        onClick={() => changeRoutePush('/atw/dictionaries/sizes', history)}
                    >
                        Sizes
                    </Nav.Link>
                </Nav.Item>
            )}
            {(findPermInArray(roles, permViewChannelsDictionary) ||
                findPermInArray(roles, permEditChannelsDictionary)) && (
                <Nav.Item>
                    <Nav.Link
                        eventKey="channels"
                        onClick={() =>
                            changeRoutePush('/atw/dictionaries/channels', history)
                        }
                    >
                        Channels
                    </Nav.Link>
                </Nav.Item>
            )}
            {findPermInArray(roles, permViewTaskTemplateDictionary) && (
                <Nav.Item>
                    <Nav.Link
                        eventKey="task-templates"
                        onClick={() =>
                            changeRoutePush('/atw/dictionaries/task-templates', history)
                        }
                    >
                        Ticket templates
                    </Nav.Link>
                </Nav.Item>
            )}
            {(findPermInArray(roles, permEditBrandsDictionary) ||
                findPermInArray(roles, permViewBrandsDictionary)) && (
                <Nav.Item>
                    <Nav.Link
                        eventKey="brands"
                        onClick={() => changeRoutePush('/atw/dictionaries/brands', history)}
                    >
                        Brands
                    </Nav.Link>
                </Nav.Item>
            )}
            {(findPermInArray(roles, permViewArticles) ||
                findPermInArray(roles, permEditArticles)) && (
                <Nav.Item>
                    <Nav.Link
                        eventKey="articles"
                        onClick={() =>
                            changeRoutePush('/atw/dictionaries/articles', history)
                        }
                    >
                        Articles
                    </Nav.Link>
                </Nav.Item>
            )}
            {(findPermInArray(roles, permEditProductNamesDictionary) || findPermInArray(roles, permViewProductNamesDictionary)) && (
                <Nav.Item>
                    <Nav.Link
                        eventKey="productNames"
                        onClick={() =>
                            changeRoutePush('/atw/dictionaries/productNames', history)
                        }
                    >
                        Product names
                    </Nav.Link>
                </Nav.Item>
            )}
            <Nav.Item>
                <Nav.Link
                    eventKey="work-places"
                    onClick={() => changeRoutePush('/atw/dictionaries/work-places',history)}
                >
                    Workplaces
                </Nav.Link>
            </Nav.Item>

            {(findPermInArray(roles, permEditQualitiesDictionary) || findPermInArray(roles, permViewQualitiesDictionary)) && (
            <Nav.Item>
                <Nav.Link
                    eventKey="qualities"
                    onClick={() => changeRoutePush('/atw/dictionaries/qualities',history)}
                >
                    Qualities
                </Nav.Link>
            </Nav.Item>
            )}

        </Nav>
    );
};

const mapStateToProps = state => ({
    roles: state.rolesReducer.roles
});

export default connect(mapStateToProps, null)(TabsDictionariesComponent);
