import React ,  { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import adminInstance from '../../../../../instances/admininstance'
import { url } from '../../../../../../constants'
import Preloader from '../../../../../reuseComponent/preloader/preloader'


class TableAnalysis extends PureComponent{

    state = {
        data : [],
        isLoading: true
    }

    getData = () => {
        const { details } = this.props
        adminInstance.get(`${url}/analytics/summary/${details.id}/brand`)
            .then(res => {
                const data = res.data.slice(0,res.data.length - 2)
                this.setState({
                    data: data,
                    total: res.data[res.data.length - 2],
                    totalRrp: res.data[res.data.length - 1],
                    isLoading: false
                })
            })
    }

    componentDidMount(){
        const { snapshot } = this.props
        // console.log(JSON.parse(snapshot.analysis))
        if(!snapshot){
            this.getData()
        }
        else{
            const json = JSON.parse(snapshot.analysis)
            this.setState({
                data: json.summaryBrand.filter(row => typeof row === 'object'),
                isLoading: false,
                total: json.summaryBrand[json.summaryBrand.length - 2],
                totalRrp: json.summaryBrand[json.summaryBrand.length - 1],
            })
        }
    }

    render(){
        const { isLoading,data,total,totalRrp } = this.state
        return (
            isLoading ? <Preloader/> : <div className="wrapper-table-analysis">
                <Table size="sm">
                    <thead>
                    <tr>
                        <th>Brand</th>
                        <th>Total Items ({total})</th>
                        <th>Total RRP ({totalRrp.toFixed(2)})</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map(row => {
                        if(typeof row === 'object'){
                            return <tr key={row.label}>
                                <td>{row.label}</td>
                                <td>{row.items}</td>
                                <td>{row.rrp.toFixed(2)}</td>
                            </tr>
                        }
                        return null;
                    })}
                    {data.length > 2 && typeof data[data.length-1] === 'number' &&
                    typeof data[data.length-2] === 'number' &&
                        <tr className="caption-row" key="total">
                            <td>Total</td>
                            <td>{data[data.length-2]}</td>
                            <td>{data[data.length-1].toFixed(2)}</td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        snapshot: state.purchaseOrdersReducer.snapshot
    }
}

export default connect(mapStateToProps,null)(TableAnalysis)