import React from 'react'

const ProductsFromPallet = React.memo(({products}) => {
    return <table className="table table-sm">
        <thead>
        <tr>
            <th>EAN</th>
            <th>SKU</th>
            <th>Size</th>
            <th>CG1</th>
            <th>CG2</th>
            <th>CG3</th>
            <th>Season</th>
            <th>Brand</th>
            <th>Quality</th>
            <th>Quantity</th>
        </tr>
        </thead>
        <tbody>
        {products.map(item => {
                return <tr key={item.id}>
                    <td>{item.ean}</td>
                    <td>{item.styleNumber}</td>
                    <td>{item.size}</td>
                    <td>{item.cg1}</td>
                    <td>{item.cg2}</td>
                    <td>{item.cg3}</td>
                    <td>{item.season}</td>
                    <td>{item.brand}</td>
                    <td>{item.quality}</td>
                    <td>{item.actualCount}</td>
                </tr>
            }
        )}
        </tbody>
    </table>
})

export default ProductsFromPallet