import React, {PureComponent} from 'react'
import {connect} from 'react-redux';
import TableChart from './table-analysis-component'
import TableSkuBrand from './table-analysis-component/tableSkuBrand'
import TableRrpBrand from './table-analysis-component/tableRrpBrand';
import {setData} from '../../../../actions/admin-header';
import Preloader from '../../../reuseComponent/preloader/preloader'
import axios from 'axios'
import adminInstance from '../../../instances/admininstance';
import {url} from '../../../../constants';

class TablesAnalysis extends PureComponent {

    state = {
        data: {},
        isLoading: true
    }

    componentDidMount() {
        const {setHeaderData} = this.props;

        setHeaderData && setHeaderData({
            clearSearch: true
        })

        axios.all([this.getData('cg2', 'cg3'), this.getData('cg2', 'size'), this.getRrpBrand(), this.getSkuBrand()])
            .then(axios.spread((cg2Cg3Res, cg2SizeRes, rrpBrandRes, skuBrandRes) => {
                this.setState({
                    isLoading: false,
                    data: {
                        cg2Cg3: cg2Cg3Res.data,
                        cg2Size: cg2SizeRes.data,
                        rrpBrand: rrpBrandRes.data,
                        skuBrand: skuBrandRes.data
                    }
                })
            }))
    }

    getData = (param1, param2) => {
        const {orderId} = this.props
        return adminInstance.get(`${url}/analytics/summary/${orderId}/${param1}/${param2}`)
    }

    getRrpBrand = () => {
        const {orderId} = this.props
        return adminInstance.get(`${url}/analytics/summary/${orderId}/brand`)
    }

    getSkuBrand = () => {
        const {orderId} = this.props
        return adminInstance.get(`${url}/analytics/summary/${orderId}/styleNumber/brand`)
    }


    componentWillUnmount() {
        const {setHeaderData} = this.props;

        setHeaderData && setHeaderData({
            clearSearch: false
        })
    }


    render() {
        const {data, isLoading} = this.state
        return (
            <div className="tables-chart">
                {isLoading ? <Preloader/> :
                    <>
                        <TableChart data={data.cg2Cg3} param1="cg2" param2="cg3" label="Gender & CG3"/>
                        <TableChart data={data.cg2Size} param1="cg2" param2="size" label="Gender & Sizes"/>
                        <TableSkuBrand data={data.skuBrand}/>
                        <TableRrpBrand data={data.rrpBrand}/>
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        orderId: state.analysisToolReducer.orderId
    }
}

const mapDispatchToProps = dispatch => ({
    setHeaderData: data => dispatch(setData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TablesAnalysis);