import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Tooltip from '../../../../../../reuseComponent/Tooltips'
import { Draggable } from 'react-beautiful-dnd'
import RenameInput from '../../renameInput'

const getStyle = (style, snapshot,provided,activePackage) => {

    if (snapshot.isDragging) {
        return {
            ...style,
            marginTop: -(style.height/2)
        }
    }
    return {
        ...style,
        transitionDuration: `0.001s`,
        transform: `translate(0px, 0px)`,
        boxShadow:  parseInt(provided.draggableProps['data-rbd-draggable-id']) === activePackage ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
        borderColor: parseInt(provided.draggableProps['data-rbd-draggable-id']) === activePackage ? '#80bdff' : 'rgba(0, 0, 0, 0.125)'
    };
}

class PackageCollapseHeader extends PureComponent {
    render(){
        const {open,pack,index,details,status,activePackage, set} = this.props
        return (
            <Draggable
                isDragDisabled={status || details.completeInbound}
                draggableId={pack.id}
                index={index}

            >
                {(provided,snapshot) => {
                    return(
                        <div
                            className="header-collapse-package-wrapper"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getStyle(provided.draggableProps.style, snapshot,provided,activePackage)}
                        >
                            <div className="left-part">
                                <svg className={`${open ? 'open' : ''}`} viewBox="0 0 24 24">
                                    <path fill="#000000" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                                </svg>
                                <RenameInput pack={pack} setObject={set}/>
                            </div>
                            <div className="right-part">
                                <Tooltip
                                    text="Total items"
                                >
                                    <svg className='item-icon' width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M20.5,11H19V7C19,5.89 18.1,5 17,5H13V3.5A2.5,2.5 0 0,0 10.5,1A2.5,2.5 0 0,0 8,3.5V5H4A2,2 0 0,0 2,7V10.8H3.5C5,10.8 6.2,12 6.2,13.5C6.2,15 5,16.2 3.5,16.2H2V20A2,2 0 0,0 4,22H7.8V20.5C7.8,19 9,17.8 10.5,17.8C12,17.8 13.2,19 13.2,20.5V22H17A2,2 0 0,0 19,20V16H20.5A2.5,2.5 0 0,0 23,13.5A2.5,2.5 0 0,0 20.5,11Z"></path></svg>
                                </Tooltip>
                                {pack.totalCount}
                                {/*<span style={{marginLeft: 5,marginRight: 5}}>*/}
                                {/*<Tooltip text="Boxes">*/}
                                    {/*<span role="img" aria-label="package">📦</span>*/}
                                {/*</Tooltip>*/}
                                    {/*{pack.amountOfBoxes}*/}
                            {/*</span>*/}

                            <Tooltip
                                text="Total RRP"
                            >
                                <span className="price">€ {pack.packageRRP.toFixed(2)}</span>
                            </Tooltip>

                            </div>
                        </div>
                    )
                }}
            </Draggable>
        )
    }

}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        statusDividing: state.dividingReducer.statusDividing
    }
}

export default connect(mapStateToProps,null)(PackageCollapseHeader)

