import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PieChart from './pie-chart'
import BarChart from './bar-chart'

class Analysis extends PureComponent{
    render(){
        return (
            <div className="charts-block">
                <div className="top-charts">
                    <PieChart
                        field="cg3"
                    />
                    <PieChart
                        field="cg4"
                    />
                    <PieChart
                        field="brand"
                    />
                    <PieChart
                        field="size"
                    />
                </div>
                <div className="total-charts">
                    <BarChart
                        field="cg1"
                    />
                    <BarChart
                        field="cg2"
                    />
                    <BarChart
                        field="season"
                    />
                </div>
            </div>
        )
    }
}



export default connect(null,null)(Analysis)