import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Form, Col, Button, Row} from 'react-bootstrap'
import Select from '../../../reuseComponent/Select/select-form'
import {getBrands} from '../../../../services/brands'
import AsyncSelect from '../../../reuseComponent/Select/async-select'
import adminInstance from '../../../instances/admininstance'
import {url} from '../../../../constants'
import axios from 'axios'
import Preloader from '../../../reuseComponent/preloader/preloader'
import {getQualities} from '../../../../actions/detailsPo';

class FormBody extends PureComponent {

    state = {
        brands: [],
        isLoading: true,
        simpleParameters: {
            cg1: [],
            cg2: [],
            cg3: []
        }
    }


    componentDidMount() {
        const {values,getQualities} = this.props
        getQualities && getQualities()
        axios.all([
            this.getValuesByAlias('commodity'),
            this.getValuesByAlias('gender')
        ])
            .then(axios.spread((cg1Res, cg2Res, packRes) => {
                this.setState({
                    isLoading: false,
                    simpleParameters: {
                        cg1: cg1Res.data.map(item => ({
                            label: item.value,
                            value: item.value,
                            id: item.id
                        })),
                        cg2: cg2Res.data.map(item => ({
                            label: item.value,
                            value: item.value,
                            id: item.id
                        })),
                        cg3: []
                    },
                },() => {
                    if(values.cg1 && values.cg2){
                        this.getRelatedParams(values.cg1,values.cg2)
                    }
                    else{
                        this.setState({
                            isLoading: false
                        })
                    }

                })
            }))
        if(values.packageId){
            this.getOpenBoxes(values.packageId)
        }
    }

    getValuesByAlias = alias => {
        return adminInstance.get(`${url}/simple_parameter/${alias}`)
    }

    getRelatedParams = (param1, param2) => {
        adminInstance.get(`${url}/simple_parameter/category/related_params`, {
            params: {
                param1: param1,
                param2: param2
            }
        })
            .then(res => {
                const { setFieldValue,values } = this.props
                this.setState({
                    simpleParameters: {
                        ...this.state.simpleParameters,
                        cg3: res.data.map(category => ({
                            value: category.value,
                            label: category.value,
                            id: category.id
                        }))

                    },
                    isLoading: false
                },() => {
                    if(values.cg3 && !this.state.simpleParameters.cg3.find(val => val.value === values.cg3)){
                        setFieldValue('cg3','')
                    }
                })
            })

    }

    getBrands = (inputValue, callback) => {
        getBrands({
            limit: 20,
            page: 0,
            search: inputValue
        })
            .then(res => {
                callback(res.data.brands.map(item => ({
                    value: item.name,
                    label: item.name
                })))
            })
    }



    render() {
        const {
            values,
            setFieldValue,
            handleChange,
            errors,
            touched,
            handleSubmit,
            handleClose,
            valuesOptions
        } = this.props
        const {simpleParameters,isLoading} = this.state

        const cg1Option = simpleParameters.cg1.find(option => option.value === values.cg1)
        const cg2Option = simpleParameters.cg2.find(option => option.value === values.cg2)
        const cg3Option = simpleParameters.cg3.find(option => option.value === values.cg3)
        return (
            <Form noValidate className="edit-article-in-box" onSubmit={handleSubmit}>
                {isLoading && <Preloader />}
                <Row>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG1</Form.Label>
                            <Select
                                options={simpleParameters.cg1}
                                value={cg1Option}
                                errorLabel={errors.cg1}
                                isInvalid={errors.cg1 && touched.cg1}
                                onChange={valObj => {
                                    setFieldValue('cg1', valObj.value)
                                    if (cg2Option) {
                                        this.getRelatedParams(valObj.id, cg2Option.id)
                                    }
                                    else {
                                        this.setState(state => ({
                                            simpleParameters: {
                                                ...state.simpleParameters,
                                                cg3: []
                                            }
                                        }))
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG2</Form.Label>
                            <Select
                                options={simpleParameters.cg2}
                                value={cg2Option}
                                errorLabel={errors.cg2}
                                isInvalid={errors.cg2 && touched.cg2}
                                onChange={valObj => {
                                    setFieldValue('cg2', valObj.value)
                                    if (cg1Option) {
                                        this.getRelatedParams(cg1Option.id, valObj.id)
                                    }
                                    else {
                                        this.setState(state => ({
                                            simpleParameters: {
                                                ...state.simpleParameters,
                                                cg3: []
                                            }
                                        }))
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG3</Form.Label>
                            <Select
                                options={simpleParameters.cg3}
                                value={cg3Option}
                                errorLabel={errors.cg3}
                                isInvalid={errors.cg3 && touched.cg3}
                                onChange={valObj => {
                                    setFieldValue('cg3', valObj.value)
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Brand</Form.Label>
                            <AsyncSelect
                                name="brand"
                                loadOptions={this.getBrands}
                                defaultOptions={true}
                                isInvalid={errors.brand && touched.brand}
                                errorLabel={errors.brand}
                                onChange={obj => setFieldValue('brand', obj.value)}
                                value={values.brand ? {
                                    value: values.brand,
                                    label: values.brand
                                } : null}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Season</Form.Label>
                            <Form.Control
                                name="season"
                                onChange={handleChange}
                                isInvalid={errors.season && touched.season}
                                value={values.season}
                            />
                            <Form.Control.Feedback type="invalid">{errors.season}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Size</Form.Label>
                            <Form.Control
                                name="size"
                                onChange={handleChange}
                                isInvalid={errors.size && touched.size}
                                value={values.size}
                            />
                            <Form.Control.Feedback type="invalid">{errors.size}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Quality</Form.Label>
                            <Select
                                name="quality"
                                value={values.quality ? {
                                    label: values.quality,
                                    value: values.quality
                                } : null}
                                onChange={obj => setFieldValue('quality', obj.value)}
                                options={valuesOptions.qualities}
                                isInvalid={errors.quality && touched.quality}
                                errorLabe={errors.quality}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="12">
                        <Form.Group className="footer-form footer-form-custom">
                            <Button variant="secondary" type="button" onClick={handleClose}>Cancel</Button>
                            <Button variant="primary" type="submit">Save</Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        )
    }
}

const mapStateToProps = state => {
    return {
        valuesOptions: state.poDetailsReducer.valuesOptions,
    }
}

export default connect(mapStateToProps, {
    getQualities
})(FormBody)