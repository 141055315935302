import { url, NOTIFICATIONS } from '../constants'
import adminInstance from '../components/instances/admininstance'
import { notify } from '../components/reuseComponent/toast'
import { convertToFormData } from '../reuseFunctions/objectToFormData'
import { history } from '../history'

const loadingNotificationStatus = isLoading => ({
    type: NOTIFICATIONS.IS_LOADING_NOTIFICATION,
    payload: isLoading
});

const loadingEvents = isLoading => ({
    type: NOTIFICATIONS.IS_LOADING_EVENTS,
    payload: isLoading
});

const setNotificaiton = notification => ({
    type: NOTIFICATIONS.SET_NOTIFICATION,
    payload: {
        name: notification.name,
        subject: notification.subject,
        content: notification.content,
        event: notification.event.id,
        email: notification.email,
        eventObj: notification.event,
    }
});

const setEvents = events => ({
    type: NOTIFICATIONS.SET_EVENTS,
    payload: events
});

const setVariables = variables => ({
    type: NOTIFICATIONS.SET_VARIABLES,
    payload: variables
});

export const setEditor = editor => ({
    type: NOTIFICATIONS.SET_EDITOR,
    payload: editor,
});

export const getNotificationById = (id) => {
    return dispatch => {
        dispatch(loadingNotificationStatus(true))
        adminInstance.get(`${url}/notify/${id}`)
            .then(res => {
                dispatch(setNotificaiton(res.data))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(loadingNotificationStatus(false))
            })
    }
};

export const getEvents = () => {
    return dispatch => {
        dispatch(loadingEvents(true))
        adminInstance.get(`${url}/notify/events`)
            .then(res => {
                dispatch(setEvents(res.data))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(loadingEvents(false))
            })
    }
};

export const getVariables = (values) => {
    return dispatch => {
        adminInstance.get(`${url}/notify/variable?event=${values.event}`)
            .then(res => {
                dispatch(setVariables(res.data))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
};

export const createNotification = ({values = {}}) => {
    return dispatch => {
        adminInstance.post(`${url}/notify`,convertToFormData(values))
            .then(res => {
                notify('success', 'Notification created');
                history.replace(`/atw/settings/notifications`)
            })
            .catch(err => {
                console.log(err)
            })
    }
};

export const editNotification = ({ values = {},id }) => {
    return dispatch => {
        adminInstance.put(`${url}/notify/${id}`,convertToFormData(values))
            .then(res => {
                notify('success', 'Notification edited');
                history.replace(`/atw/settings/notifications`)
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
};

export const removeNotification = ({ id, callback }) => {
    return dispatch => {
        adminInstance.delete(`${url}/notify/${id}`)
            .then(res => {
                callback && callback()
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
};

// export const sendEmail = (values) => {
//     return dispatch => {
//         adminInstance.post(`${url}/notify/send/email`, convertToFormData(values))
//             .then(res => {
//                 notify('success', 'Send notification');
//                 history.replace(`/atw/settings/notifications`)
//             })
//             .catch(err => {
//                 console.log(err)
//             })
//     }
// };

export const sendEmail = (values) => {
    return adminInstance.post(`${url}/notify/send/email`, convertToFormData(values))
};