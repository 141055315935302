import React from 'react'
import {Button, Form} from 'react-bootstrap'

const DepartmentFormBody = props => {
    const {
        handleSubmit,
        handleChange,
        errors,
        touched,
        handleClose,
        setFieldValue,
        values
    } = props
    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onBlur={e => setFieldValue('name',e.target.value.trim())}
                    onChange={handleChange}
                    isInvalid={touched.name && errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Icon</Form.Label>
                <Form.Control
                    as="textarea"
                    name="icon"
                    value={values.icon}
                    onChange={handleChange}
                    isInvalid={touched.icon && errors.icon}
                />
                <Form.Control.Feedback type="invalid">{errors.icon}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="footer-form footer-form-custom">
                <Button variant="secondary"
                        onClick={handleClose}>Cancel</Button>
                <Button variant="primary" type="submit">Save</Button>
            </Form.Group>
        </Form>

    )
}

export default DepartmentFormBody
