import React  from 'react'
import { connect } from 'react-redux'
import DropDownMenu from '../../../../reuseComponent/DropDown'
import { changeRoutePush } from '../../../../../reuseFunctions/changeRoute'
import { history } from '../../../../../history'
import {findPermInArray} from "../../../../../reuseFunctions/checkPermission";
import {permEditCg3Dictionary} from "../../../../../permissions/dictionaries";


const ActionsMenuComponent = ({rowData,handleDelete, roles}) => {

    return (
        findPermInArray(roles, permEditCg3Dictionary) &&
        <DropDownMenu
            ContentMenu={() => {
                return (
                    <ul className='list-unstyled no-margin menu-table-actions'>
                        <li>
                            <a href="/" onClick={e => {
                                e.preventDefault()
                                changeRoutePush(`/atw/dictionaries/cg3/edit/${rowData.id}`,history)
                            }}>Edit</a>
                        </li>
                        <li>
                            <a href="/" onClick={(e) => {
                                e.preventDefault()
                                handleDelete && handleDelete()
                            }}>Delete</a>
                        </li>
                    </ul>
                )
            }}
        >

        </DropDownMenu>
    )
}

const mapStateToProps = state => ({
    roles: state.rolesReducer.roles,
});

export default connect(mapStateToProps,null)(ActionsMenuComponent)