import React  from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import FormRegister from './FormRegister'
import './style/style.scss'


const RegistrationUser = (props) =>{
        return(
            <Container >
                <Row>
                    <Col xs="6" className="center-col">
                        <FormRegister />
                    </Col>
                </Row>
            </Container>
        )
}

export default RegistrationUser