import React from 'react'
import { ArticleRow } from './ArticleRow'

const List = ({articles,showDeleteModal,handleCreate,removeArticle,handleEdit}) => {
    return <div className="list-articles">
        <div className="article-row header_row">
            <div className="article-field ean">
                EAN
            </div>
            <div className="article-field description">
                Description
            </div>
            <div className="article-field actions">

            </div>
        </div>
        {articles.length > 0 ? articles.map(article => {
            const isNew = article.id.toString().indexOf('new-article') >= 0
            return <ArticleRow
                handleCancel={isNew ? removeArticle : null}
                handleConfirm={isNew ? handleCreate : handleEdit}
                showDeleteModal={showDeleteModal}
                key={article.id}
                article={article}
                articles={articles}
                isNew={article.id.toString().indexOf('new-article') >= 0}
            />
        }) : <div className="row-empty">
            No records to display
    </div>}
    </div>
}

export { List }