import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {formatCoordinatesToView} from "../../../../../reuseFunctions/mapUtils";

const PalletDetailInfo = ({pallet}) => {
  return ( <div className="pallet-detail">
    <div className="pallet-detail-row">
      <div className="pallet-detail-col">
        <div className="detail-label">#:</div>
        <div className="detail-val">{pallet.id}</div>
      </div>
      <div className="pallet-detail-col">
        <div className="detail-label">PO#:</div>
        <div className="detail-val">
          {pallet.orders
          && pallet.orders.map(order => <div className="link-po" key={order.id}>
            <Link
              onClick={e => e.stopPropagation()}
              target="_blank"
              to={`/atw/purchase-orders/${order.id}`}
            >{order.orderNumber}</Link>
          </div>)}
        </div>
      </div>
    </div>
    <div className="pallet-detail-row">
      <div className="pallet-detail-col">
        <div className="detail-label">Created:</div>
        <div className="detail-val">
          {pallet.created ? moment(pallet.created, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm')
            : 'New pallet'}
        </div>
      </div>
      <div className="pallet-detail-col">
        <div className="detail-label">Status: </div>
        <div className="detail-val">{pallet.palletStatus ? pallet.palletStatus.name : null}</div>
      </div>
    </div>
    <div className="pallet-detail-row">
      <div className="pallet-detail-col">
        <div className="detail-label">Closed packs:</div>
        <div className="detail-val">{pallet.closedPack}</div>
      </div>
      <div className="pallet-detail-col">
        <div className="detail-label">Packs type:</div>
        <div className="detail-val">
          {pallet.palletPackType ? pallet.palletPackType.name : null}
        </div>
      </div>
    </div>
    <div className="pallet-detail-row">
      <div className="pallet-detail-col">
        <div className="detail-label">Total items:</div>
        <div className="detail-val">{pallet.boxes && pallet.boxes.reduce((sum, b) => b.item + sum, 0)}</div>
      </div>
      <div className="pallet-detail-col">
        <div className="detail-label">Net weight, kg:</div>
        <div className="detail-val">{pallet.netWeight || pallet.netWeight === 0 ? pallet.netWeight.toFixed(2) : null}</div>
      </div>
    </div>
    <div className="pallet-detail-row">
      <div className="pallet-detail-col">
        <div className="detail-label">CG1:</div>
        <div className="detail-val">{pallet.cg1 && pallet.cg1.filter(cg1 => cg1 !== '').join(', ')}</div>
      </div>
      <div className="pallet-detail-col">
        <div className="detail-label">Gross weight, kg:</div>
        <div className="detail-val">{pallet.weight || pallet.weight === 0 ? pallet.weight.toFixed(2) : null}</div>
      </div>
    </div>
    <div className="pallet-detail-row">
      <div className="pallet-detail-col">
        <div className="detail-label">CG2:</div>
        <div className="detail-val">{pallet.cg2 && pallet.cg2.filter(cg2 => cg2 !== '').join(', ')}</div>
      </div>
      <div className="pallet-detail-col">
        <div className="detail-label">Product name:</div>
        <div className="detail-val">{pallet.productName}</div>
      </div>
    </div>
    <div className="pallet-detail-row">
      <div className="pallet-detail-col">
        <div className="detail-label">Season:</div>
        <div className="detail-val">{pallet.season}</div>
      </div>
      <div className="pallet-detail-col">
        <div className="detail-label">BID:</div>
        <div className="detail-val">{pallet.bid !== "invalid" ? pallet.bid : pallet.bids.map(b => b.number).join(', ')}</div>
      </div>
    </div>
    <div className="pallet-detail-row">
      <div className="pallet-detail-col">
        <div className="detail-label">Quality:</div>
        <div className="detail-val">{pallet.quality}</div>
      </div>
      <div className="pallet-detail-col">
        <div className="detail-label">Location:</div>
        <div className="detail-val">{pallet.location ?  formatCoordinatesToView(`${pallet.location.coordinateX}-${pallet.location.coordinateY}`) : ''}</div>
      </div>
    </div>
    <div className="pallet-detail-row">
      <div className="pallet-detail-col">
        <div className="detail-label">Note:</div>
        <div className="detail-val">{pallet.note}</div>
      </div>
    </div>
  </div>)
}

export { PalletDetailInfo }