import React, { PureComponent } from 'react'
import { Collapse } from 'react-bootstrap'

class CustomCollapse extends PureComponent{

    state = {
        open: false,
        isEntered: false
    }

    static defaultProps = {
        Toggle: () => <div>Header</div>,
        Body: () => <div>Body</div>,
        data: {},
        dataBody: {}
    }

    handleChangeCollapse = e => {
        // const  tagName  = e.target.tagName.toLowerCase()
        // if(tagName !== 'i'  && tagName !== 'button')
            this.setState( state => {
                return {
                    open : !state.open
                }
            } )
    }

    render(){
        const { open,isEntered } = this.state
        const { Toggle,Body,data,dataBody } = this.props
        return (
            <div className="collapse-set-item">
                <div className="header-collapse-set-item"
                     onClick={this.handleChangeCollapse}
                >
                    <Toggle
                        {...data}
                        open={open}
                    />
                </div>
                <Collapse onEntered={(e) => {
                    this.setState({
                        isEntered: true
                    })
                }} onExited={e => {
                    this.setState({
                        isEntered: false
                    })
                }} in={open}>
                    <div>
                        <div className="content-collapse">
                            {(open || isEntered) && <Body open={open} {...dataBody}/>}
                        </div>
                    </div>
                </Collapse>

            </div>
        )
    }
}

export default CustomCollapse