import React, {PureComponent, Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import TableUsers from './table'
import adminInstance from '../../../instances/admininstance'
import {convertToFormData} from '../../../../reuseFunctions/objectToFormData'
import {url} from '../../../../constants'
import CustoModal from '../../../reuseComponent/modal'
import ModalContent from './modalCreateContent'
import axios from 'axios'
import {notify} from '../../../reuseComponent/toast'
import {connect} from 'react-redux'
import {loadingAct} from '../../../../actions/users'
import {changeStatusInOut} from "../../../../services/users";
import ModalWithInput from '../../../reuseComponent/modal/modalWithInputConfirm'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import { permDeleteUsers,permInviteUsers } from '../../../../permissions/users'
import withTracker from "../../../reuseComponent/GoogleAnalytics";


class ListUsers extends PureComponent {

    state = {
        users: [],
        pageSize: 10,
        page: 0,
        modalOpen: false,
        modalRemoveOpen: false,
        currUserId: null,
        roles: []
    }

    tableRef = React.createRef()

    handleChangeStatus = id => {
        let obj = {
            userId: id
        }
        adminInstance.post(`${url}/user/change_status`, convertToFormData(obj))
            .then(res => {
                notify('success', 'Status successfully changed')
                if (this.tableRef.current) {
                    const data = this.tableRef.current.state.data;

                    const newData = data.map(item => item.id !== id ? item : {...item, status: !item.status});

                    this.tableRef.current.dataManager.setData(newData);
                    this.tableRef.current.setState({...this.tableRef.current.dataManager.getRenderState()})
                }
            })
    }

    handleSetUserOfficeStatus = (id) => {
        changeStatusInOut(id)
            .then(res => {
                notify('success', 'Status successfully changed')
                if (this.tableRef.current) {
                    const data = this.tableRef.current.state.data;

                    const newData = data.map(item => item.id !== id ? item : {...item, inOffice: !item.inOffice});

                    this.tableRef.current.dataManager.setData(newData);
                    this.tableRef.current.setState({...this.tableRef.current.dataManager.getRenderState()})
                }
            })
    }


    handleRemoveUser = ({inputText}) => {
        const {currUserId} = this.state
        this.setState({
            modalRemoveOpen: false
        }, () => {
            // this.props.loadProcess(true)
            adminInstance.delete(`${url}/user/${currUserId}`, {
                params: {
                    userEmail: inputText
                }
            })
                .then(res => {
                    this.getAllData()
                    this.refetchTable();
                    notify('success', 'User successfully deleted')
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
                .finally(() => {
                    this.setState({
                        loading: false
                    })
                })
        })


    }

    handleClickRemoveBtn = (e, id) => {
        e.preventDefault()
        e.target.blur()
        this.showHideModal('modalRemoveOpen', true)
        this.setState({
            currUserId: id
        })
    }

    showHideModal = (stateField, open) => {
        this.setState({
            [stateField]: open
        })
    }

    handleSubmitForm = (values) => {
        values.url = window.location.origin + '/registration'
        this.setState({
            modalOpen: false
        }, () => {
            let data = convertToFormData(values)
            adminInstance.post(`${url}/user/invite`, data)
                .then(res => {
                    notify('success', 'Invitation successfully sent')
                })
                .catch(err => console.log(err))
        })

    }

    refetchTable = () => {
        this.tableRef.current && this.tableRef.current.onQueryChange()
    };

    getRoles() {
        return adminInstance.get(`${url}/role`)
    }

    getUsers() {
        return adminInstance.get(`${url}/user`)
    }

    getAllData() {
        axios.all([this.getRoles(), this.getUsers()])
            .then(axios.spread((roleData, usersData) => {
                this.setState({
                    roles: roleData.data.map(item => {
                        return {
                            value: item.id,
                            label: item.Name
                        }
                    }),
                    users: usersData.data.users,

                })
            }))
            .catch(err => {

            })
            .finally(() => {
                this.props.loadProcess(false)
            })
    }

    componentDidMount() {
        this.props.loadProcess(true)
        this.getAllData()
    }

    render() {
        const {
            users,
            modalOpen,
            modalRemoveOpen,
            roles,
            currUserId
        } = this.state
        const {rolesPermissions} = this.props
        const userObj = users.find(us => us.id === currUserId)
        return (
            <Fragment>
                <TableUsers
                    handleChangeStatus={this.handleChangeStatus}
                    handleSetUserOfficeStatus={this.handleSetUserOfficeStatus}
                    handleClick={(e) => this.showHideModal('modalOpen', true)}
                    handleShowRemoveModal={this.handleClickRemoveBtn}
                    tableRef={this.tableRef}
                />
                {findPermInArray(rolesPermissions, permInviteUsers) && <CustoModal
                    open={modalOpen}
                    isFooter={false}
                    Content={() => <ModalContent
                        roles={roles}
                        handleSubmit={this.handleSubmitForm}
                        handleCancel={(e) => this.showHideModal('modalOpen', false)}
                    />}

                    handleClose={(e) => this.showHideModal('modalOpen', false)}

                    options={{
                        centered: true
                    }}
                />
                }
                {findPermInArray(rolesPermissions,permDeleteUsers ) && <ModalWithInput
                    textToCompare={userObj ? userObj.username : ''}
                    open={modalRemoveOpen}
                    TextComponent={() => <>
                        <div className="wrap-text">
                            <p>You're going to delete user. Deleted user can not be restored! Are you
                                ABSOLUTELY sure?</p>
                            <p>The action can lead to data loss. Please enter the <span
                                className="text-to-repeat-for-delete">{userObj ? userObj.username : ''}</span> to
                                confirm deleting</p>
                        </div>
                    </>}
                    withReason={false}
                    handleConfirm={this.handleRemoveUser}
                    handleCancel={e => this.showHideModal('modalRemoveOpen', false)}
                    handleClose={e => this.showHideModal('modalRemoveOpen', false)}
                    options={{
                        centered: true
                    }}
                />
                }
            </Fragment>

        )
    }
}


const mapStateToProps = state => {
    return {
        loading: state.usersReducer.loading,
        rolesPermissions: state.rolesReducer.roles
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadProcess: (loading) => dispatch(loadingAct(loading)),
    }
}


export default withTracker(connect(mapStateToProps, mapDispatchToProps)(withRouter(ListUsers)))