import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Formik} from 'formik'
import {schema} from './schema'
import Select from '../../../../reuseComponent/Select/select-form'
import {Form} from 'react-bootstrap'
import Tooltip from '../../../../reuseComponent/Tooltips'
import {
    createNewSizeSetRequest,
    removeSetAction,
    editSizeSetRequest
} from '../../../../../actions/size-dictionary'
import { notify } from '../../../../reuseComponent/toast'
import {findPermInArray} from "../../../../../reuseFunctions/checkPermission";
import {permEditSizesDictionary} from "../../../../../permissions/dictionaries";
import AutosizeInput from "react-input-autosize";

class SetRow extends PureComponent {

    state = {
        isEdit: false
    }

    handleSubmit = (id,values) => {
        const { isCreate,createSet,editSize } = this.props
        if(isCreate){
            createSet && createSet(id,values)
        }
        else{
            this.setState({
                isEdit: false
            },() => editSize && editSize(id,values))
        }

    }

    componentDidUpdate(prevProps){
        const roles = this.props.roles
        const prevRoles = prevProps.roles
        if(JSON.stringify(roles) !== JSON.stringify(prevRoles)){
            if(!findPermInArray(roles,permEditSizesDictionary))
                this.setState({isEdit: false})

        }
    }

    render() {
        const {
            set,
            commodities,
            genders,
            isCreate,
            removeSetAction,
            activeSet,
            onClick,
            handleDeleteSet,
            sizeSets,
            roles,
        } = this.props
        const {isEdit} = this.state
        return (
            <Formik
                onSubmit={values => {
                    let isDuplicate = false
                    sizeSets.filter(sizeSet => {
                        return isCreate
                            ? !(typeof sizeSet.id === 'string' && sizeSet.id.indexOf('set-id-') >= 0)
                            : !(typeof sizeSet.id === 'string' && sizeSet.id.indexOf('set-id-') >= 0) && sizeSet.id !== set.id

                    })
                        .forEach(set => {
                            if(set.brand === values.brand
                                && set.commodityId === parseInt(values.commodityId)
                                && set.genderId === parseInt(values.genderId)
                            )
                                isDuplicate = true
                        })
                    if(!isDuplicate)
                        this.handleSubmit(set.id,values)
                    else
                        notify('error','Set already exists')
                }}
                validationSchema={schema}
                initialValues={set}
                render={props => {
                    const {
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        values,
                        setValues,
                        initialValues,
                    } = props
                    return <Form noValidate onSubmit={handleSubmit} onClick={onClick} className="size-set-form">
                        <div className={`set-row ${activeSet === set.id ? 'active-set' : ''}`}>
                            <div className="column-set-row">
                                <Select
                                    isDisabled={!isCreate && !isEdit}
                                    name="commodityId"
                                    options={commodities}
                                    value={commodities.find(item => item.value === values.commodityId)}
                                    onChange={obj => setFieldValue('commodityId', obj.value)}
                                    isInvalid={touched.commodityId && errors.commodityId}
                                    errorLabel={errors.commodityId}
                                />
                            </div>
                            <div className="column-set-row">
                                <Select
                                    isDisabled={!isCreate && !isEdit}
                                    name="genderId"
                                    options={genders}
                                    value={genders.find(item => item.value === values.genderId)}
                                    onChange={obj => setFieldValue('genderId', obj.value)}
                                    isInvalid={touched.genderId && errors.genderId}
                                    errorLabel={errors.genderId}
                                />
                            </div>
                            <div className="column-set-row input-item brand-col">
                                <AutosizeInput
                                    disabled={!isCreate && !isEdit}
                                    type="text"
                                    placeholder={!isCreate && !isEdit ? '' : 'Enter brand'}
                                    className="auto-resize-wrapper"
                                    name="brand"
                                    value={values.brand}
                                    onChange={handleChange}
                                />
                                <span className="error-label">{touched.brand && errors.brand}</span>
                                {/*<Form.Control*/}
                                {/*    disabled={!isCreate && !isEdit}*/}
                                {/*    name="brand"*/}
                                {/*    onChange={handleChange}*/}
                                {/*    value={values.brand}*/}
                                {/*    isInvalid={touched.brand && errors.brand}*/}
                                {/*/>*/}
                                {/*<Form.Control.Feedback type="invalid">{errors.brand}</Form.Control.Feedback>*/}
                            </div>
                            <div className="column-set-row actions-set">
                                {isCreate
                                    ? <>
                                        <Tooltip
                                            text="Create"
                                        >
                                            <button type="submit" className="icon-set-size-button">
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="#000000"
                                                          d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"/>
                                                </svg>
                                            </button>
                                        </Tooltip>
                                        <Tooltip
                                            text="Cancel"
                                        >
                                            <button onClick={() => removeSetAction(set.id)} type="button" className="icon-set-size-button">
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="#000000"
                                                          d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
                                                </svg>
                                            </button>
                                        </Tooltip>
                                    </>
                                    :
                                    !isEdit
                                        ? (findPermInArray(roles, permEditSizesDictionary) ? <>
                                            <Tooltip
                                                text="Edit"
                                            >
                                                <button onClick={e => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    this.setState({
                                                        isEdit: true
                                                    })
                                                }} type="button" className="icon-set-size-button">
                                                    <svg viewBox="0 0 24 24">
                                                        <path fill="#000000"
                                                              d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"/>
                                                    </svg>
                                                </button>
                                            </Tooltip>
                                            <Tooltip
                                                text="Delete"
                                            >
                                                <button onClick={e => {
                                                    e.stopPropagation()
                                                    handleDeleteSet()
                                                }} type="button" className="icon-set-size-button">
                                                    <svg viewBox="0 0 24 24">
                                                        <path fill="#000000" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                                                    </svg>
                                                </button>
                                            </Tooltip>
                                        </> : null)
                                        : <>
                                            <Tooltip
                                                text="Save"
                                            >
                                                <button type="submit" className="icon-set-size-button">
                                                    <svg viewBox="0 0 24 24">
                                                        <path fill="#000000"
                                                              d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"/>
                                                    </svg>
                                                </button>
                                            </Tooltip>
                                            <Tooltip
                                                text="Cancel"
                                            >
                                                <button onClick={() => {
                                                    this.setState({
                                                        isEdit: false
                                                    },() => setValues(initialValues))
                                                }} type="button" className="icon-set-size-button">
                                                    <svg viewBox="0 0 24 24">
                                                        <path fill="#000000"
                                                              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
                                                    </svg>
                                                </button>
                                            </Tooltip>
                                        </>
                                }
                            </div>
                        </div>
                    </Form>
                }}
            />

        )
    }
}

const mapStateToProps = state => {
    return {
        commodities: state.sizeDictionaryReducer.commodities.map(item => ({
            value: item.id,
            label: item.value
        })),
        genders: state.sizeDictionaryReducer.genders.map(item => ({
            value: item.id,
            label: item.value
        })),
        activeSet: state.sizeDictionaryReducer.activeSet,
        sizeSets: state.sizeDictionaryReducer.sizeSets,
        roles: state.rolesReducer.roles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createSet: (id,values) => dispatch(createNewSizeSetRequest(id,values)),
        removeSetAction: id => dispatch(removeSetAction(id)),
        editSize: (id,values) => dispatch(editSizeSetRequest(id,values))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetRow)