import React, { PureComponent } from 'react'
import {Bar} from 'react-chartjs-2'
import { connect } from 'react-redux'
import adminInstanse from '../../../../instances/admininstance'
import {url} from '../../../../../constants'
import {notify} from '../../../../reuseComponent/toast'
import Preloader from '../../../../reuseComponent/preloader/preloader'


class BarChart extends PureComponent{

    state = {
        data: {},
        isLoading: true
    }

    handleGetData = () => {
        const { orderId , field } = this.props
        adminInstanse.get(`${url}/analytics/total/${orderId}/${field}`,{
            params: {
                field: field
            }
        })
            .then(res => {
                this.setState({
                    isLoading: false,
                    data: {
                        labels: res.data.map(item => item.label),
                        datasets: [{
                            label: '',
                            backgroundColor: 'rgba(255,99,132,0.2)',
                            borderColor: 'rgba(255,99,132,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                            hoverBorderColor: 'rgba(255,99,132,1)',
                            data: res.data.map(item => item.value)
                        }]
                    }
                })
            })
            .catch(err => {
                console.log(err)
                notify('error','Oops something went wrong')
            })
    }

    componentDidMount() {
        this.handleGetData()
    }

    render(){
        const { data,isLoading } = this.state
        const { field } = this.props
        return (
            isLoading ? <Preloader /> : <div className="chart-wrapper">
                <h5>Total by {field}</h5>
                <Bar
                    data={data}
                />
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        orderId: state.analysisToolReducer.orderId,
    }
}

export default connect(mapStateToProps,null)(BarChart)