import { DEPARTMENTS } from '../constants'
import adminInstanse from '../components/instances/admininstance'
import { url } from '../constants'
import { notify } from '../components/reuseComponent/toast'
import { convertToFormData } from '../reuseFunctions/objectToFormData'

const loadingDepartmentAction = isLoading => ({
    type: DEPARTMENTS.IS_LOADING_DEPARTMENT,
    payload: isLoading
})

export const setDepartmentAction = department => ({
    type: DEPARTMENTS.SET_DEPARTMENT,
    payload: department
})

const setDepartmentsAction = departments => ({
    type: DEPARTMENTS.SET_DEPARTMENTS,
    payload: departments,
});

export const getDepartments = () => {
    return dispatch => {
        dispatch(loadingDepartmentAction(true));
        adminInstanse.get(`${url}/department`, {
            params: {
                limit: 100,
                page: 0,
            }
        })
            .then(res => {
                dispatch(setDepartmentsAction(res.data.departments))
            })
            .catch(err => console.log(err))
            .finally(() => dispatch(loadingDepartmentAction(false))
    )
    }
};

export const getDepartmentById = (id) => {
    return dispatch => {
        dispatch(loadingDepartmentAction(true))
        adminInstanse.get(`${url}/department/${id}`)
            .then(res => {
                dispatch(setDepartmentAction(res.data))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(loadingDepartmentAction(false))
            })

    }
}

export const createDepartment = ({values = {},callback}) => {
    return dispatch => {
        dispatch(loadingDepartmentAction(true))
        adminInstanse.post(`${url}/department`,convertToFormData(values))
            .then(res => {
                callback && callback(res)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                dispatch(loadingDepartmentAction(false))
            })
    }
}

export const editDepartment = ({ values = {},callback,idDepartment }) => {
    return dispatch => {
        dispatch(loadingDepartmentAction(true))
        adminInstanse.put(`${url}/department/${idDepartment}`,convertToFormData(values))
            .then(res => {
                callback && callback(res)
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(loadingDepartmentAction(false))
            })
    }
}

export const removeDepartment = ({ id, callback,newDepartmentId }) => {
    return dispatch => {
        adminInstanse.delete(`${url}/department/${id}`,{
            params: newDepartmentId ? {
                newDepartmentId: newDepartmentId
            } : {

            }
        })
            .then(res => {
                callback && callback(res.data)
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}