import React from 'react'
import {Form, Button, Card} from 'react-bootstrap'
import {changeRoute} from "../../reuseFunctions/changeRoute"
import CustomSelect from '../reuseComponent/Select/select-form'

const RegisterUserFormBody = (props) => {
    const {
        handleSubmit,
        handleChange,
        touched,
        errors,
        values,
        notDisableEmail,
        linkCancel,
        history,
        isRole,
        roles = []
    } = props

    return (
        <Card>
            <Card.Body>
                <Form autoComplete='new-password' noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={touched.name && errors.name}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            autoComplete="nope"
                            name="secondName"
                            onChange={handleChange}
                            value={values.secondName}
                            isInvalid={touched.secondName && errors.secondName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.secondName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            autoComplete="off"
                            disabled={notDisableEmail ? false : true}
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            isInvalid={touched.username && errors.username}
                        />
                        <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                    </Form.Group>
                    {isRole
                        ? <Form.Group>
                            <Form.Label>Role</Form.Label>
                            <CustomSelect
                                value={roles.find(role => role.value === values.roleId)}
                                name='roleId'
                                onChange={e => props.setFieldValue('roleId', e.value)}
                                options={roles}
                                isInvalid={touched.roleId && errors.roleId}
                                errorLabel={errors.roleId}
                            />
                        </Form.Group>
                        : null
                    }
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={touched.password && errors.password}
                        />
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="confirm_password"
                            value={values.confirm_password}
                            onChange={handleChange}
                            isInvalid={touched.confirm_password && errors.confirm_password}
                        />
                        <Form.Control.Feedback type="invalid">{errors.confirm_password}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="footer-form footer-modal-delete footer-form-custom">
                        <Button variant="secondary" onClick={(e) => changeRoute(linkCancel, history)}>Cancel</Button>
                        <Button variant="primary" type="submit">Save</Button>
                    </Form.Group>

                </Form>
            </Card.Body>
        </Card>
    )
}

export default RegisterUserFormBody