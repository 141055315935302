import React from 'react'

import { PALLET_PLACE_STROKE } from '../InventoryMap/constants'

const ActiveGridItem = ({
                          gridItem,
                          sizeX,
                          sizeY,
                          scale,
                          handleStartSelection,
                          handleUpdateSelection,
                          handleFinishSelection,
                        }) => {
  return (
    <g
      className="grid-item clickable"
      onMouseDown={() => {
          handleStartSelection(gridItem)
      }}
      onMouseMove={() => {
          handleUpdateSelection(gridItem)
      }}
      onMouseUp={() => {
          handleFinishSelection()
      }}
    >
      <rect
        x={(gridItem.coordinateX-1)*sizeX*scale +  0.5}
        y={((gridItem.coordinateY-1)*sizeY)* scale + 0.5}
        width={(sizeX)*scale - 1}
        height={(sizeY)*scale - 1}
        fill={gridItem.newLocation}
        stroke={PALLET_PLACE_STROKE.HIGHLIGHTED}
        strokeWidth={1}
        opacity={gridItem.isCtrl ? 0.6 : 1}
      />
    </g>
  )
}

export { ActiveGridItem }
