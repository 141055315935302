import {INBOUNDING_REPORT} from "../constants";

const initialState = {
    loadingPackages: false,
    loadingBoxes: false,
    loadingProducts: false,
    packages: [],
    boxes: [],
    report: {},
    activePackage: {},
    searchedPackages: []
};

export const inboundingReportReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case INBOUNDING_REPORT.SET_SEARCHED_PACKAGES: {
            return {
                ...state,
                searchedPackages: payload
            }
        }
        case INBOUNDING_REPORT.CLEAR_INBOUND_REPORT_REDUCER: {
            return {
                ...initialState,
                packages: state.packages,
            }
        }
        case INBOUNDING_REPORT.SET_LOADING_PACKAGES_INBOUNDING:{
            return {
                ...state,
                loadingPackages: payload
            }
        }
        case INBOUNDING_REPORT.SET_LOADING_BOXES_INBOUNDING:{
            return {
                ...state,
                loadingBoxes: payload
            }
        }
        case INBOUNDING_REPORT.SET_PACKAGES_INBOUNDING: {
            return {
                ...state,
                packages: payload.packages,
                report: payload.report,
            }
        }
        case INBOUNDING_REPORT.SET_ACTIVE_PACKAGE_INBOUNDING: {
            return {
                ...state,
                activePackage: payload,
            }
        }
        case INBOUNDING_REPORT.SET_BOXES_INBOUNDING: {
            return {
                ...state,
                boxes: payload
            }
        }
        case INBOUNDING_REPORT.SET_LOADING_PRODUCTS_INBOUNDING: {
            return {
                ...state,
                boxes: state.boxes.map(item => item.id === payload.boxId ? {...item, isLoading: payload.loading} : item)
            }
        }
        case INBOUNDING_REPORT.SET_PRODUCTS_INBOUNDING: {
            return {
                ...state,
                boxes: state.boxes.map(item => item.id === payload.boxId ? {...item, products: payload.products} : item)
            }
        }
        case INBOUNDING_REPORT.UPDATE_REPORT: {
            return {
                ...state,
                report: payload.report,
                packages: payload.packages,
                boxes: payload.boxes ? payload.boxes : state.boxes,
            }
        }
        case INBOUNDING_REPORT.UPDATE_BOX_ITEM_COUNT: {
            return {
                ...state,
                packages: state.packages.map(item => item.id === payload.pack.id ? ({...item,...payload.pack}) : item),
                boxes: state.boxes.map(item => item.id === payload.box.id ? {
                    ...item, 
                    ...payload.box,
                    bid: {
                        ...payload.box.bid,
                        ...payload.bid.bid
                    }
                } : item),
                activePackage: {...state.activePackage, ...payload.pack}
            }
        }
        default:
            return state;
    }
}

