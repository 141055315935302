import adminInstance from '../components/instances/admininstance'
import { url } from '../constants'
import qs from 'qs'
import { convertToFormData } from '../reuseFunctions/objectToFormData'

const getLocationById = (locationId) => {
  return adminInstance.get(`${url}/location/${locationId}`)
}

const getLocationByPlan = (planId, params) => {
  return adminInstance.get(`${url}/location/plan/${planId}/map`, {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  })
}

const getLocationForEditing = (planId) => {
  return adminInstance.get(`${url}/location/plan/${planId}/map/edit`)
}

const editLocationTypes = (planId, locationTypeNew, body) => {
  return adminInstance.post(`${url}/location/plan/${planId}/map/edit`,
    convertToFormData(body) ,
    // null ,
    {
    params: {
      locationTypeNew,
      // coordinates
    },
      timeout: 300000,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  })
}

const getFreeLocationByPlan = (planId, params) => {
  return adminInstance.get(`${url}/location/free/plan/${planId}`, {
    params,
  })
}

export const LocationAPI = {
  getLocationById,
  getLocationByPlan,
  getFreeLocationByPlan,
  getLocationForEditing,
  editLocationTypes,
}