import axios from 'axios'
import {convertToFormData} from '../reuseFunctions/objectToFormData'
import {LOGIN} from '.././constants'
import {history} from '../history'
import {url} from '.././constants'
import {notify} from '../components/reuseComponent/toast'

export const setLoading = () => ({
    type: LOGIN.SET_LOADING,
})

export const setUser = (userData) => ({
    type: LOGIN.SET_USER,
    payload: userData
})

export const logOut = (dispatch) => {
    window.localStorage.removeItem('userWCS')
    dispatch({type: LOGIN.LOG_OUT})
}

const getRoles = (data, dispatch) => {
    let user = data
    window.localStorage.removeItem('userWCS')
    user.createData = (new Date()).getTime()

    axios.get(`${url}/user/privileges`, {
        headers: {
            'Authorization': `Bearer ${data.access_token}`
        }
    })
        .then(result => {
            user.role = result.data.role.alias
            user.privileges = result.data.privileges
            user.user_id = result.data.info.id;
            window.localStorage.setItem('userWCS', JSON.stringify(user))
            dispatch(setUser(user))
            dispatch(setLoading())
            history.push('/atw')
        })
        .catch(err => {
            notify('error', 'Can not get privileges')
        })
}

export const loginRequest = (dispatch, values) => {
    dispatch(setLoading())

    let hash = window.btoa('wcs:3A37A940F361D2148CCCF2D5C7C6B605')
    values['grant_type'] = 'password'
    const data = convertToFormData(values)

    axios.post(`${url}/oauth/token`, data, {
        headers: {
            'Accept': '*/*',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${hash}`,
        }
    })
        .then(res => {
            getRoles(res.data, dispatch)
        })
        .catch(err => {
            dispatch(setLoading())
                axios.get(`${process.env.REACT_APP_API_URL}/system`)
                    .then(res => {
                        if (err.response && err.response.status === 400 && err.response.data &&
                            err.response.data.error_description === 'User is disabled') {
                            notify('error', 'User deactivated')
                        } else if (err.response && err.response.status === 400 && err.response.data &&
                            err.response.data.error_description === 'Bad credentials') {
                            notify('error', 'Incorrect email or password')
                        } else
                            notify('error', 'Authorization Error')
                    })
                    .catch(err => {
                        history.replace('/login')
                        notify('error', 'System is under updating, please wait...')
                    })

        })
}

