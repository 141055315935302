import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Nav} from "react-bootstrap"

import {setData} from "../../../actions/admin-header"
import StandartLeftPanner from "../../reuseComponent/Header/standartLeftPannel"
import {getSavedAnalysisTool, setShowHideUploadSchema} from '../../../actions/analysisTool'
import Preloader from "../../reuseComponent/preloader/preloader"
import UploadingSchemaForm from "./schema-uploading-excel"
import SizeConverter from "./sizeConverter"
import TableProducts from "./table"
import AnalysisCompnent from "./analysis"
import TablesAnalysis from "./analysis/tables"
// import {ConfirmModalContent} from '../../reuseComponent/confirmModalComponent';
import CustomModalWithChildren from "../../reuseComponent/modal/modalWithChildren";

import './style.scss';
import withTracker from "../../reuseComponent/GoogleAnalytics";

class AnalysisTool extends PureComponent{

    state = {
        activeTab: 'product-list',
        isConvert: false,
        count: 0
    }

    tableRef = React.createRef();

    componentDidUpdate(prevProps) {
        if (this.props.activeTabProducts && this.props.activeTabProducts !== prevProps.activeTabProducts) {
            this.setState({
                activeTab: this.props.activeTabProducts
            })
        }
    }


    componentDidMount() {
        const {setHeaderData, getSavedAnalysisTool} = this.props;

        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'Analysis tool',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#CDDC39" d="M11,2V22C5.9,21.5 2,17.2 2,12C2,6.8 5.9,2.5 11,2M13,2V11H22C21.5,6.2 17.8,2.5 13,2M13,13V22C17.7,21.5 21.5,17.8 22,13H13Z" />
                </svg>)),
            },
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            // handleSearch: null,
            Actions: null
        });

        getSavedAnalysisTool();
    }

    handleChangeConvertMode = isConvert => {
        this.setState({
            isConvert,
        })
    }

    handleBlockTabs = count => {
        this.setState({count})
    }

    renderBodyModal = () => {
        const {activeTab} = this.state
        const {isUploading, isVisibleUploadSchema} = this.props
        if (activeTab === 'product-list' && !isUploading) {
            if (isVisibleUploadSchema)
                return <UploadingSchemaForm/>
            else
                return <TableProducts tableRef={this.tableRef} handleChangeConvertMode={this.handleChangeConvertMode} handleBlockTabs={this.handleBlockTabs}/>
        }
        else if (activeTab === 'analysis') {
            return <AnalysisCompnent/>
        }
        else if (activeTab === 'analysis-table') {
            return <TablesAnalysis/>
        }
        return null
    };

    handleChangeTab = eventKey => {
        this.setState({
            activeTab: eventKey
        })
    }

    render() {

        const {
            isLoading,
            orderId,
            isVisibleUploadSchema,
            isImporting
        } = this.props;

        const {
            activeTab,
            isConvert,
            count
        } = this.state;
        return (
            (orderId || isVisibleUploadSchema) &&
            <>
                <div className={`content-header column-3 ${isLoading ? 'disabled' : ''}`}>
                    {!(isConvert || isVisibleUploadSchema) &&
                    <div className="left-part flex-1">
                        <Nav justify variant="tabs" className="tabs-custom " activeKey={activeTab}
                             onSelect={this.handleChangeTab}>
                            <Nav.Item>
                                <Nav.Link eventKey="product-list">
                                    <div className="content-tab">
                                        <span className="tab-name">Product list</span>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link disabled={count === 0} eventKey="analysis">
                                    <div className="content-tab">
                                        <span className="tab-name">Charts</span>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link disabled={count === 0} eventKey="analysis-table">
                                    <div className="content-tab">
                                        <span className="tab-name">Analysis</span>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>}
                </div>
                <div className="content-modal-body">
                    {isLoading || isImporting ? <Preloader
                        text={isImporting ? 'Uploading' : 'Loading'}
                    /> : this.renderBodyModal()}
                    {/*{this.renderBodyModal()}*/}
                </div>
                <CustomModalWithChildren
                    classNameModal='converter-modal'
                    open={isConvert}
                    isFooter={false}
                    handleClose={() => {
                        this.setState({
                            isConvert: false
                        })
                    }}
                    options={{
                        centered: true,
                        // animation: false
                    }}
                >
                    <SizeConverter
                    callback={() => this.setState({
                        isConvert: false
                    })}
                    handleCancel={() => {
                        this.setState({
                            isConvert: false
                        })
                    }}
                />
                </CustomModalWithChildren> 
            </>
        )
    }
}

const mapStateToProps = state => ({
    isVisibleUploadSchema: state.analysisToolReducer.isVisibleUploadSchema,
    isLoading: state.analysisToolReducer.isLoading,
    activeTabProducts: state.purchaseOrdersReducer.activeTab,
    orderId: state.analysisToolReducer.orderId,
    isImporting: state.analysisToolReducer.isImporting
});

const mapDispatchToProps = dispatch => ({
    setHeaderData: data => dispatch(setData(data)),
    getSavedAnalysisTool: () => dispatch(getSavedAnalysisTool()),
    setShowHideUploadSchema: isVisible => dispatch(setShowHideUploadSchema(isVisible)),
});

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(AnalysisTool));