import React from 'react'
import { Form } from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import { connect } from 'react-redux'
import {showHideCreateContact} from '../../../../../../../actions/purchase-orders'


const BodyForm = props => {
    const {
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        openHideContact
    } = props
    return (
        <Form noValidate onSubmit={handleSubmit}>
            <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.name}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                    name="secondName"
                    value={values.secondName}
                    onChange={handleChange}
                    isInvalid={touched.secondName && errors.secondName}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.secondName}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    isInvalid={errors.phone && touched.phone}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.phone}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={touched.email && errors.email}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.email}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="footer-form footer-form-custom">
                <Button variant="secondary"
                        onClick={() => openHideContact(false)}>Cancel</Button>
                <Button variant="primary" type="submit">Save</Button>
            </Form.Group>
        </Form>

    )
}

const mapDispatchToProps = dispatch => {
    return {
        openHideContact: isShow => dispatch(showHideCreateContact(isShow))
    }
}

export default connect(null,mapDispatchToProps)(BodyForm)