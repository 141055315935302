import React, { PureComponent } from 'react'
import {Collapse} from "react-bootstrap";
import {ReactComponent as MenuRigth} from "../../../../assets/images/menu-rigth.svg";
import Tooltip from "../../../reuseComponent/Tooltips";
import {ReactComponent as ItemIcon} from "../../../../assets/images/item.svg";
import {ReactComponent as CheckCircleIcon} from "../../../../assets/images/check-circle.svg";
import {ReactComponent as CircleIcon} from "../../../../assets/images/empty-circle.svg";
import {ReactComponent as BoxIcon} from "../../../../assets/images/box.svg";

class WorkplaceCollapse extends PureComponent{
    state = {
        open: false,
    }

    handleOpenCloseCollapse = () => {
        this.setState(state => ({
            open: !state.open
        }))
    }

    render() {
        const {
            open
        } = this.state;
        const {
            workplace,
            handleChange,
            boxesId
        } = this.props;
        return (
            <div className="workplace_row">
                <div className="workplace__header" onClick={this.handleOpenCloseCollapse}>
                    <div
                        className={`toggle ${open ? 'opened' : ''}`}
                        // onClick={this.handleOpenCloseCollapse}
                    >
                        <MenuRigth/>
                    </div>
                    <div className={`info__header ${open ? 'opened' : ''}`}>
                        {workplace.workplace}
                    </div>
                </div>
                <Collapse in={open}>
                    <div>
                        <div className="workplace__content">
                            <div className="details__line">
                                <div className="line"></div>
                            </div>
                            <div className="content">
                                {workplace.boxes.length > 0 ? workplace.boxes.map(box => {
                                    const isSelected = boxesId.includes(box.id)
                                    return <div
                                        className={`box__wrap ${ isSelected ? 'selected' : ''}`}
                                        key={box.id}
                                        onClick={() => handleChange(box)}
                                    >

                                        <div className="name-box">{box.pack ? `${box.pack.orderNumber} > ${box.pack.shortName} > ` : null} #{box.custom ? box.id : box.name}</div>
                                        <div className="items-count">
                                            <Tooltip text="Items">
                                                <ItemIcon className="standart-svg"/>
                                            </Tooltip> {box.productsCount}
                                            <div className="icon-wrap">
                                                {isSelected
                                                    ? <CheckCircleIcon className="standart-svg"/>
                                                    : <CircleIcon className="standart-svg"/>}
                                            </div>
                                        </div>
                                        <div className="box-bg">
                                            <BoxIcon/>
                                        </div>
                                    </div>
                                }) : <p className="no-data-to-display">No data to display</p>}
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        )
    }
}

export default WorkplaceCollapse