import {PRINTERS} from "../constants";
// import {notify} from "../components/reuseComponent/toast";

// export const setLoadingLocationConfiguration = loading => ({
//     type: PRINTERS.SET_LOADING_LOCATION_CONFIGURATION,
//     payload: loading,
// });

export const setLoadingConnectionToPrinters = loading => ({
    type: PRINTERS.SET_LOADING_CONNECTION_TO_PRINTERS,
    payload: loading
});

export const setSelectedDevice = (device) => ({
    type: PRINTERS.SET_SELECTED_PRINTER,
    payload: device
});

export const setAvailablePrinters = (printers) => ({
    type: PRINTERS.SET_AVAILABLE_PRINTERS,
    payload: printers,
});

export const setup = () => dispatch => {
    dispatch(setLoadingConnectionToPrinters(true));
    window.BrowserPrint.getDefaultDevice("printer", (device) =>
    {
        if(device.uid){
            //Add device to list of devices and to html select element
            dispatch(setSelectedDevice(device));
            dispatch(setAvailablePrinters([device]));
        }

        //Discover any other devices available to the application
        window.BrowserPrint.getLocalDevices((device_list) => {
            const devicesList = [];
            if(device_list){
                for(let i = 0; i < device_list.length; i++)
                {
                    //Add device to list of devices and to html select element
                    let deviceS = device_list[i];
                    if(!device || deviceS.uid !== device.uid)
                    {
                        devicesList.push(deviceS);
                    }
                }
            }
            dispatch(setAvailablePrinters(devicesList));
            dispatch(setLoadingConnectionToPrinters(false));


        }, function(){
            // notify('error',"Printer connection failed!")
            }
            ,"printer");
        dispatch(setLoadingConnectionToPrinters(false));

    }, function(error){
        // notify('error',"Printer connection failed!");
        dispatch(setLoadingConnectionToPrinters(false));
    })
};