import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { schema } from './schema'
import { getSchemaOfProductsExcel } from '../../../../actions/purchase-orders'
import { uploadAnalysisProductsXls } from "../../../../actions/analysisTool";
import Preloader from '../../../reuseComponent/preloader/preloader'
import BodyForm from './bodyForm'

class SchemaUploadingExcel extends PureComponent{

    componentDidMount(){
        const { getSchema } = this.props
        getSchema && getSchema()
    }

    handleSubmit = values => {
        const file = values.file
        const { uploadExcel } = this.props
        delete values.file
        Object.keys(values).forEach(key => {
            if(typeof values[key] === 'string')
                values[key] = values[key].toUpperCase()
        })
        uploadExcel && uploadExcel(file,values)
    }

    render(){
        const { schemaUploading,isLoading } = this.props
        return (
            <div className="wrapper-schema-uploading-excel">
                {isLoading
                    ? <Preloader/>
                    : <Formik
                        onSubmit={this.handleSubmit}
                        initialValues={{
                            // file: '',
                            ...schemaUploading
                        }}
                        validationSchema={schema}
                        component={BodyForm}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        schemaUploading: state.purchaseOrdersReducer.schemaExcelUploading,
        isLoading : state.purchaseOrdersReducer.isLoadingExcelSchema,
        id: state.purchaseOrdersReducer.detailsPurchase.id
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSchema: () => dispatch(getSchemaOfProductsExcel()),
        uploadExcel: (file,schema) => dispatch(uploadAnalysisProductsXls(file,schema))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SchemaUploadingExcel)