import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button, Form} from 'react-bootstrap';
import {FieldArray} from 'formik';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import ContactBlock from './contacts-item';
import {getPaginatableSimpleParameter, getPaginatableFewSimpleParameters} from '../../../../services/dictionaries';
import classNames from 'classnames';
import {ReactComponent as InfoIcon} from '../../../../assets/images/info.svg';
import CustomSelect from '../../../reuseComponent/Select/select-form';
import AutosizeInput from 'react-input-autosize';
import {disabledSelectStyle} from '../../../reuseComponent/Select/disabledSelectStyling';
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute';
import {history} from '../../../../history';
import {ReactComponent as BackIcon} from '../../../../assets/images/arrow-left.svg';
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import {permViewAllCustomers} from '../../../../permissions/customers';
import TextareaAutosize from 'react-textarea-autosize';

class BodyFormServiceDesk extends PureComponent {
    state = {
        busModels: [
            {
                value: 'Wholesale'
            },
            {value: 'Retail store'},
            {value: 'Online shop'},
            {value: 'Market Shops'}
        ]
    };

    filterDataForOptions = (data, withId) => {

        return data.map(item => ({
            value: withId ? item.id : item.value,
            label: item.value
        }));
    };

    checkIfChangeData = () => {
        const {values, initialValues} = this.props;
        let res = false;
        Object.keys(initialValues).forEach(key => {
            try {
                if (
                    JSON.stringify(values[key]) !== JSON.stringify(initialValues[key])
                ) {
                    res = true;
                }
            } catch (err) {
            }
        });
        return res;
    };

    getCg3OptionsList = (inputValue, alias, withId) => {
        return new Promise(resolve => {
            getPaginatableSimpleParameter(alias, 0, 25, inputValue).then(resp => {
                resolve(this.filterDataForOptions(resp.data.parameters, withId));
            });
        });
    };

    getFewOptionsList = (inputValue, aliases, withId) => {
        return new Promise(resolve => {
            getPaginatableFewSimpleParameters(aliases, 0, 1000, inputValue).then(resp => {
                resolve(resp.data.map(item => ({
                    value: item,
                    label: item,
                })));
            });
        });
    }

    hasPermToAllocate = (user) => {
        const {roles, currUser} = this.props;
        return findPermInArray(roles, permViewAllCustomers)
            || (user ? user.username === currUser.username : false)
    };

    render() {
        const {
            handleSubmit,
            values,
            setFieldValue,
            errors,
            touched,
            isView,
            cancelCreate,
            responsibleCountries,
            responsibleLanguages,
        } = this.props;
        const {params} = this.props.match;
        const {busModels} = this.state;
        const modelsOptions = busModels.map(model => ({
            label: model.value,
            value: model.value
        }));
        const selectedModel = modelsOptions.find(
            model => model.value === values.busModel
        );

        return (
            <Form onSubmit={handleSubmit} autoComplete="off" className="customer-form">
                <div className="form-header">
                    <div className="left-part">
                        <Button
                            variant="primary"
                            className="standard"
                            onClick={() => {
                                changeRoutePush(`/atw/tasks/tickets`, history)
                                cancelCreate()
                            }}
                        >
                            <BackIcon/>
                            Back
                        </Button>
                    </div>
                    <div className="right-part">
                        {!isView && this.checkIfChangeData() && (
                            <Form.Group className="footer-form footer-form-custom">
                                <Button variant="secondary" onClick={cancelCreate}>
                                    Cancel
                                </Button>
                                <Button variant="primary" type="submit">
                                    Save
                                </Button>
                            </Form.Group>
                        )}
                    </div>
                </div>
                <div className="info-wrapper">
                    <h6 className="form-title">
                        <InfoIcon/>
                        Profile details
                    </h6>
                    <div className="info">
                        <Form.Group className="horizontal">
                            <Form.Label>Company name</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter company name' : ''}
                                    className="auto-resize-wrapper"
                                    name="companyName"
                                    value={values.companyName}
                                    onChange={event => {
                                        setFieldValue('companyName', event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.companyName && errors.companyName}</span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Tax ID</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter tax ID' : ''}
                                    className="auto-resize-wrapper"
                                    name="taxId"
                                    value={values.taxId}
                                    onChange={event => {
                                        setFieldValue('taxId', event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.taxId && errors.taxId}</span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Phone number</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter phone number' : ''}
                                    className="auto-resize-wrapper"
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    onChange={event => {
                                        setFieldValue('phoneNumber', event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.phoneNumber && errors.phoneNumber}</span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Email</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    // disabled={isView || (params.id !== 'new' && !isAdmin)}
                                    disabled={isView || params.id !== 'new'}
                                    type="text"
                                    placeholder={!isView ? 'Enter email' : ''}
                                    className="auto-resize-wrapper"
                                    name="email"
                                    value={values.email}
                                    onChange={event => {
                                        setFieldValue('email', event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.email && errors.email}</span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Address</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter address' : ''}
                                    className="auto-resize-wrapper"
                                    name="address"
                                    value={values.address}
                                    onChange={event => {
                                        setFieldValue('address', event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.address && errors.address}</span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Business model</Form.Label>
                            <div className="input-item">
                                <CreatableSelect
                                    className={classNames({
                                        'bootstrap-class': true,
                                        'invalid-select': touched.busModel && errors.busModel
                                    })}
                                    isMulti={true}
                                    isDisabled={isView}
                                    name="busModel"
                                    placeholder={!isView ? 'Select business model' : ''}
                                    value={values.busModel ? values.busModel : selectedModel}
                                    styles={isView ? disabledSelectStyle : {}}
                                    options={
                                        values.busModel && values.busModel.length > 0
                                            ? modelsOptions.filter(
                                            item => item.value !== values.busModel.value
                                            )
                                            : modelsOptions
                                    }
                                    onChange={values => {
                                        setFieldValue('busModel', values);
                                    }}
                                />
                                <span className="error-span">
                {touched.busModel && errors.busModel}
              </span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Demanded categories</Form.Label>
                            <div className="input-item">
                                <AsyncSelect
                                    isMulti={true}
                                    className={classNames({
                                        'customer-select': true,
                                        'bootstrap-class': true,
                                        'invalid-select': touched.demandCategory && errors.demandCategory
                                    })}
                                    isDisabled={isView}
                                    name="demandCategory"
                                    placeholder={!isView ? 'Select demanded categories' : ''}
                                    cacheOptions
                                    defaultOptions
                                    styles={isView ? disabledSelectStyle : {}}
                                    value={values.demandCategory && values.demandCategory.sort((a, b) => {
                                        if (a.label.toLowerCase() < b.label.toLowerCase()) {
                                            return -1;
                                        }
                                        if (a.label.toLowerCase() > b.label.toLowerCase()) {
                                            return 1;
                                        }
                                        return 0;
                                    })}
                                    onChange={value => {
                                        setFieldValue('demandCategory', value);
                                    }}
                                    loadOptions={value => this.getFewOptionsList(value, ['commodity', 'category'], false)}
                                />
                                <span className="error-span">
                {touched.demandCategory && errors.demandCategory}
              </span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Quality interest</Form.Label>
                            <div className="input-item">
                                <AsyncSelect
                                    isMulti={true}
                                    className={classNames({
                                        'customer-select': true,
                                        'bootstrap-class': true,
                                        'invalid-select': touched.qualityIds && errors.qualityIds
                                    })}
                                    isDisabled={isView}
                                    name="qualityIds"
                                    placeholder={!isView ? 'Select quality interest' : ''}
                                    cacheOptions
                                    defaultOptions
                                    styles={isView ? disabledSelectStyle : {}}
                                    value={values.qualityIds && values.qualityIds.sort((a, b) => {
                                        if (a.label.toLowerCase() < b.label.toLowerCase()) {
                                            return -1;
                                        }
                                        if (a.label.toLowerCase() > b.label.toLowerCase()) {
                                            return 1;
                                        }
                                        return 0;
                                    })}
                                    onChange={value => {
                                        setFieldValue('qualityIds', value);
                                    }}
                                    loadOptions={value => this.getCg3OptionsList(value, 'quality', true)}
                                />
                                <span className="error-span">
                {touched.qualityIds && errors.qualityIds}
              </span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Spoken language</Form.Label>
                            <div className="input-item">
                                <CustomSelect
                                    className="customer-select"
                                    name="spokenLanguage"
                                    options={responsibleLanguages}
                                    styles={isView ? disabledSelectStyle : {}}
                                    onChange={e => setFieldValue('spokenLanguage', e.value)}
                                    value={responsibleLanguages.find(lang => lang.value === values.spokenLanguage)}
                                    errorLabel={errors.spokenLanguage}
                                    isInvalid={touched.spokenLanguage && errors.spokenLanguage}
                                    placeholder={!isView ? 'Select spoken language' : ''}
                                    isDisabled={isView}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Country</Form.Label>
                            <div className="input-item">
                                <CustomSelect
                                    name="country"
                                    className="customer-select"
                                    styles={isView ? disabledSelectStyle : {}}
                                    options={responsibleCountries}
                                    onChange={e => setFieldValue('country', e.value)}
                                    value={responsibleCountries.find(lang => lang.value === values.country)}
                                    errorLabel={errors.country}
                                    isInvalid={touched.country && errors.country}
                                    placeholder={!isView ? 'Select country' : ''}
                                    isDisabled={isView}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Business countries</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter business countries' : ''}
                                    className="auto-resize-wrapper"
                                    name="countriesOperate"
                                    value={values.countriesOperate}
                                    onChange={event => {
                                        setFieldValue('countriesOperate', event.target.value);
                                    }}
                                />
                                <span
                                    className="error-label">{touched.countriesOperate && errors.countriesOperate}</span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>City</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter city' : ''}
                                    className="auto-resize-wrapper"
                                    name="city"
                                    value={values.city}
                                    onChange={event => {
                                        setFieldValue('city', event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.city && errors.city}</span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Zip code</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter zip code' : ''}
                                    className="auto-resize-wrapper"
                                    name="zip"
                                    value={values.zip}
                                    onChange={event => {
                                        setFieldValue('zip', event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.zip && errors.zip}</span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Website</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={isView}
                                    type="text"
                                    placeholder={!isView ? 'Enter your website url' : ''}
                                    className="auto-resize-wrapper"
                                    name="website"
                                    value={values.website}
                                    onChange={event => {
                                        setFieldValue('website', event.target.value);
                                    }}
                                />
                                <span className="error-label">{touched.website && errors.website}</span>
                            </div>
                        </Form.Group>

                        <Form.Group className="horizontal">
                            <Form.Label>Note</Form.Label>
                            <div className="input-item">
                                <TextareaAutosize
                                    disabled={isView}
                                    style={{resize: 'none', height: 'auto'}}
                                    minRows={1}
                                    placeholder={!isView ? 'Enter note' : ''}
                                    type="text"
                                    className="auto-resize-wrapper"
                                    name="notes"
                                    value={values.notes}
                                    onChange={event => {
                                        setFieldValue('notes', event.target.value)
                                    }}
                                />
                                <span className="error-span">{errors.notes && touched.notes}</span>
                            </div>
                        </Form.Group>
                    </div>
                </div>

                {!isView || values.contacts.length > 0 ? <div className="contacts-wrapper">
                    <h6 className="contacts-title">
                        <svg viewBox="0 0 24 24">
                            <path
                                fill="currentColor"
                                d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
                            />
                        </svg>
                        Contacts
                    </h6>

                    <FieldArray
                        name="contacts"
                        render={arrayHelpers => {
                            return (
                                <div className="contacts">
                                    {values.contacts &&
                                    values.contacts.length > 0 &&
                                    values.contacts.map((contact, index) => {
                                        return (
                                            <ContactBlock
                                                key={index}
                                                isView={isView}
                                                index={index}
                                                values={values}
                                                touched={touched}
                                                errors={errors}
                                                setFieldValue={setFieldValue}
                                                arrayHelpers={arrayHelpers}
                                            />
                                        );
                                    })}
                                    <>
                                        {!isView && (
                                            <Button
                                                variant="primary"
                                                className="add-button-customer add-customer-contact"
                                                onClick={() =>
                                                    arrayHelpers.push({
                                                        name: '',
                                                        secondName: '',
                                                        phone: undefined,
                                                        email: '',
                                                        position: ''
                                                    })
                                                }
                                            >
                                                <svg viewBox="0 0 24 24">
                                                    <path
                                                        fill="currentColor"
                                                        d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                                                    />
                                                </svg>
                                                Add contact
                                            </Button>
                                        )}
                                    </>
                                    {touched.contacts && typeof errors.contacts === 'string' && <span style={{
                                        marginLeft: '15px',
                                        marginTop: '4px'
                                    }} className="error-label">{errors.contacts}</span>}
                                </div>
                            );
                        }}
                    />
                </div> : null}
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return {
        currUser: state.usersReducer.currUser.userDetails,
        roles: state.rolesReducer.roles,
        types: state.serviceDeskReducer.types,
        statuses: state.serviceDeskReducer.statuses,
        history: state.serviceDeskReducer.history,
        responsibleLanguages: state.usersReducer.languages.map(item => ({value: item.name, label: item.name})),
        responsibleCountries: state.customersReducer.countries.map(item => ({value: item, label: item})),
        customersResponsible: state.customersReducer.customersResponsible,
        customersStatus: state.customersReducer.customersStatus,
    };
};

export default withRouter(connect(mapStateToProps, null)(BodyFormServiceDesk));
