import { url } from "../constants";
import adminInstance from "../components/instances/admininstance";
import { convertToFormData } from "../reuseFunctions/objectToFormData";

export const editPackageName = (id, name) => {
  const data = convertToFormData({
    id,
    name
  });
  return adminInstance.put(`${url}/package/${id}`, data);
};
