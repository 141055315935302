import {ANALYSIS_TOOL, url} from "../constants";
import adminInstance from "../components/instances/admininstance";
import {notify} from "../components/reuseComponent/toast";

export const setShowHideUploadSchema = (isVisible) => ({
    type: ANALYSIS_TOOL.SHOW_HIDE_UPLOADING_SCHEMA_ANALYSIS,
    payload: isVisible,
});

export const setLoading = isLoading => ({
    type: ANALYSIS_TOOL.IS_LOADING_ANALYSIS,
    payload: isLoading,
});

export const setProducts = products => ({
    type: ANALYSIS_TOOL.SET_PRODUCTS_ANALYSIS,
    payload: products,
});

export const setOrderId = id => ({
    type: ANALYSIS_TOOL.SET_ORDER_ID,
    payload: id
});

export const setActiveTab = activeTab => ({
    type: ANALYSIS_TOOL.SET_ACTIVE_TAB_ANALYSIS,
    payload: activeTab,
});

export const setFilterConditions = conditions => {
    return dispatch => {
        dispatch({
            type: ANALYSIS_TOOL.SET_FILTER_CONDITIONS_ANALYSIS,
            payload: conditions
        })
        return Promise.resolve()
    }
}

export const setProductsErrors = count => ({
    type: ANALYSIS_TOOL.SET_ERRORS_COUNT_ANALYSIS,
    payload: count
})

export const getProducts = () => dispatch => {
    dispatch(setLoading(true));

    adminInstance.get(`${url}/analysis_tool`)
        .then(res => {

        })
        .catch(err => notify('error', 'Oops something went wrong'))
        .finally(() => dispatch(setLoading(false)))
};

const setImportFlag = (isImporting) => ({
    type: ANALYSIS_TOOL.IS_IMPORT_FILE_ANALYSIS,
    payload: isImporting
})

export const uploadAnalysisProductsXls = (file,schema={}) => {
    return dispatch => {
        dispatch(setImportFlag(true))
        let data = new FormData()
        data.append('file',file)
        Object.keys(schema).forEach(key => {
            data.append(key,schema[key])
        })

        adminInstance.post(`${url}/analysis_tool/upload`,data)
            .then(res => {
                dispatch(setShowHideUploadSchema(false));
                if(typeof res.data === 'string'){
                    notify('success','Uploading already in progress')
                }
                else
                    dispatch(setOrderId(res.data.orderId));
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(setImportFlag(false))
            })
    }
}

export const getSavedAnalysisTool = () => dispatch => {
    dispatch(setLoading(true));

    adminInstance.get(`${url}/analysis_tool`)
        .then(res => {
            if(res.data)
                dispatch(setOrderId(res.data.id));
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
        .finally(() => {
            dispatch(setLoading(false))
        })
};

export const removeAnalysisToolResults = (callback) => dispatch => {
    // dispatch(setLoading(true));
    adminInstance.delete(`${url}/analysis_tool`)
        .then(res => {
            callback && callback()
            notify('success','Data successfully removed')
            // dispatch(setOrderId(res.data.id))
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
        .finally(() => {
            // dispatch(setLoading(false))
        })
}