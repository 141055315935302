import { PO_SMART_FILTER } from '../constants'
import { getPoFilters,createFilter } from '../services/poSmartFilter'
import { notify } from '../components/reuseComponent/toast'

const setPoFilters = filters => ({
    type: PO_SMART_FILTER.SET_PO_FILTERS,
    payload: filters
})

export const getPoFiltersAsyncAction = () => {
    return dispatch => {
        let filtersPromise = getPoFilters()
        filtersPromise.then(res => {
            dispatch(setPoFilters(res.data))
        })
            .catch(err => {
                notify('error','Can not to get filters')
            })
    }
}

export const createPoFilterAsyncAction = ({values,successCallback,errorCallback}) => {
    return dispatch => {
        let promise = createFilter(values)
        promise.then(res => {
            successCallback && successCallback()
        })
            .catch(err => {
                errorCallback && errorCallback()
            })
    }
}