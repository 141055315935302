import axios from 'axios'
// import { convertToFormData } from '../../reuseFunctions/objectToFormData'
// import { url } from '../../constants'
import { history } from '../../history'
import { notify } from '../reuseComponent/toast';

const adminInstance = axios.create()

// let isAlreadyFetchingAccessToken = false

adminInstance.interceptors
    .request
    .use(
        async function (config) {
            const user = JSON.parse(window.localStorage.getItem('userWCS'))
            if (user) {
                config.headers['Authorization'] = `Bearer ${user.access_token}`
            }
            else {
                history.replace('/login')
            }
            return config
        }, function (error) {
            return Promise.reject(error)
        })

adminInstance
    .interceptors
    .response
    .use(
        function (config) {
            return config
        }, function(error) {

                axios.get(`${process.env.REACT_APP_API_URL}/system`)
                    .then(res => {

                    })
                    .catch(err => {
                        history.replace('/login')
                        notify('error','System is under updating, please wait...')
                    })

            return Promise.reject(error);
        }
    )

export default adminInstance
