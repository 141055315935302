import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import Modal from '../../../../../../reuseComponent/modal/index'
import { changeTasksData } from '../../../../../../../actions/purchase-orders'
import Form from './form/index'

class Tasks extends PureComponent{
    render(){
        const { tasks,changeTasksData } = this.props
        return (
            <Modal
                open={tasks.openModalCreate || tasks.openModalEdit}
                Content={() => <Form />}
                isFooter={false}
                handleClose={() => {
                    changeTasksData({
                        openModalCreate: false,
                        openModalEdit: false,
                        openModalRemove: false,
                        idTask: null
                    })
                }}
                options={{
                    centered: true
                }}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        tasks: state.purchaseOrdersReducer.tasks
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeTasksData : data => dispatch(changeTasksData(data))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Tasks)