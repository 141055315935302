import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {checkPackageName} from '../../../../../../reuseFunctions/checkPackageName';
import Tooltip from '../../../../../reuseComponent/Tooltips';
import {permManageCustomPackage} from '../../../../../../permissions/productOverview';
import {findPermInArray} from '../../../../../../reuseFunctions/checkPermission';

import { ReactComponent as DeleteIcon } from '../../../../../../assets/images/delete.svg'
import { notify } from '../../../../../reuseComponent/toast'
import DropDownMenu from '../../../../../reuseComponent/DropDown';
import {  selectedPackegeAction } from '../../../../../../actions/purchase-orders';

const PackageRow = ({pack, handleRowClick, handleEditPackage,isCustom, handleDeletePackage}) => {
    const activePackage = useSelector(
        state => state.inboundingReportReducer.activePackage
    );
    const searchedPackages = useSelector(
        state => state.inboundingReportReducer.searchedPackages
    );
    const dispatch = useDispatch()
    const roles = useSelector(state => state.rolesReducer.roles)
    const details = useSelector(state => state.purchaseOrdersReducer.detailsPurchase)
    const currentId = useSelector(
        state => state.purchaseOrdersReducer.selectedPackege
    );
    const handleMouseOver = (id) => {
        if (id === currentId) {
            return;
        } else {
            dispatch(selectedPackegeAction(id))
        }
    }
    const handleMouseLeave = (removeId) => {
        if (removeId === currentId) {
            return;
        } else {
            dispatch(selectedPackegeAction(removeId))
        }
    }

    const toolbar = () => {
        return pack.id === currentId && (
                <DropDownMenu
                        ContentMenu={() => {
                            return (
                                <div className={`actions-dropdown-body`}>
                                    <ul className='list-unstyled no-margin menu-table-actions'>
                                        <li>
                                            <a href="/" onClick={e => {
                                                e.preventDefault()
                                                handleEditPackage(pack);
                                            }}>Edit</a>
                                        </li>
                                        <li>
                                            <a href="/" onClick={e => {
                                                e.preventDefault()
                                                if(pack.actual_items > 0){
                                                    notify('error', 'Move all boxes and articles before')
                                                }else{
                                                    handleDeletePackage(pack);
                                                }
                                            }}>Delete</a>
                                        </li>
                                    </ul>
                            
                                </div>
                            )
                        }}
                    >
                </DropDownMenu>
        )
    }
    return (
        <div
            className={`package__row ${isCustom ? 'custom-package__row' : ''}
            ${activePackage.id !== undefined && activePackage.id === pack.id ? 'active' : '' }
            ${!!searchedPackages.find(packageObj => packageObj.id === pack.id) ? 'is-searched' : ''}`}
            onClick={handleRowClick}
            onMouseLeave={() => handleMouseLeave(null)}
            onMouseOver={() => handleMouseOver(pack.id)}
        >
            <div className="package__content">
                <div className="package__cell">
                    <Tooltip text={checkPackageName(pack, details.inboundType === 'Totals')}>
                        <span className="package__name">{checkPackageName(pack, details.inboundType === 'Totals')}</span>
                    </Tooltip>
                    {pack.description && <span className='package__description'>{pack.description}</span>}
                </div>
                <div className="package__cell">{details.inboundType === 'Totals' ? pack.closedBox : pack.openBox}</div>
                {details.inboundType !== 'Totals' && <div className="package__cell">{pack.closedBox}</div>}
                <div className="package__cell">
                    <div>
                        {pack.actual_items ? pack.actual_items : 0} <span className="inactive">
                            {details.actualQuantity && parseInt(details.actualQuantity) ? `/ ${parseInt(details.actualQuantity)}` : null}
                        </span>
                    </div>
                </div>
            </div>
            {pack.custom && handleEditPackage && findPermInArray(roles, permManageCustomPackage) && toolbar()}
        </div>
    );
};

export default PackageRow;
