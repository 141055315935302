import React from 'react'
import PropTypes from 'prop-types'

const ConfirmModalWithText = ({text, additionalText}) => {
    return <h6 className="center-h">
        <span role="img" aria-label="think">🤔</span> Sure you want to {text}?
        {additionalText && <span> {additionalText}</span>}
    </h6>
};


export {
    ConfirmModalWithText
}