import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button, Form} from 'react-bootstrap';
import {ReactComponent as InfoIcon} from '../../../../assets/images/info.svg';
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute';
import {history} from '../../../../history';
import {ReactComponent as BackIcon} from '../../../../assets/images/arrow-left.svg';
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import {ReactComponent as DeleteIcon} from '../../../../assets/images/delete.svg';
import {ReactComponent as ClearIcon} from '../../../../assets/images/clear.svg';
import {ReactComponent as DeactivateIcon} from '../../../../assets/images/deactivate.svg';
import {ReactComponent as EditIcon} from '../../../../assets/images/edit.svg';
import {ReactComponent as CheckIcon} from '../../../../assets/images/check.svg';
import {permRemoveCustomers, permViewAllCustomers} from '../../../../permissions/customers';
import ResponsibleCompoent from '../list/responsibleComponent';
import FormEdit from './edit/form'
import View from './edit/view'
import {ReactComponent as AcountSearch} from '../../../../assets/images/acount-search.svg'
import {ReactComponent as AcountCheck} from '../../../../assets/images/acount-check.svg'
import {ReactComponent as AcountCancel} from '../../../../assets/images/acount-cancel.svg'
import {ReactComponent as AccountOff} from '../../../../assets/images/account-off.svg'
import HistoryCustomers from "../history"

class BodyFormServiceDesk extends PureComponent {
    state = {
        busModels: [
            {
                value: 'Wholesale'
            },
            {value: 'Retail store'},
            {value: 'Online shop'},
            {value: 'Market Shops'}
        ],
        isEditForm: false
    };

    // filterDataForOptions = (data, withId) => {
    //
    //     return data.map(item => ({
    //         value: withId ? item.id : item.value,
    //         label: item.value
    //     }));
    // };

    checkIfChangeData = () => {
        const {values, initialValues} = this.props;
        let res = false;
        Object.keys(initialValues).forEach(key => {
            try {
                if (
                    JSON.stringify(values[key]) !== JSON.stringify(initialValues[key])
                ) {
                    res = true;
                }
            } catch (err) {
            }
        });
        return res;
    };

    // getCg3OptionsList = (inputValue, alias, withId) => {
    //     return new Promise(resolve => {
    //         getPaginatableSimpleParameter(alias, 0, 25, inputValue).then(resp => {
    //             resolve(this.filterDataForOptions(resp.data.parameters, withId));
    //         });
    //     });
    // };
    //
    // getFewOptionsList = (inputValue, aliases, withId) => {
    //     return new Promise(resolve => {
    //         getPaginatableFewSimpleParameters(aliases, 0, 25, inputValue).then(resp => {
    //             resolve(resp.data.map(item => ({
    //                 value: item,
    //                 label: item,
    //             })));
    //         });
    //     });
    // }

    hasPermToAllocate = (user) => {
        const {roles, currUser} = this.props;
        return findPermInArray(roles, permViewAllCustomers)
            || (user ? user.username === currUser.username : false)
    };

    render() {
        const {
            handleSubmit,
            values,
            setFieldValue,
            errors,
            touched,
            isView,
            cancelCreate,
            responsibleCountries,
            responsibleLanguages,
            handleShowRemoveModal,
            initialValues,
            customersResponsible,
            customersStatus,
            handleShowAllocateModal,
            handleShowRejectModal,
            handleShowDeactivateModal,
            changeRow,
            handleAllocate,
            setValues,
            refetchTableData
        } = this.props;
        const {params} = this.props.match;
        const {busModels, isEditForm} = this.state;
        const modelsOptions = busModels.map(model => ({
            label: model.value,
            value: model.value
        }));
        const selectedModel = modelsOptions.find(
            model => model.value === values.busModel
        );

        const hasPermToAllocate = this.hasPermToAllocate(customersResponsible)
        return (
            <Form onSubmit={e => {
                handleSubmit(e)
            }} autoComplete="off" className="customer-form">
                <div className="form-header">
                    <div className="left-part">
                        <Button
                            type="button"
                            variant="primary"
                            className="standard"
                            onClick={() => {
                                changeRoutePush(`/atw/tasks/tickets`, history)
                                cancelCreate()
                            }}
                        >
                            <BackIcon/>
                            Back
                        </Button>
                    </div>
                    <div className="right-part">
                        <>
                            <div className={`status ${customersStatus && customersStatus.alias === 'deactivated' && 'deactivated'}`}>
                                <span>Status:  </span>
                                {customersStatus && <>
                                {customersStatus.alias === 'new' && <AcountSearch/>}
                                {customersStatus.alias === 'activated' && <AcountCheck />}
                                {customersStatus.alias === 'rejected' && <AcountCancel />}
                                {customersStatus.alias === 'deactivated' && <AccountOff />}
                                    {customersStatus.name}
                                </>
                                }
                            </div>
                            <span className="responsible-name">Responsible: </span>
                            <ResponsibleCompoent
                                responsibleUser={customersResponsible}
                                customer={{
                                    id: parseInt(params.id),
                                    companyName: initialValues.companyName,
                                }}
                                changeRow={changeRow}
                                handleAllocate={handleAllocate}
                            />
                            {hasPermToAllocate && ((customersStatus && customersStatus.alias !== 'activated') || !customersStatus) &&
                            <Button
                                type="button"
                                variant="primary"
                                className="standard"
                                onClick={() => handleShowAllocateModal(parseInt(params.id), initialValues.companyName, customersResponsible)}
                            >
                                <CheckIcon/>
                                Accept
                            </Button>
                            }
                            {hasPermToAllocate && ((customersStatus && customersStatus.alias !== 'deactivated' &&
                                customersStatus.alias !== 'rejected') || !customersStatus) &&
                            <Button
                                type="button"
                                variant="primary"
                                className="standard"
                                onClick={() => handleShowRejectModal(parseInt(params.id), initialValues.companyName, customersResponsible)}
                            >
                                <ClearIcon/>
                                Reject
                            </Button>}
                            {hasPermToAllocate && customersStatus && customersStatus.alias === 'activated' &&
                            <Button
                                type="button"
                                variant="primary"
                                className="standard"
                                onClick={() => handleShowDeactivateModal(parseInt(params.id), initialValues.companyName, customersResponsible)}
                            >
                                <DeactivateIcon/>
                                Deactivate
                            </Button>}
                            {findPermInArray(this.props.roles, permRemoveCustomers) && <Button
                                type="button"
                                variant="primary"
                                className="standard"
                                onClick={() => handleShowRemoveModal(params.id, initialValues.email)}
                            >
                                <DeleteIcon/>
                                Delete
                            </Button>}
                        </>
                    </div>
                </div>
                <div className="customer-info-wrapper">
                    <div>
                    <h6 className="form-title">
                        <InfoIcon/>
                        Profile details

                        <Form.Group className="footer-form footer-form-custom">
                            {!isEditForm && !isView &&
                            <Button type="button" variant="primary" onClick={() => this.setState({isEditForm: true})}>
                                <EditIcon className="standart-svg"/>
                                Edit
                            </Button>
                            }
                            {!isView && isEditForm && <>
                                <Button variant="secondary" type="button" onClick={() => this.setState({
                                    isEditForm: false
                                }, () => {
                                    setValues(initialValues)
                                })}>
                                    Cancel
                                </Button>
                                {this.checkIfChangeData() && <Button variant="primary" type="submit">
                                    Save
                                </Button>
                                }
                            </>
                            }
                        </Form.Group>

                    </h6>
                    {isEditForm && !isView && <FormEdit
                        isView={isView}
                        values={values}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                        selectedModel={selectedModel}
                        modelsOptions={modelsOptions}
                        responsibleLanguages={responsibleLanguages}
                        responsibleCountries={responsibleCountries}
                    />}
                    {(!isEditForm || isView) && <View values={initialValues}/>}
                    </div>
                    <HistoryCustomers refetchTableData={refetchTableData} />
                </div>
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return {
        currUser: state.usersReducer.currUser.userDetails,
        roles: state.rolesReducer.roles,
        types: state.serviceDeskReducer.types,
        statuses: state.serviceDeskReducer.statuses,
        history: state.serviceDeskReducer.history,
        responsibleLanguages: state.usersReducer.languages.map(item => ({value: item.name, label: item.name})),
        responsibleCountries: state.customersReducer.countries.map(item => ({value: item, label: item})),
        customersResponsible: state.customersReducer.customersResponsible,
        customersStatus: state.customersReducer.customersStatus,
    };
};

export default withRouter(connect(mapStateToProps, null)(BodyFormServiceDesk));
