import React, {PureComponent} from 'react'
import {Button} from 'react-bootstrap'
import { connect } from 'react-redux'
import {
    createOfferFilter,
    deleteFilterById,
    editFilterById,
    setActiveFilter
} from '../../../../../services/smart-filter'
import {notify} from '../../../../reuseComponent/toast'
import FiltersList from '../../../../reuseComponent/smart-filter/filters-list'
import FilterModal from '../../../../reuseComponent/smart-filter/modalFilter'
import FormFilter from '../../../../reuseComponent/smart-filter/form'
import EditFilter from '../../../../reuseComponent/smart-filter/editFilter'
import {getFilters} from '../../../../../actions/smart-filter';
import {ConfirmModalContent} from '../../../../reuseComponent/confirmModalComponent';
import { withRouter } from 'react-router-dom'
import CustomModalWithChildren from "../../../../reuseComponent/modal/modalWithChildren";

const aliasTasks = 'tasks'

class ToolbarDictionary extends PureComponent {

    state = {
        openCreateModal: false,
        openEditModal: false,
        openDeleteModal: false,
        removedTitle: '',
        currFilter: null,
        isLoadingFilterModal: false
    }

    openCreateModal = () => this.setState({openCreateModal: true})

    hideCreateModal = () => this.setState({openCreateModal: false, isLoadingFilterModal: false})

    openEditModal = (idFilter) => this.setState({openEditModal: true, currFilter: idFilter})

    hideEditModal = () => this.setState({openEditModal: false, currFilter: null, isLoadingFilterModal: false})

    openDeleteModal = (idFilter, removedTitle) => this.setState({
        openDeleteModal: true,
        currFilter: idFilter ,
        removedTitle
    })

    hideDeleteModal = () => this.setState({openDeleteModal: false, currFilter: null})

    setLoading = (isLoading, callback) => this.setState({isLoadingFilterModal: isLoading}, () => callback && callback())

    handleCreateFilter = values => {
        const promise = createOfferFilter(values,aliasTasks)
        this.setLoading(true, () => {
            promise
                .then(res => {
                    this.updateViewAndFilter()
                    this.hideCreateModal()
                    notify('success', 'Filter successfully created')
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })

    }

    handleActivateFilter = (id) => {
        const promise = setActiveFilter(id,aliasTasks)
        promise
            .then(this.updateViewAndFilter)
            .catch(err => notify('error', 'Oops something went wrong'))
    }

    updateViewAndFilter = () => {
        const {onSearchChanged, getFilters} = this.props
        onSearchChanged && onSearchChanged('')
        getFilters && getFilters(aliasTasks)
    }

    handleEditFilter = values => {
        const {currFilter} = this.state
        this.setLoading(true, () => {
            editFilterById(currFilter, values)
                .then(res => {
                    notify('success', 'Filter successfully edited')
                    this.hideEditModal()
                    this.updateViewAndFilter()
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                    this.hideEditModal()
                })
        })

    }

    handleDeleteFilter = () => {
        const {currFilter} = this.state
        this.hideDeleteModal()
        deleteFilterById(currFilter)
            .then(res => {
                this.updateViewAndFilter()
                notify('success', 'Filter successfully deleted')
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }

    componentDidMount() {
        const {getFilters} = this.props
        getFilters && getFilters(aliasTasks)
    }


    render() {
        const {
            pagination,
            paginationPosition,
            handleAdd
        } = this.props
        const { alias } = this.props.match.params
        const {openCreateModal, openEditModal, openDeleteModal, currFilter, isLoadingFilterModal, removedTitle} = this.state
        return (
            <div className="toolbar-tasks">
                <div className="actions-tasks-toolbar">
                    {handleAdd && <Button onClick={handleAdd} className="add-button-task">
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                        </svg>
                        Add
                    </Button>
                    }
                    {alias !== 'my-tasks' && <FiltersList
                        isHideNoFilter
                        setActiveFilter={this.handleActivateFilter}
                        openCreateModal={this.openCreateModal}
                        openEditModal={this.openEditModal}
                        openDeleteModal={this.openDeleteModal}
                    />
                    }
                </div>
                {paginationPosition === 'toolbar'
                &&
                <div className="pagination-toolbar">
                    {pagination}
                </div>
                }
                <FilterModal
                    open={openCreateModal}
                    handleClose={this.hideCreateModal}

                >
                    <FormFilter
                        isLoading={isLoadingFilterModal}
                        handleSubmit={this.handleCreateFilter}
                        alias={aliasTasks}
                        handleCancel={this.hideCreateModal}
                        initValues={{
                            name: '',
                            criterias: [],
                            orCriterias: []
                        }}
                    />
                </FilterModal>
                <FilterModal
                    open={openEditModal}
                    handleClose={this.hideEditModal}
                >
                    <EditFilter
                        alias={aliasTasks}
                        idFilter={currFilter}
                        isLoading={isLoadingFilterModal}
                        handleCancel={this.hideEditModal}
                        handleSubmit={this.handleEditFilter}
                    />
                </FilterModal>
                <CustomModalWithChildren
                    open={openDeleteModal}
                    Content={ConfirmModalContent}
                    handleConfirm={this.handleDeleteFilter}
                    handleClose={this.hideDeleteModal}
                    handleCancel={this.hideDeleteModal}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`delete "${removedTitle}" filter`}
                    />
                </CustomModalWithChildren>
            </div>
        )
    }
}


export default withRouter(connect(null,{
    getFilters
})(ToolbarDictionary))