import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import FilterForm from '../form'
import { getFilterInfo } from '../../../../services/smart-filter'


class EditFilter extends PureComponent {

    state = {
        initValues: {
            criterias: [],
            orCriterias: []
        },
        isLoadingFilter: true
    }

    componentDidMount() {
        const {idFilter} = this.props
        if (idFilter && getFilterInfo) {
            this.setState({
                isLoadingFilter: true
            }, () => {
                const promise = getFilterInfo(idFilter)
                promise.then(res => {
                    this.setState({
                        isLoadingFilter: false,
                        initValues: res.data
                    })
                })
            })

        }
    }

    render() {
        const {isLoading, handleSubmit, handleCancel,alias, isCustomers} = this.props
        const {isLoadingFilter, initValues} = this.state
        return (
            <FilterForm
                alias={alias}
                initValues={initValues}
                isLoading={isLoading || isLoadingFilter}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                isCustomers={isCustomers}
            />

        )
    }

}

export default connect(null, null)(EditFilter)