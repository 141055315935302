import React, {PureComponent} from 'react'
import Table from './tableActivity'
import adminInstance from '../../../instances/admininstance'
import {connect} from 'react-redux'
import {url} from '../../../../constants'
import moment from 'moment'
import {setData} from "../../../../actions/admin-header";
import TabsSettings from "../../settings/tabs";
import Toolbar from './Toolbar'
import Header from '../../../reuseComponent/Table/table-head'
import Pagination from '../../../reuseComponent/Table/Pagination'
import withTracker from "../../../reuseComponent/GoogleAnalytics";


class TableActivity extends PureComponent {

    tableRef = React.createRef()

    componentDidMount(){
        this.props.setHeaderData({
            LeftPannel: TabsSettings,
            leftPannelData: {
                alias: 'activity'
            },
            handleAdd: null,
            handleSearch: (value) => this.tableRef.current.onSearchChange(value)
        })
    }

    componentWillUnmount() {
        this.props.setHeaderData({title: null, handleAdd: null, handleSearch: null, CustomLeftPannel: null})
    }

    getActionsForTable = query => {
        return new Promise((resolve, reject) => {
            adminInstance.get(`${url}/action`, {
                params: {
                    page: query.page ? query.page : 0,
                    limit: query.pageSize,
                    search: query.search
                }
            })
                .then(res => {
                    resolve({
                        data: this.generateRows(res.data.actions),
                        page: query.page,
                        totalCount: res.data.count
                    })
                })
        })
    }


    generateRows = (actions) => {
        return actions.map(act => {
            return {
                date: moment(act.createdAt, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm:ss'),
                action: `${act.user ? act.user.name : ''} 
                ${act.user ? act.user.secondName : ''} 
                ${act.actionType.text} 
                ${act.actionText}`
            }
        })

    }


    render() {        
        return (
            <div className="wrapper-table">
                <Table
                    components={{
                        Toolbar: Toolbar,
                        Header: Header,
                        Pagination: Pagination
                    }}
                    paginationPosition="toolbar"
                    columns={generateCols()}
                    data={this.getActionsForTable}
                    tableRef={this.tableRef}
                    title="Activities"
                    options={{
                        maxBodyHeight: 'calc(100vh - 130px)',
                        search: true,
                        emptyRowsWhenPaging: false,
                        pageSize: 10,
                        pageSizeOptions: [10, 25, 50]
                    }}
                />
            </div>
        )
    }
}

const generateCols = () => {
    return [
        {
            title: 'Date',
            field: 'date',
            sorting: false
        },
        {
            title: 'Action',
            field: 'action',
            sorting: false
        }
    ]
}

const mapDispatchToProps = dispatch => ({
    setHeaderData: (data) => dispatch(setData(data))
});



export default withTracker(connect(null, mapDispatchToProps)(TableActivity))