import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import BodyForm from './bodyForm'
import { schema } from './schema'

const Form = props => {
    const { handleSubmit,handleCancel, currId } = props
    return (
        <Formik
            onSubmit={handleSubmit}
            validationSchema={schema}
            render={props => <BodyForm {...props} handleCancel={handleCancel} currId={currId} />}
        />
    )
}

export default connect(null,null)(Form)