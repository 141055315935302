import React, { PureComponent } from "react";
import { connect } from "react-redux";
import DividingModule from "../../products/dividing";
import adminsInstance from "../../../../instances/admininstance";
import {
  changeDividingStatus,
  createConditionsSet
} from "../../../../../actions/dividing";
import { url } from "../../../../../constants";
import { findPermInArray } from "../../../../../reuseFunctions/checkPermission";
import { permManageDividing } from "../../../../../permissions/productOverview";
import { Button } from "react-bootstrap";
import CustomModalWithChildren from "../../../../reuseComponent/modal/modalWithChildren";
import './style.scss'

const checkIfSetsFill = (sets = []) => {
  let res = true;
  sets.forEach(set => {
    if (!set.rest) {
      if (set.ipp <= 0)
        res = false;
      else {
        if(set.conditions){
          set.conditions.forEach(condition => {
            if (
                !condition.fieldAlias ||
                !condition.values ||
                condition.values.length === 0
            )
              res = false;
          });
        }
      }
    }
  });
  return res;
};

class Packages extends PureComponent {
  state = {
    isExporting: false,
    openModal: false,
  };

  handleExportDividingResult = e => {
    e.preventDefault();
    if (!this.state.isExporting) {
      const { details } = this.props;
      this.setState(
        {
          isExporting: true
        },
        () => {
          adminsInstance({
            method: "GET",
            responseType: "blob",
            url: `${url}/purchase_order/${details.id}/exp_divided_result`
          }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Product_list_${details.orderNumber}.xls`
            );
            document.body.appendChild(link);
            link.click();
            this.setState({
              isExporting: false
            });
          });
        }
      );
    }
  };

  render() {
    const { isExporting } = this.state;
    const {
      details,
      changeStatus,
      status,
      sets,
      createSet,
      conditionSets,
      roles
    } = this.props;
    const isFillSets = checkIfSetsFill(
      sets.filter(set => !set.unordered && !set.overdelivered && !set.custom)
    );

    const rest = conditionSets.find(set => set.rest);
    return (
      <div className="po-tab-content">
        <div className="header-po-tab">
          <div className="left-part">
            {findPermInArray(roles, permManageDividing) && (
              <div className="actions-conditions">
                {!details.completeInbound &&
                  !status &&
                  isFillSets &&
                  rest &&
                  rest.totalCount > 0 && (
                    <Button
                      className="with-icon"
                      onClick={() => createSet && createSet(details.id)}
                    >
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="#000000"
                          d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                        />
                      </svg>
                      Add Condition Set
                    </Button>
                  )}
              </div>
            )}
          </div>
          <div className="central-part"></div>
          <div className="right-part">
            {findPermInArray(roles, permManageDividing) && (
              <div className="actions-conditions">
                {isFillSets && (
                  <Button
                    disabled={details.completeInbound}
                    onClick={() => {
                      if(!status && sets && sets.length === 1 && sets[0].rest){
                        this.setState({
                          openModal: true
                        })
                      }else{
                        changeStatus()
                      }
                    }}
                    className={`with-icon ${
                      status ? "complete" : ""
                    }`}
                  >
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="#000000"
                        d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                      />
                    </svg>
                    {!status ? "Mark Complete" : "Completed"}
                  </Button>
                )}
              </div>
            )}

            {findPermInArray(roles, permManageDividing) && <Button onClick={this.handleExportDividingResult}>
              <svg className="standart-svg" viewBox="0 0 24 24">
                <path
                  fill="#000000"
                  d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"
                />
              </svg>
              Export to xls
            </Button>}
          </div>
        </div>
        <div className="content-po-tab">
          <DividingModule isExporting={isExporting} />
        </div>
        <CustomModalWithChildren
            open={this.state.openModal}
            handleClose={() => {
              this.setState({
                openModal: false
              })
            }}
            handleCancel={() => {
              this.setState({
                openModal: false
              })
            }}
            handleConfirm={() => {
              this.setState({
                openModal: false
              }, () => {
                changeStatus(details.id)
              })
            }}
        >
          <h6 className="center-h">There no sets configured!</h6>
          <h6 className="center-h">Completing with no dividing will create the single Package for all articles.</h6>
          <h6 className="center-h"><span role="img" aria-label="think">🤔</span> Are you sure you want to complete no sets configured?</h6>
        </CustomModalWithChildren>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    details: state.purchaseOrdersReducer.detailsPurchase,
    conditionSets: state.dividingReducer.conditionSets,
    sets: state.dividingReducer.conditionSets,
    status: state.dividingReducer.dividingStatus,
    roles: state.rolesReducer.roles
    // isProductsExist: state.dividingReducer.isProductsExist,
  };
};

const mapDispatchToProps = dispatch => ({
  changeStatus: (idPo) => dispatch(changeDividingStatus(idPo)),
  createSet: idPo => dispatch(createConditionsSet(idPo))
});

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
