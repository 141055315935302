export const permСhangeStatus = {
  access: "change_status",
  part: "service_desk"
};

export const permViewAll = {
  access: "show_all",
  part: "service_desk"
};

export const permViewReopen = {
  access: "reopen",
  part: "service_desk"
};

export const permViewDepartment = {
  access: "show_department",
  part: "service_desk"
};
