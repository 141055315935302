import React from 'react'
import moment from 'moment'
import Tooltip from '../../../../../../reuseComponent/Tooltips/index'

const FileItemComponent = props => {
    const {isShowDate, action,handleShowRemoveModal} = props
    return (
        <div className="history-item file">
            {isShowDate && <div className="date-history-action">
                {moment(action.createdAt).format('MMM DD, YYYY')}
            </div>
            }
            <div className="row-action">
                <span className="user-name-action">{action.user.name} {action.user.secondName}</span>
                <span className={`action-name`}>{action.text} </span>
                {action.file && <a href={action.file.fileUrl} className="file-link">{action.file.realName}</a>}
                <Tooltip text="Delete">
                        <span onClick={() => handleShowRemoveModal(action)} className="delete-icon">
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                            </svg>
                        </span>
                </Tooltip>
                <span className="time-action">{moment(action.createdAt,'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}</span>
            </div>
        </div>
    )
}

export default FileItemComponent