import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Formik} from 'formik'
import {schema} from '../createForm/schema'
import {getUsers} from '../../../../actions/users'
import {Form, Button} from 'react-bootstrap'
import DatePicker from '../../../reuseComponent/DatePicker/datepicker-with-children'
import adminInstance from '../../../instances/admininstance';
import {url} from '../../../../constants';
import Select from '../../../reuseComponent/Select/select-form'
import Modal from '../../../reuseComponent/modal'
import CreateSupplier from '../createForm/createSupplier'
import {setQualitiesAction} from '../../../../actions/suppliers'
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute';
import {history} from '../../../../history';
import {convertToFormData} from '../../../../reuseFunctions/objectToFormData';
import {notify} from '../../../reuseComponent/toast';
import Preloader from '../../../reuseComponent/preloader/preloader'
import { sortArrayByObjectKey } from '../../../../reuseFunctions/reorder'

class CreatePurchaseOrderForm extends PureComponent {

    state = {
        isShowSupplierModal: false,
        initValues: {
            supplier: null,
            supplierId: '',
            placementDate: '',
            offerNumber: '',
            buyerId: ''
        },
        suppliersOptions: [],
        isLoading: false
    }

    handleSubmit = values => {

        if (!values.placementDate)
            delete values.placementDate
        this.handleCreatePurchase(values)
    }

    componentDidMount() {
        const {getUsers, users} = this.props
        users.length === 0 && getUsers && getUsers()
        this.getSuppliers()
    }

    getSuppliers = () => {
        adminInstance.get(`${url}/supplier/list`)
            .then(res => {
                this.props.setQuality(res.data.data.quality.map(q => q.value))
                this.setState({suppliersOptions: [{
                    value: 'new',
                    label: 'Create new supplier'
                }, ...res.data.data.suppliers.map(sup => ({
                    value: sup.id,
                    label: sup.title
                }))]})
            })
    }

    handleCreatePurchase = values => {
        delete values.supplier
        const data = convertToFormData(values)
        this.setState({
            isLoading: true
        },() => {
            adminInstance.post(`${url}/purchase_order`, data)
                .then(res => {
                    changeRoutePush(`/atw/purchase-orders/${res.data.id}`, history)
                    notify('success', 'Purchase order successfully created')
                    this.setState({
                        isLoading: false
                    })
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
        })
    }

    render() {
        const {users,isLoadingDetails, handleCancel} = this.props
        const {isShowSupplierModal, initValues, suppliersOptions, isLoading} = this.state

        const sortSupplierOptions = (suppliersOptions) => {
            const sortedOptions = sortArrayByObjectKey(suppliersOptions);
            return sortedOptions;
        };

        return (
            <Formik
                onSubmit={this.handleSubmit}
                validationSchema={schema}
                initialValues={initValues}
                render={props => {
                    const {
                        handleSubmit,
                        values,
                        errors,
                        touched,
                        setValues,
                        setFieldValue,
                        handleChange
                    } = props
                    return (
                        <>
                            {/*<div className='details-po'>*/}
                            {/*    <div className="content-details">*/}
                                    {isLoadingDetails ? <Preloader/> : <div className="create-po-form">
                                        <Form onSubmit={handleSubmit} noValidate>
                                            <div className="row-po-form">
                                                <Form.Group>
                                                    <div className="wrapper-calendar-asana">
                                                        <DatePicker
                                                            onApply={(event, picker) => {
                                                                setFieldValue('placementDate', picker.startDate.format('DD.MM.YYYY'))
                                                            }}
                                                        >
                                                            <>
                                                                <svg viewBox="0 0 24 24">
                                                                    <path fill="#000000"
                                                                          d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z"/>
                                                                </svg>
                                                                <Form.Control
                                                                    readOnly={true}
                                                                    isInvalid={errors.placementDate && touched.placementDate}
                                                                    name="placementDate"
                                                                    style={{color: !values.placementDate ? '#B0BEC5' : '#616161'}}
                                                                    value={values.placementDate ? values.placementDate : 'Placement date'}
                                                                />
                                                                <Form.Control.Feedback
                                                                    type="invalid">{errors.placementDate}</Form.Control.Feedback>

                                                            </>
                                                        </DatePicker>
                                                        {values.placementDate && <svg onClick={e => {
                                                            setFieldValue('placementDate', '')
                                                            document.body.click()
                                                        }} className="circle-close" viewBox="0 0 24 24">
                                                            <path fill="#000000"
                                                                  d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/>
                                                        </svg>
                                                        }
                                                    </div>
                                                </Form.Group>
                                                <Form.Group>
                                                    {/*<Button onClick={() => this.setState({*/}
                                                    {/*isShowSupplierModal: true*/}
                                                    {/*})} className="with-icon">*/}
                                                    {/*<svg viewBox="0 0 24 24">*/}
                                                    {/*<path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />*/}
                                                    {/*</svg>*/}
                                                    {/*Add*/}
                                                    {/*</Button>*/}
                                                    <Select
                                                        className="with-add-button"
                                                        isInvalid={touched.supplierId && errors.supplierId}
                                                        isDisabled={suppliersOptions.length===0}
                                                        errorLabel={errors.supplierId}
                                                        placeholder="Select supplier"
                                                        options={sortSupplierOptions(suppliersOptions)}
                                                        value={values.supplier}
                                                        onChange={obj => {
                                                            if (obj.value === 'new') {
                                                                this.setState({
                                                                    isShowSupplierModal: true
                                                                })
                                                            }
                                                            else {
                                                                values.supplier = obj
                                                                values.supplierId = obj.value
                                                                setValues(values)
                                                            }

                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="row-po-form">
                                                <Form.Group>
                                                    <Form.Control
                                                        placeholder="Offer number"
                                                        name="offerNumber"
                                                        value={values.offerNumber}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Select
                                                        placeholder="Select responsible buyer"
                                                        name="buyerId"
                                                        options={sortSupplierOptions(users)}
                                                        onChange={obj => setFieldValue('buyerId', obj.value)}
                                                        value={users.find(user => user.id === values.buyerId)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <Form.Group className="footer-form footer-form-custom footer-with-margin">
                                                <Button variant="secondary" type="button" onClick={handleCancel}>Cancel</Button>

                                                <Button variant="primary" type="submit" disabled={isLoading}>Save</Button>
                                            </Form.Group>
                                        </Form>
                                        <Modal
                                            classNameModal="po-create-supp-modal"
                                            open={isShowSupplierModal}
                                            isFooter={false}
                                            Content={() => <CreateSupplier
                                                setSupplier={(supplier) => {
                                                    this.setState({
                                                        isShowSupplierModal: false
                                                    }, () => {
                                                        values.supplier = {
                                                            label: supplier.title,
                                                            value: supplier.id
                                                        }
                                                        values.supplierId = supplier.id
                                                        setValues(values)
                                                    })
                                                }}
                                                handleCancel={() => this.setState({
                                                    isShowSupplierModal: false
                                                })}
                                            />}
                                            handleClose={() => this.setState({
                                                isShowSupplierModal: false
                                            })}
                                            options={{
                                                centered: true,
                                                animation: false
                                            }}
                                        />
                                    </div>
                                    }
                            {/*    </div>*/}
                            {/*</div>*/}
                        </>
                    )
                }}
            />

        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.usersReducer.users.map(user => ({
            label: `${user.name} ${user.secondName}`,
            value: user.id
        })),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(getUsers()),
        setQuality: (qualities) => dispatch(setQualitiesAction(qualities))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePurchaseOrderForm)