import React, { PureComponent, createRef } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Select from "../../../reuseComponent/Select/select-form";
import TextareaAutosize from "react-textarea-autosize";
import AutosizeInput from "react-input-autosize";
import Tooltip from "../../../reuseComponent/Tooltips";
import moment from "moment";
import FormStatus from "../changeStatusForm";
import {
	permСhangeStatus,
	permViewReopen
} from "../../../../permissions/serviceDesk";
import { findPermInArray } from "../../../../reuseFunctions/checkPermission";
import {disabledSelectStyle} from "../../../reuseComponent/Select/disabledSelectStyling";
import CheckBox from "../../../reuseComponent/checkbox";
import { ReactComponent as SendIcon } from '../../../../assets/images/send.svg';
import { ReactComponent as SaveIcon } from '../../../../assets/images/content-save-edit-outline.svg';
import { getServiceDeskStatuses, getServiceDeskPriority, getServiceDeskTypes } from "../../../../services/serviceDesk";
import axios from 'axios';
import { getUsers } from '../../../../actions/users'

const findStatus = (statuses, alias) => {
	return statuses.find(status => status.alias === alias);
};

class BodyFormServiceDesk extends PureComponent {

	state = {
		priorities: [],
		statuses: [],
		types: []
	}

	inputFile = createRef();
	checkIfChangeData = () => {
		const { values, initialValues } = this.props;
		let res = false;
		Object.keys(initialValues).forEach(key => {
			try {
				if (
					JSON.stringify(values[key]) !==
					JSON.stringify(initialValues[key])
				) {
					res = true;
				}
			} catch (err) {}
		});
		return res;
	};

	handleChangePriority = priority => {
		this.props.setFieldValue("priority", priority);
	};

	setPriority = (isUrgent = false) => {
		const alias = isUrgent ? 'urgent' : 'low';
		const priority = this.state.priorities.find(e => (e.alias && e.alias === alias) ? e : null);
		this.handleChangePriority(priority ? priority : null);
	}

	componentDidMount() {
		axios.all([getServiceDeskPriority(), getServiceDeskTypes(), getServiceDeskStatuses()])
		.then(axios.spread((priorityRes, typesRes, statusesRes) => {
			this.setState({
				priorities: priorityRes.data,
				types: typesRes.data,
				statuses: statusesRes.data
			})
		}));

		const { getUsers, users } = this.props
        users.length === 0 && getUsers && getUsers()
	}

	render() {
		const {
			handleSubmit,
			values,
			setFieldValue,
			errors,
			touched,
			isView,
			cancelCreate,
			user,
			request,
			history,
			showConfirm,
			showConfirmAsana,
			handleDeleteRequest,
			roles,
			setValues,
			users,
		} = this.props;
		
		const {types, statuses} = this.state;

		const { createdAt, author } = this.props.values;
		const typesOptions = types.map(type => ({
			value: type.id,
			label: type.name
		}));
		const selectedType = typesOptions.find(
			type => type.value === values.typeId
		);
		const selectedResponsible = users.find(
			responsible => {
				if (responsible.value === values.responsibleId) {

					return true;
				} else if (
					values && values.responsiblePerson && values.responsiblePerson.name && values.responsiblePerson.secondName
				) {
					if (responsible.label === `${values.responsiblePerson.name} ${values.responsiblePerson.secondName}`) {
						return true;
					}
				}
			}
		);

		const isUrgent = request && request.priority && request.priority.alias === 'urgent';

		const isAuthor = author && author.name === user.info.name;
		const isAdmin = user.role.alias === "admin";
		const closeStatus = findStatus(statuses, "closed_canceled");
		const completeStatus = findStatus(statuses, "closed_completed");
		const sendToSupportStatus = findStatus(statuses, "sending");
		return (
			<Form onSubmit={handleSubmit} className="service-desk-form">
				<div className="service-desk-actions">
					<Button className="btn-back" onClick={cancelCreate}>
						<svg viewBox="0 0 24 24">
							<path
								fill="currentColor"
								d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
							/>
						</svg>
						Back
					</Button>

					{this.checkIfChangeData() && (
						<Form.Group className="footer-form footer-form-custom">
							<Button
								variant="secondary"
								className="with-icon"
								onClick={cancelCreate}
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								className="with-icon"
								onClick={e => {
									values.sendToSupport = false;
									handleSubmit(values);
								} }
							>
								<SaveIcon className="inactive-icon" />
								Save draft
							</Button>
							<Button
								variant="primary"
								className="with-icon ml-1"
								onClick={e => {
									values.sendToSupport = true;
									handleSubmit(values);
								}}
							>
								<SendIcon className="inactive-icon" />
								Send
							</Button>
						</Form.Group>
					)}
					
					<div className="service-desk-actions-inner">
						{((!isAdmin &&
							findPermInArray(roles, permСhangeStatus)) ||
							isAuthor ||
							isAdmin) &&
							request.status.alias === "done" && (
								<Button
									variant="secondary"
									className="with-icon"
									onClick={() =>
										showConfirm(
											{
												id: completeStatus.id,
												name: completeStatus.name
											},
											request.id
										)
									}
								>
									<svg
										viewBox="0 0 24 24"
										className="icon-button"
									>
										<path
											fill="#000000"
											d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
										></path>
									</svg>
									Complete
								</Button>
							)}
						{request.status.alias === "done" &&
							findPermInArray(roles, permViewReopen) && (
								<Button
									variant="secondary"
									className="with-icon"
									onClick={() =>
										showConfirm(
											{
												id: sendToSupportStatus.id,
												name: sendToSupportStatus.name
											},
											request.id
										)
									}
								>
									<svg
										className="icon-button"
										viewBox="0 0 24 24"
									>
										<path
											fill="currentColor"
											d="M12,3A9,9 0 0,0 3,12H0L4,16L8,12H5A7,7 0 0,1 12,5A7,7 0 0,1 19,12A7,7 0 0,1 12,19C10.5,19 9.09,18.5 7.94,17.7L6.5,19.14C8.04,20.3 9.94,21 12,21A9,9 0 0,0 21,12A9,9 0 0,0 12,3M14,12A2,2 0 0,0 12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12Z"
										/>
									</svg>
									Reopen
								</Button>
							)}
						{(request.status.alias === "sending" ||
							request.status.alias === "in_progress" ||
							request.status.alias === "info_requested") &&
							((!isAdmin &&
								findPermInArray(roles, permСhangeStatus)) ||
								isAuthor ||
								isAdmin) && (
								<Button
									variant="secondary"
									className="with-icon"
									onClick={() =>
										showConfirm(
											{
												id: closeStatus.id,
												name: closeStatus.name
											},
											request.id
										)
									}
								>
									<svg
										className="icon-button"
										viewBox="0 0 24 24"
									>
										<path
											fill="#696969"
											d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12C4,13.85 4.63,15.55 5.68,16.91L16.91,5.68C15.55,4.63 13.85,4 12,4M12,20A8,8 0 0,0 20,12C20,10.15 19.37,8.45 18.32,7.09L7.09,18.32C8.45,19.37 10.15,20 12,20Z"
										></path>
									</svg>
									Cancel request
								</Button>
							)}
						{values.status.name === "Draft" && isAuthor && (
							<Button
								variant="secondary"
								className="with-icon"
								onClick={() => handleDeleteRequest(request)}
							>
								<svg
									className="icon-button"
									viewBox="0 0 24 24"
								>
									<path
										fill="#000000"
										d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"
									></path>
								</svg>
								Delete
							</Button>
						)}
						{this.props.match.params.id !== "new" &&
							values.status.name === "Draft" &&
							isAuthor &&
							!this.checkIfChangeData() &&
							request.canSend && (
								<Button
									variant="secondary"
									className="send-btn with-icon"
									onClick={() => showConfirmAsana(request)}
								>
									<svg viewBox="0 0 24 24">
										<path
											fill="currentColor"
											d="M2,21L23,12L2,3V10L17,12L2,14V21Z"
										/>
									</svg>
									Send
								</Button>
							)}
					</div>
				</div>
				<div>
					<Row className="service-desk-row">
						{/*<div className="service-desk-inner">*/}
						<Col xs="12">
								
							<Row >
								<Col xs="10">
								<div className="service-desk-form-wrapper">
									<Form.Group className="form-item horizontal">
										<Select
											isDisabled={!isView}
											errorLabel={errors.typeId}
											isInvalid={
												touched.typeId && errors.typeId
											}
											name="typeId"
											placeholder={!isView ? '' : 'Select ticket Type'}
											value={
												selectedType
													? selectedType
													: null
											}
											styles={!isView ? disabledSelectStyle : {}}
											options={typesOptions}
											onChange={obj => {
												setFieldValue(
													"typeId",
													obj.value
												);
											}}
										/>
									</Form.Group>
								</div>
								</Col>
								{
								(this.props.match.params.id === "new" || request.status.alias === 'draft') && 
								<Col xs="2">		
								<div className="service-desk-form-wrapper">
										<Form.Group className="form-item horizontal">
											<Form.Label className="with-checkbox">Urgent</Form.Label>
											<div className="input-item input-item-priority">
												<CheckBox 
													options={{
														className: 'checkbox-material',
														disabled: !isView
													}}
													handleChange={(ev) => {
														this.setPriority(ev.target.checked);
													}}
													checked={isUrgent}
												/>
												{/* <PriorityComponent
													handleChangePriority={
														this.handleChangePriority
													}
													activePriority={
														values.priorityId
													}
													isView={isView}
													errors={errors}
													touched={touched}
												/>
												<span className="error-span">
													{touched.priorityId &&
														errors.priorityId}
												</span> */}
											</div>
										</Form.Group>
									</div>
								</Col>
								}
							</Row>
							<Row>
								<Col xs="12">
								<div className="service-desk-form-wrapper">
									 <Form.Group className="form-item horizontal"> 
										 {/* <Form.Label className={`${isView ? 'with-info' : ''} `}>{isView && (
											<Tooltip text="Example: “PO overview…”">
												<svg
													className="info-icon"
													viewBox="0 0 24 24"
												>
													<path
														fill="currentColor"
														d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z"
													/>
												</svg>
											</Tooltip>
										)} Title</Form.Label>  */}
										 <div className="input-item">
											<AutosizeInput
												disabled={!isView}
												maxLength={50}
												// placeholder="Enter Ticket Title"
												placeholder={!isView ? '' : 'Enter Ticket Title'}
												className={`auto-resize-wrapper ${isUrgent ? 'urgent' : ''}`}
												isInvalid={
													errors.title &&
													touched.title
												}
												name="title"
												type="text"
												value={values.title}
												onChange={event => {
													setFieldValue(
														"title",
														event.target.value
													);
												}}
											/>
											<span className="error-span">
												{touched.title && errors.title}
											</span>
										</div> 
									</Form.Group>
								</div>
								</Col>
								{((findPermInArray(roles, permСhangeStatus) &&
								!isAdmin) ||
								isAdmin) &&
								this.props.match.params.id !== "new" &&
								request.status.alias !== "closed_completed" &&
								request.status.alias !== "closed_canceled" &&
								request.status.alias !== "draft" && (
									<Col xs="6">
										<Form.Group className="form-item horizontal">
											<Form.Label className="with-select">Status</Form.Label>
											<div className="input-item">
												<FormStatus
													statuses={statuses}
													showConfirm={showConfirm}
													author={author}
												/>
											</div>
										</Form.Group>
									</Col>
							)}
							<Col xs="12">
								<div className="service-desk-form-wrapper">
									<Form.Group className="form-item horizontal">
										{/* <Form.Label className={`${isView ? 'with-info' : ''}`}>{isView && (
											<Tooltip text="Example: “At the moment it requires to export list into xls in order to get total amount of pcs. Would be great to see total pcs directly in totals of overview”">
												<svg
													className="info-icon"
													viewBox="0 0 24 24"
												>
													<path
														fill="currentColor"
														d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z"
													/>
												</svg>
											</Tooltip>
										)} Cover Letter</Form.Label> */}
										<div className="input-item">
										<TextareaAutosize
											className="auto-resize-wrapper auto-resize-textarea form-control"
											disabled={!isView}
											minRows={2}
											maxRows={15}
											placeholder={!isView ? '' : 'Enter Cover letter'}
											name="shortDescription"
											value={values.shortDescription}
											onChange={event => {
												setFieldValue(
													"shortDescription",
													event.target.value
												);
											}}
										/>
										</div>
									</Form.Group>
								</div>
							</Col>
							{this.props.match.params.id === "new" &&<Col xs={6}>
									<div className="service-desk-form-wrapper service-file-wrapper">
									<span className="form-text">
										<Button
											variant="secondary"
											className="with-icon"
											onClick={() =>
												this.inputFile.current.click()
											}
										>
											<svg
												enableBackground="new 0 0 512 512"
												height="24"
												viewBox="0 0 512 512"
												width="24"
											>
												<path d="m226 512c-49.626 0-90-40.374-90-90v-302c0-66.168 53.832-120 120-120s120 53.832 120 120v302h-30v-302c0-49.626-40.374-90-90-90s-90 40.374-90 90v302c0 33.084 26.916 60 60 60s60-26.916 60-60v-242c0-16.542-13.458-30-30-30s-30 13.458-30 30v242h-30v-242c0-33.084 26.916-60 60-60s60 26.916 60 60v242c0 49.626-40.374 90-90 90z" />
											</svg>
											Attach file
										</Button>
									</span>
										{values.files && values.files.length > 0 ? (
											<div className="files-list">
												<div className="files-list-inner">
													{values.files.map(
														(file, fileIndex) => (
															<div
																className="service-file-item"
																key={fileIndex}
															>
															<span>
																{file.name}
															</span>
																<Tooltip text="Delete">
																	<svg
																		onClick={() =>
																			setFieldValue(
																				"files",
																				values.files.filter(
																					(
																						item,
																						index
																					) =>
																						index !==
																						fileIndex
																				)
																			)
																		}
																		className="service-delete-file"
																		viewBox="0 0 24 24"
																	>
																		<path
																			fill="#000000"
																			d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
																		></path>
																	</svg>
																</Tooltip>
															</div>
														)
													)}
												</div>
											</div>
										) : null}
										<input
											multiple
											disabled={!isView}
											style={{ display: "none" }}
											type="file"
											ref={this.inputFile}
											onChange={e => {
												const files = Object.values(
													e.target.files
												);
												setFieldValue("files", [
													...values.files,
													...files
												]);
											}}
										/>
									</div>
								</Col>}
							</Row>
						</Col>
						{/*</div>*/}
					{/*</Row>*/}
					{/*<Row>*/}
					{/*	<div className="service-desk-inner">*/}
							{/* <Col xs="6">
								<div className="service-desk-form-wrapper">
									<Form.Group className="form-item horizontal">
										<Form.Label className={`${isView ? 'with-info' : ''}`}>{isView && (
											<Tooltip text="Example: “Save time when analysing total amount of pcs”">
												<svg
													className="info-icon"
													viewBox="0 0 24 24"
												>
													<path
														fill="currentColor"
														d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z"
													/>
												</svg>
											</Tooltip>
										)} Purpose</Form.Label>
										<div className="input-item">
											<AutosizeInput
												maxLength={100}
												isInvalid={
													errors.purpose &&
													touched.purpose
												}
												className="auto-resize-wrapper"
												placeholder={!isView ? '' : 'Enter purpose'}
												disabled={!isView}
												name="purpose"
												value={values.purpose}
												onChange={event => {
													setFieldValue(
														"purpose",
														event.target.value
													);
												}}
											/>
											<span className="error-span">
												{touched.purpose &&
													errors.purpose}
											</span>
										</div>
									</Form.Group>
								</div>
							</Col> */}

							
								{/* <Col xs="6">
									<div className="service-desk-form-wrapper">
										<Form.Group className="form-item horizontal">
											<Form.Label className="with-checkbox">Responsible</Form.Label>
											<div className="input-item input-item-priority">
												<Select
													isDisabled={!isView}
													isInvalid={touched.responsible && errors.responsible}
													errorLabel={errors.responsible}
													value={values.responsible}
													onChange={obj => {
														console.log('obj: ', obj)
														setFieldValue(
															'responsibleId',
															obj.value
														)
													}}
													placeholder={'Select responsible'}
													options={users}
												/>
											</div>
										</Form.Group>
									</div>
								</Col> */}
					{/*	</div>*/}
					{/*</Row>*/}
					{/*<Row>*/}
					{/*	<div*/}
					{/*		className="service-desk-inner"*/}
					{/*		style={*/}
					{/*			this.props.match.params.id === "new"*/}
					{/*				? { maxWidth: "70%" }*/}
					{/*				: {}*/}
					{/*		}*/}
					{/*	>*/}
							
						
							{/* {((findPermInArray(roles, permСhangeStatus) &&
								!isAdmin) ||
								isAdmin) &&
								this.props.match.params.id !== "new" &&
								request.status.alias !== "closed_completed" &&
								request.status.alias !== "closed_canceled" &&
								request.status.alias !== "draft" && (
									<Col xs="6">
										<Form.Group className="form-item horizontal">
											<Form.Label className="with-select">Status</Form.Label>
											<div className="input-item">
												<FormStatus
													statuses={statuses}
													showConfirm={showConfirm}
													author={author}
												/>
											</div>
										</Form.Group>
									</Col>
								)} */}
						{/*</div>*/}
					</Row>
				</div>
				{author && createdAt && (
					<p className="action-supp-row">
						<b>{history.length ? history[0].text : ""} </b>
						<b>
							{moment(createdAt, "YYYY-MM-DDTHH:mm").format(
								"MMM DD, YYYY, HH:mm"
							)}
						</b>{" "}
						by{" "}
						<b>
							{history.length && history[0].user
								? `${history[0].user.name} ${history[0].user.secondName}`
								: null}
						</b>
					</p>
				)}
			</Form>
		);
	}
}

const mapStateToProps = state => {
	return {
		// types: state.serviceDeskReducer.types,
		// statuses: state.serviceDeskReducer.statuses,
		history: state.serviceDeskReducer.history,
		user: state.usersReducer.currUser,
		request: state.serviceDeskReducer.request,
		roles: state.rolesReducer.roles,
		// priorities: state.serviceDeskReducer.priorities
		users: state.usersReducer.users.map(user => ({
            value: user.id,
            label: `${user.name} ${user.secondName}`,
            type: 'User'
        }))
	};
};

export default withRouter(connect(mapStateToProps, {
    getUsers
})(BodyFormServiceDesk));
