import { STOWING } from '../constants'
import { getPackages,getBoxes } from '../services/products'
import { notify } from '../components/reuseComponent/toast';

const setLoadingBoxesAction = isLoading => ({
    type: STOWING.SET_LOADING_BOXES,
    payload: isLoading
})

const setLoadingPackagesAction = isLoading => ({
    type: STOWING.SET_LOADING_PACKAGES,
    payload: isLoading
})

const setPackagesAction = packages => ({
    type: STOWING.SET_STOWING_PACKAGES,
    payload: packages
})

export const setBoxesAction = boxes => ({
    type: STOWING.SET_STOWING_BOXES,
    payload: boxes
})

const setActivePackageAction = (packageId) => ({
    type: STOWING.SET_ACTIVE_STOWED_PACKAGE,
    payload: packageId
})

export const setActivePackage = packageId => dispatch => {
    dispatch(setActivePackageAction(packageId))
    packageId && dispatch(getBoxesRequest(packageId))
}


export const getPackagesRequest = (orderId) => dispatch => {
    dispatch(setLoadingPackagesAction(true))
    getPackages(orderId)
        .then(res => {
            const filtredPackages = res.data.packages.filter(pack => pack.shortName !== 'REST')
            dispatch(isChangeDividingStatus(false))
            dispatch(setPackagesAction(filtredPackages))
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
        .finally(() => dispatch(setLoadingPackagesAction(false)))
}

export const getBoxesRequest = (packageId) => dispatch => {
    dispatch(setLoadingBoxesAction(true))
    getBoxes(packageId)
        .then(res => {
            dispatch(setBoxesAction(res.data))
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
        .finally(() => dispatch(setLoadingBoxesAction(false)))
}

export const cleartDataStowing = () => ({
    type: STOWING.CLEAR_STOWING_DATA
})

export const isChangeDividingStatus = isChange => ({
    type: STOWING.IS_CHANGE_DIVIDING,
    payload: isChange
})

// export const isPutProduct = (data) => ({
//     type: STOWING.IS_PUT_PRODUCT,
//     payload: data
// })
