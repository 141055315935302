export const selectValueToString = (arr, type) => {
  return arr && arr.length > 0 && arr.map(item => item[type]).join(", ");
};

export const selectStringToArr = str => {
  if (!str) return;
  const listValues = str.split(", ");
  const result = [];
  listValues.forEach(item => {
    let obj = {};
    obj.label = item;
    obj.value = item;
    result.push(obj);
  });
  return result;
};
