import React, { PureComponent } from 'react'
import { Switch } from 'react-router-dom'
import TableActivity from "./table"
import ProtectedRoute from '../../reuseComponent/Routes/protected-route'
import {permViewHistory} from "../../../permissions/settings";


class ActivityModule extends PureComponent{
    render(){
        return (
                <Switch>
                    <ProtectedRoute exact to="/ats/activity" permission={permViewHistory} component={TableActivity} />
                </Switch>
        )
    }
}

export default ActivityModule