import React ,  { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import adminInstance from '../../../../../instances/admininstance'
import { url } from '../../../../../../constants'
import Preloader from '../../../../../reuseComponent/preloader/preloader'


class TableAnalysis extends PureComponent{

    state = {
        data : [],
        isLoading: true
    }

    getData = () => {
        const { details } = this.props
        adminInstance.get(`${url}/analytics/summary/${details.id}/styleNumber/brand`)
            .then(res => {
                const data = res.data.slice(0,res.data.length - 2)
                this.setState({
                    data: data,
                    total: res.data[res.data.length - 2],
                    items: res.data[res.data.length - 1],
                    isLoading: false
                },() => console.log(this.state))
            })
    }

    componentDidMount(){
        const { snapshot } = this.props
        // console.log(JSON.parse(snapshot.analysis))
        if(!snapshot){
            this.getData()
        }
        else{
            const json = JSON.parse(snapshot.analysis)
            this.setState({
                data: json.summaryStyleNumberBrand.filter(row => typeof row === 'object'),
                isLoading: false,
                total: json.summaryStyleNumberBrand[json.summaryStyleNumberBrand.length - 2],
                items: json.summaryStyleNumberBrand[json.summaryStyleNumberBrand.length - 1],
            })
        }

    }

    render(){
        const { isLoading,data,total,items } = this.state
        return (
            isLoading ? <Preloader/> : <div className="wrapper-table-analysis">
                <Table size="sm">
                    <thead>
                    <tr>
                        <th>SKU Depth ({items})</th>
                        <th>Brand</th>
                        <th>Total Items ({total})</th>
                    </tr>
                    </thead>
                    <tbody>
                        {data.map(row => {
                            const keys = Object.values(row.values)
                            return <tr key={row.label}>
                                <td>{row.label}</td>
                                    <td>{keys.map(key => <div key={key}>{Object.keys(key).map(brandKey => <span style={{display: 'block'}} key={brandKey}>{brandKey} ({key[brandKey]})</span>)}</div>)}</td>
                                <td>{row.total}</td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        snapshot: state.purchaseOrdersReducer.snapshot
    }
}

export default connect(mapStateToProps,null)(TableAnalysis)