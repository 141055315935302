import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Table} from 'react-bootstrap'
import TotalRow from './total-row-with-products'
import DividerRow from './divider-row'
import EditedRow from './row-bid/edited-row'
import RowBid from './row-bid/index'
// import ReceivedRow from './received-row'
// import ResultRow from './result-row'
import {findPermInArray} from '../../../../../../../reuseFunctions/checkPermission'
import {permEditInfo, permFinanceInfo} from '../../../../../../../permissions/productOverview';

class TableBidsComponent extends PureComponent {

    render() {
        const { details,newBids,roles,refetchData } = this.props
        return (
            <div className="wrapper-bids-table">
                <Table size="sm" responsive >
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Commodity</th>
                            <th>Gender</th>
                            <th>Category</th>
                            <th>Brand</th>
                            <th>Product name</th>
                            <th>Quality</th>
                            <th>Channel</th>
                            <th className="custom-bids-th">{findPermInArray(roles, permFinanceInfo) && 'Negotiated € / Unit'}</th>
                            <th>Unit</th>
                            <th className="custom-bids-th">Pcs</th>
                            <th className="custom-bids-th">Packs</th>
                            <th className="custom-bids-th">Pallets</th>
                            <th className="custom-bids-th">Gross kg</th>
                            <th className="custom-bids-th">Net kg</th>
                            <th className="custom-bids-th">Actual pcs / kg</th>
                            <th className="custom-bids-th">{findPermInArray(roles, permFinanceInfo) && 'Credit note'}</th>
                            <th className="custom-bids-th">{findPermInArray(roles, permFinanceInfo) && 'Actual €/unit'}</th>
                            <th className='total-fix custom-bids-th'>{findPermInArray(roles, permFinanceInfo) && 'Total negotiated'}</th>
                            <th className='total-fix custom-bids-th'>{findPermInArray(roles, permFinanceInfo) && 'Total price'}</th>
                            <th className='total-fix custom-bids-th'>{findPermInArray(roles, permFinanceInfo) && 'Discount'}</th>
                            <th style={{width: 32}}></th>
                        </tr>
                    </thead>
                    <tbody>
                    {details.bids.length > 0 || newBids.length > 0 ? <>
                            {details.bids.map(bid => <RowBid refetchData={refetchData} key={bid.id} bid={bid} bidList={details.bids} />)}
                            {findPermInArray(roles, permEditInfo) && newBids.map(bid => <EditedRow refetchData={refetchData} idUBid={bid.id} values={{
                                gender: '',
                                category: '',
                                articleId: '',
                                quality: '',
                                channel: '',
                                pricePerUnit: '',
                                creditNote: '',
                                quantity: '',
                                actualQuantity: '',
                                packages: '',
                                pallets: '',
                                grossWeight: '',
                                netWeight: '',
                                totalPrice: '',
                                unit: '',
                                productName: ''
                            }} key={bid.id}/>)}
                        </>
                        : <tr>
                            <td colSpan={16}>
                                <div className="no-data-to-display">
                                    No data to display...
                                </div>
                            </td>
                    </tr>
                    }
                        <DividerRow migrated={details.migrated}/>
                        <TotalRow handleChangePurchase={this.props.handleChangePurchase}/>
                        {/*<ReceivedRow handleChangePurchase={this.props.handleChangePurchase}/>*/}
                        {/*<ResultRow/>*/}
                    </tbody>
                </Table>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return{
        details: state.purchaseOrdersReducer.detailsPurchase,
        newBids: state.poDetailsReducer.unconfirmedBids,
        roles: state.rolesReducer.roles,
        isEditMain: state.poDetailsReducer.isEditMain
    }
}

export default connect(mapStateToProps, null)(TableBidsComponent)