import React, { PureComponent } from 'react'


class ContainerTable extends PureComponent{
    render(){
        return (
            <div {...this.props} className="flat-table" ></div>
        )
    }
}

export default ContainerTable