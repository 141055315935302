import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Form} from 'react-bootstrap'
import Tooltip from '../../../../reuseComponent/Tooltips'
import AutosizeInput from "react-input-autosize";

class EditableCell extends PureComponent {

    state = {
        isEdit: false,
        value: ''
    }



    formRef = React.createRef()
    inputRef = React.createRef()

    clickOutside = e => {
        if (this.formRef.current && !this.formRef.current.contains(e.target)) {
            this.activeEditMode({
                isEdit: false,
                value: ''
            })
        }
    }


    activeEditMode = ({isEdit, value,callback}) => {
        this.setState(state => ({
            isEdit: isEdit,
            value: value ? value : ''
        }),() => {
            if(!this.state.isEdit)
                document.removeEventListener('click',this.clickOutside)
            else
                document.addEventListener('click',this.clickOutside)
            callback && callback()
        })
    }

    checkSizeValue = value => {
        let regExp = /^[A-Za-z0-9\-.*]*$/
            if(value)
                return regExp.test(value)
        return false
    }

    handleChange = e => {
        this.setState({
            value: e.target.value
        })
    }

    render() {
        const {type,handleConfirm,removeSizeType,isType, hasPermToEdit} = this.props
        const {isEdit, value} = this.state
        return (
            !isEdit ? <div className={hasPermToEdit ? "edit-title" : ""} onClick={() => hasPermToEdit && this.activeEditMode({
                    isEdit: true,
                    value: type.value
                })}>{type.value} {isType && hasPermToEdit && <Tooltip
                    text="Delete"
                >
                    <button onClick={e => {
                        e.stopPropagation()
                        removeSizeType()
                    }} className="remove-size-type-button">
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
                        </svg>
                    </button>
                </Tooltip>
                }
                </div>
                : <Form.Group ref={this.formRef}>
                    {/*<Form.Control*/}
                    {/*    isInvalid={value === '' || value === 'EU' || (!isType && !this.checkSizeValue(value))}*/}
                    {/*    value={value}*/}
                    {/*    onChange={this.handleChange}*/}
                    {/*/>*/}
                    <AutosizeInput
                        type="text"
                        className={`auto-resize-wrapper size-type ${value === '' || value === 'EU' || 
                        (!isType && !this.checkSizeValue(value)) ? 'with-error' : ''}`}
                        name="brand"
                        value={value}
                        onChange={this.handleChange}
                    />
                    <div className="actions-editable-type-cell">
                        <Tooltip
                            text="Save"
                        >
                            <button onClick={() => {
                                ((value && value !== 'EU' && isType) || (value && !isType && this.checkSizeValue(value))) && this.activeEditMode({
                                    isEdit: false,
                                    value: '',
                                    callback: () => handleConfirm(value)
                                })
                            }} className="action-button">
                                <svg viewBox="0 0 24 24">
                                    <path fill="#000000" d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
                                </svg>
                            </button>
                        </Tooltip>
                        <Tooltip
                            text="Cancel"
                        >
                            <button onClick={() => {
                                 this.activeEditMode({
                                    isEdit: false,
                                    value: ''
                                })
                            }} className="action-button">
                                <svg viewBox="0 0 24 24">
                                    <path fill="#000000" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                </svg>
                            </button>
                        </Tooltip>
                    </div>
                </Form.Group>

        )
    }
}

export default connect(null, null)(EditableCell)