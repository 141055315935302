export const permViewCg1Dictionary = {
    access: 'view_cg1',
    part: 'cg1'
};

export const permEditCg1Dictionary = {
    access: 'edit_cg1',
    part: 'cg1'
};

export const permViewCg2Dictionary = {
    access: 'view_cg2',
    part: 'cg2'
};

export const permEditCg2Dictionary = {
    access: 'edit_cg2',
    part: 'cg2'
};

export const permViewCg3Dictionary = {
    access: 'view_cg3',
    part: 'cg3'
};

export const permEditCg3Dictionary = {
    access: 'edit_cg3',
    part: 'cg3'
};

export const permViewQualitiesDictionary = {
    access: 'view_qualities',
    part: 'qualities'
};

export const permEditQualitiesDictionary = {
    access: 'edit_qualities',
    part: 'qualities'
};

export const permViewSizesDictionary = {
    access: 'view_sizes',
    part: 'sizes'
};

export const permEditSizesDictionary = {
    access: 'edit_sizes',
    part: 'sizes'
};

export const permViewChannelsDictionary = {
    access: 'view_channel',
    part: 'channel'
};

export const permEditChannelsDictionary = {
    access: 'edit_channel',
    part: 'channel'
};
export const permViewProductNamesDictionary = {
    access: 'view_product_names',
    part: 'product_names'
};

export const permEditProductNamesDictionary = {
    access: 'edit_product_names',
    part: 'product_names'
};

export const permViewTaskTemplateDictionary = {
    access: 'view_task_template',
    part: 'task_templates'
};

export const permEditTaskTemplateDictionary = {
    access: 'edit_task_template',
    part: 'task_templates'
}

export const permViewBrandsDictionary = {
    access: 'view_brand',
    part: 'brands'
}

export const permEditBrandsDictionary = {
    access: 'edit_brand',
    part: 'brands'
}

export const permViewArticles = {
    access: 'view_dic_articles',
    part: 'articles'
}

export const permEditArticles = {
    access: 'edit_dic_articles',
    part: 'articles'
}

export const permEditWorkPlaces = {
    part: 'workplaces',
    access: 'edit_workspaces'
}

export const permViewWorkPlaces = {
    part: 'workplaces',
    access: 'view_workspaces'
}