import React, { PureComponent } from "react";
import { connect } from "react-redux";
// import Tooltip from "../../../reuseComponent/Tooltips";
// import { changeRoutePush } from "../../../../reuseFunctions/changeRoute";
import { setData } from "../../../actions/admin-header";
// import { history } from "../../../../history";
import EditUser from "./edit";
import StandartLeftPanner from "../../reuseComponent/Header/standartLeftPannel";

class ProfileSettings extends PureComponent {
	componentDidMount() {
		const { setHeaderData } = this.props;
		setHeaderData({
			leftPannelData: {
				title: "Profile settings",
				logo: (
					<svg
						className="action-icon header-icon"
						viewBox="0 0 24 24"
					>
						<path
							fill="currentColor"
							d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
						/>
					</svg>
				)
			},
			LeftPannel: StandartLeftPanner,
			handleSearch: null,
			handleAdd: null
		});
	}
	render() {
		return (
			<div className="profile">
				<div className="content-details">
					<EditUser />
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		roles: state.rolesReducer.roles
	};
};

export default connect(mapStateToProps, { setHeaderData: setData })(
	ProfileSettings
);
