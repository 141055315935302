import * as yup from 'yup'


export const schema = yup.object().shape({
    name: yup.string().required('required field'),
    criterias: yup.array()
        .of(
            yup.object().shape({
                field: yup.string().required('required field'),
                criteria: yup.string().required('required field'),
                fieldCriteriaNameForm: yup.mixed(),
                value: yup.mixed().when(['fieldCriteriaNameForm'],{
                    is: fieldCriteriaNameForm => {
                        return fieldCriteriaNameForm && fieldCriteriaNameForm.alias !== 'empty' && fieldCriteriaNameForm.alias !== 'not_empty'
                    },
                    then: yup.string().required('required field'),
                    otherwise: yup.string()
                })
            })
        ).when(['orCriterias'],{
            is: orCriterias => {
                return orCriterias.length > 0
            },
            then: yup.array(),
            otherwise: yup.array().min(1,'Please create at least one condition')
        }),
    orCriterias: yup.array()
        .of(
            yup.object().shape({
                field: yup.mixed().required('required field'),
                criteria: yup.string().required('required field'),
                fieldCriteriaNameForm: yup.mixed(),
                value: yup.mixed().when(['fieldCriteriaNameForm'],{
                    is: fieldCriteriaNameForm => {
                        return fieldCriteriaNameForm && fieldCriteriaNameForm.alias !== 'empty' && fieldCriteriaNameForm.alias !== 'not_empty'
                    },
                    then: yup.string().required('required field'),
                    otherwise: yup.string()
                })
            })
        )
})