import React, { useCallback, useEffect, useState, useRef, } from 'react'
import { Form, Button } from 'react-bootstrap'
import AutosizeInput from 'react-input-autosize'
import classNames from 'classnames'

import Switcher from '../../../../reuseComponent/Switcher'
import { colors } from '../../constants'

import { ReactComponent as CheckIcon } from '../../../../../assets/images/check.svg'

// const Option = ({children, ...props}) => {
//   const { value } = props;
//   return (
//     <components.Option {...props}>
//       <div className="color-option" style={{backgroundColor: value}}></div>
//     </components.Option>
//   )
// }

const CreateInstrumentFormBody = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleSubmit,
  handleCancel,
                                  }) => {
  // const options = useMemo(() => {
  //   return colors.map(color => ({
  //     value: color,
  //     label: color,
  //   }))
  // }, [])

  const [isOpen, setIsOpen] = useState(false)

  const dropdownRef = useRef(null)

  const handleClickOutside = useCallback((e) => {
    if(isOpen && dropdownRef && !dropdownRef.current.contains(e.target)){
      setIsOpen(false)
    }
  }, [isOpen])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <Form
      noValidate
      onSubmit={handleSubmit}
      className="create-instrument-form"
    >
      <div className="create-instrument-form-row">
        <Form.Group className="space-val">
          <Form.Label>Space</Form.Label>
          <AutosizeInput
            placeholder="Enter space name"
            className="auto-resize-wrapper"
            value={values.name}
            name='name'
            onChange={e => {
              setFieldValue('name', e.target.value)
            }}
          />
          <span className="error-label">{touched.name && errors.name}</span>
        </Form.Group>
        <Form.Group className="color-val">
          <Form.Label className="with-select">Color</Form.Label>
          <div className="color-dropdown" ref={dropdownRef}>
            <div className="color-dropdown-toggle" onClick={() => setIsOpen(prevState => !prevState)}>
              <div className="color" style={{backgroundColor: values.color}}></div>
              <svg className="down-icon" viewBox="0 0 24 24">
                <path
                  fill="#000000"
                  d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                ></path>
              </svg>
            </div>
            <div className={classNames("colors-list-picker", {"opened": isOpen})}>
              {colors.map(color => <div
                onClick={() => {
                  setFieldValue('color',color)
                  setIsOpen(false)
                }}
                className="project-color-block" key={color}
                style={{backgroundColor: color}}
              >
                {values.color === color && <CheckIcon className="standart-svg"/>}
              </div>)}
            </div>
            <Form.Control
              type="hidden"
              onChange={e => setFieldValue('icon', e.target.value)}
              value={values.color}
              isInvalid={errors.color && touched.color}
            />
          </div>
          {/*<CustomSelect*/}
          {/*  name="color"*/}
          {/*  value={options.find(item => item.value === values.color)}*/}
          {/*  onChange={obj => setFieldValue('color', obj.value)}*/}
          {/*  options={options}*/}
          {/*  components={{Option}}*/}
          {/*/>*/}
        </Form.Group>
        <Form.Group className="slot-val">
          <Form.Label className="with-select">Slot</Form.Label>
          <Switcher
            checked={values.slot}
            onChange={e => setFieldValue('slot', e)}
          />
        </Form.Group>
      </div>
      <Form.Group className="footer-form footer-form-custom">
        <Button variant="secondary" type="button" onClick={handleCancel}>Cancel</Button>
        <Button variant="primary" type="submit">Save</Button>
      </Form.Group>
    </Form>
  )
}

export { CreateInstrumentFormBody }