import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import InboundReport from '../../products/inbound-report'
import {Button} from 'react-bootstrap';
import adminsInstance from '../../../../instances/admininstance';
import {url} from '../../../../../constants';
import moment from 'moment';
import {notify} from '../../../../reuseComponent/toast';
import {SearchInboundReportComponent} from '../../products/inbound-report/searchComponent';
import { findPermInArray } from '../../../../../reuseFunctions/checkPermission'
import {permExportXlsInbounding} from "../../../../../permissions/productOverview";
import {LastScannedItem} from '../../products/inbound-report/lastScannedItem'
import './style.scss'


class InboundReportComponent extends PureComponent{

    state = {
        isExporting: false
    }

    handleExportInboundReport = e => {
        e.preventDefault()
        if(!this.state.isExporting){
            const { details } = this.props
            this.setState({
                isExporting: true
            },() => {
                adminsInstance({
                    method: 'GET',
                    responseType: 'blob',
                    url: `${url}/purchase_order/${details.id}/export_inbound_report`
                })
                    .then(response => {
                        // const date = moment().format('YYYY-MM-DDTHH:mm')
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `WCS. PO-${details.orderNumber}. Inbound report.xls`);
                        // link.setAttribute('download', `${details.orderNumber}_inbound_report_${date}.xls`);
                        document.body.appendChild(link);
                        link.click();
                        this.setState({
                            isExporting: false
                        })
                    })
                    .catch(err => {
                        this.setState({
                            isExporting: false
                        })
                        if(details.totalLocations >= 48) {
                            notify('error','Inbound report can not be exported due to limits in xls (should be less than 47 packages)')
                        } else {
                            notify('error','Oops something went wrong')
                        }
                    })
            })
        }

    }

    render(){
        const { isExporting }  = this.state
        const { packagesCount, roles, details } = this.props
        return (
            <div className="po-tab-content">
                <div className="header-po-tab">
                    <div className="left-part">

                    </div>
                    <div className="central-part">
                        {details.inboundType !== 'Totals' && <SearchInboundReportComponent />}
                    </div>
                    <div className="right-part inbound-report-right-part">
                        <LastScannedItem />
                        {packagesCount > 0 && findPermInArray(roles, permExportXlsInbounding)
                        && details.inboundType !== 'Totals' && <Button onClick={this.handleExportInboundReport}>
                            <svg className="standart-svg" viewBox="0 0 24 24">
                                <path fill="#000000" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"/>
                            </svg>
                            Export to xls
                        </Button>
                        }
                    </div>
                </div>
                <div className="content-po-tab">
                    <InboundReport
                        isExporting={isExporting}
                        actionsComponent={null}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        packagesCount: state.inboundingReportReducer.packages.filter(pack => pack.shortName !== 'Rest').reduce((acc, pack) => {
            if (pack.actual_items)
                return acc + pack.actual_items
            return acc
        }, 0),
        details: state.purchaseOrdersReducer.detailsPurchase,
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps,null)(InboundReportComponent)