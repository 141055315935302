import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Table} from 'react-bootstrap'
import { isChangingSetLoading } from '../../../../../../../actions/dividing'

class TableProducts extends PureComponent {

    // componentDidUpdate(){
    //     const { isChangingSetLoading } = this.props
    //     isChangingSetLoading(false)
    // }
    //
    // componentDidMount(){
    //     const { isChangingSetLoading } = this.props
    //     isChangingSetLoading(false)
    // }

    render() {
        const { products } = this.props
        return (
            <Table size="sm">
                <thead>
                    <tr>
                        <th>EAN</th>
                        <th>SKU</th>
                        <th>SIZE</th>
                        {/*<th>CG1</th>*/}
                        <th>CG2</th>
                        <th>CG3</th>
                        <th>CG4</th>
                        <th>SEASON</th>
                        <th>BRAND</th>
                        <th>RRP</th>
                        <th>Items</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(productObj => {
                        const { product } = productObj
                        return (
                            <tr key={productObj.id}>
                                <td>{product.ean}</td>
                                <td>{product.styleNumber}</td>
                                <td>{product.size}</td>
                                {/*<td>{product.cg1}</td>*/}
                                <td>{product.cg2}</td>
                                <td>{product.cg3}</td>
                                <td>{product.cg4}</td>
                                <td>{product.season}</td>
                                <td>{product.brand}</td>
                                <td>{product.rrp}</td>
                                <td>{productObj.items}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }
}



export default connect(null, {
    isChangingSetLoading: isChangingSetLoading
})(TableProducts)