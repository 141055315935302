import { ROLES } from '../constants'

const initState = {
    roles: [],
    isLoading: false,
    isLoaded: false,
    userRole: ''
}


export const rolesReducer = (state = initState,action) => {
    switch (action.type) {
        case ROLES.SET_ROLES: {
            return {
                ...state,
                userRole: action.userRole,
                roles: action.roles,
                isLoaded: action.isLoaded
            }
        }
        default:
            return state
    }
}