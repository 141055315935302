import { SERVICE_DESK } from "../constants";
import {
  getServiceDeskStatuses,
  getServiceDeskTypes,
  getServiceDeskRequest,
  getServiceDeskPriority,
  fetchToAsana
} from "../services/serviceDesk";
import {
  convertToFormData,
  convertToFormDataWithSingleArray
} from "../reuseFunctions/objectToFormData";
import { notify } from "../components/reuseComponent/toast";
import adminsInstance from "../components/instances/admininstance";
import { url } from "../constants";

const loadingAction = isLoading => ({
  type: SERVICE_DESK.IS_LOADING_SERVICE_DESK,
  payload: isLoading
});

const setStatusesAction = statuses => ({
  type: SERVICE_DESK.SET_SERVICE_DESK_STATUSES,
  payload: statuses
});

const setPrioritiesAction = priorities => ({
  type: SERVICE_DESK.SET_SERVICE_DESK_PRIORITIES,
  payload: priorities
});

const setTypesAction = types => ({
  type: SERVICE_DESK.SET_SERVICE_DESK_TYPES,
  payload: types
});

const setRequestAction = request => ({
  type: SERVICE_DESK.SET_SERVICE_DESK_REQUEST,
  payload: request
});

export const setStatusAction = status => ({
  type: SERVICE_DESK.SET_STATUS,
  payload: status
});

export const clearRequest = () => ({
  type: SERVICE_DESK.CLEAR_REQUEST
});

const loadingServiceDeskHistoryAction = isLoading => ({
  type: SERVICE_DESK.IS_LOADING_SERVICE_DESK_HISTORY,
  payload: isLoading
});

export const setServiceDeskHistory = history => ({
  type: SERVICE_DESK.SET_HISTORY_SD,
  payload: history
});

export const changeActiveSDHistoryTab = tabName => ({
  type: SERVICE_DESK.CHANGE_ACTIVE_HISTORY_SD_TAB,
  payload: tabName
});

const addHistorySDItem = historyItem => ({
  type: SERVICE_DESK.ADD_HISTORY_SD_ITEM,
  payload: historyItem
});

const addHistoryFiles = files => ({
  type: SERVICE_DESK.ADD_HISTORY_SD_FILES,
  payload: files
});

export const getStatuses = () => dispatch => {
  getServiceDeskStatuses().then(res => {
    dispatch(setStatusesAction(res.data));
  });
};

export const getPriorities = () => dispatch => {
  getServiceDeskPriority().then(res => {
    dispatch(setPrioritiesAction(res.data));
  });
};

export const getTypes = () => dispatch => {
  getServiceDeskTypes().then(res => {
    dispatch(setTypesAction(res.data));
  });
};

export const getCurrentRequest = id => dispatch => {
  dispatch(loadingAction(true))
  getServiceDeskRequest(id).then(res => {
    //
    const formatedData = {
      ...res.data,
      typeId: res.data.type.id,
      priorityId: res.data.priority
    };

    dispatch(setRequestAction(formatedData));
    dispatch(setServiceDeskHistory(formatedData.history));
    dispatch(loadingAction(false))
  });
};

export const createSDRequest = (values, callback, errorCallback) => dispatch => {
  values.priorityId = values.priority ? values.priority.id : '';
  delete values.priority;

  const formData = convertToFormDataWithSingleArray(
    {
      ...values,
      reqUrl: window.location.origin + "/atw/service_desk"
    },
    true
  );

  dispatch(loadingAction(true));

  return adminsInstance
    .post(`${url}/service_desk`, formData)
    .then(res => {
      dispatch(loadingAction(false));
      if(values.sendToSupport === true) {
        notify("success", "Request successfully sent to support");
      } else { 
        notify("success", "Request successfully created");
      }
      callback(res.data);
    })
    .catch(err => {
      dispatch(loadingAction(false));
      if(err && err.response && err.response.status === 400 && err.response.data === 'Request with this name is already exists, please change title'){
        notify('error', 'Request with this name is already exists, please change title')
        errorCallback(values)
      }else
        notify("error", "Oops something went wrong");
    });
};

export const editRequest = (values, callback, errorCallback) => dispatch => {
  const { createdAt, author, id, history, status, ...rest } = values;
  delete rest.type;

  let data = {
    ...rest,
    reqUrl: window.location.origin + "/atw/service_desk",
    typeId: values.typeId,
    priorityId: values.priority.id
  };

  delete data.priority;

  const formData = convertToFormData(data);

  dispatch(loadingAction(true));

  return adminsInstance
    .put(`${url}/service_desk/${values.id}/edit`, formData)
    .then(res => {
      dispatch(loadingAction(false));
      notify("success", "Request successfully edited");

      callback(res.data);
      
      const formatedData = {
        ...res.data,
        typeId: res.data.type.id,
        priority: res.data.priority
      };

      dispatch(setRequestAction(formatedData));
      dispatch(setServiceDeskHistory(formatedData.history));
    })
    .catch(err => {
      dispatch(loadingAction(false));
      if(err && err.response && err.response.status === 400 && err.response.data === 'Request with this name is already exists, please change title'){
        notify('error', 'Request with this name is already exists, please change title')
        errorCallback(values)
      }else
        notify("error", "Oops something went wrong");
    });
};

export const sendToAsana = (currRequest, callback, param) => dispatch => {
  fetchToAsana(currRequest.id, {
    id: currRequest.id,
    reqUrl: window.location.origin + "/atw/service_desk"
  })
    .then(res => {
      notify("success", "Request successfully sent to support");
      !param && callback(currRequest, false)
      param && getServiceDeskRequest(currRequest.id).then(res => {
        //
        const formatedData = {
          ...res.data,
          typeId: res.data.type.id,
          priorityId: res.data.priority
        };
    
        
        callback(res.data)
        dispatch(setRequestAction(formatedData));
        dispatch(setServiceDeskHistory(formatedData.history));
      });
    })
    .catch(err => {
      notify("error", "Oops something went wrong");
    });
};

export const uploadFileToSDHistory = files => {
  return (dispatch, getState) => {
    const request = getState().serviceDeskReducer.request;
    dispatch(loadingServiceDeskHistoryAction(true));

    adminsInstance
      .post(
        `${url}/service_desk/${request.id}/add_file`,
        convertToFormDataWithSingleArray(
          {
            files: files,
            reqUrl: window.location.origin + "/atw/service_desk"
          },
          true
        ),
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
      .then(res => {
        notify("success", "File successfully uploaded");
        dispatch(addHistoryFiles(res.data));
      })
      .catch(err => {
        notify("error", "Oops something went wrong");
      })
      .finally(() => dispatch(loadingServiceDeskHistoryAction(false)));
  };
};

export const addSDComment = ({ id, text }) => {
  return dispatch => {
    const data = {
      reqUrl: window.location.origin + "/atw/service_desk",
      comment: text
    };
    dispatch(loadingServiceDeskHistoryAction(true));
    adminsInstance
      .post(`${url}/service_desk/${id}/add_comment`, convertToFormData(data))
      .then(res => {
        notify("success", "Сomment successfully left");
        dispatch(addHistorySDItem(res.data));
      })
      .catch(err => {
        console.log(err);
        notify("error", "Oops something went wrong");
      })
      .finally(() => {
        dispatch(loadingServiceDeskHistoryAction(false));
      });
  };
};
