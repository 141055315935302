import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Table} from 'react-bootstrap'


class TableAnalysis extends PureComponent {

    render() {
        const {label,data} = this.props
        return (
            <div className="wrapper-table-analysis">
                <Table size="sm">
                    <thead>
                    <tr>
                        <th>{label}</th>
                        <th>Total Items</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map(topic => {
                        if(typeof topic !== 'object') return null
                        return <React.Fragment key={topic.label}>
                        <tr className="caption-row">
                            <td>{topic.label}</td>
                            <td>{topic.total}</td>
                        </tr>
                        {topic.values.map(valRow => {
                            const keys = Object.keys(valRow)
                            return keys.length > 0 ? <tr className="value-row" key={keys[0]}>
                                <td>{keys[0]}</td>
                                <td>{valRow[keys[0]]}</td>
                            </tr> : null
                        })}
                    </React.Fragment>
                    })}
                    </tbody>
                </Table>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        orderId: state.analysisToolReducer.orderId,
    }
}

export default connect(mapStateToProps, null)(TableAnalysis)