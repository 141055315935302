import React , { PureComponent } from 'react'
import { privileges } from '../privileges'
import Tooltip from '../../../reuseComponent/Tooltips'
import {Form} from 'react-bootstrap'
import * as uniqId from 'uniqid'

import './style.scss'


class ListPrivileges extends PureComponent{

    state = {
        open: false,
        list: [],
    }

    openHideList = (open,callback) => {
        this.setState({
            open: open
        },() => callback && callback())
    }

    refDropDown = React.createRef()

    componentDidMount() {
        // document.addEventListener('mousedown', this.handleClickOutside);
        const {activeStatus} = this.props;
        // console.log(activeStatus);
        activeStatus && this.setState({list: activeStatus.map(item => item.privilege)})
    }

    // componentWillUnmount() {
    //     document.removeEventListener('mousedown', this.handleClickOutside);
    // }

    componentDidUpdate(prevProps){
        const { hasAccessToEdit } = this.props
        const prevHasAccessToEdit = prevProps.hasAccessToEdit
        if(hasAccessToEdit !== prevHasAccessToEdit){
            if(!hasAccessToEdit){
                this.setState({
                    open: false
                })
            }
        }
    }

    // hasView = list => {
    //     return list.filter(item => item.indexOf('view') === 0).length > 0
    // }

    handleClickOutside = event => {
        const {changePrivilege, activeStatus} = this.props;
        this.openHideList(false, () => {
            if(JSON.stringify(activeStatus.map(item => item.privilege).sort()) !== JSON.stringify(this.state.list.sort())){
                const permissionsListStaged = [...new Set(this.state.list)]
                changePrivilege(permissionsListStaged);
            }
        })
    }

    handleChange = (e) => {
        const name = e.target.name;
        const privilegesItem = this.props.listPrivileges.find(item => item.alias === name)
        const enableAliases = privilegesItem.enableAliases && privilegesItem.enableAliases.split(',')
        const disableAliases = privilegesItem.disableAliases && privilegesItem.disableAliases.split(',')
        // if(this.state.list.indexOf(name) === -1){
        //     if(!this.hasView(this.state.list) && name.indexOf('view') !== 0){
        //         const view = this.props.listPrivileges.filter(item => item.alias.indexOf('view') !== -1);
        //         const viewText = view && view[0] && view[0].alias ? [view[0].alias] : [];
        //         this.setState(prevState => ({list: [...prevState.list, name, ...viewText]}))
        //     }else{
        //         this.setState(prevState => ({list: [...prevState.list, name]}))
        //         console.log('enable')
        //     }
        // }else{
        //     if(name.indexOf('view') === 0){
        //         this.setState({list: []})
        //     }else{
        //         console.log('disable')

        //         this.setState(prevState => ({list: prevState.list.filter(item => item !== name)}))
        //     }
        // }

        if(this.state.list.indexOf(name) === -1){
            this.setState(prevState => ({list: [...prevState.list, name]}))
            if(enableAliases) {
                enableAliases.forEach(alias => {
                    this.setState(prevState => ({list: [...prevState.list, alias]}))
                })
            }
        } else {
            this.setState(prevState => ({list: prevState.list.filter(item => item !== name)}))
            if(disableAliases && name != 'view_all_customers') {
                disableAliases.forEach(alias => {
                    this.setState(prevState => ({list: prevState.list.filter(item => item !== alias)}))
                })
            }

        }

        this.handleClickOutside();

    };

    render(){
        const { activeStatus,listPrivileges, hasAccessToEdit } = this.props
        const { open, list } = this.state;

        return (
            <div className="wrap-list-privileges">
                <div className="roles-list">
                        {listPrivileges.map(privilege => <div
                            key={privilege.name}
                        >
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check
                                    custom
                                    id={`check-${uniqId()}`}
                                    checked={list.indexOf(privilege.alias) === -1 ? false : true}
                                    name={privilege.alias}
                                    type="checkbox"
                                    label={privilege.name}
                                    onChange={hasAccessToEdit ? (e) => this.handleChange(e) : null}
                                />
                            </Form.Group>
                        </div>)}
                    {/*<button*/}
                        {/*disabled={!hasAccessToEdit}*/}
                        {/*onClick={hasAccessToEdit ? () => this.openHideList(!open) : null}*/}
                        {/*className="active-privilege-button"*/}
                        {/*// dangerouslySetInnerHTML={{__html: currPrivilige.icon}}*/}
                    {/*>*/}
                        {/*asdasd*/}
                    {/*</button>*/}
                </div>
                {/*<Tooltip*/}
                {/*    text={activeStatusObject.label}*/}
                {/*>*/}
                {/*    /!*<div>*!/*/}
                {/*    /!*    *!/*/}
                {/*    /!*</div>*!/*/}
                {/*    <button disabled={!hasAccessToEdit} onClick={hasAccessToEdit ? () => this.openHideList(!open) : null} className="active-privilege-button" dangerouslySetInnerHTML={{__html: activeStatusObject.icon}}></button>*/}
                {/*</Tooltip>*/}
                <ul className={`list-privilege ${open ? 'open' : ''}`}>
                    {listPrivileges.map(privilege => <li
                        key={privilege.name}
                        // onClick={() => {
                        //     this.openHideList(false,() => changePrivilege(privilege.alias))
                        // }}
                    >
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check
                                custom
                                id={`check-${uniqId()}`}
                                checked={list.indexOf(privilege.alias) === -1 ? false : true}
                                name={privilege.alias}
                                type="checkbox"
                                label={privilege.name}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        {/*{privilege.name}*/}
                    </li>)}
                </ul>
            </div>
        )
    }
}

export default ListPrivileges