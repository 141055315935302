import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import CustomCheckBox from '../../../reuseComponent/checkbox';
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'
import { permEditInventory } from '../../../../permissions/inventory'

const ProductsFromBox = React.memo(({products, boxClosed,handleSelect,selectedProducts,selectUnselectAll}) => {
    const roles = useSelector(state => state.rolesReducer.roles)

    const hasPermToEdit = useMemo(() => {
        return findPermInArray(roles, permEditInventory)
    }, [roles])

    return <table className="table table-sm">
        <thead>
        <tr>
            {!boxClosed && hasPermToEdit && products.length > 0 && <th>
                <CustomCheckBox
                    checked={selectedProducts.length === products.length}
                    handleChange={selectUnselectAll}
                    options={{
                        className: "checkbox-material",
                    }}
                />
            </th>}
            <th>EAN</th>
            <th>SKU</th>
            <th>Size</th>
            <th>CG1</th>
            <th>CG2</th>
            <th>CG3</th>
            <th>Season</th>
            <th>Brand</th>
            <th>Quality</th>
            <th>Quantity</th>
        </tr>
        </thead>
        <tbody>
        {products.map(item => {
            return <tr key={item.id}>
                {!boxClosed && hasPermToEdit && <td>
                    <CustomCheckBox
                        checked={selectedProducts.includes(item.id)}
                        handleChange={() => handleSelect(item.id)}
                        options={{
                            className: "checkbox-material",
                        }}
                    />
                </td>}
                <td>{item.ean}</td>
                <td>{item.styleNumber}</td>
                <td>{item.size}</td>
                <td>{item.cg1}</td>
                <td>{item.cg2}</td>
                <td>{item.cg3}</td>
                <td>{item.season}</td>
                <td>{item.brand}</td>
                <td>{item.quality}</td>
                <td>{item.count}</td>
            </tr>
        }
        )}
        </tbody>
    </table>
})

export default ProductsFromBox