import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Preloader from "../../../reuseComponent/preloader/preloader";
import ActionComponent from "./action";
import moment from "moment";
import CommentComponent from "./comment-item/index";
import {setHistory} from "../../../../actions/tasks";
import Comment from './comment-editor/comment-editor'

class HistoryList extends PureComponent {
  state = {
    modalDiff: false,
    diff: null,
    modalRemoveFile: false,
    currFile: null
  };

  handleShowRemoveFileModal = action =>
    this.setState({ modalRemoveFile: true, currFile: action });

  handleHideRemoveFileModal = () =>
    this.setState({ modalRemoveFile: false, currFile: null });

  showHideModal = (open, diff, callback) => {
    this.setState(
      {
        modalDiff: open,
        diff: diff
      },
      () => callback && callback()
    );
  };

  checkIfContainsType = type => {
    const types = ["ACTION", "COMMENT"];
    return types.includes(type);
  };

  getFilteredHistory = (history, activeType) => {
    //dispatch(setHistory(history));
    switch (activeType) {
      case "CHRONOLOGY": {
        return history.filter(item => !item.text.includes(': '));
      }
      case "COMMENT": {
        return history.filter(item => item.text.includes(': '));
      }
    }
  };

  render() {
    const { isLoading, history, request, activeTab } = this.props;
    let lastDate = null;
    let lastType = null;

    
    const filteredHistory = this.getFilteredHistory(history, activeTab);
    console.log('filteredHistoryfilteredHistory',filteredHistory)
    return (
      <>
        <div className="list-history">
          {isLoading ? (
            <Preloader />
          ) : (
            filteredHistory.map((item, index) => {
              const isShowDate =
                !lastDate ||
                !lastType ||
                (this.checkIfContainsType(item.type) &&
                  lastDate &&
                  !moment(item.createdAt).isSame(lastDate, "day"));
              lastDate = item.createdAt;
              lastType = item.type;

              switch (item.type) {

                case "TASK_ACTION": {
                  return (
                    item.text && (
                      <CommentComponent
                        key={item.createdAt + item.text}
                        comment={item}
                        request={request}
                        isShowDate={isShowDate}
                      />
                    )
                  );
                }
                default: {
                  return (
                      <ActionComponent
                          showDiff={this.showHideModal}
                          key={item.createdAt + item.text}
                          action={item}
                          isShowDate={isShowDate}
                          request={request}
                      />
                  );
                }
              }
            })
          )}
        </div>

        {activeTab === "COMMENT" && <>
          <Comment ticketId={history && history[0] && history[0].taskId}/>
        </>}

      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    history: state.ticketsReducer.history,
    request: state.ticketsReducer.request,
    comment: state.ticketsReducer.comment,
    isLoading: state.ticketsReducer.isLoadingHistory,
    activeTab: state.ticketsReducer.activeTabHistory
  };
};

export default connect(mapStateToProps, null)(HistoryList);
