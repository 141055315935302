import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Table from '../../../reuseComponent/Table/MaterialTable'
import adminInstance from '../../../instances/admininstance'
import {url} from '../../../../constants'
import DropDownMenu from '../../../reuseComponent/DropDown/index'
import {setData} from '../../../../actions/admin-header'
import Modal from '../../../reuseComponent/modal/index'
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import DepartmentForm from '../create-department/index'
import {removeDepartment, setDepartmentAction} from '../../../../actions/departments'
import {notify} from '../../../reuseComponent/toast'
import AsyncSelect from '../../../reuseComponent/Select/async-select'
import {Form} from 'react-bootstrap'
import {findPermInArray} from "../../../../reuseFunctions/checkPermission";
import {permEditDepartmentsDictionary} from "../../../../permissions/settings";
// import StandartLeftPanner from "../../../reuseComponent/Header/standartLeftPannel";
import Toolbar from '../Toolbar'
import Header from '../../../reuseComponent/Table/table-head'
import Pagination from '../../../reuseComponent/Table/Pagination'
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import TabsSettings from "../../settings/tabs"
import withTracker from "../../../reuseComponent/GoogleAnalytics";


class DepartmentsList extends PureComponent {

    state = {
        openCreateModal: false,
        removeModal: false,
        removedTitle: '',
        idDepartment: null,
        removeModalWithReasign: false,
        data: {},
        newDepartment: null,
        isError: false
    }

    tableRef = React.createRef()

    generateColumns = [
        {
            title: 'Title',
            field: 'name',
            sorting: false
        },
        {
            title: 'Icon',
            field: 'icon',
            sorting: false,
            render: rowData => {
                return <div className="icon-department" dangerouslySetInnerHTML={{__html: rowData.icon}}></div>
            }
        },
        {
            title: '',
            field: 'actions',
            sorting: false,
            headerStyle: {
                width: 32
            },
            render: rowData => {
                const {roles} = this.props;
                return (
                    findPermInArray(roles, permEditDepartmentsDictionary) &&
                    <DropDownMenu
                        ContentMenu={() => {
                            return (
                                <ul className='list-unstyled no-margin menu-table-actions'>
                                    <li>
                                        <a href="/" onClick={e => {
                                            e.preventDefault()
                                            this.showHideModal({
                                                modalName: 'openCreateModal',
                                                idDepartment: rowData.id,
                                                open: true
                                            })
                                        }}>Edit</a>
                                    </li>
                                    <li>
                                        <a href="/" onClick={e => {
                                            e.preventDefault()
                                            this.setState({
                                                removedTitle: rowData.name
                                            })
                                            this.showHideModal({
                                                modalName: 'removeModal',
                                                open: true,
                                                idDepartment: rowData.id
                                            })
                                        }}>Delete</a>
                                    </li>
                                </ul>
                            )
                        }}
                    >
                    </DropDownMenu>
                )
            }
        }
    ]

    componentDidMount() {
        this.props.setHeaderData({
            LeftPannel: TabsSettings,
                        leftPannelData: {
                            alias: 'departments'
                    },
            handleAdd: null,
            handleSearch: (value) => this.tableRef.current.onSearchChange(value)
        })
    }

    componentWillUnmount() {
        this.props.setHeaderData({title: null, handleAdd: null, handleSearch: null})
    }

    showHideModal = ({modalName, idDepartment, open, callback, data = {},newDepartment}) => {
        this.setState({
            [modalName]: open,
            idDepartment: idDepartment,
            data: data,
            newDepartment: newDepartment ? newDepartment : null
        }, () => {
            callback && callback()
        })
    }

    getDepartmentsPromise = ({page, pageSize, search}) => {
        return adminInstance.get(`${url}/department`, {
            params: {
                page: page ? page : 0,
                limit: pageSize,
                search: search
            }
        })
    }

    getDepartmentsList = query => {
        return new Promise((resolve, reject) => {
            this.getDepartmentsPromise(query)
                .then(res => {
                    resolve({
                        totalCount: res.data.count,
                        data: res.data.departments,
                        page: query.page
                    })
                })
        })
    }

    refetchTable = () => {
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    render() {
        const {
            openCreateModal,
            idDepartment,
            removeModal,
            removedTitle,
            removeModalWithReasign,
            data,
            newDepartment,
            isError
        } = this.state
        const {removeDepartment, clearDepartment,roles} = this.props
        return (
            <div className="wrapper-table">
                <Table
                    components={{
                        Toolbar: props => <Toolbar {...props} handleAdd={findPermInArray(roles, permEditDepartmentsDictionary) ? () => this.showHideModal({
                            modalName: 'openCreateModal',
                            open: true
                        }) : null} />,
                        Header: Header,
                        Pagination: Pagination
                        }}
                    paginationPosition="toolbar"
                    tableRef={this.tableRef}
                    columns={this.generateColumns}
                    data={this.getDepartmentsList}
                    title=""
                    options={{
                        showTitle: false,
                        search: true,
                        emptyRowsWhenPaging: false,
                        pageSize: 50,
                        pageSizeOptions: [10, 25, 50],
                    }}
                />
                {findPermInArray(roles, permEditDepartmentsDictionary) && <Modal
                    open={openCreateModal}
                    isFooter={false}
                    handleClose={() => this.showHideModal({
                        modalName: 'openCreateModal',
                        open: false,
                        idDepartment: null,
                        callback: clearDepartment
                    })}
                    Content={() => <DepartmentForm
                        refetchTable={this.refetchTable}
                        idDepartment={idDepartment}
                        handleClose={() => this.showHideModal({
                            modalName: 'openCreateModal',
                            open: false,
                            idDepartment: null,
                            callback: clearDepartment
                        })}
                    />}
                    options={{
                        centered: true
                    }}
                />
                }
                {findPermInArray(roles, permEditDepartmentsDictionary) && <CustomModalWithChildren
                    open={removeModal}
                    handleClose={e => this.showHideModal({
                        modalName: 'removeModal',
                        open: false
                    })}
                    handleCancel={e => this.showHideModal({
                        modalName: 'removeModal',
                        open: false
                    })}
                    handleConfirm={() => {
                        this.showHideModal({
                            modalName: 'removeModal',
                            open: false,
                            callback: () => {
                                removeDepartment({
                                    id: idDepartment,
                                    callback: (data) => {
                                        if (!data.error) {
                                            notify('success', 'Department successfully deleted')
                                            this.refetchTable()
                                        }
                                        else
                                            this.showHideModal({
                                                data: data,
                                                modalName: 'removeModalWithReasign',
                                                open: true,
                                                idDepartment: idDepartment
                                            })
                                    }
                                })
                            }
                        })

                    }}
                    options={{
                        centered: true
                    }}
                ><ConfirmModalContent text={`delete "${removedTitle}" department`}/></CustomModalWithChildren>
                }
                {findPermInArray(roles, permEditDepartmentsDictionary) && <Modal
                    open={removeModalWithReasign}
                    Content={() => <div>
                        <h6 className="error-title-h">{data.error} Please select the department where you want to
                            transfer {data.count} users.</h6>
                        <Form.Group>
                            <Form.Label>Department</Form.Label>
                            <AsyncSelect
                                isInvalid={isError}
                                onChange={obj => {
                                    this.setState({
                                        newDepartment: obj
                                    })
                                }}
                                errorLabel="required field"
                                value={newDepartment}
                                loadOptions={(inputValue, callback) => {
                                    this.getDepartmentsPromise({
                                        page: 0,
                                        pageSize: 50,
                                        search: inputValue
                                    })
                                        .then(res => {
                                            callback(res.data.departments.map(department => ({
                                                value: department.id,
                                                label: department.name
                                            })))
                                        })
                                }}
                                defaultOptions={true}
                            />
                        </Form.Group>
                    </div>}
                    handleConfirm={() => {
                        const newDepartment = this.state.newDepartment
                        if (newDepartment)
                            this.showHideModal({
                                modalName: 'removeModalWithReasign',
                                open: false,
                                newDepartment: null,
                                callback: () => {
                                    removeDepartment({
                                        id: idDepartment,
                                        newDepartmentId: newDepartment.value,
                                        callback: () => {
                                            notify('success', 'Department successfully deleted')
                                            this.refetchTable()
                                        }
                                    })
                                }
                            })
                        else
                            this.setState({
                                isError: true
                            })
                    }}
                    handleClose={() => this.showHideModal({
                        modalName: 'removeModalWithReasign',
                        open: false
                    })}
                    handleCancel={() => this.showHideModal({
                        modalName: 'removeModalWithReasign',
                        open: false
                    })}
                    options={{
                        centered: true
                    }}
                />
                }

            </div>
        )
    }
}

const mapStateToProps = state => ({
    roles: state.rolesReducer.roles,
});

const mapDispatchToProps = dispatch => {
    return {
        setHeaderData: (data) => dispatch(setData(data)),
        removeDepartment: obj => dispatch(removeDepartment(obj)),
        clearDepartment: () => dispatch(setDepartmentAction({
            name: '',
            icon: ''
        }))
    }
}

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(DepartmentsList));