import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'

import Tooltip from '../../../../../../../reuseComponent/Tooltips/index'
import adminInstance from '../../../../../../../instances/admininstance'
import { editCommentAction, addCommentAction } from '../../../../../../../../actions/purchase-orders'
import { url } from '../../../../../../../../constants'
import { notify } from '../../../../../../../reuseComponent/toast/index'


class TaskHistoryItem extends PureComponent {

    isProcess = false

    handleChangeStatus = () => {
        const { task,addHistoryItem,editHistoryItem } = this.props
        !this.isProcess && adminInstance.post(`${url}/task/${task.id}`)
            .then(res => {
                addHistoryItem(res.data)
                editHistoryItem({
                    ...task,
                    status: !task.status
                })
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                this.isProcess = false
            })
        this.isProcess = true
    }

    render() {
        const {task} = this.props
        return (
            <div className="history-item task">
                <div className="row-action">
                    <div className="info-task">
                        <span className="status" onClick={this.handleChangeStatus}>
                            {task.status ?
                                <Tooltip
                                    text="Incomplete"
                                >
                                    <svg className="complete" viewBox="0 0 24 24">
                                    <path fill="green"
                                          d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"/>
                                    </svg>
                                </Tooltip>
                                : <Tooltip
                                    text="Complete"
                                >
                                    <svg viewBox="0 0 24 24">
                                        <path fill="#000000"
                                              d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"/>
                                    </svg>
                            </Tooltip>}
                        </span>
                        <span className="task-name">{task.text}</span>
                    </div>
                    <div className="deadline-executor">
                        <span className="deadline-value">
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000"
                                      d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z"/>
                            </svg>
                            {moment(task.deadline).format('MMM D')},
                        </span>
                        {task.executor && task.executor.name &&
                        <Tooltip
                            text={`${task.executor.name} ${task.executor.secondName}`}
                        >
                            <span className="executor-value" style={{
                                color: task.executor.initialsColor
                            }}>{task.executor.initials}</span>
                        </Tooltip>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addHistoryItem: action => dispatch(addCommentAction(action)),
        editHistoryItem: action => dispatch(editCommentAction(action))
    }
}

export default connect(null, mapDispatchToProps)(TaskHistoryItem)