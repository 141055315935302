import { url } from '../constants'
import adminInstance from '../components/instances/admininstance'
import {convertToFormData} from '../reuseFunctions/objectToFormData';

const convertOfferValues = values => {
    const formData = new FormData()
    Object.entries(values).forEach(([key,value]) => {
        if(Array.isArray(value)){
            value.forEach(val => {
                formData.append(`${key}`,val)
            })
        }
        else
            formData.append(key,value)
    })
    return formData
}

const createOffer = (values) => {
    return adminInstance.post(`${url}/offers`,convertOfferValues(values))
}

const editOffer = (id,values) => {
    return adminInstance.put(`${url}/offers/${id}`,convertOfferValues(values))
}

const getAvailability = () => {
    return adminInstance.get(`${url}/offers/availability`)
}

const getFields = () => {
    return adminInstance.get(`${url}/offers/fields`)
}

const getOffer = (id) => {
    return adminInstance.get(`${url}/offers/${id}`)
}

const saveOfferConditions = (sets) => {
    return adminInstance.post(`${url}/user/offers-conditions`,convertToFormData({
        offersConditions: JSON.stringify(sets)
    }))
}

const getOfferConditions = () => {
    return adminInstance.get(`${url}/user/offers-conditions`)
}

const reservationOffer = (id,date, userId) => {
    return adminInstance.post(`${url}/offers/${id}/reservation`,convertToFormData({
        reservationDate: date,
        userId
    }))
}

const cancelReservation = (id) => {
    return adminInstance.post(`${url}/offers/${id}/cancel-reservation`)
}

const getProductsByPallet = id => {
    return adminInstance.get(`${url}/pallet/${id}/products`)
}

const exportToPdf = id => {
    return adminInstance({
        method: 'GET',
        responseType: 'blob',
        url: `${url}/offers/export-pdf/${id}`
    })
}

export const getOffersList = params => {
    return adminInstance.get(`${url}/offers`,{
        params: params
    })
}

export const getHistory = (id, type = '') => {
    return adminInstance.get(`${url}/offers/${id}/history`, {
        params: { type: type }
    });
}

export const OfferApi = {
    createOffer,
    getAvailability,
    getFields,
    editOffer,
    getOffer,
    saveOfferConditions,
    getOfferConditions,
    reservationOffer,
    getProductsByPallet,
    exportToPdf,
    cancelReservation,
    getOffersList
}