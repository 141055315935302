import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap'
import { changeEditStatusMainBlock } from '../../../../../../../../actions/detailsPo'
import { closeOpenSection } from '../../../../../../../../actions/purchase-orders'
import {findPermInArray} from "../../../../../../../../reuseFunctions/checkPermission";
import {permCompleteInfo, permEditInfo} from "../../../../../../../../permissions/productOverview";

class RightPartBidsHeader extends PureComponent {

    handleClickDoneButton = () => {
        const { initValues,values } = this.props
        let isChange = false
        Object.keys(values).forEach(key => {
            if(values[key] !== initValues[key])
                isChange = true
        })
        return isChange
    }

    render() {
        const { editingMode,isEditMain,details,closeOpenSection,showModal, roles } = this.props
        return (
            <div className="right-part">
                {/*{findPermInArray(roles, permCompleteInfo) &&*/}
                {/*!isEditMain &&  <Button onClick={() => closeOpenSection('main')} className={`with-icon ${details.mainCompleted ? 'complete' : ''}`}>*/}
                {/*    <svg viewBox="0 0 24 24">*/}
                {/*        <path fill="#000000" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/>*/}
                {/*    </svg>*/}
                {/*    {details.mainCompleted ? 'Completed' : 'Mark Complete'}</Button>*/}
                {/*}*/}
                {findPermInArray(roles, permEditInfo) &&!isEditMain && !details.mainCompleted && <Button className="with-icon" onClick={() => editingMode(true)}>
                    <svg viewBox="0 0 24 24">
                        <path fill="#000000"
                              d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"/>
                    </svg>
                    Edit</Button>
                }
                {/*{isEditMain && <Button className="with-icon" >*/}
                    {/*<svg viewBox="0 0 24 24">*/}
                        {/*<path fill="#000000" d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />*/}
                    {/*</svg>*/}
                    {/*Save</Button>*/}
                {/*}*/}
                {isEditMain && <Button className="with-icon" onClick={() => {
                    if(this.handleClickDoneButton())
                        showModal()
                    else
                        editingMode(false)
                }}>
                    Done</Button>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isEditMain : state.poDetailsReducer.isEditMain,
        details: state.purchaseOrdersReducer.detailsPurchase,
        roles: state.rolesReducer.roles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editingMode: isEdit => dispatch(changeEditStatusMainBlock(isEdit)),
        closeOpenSection: (section) => dispatch(closeOpenSection(section))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightPartBidsHeader)