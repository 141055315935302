import validator from 'validator'
import * as yup from 'yup'

export const schema = yup.object().shape({
    productId: yup.string().required('required field'),
    packageId: yup.string().required('required field'),
    newPackage: yup.string().required('required field'),
    boxId: yup.string().required('required field'),
    newBoxId: yup.string().required('required field'),
    items: yup.string().required('required field'),
    brand: yup.string(),
    size: yup.string(),
    season: yup.string(),
    cg1: yup.string(),
    cg2: yup.string(),
    cg3: yup.string(),
    bid: yup.string(),
    workplaceId: yup.string().required('required field'),
    rrp: yup.string().test('change length','must be a number',value => {
        const result = value === undefined || value.length === 0 || (value.length > 0) && validator.isFloat(value.replace(',','.').trim())
        return result
    })
})