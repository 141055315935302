import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import FilterForm from '../form'
import {getPoFilterById} from '../../../../../../services/poSmartFilter'


class EditFilter extends PureComponent {

    state = {
        initValues: {
            criterias: [],
            orCriterias: []
        },
        isLoadingFilter: false
    }

    componentDidMount() {
        const {idFilter} = this.props
        if (idFilter && getPoFilterById) {
            this.setState({
                isLoadingFilter: true
            }, () => {
                const promise = getPoFilterById(idFilter)
                promise.then(res => {
                    this.setState({
                        isLoadingFilter: false,
                        initValues: res.data
                    })
                })
            })

        }
    }

    render() {
        const {isLoading, handleSubmit, handleCancel} = this.props
        const {isLoadingFilter, initValues} = this.state
        return (
            <FilterForm
                initValues={initValues}
                isLoading={isLoading || isLoadingFilter}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
            />

        )
    }

}

export default connect(null, null)(EditFilter)