import React, { useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'

import CustomSelect from '../../../../reuseComponent/Select/select-form'
import { Button } from 'react-bootstrap/index'

const RemoveInstrumentWithSelect = ({locationTypes, selectedToDelete, handleCancel, handleConfirm}) => {

  const [isTouched, setIsTouched] = useState(false)
  const [value, setValue] = useState(null)

  const options = useMemo(() => {
    return locationTypes.filter(item => item.slot && item.id !== selectedToDelete.id).map(item => ({
      value: item.id,
      label: item.name,
    }))
  }, [locationTypes, selectedToDelete])

  return(
    <>
      <p>"{selectedToDelete.name}" has placed pallets. Select new space to set instead of deleting one</p>
      <Form.Group>
        <CustomSelect
          value={options.find(item => item.value === value)}
          options={options}
          isInvalid={isTouched && !value}
          errorLabel="Required field"
          onChange={obj => setValue(obj.value)}
          placeholder="Select new space"
        />
      </Form.Group>
      <Form.Group className="footer-form footer-form-custom">
        <Button variant="secondary" type="button" onClick={handleCancel}>Cancel</Button>
        <Button variant="primary" type="button" onClick={() => {
          if(value){
            handleConfirm(value)
          }else{
            setIsTouched(true)
          }
        }}>Save</Button>
      </Form.Group>
    </>
  )
}

export { RemoveInstrumentWithSelect }