import React, {PureComponent, createRef} from 'react'
import {connect} from 'react-redux'

import StandartLeftPanner from '../../../reuseComponent/Header/standartLeftPannel'
import {setData} from '../../../../actions/admin-header'
import { getUsers } from '../../../../actions/users'
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute'
import {history} from '../../../../history'
import {resetDetailsState} from '../../../../actions/detailsPo'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import {
    permViewInbounding,
    permShowAnalysis,
    permShowDividing,
    permViewProductList,
    permViewFollowersInfo
} from '../../../../permissions/productOverview';
import { getDetail, setDetailsPurchaseAction, setSnapshotAction } from '../../../../actions/purchase-orders';
import {Button} from 'react-bootstrap';
import Tabs from './tabs'
import Info from './info'
import ProductList from './product-list'
import Packages from './packages'
import Charts from './charts'
import Analysis from './analysis'
import InboundReport from './inbound-report'
import Inbounding from './inbounding'
import Participients from './info/followers'
import { getSnapshotByPo } from '../../../../services/purchaseOrders';
import { getPackagesRed } from '../../../../actions/inboundingReport'
import { client, decoratorSocket } from '../../../../socketClient'

class DetailsPO extends PureComponent {

    state = {
        activeTab: 'order-info'
    }

    subscribeQualityCheck = null
    detailsWrapper = createRef()

    handleChangeTab = (eventKey,e) => {
        if(!e || e.type !== 'keydown')
            this.setState({
                activeTab: eventKey
            })
    }

    handleConnectToSocket = () => {
        const { id } = this.props.match.params
        const { setDetailsPurchase } = this.props;
        this.subscribeQualityCheck = client.subscribe(`/purchase_order/${id}/quality`, (data) => {
            setDetailsPurchase({
                qualityCheck: data.body === 'true' ? true : false
            })
        })
    }

    componentDidMount() {
        const { id } = this.props.match.params
        const { getDetails, getPackages } = this.props
        id && getDetails(id)
        id && getPackages && getPackages(id);
        this.props.getUsers && this.props.getUsers()
        this.props.setHeaderData({
            leftPannelData: {
                title: 'Purchase Orders',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#3F51B5"
                          d="M19,3H14.82C14.25,1.44 12.53,0.64 11,1.2C10.14,1.5 9.5,2.16 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M7,7H17V5H19V19H5V5H7V7M17,11H7V9H17V11M15,15H7V13H15V15Z"/>
                </svg>)),
            },
            LeftPannel: StandartLeftPanner,
        })

        decoratorSocket(this.handleConnectToSocket, true)
    }

    componentDidUpdate(prevProps){
        const { setSnapShot } = this.props
        if(prevProps.details.completeInbound !== undefined && this.props.details.completeInbound !== undefined){
            if(prevProps.details.completeInbound !== this.props.details.completeInbound){
                if(this.props.details.completeInbound){
                    const { id } = this.props.match.params
                    getSnapshotByPo(id,'')
                        .then(res => {
                            setSnapShot(res.data.data)
                        })
                }
                else{
                    setSnapShot(null)
                }
            }
        }
        else if(this.props.details.completeInbound!== undefined){
            if(this.props.details.completeInbound){
                const { id } = this.props.match.params
                getSnapshotByPo(id,'')
                    .then(res => {
                        setSnapShot(res.data.data)
                    })
            }
        }
    }

    componentWillUnmount() {
        if(this.subscribeQualityCheck){
            this.subscribeQualityCheck.unsubscribe()
        }
    }

    handleCloseDetails = () => {
        const {resetDetailsState} = this.props
        resetDetailsState && resetDetailsState()

        changeRoutePush('/atw/purchase-orders', history)
    }

    renderTabContent = () => {
        const {roles} = this.props
        const {activeTab} = this.state
        const { id } = this.props.match.params 
        if(activeTab === 'product-list') {
            if(!findPermInArray(roles, permViewProductList)) {
                this.setState({activeTab: 'order-info'})
                return <Info idOrder={id} detailsWrapper={this.detailsWrapper.current}/>
            } else {
                return <ProductList />
            } 
        } else if (activeTab === 'dividing') {
            if(!findPermInArray(roles, permShowDividing)) {
                this.setState({activeTab: 'order-info'})
                return <Info idOrder={id} detailsWrapper={this.detailsWrapper.current}/>
            } else {
                return <Packages />
            } 
        } else if (activeTab === 'analysis') {
            if(!findPermInArray(roles, permShowAnalysis)) {
                this.setState({activeTab: 'order-info'})
                return <Info idOrder={id} detailsWrapper={this.detailsWrapper.current}/>
            } else {
                return <Charts />
            } 
        } else if (activeTab === 'analysis-table') {
            if(!findPermInArray(roles, permShowAnalysis)) {
                this.setState({activeTab: 'order-info'})
                return <Info idOrder={id} detailsWrapper={this.detailsWrapper.current}/>
            } else {
                return <Analysis />
            } 
        } else if (activeTab === 'inbound-tab') {
            if(!findPermInArray(roles, permViewInbounding)) {
                this.setState({activeTab: 'order-info'})
                return <Info idOrder={id} detailsWrapper={this.detailsWrapper.current}/>
            } else {
                return <InboundReport />
            } 
        } else if (activeTab === 'stowing-tab') {
            if(!findPermInArray(roles, permViewInbounding)) {
                this.setState({activeTab: 'order-info'})
                return <Info idOrder={id} detailsWrapper={this.detailsWrapper.current}/>
            } else {
                return <Inbounding />
            } 
        }
    }

    render() {
        const {activeTab} = this.state
        const { id } = this.props.match.params
        const { roles } = this.props
        return (
            <>
                <div className="details-po-header">
                    <div className='details-po-action'>    
                        <Button className='btn-back' onClick={this.handleCloseDetails}>
                            <svg viewBox="0 0 24 24">
                                <path fill="currentColor"
                                    d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"/>
                            </svg>
                            Back
                        </Button>
                        {findPermInArray(roles, permViewFollowersInfo) && <Participients />}
                    </div>
                    <Tabs
                        activeTab={activeTab}
                        handleChangeTab={this.handleChangeTab}
                    />
                </div>
                <div className='details-po'>
                    <div className="content-details" ref={this.detailsWrapper}>
                        {/*{id === 'new' && <CreateForm />}*/}
                        {id !== 'new' ? <>
                            {activeTab === 'order-info' && <Info idOrder={id} detailsWrapper={this.detailsWrapper.current}/>}
                            {this.renderTabContent()}
                        </> : null}
                    </div>
                </div>
            </>

        )
    }
}

const mapStateToProps = state => ({
    roles: state.rolesReducer.roles,
    details: state.purchaseOrdersReducer.detailsPurchase,
})

const mapDispatchToProps = dispatch => {
    return {
        resetDetailsState: () => dispatch(resetDetailsState()),
        setHeaderData: (data) => dispatch(setData(data)),
        getUsers: () => dispatch(getUsers()),
        setSnapShot: snapShot => dispatch(setSnapshotAction(snapShot)),
        getDetails: id => dispatch(getDetail(id)),
        getPackages: id => dispatch(getPackagesRed(id)),
        setDetailsPurchase: data => dispatch(setDetailsPurchaseAction(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPO)