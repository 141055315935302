import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Table from '../../../reuseComponent/Table/MaterialTable';
import Toolbar from './toolbar';
import Header from '../../../reuseComponent/Table/table-head';
import Pagination from '../../../reuseComponent/Table/Pagination';
import Modal from '../../../reuseComponent/modal/modalWithChildren';
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import ActionMenuComponent from './actions-menu';
import { WorkPlacesApi } from '../../../../services/workPlaces'
import { notify } from '../../../reuseComponent/toast'
import StandartLeftPanner from '../../../reuseComponent/Header/standartLeftPannel';
import { ReactComponent as DictionaryIcon } from '../../../../assets/images/dictionary.svg'
import { setData } from '../../../../actions/admin-header'
import FormWorkPlace from './form'
import {defaultServerError} from '../../../../reuseFunctions/toasts';
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import {permEditWorkPlaces} from '../../../../permissions/dictionaries';

class WorkPlacesDictionary extends PureComponent {

    state = {
        isLoading: false,
        modalForm: false,
        modalDelete: false,
        selectedWorkPlace: null
    }

    tableRef = React.createRef()

    columns = [
        {
            title: '',
            field: 'name',
            sorting: false
        },
        {
            title: '',
            field: 'actions',
            sorting: false,
            cellStyle: {
                width: 32
            },
            render: rowData => {
                return <ActionMenuComponent
                    handleEdit={() => this.setState({
                        modalForm: true,
                        selectedWorkPlace: rowData
                    })}
                    handleDelete={() => this.setState({
                        selectedWorkPlace: rowData,
                        modalDelete: true
                    })}
                />
            }
        }
    ]

    componentDidMount() {
        const {setHeaderData} = this.props
        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'Workplaces',
                logo: <DictionaryIcon className="action-icon header-icon"/>,
            },
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: null
        })
    }

    getWorkPlaces = query => {
        return new Promise((resolve) => {
            WorkPlacesApi.getWorkPlaces({
                page: query.page,
                limit: query.pageSize,
            })
                .then(res => {
                    resolve({
                        totalCount: res.data.data.totalElements,
                        data: res.data.data.content,
                        page: query.page
                    })
                })
        })
    }

    handleDeleteWorkPlace = () => {
        const { selectedWorkPlace } = this.state
        this.setState({
            modalDelete: false,
            isLoading: true
        },() => {
            WorkPlacesApi.deleteWorkPlace(selectedWorkPlace.id)
                .then(res => {
                    if(res.data.data !== null){
                        this.tableRef.current && this.tableRef.current.onQueryChange()
                        notify('success','Workplace successfully deleted')
                    }
                    else{
                        notify('error','Workplace already in use')
                    }
                    this.setState({
                        selectedWorkPlace: false,
                        isLoading: false
                    })

                })
                .catch(err => defaultServerError())
        })
    }

    handleCreate = (values) => {
        this.setState({
            modalForm: false,
            isLoading: true,
            selectedWorkPlace: null
        },() => {
            WorkPlacesApi.createWorkPlace(values)
                .then(res => {
                    if(res.data.data !== null){
                        this.tableRef.current && this.tableRef.current.onQueryChange()
                        notify('success','Workplace successfully created')
                    }
                    else{
                        notify('error','Value already exists')
                    }
                    this.setState({
                        isLoading: false
                    })

                })
                .catch(err => defaultServerError())
        })

    }

    handleEdit = values => {
        const { selectedWorkPlace } = this.state
        this.setState({
            modalForm: false,
            isLoading: false
        },() => {
            WorkPlacesApi.editWorkPlace(values,selectedWorkPlace.id)
                .then(res => {
                    if(res.data.data !== null){
                        this.tableRef.current && this.tableRef.current.onQueryChange()
                        notify('success','Workplace successfully edited')
                    }
                    else{
                        notify('error','Value already exists')
                    }
                    this.setState({
                        isLoading: false,
                        selectedWorkPlace: null
                    })
                })
                .catch(err => defaultServerError())
        })
    }

    handleCloseFormModal = () => {
        this.setState({
            modalForm: false,
            selectedWorkPlace: null
        })
    }

    render() {
        const {isLoading,modalDelete,modalForm,selectedWorkPlace} = this.state
        const { roles } = this.props
        return (
            <div className="wrapper-table">
                <Table
                    components={{
                        Toolbar: props => <Toolbar
                            {...props}
                            handleAdd={findPermInArray(roles,permEditWorkPlaces) ? () => this.setState({
                                modalForm: true
                            }) : null}/>,
                        Header: Header,
                        Pagination: Pagination
                    }}
                    paginationPosition="toolbar"
                    isLoading={isLoading}
                    tableRef={this.tableRef}
                    columns={this.columns}
                    data={this.getWorkPlaces}
                    options={{
                        showTitle: false,
                        search: true,
                        emptyRowsWhenPaging: false,
                        pageSize: 50,
                        pageSizeOptions: [10, 25, 50],
                    }}
                />
                {findPermInArray(roles, permEditWorkPlaces) && <Modal
                    isFooter={false}
                    open={modalForm}
                    handleClose={this.handleCloseFormModal}
                    options={{
                        centered: true
                    }}
                >
                    <FormWorkPlace
                        initValues={selectedWorkPlace ? {
                            name: selectedWorkPlace.name
                        } : {
                            name: ''
                        }}
                        handleSubmit={selectedWorkPlace ? this.handleEdit : this.handleCreate}
                        handleCancel={this.handleCloseFormModal}
                    />
                </Modal>
                }
                {findPermInArray(roles, permEditWorkPlaces) && <Modal
                    open={modalDelete}
                    handleClose={() => this.setState({
                        modalDelete: false,
                        selectedWorkPlace: null
                    })}
                    handleCancel={() => this.setState({
                        modalDelete: false,
                        selectedWorkPlace: null
                    })}
                    handleConfirm={this.handleDeleteWorkPlace}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`delete "${selectedWorkPlace ? selectedWorkPlace.name : null}" workplace`}/>
                </Modal>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps, {
    setHeaderData: setData
})(WorkPlacesDictionary)