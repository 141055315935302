import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Form} from 'react-bootstrap'
import DatePicker from '../../../../../../../reuseComponent/DatePicker/datepicker-with-children'
import Select from '../../../../../../../reuseComponent/Select/select-form'
import TextareaAutosize from "react-textarea-autosize";
import {Col, Row} from 'react-bootstrap'
import {findPermInArray} from '../../../../../../../../reuseFunctions/checkPermission'
import {permFinanceInfo} from "../../../../../../../../permissions/productOverview";

class BodyForm extends PureComponent {
    render() {
        const {
            setFieldValue,
            values,
            handleChange,
            touched,
            errors,
            poStatuses,
            handleSubmit,
            roles,
            users
        } = this.props
        return (
            <div className="form-wrap">
                <Form onSubmit={handleSubmit}>
                    <div className="form-left">
                        <Row>
                            <Col xs="3">
                                <Form.Group>
                                    <Form.Label>Placement Date</Form.Label>
                                    <div className="form-element ">
                                        <div className="wrapper-calendar-asana">
                                            <DatePicker
                                                onApply={(event, picker) => {
                                                    setFieldValue('placementDate', picker.startDate.format('DD.MM.YYYY'))
                                                }}
                                                startDate={values.placementDate ? values.placementDate : null}
                                            >
                                                <>
                                                    <svg viewBox="0 0 24 24">
                                                        <path fill="#000000"
                                                              d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z"/>
                                                    </svg>
                                                    <Form.Control
                                                        readOnly={true}
                                                        isInvalid={errors.placementDate && touched.placementDate}
                                                        name="placementDate"
                                                        value={values.placementDate ? values.placementDate : 'No placement date'}
                                                    />
                                                    <Form.Control.Feedback
                                                        type="invalid">{errors.placementDate}</Form.Control.Feedback>

                                                </>
                                            </DatePicker>
                                            {values.placementDate && <svg onClick={e => {
                                                setFieldValue('placementDate', '')
                                                document.body.click()
                                            }} className="circle-close" viewBox="0 0 24 24">
                                                <path fill="#000000"
                                                      d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/>
                                            </svg>
                                            }
                                        </div>
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col xs="2">
                                <Form.Group>
                                    <Form.Label>PO status</Form.Label>
                                    <div className="form-element">
                                        <Select
                                            value={poStatuses.find(poStatus => poStatus.value === values.poStatus.id)}
                                            isInvalid={touched.poStatus && errors.poStatus}
                                            name="poStatus"
                                            options={poStatuses}
                                            onChange={obj => setFieldValue('poStatus', obj.value)}
                                            errorLabel={errors.poStatus}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>

                            {findPermInArray(roles, permFinanceInfo) && <>
                                <Col xs="4">
                                    <Form.Label>Responsible buyer</Form.Label>
                                    <Select
                                        options={users}
                                        value={users.find(user => user.value === values.buyer)}
                                        name="buyer"
                                        onChange={obj => setFieldValue('buyer',obj.value)}
                                    />
                                </Col>
                                <Col xs="5">
                                    <Form.Group>
                                        <Form.Label>Offer number</Form.Label>
                                        <Form.Control
                                            name="offerNumber"
                                            value={values.offerNumber}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                            }
                        </Row>
                    </div>
                    <div className="comment-form">
                        <Form.Group>
                            <Form.Label>Notes</Form.Label>
                            <div className="form-element">
                                <TextareaAutosize
                                    type="text"
                                    className='form-control'
                                    name="comment"
                                    style={{resize: 'none'}}
                                    onChange={handleChange}
                                    value={values.comment}
                                    isInvalid={touched.comment && errors.comment}
                                >

                                </TextareaAutosize>


                                <Form.Control.Feedback type="invalid">{errors.comment}</Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </div>
                    {/*<Form.Group className="footer-form">*/}
                    {/*<Button variant="primary" type="submit">Save</Button>*/}
                    {/*</Form.Group>*/}
                </Form>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles,
        users: state.usersReducer.users.map(user => ({
            label: `${user.name} ${user.secondName}`,
            value: user.id
        }))
    }
}


export default connect(mapStateToProps, null)(BodyForm)