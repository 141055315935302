import React from 'react'
import { InstrumentTableItem } from './InstrumentTableItem'

const InstrumentsTable = ({
                            locationTypes,
                            handleOpenRemoveModal,
                            selectedToEdit,
                            setSelectedToEdit,
                            editCallback,
                            setLoading,
                            selectedInstrument,
                            setSelectedInstrument,
                          }) => {

  return (
    <table>
      <thead>
      <tr>
        <th>Space</th>
        <th style={{width: '55px'}}>Color</th>
        <th style={{width: '25px'}}>Slot</th>
        <th className="actions-cell"></th>
      </tr>
      </thead>
      <tbody>
        {locationTypes && locationTypes.length > 0 && locationTypes.map(item => <InstrumentTableItem
          key={item.id}
          instrument={item}
          handleOpenRemoveModal={handleOpenRemoveModal}
          selectedToEdit={selectedToEdit}
          setSelectedToEdit={setSelectedToEdit}
          editCallback={editCallback}
          setLoading={setLoading}
          selectedInstrument={selectedInstrument}
          setSelectedInstrument={setSelectedInstrument}
        />)}
      </tbody>
    </table>
  )
}

export { InstrumentsTable }