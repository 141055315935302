import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { colors } from '../../constants'

import { ReactComponent as CheckIcon } from '../../../../../assets/images/check.svg'

const ColorPicker = ({value, handleChange}) => {
  const [isOpen, setIsOpen] = useState(false)

  const dropdownRef = useRef(null)

  const handleClickOutside = useCallback((e) => {
    if(isOpen && dropdownRef && !dropdownRef.current.contains(e.target)){
      setIsOpen(false)
    }
  }, [isOpen])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <div className="color-dropdown" ref={dropdownRef}>
      <div className="color-dropdown-toggle" onClick={() => setIsOpen(prevState => !prevState)}>
        <div className="color" style={{backgroundColor: value}}></div>
        <svg className="down-icon" viewBox="0 0 24 24">
          <path
            fill="#000000"
            d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
          ></path>
        </svg>
      </div>
      <div className={classNames("colors-list-picker", {"opened": isOpen})}>
        {colors.map(color => <div
          onClick={() => {
            handleChange(color)
            setIsOpen(false)
          }}
          className="project-color-block" key={color}
          style={{backgroundColor: color}}
        >
          {value=== color && <CheckIcon className="standart-svg"/>}
        </div>)}
      </div>
    </div>
  )
}

export { ColorPicker }