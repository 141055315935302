import React from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {changeState} from '../../../../../../../../actions/detailsPo'
import DropDownMenu from '../../../../../../../reuseComponent/DropDown/index'
import {findPermInArray} from '../../../../../../../../reuseFunctions/checkPermission';
import {permEditInfo, permFinanceInfo} from '../../../../../../../../permissions/productOverview';
import { formatPrice, thousandSeparator } from '../../../../../../../../reuseFunctions/formatValue';
import { calculateItemActualPricePerUnit, calculateItemActualTotalPrice } from '../../../../../../../../reuseFunctions/calculateTotalBids';
import { notify } from '../../../../../../../reuseComponent/toast'
import { removeProductsDeletedBid, selectedPackegeAction } from '../../../../../../../../actions/purchase-orders';


const RowBidView = props => {
    const { bid, bidList, changeBidState,removeProductsDeletedBidProps, handleChange, roles, inboundReport, inboundingPackages, details, inboundReportPackage } = props;

    const inboundingPackagesList =
                    inboundingPackages &&
                    inboundingPackages.length > 0 ? true : false;

    const { total_boxes, underDelivered, ...restTotal } = inboundReport
    const getTotalItems = totals => Object.keys(totals).reduce((acc, val) => totals[val].items ? acc + totals[val].items : acc, 0);

    const dispatch = useDispatch()

    const currentId = useSelector(
        state => state.purchaseOrdersReducer.selectedPackege
    );

    const handleMouseOver = (id) => {
        if (id === currentId) {
            return;
        } else {
            dispatch(selectedPackegeAction(id))
        }
    }
    const handleMouseLeave = (removeId) => {
        if (removeId === currentId) {
            return;
        } else {
            dispatch(selectedPackegeAction(removeId))
        }
    }

    const handleChangeBid = () => {
        const inboundBegan = (inboundReportPackage && inboundReportPackage.actual_items > 0) ||
            (details && details.actualQuantity && parseInt(details.actualQuantity) > 0);
        const inboundBeganAndProgress = (inboundReport && inboundReport.total_boxes > 0) ||
            (getTotalItems(restTotal) > 0) ||
            (inboundReport && inboundReport.items > 0);

        handleChange(inboundBegan, inboundBeganAndProgress);
    }

    const handleDeleteBid = (bid) => {
        if (
            (inboundReport && inboundReport.total_boxes > 0) ||
            (getTotalItems(restTotal) > 0) ||
            (details && details.actualQuantity && parseInt(details.actualQuantity) > 0) ||
            (inboundReportPackage && inboundReportPackage.actual_items > 0) ||
            (inboundReport && inboundReport.items > 0)
        ) {
            notify('error', 'Can\'t be deleted when inbound began');
            return;
        } else {
            changeBidState({
                modalRemoveBid: true,
                bidToEdit: bid.id
            });
            removeProductsDeletedBidProps(bid.number)
        }
    }

    const getActualPricePerUnit = (bid) => {
        return calculateItemActualPricePerUnit(bid, bidList, details.addressPrice);
    }

    const getActualTotalPrice = (bid) => {
        return calculateItemActualTotalPrice(bid, bidList, details.addressPrice)
    }

    const toolbar = () => {
        return bid.id === currentId && (
            <DropDownMenu
                ContentMenu={() => {
                    return  (
                        <ul className='list-unstyled no-margin menu-table-actions'>
                            <li>
                                <a href="/" onClick={e => {
                                    e.preventDefault()
                                    handleChangeBid()
                                }}>Edit</a>
                            </li>
                            <li>
                                <a href="/" onClick={e => {
                                    e.preventDefault()
                                    handleDeleteBid(bid);
                                }}>Delete</a>
                            </li>
                        </ul>
                    )
                }}
            />
        )
    }

    return (
        <tr onMouseOver={() => handleMouseOver(bid.id)} onMouseLeave={() => handleMouseLeave(null)}>
            <td>
                <div>
                    {bid.number}
                </div>
                {bid.articleEan && <>
                    <div className="article-title">Article</div>
                    <div>{bid.articleEan}</div>
                    <div>{bid.articleDescription}</div>
                </>
                }
            </td>
            <td>{bid.commodity}</td>
            <td>{bid.gender}</td>
            <td>{bid.category}</td>
            <td>{bid.brand && bid.brand.name ? bid.brand.name : ''}</td>
            <td>{bid.productName}</td>
            <td>{bid.quality}</td>
            <td>{bid.channel}</td>
            <td className="custom-bids-th">{findPermInArray(roles, permFinanceInfo) && bid.pricePerUnit !== null ? `${thousandSeparator(formatPrice(bid.pricePerUnit))} €` : ''}</td>
            <td>{bid.unit}</td>
            <td className="custom-bids-th">{bid.quantity}</td>
            <td className="custom-bids-th">{bid.packages}</td>
            <td className="custom-bids-th">{bid.pallets}</td>
            <td className="custom-bids-th">{bid.grossWeight !== null ? thousandSeparator(formatPrice(bid.grossWeight)) : null}</td>
            <td className="custom-bids-th">{bid.netWeight !== null ? thousandSeparator(formatPrice(bid.netWeight)) : null}</td>
            <td className="custom-bids-th">{bid.actualQuantity}</td>
            <td className="custom-bids-th">{findPermInArray(roles, permFinanceInfo) && bid.creditNote !== null ? `${thousandSeparator(formatPrice(bid.creditNote))} €` : ''}</td>
            <td className="custom-bids-th">{findPermInArray(roles, permFinanceInfo) && getActualPricePerUnit(bid) ? `${thousandSeparator(formatPrice(getActualPricePerUnit(bid)))} €` : ''}</td>
            <td className="custom-bids-th">{findPermInArray(roles, permFinanceInfo) && bid.totalPrice !== null ? thousandSeparator(formatPrice(bid.totalPrice)) + ' €' : null}</td>
            <td className="custom-bids-th">{findPermInArray(roles, permFinanceInfo) && getActualTotalPrice(bid) ? thousandSeparator(formatPrice(getActualTotalPrice(bid))) + ' €' : null}</td>
            <td className="custom-bids-th">{findPermInArray(roles, permFinanceInfo) && bid.percent ?
                <span>{Math.round(bid.percent * 100)/100} %</span> : null}</td>
            <td>
                {findPermInArray(roles, permEditInfo) && (
                    <div className="tool-bar">
                        {toolbar()}
                    </div>
                )}
            </td>
        </tr>
    )
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles,
        inboundingPackages: state.inboundingReportReducer.boxes,
        details: state.purchaseOrdersReducer.detailsPurchase,
        inboundReport: state.poDetailsReducer.inboundReport,
        inboundReportPackage: state.inboundingReportReducer.packages.find(item => item.shortName !== 'REST'),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeBidState: state => dispatch(changeState(state)),
        removeProductsDeletedBidProps: bid => dispatch(removeProductsDeletedBid(bid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RowBidView)