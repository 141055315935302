import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { schema } from "./schema";
import BodyForm from "./bodyForm";
import {createCustomer, clearCustomer, getCountries} from "../../../../actions/customers";
import Preloader from "../../../reuseComponent/preloader/preloader";
import { withRouter } from "react-router-dom";
import {findPermInArray} from "../../../../reuseFunctions/checkPermission";
import {permCreateCustomers} from "../../../../permissions/customers";
import {getLanguagesList} from "../../../../actions/users";
import {getCustomerCurrentRequest} from "../../../../actions/customers";

class FormCustomersCreate extends PureComponent {
  handleSubmit = values => {
    const { createCustomer, callbackAfterCreation} = this.props;

    createCustomer(values, callbackAfterCreation);
  };

  componentDidMount() {
    const { clearCustomer, customer,  getLanguagesList, getCountriesList  } = this.props;
    customer && clearCustomer();
    getLanguagesList && getLanguagesList();
    getCountriesList && getCountriesList();
    if (this.props.match.params.id !== 'new') {
      getCustomerCurrentRequest(this.props.match.params.id)
    } else {
      clearCustomer();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getCustomerCurrentRequest,
      clearCustomer,
      request,
      setCurrentRequest
    } = this.props;
    if (prevProps.match.params.id !== this.props.match.params.id) {
      clearCustomer();
      getCustomerCurrentRequest(this.props.match.params.id);
    }
    if (request !== prevProps.request) {
      setCurrentRequest(request);
      this.setState({
        initValues: this.props.request
      })
    }
  }

  render() {
    const { cancelCreate, customer, isLoading, roles,handleShowRemoveModal } = this.props;
    return !isLoading ? (
      <Formik
        onSubmit={this.handleSubmit}
        // enableReinitialize={true}
        initialValues={{...customer, isCreate: true}}
        validationSchema={schema}
        render={props => (
          <BodyForm {...props}
                    cancelCreate={cancelCreate}
                    isView={!findPermInArray(roles, permCreateCustomers)}
                    handleShowRemoveModal={handleShowRemoveModal}
          />
        )}
      />
    ) : (
      <Preloader />
    );
  }
}

const mapStateToProps = state => ({
  customer: state.customersReducer.customer,
  isLoading: state.customersReducer.isLoadingCustomer  || state.customersReducer.isLoadingCountries
      || state.usersReducer.loadingLanguagesList,
  roles: state.rolesReducer.roles,
});

const mapDispatchToProps = dispatch => ({
  createCustomer: (value, callback) =>
    dispatch(createCustomer(value, callback)),
  clearCustomer: () => dispatch(clearCustomer()),
  getLanguagesList: () => dispatch(getLanguagesList()),
  getCountriesList: () => dispatch(getCountries()),
  getCustomerCurrentRequest: id => dispatch(getCustomerCurrentRequest(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormCustomersCreate)
);
