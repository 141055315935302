import React, {PureComponent, Fragment} from 'react'
import {Form, Button,FormControl} from 'react-bootstrap'
import './style/style.scss'
import { notify } from "../toast"

class EditableItem extends PureComponent {

    constructor(props) {
        super(props)
        this.refInput = React.createRef()
        this.state = {
            edit: false,
            defaultValue: this.props.text ? this.props.text : '',
            currentValue: this.props.text ? this.props.text : '',
            empty: false,
            errorMessage: '',
        }
    }

    static defaultProps = {
        handleSubmit: e => e.preventDefault(),
        type: 'text',
        name: 'value',
        url: 'default',
        changeValueFromProps : true,
        canBeEmpty : false
    }

    static getDerivedStateFromProps(props,state) {
        if(props.changeValueFromProps){
            return {
                defaultValue: props.text
            }
        }
        else{
            return {}
        }

    }

    handleSubmitChange = (name, currentValue, optId) => {
        this.props.handleSubmit(name,currentValue,optId)
            .then(res => {
                this.setState({
                    defaultValue: this.state.currentValue,
                    edit: false,
                    empty: false,
                    errorMessage: '',
                },() => {
                    document.removeEventListener('click', this.handleClickOutside)
                    notify('success','Data saved successfully')
                })
            })
            .catch(err => {
                notify('error','Error')
            })
}

    handleChangeName = e => {
        e.preventDefault()

        const { name,optId,canBeEmpty, handleValidate } = this.props,
            { currentValue } = this.state
        if(currentValue === '' && !canBeEmpty){
            this.setState({
                empty: true,
                errorMessage: 'Can not be empty'
            })
            return false
        }

        if(handleValidate){
            let error = handleValidate(currentValue, optId);

            if(error){
                this.setState({
                    empty: true,
                    errorMessage: error,
                })
            }else{
                this.handleSubmitChange(name, currentValue, optId);
            }
        }else{
            this.handleSubmitChange(name, currentValue, optId);
        }
    }

    //show form editable

    handleClick = e => {
        this.setState({
            edit: true,
            currentValue: this.state.defaultValue
        })
        document.addEventListener('click', this.handleClickOutside)
    }

    // handler for input

    handleChange = e => {
        this.setState({
            currentValue: e.target.value
        })
    }

    //handle to cancel changes form

    handleCancel = e => {
        this.setState({
            edit: false,
            empty: false,
            currentValue: this.state.defaultValue
        })
        document.removeEventListener('click', this.handleClickOutside)
    }

    handleClickOutside = e => {
        if (!checkParentNode(e.target, this.refInput.current)) {
            this.setState({
                edit: false,
                currentValue: this.state.defaultValue,
                empty: false
            })
            document.removeEventListener('click', this.handleClickOutside)
        }

    }

    render() {

        const { type, name} = this.props,
            {edit, currentValue,defaultValue,empty, errorMessage} = this.state
        return (
            <Fragment>
                {!edit
                    ? <span className="editable-item" onClick={this.handleClick}>{defaultValue}</span>
                    : <Form className="editable-form" onSubmit={this.handleChangeName} ref={this.refInput}>
                        <Form.Group>
                            <Form.Control
                                type={type}
                                name={name}
                                onChange={this.handleChange}
                                value={currentValue}
                                isInvalid={empty}
                            />
                            <FormControl.Feedback type="invalid">{errorMessage}</FormControl.Feedback>
                        </Form.Group>
                        <div className="controll-buttons">
                            <Button type="submit" className="subm-btn" variant="primary">Save</Button>
                            <Button variant="secondary" onClick={this.handleCancel}>Cancel</Button>
                        </div>
                    </Form>
                }
            </Fragment>
        )
    }
}

const checkParentNode = (target, compareDom) => {
    let parent = target
    while (parent !== document) {
        if (parent === compareDom)
            return true
        else if (!parent.parentNode)
            return false
        else
            parent = parent.parentNode
    }
    return false
}

export default EditableItem