export const removeEmptyValues = values => {
    let res = {}
    Object.keys(values).filter(key =>  (values[key] !== '' && values[key] !== undefined && values[key] !== null)
        || key==='comment'
        || key === 'actualArrivalDate'
        || key === 'addressPrice'
        || key === 'addressNote'
        || key === 'pickupDate'
        || key === 'targetArrivalDate'
        || key === 'placementDate'
        || key === 'accountingCreditPeriod'
        || key === 'accountingPaymentDate').forEach(key => {
        res[key] = values[key]
    })
    return res
}