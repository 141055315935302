import { url } from '../constants'
import  adminInstance from '../components/instances/admininstance'
import { convertToFormData } from '../reuseFunctions/objectToFormData'

export const getPartlyPrivileges = alias => {
    console.log('route', alias)
    const promise = adminInstance.get(`${url}/role/privileges/${alias}`)
    return promise
}

export const changePrivilegesService = ({frontPartId,accessAlias,roleId}) => {
    const promise = adminInstance.post(`${url}/role/privileges/change_partly`,convertToFormData({
        frontPartId,
        accessAlias,
        roleId
    }))
    return promise
}

export const getRolesList = () => adminInstance.get(`${url}/role`);

export const removeRoleWithNewRoleSet = (id, newRoleId, roleName) =>
    adminInstance.delete(`${url}/role/${id}`, {
        params: {
            newRoleId,
            roleName
        }
    });

export const checkRoleNameUnique = (roleName, roleId) =>
    adminInstance.get(`${url}/role/validate`, {
        params: {
            roleName,
            roleId
        }
    });