import React from 'react'; 
import {PureComponent} from 'react';
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute'
import {history} from '../../../../history'

import './texts.scss';

export default class SuccessUpdateSystem extends PureComponent {

    hadleClick = () => { 
        changeRoutePush('/atw/release-notes', history);
    }

    render() {
        return (
            <div> 
                System has been updated.
                <br/>
                <span className="link-text" onClick={this.hadleClick}>View updates.</span>
                { sessionStorage.clear() }
            </div>
        );
    }

}