import { url } from '../constants'
import adminInstance from '../components/instances/admininstance'
import { convertToFormData } from '../reuseFunctions/objectToFormData'

export const createTask = (values) => {
    values.soUrl = window.location.origin + '/atw/sales-orders'
    return adminInstance.post(`${url}/tasks`,convertToFormData(values))
}

export const createTransportTask = (values, id) => {
    values.soUrl = window.location.origin + '/atw/sales-orders'
    return adminInstance.put(`${url}/purchase_order/${id}/set_transport_responsible_person`, convertToFormData(values))
}

export const createAccountingTask = (values, id) => {
    values.soUrl = window.location.origin + '/atw/sales-orders'
    return adminInstance.put(`${url}/purchase_order/${id}/set_accounting_responsible_person`, convertToFormData(values))
}

export const createInboundTask = (values, id) => {
    values.soUrl = window.location.origin + '/atw/sales-orders'
    return adminInstance.put(`${url}/purchase_order/${id}/set_inbound_responsible_person`, convertToFormData(values))
}

export const getTaskById = (id) => {
    return adminInstance.get(`${url}/tasks/${id}`)
}

export const deleteTask = (id) => {
    return adminInstance.delete(`${url}/tasks/${id}`)
}

export const editTask = (values,id) => {
    values.soUrl = window.location.origin + '/atw/sales-orders'
    return adminInstance.put(`${url}/tasks/${id}`,convertToFormData(values))
}

export const changeTaskExecutor = (id, values) => {
    values.poUrl = window.location.origin + '/atw/purchase-orders'
    values.taskUrl = window.location.origin + '/atw/tasks/tickets'
    values.soUrl = window.location.origin + '/atw/sales-orders'
    return adminInstance.put(`${url}/tasks/${id}/set_responsible`,convertToFormData(values))
}

export const markCompleteTask = (poId, taskId, task) => {
    if (task.inbound) {  // hard code
        const data = convertToFormData({
            urlToPO: window.location.origin + '/atw/purchase-orders'
        });
        return adminInstance.put(`${url}/purchase_order/${poId}/inbound`, data)
            .then((res) => changeCompleteStatus(taskId))
    } else {
        return changeCompleteStatus(taskId);
    }
}

export const changeCompleteStatus = (id) => {
    return adminInstance.post(`${url}/tasks/${id}`)
}

export const getTasks = (params) => {
    return adminInstance.get(`${url}/tasks/list`,{
        params: {
            ...params
        }
    })
}

export const getTasksTemplates = (params = {}) => {
    return adminInstance.get(`${url}/template/task`,{
        params: {
            ...params
        }
    })
}

export const getTasksByPo = ({poId,currUser = false}) => {
    return adminInstance.get(`${url}/tasks/list/${poId}`,{
        params: {
            currentUser: currUser
        }
    })
}