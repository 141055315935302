export const TYPES = {
  COORD_X: 'COORD_X',
  COORD_Y: 'COORD_Y',
  EMPTY_LEGEND: 'EMPTY_LEGEND'
}

export const PALLET_PLACE_STROKE = {
  // EMPTY: 'green',
  // SOLD: 'yellow',
  // RESERVED: 'grey',
  // PICK: 'blue',
  // IN_PROGRESS: 'brown',
  // FREE: '#4682B4',
  EMPTY: 'green',
  SOLD: '#fafafa',
  RESERVED: '#03a9f4',
  PICK: '#8bc34a',
  IN_PROGRESS: '#ff9800',
  FREE: '#4682B4',
  DEFAULT: '#B0BEC5',
  HIGHLIGHTED: '#3498DB',
  PRIMARY: '#616161',
  WHITE: '#ffffff'
}

export const defaultFilterData = {
  brand: [],
  cg1: [],
  cg2: [],
  cg3: [],
  palletStatus: [],
  productName: [],
  quality: [],
  season: [],
  term: '',
  termCondition: [],
}

export const checkIfPaginationIsEmpty = (pagination) => {
  const {
    brand,
    cg1,
    cg2,
    cg3,
    palletStatus,
    productName,
    quality,
    season,
    term,
  } = pagination

  return !(brand.length > 0 || cg1.length > 0 || cg2.length > 0 || cg3.length > 0
    || palletStatus.length > 0 || productName.length > 0 || quality.length > 0 || season.length > 0
    || !!term)
}