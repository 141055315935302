import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import HistorList from './list'
import Comment from './comment-editor/comment-editor'
import Tabs from './tabs/index'
import Tasks from './tasks/index'
import Compose from './compose-component/index'
import {findPermInArray} from '../../../../../../reuseFunctions/checkPermission'
import {changeActiveHistoryTab} from '../../../../../../actions/purchase-orders'
import TaskList from '../tasks/index'
import {permTaskChronology} from '../../../../../../permissions/productOverview'

class HistoryPartPurchaseOrder extends PureComponent {

    componentWillUnmount() {
        const {changeActiveTab} = this.props;
        changeActiveTab && changeActiveTab('TASK');

    }

    render() {

        const {activeTab, isLoading, roles, refetchTableData, hasOwnTasks} = this.props
        const hasPermToTasks = findPermInArray(roles, permTaskChronology);

        return (
            <>
                <div className="history-block">
                    <div className="title-history">
                        <Tabs hasOwnTasks={hasOwnTasks}/>
                        <Compose refetchTableData={refetchTableData}/>
                    </div>
                    {activeTab === 'TASK' && (hasPermToTasks || hasOwnTasks) && <TaskList/>}
                    {activeTab !== 'TASK' && <HistorList activeTab={activeTab}/>}
                </div>

                {(activeTab === '' || activeTab === 'COMMENT' || (activeTab === '' && !hasPermToTasks)) && !isLoading &&
                <Comment/>}
                {(hasPermToTasks || hasOwnTasks) && <Tasks/>}

            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeTab: state.purchaseOrdersReducer.activeTabHistory,
        isLoading: state.purchaseOrdersReducer.isLoadingHistory,
        roles: state.rolesReducer.roles
    }
}

const mapDispatchToProps = dispatch => ({
    changeActiveTab: tabName => dispatch(changeActiveHistoryTab(tabName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPartPurchaseOrder)