import * as yup from 'yup'
import { formatCoordinatesToSend } from '../../../../reuseFunctions/mapUtils'

export const mapDictionarySchema = yup.object().shape({
  name: yup.string().trim().required('Required field'),
  color: yup.string().required('Required field'),
  slot: yup.bool().required('Required field'),
  coordinates: yup.array()
    .typeError('Required field')
    .test('out of range', 'Coordinates out of map range', function(val) {
      const { maxX, maxY } = this.parent
      if(val && val.length > 0){
        try{
          formatCoordinatesToSend(val.map(item => item.value), maxX, maxY)
          return true
        }
        catch (err){
          if(err.message === 'Coordinates out of map range'){
            return false
          }else{
            return true
          }
        }
      }
    })
    .test('invalid format', 'Invalid coordinates format', function(val) {
      const { maxX, maxY } = this.parent
      if(val && val.length > 0){
        try{
          formatCoordinatesToSend(val.map(item => item.value), maxX, maxY)
          return true
        }
        catch (err){
          if(err.message === 'Invalid coordinates format'){
            return false
          }else{
            return true
          }
        }
      }
    })
    .required('Required field')
  ,
})