import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap'
import Tooltip from '../../../../../reuseComponent/Tooltips';
import PackageRow from './packageRow';
import {getBoxByPackage} from '../../../../../../actions/inboundingReport';

import {ReactComponent as OpenedBox} from '../../../../../../assets/images/opened_box.svg';
import {ReactComponent as ClosedBox} from '../../../../../../assets/images/closed_box.svg';
import {ReactComponent as Item} from '../../../../../../assets/images/item.svg';
import {ReactComponent as PlusIcon} from '../../../../../../assets/images/plus.svg';
import {ReactComponent as PackIcon} from '../../../../../../assets/images/packIcon.svg'
import Preload from '../../../../../reuseComponent/preloader/preloader';
import {permManageCustomPackage} from '../../../../../../permissions/productOverview';
import {findPermInArray} from '../../../../../../reuseFunctions/checkPermission';

const PackageTable = ({
                          packages,
                          // report,
                          getBoxesByPackage,
                          loadingPackage,
                          handleEditPackage,
                          handleDeletePackage,
                          openCreateModal,
                          roles,
                          details
                      }) => {
    let overDel = null;
    let unordered = null;
    packages.forEach(item => {
        if (item.shortName === 'Overdelivered') overDel = item;
        if (item.shortName === 'Unordered') unordered = item;
    });
    const filterCustomPackages = packages.filter(item => item.custom).reverse();
    const filterDividingPackages = packages.filter(item => !item.custom);
    const activePackage = useSelector(
      state => state.inboundingReportReducer.activePackage
    );

    useEffect(() => {
      if(packages.length > 0 && (!activePackage || Object.keys(activePackage).length === 0 ) && details && details.inboundType === 'Totals'){
        let pack = packages.find(item => item.shortName !== 'REST')
        if(pack)
          getBoxesByPackage(pack.id)
      }
    }, [packages, activePackage, details, getBoxesByPackage])

    return (
        <div className="packages__wrapper">
            {/*<div className="packages__inner">*/}
                {loadingPackage ? (
                    <Preload/>
                ) : (
                    <>
                        <div className="packages__outer">
                            <div className="package__row row__header">
                                <div className="package__content">
                                    <div className="package__cell package__cell__add">
                                        Packages
                                        {findPermInArray(roles, permManageCustomPackage) && !details.completeInbound && details.inboundType !== 'Totals' && (
                                            <Button onClick={openCreateModal}>
                                                <PlusIcon className="standart-svg"/> Add
                                            </Button>
                                        )}
                                    </div>
                                  {details.inboundType !== 'Totals' ? <>
                                    <div className="package__cell opened-box package-icon">
                                        <Tooltip text="Opened boxes">
                                            <OpenedBox/>
                                        </Tooltip>
                                    </div>
                                    <div className="package__cell closed-box package-icon">
                                        <Tooltip text="Closed boxes">
                                            <ClosedBox/>
                                        </Tooltip>
                                    </div>
                                    </> : <div className="package__cell closed-box package-icon">
                                    <Tooltip text="Packs">
                                      <PackIcon/>
                                    </Tooltip>
                                  </div>}
                                    <div className="package__cell items package-icon">
                                        <Tooltip text="Items">
                                            <Item/>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="package--info">
                            {filterDividingPackages.map(item =>
                                item.shortName !== 'REST' &&
                                item.shortName !== 'Overdelivered' &&
                                item.shortName !== 'Unordered' ? (
                                    <PackageRow
                                        handleRowClick={() => getBoxesByPackage(item.id)}
                                        key={item.id}
                                        pack={item}
                                    />
                                ) : null
                            )}
                            {filterCustomPackages.map(item => (
                                <PackageRow
                                    isCustom={true}
                                    handleRowClick={() => getBoxesByPackage(item.id)}
                                    handleEditPackage={!details.completeInbound ? handleEditPackage : null}
                                    handleDeletePackage={!details.completeInbound ? handleDeletePackage : null}
                                    key={item.id}
                                    pack={item}
                                />
                            ))}
                        </div>
                        {details.inboundType === 'Deep' && <div className="packages__bottom">
                            <div className="package--additional-info">
                                <PackageRow
                                    handleRowClick={
                                        overDel ? () => getBoxesByPackage(overDel.id) : null
                                    }
                                    pack={
                                        overDel
                                            ? overDel
                                            : {
                                                shortName: 'Overdelivered',
                                                openBox: 0,
                                                closedBox: 0,
                                                actual_items: 0
                                            }
                                    }
                                />

                                <PackageRow
                                    handleRowClick={
                                        unordered ? () => getBoxesByPackage(unordered.id) : null
                                    }
                                    pack={
                                        unordered
                                            ? unordered
                                            : {
                                                shortName: 'Unordered',
                                                openBox: 0,
                                                closedBox: 0,
                                                actual_items: 0
                                            }
                                    }
                                />
                            </div>
                        </div>}
                    </>
                )}
            {/*</div>*/}
        </div>
    );
};

const mapStateToProps = state => ({
    packages: state.inboundingReportReducer.packages,
    report: state.inboundingReportReducer.report,
    loadingPackages: state.inboundingReportReducer.loadingPackages,
    roles: state.rolesReducer.roles,
    details: state.purchaseOrdersReducer.detailsPurchase,
});

const mapDispatchToProps = dispatch => ({
    getBoxesByPackage: packageId => dispatch(getBoxByPackage(packageId))
});

export default connect(mapStateToProps, mapDispatchToProps)(PackageTable);
