import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap'
import {getUsers} from '../../../../../../actions/users'
import {addFollower, removeFollower} from '../../../../../../actions/purchase-orders'
import Tooltip from '../../../../../reuseComponent/Tooltips/index'
import {findPermInArray} from '../../../../../../reuseFunctions/checkPermission'
import {Form} from 'react-bootstrap';
import {permEditFollowersInfo} from '../../../../../../permissions/productOverview';
import {ReactComponent as AddIcon} from '../../../../../../assets/images/plus.svg'
import {ReactComponent as HorizontalDots} from '../../../../../../assets/images/horizontal-dots.svg'


class Followers extends PureComponent {

    state = {
        showHide: false,
        search: '',
        showMoreList: false
    }


    showHideList = (callback) => {
        this.setState({
            showHide: !this.state.showHide,
            search: ''
        }, () => {
            this.searchFieldRef.current && this.searchFieldRef.current.focus()
            callback && callback()
        })
    }

    showHideMoreList = () => {
        this.setState({
            showMoreList: !this.state.showMoreList
        })
    }

    followersRef = React.createRef()
    searchFieldRef = React.createRef()
    listFollowersRef = React.createRef()
    moreListRef = React.createRef()

    handleSearch = e => {
        this.setState({
            search: e.target.value,
            selectedUser: null
        })
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutSide)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutSide)
    }

    handleClickOutSide = (event) => {
        if (this.followersRef.current && !this.followersRef.current.contains(event.target)) {
            this.setState({
                showHide: false
            })
        }
        if(this.moreListRef.current && !this.moreListRef.current.contains(event.target)){
            this.setState({
                showMoreList: false
            })
        }
    }

    getParticipiantToRenderAndHide = () => {
        const {details: {followers}} = this.props
        const widthAddButton = 115
        if (followers && this.listFollowersRef.current) {
            const widthList = this.listFollowersRef.current.offsetWidth
            const countParticipants = followers.length
            const countParticipantsToRender = Math.trunc((widthList - widthAddButton)/65)
            if(countParticipants - countParticipantsToRender > 0){
                return {
                    toRender: followers.slice(0,countParticipantsToRender - 1),
                    toHide: followers.slice(countParticipantsToRender - 1)
                }
            }
            else{
                return {
                    toRender: followers,
                    toHide: []
                }
            }
        }
        else {
            return {
                toRender: [],
                toHide: []
            }
        }
    }

    render() {
        const {users, details, removeFollower, addFollower /*currUser*/, roles} = this.props
        // const isFollowCurrUser = details.followers.findIndex(follow => follow.id === currUser.info.id) > -1
        const {showHide, search,showMoreList} = this.state
        const filteredUsers = users.filter(user => {
            const compareString = user.name + user.secondName
            return compareString.toLowerCase().indexOf(search.toLowerCase()) >= 0
        })

        const {toRender, toHide} = this.getParticipiantToRenderAndHide()
        return (
            <div className="followers-block">
                <div className="followers-row">

                    <div className="list-followers" ref={this.listFollowersRef}>
                        {findPermInArray(roles, permEditFollowersInfo) &&
                        <div ref={this.followersRef} className={`followers ${showHide ? 'open' : ''}`}>
                            <Button onClick={() => this.showHideList()}>
                                <AddIcon className="standart-svg"/>
                                Add Participant
                            </Button>
                            <ul className="list-users-to-follow">
                                <li className="search-li">
                                    <Form.Control
                                        ref={this.searchFieldRef}
                                        placeholder="Search..."
                                        onChange={this.handleSearch}
                                        value={search}
                                    />
                                </li>
                                {filteredUsers.map(user => {
                                    const isFollow = details.followers && details.followers.findIndex(follower => follower.id === user.id) !== -1
                                    return (
                                        !isFollow &&
                                        <li onClick={() => this.showHideList(() => addFollower(details.id, user.id))}
                                            key={user.id}>
                                            {user.name} {user.secondName}
                                        </li>

                                    )
                                })}
                            </ul>
                        </div>
                        }
                        {toRender.map(follower => {
                            return <Tooltip
                                text={`${follower.name} ${follower.secondName}`}
                                key={follower.id}
                            >
                                <div className="follower-item" style={{
                                    color: follower.initialsColor
                                }}>
                                    {follower.initials}
                                    {findPermInArray(roles, permEditFollowersInfo) && <svg className="remove-follower"
                                                                                           onClick={() => removeFollower(details.id, follower.id)}
                                                                                           viewBox="0 0 24 24">
                                        <path fill="#000000"
                                              d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/>
                                    </svg>
                                    }
                                </div>
                            </Tooltip>
                        })}
                        {toHide.length > 0 &&
                        <div className="more-followers" onClick={this.showHideMoreList} ref={this.moreListRef}>
                            <Tooltip text="Show more">
                                <div className="follower-item more-followers">
                                    <HorizontalDots className="standart-svg"/>
                                </div>
                            </Tooltip>
                            {showMoreList && <ul>
                                {toHide.map(follower => <li
                                    key={follower.id}
                                >
                                    <Tooltip
                                        text={`${follower.name} ${follower.secondName}`}

                                    >
                                        <div className="follower-item" style={{
                                            color: follower.initialsColor
                                        }}>
                                            {follower.initials}
                                            {findPermInArray(roles, permEditFollowersInfo) &&
                                            <svg className="remove-follower"
                                                 onClick={() => removeFollower(details.id, follower.id)}
                                                 viewBox="0 0 24 24">
                                                <path fill="#000000"
                                                      d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/>
                                            </svg>
                                            }
                                        </div>
                                    </Tooltip>
                                </li>)
                                }
                            </ul>
                            }
                        </div>
                        }

                    </div>


                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        users: state.usersReducer.users,
        currUser: state.usersReducer.currUser,
        roles: state.rolesReducer.roles,
        isLoadingDetails: state.purchaseOrdersReducer.isLoadingDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(getUsers()),
        addFollower: (orderId, userId) => dispatch(addFollower(orderId, userId)),
        removeFollower: (orderId, userId) => dispatch(removeFollower(orderId, userId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Followers)