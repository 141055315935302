import { PO_DETAILS } from '../constants'
import adminInstance from '../components/instances/admininstance'
import { url } from '../constants'
import { convertToFormData } from '../reuseFunctions/objectToFormData'
import axios from 'axios'
import { notify } from '../components/reuseComponent/toast'
import { getHistory } from './purchase-orders'



export const changeModalView = ({ modalName,open }) => ({
    type: PO_DETAILS.DPO_MODAL_VIEW_CHANGE,
    payload: {
        modalName,
        open
    }
})

export const changeState = (partState) => ({
    type: PO_DETAILS.DPO_MULTIPLE_CHANGES_STATE,
    payload: partState
})


export const createBid = ({values,resolve = () => {},reject = () => {} }) => {
    const data = convertToFormData(values)
    return (dispatch,getState) => {
        const activeTab = getState().purchaseOrdersReducer.activeTabHistory
        adminInstance.post(`${url}/purchase_order/${values.idPurchase}/bid`,data)
            .then(res => {
                resolve(res.data)
                activeTab === 'chronology' && dispatch(getHistory())
                notify('success', 'BID successfully created')
            })
            .catch(err => {
                reject(err)
            })
    }
}

export const removeBid = ({values,resolve = () => {}, reject = () => {}}) => {
    return (dispatch,getState) => {
        const activeTab = getState().purchaseOrdersReducer.activeTabHistory
        adminInstance.delete(`${url}/purchase_order/${values.idPurchase}/bid/${values.idBid}`,{
            params: {
                ...values
            }
        })
            .then(res => {
                resolve(res.data)
                activeTab === 'chronology' && dispatch(getHistory())
            })
            .catch(err => {
                reject(err)
            })

    }
}

export const editBid = ({ values, resolve = () => {}, reject = () => {} }) => {
    const data = convertToFormData(values)
    return (dispatch,getState) => {
        const activeTab = getState().purchaseOrdersReducer.activeTabHistory
        adminInstance.put(`${url}/purchase_order/${values.idPurchase}/bid/${values.idBid}`,data)
            .then(res => {
                resolve(res.data)
                activeTab === 'chronology' && dispatch(getHistory())
            })
            .catch(err => {
                reject(err)
            })
    }
}

export const changeEditStatusMainBlock = (isEditing) => ({
    type: PO_DETAILS.DPO_EDIT_BIDS,
    payload: isEditing
})

const isLoadingBidInfo = isLoading => ({
    type: PO_DETAILS.IS_LOADING_BIDS_INFO,
    payload: isLoading
})

const changeBidsValues = values => ({
    type: PO_DETAILS.CHANGE_BIDS_VALUES_FORM,
    payload: values
})

export const getParamsByAlias = alias => {
    return adminInstance.get(`${url}/simple_parameter/${alias}`)
}
export const addInputParams = (id, alias, value) => {
    const data = convertToFormData(value)
    return adminInstance.put(`${url}/simple_parameter/${alias}/${id}/add_inputs`, data)
}

export const getQualities = callback => {
    return (dispatch,getState) => {
        const valuesOptions = getState().purchaseOrdersReducer.valuesOptions
        getParamsByAlias('quality')
            .then(res => {
                dispatch(changeBidsValues({
                    ...valuesOptions,
                    qualities: res.data.map(item => ({value: item.value, label: item.value})),
                }))
            })
    }
}

export const getBidsValues = (callback) => {
    return dispatch => {
        dispatch(isLoadingBidInfo(true))
        axios.all([
            getParamsByAlias('quality'),
            getParamsByAlias('gender'),
            getParamsByAlias('category'),
            getParamsByAlias('commodity'),
            getParamsByAlias('channel'),
            getParamsByAlias('quantityUnit'),
            getParamsByAlias('productName'),
        ])
            .then(axios.spread((qualityRes, genderRes, categoryRes, commodityRes, channelRes, unitsRes, productNameRes) => {
                dispatch(isLoadingBidInfo(false))
                dispatch(changeBidsValues({
                    qualities: qualityRes.data.map(item => ({value: item.value, label: item.value})),
                    genders: genderRes.data.map(item => ({value: item.id, label: item.value})),
                    categories: categoryRes.data.map(item => ({value: item.value, label: item.value})),
                    commodities: commodityRes.data.map(item => ({value: item.id, label: item.value})),
                    channels: channelRes.data.map(item => ({value: item.value, label: item.value})),
                    units: unitsRes.data.map(item => ({value: item.value, label: item.value})),
                    productName: productNameRes.data.map(item => ({value: item.value, label: item.value}))
                }))
                callback && callback(commodityRes.data.map(item => ({value: item.id, label: item.value})),
                    genderRes.data.map(item => ({value: item.id, label: item.value})));
            }))
            .catch(() => {
                notify('error','Oops something went wrong')
            })
    }
}

export const addUnconfirmedBid = () => ({
    type: PO_DETAILS.ADD_UNCONFIRMED_BID
})

export const removeUnconfirmedBid = id => ({
    type: PO_DETAILS.REMOVE_UNCONFIRMED_BID,
    payload: id
})

export const resetDetailsState = () => ({
    type: PO_DETAILS.RESET_PO_DETAILS_STATE
})

export const isEditInboundAction = isEdit => ({
    type: PO_DETAILS.IS_EDIT_INBOUND,
    payload: isEdit
})

export const setInboundReport = (info) => ({
    type: PO_DETAILS.SET_INBOUND_INFO,
    payload: info
})

export const getInboundReport = (idOrder) => (dispatch,getState) => {
    // const details = getState().purchaseOrdersReducer.detailsPurchase
    adminInstance.get(`${url}/product/inbound/${idOrder}`)
        .then(res => {
            dispatch(setInboundReport(res.data))
        })
}