import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {findPermInArray} from '../../../../../../reuseFunctions/checkPermission'
import { permEditInbounding } from '../../../../../../permissions/productOverview'
import Tooltip from '../../../../../reuseComponent/Tooltips'
import { formatNumber } from '../../../../../../reuseFunctions/formatValue'

class TableProducts extends PureComponent {
    render() {
        const {handleShowEditModal, box, details, searchedProducts, roles, bst} = this.props;
        // console.log('BOX: ', box);
        return (
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th>BID</th>
                        <th>EAN</th>
                        <th>SKU</th>
                        <th>Size</th>
                        <th>CG1</th>
                        <th>CG2</th>
                        <th>CG3</th>
                        <th>CG4</th>
                        <th>Season</th>
                        <th>Brand</th>
                        <th>Quality</th>
                        <th>Quantity</th>
                        <th>RRP</th>
                        <th>BST</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {box && box.products && box.products.map(pr => {
                    const isSearchedProduct = !!searchedProducts.find(sPr => sPr.id === pr.id)
                    return <tr key={pr.product.id}>
                        <td>{pr.product.bid}</td>
                        <td className={`${isSearchedProduct ? 'is-searched' : ''}`}>
                                <span>{pr.product.ean}
                                </span>
                        </td>
                        <td>{pr.product.styleNumber}</td>
                        <td>{pr.product.size}</td>
                        <td>{pr.product.cg1}</td>
                        <td>{pr.product.cg2}</td>
                        <td>{pr.product.cg3}</td>
                        <td>{pr.product.cg4}</td>
                        <td>{pr.product.season}</td>
                        <td>{pr.product.brand}</td>
                        <td>{pr.product.quality}</td>
                        <td>{pr.itemsCount}</td>
                        <td>{pr.product.rrp ? String(pr.product.rrp).replace('.',',') : ''}</td>
                        <td>{pr.bst}</td>
                        <td>
                            <div className="actions">
                                {!box.closed && !details.completeInbound && findPermInArray(roles, permEditInbounding) && <Tooltip text="Edit">
                                    <svg onClick={() => handleShowEditModal(pr, box)} viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                              d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"/>
                                    </svg>
                                </Tooltip>
                                }
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        roles: state.rolesReducer.roles,
        bst: state.inboundingReportReducer.boxes,
    }
}

export default connect(mapStateToProps, null)(TableProducts)