import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PalletDetailInfo } from './PalletDetailInfo'
import { Button } from 'react-bootstrap/index'
import BoxCollapse from '../../details/boxCollapse'
import { PackItem } from '../../details/PackItem'
import { ConfirmModalContent } from '../../../../reuseComponent/confirmModalComponent'
import Modal from '../../../../reuseComponent/modal/modalWithChildren'
import { closeBox, openBox, removeBox } from '../../../../../services/boxes'
import { defaultServerError } from '../../../../../reuseFunctions/toasts'
import { notify } from '../../../../reuseComponent/toast'
import { moveBoxesBetweenPallet, sortPallet } from '../../../../../services/inventory'
import ModalMoveBoxes from '../../details/moveBoxesModal'
import { findPermInArray } from '../../../../../reuseFunctions/checkPermission'
import { getHistory } from '../../../../../services/pallet'
import { palletChronologyFiledsConverting } from '../../../../../constants'
import HistoryList from '../../../../reuseComponent/history/list'
import { permEditInventory } from '../../../../../permissions/inventory'

import { ReactComponent as MoveIcon } from '../../../../../assets/images/package-up.svg'
import { ReactComponent as EditIcon } from '../../../../../assets/images/edit.svg'
import { ReactComponent as ChronoIcon } from '../../../../../assets/images/chronology.svg'
import { ReactComponent as InfoIcon } from '../../../../../assets/images/info.svg'
import { ReactComponent as ArrowDown } from '../../../../../assets/images/arrow-bottom.svg'
import { ReactComponent as ArrowUp } from '../../../../../assets/images/arrow-top.svg'
import { ReactComponent as ArrowsSort } from '../../../../../assets/images/sortArrows.svg'
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/delete.svg'


const PalletDetailModal = ({
                             pallet,
                             setPallet,
                             setLoading,
                             handleOpenDetailModal,
                             handleClose,
                             handleOpenEditModal,
                            handleOpenRemoveModal,
                             createPalletCallback,
                           }) => {

  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [selectedPallet, setSelectedPallet] = useState(null)
  const [isConfirm, setIsConfim] = useState(false)
  const [openBoxesMoveModal, setOpenBoxesMoveModal] = useState(false)
  const [openRemoveBoxModal, setOpenRemoveBoxModal] = useState(false)
  const [selectedBox, setSelectedBox] = useState(null)
  const [highlightedBox, setHighlightedBox] = useState(null)
  const [openHronology, setOpenChronology] = useState(false)
  const [sortType, setSortType] = useState(null)
  const [sortedBoxes, setSortedBoxes] = useState([])

  const roles = useSelector(state => state.rolesReducer.roles)

  const location = useLocation();

  const hasPermToEdit = useMemo(() => {
    return findPermInArray(roles, permEditInventory)
  }, [roles])

  const handleSelectUnselectBox = useCallback(boxId => {
    setSelectedBoxes(prevState =>
      prevState.includes(boxId) ? prevState.filter(id => id !== boxId) : prevState.concat(boxId))
  }, [])

  const handleOpenRemoveBoxModal = useCallback((box) => {
    setSelectedBox(box)
    setOpenRemoveBoxModal(true)
  }, [])

  const handleCloseRemoveBoxModal = useCallback(() => {
    setOpenRemoveBoxModal(false)
    setSelectedBox(null)
  }, [])

  const handleRemoveBox = useCallback((id) => {
    setLoading(true)
    removeBox(id)
      .then(res => {
        handleOpenDetailModal(pallet, null, () => {
          notify('success','Pack successfully deleted')
          setOpenRemoveBoxModal(false)
        })
      })
      .catch(() => {
        setLoading(false)
        defaultServerError()
      })
  }, [handleOpenDetailModal, setLoading, pallet])

  const handleMoveBoxes = useCallback((palletId) => {
      setLoading(true)
      moveBoxesBetweenPallet(selectedBoxes, palletId)
        .then(res => {
          handleOpenDetailModal(pallet, null, () => {
            notify('success', 'Packs successfully moved')
            setOpenBoxesMoveModal(false)
            setSelectedBoxes([])
          })
        })
        .catch(err => {
          setLoading(false)
          notify('error', 'Oops something went wrong')
        })
  }, [selectedBoxes, setLoading, handleOpenDetailModal, pallet])

  const handleOpenCloseBox = useCallback((boxId, open) => {
    const promise = open ? openBox(boxId) : closeBox(boxId)
    promise
      .then(res => {
        notify('success', `Pack successfully ${open ? 'opened' : 'closed'}`)
        setPallet(prevState => ({
          ...prevState,
          closedPack: open ? prevState.closedPack - 1 : prevState.closedPack + 1,
          boxes: prevState.boxes.map(item => item.id === boxId ? ({...item, closed: !open}) : item)
        }))
      })
      .catch(err => {
        notify('error', 'Oops something went wrong')
      })
  }, [setPallet])

  const handleSort = useCallback(() => {
    setSortType(prevState => prevState ? (prevState === 'desc' ? 'asc' : null) : 'desc')
    if(sortType === 'desc' || !sortType){
      sortPallet(pallet.id, sortType)
        .then(res => {
          if(res){
            setSortedBoxes(res.data)
          }
        })
    }else{
      setSortedBoxes(pallet.boxes)
    }
  }, [pallet, sortType])

  useEffect(() => {
    if(hasPermToEdit){
      setOpenBoxesMoveModal(false);
      setOpenRemoveBoxModal(false);
      setSelectedBoxes([])
    }
  }, [hasPermToEdit])

  useEffect(() => {
    if (location && location.state && location.state.scannedBox) {
      setHighlightedBox(location.state.scannedBox)
    }
  }, [location])

  useEffect(() => {
    if(pallet && pallet.boxes){
      setSortedBoxes(pallet.boxes)
    }else{
      setSortedBoxes([])
    }
  }, [pallet])

  return pallet && <>
    <div className="pallet-detail">
      <div className="actions-pallet">
        {sortedBoxes && sortedBoxes.length > 0 && <Button onClick={() => handleSort()}>
          {sortType ? (sortType === 'desc' ? <ArrowDown className="standart-svg"/> : <ArrowUp className="standart-svg"/>) : <ArrowsSort className="standart-svg"/>}
          Sort
        </Button>}
        {selectedBoxes.length > 0 && <Button onClick={() => setOpenBoxesMoveModal(true)}>
          <MoveIcon className="standart-svg"/> Move Packs ({selectedBoxes.length})
        </Button>}
        <Button onClick={() => setOpenChronology(!openHronology)}>
          {!openHronology ? <ChronoIcon className="standart-svg"/> : <InfoIcon className="standart-svg"/> }
          {!openHronology ? 'View сhronology' : 'View info' }
        </Button>
        {findPermInArray(roles, permEditInventory) && <Button onClick={handleOpenEditModal}>
          <EditIcon className="standart-svg"/> Edit
        </Button>}
        {findPermInArray(roles, permEditInventory) && <Button onClick={handleOpenRemoveModal}>
          <DeleteIcon className="standart-svg"/> Delete
        </Button>}
        <Button onClick={handleClose}>
          <svg className="standart-svg" viewBox="0 0 24 24">/><path fill="#000000" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
          Close
        </Button>
      </div>
      {pallet && <PalletDetailInfo pallet={pallet} />}
      <div className="list-boxes">
        {!openHronology && sortedBoxes.map(item => item.poInboundType !== 'Totals' ? <BoxCollapse
            key={item.id}
            box={item}
            isSelected={selectedBoxes.includes(item.id)}
            isScanned={highlightedBox === item.id}
            handleSelect={handleSelectUnselectBox}
            handleOpenCloseBox={handleOpenCloseBox}
            removeBox={e => {
              e.stopPropagation()
              handleOpenRemoveBoxModal(item)
            }}
          />
          : <PackItem
            key={item.id}
            box={item}
            isSelected={selectedBoxes.includes(item.id)}
            isScanned={highlightedBox === item.id}
            handleSelect={handleSelectUnselectBox}
            handleOpenCloseBox={handleOpenCloseBox}
            removeBox={e => {
              e.stopPropagation()
              handleOpenRemoveBoxModal(item)
            }}
          />
        )}
        {openHronology && <div className="list-history">
          <HistoryList
          id={pallet.id}
          getHistory={getHistory}
          chronologyFiledsConverting={palletChronologyFiledsConverting} />
        </div>}
      </div>
    </div>
      <Modal
        open={openRemoveBoxModal}
        isFooter={true}
        handleConfirm={() => handleRemoveBox(selectedBox ? selectedBox.id : null)}
        handleClose={handleCloseRemoveBoxModal}
        handleCancel={handleCloseRemoveBoxModal}
        options={{
          centered: true
        }}
      >
        <ConfirmModalContent text="delete pack"/>
      </Modal>
      <Modal
        open={openBoxesMoveModal}
        // handleClose={this.handleHideBoxesMoveModal}
        isFooter={isConfirm}
        handleConfirm={() => handleMoveBoxes(selectedPallet)}
        handleCancel={() => {
          setOpenBoxesMoveModal(false)
          setSelectedPallet(null)
          setIsConfim(false)
        }}
        options={{
          centered: true
        }}
      >
       <ModalMoveBoxes
          refetchTable={() => console.log('refetch')}
          palletId={pallet}
          selectedPallet={selectedPallet}
          selectedBoxes={selectedBoxes}
          handleConfirm={(selectedPallet) => {
            setSelectedPallet(selectedPallet)
            setIsConfim(true)
          }}
          handleChangePallet={pallet => setSelectedPallet(pallet)}
          handleClose={() => {
            setOpenBoxesMoveModal(false)
            setSelectedPallet(null)
            setIsConfim(false)
          }}
          isConfirm={isConfirm}
          callbackCreatePallet={createPalletCallback}
        />
      </Modal>
    </>
}

export { PalletDetailModal }