import React, {PureComponent} from 'react'
import {url} from '../../../../../constants'
import adminInstance from '../../../../instances/admininstance'
import Select from '../../../../reuseComponent/Select/select-form'
import {Button} from 'react-bootstrap'
import {convertToFormData} from '../../../../../reuseFunctions/objectToFormData'
import {notify} from '../../../../reuseComponent/toast'
import Preloader from '../../../../reuseComponent/preloader/preloader'
import { connect } from 'react-redux'

class SizeConverter extends PureComponent {

    state = {
        isLoading: true,
        types: [],
        selectedType: null,
        isTouched: false
    }

    handleConvert = () => {
        const {selectedType} = this.state
        const {callback,details} = this.props
        selectedType && this.setState({
            isLoading: true
        }, () => {
            adminInstance.put(`${url}/product/convert_size`, convertToFormData({
                sizeType: selectedType,
                orderId: details.id
            }))
                .then(res => {
                    notify('success', 'Products successfully converted')
                    callback && callback()
                })
        })
    }

    getTypesSizes = () => {
        adminInstance.get(`${url}/size/type`)
            .then(res => {
                this.setState({
                    types: res.data.map(type => ({
                        value: type.id,
                        label: type.value
                    })),
                    isLoading: false
                })
            })
    }

    componentDidMount() {
        this.getTypesSizes()
    }

    render() {
        const {types, isTouched, selectedType, isLoading} = this.state
        const {handleCancel} = this.props
        return (
            <div className="wrapper-converter">
                {isLoading ? <Preloader/> :
                    <>
                        <h6 className="center-h">Choose the target type of size you want to convert to</h6>
                        <Select
                            errorLabel="required field"
                            isInvalid={isTouched && !selectedType}
                            options={types}
                            onChange={obj => {
                                this.setState({
                                    selectedType: obj.value
                                })
                            }}
                        />
                        <div className="actions">
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button onClick={() => this.setState({
                                isTouched: true
                            }, () => this.handleConvert())}>Confirm</Button>
                        </div>
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

export default connect(mapStateToProps,null)(SizeConverter)