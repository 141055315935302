import React from 'react'
import {Button} from 'react-bootstrap'
import { connect } from 'react-redux'

const CustomToolbar = (props) => {
    const { handleSave,isEditing,handleCancel,id } = props
    return (
        <div id={id} style={props.style}>
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
            <div style={{float: 'right'}}>
                {isEditing && <Button variant="primary" className="comment-button comment-button-cancel" onClick={handleCancel} style={{marginRight: 5}}>Cancel</Button>}
                <Button variant="primary" className="comment-button" onClick={handleSave}>Comment</Button>
            </div>
        </div>
    )
}



export default connect(null,null)(CustomToolbar)