import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Form, Button} from 'react-bootstrap'
import {withRouter} from 'react-router-dom'
import {allocateCustomerService, bulkAllocateCustomerService} from "../../../../services/customers";
import {defaultServerError} from "../../../../reuseFunctions/toasts";
import {notify} from "../../../reuseComponent/toast";
import Tooltip from '../../../reuseComponent/Tooltips'
import {findPermInArray} from "../../../../reuseFunctions/checkPermission";
import { permViewAllCustomers, permReallocationCustomers } from "../../../../permissions/customers";
import {ReactComponent as AllocateIcon} from '../../../../assets/images/assing.svg'

class ResponsibleList extends PureComponent {

    state = {
        showHide: false,
        confirmModal: false,
        currUser: false,
        search: '',
        selectedUser: null,
    }

    refNode = React.createRef()
    searchFieldRef = React.createRef()


    showHideModal = (modalName, open) => {
        this.setState({
            [modalName]: open
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const activeUser = document.querySelector('.selected-li')
        if (activeUser)
            activeUser.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })

        if(prevProps.responsibleUser !== this.props.responsibleUser){
            this.setState({
                showHide: false
            })
        }

        // const {roles} = this.props;
        // if(prevProps.roles.length !== roles.length){
        //     const hasPermToAllocate = this.hasPermToAllocate();
        //
        //     this.setState({
        //         showHide: hasPermToAllocate ? prevState.showHide : false,
        //         confirmModal: hasPermToAllocate ? prevState.confirmModal : false,
        //     })
        // }
    }

    showHide = e => {
        e.stopPropagation()
        const isShow = this.state.showHide
        this.setState(state => ({
            showHide: !state.showHide,
            search: '',
            selectedUser: state.showHide ? null : state.selectedUser
        }), () => {
            if (isShow)
                document.removeEventListener('keyup', this.handlePressArrows)
            else
                document.addEventListener('keyup', this.handlePressArrows)
            this.searchFieldRef.current && this.searchFieldRef.current.focus()
        })
    }

    handleSearch = e => {
        this.setState({
            search: e.target.value,
            selectedUser: null
        })
    }

    changeResponsible = (comment) => {
        const {changeRow, customer, isBulk, isAllSelected, updateTable, selectedCustomers} = this.props
        const userId = this.state.currUser.id;
        this.setState({
            confirmModal: false,
            selectedUser: null,
            search: ''
        }, () => {
            if(!isBulk) {
                allocateCustomerService(customer.id, {
                    acceptUrl: `${window.location.origin}/atw/customer/${customer.id}/allocate`,
                    customerUrl: `${window.location.origin}/atw/customer`,
                    reallocateUrl: `${window.location.origin}/atw/customer/${customer.id}/reject`,
                    rejectUrl: `${window.location.origin}/atw/customer`,
                    userTo: userId,
                    comment: comment,
                })
                    .then(res => {
                        if (res.data) {
                            notify('success', 'Responsible successfully allocated');
                            changeRow(customer.id, this.state.currUser)
                        }
                    })
                    .catch(err => {
                        defaultServerError()
                    })
            }else{
                bulkAllocateCustomerService({
                    all: isAllSelected,
                    comment: comment,
                    ids: selectedCustomers,
                    userTo: userId,
                })
                    .then(res => {
                        notify('success', 'Responsible successfully allocated');
                        updateTable();
                    })
            }
        })
    };

    handleClickOutSide = (event) => {
        if (this.refNode.current && !this.refNode.current.contains(event.target)) {
            this.setState({
                showHide: false,
                search: '',
                selectedUser: null
            }, () => document.removeEventListener('keyup', this.handlePressArrows))
        }
    }

    handlePressArrows = e => {
        const {users} = this.props
        const {selectedUser} = this.state
        if (e.keyCode === 38 && selectedUser > 0) {
            this.setState(state => ({
                selectedUser: state.selectedUser - 1,
            }))
        }
        else if (e.keyCode === 38 && selectedUser === 0) {
            this.setState({
                selectedUser: null,
            })
        }
        else if (e.keyCode === 40 && selectedUser !== null && selectedUser < users.length - 1) {
            this.setState(state => ({
                selectedUser: state.selectedUser + 1,
            }))
        }
        else if (e.keyCode === 40 && selectedUser !== null && selectedUser === users.length - 1) {
            this.setState({
                selectedUser: null,
            })
        }
        else if (e.keyCode === 40 || e.keyCode === 38)
            this.setState({
                selectedUser: 0
            })
        else if (e.keyCode === 13 && selectedUser !== null) {
            const {responsibleUser, users, idPurchase} = this.props
            const {search} = this.state
            const filteredUsers = users.filter(user => {
                const compareString = user.name + user.secondName
                return compareString.toLowerCase().indexOf(search.toLowerCase()) >= 0
            })
            if (!responsibleUser)
                this.changeResponsible(idPurchase, filteredUsers[selectedUser])
            else {
                this.setState({
                    currUser: filteredUsers[selectedUser],
                    confirmModal: true
                })
            }
        }
    }

    hasPermToAllocate = () => {
        const {roles, responsibleUser, isBulk, currUser} = this.props;
        if(isBulk){
            return findPermInArray(roles, permViewAllCustomers)
        }else{
            return findPermInArray(roles, permViewAllCustomers)
                || (responsibleUser ? responsibleUser.username === currUser.username : false)
        }
    };

    hasPermToReAllocate = () => {
        const { roles } = this.props;
        return findPermInArray(roles, permReallocationCustomers);
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutSide)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutSide)
    }

    onInputClick = () => {
        this.setState({
            showHide: false,
        });
    }
    
    render() {
        const {users, responsibleUser, customer, isBulk, style, handleAllocate, handleBulkAllocate} = this.props
        const {
            showHide,
            search,
            selectedUser,
        } = this.state
        let initials = ''
        let initialsColor = '';
        let name = ''
        if (responsibleUser) {
            if (responsibleUser.initials)
                initials = responsibleUser.initials
            else {
                name = `${responsibleUser.name} ${responsibleUser.secondName}`
                if (responsibleUser.name)
                    initials += responsibleUser.name[0].toUpperCase()
                if (responsibleUser.secondName)
                    initials += responsibleUser.secondName[0].toUpperCase()
            }
        }
        else {
            initials = 'UN'
            name= 'Select responsible'
        }

        let filteredUsers = users.filter(user => {
            const compareString = user.name + user.secondName
            return compareString.toLowerCase().indexOf(search.toLowerCase()) >= 0
        })

        if(responsibleUser){
            filteredUsers = filteredUsers.filter(user => user.username !== responsibleUser.username)
        }

        const respUserExtended = responsibleUser ? users.find(item => item.username === responsibleUser.username) : null

        if(respUserExtended){
            initials = respUserExtended.initials ? respUserExtended.initials : initials;
            initialsColor = respUserExtended.initialsColor ? respUserExtended.initialsColor : '#000000';
        }

        return (
            <>
                <div
                    style={style}
                    className={`wrapper-department-column ${isBulk ? 'bulk': ''}`}
                    ref={this.refNode} onClick={(e) => {
                        this.showHide(e)
                }}>
                    {respUserExtended && respUserExtended.department &&
                        <div className="departament-flex"><Tooltip
                            text={respUserExtended.department.name}
                        >
                        <span className="icon-purchase-status"
                              dangerouslySetInnerHTML={{__html: respUserExtended.department.icon}}></span>
                        </Tooltip>
                        </div>
                    }
                    <div className="responsible-wrapper">
                        {!isBulk ? <Tooltip
                            text={name}
                            >
                        <button type="button" className="initials-button"
                                disabled={!this.hasPermToReAllocate()}
                                style={responsibleUser ? {
                            color: initialsColor
                        } : {}}>{initials}</button>
                        </Tooltip> :
                            <Button
                                // disabled={!this.hasPermToAllocate()}
                                type="button"
                                style={{
                                    marginRight: '0'
                                }}
                                className="bulk-active-mode-button"
                                variant="primary"
                            >
                                <AllocateIcon/>
                                Allocate
                            </Button>
                        }
                        <ul className={`list-users-responsible ${showHide && this.hasPermToReAllocate() ? '' : 'hide'}`}>
                            <div className="search-li"
                                onClick={(e) => this.showHide(e)}
                            >
                                <Form.Control
                                    onFocus={() => this.setState({
                                        selectedUser: null
                                    })}
                                    ref={this.searchFieldRef}
                                    onClick={() => this.onInputClick()}
                                    placeholder="Search..."
                                    onChange={this.handleSearch}
                                    value={search}
                                />
                            </div>
                            {filteredUsers.map((user, index) => {
                                const compareString = user.name + user.secondName
                                return compareString.toLowerCase().indexOf(search.toLowerCase()) >= 0 ?
                                    <li onClick={(e) => {
                                        e.stopPropagation()
                                        // if (!responsibleUser)
                                        //     this.changeResponsible(customer.id, user)
                                        // else
                                            this.showHide(e);
                                        if(isBulk){
                                            handleBulkAllocate(user)
                                        }else{
                                            handleAllocate(user, customer)
                                        }
                                            // this.setState({
                                            //     currUser: user,
                                            //     confirmModal: true,
                                            // })
                                    }} key={user.id}
                                        className={selectedUser === index ? 'selected-li' : ''}
                                    >
                                        <div>{user.name} {user.secondName}</div>
                                    </li> : null
                            })}
                        </ul>
                    </div>
                    {/*{!isBulk && <CustomModalWithChildren*/}
                    {/*    open={confirmModal}*/}
                    {/*    handleClose={e => this.showHideModal('confirmModal', false)}*/}
                    {/*    options={{*/}
                    {/*        centered: true*/}
                    {/*    }}*/}
                    {/*    isFooter={false}*/}
                    {/*>*/}
                    {/*    <RejectModal*/}
                    {/*        commentTitle="Comment"*/}
                    {/*        handleCancel={e => this.showHideModal('confirmModal', false)}*/}
                    {/*        handleConfirm={(reason) => this.changeResponsible(reason)}*/}
                    {/*    >*/}
                    {/*        <ConfirmModalContent*/}
                    {/*            text={`allocate "${customer.companyName}" customer to "${currUser ?*/}
                    {/*                currUser.name + ' ' + currUser.secondName : ''}"`}*/}
                    {/*        />*/}
                    {/*    </RejectModal>*/}
                    {/*</CustomModalWithChildren>}*/}
                    {/*{isBulk && <CustomModalWithChildren*/}
                    {/*    open={confirmModal}*/}
                    {/*    handleClose={e => this.showHideModal('confirmModal', false)}*/}
                    {/*    options={{*/}
                    {/*        centered: true*/}
                    {/*    }}*/}
                    {/*    ifFooter={false}*/}
                    {/*>*/}
                    {/*    <RejectModal*/}
                    {/*        commentTitle="Comment"*/}
                    {/*        handleCancel={e => this.showHideModal('confirmModal', false)}*/}
                    {/*        handleConfirm={(reason) => this.changeResponsible(reason)}*/}
                    {/*    >*/}
                    {/*        <ConfirmModalContent*/}
                    {/*            text={`allocate all selected customers to "${currUser ?*/}
                    {/*                currUser.name + ' ' + currUser.secondName : ''}"`}*/}
                    {/*        />*/}
                    {/*    </RejectModal>*/}
                    {/*</CustomModalWithChildren>}*/}
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.usersReducer.users,
        currUser: state.usersReducer.currUser.userDetails,
        roles: state.rolesReducer.roles,
    }
}

export default withRouter(connect(mapStateToProps, null)(ResponsibleList))