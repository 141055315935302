import * as yup from 'yup'

export const inboundQuantitySchema = yup.object().shape({
  actualQuantity: yup.number()
    .typeError('Should be number')
    .integer('Should be number')
    .min(1, 'Should be number')
    .required('Required field'),
})

export const inboundArrivalDateSchema = yup.object().shape({
  actualArrivalDate: yup.string()
    .typeError('Required field')
    .required('Required field'),
})

export const inboundPackSchema = yup.object().shape({
  actualPackagesCount: yup.number()
    .typeError('Should be number')
    .integer('Should be number')
    .min(1, 'Should be number')
    .required('Required field')
})

export const inboundPalletsSchema = yup.object().shape({
  actualPalletsCount: yup.number()
      .typeError('Should be number')
      .integer('Should be number')
      .min(1, 'Should be number')
      .required('Required field'),
})

export const inboundGrossKgSchema = yup.object().shape({
  actualGrossWeight: yup.number()
      .typeError('Should be number')
      .min(1, 'Should be number')
      .required('Required field'),
})

export const inboundNetKgSchema = yup.object().shape({
  actualNetWeight: yup.number()
      .typeError('Should be number')
      .min(1, 'Should be number')
      .required('Required field'),
})