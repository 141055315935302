import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import * as uniqId from 'uniqid';
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import {
    permManageProductList,
} from '../../../../permissions/productOverview';
import {setFilterConditions} from '../../../../actions/purchase-orders';
import adminInstance from '../../../instances/admininstance'
import {url} from '../../../../constants'

class Toolbar extends PureComponent {

    handleRevalidate = () => {
        const {tableRef, details} = this.props
        adminInstance.post(`${url}/purchase_order/${details.id}/revalidate`)
            .then(res => {
                tableRef.current && tableRef.current.onQueryChange()
            })
    }

    render() {
        const {
            isBulkEdit,
            productsErrors,
            details,
            dividingStatus,
            roles,
            handleClickImportXls,
            showConvertModal,
            status,
            setFilter,
            products,
            isAllChecked,
            paginationPosition,
            pagination,
            handleOpenFilterModal,
            handleClickEdit,
            activeDisableBulkMode
        } = this.props;
        return (
            <div className="products-toolbar-wrapper">
                <div className="buttons-bulk-edit">
                    {isBulkEdit && findPermInArray(roles, permManageProductList) && (
                        <>
                            <Button
                                onClick={handleClickEdit}
                                disabled={products.length === 0 && !isAllChecked}
                                className="edit-bulk-button"
                            >
                                <svg viewBox="0 0 24 24">
                                    <path
                                        fill="#000000"
                                        d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                                    />
                                </svg>
                                Edit
                            </Button>
                        </>
                    )}
                    {!findPermInArray(roles, permManageProductList) ? null : <Button
                        disabled={
                           details.totalItems === 0
                        }
                        className="bulk-active-mode-button"
                        onClick={activeDisableBulkMode}
                    >
                        {!isBulkEdit ? (
                            <>
                                <svg viewBox="0 0 24 24">
                                    <path
                                        fill="#000000"
                                        d="M21,19V17H8V19H21M21,13V11H8V13H21M8,7H21V5H8V7M4,5V7H6V5H4M3,5A1,1 0 0,1 4,4H6A1,1 0 0,1 7,5V7A1,1 0 0,1 6,8H4A1,1 0 0,1 3,7V5M4,11V13H6V11H4M3,11A1,1 0 0,1 4,10H6A1,1 0 0,1 7,11V13A1,1 0 0,1 6,14H4A1,1 0 0,1 3,13V11M4,17V19H6V17H4M3,17A1,1 0 0,1 4,16H6A1,1 0 0,1 7,17V19A1,1 0 0,1 6,20H4A1,1 0 0,1 3,19V17Z"
                                    />
                                </svg>
                                Select
                            </>
                        ) : (
                            <>
                                <svg viewBox="0 0 24 24">
                                    <path
                                        fill="#000000"
                                        d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                                    />
                                </svg>
                                Cancel
                            </>
                        )}
                    </Button>}
                    {details.totalItems > 0 && <Button
                        disabled={details.totalItems === 0}
                        className="smart-filter-button"
                        onClick={handleOpenFilterModal}
                    >
                        <svg viewBox="0 0 24 24">
                            <path
                                fill="#000000"
                                d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"
                            />
                        </svg>
                        Filter
                    </Button>}
                    {findPermInArray(roles, permManageProductList) && (
                        <Button
                            disabled={details.completeInbound}
                            onClick={handleClickImportXls}
                            className="smart-filter-button"
                        >
                            <svg viewBox="0 0 24 24">
                                <path
                                    fill="#000000"
                                    d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z"
                                />
                            </svg>
                            Import
                        </Button>
                    )}
                    {findPermInArray(roles, permManageProductList) && <Button
                        disabled={status || details.completeInbound || details.totalItems === 0}
                        onClick={showConvertModal}
                        className="smart-filter-button"
                    >
                        <svg viewBox="0 0 24 24">
                            <path
                                fill="#000000"
                                d="M12,6V9L16,5L12,1V4A8,8 0 0,0 4,12C4,13.57 4.46,15.03 5.24,16.26L6.7,14.8C6.25,13.97 6,13 6,12A6,6 0 0,1 12,6M18.76,7.74L17.3,9.2C17.74,10.04 18,11 18,12A6,6 0 0,1 12,18V15L8,19L12,23V20A8,8 0 0,0 20,12C20,10.43 19.54,8.97 18.76,7.74Z"
                            />
                        </svg>
                        Convert sizes
                    </Button>}

                    {findPermInArray(roles, permManageProductList) && <Button
                        disabled={productsErrors <= 0 || status || details.completeInbound}
                        onClick={this.handleRevalidate}
                        className="smart-filter-button"
                    >
                        <svg viewBox="0 0 24 24">
                            <path
                                fill="#000000"
                                d="M19,12V13.5A4,4 0 0,1 23,17.5C23,18.32 22.75,19.08 22.33,19.71L21.24,18.62C21.41,18.28 21.5,17.9 21.5,17.5A2.5,2.5 0 0,0 19,15V16.5L16.75,14.25L19,12M19,23V21.5A4,4 0 0,1 15,17.5C15,16.68 15.25,15.92 15.67,15.29L16.76,16.38C16.59,16.72 16.5,17.1 16.5,17.5A2.5,2.5 0 0,0 19,20V18.5L21.25,20.75L19,23M12,3C16.42,3 20,4.79 20,7C20,9.21 16.42,11 12,11C7.58,11 4,9.21 4,7C4,4.79 7.58,3 12,3M4,9C4,11.21 7.58,13 12,13C13.11,13 14.17,12.89 15.14,12.68C14.19,13.54 13.5,14.67 13.18,15.96L12,16C7.58,16 4,14.21 4,12V9M20,9V11H19.5L18.9,11.03C19.6,10.43 20,9.74 20,9M4,14C4,16.21 7.58,18 12,18L13,17.97C13.09,19.03 13.42,20 13.95,20.88L12,21C7.58,21 4,19.21 4,17V14Z"
                            />
                        </svg>
                        Re-validate
                    </Button>}

                    {productsErrors > 0 && (
                        <span
                            onClick={() => {
                                setFilter([
                                    {
                                        field: 'errorsCount',
                                        separator: {
                                            label: 'is more than',
                                            value: 'is more than',
                                            withValue: true
                                        },
                                        id: uniqId(),
                                        value: 0
                                    }
                                ]).then(() => {
                                    if (this.props.tableRef.current)
                                        this.props.tableRef.current.onQueryChange();
                                });
                            }}
                            className="products-errors"
                        >
              {productsErrors} undefined values
            </span>
                    )}
                </div>

                {paginationPosition === 'toolbar' && (
                    <div className="pagination-toolbar">
                        {pagination}
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setFilter: conditions => dispatch(setFilterConditions(conditions))
    };
};

export default connect(null, mapDispatchToProps)(Toolbar);
