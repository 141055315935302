import {PURCHASE_ORDERS} from '../constants'
import adminInstanse from '../components/instances/admininstance'
import {url} from '../constants'
import {notify} from '../components/reuseComponent/toast'
import {convertToFormData} from '../reuseFunctions/objectToFormData'
import {changeRoutePush} from '../reuseFunctions/changeRoute'
import {history} from '../history'
import {getDividingStatus} from './dividing'
import {getTasksByPo} from '../services/tasks'
import {permInvoiceChronology} from '../permissions/productOverview'
import {findPermInArray} from '../reuseFunctions/checkPermission'
import axios from 'axios'
// import { generateSearchString } from '../reuseFunctions/queries'
// import { changeWrapperStyleAction } from './admin-global'


export const removeProductsDeletedBid = (bid) => {
    return {
        type: PURCHASE_ORDERS.REMOVE_PRODUCTS_DELETED_BID,
        payload: bid
    }
}
export const changeViewTableAction = (isFullTable) => {
    return {
        type: PURCHASE_ORDERS.CHANGE_VIEW_TABLE,
        payload: isFullTable
    }
}

export const setActiveRow = (idRow) => ({
    type: PURCHASE_ORDERS.SET_ACTIVE_ROW,
    payload: idRow
})

export const loadingDetailAction = (isLoading) => ({
    type: PURCHASE_ORDERS.IS_LOADING_DETAIL,
    payload: isLoading
})


export const resetState = () => ({
    type: PURCHASE_ORDERS.RESET_ALL
})

export const setDetailsPurchaseAction = data => ({
    type: PURCHASE_ORDERS.SET_DETAIL,
    payload: data
})

export const addDeliveryNotes = file => ({
    type: PURCHASE_ORDERS.ADD_DELIVERY_NOTE,
    payload: file
})

export const removeDeliveryNotes = id => ({
    type: PURCHASE_ORDERS.REMOVE_DELIVERY_NOTE,
    payload: id
})


export const changeDetailsAction = values => ({
    type: PURCHASE_ORDERS.CHANGE_PURCHASE,
    payload: values
})

const setListOfParamets = (list, alias) => ({
    type: PURCHASE_ORDERS.SET_LIST_WITH_PARAMETRS,
    payload: {
        name: alias,
        values: list
    }
})

export const getPOStatusesList = () => {
    return dispatch => {
        adminInstanse.get(`${url}/purchase_order/statuses`)
            .then(res => {
                dispatch(setListOfParamets(res.data,'statuses'))
            })
    }
}

export const getListOfParametrs = alias => {
    return dispatch => {
        adminInstanse.get(`${url}/simple_parameter/${alias}`)
            .then(res => {
                dispatch(setListOfParamets(res.data, alias))
            })
    }
}

export const getDetail = (id, withLoading = true) => {
    return dispatch => {
        withLoading && dispatch(loadingDetailAction(true))
        dispatch(changeViewTableAction(false))
        adminInstanse.get(`${url}/purchase_order/${id}`)
            .then(res => {
                dispatch(setDetailsPurchaseAction(res.data))
                dispatch(getDividingStatus(id))
                dispatch(loadingDetailAction(false))
            })
            .catch(err => {
                changeRoutePush('/atw/purchase-orders', history)
                dispatch(loadingDetailAction(false))
                dispatch(changeViewTableAction(true))
                // notify('error','Oops something went wrong')
            })
    }
}


const uploadingProductsAction = isUpload => ({
    type: PURCHASE_ORDERS.IS_UPLOADING_PRODUCTS,
    payload: isUpload
})

export const uploadProductsXls = (id, file, schema = {}, reason) => {
    return dispatch => {
        dispatch(uploadingProductsAction(true))
        let data = new FormData()
        data.append('file', file)
        Object.keys(schema).forEach(key => {
            data.append(key, schema[key])
        })
        if (reason) {
            data.append('reasonReupload', reason)
        }
        adminInstanse.post(`${url}/purchase_order/${id}/upload_xls`, data)
            .then(res => {
                dispatch(showHideUploadingSchema(false))
                dispatch(uploadingProductsAction(false))
                adminInstanse.get(`${url}/purchase_order/${id}`)
                    .then(res => {
                        dispatch(setDetailsPurchaseAction(res.data))
                    })
                reason && dispatch(getHistory(''))
            })
            .catch(err => {
                console.log(err)
                console.log(err.response)
                if(err && err.response && err.response.status === 400 && err.response.data === 'Excel file is not valid! Please check it and try again'){
                    notify('error', 'Excel file is not valid! Please check it and try again')
                }else{
                    notify('error', 'Oops something went wrong')
                }
                dispatch(uploadingProductsAction(false))
            })
    }
}

export const showHideUploadingSchema = isShow => ({
    type: PURCHASE_ORDERS.SHOW_HIDE_UPLOADING_SCHEMA,
    payload: isShow
})

const isLoadingExcelSchema = isLoading => ({
    type: PURCHASE_ORDERS.IS_LOADING_UPLOADING_EXCEL_SCHEMA,
    payload: isLoading
})

const setUploadingSchemaAction = schema => ({
    type: PURCHASE_ORDERS.SET_UPLOADING_EXCEL_SCHEMA,
    payload: schema
})

export const getSchemaOfProductsExcel = () => {
    return dispatch => {
        dispatch(isLoadingExcelSchema(true))
        adminInstanse.get(`${url}/schema/basic`)
            .then(res => {
                dispatch(setUploadingSchemaAction(res.data))
                dispatch(isLoadingExcelSchema(false))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
                dispatch(isLoadingExcelSchema(false))
            })
    }
}

export const addFollower = (id, userId) => {
    return dispatch => {
        adminInstanse.put(`${url}/purchase_order/${id}/add_follower`, {}, {
            params: {
                userId: userId
            }
        })
            .then(res => {
                dispatch(setDetailsPurchaseAction(res.data))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }
}

export const removeFollower = (id, userId) => {
    return dispatch => {
        adminInstanse.delete(`${url}/purchase_order/${id}/remove_follower`, {
            params: {
                userId: userId
            }
        })
            .then(res => {
                dispatch(setDetailsPurchaseAction(res.data))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }
}

export const showHideModalProducts = (data) => {
    return {
        type: PURCHASE_ORDERS.IS_SHOWING_PRODUCTS_MODAL,
        payload: data
    }
}

const loadingHistoryAction = isLoading => ({
    type: PURCHASE_ORDERS.IS_LOADING_HISTORY,
    payload: isLoading
})

const setHistory = history => ({
    type: PURCHASE_ORDERS.SET_HISTORY_PO,
    payload: history
})

// const changeFilterValue = type => ({
//     type: PURCHASE_ORDERS.CHANGE_FILTER_HISTORY,
//     payload: type
// })

export const getHistory = (typeHistory = '') => {
    const params = {}
    if (typeHistory) {
        params.type = typeHistory
    }
    return (dispatch, getState) => {
        const state = getState()
        const roles = state.rolesReducer.roles
        dispatch(loadingHistoryAction(true))
        if (typeHistory !== 'FILE' || !findPermInArray(roles, permInvoiceChronology))
            adminInstanse.get(`${url}/purchase_order/${state.purchaseOrdersReducer.detailsPurchase.id}/history`, {
                params: params
            })
                .then(res => {
                    dispatch(setHistory(res.data.history))
                    // dispatch(changeActiveHistoryTab(typeHistory))

                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
                .finally(() => {
                    dispatch(loadingHistoryAction(false))
                })
        else {
            axios.all([adminInstanse.get(`${url}/purchase_order/${state.purchaseOrdersReducer.detailsPurchase.id}/history`, {
                params: {
                    type: 'FILE'
                }
            }), adminInstanse.get(`${url}/purchase_order/${state.purchaseOrdersReducer.detailsPurchase.id}/history`, {
                params: {
                    type: 'INVOICE'
                }
            })])
                .then(axios.spread((fileRes, invoiceRes) => {
                    dispatch(setHistory([...fileRes.data.history, ...invoiceRes.data.history]))
                }))
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
                .finally(() => {
                    dispatch(loadingHistoryAction(false))
                })
        }
    }
}

const addHistoryItem = historyItem => ({
    type: PURCHASE_ORDERS.ADD_HISTORY_ITEM,
    payload: historyItem
})

export const uploadFileToHistory = file => {
    return (dispatch, getState) => {
        const details = getState().purchaseOrdersReducer.detailsPurchase
        dispatch(loadingHistoryAction(true))

        adminInstanse.post(`${url}/purchase_order/${details.id}/upload_file`, convertToFormData({file: file}), {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                notify('success', 'File successfully uploaded')
                dispatch(addHistoryItem(res.data))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
            .finally(() => dispatch(loadingHistoryAction(false)))
    }
}

export const uploadInvoiceToHistory = (file) => {
    return (dispatch, getState) => {
        const details = getState().purchaseOrdersReducer.detailsPurchase
        dispatch(loadingHistoryAction(true))
        return new Promise((resolve, reject) => {
            adminInstanse.post(`${url}/purchase_order/${details.id}/add_invoice`, convertToFormData({
                file: file,
                poUrl: window.location.origin + '/atw/purchase-orders'
            }), {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    dispatch(addHistoryItem(res.data))
                    resolve()
                    notify('success', 'Invoice successfully uploaded')

                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
                .finally(() => dispatch(loadingHistoryAction(false)))
        })

    }
}

export const addCommentAction = (comment) => ({
    type: PURCHASE_ORDERS.ADD_HISTORY_COMMENT,
    payload: comment
})

export const addComment = ({id, text, usersInComment}) => {
    return dispatch => {
        const data = {
            text: text,
            poUrl: window.location.origin + '/atw/purchase-orders'
        }
        dispatch(loadingHistoryAction(true))
        adminInstanse.post(`${url}/purchase_order/${id}/comment`, convertToFormData(data))
            .then(res => {
                dispatch(addCommentAction(res.data))
            })
            .catch(err => {
                console.log(err)
                notify('error', 'Oops something went wrong')
            })
            .finally(() => {
                dispatch(loadingHistoryAction(false))
            })

    }
}

export const addNotificationAction = (result) => ({
    type: PURCHASE_ORDERS.ADD_NOTIFICATION,
    payload: result
});

export const addNotification = ({userIds, description, poId}) => {
    return dispatch => {
        const data = {
            userIds,
            description,
            poId
        };
        adminInstanse.post(`${url}/purchase_order/notification/comment`, convertToFormData(data))
            .then(res => {
                dispatch(addNotificationAction(res.data));
            })
            .catch(err => {
                console.log(err);
                notify('error', 'Oops something went wrong');
            })
    }
};

export const editCommentAction = (comment) => ({
    type: PURCHASE_ORDERS.EDIT_HISTORY_COMMENT,
    payload: comment
})

export const editComment = ({id, text, commentId, callback}) => {
    return dispatch => {
        const data = {
            text: text,
            commentId: commentId
        }
        adminInstanse.put(`${url}/purchase_order/${id}/comment`, convertToFormData(data))
            .then(res => {
                dispatch(editCommentAction(res.data))
                callback && callback()
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }
}

const removeCommentAction = id => ({
    type: PURCHASE_ORDERS.REMOVE_HISTORY_COMMENT,
    payload: id
})

export const removeComment = ({id, commentId}) => {
    return dispatch => {
        adminInstanse.delete(`${url}/purchase_order/${id}/comment`, {
            params: {
                commentId
            }
        })
            .then(res => {
                dispatch(removeCommentAction(commentId))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }
}

export const changeTasksData = obj => ({
    type: PURCHASE_ORDERS.CHANGE_TASKS_DATA,
    payload: obj
})


export const changeResponsiblePerson = ({id, callback, user, currResponsible}) => {
    return (dispatch, getState) => {
        callback({data: user})
        notify('success', 'Responsible person successfully changed')
        adminInstanse.put(`${url}/purchase_order/${id}/set_reponsible_person?userId=${user.id}`, convertToFormData({
            poUrl: window.location.origin + '/atw/purchase-orders'
        }))
            .then(res => {
                callback && callback(res)
            })
            .catch(err => {
                callback && callback({data: currResponsible})
                notify('error', 'Oops something went wrong')
            })
            .finally(() => {
                // dispatch(changeResponsibleAction(false))
            })
    }
}

export const showHideCreateContact = (isShow) => ({
    type: PURCHASE_ORDERS.SHOW_HIDE_CREATE_CONTACT_MODAL,
    payload: isShow
})


export const closeOpenSectionAction = section => ({
    type: PURCHASE_ORDERS.CLOSE_OPEN_SECTION,
    payload: section
})

export const closeOpenSection = (section, poId) => {
    return (dispatch, getState) => {
        const details = getState().purchaseOrdersReducer.detailsPurchase
        const activeTab = getState().purchaseOrdersReducer.activeTabHistory
        const data = convertToFormData({
            section: section,
            urlToPO: window.location.origin + '/atw/purchase-orders'
        })
        const detailsId = details && details.id ? details.id : poId;

        adminInstanse.put(`${url}/purchase_order/${detailsId}/mark_section`, data)
            .then(res => {
                const sectionObj = {}
                if (section === 'main') {
                    sectionObj.mainCompleted = !details.mainCompleted
                }
                else if (section === 'transport') {
                    sectionObj.transportCompleted = !details.transportCompleted
                }
                else if (section === 'accounting') {
                    sectionObj.accountingCompleted = !details.accountingCompleted
                }
                dispatch(setDetailsPurchaseAction({
                    ...details,
                    ...sectionObj
                }))
                activeTab === '' && dispatch(getHistory())

            })
            .catch(err => {

            })
    }
}

export const closeOpenInbound = (urlPo, poId, taskId) => {
    return (dispatch, getState) => {
        const state = getState()
        const activeTab = state.purchaseOrdersReducer.activeTabHistory
        const details = state.purchaseOrdersReducer.detailsPurchase
        const data = convertToFormData({
            urlToPO: urlPo
        })
        const detailsId = details && details.id ? details.id : poId;

        let inboundData;

        adminInstanse.put(`${url}/purchase_order/${detailsId}/inbound`, data)
            .then((res) => {
                inboundData = res;
                if (taskId) {
                    return adminInstanse.post(`${url}/tasks/${taskId}`)
                }
                return false;
            })
            .then(res => {
                res && dispatch(editTaskAction(res.data[1] ? res.data[1] : {}));
                dispatch(setDetailsPurchaseAction({
                    ...inboundData.data
                }));
                activeTab === '' && dispatch(getHistory())
            })
            .catch(err => {
                notify('error', 'Inbound report contains undefined BID!')
            })

    }
}


export const changeActiveHistoryTab = (tabName) => ({
    type: PURCHASE_ORDERS.CHANGE_ACTIVE_HISTORY_TAB,
    payload: tabName
})

export const setProductsErrors = count => ({
    type: PURCHASE_ORDERS.SET_PRODUCTS_ERRORS,
    payload: count
})

export const setProductsList = productList => ({
    type: PURCHASE_ORDERS.SET_PRODUCTS_LIST,
    payload: productList
})

export const resetFilterForProductsAction = () => ({
    type: PURCHASE_ORDERS.RESET_FILTER_PRODUCT_LIST
})


export const setFilterConditions = conditions => {
    return dispatch => {
        dispatch({
            type: PURCHASE_ORDERS.SET_FILTER_PRODUCT_LIST,
            payload: conditions
        })
        return Promise.resolve()
    }
}

export const setTasksList = (tasks) => ({
    type: PURCHASE_ORDERS.SET_TASKS_LIST,
    payload: tasks
})

export const editTaskAction = (task) => ({
    type: PURCHASE_ORDERS.EDIT_PO_TASK,
    payload: task
})

export const deleteTaskAction = id => ({
    type: PURCHASE_ORDERS.REMOVE_PO_TASK,
    payload: id
})

export const addTaskAction = task => ({
    type: PURCHASE_ORDERS.ADD_TASK_TO_PO,
    payload: task
})

export const editPOAction = po => ({
    type: PURCHASE_ORDERS.EDIT_PO,
    payload: po
})

export const setLoadingTaskAction = isLoading => ({
    type: PURCHASE_ORDERS.LOADING_PO_TASKS,
    payload: isLoading
})

export const getTasksAction = (params, withPreloader = true) => {
    return dispatch => {
        withPreloader && dispatch(setLoadingTaskAction(true))
        getTasksByPo(params)
            .then(res => {
                dispatch(setTasksList(res.data))
            })
            .catch(err => {

            })
            .finally(() => dispatch(setLoadingTaskAction(false)))
    }
}

export const setAvgTotalPo = (data) => ({
    type: PURCHASE_ORDERS.SET_TOTAL_AVARAGE_PO,
    payload: data
})

const setPoStatuses = statuses => ({
    type: PURCHASE_ORDERS.SET_PO_STATUSES,
    payload: statuses
})

export const getPoStatuses = () => {
    return dispatch => {
        adminInstanse.get(`${url}/purchase_order/statuses`)
            .then(res => {
                dispatch(setPoStatuses(res.data))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }
}

const isLoadingOverview = isLoading => ({
    type: PURCHASE_ORDERS.IS_LOADING_PO_OVERVIEW,
    payload: isLoading
})

export const saveOverviewPo = columns => {
    return dispatch => {
        dispatch(isLoadingOverview(true))
        adminInstanse.post(`${url}/user/setting`, convertToFormData({
            setting: JSON.stringify(columns.map(column => {
                const newColumn = {...column}
                delete newColumn.title
                return newColumn
            }))
        }))
            .then(res => {
                dispatch(isLoadingOverview(false))
            })
            .catch(err => {
                notify('error', 'Can not save overview')
            })
    }
}

export const setPoTableRef = tableRef => ({
    type: PURCHASE_ORDERS.SET_TABLE_REF,
    payload: tableRef
})

export const setSnapshotAction = snapshot => ({
    type: PURCHASE_ORDERS.SET_SNAPSHOT,
    payload: snapshot
})

export const setPoInboundingTypes = list => ({
    type: PURCHASE_ORDERS.SET_PO_INBOUNDING_TYPES,
    payload: list,
})

export const selectedPackegeAction = value => {
    return ({
        type: PURCHASE_ORDERS.SELECTED_PACKEGE,
        payload: value
    })
}

