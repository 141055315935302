import React from "react";
import moment from "moment";

const FileItemComponent = props => {
  const { isShowDate, action } = props;
  return (
    <div className="history-item file service-desk-item">
      {isShowDate && (
        <div className="date-history-action">
          {moment(action.createdAt).format("MMM DD, YYYY")}
        </div>
      )}
      <div className="row-action">
        <span className="user-name-action">
          {action.user &&
            `${action.user.name} ${action.user.secondName}`}
        </span>
        <span className={`action-name`}>{action.text}</span>
        {action.file && (
          <a href={action.file.fileUrl} download className="file-link">
            {action.file.originFileName}
          </a>
        )}
        <span className="time-action">
          {moment(action.createdAt, "YYYY-MM-DDTHH:mm:ss").format("HH:mm")}
        </span>
      </div>
    </div>
  );
};

export default FileItemComponent;
