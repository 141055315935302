import React from "react";
import { connect } from "react-redux";

import { findPermInArray } from "../../../../reuseFunctions/checkPermission";

import ToolTip from "../../../reuseComponent/Tooltips";
import {permExportCustomers} from "../../../../permissions/customers";

const DownloadXlsxCustomersComponent = ({ roles, handleConfirmExport }) => {
    return findPermInArray(roles, permExportCustomers) ? (
        <>
            <ToolTip text="Export to xls" placement="bottom">
                <button onClick={handleConfirmExport} className="menu-button">
                    <svg className="action-icon header-icon" viewBox="0 0 24 24">
                        <path
                            fill="#000000"
                            d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"
                        />
                    </svg>
                </button>
            </ToolTip>
        </>
    ) : null;
};

const mapStateToProps = state => ({ roles: state.rolesReducer.roles });

export default connect(mapStateToProps, null)(DownloadXlsxCustomersComponent);