import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Table from '../../../../reuseComponent/Table/MaterialTable'
import Pagination from '../../../../reuseComponent/Table/Pagination'
import Header from '../../../../reuseComponent/Table/table-head'
import {getBrands, createBrand, editBrand, deleteBrand} from '../../../../../services/brands'
import Modal from '../../../../reuseComponent/modal'
import CustomModalWithChildren from "../../../../reuseComponent/modal/modalWithChildren";
import StandartLeftPanner from '../../../../reuseComponent/Header/standartLeftPannel'
import {setData} from '../../../../../actions/admin-header'
import FormikBrands from '../form'
import {notify} from '../../../../reuseComponent/toast'
import DropDownMenu from '../../../../reuseComponent/DropDown'
import EditBrand from '../edit'
import {findPermInArray} from '../../../../../reuseFunctions/checkPermission'
import {permEditBrandsDictionary} from '../../../../../permissions/dictionaries'
import Toolbar from '../Toolbar'
import {ConfirmModalContent} from '../../../../reuseComponent/confirmModalComponent';
import withTracker from "../../../../reuseComponent/GoogleAnalytics";


class ListBrands extends PureComponent {

    state = {
        openCreateModal: false,
        openEditModal: false,
        openDeleteModal: false,
        removedTitle: '',
        currentBrand: null
    }

    tableRef = React.createRef()

    columns = [{
        title: 'Output',
        field: 'name',
        sorting: false
    },
        {
            title: 'Inputs',
            field: 'inputs',
            sorting: false,
            render: rowData => {
                return rowData.synonyms ? rowData.synonyms.map(val => <div key={val}>{val}</div>) : null
            }
        }, {
        title: '',
        field: 'actions',
        sorting: false,
        headerStyle: {
            width: 32
        },
        render: rowData => {
            return findPermInArray(this.props.roles, permEditBrandsDictionary) && <DropDownMenu
                ContentMenu={() => {
                    return (
                        <ul className='list-unstyled no-margin menu-table-actions'>
                            <li>
                                <a href="/" onClick={e => {
                                    e.preventDefault()
                                    this.handleShowEditModal(rowData.id)
                                }}>Edit</a>
                            </li>
                            <li>
                                <a href="/" onClick={e => {
                                    e.preventDefault()
                                    this.handleShowDeleteModal(rowData.id, rowData.name)
                                }}>Delete</a>
                            </li>
                        </ul>
                    )
                }}
            >
            </DropDownMenu>
        }
    }]

    componentDidMount() {
        const {setHeaderData} = this.props;
        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'Brands',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#795548"
                          d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z"/>
                </svg>)),
            },
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: (value) => this.tableRef.current.onSearchChange(value)
        })

    }

    handleShowCreateModal = () => this.setState({openCreateModal: true})

    handleHideCreateModal = () => this.setState({openCreateModal: false})

    handleShowEditModal = (id) => this.setState({openEditModal: true, currentBrand: id})

    handleHideEditModal = () => this.setState({openEditModal: false, currentBrand: null})

    handleShowDeleteModal = (id, removedTitle) => this.setState({openDeleteModal: true, currentBrand: id, removedTitle})

    handleHideDeleteModal = (id) => this.setState({openDeleteModal: false, currentBrand: null})

    getData = query => {
        const {page, pageSize, search} = query;
        return new Promise((resolve, reject) => {
            getBrands({
                page: page,
                limit: pageSize,
                search: search
            })
                .then(res => {
                    resolve({
                        totalCount: res.data.count,
                        data: res.data.brands,
                        page: query.page
                    })
                })
        })
    }

    handleCreateBrand = values => {
        this.handleHideCreateModal()
        createBrand(values)
            .then(res => {
                if(typeof res.data !== 'string'){
                    this.tableRef.current && this.tableRef.current.onQueryChange()
                    notify('success', 'Brand successfully created')
                }
                else{
                    notify('error','Value already exists')
                }
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }

    handleEditBrand = values => {
        const {currentBrand} = this.state
        this.handleHideEditModal()
        editBrand(currentBrand, values)
            .then(res => {
                if(typeof res.data !== 'string'){
                    this.tableRef.current && this.tableRef.current.onQueryChange()
                    notify('success', 'Brand successfully edited')
                }
                else{
                    notify('error','Value already exists')
                }
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }

    handleDeleteBrand = () => {
        const {currentBrand} = this.state
        this.handleHideDeleteModal()
        deleteBrand(currentBrand)
            .then(res => {
                this.tableRef.current && this.tableRef.current.onQueryChange()
                notify('success', 'Brand successfully deleted')
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }

    render() {
        const { roles } = this.props
        const {
            openCreateModal,
            openEditModal,
            openDeleteModal,
            currentBrand,
            removedTitle,
        } = this.state
        return (
            <div className="wrapper-table">
                <Table
                    components={{
                        Header: Header,
                        Toolbar: props => <Toolbar
                            {...props}
                            handleAdd={findPermInArray(roles,permEditBrandsDictionary) ? this.handleShowCreateModal : null}
                        />,
                        Pagination: Pagination
                    }}
                    paginationPosition="toolbar"
                    tableRef={this.tableRef}
                    columns={this.columns}
                    data={this.getData}
                    title=""
                    options={{
                        showTitle: false,
                        search: true,
                        emptyRowsWhenPaging: false,
                        pageSize: 50,
                        pageSizeOptions: [10, 25, 50],
                        maxBodyHeight: 'calc(100vh - 150px)'
                    }}
                />
                {findPermInArray(roles, permEditBrandsDictionary) && <Modal
                    open={openCreateModal}
                    Content={() => <FormikBrands
                        initValues={{
                            name: '',
                            inputs: []
                        }}
                        handleSubmit={this.handleCreateBrand}
                        handleCancel={this.handleHideCreateModal}
                    />}
                    handleClose={this.handleHideCreateModal}
                    isFooter={false}
                    options={{
                        centered: true
                    }}
                />
                }
                {findPermInArray(roles, permEditBrandsDictionary) && <Modal
                    open={openEditModal}
                    Content={() => <EditBrand
                        id={currentBrand}
                        handleSubmit={this.handleEditBrand}
                        handleCancel={this.handleHideEditModal}
                    />}
                    handleClose={this.handleHideEditModal}
                    isFooter={false}
                    options={{
                        centered: true
                    }}
                />
                }
                {findPermInArray(roles, permEditBrandsDictionary) && <CustomModalWithChildren
                    open={openDeleteModal}
                    handleClose={this.handleHideDeleteModal}
                    handleCancel={this.handleHideDeleteModal}
                    handleConfirm={this.handleDeleteBrand}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent text={`delete "${removedTitle}" brand`}/>
                </CustomModalWithChildren>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

export default withTracker(connect(mapStateToProps, {
    setHeaderData: setData
})(ListBrands))