import {Client} from '@stomp/stompjs'
import * as SockJS from 'sockjs-client'
import {url} from './constants'
import store from './store'
import { ADMIN_GLOBAL } from './constants'

const client = new Client()

let timeout = null
const decoratorSocket = (fn) => {
    // clearTimeout(timeout)
    // if(client.connected){
    //     fn()
    // }
    // else{
    //     timeout = setTimeout(() => {
    //         decoratorSocket(fn)
    //     },500)
    // }

    let timeout1 = null;

    const callConnect = (fn) => {
        clearTimeout(timeout1)
        if(client.connected){
            fn()
        }
        else{
            timeout1 = setTimeout(() => {
                callConnect(fn)
            },500)
        }
    }

    callConnect(fn)
}

const handleDestroySocket = (e) => {
    console.log('deactivate')
    if(client.connected) {
        client.deactivate()
    }
}


window.addEventListener('beforeunload', handleDestroySocket)

client.webSocketFactory = function () {
    return new SockJS(`${url}/ws`, {transports: ['websocket']});
};

client.onConnect = () => {
    console.log('connected')
    store.dispatch({
        type: ADMIN_GLOBAL.IS_CONNECTED_TO_SOCKET_CLIENT,
        payload: true
    })
}

// client.debug = (str) => {
//     console.log(str)
// }

client.activate()

export { client,decoratorSocket }