import React from 'react'
import {useSelector} from 'react-redux'
import { SERVICE_WORKER } from './constants'
import {Switch, Route, Redirect} from 'react-router-dom'
import LoginComponent from './components/login'
import AdminPanelComponent from './components/admin'
import Toast from './components/reuseComponent/toast'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/globalStyles/material-fonts.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import Registration from './components/registration'
import './components/admin/adminStyle/style.scss'
import ResetPasswordModule from './components/forgot-password'
import NewVersionDialog from './components/newVersionComponent'
import { notify } from './components/reuseComponent/toast'
import SuccessUpdateSystem from './components/reuseComponent/toast/text/successUpdateSystem'

function App() {
    const isUpdated = useSelector(state => state.serviceWorkerReducer.serviceWorkerUpdated)
    const user = useSelector(state => state.usersReducer.currUser)
    // const isInit = useSelector(state => state.serviceWorkerReducer.serviceWorkerInitialized)
    const serviceWorkerRegistration = useSelector(state => state.serviceWorkerReducer.serviceWorkerRegistration)
    const updateServiceWorker = () => {
        const registrationWaiting = serviceWorkerRegistration ? serviceWorkerRegistration.waiting : null;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    sessionStorage.setItem('showUpdatedToast', true);
                    window.location.reload(true);
                }
            });
        }
        else {
            sessionStorage.setItem('showUpdatedToast', true);
            window.location.reload(true);
        }
    }

    const showUpdatedToast = sessionStorage.getItem('showUpdatedToast');

    return (
        <div className="App">
            <Switch>
                <Route exact path="/login" component={LoginComponent}/>
                <Route path="/reset-password" component={ResetPasswordModule}/>
                <Route exact path="/registration/:token" component={Registration}/>
                <Route path="/atw" component={AdminPanelComponent}/>
                <Redirect to="/atw/homepage"/>
            </Switch>
            {isUpdated && <NewVersionDialog
                onClick={updateServiceWorker}
                type={SERVICE_WORKER.SW_UPDATE}
            />
            }
            <Toast/>
            { !!user.info && showUpdatedToast && notify('success', <SuccessUpdateSystem />) }
        </div>
    );
}

export default App;
