import * as yup from 'yup'

export const schemaLogin = yup.object().shape({
    username: yup
        .string()
        .email()
        .required('Email is a required field'),
    password: yup
        .string()
        .required('Password is a required field')
        .min(6,'Password must be at least 6 characters')
})
