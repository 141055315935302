import * as yup from 'yup'

export const schema = yup.object().shape({
    accountingCreditPeriod: yup.number()
        .typeError('Should be number')
        .integer('Should be number')
        .min(1, 'Should be positive number'),
    prepaidPercentage: yup.string(),
    financeStatus: yup.string(),
    accountingPaymentDate: yup.mixed(),
})
