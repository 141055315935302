import React from 'react'
import { Formik } from 'formik'
import { schema } from './schema'
import BodyForm from './bodyForm'

const FormBrand = props => {
    const {
        initValues,
        handleSubmit,
        handleCancel
    } = props
    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initValues}
            validationSchema={schema}
            render={formikProps => <BodyForm
                {...formikProps}
                handleCancel={handleCancel}
            />}
        />
    )
}

export default FormBrand