import React , { PureComponent } from 'react'
import {connect} from 'react-redux';
import { formatPrice } from '../../../../reuseFunctions/formatValue';

class OfferInfoComponent extends PureComponent{
    render(){
        const { offer, reservationData } = this.props
        // console.log(offer)
        return <div className="offer-details-info">
            {offer.picture && <div className="image-offer">
                <img src={offer.picture} alt="Offer pick"/>
            </div>
            }
            <div className="offer-row-info">
                <strong>Title: </strong> <span>{offer.title}</span>
            </div>
            <div className="offer-row-info">
                <strong>Price, €: </strong> <span>{formatPrice(offer.price)}</span>
            </div>
            <div className="offer-row-info">
                <strong>Unit: </strong> <span>{offer.unit}</span>
            </div>
            <div className="offer-row-info">
                <strong>MOQ: </strong> <span>{offer.moq}</span>
            </div>
            <div className="offer-row-info">
                <strong>Availability: </strong> <span>{offer.availability}</span>
            </div>
            <div className="offer-row-info">
                <strong>Delivery time: </strong> <span>{offer.deliveryTime}</span>
            </div>
            <div className="offer-row-info">
                <strong>Comment: </strong> <span>{offer.comment}</span>
            </div>
            <div className="offer-row-info">
                <strong>Views: </strong> <span>{offer.viewsCount}</span>
            </div>
            {reservationData && reservationData.usersReservation &&
            <>
                <div className="offer-row-info" style={{whiteSpace: 'normal'}}>
                    <strong>Reservation period: </strong>
                    <span>{reservationData.reservationStartDate} - {reservationData.reservationEndDate}</span>
                </div>
                <div className="offer-row-info">
                    <strong>Reserved by: </strong>
                    <span>{reservationData.usersReservation.name} {reservationData.usersReservation.secondName}</span>
                </div>
            </>}
        </div>
    }
}

const mapStateToProps = state => ({
    reservationData: state.offersReducer.reservationData,
});

export default connect(mapStateToProps)(OfferInfoComponent)