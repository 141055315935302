import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {
    getPackagesRed,
    updateInboundReport,
    clearInboundReport,
    setPackagesAction, fetchDeleteCustomPackage
} from '../../../../../actions/inboundingReport';
import {permManageCustomPackage} from '../../../../../permissions/productOverview';
import {findPermInArray} from '../../../../../reuseFunctions/checkPermission';
import PackageTable from './packagesTable';
import BoxesList from './boxesTable';
import {convertPOInboundReportSocketData} from '../../../../../reuseFunctions/formatValue';
import Preloader from '../../../../reuseComponent/preloader/preloader';
import {client, decoratorSocket} from '../../../../../socketClient';
import CreateForm from './form/create';
import EditForm from './form/edit';
import Modal from '../../../../reuseComponent/modal';
import {setup} from '../../../../../actions/printerActions';
import { ConfirmModalWithText } from '../../../../reuseComponent/confirmModalComponent/ConfirmModalWithText'
import CustomModalWithChildren from '../../../../reuseComponent/modal/modalWithChildren'

class InboundingReport extends PureComponent {
    state = {
        openCreateModal: false,
        openRemoveModal: false,
        pack: null
    };

    subscribeInbounding = null;

    openCreateModal = () => this.setState({openCreateModal: true});
    hideCreateModal = () => this.setState({openCreateModal: false, pack: null});
    hideRemoveModal = () => this.setState({openRemoveModal: false, pack: null})

    handleEditPackage = pack => this.setState({pack, openCreateModal: true});
    handleDeletePackage = pack => this.setState({pack, openRemoveModal: true});

    handleRemovePackage = () => {
        const { pack } = this.state;
        const { fetchDeleteCustomPackage} = this.props;
        console.log('remove', pack.shortName)

        fetchDeleteCustomPackage(pack.id, () => {
            this.hideRemoveModal()
        })
    }

    handleConnectSocket = () => {
        const {
            details: {id},
            updateInboundReport
        } = this.props;
        this.subscribeInbounding = client.subscribe(`/inbounding/${id}`, data => {
            const d = JSON.parse(data.body);
            updateInboundReport(d.report, convertPOInboundReportSocketData(d.data));
        });
    };

    componentDidMount() {
        const {
            getPackages,
            printerSetup,
            details: {id},
            snapshot,
            setPackages
        } = this.props;
        // console.log(JSON.parse(snapshot.inboundReport))
        if(snapshot){
            setPackages(JSON.parse(snapshot.inboundReport))
        }
        else{
            id && getPackages && getPackages(id);
        }


        printerSetup && printerSetup();

        decoratorSocket(this.handleConnectSocket);
    }

    componentWillUnmount() {
        const {clearInboundReport} = this.props;
        if (this.subscribeInbounding) this.subscribeInbounding.unsubscribe();
        clearInboundReport();
    }

    checkExistTitle = (packages, title) => {
        let isExist = false;
        packages.forEach(pack => {
            if (pack.custom && pack.fullName === title) {
                isExist = true;
            }
            if (!pack.custom && `${pack.shortName}(${pack.fullName})` === title) {
                isExist = true;
            }
        });
        return isExist;
    };

    render() {
        const {
            actionsComponent,
            isExporting,
            packagesCount,
            isConnectedToSocket,
            loadingPrinters,
            roles,
        } = this.props;
        // console.log(packagesCount)
        const {openCreateModal, pack, openRemoveModal} = this.state;
        return (
            <div className="inbounding-report__wrapper">
                {isExporting && <Preloader text="Downloading"/>}
                {(!isConnectedToSocket || loadingPrinters) && <Preloader/>}
                <div className="inbound-actions">
                    {packagesCount > 0 ? actionsComponent : null}
                </div>
                <PackageTable
                    handleEditPackage={this.handleEditPackage}
                    handleDeletePackage={this.handleDeletePackage}
                    openCreateModal={this.openCreateModal}
                />
                <BoxesList/>
                {findPermInArray(roles, permManageCustomPackage) && (
                    <Modal
                        open={openCreateModal}
                        Content={() =>
                            !pack ? (
                                <CreateForm
                                    handleCancel={this.hideCreateModal}
                                    checkExistTitle={this.checkExistTitle}
                                />
                            ) : (
                                <EditForm
                                    handleCancel={this.hideCreateModal}
                                    checkExistTitle={this.checkExistTitle}
                                    pack={pack}
                                />
                            )
                        }
                        handleClose={this.hideCreateModal}
                        isFooter={false}
                        options={{
                            centered: true
                        }}
                    />
                )}
                {findPermInArray(roles, permManageCustomPackage) && (
                  <CustomModalWithChildren
                    open={openRemoveModal}
                    handleClose={this.hideRemoveModal}
                    handleCancel={this.hideRemoveModal}
                    handleConfirm={this.handleRemovePackage}
                  >
                      <ConfirmModalWithText text={`delete "${pack ? pack.shortName : ''}" package`}/>
                  </CustomModalWithChildren>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loadingPackages: state.inboundingReportReducer.loadingPackages,
    details: state.purchaseOrdersReducer.detailsPurchase,
    packagesCount: state.inboundingReportReducer.packages
        .filter(pack => pack.shortName !== 'Rest')
        .reduce((acc, pack) => {
            if (pack.actual_items) return acc + pack.actual_items;
            return acc;
        }, 0),
    isConnectedToSocket: state.adminGlobal.isSocketConnected,
    roles: state.rolesReducer.roles,
    loadingPrinters:
        state.printersReducer.loading ||
        state.printersReducer.loadingLocationConfiguration,
    snapshot: state.purchaseOrdersReducer.snapshot,
});

const mapDispatchToProps = dispatch => ({
    getPackages: id => dispatch(getPackagesRed(id)),
    updateInboundReport: (report, packages) =>
        dispatch(updateInboundReport(report, packages)),
    clearInboundReport: () => dispatch(clearInboundReport()),
    printerSetup: () => dispatch(setup()),
    setPackages: packages => dispatch(setPackagesAction(packages)),
    fetchDeleteCustomPackage: (packId, callback) => dispatch(fetchDeleteCustomPackage(packId, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(InboundingReport);
