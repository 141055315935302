import { DEPARTMENTS } from '../constants'

const initState = {
    isLoadingDepartment : false,
    department: {
        name: '',
        icon: ''
    },
    departments: []
}

export const departmentsReducer = (state = initState, action) => {
    const { type, payload } = action
    switch(type){
        case DEPARTMENTS.IS_LOADING_DEPARTMENT: {
            return {
                ...state,
                isLoadingDepartment: payload
            }
        }
        case DEPARTMENTS.SET_DEPARTMENT: {
            return {
                ...state,
                department: {
                    name: payload.name,
                    icon: payload.icon
                }
            }
        }
        case DEPARTMENTS.SET_DEPARTMENTS:{
            return {
                ...state,
                departments: [...payload],
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}