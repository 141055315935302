import React from 'react'

const RemoveSizeTypeModalContent = () => {
    return <div className="remove-size-type-modal-content">
        <svg viewBox="0 0 24 24">
            <path fill="#000000" d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z" />
        </svg>
        Are you sure you want to delete this size type for ALL SETS?
    </div>
}

export default RemoveSizeTypeModalContent