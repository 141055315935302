import React , { PureComponent } from 'react'
import AnalysisCompnent from '../../products/analysis';

class Charts extends PureComponent{
    render(){
        return (
            <div className="po-tab-content">
                <div className="content-po-tab">
                    <AnalysisCompnent/>
                </div>
            </div>

        )
    }
}

export default Charts