import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Accordion} from 'react-bootstrap'
import Package from './package-item'
import TableProducts from './package-item/table-products'
import AllPackagesComponent from '../all-component-packages'
import { isChangingSetLoading } from '../../../../../../actions/dividing'
import TableRest from './rest-table'


class ListPackagesComponent extends PureComponent {

    state = {
        activeKey: 0
    }

    handleChangeActiveCollapse = (eventKey) => {
        this.setState({
            activeKey: eventKey
        })
    }

    render() {
        const {activeSet, sets} = this.props
        const setObject = sets.find(set => set.id === activeSet)
        const {activeKey} = this.state
        return (
            <div className="packages-wrapper">
                {setObject && !setObject.rest ? <Accordion activeKey={activeKey}>
                    {setObject.packages ? setObject.packages.map(pc => <Package
                        activeKey={activeKey}
                        handleChange={this.handleChangeActiveCollapse}
                        pack={pc}
                        key={pc.id}
                        setObject={setObject}
                    />)
                    : null}
                </Accordion> : setObject && setObject.rest ?  <TableProducts
                    products={setObject.packages && setObject.packages.length > 0 ? setObject.packages[0].packageProductSet : []}
                />  : null}
                {/* <TableRest
                     products={setObject.packages && setObject.packages.length > 0 ? setObject.packages[0].packageProductSet : []}
                 /> */}
                {activeSet === 'all' && <AllPackagesComponent />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeSet: state.dividingReducer.activeSet,
        sets: state.dividingReducer.conditionSets
    }
}

export default connect(mapStateToProps, {
    isChangingSetLoading
})(ListPackagesComponent)