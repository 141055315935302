import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import moment from "moment";
import { setData } from "../../../actions/admin-header";
import Table from "../../reuseComponent/Table/MaterialTable";
import Pagination from "../../reuseComponent/Table/Pagination";
import Header from "../../reuseComponent/Table/table-head";
import Container from "../../reuseComponent/Table/container";
import { changeRoutePush } from "../../../reuseFunctions/changeRoute";
import { history } from "../../../history";
import adminsInstance from "../../instances/admininstance";
import { url } from "../../../constants";
import Toolbar from "./Toolbar";
import StandartLeftPanner from "../../reuseComponent/Header/standartLeftPannel";
import Details from "./details";
import ToolTip from "../../reuseComponent/Tooltips";
import { setStatusAction, sendToAsana } from "../../../actions/serviceDesk";
import { convertToFormData } from "../../../reuseFunctions/objectToFormData";
import { notify } from "../../reuseComponent/toast";
// import StatusComponent from "./statusComponent";
import {
	permСhangeStatus,
	permViewDepartment,
	permViewAll
} from "../../../permissions/serviceDesk";
import { findPermInArray } from "../../../reuseFunctions/checkPermission";
import CustomModalWithChildren from "../../reuseComponent/modal/modalWithChildren";
import { ConfirmModalContent } from "../../reuseComponent/confirmModalComponent";
import withTracker from "../../reuseComponent/GoogleAnalytics";

const generateQuary = (roles, user) => {
	if (
		findPermInArray(roles, permViewAll) &&
		findPermInArray(roles, permViewDepartment)
	) {
		return "status.alias!#~close";
	} else if (
		findPermInArray(roles, permViewAll) &&
		!findPermInArray(roles, permViewDepartment)
	) {
		return "status.alias!#~close";
	} else if (
		!findPermInArray(roles, permViewAll) &&
		findPermInArray(roles, permViewDepartment)
	) {
		return `author.department.id:${user.userDetails.department.id};status.alias!#~close;`;
	} else {
		return `author.id:${user.userDetails.id};status.alias!#~close;`;
	}
};

class ServiceDeskModule extends PureComponent {
	state = {
		currentStatus: "",
		currentRequest: "",
		modalChangeStatus: false,
		modalSendToAsana: false,
		modalRemoveRequest: false
	};

	tableRef = React.createRef();


	generateColumns = () => {
		const { id } = this.props.match.params;
		const {activeFilterObj} = this.state;
		const defaultColumn = [
			{
				title: '',
				headerStyle: {
					verticalAlign: "middle",
					width: '30px'
				},
				field: "priority",
				sorting: false,
				render: rowData => {
					return (
						<div className="priority-wrapper">
							{rowData.priority && rowData.priority.alias === 'urgent' ? (
								<ToolTip text={rowData.priority.name}>
									<span
										className="priority-info"
										dangerouslySetInnerHTML={{
											__html: rowData.priority.icon
										}}
									></span>
								</ToolTip>
							) : null}
						</div>
					);
				}
			},
			{
				title: (
					<ToolTip text="Status">
						<svg
							style={{
								width: "21px",
								height: "21px",
								marginLeft: "3px"
							}}
							viewBox="0 0 22 22"
						>
							<path
								fill="#B0BEC5"
								d="M11,7H15V9H11V11H13A2,2 0 0,1 15,13V15A2,2 0 0,1 13,17H9V15H13V13H11A2,2 0 0,1 9,11V9A2,2 0 0,1 11,7M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z"
							/>
						</svg>
					</ToolTip>
				),
				headerStyle: {
					verticalAlign: "middle"
				},
				field: "status",
				sorting: false,
				render: rowData => {
					return (
						<div className="actions-wrapper">
							{/* <StatusComponent
								showConfirm={status => {
									this.setState({
										currentStatus: status,
										currentRequest: rowData && rowData.id,
										modalChangeStatus: true
									});
								}}
								activeStatus={rowData.status}
							/> */}
							{rowData.status ? (
								<ToolTip text={rowData.status.name}>
									<span
										className="actions-info"
										dangerouslySetInnerHTML={{
											__html: rowData.status.icon
										}}
									></span>
								</ToolTip>
							) : null}
						</div>
					);
				}
			},
			{
				title: "#",
				field: "#",
				sorting: false,
				headerStyle: {
					textAlign: "right"
				},
				cellStyle: {
					textAlign: "right"
				},
				render: rowData => {
					return (
						<div className="table-cell-th">
							{rowData.requestId && rowData.requestId === "new"
								? ""
								: rowData.requestId}
						</div>
					);
				}
			},
			{
				title: "Title",
				field: "title",
				sorting: false,
				render: rowData => {
					return (
						<div
							className={`table-cell-th ${
								rowData.priority &&
								rowData.priority.alias === "urgent"
									? "urgent"
									: ""
							}`}
						>
							{rowData.title}
						</div>
					);
				}
			},
			{
				title: "Type",
				field: "typeId",
				sorting: false,
				render: rowData => {
					return (
						id && rowData.id !== 'new' && rowData.type ?
							<div className="table-cell-th">
								<ToolTip text={rowData.type.name}>
									<span className="type-icon"
										  dangerouslySetInnerHTML={{
											  __html: rowData.type.icon
										  }}
									></span>
								</ToolTip>
							</div> :
							<div className="table-cell-th">
								{rowData.type ? rowData.type.name : ""}
							</div>
					);
				}
			},
			{
				title: "Created",
				field: "createdAt",
				sorting: false,
				render: rowData => {
					return (
						<>
							<div className="table-cell-th">
								{rowData.createdAt
									? moment(
											rowData.createdAt,
											"YYYY-MM-DDTHH:mm"
									  ).format("DD.MM.YYYY HH:mm")
									: ""}
							</div>
						</>
					);
				}
			},
			{
				title: "Author",
				field: "author",
				sorting: false,
				render: rowData => {
					return (
						<div className="table-cell-th">
							{rowData.author
								? `${rowData.author.name} ${rowData.author.secondName}`
								: ""}
						</div>
					);
				}
			}
		];
		return id
			? defaultColumn.filter(
					column =>
						column.field === "status" || column.field === '#' || column.field === 'typeId' || column.field === 'title' || column.field === 'createdAt' || column.field === 'author'
			  )
			: defaultColumn;
	};

	removeEmptyRow = () => {
		let data = this.tableRef.current
			? this.tableRef.current.state.data
			: [];
		data = data.filter(request => request.id !== "new");
		this.tableRef.current &&
			this.tableRef.current.dataManager.setData(data);
		this.tableRef.current.setState({
			...this.tableRef.current.dataManager.getRenderState()
		});
	};

	cancelHander = () => {
		changeRoutePush("/atw/service_desk", history);
		this.removeEmptyRow();
	};

	addEmptyRowToTable = () => {
		const data = this.tableRef.current
			? this.tableRef.current.state.data
			: [];
		if (data.find(request => request.id === "new") === undefined) {
			const newRequest = {
				id: "new",
				title: "",
				requestId: "",
				typeId: {},
				// purpose: "",
				shortDescription: "",
				status: { icon: "New request" },
				author: "",
				createdAt: "",
				priority: ""
			};
			this.tableRef.current &&
				this.tableRef.current.dataManager.setData([
					newRequest,
					...data
				]);
			this.tableRef.current.setState({
				...this.tableRef.current.dataManager.getRenderState()
			});
		}
	};

	callbackAfterCreation = (data, isTable = true) => {
		this.tableRef.current && this.tableRef.current.onQueryChange();
		isTable && changeRoutePush(`/atw/service_desk/${data.id}`, history);
	};

	handleChangeStatus = () => {
		const { currentStatus, currentRequest } = this.state;
		const { setStatusAction } = this.props;
		this.setState(
			{
				modalChangeStatus: false
			},
			() => {
				adminsInstance
					.put(
						`${url}/service_desk/${currentRequest}/change_status`,
						convertToFormData({
							reqUrl:
								window.location.origin + "/atw/service_desk",
							newStatusId: currentStatus.id
						})
					)
					.then(res => {
						setStatusAction(res.data);
						this.changeStatusInRow(currentRequest, res);
						notify("success", "Status successfully changed");
					})
					.catch(err => {
						notify("error", "Oops something went wrong");
					});
			}
		);
	};

	changeStatusInRow = (id, resp) => {
		const columnsData = this.tableRef.current.dataManager.data.map(row => {
			if (row.id === id) {
				row = {
					...row,
					status: resp.data
				};
			}
			return row;
		});

		this.tableRef.current.dataManager.setData(columnsData);
		this.tableRef.current.setState(
			this.tableRef.current.dataManager.getRenderState()
		);
	};

	setCurrentRequest = request => {
		this.setState({ currRequest: request });
	};

	componentDidMount() {
		const {
			setHeaderData,
			getStatuses,
			getTypes,
			getPriorities
		} = this.props;
		setHeaderData({
			leftPannelData: {
				title: "Service Desk",
				logo: (
					<svg
						className="action-icon header-icon"
						viewBox="0 0 24 24"
					>
						<path
							fill="#00a9ea"
							d="M18.72,14.76C19.07,13.91 19.26,13 19.26,12C19.26,11.28 19.15,10.59 18.96,9.95C18.31,10.1 17.63,10.18 16.92,10.18C13.86,10.18 11.15,8.67 9.5,6.34C8.61,8.5 6.91,10.26 4.77,11.22C4.73,11.47 4.73,11.74 4.73,12A7.27,7.27 0 0,0 12,19.27C13.05,19.27 14.06,19.04 14.97,18.63C15.54,19.72 15.8,20.26 15.78,20.26C14.14,20.81 12.87,21.08 12,21.08C9.58,21.08 7.27,20.13 5.57,18.42C4.53,17.38 3.76,16.11 3.33,14.73H2V10.18H3.09C3.93,6.04 7.6,2.92 12,2.92C14.4,2.92 16.71,3.87 18.42,5.58C19.69,6.84 20.54,8.45 20.89,10.18H22V14.67H22V14.69L22,14.73H21.94L18.38,18L13.08,17.4V15.73H17.91L18.72,14.76M9.27,11.77C9.57,11.77 9.86,11.89 10.07,12.11C10.28,12.32 10.4,12.61 10.4,12.91C10.4,13.21 10.28,13.5 10.07,13.71C9.86,13.92 9.57,14.04 9.27,14.04C8.64,14.04 8.13,13.54 8.13,12.91C8.13,12.28 8.64,11.77 9.27,11.77M14.72,11.77C15.35,11.77 15.85,12.28 15.85,12.91C15.85,13.54 15.35,14.04 14.72,14.04C14.09,14.04 13.58,13.54 13.58,12.91A1.14,1.14 0 0,1 14.72,11.77Z"
						/>
					</svg>
				)
			},
			textAddButton: "Add",
			LeftPannel: StandartLeftPanner,
			handleAdd: null,
			handleSearch: value => this.tableRef.current.onSearchChange(value)
		});
	}

	componentWillUnmount() {
		this.props.setHeaderData({
			title: null,
			handleAdd: null,
			handleSearch: null
		});
	}
	generateRows = data => {
		return data.map(request => {
			return {
				id: request.id,
				requestId: request.id,
				type: request.type,
				title: request.title,
				// purpose: request.purpose,
				shortDescription: request.shortDescription,
				status: request.status,
				author: request.author,
				createdAt: request.createdAt,
				priority: request.priority
			};
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.activeFilter !== this.props.activeFilter) {
			this.tableRef.current && this.tableRef.current.onQueryChange();
		}
	}

	handleShowRemoveModal = currentRequest =>
		this.setState({ modalRemoveRequest: true, currentRequest });

	handleDeleteRequest = () => {
		const idRequest = this.state.currentRequest.id;
		const { id } = this.props.match.params;
		this.setState(
			{
				modalRemoveRequest: false
			},
			() => {
				adminsInstance
					.delete(`${url}/service_desk/${idRequest}`)
					.then(res => {
						if (id && parseInt(id) === parseInt(idRequest)) {
							changeRoutePush(`/atw/service_desk`, history);
						}
						let query = {
							...this.tableRef.current.state.query,
							page: 0
						};
						this.tableRef.current &&
							this.tableRef.current.onQueryChange(query);
						notify("success", "Request successfully deleted");
					})
					.catch(err => {
						notify("error", "Oops something went wrong");
					});
			}
		);
	};

	getServiceDeskList = query => {
		const { user, activeFilter, filters, roles } = this.props;
		const activeFilterObj = filters.find(item => item.id === activeFilter);
		const isCustomFilter = activeFilterObj && activeFilterObj.deletable;
		const isAdmin = user.role.alias === "admin";
		return new Promise(resolve => {
			adminsInstance
				.get(`${url}/service_desk`, {
					params: {
						page: query.page ? query.page : 0,
						limit: query.pageSize,
						search: query.search,
						andFilter:
							!isAdmin && isCustomFilter
								? generateQuary(roles, user)
								: ""

						// orFilter:
						//   !isAdmin && isCustomFilter
						//     ? `author.id:${user.userDetails.id};status.alias!#~close;`
						//     : ""
					}
				})
				.then(res => {
					const { id } = this.props.match.params;
					const newRequest = {
						id: "new",
						title: "",
						typeId: {},
						requestId: "",
						// purpose: "",
						shortDescription: "",
						// status: { icon: "New request" },
						author: "",
						createdAt: "",
						priority: ""
					};
					resolve({
						data:
							id === "new"
								? this.generateRows([
										newRequest,
										...res.data.requests
								  ])
								: this.generateRows(res.data.requests),
						page: query.page,
						totalCount: res.data.count
					});
				});
		});
	};

	render() {
		const { id } = this.props.match.params;
		const {
			modalChangeStatus,
			currRequest,
			currentRequest,
			currentStatus,
			modalSendToAsana,
			modalRemoveRequest
		} = this.state;
		const { roles, activeFilter, filters, user, sendToAsana } = this.props;
		const activeFilterObj = filters.find(item => item.id === activeFilter);

		const isAuthor =
			currRequest &&
			currRequest.author &&
			currRequest.author.name === user.info.name;
		const isPersonalStatus =
			!findPermInArray(roles, permСhangeStatus) && isAuthor;
		return (
			<>
				<div
					className={`wrap-table-with-details-panel service-desk-table ${
						!id ? "" : "with-details"
					}`}
				>
					<div className="table-block wrapper-table">
						<Table
							components={{
								Header: Header,
								Container: Container,
								Toolbar: Toolbar,
								Pagination: Pagination
							}}
							toolbarData={{
								handleAdd: () => {
									changeRoutePush(
										"/atw/service_desk/new",
										this.props.history
									);
									this.addEmptyRowToTable();
								}
							}}
							tableRef={this.tableRef}
							paginationPosition="toolbar"
							columns={
								activeFilterObj &&
								activeFilterObj.name === "My open requests"
									? this.generateColumns().filter(
											item => item.field !== "author"
									  )
									: this.generateColumns()
							}
							data={this.getServiceDeskList}
							onRowClick={(event, rowData) => {
								event.preventDefault();
								if (rowData.id !== "new") {
									changeRoutePush(
										`/atw/service_desk/${rowData.id}`,
										history
									);
									this.removeEmptyRow();
								}
							}}
							options={{
								maxBodyHeight: "calc(100vh - 130px)",
								search: true,
								// searchFieldStyle: { display: "none" },
								emptyRowsWhenPaging: false,
								pageSize: 50,
								pageSizeOptions: [10, 25, 50],
								rowStyle: props => {
									if (
										parseInt(id) === props.id ||
										id === props.id
									)
										return {
											backgroundColor: "#f2f2f2"
										};
									else {
										return {};
									}
								}
							}}
						/>
					</div>
					<Route
						exact
						path="/atw/service_desk/:id"
						render={props => (
							<Details
								{...props}
								cancelCreate={this.cancelHander}
								callbackAfterCreation={
									this.callbackAfterCreation
								}
								refetchData={() =>
									this.tableRef.current.onQueryChange()
								}
								showConfirm={(status, currRequest) => {
									this.setState({
										currentStatus: status,
										currentRequest: currRequest,
										modalChangeStatus: true
									});
								}}
								showConfirmAsana={currRequest =>
									this.setState({
										modalSendToAsana: true,
										currentRequest: currRequest
									})
								}
								setCurrentRequest={this.setCurrentRequest}
								handleDeleteRequest={this.handleShowRemoveModal}
							/>
						)}
					/>
				</div>
				{(findPermInArray(roles, permСhangeStatus) ||
					isPersonalStatus) && (
					<CustomModalWithChildren
						open={modalChangeStatus}
						handleCancel={() =>
							this.setState({
								modalChangeStatus: false
							})
						}
						handleClose={() =>
							this.setState({
								modalChangeStatus: false
							})
						}
						handleConfirm={() => this.handleChangeStatus()}
						options={{
							centered: true
						}}
					>
						<ConfirmModalContent
							text={`change status to ${currentStatus.name} for request #${currentRequest}`}
						/>
					</CustomModalWithChildren>
				)}
				<CustomModalWithChildren
					open={modalSendToAsana}
					handleCancel={() =>
						this.setState({
							modalSendToAsana: false
						})
					}
					handleClose={() =>
						this.setState({
							modalSendToAsana: false
						})
					}
					handleConfirm={() => {
						sendToAsana(
							currentRequest,
							this.callbackAfterCreation,
							this.props.match.params.id
						);
						this.setState({ modalSendToAsana: false });
					}}
					options={{
						centered: true
					}}
				>
					<ConfirmModalContent
						text={`send request #${currentRequest.id} to support`}
					/>
				</CustomModalWithChildren>

				<CustomModalWithChildren
					open={modalRemoveRequest}
					handleCancel={() =>
						this.setState({
							modalRemoveRequest: false
						})
					}
					handleClose={() =>
						this.setState({
							modalRemoveRequest: false
						})
					}
					handleConfirm={this.handleDeleteRequest}
					options={{
						centered: true
					}}
				>
					<ConfirmModalContent
						text={`delete request #${currentRequest.id}`}
					/>
				</CustomModalWithChildren>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		roles: state.rolesReducer.roles,
		activeFilter: state.smartFilterReducer.activeFilter,
		filters: state.smartFilterReducer.filters,
		request: state.serviceDeskReducer.request,
		user: state.usersReducer.currUser
	};
};

export default withTracker(
	connect(mapStateToProps, {
		setHeaderData: setData,
		setStatusAction,
		sendToAsana
	})(ServiceDeskModule)
);
