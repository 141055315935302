import { url } from '../constants'
import adminInstance from '../components/instances/admininstance'
import { convertToFormData } from '../reuseFunctions/objectToFormData'

export const getBrands = (params = {}) => {
    return adminInstance.get(`${url}/brands`,{
        params: params
    })
}

export const addBrandInput = (id, value) => {
    const data = convertToFormData(value)
    return adminInstance.put(`${url}/brands/${id}/add_input`, data)
}

export const createBrand = (data = {}) => {
    const formData = convertToFormData(data)
    return adminInstance.post(`${url}/brands`,formData)
}

export const getBrandById = id => {
    return adminInstance.get(`${url}/brands/${id}`)
}

export const editBrand = (id,data) => {
    const formData = convertToFormData(data)
    return adminInstance.put(`${url}/brands/${id}`,formData)
}

export const deleteBrand = id => {
    return adminInstance.delete(`${url}/brands/${id}`)
}