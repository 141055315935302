import React, {PureComponent} from 'react'
import Form from 'react-bootstrap/es/Form';
import ContactBlock from './contact-block';

class FormEdit extends PureComponent {

    render() {
        const {
            values,
        } = this.props
        return (
            <>
                <div className="info view">
                    {values.companyName && <Form.Group className="horizontal">
                        <Form.Label>Company name</Form.Label>
                        <div className="input-item">
                            {values.companyName}
                        </div>
                    </Form.Group>
                    }

                    {values.taxId && <Form.Group className="horizontal">
                        <Form.Label>Tax ID</Form.Label>
                        <div className="input-item">
                            {values.taxId}
                        </div>
                    </Form.Group>
                    }

                    {values.phoneNumber && <Form.Group className="horizontal">
                        <Form.Label>Phone number</Form.Label>
                        <div className="input-item">
                            {values.phoneNumber}
                        </div>
                    </Form.Group>
                    }

                    {values.email && <Form.Group className="horizontal">
                        <Form.Label>Email</Form.Label>
                        <div className="input-item">
                            {values.email}
                        </div>
                    </Form.Group>
                    }

                    {values.address && <Form.Group className="horizontal">
                        <Form.Label>Address</Form.Label>
                        <div className="input-item">
                            {values.address}
                        </div>
                    </Form.Group>}

                    {values.busModel && <Form.Group className="horizontal">
                        <Form.Label>Business model</Form.Label>
                        <div className="input-item">
                            {values.busModel.map(m => m.label).join(', ')}
                        </div>
                    </Form.Group>
                    }

                    {values.demandCategory && <Form.Group className="horizontal">
                        <Form.Label>Demanded categories</Form.Label>
                        <div className="input-item">
                            {values.demandCategory.map(c => c.label).join(', ')}
                        </div>
                    </Form.Group>
                    }

                    {values.qualityIds && <Form.Group className="horizontal">
                        <Form.Label>Quality interest</Form.Label>
                        <div className="input-item">
                            {values.qualityIds.map(q => q.label).join(', ')}
                        </div>
                    </Form.Group>
                    }

                    {values.spokenLanguage && <Form.Group className="horizontal">
                        <Form.Label>Spoken language</Form.Label>
                        <div className="input-item">
                            {values.spokenLanguage}
                        </div>
                    </Form.Group>
                    }

                    {values.country && <Form.Group className="horizontal">
                        <Form.Label>Country</Form.Label>
                        <div className="input-item">
                            {values.country}
                        </div>
                    </Form.Group>
                    }

                    {values.countriesOperate && <Form.Group className="horizontal">
                        <Form.Label>Business countries</Form.Label>
                        <div className="input-item">
                            {values.countriesOperate}
                        </div>
                    </Form.Group>
                    }

                    {values.city && <Form.Group className="horizontal">
                        <Form.Label>City</Form.Label>
                        <div className="input-item">
                            {values.city}
                        </div>
                    </Form.Group>
                    }

                    {values.zip && <Form.Group className="horizontal">
                        <Form.Label>Zip code</Form.Label>
                        <div className="input-item">
                            {values.zip}
                        </div>
                    </Form.Group>
                    }

                    {values.website && <Form.Group className="horizontal">
                        <Form.Label>Website</Form.Label>
                        <div className="input-item">
                            {values.website}
                        </div>
                    </Form.Group>
                    }

                    {values.notes && <Form.Group className="horizontal">
                        <Form.Label>Note</Form.Label>
                        <div className="input-item">
                            {values.notes}
                        </div>
                    </Form.Group>
                    }
                </div>
                {values.contacts.length > 0 && <div className="contacts-wrapper">

                    <h6 className="contacts-title">
                        <svg viewBox="0 0 24 24">
                            <path
                                fill="currentColor"
                                d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
                            />
                        </svg>
                        Contacts
                    </h6>
                    <div className="contacts view">
                        {values.contacts.map((contact, index) => {
                            return (

                                <ContactBlock
                                    key={index}
                                    isView={true}
                                    index={index}
                                    values={values}
                                />

                            );
                        })}
                    </div>
                </div>}
            </>
        )
    }
}

export default FormEdit