import React from 'react'
import {Button, Form} from 'react-bootstrap'

import Select from '../../../reuseComponent/Select/select-form'

const LocationFormBody = props => {
    const {
        handleSubmit,
        errors,
        touched,
        handleClose,
        roles,
        setFieldValue,
        values
    } = props;

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Form.Group>
                <Form.Label>Note! Deleting role has users, please select role for linked users</Form.Label>
                <Select
                    name="newLocationId"
                    isInvalid={errors.newRoleId && touched.newRoleId}
                    errorLabel={errors.newRoleId}
                    value={roles.find(item => item.value === values.newRoleId)}
                    onChange={status => {
                        setFieldValue('newRoleId', status.value)
                    }}
                    options={roles}
                />
                <Form.Control.Feedback type="invalid">{errors.newRoleId}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="footer-form footer-form-custom">
                <Button variant="secondary"
                        onClick={handleClose}>Cancel</Button>
                <Button variant="primary" type="submit">Save</Button>
            </Form.Group>
        </Form>
    )
}

export default LocationFormBody;