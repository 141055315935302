import React, {PureComponent} from 'react'
import {Form} from 'react-bootstrap'
import {connect} from 'react-redux'
import Select from '../../../../../../reuseComponent/Select/select-form'
import DatePicker from '../../../../../../reuseComponent/DatePicker/datepicker-with-children'
import { formatIntegerWithEmptyString, formatPercentWithEmptyString } from '../../../../../../../reuseFunctions/formatValue'

class BodyForm extends PureComponent {
    render() {
        const {
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            setFieldValue,
            statuses
        } = this.props

        return (
            <div className="form-line-format">
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Credit Period (days)</Form.Label>
                        <div className="form-element">
                            <Form.Control
                                type="text"
                                name="accountingCreditPeriod"
                                onChange={handleChange}
                                onBlur={e => setFieldValue('quantity', formatIntegerWithEmptyString(e.target.value))}
                                value={values.accountingCreditPeriod}
                                isInvalid={touched.accountingCreditPeriod && errors.accountingCreditPeriod}
                            >

                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.accountingCreditPeriod}</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Payment status</Form.Label>
                        <div className="form-element">
                            <Select
                                value={statuses.find(status => status.value === values.financeStatus)}
                                isInvalid={touched.financeStatus && errors.financeStatus}
                                name="financeStatus"
                                options={statuses}
                                onChange={obj => setFieldValue('financeStatus', obj.value)}
                                errorLabel={errors.financeStatus}
                            />
                        </div>
                    </Form.Group>
                    {(values.financeStatus === 'Prepayment' || values.financeStatus === 'Partly paid')
                        ? <Form.Group>
                            <Form.Label>Percentage</Form.Label>
                            <div className="form-element">
                                <Form.Control
                                    type="text"
                                    name="prepaidPercentage"
                                    onChange={handleChange}
                                    onBlur={e => setFieldValue('prepaidPercentage', formatPercentWithEmptyString(e.target.value))}
                                    value={values.prepaidPercentage}
                                    isInvalid={touched.prepaidPercentage && errors.prepaidPercentage}
                                >

                                </Form.Control>
                                <Form.Control.Feedback type="invalid">{errors.prepaidPercentage}</Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        : null
                    }
                    <Form.Group>
                        <Form.Label>Payment date</Form.Label>
                        <div className="form-element">
                            <div className="wrapper-calendar-asana">
                                <DatePicker
                                    onApply={(event, picker) => {
                                        setFieldValue('accountingPaymentDate', picker.startDate.format('DD.MM.YYYY'))
                                    }}
                                    startDate={values.accountingPaymentDate ? values.accountingPaymentDate : null}
                                >
                                    <>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z"/>
                                        </svg>
                                        <Form.Control
                                            readOnly={true}
                                            isInvalid={errors.accountingPaymentDate && touched.accountingPaymentDate}
                                            name="accountingPaymentDate"
                                            value={values.accountingPaymentDate ? values.accountingPaymentDate : 'No value'}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors.accountingPaymentDate}</Form.Control.Feedback>

                                    </>
                                </DatePicker>
                                {values.accountingPaymentDate && <svg onClick={e => {
                                    setFieldValue('accountingPaymentDate', '')
                                    document.body.click()
                                }} className="circle-close" viewBox="0 0 24 24">
                                    <path fill="#000000"
                                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/>
                                </svg>
                                }
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
    }
}

export default connect(mapStateToProps, null)(BodyForm)