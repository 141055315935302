import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Button, Form, Row, Col} from 'react-bootstrap'
import Select from '../../../reuseComponent/Select/select-form'
import AsyncSelect from '../../../reuseComponent/Select/async-select'
import { getBrands } from '../../../../services/brands'
import { getQualities } from '../../../../actions/detailsPo'

class BodyForm extends PureComponent {

    componentDidMount(){
        const { getQualities } = this.props
        getQualities && getQualities()

    }

    getBrands = (inputValue,callback) => {
        getBrands({
            limit: 20,
            page: 0,
            search: inputValue
        })
            .then(res => {
                callback(res.data.brands.map(item => ({
                    value: item.name,
                    label: item.name
                })))
            })
    }

    render() {
        const {
            handleChange,
            values,
            handleSubmit,
            touched,
            errors,
            setFieldValue,
            simpleParameters,
            handleClose,
            getRelatedParams,
            valuesOptions
        } = this.props
        return (
            <Form noValidate onSubmit={handleSubmit}>
                <Row>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>EAN</Form.Label>
                            <Form.Control
                                name="ean"
                                value={values.ean}
                                onChange={handleChange}
                                isInvalid={errors.ean && touched.ean}
                            />
                            <Form.Control.Feedback type="invalid">{errors.ean}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>SKU</Form.Label>
                            <Form.Control
                                name="styleNumber"
                                value={values.styleNumber}
                                onChange={handleChange}
                                isInvalid={errors.styleNumber && touched.styleNumber}
                            />
                            <Form.Control.Feedback type="invalid">{errors.styleNumber}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG1</Form.Label>
                            <Select
                                name="cg1"
                                value={simpleParameters.cg1.find(item => item.value === values.cg1)}
                                options={simpleParameters.cg1}
                                onChange={obj => {
                                    getRelatedParams(obj.id,simpleParameters.cg2.find(item => item.value === values.cg2)
                                        ? simpleParameters.cg2.find(item => item.value === values.cg2).id
                                        : null)
                                    setFieldValue('cg1', obj.value)
                                    setFieldValue('cg3', '')
                                }}
                                isInvalid={errors.cg1 && touched.cg1}
                                errorLabel={errors.cg1}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG2</Form.Label>
                            <Select
                                name="cg2"
                                options={simpleParameters.cg2}
                                value={simpleParameters.cg2.find(item => item.value === values.cg2)}
                                onChange={obj => {
                                    getRelatedParams(simpleParameters.cg1.find(item => item.value === values.cg1) ?
                                        simpleParameters.cg1.find(item => item.value === values.cg1).id : null,obj.id)
                                    setFieldValue('cg3', '')
                                    setFieldValue('cg2', obj.value)
                                }}
                                isInvalid={errors.cg2 && touched.cg2}
                                errorLabel={errors.cg2}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG3</Form.Label>
                            <Select
                                isDisabled={!values.cg1 || !values.cg2}
                                name="cg3"
                                value={simpleParameters.cg3.find(item => item.value === values.cg3) ? simpleParameters.cg3.find(item => item.value === values.cg3) : null}
                                options={simpleParameters.cg3}
                                onChange={obj => setFieldValue('cg3', obj.value)}
                                isInvalid={errors.cg3 && touched.cg3}
                                errorLabel={errors.cg3}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG4</Form.Label>
                            <Form.Control
                                name="cg4"
                                value={values.cg4}
                                onChange={handleChange}
                                isInvalid={errors.cg4 && touched.cg4}
                            />
                            <Form.Control.Feedback type="invalid">{errors.cg4}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Size</Form.Label>
                            <Form.Control
                                name="size"
                                value={values.size}
                                onChange={handleChange}
                                isInvalid={errors.size && touched.size}
                            />
                            <Form.Control.Feedback type="invalid">{errors.size}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Size Type</Form.Label>
                            <Form.Control
                                name="sizeType"
                                value={values.sizeType}
                                onChange={handleChange}
                                isInvalid={errors.sizeType && touched.sizeType}
                            />
                            <Form.Control.Feedback type="invalid">{errors.sizeType}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Channel</Form.Label>
                            <Select
                                name="channel"
                                value={simpleParameters.channels.find(item => item.value === values.channel) ? simpleParameters.channels.find(item => item.value === values.channel) : null}
                                options={simpleParameters.channels}
                                onChange={obj => setFieldValue('channel', obj.value)}
                                isInvalid={errors.channel && touched.channel}
                                errorLabel={errors.channel}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Season</Form.Label>
                            <Form.Control
                                name="season"
                                value={values.season}
                                onChange={handleChange}
                                isInvalid={errors.season && touched.season}
                            />
                            <Form.Control.Feedback type="invalid">{errors.season}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Brand</Form.Label>
                            <AsyncSelect
                                name="brand"
                                loadOptions={this.getBrands}
                                defaultOptions={true}
                                isInvalid={errors.brand && touched.brand}
                                errorLabel={errors.brand}
                                onChange={obj => setFieldValue('brand', obj.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>RRP</Form.Label>
                            <Form.Control
                                name="rrp"
                                value={values.rrp}
                                onChange={handleChange}
                                isInvalid={errors.rrp && touched.rrp}
                            />
                            <Form.Control.Feedback type="invalid">{errors.rrp}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Quality</Form.Label>
                            <Select
                                name="quality"
                                value={values.quality ? {
                                    label: values.quality,
                                    value: values.quality
                                } : null}
                                onChange={obj => setFieldValue('quality', obj.value)}
                                options={valuesOptions.qualities}
                                isInvalid={errors.quality && touched.quality}
                            />
                            <Form.Control.Feedback type="invalid">{errors.quality}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Items</Form.Label>
                            <Form.Control
                                name="incomeStock"
                                value={values.incomeStock}
                                onChange={handleChange}
                                isInvalid={errors.incomeStock && touched.incomeStock}
                            />
                            <Form.Control.Feedback type="invalid">{errors.incomeStock}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="footer-form footer-form-custom">
                    <Button variant="secondary"
                            onClick={handleClose}>Cancel</Button>
                    <Button variant="primary" type="submit">Save</Button>
                </Form.Group>
            </Form>
        )
    }
}

const mapStateToProps = state => {
    return {
        valuesOptions: state.poDetailsReducer.valuesOptions,
    }
}

export default connect(mapStateToProps, {
    getQualities
})(BodyForm)