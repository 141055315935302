import React ,{ PureComponent } from 'react'
import { connect } from 'react-redux'
import {Button} from 'react-bootstrap'
import FiltersList from '../../../reuseComponent/smart-filter/filters-list'
import FilterModal from '../../../reuseComponent/smart-filter/modalFilter'
import FormFilter from '../../../reuseComponent/smart-filter/form'
import EditFilter from '../../../reuseComponent/smart-filter/editFilter'
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent'
import {createOfferFilter, deleteFilterById, editFilterById, setActiveFilter} from '../../../../services/smart-filter'
import {notify} from '../../../reuseComponent/toast'
import {getFilters} from '../../../../actions/smart-filter'
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import {permViewReopen, permViewAll, permViewDepartment} from "../../../../permissions/serviceDesk";

const aliasServiceDesk = 'sdRequest'

class ToolbarServiceDesk extends PureComponent{

    state = {
        openCreateModal: false,
        openEditModal: false,
        openDeleteModal: false,
        removedTitle: '',
        currFilter: null,
        isLoadingFilterModal: false 
    }

    openCreateModal = () => this.setState({openCreateModal: true})

    hideCreateModal = () => this.setState({openCreateModal: false, isLoadingFilterModal: false})

    openEditModal = (idFilter) => this.setState({openEditModal: true, currFilter: idFilter})

    hideEditModal = () => this.setState({openEditModal: false, currFilter: null, isLoadingFilterModal: false})

    openDeleteModal = (idFilter, removedTitle) => this.setState({openDeleteModal: true, currFilter: idFilter, removedTitle})

    hideDeleteModal = () => this.setState({openDeleteModal: false, currFilter: null})

    setLoading = (isLoading, callback) => this.setState({isLoadingFilterModal: isLoading}, () => callback && callback())

    handleCreateFilter = values => {
        const promise = createOfferFilter(values,aliasServiceDesk)
        this.setLoading(true, () => {
            promise
                .then(res => {
                    this.updateViewAndFilter()
                    this.hideCreateModal()
                    notify('success', 'Filter successfully created')
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })

    }

    handleActivateFilter = (id) => {
        const promise = setActiveFilter(id,aliasServiceDesk)
        promise
            .then(this.updateViewAndFilter)
            .catch(err => notify('error', 'Oops something went wrong'))
    }

    updateViewAndFilter = () => {
        const {onSearchChanged, getFilters} = this.props
        onSearchChanged && onSearchChanged('')
        getFilters && getFilters(aliasServiceDesk)
    }

    handleEditFilter = values => {
        const {currFilter} = this.state
        this.setLoading(true, () => {
            editFilterById(currFilter, values)
                .then(res => {
                    notify('success', 'Filter successfully edited')
                    this.hideEditModal()
                    this.updateViewAndFilter()
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                    this.hideEditModal()
                })
        })

    }

    handleDeleteFilter = () => {
        const {currFilter} = this.state
        const {filters, activeFilterId, userInfo} = this.props
        const defaultFilterByNoAdmin = filters.find(
            filter => filter.name === "My open tickets"
          );
        this.hideDeleteModal()
        deleteFilterById(currFilter)
            .then(res => {
                if (userInfo.role.Name !== 'Admin' && currFilter === activeFilterId) {
                    this.handleActivateFilter(defaultFilterByNoAdmin.id)
                }
                this.updateViewAndFilter()
                notify('success', 'Filter successfully deleted')
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }

    componentDidMount() {
        const {getFilters} = this.props
        getFilters && getFilters(aliasServiceDesk)
    }

    render(){
        const {
            pagination,
            paginationPosition,
            handleAdd
        } = this.props
        const {openCreateModal, openEditModal, openDeleteModal, currFilter, isLoadingFilterModal, removedTitle} = this.state
        return (
            <div className="toolbar-service-desk custom-toolbar">
                <div className="actions-service-desk-toolbar actions-toolbar">
                    {handleAdd && <Button onClick={handleAdd} className="add-button-service-desk add-button">
                        <svg viewBox="0 0 24 24">
                            <path fill="#00a9ea" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                        </svg>
                        Add ticket
                    </Button>
                    }
                    <FiltersList
                        isServiceDesk
                        aliasesToCheck={[permViewAll, permViewReopen, permViewDepartment]}
                        isHideNoFilter
                        setActiveFilter={this.handleActivateFilter}
                        openCreateModal={this.openCreateModal}
                        openEditModal={this.openEditModal}
                        openDeleteModal={this.openDeleteModal}
                    />
                </div>
                {paginationPosition === 'toolbar'
                &&
                <div className="pagination-toolbar">
                    {pagination}
                </div>
                }
                <FilterModal
                    open={openCreateModal}
                    handleClose={this.hideCreateModal}

                >
                    <FormFilter
                        isServiceDesk
                        isLoading={isLoadingFilterModal}
                        handleSubmit={this.handleCreateFilter}
                        alias={aliasServiceDesk}
                        handleCancel={this.hideCreateModal}
                        initValues={{
                            name: '',
                            criterias: [],
                            orCriterias: []
                        }}
                    />
                </FilterModal>
                <FilterModal
                    open={openEditModal}
                    handleClose={this.hideEditModal}
                >
                    <EditFilter
                        alias={aliasServiceDesk}
                        idFilter={currFilter}
                        isLoading={isLoadingFilterModal}
                        handleCancel={this.hideEditModal}
                        handleSubmit={this.handleEditFilter}
                    />
                </FilterModal>
                <CustomModalWithChildren
                    open={openDeleteModal}
                    handleConfirm={this.handleDeleteFilter}
                    handleClose={this.hideDeleteModal}
                    handleCancel={this.hideDeleteModal}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`delete "${removedTitle}" filter`}
                    />
                </CustomModalWithChildren>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    filters: state.smartFilterReducer.filters,
    activeFilterId: state.smartFilterReducer.activeFilter,
    userInfo: state.usersReducer.currUser
})

export default connect(mapStateToProps,{
    getFilters
})(ToolbarServiceDesk)
