import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import {schema} from './schema';
import BodyForm from './bodyFormEdit';
import {
    editCustomer, getCountries,
    getCurrentCustomer
} from '../../../../actions/customers';
import Preloader from '../../../reuseComponent/preloader/preloader';
import {withRouter} from 'react-router-dom';
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import {permViewAllCustomers} from '../../../../permissions/customers';
import {getLanguagesList} from '../../../../actions/users';

class FormCustomersEdit extends PureComponent {

    state = {
        isEditForm: false,
        isEditContacts: false
    }

    handleSubmit = values => {
        const {editCustomer,getCurrentCustomer} = this.props;
        editCustomer(values, () => {
            getCurrentCustomer(this.props.match.params.id)
        });
    };

    componentDidMount() {
        const {
            getCurrentCustomer,
            getLanguagesList,
            getCountriesList,
            acceptCustomer,
            rejectCustomer,
        } = this.props;
        getCurrentCustomer(this.props.match.params.id, this.props.match.params.type, (type) => {
            if (type === 'allocate') {
                acceptCustomer(this.props.match.params.id)
            } else if (type === 'reject') {
                rejectCustomer(this.props.match.params.id, '');
            }
        });
        getLanguagesList && getLanguagesList();
        getCountriesList && getCountriesList()
    }

    componentDidUpdate(prevProps) {
        const {getCurrentCustomer} = this.props;

        if (prevProps.match.params.id !== this.props.match.params.id) {
            getCurrentCustomer(this.props.match.params.id);
        }
    }

    render() {
        const {
            cancelCreate, customer, isLoading, roles, currUser, handleShowRemoveModal, handleShowAllocateModal,
            handleShowRejectModal, handleShowDeactivateModal, changeRow, handleAllocate
        } = this.props;
        return !isLoading ? (
            <>
                <Formik
                    onSubmit={this.handleSubmit}
                    enableReinitialize={true}
                    initialValues={{
                        ...customer,
                        website: customer.website ? customer.website : '',
                        notes: customer.notes ? customer.notes : '',
                        countriesOperate: customer.countriesOperate ? customer.countriesOperate : '',
                        isCreate: false
                    }}
                    validationSchema={schema}
                    render={props => (
                        <BodyForm {...props}
                                  cancelCreate={cancelCreate}
                                  isView={!(
                                      findPermInArray(roles, permViewAllCustomers) ||
                                      (customer && customer.user && customer.user.username === currUser.username))}
                                  handleShowRemoveModal={handleShowRemoveModal}
                                  handleShowAllocateModal={handleShowAllocateModal}
                                  handleShowRejectModal={handleShowRejectModal}
                                  handleShowDeactivateModal={handleShowDeactivateModal}
                                  changeRow={changeRow}
                                  handleAllocate={handleAllocate}
                        />
                    )}
                />

            </>
        ) : (
            <Preloader/>
        );
    }
}

const mapStateToProps = state => ({
    customer: state.customersReducer.customer,
    isLoading: state.customersReducer.isLoadingCustomer || state.customersReducer.isLoadingCountries
        || state.usersReducer.loadingLanguagesList,
    currUser: state.usersReducer.currUser.userDetails,
    roles: state.rolesReducer.roles,
});

const mapDispatchToProps = dispatch => ({
    getCurrentCustomer: (id, type, callback) => dispatch(getCurrentCustomer(id, type, callback)),
    getLanguagesList: () => dispatch(getLanguagesList()),
    getCountriesList: () => dispatch(getCountries()),
    editCustomer: (values, callback) =>
        dispatch(editCustomer(values, callback))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FormCustomersEdit)
);
