import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { schema } from "./schema";
import BodyForm from "./bodyForm";
import {
  getCurrentRequest,
  clearRequest,
  editRequest
} from "../../../../actions/serviceDesk";
import Preloader from "../../../reuseComponent/preloader/preloader";
import { withRouter } from "react-router-dom";

class FormServiceDesk extends PureComponent {
  state = {
    initValues: null
  }
  handleSubmit = values => {
    const { callbackAfterCreation, request, user, editRequest } = this.props;
    const isAdmin = user.role.alias === "admin";
    const isAuthor = request.author && request.author.name === user.info.name;

    if (isAdmin || isAuthor) {
      editRequest(values, callbackAfterCreation, (initValues) => {
        this.setState({
          initValues
        })
      });
    }
  };

  componentDidMount() {
    const { clearRequest, getCurrentRequest } = this.props;
    if (this.props.match.params.id !== "new") {
      getCurrentRequest(this.props.match.params.id);
    } else {
      clearRequest();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getCurrentRequest,
      clearRequest,
      request,
      setCurrentRequest
    } = this.props;
    if (prevProps.match.params.id !== this.props.match.params.id) {
      clearRequest();
      getCurrentRequest(this.props.match.params.id);
    }
    if (request !== prevProps.request) {
      setCurrentRequest(request);
      this.setState({
        initValues: this.props.request
      })
    }
  }

  render() {
    const {
      cancelCreate,
      request,
      isLoading,
      showConfirm,
      user,
      showConfirmAsana,
      handleDeleteRequest
    } = this.props;
    const isDraftStatus =
      request && request.status && request.status.name === "Draft";
    const userWithDraft =
      request &&
      request.author &&
      request.author.name === user.info.name &&
      isDraftStatus;
    return !isLoading && this.state.initValues ? (
      <Formik
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
        initialValues={this.state.initValues}
        validationSchema={schema}
        render={props => (
          <BodyForm
            {...props}
            cancelCreate={cancelCreate}
            isView={userWithDraft}
            showConfirm={showConfirm}
            showConfirmAsana={showConfirmAsana}
            handleDeleteRequest={handleDeleteRequest}
          />
        )}
      />
    ) : (
      <Preloader />
    );
  }
}

const mapStateToProps = state => ({
  request: state.serviceDeskReducer.request,
  isLoading: state.serviceDeskReducer.isLoading,
  user: state.usersReducer.currUser,
  // statuses: state.serviceDeskReducer.statuses,
  // priorities: state.serviceDeskReducer.priorities
});

const mapDispatchToProps = dispatch => ({
  getCurrentRequest: id => dispatch(getCurrentRequest(id)),
  clearRequest: () => dispatch(clearRequest()),
  editRequest: (value, callback, errorCallback) => dispatch(editRequest(value, callback, errorCallback))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormServiceDesk)
);
