import { SMART_FILTER } from '../constants'

const initState = {
    filters: [],
    activeFilter: null
}

export const smartFilterReducer = (state = initState,action) => {
    const { type,payload } = action
    switch(type){
        case SMART_FILTER.SET_FILTERS: {
            return {
                ...state,
                filters: payload
            }
        }
        case SMART_FILTER.SET_ACTIVE_FILTER: {
            return {
                ...state,
                activeFilter: payload
            }
        }
        default: {
            return state
        }
    }
}