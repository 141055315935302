import React from 'react'
import Modal from '../../../../../reuseComponent/modal'

const ModalFilter = props => {
    const {
        open,
        handleClose,
        Content
    } = props
    return (
        <Modal
            classNameModal="po-filter-modal"
            Content={() => <Content />}
            open={open}
            isFooter={false}
            handleClose={handleClose}
            options={{
                enforceFocus: false,
                centered: true,
                animation: false
            }}
        />
    )
}

export default ModalFilter