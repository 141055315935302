import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { schema } from "./schema";
import BodyForm from "./bodyForm";
import { createSDRequest, clearRequest } from "../../../../actions/serviceDesk";
import Preloader from "../../../reuseComponent/preloader/preloader";
import { withRouter } from "react-router-dom";
import isEqual from "react-fast-compare";

class CreateForm extends PureComponent {
  state = {
    initValues: null
  }

  handleSubmit = values => {
    const { createSDRequest, callbackAfterCreation } = this.props;
    createSDRequest(values, callbackAfterCreation, (initValues) => {
      this.setState({
        initValues
      })
    });
  };

  componentDidMount() {
    const { clearRequest } = this.props;
    clearRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    if(!isEqual(prevProps.request, this.props.request)){
      this.setState({
        initValues: this.props.request
      })
    }
  }

  render() {
    const {
      cancelCreate,
      request,
      isLoading,
      showConfirm,
      handleDeleteRequest
    } = this.props;
    
    return !isLoading && this.state.initValues ? (
      <Formik
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
        initialValues={this.state.initValues}
        validationSchema={schema}
        render={props => (
          <BodyForm
            {...props}
            cancelCreate={cancelCreate}
            isView={true}
            showConfirm={showConfirm}
            handleDeleteRequest={handleDeleteRequest}
          />
        )}
      />
    ) : (
      <Preloader />
    );
  }
}

const mapStateToProps = state => ({
  request: state.serviceDeskReducer.request,
  isLoading: state.serviceDeskReducer.isLoading,
  user: state.usersReducer.currUser,
  // statuses: state.serviceDeskReducer.statuses,
  // priorities: state.serviceDeskReducer.priorities
});

const mapDispatchToProps = dispatch => ({
  createSDRequest: (value, callback, errCallback) =>
    dispatch(createSDRequest(value, callback, errCallback)),
  clearRequest: () => dispatch(clearRequest())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateForm)
);
