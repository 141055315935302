import React, { PureComponent } from "react";
import moment from "moment";
import CommentEditor from "../comment-editor/index";

class CommentComponent extends PureComponent {
  state = {
    isEdit: false
  };

  handleChangeMode = () => {
    this.setState({
      isEdit: !this.state.isEdit
    });
  };

  render() {
    const { comment, isShowDate } = this.props;
    const { isEdit } = this.state;
    return !isEdit ? (
      <div className="history-item comment service-desk-item">
        {isShowDate && (
          <div className="date-history-action">
            {moment(comment.createdAt).format("MMM DD, YYYY")}
          </div>
        )}
        {/* <div className="date-history-action">
          <svg className="absolute-type-action-icon" viewBox="0 0 24 24">
            <path
              fill="#000000"
              d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M17,11H15V9H17V11M13,11H11V9H13V11M9,11H7V9H9V11Z"
            />
          </svg>
          <span className="date">
            {moment(comment.createdAt).format("MMM DD, YYYY")}
          </span>
          <span className="time">
            {moment(comment.createdAt, "YYYY-MM-DDTHH:mm:ss").format("HH:mm")}
          </span>
        </div> */}
        <div className="row-action">
          <span className="user-name-action">
            {comment.user ? `${comment.user.name} ${comment.user.secondName}` : 'WCS System'}
          </span>
          <div
            className="comment-content"
            dangerouslySetInnerHTML={{ __html: comment.text }}
          />
          <span className="time-action">
            {moment(comment.createdAt, "YYYY-MM-DDTHH:mm:ss").format("HH:mm")}
          </span>
        </div>
      </div>
    ) : (
      <CommentEditor
        handleCancel={this.handleChangeMode}
        isEditing={true}
        commentId={comment.id}
        value={comment.text}
      />
    );
  }
}

export default CommentComponent;
