import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Card, Accordion } from "react-bootstrap";
import TableProducts from "./table-products";
import Tooltip from "../../../../../../reuseComponent/Tooltips";
import RenameInput from '../../renameInput'

class PackageItem extends PureComponent {

  render() {
    const { pack, activeKey, handleChange } = this.props;

    return (
      <Card>
        <Accordion.Toggle
          onClick={() => {
            if (activeKey === pack.id) {
              handleChange(null);
            } else {
              handleChange(pack.id);
            }
          }}
          as={Card.Header}
          eventKey={pack.id}
        >
          <div className="header-collapse-package">
            <div className="left-part">
              <svg
                className={activeKey === pack.id ? "open" : ""}
                viewBox="0 0 24 24"
              >
                <path
                  fill="#000000"
                  d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                />
              </svg>
              <RenameInput setObject={this.props.setObject} pack={this.props.pack}/>
            </div>
            <div className="right-part">
              <Tooltip text="Total items">
                <svg className='item-icon' width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M20.5,11H19V7C19,5.89 18.1,5 17,5H13V3.5A2.5,2.5 0 0,0 10.5,1A2.5,2.5 0 0,0 8,3.5V5H4A2,2 0 0,0 2,7V10.8H3.5C5,10.8 6.2,12 6.2,13.5C6.2,15 5,16.2 3.5,16.2H2V20A2,2 0 0,0 4,22H7.8V20.5C7.8,19 9,17.8 10.5,17.8C12,17.8 13.2,19 13.2,20.5V22H17A2,2 0 0,0 19,20V16H20.5A2.5,2.5 0 0,0 23,13.5A2.5,2.5 0 0,0 20.5,11Z"></path></svg>
              </Tooltip>
              {pack.totalCount}
              {/*<span style={{ marginLeft: 5, marginRight: 5 }}>*/}
                {/*<Tooltip text="Boxes">*/}
                  {/*<span role="img" aria-label="package">*/}
                    {/*📦*/}
                  {/*</span>*/}
                {/*</Tooltip>*/}
                {/*{pack.amountOfBoxes}*/}
              {/*</span>*/}
              <Tooltip text="Total RRP">
                <span className="price">€ {pack.packageRRP.toFixed(2)}</span>
              </Tooltip>
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={pack.id}>
          <Card.Body>
            <TableProducts products={pack.packageProductSet} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export default connect(null, null)(PackageItem);
