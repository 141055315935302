import React, { PureComponent } from 'react'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'

class DateRangePickerCustom extends PureComponent{
    static defaultProps = {
        options: {},
        onApply: () => {},
        filters: []
    }

    render(){
        let { startDate,endDate } = this.props
        if(startDate){
            startDate = moment(startDate,'DD.MM.YYYY')
        }
        else {
            startDate = moment()
        }
        if(endDate){
            endDate = moment(endDate,'DD.MM.YYYY')
        }
        else{
           endDate = moment()
        }
        const   { options,onApply } = this.props
        return(
            <DateRangePicker
                startDate={startDate}
                endDate = {endDate}
                maxDate={endDate}
                {...options}
                onApply={onApply}
            >
                {this.props.children}
            </DateRangePicker>
        )
    }
}

export default DateRangePickerCustom