import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Formik} from 'formik'
import {getUsers} from '../../../../actions/users'
import {schema} from './schema'
import BodyForm from './bodyForm'
import Preloader from '../../../reuseComponent/preloader/preloader'
import {getSupllier, editSupllier} from '../../../../actions/suppliers'
import {withRouter} from 'react-router-dom'
import HistoryList from '../../../reuseComponent/history/list';
import {supplierChronologyFiledsConverting} from '../../../../constants';
import {getHistory} from '../../../../services/supplier';

class FormSupplier extends PureComponent {

    handleSubmit = values => {
        const {id} = this.props.match.params
        const {editSupllier,callbackAfterEdit} = this.props
        editSupllier && editSupllier(id, values,callbackAfterEdit)
    }

    componentDidUpdate(prevProps){
        const { getSupplier } = this.props
        if(prevProps.match.params.id !== this.props.match.params.id)
            getSupplier(this.props.match.params.id)
    }

    componentDidMount() {
        const {id} = this.props.match.params
        const {getUsers, getSupplier, users} = this.props
        getUsers && users.length === 0 && getUsers()
        getSupplier && getSupplier(id)
    }

    render() {
        const {loadingUser, users, loadingSupplier, supplier,isView,cancelCreate, handleShowRemoveModal} = this.props
        
        return (
            !loadingUser && !loadingSupplier ?
                <>
                    <Formik
                        enableReinitialize={true}
                        onSubmit={this.handleSubmit}
                        initialValues={supplier}
                        validationSchema={schema}
                        render={props => <BodyForm
                            {...props}
                            users={users}
                            isView={isView}
                            cancelHandler={cancelCreate}
                            handleShowRemoveModal={handleShowRemoveModal}
                        />}
                    />
                    <HistoryList 
                        id={this.props.match.params.id} 
                        getHistory={getHistory}
                        chronologyFiledsConverting={supplierChronologyFiledsConverting}/>
                </>
                : <Preloader/>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.usersReducer.users,
        loadingUser: state.usersReducer.loading,
        loadingSupplier: state.supplierReducer.loading,
        supplier: state.supplierReducer.currentSupplier
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(getUsers()),
        getSupplier: (id) => dispatch(getSupllier(id)),
        editSupllier: (id, values,callback) => dispatch(editSupllier(id, values,callback))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormSupplier))