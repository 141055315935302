import React from 'react'
import {Button, Form} from 'react-bootstrap'
import Select from '../../../../../reuseComponent/Select/select-form'

const BodyForm = props => {
    const {
        handleSubmit,
        values,
        touched,
        errors,
        handleCancel,
        setFieldValue,
        paramsList,
        getDictId
    } = props   
    const paramsListOptions = paramsList.map(item => ({
        value: item.id,
        label: item.value
    }))
    const selectedParam = paramsListOptions.find(item => item.value === values.value.value)
    return (
        <Form noValidate onSubmit={handleSubmit}>
            <Form.Group>
                <Form.Label>Output</Form.Label>
                <Select
                    name="value"
                    value={selectedParam ? selectedParam : ''}
                    options={paramsListOptions}
                    onChange={obj => {
                        setFieldValue('value', obj)
                        getDictId(obj.value)
                    }}
                    errorLabel={errors.value}
                    isInvalid={errors.value && touched.value}
                />
            </Form.Group>
            <Form.Group className="footer-form footer-form-custom">
                <Button variant="secondary"
                        onClick={handleCancel}>Cancel</Button>
                <Button variant="primary" type="submit">Save</Button>
            </Form.Group>
        </Form>
    )
}

export default BodyForm