import React from 'react'
import {Switch} from 'react-router-dom'
import ProtectedRoute from '../../reuseComponent/Routes/protected-route';
import {
    permEditRoles,
    permEditUsers,
    permViewHistory, permViewNotifications,
    permViewRoles,
    permViewUsers,
    permViewDepartmentsDictionary,
    permEditDepartmentsDictionary,
} from '../../../permissions/settings'
import UsersModule from '../users'
import ActivityModule from '../activity'
import RolesModule from '../roles'
import NotificationModule from '../notifications'
// import AppActivity from '../activityApp'
import Departments from '../departments'


const Settings = (props) => {
    return (
        <>
            <Switch>
                <ProtectedRoute
                    permission={permViewNotifications}
                    path="/atw/settings/notifications"
                    component={NotificationModule}
                />
                <ProtectedRoute
                        permission={[permViewDepartmentsDictionary, permEditDepartmentsDictionary]}
                        path="/atw/settings/departments"
                        component={Departments}
                    />
                <ProtectedRoute permission={[permViewUsers, permEditUsers]} path="/atw/settings/users"
                                component={UsersModule}/>
                <ProtectedRoute exact permission={permViewHistory} path="/atw/settings/activity"
                                component={ActivityModule}/>
                <ProtectedRoute exact permission={[permViewRoles, permEditRoles]} path="/atw/settings/roles"
                                component={RolesModule}/>
                {/*<Route exact path="/atw/settings/app-activity" component={AppActivity}/>*/}
            </Switch>
        </>
    )
}

export default Settings