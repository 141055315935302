import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import BodyForm from "./bodyForm";
import { schema, schemaCustomAmountPackage } from './schema'
import {
  fetchEditCustomPackage,
  fetchEditCustomPackageWithAmountOfPacks, getBoxByPackage,
} from '../../../../../../actions/inboundingReport'
import { notify } from "../../../../../reuseComponent/toast";
import CustomModalWithChildren from '../../../../../reuseComponent/modal/modalWithChildren'

class Form extends PureComponent {
  state = {
    buttonPressed: false,
    formValues: null,
    openModalConfirm: false,
  }

  initValues = {
    id: this.props.pack.id,
    title: this.props.pack.fullName ? this.props.pack.fullName : "",
    description: this.props.pack.description ? this.props.pack.description : "",
    countPack: this.props.pack.closedBox
  };

  updatePackageWithAmountOfPacks = (packId, values) => {
    const {
      fetchEditCustomPackageWithAmountOfPacks,
      handleCancel,
      getBoxesByPackage,
      activePackage,
      pack,
    } = this.props

    fetchEditCustomPackageWithAmountOfPacks(packId, values, () => {
      handleCancel()
      if(values.countPack && activePackage && pack.id === activePackage.id){
        getBoxesByPackage(pack.id)
      }
      this.handleCloseConfirmModal()
    })
  }

  handleSubmit = values => {
    this.setState({
      buttonPressed: true
    }, () => {
      const {
        fetchEditCustomPackage,
        handleCancel,
        pack,
        packages,
        checkExistTitle
      } = this.props;
      if (this.initValues.title === values.title || !checkExistTitle(packages, values.title)) {
        if(pack.total){
          if(values.countPack === pack.closedBox) {
            this.updatePackageWithAmountOfPacks(pack.id, { ...values, countPack: null })
          } else {
            this.setState({
              formValues: values,
              openModalConfirm: true,
            })
          }
        }else{
          fetchEditCustomPackage(pack.id, values, () => {
            handleCancel()
            this.setState({
              buttonPressed: false
            })
          });
        }
      } else {
        notify("error", "Package name already exists");
        handleCancel()
        this.setState({
          buttonPressed: false
        })
      }
    })
  };

  handleCloseConfirmModal = () => {
    this.setState({
      formValues: null,
      openModalConfirm: false,
      buttonPressed: false,
    })
  }

  render() {
    const { handleCancel, packages, pack } = this.props;
    const { buttonPressed, openModalConfirm, formValues } = this.state;

    return (
      <>
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={{...this.initValues, packages}}
        validationSchema={!!pack.total ? schemaCustomAmountPackage : schema}
        render={props => <BodyForm
          {...props}
          isPacks={!!pack.total}
          buttonPressed={buttonPressed}
          handleCancel={handleCancel}
        />}
      />
       <CustomModalWithChildren
         open={openModalConfirm}
         handleClose={this.handleCloseConfirmModal}
         handleCancel={this.handleCloseConfirmModal}
         handleConfirm={() => this.updatePackageWithAmountOfPacks(pack.id, formValues)}
       >
         <div className="wrap-text">
           <p><span className="warn-text">Note!</span></p>
           <p>Changing packs count lead to clearing all pack's item quantity in package</p>
         </div>
       </CustomModalWithChildren>
      </>
    );
  }
}

const mapStateToProps = state => ({
  packages: state.inboundingReportReducer.packages,
  activePackage: state.inboundingReportReducer.activePackage,
});

export default connect(mapStateToProps, {
  fetchEditCustomPackage,
  fetchEditCustomPackageWithAmountOfPacks,
  getBoxesByPackage: getBoxByPackage,
})(Form);
