import * as yup from 'yup'

export const INVENTORY_TABS = {
  LIST: 'LIST',
  MAP: 'MAP',
  DICTIONARY: 'DICTIONARY',
  ARCHIVE: 'ARCHIVE',
}

export const mapDictionaryInitialValues = {
  name: '',
  weight: 1,
  color: '',
  slot: false,
  coordinates: '',
}

export const colors = [
  '#ffffff',
  '#b3e5fc',
  '#cfd8dcff',
  '#d7ccc8',
  '#9e9e9e',
  '#607d8b',
  '#795548',
  '#212121'
]

// export const colors = [
//   '#ac725e',
//   '#d06b64',
//   '#f83a22',
//   '#fa573c',
//   '#ff7537',
//   '#ffad76',
//   '#42d692',
//   '#16a765',
//   '#7bd148',
//   '#b3dc6c',
//   '#fbe983',
//   '#fad165',
//   '#92e1c0',
//   '#9fe1e7',
//   '#9fc6e7',
//   '#4986e7',
//   '#9a9cff',
//   '#b99aff',
//   '#5f6368',
//   '#ffffff',
//   '#f8f9fa',
//   '#f2f2f2',
//   '#B0BEC5',
//   '#cabdbf',
//   '#cca6ac',
//   '#f691b2',
//   '#cd74e6',
//   '#a47ae2',
// ]