import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import {Formik} from 'formik'
import { schema } from './schema'
import BodyForm from './bodyForm'
import { createProduct } from '../../../../../../services/products'
import { notify } from '../../../../../reuseComponent/toast'
import { formatNumber } from '../../../../../../reuseFunctions/formatValue'


class CreateProductComponent extends PureComponent{

    handleSubmit = (values) => {
        const { handleClose,details: { id },putCallback } = this.props
        handleClose()
        const {
          cg4,
          ...sendData
        } = values;
        sendData.rrp = sendData.rrp === '' ? 0 : (isNaN(sendData.rrp) ? (sendData.rrp.includes(",") ?
            parseFloat(formatNumber(values.rrp.replace(',','.'))) : parseFloat(formatNumber(values.rrp))) : sendData.rrp);
        sendData.bid = sendData.bid && sendData.bid.id;
        createProduct(id,sendData)
            .then(res => {
                putCallback && putCallback()
                notify('success','Product successfully created')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }

    render(){
        const { handleClose,packageItem,activeWorkPlace, productDetails, selectedQuality,details } = this.props;
        const ckeckCountsBids = this.props.details.bids.length === 1 ? this.props.details.bids[0] : '';
        return (
            <Formik
              enableReinitialize
                initialValues={productDetails ? {
                    ean: this.props.ean ? this.props.ean : '',
                    packageId: this.props.packageItem ? this.props.packageItem.id : '',
                    incomeStock: 1,
                    warehousing: 1,
                    brand: '',
                    boxId: '',
                    styleNumber: '',
                    size: '',
                    season: '',
                    bid: ckeckCountsBids,
                    cg1: ckeckCountsBids.commodity,
                    cg2: ckeckCountsBids.gender,
                    cg3: '',
                    rrp: '',

                  ...productDetails,
                  quality: selectedQuality ? selectedQuality : (productDetails && productDetails.quality ? productDetails.quality : '')
                } : {
                  ean: this.props.ean ? this.props.ean : '',
                  packageId: this.props.packageItem ? this.props.packageItem.id : '',
                  incomeStock: 1,
                  warehousing: 1,
                  brand: '',
                  boxId: '',
                  styleNumber: '',
                  size: '',
                  season: '',
                  bid: ckeckCountsBids,
                  cg1: ckeckCountsBids.commodity,
                  cg2: ckeckCountsBids.gender,
                  cg3: '',
                  rrp: '',
                  quality: selectedQuality ? selectedQuality : (this.props.packageItem && this.props.packageItem.quality ? this.props.packageItem.quality : '')
                }}
                onSubmit={this.handleSubmit}
                validationSchema={schema}
                render={props => <BodyForm
                    {...props}
                    activeWorkPlace={activeWorkPlace}
                    packageItem={packageItem}
                    handleClose={handleClose}
                />}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        activePackage: state.stowingReducer.activePackage,
    }
}

export default connect(mapStateToProps,null)(CreateProductComponent)