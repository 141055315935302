/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';
import {
    TableHead, TableRow, TableCell,
    withStyles
} from '@material-ui/core';
import {Droppable, Draggable} from 'react-beautiful-dnd'
import HeaderCell from './header-cell'

/* eslint-enable no-unused-vars */

export class MTableHeader extends React.Component {

    renderHeader() {
        const mapArr = this.props.columns.filter(columnDef => !columnDef.hidden && !(columnDef.tableData.groupOrder > -1))
            .sort((a, b) => a.tableData.columnOrder - b.tableData.columnOrder)
            .map((columnDef, index) => {
                let content = (
                    columnDef.title ? <Draggable
                            key={columnDef.tableData.id}
                            draggableId={columnDef.tableData.id.toString()}
                            index={index}>
                            {(provided, snapshot) => {
                                return(
                                    <div
                                        className={`table-cell-th`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {columnDef.title}
                                        {columnDef.filterComponent
                                            ? <columnDef.filterComponent column={columnDef}/>
                                            : null
                                        }

                                    </div>
                                )
                            }}
                        </Draggable>
                        : null
                );
                return (
                    <HeaderCell
                        changeHeaderStyle={this.props.changeHeaderStyle}
                        key={columnDef.tableData.id}
                        columnDef={columnDef}
                        classes={this.props.classes}
                        headerStyle={{...this.props.headerStyle,zIndex: this.props.dragColumn === columnDef.tableData.id.toString() ? 10000 : 10}}
                        content={content}
                    />
                );
            });
        return mapArr;
    }


    render() {
        // console.log(this.props)
        const headers = this.renderHeader();

        return (
            <Droppable droppableId="headers" direction="horizontal">
                {(provided, snapshot) => (
                    <TableHead
                        ref={provided.innerRef}
                    >
                        <TableRow>
                            {headers}
                        </TableRow>
                    </TableHead>
                )}
            </Droppable>
        );
    }
}

MTableHeader.defaultProps = {
    dataCount: 0,
    hasSelection: false,
    headerStyle: {},
    selectedCount: 0,
    sorting: true,
    localization: {
        actions: 'Actions'
    },
    orderBy: undefined,
    orderDirection: 'asc',
    actionsHeaderIndex: 0,
    detailPanelColumnAlignment: 'left'
};

MTableHeader.propTypes = {
    columns: PropTypes.array.isRequired,
    dataCount: PropTypes.number,
    hasDetailPanel: PropTypes.bool.isRequired,
    detailPanelColumnAlignment: PropTypes.string,
    hasSelection: PropTypes.bool,
    headerStyle: PropTypes.object,
    localization: PropTypes.object,
    selectedCount: PropTypes.number,
    sorting: PropTypes.bool,
    onAllSelected: PropTypes.func,
    onOrderChange: PropTypes.func,
    orderBy: PropTypes.number,
    orderDirection: PropTypes.string,
    actionsHeaderIndex: PropTypes.number,
    showActionsColumn: PropTypes.bool,
    showSelectAllCheckbox: PropTypes.bool,
};


export const styles = theme => ({
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 10,
        backgroundColor: theme.palette.background.paper, // Change according to theme,
    }
});

export default withStyles(styles)(MTableHeader);