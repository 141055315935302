import React, {PureComponent} from 'react'
import {Switch, Route} from 'react-router-dom'
import Categories from './cg3'
import Tabs from './tabs'
import Commodities from './cg1'
import Genders from './cg2'
import Channels from './channels'
import SizesDictionary from './sizes'
import TaskTemplates from "./taskTemplates"
import ProtectedRoute from "../../reuseComponent/Routes/protected-route"
import BrandsModule from './brands'
import ProductNamesModule from './productNames'
import { ArticlesModule } from './Articles'
import WorkPlacesModule from './WorkPlaces'
import QualitiesModule from './Qualities'
import {
    permViewCg1Dictionary,
    permEditCg1Dictionary,
    permViewCg3Dictionary,
    permEditCg3Dictionary,
    permViewCg2Dictionary,
    permEditCg2Dictionary,
    permViewChannelsDictionary,
    permEditChannelsDictionary,
    permViewProductNamesDictionary,
    permEditProductNamesDictionary,
    permViewSizesDictionary,
    permEditSizesDictionary, permViewTaskTemplateDictionary,permViewArticles,permEditArticles,
    permViewWorkPlaces,permEditWorkPlaces
} from "../../../permissions/dictionaries"

class Dictionaries extends PureComponent {

    render() {
        const { alias } = this.props.match.params
        return (
            <>
                <Route exact path="/atw/dictionaries/:alias" component={() => <Tabs alias={alias}/>}/>
                <Switch>
                    <ProtectedRoute
                        permission={[permViewCg3Dictionary, permEditCg3Dictionary]}
                        path="/atw/dictionaries/cg3"
                        component={Categories}
                    />
                    <ProtectedRoute
                        permission={[permViewCg1Dictionary, permEditCg1Dictionary]}
                        exact
                        path="/atw/dictionaries/cg1"
                        component={Commodities}
                    />
                    <ProtectedRoute
                        permission={[permViewCg2Dictionary, permEditCg2Dictionary]}
                        exact
                        path="/atw/dictionaries/cg2"
                        component={Genders}
                    />
                    <ProtectedRoute
                        permission={[permViewChannelsDictionary, permEditChannelsDictionary]}
                        exact
                        path="/atw/dictionaries/channels"
                        component={Channels}
                    />
                    <ProtectedRoute
                        permission={[permViewSizesDictionary, permEditSizesDictionary]}
                        exact
                        path="/atw/dictionaries/sizes"
                        component={SizesDictionary}
                    />
                    <ProtectedRoute
                        permission={permViewTaskTemplateDictionary}
                        exact
                        path="/atw/dictionaries/task-templates"
                        component={TaskTemplates}
                    />
                    <ProtectedRoute
                        permission={[permViewArticles,permEditArticles]}
                        exact
                        path="/atw/dictionaries/articles"
                        component={ArticlesModule}
                    />
                    <ProtectedRoute
                        permission={[permViewProductNamesDictionary,permEditProductNamesDictionary]}
                        exact
                        path="/atw/dictionaries/productNames"
                        component={ProductNamesModule}
                    />
                    <ProtectedRoute
                        permission={[permViewWorkPlaces,permEditWorkPlaces]}
                        exact
                        path="/atw/dictionaries/work-places"
                        component={WorkPlacesModule}
                    />
                    <Route path="/atw/dictionaries/qualities" component={QualitiesModule}
                    />
                    <BrandsModule />

                </Switch>
            </>
        )
    }
}

export default Dictionaries