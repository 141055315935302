// export const url = "https://wcs-api.testly.space"; // TEST
// export const url = 'https://wcs-api.stock-solution.de' // PROD
// export const url = 'https://wcs-api.aily.team' // OLD PROD
// export const url = 'http://192.168.0.101:8082/v1' // LOCAL API
// export const url = 'http://127.0.0.1:8081/v1' // LOCAL API



export const url = process.env.REACT_APP_API_URL;

export const LOGIN = {
    SET_LOADING: 'SET_LOADING',
    SET_USER: 'SET_USER',
    LOG_OUT: 'LOG_OUT'
};


export const SUPPLIERS = {
    IS_LOADING: 'IS_LOADING',
    GET_SUPPLIER: 'GET_SUPPLIER',
    CLEAR_SUPPLIER: 'CLEAR_SUPPLIER',
    SET_QUALITIES: 'SET_QUALITIES'
};

export const USERS = {
    IS_LOADING_USER: 'IS_LOADING_USER',
    IS_LOADING_LANGUAGES_LIST: 'IS_LOADING_LANGUAGES_LIST',
    IS_LOADING_USERS_FOR_ALLOCATION: 'IS_LOADING_USERS_FOR_ALLOCATION',
    SET_USERS: 'SET_USERS',
    SET_LANGUAGES_LIST: 'SET_LANGUAGES_LIST',
    SET_USERS_FOR_ALLOCATION: 'SET_USERS_FOR_ALLOCATION',
    CURR_USER_REQUEST: 'CURR_USER_REQUEST',
    CURR_USER_RECEIVED: 'CURR_USER_RECEIVED',
    CURR_USER_FAILED: 'CURR_USER_RECEIVED',
    REQUEST_REFRESH_TOKEN: 'REQUEST_REFRESH_TOKEN',
    IS_LOADING_USER_BY_ID: 'IS_LOADING_USER_BY_ID',
    SET_USER_BY_ID: 'SET_USER_BY_ID',
    IS_LOADING_ROLES: 'IS_LOADING_ROLES',
    SET_ROLES_USERS: 'SET_ROLES_USERS',
    SET_PO_OVERVIEW: 'SET_PO_OVERVIEW',
    SET_USER_CUSTOMERS_COUNT: 'SET_USER_CUSTOMERS_COUNT'
};

export const PURCHASE_ORDERS = {
    IS_LOADING: 'IS_LOADING',
    IS_LOADING_DETAIL: 'IS_LOADING_DETAIL',
    RESET_ALL: 'RESET_ALL',
    SET_DETAIL: 'SET_DETAIL',
    CHANGE_PURCHASE: 'CHANGE_PURCHASE',
    CHANGE_VIEW_TABLE: 'CHANGE_VIEW_TABLE',
    SET_ACTIVE_ROW: 'SET_ACTIVE_ROW',
    SET_LIST_WITH_PARAMETRS: 'SET_LIST_WITH_PARAMETRS',
    CLEAR_PRODUCTS: 'CLEAR_PRODUCTS',
    IS_UPLOADING_PRODUCTS: 'IS_UPLOADING_PRODUCTS',
    ADD_FOLLOWER: 'ADD_FOLLOWER',
    IS_SHOWING_PRODUCTS_MODAL: 'IS_SHOWING_PRODUCTS_MODAL',
    IS_LOADING_HISTORY: 'IS_LOADING_HISTORY',
    SET_HISTORY_PO: 'SET_HISTORY_PO',
    ADD_HISTORY_COMMENT: 'ADD_HISTORY_COMMENT',
    ADD_NOTIFICATION: 'ADD_NOTIFICATION',
    EDIT_HISTORY_COMMENT: 'EDIT_HISTORY_COMMENT',
    REMOVE_HISTORY_COMMENT: 'REMOVE_HISTORY_COMMENT',
    CHANGE_FILTER_HISTORY: 'CHANGE_FILTER_HISTORY',
    SHOW_HIDE_CREATE_CONTACT_MODAL: 'SHOW_HIDE_CREATE_CONTACT_MODAL',
    CLOSE_OPEN_SECTION: 'CLOSE_OPEN_SECTION',
    ADD_HISTORY_ITEM: 'ADD_HISTORY_ITEM',
    CHANGE_TASKS_DATA: 'CHANGE_TASKS_DATA',
    CHANGE_ACTIVE_HISTORY_TAB: 'CHANGE_ACTIVE_HISTORY_TAB',
    SHOW_HIDE_UPLOADING_SCHEMA: 'SHOW_HIDE_UPLOADING_SCHEMA',
    IS_LOADING_UPLOADING_EXCEL_SCHEMA: 'IS_LOADING_UPLOADING_EXCEL_SCHEMA',
    SET_UPLOADING_EXCEL_SCHEMA: 'SET_UPLOADING_EXCEL_SCHEMA',
    SET_PRODUCTS_ERRORS: 'SET_PRODUCTS_ERRORS',
    SET_FILTER_PRODUCT_LIST: 'SET_FILTER_PRODUCT_LIST',
    RESET_FILTER_PRODUCT_LIST: 'RESET_FILTER_PRODUCT_LIST',
    SET_INBOUND_REPORT: 'SET_INBOUND_REPORT',
    SET_TASKS_LIST: 'SET_TASKS_LIST',
    LOADING_PO_TASKS: 'LOADING_PO_TASKS',
    EDIT_PO_TASK: 'EDIT_PO_TASK',
    REMOVE_PO_TASK: 'REMOVE_PO_TASK',
    ADD_TASK_TO_PO: 'ADD_TASK_TO_PO',
    SET_TOTAL_AVARAGE_PO: 'SET_TOTAL_AVARAGE_PO',
    SET_PO_STATUSES: 'SET_PO_STATUSES',
    IS_LOADING_PO_OVERVIEW: 'IS_LOADING_PO_OVERVIEW',
    SET_COLUMNS_PO: 'SET_COLUMNS_PO',
    ADD_DELIVERY_NOTE: 'ADD_DELIVERY_NOTE',
    REMOVE_DELIVERY_NOTE: 'REMOVE_DELIVERY_NOTE',
    SET_TABLE_REF: 'SET_TABLE_REF',
    SET_SNAPSHOT: 'SET_SNAPSHOT',
    SET_PO_INBOUNDING_TYPES: 'SET_PO_INBOUNDING_TYPES',
    EDIT_PO: 'EDIT_PO',
    SET_PRODUCTS_LIST: 'SET_PRODUCTS_LIST',
    SELECTED_PACKEGE:'SELECTED_PACKEGE',
    REMOVE_PRODUCTS_DELETED_BID: 'REMOVE_PRODUCTS_DELETED_BID'
};

export const PO_DETAILS = {
    DPO_MODAL_VIEW_CHANGE: 'DPO_MODAL_VIEW_CHANGE',
    DPO_MULTIPLE_CHANGES_STATE: 'DPO_MULTIPLE_CHANGES_STATE',
    DPO_EDIT_BIDS: 'DPO_EDIT_BIDS',
    IS_LOADING_BIDS_INFO: 'IS_LOADING_BIDS_INFO',
    CHANGE_BIDS_VALUES_FORM: 'CHANGE_BIDS_VALUES_FORM',
    ADD_UNCONFIRMED_BID: 'ADD_UNCONFIRMED_BID',
    REMOVE_UNCONFIRMED_BID: 'REMOVE_UNCONFIRMED_BID',
    RESET_PO_DETAILS_STATE: 'RESET_PO_DETAILS_STATE',
    IS_EDIT_INBOUND: 'IS_EDIT_INBOUND',
    SET_INBOUND_INFO: 'SET_INBOUND_INFO',
    CLEAR_INBOUND_INFO: 'CLEAR_INBOUND_INFO'
};

export const DIVIDING = {
    IS_LOADING_DIVIDING_RESULTS: 'IS_LOADING_DIVIDING_RESULTS',
    ADD_CONDITION_SET: 'ADD_CONDITION_SET',
    SET_CONDITIONS_SETS: 'SET_CONDITIONS_SETS',
    SET_FIELDS_FOR_CONDITION: 'SET_FIELDS_FOR_CONDITION',
    CHANGE_CONDITION_SET_DATA: 'CHANGE_CONDITION_SET_DATA',
    ADD_CONDITION_TO_SET: 'ADD_CONDITION_TO_SET',
    EDIT_CONDITION_IN_SET: 'EDIT_CONDITION_IN_SET',
    CHANGE_ACTIVE_SET: 'CHANGE_ACTIVE_SET',
    REMOVE_CONDITION: 'REMOVE_CONDITION',
    IS_PRODUCTS_EXIST: 'IS_PRODUCTS_EXIST',
    IS_LOADING_PRODUCTS: 'IS_LOADING_PRODUCTS',
    SET_DIVIDING_STATUS: 'SET_DIVIDING_STATUS',
    IS_LOADING_STATUS: 'IS_LOADING_STATUS',
    RESET_DIVIDING_STATE: 'RESET_DIVIDING_STATE',
    IS_CHANGING_SET_LOADING: 'IS_CHANGING_SET_LOADING',
    CHANGE_PACKAGE_NAME: 'CHANGE_PACKAGE_NAME'
};

export const DEPARTMENTS = {
    IS_LOADING_DEPARTMENT: 'IS_LOADING_DEPARTMENT',
    SET_DEPARTMENT: 'SET_DEPARTMENT',
    SET_DEPARTMENTS: 'SET_DEPARTMENTS'
};

export const ADMIN_HEADER = {
    SET_DATA: 'SET_DATA',
    CHANGE_FILTERS: 'CHANGE_FILTERS',
    RESET_STATE: 'RESET_STATE',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT'
};

export const RELEASE_NOTES = {
    IS_LOADING_NOTE: 'IS_LOADING_NOTE',
    SET_NOTE: 'SET_NOTE',
    SET_CURRENT_VERSION: 'SET_CURRENT_VERSION',
    SET_NOTES_LIST: 'SET_NOTES_LIST',
    ADD_RELEASE_NOTE: 'ADD_RELEASE_NOTE',
    REMOVE_RELEASE_NOTE: 'REMOVE_RELEASE_NOTE',
    EDIT_RELEASE_NOTE: 'EDIT_RELEASE_NOTE'
};

export const NOTIFICATIONS = {
    IS_LOADING_NOTIFICATION: 'IS_LOADING_NOTIFICATION',
    SET_NOTIFICATION: 'SET_NOTIFICATION',
    IS_LOADING_EVENTS: 'IS_LOADING_EVENTS',
    SET_EVENTS: 'SET_EVENTS',
    SET_VARIABLES: 'SET_VARIABLES',
    SET_EDITOR: 'SET_EDITOR'
};

export const CATEGORIES_DICTIONARY = {
    RESET_ALL_CATEGORIES_DICTIONARY: 'RESET_ALL_CATEGORIES_DICTIONARY',
    SET_CATEGORIES_DICTIONARY: 'SET_CATEGORIES_DICTIONARY',
    IS_LOADING_CATEGORIES_DICTIONARY: 'IS_LOADING_CATEGORIES_DICTIONARY',
    SET_RELATED_CATEGORIES_VALUES: 'SET_RELATED_CATEGORIES_VALUES',
    IS_LOADING_VALUES: 'IS_LOADING_VALUES',
    REMOVE_CATEGORY_DICTIONARY: 'REMOVE_CATEGORY_DICTIONARY'
};

// GENDER
export const CG1_DICTIONARY = {
    IS_LOADING_CG1_LIST: 'IS_LOADING_CG1_LIST',
    SET_LIST_CG1_DICTIONARY: 'SET_LIST_CG1_DICTIONARY',
    ADD_ITEM_TO_CG1_LIST: 'ADD_ITEM_TO_CG1_LIST',
    REMOVE_ITEM_FROM_CG1_LIST: 'REMOVE_ITEM_FROM_CG1_LIST',
    EDIT_CG1_ITEM: 'EDIT_CG1_ITEM'
};

// QUALITY
export const QUALITY_DICTIONARY = {
    IS_LOADING_QUALITY_LIST: 'IS_LOADING_QUALITY_LIST',
    SET_LIST_QUALITY_DICTIONARY: 'SET_LIST_QUALITY_DICTIONARY',
    ADD_ITEM_TO_QUALITY_LIST: 'ADD_ITEM_TO_QUALITY_LIST',
    REMOVE_ITEM_FROM_QUALITY_LIST: 'REMOVE_ITEM_FROM_QUALITY_LIST',
    EDIT_QUALITY_ITEM: 'EDIT_QUALITY_ITEM'
};

// Commodity
export const CG2_DICTIONARY = {
    IS_LOADING_CG2_LIST: 'IS_LOADING_CG2_LIST',
    SET_LIST_CG2_DICTIONARY: 'SET_LIST_CG2_DICTIONARY',
    ADD_ITEM_TO_CG2_LIST: 'ADD_ITEM_TO_CG2_LIST',
    REMOVE_ITEM_FROM_CG2_LIST: 'REMOVE_ITEM_FROM_CG2_LIST',
    EDIT_CG2_ITEM: 'EDIT_CG2_ITEM'
};

// Channel

export const CHANNEL_DICTIONARY = {
    IS_LOADING_CHANNEL_LIST: 'IS_LOADING_CHANNEL_LIST',
    SET_LIST_CHANNEL_DICTIONARY: 'SET_LIST_CHANNEL_DICTIONARY',
    ADD_ITEM_TO_CHANNEL_LIST: 'ADD_ITEM_TO_CHANNEL_LIST',
    REMOVE_ITEM_FROM_CHANNEL_LIST: 'REMOVE_ITEM_FROM_CHANNEL_LIST',
    EDIT_CHANNEL_ITEM: 'EDIT_CHANNEL_ITEM'
};

// Product names
export const PRODUCT_NAMES_DICTIONARY = {
    IS_LOADING_PRODUCT_NAMES_LIST: 'IS_LOADING_PRODUCT_NAMES_LIST',
    SET_LIST_PRODUCT_NAMES_DICTIONARY: 'SET_LIST_PRODUCT_NAMES_DICTIONARY',
    ADD_ITEM_TO_PRODUCT_NAMES_LIST: 'ADD_ITEM_TO_PRODUCT_NAMES_LIST',
    REMOVE_ITEM_FROM_PRODUCT_NAMES_LIST: 'REMOVE_ITEM_FROM_PRODUCT_NAMES_LIST',
    EDIT_PRODUCT_NAMES_ITEM: 'EDIT_PRODUCT_NAMES_ITEM'
}

export const SIZES_DICTIONARY = {
    IS_LOADING_SIZES: 'IS_LOADING_SIZES',
    SET_SIZES_SETS: 'SET_SIZES_SETS',
    IS_LOADING_SIMPLE_PARAMETERS_FOR_SIZES:
        'IS_LOADING_SIMPLE_PARAMETERS_FOR_SIZES',
    SET_SIMPLE_PARAMS_FOR_SIZES: 'SET_SIMPLE_PARAMS_FOR_SIZES',
    ADD_SIZE_SET: 'ADD_SIZE_SET',
    REMOVE_SIZE_SET: 'REMOVE_SIZE_SET',
    EDIT_SIZE_SET: 'EDIT_SIZE_SET',
    SET_ACTIVE_SIZE_SET: 'SET_ACTIVE_SIZE_SET',
    IS_LOADING_SIZE_TYPES: 'IS_LOADING_SIZE_TYPES',
    SET_SIZE_TYPES: 'SET_SIZE_TYPES',
    ADD_NEW_SIZE_TYPE: 'ADD_NEW_SIZE_TYPE',
    EDIT_SIZE_TYPE: 'EDIT_SIZE_TYPE',
    REMOVE_SIZE_TYPE: 'REMOVE_SIZE_TYPE',
    ADD_ROW_TO_SIZE_SET: 'ADD_ROW_TO_SIZE_SET',
    ADD_NEW_VALUE_TO_ROW_SIZE_SET: 'ADD_NEW_VALUE_TO_ROW_SIZE_SET',
    EDIT_VALUE_OF_ROW_SIZE_SET: 'EDIT_VALUE_OF_ROW_SIZE_SET',
    REMOVE_SIZE_ROW: 'REMOVE_SIZE_ROW'
};

export const PO_SMART_FILTER = {
    SET_PO_FILTERS: 'SET_PO_FILTERS'
};

export const ADMIN_GLOBAL = {
    CHANGE_STYLE_WRAPPER: 'CHANGE_STYLE_WRAPPER',
    IS_CONNECTED_TO_SOCKET_CLIENT: 'IS_CONNECTED_TO_SOCKET_CLIENT'
};

export const ROLES = {
    SET_ROLES: 'SET_ROLES'
};

export const TASKS = {
    SET_ACTIVE_TASKS: 'SET_ACTIVE_TASKS',
    SET_ACTIVE_INBOX: 'SET_ACTIVE_INBOX',
    ADD_COMMENT: 'ADD_COMMENT',
    CHANGE_ACTIVE_HISTORY_TAB: 'CHANGE_ACTIVE_HISTORY_TAB',
    SET_HISTORY: 'SET_HISTORY',
    SET_SELECTED_TASK: 'SET_SELECTED_TASK',
};

export const SERVICE_DESK = {
    SET_SERVICE_DESK_STATUSES: 'SET_SERVICE_DESK_STATUSES',
    SET_SERVICE_DESK_TYPES: 'SET_SERVICE_DESK_TYPES',
    SET_SERVICE_DESK_REQUEST: 'SET_SERVICE_DESK_REQUEST',
    CLEAR_REQUEST: 'CLEAR_REQUEST',
    IS_LOADING_SERVICE_DESK: 'IS_LOADING_SERVICE_DESK',
    SET_HISTORY_SD: 'SERVICE_DESK.SET_HISTORY_SD',
    IS_LOADING_SERVICE_DESK_HISTORY: 'IS_LOADING_SERVICE_DESK_HISTORY',
    CHANGE_ACTIVE_HISTORY_SD_TAB: 'CHANGE_ACTIVE_HISTORY_SD_TAB',
    ADD_HISTORY_SD_ITEM: 'ADD_HISTORY_SD_ITEM',
    ADD_HISTORY_SD_FILES: 'ADD_HISTORY_SD_FILES',
    SET_STATUS: 'SET_STATUS',
    SET_SERVICE_DESK_PRIORITIES: 'SET_SERVICE_DESK_PRIORITIES',
    SET_ACTIVE_PRIORITY: 'SET_ACTIVE_PRIORITY'
};

export const CUSTOMERS = {
    SET_CUSTOMER: 'SET_CUSTOMER',
    CLEAR_CUSTOMER: 'CLEAR_CUSTOMER',
    IS_LOADING_CUSTOMER: 'IS_LOADING_CUSTOMER',
    IS_LOADING_COUNTRIES: 'IS_LOADING_COUNTRIES',
    SET_COUNTRIES: 'SET_COUNTRIES',
    SET_CUSTOMER_HISTORY: 'SET_CUSTOMER_HISTORY',
    SET_LOADING_CUSTOMERS_STATUSES: 'SET_LOADING_CUSTOMERS_STATUSES',
    SET_CUSTOMERS_STATUSES: 'SET_CUSTOMERS_STATUSES',
    SET_CUSTOMERS_STATUS: 'SET_CUSTOMERS_STATUS',
    SET_CUSTOMERS_RESPONSIBLE: 'SET_CUSTOMERS_RESPONSIBLE',
    CHANGE_CUSTOMER_HISTORY_TAB: 'CHANGE_CUSTOMER_HISTORY_TAB',
    ADD_HISTORY_FILE: 'ADD_HISTORY_FILE',
    ADD_HISTORY_CUSTOMERS_ITEM: 'ADD_HISTORY_CUSTOMERS_ITEM',
    SET_CUSTOMER_REQUEST: 'SET_CUSTOMER_REQUEST',
    CLEAR_REQUEST: 'CLEAR_REQUEST',
    SET_HISTORY_CUSTOMER: 'SET_HISTORY_CUSTOMER'
};

export const ANALYSIS_TOOL = {
    SHOW_HIDE_UPLOADING_SCHEMA_ANALYSIS: 'SHOW_HIDE_UPLOADING_SCHEMA_ANALYSIS',
    IS_LOADING_ANALYSIS: 'IS_LOADING_ANALYSIS',
    SET_PRODUCTS_ANALYSIS: 'SET_PRODUCTS_ANALYSIS',
    SET_ORDER_ID: 'SET_ORDER_ID',
    SET_ACTIVE_TAB_ANALYSIS: 'SET_ACTIVE_TAB_ANALYSIS',
    SET_ERRORS_COUNT_ANALYSIS: 'SET_ERRORS_COUNT_ANALYSIS',
    SET_FILTER_CONDITIONS_ANALYSIS: 'SET_FILTER_CONDITIONS_ANALYSIS',
    IS_IMPORT_FILE_ANALYSIS: 'IS_IMPORT_FILE_ANALYSIS'
};

export const SERVICE_WORKER = {
    SW_INIT: 'SW_INIT',
    SW_UPDATE: 'SW_UPDATE'
};

export const SMART_FILTER = {
    SET_FILTERS: 'SET_FILTERS',
    SET_ACTIVE_FILTER: 'SET_ACTIVE_FILTER'
};

export const STOWING = {
    SET_LOADING_PACKAGES: 'SET_LOADING_PACKAGES',
    SET_STOWING_PACKAGES: 'SET_STOWING_PACKAGES',
    SET_LOADING_BOXES: 'SET_LOADING_BOXES',
    SET_ACTIVE_STOWED_PACKAGE: 'SET_ACTIVE_STOWED_PACKAGE',
    SET_STOWING_BOXES: 'SET_STOWING_BOXES',
    CLEAR_STOWING_DATA: 'CLEAR_STOWING_DATA',
    IS_PUT_PRODUCT: 'IS_PUT_PRODUCT',
    IS_CHANGE_DIVIDING: 'IS_CHANGE_DIVIDING'
}

export const INBOUNDING_REPORT = {
    SET_LOADING_PACKAGES_INBOUNDING: 'SET_LOADING_PACKAGES_INBOUNDING',
    SET_LOADING_BOXES_INBOUNDING: 'SET_LOADING_BOXES_INBOUNDING_INBOUNDING',
    SET_LOADING_PRODUCTS_INBOUNDING: 'SET_LOADING_PRODUCTS',
    SET_PACKAGES_INBOUNDING: 'SET_PACKAGES_INBOUNDING',
    SET_ACTIVE_PACKAGE_INBOUNDING: 'SET_ACTIVE_PACKAGE_INBOUNDING',
    SET_BOXES_INBOUNDING: 'SET_BOXES_INBOUNDING',
    SET_PRODUCTS_INBOUNDING: 'SET_PRODUCTS_INBOUNDING',
    UPDATE_REPORT: 'UPDATE_REPORT',
    CLEAR_INBOUND_REPORT_REDUCER: 'CLEAR_INBOUND_REPORT_REDUCER',
    SET_SEARCHED_PACKAGES: 'SET_SEARCHED_PACKAGES',
    UPDATE_BOX_ITEM_COUNT: 'UPDATE_BOX_ITEM_COUNT'
};

export const PRINTERS = {
    SET_LOADING_LOCATION_CONFIGURATION: 'SET_LOADING_LOCATION_CONFIGURATION',
    SET_LOADING_CONNECTION_TO_PRINTERS: 'SET_LOADING_CONNECTION_TO_PRINTERS',
    SET_SELECTED_PRINTER: 'SET_SELECTED_PRINTER',
    SET_AVAILABLE_PRINTERS: 'SET_AVAILABLE_PRINTERS',
}

export const OFFERS = {
    SET_RESERVATION_DATA: 'SET_RESERVATION_DATA'
};

export const INVENTORY = {
    SET_INVENTORY_STATUS_LIST: 'SET_INVENTORY_STATUS_LIST',
    SET_INVENTORY_PACK_TYPE_LIST: 'SET_INVENTORY_PACK_TYPE_LIST',
    SET_PLAN: 'SET_PLAN'
}

export const chronologyFiledsConverting = {
    pickupDate: 'Pickup date',
    targetArrivalDate: 'Planned Arrival date',
    transportShippingCompany: 'Shipping company',
    placementDate: 'Placement date',
    pickupAddress: 'Pickup address',
    referenceNumber: 'Ref. ID',
    pickupContacts: 'Contact',
    financeStatus: 'Payment status',
    comment: 'Notes',
    addressNote: 'Note',
    prepaidPercentage: 'Prepayment',
    addressPrice: 'Price',
    actualPackagesCount: 'Received packs',
    actualPalletsCount: 'Received pallets',
    actualGrossWeight: 'Received gross, kg',
    actualNetWeight: 'Received net, kg',
    actualQuantity: 'Received quantity',
    targetQuantity: 'total quantity',
    targetPalletsCount: 'total pallets',
    targetNetWeight: 'total net, kg',
    targetGrossWeight: 'total gross, kg',
    targetPackagesCount: 'total packs',
    offerNumber: 'Offer number',
    buyer: 'Responsible Buyer',
    qualityCheck: 'Quality check',
    discount: 'Discount',
    totalPrice: 'Total price',
};

export const supplierChronologyFiledsConverting = {
    'title': 'Company',
    'email': 'Email',
    'buyer': 'Buyer',
    'camouflaging': 'Camouflage',
    'officeAddress': 'Office address',
    'additionalInfo': 'Note',
    'warehouseAddress': 'Warehouse addresses',
    'qualityCamouflage.Mix': 'Mix',
    'qualityCamouflage.2nd Hand': '2nd Hand',
    'qualityCamouflage.C2': 'C2',
    'qualityCamouflage.B2': 'B2',
    'qualityCamouflage.B': 'B',
    'qualityCamouflage.A': 'A',
    'qualityCamouflage.Samples': 'Samples',
    'qualityCamouflage.D': 'D',
    'qualityCamouflage.C': 'C',
    'qualityCamouflage.B-': 'B-',
    'qualityCamouflage.A-': 'A-',
}

export const palletChronologyFiledsConverting = {
    'box': 'Boxes',
    'productName': 'Product name',
    'location': 'Location',
    'weight': 'Gross weight, kg',
    'netWeight': 'Net weight, kg',
    'order': 'POs',
    'status': 'Status',
    'pack_type': 'Packs type',
    'season': 'Season',
    'quality': 'Quality',
    'bid': 'Bid'
}

export const offersChronologyFiledsConverting = {
    'title': 'Title',
    'unit': 'Unit',
    'price': 'Price',
    'moq': 'MOQ',
    'availability': 'Availability',
    'deliveryTime': 'Delivery time',
    'comment': 'Comment',
    'side': 'Sise',
    'viewsCount': 'Views',
    'pallets': 'Pallets',
}