import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
// import Table from 'material-table'
import adminInstance from '../../../instances/admininstance'
import {url} from '../../../../constants'
import DropDownMenu from '../../../reuseComponent/DropDown'
import {setData} from '../../../../actions/admin-header';
import {getDepartments} from '../../../../actions/departments';
import {getUsers} from '../../../../actions/users';
import Modal from '../../../reuseComponent/modal';
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import CreateTemplateForm from './create';
import ToolTip from '../../../reuseComponent/Tooltips';
import Switch from '../../../reuseComponent/Switcher';
import {removeTaskTemplate, updateTaskTemplateField} from '../../../../services/dictionaries';
import {notify} from '../../../reuseComponent/toast';
import StandartLeftPanner from '../../../reuseComponent/Header/standartLeftPannel';
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import {permEditTaskTemplateDictionary} from '../../../../permissions/dictionaries';
import Table from '../../../reuseComponent/Table/MaterialTable'
import Pagination from '../../../reuseComponent/Table/Pagination'
import Toolbar from './Toolbar'
import Header from '../../../reuseComponent/Table/table-head'
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import withTracker from "../../../reuseComponent/GoogleAnalytics";

class TaskTemplateList extends PureComponent {

    state = {
        openCreateModal: false,
        removeModal: false,
        removedTitle: '',
        idTemplate: null
    };

    tableRef = React.createRef();

    generateColumns = [
        {
            title: 'Title',
            field: 'title',
            sorting: false
        },
        {
            title: 'Default for PO',
            field: 'poDefault',
            sorting: false,
            render: rowData => {
                return (
                    <Switch
                        checked={rowData.poDefault}
                        disabled={!findPermInArray(this.props.roles, permEditTaskTemplateDictionary)}
                        onChange={() => {
                            updateTaskTemplateField(rowData.id, 'poDefault', !rowData.poDefault)
                                .then(res => {
                                    this.refetchTable();
                                    notify('success', 'Status changed');
                                })
                                .catch(err => console.log(err))
                        }}
                    />
                )
            }
        },
        {
            title: 'Department',
            field: 'department',
            sorting: false,
            render: rowData => {
                return (
                    rowData.department
                        ?
                        <ToolTip
                            text={rowData.department.name}
                        >
                            <span className="icon-purchase-status"
                                  dangerouslySetInnerHTML={{__html: rowData.department.icon}}
                            >

                            </span>
                        </ToolTip>
                        :
                        rowData.executor && rowData.executor.department ?
                            <ToolTip
                                text={rowData.executor.department.name}
                            >
                                <span className="icon-purchase-status"
                                      dangerouslySetInnerHTML={{__html: rowData.executor.department.icon}}
                                >

                                </span>
                            </ToolTip>
                        : null
                )
            }
        },
        {
            title: 'Responsible',
            field: 'executor',
            sorting: false,
            render: rowData => {
                return (
                    rowData.executor && `${rowData.executor.name} ${rowData.executor.secondName}`
                )
            }
        },
        {
            title: '',
            field: 'actions',
            sorting: false,
            headerStyle: {
                width: 32
            },
            render: rowData => {
                return findPermInArray(this.props.roles, permEditTaskTemplateDictionary) ?
                    <DropDownMenu
                        ContentMenu={() => {
                            return (
                                <ul className='list-unstyled no-margin menu-table-actions'>
                                    <li>
                                        <a href="/" onClick={e => {
                                            e.preventDefault()
                                            this.showHideModal({
                                                modalName: 'openCreateModal',
                                                idTemplate: rowData.id,
                                                open: true
                                            })
                                        }}>Edit</a>
                                    </li>
                                    <li>
                                        <a href="/" onClick={e => {
                                            e.preventDefault()
                                            this.setState({
                                                removedTitle: rowData.title,
                                            })
                                            this.showHideModal({
                                                modalName: 'removeModal',
                                                open: true,
                                                idTemplate: rowData.id
                                            })
                                        }}>Delete</a>
                                    </li>
                                </ul>
                            )
                        }}
                    >
                    </DropDownMenu> : null
            }
        }
    ];

    componentDidMount() {
        const {setHeaderData, getDepartments, getUsers} = this.props;
        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'Ticket templates',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#795548"
                          d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z"/>
                </svg>)),
            },
            // textAddButton: 'Add',
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: (value) => this.tableRef.current.onSearchChange(value)
        })

        getUsers && getUsers();
        getDepartments && getDepartments();
    }

    refetchTable = () => {
        this.tableRef.current && this.tableRef.current.onQueryChange()
    };

    showHideModal = ({modalName, idTemplate, open, callback}) => {
        this.setState({
            [modalName]: open,
            idTemplate: idTemplate
        }, () => {
            callback && callback()
        })
    };

    getTemplatesList = query => {
        const {page, pageSize, search} = query;
        return new Promise((resolve, reject) => {
            adminInstance.get(`${url}/template/task`, {
                params: {
                    page: page ? page : 0,
                    limit: pageSize,
                    search: search
                }
            })
                .then(res => {
                    resolve({
                        totalCount: res.data.count,
                        data: res.data.templates,
                        page: query.page
                    })
                })
        })
    };

    render() {
        const { roles } = this.props
        const {
            idTemplate,
            openCreateModal,
            removeModal,
            removedTitle,
        } = this.state;


        return (
            <div className="wrapper-table">
                <Table
                    components={{
                        Toolbar: props => <Toolbar
                            {...props}
                            handleAdd={findPermInArray(roles, permEditTaskTemplateDictionary) ?
                                () => this.showHideModal({modalName: 'openCreateModal', idTemplate: null, open: true})
                                : null}
                        />,
                        Header: Header,
                        Pagination: Pagination
                    }}
                    paginationPosition="toolbar"
                    tableRef={this.tableRef}
                    columns={this.generateColumns}
                    data={this.getTemplatesList}
                    title=""
                    options={{
                        maxBodyHeight: 'calc(100vh - 135px)',
                        showTitle: false,
                        search: true,
                        emptyRowsWhenPaging: false,
                        pageSize: 50,
                        pageSizeOptions: [10, 25, 50],
                    }}
                />
                {findPermInArray(this.props.roles, permEditTaskTemplateDictionary) && <Modal
                    open={openCreateModal}
                    isFooter={false}
                    handleClose={() => this.showHideModal({
                        modalName: 'openCreateModal',
                        open: false
                    })}
                    Content={() => <CreateTemplateForm
                        refetchTable={this.refetchTable}
                        idTemplate={idTemplate}
                        handleClose={() => this.showHideModal({
                            modalName: 'openCreateModal',
                            open: false
                        })}
                    />}
                    options={{
                        centered: true
                    }}
                />
                }
                {findPermInArray(this.props.roles, permEditTaskTemplateDictionary) && <CustomModalWithChildren
                    open={removeModal}
                    handleClose={e => this.showHideModal({
                        modalName: 'removeModal',
                        open: false
                    })}
                    handleCancel={e => this.showHideModal({
                        modalName: 'removeModal',
                        open: false
                    })}
                    handleConfirm={() => {
                        this.showHideModal({
                            modalName: 'removeModal',
                            open: false,
                            callback: () => {
                                removeTaskTemplate(idTemplate)
                                    .then(res => {
                                        this.refetchTable()
                                        notify('success', 'Template successfully deleted')
                                    })
                                    .catch(err => {
                                        notify('error', 'Oops something went wrong')
                                    })
                            }
                        })

                    }}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent text={`delete "${removedTitle}" task template`}/>
                </CustomModalWithChildren>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    roles: state.rolesReducer.roles,
});

const mapDispatchToProps = dispatch => ({
    setHeaderData: obj => dispatch(setData(obj)),
    getDepartments: () => dispatch(getDepartments()),
    getUsers: () => dispatch(getUsers()),
});

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(TaskTemplateList));