import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {setDetailsPurchaseAction} from '../../../../actions/purchase-orders'
import {changeResponsiblePerson} from '../../../../actions/purchase-orders'
import {Form} from 'react-bootstrap'
import {withRouter} from 'react-router-dom'
import {getHistory} from '../../../../actions/purchase-orders'
import adminInstance from '../../../instances/admininstance'
import {url} from '../../../../constants'
import {convertToFormData} from '../../../../reuseFunctions/objectToFormData'
import {notify} from '../../../reuseComponent/toast'
import ToolTip from '../../../reuseComponent/Tooltips'
import { ConfirmModalContent } from '../../../reuseComponent/confirmModalComponent'
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import {findPermInArray} from "../../../../reuseFunctions/checkPermission";
import {permViewPO} from "../../../../permissions/productOverview";


class ResponsibleList extends PureComponent {

    state = {
        showHide: false,
        confirmModal: false,
        confirmChangeDepartment: false,
        currUser: false,
        currDepartment: null,
        search: '',
        selectedUser: null,
        selectedDepartment: null
    }

    refNode = React.createRef()
    searchFieldRef = React.createRef()


    showHideModal = (modalName, open) => {
        this.setState({
            [modalName]: open
        })
    }

    componentDidUpdate() {
        const activeUser = document.querySelector('.selected-li')
        if (activeUser)
            activeUser.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
    }

    showHide = e => {
        e.stopPropagation()
        const isShow = this.state.showHide
        this.setState(state => ({
            showHide: !state.showHide,
            search: '',
            selectedUser: state.showHide ? null : state.selectedUser
        }), () => {
            if (isShow)
                document.removeEventListener('keyup', this.handlePressArrows)
            else
                document.addEventListener('keyup', this.handlePressArrows)
            this.searchFieldRef.current && this.searchFieldRef.current.focus()
        })
    }

    handleSearch = e => {
        this.setState({
            search: e.target.value,
            selectedUser: null
        })
    }

    changeDepartment = (id, department) => {
        const {changeDepartInRow, setDetails, getHistory, activeTab} = this.props
        this.setState({
            showHide: false,
            currDepartment: null,
            confirmChangeDepartment: null,
            search: ''
        }, () => {
            adminInstance.put(`${url}/purchase_order/${id}/set_department`, convertToFormData({
                depId: department.id,
                poUrl: '/atw/purchase_orders'
            }))
                .then(res => {
                    notify('success', 'Department successfully changed')
                    const idUrl = this.props.match.params.id
                    if (res.data && res.data.id === id) {
                        setDetails(res.data)
                        if (idUrl && activeTab === '')
                            getHistory(activeTab)
                    }
                    changeDepartInRow(id, department)
                    this.setState({showHide: false})
                })
                .catch(err => {
                    console.log(err)
                })
        })

    }

    changeResponsible = (id, user) => {
        const {changeRow, setDetails, changeResponsible, responsibleUser, getHistory, activeTab} = this.props
        this.setState({
            showHide: false,
            confirmModal: false,
            selectedUser: null,
            search: ''
        }, () => {
            changeResponsible({
                id: id,
                currResponsible: responsibleUser,
                user: user,
                callback: (res) => {
                    const idUrl = this.props.match.params.id
                    if (res.data && res.data.id === id) {
                        setDetails(res.data)
                        if (idUrl && activeTab === '')
                            getHistory(activeTab)
                    }
                    changeRow(id, user)
                    this.setState({showHide: false})
                }
            })
        })
    }

    handleClickOutSide = (event) => {
        if (this.refNode.current && !this.refNode.current.contains(event.target)) {
            this.setState({
                showHide: false,
                search: '',
                selectedUser: null
            }, () => document.removeEventListener('keyup', this.handlePressArrows))
        }
    }

    handlePressArrows = e => {
        const {users, departments} = this.props
        const {selectedUser, selectedDepartment} = this.state
        if (e.keyCode === 38 && selectedUser > 0) {
            this.setState(state => ({
                selectedUser: state.selectedUser - 1,
                selectedDepartment: null
            }))
        }
        else if (e.keyCode === 38 && selectedUser === 0) {
            this.setState({
                selectedUser: null,
                selectedDepartment: departments.length - 1
            })
        }
        else if (e.keyCode === 40 && selectedUser !== null && selectedUser < users.length - 1) {
            this.setState(state => ({
                selectedUser: state.selectedUser + 1,
                selectedDepartment: null
            }))
        }
        else if (e.keyCode === 40 && selectedUser !== null && selectedUser === users.length - 1) {
            this.setState({
                selectedUser: null,
                selectedDepartment: 0
            })
        }
        else if (e.keyCode === 40 && selectedDepartment !== null && selectedDepartment < departments.length - 1) {
            this.setState(state => ({
                selectedUser: null,
                selectedDepartment: state.selectedDepartment + 1
            }))
        }
        else if (e.keyCode === 38 && selectedDepartment !== null && selectedDepartment > 0) {
            this.setState(state => ({
                selectedUser: null,
                selectedDepartment: state.selectedDepartment - 1
            }))
        }
        else if (e.keyCode === 38 && selectedDepartment !== null && selectedDepartment === 0) {
            this.setState(state => ({
                selectedUser: users.length - 1,
                selectedDepartment: null
            }))
        }
        else if (e.keyCode === 40 || e.keyCode === 38)
            this.setState({
                selectedUser: 0
            })
        else if (e.keyCode === 13 && selectedUser !== null) {
            const {responsibleUser, users, idPurchase, department} = this.props
            const {search} = this.state
            const filteredUsers = users.filter(user => {
                const compareString = user.name + user.secondName
                return compareString.toLowerCase().indexOf(search.toLowerCase()) >= 0
            })
            if (!responsibleUser && !department)
                this.changeResponsible(idPurchase, filteredUsers[selectedUser])
            else {
                this.setState({
                    currUser: filteredUsers[selectedUser],
                    confirmModal: true
                })
            }
        }
        else if (e.keyCode === 13 && selectedDepartment !== null) {
            const {search} = this.state
            const {departments, idPurchase, department, responsibleUser} = this.props
            const filteredDepartments = departments.filter(dep => {
                return dep.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
            })
            if (!department && !responsibleUser) {
                this.changeDepartment(idPurchase, filteredDepartments[selectedDepartment])
            }
            else {
                this.setState({
                    currDepartment: filteredDepartments[selectedDepartment],
                    confirmChangeDepartment: true
                })
            }
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutSide)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutSide)
    }

    onInputClick = () => {
        this.setState({
            showHide: false,
        });
    }

    render() {
        const {users, responsibleUser, idPurchase, purchaseOrderNumber, departments, department, roles, Spec} = this.props
        const {
            showHide,
            confirmModal,
            currUser,
            search,
            selectedUser,
            confirmChangeDepartment,
            currDepartment,
            selectedDepartment,
        } = this.state
        let initials = ''
        if (responsibleUser) {
            if (responsibleUser.initials)
                initials = responsibleUser.initials
            else {
                if (responsibleUser.name)
                    initials += responsibleUser.name[0].toUpperCase()
                if (responsibleUser.secondName)
                    initials += responsibleUser.secondName[0].toUpperCase()
            }
        }
        else if (department) {
            initials = ''
        }
        else {
            initials = 'UN'
        }
        const filteredUsers = users.filter(user => {
            const compareString = user.name + user.secondName
            return compareString.toLowerCase().indexOf(search.toLowerCase()) >= 0
        })
        const filteredDepartments = departments.filter(dep => {
            return dep.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        })

        const hasPermToChangeResponsible = findPermInArray(roles, permViewPO);

        return (
            <>
                <div className={`wrapper-department-column ${!hasPermToChangeResponsible ? 'bulk' : ''}`} ref={this.refNode} onClick={(e) => hasPermToChangeResponsible ? this.showHide(e) : null}>
                    {!Spec && responsibleUser && responsibleUser.department ?
                        <div className="departament-flex"><ToolTip
                            text={responsibleUser.department.name}
                        >
                        <span className="icon-purchase-status"
                              dangerouslySetInnerHTML={{__html: responsibleUser.department.icon}}></span>
                        </ToolTip>
                        </div>
                        : !Spec && department ? <div className="departament-flex">
                                <ToolTip
                                    text={department.name}
                                >
                        <span className="icon-purchase-status"
                              dangerouslySetInnerHTML={{__html: department.icon}}></span>
                                </ToolTip>
                            </div>
                            : null
                    }
                    <div className="responsible-wrapper">
                        <button className="initials-button" style={!Spec &&responsibleUser ? {
                            color: responsibleUser.initialsColor ? responsibleUser.initialsColor : '#00000'
                        } : {}}>
                            {Spec ? <Spec/> : null}
                            {Spec && responsibleUser && responsibleUser.department ?
                            <div className="departament-flex">
                        <span className={`icon-purchase-status ${Spec ? 'main-info-icon' : ''}`}
                              dangerouslySetInnerHTML={{__html: responsibleUser.department.icon}}></span>
                            </div>
                            : Spec && department ? <div className="departament-flex">
                        <span  className={`icon-purchase-status ${Spec ? 'main-info-icon' : ''}`}
                              dangerouslySetInnerHTML={{__html: department.icon}}></span>
                        </div>
                            : null}
                            {!Spec ? initials : ''}
                        </button>
                        <ul className={`list-users-responsible ${showHide ? '' : 'hide'}`}>
                            <div className="search-li"
                                onClick={(e) => this.showHide(e)}
                            >
                                <Form.Control
                                    onFocus={() => this.setState({
                                        selectedUser: null,
                                    })}
                                    onClick={() => this.onInputClick()}
                                    ref={this.searchFieldRef}
                                    placeholder="Search..."
                                    onChange={this.handleSearch}
                                    value={search}
                                />
                            </div>
                            {filteredUsers.map((user, index) => {
                                const compareString = user.name + user.secondName
                                return compareString.toLowerCase().indexOf(search.toLowerCase()) >= 0 ?
                                    <li onClick={(e) => {
                                        e.stopPropagation()
                                        if (!responsibleUser && !department)
                                            this.changeResponsible(idPurchase, user)
                                        else
                                            this.setState({
                                                currUser: user,
                                                confirmModal: true
                                            })
                                    }} key={user.id}
                                        className={selectedUser === index ? 'selected-li' : ''}
                                    >
                                        <div>{user.name} {user.secondName}</div>
                                    </li> : null
                            })}
                            {filteredDepartments.map((dep, index) => {
                                return <li
                                    className={selectedDepartment === index ? 'selected-li' : ''}
                                    key={dep.id}
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (!responsibleUser && !department)
                                            this.changeDepartment(idPurchase, dep)
                                        else
                                            this.setState({
                                                currDepartment: dep,
                                                confirmChangeDepartment: true
                                            })
                                    }}
                                >
                                    <div>{dep.name}</div>
                                </li>
                            })}
                        </ul>

                    </div>
                    <CustomModalWithChildren
                        open={confirmModal}
                        handleClose={e => this.showHideModal('confirmModal', false)}
                        handleCancel={e => this.showHideModal('confirmModal', false)}
                        handleConfirm={() => this.changeResponsible(idPurchase, currUser)}
                        options={{
                            centered: true
                        }}
                    >
                        <ConfirmModalContent
                            text={`change #${purchaseOrderNumber} PO's responsible to "${currUser ? 
                                currUser.name + ' ' + currUser.secondName : ''}"`}
                        />
                    </CustomModalWithChildren>
                    <CustomModalWithChildren
                        open={confirmChangeDepartment}
                        handleClose={e => this.showHideModal('confirmChangeDepartment', false)}
                        handleCancel={e => this.showHideModal('confirmChangeDepartment', false)}
                        handleConfirm={() => this.changeDepartment(idPurchase, currDepartment)}
                        options={{
                            centered: true
                        }}
                    >
                        <ConfirmModalContent
                            text={`change #${purchaseOrderNumber} PO's responsible to "${currDepartment ? currDepartment.name : ''}"`}
                        />
                    </CustomModalWithChildren>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles,
        users: state.usersReducer.users,
        details: state.purchaseOrdersReducer.detailsPurchase,
        activeTab: state.purchaseOrdersReducer.activeTabHistory
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDetails: (details) => dispatch(setDetailsPurchaseAction(details)),
        changeResponsible: obj => dispatch(changeResponsiblePerson(obj)),
        getHistory: () => dispatch(getHistory(''))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponsibleList))