import { LOGIN } from '../constants'

export const loginReducer = (state = { loading: false, user: {} }, action) => {
    switch (action.type) {
        case LOGIN.SET_LOADING: 
            return {
                ...state,
                loading: !state.loading
            }
        case LOGIN.SET_USER: 
            return {
                ...state,
                user: action.payload
            }    
        case LOGIN.LOG_OUT: 
            return {
                ...state,
                user: null
            }    
        default:
            return state
    }
}