import React from 'react'
import Tooltip from '../../../../reuseComponent/Tooltips'

const StatusChange = props => {
    const renderFlags = () => {
        if (props && props.read) {
            return (
                <>
                    <Tooltip text="Change status to Unread">
                        <div onClick={() => props.toggleStatus(props.read, props.id)}>
                            <svg viewBox="0 0 24 24">
                                <path fill="#B0BEC5" d="M21.03 6.29L12 .64L2.97 6.29C2.39 6.64 2 7.27 2 8V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 7.27 21.61 6.64 21.03 6.29M20 18H4V10L12 15L20 10V18M12 13L4 8L12 3L20 8L12 13Z" />
                            </svg>
                        </div>
                    </Tooltip>
                </>
            )
        } else if (props && !props.read) {
            return (
                <>
                    <Tooltip text="Change status to Read">
                        <div onClick={() => props.toggleStatus(props.read, props.id)}>
                            <svg viewBox="0 0 24 24">
                                <path fill="#FF9800" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
                            </svg>
                        </div>
                    </Tooltip>
                </>
            )
        }
    }
    return (
        <div className="status-toggler">
            {renderFlags()}
        </div>
    )
}

export default StatusChange