import React from "react";
import { useSelector } from 'react-redux'
import { Button, Form } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import classNames from 'classnames'
import { customPalletMethod, inboundMethod, inventoryMethod } from '../../../../inventory/create/choseMethodCreate'

const BodyForm = props => {
  const {
    handleSubmit,
    values,
    touched,
    handleChange,
    errors,
    handleCancel,
    setFieldValue,
    buttonPressed,
    isPacks,
    updatePackType,
    isCreate,
  } = props;

  const inboundType = useSelector(state => state.purchaseOrdersReducer.detailsPurchase.inboundType)

  return (
    <Form noValidate onSubmit={handleSubmit} className="custom-package-form">
      <Form.Group>
        <Form.Label>Title</Form.Label>
        <Form.Control
          name="title"
          value={values.title}
          onBlur={e => setFieldValue('title', e.target.value.trim())}
          onChange={handleChange}
          isInvalid={errors.title && touched.title}
        />
        <Form.Control.Feedback type="invalid">
          {errors.title}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <div className="form-element">
          <TextareaAutosize
            type="text"
            className="form-control"
            onBlur={e => setFieldValue('description', e.target.value.trim())}
            name="description"
            style={{ resize: "none" }}
            onChange={handleChange}
            value={values.description}
          />
          <Form.Control.Feedback type="invalid">
            {errors.description}
          </Form.Control.Feedback>
        </div>
      </Form.Group>
      {inboundType === 'Deep' && isCreate && <Form.Group>
        <Form.Label>Pack type</Form.Label>
         <div className="method-buttons-wrapper">
          <Button
            variant="primary"
            className={classNames("header-tab-button", {"cancel": isPacks})}
            onClick={() => updatePackType(false)}
          >
            Scannable
          </Button>
          <Button
            variant="primary"
            className={classNames("header-tab-button", {"cancel": !isPacks})}
            onClick={() => updatePackType(true)}
          >
            Custom amount
          </Button>
        </div>
      </Form.Group>}
      {isPacks && <Form.Group>
        <Form.Label>Packs Count</Form.Label>
        <Form.Control
          name="countPack"
          value={values.countPack}
          onChange={handleChange}
          isInvalid={errors.countPack && touched.countPack}
        />
        <Form.Control.Feedback type="invalid">
          {errors.countPack}
        </Form.Control.Feedback>
      </Form.Group>}
      <Form.Group className="footer-form footer-form-custom">
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={buttonPressed}>
          Save
        </Button>
      </Form.Group>
    </Form>
  );
};

export default BodyForm;
