import React , { PureComponent } from 'react'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg'
import Tooltip from '../../../reuseComponent/Tooltips'
import Select from '../../../reuseComponent/Select/select-form'



class Condition extends PureComponent{
    render(){
        const { condition,removeCondition,setFieldValue,fields } = this.props
        const activeField = fields.find(field => field.value === condition.field)
        // console.log(fields)
        return (
            <div className="offer-condition-row">
                <div className="offer-condition-col field">
                    <Select
                        value={activeField ? activeField : null}
                        options={fields}
                        placeholder="Select condition"
                        onChange={obj => {
                            setFieldValue('values',[])
                            setFieldValue('field',obj.value)
                        }}
                    />
                </div>
                <div className="offer-condition-col val">
                    <Select
                        isMulti
                        value={condition.values}
                        placeholder="Select value"
                        options={activeField ? activeField.values : []}
                        onChange={values => setFieldValue('values',values ? values : [])}
                    />
                </div>
                <div className="action-condition">
                    <Tooltip text="Delete condition">
                        <DeleteIcon onClick={removeCondition} className="standart-svg"/>
                    </Tooltip>
                </div>
            </div>
        )
    }
}


export default Condition