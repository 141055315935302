import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import Form from '../form'
import { editChannelItemRequest } from '../../../../../actions/commodity-groups'


class CreateForm extends PureComponent{

    handleSubmit = values => {
        const { handleCancel,editChannel,id } = this.props
        // handleCancel && handleCancel ()
        id && editChannel && editChannel(id,values,handleCancel)

    }

    render(){
        const { handleCancel,value,inputs } = this.props
        return (
            <Form
                initValues={{
                    value: value,
                    inputs: inputs
                }}
                handleSubmit={this.handleSubmit}
                handleCancel={handleCancel}
            />
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editChannel: (id,values,callback) => dispatch(editChannelItemRequest(id,values,callback))
    }
}

export default connect(null,mapDispatchToProps)(CreateForm)