import React, {PureComponent} from 'react'
import {getBrandById} from '../../../../../services/brands'
import FormikBrand from '../form'
import Preloader from '../../../../reuseComponent/preloader/preloader'
import { notify } from '../../../../reuseComponent/toast'

class EditFormBrand extends PureComponent {

    state = {
        isLoading: true,
        brand: {}
    }

    componentDidMount() {
        const {id} = this.props
        if (id) {
            getBrandById(id)
                .then(res => {
                    this.setState({
                        isLoading: false,
                        brand: {
                            name: res.data.name,
                            inputs: res.data.synonyms
                        }
                    })
                })
                .catch(err => {
                    notify('error','Oops something went wrong')
                })
        }
    }

    render() {
        const {isLoading,brand} = this.state
        const { handleSubmit,handleCancel } = this.props
        return (
            isLoading ? <Preloader/> : <FormikBrand
                initValues={brand}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
            />
        )
    }
}

export default EditFormBrand