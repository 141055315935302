import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import { checkIfObjectEmptyValues } from '../../../../../../reuseFunctions/objectFunctions'

class AccountingView extends PureComponent {
    render() {
        const {details} = this.props
        const dateFormat = 'MMMM DD, YYYY'
        return (
            !(checkIfObjectEmptyValues({
                financeStatus: details.financeStatus,
                accountingPaymentDate:  details.accountingPaymentDate,
                accountingCreditPeriod: details.accountingCreditPeriod,
            })) ? <div>
                    {details.accountingCreditPeriod
                        ? <div className="row-form">
                            <div className="label">Credit Period (days):</div>
                            <div className="value">
                                {details.accountingCreditPeriod}
                            </div>
                        </div>
                        : null
                    }
                    {details.placementDate && details.accountingCreditPeriod
                        ? <div className="row-form">
                            <div className="label">To be paid by:</div>
                            <div className="value">
                                {moment(details.placementDate).add(details.accountingCreditPeriod, 'days').format(dateFormat)}
                            </div>
                        </div>
                        : null
                    }
                    {details.financeStatus
                        ? <div className="row-form">
                            <div className="label">Payment Status:</div>
                            <div className="value">
                                {details.financeStatus} {details.prepaidPercentage && (details.financeStatus === 'Prepayment' || details.financeStatus === 'Partly paid')
                                    ? details.prepaidPercentage + '%'
                                    : ''
                                }
                            </div>
                        </div>
                        : null
                    }
                    {details.accountingPaymentDate
                        ? <div className="row-form">
                            <div className="label">Payment date:</div>
                            <div className="value">
                                {moment(details.accountingPaymentDate).format(dateFormat)}
                            </div>
                        </div>
                        : null
                    }
                </div>
                : <div className="no-data-to-display">
                    No data to display...
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

export default connect(mapStateToProps, null)(AccountingView)