import { combineReducers } from 'redux'
import { loginReducer } from './loginReducer'
import { rolesReducer } from './roles'
import { usersReducer } from './users'
import { supplierReducer } from './suppliers'
import { purchaseOrdersReducer } from './purchase-orders'
import { adminHeaderReducer } from './admin-header'
import { adminGlobal } from './admin-global'
import { departmentsReducer } from './departments'
import { poDetailsReducer } from './detailsPOReducer'
import { dividingReducer } from './dividing'
import { notificationsReducer } from './notifications'
import { releaseNotesReducer } from './releaseNotesReducer'
import { commodityGroupsReducer } from './commodity-groups'
import { sizeDictionaryReducer } from './size-dictionary'
import { poSmartFiltersReducer } from './poFiltersReducer'
import { analysisToolReducer } from "./analysisToolReducer"
import { serviceWorkerReducer } from './serviceWorkerReducer'
import { smartFilterReducer } from './smart-filter'
import { stowingReducer } from './stowing'
import { serviceDeskReducer } from './serviceDesk'
import { customersReducer } from './customers'
import { inboundingReportReducer } from "./inboundingReportReducer";
import { printersReducer } from "./printersReducer";
import { offersReducer } from "./offersReducer";
import { inventoryReducer } from './inventoryReducer'
import { ticketsReducer } from './tickets'

export default combineReducers({
    loginReducer,
    usersReducer,
    rolesReducer,
    supplierReducer,
    purchaseOrdersReducer,
    adminHeaderReducer,
    adminGlobal,
    departmentsReducer,
    poDetailsReducer,
    dividingReducer,
    notificationsReducer,
    releaseNotesReducer,
    commodityGroupsReducer,
    sizeDictionaryReducer,
    poSmartFiltersReducer,
    analysisToolReducer,
    serviceWorkerReducer,
    smartFilterReducer,
    stowingReducer,
    serviceDeskReducer,
    customersReducer,
    inboundingReportReducer,
    printersReducer,
    offersReducer,
    inventoryReducer,
    ticketsReducer,
})