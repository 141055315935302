import React from 'react'
import CustomDropMenu from '../CustomDropMenu'
import { Dropdown,Button } from 'react-bootstrap'

export default class DropDownMenu extends React.PureComponent{
    static defaultProps = {
        id : 0,
        ToggleButton : Button,
        CustomMenu : CustomDropMenu,
        ToggleContent : '',
        title : '',
        className : '',
        onToggle: show => {}
    }

    render() {
        const { id,CustomMenu,ToggleButton,ToggleContent,title,className,onToggle } = this.props
        return(
            <Dropdown onToggle={onToggle}>
                <Dropdown.Toggle as={ToggleButton} title={title} id={`dropdown-custom-components-${id}`}>
                    <ToggleContent/>
                </Dropdown.Toggle>
                <Dropdown.Menu className={className} as={CustomMenu}>
                    {this.props.children}
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}


