import React, { PureComponent } from 'react'
import { OverlayTrigger,Tooltip } from 'react-bootstrap'

class CustomTooltip extends PureComponent{

    static defaultProps = {
        text : 'hint', // text tooltip
        placement: 'top', // position tooltip
        delay: 200 // delay before showing
    }

    render(){
        const { text,placement,delay } = this.props
        return (
            <OverlayTrigger
                delay={delay}
                trigger="hover"
                placement={placement}
                popperConfig={{
                    modifiers: {
                        preventOverflow: {
                            enabled: false
                        },
                        hide: {
                            enabled: false
                        }
                    }
                }}
                overlay={(props) => {
                    return <Tooltip {...props} show={props.show.toString()}>{text}</Tooltip>
                }}
            >
                {this.props.children}
            </OverlayTrigger>
        )
    }
}

export default CustomTooltip