import React from 'react'
import { Switch, Route } from 'react-router-dom'
import EmailResset from './email-account'
import ConfirmPassword from './confirm-password'
import { Container } from 'react-bootstrap'

const ForgotPasswordIndex = props => (
    <Container>
        <Switch>
            <Route exact path="/reset-password" component={EmailResset} />
            <Route exact path="/reset-password/confirm/:token" component={ConfirmPassword} />
        </Switch>
    </Container>
)

export default ForgotPasswordIndex