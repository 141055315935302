import { SERVICE_DESK } from "../constants";

const initState = {
  types: [],
  statuses: [],
  priorities: [],
  request: {
    status: "",
    shortDescription: "",
    typeId: "",
    title: "",
    // purpose: "",
    files: [],
    priority: '',
    comment: [{ text: "" }],
    author: { name: "", secondName: "" }
  },
  activeTabHistory: "CHRONOLOGY",
  history: [],
  isLoading: false,
  isLoadingHistory: false
};

export const serviceDeskReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_DESK.SET_ACTIVE_PRIORITY: {
      return {
        ...state,
        activePriority: payload
      };
    }

    // case SERVICE_DESK.SET_SERVICE_DESK_PRIORITIES: {
    //   return {
    //     ...state,
    //     priorities: payload
    //   };
    // }
    // case SERVICE_DESK.SET_SERVICE_DESK_STATUSES: {
    //   return {
    //     ...state,
    //     statuses: payload
    //   };
    // }
    // case SERVICE_DESK.SET_SERVICE_DESK_TYPES: {
    //   return {
    //     ...state,
    //     types: payload
    //   };
    // }
    case SERVICE_DESK.SET_SERVICE_DESK_REQUEST: {
      return {
        ...state,
        request: payload
      };
    }
    case SERVICE_DESK.CLEAR_REQUEST: {
      return {
        ...state,
        request: {
          status: "",
          shortDescription: "",
          typeId: "",
          title: "",
          // purpose: "",
          priority: "",
          files: [],
          comment: [{ text: "" }]
        }
      };
    }
    case SERVICE_DESK.IS_LOADING_SERVICE_DESK: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case SERVICE_DESK.SET_HISTORY_SD: {
      return {
        ...state,
        history: action.payload
      };
    }
    case SERVICE_DESK.IS_LOADING_SERVICE_DESK_HISTORY: {
      return {
        ...state,
        isLoadingHistory: action.payload
      };
    }
    case SERVICE_DESK.CHANGE_ACTIVE_HISTORY_SD_TAB: {
      return {
        ...state,
        activeTabHistory: action.payload
      };
    }
    case SERVICE_DESK.ADD_HISTORY_SD_ITEM: {
      return {
        ...state,
        history: [...state.history, action.payload]
      };
    }
    case SERVICE_DESK.ADD_HISTORY_SD_FILES: {
      return {
        ...state,
        history: [...state.history, ...action.payload]
      };
    }
    case SERVICE_DESK.SET_STATUS: {
      return {
        ...state,
        request: { ...state.request, status: action.payload }
      };
    }
    default: {
      return state;
    }
  }
};
