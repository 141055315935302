import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Form from '../form'
import {setData} from '../../../../../actions/admin-header'
import {createCategoryInDictionary} from '../../../../../actions/commodity-groups'
import {notify} from '../../../../reuseComponent/toast'
import {changeRoute} from '../../../../../reuseFunctions/changeRoute'
import {history} from '../../../../../history'
import {getRelatedCategoriesValues} from '../../../../../actions/commodity-groups'
import Preloader from '../../../../reuseComponent/preloader/preloader'
import StandartLeftPanner from '../../../../reuseComponent/Header/standartLeftPannel';


class CreateCategory extends PureComponent {

    state = {
        loading: false,
        values: null
    }

    componentDidMount() {
        const {setHeaderData, getValues} = this.props
        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'New CG3',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#000000"
                          d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z"/>
                </svg>)),
            },
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: null,
        })

        getValues && getValues()
    }

    handleSubmit = values => {
        // values.title = values.title ? values.title.trim() : ''
        this.setState({
            loading: true,
            values: values
        }, () => {
            createCategoryInDictionary(values)
                .then(res => {
                    if (typeof res.data === 'string') {
                        this.setState({
                                loading: false
                            }, () => notify('error', 'Value already exist')
                        );
                    }
                    else{
                        changeRoute('/atw/dictionaries/cg3', history)
                        notify('success', 'Category successfully created')
                    }


                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                    this.setState({loading: false})
                })

        })

    }

    render() {
        const {loading, values} = this.state
        const {isLoadingValues} = this.props
        return (
            loading || isLoadingValues ? <Preloader/> : <Form
                handleSubmit={this.handleSubmit}
                initialValues={values ? values : {
                    value: '',
                    inputs: [],
                    related: []
                }}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoadingValues: state.commodityGroupsReducer.isLoadingValues
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setHeaderData: (data) => dispatch(setData(data)),
        getValues: () => dispatch(getRelatedCategoriesValues())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory)