import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Tooltip from '../../../../../../reuseComponent/Tooltips/index'
import moment from 'moment'
import {changeCompleteStatus, markCompleteTask} from '../../../../../../../services/tasks'
import { editTaskAction, closeOpenInbound, closeOpenSection } from '../../../../../../../actions/purchase-orders'
import IconButtonMaterial from '../../../../../../reuseComponent/Buttons/ButtonWithMaterialIcon/index'
import {DropdownItem} from 'react-bootstrap'
import DropdownMenu from '../../../../../../reuseComponent/DropDown/DropDownWithChildren/index'
import {findPermInArray} from '../../../../../../../reuseFunctions/checkPermission';
import TaskAssignDropdown from './taskAssignDropdown';
import {
    permTaskChronology,
} from '../../../../../../../permissions/productOverview';
import {notify} from "../../../../../../reuseComponent/toast";


class TaskItem extends PureComponent {

    handleMarkCompleteTask = (task) => {
        const { closeOpenInbound, closeOpenSection, editTaskAction } = this.props;
        if (task.inbound) {
            closeOpenInbound(window.location.origin + '/atw/purchase-orders', task.id)
        } else if (task.transport || task.accounting) {
            changeCompleteStatus(task && task.id ? task.id : null)
                .then(res => {
                    editTaskAction(res.data[1] ? {...res.data[1]} : {});
                })
            closeOpenSection(task.transport ? "transport" : "accounting");
        } else {
            markCompleteTask(task.order.id, task.id, task).then(res => {
                editTaskAction(res.data[1] ? {...res.data[1]} : {});
                if (task.complete) {
                    notify('success', 'Ticket incompleted')
                } else {
                    notify('success', 'Ticket completed')
                }
            });
        }
    }

    render() {
        const {task, editTask, deleteTask, roles, currUser, departments, details} = this.props

        const userId = currUser && currUser.info && currUser.info.id ? currUser.info.id : -1;
        const isAssignedToUser = task.executor && task.executor.id === userId;
        // console.log(userId,isAssignedToUser)
        const isCreatedByUser = task && task.author && task.author.id === userId;
        // const hasPermToComplete = /*(findPermInArray(roles, permViewChronology) &&*/ isAssignedToUser
        const hasPermToComplete = findPermInArray(roles, permTaskChronology) ||  isAssignedToUser;
        const hasPermToEdit = (findPermInArray(roles, permTaskChronology));
        const completable = (task.inbound && !task.complete && details && details.actualPackagesCount == null) ? false : true;
        return (
            <div className={`history-item task ${task.complete ? 'completed-task' : ''}`}>
                <div className="row-action">
                    <div className="info-task">
                        <span className="status" onClick={() => {
                        }}>
                            {(hasPermToComplete && completable) ?
                                <Tooltip
                                    text={task.complete ? 'Mark ticket incomplete' : 'Mark ticket complete'}
                                >
                                <span onClick={() => this.handleMarkCompleteTask(task)}
                                      className={`complete-icon ${task.complete ? 'complete' : ''} with-hover`}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="#000000"
                                              d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"/>
                                    </svg>
                                </span>
                                </Tooltip>
                                :
                                <span
                                    className={`complete-icon ${task.complete ? 'complete' : ''}`}>
                                    <svg viewBox="0 0 24 24">
                                        <path fill="#000000"
                                              d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"/>
                                    </svg>
                                </span>
                            }
                        </span>
                        <span className="task-name">{task.title}</span>
                    </div>
                    <div className="deadline-executor">
                        <span className="deadline-value">
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000"
                                      d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z"/>
                            </svg>
                            {moment(task.dueDate).isValid() ? moment(task.dueDate).format('MMM D') : "No due date"}
                        </span>
                        {task.executor && task.executor.name &&
                        <>
                            {hasPermToEdit ?
                                <TaskAssignDropdown
                                    departments={departments}
                                    task={task}
                                    isExecuror={true}
                                />
                                : <>
                                    {task.executor.department && <Tooltip text={task.executor.department.name}>
                                        <span className="icon-department"
                                              dangerouslySetInnerHTML={{__html: task.executor.department.icon}}></span>
                                    </Tooltip>}
                                    <Tooltip
                                        text={`${task.executor.name} ${task.executor.secondName}`}
                                    >
                                <span className={`executor-value`} style={{
                                    color: task.executor.initialsColor
                                }}>{task.executor.initials}</span>
                                    </Tooltip>
                                </>}

                        </>
                        }
                        {task.department && (findPermInArray(roles, permTaskChronology) ? <TaskAssignDropdown
                            departments={departments}
                            task={task}
                            isExecuror={false}
                        /> : <Tooltip text={task.department.name}>
                            <span className="icon-department"
                                  dangerouslySetInnerHTML={{__html: task.department.icon}}></span>
                        </Tooltip>)
                        }

                        {hasPermToEdit && <DropdownMenu
                            ToggleButton={IconButtonMaterial}
                            ToggleContent={() => <svg viewBox="0 0 24 24">
                                <path fill="#000000" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                            </svg>}
                        >
                            <>

                                <DropdownItem onClick={() => editTask(task.id, false)}>Edit</DropdownItem>
                                <DropdownItem onClick={() => deleteTask(task.id, task.title)}>Delete</DropdownItem>
                            </>
                        </DropdownMenu>
                        }
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{__html: task.description}}></div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles,
        currUser: state.usersReducer.currUser,
        details: state.purchaseOrdersReducer.detailsPurchase,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeOpenInbound: (url, taskId) => dispatch(closeOpenInbound(url, null, taskId)),
        editTaskAction: data => dispatch(editTaskAction(data)),
        closeOpenSection: section => dispatch(closeOpenSection(section)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskItem)