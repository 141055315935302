import React from 'react'

const ConfirmModalContent = ({text}) => {
    return <h6 className="center-h">
        Are you sure you want to {text}?
    </h6>
}

ConfirmModalContent.defaultProps = {
    text: "do this"
}

export {
    ConfirmModalContent
}