import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {formatPrice, thousandSeparator} from '../../../../../reuseFunctions/formatValue'
import {getPoFiltersAsyncAction, createPoFilterAsyncAction} from '../../../../../actions/poFilters'
import FiltersList from './filters-list'
import ModalFilter from './modalFilter'
import FormFilter from './form'
import {notify} from '../../../../reuseComponent/toast'
import EditPoFilterComponent from './editFilter'
import {editPoFilter, removePoFilter, setActiveFilter} from '../../../../../services/poSmartFilter'
import {Button} from 'react-bootstrap'
import {changeRoutePush} from '../../../../../reuseFunctions/changeRoute'
import {history} from '../../../../../history'
import {permCreatePO, permFinanceInfoOverview} from '../../../../../permissions/productOverview'
import {findPermInArray} from '../../../../../reuseFunctions/checkPermission'
import {getPoOverview} from '../../../../../actions/users'
import {ConfirmModalContent} from '../../../../reuseComponent/confirmModalComponent'
import {TotalDropDown} from './TotalDropdown'
import Tooltip from '../../../../reuseComponent/Tooltips'
import CustomModalWithChildren from "../../../../reuseComponent/modal/modalWithChildren";
import CreatePo from "../../createPurchaseOrder";


class PurchaseOrdersToolbar extends PureComponent {

    state = {
        openEdit: false,
        openCreate: false,
        openDelete: false,
        removedTitle: '',
        currentFilter: null,
        isLoading: false,
        isShowColumns: false,
        modalCreate: false,
        modalSupplier: false,
        supplier: null,
    }

    listWrapperRef = React.createRef()

    hideDeleteModal = () => this.setState({openDelete: false, currentFilter: null})

    showDeleteModal = (idFilter, removedTitle) => this.setState({
        openDelete: true,
        currentFilter: idFilter,
        removedTitle,
    })

    hideCreateModal = () => this.setState({openCreate: false, currentFilter: null, isLoading: false})

    showCreateModal = () => this.setState({openCreate: true, currentFilter: null})

    hideEditModal = () => this.setState({openEdit: false, currentFilter: null, isLoading: false})

    showEditModal = (idFilter) => this.setState({openEdit: true, currentFilter: idFilter})

    setLoading = (isLoading, callback) => this.setState({isLoading: isLoading}, () => callback && callback())

    componentDidMount() {
        const {getFilters, getPoOverview} = this.props
        getPoOverview && getPoOverview()
        getFilters && getFilters()
        document.addEventListener('click', this.handleClickOutSide)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutSide)
    }

    handleClickOutSide = (event) => {
        if (this.listWrapperRef.current && !this.listWrapperRef.current.contains(event.target)) {
            this.setState({
                isShowColumns: false
            })
        }
    }

    render() {
        const {
            total,
            match,
            createFilter,
            filters,
            columns,
            pagination,
            paginationPosition,
            roles,
            getPoOverview,
            totalNetKg,
            totalQuantity,
            totalPcsEuro,
            avgPcsEuro,
            avgKgEuro,
            totalKgEuro
        } = this.props
        const {
            openEdit,
            openCreate,
            openDelete,
            currentFilter,
            isLoading,
            isShowColumns,
            removedTitle,
            modalCreate,
        } = this.state
        // console.log(this.props)
        let isAllShown = true
        columns.forEach(column => {
            if (!!column.hidden === true)
                isAllShown = false
        })

        if (!findPermInArray(roles, permCreatePO) && match.params.id === 'new') {
            changeRoutePush('/atw/purchase-orders/', history)
        }

        return (
            <div className={`purchase-order-toolbar ${match.params.id ? 'opened-purchase-toolbar' : ''}`}>
                <div className="part-toolbar filters-toolbar columns-toolbar">
                    {findPermInArray(roles, permCreatePO) &&
                    <Button
                        // onClick={() => changeRoutePush('/atw/purchase-orders/new', history)}
                        onClick={() => this.setState({
                            modalCreate: true
                        })}
                            className="with-icon add-btn">
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                        </svg>
                        Add PO
                    </Button>
                    }
                    <div ref={this.listWrapperRef} className={`wrapper-column-list ${isShowColumns ? 'open' : ''}`}>
                        <Button className="with-icon" onClick={() => this.setState(state => ({
                            isShowColumns: !state.isShowColumns
                        }))}>
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000" d="M16,5V18H21V5M4,18H9V5H4M10,18H15V5H10V18Z"/>
                            </svg>
                            Columns
                        </Button>
                        <ul className="column-list">
                            <li
                                onClick={() => this.props.onColumnsAllChanged(isAllShown)}
                            >
                                <input
                                    type="checkbox"
                                    onChange={e => {
                                    }}
                                    checked={isAllShown}
                                />
                                {isAllShown ? 'Unselect' : 'Select'} All
                            </li>
                            {columns.filter(column => column.field !== 'actions').map(column => {
                                return <li
                                    key={column.field}
                                    onClick={() => this.props.onColumnsChanged(column, !!!column.hidden)}
                                >
                                    <input
                                        type="checkbox"
                                        onChange={e => {
                                        }}
                                        checked={!!!column.hidden}
                                    />
                                    {column.field !== 'invoice' ? column.title : 'Invoice'}
                                </li>
                            })}
                        </ul>
                    </div>
                    <FiltersList
                        setActiveFilter={id => {
                            setActiveFilter(id)
                                .then(res => {
                                    getPoOverview()
                                    this.props.onSearchChanged('')
                                    this.props.getFilters()
                                })
                        }}
                        openCreateModal={this.showCreateModal}
                        openEditModal={this.showEditModal}
                        openDeleteModal={this.showDeleteModal}
                    />
                </div>
                <div className="total-info-toolbar-po">
                    {findPermInArray(roles, permFinanceInfoOverview) && <>
                        <strong>
                            <Tooltip text="Total sum, €">
                                <span>Σ</span>
                            </Tooltip>
                            {thousandSeparator(formatPrice(total))} €</strong>
                        <TotalDropDown
                            options={[{
                                label: <Tooltip text="Total values per pcs, €">
                                    <b>
                                        <svg className="icon-value" viewBox="0 0 24 24">
                                            <path fill="currentColor"
                                                  d="M20.5,11H19V7C19,5.89 18.1,5 17,5H13V3.5A2.5,2.5 0 0,0 10.5,1A2.5,2.5 0 0,0 8,3.5V5H4A2,2 0 0,0 2,7V10.8H3.5C5,10.8 6.2,12 6.2,13.5C6.2,15 5,16.2 3.5,16.2H2V20A2,2 0 0,0 4,22H7.8V20.5C7.8,19 9,17.8 10.5,17.8C12,17.8 13.2,19 13.2,20.5V22H17A2,2 0 0,0 19,20V16H20.5A2.5,2.5 0 0,0 23,13.5A2.5,2.5 0 0,0 20.5,11Z"/>
                                        </svg>
                                        <span className="sigma">Σ</span>
                                    </b>
                                </Tooltip>,
                                key: 'totalSum',
                                value: <span className="value">{thousandSeparator(formatPrice(totalPcsEuro))} €</span>
                            }, {
                                label: <>
                                    <svg className="icon-value" viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                              d="M20.5,11H19V7C19,5.89 18.1,5 17,5H13V3.5A2.5,2.5 0 0,0 10.5,1A2.5,2.5 0 0,0 8,3.5V5H4A2,2 0 0,0 2,7V10.8H3.5C5,10.8 6.2,12 6.2,13.5C6.2,15 5,16.2 3.5,16.2H2V20A2,2 0 0,0 4,22H7.8V20.5C7.8,19 9,17.8 10.5,17.8C12,17.8 13.2,19 13.2,20.5V22H17A2,2 0 0,0 19,20V16H20.5A2.5,2.5 0 0,0 23,13.5A2.5,2.5 0 0,0 20.5,11Z"/>
                                    </svg>
                                    <span>AVG</span>
                                    </>,
                                key: 'avgPc',
                                value: <span className="value">{thousandSeparator(formatPrice(avgPcsEuro))} €</span>
                            }, {
                                label: <Tooltip text="Total amount of pcs">
                                    <svg className="icon-value" viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                              d="M20.5,11H19V7C19,5.89 18.1,5 17,5H13V3.5A2.5,2.5 0 0,0 10.5,1A2.5,2.5 0 0,0 8,3.5V5H4A2,2 0 0,0 2,7V10.8H3.5C5,10.8 6.2,12 6.2,13.5C6.2,15 5,16.2 3.5,16.2H2V20A2,2 0 0,0 4,22H7.8V20.5C7.8,19 9,17.8 10.5,17.8C12,17.8 13.2,19 13.2,20.5V22H17A2,2 0 0,0 19,20V16H20.5A2.5,2.5 0 0,0 23,13.5A2.5,2.5 0 0,0 20.5,11Z"/>
                                    </svg>
                                </Tooltip>,
                                key: 'totalPcs',
                                value: totalQuantity
                            }]}
                        />
                        <TotalDropDown
                            options={[{
                                label: <Tooltip text="Total sum per kg">
                                    <b>
                                        <svg className="icon-value" viewBox="0 0 24 24">
                                            <path fill="currentColor"
                                                  d="M12,3A4,4 0 0,1 16,7C16,7.73 15.81,8.41 15.46,9H18C18.95,9 19.75,9.67 19.95,10.56C21.96,18.57 22,18.78 22,19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19C2,18.78 2.04,18.57 4.05,10.56C4.25,9.67 5.05,9 6,9H8.54C8.19,8.41 8,7.73 8,7A4,4 0 0,1 12,3M12,5A2,2 0 0,0 10,7A2,2 0 0,0 12,9A2,2 0 0,0 14,7A2,2 0 0,0 12,5M6,11V19H8V16.5L9,17.5V19H11V17L9,15L11,13V11H9V12.5L8,13.5V11H6M15,11C13.89,11 13,11.89 13,13V17C13,18.11 13.89,19 15,19H18V14H16V17H15V13H18V11H15Z"/>
                                        </svg>
                                        <span className="sigma">Σ</span>
                                    </b>
                                </Tooltip>,
                                key: 'totalNet',
                                value: <span className="value">{thousandSeparator(formatPrice(totalKgEuro))} €</span>
                            }, {
                                label: <>
                                    <svg className="icon-value" viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                              d="M12,3A4,4 0 0,1 16,7C16,7.73 15.81,8.41 15.46,9H18C18.95,9 19.75,9.67 19.95,10.56C21.96,18.57 22,18.78 22,19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19C2,18.78 2.04,18.57 4.05,10.56C4.25,9.67 5.05,9 6,9H8.54C8.19,8.41 8,7.73 8,7A4,4 0 0,1 12,3M12,5A2,2 0 0,0 10,7A2,2 0 0,0 12,9A2,2 0 0,0 14,7A2,2 0 0,0 12,5M6,11V19H8V16.5L9,17.5V19H11V17L9,15L11,13V11H9V12.5L8,13.5V11H6M15,11C13.89,11 13,11.89 13,13V17C13,18.11 13.89,19 15,19H18V14H16V17H15V13H18V11H15Z"/>
                                    </svg>
                                    <span>AVG</span>
                                </>,
                                key: 'avgKg',
                                value: <span className="value">{thousandSeparator(formatPrice(avgKgEuro))} €</span>
                            },{
                                label: <Tooltip text="Total Net weight, kg">
                                    <svg className="icon-value" viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                              d="M12,3A4,4 0 0,1 16,7C16,7.73 15.81,8.41 15.46,9H18C18.95,9 19.75,9.67 19.95,10.56C21.96,18.57 22,18.78 22,19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19C2,18.78 2.04,18.57 4.05,10.56C4.25,9.67 5.05,9 6,9H8.54C8.19,8.41 8,7.73 8,7A4,4 0 0,1 12,3M12,5A2,2 0 0,0 10,7A2,2 0 0,0 12,9A2,2 0 0,0 14,7A2,2 0 0,0 12,5M6,11V19H8V16.5L9,17.5V19H11V17L9,15L11,13V11H9V12.5L8,13.5V11H6M15,11C13.89,11 13,11.89 13,13V17C13,18.11 13.89,19 15,19H18V14H16V17H15V13H18V11H15Z"/>
                                    </svg>
                                </Tooltip>,
                                key: 'totalNetKg',
                                value: thousandSeparator(formatPrice(totalNetKg))
                            }]}
                        />
                    </>
                    }
                    {/*<span className="separator-avg-total">|</span>*/}
                    {/*<strong>AVG: {formatPrice(avg)} €</strong>*/}
                    {/*<span className="separator-avg-total">|</span>*/}
                    {/*<strong>Total Pcs: {totalQuantity}</strong>*/}
                    {/*<span className="separator-avg-total">|</span>*/}
                    {/*<strong>Total Net, kg: {formatPrice(totalNetKg)} kg</strong>*/}
                </div>
                {paginationPosition === 'toolbar'
                &&
                <div className="pagination-toolbar">
                    {pagination}
                </div>
                }
                <ModalFilter
                    open={openCreate}
                    handleClose={this.hideCreateModal}
                    Content={() => <FormFilter
                        handleSubmit={values => createFilter({
                            values: values,
                            successCallback: () => {
                                this.props.onSearchChanged('')
                                this.props.getFilters()
                                this.hideCreateModal()
                                getPoOverview()
                                notify('success', 'Filter successfully created')
                            },
                            errorCallback: () => notify('error', 'Oops something went wrong')
                        })}
                        handleCancel={this.hideCreateModal}
                        initValues={{
                            name: '',
                            criterias: [],
                            orCriterias: []
                        }}
                    />}
                />
                <ModalFilter
                    open={openEdit}
                    handleClose={this.hideEditModal}
                    Content={() => <EditPoFilterComponent
                        setLoading={this.setLoading}
                        isLoading={isLoading}
                        idFilter={currentFilter}
                        handleCancel={this.hideEditModal}
                        handleSubmit={values => {
                            this.setLoading(true, () => {
                                editPoFilter(currentFilter, values)
                                    .then(res => {
                                        getPoOverview()
                                        this.props.onSearchChanged('')
                                        this.props.getFilters()
                                        this.hideEditModal()
                                    })
                            })
                        }}
                    />}
                />
                <CustomModalWithChildren
                    open={openDelete}
                    handleClose={this.hideDeleteModal}
                    handleCancel={this.hideDeleteModal}
                    handleConfirm={() => {
                        this.hideDeleteModal()
                        removePoFilter(currentFilter)
                            .then(res => {
                                this.props.getFilters()
                                if (currentFilter === filters.activeFilter) {
                                    this.props.onSearchChanged('')
                                    getPoOverview()
                                }

                                notify('success', 'Filter successfully deleted')
                            })
                    }}
                    options={{
                        centered: true,
                        animation: false
                    }}
                >
                    <ConfirmModalContent text={`delete "${removedTitle}" filter`}/>
                </CustomModalWithChildren>
                <CustomModalWithChildren
                    open={modalCreate}
                    handleClose={() => {
                        this.setState({
                            modalCreate: false,
                            supplier: null,
                        })
                    }}
                    options={{
                        centered: true,
                        animation: false
                    }}
                    isFooter={false}
                >
                    <CreatePo
                        handleCancel={() => {
                            this.setState({
                                modalCreate: false,
                                supplier: null,
                            })
                        }}
                    />
                </CustomModalWithChildren>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    avg: state.purchaseOrdersReducer.avg,
    total: state.purchaseOrdersReducer.total, // Total e
    totalQuantity: state.purchaseOrdersReducer.totalQuantity, // Total pcs
    avgPcsEuro: state.purchaseOrdersReducer.avgPcsEuro, // AVG/pcs e
    totalPcsEuro: state.purchaseOrdersReducer.totalPcsEuro, // Total pcs e
    totalNetKg: state.purchaseOrdersReducer.totalNetKg, // Total Net kg
    avgKgEuro: state.purchaseOrdersReducer.avgKgEuro, // Total kg e
    totalKgEuro: state.purchaseOrdersReducer.totalKgEuro, // Total net e
    filters: state.poSmartFiltersReducer.filters,
    roles: state.rolesReducer.roles
})

export default withRouter(connect(mapStateToProps, {
    getFilters: getPoFiltersAsyncAction,
    createFilter: createPoFilterAsyncAction,
    getPoOverview: getPoOverview
})(PurchaseOrdersToolbar))