export const permViewUsers = {
    access: 'view_users',
    part: 'users'
}

export const permInviteUsers = {
    access: 'invite_users',
    part: 'users'
}

export const permEditUsers = {
    access: 'edit_users',
    part: 'users'
}

export const permDeleteUsers = {
    access: 'delete',
    part: 'users'
}

export const permEditOwnProfileUsers = {
    access: 'edit_own_profile',
    part: 'users'
}

export const permActDeactUsers = {
    access: 'act_deact_users',
    part: 'users'
}

