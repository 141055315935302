import {QUALITY_DICTIONARY, CATEGORIES_DICTIONARY, CG1_DICTIONARY, CG2_DICTIONARY, CHANNEL_DICTIONARY, PRODUCT_NAMES_DICTIONARY} from '../constants'

const initState = {
    categories: [],
    isLoadingCategories: false,
    genders: [],
    commodities: [],
    isLoadingValues: false,
    isLoadingQuality: false,
    isLoadingCG1: false,
    isLoadingCg2: false,
    isLoadingChannel: false,
    isLoadingProductNames: false,
    listQualities: [],
    listCg1: [],
    listCg2: [],
    listChannels: [],
    productNamesList: []
};

export const commodityGroupsReducer = (state = initState,action) => {
    const { type,payload } = action
    switch (type) {
        case QUALITY_DICTIONARY.IS_LOADING_QUALITY_LIST: {
            return {
                ...state,
                isLoadingQuality: payload
            }
        }
        case QUALITY_DICTIONARY.SET_LIST_QUALITY_DICTIONARY: {
            return {
                ...state,
                listQualities: payload
            }
        }
        case QUALITY_DICTIONARY.ADD_ITEM_TO_QUALITY_LIST: {
            return {
                ...state,
                listQualities: [payload].concat(state.listQualities)
            }
        }
        case QUALITY_DICTIONARY.REMOVE_ITEM_FROM_QUALITY_LIST: {
            return {
                ...state,
                listQualities: state.listQualities.filter(item => item.id !== payload)
            }
        }
        case QUALITY_DICTIONARY.EDIT_QUALITY_ITEM: {
            return {
                ...state,
                listQualities: state.listQualities.map(item => {
                    if(item.id === payload.id)
                        return {
                            ...item,
                            synonyms: payload.synonyms ? payload.synonyms : [],
                            value: payload.value
                        }
                    else
                        return item
                })
            }
        }


        case CG1_DICTIONARY.EDIT_CG1_ITEM: {
            return {
                ...state,
                listCg1: state.listCg1.map(item => {
                    if(item.id === payload.id)
                        return {
                            ...item,
                            synonyms: payload.synonyms ? payload.synonyms : [],
                            value: payload.value
                        }
                    else
                        return item
                })
            }
        }
        case CG1_DICTIONARY.REMOVE_ITEM_FROM_CG1_LIST: {
            return {
                ...state,
                listCg1: state.listCg1.filter(item => item.id !== payload)
            }
        }
        case CG1_DICTIONARY.ADD_ITEM_TO_CG1_LIST: {
            return {
                ...state,
                listCg1: [payload].concat(state.listCg1)
            }
        }

        case CG2_DICTIONARY.EDIT_CG2_ITEM: {
            return {
                ...state,
                listCg2: state.listCg2.map(item => {
                    if(item.id === payload.id)
                        return {
                            ...item,
                            synonyms: payload.synonyms ? payload.synonyms : [],
                            value: payload.value
                        }
                    else
                        return item
                })
            }
        }
        case CG2_DICTIONARY.REMOVE_ITEM_FROM_CG2_LIST: {
            return {
                ...state,
                listCg2: state.listCg2.filter(item => item.id !== payload)
            }
        }
        case CG2_DICTIONARY.ADD_ITEM_TO_CG2_LIST: {
            return {
                ...state,
                listCg2: [payload].concat(state.listCg2)
            }
        }
        case CG2_DICTIONARY.IS_LOADING_CG2_LIST: {
            return {
                ...state,
                isLoadingCg2: payload
            }
        }
        case CG2_DICTIONARY.SET_LIST_CG2_DICTIONARY: {
            return {
                ...state,
                listCg2: payload
            }
        }
        case CG1_DICTIONARY.SET_LIST_CG1_DICTIONARY: {
            return {
                ...state,
                listCg1: payload
            }
        }
        case CG1_DICTIONARY.IS_LOADING_CG1_LIST: {
            return {
                ...state,
                isLoadingCG1: payload
            }
        }
        case CHANNEL_DICTIONARY.EDIT_CHANNEL_ITEM: {
            return {
                ...state,
                listChannels: state.listChannels.map(item => {
                    if(item.id === payload.id)
                        return {
                            ...item,
                            synonyms: payload.synonyms ? payload.synonyms : [],
                            value: payload.value
                        }
                    else
                        return item
                })
            }
        }
        case CHANNEL_DICTIONARY.REMOVE_ITEM_FROM_CHANNEL_LIST: {
            return {
                ...state,
                listChannels: state.listChannels.filter(item => item.id !== payload)
            }
        }
        case CHANNEL_DICTIONARY.ADD_ITEM_TO_CHANNEL_LIST: {
            return {
                ...state,
                listChannels: [payload].concat(state.listChannels)
            }
        }
        case CHANNEL_DICTIONARY.IS_LOADING_CHANNEL_LIST: {
            return {
                ...state,
                isLoadingChannel: payload
            }
        }
        case CHANNEL_DICTIONARY.SET_LIST_CHANNEL_DICTIONARY: {
            return {
                ...state,
                listChannels: payload
            }
        }

        case PRODUCT_NAMES_DICTIONARY.EDIT_PRODUCT_NAMES_ITEM: {
            return {
                ...state,
                productNamesList: state.productNamesList.map(item => {
                    if(item.id === action.id)
                        return {
                            ...item,
                            value: payload.value
                        }
                    else
                        return item
                })
            }
        }
        case PRODUCT_NAMES_DICTIONARY.REMOVE_ITEM_FROM_PRODUCT_NAMES_LIST: {
            return {
                ...state,
                productNamesList: state.productNamesList.filter(item => item.id !== payload)
            }
        }
        case PRODUCT_NAMES_DICTIONARY.ADD_ITEM_TO_PRODUCT_NAMES_LIST: {
            return {
                ...state,
                productNamesList: [payload].concat(state.productNamesList)
            }
        }
        case PRODUCT_NAMES_DICTIONARY.IS_LOADING_PRODUCT_NAMES_LIST: {
            return {
                ...state,
                isLoadingProductNames: payload
            }
        }
        case PRODUCT_NAMES_DICTIONARY.SET_LIST_PRODUCT_NAMES_DICTIONARY: {
            return {
                ...state,
                productNamesList: payload
            }
        }

        case CATEGORIES_DICTIONARY.REMOVE_CATEGORY_DICTIONARY: {
            return {
                ...state,
                categories: state.categories.filter(ct => ct.id !== payload)
            }
        }
        case CATEGORIES_DICTIONARY.IS_LOADING_VALUES: {
            return {
                ...state,
                isLoadingValues: payload
            }
        }
        case CATEGORIES_DICTIONARY.SET_RELATED_CATEGORIES_VALUES: {
            return {
                ...state,
                genders: payload.genders,
                commodities: payload.commodities
            }
        }
        case CATEGORIES_DICTIONARY.IS_LOADING_CATEGORIES_DICTIONARY: {
            return {
                ...state,
                isLoadingCategories: payload
            }
        }
        case CATEGORIES_DICTIONARY.SET_CATEGORIES_DICTIONARY: {
            return {
                ...state,
                categories: payload
            }
        }
        case CATEGORIES_DICTIONARY.RESET_ALL_CATEGORIES_DICTIONARY: {
            return {
                ...initState
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}