import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Table} from 'react-bootstrap'
import adminInstance from '../../../../../instances/admininstance'
import {url} from '../../../../../../constants'
import Preloader from '../../../../../reuseComponent/preloader/preloader'


class TableAnalysis extends PureComponent {

    state = {
        data: [],
        isLoading: true
    }

    getData = () => {
        const {param1, param2, details} = this.props
        adminInstance.get(`${url}/analytics/summary/${details.id}/${param1}/${param2}`)
            .then(res => {
                const data = res.data.slice(0,res.data.length - 2)
                this.setState({
                    data: data,
                    total: res.data[res.data.length - 2],
                    items: res.data[res.data.length - 1],
                    isLoading: false
                })
            })
    }

    componentDidMount() {
        const { snapshot,param1,param2 } = this.props
        if(!snapshot)
            this.getData()
        else{
            const json = JSON.parse(snapshot.analysis)
            if(param1 === 'cg2' && param2 === 'cg3'){
                this.setState({
                    isLoading: false,
                    data: json.summaryCg2Cg3.filter(row => typeof row === 'object'),
                    total: json.summaryCg2Cg3[json.summaryCg2Cg3.length - 2],
                    items: json.summaryCg2Cg3[json.summaryCg2Cg3.length - 1],
                })
            }
            else{
                this.setState({
                    isLoading: false,
                    data: json.summaryCg2Size.filter(row => typeof row === 'object'),
                    total: json.summaryCg2Size[json.summaryCg2Size.length - 2],
                    items: json.summaryCg2Size[json.summaryCg2Size.length - 1],
                })
            }
        }
    }

    render() {
        const {isLoading, data} = this.state
        const {label} = this.props
        // console.log(data)
        return (
            isLoading ? <Preloader/> : <div className="wrapper-table-analysis">
                <Table size="sm">
                    <thead>
                    <tr>
                        <th>{label}</th>
                        <th>Total Items </th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map(topic => <React.Fragment key={topic.label}>
                        <tr className="caption-row">
                            <td>{topic.label}</td>
                            <td>{topic.total}</td>
                        </tr>
                        {topic.values.map(valRow => {
                            const keys = Object.keys(valRow)
                            return keys.length > 0 ? <tr className="value-row" key={keys[0]}>
                                <td>{keys[0]}</td>
                                <td>{valRow[keys[0]]}</td>
                            </tr> : null
                        })}
                    </React.Fragment>)}
                    </tbody>
                </Table>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        snapshot: state.purchaseOrdersReducer.snapshot
    }
}

export default connect(mapStateToProps, null)(TableAnalysis)