import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Tooltip from '../../../reuseComponent/Tooltips'
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute'
import {history} from '../../../../history'
import EditUser from '../edit'


class TaskDetails extends PureComponent {

    render() {
        const { changeUserInTable } = this.props
        return (
            <div className="details tasks-details">
                <Tooltip
                    text="Close"
                >
                    <div className="close-details-block" onClick={() => {
                        changeRoutePush(`/atw/settings/users`, history)
                    }}>
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
                        </svg>
                    </div>
                </Tooltip>
                <div className="content-details">
                    <EditUser changeUserInTable={changeUserInTable}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps, null)(TaskDetails)