import React , { PureComponent } from 'react'
import {Button,Form} from 'react-bootstrap';
import { ReactComponent as AddIcon } from '../../../../assets/images/plus.svg'
import {ReactComponent as LeftArrow} from '../../../../assets/images/arrow-left.svg';
import Select from '../../../reuseComponent/Select/select-form'
import { OfferApi } from '../../../../services/offers';
import AutosizeInput from "react-input-autosize";
import TextareaAutosize from "react-textarea-autosize";

const units = [{
    label: 'Pcs',
    value: 'pcs'
},{
    label: 'Kg',
    value: 'kg'
}]

class SecondStep extends PureComponent{

    state = {
        availabilities: []
    }

    fileRef = React.createRef()

    handleChangeFile = e => {
        const { setFieldValue,setImage } = this.props
        if(e.target.files && e.target.files[0]){

            if(e.target.files[0].type.indexOf('image') < 0){
                setImage(null)
                setFieldValue('file',null)
            }
            else{
                const reader = new FileReader()
                reader.onload = e => {
                    setImage(e.target.result)

                }
                setFieldValue('file',e.target.files[0])
                reader.readAsDataURL(e.target.files[0])
            }

        }
    }

    componentDidMount(){
        OfferApi.getAvailability()
            .then(res => {
                // console.log(res)
                this.setState({
                    availabilities: res.data.map(av => ({
                        label: av,
                        value: av
                    }))
                })
            })
    }

    render(){
        const { changeStep,handleCancel,values,setFieldValue,errors,touched,src } = this.props
        const { availabilities } = this.state
        return (
            <div className="second-step step">
                <div className="title-step">
                    <span className="name-step"><span>Step 2:</span> Offer info</span>
                    <div className="actions">
                        <Button type="button" onClick={() => changeStep(1)}>
                            <LeftArrow className="standart-svg"/> Back
                        </Button>
                        <Button type="button" variant="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button type="submit">
                            Save
                        </Button>

                    </div>
                </div>
                <div className="content-step offer-second-step-form">
                    <div className="offer-info-row">
                        <div className="offer-info-col col-with-file">
                            <div className="file-wrapper">
                                {src && <img src={src} alt="pic-offer"/>}
                                {src ? <div className="overlay" onClick={() => this.fileRef.current.click()}>
                                    <AddIcon className="standart-svg"/>
                                    Add picture
                                </div> : <Button onClick={() => this.fileRef.current.click()}>
                                    <AddIcon className="standart-svg"/>
                                    Add picture
                                </Button>}
                            </div>
                            <div className='offer-file-control'>
                                <Form.Control
                                    ref={this.fileRef}
                                    type="file"
                                    onChange={this.handleChangeFile}
                                    isInvalid={touched.file && errors.file}
                                />
                                <Form.Control.Feedback type="invalid">{errors.file}</Form.Control.Feedback>
                            </div>
                        </div>
                    </div>
                    <div className="offer-info-row">
                        <div className="offer-info-col">
                            <Form.Group className="horizontal">
                                <Form.Label>Title</Form.Label>
                                <div className="input-item">
                                    <AutosizeInput
                                        type="text"
                                        placeholder="Enter title"
                                        className="auto-resize-wrapper"
                                        name="title"
                                        value={values.title}
                                        onChange={e => setFieldValue('title',e.target.value)}
                                    />
                                    <span className="error-label">{touched.title && errors.title}</span>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="offer-info-col">
                            <Form.Group className="horizontal">
                                <Form.Label>Price</Form.Label>
                                <div className="input-item">
                                    <AutosizeInput
                                        type="text"
                                        placeholder="Enter price"
                                        className="auto-resize-wrapper"
                                        name="price"
                                        value={values.price}
                                        onChange={e => setFieldValue('price',e.target.value)}
                                    />
                                    <span className="error-label">{touched.price && errors.price}</span>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="offer-info-row">
                        <div className="offer-info-col">
                            <Form.Group className="horizontal">
                                <Form.Label>Unit</Form.Label>
                                <div className="input-item">
                                    <Select
                                        isInvalid={touched.unit && errors.unit}
                                        errorLabel={errors.unit}
                                        value={units.find(unit => unit.value === values.unit)}
                                        options={units}
                                        onChange={option => setFieldValue('unit',option.value)}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="offer-info-col">
                            <Form.Group className="horizontal">
                                <Form.Label>MOQ</Form.Label>
                                <div className="input-item">
                                    <AutosizeInput
                                        type="text"
                                        placeholder="Enter MOQ"
                                        className="auto-resize-wrapper"
                                        name="moq"
                                        value={values.moq}
                                        onChange={e => setFieldValue('moq',e.target.value)}
                                    />
                                    <span className="error-label">{touched.moq && errors.moq}</span>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="offer-info-row">
                        <div className="offer-info-col">
                            <Form.Group className="horizontal">
                                <Form.Label>Availability</Form.Label>
                                <div className="input-item">
                                    <Select
                                        options={availabilities}
                                        onChange={option => setFieldValue('availability',option.value)}
                                        isInvalid={touched.availability && errors.availability}
                                        errorLabel={errors.availability}
                                        value={availabilities.find(av => av.value === values.availability)}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="offer-info-col">
                            <Form.Group className="horizontal">
                                <Form.Label>Delivery time</Form.Label>
                                <div className="input-item">
                                    <AutosizeInput
                                        type="text"
                                        placeholder="Enter delivery time"
                                        className="auto-resize-wrapper"
                                        name="deliveryTime"
                                        value={values.deliveryTime}
                                        onChange={e => setFieldValue('deliveryTime',e.target.value)}
                                    />
                                    <span className="error-label">{touched.deliveryTime && errors.deliveryTime}</span>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="offer-info-row">
                        <div className="offer-info-col">
                            <Form.Group className="horizontal">
                                <Form.Label>Views</Form.Label>
                                <div className="input-item">
                                    <AutosizeInput
                                        type="text"
                                        placeholder="Enter views count"
                                        className="auto-resize-wrapper"
                                        name="viewsCount"
                                        value={values.viewsCount}
                                        onChange={e => setFieldValue('viewsCount',e.target.value)}
                                    />
                                    <span className="error-label">{touched.viewsCount && errors.viewsCount}</span>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="offer-info-col">
                            <Form.Group className="horizontal">
                                <Form.Label>Comment</Form.Label>
                                <div className="input-item">
                                    <TextareaAutosize
                                        style={{resize: 'none', height: 'auto'}}
                                        minRows={2}
                                        placeholder="Enter comment"
                                        type="text"
                                        className="auto-resize-wrapper"
                                        as="textarea"
                                        name="comment"
                                        onChange={e => setFieldValue('comment',e.target.value)}
                                        value={values.comment}
                                    />
                                    <span className="error-label">{touched.comment && errors.comment}</span>

                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SecondStep