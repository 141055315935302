import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Button, Table} from 'react-bootstrap'

import {getPartlyPrivileges, changePrivilegesService} from '../../../../services/roles'
import {notify} from '../../../reuseComponent/toast'
import Tooltip from '../../../reuseComponent/Tooltips'
import {privileges} from '../privileges'
import ListStatuses from './statusesCheckbox'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission'
import Editable from '../../../reuseComponent/editable'
import {permEditRoles} from "../../../../permissions/settings";


class PartlyRolesComponent extends PureComponent {

    state = {
        isLoading: true,
        parts: [],
        rolesPart: []
    }

    static defaultProps = {
        rolesList: [],
        alias: ''
    }

    changePrivileges = (data) => {
        changePrivilegesService(data)
            .then(res => {
                this.getPrivileges()
                notify('success','Permission successfully changed')
            })
            .catch(err => {
                console.log(err)
                notify('error','Oops something went wrong')
            })
    }

    getPrivileges = () => {
        const {alias} = this.props
        this.setState({
            isLoading: true
        }, () => {
            getPartlyPrivileges(alias)
                .then(res => {
                    this.setState({
                        isLoading: false,
                        parts: res.data.parts,
                        rolesPart: res.data.roles
                    })
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                    this.setState({
                        isLoading: false
                    })
                })
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.rolesList.length !== this.props.rolesList.length) || (prevProps.alias !== this.props.alias) ||
        JSON.stringify(prevProps.rolesList) !== JSON.stringify(this.props.rolesList)) {
            this.getPrivileges()
        }
    }

    componentDidMount() {
        this.getPrivileges()
    }

    render() {
        const {parts, rolesPart} = this.state
        const {handleCreate, handleRemove, handleChange, roles, handleValidate} = this.props
        return (
            <div className="wrapper-partly-privileges">
                <Table>
                    <thead>
                    <tr>
                        <th className="name-role-cell">
                            {findPermInArray(roles, permEditRoles) &&
                                <Button onClick={handleCreate} variant="primary">
                                    <svg viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                                    </svg> Add Role
                                </Button>
                            }
                        </th>
                        {parts.map(part => <th className="centered-content" key={part.id}>
                             {/* <Tooltip
                                text={part.name}
                            >
                                <span dangerouslySetInnerHTML={{__html: part.icon}}></span>
                            </Tooltip>  */}
                            <span>{part.name}</span>
                        </th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {rolesPart.map(rolePart => {
                        return <tr key={rolePart.id}>
                            <td>
                                <div className="hover-container-icon">
                                    {findPermInArray(roles, permEditRoles) ? <Editable
                                            handleSubmit={handleChange}
                                            handleValidate={handleValidate}
                                            optId={rolePart.id}
                                            name='name'
                                            text={rolePart.name}
                                        />
                                        : rolePart.name}
                                    {findPermInArray(roles,permEditRoles) ?
                                        <Tooltip
                                            text="Delete"
                                        >
                                            <svg className='icon-button' onClick={() => handleRemove(rolePart.id)} viewBox="0 0 24 24"><path fill="#000000" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"></path></svg>
                                        </Tooltip>
                                        : null
                                    }
                                </div>
                            </td>

                            {parts.map(part => {
                                let arrayPerm = []
                                part.permissionAliases.forEach(alias => {
                                    if (privileges[alias])
                                        arrayPerm.push(privileges[alias])
                                })
                                arrayPerm.push(privileges['not_view'])
                                const activeStatus = rolePart.privileges.filter(pr => pr.id === part.id)
                                return <td className="centered-content" key={part.id}>
                                    <ListStatuses
                                        changePrivilege={alias => {
                                            this.changePrivileges({
                                                frontPartId: part.id,
                                                accessAlias: alias,
                                                roleId: rolePart.id
                                            })
                                        }}
                                        hasAccessToEdit={findPermInArray(roles, permEditRoles)}
                                        listPrivileges={part.permissionAliases}
                                        activeStatus={activeStatus}
                                    />
                                </td>
                            })}
                        </tr>
                    })
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps, null)(PartlyRolesComponent)