import React, { PureComponent } from 'react'
import { Formik } from 'formik'
import FormBody from './formBody'
import { schema } from './schema';

class EditArticles extends PureComponent{

    render(){
        const { handleSubmit,handleClose } = this.props
        return (
            <Formik
                onSubmit={handleSubmit}
                validationSchema={schema}
                initialValues={{
                    cg1: '',
                    cg2: '',
                    cg3: '',
                    brand: '',
                    season: '',
                    size: '',
                    quality: '',
                    rrp: '',
                }}
                render={props => <FormBody {...props} handleClose={handleClose}/>}
            />
        )
    }
}

export default EditArticles