import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Form, Col, Button, Row} from 'react-bootstrap'
import Select from '../../../../../reuseComponent/Select/select-form'
import {getOpenBoxesByWorkplace} from '../../../../../../services/products'
import {getBrands} from '../../../../../../services/brands'
import AsyncSelect from '../../../../../reuseComponent/Select/async-select'
import adminInstance from '../../../../../instances/admininstance'
import {url} from '../../../../../../constants'
import axios from 'axios'
import {formatIntegerPlus} from '../../../../../../reuseFunctions/formatValue'
import Preloader from '../../../../../reuseComponent/preloader/preloader'
import {checkPackageName} from '../../../../../../reuseFunctions/checkPackageName'
import {getQualities} from '../../../../../../actions/detailsPo';
import { WorkPlacesApi } from '../../../../../../services/workPlaces'

class FormBody extends PureComponent {

    state = {
        boxes: [],
        brands: [],
        isLoading: true,
        workPlaces: [],
        simpleParameters: {
            cg1: [],
            cg2: [],
            cg3: [],
            bidId: [],
        }
    }


    componentDidMount() {
        const { values, getQualities, details} = this.props
        getQualities && getQualities()
        axios.all([
            this.getValuesByAlias('commodity'),
            this.getValuesByAlias('gender'),
            WorkPlacesApi.getAllWorkPlaces()
        ])
            .then(axios.spread((cg1Res, cg2Res, workPlacesRes) => {
                // console.log(workPlacesRes)
                this.setState({
                    workPlaces: workPlacesRes.data.data.content.map(workPlace => ({
                        label: workPlace.name,
                        value: workPlace.id
                    })),
                    isLoading: false,
                    simpleParameters: {
                        cg1: cg1Res.data.map(item => ({
                            label: item.value,
                            value: item.value,
                            id: item.id
                        })),
                        cg2: cg2Res.data.map(item => ({
                            label: item.value,
                            value: item.value,
                            id: item.id
                        })),
                        cg3: [],
                        bidId: details.bids.map(bid => ({
                            label: bid.number,
                            value: bid.number,
                            id: bid.id 
                        }))
                    },
                },() => {
                    if(values.cg1 && values.cg2){
                        this.getRelatedParams(values.cg1,values.cg2)
                    }
                    else{
                        this.setState({
                            isLoading: false
                        })
                    }

                })
            }))
        if(values.packageId && values.workplaceId){
            this.getOpenBoxes(values.packageId,values.workplaceId)
        }
    }

    getValuesByAlias = alias => {
        return adminInstance.get(`${url}/simple_parameter/${alias}`)
    }

    getRelatedParams = (param1, param2) => {
        adminInstance.get(`${url}/simple_parameter/category/related_params`, {
            params: {
                param1: param1,
                param2: param2
            }
        })
            .then(res => {
                const { setFieldValue,values } = this.props
                this.setState({
                    simpleParameters: {
                        ...this.state.simpleParameters,
                        cg3: res.data.map(category => ({
                            value: category.value,
                            label: category.value,
                            id: category.id
                        }))

                    },
                    isLoading: false
                },() => {
                    if(values.cg3 && !this.state.simpleParameters.cg3.find(val => val.value === values.cg3)){
                        setFieldValue('cg3','')
                    }
                })
            })

    }

    getBrands = (inputValue, callback) => {
        getBrands({
            limit: 20,
            page: 0,
            search: inputValue
        })
            .then(res => {
                callback(res.data.brands.map(item => ({
                    value: item.name,
                    label: item.name
                })))
            })
    }


    getOpenBoxes = (idPackage,workplaceId,setLastBox = false) => {
        const {setFieldValue} = this.props
        getOpenBoxesByWorkplace(idPackage,workplaceId)
            .then(res => {
                this.setState({
                    boxes: res.data.boxes.length > 0
                        ? res.data.boxes
                            .map(box => ({
                                label: `BOX-${box.name}`,
                                value: box.id,
                                name: box.name
                            })).concat({
                                label: 'New box',
                                value: 0
                            }) : [{
                            label: `New box`,
                            value: 0,
                        }]
                })
                let resBox = ''
                    if(res.data.last_box !== null && res.data.boxes.length > 0){
                        if(res.data.boxes.find(box => box.id === res.data.last_box))
                            resBox = res.data.last_box
                        else
                            resBox = ''
                    }
                    else if(res.data.boxes.length === 0)
                        resBox = 0
                    else resBox = ''
                    setLastBox && setFieldValue('newBoxId',resBox)
            })
    }

    render() {
        const {
            values,
            setFieldValue,
            handleChange,
            errors,
            touched,
            handleSubmit,
            handleClose,
            packages,
            valuesOptions
        } = this.props
        const {boxes, simpleParameters,isLoading,workPlaces} = this.state
        // console.log(values)
        const cg1Option = simpleParameters.cg1.find(option => option.value === values.cg1)
        const cg2Option = simpleParameters.cg2.find(option => option.value === values.cg2)
        const cg3Option = simpleParameters.cg3.find(option => option.value === values.cg3)
        const bidsOption = simpleParameters.bidId.find(option => option.value === values.bid)
        return (
            <Form noValidate className="edit-article-in-box" onSubmit={handleSubmit}>
                {isLoading && <Preloader />}
                <Row>
                    <Col xs="12">
                        <Form.Group>
                            <Form.Label>EAN</Form.Label>
                            <Form.Control
                                disabled={true}
                                name="ean"
                                value={values.ean}
                                onChange={handleChange}
                                isInvalid={errors.ean && touched.ean}
                            />
                            <Form.Control.Feedback type="invalid">{errors.ean}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>SKU</Form.Label>
                            <Form.Control
                                disabled={true}
                                name="styleNumber"
                                value={values.styleNumber}
                                onChange={handleChange}
                                isInvalid={errors.styleNumber && touched.styleNumber}
                            />
                            <Form.Control.Feedback type="invalid">{errors.styleNumber}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>BID</Form.Label>
                            <Select
                                options={simpleParameters.bidId}
                                value={bidsOption}
                                errorLabel={errors.bidId}
                                isInvalid={errors.bidId && touched.bidId}
                                onChange={valObj => {
                                    setFieldValue('bidId', valObj.id)
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Package</Form.Label>
                            <Select
                                options={packages}
                                value={packages.find(pack => pack.value === values.newPackage)}
                                errorLabel={errors.newPackage}
                                isInvalid={errors.newPackage && touched.newPackage}
                                onChange={valObj => {
                                    setFieldValue('newPackage', valObj.value)
                                    this.getOpenBoxes(valObj.value,values.workplaceId,true)
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Workplace</Form.Label>
                            <Select
                                options={workPlaces}
                                value={workPlaces.find(workPlace => workPlace.value === values.workplaceId)}
                                onChange={valObj => {
                                    setFieldValue('workplaceId',valObj.value)
                                    setFieldValue('workplace',valObj.label)
                                    this.getOpenBoxes(values.newPackage,valObj.value,true)
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Box</Form.Label>
                            <Select
                                value={boxes.find(box => box.value === values.newBoxId)}
                                errorLabel={errors.newBoxId}
                                isInvalid={errors.newBoxId && touched.newBoxId}
                                onChange={valObj => {
                                        setFieldValue('newBoxId', valObj.value)

                                }}
                                options={boxes}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG1</Form.Label>
                            <Select
                                options={simpleParameters.cg1}
                                value={cg1Option}
                                errorLabel={errors.cg1}
                                isInvalid={errors.cg1 && touched.cg1}
                                onChange={valObj => {
                                    setFieldValue('cg1', valObj.value)
                                    if (cg2Option) {
                                        this.getRelatedParams(valObj.id, cg2Option.id)
                                    }
                                    else {
                                        this.setState(state => ({
                                            simpleParameters: {
                                                ...state.simpleParameters,
                                                cg3: []
                                            }
                                        }))
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG2</Form.Label>
                            <Select
                                options={simpleParameters.cg2}
                                value={cg2Option}
                                errorLabel={errors.cg2}
                                isInvalid={errors.cg2 && touched.cg2}
                                onChange={valObj => {
                                    setFieldValue('cg2', valObj.value)
                                    if (cg1Option) {
                                        this.getRelatedParams(cg1Option.id, valObj.id)
                                    }
                                    else {
                                        this.setState(state => ({
                                            simpleParameters: {
                                                ...state.simpleParameters,
                                                cg3: []
                                            }
                                        }))
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG3</Form.Label>
                            <Select
                                options={simpleParameters.cg3}
                                value={cg3Option}
                                errorLabel={errors.cg3}
                                isInvalid={errors.cg3 && touched.cg3}
                                onChange={valObj => {
                                    setFieldValue('cg3', valObj.value)
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Brand</Form.Label>
                            <AsyncSelect
                                name="brand"
                                loadOptions={this.getBrands}
                                defaultOptions={true}
                                isInvalid={errors.brand && touched.brand}
                                errorLabel={errors.brand}
                                onChange={obj => setFieldValue('brand', obj.value)}
                                value={values.brand ? {
                                    value: values.brand,
                                    label: values.brand
                                } : null}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Season</Form.Label>
                            <Form.Control
                                name="season"
                                onChange={handleChange}
                                isInvalid={errors.season && touched.season}
                                value={values.season}
                            />
                            <Form.Control.Feedback type="invalid">{errors.season}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Size</Form.Label>
                            <Form.Control
                                name="size"
                                onChange={handleChange}
                                isInvalid={errors.size && touched.size}
                                value={values.size}
                            />
                            <Form.Control.Feedback type="invalid">{errors.size}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>


                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control
                                name="items"
                                onChange={handleChange}
                                value={values.items}
                                onBlur={e => setFieldValue('items',formatIntegerPlus(e.target.value))}
                                isInvalid={errors.items && touched.items}

                            />
                            <Form.Control.Feedback type="invalid">{errors.incomeStock}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Quality</Form.Label>
                            <Select
                                name="quality"
                                value={values.quality ? {
                                    label: values.quality,
                                    value: values.quality
                                } : null}
                                onChange={obj => setFieldValue('quality', obj.value)}
                                options={valuesOptions.qualities}
                                isInvalid={errors.quality && touched.quality}
                            />
                            <Form.Control.Feedback type="invalid">{errors.quality}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>RRP</Form.Label>
                            <Form.Control
                                name="rrp"
                                value={values.rrp}
                                onChange={e => setFieldValue('rrp', e.target.value)}
                                isInvalid={errors.rrp && touched.rrp}
                            />
                            <Form.Control.Feedback type="invalid">{errors.rrp}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>      
                    <Col xs="12">
                        <Form.Group className="footer-form footer-form-custom">
                            <Button type="button" onClick={handleClose}>Cancel</Button>
                            <Button type="submit">Save</Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        packages: state.purchaseOrdersReducer.detailsPurchase.inboundType === 'Custom' ?
          state.inboundingReportReducer.packages.filter(item => item.custom).filter(pack => pack.shortName !== 'REST').map(pack => ({
              value: pack.id,
              label: checkPackageName(pack)
          }))
          : state.inboundingReportReducer.packages.filter(pack => pack.shortName !== 'REST').map(pack => ({
            value: pack.id,
            label: checkPackageName(pack)
        })),
        valuesOptions: state.poDetailsReducer.valuesOptions,
    }
}

export default connect(mapStateToProps, {
    getQualities
})(FormBody)