import React , { PureComponent } from 'react'
import ScanComponent from '../../products/scanComponent';


class Inbounding extends PureComponent{
    render(){
        return (
            <div className="po-tab-content">
                <div className="content-po-tab">
                    <ScanComponent />
                </div>
            </div>
        )
    }
}

export default Inbounding