import * as yup from 'yup'

export const schema = yup.object().shape({
    title: yup.string()
      .test('qnique name', 'Package name already exists', function (val) {
          const { packages, id } = this.parent
          const resPacks = id ? packages.filter(item => item.id !== id) : packages
          let isExist = true;
          resPacks.forEach(pack => {
              if (pack.custom && pack.fullName === val) {
                  isExist = false;
              }
              if (!pack.custom && `${pack.shortName}(${pack.fullName})` === val) {
                  isExist = false;
              }
          });
          return isExist;
      })
      .required('Required field'),
    description: yup.string()
})

export const schemaCustomAmountPackage = yup.object().shape({
  title: yup.string()
    .test('qnique name', 'Package name already exists', function (val) {
      const { packages, id } = this.parent
      const resPacks = id ? packages.filter(item => item.id !== id) : packages
      let isExist = true;
      resPacks.forEach(pack => {
        if (pack.custom && pack.fullName === val) {
          isExist = false;
        }
        if (!pack.custom && `${pack.shortName}(${pack.fullName})` === val) {
          isExist = false;
        }
      });
      return isExist;
    })
    .required('Required field'),
  description: yup.string(),
  countPack: yup
    .number()
    .typeError('Should be number')
    .integer('Should be number')
    .min(1, 'Packs count cannot be less than 1')
    .required('Required field')
})
