import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Table from '../../../reuseComponent/Table/MaterialTable'
import Pagination from '../../../reuseComponent/Table/Pagination'
import ContainerTable from '../../../reuseComponent/Table/container'
import Header from '../../../reuseComponent/Table/table-head'
import adminInstanse from '../../../instances/admininstance'
import {url} from '../../../../constants'
import {generateQueryStringWithFilters} from '../../../../reuseFunctions/queries'
import {notify} from '../../../reuseComponent/toast'
import CheckBox from '../../../reuseComponent/checkbox'
import Modal from '../../../reuseComponent/modal'
import BulkForm from './bulk-edit-form'
import ProductTableCell from './product-table-cell'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import {
    permManageProductList,
} from '../../../../permissions/productOverview';
import { setFilterConditions, setProductsErrors, setProductsList } from '../../../../actions/purchase-orders'
import SmartFilter from './smart-filter'
import Tooltip from '../../../reuseComponent/Tooltips'
import Toolbar from './Toolbar.jsx'
import { ReactComponent as ItemIcon } from '../../../../assets/images/item.svg'


class TableProducts extends PureComponent {

    state = {
        isBulkEdit: false,
        products: [],
        isAllChecked: false,
        modalBulkEdit: false,
        modalSmartFilter: false,
        cg1: [],
        cg2: [],
        cg3: [],
        cg4: [],
        bid: [],
        sizeType: [],
        channel: [],
        count: 0
    }

    handleRevalidate = () => {
        const {tableRef, details} = this.props
        adminInstanse.post(`${url}/purchase_order/${details.id}/revalidate`)
            .then(res => {
                tableRef.current && tableRef.current.onQueryChange()
            })
    }

    handleClickEdit = () => {
        this.setState({
            modalBulkEdit: true
        })
    }

    activeDisableBulkMode = () => {
        this.setState(state => ({
            isBulkEdit: !state.isBulkEdit,
            products: [],
            isAllChecked: false
        }))
    }

    handleChange = (id, isChecked) => {
        const {products} = this.state
        if (isChecked)
            this.setState({
                products: products.filter(prId => prId !== id),
                isAllChecked: false
            })
        else
            this.setState({
                products: products.concat(id),
                isAllChecked: false
            })
    }

    renderColumns = () => {
        const {tableRef, roles} = this.props
        const {isAllChecked, products, isBulkEdit} = this.state
        let selectedAllRows = false
        if (tableRef.current) {
            selectedAllRows = products.length === tableRef.current.state.query.totalCount && tableRef.current.state.query.totalCount !== 0
        }
        return [
            {
                hidden: !isBulkEdit || !findPermInArray(roles, permManageProductList),
                title: (() => <CheckBox
                    options={{
                        className: 'checkbox-material'
                    }}
                    id="select-all-checkbox"
                    checked={isAllChecked || selectedAllRows}
                    indeterminate={!isAllChecked && !selectedAllRows && products.length > 0}
                    handleChange={e => {
                        const checked = e.target.checked
                        this.setState(state => ({
                            isAllChecked: checked,
                            products: !checked ? [] : tableRef.current.state.data.map(item => item.id)
                        }))
                    }}
                />)(),
                field: 'bulk-edit-field',
                sorting: false,
                headerStyle: {
                    padding: '0 20px 0 10px',
                    textAlign: 'center'
                },
                cellStyle: {
                    padding: '0 20px 0 10px',
                    textAlign: 'center'
                },
                render: rowData => {
                    const isChecked = products.includes(rowData.id)
                    return <CheckBox
                        options={{
                            className: 'checkbox-material'
                        }}
                        id={`bulk-checkbox-${rowData.id}`}
                        checked={isChecked || isAllChecked}
                        handleChange={() => this.handleChange(rowData.id, isChecked)}
                    />
                }
            },
            {
                title: 'BID',
                field: 'bid',
                sorting: false,
            },
            {
                title: 'EAN',
                field: 'ean',
                sorting: false,
            },
            {
                title: 'SKU',
                field: 'styleNumber',
                sorting: false
            },
            {
                title: 'CG1',
                field: 'cg1',
                sorting: false
            },
            {
                title: 'CG2',
                field: 'cg2',
                sorting: false
            },
            {
                title: 'CG3',
                field: 'cg3',
                sorting: false
            },
            {
                title: 'CG4',
                field: 'cg4',
                sorting: false
            },
            {
                title: 'Size',
                field: 'size',
                sorting: false
            },
            {
                title: 'Size Type',
                field: 'sizeType',
                sorting: false
            },
            {
                title: 'Season',
                field: 'season',
                sorting: false
            },
            {
                title: 'Brand',
                field: 'brand',
                sorting: false
            },
            {
                title: 'Channel',
                field: 'channel',
                sorting: false,
            },
            {
                title: 'Quality',
                field: 'quality',
                sorting: false
            },
            {
                title: 'RRP',
                field: 'rrp',
                sorting: false
            },
            {
                title: 'Quantity',
                field: 'incomeStock',
                sorting: false
            },
            {
                title: <Tooltip text="Inbounded">
                    <ItemIcon className="standard-svg" />
                    {/*<svg style={{*/}
                    {/*    width: 20,*/}
                    {/*    height: 20*/}
                    {/*}} viewBox="0 0 24 24">*/}
                    {/*    <path fill="#B0BEC5"*/}
                    {/*          d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z"/>*/}
                    {/*</svg>*/}
                </Tooltip>,
                field: 'actualCount',
                sorting: false
            }
        ]
    }

    getProducts = query => {
        console.log('query',query)
        const { details, setProductsErrors, conditions, setProductsList } = this.props

        console.log('details',details)
        return new Promise((resolve, reject) => {
            adminInstanse.get(`${url}/product/list/${details.id}`, {
                params: {
                    page: query.page ? query.page : 0,
                    limit: query.pageSize,
                    search: generateQueryStringWithFilters({
                        search: query.search,
                        conditions: conditions
                    })
                }
            })
                .then(res => {
                    console.log('res',res)
                    resolve({
                        data: this.generateRows(res.data.products),
                        page: query.page,
                        totalCount: res.data.count
                    })
                    this.setState({
                        cg1: res.data.cg1 ? res.data.cg1 : [],
                        cg2: res.data.cg2 ? res.data.cg2 : [],
                        cg3: res.data.cg3 ? res.data.cg3 : [],
                        cg4: res.data.cg4 ? res.data.cg4 : [],
                        quality: res.data.quality ? res.data.quality : [],
                        sizeType: res.data.sizeType ? res.data.sizeType : [],
                        channel: res.data.channel ? res.data.channel : [],
                        count: res.data.count ? res.data.count : 0,
                        bid: details.bids ? details.bids.map(bid => bid.number) : [],
                    })
                    setProductsErrors(res.data.errorsCount);
                    setProductsList(res.data);
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })
    }

    componentDidMount() {
        // this.getProducts();
    }

    componentDidUpdate(prevProps) {
        const {getProducts,details} = this.props
        if (prevProps.details.productList.products !== details.productList.products) {
            console.log('rerererendered')
            // this.getProducts();
            // this.generateRows(details.productList.products)
        }
    }

    generateRows = data => {
        return data.map(product => ({
            bid: product.bid && product.bid ? product.bid : '',
            id: product.id,
            ean: product.ean,
            styleNumber: product.styleNumber,
            cg1: product.cg1,
            cg2: product.cg2,
            cg3: product.cg3,
            cg4: product.cg4,
            season: product.season,
            brand: product.brand,
            size: product.size,
            rrp: product.rrp,
            buingPrice: product.buingPrice,
            incomeStock: product.incomeStock,
            actualCount: product.actualCount,
            sizeType: product.sizeType,
            errors: JSON.parse(product.errors),
            channel: product.channel,
            quality: product.quality
        }))
    }
    handleOpenFilterModal = () => {
        this.setState({modalSmartFilter: true})
    }

    render() {
        const {
            products,
            isAllChecked,
            modalBulkEdit,
            modalSmartFilter,
            cg1,
            cg2,
            cg3,
            cg4,
            sizeType,
            channel,
            quality,
            bid,
        } = this.state
        const {
            conditions,
            setFilter,
            roles
        } = this.props
        return (
            <>
                <div className="wrapper-table products-table">
                    <Table
                        components={{
                            Cell: ProductTableCell,
                            Container: ContainerTable,
                            Toolbar: props => <Toolbar {...this.props} {...props} {...this.state} handleClickEdit={this.handleClickEdit} handleOpenFilterModal={this.handleOpenFilterModal} activeDisableBulkMode={this.activeDisableBulkMode}/>,
                            Header: props => <Header {...props}/>,
                            Pagination: Pagination
                        }}
                        tableRef={this.props.tableRef}
                        columns={this.renderColumns()}
                        paginationPosition="toolbar"
                        // data={snapshot ? JSON.parse(snapshot.pl) : this.getProducts}
                        data={this.getProducts}
                        title=""
                        options={{
                            maxBodyHeight: 'calc(90vh - 180px)',
                            debounceInterval: 500,
                            search: true,
                            emptyRowsWhenPaging: false,
                            pageSize: 100,
                            pageSizeOptions: [10, 25, 50, 100, 200, 500],
                        }}
                    />
                </div>
                {modalSmartFilter && <Modal
                    classNameModal="smart-filter-products-modal"
                    open={modalSmartFilter}
                    options={{
                        centered: true,
                        animation: false,
                        style: {
                            zIndex: 1051,
                            backgroundColor: 'rgba(0,0,0,0.6)'
                        }
                    }}
                    isFooter={false}

                    Content={() => <SmartFilter
                        cg1={cg1}
                        cg2={cg2}
                        cg3={cg3}
                        cg4={cg4}
                        bid={bid}
                        quality={quality}
                        sizeType={sizeType}
                        channel={channel}
                        conditions={conditions}
                        handleCancel={() => this.setState({
                            modalSmartFilter: false
                        })}
                        handleConfirm={(data) => {
                            this.setState({
                                modalSmartFilter: false
                            }, () => {
                                setFilter(data)
                                    .then(() => {
                                        if (this.props.tableRef.current)
                                            this.props.tableRef.current.onQueryChange()
                                    })

                            })
                        }}
                    />}
                    handleClose={() => this.setState({
                        modalSmartFilter: false
                    })}
                />
                }
                {modalBulkEdit && findPermInArray(roles, permManageProductList) && <Modal
                    classNameModal="bulk-modal-edit"
                    open={modalBulkEdit}
                    options={{
                        centered: true,
                        animation: false,
                        style: {
                            zIndex: 1051,
                            backgroundColor: 'rgba(0,0,0,0.6)'
                        }
                    }}
                    handleClose={() => this.setState({
                        modalBulkEdit: false
                    })}
                    isFooter={false}
                    Content={() => <BulkForm
                        callback={(values) => {
                            this.setState({
                                isBulkEdit: false,
                                products: [],
                                isAllChecked: false,
                                modalBulkEdit: false
                            }, () => {
                                if (this.props.tableRef.current) {
                                    const tableRef = this.props.tableRef.current
                                    tableRef.onQueryChange()
                                }
                            })
                        }}
                        search={generateQueryStringWithFilters({
                            search: this.props.tableRef && this.props.tableRef.current
                                ? this.props.tableRef.current.state.query.search
                                : '',
                            conditions: conditions
                        })}
                        products={isAllChecked ? 'all' : products}
                        handleClose={() => this.setState({
                            modalBulkEdit: false
                        })}/>}
                />
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        productsErrors: state.purchaseOrdersReducer.productsErrors,
        conditions: state.purchaseOrdersReducer.conditionsFilter,
        dividingStatus: state.dividingReducer.dividingStatus,
        roles: state.rolesReducer.roles,
        status: state.dividingReducer.dividingStatus,
        snapshot: state.purchaseOrdersReducer.snapshot
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setProductsErrors: count => dispatch(setProductsErrors(count)),
        setFilter: conditions => dispatch(setFilterConditions(conditions)),
        setProductsList: pl => dispatch(setProductsList(pl)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableProducts)