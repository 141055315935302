import React from 'react'
import { fieldsTask } from './fieldsTask'
import {Button} from 'react-bootstrap';

const DiffModalContent = ({diff,handleClose}) => {
    let diffObj = null
    try {
        diffObj = JSON.parse(diff)
    }
    catch {
        console.log('error')
    }
    return diffObj ? <><div className="wrap-diff-modal">
            <div className="diff-row header">
                <div className="field-name">
                    Name
                </div>
                <div className="diff-old">
                    Old value
                </div>
                <div className="diff-new">
                    New value
                </div>
            </div>
            {Object.keys(diffObj).map(key => (
                <div className="diff-row" key={key}>
                    <div className="field-name">
                        {fieldsTask[key]}
                    </div>
                    <div className="diff-old">
                        {fieldsTask[key] !== 'Description' ? diffObj[key].old : <span dangerouslySetInnerHTML={{__html: diffObj[key].old}}></span>}
                    </div>
                    <div className="diff-new">
                        {fieldsTask[key] !== 'Description' ? diffObj[key].new : <span dangerouslySetInnerHTML={{__html: diffObj[key].new}}></span>}
                    </div>
                </div>
            ))}
        </div>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
        </>
        : null
}

export default DiffModalContent