import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Table from '../../../reuseComponent/Table/MaterialTable'
import Header from '../../../reuseComponent/Table/table-head'
import Pagination from '../../../reuseComponent/Table/Pagination'
import {setData} from '../../../../actions/admin-header'
import {getProductNamesList,removeItemFromProductNames} from '../../../../actions/commodity-groups'
import ActionMenuComponent from './actions-menu'
import Modal from '../../../reuseComponent/modal'
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import CreateProductNameForm from './create'
import EditProductNameForm from './edit'
import {findPermInArray} from "../../../../reuseFunctions/checkPermission";
import { permEditProductNamesDictionary} from '../../../../permissions/dictionaries';
import StandartLeftPanner from "../../../reuseComponent/Header/standartLeftPannel";
import Toolbar from './Toolbar'
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import withTracker from "../../../reuseComponent/GoogleAnalytics";
class ListProductNames extends PureComponent {

    state = {
        currId: null,
        modalCreate: false,
        modalRemove: false,
        removedTitle: '',
    }

    tableRef = React.createRef()

    columns = [
        {
            title: '',
            field: 'value',
            sorting: false
        },
        {
            title: '',
            field: 'actions',
            sorting: false,
            cellStyle: {
                width: 32
            },
            render: rowData => {
                return <ActionMenuComponent
                    handleEdit={() => this.setState({
                        modalCreate: true,
                        currId: rowData.id
                    })}
                    handleDelete={() => this.setState({
                        removedTitle: rowData.value,
                        modalRemove: true,
                        currId: rowData.id
                    })}
                />
            }
        }
    ]

    componentDidMount() {
        const {setHeaderData, getProductNamesList} = this.props
        getProductNamesList && getProductNamesList();
        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'Product names',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#795548"
                          d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z"/>
                </svg>)),
            },
            // textAddButton: 'Add',
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: (value) => {
                this.tableRef.current && this.tableRef.current.onSearchChange(value)
            }
        })
    }


    componentDidUpdate(prevProps){
        const { setHeaderData, roles } = this.props
        if(JSON.stringify(roles) !== JSON.stringify(prevProps.roles))
            setHeaderData && setHeaderData({
                leftPannelData: {
                    title: 'Product names',
                    logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                        <path fill="#000000"
                              d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z"/>
                    </svg>)),
                },
                LeftPannel: StandartLeftPanner,
                handleAdd: null,
                handleSearch: (value) => {
                    this.tableRef.current && this.tableRef.current.onSearchChange(value)
                }
            })
    }

    render() {
        const {isLoading, productNamesList,removeItemFromProductNames,roles} = this.props
        const {modalCreate, modalRemove, currId, removedTitle} = this.state
        return (
            <div className="wrapper-table">
                <Table
                    components={{
                        Toolbar: props => <Toolbar
                            {...props}
                            handleAdd={findPermInArray(roles, permEditProductNamesDictionary) ? () => this.setState({
                            modalCreate: true
                        }) : null}/>,
                        Header: Header,
                        Pagination: Pagination
                    }}
                    paginationPosition="toolbar"
                    isLoading={isLoading}
                    tableRef={this.tableRef}
                    columns={this.columns}
                    data={productNamesList}
                    options={{
                        showTitle: false,
                        search: true,
                        emptyRowsWhenPaging: false,
                        pageSize: 50,
                        pageSizeOptions: [10, 25, 50],
                    }}
                />
                {findPermInArray(roles, permEditProductNamesDictionary) && <Modal
                    open={modalCreate}
                    isFooter={false}
                    Content={() => currId ? <EditProductNameForm
                        id={currId}
                        value={productNamesList.find(item => item.id === currId).value}
                        handleCancel={() => this.setState({
                            currId: null,
                            modalCreate: false
                        })}
                    /> : <CreateProductNameForm
                        handleCancel={() => this.setState({
                            currId: null,
                            modalCreate: false
                        })}
                    />}
                    handleClose={() => this.setState({
                        currId: null,
                        modalCreate: false
                    })}
                    options={{
                        centered: true
                    }}
                />
                }
                {findPermInArray(roles, permEditProductNamesDictionary) && <CustomModalWithChildren
                    open={modalRemove}
                    handleClose={() => this.setState({
                        modalRemove: false,
                        currId: null
                    })}
                    handleCancel={() => this.setState({
                        modalRemove: false,
                        currId: null
                    })}
                    handleConfirm={() => this.setState({
                        modalRemove: false,
                        currId: null
                    }, () => removeItemFromProductNames(currId))}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent text={`delete "${removedTitle}" product name`}/>
                </CustomModalWithChildren>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.commodityGroupsReducer.isLoadingProductNames,
        productNamesList: state.commodityGroupsReducer.productNamesList,
        roles: state.rolesReducer.roles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setHeaderData: (data) => dispatch(setData(data)),
        getProductNamesList: () => dispatch(getProductNamesList()),
        removeItemFromProductNames: id => dispatch(removeItemFromProductNames(id))
    }
}

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(ListProductNames))