import React, {PureComponent} from 'react'
import {Formik} from 'formik'
import {schema} from './schema'
import {notify} from '../../../reuseComponent/toast'
import {OfferApi} from '../../../../services/offers'
import OfferForm from './form'
import axios from 'axios'


class CreateOfferComponent extends PureComponent {

    state = {
        isLoading: false,
        initialValues: {
            availability: '',
            comment: '',
            deliveryTime: '',
            file: '',
            moq: '',
            palletsIds: [],
            price: '',
            title: '',
            unit: '',
            viewsCount: '',
            sets: {}
        }
    }

    setLoading = isLoading => this.setState({isLoading: isLoading})

    handleCreate = values => {
        const {updateListOffers,handleCancel} = this.props
        const { sets } = values
        delete values.sets
        values.palletsIds = values.palletsIds.join(',')
        this.setLoading(true)
        axios.all([OfferApi.createOffer(values),OfferApi.saveOfferConditions(sets)])
            .then(res => {
                updateListOffers && updateListOffers()
                this.setLoading(false)
                notify('success', 'Offer successfully created')
                handleCancel()
            })
            .catch(err => this.setLoading(false))
    }

    render() {
        const {initialValues, isLoading} = this.state
        const {handleCancel} = this.props
        return (
            <Formik
                enableReinitialize={true}
                onSubmit={this.handleCreate}
                validationSchema={schema}
                initialValues={initialValues}
                render={props => <OfferForm
                    {...props}
                    isLoading={isLoading}
                    setLoading={this.setLoading}
                    handleCancel={handleCancel}
                />}
            />
        )
    }
}

export default CreateOfferComponent