export const checkAlias = (alias) => {
    let formatedAlias = '';
    if (alias === "cg1") {
        formatedAlias = "commodity";
      } else if (alias === "cg2") {
        formatedAlias = "gender";
      } else if (alias === "cg3") {
        formatedAlias = "category";
      }
      
      return formatedAlias
}