import React, {useState,useCallback} from 'react'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap'
import * as uniqId from 'uniqid'
import Modal from '../../../reuseComponent/modal'
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import SmartFilter from '../smart-filter'
import BulkForm from '../bulk-edit-form'
import {removeAnalysisToolResults, setShowHideUploadSchema} from '../../../../actions/analysisTool'
import ConvertToPoForm from '../convertToPoForm'
import adminInstance from '../../../instances/admininstance'
import {url} from '../../../../constants';
import {convertToFormData} from '../../../../reuseFunctions/objectToFormData';
import { notify } from '../../../reuseComponent/toast'
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';


const ToolbarAnalysisTool = props => {

    const [modalRemove, changeModal] = useState(false)
    const [modalConvert, changeModalConvert] = useState(false)
    const [actionModalData,changeActionModalData] = useState({
        showModal: false,
        poId: null
    })

    const handleReupload = useCallback(e => {
        const { tableRef } = props
        adminInstance.post(`${url}/analysis_tool/convert_to_po`,convertToFormData({
            poId: actionModalData.poId,
            action: 'REPLACE'
        }))
            .then(res => {
                tableRef.current && tableRef.current.onQueryChange()
                notify('success','Product list successfully converted')
                changeActionModalData({
                    showModal: false,
                    poId: null
                })
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    },[actionModalData,props])


    const {
        productsErrors,
        conditions,
        setFilter,
        cg1,
        cg2,
        cg3,
        cg4,
        quality,
        count,
        modalSmartFilter,
        isAllChecked,
        modalBulkEdit,
        products,
        isBulkEdit,
        handleClickEdit,
        activeDisableBulkMode,
        showSmartFilterModal,
        handleRevalidate,
        hideSmartFilterModal,
        handleConfirmSmartFilter,
        hideBulkEdit,
        callbackFormBulk,
        search,
        callbackSetFilter,
        paginationPosition,
        pagination,
        removeProducts,
        tableRef,
        sizeType,
        startRemoving,
        setShowHideUploadSchema,
        handleChangeConvertMode
    } = props
    return (
        <>
            <div className="toolbar-analysis">
                <div className="buttons-bulk-edit">
                    {isBulkEdit &&
                    <>
                        <Button onClick={handleClickEdit} disabled={products.length === 0 && !isAllChecked}
                                className="edit-bulk-button">
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000"
                                      d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"/>
                            </svg>
                            Edit
                        </Button>
                    </>
                    }
                    <Button className="bulk-active-mode-button" onClick={activeDisableBulkMode} disabled={count === 0}>
                        {!isBulkEdit
                            ? <>
                                <svg viewBox="0 0 24 24">
                                    <path fill="#000000"
                                          d="M21,19V17H8V19H21M21,13V11H8V13H21M8,7H21V5H8V7M4,5V7H6V5H4M3,5A1,1 0 0,1 4,4H6A1,1 0 0,1 7,5V7A1,1 0 0,1 6,8H4A1,1 0 0,1 3,7V5M4,11V13H6V11H4M3,11A1,1 0 0,1 4,10H6A1,1 0 0,1 7,11V13A1,1 0 0,1 6,14H4A1,1 0 0,1 3,13V11M4,17V19H6V17H4M3,17A1,1 0 0,1 4,16H6A1,1 0 0,1 7,17V19A1,1 0 0,1 6,20H4A1,1 0 0,1 3,19V17Z"/>
                                </svg>
                                Select
                            </>
                            : <>
                                <svg viewBox="0 0 24 24">
                                    <path fill="#000000"
                                          d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
                                </svg>
                                Cancel
                            </>}
                    </Button>
                    <Button disabled={!tableRef.current || tableRef.current.state.data.length <= 0} className="smart-filter-button" onClick={showSmartFilterModal}>
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000" d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"/>
                        </svg>
                        Filter
                    </Button>
                    <Button className="smart-filter-button" onClick={() => {
                        setShowHideUploadSchema(true)
                    }}>
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000" d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z"/>
                        </svg>
                        Import
                    </Button>
                    <Button disabled={!tableRef.current || tableRef.current.state.data.length <= 0} className="smart-filter-button" onClick={() => {
                        handleChangeConvertMode(true)
                    }}>
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M12,6V9L16,5L12,1V4A8,8 0 0,0 4,12C4,13.57 4.46,15.03 5.24,16.26L6.7,14.8C6.25,13.97 6,13 6,12A6,6 0 0,1 12,6M18.76,7.74L17.3,9.2C17.74,10.04 18,11 18,12A6,6 0 0,1 12,18V15L8,19L12,23V20A8,8 0 0,0 20,12C20,10.43 19.54,8.97 18.76,7.74Z"/>
                        </svg>
                        Convert sizes
                    </Button>

                    <Button disabled={productsErrors <= 0} onClick={handleRevalidate} className="smart-filter-button">
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M19,12V13.5A4,4 0 0,1 23,17.5C23,18.32 22.75,19.08 22.33,19.71L21.24,18.62C21.41,18.28 21.5,17.9 21.5,17.5A2.5,2.5 0 0,0 19,15V16.5L16.75,14.25L19,12M19,23V21.5A4,4 0 0,1 15,17.5C15,16.68 15.25,15.92 15.67,15.29L16.76,16.38C16.59,16.72 16.5,17.1 16.5,17.5A2.5,2.5 0 0,0 19,20V18.5L21.25,20.75L19,23M12,3C16.42,3 20,4.79 20,7C20,9.21 16.42,11 12,11C7.58,11 4,9.21 4,7C4,4.79 7.58,3 12,3M4,9C4,11.21 7.58,13 12,13C13.11,13 14.17,12.89 15.14,12.68C14.19,13.54 13.5,14.67 13.18,15.96L12,16C7.58,16 4,14.21 4,12V9M20,9V11H19.5L18.9,11.03C19.6,10.43 20,9.74 20,9M4,14C4,16.21 7.58,18 12,18L13,17.97C13.09,19.03 13.42,20 13.95,20.88L12,21C7.58,21 4,19.21 4,17V14Z"/>
                        </svg>
                        Re-validate
                    </Button>
                    {productsErrors > 0 && <Button onClick={() => {
                        setFilter([{
                            field: 'errorsCount',
                            separator: {
                                label: 'is more than',
                                value: 'is more than',
                                withValue: true
                            },
                            id: uniqId(),
                            value: 0
                        }])
                            .then(callbackSetFilter)
                    }} className="products-errors">
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000" d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z"/>
                        </svg>
                        {productsErrors} errors
                    </Button>
                    }
                        <Button disabled={!tableRef.current || tableRef.current.state.data.length <= 0} className="smart-filter-button" onClick={() => changeModalConvert(true)}>
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000"
                                      d="M19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H19C20.11 21 21 20.11 21 19V5C21 3.9 20.11 3 19 3M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M7 7H17V5H19V19H5V5H7V7M10 16H8V11H6L9 8L12 11H10V16M14 10H16V15H18L15 18L12 15H14V10Z"/>
                            </svg>
                            Convert to PO
                        </Button>
                        <Button disabled={!tableRef.current || tableRef.current.state.data.length <= 0} onClick={() => changeModal(true)} className="remove-button">
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000"
                                      d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
                            </svg>
                            Delete
                        </Button>
                </div>
                {paginationPosition === 'toolbar'
                &&
                <div className="pagination-toolbar">
                    {pagination}
                </div>
                }
            </div>
            {modalSmartFilter && <Modal
                classNameModal="smart-filter-products-modal"
                open={modalSmartFilter}
                options={{
                    centered: true,
                    animation: false,
                    style: {
                        zIndex: 1051,
                        backgroundColor: 'rgba(0,0,0,0.6)'
                    }
                }}
                isFooter={false}

                Content={() => <SmartFilter
                    cg1={cg1}
                    cg2={cg2}
                    cg3={cg3}
                    cg4={cg4}
                    quality={quality}
                    sizeType={sizeType}
                    conditions={conditions}
                    handleCancel={hideSmartFilterModal}
                    handleConfirm={handleConfirmSmartFilter}
                />}
                handleClose={hideSmartFilterModal}
            />
            }
            {modalBulkEdit && <Modal
                classNameModal="bulk-modal-edit"
                open={modalBulkEdit}
                options={{
                    centered: true,
                    animation: false,
                    style: {
                        zIndex: 1051,
                        backgroundColor: 'rgba(0,0,0,0.6)'
                    }
                }}
                handleClose={hideBulkEdit}
                isFooter={false}
                Content={() => <BulkForm
                    callback={callbackFormBulk}
                    search={search}
                    products={isAllChecked ? 'all' : products}
                    handleClose={hideBulkEdit}/>}
            />
            }
            {modalRemove && <CustomModalWithChildren
                open={modalRemove}
                handleClose={() => changeModal(false)}
                handleCancel={() => changeModal(false)}
                handleConfirm={() => {
                    startRemoving()
                    changeModal(false)
                    removeProducts(callbackSetFilter)
                }}
                options={{
                    centered: true,
                    animation: false
                }}
            >
                <ConfirmModalContent text="delete Analysis tool data"/>
            </CustomModalWithChildren>}
            {modalConvert && <Modal
                open={modalConvert}
                Content={() => <ConvertToPoForm
                    changeActionModalData={changeActionModalData}
                    handleClose={() => changeModalConvert(false)}
                    refetchData={tableRef.current ? tableRef.current.onQueryChange : () => {
                    }}
                />}
                handleClose={() => changeModalConvert(false)}
                handleCancel={() => changeModalConvert(false)}
                isFooter={false}
                options={{
                    centered: true,
                    animation: false
                }}
            />}
            {actionModalData.showModal && <Modal
                open={actionModalData.showModal}
                Content={() => <h6 className="center-h">Product list is already added to selected PO. Would you like to re-upload product list?</h6>}
                handleClose={() => changeActionModalData({
                    poId: null,
                    showModal: false
                })}
                handleCancel={() => changeActionModalData({
                    poId: null,
                    showModal: false
                })}
                handleConfirm={handleReupload}
                options={{
                    centered: true,
                    animation: false
                }}
            />}
        </>
    )
}

export default connect(null, {
    removeProducts: removeAnalysisToolResults,
    setShowHideUploadSchema: setShowHideUploadSchema
})(ToolbarAnalysisTool)