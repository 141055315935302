import React, { PureComponent } from 'react'
import { Formik } from 'formik'
import {schema} from "./schemas"
import FormBody from './FormBody'
import Preloader from '../reuseComponent/preloader/preloader'
import axios from 'axios'
import { url } from "../../constants"
import { withRouter } from 'react-router-dom'
import { convertToFormData } from "../../reuseFunctions/objectToFormData"
import {notify} from "../reuseComponent/toast";

class RegistrationUserForm extends PureComponent{

    state = {
        initValues: {
            username: '',
            name: '',
            password : '',
            confirm_password : '',
            secondName: '',
            roleId : ''
        },
        loading: true,
        isTokenAccess: true
    }

    componentDidMount(){
        document.body.classList.remove('main-site')
        const { token } = this.props.match.params
            axios.get(`${url}/user/invite/${token}`)
                .then(res => {
                    const { data } = res
                    this.setState({
                        initValues: {
                            username: data.email,
                            name: data.name,
                            password : '',
                            confirm_password: '',
                            secondName: '',
                            roleId : data.roleId
                        },
                        isTokenAccess: true
                    })
                })
                .catch(err => {
                    this.setState({
                        isTokenAccess: false
                    })
                })
                .finally(() => {
                    this.setState({
                        loading: false
                    })
                })
    }

    handleSubmit = (values) => {
        values.token = this.props.match.params.token
        let data = convertToFormData(values)
        this.setState({
            loading: true
        },() => {
            axios.post(`${url}/user/invite/${values.token}`,data)
                .then(res => {
                    notify('success', 'User successfully registered');
                    this.props.history.replace('/login')
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        initValues: values

                    })
                })
        })
    }

    render(){
        const { initValues,loading,isTokenAccess } = this.state
        return(
            !loading ? isTokenAccess ? <Formik
                initialValues = {initValues}
                onSubmit={this.handleSubmit}
                validationSchema = {schema}
                render={(props) => <FormBody
                    {...props}
                    linkCancel={'/login'}
                    history = {this.props.history}
                />}

            />
                : <h2>Token is not active</h2>
            : <Preloader/>
        )
    }
}

export default withRouter(RegistrationUserForm)