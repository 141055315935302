import React from 'react'
import Tooltip from '../../../../../reuseComponent/Tooltips'
import {Button} from 'react-bootstrap'

const UndoItem = props => {
    const { order,removeItem,handleUndo } = props
    return (
        <div className="wrapper-undo-item">
            <div className="body-undo">
                <span className="name-po">PO <span className="order-number">#{order.orderNumber}</span> deleted</span>
                <Tooltip text="Close">
                    <svg onClick={() => removeItem(order.id)} viewBox="0 0 24 24">
                        <path fill="#000000"
                              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
                    </svg>
                </Tooltip>
            </div>
            <div className="footer-undo">
                <Button onClick={() => handleUndo(order.id,order.actionId)} className="undo-button">UNDO</Button>
            </div>
        </div>
    )
}

export default UndoItem