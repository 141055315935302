import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Chip from '../../../../reuseComponent/chips/chip';
import {fields} from '../../products/smart-filter/conditions';
import {
    setFilterConditions, showHideUploadingSchema,

} from '../../../../../actions/purchase-orders';
import ReasonToReupload from '../../products/reason-to-reupload';
import {findPermInArray} from '../../../../../reuseFunctions/checkPermission';
import {permManageProductList} from '../../../../../permissions/productOverview';
import UploadingSchemaForm from '../../products/schema-uploading-excel';
import SizeConverter from '../../products/sizeConverter';
import TableProducts from '../../products/table';
import Preloader from '../../../../reuseComponent/preloader/preloader'

class ProductList extends PureComponent {

    state = {
        search: '',
        isConfirm: false,
        reason: '',
        isConvert: false
    }

    tableRef = React.createRef()

    clearSearch = () => {
        this.setState({
            search: ''
        })
    }

    handleDelete = text => {
        let arrayChips = this.state.search.split(',')
        this.setState({
            search: arrayChips.filter(item => item !== text).join(',')
        }, () => {
            if (this.tableRef.current) {
                this.tableRef.current.onSearchChange(this.state.search)
            }
        })
    }

    showConvertModal = () => {
        this.setState({
            isConvert: true
        })
    }

    handleChangeSearch = e => {
        this.setState({
            search: e.target.value
        }, () => {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                if (this.tableRef.current) {
                    this.tableRef.current.onSearchChange(this.state.search)
                }
            }, 700)
        })
    }

    handleClickImportXls = () => {
        const isConfirm = this.state.isConfirm
        this.setState({
            isConfirm: this.tableRef.current ? this.tableRef.current.state.data.length > 0 ? true : false : isConfirm
        }, () => {
            const {showHideUploadingSchema} = this.props
            this.clearSearch()
            if (!this.state.isConfirm)
                showHideUploadingSchema(true)
        })
    }

    renderContent = () => {
        const {isConfirm, reason, search, isConvert} = this.state
        const {isUploading, isShowUploadingSchema, showHideUploadingSchema,roles} = this.props
        if (isConfirm)
            return <ReasonToReupload
                handleConfirm={reason => {
                    this.setState({
                        isConfirm: false,
                        reason: reason
                    }, () => showHideUploadingSchema(true))
                }}
                handleCancel={() => {
                    this.setState({
                        isConfirm: false,
                        reason: ''
                    })
                }}
            />
        else if (isShowUploadingSchema && findPermInArray(roles, permManageProductList))
            return <UploadingSchemaForm reason={reason}/>
        else if (isConvert)
            return <SizeConverter
                callback={() => this.setState({
                    isConvert: false
                })}
                handleCancel={() => {
                    this.setState({
                        isConvert: false
                    })
                }}
            />
            else if(isUploading)
                return <Preloader text="Uploading"/>
        else
            return <TableProducts
                search={search}
                tableRef={this.tableRef}
                handleClickImportXls={this.handleClickImportXls}
                showConvertModal={this.showConvertModal}
            />
    }

    render() {
        const { conditions, setFilter, isUploading, isShowUploadingSchema, showHideUploadingSchema, roles, details} = this.props
        const {isConfirm, reason, search, isConvert} = this.state
        const chips = search.split(',');
        return (
            <div className="po-tab-content">
                <div className="header-po-tab">
                    <div className="left-part empty-bid">
                        { 
                            details && details.productList && details.productList.productWithoutBid > 0 ? (
                                <p>Caution! Product list contains {details.productList.productWithoutBid} {details.productList.productWithoutBid === 1 ? 'article with empty BID!' : 'articles with empty BID!'}</p>
                            ) : null
                        }
                    </div>
                    {(!isConvert && !isConfirm && !isShowUploadingSchema) && <div className="central-part">
                        <input type="text" value={search} onChange={this.handleChangeSearch}
                               className="search-field" placeholder="Search..."/>
                        <div className="search-wrap">
                            {chips.length > 0 && chips[0] && chips.map(item => (
                                item !== '' ?
                                    <Chip
                                        handleDeleteChip={this.handleDelete}
                                        key={item}
                                        text={item}
                                    />
                                    : null
                            ))}
                            {conditions.map(condition => {
                                return <Chip
                                    handleDeleteChip={() => setFilter(conditions.filter(cond => cond.id !== condition.id))
                                        .then(() => {
                                            this.tableRef.current && this.tableRef.current.onQueryChange()
                                        })
                                    }
                                    key={condition.id}
                                    text={`${fields[condition.field].labelField} ${condition.separator.label} ${condition.value}`}
                                />
                            })}
                        </div>
                    </div>}
                    <div className="right-part">

                    </div>
                </div>
                <div className="content-po-tab">
                    {isConfirm && <ReasonToReupload
                        handleConfirm={reason => {
                            this.setState({
                                isConfirm: false,
                                reason: reason
                            }, () => showHideUploadingSchema(true))
                        }}
                        handleCancel={() => {
                            this.setState({
                                isConfirm: false,
                                reason: ''
                            })
                        }}
                    />}

                    {isShowUploadingSchema
                    && findPermInArray(roles, permManageProductList)
                    && <UploadingSchemaForm reason={reason}/>}

                    {isConvert && !isUploading && <SizeConverter
                        callback={() => this.setState({
                            isConvert: false
                        })}
                        handleCancel={() => {
                            this.setState({
                                isConvert: false
                            })
                        }}
                    />}

                    {isUploading && <Preloader text="Uploading"/>}

                    {!isConfirm && !isConvert && !isShowUploadingSchema && <TableProducts
                        search={search}
                        tableRef={this.tableRef}
                        handleClickImportXls={this.handleClickImportXls}
                        showConvertModal={this.showConvertModal}
                    />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isUploading: state.purchaseOrdersReducer.isUploadingProducts,
        conditions: state.purchaseOrdersReducer.conditionsFilter,
        isShowUploadingSchema: state.purchaseOrdersReducer.isShowUploadingSchema,
        roles: state.rolesReducer.roles,
        details: state.purchaseOrdersReducer.detailsPurchase,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setFilter: conditions => dispatch(setFilterConditions(conditions)),
        showHideUploadingSchema: isShow => dispatch(showHideUploadingSchema(isShow)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)