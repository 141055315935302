import React, { PureComponent } from "react";
import { connect } from "react-redux";
import HistorList from "./list";
import Comment from "./comment-editor/comment-editor";
import Tabs from "./tabs";
import UploadFile from "./upload-file";
import { changeActiveSDHistoryTab } from "../../../../actions/serviceDesk";

class HistoryPartServiceDesk extends PureComponent {
  
  componentWillUnmount() {
    const { changeActiveSDHistoryTab } = this.props;
    changeActiveSDHistoryTab && changeActiveSDHistoryTab("CHRONOLOGY");
  }

  render() {
    const { activeTab, isLoading, refetchTableData } = this.props;
    return (
      <div>
        <div className="history-block">
          <div className="title-history">
            <Tabs />
            {activeTab === 'FILE' && <UploadFile refetchTableData={refetchTableData} />}
          </div>
          <HistorList />
        </div>
        {(activeTab === "CHRONOLOGY" || activeTab === "COMMENT") &&
          !isLoading && <Comment />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeTab: state.serviceDeskReducer.activeTabHistory,
    isLoading: state.serviceDeskReducer.isLoadingHistory
  };
};

const mapDispatchToProps = dispatch => ({
  changeActiveSDHistoryTab: tabName =>
    dispatch(changeActiveSDHistoryTab(tabName))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryPartServiceDesk);
