import React  from 'react'
import MaterialTable from '../../../reuseComponent/Table/MaterialTable'

const TableActivity = props => {
        const {
            columns = [],
            data = []
        } = props
        return(
            <MaterialTable
                {...props}
                columns={columns}
                data={data}
            />
        )

}

export default TableActivity