import React, { PureComponent } from 'react'
import BodyForm from '../../suppliers/createEdit/bodyForm'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import {getUsers} from '../../../../actions/users'
import {createSupplier} from '../../../../actions/suppliers'
import {schema} from '../../suppliers/createEdit/schema'
import Preloader from '../../../reuseComponent/preloader/preloader'

class CreateSupplierInPoModal extends PureComponent{

    state = {
        initValues: {
            title: '',
            email: '',
            contacts: [],
            buyersId: '',
            additionalInfo: '',
            address: '',
            qualities: {}
        }
    }

    handleSubmit = values => {
        const { createSupplier,setSupplier } = this.props
        createSupplier && createSupplier(values,(supplier) => {
            setSupplier(supplier)
            // changeTab('createPO')
            // notify('success','Supplier successfully created')
        })
    }

    render(){
        const { initValues } = this.state
        const { users,isLoading } = this.props
        return (
            !isLoading ? <Formik
                onSubmit={this.handleSubmit}
                initialValues = {initValues}
                validationSchema={schema}
                render={props => <BodyForm
                    {...props}
                    users={users}
                    cancelHandler={this.props.handleCancel}
                />}
            />
            : <Preloader/>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.supplierReducer.loading || state.usersReducer.loading,
        users: state.usersReducer.users
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers : () => dispatch(getUsers()),
        createSupplier: (values,callback) => dispatch(createSupplier(values,callback))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateSupplierInPoModal)