import {ADMIN_HEADER} from "../constants";

const initualState = {
    LeftPannel: null,
    searchText: '',
    filters: [],
    tableRef: {},
    Actions: null
}

export const adminHeaderReducer = (state = initualState, aciton) => {
    switch(aciton.type){
        case ADMIN_HEADER.SET_SEARCH_TEXT:{
            return {
                ...state,
                searchText: aciton.payload.searchText
            }
        }
        case ADMIN_HEADER.RESET_STATE:{
            return {
                ...state,
                tableRef: {},
                filters: []
            }
        }
        case ADMIN_HEADER.CHANGE_FILTERS: {
            return {
                ...state,
                filters: aciton.payload.concat()
            }
        }
        case ADMIN_HEADER.SET_DATA:{
            return {
                ...state,
                ...aciton.payload
            }
        }
        default:
            return state
    }
}