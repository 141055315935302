import { url } from '../constants'
import adminInstance from '../components/instances/admininstance'
import {convertToFormData} from '../reuseFunctions/objectToFormData';

const getWorkPlaces = params => {
    return adminInstance.get(`${url}/workplace`,{
        params: params
    })
}

const deleteWorkPlace = id => {
    return adminInstance.delete(`${url}/workplace/${id}`)
}

const createWorkPlace = values => {
    return adminInstance.post(`${url}/workplace`,convertToFormData(values))
}

const editWorkPlace = (values,id) => {
    return adminInstance.put(`${url}/workplace/${id}`,convertToFormData(values))
}

const getAllWorkPlaces = () => {
    return adminInstance.get(`${url}/workplace/all`)
}

export const WorkPlacesApi = {
    getWorkPlaces,
    deleteWorkPlace,
    createWorkPlace,
    editWorkPlace,
    getAllWorkPlaces
}