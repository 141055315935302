export const input = 'input'
export const select = 'select'
export const price = 'price'
export const integer = 'integer'

const priceFieldCondition = {
    typeValue: price,
    conditions: [{
        label: 'is',
        separator: ':',
        withValue: true
    },{
        label: 'is not',
        separator: '!:',
        withValue: true
    },{
        label: 'is more than',
        separator: '>',
        withValue: true
    },{
        label: 'is less than',
        separator: '<',
        withValue: true
    },{
        label: 'is more or equal to',
        separator: '>:',
        withValue: true
    },{
        label: 'is less or equal to',
        separator: '<:',
        withValue: true
    },{
        label: 'empty',
        separator: ':',
        withValue: false
    },{
        label: 'is not empty',
        separator: '!:',
        withValue: false
    }]
}

const selectFieldConditions = {
    typeValue: select,
    conditions: [{
        label: 'is',
        separator: ':',
        withValue: true
    },{
        label: 'is not',
        separator: '!:',
        withValue: true
    }],
}

const textFieldConditions = {
    typeValue: input,
    conditions: [{
        label: 'is',
        separator: ':',
        withValue: true
    },{
        label: 'is not',
        separator: '!:',
        withValue: true
    },{
        label: 'empty',
        separator: ':',
        withValue: false
    },{
        label: 'is not empty',
        separator: '!:',
        withValue: false
    },{
        label: 'contains',
        separator: '~',
        withValue: true
    },{
        label: "doesn't contain",
        separator: '!~',
        withValue: true
    },{
        label: 'begins with',
        separator: '~#',
        withValue: true
    }]
}

export const separators = [{
    label: 'is',
    separator: ':'
},{
    label: 'is not',
    separator: '!:',
},{
    label: 'empty',
    separator: ':',

},{
    label: 'is not empty',
    separator: '!:',

},{
    label: 'contains',
    separator: '~',

},{
    label: "doesn't contain",
    separator: '!~',

},{
    label: 'begins with',
    separator: '~#',

},{
    label: 'is more than',
    separator: '>',
},{
    label: 'is less than',
    separator: '<',
},{
    label: 'is more or equal to',
    separator: '>:',
},{
    label: 'is less or equal to',
    separator: '<:',
}]

export const fields = {
    bid: {
        ...selectFieldConditions,
        labelField: 'BID'
    },
    brand: {
        ...textFieldConditions,
        labelField: 'Brand'
    },
    cg1: {
        ...selectFieldConditions,
        labelField: 'Cg1'
    },
    cg2: {
        ...selectFieldConditions,
        labelField: 'Cg2'
    },
    cg3: {
        ...selectFieldConditions,
        labelField: 'Cg3'
    },
    cg4: {
        ...selectFieldConditions,
        labelField: 'Cg4'
    },
    channel: {
        ...selectFieldConditions,
        labelField: 'Channel'
    },
    ean: {
        ...textFieldConditions,
        labelField: 'EAN'
    },
    errorsCount: {
        ...priceFieldCondition,
        typeValue: integer,
        labelField: 'Errors count'
    },
    incomeStock: {
        ...priceFieldCondition,
        typeValue: integer,
        labelField: 'Items'
    },
    quality: {
        ...selectFieldConditions,
        labelField: 'Quality'
    },
    rrp: {
        ...priceFieldCondition,
        labelField: 'RRP'
    },
    stowedItems: {
        ...priceFieldCondition,
        typeValue: integer,
        labelField: 'Scanned items',
    },
    size: {
        ...textFieldConditions,
        labelField: 'Size'
    },
    sizeType: {
        ...selectFieldConditions,
        labelField: 'Size type'
    },
    styleNumber: {
        ...textFieldConditions,
        labelField: 'SKU'
    }
}

