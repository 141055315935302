import React, {useState, useCallback,useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Form} from 'react-bootstrap'
import {ReactComponent as DeleteIcon} from '../../../../assets/images/delete.svg'
import {ReactComponent as SaveIcon} from '../../../../assets/images/save.svg'
import {ReactComponent as EditIcon} from '../../../../assets/images/edit.svg'
import {ReactComponent as ClearIcon} from '../../../../assets/images/clear.svg'
import Tooltip from '../../../reuseComponent/Tooltips'
import {permEditArticles} from '../../../../permissions/dictionaries'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission'
import AutosizeInput from "react-input-autosize";

const ArticleRow = ({isNew, article, handleConfirm, handleCancel, showDeleteModal, articles}) => {

    const [isTouched, setTouched] = useState(false)
    const [disableForm, setDisableForm] = useState(!isNew)
    const [ean, setEan] = useState(article.ean)
    const [description, setDescription] = useState(article.description)
    const roles = useSelector(state => state.rolesReducer.roles)

    const handleChangeEan = useCallback((e) => {
        setEan(e.target.value)
    }, [])

    const handleChangeDescription = useCallback((e) => {
        setDescription(e.target.value)
    }, [])

    const handleDelete = useCallback(() => {
        showDeleteModal(article)
    }, [article, showDeleteModal])

    const changeFormMode = useCallback(() => {
        setDisableForm(!disableForm)
        setTouched(false)
        setEan(article.ean)
        setDescription(article.description)
    }, [disableForm, setTouched, article])

    const cancelHandler = useCallback(() => {
        if (handleCancel) handleCancel(article.id)
        else changeFormMode()
    }, [handleCancel, article.id, changeFormMode])
    
    const findExistEan = useCallback(() => {
        const existEan = articles.find(item => ean === item.ean)
        if(existEan && ean !== article.ean) return true
        return false
    }, [ean, article.ean, articles])

    const handleSubmit = useCallback(() => {
        setTouched(true)
        if (ean.length !== 13 || isNaN(ean) || findExistEan())
            return
        handleConfirm(article.id, {
            ean: ean,
            description: description
        }, () => setDisableForm(true))
        setTouched(false)
    }, [handleConfirm, article.id, ean, description, findExistEan])

    useEffect(() => {
        if(!findPermInArray(roles,permEditArticles)){
            setDisableForm(true)
            setDescription(article.description)
            setEan(article.ean)
        }
    },[roles,article])
    return <div className={`article-row ${disableForm ? 'disable' : ''}`}>
        <div className="article-field ean">
            <Form.Group
                className="horizontal"
            >
                <div className="input-item">
                    {/*<Form.Control*/}
                    {/*    isInvalid={isTouched && (ean.length === 0 || ean.length > 13 || ean.length < 13 || isNaN(ean) || findExistEan())}*/}
                    {/*    value={ean}*/}
                    {/*    onChange={handleChangeEan}*/}
                    {/*/>*/}
                    <AutosizeInput
                        disabled={disableForm}
                        type="text"
                        placeholder={disableForm ? '' : 'Enter EAN'}
                        className="auto-resize-wrapper"
                        name="name"
                        value={ean}
                        onChange={handleChangeEan}
                    />
                    {isTouched && <span className="error-label">{ean.length === 0 && 'required field'}
                        {(ean.length > 13 || (ean.length > 0 && ean.length < 13)) && 'must be 13 characters'}
                        {ean.length === 13 && isNaN(ean) && 'only digits'}
                        {findExistEan() && ean.length !== 0 && 'ean already exists'}</span>}
                    {/*{isTouched && <Form.Control.Feedback type="invalid">*/}
                    {/*    {ean.length === 0 && 'required field'}*/}
                    {/*    {(ean.length > 13 || (ean.length > 0 && ean.length < 13)) && 'must be 13 characters'}*/}
                    {/*    {ean.length === 13 && isNaN(ean) && 'only digits'}*/}
                    {/*    {findExistEan() && ean.length !== 0 && 'ean already exists'}*/}
                    {/*</Form.Control.Feedback>*/}
                    {/*}*/}
                </div>
            </Form.Group>
        </div>
        <div className="article-field description">
            <Form.Group
                className="horizontal"
            >
                <div className="input-item">
                    {/*<Form.Control*/}
                    {/*    value={description}*/}
                    {/*    onChange={handleChangeDescription}*/}
                    {/*/>*/}
                    <AutosizeInput
                        disabled={disableForm}
                        type="text"
                        placeholder={disableForm ? '' : 'Enter description'}
                        className="auto-resize-wrapper"
                        value={description}
                        onChange={handleChangeDescription}
                    />
                </div>
            </Form.Group>
        </div>
        <div className="article-field actions article-field-custom">
            {findPermInArray(roles, permEditArticles) && <>
                {disableForm ? <>
                        <Tooltip text="Edit">
                            <EditIcon onClick={changeFormMode}/>
                        </Tooltip>
                        <Tooltip text="Delete">
                            <DeleteIcon onClick={handleDelete}/>
                        </Tooltip>
                    </>
                    : <>
                        <Tooltip text="Save">
                            <SaveIcon onClick={handleSubmit}/>
                        </Tooltip>
                        <Tooltip text="Cancel">
                            <ClearIcon onClick={cancelHandler}/>
                        </Tooltip>
                    </>
                }
            </>
            }
        </div>
    </div>
}

export {ArticleRow}