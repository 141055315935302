import React from 'react'
import {Button, Form} from 'react-bootstrap'
import Select from '../../../../reuseComponent/Select/select-form';
import Switch from "../../../../reuseComponent/Switcher";

import './style.scss';
import AutosizeInput from "react-input-autosize";
import TextareaAutosize from "react-textarea-autosize";

const TemplateFormBody = props => {
    const {
        handleSubmit,
        errors,
        touched,
        handleClose,
        setFieldValue,
        departmentsList,
        usersList,
        values,
        setValues
    } = props;

    return (
        <Form
            onSubmit={handleSubmit}
            noValidate
            className="task-template-form"
        >
            <Form.Group className="horizontal">
                <Form.Label>Title</Form.Label>
                <div className="input-item">
                    <AutosizeInput
                        type="text"
                        placeholder="Enter title"
                        className="auto-resize-wrapper"
                        name="title"
                        value={values.title}
                        onChange={event => {
                            setFieldValue("title", event.target.value);
                        }}
                        onBlur={e => setFieldValue('title', e.target.value.trim())}
                    />
                    <span className="error-label">{touched.title && errors.title}</span>
                </div>
                {/*<Form.Control*/}
                {/*    type="text"*/}
                {/*    name="title"*/}
                {/*    value={values.title}*/}
                {/*    onChange={handleChange}*/}
                {/*    isInvalid={touched.title && errors.title}*/}
                {/*/>*/}
                {/*<Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>*/}
            </Form.Group>
            <Form.Group className="horizontal">
                <Form.Label>Description</Form.Label>
                <div className="input-item">
                    <TextareaAutosize
                        style={{resize: 'none', height: 'auto'}}
                        minRows={2}
                        placeholder={'Type description'}
                        type="text"
                        className="auto-resize-wrapper"
                        as="textarea"
                        name="description"
                        value={values.description}
                        onChange={event => {
                            setFieldValue('description', event.target.value)
                        }}
                    />
                    <span className="error-label">{touched.description && errors.description}</span>
                </div>

                {/*<Form.Control*/}
                {/*    type="text"*/}
                {/*    as="textarea"*/}
                {/*    name="description"*/}
                {/*    value={values.description}*/}
                {/*    onChange={handleChange}*/}
                {/*    isInvalid={touched.description && errors.description}*/}
                {/*/>*/}
                {/*<Form.Control.Feedback type="invalid">{errors.text}</Form.Control.Feedback>*/}
            </Form.Group>
            {/*<div className='form-group-custom'>*/}
                <Form.Group className="horizontal">
                    <Form.Label>Default for PO</Form.Label>
                    <div className="input-item with-switch">
                        <Switch checked={values.poDefault} onChange={() => setFieldValue('poDefault', !values.poDefault)}/>
                    </div>
                </Form.Group>
            {/*</div>*/}
            <Form.Group className="horizontal">
                <Form.Label>Responsible</Form.Label>
                <div className="input-item">
                    <Select
                        isInvalid={touched.responsible && errors.responsible}
                        errorLabel={errors.responsible}
                        value={values.responsible}
                        onChange={obj => {
                            if (obj.type === 'User')
                                setValues({
                                    ...values,
                                    executorId: obj.value,
                                    departmentId: '',
                                    responsible: obj
                                })
                            else {
                                setValues({
                                    ...values,
                                    executorId: '',
                                    departmentId: obj.value,
                                    responsible: obj
                                })
                            }
                        }}
                        options={usersList.concat(departmentsList)}
                    />
                </div>
            </Form.Group>
            <Form.Group className="footer-form footer-form-custom">
                <Button variant="secondary"
                        onClick={handleClose}>Cancel</Button>
                <Button variant="primary" type="submit">Save</Button>
            </Form.Group>
        </Form>

    )
}

export default TemplateFormBody;
