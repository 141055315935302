import React from "react";
import { connect } from "react-redux";

import { changeActiveHistoryTab } from "../../../../../actions/tasks";

const Tabs = props => {
  const { activeTab, changeActiveHistoryTab } = props;

  return (
    <div className="tabs">
      <button
        className={`tab-button ${activeTab === "CHRONOLOGY" ? "active" : ""}`}
        onClick={() => {
            changeActiveHistoryTab("CHRONOLOGY");
        }}
      >
        Chronology
      </button>

      <button
        className={`tab-button ${activeTab === "COMMENT" ? "active" : ""}`}
        onClick={() => {
            changeActiveHistoryTab("COMMENT");
        }}
      >
        Comments
      </button>

    </div>
  );
};

const mapStateToProps = state => {
  return {
    activeTab: state.ticketsReducer.activeTabHistory,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeActiveHistoryTab: tabName =>
      dispatch(changeActiveHistoryTab(tabName))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
