import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import ReactQuill from 'react-quill'
import QuillMention from 'quill-mention';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import Toobar from './toolbar'
import { addComment, editComment, addNotification} from '../../../../../../../actions/purchase-orders'
import { notify } from '../../../../../../reuseComponent/toast/index'

class CommentEditor extends PureComponent {

    mentionModule = {
        source: (searchTerm, renderList, mentionChar) => {
            let values;
            if (mentionChar === "@") {
                values = this.props.users.map(v => ({id: v.id, value: (v.name + v.secondName)}));
            }

            if (searchTerm.length === 0) {
                renderList(values, searchTerm);
            } else {
                const matches = [];
                for (let i = 0; i < values.length; i++)
                    if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                renderList(matches, searchTerm);
            }
        },
    }

    state = {
        value: this.props.value ? this.props.value : '',
        isFocus: false,
        userSearch: '',
        show: false,
        users: []
    };

    refQuill = React.createRef();

    static defaultProps = {
        isEditing: false,
        commentId: null,
        value: null,
        handleCancel: () => {}
    }

    static modules = {
        toolbar: {
            container: "#toolbar"
        }
    }

    static formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'color', 'mention'
    ]

    getUsersIdFromComment(text) {
        let ids = [];
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/html");
        const arr = doc.querySelectorAll('[data-id]');

        if (arr) {
            for (let i = 0; i < arr.length; i++) {
                ids.push(arr[i].dataset.id);
            }
        }
        return ids;
    }

    handleComment = () => {
        const { value, users } = this.state;
        const { details, addComment, isEditing, editComment, commentId, handleCancel, addNotification } = this.props;
        const ids = this.getUsersIdFromComment(value);
        if(value.trim()){
            this.refQuill.current && this.refQuill.current.blur();
            if(isEditing){
                editComment && editComment({
                    id: details.id,
                    text: value,
                    commentId: commentId,
                    callback: handleCancel
                })
            }
            else{
                this.setState({
                    value: ''
                },() => {
                    ids.length && addNotification({
                            userIds: ids,
                            description: '#' + details.id + ':' + value,
                            poId: details.id
                        });
                    addComment && details && addComment({
                        id: details.id,
                        text: value,
                        usersInComment: users
                    });
                })

            }
        }
        else{
            notify('warning','Comment can not be empty')
        }

    }

    handleChange = (value) => {
        this.setState({
            value: value
        })
    }

    render() {
        const { value, isFocus } = this.state;
        const { isEditing, handleCancel, commentId } = this.props;
        const toolBarId = `toolbar-${commentId ? commentId : 'create'}`;

        return (
            <div className={`wrap-comment ${isFocus || isEditing ? '' : ''}`}>
                <ReactQuill
                    ref={this.refQuill}
                    theme="snow"
                    value={value}
                    style={{
                        height: isFocus || isEditing ? '100%' : 28
                    }}
                    onChange={this.handleChange}
                    placeholder="Type to comment"
                    modules={{
                        toolbar: {
                            container: '#' + toolBarId
                        },
                        mention: this.mentionModule
                    }}
                    formats={CommentEditor.formats}
                    onFocus={() => this.setState({
                        isFocus: true
                    })}
                    onBlur={() => this.setState({
                        isFocus: false
                    })}
                />
                <Toobar
                    id={toolBarId}
                    handleCancel={handleCancel}
                    isEditing={isEditing}
                    style={{display: isFocus || isEditing ? 'block' : 'none'}}
                    handleSave={this.handleComment}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        users: state.usersReducer.users,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addComment: values => dispatch(addComment(values)),
        editComment: values => dispatch(editComment(values)),
        addNotification: values => dispatch(addNotification(values)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(CommentEditor)

