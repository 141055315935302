import { ADMIN_GLOBAL } from '../constants'

const initState = {
    style: {},
    isSocketConnected: false
}

export const adminGlobal = (state = initState,action) => {
    const { type,payload } = action
    switch (type) {
        case ADMIN_GLOBAL.IS_CONNECTED_TO_SOCKET_CLIENT: {
            return {
                ...state,
                isSocketConnected: payload
            }
        }
        case ADMIN_GLOBAL.CHANGE_STYLE_WRAPPER: {
            return {
                ...state,
                style: {...action.payload}
            }
        }
        default: {
            return {...state}
        }
    }
}