import React , { PureComponent } from 'react'
import AsyncSelect from '../../../reuseComponent/Select/async-select'
import { Formik } from 'formik'
import { Form,Button } from 'react-bootstrap'
import adminInstance from '../../../instances/admininstance'
import {url} from '../../../../constants'
import {convertToFormData} from '../../../../reuseFunctions/objectToFormData'
import { notify } from '../../../reuseComponent/toast'
import * as yup from 'yup'

const schema = yup.object().shape({
    poId: yup.string().required('required field')
})


class ConvertToPoForm extends PureComponent{

    generateQueryString = (inputString) => {
        return `?page=0&limit=10&search=s:${inputString};`
    }

    getPurchaseOrders = (inputValue, callback) => {
        adminInstance.get(`${url}/purchase_order/offers`,{
            params: {
                page: 0,
                limit: 25,
                status: '1,2',
                search: inputValue
            }
        })
            .then(res => {
                callback(res.data.purchaseOrders
                    .filter(po => !po.completeDividing && !po.completeInbound)
                    .map(po => ({
                    value: po.id,
                    label: 'PO#'+po.orderNumber
                })))
            })
    }

    handleSubmit = values => {
        const { handleClose,refetchData,changeActionModalData } = this.props
        handleClose()
        adminInstance.post(`${url}/analysis_tool/convert_to_po`,convertToFormData({
            poId: values.poId
        }))
            .then(res => {
                if(res.data.status === 200){
                    refetchData()
                    notify('success','Product list successfully converted')
                }
                else{
                    handleClose()
                    changeActionModalData && changeActionModalData({
                        poId: values.poId,
                        showModal: true
                    })
                }
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }

    render(){
        const { handleClose } = this.props
        return <Formik
            onSubmit={this.handleSubmit}
            initialValues={{
                poId: '',
                po: null
            }}
            validationSchema={schema}
            render={props => <Form onSubmit={props.handleSubmit}>
                    <Form.Group>
                        <Form.Label>PO</Form.Label>
                        <AsyncSelect
                            value={props.values.po}
                            isInvalid={props.errors.poId && props.touched.poId}
                            errorLabel={props.errors.poId}
                            loadOptions={this.getPurchaseOrders}
                            defaultOptions={true}
                            onChange={obj => {
                                props.setValues({
                                    poId: obj.value,
                                    po: obj
                                })
                            }}
                        />
                </Form.Group>
                <Form.Group className="footer-form footer-form-custom">
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Convert</Button>
                </Form.Group>
            </Form>}
        />
    }
}

export default ConvertToPoForm