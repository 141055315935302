import React from 'react'
import './chip.scss'

const Chip = props => {
    const {
        text = '',
        handleDeleteChip
    } = props
    return (
        <button type="button" className="custom-chip">
            <span className="text-chip">{text}</span>
            <i className="fas fa-times-circle" onClick={() => handleDeleteChip(text)}></i>
        </button>
    )
}

export default Chip