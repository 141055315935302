
//GENERAL

export const permViewPO = {
    access: 'view_po_list',
    part: 'general'
};

export const permCreatePO = {
    access: 'create_po',
    part: 'general'
};

export const permRemovePO = {
    access: 'remove_po',
    part: 'general'
};

export const permDownloadXlsxPO = {
    access: 'download_xlsx',
    part: 'general'
}

//HISTORY

export const permViewChronology = {
    access: 'view_chronology',
    part: 'history_po'
};

export const permEditChronology = {
    access: 'edit_chronology',
    part: 'history_po'
};

export const permTaskChronology = {
    access: 'task_chronology',
    part: 'info'
};

export const permFilesChronology = {
    access: 'files_chronology',
    part: 'info'
};

export const permInvoiceChronology = {
    access: 'invoice_chronology',
    part: 'info'
};

//TRANSPORT

export const permViewTransport = {
    access: 'view_transport',
    part: 'transport'
};

export const permEditTransport = {
    access: 'edit_transport',
    part: 'transport'
};

export const permCompleteTransport = {
    access: 'complate_transport',
    part: 'transport'
};

export const permComposeDeliveryNotes = {
    access: 'compose_delivery_notes',
    part: 'transport'
}

//INFO

export const permViewInfo = {
    access: 'view_main_info',
    part: 'info'
};

export const permEditInfo = {
    access: 'edit_main_info',
    part: 'info'
};

export const permCompleteInfo = {
    access: 'complate_po',
    part: 'info'
};

export const permEditFollowersInfo = {
    access: 'edit_followers_po',
    part: 'info'
};
export const permViewFollowersInfo = {
    access: 'show_followers_po',
    part: 'info'
};

export const permFinanceInfoOverview = {
    access: 'finance_info_overview',
    part: 'general'
}

export const permFinanceInfo = {
    access: 'finance_info',
    part: 'info'
};

export const permQualityCheck = {
    access: 'quality_check',
    part: 'table'
}

export const permInboundType = {
    access: 'inbound_type',
    part: 'table'
}

//TABLE

export const permViewInbound = {
    access: 'view_inbound',
    part: 'table'
};

export const permEditInbound = {
    access: 'edit_inbound',
    part: 'table'
};

export const permCompleteInbound = {
    access: 'complate_inbound',
    part: 'table'
};

export const permUploadProduct = {
    access: 'upload_product',
    part: 'table'
};


// product list

export const permEditPackageName = {
    access: 'manage_dividing',
    part: 'product_list'
}

export const permViewProductList = {
    access: 'view_product_list',
    part: 'product_list'
}
export const permManageProductList = {
    access: 'manage_product_list',
    part: 'product_list'
}

export const permShowAnalysis = {
    access: 'show_analysis',
    part: 'product_list'
}
export const permShowDividing = {
    access: 'show_dividing',
    part: 'product_list'
} 

export const permManageDividing = {
    access: 'manage_dividing',
    part: 'product_list'
}

//inbounding

export const permOpenCloseInbounding = {
    access: 'open_close_boxes',
    part: 'inbound_management'
}
export const permExportXlsInbounding = {
    access: 'export_xls',
    part: 'inbound_management'
}
export const permEditInbounding = {
    access: 'edit_inbound_management',
    part: 'inbound_management'
}
export const permViewInbounding = {
    access: 'view_inbound_management',
    part: 'inbound_management'
}
export const permManageCustomPackage = {
    access: 'manage_custom_packages',
    part: 'inbound_management'
}