import React, { PureComponent } from 'react'
import { Modal,Button } from 'react-bootstrap'

class CustomModal extends PureComponent{

    static defaultProps = {
        backdropClassName: '',
        classNameModal: '',
        classNameBody: '',
        modalId : '',
        handleConfirm : () => {},
        open : false,
        handleCancel : () => {},
        Content : () => <div></div>,
        handleClose : () => {},
        isFooter : true,
        confirm: 'Confirm',
        cancel: 'Cancel',
        options: {

        }
    }

    render(){
        const {
            handleConfirm,
            open,
            handleCancel,
            Content,
            handleClose,
            options,
            isFooter,
            modalId,
            classNameBody,
            classNameModal,
            confirm,
            cancel,
            backdropClassName
        } = this.props
        return (
            <Modal
                backdropClassName={backdropClassName}
                dialogClassName={classNameModal}
                id={modalId}
                show={open}
                onHide={handleClose}
                {...options}
            >
                <Modal.Body className={classNameBody}>
                    <Content/>
                </Modal.Body>
                {isFooter ? <Modal.Footer>
                    <Button variant="primary" onClick={handleConfirm}>
                        {confirm}
                    </Button>
                    <Button variant="secondary" onClick={handleCancel}>
                        {cancel}
                    </Button>

                </Modal.Footer> : null}

            </Modal>
        )
    }
}

export default CustomModal