import React from "react";
import { connect } from "react-redux";

import { changeActiveSDHistoryTab } from "../../../../../actions/serviceDesk";

const Tabs = props => {
  const { activeTab, changeActiveSDHistoryTab } = props;

  return (
    <div className="tabs">
      <button
        className={`tab-button ${activeTab === "CHRONOLOGY" ? "active" : ""}`}
        onClick={() => {
          changeActiveSDHistoryTab("CHRONOLOGY");
        }}
      >
        Chronology
      </button>
      <button
        className={`tab-button ${activeTab === "COMMENT" ? "active" : ""}`}
        onClick={() => {
          changeActiveSDHistoryTab("COMMENT");
        }}
      >
        Comments
      </button>
      {
        <button
          className={`tab-button ${activeTab === "FILE" ? "active" : ""}`}
          onClick={() => {
            changeActiveSDHistoryTab("FILE");
          }}
        >
          Files
        </button>
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    activeTab: state.serviceDeskReducer.activeTabHistory,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeActiveSDHistoryTab: tabName =>
      dispatch(changeActiveSDHistoryTab(tabName))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
