import React, { PureComponent } from 'react'
import {Formik} from 'formik'
import {Button, Form} from 'react-bootstrap'
import {schemaInviteUser} from "../shemas"
import './styles/modalCreateFooter.scss'
import CustomSelect from '../../../reuseComponent/Select/select-form'
import adminInstance from '../../../instances/admininstance';
import {url} from '../../../../constants';
import AsyncSelect from '../../../reuseComponent/Select/async-select';
import AutosizeInput from "react-input-autosize";

class ModalCreateContent extends PureComponent{

    state = {
        defaultDepartments: []
    }

    componentDidMount(){
        this.getOptions('')
            .then(res => {
                this.setState({
                    defaultDepartments: res
                })
            })
    }

    getOptions(inputValue) {
        let promise =  adminInstance.get(`${url}/department`, {
            params: {
                limit: 20,
                page: 0,
                search: inputValue
            }
        })
            .then(res => {
                let options = res.data.departments.map(sup => ({
                    value: sup.id,
                    label: sup.name
                }))
                return options
            })
        return promise
    }

    render(){
        const { defaultDepartments } = this.state
        const {handleSubmit, handleCancel,roles} = this.props
        return (
                <Formik
                    onSubmit={handleSubmit}
                    validationSchema={schemaInviteUser}
                    validateOnChange={false}
                    initialValues={{
                        email: '',
                        name: '',
                        roleId: '',
                        departmentId: ''
                    }}
                    render={propsFormik => {
                        return (
                            <Form noValidate onSubmit={propsFormik.handleSubmit} className="user-invite-form">
                                <Form.Group className="horizontal">
                                    <Form.Label>Email</Form.Label>
                                    <div className="input-item">
                                        <AutosizeInput
                                            type="email"
                                            placeholder="Enter email"
                                            className="auto-resize-wrapper"
                                            name="email"
                                            onChange={propsFormik.handleChange}
                                            value={propsFormik.values.email}
                                            // isInvalid={propsFormik.touched.email && !!propsFormik.errors.email}
                                        />
                                        <span className="error-label">{propsFormik.touched.email && propsFormik.errors.email}</span>

                                        {/*<Form.Control.Feedback type="invalid">{propsFormik.errors.email}</Form.Control.Feedback>*/}
                                    </div>
                                </Form.Group>
                                <Form.Group className="horizontal">
                                    <Form.Label>First name</Form.Label>
                                    <div className="input-item">
                                        <AutosizeInput
                                            type="text"
                                            placeholder="Enter first name"
                                            className="auto-resize-wrapper"
                                            name="name"
                                            onChange={propsFormik.handleChange}
                                            value={propsFormik.values.name}
                                            // isInvalid={propsFormik.touched.name && !!propsFormik.errors.name}
                                        />
                                        <span className="error-label">{propsFormik.touched.name && propsFormik.errors.name}</span>

                                        {/*<Form.Control.Feedback type="invalid">{propsFormik.errors.name}</Form.Control.Feedback>*/}
                                    </div>
                                </Form.Group>
                                <Form.Group className="horizontal">
                                    <Form.Label className="with-select">Role</Form.Label>
                                    <div className="input-item">
                                        <CustomSelect
                                            name="roleId"
                                            isInvalid={propsFormik.touched.name && propsFormik.errors.roleId}
                                            onChange={(e) => {
                                                propsFormik.setFieldValue('roleId',e.value)
                                            }}
                                            errorLabel={propsFormik.errors.roleId}
                                            options={roles}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group  className="horizontal last">
                                    <Form.Label className="with-select">Department</Form.Label>
                                    <div className="input-item">
                                        <AsyncSelect
                                            defaultOptions={defaultDepartments}
                                            name="departmentId"
                                            cacheOptions
                                            value={propsFormik.values.department}
                                            onChange={obj => {
                                                propsFormik.setValues({
                                                    ...propsFormik.values,
                                                    department: obj,
                                                    departmentId: obj.value
                                                })
                                                // setFieldValue('supplierId', obj.value)
                                            }}
                                            isInvalid={propsFormik.touched.departmentId && propsFormik.errors.departmentId}
                                            errorLabel={propsFormik.errors.departmentId}
                                            loadOptions={this.getOptions}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group className="footer-form footer-form-custom">
                                    <Button variant="secondary" onClick={handleCancel}>
                                        Close
                                    </Button>
                                    <Button type="submit" variant="primary">
                                        Confirm
                                    </Button>
                                </Form.Group>

                            </Form>
                        )
                    }}
                />

        )
    }
}

export default ModalCreateContent