import React, {PureComponent} from 'react'
import {Button, Form} from 'react-bootstrap'
import {connect} from 'react-redux'
import Select from '../../../../../../reuseComponent/Select/select-form'
import DatePicker from '../../../../../../reuseComponent/DatePicker/datepicker-with-children'
import {formatPriceWithEmptyString} from '../../../../../../../reuseFunctions/formatValue'
import {showHideCreateContact, setDetailsPurchaseAction} from '../../../../../../../actions/purchase-orders'
import adminInstance from '../../../../../../instances/admininstance'
import {url} from '../../../../../../../constants'
import {convertToFormData} from '../../../../../../../reuseFunctions/objectToFormData'
import Tooltip from '../../../../../../reuseComponent/Tooltips/index'
import {ReactComponent as EditIcon} from '../../../../../../../assets/images/edit.svg'
import { notify } from '../../../../../../reuseComponent/toast/index'
import { sortArrayByObjectKey } from '../../../../../../../reuseFunctions/reorder'

class BodyForm extends PureComponent {

    state = {
        isNewAddress: false,
        valueNewAddress: '',
        touchedAddress: false,
        isEditAddress: false,
        editAddress: '',
        newAddress: '',
        isShowSelectMenu: false
    }

    handleCancel = () => {
        this.setState({
            isNewAddress: false,
            valueNewAddress: '',
            touchedAddress: false
        })
    }

    handleCreateWarehouseAddress = (setFieldValue) => {
        const value = this.state.valueNewAddress
        if (value) {
            const {details, setDetails} = this.props
            adminInstance.put(`${url}/supplier/${details.supplier.id}/pickup_address`, convertToFormData({
                warehouseAddress: value
            }))
                .then(res => {
                    this.setState({
                        isNewAddress: false,
                        valueNewAddress: '',
                        touchedAddress: false
                    }, () => {
                        setFieldValue && setFieldValue('pickupAddress', value)
                        setDetails({
                            supplier: res.data
                        })
                    })

                })
        }
        else {
            this.setState({
                touchedAddress: true
            })
        }

    }

    handleEditWareHouseAddress = () => {
        const { details, setDetails, values, setFieldValue } = this.props
        const { newAddress,editAddress } = this.state
        this.setState({
            touchedAddress: true
        },() => {
            if(newAddress){
                this.setState({
                    isEditAddress: false,
                    editAddress: '',
                    newAddress: '',
                },() => {
                    const formData = convertToFormData({
                        newWarehouseAddress: newAddress,
                        oldWarehouseAddress: editAddress
                    })
                    adminInstance.put(`${url}/supplier/${details.supplier.id}/edit_pickup_address`,formData)
                        .then(res => {
                            if(editAddress === values.pickupAddress) {
                                setFieldValue('pickupAddress', newAddress)
                            }
                            setDetails({
                                supplier: {
                                    ...details.supplier,
                                    warehouseAddresses: res.data.warehouseAddresses
                                }
                            })
                            notify('success','Address successfully edited')
                        })
                        .catch(err => {
                            notify('Oops something went wrong')
                        })
                })
            }

        })


    }

    handleChange = e => {
        this.setState({
            valueNewAddress: e.target.value
        })
    }

    render() {
        const {isNewAddress, valueNewAddress, touchedAddress, isEditAddress,newAddress,isShowSelectMenu} = this.state

        const {
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            setFieldValue,
            details,
            openHideContact
        } = this.props
        const contactsOptions = details.supplier.contacts.map(item => ({
            value: item.id,
            label: item.name + ' ' + item.secondName
        }))
        const addressesOptions = details.supplier.warehouseAddresses.map(item => ({
            value: item,
            label: <div className="supplier-address-line">
                {item}
                <Tooltip text="Edit">
                    <EditIcon onClick={(e) => {
                        e.stopPropagation()
                        this.setState({
                            isEditAddress: true,
                            editAddress: item,
                            newAddress: item,
                            isShowSelectMenu: false
                        })
                    }} className="standart-svg"/>
                </Tooltip>
            </div>
        }))

        const sortSupplierOptions = (suppliersOptions, value) => {
            const sortedOptions = sortArrayByObjectKey(suppliersOptions, value);
            return sortedOptions;
        };
        
        return (
            <div className="form-line-format">
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Ref.ID</Form.Label>
                        <div className="form-element">
                            <Form.Control
                                type="text"
                                name="referenceNumber"
                                onChange={handleChange}
                                value={values.referenceNumber}
                                isInvalid={touched.referenceNumber && errors.referenceNumber}
                            >

                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.referenceNumber}</Form.Control.Feedback>
                        </div>

                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Pickup date</Form.Label>
                        <div className="form-element">
                            <div className="wrapper-calendar-asana">
                                <DatePicker
                                    onApply={(event, picker) => {
                                        setFieldValue('pickupDate', picker.startDate.format('DD.MM.YYYY'))
                                    }}
                                    startDate={values.pickupDate ? values.pickupDate : null}
                                >
                                    <>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z"/>
                                        </svg>
                                        <Form.Control
                                            readOnly={true}
                                            isInvalid={errors.pickupDate && touched.pickupDate}
                                            name="pickupDate"
                                            value={values.pickupDate ? values.pickupDate : 'No value'}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors.pickupDate}</Form.Control.Feedback>

                                    </>
                                </DatePicker>
                                {values.pickupDate && <svg onClick={e => {
                                    setFieldValue('pickupDate', '')
                                    document.body.click()
                                }} className="circle-close" viewBox="0 0 24 24">
                                    <path fill="#000000"
                                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/>
                                </svg>
                                }
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Planned Arrival date</Form.Label>
                        <div className="form-element">
                            <div className="wrapper-calendar-asana">
                                <DatePicker
                                    onApply={(event, picker) => {
                                        setFieldValue('targetArrivalDate', picker.startDate.format('DD.MM.YYYY'))
                                    }}
                                    startDate={values.targetArrivalDate ? values.targetArrivalDate : null}
                                >
                                    <>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z"/>
                                        </svg>
                                        <Form.Control
                                            readOnly={true}
                                            isInvalid={errors.targetArrivalDate && touched.targetArrivalDate}
                                            name="targetArrivalDate"
                                            value={values.targetArrivalDate ? values.targetArrivalDate : 'No value'}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors.targetArrivalDate}</Form.Control.Feedback>

                                    </>
                                </DatePicker>
                                {values.targetArrivalDate && <svg onClick={e => {
                                    setFieldValue('targetArrivalDate', '')
                                    document.body.click()
                                }} className="circle-close" viewBox="0 0 24 24">
                                    <path fill="#000000"
                                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/>
                                </svg>
                                }
                            </div>
                        </div>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Pickup Address</Form.Label>
                        <div className="form-element">
                            <Select
                                menuIsOpen={isShowSelectMenu}
                                blurInputOnSelect={isShowSelectMenu}
                                onFocus={ () => {
                                    this.setState({
                                        isShowSelectMenu: true
                                    })
                                }}
                                onBlur={() => {
                                    this.setState({
                                        isShowSelectMenu: false
                                    })
                                }}
                                isDisabled={isEditAddress}
                                className="with-add-button"
                                name="pickupAddress"
                                value={addressesOptions.find(option => option.value === values.pickupAddress)}
                                onChange={obj => {
                                    this.setState({
                                        isShowSelectMenu: false
                                    })
                                    setFieldValue('pickupAddress', obj.value)
                                }}
                                isInvalid={touched.pickupAddress && errors.pickupAddress}
                                options={sortSupplierOptions(addressesOptions, 'value')}
                                errorLabel={errors.pickupAddress}
                            />
                            {!isNewAddress && !isEditAddress && <Button type="button" variant="primary" className="standard transport-add-button"
                                                      onClick={() => {
                                                          this.setState({
                                                              isNewAddress: true
                                                          })
                                                      }}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                                </svg>
                                Add
                            </Button>
                            }
                            {isNewAddress && <div className="wrapper-new-warehouse-address">
                                <Form.Control
                                    isInvalid={valueNewAddress === '' && touchedAddress}
                                    value={valueNewAddress}
                                    onChange={this.handleChange}
                                    placeholder="New warehouse address "
                                />
                                <div className="actions-address">
                                    <Tooltip text="Save">
                                        <svg onClick={() => this.handleCreateWarehouseAddress(setFieldValue)}
                                             viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"/>
                                        </svg>
                                    </Tooltip>
                                    <Tooltip text="Cancel">
                                        <svg onClick={this.handleCancel} viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
                                        </svg>
                                    </Tooltip>
                                </div>
                            </div>
                            }
                            {isEditAddress && <div className="wrapper-edit-warehouse-address">
                                <Form.Control
                                    isInvalid={newAddress === '' && touchedAddress}
                                    value={newAddress}
                                    onChange={e => {
                                        this.setState({
                                            newAddress: e.target.value
                                        })
                                    }}
                                    placeholder="Edit warehouse address"
                                />
                                <div className="actions-address">
                                    <Tooltip text="Save">
                                        <svg onClick={this.handleEditWareHouseAddress}
                                             viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"/>
                                        </svg>
                                    </Tooltip>
                                    <Tooltip text="Cancel">
                                        <svg onClick={() => {
                                            this.setState({
                                                isEditAddress: false,
                                                editAddress: '',
                                                newAddress: ''
                                            })
                                        }} viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
                                        </svg>
                                    </Tooltip>
                                </div>
                            </div>}
                        </div>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Contact</Form.Label>
                        <div className="form-element">
                            <Select
                                name="pickupContacts"
                                value={contactsOptions.find(option => option.value === values.pickupContacts)}
                                onChange={obj => setFieldValue('pickupContacts', obj.value)}
                                isInvalid={touched.pickupContacts && errors.pickupContacts}
                                options={sortSupplierOptions(contactsOptions, 'value')}
                                errorLabel={errors.pickupContacts}
                            />
                            <Button type="button" variant="primary" className="standard transport-add-button"
                                    onClick={() => openHideContact(true)}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                                </svg>
                                Add
                            </Button>
                        </div>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Shipping Company</Form.Label>
                        <div className="form-element">
                            <Form.Control
                                type="text"
                                name="transportShippingCompany"
                                onChange={handleChange}
                                value={values.transportShippingCompany}
                                isInvalid={touched.transportShippingCompany && errors.transportShippingCompany}
                            >

                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.transportShippingCompany}</Form.Control.Feedback>
                        </div>

                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Price</Form.Label>
                        <div className="form-element">
                            <Form.Control
                                type="text"
                                name="addressPrice"
                                onChange={handleChange}
                                value={values.addressPrice}
                                onBlur={e => setFieldValue('addressPrice', formatPriceWithEmptyString(e.target.value))}
                                isInvalid={touched.addressPrice && errors.addressPrice}
                            >

                            </Form.Control>


                            <Form.Control.Feedback type="invalid">{errors.addressPrice}</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Note</Form.Label>
                        <div className="form-element">
                            <Form.Control
                                as="textarea"
                                name="addressNote"
                                onChange={handleChange}
                                value={values.addressNote}
                                isInvalid={touched.addressNote && errors.addressNote}
                            >

                            </Form.Control>


                            <Form.Control.Feedback type="invalid">{errors.addressNote}</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openHideContact: isShow => dispatch(showHideCreateContact(isShow)),
        setDetails: details => dispatch(setDetailsPurchaseAction(details))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BodyForm)