export const fieldsCustomer = {
    category: 'Category',
    quality: 'Quality',
    companyName: 'Company name',
    taxId: 'Tax ID',
    phoneNumber: 'Phone number',
    email: 'Email',
    address: 'Address',
    spokenLanguage: 'Spoken language',
    language: 'Language',
    countity: 'Countity',
    demandCategory: 'Demanded category',
    monthlyQuantity: 'Monthly quantity',
    quantity: 'Monthly quantity',
    zip: 'Zip code',
    country: 'Country',
    busModel: 'Business model',
    contactName: 'First name',
    contactSecond: 'Last Name',
    contactPhone: 'Contact phone',
    contactEmail: 'Contact email',
    contactPosition: 'Contact position',
    countriesOperate: 'Business countries',
    website: 'Website',
    notes: 'Notes'
};
