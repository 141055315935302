import React from 'react'
import {Button, Form} from 'react-bootstrap'
import AutosizeInput from "react-input-autosize";

const BodyForm = props => {
    const {
        handleSubmit,
        values,
        touched,
        errors,
        handleCancel,
        setFieldValue
    } = props
    return (
        <Form
            noValidate
            onSubmit={handleSubmit}
            className="workspaces__form"
        >
            {/*<Form.Group>*/}
            {/*    <Form.Label>Title</Form.Label>*/}
            {/*    <Form.Control*/}
            {/*        name="value"*/}
            {/*        value={values.value}*/}
            {/*        onChange={handleChange}*/}
            {/*        isInvalid={errors.value && touched.value}*/}
            {/*    />*/}
            {/*    <Form.Control.Feedback type="invalid">{errors.value}</Form.Control.Feedback>*/}
            {/*</Form.Group>*/}

            <Form.Group className="horizontal">
                <Form.Label>Title</Form.Label>
                <div className="input-item">
                    <AutosizeInput
                        type="text"
                        placeholder="Enter title"
                        className="auto-resize-wrapper"
                        name="value"
                        value={values.value}
                        onChange={event => {
                            setFieldValue("value", event.target.value);
                        }}
                    />
                    <span className="error-label">{touched.value && errors.value}</span>
                </div>
            </Form.Group>
            <Form.Group className="footer-form footer-form-custom">
                <Button variant="secondary"
                        onClick={handleCancel}>Cancel</Button>
                <Button variant="primary" type="submit">Save</Button>
            </Form.Group>
        </Form>
    )
}

export default BodyForm