import React , { PureComponent } from 'react'
import { Switch, Route} from 'react-router-dom'
import List from './list'

class CustomersModule extends PureComponent{
    render(){
        return (
            <Switch>
                <Route exact path={["/atw/customer/:id", "/atw/customer/:id/:type" , "/atw/customer/"]} component={List} />
            </Switch>
        )
    }
}

export default CustomersModule