import { getBoxByBarcode } from '../../../services/boxes'
import { getPalletByIdService } from '../../../services/pallet'
import { changeRoutePush } from '../../../reuseFunctions/changeRoute';
import { notify } from '../../reuseComponent/toast'
import { history } from '../../../history';

export const listenScanning = (displayRoute, getIsLoading, setIsLoading, redirectHandler) => {
  let scannedBarcode = ''
  let inputStart = null
  let inputStop = null
  let timeoutKeyPress = null
  let countPressedKeys = 0

  document.addEventListener('keypress', handleKeyPress);

  return () => {
    document.removeEventListener('keypress', handleKeyPress)
  }

  function handleKeyPress(e) {
    if (timeoutKeyPress && e.keyCode !== 13) {
        clearTimeout(timeoutKeyPress);
    }
    if(e.target.tagName !== 'INPUT'){
        if(e.keyCode !== 13){
            scannedBarcode += String.fromCharCode(e.charCode)
            inputStop = performance.now()
            countPressedKeys += 1
            if (!inputStart)
                inputStart = inputStop
            timeoutKeyPress = setTimeout(inputTimeoutHandler, 300)
        }
    }
  }

  function inputTimeoutHandler() {
    clearTimeout(timeoutKeyPress);

    if (isScannerInput() && countPressedKeys >= 3)
        handleScan(scannedBarcode)
    else
        clearData()
  }

  function isScannerInput() {
    const val = scannedBarcode
    return (((inputStop - inputStart) / val.length) < 50);
  }

  function handleScan(barCode) {
    if(typeof barCode === 'string'){
        if(barCode.indexOf('bx') === 0){
            getBox(barCode.substr(2))
        }
        else if(barCode.indexOf('pl') === 0){
            getPallet(barCode.substr(2))
        }
        else{
            notify('error','Incorrect barcode')
        }
        clearData()
    }
  }

  function clearData () {
    inputStart = null
    inputStop = null
    countPressedKeys = 0
    scannedBarcode = ''
  }

  function getBox(id) {
    if(!getIsLoading() && id) {
      setIsLoading(true)
      getBoxByBarcode(id)
        .then(res => {
            if(res.data.data && res.data.data.palletId){
                notify('success','Scanned box found')
                if (redirectHandler) {
                  redirectHandler(res.data.data.palletId, res.data.data.id)
                } else  {
                  changeRoutePush(`/atw/inventory/list/${res.data.data.palletId}`,history,{
                      scannedBox: res.data.data.id
                  })
                }
            }
            else{
                notify('error','Pallet with this box does not exist')
            }
        })
        .catch(() => notify('error','Oops something went wrong'))
        .finally(() =>setIsLoading(false))
    }
  }

  function getPallet(id) {
    if(!getIsLoading() && id) {
      setIsLoading(true)
      getPalletByIdService(id)
        .then(res => {
            if(res.data.data.id){
                notify('success', 'Scanned pallet found')
                if (redirectHandler) {
                  redirectHandler(res.data.data.id)
                } else  {
                  const route = res.data.data.palletStatus.archived ? 'archive' : displayRoute;
                  changeRoutePush(`/atw/inventory/${route}/${res.data.data.id}`,history)
                }
            }
            else{
                notify('error','Pallet does not exist')
            }
        })
        .catch(() => notify('error','Oops something went wrong'))
        .finally(() =>setIsLoading(false))
    }
  }
}
