import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Select from '../../../../../../reuseComponent/Select/select-form'
import {
    getValuesForField,
    editConditionInSet,
    saveCondition,
    editCondition,
    removeConditionAction,
    removeCondition
} from '../../../../../../../actions/dividing'
import {notify} from '../../../../../../reuseComponent/toast'
import Tooltip from '../../../../../../reuseComponent/Tooltips'
import { findPermInArray } from '../../../../../../../reuseFunctions/checkPermission'
import {permManageDividing} from "../../../../../../../permissions/productOverview";

class ConditionRow extends PureComponent {

    state = {
        valuesForField: []
    }

    handleFocusFieldValueSelect = field => {
        const {details,setId,countConditions,condition,usedValues} = this.props
        let promise = null
        if(countConditions > 0 && condition.id.toString().indexOf('condition') >= 0){
            promise = getValuesForField(field.value, details.id,setId)
        }
        else if(countConditions === 1)
            promise = getValuesForField(field.value, details.id,setId)
        else if(countConditions > 0)
            promise = getValuesForField(field.value, details.id,setId)
        else
            promise = getValuesForField(field.value, details.id,setId)


        promise
            .then(res => {
                // const filtredValues = this.filterExistingValuesForField(field.value,res.data)
                this.setState({
                    valuesForField: res.data.filter(val => {

                        let res = true
                        if(condition.fieldName && condition.fieldAlias){
                            res = !usedValues[condition.fieldAlias].includes(val)
                        }
                        return res
                    }).map(val => ({
                        label: val,
                        value: val
                    }))
                })
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }

    // Обработчик для типа филда
    handleChange = (field) => {
        const {setId,editCondition,condition} = this.props
        editCondition({
            setId: setId,
            data : {
                id: condition.id,
                fieldAlias: field.value,
                fieldName: field.label,
                values: []
            }
        })
    }
    // Обработчик на значение по филду
    handleChangeValues = values => {
        const { setId,editCondition,condition,ipp,saveCondition,editConditionRequest } = this.props
        editCondition({
            setId: setId,
            data: {
                id: condition.id,
                values: values ? values.map(val => val.value) : []
            }
        })
        if(ipp > 0 &&  values && values.length > 0 && condition.fieldAlias && condition.fieldName){
            if(condition.id.toString().indexOf('condition') >= 0){
                saveCondition
                && saveCondition({
                    setId: setId,
                    values: values ? values: [],
                    fieldName: condition.fieldName,
                    fieldAlias: condition.fieldAlias
                })
            }
            else{
                editConditionRequest({
                    setId: setId,
                    values: values ? values: [],
                    fieldName: condition.fieldName,
                    fieldAlias: condition.fieldAlias,
                    conditionId: condition.id
                })
            }
        }

    }

    handleRemoveCondition = conditionId => {
        const { setId,removeCondition,removeConditionRequest } = this.props
        if(conditionId.toString().indexOf('condition') >= 0){
            removeCondition(setId,conditionId)
        }
        else{
            removeConditionRequest(setId,conditionId)
        }
    }

    render() {
        const {fields,condition,status,roles,details,set} = this.props
        const {valuesForField} = this.state
        let existFields = []
        set.conditions.forEach(condition => {
            if(!existFields.includes(condition.fieldAlias)){
                existFields.push(condition.fieldAlias)
            }
        })
        const fieldOptions = Object.keys(fields).filter(key => !existFields.includes(key)).map(key => ({
            label: fields[key],
            value: key
        }))

        return (
            <div className="condition-row">
                <div className="field-condition">
                    <Select
                        value={condition.fieldName && condition.fieldAlias ? {
                            label: condition.fieldName,
                            value: condition.fieldAlias
                        } : null}
                        isDisabled={status || !findPermInArray(roles,permManageDividing) || details.completeInbound}
                        onChange={obj => this.handleChange(obj)}
                        options={fieldOptions}
                    />
                </div>
                <div className="value-condition">
                    {valuesForField && <Select
                        onFocus={() => condition.fieldName && condition.fieldAlias && this.handleFocusFieldValueSelect({
                            value: condition.fieldAlias,
                            label: condition.fieldName
                        })}
                        value={condition.values}
                        isDisabled={status || !findPermInArray(roles,permManageDividing) || details.completeInbound}
                        onChange={this.handleChangeValues}
                        options={valuesForField}
                        isMulti={true}
                    />}
                </div>
                {findPermInArray(roles,permManageDividing) && !details.completeInbound && !status &&  <div className="remove-condition" onClick={() => this.handleRemoveCondition(condition.id)}>
                    <Tooltip
                        text="Delete condition"
                    >
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
                        </svg>
                    </Tooltip>
                </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fields: state.dividingReducer.fieldsForCondition,
        details: state.purchaseOrdersReducer.detailsPurchase,
        status: state.dividingReducer.dividingStatus,
        roles: state.rolesReducer.roles,
        conditionSets: state.dividingReducer.conditionSets
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editCondition: obj => dispatch(editConditionInSet(obj)),
        saveCondition: obj => dispatch(saveCondition(obj)),
        editConditionRequest: data => dispatch(editCondition(data)),
        removeCondition: (setId,conditionId) => dispatch(removeConditionAction(setId,conditionId)),
        removeConditionRequest: (setId,conditionId) => dispatch(removeCondition(setId,conditionId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConditionRow)