import React ,{ PureComponent } from 'react'
import { connect } from 'react-redux'
import {Button} from 'react-bootstrap'
import FiltersList from '../../../../reuseComponent/smart-filter/filters-list'
import FilterModal from '../../../../reuseComponent/smart-filter/modalFilter'
import FormFilter from '../../../../reuseComponent/smart-filter/form'
import EditFilter from '../../../../reuseComponent/smart-filter/editFilter'
import {ConfirmModalContent} from '../../../../reuseComponent/confirmModalComponent'
import {createOfferFilter, deleteFilterById, editFilterById, setActiveFilter} from '../../../../../services/smart-filter'
import {notify} from '../../../../reuseComponent/toast'
import {getFilters} from '../../../../../actions/smart-filter'
import ResponsibleCompoent from "../responsibleComponent";
import CustomModalWithChildren from "../../../../reuseComponent/modal/modalWithChildren";
import {permViewAllCustomers} from "../../../../../permissions/customers";

const aliasCustomers = 'customers'

class ToolbarCustomers extends PureComponent{

    state = {
        openCreateModal: false,
        openEditModal: false,
        openDeleteModal: false,
        currFilter: null,
        removedTitle: '',
        isLoadingFilterModal: false
    }

    openCreateModal = () => this.setState({openCreateModal: true})

    hideCreateModal = () => this.setState({openCreateModal: false, isLoadingFilterModal: false})

    openEditModal = (idFilter) => this.setState({openEditModal: true, currFilter: idFilter})

    hideEditModal = () => this.setState({openEditModal: false, currFilter: null, isLoadingFilterModal: false})

    openDeleteModal = (idFilter, removedTitle) => {
        this.setState({
            openDeleteModal: true,
            currFilter: idFilter ,
            removedTitle
        })
    }

    hideDeleteModal = () => this.setState({openDeleteModal: false, currFilter: null})

    setLoading = (isLoading, callback) => this.setState({isLoadingFilterModal: isLoading}, () => callback && callback())

    handleCreateFilter = values => {
        const promise = createOfferFilter(values,aliasCustomers)
        this.setLoading(true, () => {
            promise
                .then(res => {
                    this.updateViewAndFilter()
                    this.hideCreateModal()
                    notify('success', 'Filter successfully created')
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })

    }

    handleActivateFilter = (id) => {
        const promise = setActiveFilter(id,aliasCustomers)
        promise
            .then(this.updateViewAndFilter)
            .catch(err => notify('error', 'Oops something went wrong'))
    }

    updateViewAndFilter = () => {
        const {onSearchChanged, getFilters} = this.props
        onSearchChanged && onSearchChanged('')
        getFilters && getFilters(aliasCustomers)
    }

    handleEditFilter = values => {
        const {currFilter} = this.state
        this.setLoading(true, () => {
            editFilterById(currFilter, values)
                .then(res => {
                    notify('success', 'Filter successfully edited')
                    this.hideEditModal()
                    this.updateViewAndFilter()
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                    this.hideEditModal()
                })
        })

    }

    handleDeleteFilter = () => {
        const {currFilter} = this.state
        this.hideDeleteModal()
        deleteFilterById(currFilter)
            .then(res => {
                this.updateViewAndFilter()
                notify('success', 'Filter successfully deleted')
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }

    // componentDidMount() {
    //     // const {getFilters} = this.props
    //     // getFilters && getFilters(aliasCustomers)
    // }

    render(){
        const {
            pagination,
            paginationPosition,
            handleAdd,
            isBulkEdit,
            activeDisableBulkMode,
            customers,
            isAllChecked,
            updateTableAfterBulk,
            resLength,
            handleBulkAllocate,
        } = this.props
        const {openCreateModal, openEditModal, openDeleteModal, currFilter, isLoadingFilterModal, removedTitle} = this.state
        return (
            <div className="toolbar-customer custom-toolbar">
                <div className="actions-customer-toolbar actions-toolbar buttons-bulk-edit">
                    {handleAdd && <Button onClick={handleAdd} disabled={isBulkEdit} className="add-button-customer add-button">
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                        </svg>
                        Add
                    </Button>
                    }
                    {isBulkEdit && resLength > 0 && (customers.length > 1 || isAllChecked) &&
                    <ResponsibleCompoent
                        style={{
                            marginRight: '15px'
                        }}
                        isBulk={true}
                        selectedCustomers={customers}
                        isAllSelected={isAllChecked}
                        updateTable={updateTableAfterBulk}
                        handleBulkAllocate={handleBulkAllocate}
                    />}
                    {resLength > 0 && <Button
                        className="bulk-active-mode-button"
                        onClick={activeDisableBulkMode}
                    > {isBulkEdit ?
                        <>
                            <svg viewBox="0 0 24 24">
                                <path
                                    fill="#000000"
                                    d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                                />
                            </svg>
                            Cancel
                        </> :
                        <>
                            <svg viewBox="0 0 24 24">
                                <path
                                    fill="#000000"
                                    d="M21,19V17H8V19H21M21,13V11H8V13H21M8,7H21V5H8V7M4,5V7H6V5H4M3,5A1,1 0 0,1 4,4H6A1,1 0 0,1 7,5V7A1,1 0 0,1 6,8H4A1,1 0 0,1 3,7V5M4,11V13H6V11H4M3,11A1,1 0 0,1 4,10H6A1,1 0 0,1 7,11V13A1,1 0 0,1 6,14H4A1,1 0 0,1 3,13V11M4,17V19H6V17H4M3,17A1,1 0 0,1 4,16H6A1,1 0 0,1 7,17V19A1,1 0 0,1 6,20H4A1,1 0 0,1 3,19V17Z"
                                />
                            </svg>
                            Select
                        </>}
                    </Button>
                    }
                    <FiltersList
                        setActiveFilter={this.handleActivateFilter}
                        openCreateModal={this.openCreateModal}
                        aliasesToCheck={[permViewAllCustomers]}
                        openEditModal={this.openEditModal}
                        openDeleteModal={this.openDeleteModal}
                    />
                </div>
                {paginationPosition === 'toolbar'
                &&
                <div className="pagination-toolbar">
                    {pagination}
                </div>
                }
                <FilterModal
                    open={openCreateModal}
                    handleClose={this.hideCreateModal}

                >
                    <FormFilter
                        isCustomers={true}
                        isLoading={isLoadingFilterModal}
                        handleSubmit={this.handleCreateFilter}
                        alias={aliasCustomers}
                        handleCancel={this.hideCreateModal}
                        initValues={{
                            name: '',
                            criterias: [],
                            orCriterias: []
                        }}
                    />
                </FilterModal>
                <FilterModal
                    open={openEditModal}
                    handleClose={this.hideEditModal}
                >
                    <EditFilter
                        isCustomers={true}
                        alias={aliasCustomers}
                        idFilter={currFilter}
                        isLoading={isLoadingFilterModal}
                        handleCancel={this.hideEditModal}
                        handleSubmit={this.handleEditFilter}
                    />
                </FilterModal>
                <CustomModalWithChildren
                    open={openDeleteModal}
                    Content={ConfirmModalContent}
                    handleConfirm={this.handleDeleteFilter}
                    handleClose={this.hideDeleteModal}
                    handleCancel={this.hideDeleteModal}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`delete "${removedTitle}" filter`}
                    />
                </CustomModalWithChildren>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    roles: state.rolesReducer.roles,
});

export default connect(mapStateToProps,{
    getFilters
})(ToolbarCustomers)
