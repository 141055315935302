import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Tooltip from '../../../../../reuseComponent/Tooltips'
import { Button } from 'react-bootstrap'


class FiltersList extends PureComponent {

    state = {
        isShow: false
    }

    listWrapperNode = React.createRef()

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutSide)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutSide)
    }

    handleClickOutSide = event => {
        if (this.listWrapperNode.current && !this.listWrapperNode.current.contains(event.target)) {
            this.setState({
                isShow: false
            })
        }
    }

    render() {
        const {isShow} = this.state
        const {
            openCreateModal,
            openEditModal,
            openDeleteModal,
            filters,
            setActiveFilter
        } = this.props
        const activeFilter = filters.activeFilter ? filters.userFilters.find(filter => filter.id === filters.activeFilter) : null
        const undeletableFilters = filters.userFilters ? filters.userFilters.filter(filter => !filter.deletable) : []
        const deletableFilters = filters.userFilters ? filters.userFilters.filter(filter => filter.deletable) : []
        return (
            <div ref={this.listWrapperNode} className="filters-list-wrapper">
                <Button className="with-icon" onClick={e => this.setState(state => ({
                    isShow: !state.isShow
                }))}>
                    <svg viewBox="0 0 24 24">
                        <path fill="#000000" d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"/>
                    </svg>
                    <span>{activeFilter ? activeFilter.name : 'No filter'}</span>
                </Button>
                <ul className={`filters-list ${isShow ? 'open' : ''}`}>
                    <li onClick={() => {
                        this.setState({
                            isShow: false
                        }, () => setActiveFilter('no'))
                    }} className={`no-filter ${!filters.activeFilter ? 'active' : ''}`}>
                        {!filters.activeFilter && <svg className="selected-filter-icon" viewBox="0 0 24 24">
                            <path fill="#000000" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/>
                        </svg>}
                        No filter
                    </li>
                    {undeletableFilters.map(filter => {
                        const isActive = filter.id === filters.activeFilter
                        return <li
                            onClick={e => {
                                this.setState({
                                    isShow: false
                                }, () => setActiveFilter(filter.id))
                            }}
                            key={filter.id}
                            className={isActive ? 'active' : ''}
                        >
                            {isActive && <svg className="selected-filter-icon" viewBox="0 0 24 24">
                                <path fill="#000000" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/>
                            </svg>}
                            {filter.name}
                        </li>
                    })}
                    {deletableFilters.map((filter,index) => {
                        const isActive = filter.id === filters.activeFilter
                        return <li
                            onClick={e => {
                                this.setState({
                                    isShow: false
                                }, () => setActiveFilter(filter.id))
                            }}
                            key={filter.id}
                            className={isActive ? index === 0 ? 'active deletable-li' : 'active' : index === 0 ? 'deletable-li' : ''}
                        >
                            {isActive && <svg className="selected-filter-icon" viewBox="0 0 24 24">
                                <path fill="#000000" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/>
                            </svg>}
                            {filter.name}
                            <div className="actions-filter">
                                <Tooltip text="Edit">
                                    <button className="filter-button-action" onClick={e => {
                                        e.stopPropagation()
                                        openEditModal(filter.id)
                                    }}>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"/>
                                        </svg>
                                    </button>
                                </Tooltip>
                                <Tooltip text="Delete">
                                    <button className="filter-button-action" onClick={e => {
                                        e.stopPropagation()
                                        openDeleteModal(filter.id, filter.name)
                                    }}>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
                                        </svg>
                                    </button>
                                </Tooltip>
                            </div>
                        </li>
                    })}
                    <li className="add-new-filter"
                        onClick={() => {
                            this.setState({
                                isShow: false
                            }, openCreateModal)
                        }}
                    >
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z"/>
                        </svg>
                        Add filter
                    </li>
                </ul>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        filters: state.poSmartFiltersReducer.filters
    }
}

export default connect(mapStateToProps, null)(FiltersList)