import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {ReactComponent as MenuRigth} from '../../../../assets/images/menu-rigth.svg';
import {ReactComponent as ItemsIcon} from '../../../../assets/images/item.svg';
import {ReactComponent as EditIcon} from '../../../../assets/images/edit.svg';
import {ReactComponent as OpenBoxIcon} from '../../../../assets/images/opened_box.svg';
import {ReactComponent as CloseBoxIcon} from '../../../../assets/images/closed_box.svg';
import {Collapse, Button} from 'react-bootstrap';
import {getProductsFromBox,moveArticles} from '../../../../services/boxes'
import Preloader from '../../../reuseComponent/preloader/preloader'
import {notify} from '../../../reuseComponent/toast'
import BoxProductsTable from './boxProductsTable'
import Tooltip from '../../../reuseComponent/Tooltips'
import CustomCheckBox from '../../../reuseComponent/checkbox'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission';
import {permEditInventory} from '../../../../permissions/inventory';
import Modal from '../../../reuseComponent/modal/modalWithChildren'
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import ModalMoveArticles from './moveArticlesModal'
import {ReactComponent as MoveIcon} from '../../../../assets/images/package-up.svg';
import EditProductsBulk from '../editArticle'
import { removeEmptyValues } from '../../../../reuseFunctions/removeEmptyValues';
import { editProductsInBox } from '../../../../services/products'
import {defaultServerError} from '../../../../reuseFunctions/toasts';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg'
import CreateProduct from "../../purchaseOrders/products/scanComponent/createProductFormInventory";
import PutForm from "../../purchaseOrders/products/scanComponent/putProduct";

class BoxCollapse extends PureComponent {

    state = {
        open: false,
        isLoading: true,
        products: [],
        modalOpen: false,
        modalMoveOpen: false,
        selectedProducts: [],
        modalEditOpen: false,
        isConfirm: false,
        values: {},
        isShowModal: false,
        status: null,
        activeWorkPlace: null,
        packageItem: null,
        selectedQuality: null,
        scannedEan: '',
        productItem: null,
        migrated: false,
    }

    headerRef = React.createRef()

    handleSelectUnselectProduct = productId => {
        const {selectedProducts} = this.state
        this.setState({
            selectedProducts: selectedProducts.includes(productId)
                ? selectedProducts.filter(id => id !== productId)
                : selectedProducts.concat(productId)
        })

    }

    handleSelectUnselectAll = () => {
        this.setState(state => ({
            selectedProducts: this.state.selectedProducts.length !== this.state.products.length
                ? state.products.map(item => item.id)
                : []
        }))
    }

    handleOpenCloseCollapse = (e) => {
        const checkBox = this.headerRef.current ? this.headerRef.current.querySelector('.checkbox-material') : null
        if (checkBox) {
            if (checkBox && !checkBox.contains(e.target))
                this.setState(state => ({
                    open: !state.open
                }))
        }
        else
            this.setState(state => ({
                open: !state.open
            }))

    }

    getProducts = () => {
        const {box} = this.props
        getProductsFromBox(box.id)
            .then(res => {
                this.setState({
                    isLoading: false,
                    products: res.data.data.map(pr => ({
                        ...pr.product,
                        id: pr.id,
                        originId: pr.product.id,
                        count: pr.itemsCount
                    }))
                })
            })
            .catch(err => notify('error', 'Oops something went wrong'))
    }

    handleShowMoveProductsModal = e => {
        e.stopPropagation()
        this.setState({modalMoveOpen: true})
    }

    handleHideMoveProductsModal = () => {
        this.setState({modalMoveOpen: false, values: {}, isConfirm: false})
    }

    handleShowEditProductsModal = e => {
        e.stopPropagation()
        this.setState({modalEditOpen: true})
    }

    handleHideEditProductsModal = () => {
        this.setState({modalEditOpen: false})
    }

    handleShowModal = (e) => {
        e.stopPropagation()
        this.setState({modalOpen: true})
    }

    handleHideModal = () => this.setState({modalOpen: false})

    handleMoveArticles = (values) => {
        const { selectedProducts } = this.state
        const { callbackMoving, refetchData } = this.props
        values.boxProductIds = [...selectedProducts]
        this.setState({
            isLoading: true,
            selectedProducts: [],
            modalMoveOpen: false,
            isConfirm: false,
            values: {}
        },() => {
            moveArticles(values)
                .then(res => {
                    callbackMoving()
                    refetchData()
                    notify('success','Articles successfully moved')
                })
                .catch(err => notify('error','Oops something went wrong'))
        })

    }

    handleEditArticlesInBulk = (values) => {
        const notEmptyValues = removeEmptyValues(values)
        const { selectedProducts,products } = this.state
        const { box } = this.props
        const data = new FormData()
        Object.keys(notEmptyValues).forEach(key => {
            data.append(key,notEmptyValues[key])
        })
        const idsProducts = products.filter(pr => {
            return selectedProducts.includes(pr.id)
        }).map(pr => pr.originId)

        idsProducts.forEach(idProduct => data.append('productIds',idProduct))
        this.setState({
            isLoading: true,
            modalEditOpen: false,
            selectedProducts: []
        },() => {
            editProductsInBox(data,box.id)
                .then(res => {
                    notify('success',`${idsProducts.length > 1 ? 'Articles' : 'Article'} successfully edited`)
                    this.getProducts()
                })
                .catch(err => defaultServerError())
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(findPermInArray(prevProps.roles, permEditInventory)
          && !findPermInArray(this.props.roles, permEditInventory)){
            this.setState({
                modalOpen: false,
                modalMoveOpen: false,
                selectedProducts: [],
                modalEditOpen: false,
            })
        }
    }

    handleOpenCreateArticleForm = () => {
        this.setState({
            isShowModal: true,
            status: 'unordered',
            packageItem: null,
            productDetails: null,
            scannedEan: '',
            productItem: null,
        })
    }

    hideModal = () => {
        this.handleCloseManually()
        this.setState({isShowModal: false, productItem: null, packageItem: null, search: ''})
    }

    handleCloseManually = () => this.setState({isEnteredManually: false, manuallyEan: ''})

    render() {
        const {box, handleSelect, isSelected, roles, isScanned, handleOpenCloseBox,removeBox, method, migrated} = this.props
        const {open, isLoading, products, modalOpen, selectedProducts,modalMoveOpen,modalEditOpen, isConfirm, values, isShowModal, productItem, status, scannedEan, activeWorkPlace, packageItem, selectedQuality} = this.state
        let {boxes} = this.state
        boxes = [box];
        const hasPermToEdit = findPermInArray(roles, permEditInventory)
        const articlesCount = products.filter(pr =>selectedProducts.includes(pr.id)).reduce((acc, item) => acc + item.count, 0)

        return <div className="box__row">
            <div className="row__header" ref={this.headerRef} onClick={this.handleOpenCloseCollapse}>
                <div
                    className={`toggle ${open ? 'opened' : ''}`}
                    // onClick={this.handleOpenCloseCollapse}
                >
                    <MenuRigth/>
                </div>
                <div className={`info__header ${open ? 'opened' : ''} ${isScanned ? 'scanned-box' : ''}`}>
                    {hasPermToEdit && <div className="checkbox-box-wrapper">
                        <CustomCheckBox
                            checked={isSelected}
                            handleChange={() => handleSelect(box.id)}
                            options={{
                                className: 'checkbox-material',
                            }}
                        />
                    </div>
                    }
                    <div className="title__box">
                        {box.pack ? `${box.pack.orderNumber} > ${box.pack.shortName} > ${method !== 'INVENTORY' && box.workplace ? box.workplace + '-' + box.name + ' >' : ''}` : null} #{box.id}
                    </div>
                    <div className="actions">
                        {hasPermToEdit && selectedProducts.length > 0 && <Button onClick={this.handleShowEditProductsModal}>
                            <EditIcon className="standart-svg"/>
                            Edit articles
                        </Button>
                        }
                        {hasPermToEdit && selectedProducts.length > 0 && <Button onClick={this.handleShowMoveProductsModal}>
                            <MoveIcon className="standart-svg"/> Move articles ({selectedProducts.length})
                        </Button>}

                        <Button disabled={migrated || box.closed || !box.pack || !box.pack.orderNumber || !box.poInboundType || box.poInboundType == 'Totals'} onClick={this.handleOpenCreateArticleForm} type="button">Create Article</Button>

                        <Button disabled={!hasPermToEdit} onClick={this.handleShowModal}>
                            {box.closed
                                ? <CloseBoxIcon className="standart-svg"/>
                                : <OpenBoxIcon className="standart-svg"/>
                            }
                            {box.closed ? 'Open' : 'Close'} Pack
                        </Button>
                    </div>
                    <div className={`total-count ${box.productsCount === 0 ? 'empty-box-error' : ''}`}>
                        <Tooltip text="Items">
                            <ItemsIcon className="standart-svg"/>
                        </Tooltip> {box.productsCount}
                    </div>
                    {findPermInArray(roles, permEditInventory) && <div className="delete-icon" >
                        {box.productsCount === 0 && <Tooltip text="Delete">
                            <DeleteIcon onClick={removeBox} className="standart-svg"/>
                        </Tooltip>
                        }
                    </div>}
                </div>
            </div>
            <Collapse
                onEntered={this.getProducts}
                onExited={() => this.setState({isLoading: true})}
                in={open}
            >
                <div>
                    <div className="row__content">
                        <div className="details__line">
                            <div className="line"></div>
                        </div>
                        <div className="content">
                            {isLoading
                                ? <Preloader/>
                                : <BoxProductsTable
                                    selectUnselectAll={this.handleSelectUnselectAll}
                                    selectedProducts={selectedProducts}
                                    handleSelect={this.handleSelectUnselectProduct}
                                    products={products}
                                    boxClosed={box.closed}
                                />
                            }
                        </div>
                    </div>
                </div>
            </Collapse>
            {modalEditOpen && <Modal

                open={modalEditOpen}
                handleClose={this.handleHideEditProductsModal}
                isFooter={false}
                options={{
                    centered: true,
                    animation: false
                }}
            >
                <EditProductsBulk
                    handleSubmit={this.handleEditArticlesInBulk}
                    handleClose={this.handleHideEditProductsModal}
                />
            </Modal>
            }

            <Modal
                open={isShowModal}
                isFooter={false}
                backdropClassName="create-product-modal-backdrop"
                options={{
                    centered: true,
                    animation: false
                }}
               >

               <CreateProduct
                    activeWorkPlace={activeWorkPlace}
                    packageItem={packageItem}
                    selectedQuality={selectedQuality}
                    handleClose={this.hideModal}
                    ean={scannedEan}
                    productDetails={null}
                    box={box}
                />

            </Modal>

            {modalOpen && <Modal
                handleClose={this.handleHideModal}
                handleCancel={this.handleHideModal}
                handleConfirm={() => {
                    this.handleHideModal()
                    handleOpenCloseBox(box.id, box.closed)
                }}
                open={modalOpen}
                options={{
                    centered: true,
                    animation: false
                }}
            >
                <ConfirmModalContent text={`${box.closed ? 'open' : 'close'} 
                ${box.pack ? box.pack.orderNumber + ' > ' + box.pack.shortName + ' > ' : ''} PACK ${box.pack ? box.name : box.id}`}/>
            </Modal>
            }
            {modalMoveOpen && <Modal
                open={modalMoveOpen}
                isFooter={isConfirm}
                handleConfirm={() => this.handleMoveArticles(values)}
                handleCancel={this.handleHideMoveProductsModal}
                handleClose={this.handleHideMoveProductsModal}
                options={{
                    centered: true
                }}
            >
                <ModalMoveArticles
                    currentBox={box.id}
                    handleConfirm={(values) => this.setState({values, isConfirm:true})}
                    handleClose={this.handleHideMoveProductsModal}
                    isConfirm={isConfirm}
                    articlesCount={articlesCount}
                    selectedProducts={selectedProducts}
                />
            </Modal>}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps, null)(BoxCollapse)