import React from 'react'

import { Switch, Route } from 'react-router-dom'
import List from './list'
import Archived from './archived'
import { InventoryMap } from './InventoryMap'
import { MapDictionary } from './MapDictionary'
import { InventoryMapEditor } from './InventoryMapEditor'
import ProtectedRoute from "../../reuseComponent/Routes/protected-route";
import {permEditInventory, permViewInventory, permViewMapEditor, permEditMapEditor} from "../../../permissions/inventory";

const InventoryModule = () => {
  return (
      <Switch>
          <ProtectedRoute permission={[
              permEditInventory,
              permViewInventory
          ]} exact path={["/atw/inventory/list/:id" , "/atw/inventory/list"]} component={List} />
          <ProtectedRoute permission={[
              permEditInventory,
              permViewInventory
          ]} exact path={["/atw/inventory/archive/:id" , "/atw/inventory/archive"]} component={Archived} />
          <ProtectedRoute permission={[
            permEditInventory,
            permViewInventory
          ]} exact path={["/atw/inventory/map", "/atw/inventory/map/:id"]} component={InventoryMap} />
          <ProtectedRoute permission={[
            permViewMapEditor,
          ]} exact path={["/atw/inventory/map-editor"]} component={InventoryMapEditor} />
      </Switch>
  )
}

export default InventoryModule