import React, {PureComponent} from 'react';
import {Button} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import {Form} from 'react-bootstrap';
import {getPallets} from '../../../../services/inventory';
import Select from '../../../reuseComponent/Select/async-select';
import {getPallet} from '../../../../services/inventory';
import {ReactComponent as CheckCircleIcon} from '../../../../assets/images/check-circle.svg';
import {ReactComponent as CircleIcon} from '../../../../assets/images/empty-circle.svg';
import Tooltip from '../../../reuseComponent/Tooltips';
import {ReactComponent as ItemIcon} from '../../../../assets/images/item.svg';
import {ReactComponent as BoxIcon} from '../../../../assets/images/box.svg';
import {ReactComponent as PlusIcon} from '../../../../assets/images/plus.svg';
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';

class MoveArticlesModal extends PureComponent {
    state = {
        isLoading: false,
        boxes: [],
        selectedPallet: null,
        selectedBox: null,
        isTouched: false,
        newBox: false,
        quantity: ''
    };

    getBoxes = palletId => {
        getPallet(palletId).then(res => {
            this.setState({
                boxes: res.data.data.boxes.map(box => ({
                    ...box,
                    count: box.productsCount,
                }))
            });
        });
    };

    handleSubmit = () => {
        const {selectedBox, selectedPallet, quantity} = this.state;
        const {handleConfirm, selectedProducts, articlesCount} = this.props;
        this.setState(
            {
                isTouched: true
            },
            () => {
                if (selectedPallet && selectedBox && selectedProducts.length > 1) {
                    handleConfirm({
                        palletId: selectedPallet,
                        newBoxId: selectedBox === 'new' ? '' : selectedBox
                    });
                }
                if (
                    selectedPallet &&
                    selectedBox &&
                    quantity <= articlesCount &&
                    quantity && quantity > 0
                ) {
                    handleConfirm({
                        palletId: selectedPallet,
                        newBoxId: selectedBox === 'new' ? '' : selectedBox,
                        count: quantity
                    });
                }
            }
        );
    };

    getPalletsForSelect = (inputValue) => {
        return new Promise(resolve => {
            getPallets({
                limit: 100,
                page: 0,
                search: `s:${inputValue}`
            }).then(res => {
                resolve(res.data.data.content.map(pallet => ({
                    value: pallet.id,
                    label: (
                        <div className="label-pallet">
                            <div className="label">Pallet #{pallet.id}</div>
                            {pallet.info.brands.length > 0 && (
                                <div>Brands: {pallet.info.brands.join(',')}</div>
                            )}
                            {pallet.info.cg1.length > 0 && (
                                <div>Cg1: {pallet.info.cg1.join(',')}</div>
                            )}
                            {pallet.info.cg2.length > 0 && (
                                <div>Cg2: {pallet.info.cg2.join(',')}</div>
                            )}
                            {pallet.info.cg3.length > 0 && (
                                <div>Cg3: {pallet.info.cg3.join(',')}</div>
                            )}
                        </div>
                    )
                })))
            })
        })
    }

    handleCreateBox = () => {
        this.setState(state => ({
            newBox: true,
            selectedBox: 'new'
        }));
    };

    // handleRemoveBox = e => {
    //     e.stopPropagation()
    //     this.setState(state => ({
    //         newBox: false,
    //         selectedBox: null
    //     }))
    // }

    render() {
        const {
            handleClose,
            currentBox,
            isConfirm,
            articlesCount,
            selectedProducts
        } = this.props;
        const {
            isTouched,
            selectedPallet,
            boxes,
            selectedBox,
            newBox,
            quantity
        } = this.state;
        return !isConfirm ? (
            <div className="move-articles-modal-wrapper">
                <p className="move-action-title">
                    You are going to move{' '}
                    {selectedProducts.length > 1 ? articlesCount : ''}{' '}
                    {`${articlesCount > 1 ? 'items' : 'item'}`} from pallet #
                    {this.props.match.params.id}
                </p>
                <div className="select-wrapper">
                    <Select
                        onChange={obj =>
                            this.setState(
                                {
                                    selectedPallet: obj.value
                                },
                                () => this.getBoxes(obj.value)
                            )
                        }
                        loadOptions={this.getPalletsForSelect}
                        defaultOptions={true}
                        placeholder="Select pallet"
                        isInvalid={isTouched && !selectedPallet}
                        errorLabel={'required field'}
                    />
                </div>
                <div className="boxes-wrapper">
                    {boxes
                        .filter(box => box.id !== currentBox)
                        .map(box => {
                            const isSelected = box.id === selectedBox;
                            return (
                                <div
                                    className={`box__wrap ${isSelected ? 'selected' : ''}`}
                                    key={box.id}
                                    onClick={() =>
                                        this.setState({
                                            selectedBox: box.id
                                        })
                                    }
                                >
                                    <div className="name-box">{box.pack ? `${box.pack.orderNumber} > ${box.pack.shortName} > ` : null} #{box.custom ? box.id : box.name}</div>
                                    <div className="items-count">
                                        <Tooltip text="Items">
                                            <ItemIcon className="standart-svg"/>
                                        </Tooltip>{' '}
                                        {box.count}
                                        <div className="icon-wrap">
                                        {isSelected ? (
                                            <CheckCircleIcon className="standart-svg"/>
                                        ) : (
                                            <CircleIcon className="standart-svg"/>
                                        )}
                                    </div>
                                    </div>
                                    <div className="box-bg">
                                        <BoxIcon/>
                                    </div>
                                </div>
                            );
                        })}
                    {newBox && (
                        <div
                            className={`box__wrap new-box ${
                                selectedBox === 'new' ? 'selected' : ''
                                }`}
                            onClick={() =>
                                this.setState({
                                    selectedBox: 'new'
                                })
                            }
                        >
                            
                            <div className="name-box">New box</div>
                            <div className="icon-wrap">
                                {selectedBox === 'new' ? (
                                    <CheckCircleIcon className="standart-svg"/>
                                ) : (
                                    <CircleIcon className="standart-svg"/>
                                )}
                                
                            </div>
                            <div className="box-bg">
                                <BoxIcon/>
                            </div>
                        </div>
                    )}
                    {selectedPallet && !newBox && (
                        <Button onClick={this.handleCreateBox} className='add-box-btn'>
                            <PlusIcon className="standart-svg"/>
                            Add pack
                        </Button>
                    )}
                    {isTouched && selectedPallet && !selectedBox && (
                        <span className="error-label">required field</span>
                    )}
                    {selectedPallet && selectedBox && selectedProducts.length === 1 && (
                        <Form.Group>
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control
                                onChange={e =>
                                    this.setState({
                                        quantity: e.target.value
                                    })
                                }
                                isInvalid={isTouched && !quantity}
                                value={quantity}
                            />
                            {isTouched  && (
                                <span className="error-label">
                                    {!quantity && 'Required field'}
                                    {quantity > articlesCount && 'Quantity must be less or equal to the items'}
                                    {quantity && (isNaN(Number(quantity))|| Number(quantity) <= 0) && 'Positive numbers only'}
                                </span>
                            )}
                        </Form.Group>
                    )}
                </div>
                <div className="footer-form footer-form-custom">
                    <Button className="cancel" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={this.handleSubmit}>Save</Button>
                </div>
            </div>
        ) : (
            <div>
                <ConfirmModalContent
                    text={`move ${
                        selectedProducts.length > 1 ? articlesCount : quantity
                        } ${
                        selectedProducts.length > 1
                            ? 'items'
                            : quantity > 1
                            ? 'items'
                            : 'item'
                        } to pallet #${selectedPallet}`}
                />
            </div>
        );
    }
}

export default withRouter(MoveArticlesModal);
