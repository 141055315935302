import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import Preloader from '../../../reuseComponent/preloader/preloader'
import { schema } from './schema'
import BodyForm from './formBody'
import {getNoteById, createNote, editNote} from "../../../../actions/releaseNotes"
import moment from 'moment'

class ReleaseNotesForm extends PureComponent{

    componentDidMount(){
        const { idNote, getNoteById } = this.props;
        idNote && getNoteById && getNoteById(idNote)
    }

    handleSubmit = values => {
        const { idNote,createNote,editNote,handleClose } = this.props;
        let data = {
            version: values.version,
            description: values.description,
            date: moment(values.date,'DD.MM.YYYYTHH:mm').format('YYYY-MM-DDTHH:mm')
        };
        if(idNote)
            handleClose(() => editNote({
                id: idNote,
                values: data
            }))
        else
            handleClose(() => createNote({
                values: data
            }))

    };

    render(){
        const { isLoadingNote,handleClose,note } = this.props;
        return (
            isLoadingNote ? <Preloader /> : <Formik
                initialValues={note}
                validationSchema={schema}
                onSubmit={this.handleSubmit}
                render={props => <BodyForm {...props} handleClose={handleClose} />}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoadingNote: state.releaseNotesReducer.isLoadingNote,
        note: state.releaseNotesReducer.note
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getNoteById: id => dispatch(getNoteById(id)),
        createNote: obj => dispatch(createNote(obj)),
        editNote: obj => dispatch(editNote(obj)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ReleaseNotesForm)