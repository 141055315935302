import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import Preloader from '../../../../reuseComponent/preloader/preloader'
import adminInstance from '../../../../instances/admininstance'
import { url } from '../../../../../constants'
import axios from 'axios'
import { notify } from '../../../../reuseComponent/toast'
import BodyForm from './bodyForm'
import { removeEmptyValues } from '../../../../../reuseFunctions/removeEmptyValues'
import { schema } from './schema'
import {getDetail} from '../../../../../actions/purchase-orders'


class BulkEditForm extends PureComponent{

    state = {
        isLoading: true,
        simpleParameters: {
            cg1: [],
            cg2: [],
            cg3: []
        }
    }

    getValuesByAlias = alias => {
        return adminInstance.get(`${url}/simple_parameter/${alias}`)
    }

    getRelatedParams = (param1, param2) => {
        adminInstance.get(`${url}/simple_parameter/category/related_params`, {
            params: {
                param1: param1,
                param2: param2
            }
        })
            .then(res => {
                this.setState({
                    simpleParameters: {
                        ...this.state.simpleParameters,
                        cg3: res.data.map(category => ({
                            value: category.value,
                            label: category.value,
                            id: category.id
                        }))

                    }
                })
            })

    }

    handleSubmit = values => {
        const { products,search,callback,details, getDetail } = this.props
        const data = new FormData()
        data.append('search',search)
        const withoutEmptyValues = removeEmptyValues(values)
        Object.keys(withoutEmptyValues).forEach(key => {
            data.append(key,withoutEmptyValues[key])
        })
        if(typeof products === 'object')
            products.forEach(val => data.append('products',val))
        else
            data.append('products[]',products)
        data.append('orderId',details.id)
        this.setState({
            isLoading: true
        },() => {
            adminInstance.put(`${url}/product/bulk_edit`,data)
                .then(res => {
                    notify('success','Successfully edited')
                    callback && callback(withoutEmptyValues)
                    getDetail(details.id, false)
                })
                .catch(err => {
                    this.setState({
                        isLoading: false
                    })
                })
        })
    }

    componentDidMount(){
        const { details } = this.props

        axios.all([
            this.getValuesByAlias('commodity'),
            this.getValuesByAlias('gender'),
            this.getValuesByAlias('channel')
            // ,this.getValuesByAlias('category')
            ])
            .then(axios.spread((cg1Res,cg2Res,channelRes) => {
                this.setState({
                    isLoading: false,
                    simpleParameters: {
                        cg1: cg1Res.data.map(item => ({
                            label: item.value,
                            value: item.value,
                            id: item.id
                        })),
                        cg2: cg2Res.data.map(item => ({
                            label: item.value,
                            value: item.value,
                            id: item.id
                        })),
                        cg3: [],
                        channels: channelRes.data.map(item => ({
                            label: item.value,
                            value: item.value
                        })),
                        bidId: details.bids.map(bid => ({
                            label: bid.number,
                            value: bid.number,
                            id: bid.id,
                        }))
                        // cg3: cg3Res.data.map(item => ({
                        //     label: item.value,
                        //     value: item.value
                        // }))
                    }
                })
            }))
            .catch(err => {
                notify('error','Can not get values')
            })
    }

    render(){
        const { isLoading, simpleParameters } = this.state;
        const { handleClose } = this.props;
        return (
            isLoading ? <Preloader/> : <Formik
                onSubmit={this.handleSubmit}
                validationSchema={schema}
                initialValues={{
                    ean: '',
                    styleNumber: '',
                    cg1: '',
                    cg2: '',
                    cg3: '',
                    cg4: '',
                    size: '',
                    sizeType: '',
                    season: '',
                    channel: '',
                    brand: '',
                    rrp: '',
                    incomeStock: '',
                    quality: '',
                    bidId: '',
                }}
                render={props => <BodyForm
                    {...props}
                    getRelatedParams={this.getRelatedParams}
                    simpleParameters={simpleParameters}
                    handleClose={handleClose}
                />}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

const mapDispatchToProps = dispatch => ({
    getDetail: (id, withLoading) => dispatch(getDetail(id, withLoading))
})

export default connect(mapStateToProps,mapDispatchToProps)(BulkEditForm)