import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Tooltip from '../../../../../../reuseComponent/Tooltips/index'
import {formatInteger} from '../../../../../../../reuseFunctions/formatValue'
import {formatPrice, thousandSeparator} from '../../../../../../../reuseFunctions/formatValue'
import {Form} from 'react-bootstrap'
import adminInstance from '../../../../../../instances/admininstance'
import {url} from '../../../../../../../constants'
import {findPermInArray} from '../../../../../../../reuseFunctions/checkPermission'
import {calculateActualTotalPrice} from '../../../../../../../reuseFunctions/calculateTotalBids'
import {permFinanceInfo} from "../../../../../../../permissions/productOverview"
import { checkIfObjectEmptyValues } from '../../../../../../../reuseFunctions/objectFunctions'


class TotalRow extends PureComponent {

    state = {
        isEditTotal: false,
        values: {
            targetQuantity: this.props.details.targetQuantity ? this.props.details.targetQuantity : null,
            targetPackagesCount: this.props.details.targetPackagesCount ,
            targetPalletsCount: this.props.details.targetPalletsCount ,
            targetGrossWeight: this.props.details.targetGrossWeight ? this.props.details.targetGrossWeight.toFixed(2) : this.props.details.targetGrossWeight ,
            targetNetWeight: this.props.details.targetNetWeight ? this.props.details.targetNetWeight.toFixed(2) : this.props.details.targetNetWeight ,
            totalPrice: this.props.details.totalPrice
        },
        unitOptions: []

    }

    componentDidMount() {
        adminInstance.get(`${url}/simple_parameter/quantityUnit`)
            .then(res => {
                this.setState({
                    unitOptions: res.data.map(item => ({
                        label: item.value,
                        value: item.value
                    }))
                })
            })
            .catch(err => {
                console.log(err)
            })
    }


    handleChangeMode = (isEdit, callback) => {
        this.setState({
            isEditTotal: isEdit,
            values: {
                targetQuantity: this.props.details.targetQuantity ? this.props.details.targetQuantity : null,
                targetPackagesCount: this.props.details.targetPackagesCount,
                targetPalletsCount: this.props.details.targetPalletsCount,
                targetGrossWeight: this.props.details.targetGrossWeight ? this.props.details.targetGrossWeight.toFixed(2) : this.props.details.targetGrossWeight ,
                targetNetWeight: this.props.details.targetNetWeight ? this.props.details.targetNetWeight.toFixed(2) : this.props.details.targetNetWeight ,
                totalPrice: this.props.details.totalPrice
            }
        }, () => callback && callback())
    }

    changeValues = (nameField, value) => {
        this.setState(state => {
            return {
                values: {
                    ...state.values,
                    [nameField]: value
                }

            }
        })
    }

    handleSubmit = () => {
        const {values} = this.state
        const {handleChangePurchase} = this.props
        values.targetQuantity = JSON.stringify(values.targetQuantity)
        this.setState({
            isEditTotal: false
        }, () => handleChangePurchase(values))
    }

    getActualTotalPrice = () => {
        return calculateActualTotalPrice(this.props.details.bids, this.props.details.addressPrice)
    }

    render() {
        const {details, isEditMain,  roles} = this.props
        const {isEditTotal, /*unitOptions*/} = this.state
        return (
            <tr className="total-row-with-products">
                <td colSpan={9}>
                </td>
                <td>Total</td>
                {checkIfObjectEmptyValues({
                    targetQuantity: details.targetQuantity,
                    targetPackagesCount: details.targetPackagesCount,
                    targetPalletsCount: details.targetPalletsCount,
                    targetGrossWeight: details.targetGrossWeight,
                    targetNetWeight: details.targetNetWeight,
                    totalPrice: details.totalPrice
                },[null,undefined,'{}','']) && !isEditMain ? <td colSpan={12}>
                    <div className="no-data-to-display">
                        No data to display...
                    </div>
                </td> : <>
                    {/*<td className="custom-bids-th">{isEditTotal ? <QuantityComponent*/}
                        {/*name="targetQuantity"*/}
                        {/*units={unitOptions}*/}
                        {/*initValue={details.targetQuantity}*/}
                        {/*handleChange={this.changeValues}*/}
                    {/*/> : details.targetQuantity} pcs /!*renderQuantity(details.targetQuantity)}*!/  </td>*/}
                    <td className="custom-bids-th">{isEditTotal ? <FormControlComponent
                        name="targetQuantity"
                        handleChange={this.changeValues}
                        isInt={true}
                        value={this.state.values.targetQuantity}
                    /> : parseInt(details.targetQuantity) ? parseInt(details.targetQuantity) : null} {details.targetQuantity > 0 ? 'pcs' : null} {/*renderQuantity(details.targetQuantity)}*/}  </td>
                    <td className="custom-bids-th">{isEditTotal ? <FormControlComponent
                        name="targetPackagesCount"
                        handleChange={this.changeValues}
                        isInt={true}
                        value={this.state.values.targetPackagesCount}
                    /> : details.targetPackagesCount}</td>
                    <td className="custom-bids-th">{isEditTotal ? <FormControlComponent
                        name="targetPalletsCount"
                        handleChange={this.changeValues}
                        isInt={true}
                        value={this.state.values.targetPalletsCount}
                    /> : details.targetPalletsCount}</td>
                    <td className="custom-bids-th">{isEditTotal ? <FormControlComponent
                        name="targetGrossWeight"
                        handleChange={this.changeValues}
                        isInt={false}
                        value={this.state.values.targetGrossWeight}
                    /> : details.targetGrossWeight ? thousandSeparator(formatPrice(details.targetGrossWeight)) : thousandSeparator(formatPrice(details.targetGrossWeight))}</td>
                    <td className="custom-bids-th">{isEditTotal ? <FormControlComponent
                        name="targetNetWeight"
                        handleChange={this.changeValues}
                        isInt={false}
                        value={this.state.values.targetNetWeight}
                    /> : details.targetNetWeight ? thousandSeparator(formatPrice(details.targetNetWeight)) : thousandSeparator(formatPrice(details.targetNetWeight))}</td>
                    <td colSpan={3}></td>
                    <td>
                        <div className="total-cell-total-row custom-bids-th">
                            {findPermInArray(roles, permFinanceInfo) && isEditTotal ? <FormControlComponent
                                name="totalPrice"
                                handleChange={this.changeValues}
                                isInt={false}
                                value={this.state.values.totalPrice}
                            /> : findPermInArray(roles, permFinanceInfo) ? details.totalPrice !== null && details.totalPrice!== undefined && details.totalPrice !== '' ? thousandSeparator(formatPrice(details.totalPrice)) + ' €' : null : null}
                        </div>
                    </td>
                    <td>
                        <div className="total-cell-total-row custom-bids-th">
                            {findPermInArray(roles, permFinanceInfo) && !isEditTotal && this.getActualTotalPrice() ? thousandSeparator(formatPrice(this.getActualTotalPrice())) + ' €' : null}
                        </div>
                    </td>
                    <td colSpan={1}></td>
                    <td>
                        {isEditMain && <>
                            {!isEditTotal && <Tooltip
                                text="Edit"
                            >
                                <button onClick={() => this.handleChangeMode(true)} className="action-row-button">
                                    <svg viewBox="0 0 24 24">
                                        <path fill="#000000"
                                              d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"></path>
                                    </svg>
                                </button>
                            </Tooltip>
                            }
                            {isEditTotal && <div className="wrap-actions">
                                <Tooltip
                                    text="Save"
                                >
                                    <button onClick={this.handleSubmit} className="action-row-button">
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"/>
                                        </svg>
                                    </button>
                                </Tooltip>
                                <Tooltip
                                    text="Cancel"
                                >
                                    <button onClick={() => this.handleChangeMode(false)} className="action-row-button">
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
                                        </svg>
                                    </button>
                                </Tooltip>
                            </div>}
                        </>
                        }
                    </td>
                </>}
            </tr>
        )
    }

}

export class QuantityComponent extends PureComponent {

    state = {}

    componentDidMount() {
        const {initValue, units} = this.props
        const parsedData = initValue ? JSON.parse(initValue) : {}
        const res = {}
        units.forEach(obj => {
            res[obj.value] = ''
        })
        Object.keys(parsedData).forEach(key => {
            res[key] = parsedData[key]
        })
        this.setState(res)
    }

    handleChange = (value, key) => {
        const {handleChange, name} = this.props
        this.setState({
            [key]: value
        }, () => {
            const res = {}
            Object.keys(this.state).forEach(key => {
                if (this.state[key] !== null && this.state[key] !== '' && this.state[key] >= 0)
                    res[key] = this.state[key]
            })
            handleChange(name, res)
        })
    }

    render() {
        return (
            Object.keys(this.state).map(key => {
                return <div key={key} className="row-quantity">
                    <Form.Control
                        onChange={e => this.handleChange(e.target.value, key)}
                        onBlur={e => this.handleChange(formatInteger(e.target.value), key)}
                        value={this.state[key]}
                    /> {key}
                </div>
            })
        )
    }
}

export const FormControlComponent = props => {
    const {name, isInt, handleChange, value, isInvalid} = props
    return <Form.Control
        onChange={e => handleChange(name, e.target.value)}
        onBlur={e => handleChange(name, isInt ? formatInteger(e.target.value) : formatPrice(e.target.value))}
        value={value !== null ? value : ''}
        isInvalid={isInvalid}
    />
}


// const renderQuantity = quantityJson => {
//     if (!quantityJson)
//         return null
//     else {
//         const data = JSON.parse(quantityJson)
//         return Object.keys(data).map(key => {
//             return <div style={{whiteSpace: 'nowrap'}} key={key}>{data[key]} {key}</div>
//         })
//     }
// }

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        isEditMain: state.poDetailsReducer.isEditMain,
        // status: state.dividingReducer.dividingStatus,
        roles: state.rolesReducer.roles
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//         showHideProducts: isShow => dispatch(showHideModalProducts(isShow))
//     }
// }

export default connect(mapStateToProps,null)(TotalRow)