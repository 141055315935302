import React ,{ PureComponent } from 'react'
import { connect } from 'react-redux'
import {Button} from 'react-bootstrap'

class ToolbarCustomers extends PureComponent{

    render(){
        const {
            pagination,
            paginationPosition,
            handleAdd
        } = this.props
        return (
            <div className="custom-toolbar toolbar-inventory">
                {/* <div className="actions-inventory-toolbar actions-toolbar">
                    {handleAdd && <Button onClick={handleAdd} className="add-button-customer add-button">
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                        </svg>
                        Add pallet
                    </Button>
                    }
                </div> */}
                {paginationPosition === 'toolbar'
                &&
                <div className="pagination-toolbar">
                    {pagination}
                </div>
                }
            </div>
        )
    }
}

export default connect(null,null)(ToolbarCustomers)
