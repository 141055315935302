import React from 'react'
import classNames from 'classnames'

const LegendItem = ({legendItem, sizeX, sizeY, scale, isSelected}) => {
  return <g className={classNames("legend-group", {"hovered": isSelected})}>
    <rect
      className="legend-rect"
      x={legendItem.coordinateX*sizeX*scale}
      y={legendItem.coordinateY*sizeY*scale}
      width={sizeX*scale}
      height={sizeY*scale}
      // fill="white"
    />
    <text
      x={(legendItem.coordinateX*sizeX + sizeX/2)*scale}
      y={(legendItem.coordinateY*sizeY + sizeY/4*3)*scale}
      color="#616161"
      fontSize={(sizeY/4*3)*scale}
      textAnchor="middle"
    >{legendItem.text}</text>
  </g>
}

export { LegendItem }