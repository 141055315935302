import { url } from '../constants'
import adminInstance from '../components/instances/admininstance'

const convertPalletValues = values => {
    const formData = new FormData()
    Object.entries(values).forEach(([key,value]) => {
        if(Array.isArray(value)){
            value.forEach(val => {
                formData.append(`${key}`,val)
            })
        }
        else
            formData.append(key,value)
    })
    return formData
}

export const createPallet = (values) => {
    const formData = convertPalletValues(values)
    return adminInstance.post(`${url}/pallet`,formData)
}

export const createCustomPallets = (values) => {
    const formData = convertPalletValues(values)
    return adminInstance.post(`${url}/pallet/custom`,formData)
}

export const createPalletFromInventory = (values) => {
    const formData = convertPalletValues(values)
    return adminInstance.post(`${url}/pallet/inventory`,formData)
}

export const editPallet = (id,values) => {
    if(values.salesManager && values.salesManager.value && Number.isInteger(values.salesManager.value)){
        values.salesManager = values.salesManager.value;
    }

    if(values.salesManager && !Number.isInteger(values.salesManager)){
        values.salesManager = ''
    }

    if(values.packTypeId && values.packTypeId.value && Number.isInteger(values.packTypeId.value)){
        values.packTypeId = values.packTypeId.value;
    }

    if(values.note == null){
        values.note = ''
    }

    if(values.orderNumber == null){
        values.orderNumber = ''
    }

    if(values.salesManager == null){
        values.salesManager = ''
    }

    const formData = convertPalletValues(values)
    return adminInstance.put(`${url}/pallet/${id}`,formData)
}


export const getPallet = (id) => {
    return adminInstance.get(`${url}/pallet/${id}`)
}

export const sortPallet = (id, type = 'asc') => {
    return adminInstance.get(`${url}/pallet/${id}/sorting`, {
        params: {
            type
        }
    })
}

export const deletePallet = (id) => {
    return adminInstance.delete(`${url}/pallet/${id}`)
}

export const getPalletsList = (params) => {
    return adminInstance.get(`${url}/pallet`,{
        params: params
    })
}

export const moveBoxesBetweenPallet = (boxesId,palletId) => {
    return adminInstance.post(`${url}/pallet/put_box`,convertPalletValues({
        boxId: boxesId,
        palletId: palletId
    }))
}

export const getPallets = params => {
    return adminInstance
        .get(`${url}/pallet`,{
            params: params
        })
}

export const getPalletForOffers = (params) => {
    return adminInstance.get(`${url}/pallet/for-offer`,{
        params: params
    })
}