import {USERS} from '../constants';

export const usersReducer = (state = {
    loading: false,
    loadingCurrUser : false,
    loadingLanguagesList: false,
    loadingUsersForAllocation: false,
    isLoadingUserById : true,
    userById: {},
    users: [],
    currUser : {},
    isLoadingRoles: true,
    roles: [],
    languages: [],
    usersForAllocation: [],
}, action) => {
    switch (action.type) {
        case USERS.SET_PO_OVERVIEW: {
            return {
                ...state,
                currUser: {
                    ...state.currUser,
                    userDetails: {
                        ...state.currUser.userDetails,
                        setting: JSON.stringify(action.payload)
                    }
                }
            }
        }
        case USERS.SET_USER_CUSTOMERS_COUNT: {
            return {
                ...state,
                currUser: {
                    ...state.currUser,
                    userDetails: {
                        ...state.currUser.userDetails,
                        customerSize: action.payload
                    }
                }
            }
        }
        case USERS.SET_ROLES_USERS: {
            return {
                ...state,
                roles: [...action.payload]
            }
        }
        case USERS.IS_LOADING_ROLES: {
            return {
                ...state,
                isLoadingRoles: action.payload
            }
        }
        case USERS.SET_USER_BY_ID: {
           return {
               ...state,
               userById: action.payload
           }
        }
        case USERS.IS_LOADING_USER_BY_ID: {
            return {
                ...state,
                isLoadingUserById: action.payload
            }
        }
        case USERS.IS_LOADING_USER: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case USERS.SET_USERS: {
            return {
                ...state,
                users: action.payload
            }
        }
        case USERS.CURR_USER_REQUEST: {
            return {
                ...state,
                loadingCurrUser : action.payload
            }
        }
        case USERS.CURR_USER_RECEIVED: {
            return {
                ...state,
                loadingCurrUser : action.payload.loading,
                currUser: action.payload.user
            }
        }
        case USERS.CURR_USER_FAILED: {
            return {
                ...state,
                loadingCurrUser : action.payload.loading,
                currUser: action.payload.user
            }
        }
        case USERS.IS_LOADING_LANGUAGES_LIST: {
            return {
                ...state,
                loadingLanguagesList: action.payload
            }
        }
        case USERS.SET_LANGUAGES_LIST: {
            return {
                ...state,
                languages: action.payload,
            }
        }
        case USERS.IS_LOADING_USERS_FOR_ALLOCATION: {
            return {
                ...state,
                loadingUsersForAllocation: action.payload,
            }
        }
        case USERS.SET_USERS_FOR_ALLOCATION: {
            return {
                ...state,
                usersForAllocation: action.payload,
            }
        }
        default:
            return state
    }
}