import {QUALITY_DICTIONARY, CATEGORIES_DICTIONARY, CG2_DICTIONARY, CG1_DICTIONARY, CHANNEL_DICTIONARY, PRODUCT_NAMES_DICTIONARY} from '../constants'
import { url } from '../constants'
import adminInstance from '../components/instances/admininstance'
import { notify } from '../components/reuseComponent/toast'
import {convertToFormData,convertToFormDataWithArray} from '../reuseFunctions/objectToFormData'
import axios from 'axios'


export const resetReducer = () => ({
    type: CATEGORIES_DICTIONARY.RESET_ALL_CATEGORIES_DICTIONARY
})

const isLoadingCategories = isLoading => ({
    type: CATEGORIES_DICTIONARY.IS_LOADING_CATEGORIES_DICTIONARY,
    payload: isLoading
})

const setCategoriesAction = categories => ({
    type: CATEGORIES_DICTIONARY.SET_CATEGORIES_DICTIONARY,
    payload: categories
})

export const getCategories = () => {
    return dispatch => {
        dispatch(isLoadingCategories(true))
        adminInstance.get(`${url}/simple_parameter/category`)
            .then(res => {
                dispatch(setCategoriesAction(res.data))
            })
            .catch(err => {
                console.log(err)
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(isLoadingCategories(false))
            })
    }
}

export const getCategoryById = (id) => {
    const promise = adminInstance.get(`${url}/simple_parameter/category/object/${id}`)
    return promise
}

export const createCategoryInDictionary = (values) => {
    const promise = adminInstance.post(`${url}/simple_parameter/category/object`,convertToFormData({data: JSON.stringify(values),inputs: values.inputs}))
    return promise
}

export const editCategoryInDictionary = (id,values) => {
    const promise = adminInstance.put(`${url}/simple_parameter/category/object/${id}`,convertToFormData({data: JSON.stringify(values),inputs: values.inputs}))
    return promise
}

const setRelatedCategoryValuesAction = ({genders,commodities}) => ({
    type: CATEGORIES_DICTIONARY.SET_RELATED_CATEGORIES_VALUES,
    payload: {
        genders,
        commodities
    }
})

const getGenders = () => {
    return adminInstance.get(`${url}/simple_parameter/gender`)
}

const getCommodities = () => {
    return adminInstance.get(`${url}/simple_parameter/commodity`)
}

const isLoadingValuesAction = isLoading => ({
    type: CATEGORIES_DICTIONARY.IS_LOADING_VALUES,
    payload: isLoading
})

export const getRelatedCategoriesValues = () => {
    return dispatch => {
        dispatch(isLoadingValuesAction(true))
        axios.all([getGenders(),getCommodities()])
            .then(axios.spread((genderRes,commoditiesRes) => {
                dispatch(setRelatedCategoryValuesAction({
                    genders: genderRes.data.map(gender => ({
                        value: gender.id,
                        label: gender.value
                    })),
                    commodities: commoditiesRes.data.map(commodity => ({
                        value: commodity.id,
                        label: commodity.value
                    }))
                }))
            }))
            .catch(() => {

            })
            .finally(() => {
                dispatch(isLoadingValuesAction(false))
            })
    }
}

const removeCategoryAction = (id) => ({
    type: CATEGORIES_DICTIONARY.REMOVE_CATEGORY_DICTIONARY,
    payload: id
})

export const removeCategory = (id) => {
    return dispatch => {
        adminInstance.delete(`${url}/simple_parameter/category/${id}`)
            .then(res => {
                dispatch(removeCategoryAction(id))
                notify('success','Category successfully removed')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}


// ============================================= Quality = Quality ======================================== //
const isLoadingQualityList = isLoading => ({
    type: QUALITY_DICTIONARY.IS_LOADING_QUALITY_LIST,
    payload: isLoading
})

const setQualityList = list => ({
    type: QUALITY_DICTIONARY.SET_LIST_QUALITY_DICTIONARY,
    payload: list
})

const addItemToQualityListAction = item => ({
    type: QUALITY_DICTIONARY.ADD_ITEM_TO_QUALITY_LIST,
    payload: item
})

const removeItemFromQualityAction = (id) => ({
    type: QUALITY_DICTIONARY.REMOVE_ITEM_FROM_QUALITY_LIST,
    payload: id
})

const editQualityItemAction = (id,values) => ({
    type: QUALITY_DICTIONARY.EDIT_QUALITY_ITEM,
    payload: values,
    id: id
})

export const editQualityItemRequest = (id,values,callback) => {
    return dispatch => {
        adminInstance.put(`${url}/simple_parameter/quality/${id}`,convertToFormData(values))
            .then(res => {
                if(res.data.data){
                    dispatch(editQualityItemAction(id,res.data.data))
                    notify('success','Quality successfully edited')
                    callback && callback()
                }
                else{
                    notify('error','Value already exists')
                }

            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

export const removeItemFromQuality = (id, toId) => {
    return dispatch => {
        adminInstance.delete(`${url}/simple_parameter/quality/${id}`, {params: {toId}})
            .then(res => {
                dispatch(removeItemFromQualityAction(id))
                notify('success','Quality successfully deleted')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

export const getQualitiesList = () => {
    return dispatch => {
        dispatch(isLoadingQualityList(true))
        adminInstance.get(`${url}/simple_parameter/quality`)
            .then(res => {
                dispatch(setQualityList(res.data))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(isLoadingQualityList(false))
            })
    }
}

export const createQuality = (values,callback) => {
    return dispatch => {
        adminInstance.post(`${url}/simple_parameter/quality`,convertToFormData(values))
            .then(res => {
                if(res.data.data){
                    dispatch(addItemToQualityListAction(res.data.data))
                    notify('success','Quality successfully created')
                    callback && callback()
                }
                else{
                    notify('error','Value already exists')
                }

            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}


// ============================================= CG1 = Genders ======================================== //

const isLoadingCg1List = isLoading => ({
    type: CG1_DICTIONARY.IS_LOADING_CG1_LIST,
    payload: isLoading
})

const setCg1List = list => ({
    type: CG1_DICTIONARY.SET_LIST_CG1_DICTIONARY,
    payload: list
})

const addItemToCg1ListAction = item => ({
    type: CG1_DICTIONARY.ADD_ITEM_TO_CG1_LIST,
    payload: item
})

const removeItemFromCg1Action = (id) => ({
    type: CG1_DICTIONARY.REMOVE_ITEM_FROM_CG1_LIST,
    payload: id
})

const editCg1ItemAction = (id,values) => ({
    type: CG1_DICTIONARY.EDIT_CG1_ITEM,
    payload: values,
    id: id
})

export const editCg1ItemRequest = (id,values,callback) => {
    return dispatch => {
        adminInstance.put(`${url}/simple_parameter/gender/${id}`,convertToFormData(values))
            .then(res => {
                if(res.data.data){
                    dispatch(editCg1ItemAction(id,res.data.data))
                    notify('success','Gender successfully edited')
                    callback && callback()
                }
                else{
                    notify('error','Value already exists')
                }

            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

export const removeItemFromCg1 = (id, toId) => {
    return dispatch => {
        adminInstance.delete(`${url}/simple_parameter/gender/${id}`, {params: {toId}})
            .then(res => {
                dispatch(removeItemFromCg1Action(id))
                notify('success','Gender successfully deleted')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

export const getCg1List = () => {
    return dispatch => {
        dispatch(isLoadingCg1List(true))
        adminInstance.get(`${url}/simple_parameter/gender`)
            .then(res => {
                dispatch(setCg1List(res.data))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(isLoadingCg1List(false))
            })
    }
}

export const createCg1 = (values,callback) => {
    return dispatch => {
        adminInstance.post(`${url}/simple_parameter/gender`,convertToFormData(values))
            .then(res => {
                if(res.data.data){
                    dispatch(addItemToCg1ListAction(res.data.data))
                    notify('success','Gender successfully created')
                    callback && callback()
                }
                else{
                    notify('error','Value already exists')
                }

            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

// ============================================= CG2 = Commodities ======================================== //

const isLoadingCg2List = isLoading => ({
    type: CG2_DICTIONARY.IS_LOADING_CG2_LIST,
    payload: isLoading
})

const setCg2List = list => ({
    type: CG2_DICTIONARY.SET_LIST_CG2_DICTIONARY,
    payload: list
})

const addItemToCg2ListAction = item => ({
    type: CG2_DICTIONARY.ADD_ITEM_TO_CG2_LIST,
    payload: item
})

const removeItemFromCg2Action = (id) => ({
    type: CG2_DICTIONARY.REMOVE_ITEM_FROM_CG2_LIST,
    payload: id
})

const editCg2ItemAction = (id,values) => ({
    type: CG2_DICTIONARY.EDIT_CG2_ITEM,
    payload: values,
    id: id
})

export const editCg2ItemRequest = (id,values,callback) => {
    return dispatch => {
        adminInstance.put(`${url}/simple_parameter/commodity/${id}`,convertToFormData(values))
            .then(res => {
                if(res.data.data){
                    dispatch(editCg2ItemAction(id,res.data.data))
                    notify('success','Commodity successfully edited')
                    callback && callback()
                }
                else{
                    notify('error','Value already exists')
                }

            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

export const removeItemFromCg2 = (id, toId) => {
    return dispatch => {
        adminInstance.delete(`${url}/simple_parameter/commodity/${id}`, {params: {toId}})
            .then(res => {
                dispatch(removeItemFromCg2Action(id))
                notify('success','Commodity successfully deleted')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

export const getCg2List = () => {
    return dispatch => {
        dispatch(isLoadingCg2List(true))
        adminInstance.get(`${url}/simple_parameter/commodity`)
            .then(res => {
                dispatch(setCg2List(res.data))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(isLoadingCg2List(false))
            })
    }
}

export const createCg2 = (values,callback) => {
    return dispatch => {
        adminInstance.post(`${url}/simple_parameter/commodity`,convertToFormDataWithArray(values))
            .then(res => {
                console.log(res.data)
                if(res.data.data){
                    dispatch(addItemToCg2ListAction(res.data.data))
                    notify('success','Commodity successfully created')
                    callback && callback()
                }
                else{
                    notify('error','Value already exists')
                }
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })

    }
}

// ============================================= Channel ======================================== //

const isLoadingChannelList = isLoading => ({
    type: CHANNEL_DICTIONARY.IS_LOADING_CHANNEL_LIST,
    payload: isLoading
});

const setChannelList = list => ({
    type: CHANNEL_DICTIONARY.SET_LIST_CHANNEL_DICTIONARY,
    payload: list
});

const addItemToChannelListAction = item => ({
    type: CHANNEL_DICTIONARY.ADD_ITEM_TO_CHANNEL_LIST,
    payload: item
})

const removeItemFromChannelAction = (id) => ({
    type: CHANNEL_DICTIONARY.REMOVE_ITEM_FROM_CHANNEL_LIST,
    payload: id
})

const editChannelItemAction = (id,values) => ({
    type: CHANNEL_DICTIONARY.EDIT_CHANNEL_ITEM,
    payload: values,
    id: id
});

export const editChannelItemRequest = (id,values,callback) => {
    return dispatch => {
        adminInstance.put(`${url}/simple_parameter/channel/${id}`,convertToFormData(values))
            .then(res => {
                console.log(res.data)
                if(res.data.data){
                    dispatch(editChannelItemAction(id,res.data.data))
                    notify('success','Channel successfully edited')
                    callback && callback()
                }
                else{
                    notify('error','Value already exists')
                }

            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

export const removeItemFromChannel = (id) => {
    return dispatch => {
        adminInstance.delete(`${url}/simple_parameter/channel/${id}`)
            .then(res => {
                dispatch(removeItemFromChannelAction(id))
                notify('success','Channel successfully deleted')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

export const getChannelList = () => {
    return dispatch => {
        dispatch(isLoadingChannelList(true))
        adminInstance.get(`${url}/simple_parameter/channel`)
            .then(res => {
                dispatch(setChannelList(res.data))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(isLoadingChannelList(false))
            })
    }
}

export const createCannel = (values,callback) => {
    return dispatch => {
        adminInstance.post(`${url}/simple_parameter/channel`,convertToFormData(values))
            .then(res => {
                if(res.data.data){
                    dispatch(addItemToChannelListAction(res.data.data))
                    notify('success','Channel successfully created')
                    callback && callback()
                }
                else{
                    notify('error','Value already exists')
                }

            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

// ====================== Product Names ====================== //

const isLoadingProductNamesList = isLoading => ({
    type: PRODUCT_NAMES_DICTIONARY.IS_LOADING_PRODUCT_NAMES_LIST,
    payload: isLoading
});

const setProductNamesList = list => ({
    type: PRODUCT_NAMES_DICTIONARY.SET_LIST_PRODUCT_NAMES_DICTIONARY,
    payload: list
});

const addItemToProductNamesListAction = item => ({
    type: PRODUCT_NAMES_DICTIONARY.ADD_ITEM_TO_PRODUCT_NAMES_LIST,
    payload: item
})

const removeItemFromProductNamesAction = (id) => ({
    type: PRODUCT_NAMES_DICTIONARY.REMOVE_ITEM_FROM_PRODUCT_NAMES_LIST,
    payload: id
})

const editProductNamesItemAction = (id,values) => ({
    type: PRODUCT_NAMES_DICTIONARY.EDIT_PRODUCT_NAMES_ITEM,
    payload: values,
    id: id
});

export const editProductNamesItemRequest = (id,values,callback) => {
    return dispatch => {
        adminInstance.put(`${url}/simple_parameter/productName/${id}`,convertToFormData(values))
            .then(res => {
                if(res.data.data){
                    dispatch(editProductNamesItemAction(id,values))
                    notify('success','Product name successfully edited')
                    callback && callback()
                }
                else{
                    notify('error','Value already exists')
                }

            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

export const removeItemFromProductNames = (id) => {
    return dispatch => {
        adminInstance.delete(`${url}/simple_parameter/productName/${id}`)
            .then(res => {
                dispatch(removeItemFromProductNamesAction(id))
                notify('success','Product name successfully deleted')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}

export const getProductNamesList = () => {
    return dispatch => {
        dispatch(isLoadingProductNamesList(true))
        adminInstance.get(`${url}/simple_parameter/productName`)
            .then(res => {
                dispatch(setProductNamesList(res.data))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(isLoadingProductNamesList(false))
            })
    }
}

export const createProductNames = (values,callback) => {
    return dispatch => {
        adminInstance.post(`${url}/simple_parameter/productName`,convertToFormData(values))
            .then(res => {
                if(res.data.data){
                    dispatch(addItemToProductNamesListAction(res.data.data))
                    notify('success','Product name successfully created')
                    callback && callback()
                }
                else{
                    notify('error','Value already exists')
                }

            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}
