import React from 'react'
import { Switch, Route } from 'react-router-dom'
import List from './list/index'

const DepartmentModule = () => {
    return (
        <Switch>
            <Route exact path="/atw/settings/departments" component={List}/>
        </Switch>
    )
}

export default DepartmentModule