import React from 'react'
import {connect} from 'react-redux'

const ResultRow = props => {
    const {details} = props
    const pacagesRes = compareToValues(details.targetPackagesCount, details.actualPackagesCount)
    const palletsRes = compareToValues(details.targetPalletsCount, details.actualPalletsCount)
    const grossRes = compareToValues(details.targetGrossWeight, details.actualGrossWeight)
    const netRes = compareToValues(details.targetNetWeight, details.actualNetWeight)
    const quantityRes = compareToValues(details.targetQuantity,details.actualQuantity)
    return (
        <tr className="result-row">
            <td className="status">
                {((pacagesRes!== null && pacagesRes < 0) ||
                    (palletsRes !== null && palletsRes < 0) ||
                    (grossRes!== null && grossRes < 0) ||
                    (netRes !== null && netRes < 0) ||
                    (quantityRes !== null && quantityRes < 0))
                // compareQuantity(details.targetQuantity ? JSON.parse(details.targetQuantity) : {}, details.actualQuantity ? JSON.parse(details.actualQuantity) : {}, true) === false)
                    && <svg viewBox="0 0 24 24">
                        <path fill="#000000" d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z"/>
                    </svg>}
            </td>
            {/*<td>{compareQuantity(details.targetQuantity ? JSON.parse(details.targetQuantity) : {}, details.actualQuantity ? JSON.parse(details.actualQuantity) : {})}</td>*/}
            <td style={{color: quantityRes < 0 ? 'red' : 'green'}}>{quantityRes}</td>
            <td style={{color: pacagesRes < 0 ? 'red' : 'green'}}>{pacagesRes}</td>
            <td style={{color: palletsRes < 0 ? 'red' : 'green'}}>{palletsRes}</td>
            <td style={{color: grossRes < 0 ? 'red' : 'green'}}>{grossRes}</td>
            <td style={{color: netRes < 0 ? 'red' : 'green'}}>{netRes}</td>
            <td></td>
        </tr>
    )
}


const compareToValues = (a, b) => {
    if (a!== null && b !== null && a!== "" && b!== "" && a >= 0 && b >= 0) {
        return b - a
    }
    // else if (a) {
    //     return -a
    // }
    // else if (b) {
    //     return b
    // }
    // else {
    //     return 0
    // }
    else
        return null
}

// const compareQuantity = (target, actual, isCheck = false) => {
//     const res = {}
//     let flag = true
//     if(Object.keys(actual).length === 0 || Object.keys(target).length === 0)
//         return isCheck ? true : null
//     Object.keys(actual).forEach(key => {
//         res[key] = actual[key]
//     })
//     Object.keys(target).forEach(key => {
//         if (res[key]!== undefined && res[key] >= 0) {
//             res[key] -= target[key]
//             if (res[key] < 0)
//                 flag = false
//         }
//         else {
//             res[key] = -target[key]
//             flag = false
//         }
//     })
//
//
//     return !isCheck ? Object.keys(res).map(key => {
//         return <div
//             className="result-quantity"
//             key={key}
//             style={res[key] < 0 ? {
//                 color: 'red'
//             } : res[key] > 0 ? {
//                 color: 'green'
//             } : {}
//             }
//         >{res[key]} {key}</div>
//     }) : flag
// }

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

export default connect(mapStateToProps, null)(ResultRow)