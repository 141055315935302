import React, {PureComponent} from 'react'
import {Formik} from 'formik'
import {schema} from '../create/schema'
import {notify} from '../../../reuseComponent/toast'
import {OfferApi} from '../../../../services/offers'
import OfferForm from '../create/form'
import Preloader from '../../../reuseComponent/preloader/preloader'
import {defaultServerError} from '../../../../reuseFunctions/toasts';
import axios from 'axios';


class EditOfferComponent extends PureComponent {

    state = {
        isLoading: true,
        isLoaded: false,
        initialValues: {
            availability: '',
            comment: '',
            deliveryTime: '',
            file: '',
            moq: '',
            palletsIds: [],
            price: '',
            title: '',
            unit: '',
            viewsCount: '',
            sets: {}
        },
        pallets: []
    }

    componentDidMount(){
        const { idOffer } = this.props
        OfferApi.getOffer(idOffer)
            .then(res => {
                this.setState({
                    isLoaded: true,
                    initialValues: {
                        availability: res.data.availability,
                        comment: res.data.comment,
                        deliveryTime: res.data.deliveryTime,
                        file: res.data.picture,
                        palletsIds: res.data.pallets.map(pallet => pallet.id),
                        price: res.data.price,
                        title: res.data.title,
                        unit: res.data.unit,
                        moq: res.data.moq,
                        viewsCount: res.data.viewsCount,
                    },
                    pallets: res.data.pallets,
                    src: res.data.picture
                })
            })
    }

    setLoading = isLoading => this.setState({isLoading: isLoading})

    handleEdit = (values,...actions) => {
        if(typeof values.file === 'string')
            delete values.file
        const {updateListOffers,handleCancel,idOffer,updateOffer} = this.props
        const { sets } = values
        delete values.sets
        this.setLoading(true)
        values.palletsIds = values.palletsIds.join(',')
        axios.all([OfferApi.editOffer(idOffer,values),OfferApi.saveOfferConditions(sets)])
            .then(res => {
                updateListOffers && updateListOffers()
                updateOffer && updateOffer()
                this.setLoading(false)
                notify('success', 'Offer successfully edited')
                handleCancel()
            })
            .catch(err => {
                actions[0] && actions[0].setSubmitting(true)
                defaultServerError()
                this.setLoading(false)
            })
    }

    render() {
        const {initialValues, isLoading,isLoaded,pallets,src} = this.state
        const {handleCancel} = this.props
        return (
            !isLoaded ? <Preloader/> :
            <Formik
                enableReinitialize={true}
                onSubmit={this.handleEdit}
                validationSchema={schema}
                initialValues={initialValues}
                render={props => {
                    // console.log(props)
                    return <OfferForm
                        {...props}
                        pallets={pallets}
                        src={src}
                        isLoading={isLoading}
                        setLoading={this.setLoading}
                        handleCancel={handleCancel}
                    />
                }}
            />
        )
    }
}

export default EditOfferComponent