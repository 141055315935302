import React, {PureComponent} from 'react'
import {Button} from 'react-bootstrap';
import { ReactComponent as PlusIcon } from '../../../../../assets/images/plus.svg'

class ToolbarDictionary extends PureComponent {

    render() {
        const {
            pagination,
            paginationPosition,
            handleAdd,
            handleSendNewsLetter
        } = this.props

        return (
            <div className="toolbar-notification">
                <div className="actions-notification-toolbar">
                    {
                        handleAdd && <Button onClick={handleAdd} className="add-button-notification">
                            <PlusIcon className="standart-svg"/>
                            Add
                        </Button>
                    }
                    {
                        handleSendNewsLetter && <Button onClick={handleSendNewsLetter} className="send-button-notification">
                            <PlusIcon className="standart-svg"/>
                            Inner newsletter
                        </Button>
                    }
                </div>
                {paginationPosition === 'toolbar'
                &&
                <div className="pagination-toolbar">
                    {pagination}
                </div>
                }
            </div>
        )
    }
}


export default ToolbarDictionary