import React, { PureComponent } from "react";
import { connect } from "react-redux";
// import ToolTip from "../../../reuseComponent/Tooltips";
import { withRouter } from "react-router-dom";
// import {
// permСhangeStatus
// permViewReopen
// } from "../../../../permissions/serviceDesk";
// import { findPermInArray } from "../../../../reuseFunctions/checkPermission";
import "./style.scss";

class StatusFormComponent extends PureComponent {
	state = {
		isShow: false
	};

	refNode = React.createRef();

	handleClickOutSide = event => {
		if (
			this.refNode.current &&
			!this.refNode.current.contains(event.target)
		) {
			this.setState({
				isShow: false
			});
		}
	};

	componentDidMount() {
		document.addEventListener("click", this.handleClickOutSide);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleClickOutSide);
	}

	render() {
		const { isShow } = this.state;
		const { request, showConfirm, statuses } = this.props;
		const filteredStatuses = statuses.filter(
			item =>
				item.alias === "done" ||
				item.alias === "in_progress" ||
				item.alias === "info_requested"
		);
		return (
			<div
				className={`wrap-form-statuses ${isShow ? "open" : ""}`}
				ref={this.refNode}
			>
				<div
					className="current-status"
					onClick={e => {
						this.setState({
							isShow: true
						});
					}}
				>
					<svg
						height="16"
						width="16"
						viewBox="0 0 20 20"
						aria-hidden="true"
						focusable="false"
						className="css-tj5bde-Svg"
					>
						<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
					</svg>
					<span
						className="active-status"
						dangerouslySetInnerHTML={{
							__html: request.status ? request.status.icon : ""
						}}
					></span>
					{request.status.name}
				</div>
				<div className="list-statuses">
					{filteredStatuses.map(status => (
						<div
							className="list-item"
							key={status.id}
							onClick={() => {
								if (request.status.id !== status.id) {
									showConfirm(
										{
											id: status.id,
											name: status.name
										},
										request.id
									);
								} else {
									this.setState({ isShow: false });
								}
							}}
						>
							<span
								dangerouslySetInnerHTML={{
									__html: status ? status.icon : ""
								}}
							></span>
							<span>{status.name}</span>
						</div>
					))}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		roles: state.rolesReducer.roles,
		user: state.usersReducer.currUser,
		request: state.serviceDeskReducer.request
	};
};

export default withRouter(connect(mapStateToProps, null)(StatusFormComponent));
