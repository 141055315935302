import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import classNames from 'classnames'
import isEqual from 'react-fast-compare'

const InventoryMapFilter = ({value, setValue, name, placeholder, options}) => {

  const [term, setTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValues, setSelectedValues] = useState([])
  const [defaultOptions, setDefaultOptions] = useState([])
  const [searchedOptions, setSearchedOptions] = useState([])

  const dropdownRef = useRef(null)
  // let timeout = useRef(null)

  const handleClose = useCallback(() => {
    setIsOpen(false)
    if(!isEqual(value, selectedValues)){
      setValue(selectedValues)
    }
  }, [setValue, selectedValues, value])

  const handleClickOutside = useCallback((e) => {
    if(isOpen && dropdownRef && !dropdownRef.current.contains(e.target)){
      handleClose()
    }
  }, [isOpen, handleClose])

  const handleClearFilterValues = useCallback(() => {
    setValue([])
    setSelectedValues([])
  }, [setValue])

  const handleSelectItem = useCallback((e) => {
    const val = e.target.name
    if(selectedValues.includes(val)){
      setSelectedValues(prevState => prevState.filter(item => item !== val))
    }else{
      setSelectedValues(prevState => [...prevState, val])
    }
  }, [selectedValues])

  const handleSearch = useCallback((e) => {
    const val = e.target.value
    // clearTimeout(timeout.current)
    setTerm(val)
    setSearchedOptions(defaultOptions.filter(item => item.label.toLowerCase().includes(val.toLowerCase())))
  }, [defaultOptions])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  useEffect(() => {
    setSelectedValues(value)
  }, [value])

  useEffect(() => {
    setDefaultOptions(options)
  }, [options])
  return (
    <div ref={dropdownRef} className="multiselect-filter__wrapper">
      <div className="multiselect-filter__toggle" onClick={() => {
        if(isOpen){
          handleClose()
        }else{
          setIsOpen(true)
        }
      }}>
        <svg viewBox="0 0 24 24">
          <path
            fill="#000000"
            d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
          ></path>
        </svg>
        <span className="toggle-name">{name}</span>
        {value && value.length > 0 && <div className="toggle-count">{value.length}</div>}
      </div>
      <ul className={classNames("multiselect-filter__list", {"open": isOpen})}>
        <li className="search-list-item">
          <Form.Control
            name={name}
            value={term}
            onChange={handleSearch}
            placeholder={placeholder}
            />
        </li>
        <li className="info-list-item">
          <span className="filter-name">{name}</span>
          <span className="clear-btn" onClick={handleClearFilterValues}>None</span>
        </li>
        {isLoading && <li>Loading...</li>}
        {((!isLoading && !term && defaultOptions.length === 0) || (!isLoading && term && options.length === 0))
        && <li>No data to display...</li>}
        {(!isLoading && !term && defaultOptions.map(item => <li className="list-item" key={item.value}>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              custom
              id={`check-${name}-${item.value}`}
              checked={selectedValues.find(tag => tag === item.value.toString())}
              name={item.value}
              type="checkbox"
              label={item.label}
              onChange={handleSelectItem}
            />
          </Form.Group>
        </li>))}
        {(!isLoading && term && searchedOptions.map(item => <li className="list-item" key={`s${item.value}`}>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              custom
              id={`check-s${name}-${item.value}`}
              checked={selectedValues.find(tag => tag === item.value.toString())}
              name={item.value}
              type="checkbox"
              label={item.label}
              onChange={handleSelectItem}
            />
          </Form.Group>
        </li>))}
      </ul>
    </div>
  )
}

export { InventoryMapFilter }
