import React, { useMemo } from 'react'
import classNames from 'classnames'

import { PALLET_PLACE_STROKE } from '../InventoryMap/constants'

const GridItem = ({
                    gridItem,
                    sizeX,
                    sizeY,
                    scale,
                    instrument,
                    // updateSelectedSlots,
                    handleStartSelection,
                    handleUpdateSelection,
                    handleFinishSelection,
                  }) => {

  const canBeSelected = instrument && !(!instrument.slot && gridItem.pallet)

  return (
    <g
      className={classNames("grid-item", {"clickable": canBeSelected
          && (gridItem.locationType.deleted || (!gridItem.locationType.deleted && instrument.clear))})}
      // onClick={() => canBeSelected ? updateSelectedSlots(gridItem) : {}}
      // onMouseDown={() => {
      //   if(instrument){
      //     handleStartSelection(gridItem)
      //   }
      // }}
      onMouseDown={() => {
        if(instrument && canBeSelected && (gridItem.locationType.deleted || (!gridItem.locationType.deleted && instrument.clear))){
          handleStartSelection(gridItem)
        }
      }}
      onMouseMove={() => {
        if(instrument){
          handleUpdateSelection(gridItem)
        }
      }}
      onMouseUp={() => {
        if(instrument){
          handleFinishSelection()
        }
      }}
    >
      <rect
        x={((gridItem.coordinateX-1)*sizeX)*scale + 0.25}
        y={((gridItem.coordinateY-1)*sizeY)* scale + 0.25}
        // x={((gridItem.coordinateX-1)*sizeX)*scale + (gridItem.newLocation ? 0.5 : 0.25)}
        // y={((gridItem.coordinateY-1)*sizeY)* scale + (gridItem.newLocation ? 0.5 : 0.25)}
        // width={(sizeX -  0.5)*scale}
        // height={(sizeY - 0.5)*scale}
        // fill={gridItem.locationType.color}
        // stroke={PALLET_PLACE_STROKE.DEFAULT}
        // strokeWidth={0.5*scale}
        // width={(sizeX)*scale- (gridItem.newLocation ? 1 : 0.5)}
        // height={(sizeY)*scale - (gridItem.newLocation ? 1 : 0.5)}
        width={(sizeX)*scale - 0.5}
        height={(sizeY)*scale - 0.5}
        fill={gridItem.newLocation ? gridItem.newLocation : (gridItem.newSelectedLocation
                                                             ? gridItem.newSelectedLocation
                                                             : gridItem.locationType.color)}
        stroke={gridItem.newLocation || gridItem.newSelectedLocation
                ? PALLET_PLACE_STROKE.HIGHLIGHTED
                : PALLET_PLACE_STROKE.DEFAULT}
        strokeWidth={0.25}
      />
      {instrument && !instrument.slot && gridItem.pallet && <svg
        x={((gridItem.coordinateX-1)*sizeX + sizeX/8)*scale}
        y={((gridItem.coordinateY-1)*sizeY + sizeY/8)*scale}
        width={(sizeX)*scale/4*3}
        height={(sizeY)*scale/4*3}
        viewBox="0 0 24 24">
        <path fill={PALLET_PLACE_STROKE.PRIMARY} d="M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,16H8V4H20V16M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16M18.53,8.06L17.47,7L12.59,11.88L10.47,9.76L9.41,10.82L12.59,14L18.53,8.06Z" />
      </svg>}
      {/*{instrument && !instrument.slot && gridItem.pallet && <g>*/}
      {/*  <line*/}
      {/*    x1={(gridItem.coordinateX-1)*sizeX*scale}*/}
      {/*    y1={(gridItem.coordinateY-1)*sizeY*scale}*/}
      {/*    x2={(gridItem.coordinateX)*sizeX*scale}*/}
      {/*    y2={(gridItem.coordinateY)*sizeY*scale}*/}
      {/*    stroke={PALLET_PLACE_STROKE.DEFAULT}*/}
      {/*  />*/}
      {/*  <line*/}
      {/*    x1={(gridItem.coordinateX)*sizeX*scale}*/}
      {/*    y1={(gridItem.coordinateY-1)*sizeY*scale}*/}
      {/*    x2={(gridItem.coordinateX-1)*sizeX*scale}*/}
      {/*    y2={(gridItem.coordinateY)*sizeY*scale}*/}
      {/*    stroke={PALLET_PLACE_STROKE.DEFAULT}*/}
      {/*  />*/}
      {/*</g>}*/}
    </g>
  )
}

export  { GridItem }