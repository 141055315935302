import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Button, Form} from 'react-bootstrap'
import {changeTasksData} from '../../../../../../../../actions/purchase-orders'
import DatePicker from '../../../../../../../reuseComponent/DatePicker/datepicker-with-children'
import moment from 'moment'
import Select from '../../../../../../../reuseComponent/Select/select-form'
import { getUsers } from '../../../../../../../../actions/users'

class BodyForm extends PureComponent {

    componentDidMount() {
        const { users,getUsers } = this.props
        users.length === 0 && getUsers && getUsers()
    }

    render() {
        const {
            values,
            touched,
            errors,
            setFieldValue,
            handleSubmit,
            handleChange,
            changeTasksData,
            users
        } = this.props
        const usersOptions = users.map(user => ({
            label: user.name + ' ' + user.secondName,
            value: user.id
        }))
        return (
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        name="text"
                        onChange={handleChange}
                        value={values.text}
                        isInvalid={touched.text && errors.textToCompare}
                    />
                    <Form.Control.Feedback type="invalid">{errors.text}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Due date</Form.Label>
                    <DatePicker
                        onApply={(event, picker) => {
                            setFieldValue('deadline', moment(picker.startDate).format('DD.MM.YYYY'))
                        }}
                        startDate={values.deadline ? values.deadline : null}
                    >
                        <div className="date-picker-input">
                            {!values.deadline && <svg viewBox="0 0 24 24">
                                <path fill="#000000"
                                      d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z"/>
                            </svg>
                            }
                            <Form.Control
                                readOnly={true}
                                name="deadline"
                                isInvalid={touched.deadline && errors.deadline}
                                value={values.deadline}
                            />
                            <Form.Control.Feedback>{errors.deadline}</Form.Control.Feedback>
                        </div>
                    </DatePicker>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Assignee</Form.Label>
                    <Select
                        value={usersOptions.find(user => user.value === values.executorId)}
                        options={usersOptions}
                        onChange={obj => {
                            setFieldValue('executorId',obj.value)
                        }}
                        isInvalid={touched.executorId && errors.executorId}
                        errorLabel={errors.executorId}
                    />
                </Form.Group>
                {/*<Form.Group>*/}
                    {/*<Form.Label>Description</Form.Label>*/}
                    {/*<Form.Control*/}
                        {/*as="textarea"*/}
                        {/*name="text"*/}
                        {/*onChange={handleChange}*/}
                        {/*isInvalid={errors.text && touched.text}*/}
                    {/*/>*/}
                    {/*<Form.Control.Feedback type="invalid">{errors.text}</Form.Control.Feedback>*/}
                {/*</Form.Group>*/}
                <Form.Group className="footer-form footer-form-custom">
                    <Button variant="secondary"
                            onClick={() => changeTasksData({
                                openModalCreate: false,
                                openModalEdit: false,
                                openModalRemove: false,
                                idTask: null
                            })}>Cancel</Button>
                    <Button variant="primary" type="submit">Save</Button>
                </Form.Group>
            </Form>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.usersReducer.users
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeTasksData: data => dispatch(changeTasksData(data)),
        getUsers: () => dispatch(getUsers())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BodyForm)