import React, { PureComponent } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import {
    createOfferFilter,
    deleteFilterById,
    editFilterById,
    setActiveFilter
} from '../../../../../services/smart-filter'
import { notify } from '../../../../reuseComponent/toast'
import FiltersList from '../../../../reuseComponent/smart-filter/filters-list'
import FilterModal from '../../../../reuseComponent/smart-filter/modalFilter'
import FormFilter from '../../../../reuseComponent/smart-filter/form'
import EditFilter from '../../../../reuseComponent/smart-filter/editFilter'
import { getFilters } from '../../../../../actions/smart-filter';
import { ConfirmModalContent } from '../../../../reuseComponent/confirmModalComponent';
import { withRouter } from 'react-router-dom'
import CustomModalWithChildren from "../../../../reuseComponent/modal/modalWithChildren";
import Tooltip from '../../../../reuseComponent/Tooltips'

const aliasTasks = 'inboxes'

class ToolbarDictionary extends PureComponent {

    state = {
        openCreateModal: false,
        openEditModal: false,
        openDeleteModal: false,
        removedTitle: '',
        currFilter: null,
        isLoadingFilterModal: false,
        isShow: false
    }

    listWrapperNode = React.createRef();

    handleClickOutSide = event => {
        if (this.listWrapperNode.current && !this.listWrapperNode.current.contains(event.target)) {
            this.setState({
                isShow: false
            })
        }
    }

    openCreateModal = () => this.setState({ openCreateModal: true })

    hideCreateModal = () => this.setState({ openCreateModal: false, isLoadingFilterModal: false })

    openEditModal = (idFilter) => this.setState({ openEditModal: true, currFilter: idFilter })

    hideEditModal = () => this.setState({ openEditModal: false, currFilter: null, isLoadingFilterModal: false })

    openDeleteModal = (idFilter, removedTitle) => this.setState({
        openDeleteModal: true,
        currFilter: idFilter,
        removedTitle
    })

    hideDeleteModal = () => this.setState({ openDeleteModal: false, currFilter: null })

    setLoading = (isLoading, callback) => this.setState({ isLoadingFilterModal: isLoading }, () => callback && callback())

    handleCreateFilter = values => {
        const promise = createOfferFilter(values, aliasTasks)
        this.setLoading(true, () => {
            promise
                .then(res => {
                    this.updateViewAndFilter()
                    this.hideCreateModal()
                    notify('success', 'Filter successfully created')
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })

    }

    handleActivateFilter = (id) => {
        const promise = setActiveFilter(id, aliasTasks)
        promise
            .then(this.updateViewAndFilter)
            .catch(err => notify('error', 'Oops something went wrong'))
    }

    updateViewAndFilter = () => {
        const { onSearchChanged, getFilters } = this.props
        onSearchChanged && onSearchChanged('')
        getFilters && getFilters(aliasTasks)
    }

    handleEditFilter = values => {
        const { currFilter } = this.state
        this.setLoading(true, () => {
            editFilterById(currFilter, values)
                .then(res => {
                    notify('success', 'Filter successfully edited')
                    this.hideEditModal()
                    this.updateViewAndFilter()
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                    this.hideEditModal()
                })
        })

    }

    handleDeleteFilter = () => {
        const { currFilter } = this.state
        this.hideDeleteModal()
        deleteFilterById(currFilter)
            .then(res => {
                this.updateViewAndFilter()
                notify('success', 'Filter successfully deleted')
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }

    componentDidMount() {
        const { getFilters } = this.props
        getFilters && getFilters(aliasTasks)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutSide)
    }


    render() {
        const {
            pagination,
            paginationPosition,
            handleReadAll,
            handleUnreadAll,
            activeInbox,
            setStatus,
        } = this.props;
        const { alias } = this.props.match.params
        const { openCreateModal, openEditModal, openDeleteModal, currFilter, isLoadingFilterModal, removedTitle, isShow } = this.state
        return (
            <div className="toolbar-inbox">
                <div className="actions-tasks-toolbar">
                    {
                        activeInbox && activeInbox > 0 ? (
                            <Button onClick={handleReadAll} className="add-button-task">
                                <Tooltip text={`Read All`}>
                                    <>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="#B0BEC5" d="M2 8V22H20V24H2C.895 24 0 23.11 0 22V8H2M23.03 6.29L14 .64L4.97 6.29C4.39 6.64 4 7.27 4 8V18C4 19.1 4.9 20 6 20H22C23.1 20 24 19.1 24 18V8C24 7.27 23.61 6.64 23.03 6.29M22 18H6V10L14 15L22 10V18M14 13L6 8L14 3L22 8L14 13Z" />
                                        </svg>
                                        Read All
                                    </>
                                </Tooltip>
                            </Button>
                        ) : (
                                <Button onClick={handleUnreadAll} className="add-button-task">
                                    <Tooltip text={`Unread All`}>
                                        <>
                                            <svg viewBox="0 0 24 24">
                                                <path fill="#FF9800" d="M2 6V20H20V22H2C.895 22 0 21.11 0 20V6H2M24 4C24 2.9 23.1 2 22 2H6C4.9 2 4 2.9 4 4V16C4 17.1 4.9 18 6 18H22C23.1 18 24 17.1 24 16V4M22 4L14 9L6 4H22M22 16H6V6L14 11L22 6V16Z" />
                                            </svg>
                                            Unread All
                                        </>
                                    </Tooltip>
                                </Button>
                            )
                    }
                    {/* {handleAdd && <Button onClick={handleAdd} className="add-button-task">
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                        </svg>
                        Add
                    </Button>
                    } */}
                    {/* {alias !== 'my-tasks' && <FiltersList
                        isHideNoFilter
                        setActiveFilter={this.handleActivateFilter}
                        openCreateModal={this.openCreateModal}
                        openEditModal={this.openEditModal}
                        openDeleteModal={this.openDeleteModal}
                    />
                    } */}
                    <div ref={this.listWrapperNode} className="filters-list-wrapper">
                        <Button className="with-icon filter-button" onClick={e => this.setState(state => ({
                            isShow: !state.isShow
                        }))}>
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000" d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"/>
                            </svg>
                            <span>{'Status'}</span>
                        </Button>
                        <ul className={`filters-list ${isShow ? 'open' : ''}`}>
                            <li className="add-new-filter pl-2"
                                onClick={() => {
                                    this.setState({
                                        isShow: false
                                    }, setStatus('all'))
                                }}
                            >
                                All
                            </li>
                            <li className="add-new-filter"
                                onClick={() => {
                                    this.setState({
                                        isShow: false
                                    }, setStatus('read'))
                                }}
                            >
                                <svg viewBox="0 0 24 24">
                                    <path fill="#B0BEC5" d="M21.03 6.29L12 .64L2.97 6.29C2.39 6.64 2 7.27 2 8V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 7.27 21.61 6.64 21.03 6.29M20 18H4V10L12 15L20 10V18M12 13L4 8L12 3L20 8L12 13Z" />
                                </svg>
                                Read
                            </li>
                            <li className="add-new-filter"
                                onClick={() => {
                                    this.setState({
                                        isShow: false
                                    }, setStatus('unread'))
                                }}
                            >
                                <svg viewBox="0 0 24 24">
                                    <path fill="#FF9800" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
                                </svg>
                                Unread
                            </li>
                        </ul>
                    </div>
                </div>
                {paginationPosition === 'toolbar'
                    &&
                    <div className="pagination-toolbar">
                        {pagination}
                    </div>
                }
                <FilterModal
                    open={openCreateModal}
                    handleClose={this.hideCreateModal}

                >
                    <FormFilter
                        isLoading={isLoadingFilterModal}
                        handleSubmit={this.handleCreateFilter}
                        alias={aliasTasks}
                        handleCancel={this.hideCreateModal}
                        initValues={{
                            name: '',
                            criterias: [],
                            orCriterias: []
                        }}
                    />
                </FilterModal>
                <FilterModal
                    open={openEditModal}
                    handleClose={this.hideEditModal}
                >
                    <EditFilter
                        alias={aliasTasks}
                        idFilter={currFilter}
                        isLoading={isLoadingFilterModal}
                        handleCancel={this.hideEditModal}
                        handleSubmit={this.handleEditFilter}
                    />
                </FilterModal>
                <CustomModalWithChildren
                    open={openDeleteModal}
                    Content={ConfirmModalContent}
                    handleConfirm={this.handleDeleteFilter}
                    handleClose={this.hideDeleteModal}
                    handleCancel={this.hideDeleteModal}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`delete "${removedTitle}" filter`}
                    />
                </CustomModalWithChildren>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeInbox: state.ticketsReducer.activeInbox
    }
}

export default withRouter(connect(mapStateToProps, {
    getFilters
})(ToolbarDictionary))
