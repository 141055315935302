import React, {PureComponent} from 'react'
import FormikWrapper from '../formik-wrapper'
import {defaultTaskOption} from '../../list'


class CreateTask extends PureComponent {

    render() {
        const {handleCancel, handleSubmit, createFromPo, onlyChangeResponsive, isDetails = false} = this.props
        return (
            <FormikWrapper
                createFromPo={createFromPo}
                initValues={{
                    taskTemplate: defaultTaskOption,
                    dueDate: '',
                    poId: '',
                    executorId: '',
                    departmentId: '',
                    description: '',
                    title: '',
                    responsible: null,
                    order: null
                }}
                handleCancel={handleCancel}
                onlyChangeResponsive={onlyChangeResponsive}
                handleSubmit={handleSubmit}
                isDetails={isDetails}
            />
        )
    }
}

export default CreateTask