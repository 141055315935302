import adminsInstance from '../components/instances/admininstance'
import { url } from '../constants'
import { convertToFormData } from '../reuseFunctions/objectToFormData'


export const addCommentToRequest = (values) => {
    const data = convertToFormData(values)
    return adminsInstance.post(`${url}/service_desk/${values.id}/add_comment`,data)
}

export const addFileToRequest = (values) => {
    const data = convertToFormData(values)
    return adminsInstance.post(`${url}/service_desk/${values.id}/add_file`,data)
}

export const changeStatusRequest = (values) => {
    const data = convertToFormData(values)
    return adminsInstance.put(`${url}/service_desk/${values.id}/change_status`,data)
}

export const getServiceDeskStatuses = () => {
    return adminsInstance.get(`${url}/service_desk/sd_status`)
}

export const getServiceDeskPriority = () => {
    return adminsInstance.get(`${url}/service_desk/sd_priority`)
}

export const getServiceDeskTypes = () => {
    return adminsInstance.get(`${url}/service_desk/sd_type`)
}

export const getServiceDeskRequest = id => {
    return adminsInstance.get(`${url}/service_desk/${id}`)
}

export const fetchToAsana = (id, params) => {
    return adminsInstance.get(`${url}/service_desk/sd_send_to_asana/${id}`, {params})
}