import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {getHistory, removeDeliveryNotes} from '../../../../../../actions/purchase-orders'
import Preloader from '../../../../../reuseComponent/preloader/preloader'
import ActionComponent from './action'
import moment from 'moment'
import Modal from '../../../../../reuseComponent/modal/index'
import {Button} from 'react-bootstrap'
import CommentComponent from './comment-item/index'
import FileItemComponent from './file-item/index'
import TaskAction from './tasks/task-action/index'
import Ticket from './tasks/task-item/index'
import {findPermInArray} from '../../../../../../reuseFunctions/checkPermission'
import {chronologyFiledsConverting, url} from '../../../../../../constants'
import EditTask from '../../../../tasks/form/edit/index'
import {editTask} from '../../../../../../services/tasks'
import {notify} from '../../../../../reuseComponent/toast/index'
import {
    permFilesChronology,
    permInvoiceChronology,
    permViewChronology
} from '../../../../../../permissions/productOverview'
import adminInstance from '../../../../../instances/admininstance'
import {ConfirmModalContent} from '../../../../../reuseComponent/confirmModalComponent/index';
import CustomModalWithChildren from "../../../../../reuseComponent/modal/modalWithChildren";

class HistoryList extends PureComponent {

    state = {
        modalDiff: false,
        diff: null,
        currTask: null,
        editModalOpen: false,
        modalRemoveFile: false,
        currFile: null,
        removeType: '',
    }

    handleShowRemoveFileModal = (removeType) => (action) => this.setState({modalRemoveFile: true,currFile: action, removeType})

    handleHideRemoveFileModal = () => this.setState({modalRemoveFile: false,currFile: null})

    handleRemoveFile = () => {
        this.handleHideRemoveFileModal()
        const { currFile } = this.state
        const { getHistory, removeDeliveryNotes } = this.props
        if(currFile)
            adminInstance.delete(`${url}/purchase_order/${currFile.file.id}/file`)
                .then(res => {
                    if(currFile.type === 'INVOICE'){
                        notify('success','Invoice successfully deleted')
                    }
                    else{
                        notify('success','File successfully deleted')
                    }
                    if(currFile.type === 'TRANSPORT_NOTE') {
                        removeDeliveryNotes(currFile.file.id)
                    }
                    getHistory && getHistory('FILE')
                    getHistory && getHistory()

                })
                .catch(err => {
                    notify('error','Oops something went wrong')
                })
    }

    componentDidMount() {
        const {getHistory, roles} = this.props;
        const hasPermToTasks = findPermInArray(roles, permViewChronology);
    }

    handleEditTask = values => {
        const {currTask} = this.state
        const { editTaskAction,getHistory } = this.props
        values.poUrl = window.location.origin + '/atw/purchase-orders'
        delete values.responsible
        delete values.order
        this.setState({
            editModalOpen: false,
            currTask: null
        }, () => {
            editTask(values, currTask)
                .then(res => {
                    notify('success', 'Ticket successfully edited')
                    editTaskAction(res.data.task ? res.data.task: {})
                    getHistory && getHistory('')
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })
    }

    showHideModal = (open, diff, callback) => {
        this.setState({
            modalDiff: open,
            diff: diff
        }, () => callback && callback())
    }

    checkIfContainsType = (type) => {
        const types = ['ACTION', 'FILE', 'TASK_ACTION', 'TRANSPORT_NOTE']
        return types.includes(type)
    }

    render() {
        const {isLoading, history, roles, activeTab} = this.props;
        const {modalDiff, diff,editModalOpen,currTask,modalRemoveFile, currFile, removeType} = this.state
        let lastDate = null
        let lastType = null
        const data = (activeTab === 'COMMENT') ? history.filter(v => v.type === 'COMMENT') : history;

        return (
            <>
                <div className="list-history">
                    {isLoading ? <Preloader/> : data.map(item => {
                        const isShowDate = !lastDate || !lastType || (this.checkIfContainsType(item.type) && lastDate && !moment(item.createdAt).isSame(lastDate, 'day'))
                        lastDate = item.createdAt
                        lastType = item.type
                        switch (item.type) {
                            case 'TASK' : {
                                return findPermInArray(roles, permViewChronology) && <Ticket
                                    task={item}
                                    key={item.id}
                                />
                            }
                            case 'TASK_ACTION' : {
                                return <TaskAction
                                    handleEdit={id => {
                                        this.setState({
                                            editModalOpen: true,
                                            currTask: id
                                        })
                                    }}
                                    action={item}
                                    isShowDate={isShowDate}
                                    key={item.id}
                                />
                            }
                            case 'FILE' : {
                                return findPermInArray(roles, permFilesChronology) && <FileItemComponent
                                    handleShowRemoveModal={this.handleShowRemoveFileModal('File')}
                                    action={item}
                                    isShowDate={isShowDate}
                                    key={item.id}
                                />
                            }
                            case 'TRANSPORT_NOTE' : {
                                return findPermInArray(roles, permFilesChronology) && <FileItemComponent
                                    handleShowRemoveModal={this.handleShowRemoveFileModal('Transport delivery note')}
                                    action={item}
                                    isShowDate={isShowDate}
                                    key={item.id}
                                />
                            }
                             case 'INVOICE' : {
                                return findPermInArray(roles, permInvoiceChronology) && <FileItemComponent
                                    handleShowRemoveModal={this.handleShowRemoveFileModal('Invoice')}
                                    action={item}
                                    isShowDate={isShowDate}
                                    key={item.id}
                                />
                            }
                            case 'ACTION' : {
                                return <ActionComponent
                                    showDiff={this.showHideModal}
                                    key={item.id}
                                    action={item}
                                    isShowDate={isShowDate}
                                />
                            }
                            case 'COMMENT' : {
                                return <CommentComponent
                                    key={item.id}
                                    comment={item}
                                    isShowDate={isShowDate}
                                />
                            }
                            default: {
                                return null
                            }
                        }
                    })}
                </div>
                <CustomModalWithChildren
                    open={modalRemoveFile}
                    handleCancel={this.handleHideRemoveFileModal}
                    handleClose={this.handleHideRemoveFileModal}
                    handleConfirm={this.handleRemoveFile}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`delete "${currFile && currFile.file ? currFile.file.realName : ''}" ${removeType}`}
                    />
                </CustomModalWithChildren>
                <Modal
                    Content={() => <EditTask
                        createFromPo={true}
                        idTask={currTask}
                        handleSubmit={this.handleEditTask}
                        handleCancel={() => this.setState({
                            editModalOpen: false
                        })}
                    />}
                    open={editModalOpen}
                    isFooter={false}
                    handleClose={() => this.setState({
                        editModalOpen: false
                    })}
                    options={{
                        centered: true
                    }}
                />
                <Modal
                    open={modalDiff}
                    isFooter={false}
                    options={{
                        centered: true
                    }}
                    handleClose={() => this.showHideModal(false, null)}
                    Content={() => {

                        const diffObj = diff ? JSON.parse(diff) : null
                        return (
                            <>
                                {diffObj && <div className="wrap-diff-modal">
                                    <div className="diff-row header">
                                        <div className="field-name">
                                            Name
                                        </div>
                                        <div className="diff-old">
                                            Old value
                                        </div>
                                        <div className="diff-new">
                                            New value
                                        </div>
                                    </div>
                                    {Object.keys(diffObj).map(key => (
                                        <div className="diff-row" key={key}>
                                            <div className="field-name">
                                                {chronologyFiledsConverting[key]}
                                            </div>
                                            <div className="diff-old">
                                                {diffObj[key].old}
                                            </div>
                                            <div className="diff-new">
                                                {diffObj[key].new}
                                            </div>
                                        </div>
                                    ))}

                                </div>
                                }
                                <Button variant="secondary" onClick={() => this.showHideModal(false, null)}>
                                    Close
                                </Button>
                            </>
                        )
                    }}

                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        history: state.purchaseOrdersReducer.history,
        isLoading: state.purchaseOrdersReducer.isLoadingHistory,
        roles: state.rolesReducer.roles
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getHistory: (type) => dispatch(getHistory(type)),
        removeDeliveryNotes: id => dispatch(removeDeliveryNotes(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryList)