import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PieChart from './pie-chart'
import BarChart from './bar-chart'
import {setData} from "../../../../actions/admin-header";

class Analysis extends PureComponent{
    componentDidMount() {
        const {setHeaderData} = this.props;

        setHeaderData && setHeaderData({
            clearSearch: true
        })
    }

    componentWillUnmount() {
        const {setHeaderData} = this.props;

        setHeaderData && setHeaderData({
            clearSearch: false
        })
    }

    render(){
        return (
            <div className="charts-block">
                <div className="top-charts">
                    <PieChart
                        field="cg3"
                    />
                    <PieChart
                        field="cg4"
                    />
                    <PieChart
                        field="brand"
                    />
                    <PieChart
                        field="size"
                    />
                </div>
                <div className="total-charts">
                    <BarChart
                        field="cg1"
                    />
                    <BarChart
                        field="cg2"
                    />
                    <BarChart
                        field="season"
                    />
                    {/*<BarChart*/}
                    {/*    field="brand"*/}
                    {/*/>*/}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    setHeaderData: data => dispatch(setData(data))
});


export default connect(null,mapDispatchToProps)(Analysis)