import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import { setData } from '../../../../actions/admin-header'
import { InventoryTabs } from '../InventoryTabs'
import { INVENTORY_TABS } from '../constants'
import { InventoryMapEditorMap } from './InventoryMapEditorMap'
import { InventoryMapEditorInstruments } from './InventoryMapEditorInstruments'
import { PlanAPI } from '../../../../services/plan'
import { defaultServerError } from '../../../../reuseFunctions/toasts'
import { formatCoordinatesToView, generateCoordinates } from '../../../../reuseFunctions/mapUtils'
import { LocationAPI } from '../../../../services/location'
import { generateEmptyLocation, generateEmptySlots } from './constants'
import CustomModalWithChildren from '../../../reuseComponent/modal/modalWithChildren'
import { ConfirmModalWithText } from '../../../reuseComponent/confirmModalComponent/ConfirmModalWithText'
import { notify } from '../../../reuseComponent/toast'
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'
import { permEditMapEditor } from '../../../../permissions/inventory'
import { PreloaderWithText } from '../../../reuseComponent/PreloaderWithText/PreloaderWithText'

import './style.scss'


const ctrlKey = 17
const cmdKey = 91
const rightcmdKey = 93
const unselectedColor = '#dddddd'

const InventoryMapEditor = () => {

  const [loading, setLoading] = useState(false)
  const [plan, setPlan] = useState(false)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [coordinatesArray, setCoordinatesArray] = useState(null)
  const [scale, setScale] = useState(1)
  const [instrument, setInstrument] = useState(null)
  const [selectedSlots, setSelectedSlots] = useState([])
  const [lastSelectedSlots, setLastSelectedSlots] = useState([])
  const [slots, setSlots] = useState([])
  const [instrumentToChange, setInstrumentToChange] = useState(null)
  const [openChangeInstrumentModal, setOpenChangeInstrumentModal] = useState(false)
  const [isSelectionEnabled, setSelectionEnabled] = useState(false)
  const [startSelectionCoordinate, setStartSelectionCoordinate] = useState(null)
  const [finishSelectionCoordinate, setFinishSelectionCoordinate] = useState(null)
  const [isCtrl, setIsCtrl] = useState(false)
  const [openModalConfirmSelectWithPillars, setOpenModalConfirmSelectWithPillars] = useState(false)
  const [numberOfSelectedPillars, setNumberOfSelectedPillars] = useState(0)

  const roles = useSelector(state => state.rolesReducer.roles)

  const hasPermToEdit = useMemo(() => {
    return findPermInArray(roles, permEditMapEditor)
  }, [roles])

  const containerRef = useRef(null)

  const dispatch = useDispatch()

  const handleOpenChangeInstrumentModal = useCallback((instrumentToChange) => {
    setInstrumentToChange(instrumentToChange)
    setOpenChangeInstrumentModal(true)
  }, [])

  const handleCloseChangeInstrumentModal = useCallback(() =>{
    setOpenChangeInstrumentModal(false)
    setInstrumentToChange(null)
  }, [])

  const handleSelectInstrument = useCallback((newInstrument) => {
    if(instrument && selectedSlots.length > 0){
        handleOpenChangeInstrumentModal(newInstrument)
    }else{
      setInstrument(prevState => {
        if(prevState && prevState.id === newInstrument.id){
          return null
        }else{
          return newInstrument
        }
      })
    }
  }, [selectedSlots, instrument, handleOpenChangeInstrumentModal])

  const handleSelectInstrumentWithClearingSelectedSlots = useCallback((newInstrument) => {
    setSelectedSlots([])
    setSlots(prevState => prevState.map(item => ({...item, newLocation: null})))
    handleCloseChangeInstrumentModal()
    setInstrument(prevState => {
      if(prevState && prevState.id === newInstrument.id){
        return null
      }else{
        return newInstrument
      }
    })
  }, [handleCloseChangeInstrumentModal])

  const updateSlotsLocation = useCallback((newLocation) => {
    setSlots(prevState => prevState.map(item => item.locationType.id === newLocation.id
      ? ({...item, locationType: {...item.locationType, color: newLocation.color}}) : item))
  }, [])

  const updateSelectedSlots = useCallback((slot) => {
    if(instrument){
      setSelectedSlots(prevState => {
        if(prevState.find(item => item.id === slot.id)){
          setSlots(prevState => prevState.map(item => item.id === slot.id ? ({...item, newLocation: null}) : item))
          return prevState.filter(item => item.id !== slot.id)
        }else{
          setSlots(prevState => prevState.map(item => item.id === slot.id ? ({...item, newLocation: instrument.color ? instrument.color : '#ffffff'}) : item))
          return [...prevState, slot]
        }
      })
    }
  }, [instrument])

  const handleStartSelection  = useCallback((gridItem) => {
    setSelectionEnabled(true)
    setStartSelectionCoordinate({
      x: gridItem.coordinateX,
      y: gridItem.coordinateY,
    })
    setFinishSelectionCoordinate({
          x: gridItem.coordinateX,
          y: gridItem.coordinateY,
    })
    // if(instrument.slot || (!instrument.slot && !gridItem.pallet)){
      setLastSelectedSlots([{
        ...gridItem,
        newLocation: isCtrl ? unselectedColor : instrument.color,
        isCtrl: isCtrl,
      }])
    // }
    // setSlots(prevState => prevState.map(slot => {
    //   if(slot.coordinateX === gridItem.coordinateX && slot.coordinateY === gridItem.coordinateY){
    //     return ({
    //       ...slot,
    //       newSelectedLocation: instrument.color
    //     })
    //   }else{
    //     return slot
    //   }
    // }))
  }, [instrument, isCtrl])

  const handleUpdateSelection = useCallback((gridItem) => {
    const getSubarrayFromSlots = (startX, startY, finishX, finishY) => {
      let arr = []
      for(let i = startY; i <= finishY ; i++){
        // console.log(plan.maxX * (i - 1) + startX - 1, finishY+1)
        // console.log(slots.slice(plan.maxX * (i - 1) + startX - 1, plan.maxX * (i - 1) + finishX))
        arr = arr.concat(slots.slice(plan.maxX * (i - 1) + startX - 1, plan.maxX * (i - 1) + finishX))
      }
      arr =  arr.map(item => ({
        ...item,
        newLocation: isCtrl ? unselectedColor : instrument.color,
        isCtrl: isCtrl,
      }))

      if(!instrument.slot){
        arr = arr.filter(item => !item.pallet)
      }

      if(!instrument.clear){
        arr = arr.filter(item => item.locationType.deleted)
      }

      return arr
    }

    if(isSelectionEnabled){
      setFinishSelectionCoordinate(prevState => {
        if(!prevState || (prevState.x !== gridItem.coordinateX || prevState.y !== gridItem.coordinateY)) {
          const startX = startSelectionCoordinate.x > gridItem.coordinateX ? gridItem.coordinateX
            : startSelectionCoordinate.x
          const startY = startSelectionCoordinate.y > gridItem.coordinateY ? gridItem.coordinateY
            : startSelectionCoordinate.y
          const finishX = startSelectionCoordinate.x > gridItem.coordinateX ? startSelectionCoordinate.x
            : gridItem.coordinateX
          const finishY = startSelectionCoordinate.y > gridItem.coordinateY ? startSelectionCoordinate.y
            : gridItem.coordinateY

          setLastSelectedSlots(getSubarrayFromSlots(startX, startY, finishX, finishY))
          // setLastSelectedSlots(slots.filter(slot => startSelectionCoordinate
          //   && slot.coordinateX >= startSelectionCoordinate.x && slot.coordinateX <= gridItem.coordinateX
          //     && slot.coordinateY >= startSelectionCoordinate.y && slot.coordinateY <= gridItem.coordinateY)
          //   .map(slot => ({
          //     ...slot,
          //     newLocation: instrument.color
          //   })))
          // console.log(startSelectionCoordinate)
          // setSlots(prevState => prevState.map(slot => {
          //   if(startSelectionCoordinate && slot.coordinateX >= startSelectionCoordinate.x && slot.coordinateX <= gridItem.coordinateX
          //     && slot.coordinateY >= startSelectionCoordinate.y && slot.coordinateY <= gridItem.coordinateY){
          //     return ({
          //       ...slot,
          //       newSelectedLocation: instrument.color,
          //     })
          //   }else{
          //     return slot
          //   }
          // }))
        }
        return {
          x: gridItem.coordinateX,
          y: gridItem.coordinateY,
        }
      })
    }
  }, [isSelectionEnabled, startSelectionCoordinate, instrument, slots, plan, isCtrl])

  const handleCloseModalConfirmSelectWithPillars = useCallback(() => {
    setOpenModalConfirmSelectWithPillars(false)
    setNumberOfSelectedPillars(0)
    setLastSelectedSlots([])
  }, [])

  const handleFinishSelectionWithPillars = useCallback(() => {
    const idsArray = []
    let checkArr = [...selectedSlots, ...lastSelectedSlots]
    let result = []
    for(let i = 0; i < checkArr.length; i++){
      if(!idsArray.includes(checkArr[i].id)){
        result.push(checkArr[i])
        idsArray.push(checkArr[i].id)
      }
    }

    setSelectedSlots(result)
    setLastSelectedSlots([])
    handleCloseModalConfirmSelectWithPillars()
  }, [lastSelectedSlots, handleCloseModalConfirmSelectWithPillars, selectedSlots])

  const handleFinishSelection = useCallback(() => {
    if(isSelectionEnabled){
      setSelectionEnabled(false)
      setStartSelectionCoordinate(null)
      setFinishSelectionCoordinate(null)

      if(!isCtrl){
        //check if has at least one pillar
        if(instrument.clear){
          const pillarNumber = lastSelectedSlots.filter(item => !item.locationType.deleted).length
          if(pillarNumber > 0){
            setNumberOfSelectedPillars(pillarNumber)
            setOpenModalConfirmSelectWithPillars(true)
            return;
          }
        }

        //remove dublicates
        const idsArray = []
        let checkArr = [...selectedSlots, ...lastSelectedSlots]
        let result = []
        for(let i = 0; i < checkArr.length; i++){
          if(!idsArray.includes(checkArr[i].id)){
            result.push(checkArr[i])
            idsArray.push(checkArr[i].id)
          }
        }

        setSelectedSlots(result)
      }else{
        const lastSelectedIds = lastSelectedSlots.map(item => item.id)
        setSelectedSlots(prevState => prevState.filter(item => !lastSelectedIds.includes(item.id)))
      }

      setLastSelectedSlots([])
    }
  }, [instrument, isSelectionEnabled, selectedSlots, lastSelectedSlots, isCtrl])

  const handleChangeCoordinates = useCallback( (coordinates) => {
    setLoading(true)
    // console.log(instrument)
    // let coordinatesToSend = []
    // if(instrument.clear){
    //
    // }else{
    //   coordinatesToSend = coordinates.filter(item => item.coordinateX > 0 && item.coordinateY > 0)
    //     .map(item => `${item.coordinateX}-${item.coordinateY}`).join(',')
    // }
    if(instrument.clear){
      const coordinatesToSend = coordinates.filter(item => item.locationType.id !== 'empty').filter(item => item.coordinateX > 0 && item.coordinateY > 0)
        .map(item => item.id).join(',')
      LocationAPI.editLocationTypes(plan.id, instrument.id, {
        coordinatesForDelete: coordinatesToSend
      })
        .then(res => {
          if(res.data.data){
            const array = coordinates.map(item => item.id)
            if(res.data.deleted){
              setSlots(prevState => prevState.map(item => {
                if (array.includes(item.id)) {
                  return generateEmptyLocation(item.coordinateX, item.coordinateY)
                } else if (item.newLocation) {
                  return ({ ...item, newLocation: null })
                } else {
                  return item
                }
              }))
            }else{
              const arr1 = array.map(item => `${item.coordinateX}-${item.coordinateY}`)
              setSlots(prevState => prevState.map(item => {
                if (arr1.includes(`${item.coordinateX}-${item.coordinateY}`)) {
                  return array.find(el => item.coordinateY === el.coordinateY && item.coordinateX === el.coordinateX)
                } else if (item.newLocation) {
                  return ({ ...item, newLocation: null })
                } else {
                  return item
                }
              }))
            }
            setInstrument(null)
            setSelectedSlots([])
            notify('Map successfully edited')
          }
        })
        .catch(err => {
          defaultServerError()
          console.log(err)
        })
        .finally(() => setLoading(false))
    }else{
      const coordinatesToSend = coordinates.filter(item => item.coordinateX > 0 && item.coordinateY > 0)
        .map(item => `${item.coordinateX}-${item.coordinateY}-${formatCoordinatesToView(
          `${item.coordinateX}-${item.coordinateY}`)}`).join(',')
      LocationAPI.editLocationTypes(plan.id, instrument.id, {
        coordinates: coordinatesToSend
      })
        .then(res => {
          if(res.data.data){
            const array = res.data.data.filter(item => !!item)
            if(res.data.deleted){
              setSlots(prevState => prevState.map(item => {
                if (array.includes(item.id)) {
                  return generateEmptyLocation(item.coordinateX, item.coordinateY)
                } else if (item.newLocation) {
                  return ({ ...item, newLocation: null })
                } else {
                  return item
                }
              }))
            }else{
              const arr1 = array.map(item => `${item.coordinateX}-${item.coordinateY}`)
              setSlots(prevState => prevState.map(item => {
                if (arr1.includes(`${item.coordinateX}-${item.coordinateY}`)) {
                  return array.find(el => item.coordinateY === el.coordinateY && item.coordinateX === el.coordinateX)
                } else if (item.newLocation) {
                  return ({ ...item, newLocation: null })
                } else {
                  return item
                }
              }))
            }
            setInstrument(null)
            setSelectedSlots([])
            notify('Map successfully edited')
          }
        })
        .catch(err => {
          defaultServerError()
          console.log(err)
        })
        .finally(() => setLoading(false))
    }

  }, [plan, instrument])

  const handleKeyDown = useCallback((e) => {
    if(e.keyCode === ctrlKey || e.keyCode === cmdKey || e.keyCode === rightcmdKey){
      setIsCtrl(true)
    }
  }, [])

  const handleKeyUp = useCallback((e) => {
    if(e.keyCode === ctrlKey || e.keyCode === cmdKey || e.keyCode === rightcmdKey){
      setIsCtrl(false)
    }
  }, [])

  const handleChangeVisibility = useCallback(() => {
    setIsCtrl(false)
  }, [])

  const removeSlotCallback = useCallback((slotId, newSlotId) => {
    if(newSlotId){
      const newLocationType = plan.locationTypes.find(item => item.id === newSlotId)
      setSlots(prevState => prevState.map(item => {
        if(item.locationType.id === slotId){
          return {
            ...item,
            locationType: newLocationType,
          }
        }else{
          return item
        }
      }))
    }else{
      setSlots(prevState => prevState.map(item => {
        if(item.locationType.id === slotId){
          return generateEmptyLocation(item.coordinateX, item.coordinateY)
        }else
          return item
      }))
    }
  }, [plan])

  useEffect(() => {
    if(isCtrl){
      setLastSelectedSlots(prevState => prevState.map(item => ({...item, newLocation: unselectedColor, isCtrl: true})))
    }else{
      setLastSelectedSlots(prevState => prevState.map(item => ({...item, newLocation: instrument.color, isCtrl: false})))
    }
  }, [isCtrl, instrument])

  useEffect(() => {
    if(instrument){
      document.addEventListener('keydown', handleKeyDown)
      document.addEventListener('keyup', handleKeyUp)
      window.addEventListener('blur', handleChangeVisibility)
    }

    return () => {
      setIsCtrl(false)
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('blur', handleChangeVisibility)
    }
  }, [handleKeyUp, handleKeyDown, instrument, handleChangeVisibility])

  //set header
  useEffect(() => {
    dispatch(setData({
      LeftPannel: InventoryTabs,
      leftPannelData: {
        alias: INVENTORY_TABS.DICTIONARY
      },
      textAddButton: 'Add',
      handleAdd: null,
      handleSearch: null,
      handleChangeSearchCriterias: null,
    }))

    return () => {
      dispatch(setData({
        LeftPannel: null,
        leftPannelData: null,
        textAddButton: null,
        handleAdd: null,
        handleSearch: null,
        handleChangeSearchCriterias: null,
      }))
    }
  }, [dispatch])

  //get initial data
  useEffect(() => {
    setLoading(true)
    PlanAPI.getPlans({
      page: 0,
      limit: 20,
    })
      .then(res => {
        if(res.data.data) {
          if (res.data.data.content.length > 0) {
            axios.all([
              PlanAPI.getPlanById(res.data.data.content[res.data.data.content.length - 1].id),
              LocationAPI.getLocationForEditing(res.data.data.content[res.data.data.content.length - 1].id),
            ])
              .then(axios.spread((planResult, itemsRes) => {
                setPlan({
                  ...planResult.data.plan,
                  locationTypes: planResult.data.plan.locationTypes
                })
                setWidth(containerRef.current.offsetWidth)
                setHeight(containerRef.current.offsetHeight - 50)
                setCoordinatesArray(generateCoordinates(planResult.data.plan.maxX, planResult.data.plan.maxY))
                setSlots(generateEmptySlots(itemsRes.data.data.filter(item => item.coordinateX > 0 && item.coordinateY > 0), planResult.data.plan.maxX, planResult.data.plan.maxY))
              }))
              .catch(defaultServerError)
              .finally(() => setLoading(false))
          }else{
            setLoading(false)
          }
        }else{
          setLoading(false)
        }
      })
      .catch(err => {
        defaultServerError()
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if(!hasPermToEdit){
      setSelectedSlots([])
      setLastSelectedSlots([])
      setInstrument(null)
      setOpenChangeInstrumentModal(false)
    }
  },[hasPermToEdit])

  return (
    <>
      {loading && <PreloaderWithText text="Loading"/>}
        <div className="inventory-editor" >
        <div className="inventory-editor-map-block-wrapper" ref={containerRef}>
          <InventoryMapEditorMap
            width={width}
            height={height}
            maxX={plan ? plan.maxX : null}
            maxY={plan ? plan.maxY : null}
            coordinatesArray={coordinatesArray}
            scale={scale}
            setScale={setScale}
            instrument={instrument}
            selectedSlots={selectedSlots}
            lastSelectedSlots={lastSelectedSlots}
            setSelectedSlots={setSelectedSlots}
            slots={slots}
            updateSelectedSlots={updateSelectedSlots}
            handleStartSelection={handleStartSelection}
            handleUpdateSelection={handleUpdateSelection}
            handleFinishSelection={handleFinishSelection}
            isCtrl={isCtrl}
          />
        </div>
        <InventoryMapEditorInstruments
          setLoading={setLoading}
          plan={plan}
          selectedInstrument={instrument}
          setSelectedInstrument={handleSelectInstrument}
          updateSlotsLocation={updateSlotsLocation}
          selectedCoordinates={selectedSlots}
          setSelectedSlots={setSelectedSlots}
          maxX={plan ? plan.maxX : null}
          maxY={plan ? plan.maxY : null}
          handleChangeCoordinates={handleChangeCoordinates}
          removeSlotCallback={removeSlotCallback}
        />
      </div>
      <CustomModalWithChildren
        open={openChangeInstrumentModal}
        handleClose={handleCloseChangeInstrumentModal}
        handleCancel={handleCloseChangeInstrumentModal}
        handleConfirm={() => handleSelectInstrumentWithClearingSelectedSlots(instrumentToChange)}
      >
        <ConfirmModalWithText text={instrument && instrumentToChange && instrumentToChange.id === instrument.id
        ? 'unselect space' : 'change space'}
        />
      </CustomModalWithChildren>
      <CustomModalWithChildren
        open={openModalConfirmSelectWithPillars}
        handleClose={handleCloseModalConfirmSelectWithPillars}
        handleCancel={handleCloseModalConfirmSelectWithPillars}
        handleConfirm={handleFinishSelectionWithPillars}
      >
        <div className="wrap-text">
          <p><span className="warn-text">Caution!</span></p>
          <p>Clearing space contains {numberOfSelectedPillars} pillars, are you sure you want to delete them?</p>
        </div>
      </CustomModalWithChildren>
    </>
  )
}

export { InventoryMapEditor }