export const permViewTasks = {
    access: 'view_tasks',
    part: 'tasks'
};

export const permViewAllTasks = {
    access: '',
    part: 'tasks',
};

export const permEditTasks = {
    access: 'edit_tasks',
    part: 'tasks'
};

export const permEditAllTasks = {
    access: '',
    part: 'tasks',
};

export const deleteTasks = {
    access: '',
    part: 'tasks'
};