import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Nav from "react-bootstrap/es/Nav";
import Tooltip from "../../../../reuseComponent/Tooltips";
import { findPermInArray } from "../../../../../reuseFunctions/checkPermission";
import {
  permViewProductList,
  permShowAnalysis,
  permShowDividing,
  permViewInbounding
} from "../../../../../permissions/productOverview";
import moment from 'moment'
import { checkIfObjectEmptyValues } from '../../../../../reuseFunctions/objectFunctions'

const Tabs = ({ activeTab, handleChangeTab }) => {
  const details = useSelector(
    state => state.purchaseOrdersReducer.detailsPurchase
  );
  const productList = useSelector(
    state => state.purchaseOrdersReducer.detailsPurchase.productList
  );
  const status = useSelector(state => state.dividingReducer.dividingStatus);
  const isUploading = useSelector(
    state => state.purchaseOrdersReducer.isUploadingProducts
  );
  const roles = useSelector(state => state.rolesReducer.roles);
  const packages = useSelector(state => state.inboundingReportReducer.packages.filter(item => item.custom))

  const  isLoadingDetails = useSelector(state => state.purchaseOrdersReducer.isLoadingDetails)

  const hasInboundInfo = useMemo(() => {
    if(details){
      const inboundInfo = {
        actualQuantity: details.actualQuantity ? JSON.parse(details.actualQuantity) : null,
        actualPackagesCount: details.actualPackagesCount,
        actualPalletsCount: details.actualPalletsCount,
        actualNetWeight: details.actualNetWeight,
        actualGrossWeight: details.actualGrossWeight,
        actualArrivalDate: details.actualArrivalDate ? moment(details.actualArrivalDate).format('DD.MM.YYYY') : '',
      }

      return !checkIfObjectEmptyValues(inboundInfo, ["", null, undefined, "{}"])
    }else{
      return false
    }
  })

  useEffect(() => {
    console.log('rerendered')
  }, [details])

  return (
    <Nav
      justify
      variant="tabs"
      className={`tabs-custom ${isUploading ? "disabled" : ""}`}
      activeKey={activeTab}
      onSelect={handleChangeTab}
    >
      <Nav.Item>
        <Nav.Link eventKey="order-info" disabled={isLoadingDetails}>
          <div className="content-tab">
            <span className="tab-name">PO Details</span>
          </div>
        </Nav.Link>
      </Nav.Item>
      {findPermInArray(roles, permViewProductList) && (
        <Nav.Item>
          <Nav.Link eventKey="product-list" disabled={!isLoadingDetails && details.inboundType === null ? true : false}>
            <div className="content-tab">
              <span className="tab-name">
                Product list ({details.totalItems ? details.totalItems : 0})
              </span>
            </div>
          </Nav.Link>
        </Nav.Item>
      )}
      {findPermInArray(roles, permShowDividing) && details.inboundType === 'Deep' && (
        <Nav.Item>
          <Nav.Link disabled={details.totalItems === 0 || isLoadingDetails ||
            (productList && productList.productWithoutBid !== 0)
          } eventKey="dividing">
            <div className="content-tab">
              <span className="tab-name">Dividing</span>
            </div>
          </Nav.Link>
        </Nav.Item>
      )}
      {/* {findPermInArray(roles, permShowAnalysis) && details.inboundType === 'Deep' && (
        <Nav.Item>
          <Nav.Link disabled={details.totalItems === 0 || isLoadingDetails} eventKey="analysis">
            <div className="content-tab">
              <span className="tab-name">Charts</span>
            </div>
          </Nav.Link>
        </Nav.Item>
      )} */}
      {findPermInArray(roles, permShowAnalysis) && (
        <Nav.Item>
          <Nav.Link
            disabled={details.totalItems === 0 || isLoadingDetails}
            eventKey="analysis-table"
          >
            <div className="content-tab">
              <span className="tab-name">Analysis</span>
            </div>
          </Nav.Link>
        </Nav.Item>
      )}
      {findPermInArray(roles, permViewInbounding) && details.inboundType && (
        <Nav.Item className="nav-item-stowing">
          <Nav.Link disabled={(details.inboundType === 'Deep' && !status) || isLoadingDetails || (details.inboundType === 'Totals' && !hasInboundInfo)} eventKey="inbound-tab">
            <div className="content-tab">
              {(details.inboundType === 'Totals' && !hasInboundInfo) ?
                <Tooltip text="Inbound info required">
                  <span className="tab-name">Inbound report</span>
                </Tooltip>
                : <span className="tab-name">Inbound report</span>}
            </div>
          </Nav.Link>
        </Nav.Item>
      )}
      {findPermInArray(roles, permViewInbounding) && details.inboundType && details.inboundType !== 'Totals' && (
        <Nav.Item className="nav-item-stowing">
          <Nav.Link disabled={(!status && details.inboundType === 'Deep')
          || isLoadingDetails
          || (details.inboundType === 'Custom' && packages.length === 0)} eventKey="stowing-tab">
            <div className="content-tab stowing-tab">
              {!status && details.inboundType === 'Deep' ? (
                <Tooltip text="Dividing is not completed">
                  <span>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z"
                      />
                    </svg>
                    <span className="tab-name">Inbounding</span>
                  </span>
                </Tooltip>
              ) : ((details.inboundType === 'Custom' && packages.length === 0) ? (<Tooltip text="Created Packages required">
                  <span>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z"
                      />
                    </svg>
                    <span className="tab-name">Inbounding</span>
                  </span>
                  </Tooltip>)
                  :
                (<span>
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z"
                    />
                  </svg>
                  <span className="tab-name">Inbounding</span>
                </span>)
              )}
            </div>
          </Nav.Link>
        </Nav.Item>
      )}
    </Nav>
  );
};

export default Tabs;
