import React from 'react'
import style from './preloader.module.scss'

const Preload = (props) => {

    const { text = 'Loading' } = props

    let custStyle = {}
    if(props.style){
        custStyle = {...props.style}
    }
    return(
        <div className={`${style['preloader-wrap']} preloader-wrap`} style={custStyle}>
            <span className={style.text}>
                {text}
            </span>
            <div className={style.spinner}>
                <div className={style.bounce1}></div>
                <div className={style.bounce2}></div>
                <div className={style.bounce3}></div>
            </div>

        </div>
    )
}

export default Preload
