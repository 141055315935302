import {TASKS} from '../constants'

const initState = {
    tasks: [],
    history: [],
    activeTickets: null,
    activeInbox: null,
    isLoading: false,
    isLoaded: false,
    activeTabHistory: "CHRONOLOGY",
    request: {
        status: "",
        shortDescription: "",
        typeId: "",
        title: "",
        // purpose: "",
        files: [],
        priority: '',
        comment: [{ text: "" }],
        author: { name: "", secondName: "" }
    },
    isLoadingHistory: false
}


export const ticketsReducer = (state = initState, action) => {
    switch (action.type) {
        case TASKS.SET_ACTIVE_TASKS: {
            return {
                ...state,
                activeTickets: action.activeTasks,
            }
        }
        case TASKS.SET_ACTIVE_INBOX: {
            return {
                ...state,
                activeInbox: action.activeInbox,
            }
        }

        case TASKS.ADD_COMMENT: {
            return {
                ...state,
                history: [...state.history, action.payload]
            }
        }

        case TASKS.CHANGE_ACTIVE_HISTORY_TAB: {
            return {
                ...state,
                activeTabHistory: action.payload
            };
        }

        case TASKS.SET_HISTORY: {
            return {
                ...state,
                history: action.payload
            };
        }

        case TASKS.SET_SELECTED_TASK: {
            return {
                ...state,
                tasks: action.payload
            };
        }

        default:
            return state
    }
}