import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Nav } from 'react-bootstrap'
import { changeRoutePush } from '../../../../reuseFunctions/changeRoute'
import { history } from '../../../../history'
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'
import Tooltip from '../../../reuseComponent/Tooltips';
import {
    permEditRoles,
    permEditUsers,
    permViewHistory, permViewNotifications,
    permViewRoles,
    permViewUsers,
    permViewDepartmentsDictionary,
    permEditDepartmentsDictionary,
} from '../../../../permissions/settings'

import './style.scss';


const TabsTickets = ({ roles, alias }) => {
    const activeTickets = useSelector(state => state.ticketsReducer.activeTickets);
    const activeInbox = useSelector(state => state.ticketsReducer.activeInbox);

    return (
        <Nav className="tickets-tabs" variant="tabs" activeKey={alias}>
            <Nav.Item>
                <Nav.Link onClick={() => changeRoutePush('/atw/tasks/tickets', history)} eventKey="users">
                    {alias === 'tickets' ? <>
                        <svg className="action-icon header-icon" viewBox="0 0 24 24">
                            <path fill="#FF9800"
                                d="M19,4H18V2H16V4H8V2H6V4H5A2,2 0 0,0 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M5,8V6H19V8H5M10.56,18.46L16.5,12.53L15.43,11.47L10.56,16.34L8.45,14.23L7.39,15.29L10.56,18.46Z" />
                        </svg>
                        {
                            activeTickets && activeTickets > 0 ? (
                                <div className="badge badge-header">{activeTickets}</div>
                            ) : null
                        }
                        <h3 className="title-text m-10">
                            Tickets
                        </h3>

                    </> : <Tooltip
                        text="Tickets"
                        placement="bottom"
                    >
                            <>
                                <svg className="action-icon header-icon" viewBox="0 0 24 24">
                                    <path fill="#FF9800"
                                        d="M19,4H18V2H16V4H8V2H6V4H5A2,2 0 0,0 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M5,8V6H19V8H5M10.56,18.46L16.5,12.53L15.43,11.47L10.56,16.34L8.45,14.23L7.39,15.29L10.56,18.46Z" />
                                </svg>
                                {
                                    activeTickets && activeTickets > 0 ? (
                                        <div className="badge badge-header">{activeTickets}</div>
                                    ) : null
                                }
                            </>
                        </Tooltip>}
                </Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link onClick={() => changeRoutePush('/atw/tasks/inbox', history)} eventKey="roles">
                    {alias === 'inbox' ? <>
                        <svg className="action-icon header-icon" viewBox="0 0 24 24">
                            <path fill="#FF9800" d="M16,10H14V7H10V10H8L12,14M19,15H15A3,3 0 0,1 12,18A3,3 0 0,1 9,15H5V5H19M19,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z" />
                        </svg>
                        {
                            activeInbox && activeInbox > 0 ? (
                                <div className="badge badge-header">{activeInbox}</div>
                            ) : null
                        }
                        <h3 className="title-text m-10">Inbox</h3>

                    </> : <Tooltip
                        text="Inbox"
                        placement="bottom"
                    >
                            <>
                                <svg className="action-icon header-icon" viewBox="0 0 24 24">
                                    <path fill="#FF9800" d="M16,10H14V7H10V10H8L12,14M19,15H15A3,3 0 0,1 12,18A3,3 0 0,1 9,15H5V5H19M19,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z" />
                                </svg>
                                {
                                    activeInbox && activeInbox > 0 ? (
                                        <div className="badge badge-header">{activeInbox}</div>
                                    ) : null
                                }
                            </>
                        </Tooltip>}
                </Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps, null)(TabsTickets)