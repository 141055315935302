export const calculateTotalBids = (bids = []) => {
    const data = {
        totalGross: '',
        totalNet: '',
        totalPacks: '',
        totalPallets: '',
        targetTotalPrice: '',
        totalQuantity: 0
    }
    bids.forEach(bid => {
        if(data.totalGross !== '' && !isNaN(parseFloat(bid.grossWeight)))
            data.totalGross += parseFloat(bid.grossWeight)
        else if(!isNaN(parseFloat(bid.grossWeight)))
            data.totalGross = parseFloat(bid.grossWeight)

        if(data.totalNet  !== '' && !isNaN(parseFloat(bid.netWeight)))
            data.totalNet += parseFloat(bid.netWeight)
        else if(!isNaN(parseFloat(bid.netWeight)))
            data.totalNet = parseFloat(bid.netWeight)

        if(data.totalPacks  !== '' && !isNaN(parseFloat(bid.packages)))
            data.totalPacks += parseFloat(bid.packages)
        else if(!isNaN(parseFloat(bid.packages)))
            data.totalPacks = parseFloat(bid.packages)

        if(data.totalPallets  !== '' && !isNaN(parseFloat(bid.pallets)))
            data.totalPallets += parseFloat(bid.pallets)
        else if(!isNaN(parseFloat(bid.pallets)))
            data.totalPallets = parseFloat(bid.pallets)

        if(data.targetTotalPrice  !== '' && !isNaN(parseFloat(bid.totalPrice)))
            data.targetTotalPrice += parseFloat(bid.totalPrice)
        else if(!isNaN(parseFloat(bid.totalPrice)))
            data.targetTotalPrice = parseFloat(bid.totalPrice)

        if(bid.quantity) {
            data.totalQuantity += bid.quantity
            // if(data.totalQuantity[bid.unit])
            //     data.totalQuantity[bid.unit] += bid.quantity
            // else
            //     data.totalQuantity[bid.unit] = bid.quantity
        }
    })
    // data.totalQuantity = JSON.stringify(data.totalQuantity)
    return data
}

export const totalBidsToStore = total => {
    return {
        targetGrossWeight: total.totalGross,
        targetNetWeight: total.totalNet,
        targetPackagesCount: total.totalPacks,
        targetPalletsCount: total.totalPallets,
        targetQuantity: total.totalQuantity,
        totalPrice: total.targetTotalPrice
    }
}

export const calculateItemActualPricePerUnit = (bid, bidList, addressPrice) => {
    if (!bidList.every(item => item.pricePerUnit && item.quantity && item.pallets && item.actualQuantity)) {
        return null;
    }

    const totalPallets = bidList.reduce((acc, item) => {
        return acc + item.pallets;
    }, 0);

    const transportPriceForBid = (addressPrice || 0) / totalPallets * bid.pallets;
    const transportPricePerUnit = transportPriceForBid / bid.actualQuantity;

    return (bid.pricePerUnit * bid.quantity - (bid.creditNote || 0)) / bid.actualQuantity + transportPricePerUnit;
}

export const calculateItemActualTotalPrice = (bid, bidList, addressPrice) => {
    const actualPricePerUnit = calculateItemActualPricePerUnit(bid, bidList, addressPrice);
    return actualPricePerUnit ? actualPricePerUnit * bid.actualQuantity : null;
}

export const calculateActualTotalPrice = (bidList, addressPrice) => {
    return bidList.reduce((acc, item) => {
        return acc + calculateItemActualPricePerUnit(item, bidList, addressPrice) * item.actualQuantity;
    }, 0)
}
