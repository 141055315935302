import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import Form from '../form'
import { editQualityItemRequest } from '../../../../../actions/commodity-groups'


class CreateForm extends PureComponent{

    handleSubmit = values => {
        const { handleCancel,editQuality,id } = this.props
        // handleCancel && handleCancel ()
        id && editQuality && editQuality(id,values,handleCancel)

    }

    render(){
        const { handleCancel,value,inputs } = this.props
        return (
            <Form
                initValues={{
                    value: value,
                    inputs: inputs
                }}
                handleSubmit={this.handleSubmit}
                handleCancel={handleCancel}
            />
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editQuality: (id,values,callback) => dispatch(editQualityItemRequest(id,values,callback))
    }
}

export default connect(null,mapDispatchToProps)(CreateForm)