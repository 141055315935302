import React, { PureComponent } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'

export default class DatePicker extends PureComponent{

    static defaultProps = {
        timePicker: false,
        timePicker24Hour: false,
        timePickerSeconds: false,
        drops: 'down',
        singleDatePicker: true,
        parentEl: 'body'
    }

    render(){
        let {
            startDate,
            onApply,
            drops,
            timePicker,
            timePicker24Hour,
            timePickerSeconds,
            minDate,
            maxDate,
            singleDatePicker,
            onShow = null,
            onHide = null,
            parentEl = 'body'
        } = this.props
        if(startDate){
            startDate = moment(startDate,'DD.MM.YYYY H:mm:ss')
        }
        else{
            startDate = moment()
            startDate.set({hour: 0,minute: 0,second: 0})
        }
        return(
            <DateRangePicker
                alwaysShowCalendars = {true}
                drops={drops}
                minDate = { minDate ? minDate : undefined }
                maxDate={maxDate ? maxDate : moment().date(31).month(8).year(2030)}
                startDate={startDate}
                singleDatePicker={singleDatePicker}
                timePicker = {timePicker}
                timePicker24Hour = {timePicker24Hour}
                timePickerSeconds={timePickerSeconds}
                onApply={onApply}
                onShow={onShow}
                onHide={onHide}
                parentEl={parentEl}
            >
                {this.props.children}
            </DateRangePicker>
        )
    }
}