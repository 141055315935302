import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {setData} from "../../../../actions/admin-header"
import ReleaseNotesItem from "./item"
import ReleaseNotesForm from "../create"
import Modal from '../../../reuseComponent/modal/index'
import {removeNote, sendRmNotification, getCurrentVersion,getNotes} from "../../../../actions/releaseNotes"
import StandartLeftPanner from "../../../reuseComponent/Header/standartLeftPannel";
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';

class ReleaseNotesList extends PureComponent{

    state={
        openCreateModal: false,
        removeModal: false,
        idNote: null,
    };

    componentDidMount(){
        const { getNotes,setHeaderData,getCurrentVersion } = this.props
        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'About WCS',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#000000" d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
                </svg>)),
            },
            LeftPannel: StandartLeftPanner,
            handleAdd: () => this.showHideModal({
                modalName: 'openCreateModal',
                open: true
            }),
            handleSearch: null,
        });

        getNotes && getNotes()
        getCurrentVersion && getCurrentVersion();
    }

    componentWillUnmount() {
        this.props.setHeaderData({title: null, handleAdd: null, handleSearch: null, CustomComponent: null})
    }

    showHideModal = ({modalName,idNote,open,callback}) => {
        this.setState({
            [modalName] : open,
            idNote: idNote
        },() => {
            callback && callback()
        })
    };

    render() {
        const {
            openCreateModal,
            removeModal,
            idNote,
        } = this.state;

        const {
            removeNote,
            sendRmNotification,
            currentVersion,
            notes
        } = this.props;


        return(
            <>
                <div>
                    <h6>Current version: {currentVersion ? currentVersion.version : null}</h6>
                    {notes.map(item =>
                        <ReleaseNotesItem
                            key={item.id}
                            data={item}
                            handleSend={() => sendRmNotification(item.id)}
                            handleEdit={() => this.showHideModal({
                                modalName: 'openCreateModal',
                                open: true,
                                idNote: item.id
                            })}
                            handleDelete={() => this.showHideModal({
                                modalName: 'removeModal',
                                open: true,
                                idNote: item.id
                            })}
                        />
                    )}
                </div>
                <Modal
                    classNameBody="release-note-modal-body"
                    open={openCreateModal}
                    isFooter={false}
                    handleClose={callback => this.showHideModal({
                        modalName: 'openCreateModal',
                        open: false,
                        callback: callback
                    })}
                    Content={() => <ReleaseNotesForm
                        idNote={idNote}
                        handleClose={callback => {
                            console.log(callback)
                            this.showHideModal({
                                modalName: 'openCreateModal',
                                open: false,
                                callback: () => callback && callback()
                            })
                        }}
                    />}
                    options={{
                        centered: true,
                        enforceFocus: false
                    }}
                    classNameModal="modal-80"
                />
                <Modal
                    open={removeModal}
                    Content={ConfirmModalContent}
                    handleClose={e => this.showHideModal({
                        modalName: 'removeModal',
                        open: false
                    })}
                    handleCancel={() => this.showHideModal({
                        modalName: 'removeModal',
                        open: false
                    })}
                    handleConfirm={() => {
                        this.showHideModal({
                            modalName: 'removeModal',
                            open: false,
                            callback: () => removeNote(idNote)
                        })

                    }}
                    options={{
                        centered: true
                    }}
                />
            </>
        )
    }
}

const mapStateToProps = state => ({
    currentVersion: state.releaseNotesReducer.currentVersion,
    notes: state.releaseNotesReducer.notes
});

const mapDispatchToProps = dispatch => ({
    setHeaderData: (data) => dispatch(setData(data)),
    removeNote: (obj) => dispatch(removeNote(obj)),
    getCurrentVersion: () => dispatch(getCurrentVersion()),
    sendRmNotification: id => dispatch(sendRmNotification(id)),
    getNotes: () => dispatch(getNotes())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotesList)