import React, {PureComponent} from 'react';
import {connect} from 'react-redux'
import FormCustomersCreate from '../form/create';
import FormCustomersEdit from '../form/edit';
import {findPermInArray} from '../../../../reuseFunctions/checkPermission'
import {permCreateCustomers} from '../../../../permissions/customers'


class RequestDetails extends PureComponent {

    render() {
        const {id} = this.props.match.params;
        const {
            cancelCreate, callbackAfterCreation, roles,
            handleShowRemoveModal,
            handleShowAllocateModal,
            handleShowRejectModal,
            handleShowDeactivateModal,
            changeRow,
            refetchData,
            handleAllocate,
            acceptCustomer,
            rejectCustomer,
        } = this.props;


        return (
            <div className={`details customer-details edit-details`}>
                <div className="content-details">
                    {id === 'new' && findPermInArray(roles, permCreateCustomers) && (
                        <div className="create-customer-form">
                            <FormCustomersCreate
                                cancelCreate={cancelCreate}
                                callbackAfterCreation={callbackAfterCreation}
                                handleShowRemoveModal={handleShowRemoveModal}
                            />
                        </div>
                    )}
                    {id !== 'new' && (
                        <div className="edit-customer-form">
                            <FormCustomersEdit
                                cancelCreate={cancelCreate}
                                callbackAfterEdit={callbackAfterCreation}
                                handleShowRemoveModal={handleShowRemoveModal}
                                handleShowAllocateModal={handleShowAllocateModal}
                                handleShowRejectModal={handleShowRejectModal}
                                handleShowDeactivateModal={handleShowDeactivateModal}
                                changeRow={changeRow}
                                handleAllocate={handleAllocate}
                                acceptCustomer={acceptCustomer}
                                rejectCustomer={rejectCustomer}
                                refetchTableData={refetchData}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps, null)(RequestDetails);
