import React, {PureComponent} from 'react';
import { connect } from 'react-redux'
import { Formik } from 'formik'
import Preloader from '../../../../reuseComponent/preloader/preloader';
import { schema } from '../form/schema'
import BodyForm from '../form'
import {createTaskTemplate, editTaskTemplate, getTaskTemplateById} from "../../../../../services/dictionaries";
import {notify} from "../../../../reuseComponent/toast";

class CreateTemplateForm extends PureComponent{
    state = {
        template: {
            departmentId: '',
            description: '',
            executorId: '',
            poDefault: false,
            title: '',
            responsible: null
        },
        loading: false,
    };

    componentDidMount(){
        const {idTemplate} = this.props;

        if(idTemplate){
            this.setState({loading: true})
            getTaskTemplateById(idTemplate)
                .then(res => {if(res.data){
                    this.setState({template: {
                            departmentId: res.data.department ? res.data.department.id : '',
                            description: res.data.description,
                            id: res.data.id,
                            executorId: res.data.executor ? res.data.executor.id : '',
                            poDefault: res.data.poDefault,
                            title: res.data.title,
                            responsible: res.data.executor ? {
                                value: res.data.executor.id,
                                label: `${res.data.executor.name} ${res.data.executor.secondName}`,
                                type: 'User'
                            } : res.data.department ? {
                                value: res.data.department.id,
                                label: res.data.department.name,
                                type: 'Department'
                            } : null
                        }})
            }})
                .catch(err => console.log(err))
                .finally(() => this.setState({loading: false}))
        }
    }

    handleSubmit = values => {
        const {idTemplate, refetchTable, handleClose} = this.props;
        if(idTemplate){
            editTaskTemplate(values.id, values)
                .then(res => {
                    refetchTable();
                    handleClose();
                    this.setState({
                        departmentId: '',
                        description: '',
                        executorId: '',
                        poDefault: false,
                        title: '',
                        responsible: null
                    })
                    notify('success', 'Template updated')
                })
                .catch(err => console.log(err))
        }else{
            createTaskTemplate(values)
                .then(res => {
                    refetchTable();
                    handleClose();
                    this.setState({
                        departmentId: '',
                        description: '',
                        executorId: '',
                        poDefault: false,
                        title: '',
                        responsible: null
                    })
                    notify('success', 'Template created')
                })
                .catch(err => console.log(err))
        }
    };

    render() {
        const {
            template,
            loading,
        } = this.state;

        const {
            handleClose,
            loadingData,
            departments,
            users,
        } = this.props;

        return (
            loading || loadingData ? <Preloader/> :
                <Formik
                    initialValues={template}
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    render={props => <BodyForm
                        {...props}
                        handleClose={handleClose}
                        departmentsList={departments}
                        usersList={users}
                    />}
                />
        )
    }

}

const mapStateToProps = state => ({
    loadingData: state.departmentsReducer.isLoadingDepartment || state.usersReducer.loading,
    departments: [...state.departmentsReducer.departments.map(item => ({value: item.id,
        label: item.name,type: 'Department'}))],
    users: [ ...state.usersReducer.users.map(item => ({value: item.id,
        label: `${item.name} ${item.secondName}`,type: 'User'}))]
});

export default connect(mapStateToProps, null)(CreateTemplateForm);