import * as yup from 'yup'
import adminInstance from '../../../instances/admininstance'
import { url } from "../../../../constants"
import validator  from 'validator'

export const schemaInviteUser = yup.object().shape({
    email: yup
        .string()
        .email()
        .required()
        .test(
            'is-valid-email',
            'email is not valid',
            value => {

                return value ? validator.isEmail(value) : false
            }
        )
        .test(
            'is-exist',
            'user exists',
            async (value) => {
                let isValid = false
                await adminInstance.get(`${url}/user/check_email/${value}`)
                    .then(res => {
                        isValid = false
                    })
                    .catch(err => {
                        isValid = true
                    })
                return isValid
            }
        ),
     name: yup
         .string()
         .trim()
         .required(),
      roleId: yup
          .string()
          .required('role is required field')
})

