import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LegendItem } from '../InventoryMap/LegendItem'
import { ScalePanel } from '../InventoryMap/ScalePanel'
import { GridItem } from './GridItem'
import { ActiveGridItem } from './ActiveGridItem'

const step = 0.25

const InventoryMapEditorMap = ({
                                 width,
                                 height,
                                 maxX,
                                 maxY,
                                 coordinatesArray,
                                 scale,
                                 setScale,
                                 slots,
                                 instrument,
                                 updateSelectedSlots,
                                 handleStartSelection,
                                 handleUpdateSelection,
                                 handleFinishSelection,
                                 selectedSlots,
                                 lastSelectedSlots,
                                  isCtrl,
                                  locations,
                               }) => {

  const wrapperRef = useRef(null)
  const scaleRef = useRef(false)

  const sizeX = useMemo(() => {

    return width / (maxX + 2);

  }, [width, maxX])

  const sizeY = useMemo(() => {
    return width / (maxX + 2)/1.3

  }, [width, maxX])

  const sizeYByY = useMemo(() => {
    const sYByX = width / (maxX + 2)/1.3;

    const sYByY =  height / (maxY + 2);

    if((maxY+2)*sYByX > height){
      return sYByY
    }else{
      return sYByX
    }
  }, [width, maxX, maxY, height])

  const [mousePosition, setMousePosition] = useState(null)
  const [offsetTop, setOffsetTop] = useState(0)
  const [offsetLeft, setOffsetLeft] = useState(0)

  const svgRef = useRef(null)
  const positionRef = useRef(null)

  useEffect(() => {
    positionRef.current = mousePosition
  }, [mousePosition])

  const handleMouseMove = useCallback((e) => {
    const bbox = svgRef.current.getBoundingClientRect();
    const x = e.clientX - bbox.left;
    const y = e.clientY - bbox.top;
    let coordinates = {
      x: Math.floor(x/sizeX/scale) + 1,
      y: Math.floor(y/sizeY/scale),
    }
    if(!positionRef.current || positionRef.current.x !== coordinates.x || positionRef.current.y !== coordinates.y)
    setMousePosition(coordinates)
  }, [sizeX, sizeY, scale])

  const handleScroll = useCallback((e) => {
    e.persist()
    if(!scaleRef.current && isCtrl){
      scaleRef.current = true
      setScale(prevState => {
        if(e.deltaY > 0){
          return prevState + step*Math.abs(e.deltaY) <= 5 ? prevState + step*Math.ceil(Math.abs(e.deltaY)) : 5
        }else{
          return prevState - step*Math.abs(e.deltaY) >= 1 ? prevState - step*Math.ceil(Math.abs(e.deltaY)) : 1
        }
      })
      setTimeout(() => {
        scaleRef.current = false
      }, 300)
    }
  }, [setScale, isCtrl])


  useEffect(() => {
    if(wrapperRef.current){}
  }, [])

  return (<>
    <div style={{
    width: maxX && sizeX ? (maxX+2)*sizeX + 7 : 0,
    height: maxY && sizeYByY ? (maxY+2)*sizeYByY + 7 : 0,
    overflow: isCtrl ? 'hidden' : 'auto',
    padding: '0 7px 0 0',
      position: 'relative'
  }}
         onWheel={handleScroll}
                 ref={wrapperRef}
         // onScroll={e => {
         //   setOffsetTop(e.target.scrollTop)
         //   setOffsetLeft(e.target.scrollLeft)
         // }}
  >
      {!instrument && <div
        className="select-space-wrapper"
        style={{
          // top: offsetTop,
          // left: offsetLeft,
          width: maxX && sizeX ? (maxX+2)*sizeX + 7 : 0,
          height: maxY && sizeYByY ? (maxY+2)*sizeYByY + 7 : 0,
          position: 'fixed',
          top: '54px',
          left: '47px',
        }}
      >
        Select space to manage
      </div>}
      <svg className="legend-svg top-legend" width={maxX && sizeX ? scale*(maxX+2)*sizeX : 0} height={ sizeY ? sizeY*scale : 0}>
        {coordinatesArray && coordinatesArray.top && coordinatesArray.top.map(item => <LegendItem key={item.id} legendItem={item} sizeX={sizeX} sizeY={sizeY} scale={scale} isSelected={mousePosition && mousePosition.x === item.coordinateX}/>)}
      </svg>
      <div style={{display: 'flex', width: maxX && sizeX ? scale*(maxX+2)*sizeX : 0, position: 'relative'}}>
        <svg className="legend-svg left-legend" width={sizeX ? sizeX*scale : 0} height={ maxY && sizeY ? scale*(maxY)*sizeY : 0} style={{minWidth: sizeX ? sizeX*scale : 0}}>
          {coordinatesArray && coordinatesArray.left && coordinatesArray.left.map(item => <LegendItem key={item.id} legendItem={item} sizeX={sizeX} sizeY={sizeY} scale={scale} isSelected={mousePosition && mousePosition.y === item.coordinateY}/>)}
        </svg>
        <svg
          ref={svgRef}
          onMouseMove={handleMouseMove}
          onMouseOut={e => {
            setMousePosition(null)
          }}
          width={maxX && sizeX ? scale*(maxX)*sizeX : 0} height={ maxY && sizeY ? scale*(maxY)*sizeY : 0}>
          {slots.map(item => <GridItem
            key={item.id}
            gridItem={item}
            sizeX={sizeX}
            sizeY={sizeY}
            scale={scale}
            instrument={instrument}
            updateSelectedSlots={updateSelectedSlots}
            handleStartSelection={handleStartSelection}
            handleUpdateSelection={handleUpdateSelection}
            handleFinishSelection={handleFinishSelection}
            locations={locations}
          />)}
          {selectedSlots.map(item => <ActiveGridItem
            key={`s${item.id}`}
            gridItem={item}
            sizeX={sizeX}
            sizeY={sizeY}
            scale={scale}
            handleStartSelection={handleStartSelection}
            handleUpdateSelection={handleUpdateSelection}
            handleFinishSelection={handleFinishSelection}
          />)}
          {lastSelectedSlots.map(item => <ActiveGridItem
            key={`l${item.id}`}
            gridItem={item}
            sizeX={sizeX}
            sizeY={sizeY}
            scale={scale}
            handleStartSelection={handleStartSelection}
            handleUpdateSelection={handleUpdateSelection}
            handleFinishSelection={handleFinishSelection}
          />)}
        </svg>
        <svg className="legend-svg right-legend" width={sizeX ? sizeX*scale : 0} height={ maxY && sizeY ? scale*(maxY)*sizeY : 0} style={{minWidth: sizeX ? sizeX*scale : 0, right: `-${7}px`}}>
          {coordinatesArray && coordinatesArray.right && coordinatesArray.right.map(item => <LegendItem key={item.id} legendItem={item} sizeX={sizeX} sizeY={sizeY} scale={scale} isSelected={mousePosition && mousePosition.y === item.coordinateY}/>)}
        </svg>
      </div>
      <svg className="legend-svg bottom-legend" width={maxX && sizeX*scale ? scale*(maxX+2)*sizeX : 0} height={ sizeY ? sizeY*scale : 0} style={{minWidth: sizeX ? sizeX*scale : 0}}>
        {coordinatesArray && coordinatesArray.bottom && coordinatesArray.bottom.map(item => <LegendItem key={item.id} legendItem={item} sizeX={sizeX} sizeY={sizeY} scale={scale} isSelected={mousePosition && mousePosition.x === item.coordinateX}/>)}
      </svg>
    </div>
    {width ? <ScalePanel scale={scale} setScale={setScale} width={maxX && sizeX ? (maxX+2)*sizeX : 0}/> : null}
  </>)
}

export { InventoryMapEditorMap }
