import React, {PureComponent} from 'react'
import {Button} from 'react-bootstrap';
import {ReactComponent as AddIcon} from '../../../../assets/images/plus.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg'
import Condition from './condition'
import Tooltip from '../../../reuseComponent/Tooltips'


class ConditionSet extends PureComponent {

    render() {
        const {
            conditions,
            handleAddCondition,
            handleRemoveCondition,
            setFieldValue,
            isSelected,
            fields,
            selectSet,
            removeSet
        } = this.props
        return <div onClick={selectSet} className={`set-item ${isSelected ? 'selected' : ''}`}>
            <Tooltip text="Delete set">
                <DeleteIcon onClick={removeSet} className="standart-svg delete-set-icon" />

            </Tooltip>
            <div className="list-conditions">
                {conditions.map(condition => <Condition
                    fields={fields}
                    setFieldValue={(field,value) => setFieldValue(condition.id,field,value)}
                    removeCondition={() => handleRemoveCondition(condition.id)}
                    condition={condition}
                    key={condition.id}
                />)}
            </div>
            <div className="footer-conditions">
                <Button type="button" onClick={handleAddCondition}>
                    <AddIcon className="standart-svg"/> Add condition
                </Button>
            </div>
        </div>
    }
}

export default ConditionSet