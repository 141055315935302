import { SUPPLIERS } from '../constants'
import adminInstance  from '../components/instances/admininstance'
import { url } from '../constants'
import { notify } from '../components/reuseComponent/toast'
import { convertToFormDataWithArray } from '../reuseFunctions/objectToFormData'


const loadingAction = isLoading => ({
    type: SUPPLIERS.IS_LOADING,
    payload: isLoading
})

export const clearSupplierAction = () => ({
    type: SUPPLIERS.CLEAR_SUPPLIER
})

export const setSupplierAction = supplier => ({
    type: SUPPLIERS.GET_SUPPLIER,
    payload: supplier
})

export const getSupllier = (id) => {
    return dispatch => {
        dispatch(loadingAction(true))
        adminInstance.get(`${url}/supplier/${id}`)
            .then(res => {
                dispatch(setSupplierAction(res.data.supplier))
                dispatch(loadingAction(false))
            })
            .catch(err => {
                dispatch(loadingAction(false))
                notify('error','Oops something went wrong')
            })
    }
}

export const editSupllier = (id,values,callback) => {
    return dispatch => {
        delete values.author
        delete values.createdAt
        try{
            values.qualities = JSON.stringify(values.qualities)
        }
        catch{
            values.qualities = ''
        }
        const data = convertToFormDataWithArray(values)
        dispatch(loadingAction(true))
        adminInstance.put(`${url}/supplier/${id}`,data)
            .then(res => {
                dispatch(loadingAction(false))
                callback && callback(res.data)
                notify('success','Supplier successfully edited')
            })
            .catch(err => {
                dispatch(loadingAction(false))
                notify('error','Oops something went wrong')
            })
    }
}

export const createSupplier = (values,callback) => {
    return dispatch => {
        try{
            values.qualities = JSON.stringify(values.qualities)
        }
        catch{
            values.qualities = ''
        }
        dispatch(loadingAction(true))
        const data = convertToFormDataWithArray(values)
        adminInstance.post(`${url}/supplier`,data)
            .then(res => {
                dispatch(loadingAction(false))
                if(callback){
                    callback(res.data)
                }
                notify('success','Supplier successfully created')
            })
            .catch(err => {
                dispatch(loadingAction(false))
                notify('error','Oops something went wrong')
            })
    }
}

export const setQualitiesAction = qualities => ({
    type: SUPPLIERS.SET_QUALITIES,
    payload: qualities
})