import React, {PureComponent} from 'react'
import {Button} from 'react-bootstrap'
import {ReactComponent as RightArrow} from '../../../../assets/images/arrow-right.svg'
import {ReactComponent as AddIcon} from '../../../../assets/images/plus.svg'
import ConditionSet from './conditionSet'
import PalletCollapse from './pallet-collapse'


class FirstStep extends PureComponent {

    getTransformedSets = (sets) => {
        let array = []
        // console.log(sets.keys())
        Object.keys(sets).forEach(key => {
            array.push({
                id: key,
                conditions: sets[key]
            })
        })
        return array
    }

    render() {
        const {
            changeStep,
            handleCancel,
            sets,
            handleAddSet,
            handleRemoveSet,
            handleAddCondition,
            handleRemoveCondition,
            setFieldValue,
            fields,
            selectSet,
            activeSet,
            pallets,
            selectUnselectPallet,
            selectedIdsPallets,
            showSelectedPallet
        } = this.props
        const transformedSets = this.getTransformedSets(sets)
        return (
            <div className="first-step step">
                <div className="title-step">
                    <span className="name-step"><span>Step 1:</span> Offer parameters <strong>(Optional)</strong></span>
                    <div className="actions">
                        <Button type="button" variant="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button type="button" onClick={() => changeStep(2)}>
                            <RightArrow className="standart-svg"/> Next
                        </Button>
                    </div>
                </div>
                <div className="content-step">
                    <div className="sets-wrapper">
                        <div className="title-sets">
                            <h3>Choose from existing sets</h3>
                            <Button type="button" onClick={handleAddSet}>
                                <AddIcon className="standart-svg"/> Add Condition Set
                            </Button>
                        </div>
                        {transformedSets.map(set => <ConditionSet
                            isSelected={activeSet && set.id === activeSet}
                            selectSet={() => selectSet(set.id)}
                            key={set.id}
                            fields={fields}
                            setFieldValue={(id, field, value) => setFieldValue(set.id, id, field, value)}
                            handleAddCondition={() => handleAddCondition(set.id)}
                            handleRemoveCondition={id => handleRemoveCondition(set.id, id)}
                            conditions={set.conditions}
                            removeSet={(e) => {
                                e.stopPropagation()
                                handleRemoveSet(set.id)
                            }}
                        />)}
                    </div>
                    <div className="offer-pallets-wrapper">
                        <div className="title-pallets">
                            <span className="pallets-count">
                                Pallets found: <strong>{pallets.length}</strong>
                            </span>
                            <span className="items-count">
                                Total items: <strong>{pallets.reduce((acc, pallet) => {
                                    return pallet.amountOfItems + acc
                                }, 0)}
                                </strong>
                            </span>
                            <div className="actions">
                                {selectedIdsPallets.length > 0 && <Button onClick={showSelectedPallet}>
                                    Show selected pallets ({selectedIdsPallets.length})
                                </Button>}
                            </div>
                        </div>
                        {pallets.map(pallet => <PalletCollapse
                            isSelected={selectedIdsPallets.includes(pallet.id)}
                            key={pallet.id}
                            pallet={pallet}
                            handleSelect={selectUnselectPallet}
                        />)}
                    </div>
                </div>
            </div>
        )
    }
}

export default FirstStep