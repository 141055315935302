import React, { useMemo } from 'react'
import classNames from 'classnames'

import Switcher from '../../../../reuseComponent/Switcher'
import CustomTooltip from '../../../../reuseComponent/Tooltips'

import { ReactComponent as EditIcon } from '../../../../../assets/images/edit.svg'
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/delete.svg'
import { EditableTableItem } from './EditableTableItem'
import { findPermInArray } from '../../../../../reuseFunctions/checkPermission'
import { permEditMapEditor } from '../../../../../permissions/inventory'
import { useSelector } from 'react-redux'

const InstrumentTableItem = ({
                               instrument,
                               handleOpenRemoveModal,
                               selectedToEdit,
                               setSelectedToEdit,
                               editCallback,
                               setLoading,
                               selectedInstrument,
                               setSelectedInstrument,
                             }) => {
  const roles = useSelector(state => state.rolesReducer.roles)

  const isSelected = useMemo(() => {
    return selectedInstrument && selectedInstrument.id === instrument.id
  }, [instrument, selectedInstrument])

  const isSelectedToEdit = useMemo(() => {
    return selectedToEdit && selectedToEdit.id === instrument.id
    }, [instrument, selectedToEdit])

  const hasPermToEdit = useMemo(() => {
    return findPermInArray(roles, permEditMapEditor)
  }, [roles])

  return (
    <tr
      className={classNames({"selected": isSelected})}
      onClick={() => {
        if(!isSelectedToEdit && hasPermToEdit){
          setSelectedInstrument(instrument)
        }
      }}
    >
      {isSelectedToEdit && hasPermToEdit ? <EditableTableItem
        handleCancel={() => setSelectedToEdit(null)}
        editCallback={editCallback}
        instrument={instrument}
        setLoading={setLoading}
      /> : <>
      <td>{instrument.name}</td>
      <td>
        <div className="color-block" style={{backgroundColor: instrument.color}}></div>
      </td>
      <td>
        {!instrument.clear && <Switcher
          disabled={true}
          checked={instrument.slot}
        />}
      </td>
      <td className="actions-cell">
        {!instrument.clear && !isSelected && hasPermToEdit && <>
          <CustomTooltip text="Edit">
            <EditIcon className="standard-svg" onClick={(e) => {
              e.stopPropagation()
              setSelectedToEdit(instrument)
            }}/>
          </CustomTooltip>
          {instrument.deleted && <CustomTooltip text="Delete">
            <DeleteIcon className="standard-svg" onClick={(e) => {
              e.stopPropagation()
              handleOpenRemoveModal(instrument)
            }} />
          </CustomTooltip>}
        </>}
      </td>
      </>}
    </tr>
  )
}

export { InstrumentTableItem }