import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import BodyForm from "./bodyForm";
import { schema } from "./schema";
import {
  getParamsByAlias,
  addInputParams
} from "../../../../../../actions/detailsPo";
import { getBrands, addBrandInput } from "../../../../../../services/brands";
import { notify } from "../../../../../reuseComponent/toast";
import { checkAlias } from "../../../../../../reuseFunctions/checkAlias";

class Form extends PureComponent {
  initValues = {
    value: ""
  };

  state = {
    paramsList: [],
    currId: null
  };

  handleSubmit = values => {
    const { alias } = this.props;
    const formatedValue = {
      value: values.value.label,
      inputs: this.props.name
    };
    let formatedAlias = checkAlias(alias);

    if (alias === "brand") {
      addBrandInput(this.state.currId, {
        ...formatedValue,
        input: this.props.name
      })
        .then(res => {
          if (res.data.data !== null) {
            notify("success", 'Input successfully added');
          } else {
            notify("error", "Input already exists");
          }
        })
        .catch(err => {
          notify("error", "Oops something went wrong");
        });
    } else {
      addInputParams(
        this.state.currId,
        formatedAlias ? formatedAlias : this.props.alias,
        formatedValue
      )
        .then(res => {
          if (res.data.data !== null) {
            notify("success", 'Input successfully added');
          } else {
            notify("error", "Input already exists");
          }
        })
        .catch(err => {
          notify("error", "Oops something went wrong");
        });
    }

    this.props.handleCancel();
  };

  componentDidMount() {
    const { alias } = this.props;
    let formatedAlias = checkAlias(alias);
    if (alias === "brand") {
      getBrands({
        limit: 20,
        page: 0,
        search: ""
      }).then(res => {
        this.setState({
          paramsList: res.data.brands.map(item => ({
            id: item.id,
            value: item.name
          }))
        });
      });
    } else {
      getParamsByAlias(formatedAlias ? formatedAlias : alias).then(res => {
        this.setState({ paramsList: res.data });
      });
    }
  }

  render() {
    const { handleCancel } = this.props;
    const { paramsList } = this.state;
    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={this.initValues}
        validationSchema={schema}
        render={props => (
          <BodyForm
            {...props}
            handleCancel={handleCancel}
            getDictId={id => this.setState({ currId: id })}
            paramsList={paramsList}
          />
        )}
      />
    );
  }
}

export default connect(null, null)(Form);
