import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import * as uniqId from 'uniqid'
import {Button, Form} from 'react-bootstrap'
import {Formik, FieldArray} from 'formik'
import ConditionRow from './conditions-row'
import { schema } from './schema'
import { ReactComponent as PlusIcon } from '../../../../assets/images/plus.svg'


class SmartFilter extends PureComponent {

    state = {
        conditions: this.props.conditions
    }

    addCondition = () => {
        this.setState(state => {
            return {
                conditions: state.conditions.concat({
                    id: uniqId(),
                    field: '',
                    value: '',
                    separator: null
                })
            }
        })
    }

    componentDidMount() {
        if (this.props.conditions && this.props.conditions.length === 0)
            this.addCondition()
    }

    render() {
        const {conditions} = this.state


        const { handleCancel,cg1,cg2,cg3,cg4,handleConfirm,sizeType,quality } = this.props
        return (
            <Formik
                validationSchema={schema}
                onSubmit={values => handleConfirm(values.conditions)}
                enableReinitialize={true}
                initialValues={{
                    conditions: conditions
                }}
                render={props => {
                    const {
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleSubmit,
                    } = props
                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className="smart-filter-wrapper">
                                <FieldArray
                                    name="conditions"
                                    render={arrayHelpers => <div className="conditions-wrapper">
                                        {values.conditions.map((condition, index) => {
                                            return <ConditionRow
                                                cg1={cg1}
                                                cg2={cg2}
                                                cg3={cg3}
                                                cg4={cg4}
                                                quality={quality}
                                                sizeType={sizeType}
                                                touched={touched}
                                                errors={errors}
                                                setFieldValue={setFieldValue}
                                                index={index}
                                                key={condition.id}
                                                condition={condition}
                                                removeCondition={() => arrayHelpers.remove(index)}
                                            />
                                        })}
                                        <Button onClick={() => arrayHelpers.push({
                                            id: uniqId(),
                                            field: '',
                                            value: '',
                                            separator: null
                                        })}>
                                            <PlusIcon className="standart-svg"/>
                                            Add condition
                                        </Button>
                                    </div>}
                                />
                                <div className="actions-smart-filter footer-form-custom">
                                    <Button type="button" variant="secondary" onClick={handleCancel}>Cancel</Button>
                                    <Button type="submit">Apply</Button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            />

        )
    }
}


export default connect(null, null)(SmartFilter)