import React  from 'react'
import { connect } from 'react-redux'
import DropDownMenu from '../../../reuseComponent/DropDown'
import { findPermInArray } from '../../../../reuseFunctions/checkPermission';
import { permEditWorkPlaces } from '../../../../permissions/dictionaries';


const ActionsMenuComponent = ({handleDelete,handleEdit,roles}) => {

    return (
        findPermInArray(roles,permEditWorkPlaces) && <DropDownMenu
            ContentMenu={() => {
                return (
                     <ul className='list-unstyled no-margin menu-table-actions'>
                        <li>
                            <a href="/" onClick={e => {
                                e.preventDefault()
                                handleEdit && handleEdit()
                            }}>Edit</a>
                        </li>
                        <li>
                            <a href="/" onClick={(e) => {
                                e.preventDefault()
                                handleDelete && handleDelete()
                            }}>Delete</a>
                        </li>
                    </ul>
                )
            }}
        >

        </DropDownMenu>
    )
}

const mapStateToProps = state => ({
    roles: state.rolesReducer.roles,
});

export default connect(mapStateToProps,null)(ActionsMenuComponent)