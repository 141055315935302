import React, { PureComponent } from 'react'
import {connect } from 'react-redux'

import {setData} from "../../../../actions/admin-header"
import {changeRoutePush} from "../../../../reuseFunctions/changeRoute"
import {
    url
} from "../../../../constants"
import adminInstance from "../../../instances/admininstance"
import Table from '../../../reuseComponent/Table/MaterialTable'
import DropDownMenu from "../../../reuseComponent/DropDown"
import {history} from "../../../../history"
import {removeNotification} from "../../../../actions/notifications"
import Header from '../../../reuseComponent/Table/table-head'
import Pagination from '../../../reuseComponent/Table/Pagination'
import TabsSettings from "../../settings/tabs"
import {findPermInArray} from "../../../../reuseFunctions/checkPermission"
import {permEditNotifications, permSendNotifications} from "../../../../permissions/settings"
import Toolbar from './Toolbar'
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import withTracker from "../../../reuseComponent/GoogleAnalytics";
import { notify } from '../../../reuseComponent/toast';


class NotificationsList extends PureComponent{

    state = {
        removeModal: false,
        idNotification: null,
        removedTitle: '',
    };

    tableRef = React.createRef()

    generateColumns =  [
        {
            title: 'Title',
            field: 'name',
            sorting: false,
            grouping: false
        },
        {
            title: 'Subject',
            field: 'subject',
            sorting: false,
            grouping: false
        },
        {
            title: 'Event',
            field: 'event',
            sorting: false,
            grouping: false,
            render: rowData => {
                return rowData.event && rowData.event.displayName ? rowData.event.displayName : null
            }
        },
        // {
        //     title: 'Content',
        //     field: 'content',
        //     sorting: false,
        //     grouping: false,
        //     render: rowData => {
        //         return <div
        //             style={{maxWidth: '300px', maxHeight: '400px', wordWrap: 'break-word', overflowY: 'hidden'}}
        //             dangerouslySetInnerHTML={{__html: rowData.content}}>
        //         </div>
        //     }
        // },
        {
            title: 'Receivers',
            field: 'email',
            sorting: false,
            grouping: false,
            render: rowData => {
                return rowData.email.length
            }
        },
        {
            title: '',
            field: 'actions',
            sorting: false,
            grouping: false,
            headerStyle: {
                width: 32
            },
            render: rowData => {
                return findPermInArray(this.props.roles, permEditNotifications) ?
                    <DropDownMenu
                        ContentMenu={() => {
                            return (
                                <ul className='list-unstyled no-margin menu-table-actions'>
                                    <li>
                                        <a href="/" onClick={e => {
                                            e.preventDefault()
                                            history.push(`/atw/settings/notifications/edit/${rowData.id}`)
                                        }}>Edit</a>
                                    </li>
                                    <li>
                                        <a href="/" onClick={e => {
                                            e.preventDefault()
                                            this.setState({
                                                removedTitle: rowData.name,
                                            });
                                            this.showHideModal({
                                                modalName: 'removeModal',
                                                open: true,
                                                idNotification: rowData.id
                                            })
                                        }}>Delete</a>
                                    </li>
                                </ul>
                            )
                        }}
                    >
                    </DropDownMenu> : null
            }
        }
    ];

    showHideModal = ({modalName,idNotification,open,callback}) => {
        this.setState({
            [modalName] : open,
            idNotification: idNotification
        },() => {
            callback && callback()
        })
    };

    componentDidMount(){
        this.props.setHeaderData({
            LeftPannel: TabsSettings,
            leftPannelData: {
                alias: 'notifications'
            },
            handleSearch: (value) => this.tableRef.current.onSearchChange(value),
            handleAdd: null
        })
    }

    componentWillUnmount() {
        this.props.setHeaderData({handleAdd: null, handleSearch: null})
    }

    refetchTable = () => {
        this.tableRef.current && this.tableRef.current.onQueryChange()
    };

    getNotificationList = query => {
        const { page,pageSize, search } = query
        return new Promise((resolve,reject) => {
            adminInstance.get(`${url}/notify`,{
                params: {
                    page: page ? page : 0,
                    limit: pageSize,
                    search: `s:${search};`
                }
            })
                .then(res => {
                    resolve({
                        totalCount: res.data.data.totalElements,
                        data: res.data.data.content,
                        page: query.page
                    })
                })
                .catch(err => {
                    notify('errors','Oops something went wrong')
                })
        })
    }

    render() {
        const {
            removeModal,
            idNotification,
            removedTitle,
        } = this.state

        const {
            removeNotification
        } = this.props
        return  <div className="table-block wrapper-table">
            <Table
                components={{
                    Toolbar: props => <Toolbar
                        {...props}
                        handleAdd={findPermInArray(this.props.roles, permEditNotifications) ? () => {changeRoutePush(`/atw/settings/notifications/create`, this.props.history)} : null}
                        handleSendNewsLetter={findPermInArray(this.props.roles, permSendNotifications) ? () => {changeRoutePush(`/atw/settings/notifications/inner-newsletter`, this.props.history)} : null}
                    />,
                    Header: Header,
                    Pagination: Pagination
                }}
                paginationPosition="toolbar"
                tableRef={this.tableRef}
                columns={this.generateColumns}
                data={this.getNotificationList}
                title=""
                options={{
                    maxBodyHeight: 'calc(100vh - 130px)',
                    showTitle: false,
                    search: true,
                    emptyRowsWhenPaging: false,
                    pageSize: 50,
                    pageSizeOptions: [10, 25, 50],
                }}
            />
            {findPermInArray(this.props.roles, permEditNotifications) ? <CustomModalWithChildren
                open={removeModal}
                Content={ConfirmModalContent}
                handleClose={e => this.showHideModal({
                    modalName: 'removeModal',
                    open: false
                })}
                handleCancel={e => this.showHideModal({
                    modalName: 'removeModal',
                    open: false
                })}
                handleConfirm={() => {
                    this.showHideModal({
                        modalName: 'removeModal',
                        open: false,
                        callback: () => {
                            removeNotification({
                                id: idNotification,
                                callback: this.refetchTable
                            })
                        }
                    })

                }}
                options={{
                    centered: true
                }}
            >
                <ConfirmModalContent text={`delete "${removedTitle}" notification`}/>
            </CustomModalWithChildren> : null}
            
        </div>
    }
}

const mapStateToProps = state => ({
    roles: state.rolesReducer.roles,
});

const mapDispatchToProps = dispatch => ({
    setHeaderData: (data) => dispatch(setData(data)),
    removeNotification: obj => dispatch(removeNotification(obj))
});

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(NotificationsList));
