import React from 'react'
import { Formik } from 'formik'
import BodyForm from './bodyForm'
import { schema } from './schema'


const FormSmartFilter = props => {

    const {
        handleSubmit,
        handleCancel,
        initValues,
        isLoading
    } = props

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
            render={formikProps => <BodyForm
                {...formikProps}
                handleCancel={handleCancel}
                isLoading={isLoading}
            />}
        />
    )
}

export default FormSmartFilter