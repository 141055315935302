import React , { useState,useEffect }  from 'react'
import { useSelector } from 'react-redux'
import { getLastScanned } from '../../../../../../services/products';

const LastScannedItem = () => {
    const [lastScannedItem,setlastScannedItem] = useState(null)

    const orderId = useSelector(state => state.purchaseOrdersReducer.detailsPurchase.id)

    useEffect(() => {
        getLastScanned(orderId)
            .then(res => {
                setlastScannedItem(res.data.data)
            })
    },[orderId])
    return (
        lastScannedItem ? <div className="last-scanned-item">
                Your last inbounded article: <span dangerouslySetInnerHTML={{__html: lastScannedItem.text}}></span>
        </div>
             : null
    )
}

export { LastScannedItem }