import { separators } from '../components/admin/purchaseOrders/products/smart-filter/conditions'

export const generateSearchString = (obj) => {
    const {search="", filters=[]} = obj
    let result = ''
    result += `s:${search};`
    filters.forEach((filter) => {
        if(typeof filter.value === 'string')
            result+= `${filter.column.field}:${filter.value};`
    })
    return result
}

export const generateQueryStringWithFilters = (obj) => {
    const { search = "",conditions = [] } = obj
    let result = ''
    result += `s:${search};`
    conditions.forEach(condition => {
        const separator = separators.find(sep => sep.label === condition.separator.label)
        result += `${condition.field}${separator ? separator.separator : ':'}${separator.label === 'empty' || separator.label === 'is not empty' ? null : condition.value};`
    })
    return result
}