import React, {PureComponent} from 'react'
import {TableCell} from '@material-ui/core'

class HeaderCell extends PureComponent {

    state = {
        minWidth: this.props.columnDef.minWidth ? this.props.columnDef.minWidth : 100,
        style: this.props.columnDef.headerStyle && this.props.columnDef.isResizable ? this.props.columnDef.headerStyle : {
            minWidth: this.props.columnDef.minWidth ? this.props.columnDef.minWidth : 100
        },
        startWidth: 0,
        startClientX: 0,
        isTouch: false
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.columnDef.headerStyle !== this.props.columnDef.headerStyle)
            || (this.props.columnDef.headerStyle
                && prevProps.columnDef.headerStyle
                && this.props.columnDef.headerStyle.minWidth !== prevProps.columnDef.headerStyle.minWidth
            )) {
            this.setState({
                style: {
                    minWidth: this.props.columnDef.headerStyle && this.props.columnDef.headerStyle.minWidth
                        ? this.props.columnDef.headerStyle.minWidth
                        : this.props.columnDef.minWidth
                }
            })
        }
    }


    handleMouseDown = e => {
        document.addEventListener('mousemove', this.handleMouseMove)
        document.addEventListener('mouseup', this.handleMouseUp)
        this.setState({
            isTouch: true,
            startClientX: e.clientX,
            startWidth: this.state.style.minWidth
        })
    }

    handleMouseUp = e => {
        const {startClientX, minWidth, startWidth} = this.state
        document.removeEventListener('mousemove', this.handleMouseMove)
        document.removeEventListener('mouseup', this.handleMouseUp)
        this.setState(state => ({
            style: {
                minWidth: (e.clientX - startClientX) + startWidth >= minWidth
                    ? (e.clientX - startClientX) + startWidth
                    : minWidth
            },
            // minWidth: (e.clientX - startClientX) > 0 ? (e.clientX - startClientX) + minWidth : 0,
            isTouch: false,
            startClientX: 0
        }), () => {
            const {columnDef, changeHeaderStyle} = this.props
            changeHeaderStyle(columnDef, {
                ...this.state.style
            })
        })
    }

    handleMouseMove = e => {
        const {isTouch, startClientX, startWidth} = this.state
        const {minWidth} = this.props.columnDef
        if (isTouch) {
            this.setState(state => ({
                style: {
                    minWidth: (e.clientX - startClientX) + startWidth >= minWidth
                        ? (e.clientX - startClientX) + startWidth
                        : minWidth
                }
            }))
        }
    }

    render() {
        const {
            content,
            headerStyle,
            columnDef,
            classes
        } = this.props
        const {style} = this.state
        return (
            <TableCell
                align={['numeric'].indexOf(columnDef.type) !== -1 ? 'right' : 'left'}
                className={`${classes.header} po-header-cell ${false ? 'dragging' : ''}`}
                style={{...columnDef.headerStyle,...headerStyle, ...style}}
            >
                {content}
                {columnDef.isResizable && <svg
                    onMouseDown={this.handleMouseDown}
                    className="resize-icon" viewBox="0 0 24 24"
                >
                    <path fill="#000000"
                          d="M18,16V13H15V22H13V2H15V11H18V8L22,12L18,16M2,12L6,16V13H9V22H11V2H9V11H6V8L2,12Z"/>
                </svg>
                }
            </TableCell>
        )
    }
}

export default HeaderCell