import { SIZES_DICTIONARY } from '../constants'
import { url } from '../constants'
import { notify } from '../components/reuseComponent/toast'
import adminInstance from '../components/instances/admininstance'
import axios from 'axios'
import { convertToFormData } from '../reuseFunctions/objectToFormData'

const isLoadingSizesAction = isLoading => ({
    type: SIZES_DICTIONARY.IS_LOADING_SIZES,
    payload: isLoading
})

export const setSizesSetsAction = sets => ({
    type: SIZES_DICTIONARY.SET_SIZES_SETS,
    payload: sets
})

export const getSizesSetsRequest = () => {
    return dispatch => {
        dispatch(isLoadingSizesAction(true))
        adminInstance.get(`${url}/size/set`)
            .then(res => {
                dispatch(setSizesSetsAction(res.data.map(set => ({
                    id: set.id,
                    commodityId: set.commodity.id,
                    genderId: set.gender.id,
                    ...set
                }))))
                if(res.data[0])
                    dispatch(setActiveSetAction(res.data[0].id))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(isLoadingSizesAction(false))
            })
    }
}

const isLoadingSimpleParams = isLoading => ({
    type: SIZES_DICTIONARY.IS_LOADING_SIMPLE_PARAMETERS_FOR_SIZES,
    payload: isLoading
})

const getSimpleParameters = alias => {
    return adminInstance.get(`${url}/simple_parameter/${alias}`)
}

const setSimpleParamsAction = data => ({
    type: SIZES_DICTIONARY.SET_SIMPLE_PARAMS_FOR_SIZES,
    payload: data
})

export const getAllSimpleParams = () => dispatch => {
    dispatch(isLoadingSimpleParams(true))
    axios.all([getSimpleParameters('commodity'),getSimpleParameters('gender')])
        .then(axios.spread((commodityRes,genderRes) => {
            dispatch(setSimpleParamsAction({
                commodities: commodityRes.data,
                genders: genderRes.data
            }))
        }))
        .catch(err => {
            notify('error','Can not to get genders and commodities values')
        })
        .finally(() => {
            dispatch(isLoadingSimpleParams(false))
        })
}

export const addSizeSetAction = set => ({
    type: SIZES_DICTIONARY.ADD_SIZE_SET,
    payload: set
})

export const editSetSizeAction = (id,newValues) => ({
    type: SIZES_DICTIONARY.EDIT_SIZE_SET,
    payload: {
        id: id,
        values: newValues
    }
})

export const createNewSizeSetRequest = (tempId,values) => dispatch => {
    adminInstance.post(`${url}/size/set`,convertToFormData(values))
        .then(res => {
            dispatch(editSetSizeAction(tempId,{
                id: res.data.id,
                commodityId: res.data.commodity.id,
                genderId: res.data.gender.id,
                ...res.data,
                sizeRow: []
            }))
            notify('success','Set successfully created')
            dispatch(setActiveSetAction(res.data.id))
        })
        .catch(err => {
            notify('error','Can not create set of size')
        })
}

export const editSizeSetRequest = (id,values) => dispatch => {
    adminInstance.put(`${url}/size/set/${id}`,convertToFormData(values))
        .then(res => {
            notify('success','Set successfully edited')
            dispatch(editSetSizeAction(id,{
                id: res.data.id,
                commodityId: res.data.commodity.id,
                genderId: res.data.gender.id,
                ...res.data
            }))
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
}

export const removeSetAction = id => ({
    type: SIZES_DICTIONARY.REMOVE_SIZE_SET,
    payload: id
})

export const removeSetRequest = id => (dispatch,getState) => {
    const state = getState()
    const activeSet = state.sizeDictionaryReducer.activeSet
    adminInstance.delete(`${url}/size/set/${id}`)
        .then(res => {
            dispatch(removeSetAction(id))
            if(activeSet === id)
                dispatch(setActiveSetAction(null))
            notify('success','Set successfully removed')
        })
        .catch(err => {
            notify('error','Can not remove set')
        })
}

export const setActiveSetAction = id => ({
    type: SIZES_DICTIONARY.SET_ACTIVE_SIZE_SET,
    payload: id
})

const isLoadingSizeTypesAction = isLoading => ({
    type: SIZES_DICTIONARY.IS_LOADING_SIZE_TYPES,
    payload: isLoading
})

const setSizeTypesAction = types => ({
    type: SIZES_DICTIONARY.SET_SIZE_TYPES,
    payload: types
})

export const getSizeTypes = () => dispatch => {
    dispatch(isLoadingSizeTypesAction(true))
    adminInstance.get(`${url}/size/type`)
        .then(res => {
            dispatch(setSizeTypesAction(res.data))
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
        .finally(() => {
            dispatch(isLoadingSizeTypesAction(false))
        })
}

const addSizeTypeAction = sizeType => ({
    type: SIZES_DICTIONARY.ADD_NEW_SIZE_TYPE,
    payload: sizeType
})

export const createSizeType = () => dispatch => {
    adminInstance.post(`${url}/size/type`,convertToFormData({
        name: 'New type'
    }))
        .then(res => {
            dispatch(addSizeTypeAction(res.data))
            notify('success','Size type successfully created')
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
}

const editSizeTypeAction = (id,data) => ({
    type: SIZES_DICTIONARY.EDIT_SIZE_TYPE,
    payload: {
        id: id,
        data: data
    }
})

export const editSizeType = (id,name) => dispatch => {
    adminInstance.put(`${url}/size/type/${id}`,convertToFormData({
        name: name
    }))
        .then(res => {
            notify('success','Size type successfully edited')
            dispatch(editSizeTypeAction(id,res.data))
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
}

export const removeSizeTypeAction = id => ({
    type: SIZES_DICTIONARY.REMOVE_SIZE_TYPE,
    payload: id
})

export const removeSizeType = (id) => dispatch => {
    adminInstance.delete(`${url}/size/type/${id}`)
        .then(res => {
            dispatch(removeSizeTypeAction(id))
            notify('success','Size type successfully deleted')
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
}

const addRowToSizeSetAction = (setId,sizeRow) => ({
    type: SIZES_DICTIONARY.ADD_ROW_TO_SIZE_SET,
    payload: {
        setId,
        sizeRow
    }
})

export const addRowToSizeSetRequest = setId => dispatch => {
    adminInstance.post(`${url}/size/row`,convertToFormData({
        setId
    }))
        .then(res => {
            dispatch(addRowToSizeSetAction(setId,res.data))
            notify('success','Size type row successfully created')
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
}

const createValueAction = (rowId,valueObj) => ({
    type: SIZES_DICTIONARY.ADD_NEW_VALUE_TO_ROW_SIZE_SET,
    payload: {
        rowId,
        valueObj
    }
})

// setId - id of Row NOT A SET!

export const createValueRequest = (setId,sizeTypeId,value) => dispatch => {
    adminInstance.post(`${url}/size/value`,convertToFormData({
        setId,
        sizeTypeId,
        value
    }))
        .then(res => {
            dispatch(createValueAction(setId,res.data))
            notify('success','Value successfully created')
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
}

const editValueAction = (valueId,valueObj) => ({
    type: SIZES_DICTIONARY.EDIT_VALUE_OF_ROW_SIZE_SET,
    payload: {
        valueId,
        valueObj
    }
})

export const editValueRequest = (value,valueId) => dispatch => {
    adminInstance.put(`${url}/size/value/${valueId}`,convertToFormData({
        value: value
    }))
        .then(res => {
            dispatch(editValueAction(valueId,res.data))
            notify('success','Value successfully edited')
        })
        .catch(err => {
            console.log(err)
        })
}

const removeSizeRowAction = sizeRowId => ({
    type: SIZES_DICTIONARY.REMOVE_SIZE_ROW,
    payload: sizeRowId
})

export const removeSizeRowRequest = sizeRowId => dispatch => {
    adminInstance.delete(`${url}/size/row/${sizeRowId}`)
        .then(res => {
            notify('success','Size type row successfully deleted')
            dispatch(removeSizeRowAction(sizeRowId))
        })
        .catch(err => {
            notify('error','Oops something went wrong')
        })
}