import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Table from '../../../reuseComponent/Table/MaterialTable'
import Header from '../../../reuseComponent/Table/table-head'
import Pagination from '../../../reuseComponent/Table/Pagination'
import {setData} from '../../../../actions/admin-header'
import {getChannelList,removeItemFromChannel} from '../../../../actions/commodity-groups'
import ActionMenuComponent from './actions-menu'
import Modal from '../../../reuseComponent/modal'
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import CreateCg1Form from './create'
import EditCg1Form from './edit'
import {findPermInArray} from "../../../../reuseFunctions/checkPermission";
import { permEditChannelsDictionary} from '../../../../permissions/dictionaries';
import StandartLeftPanner from "../../../reuseComponent/Header/standartLeftPannel";
import Toolbar from './Toolbar'
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import withTracker from "../../../reuseComponent/GoogleAnalytics";

class ListCg2 extends PureComponent {

    state = {
        currId: null,
        modalCreate: false,
        modalRemove: false,
        removedTitle: '',
    }

    tableRef = React.createRef()

    columns = [
        {
            title: 'Output',
            field: 'value',
            sorting: false
        },
        {
            title: 'Inputs',
            field: 'inputs',
            sorting: false,
            render: rowData => {
                return rowData.synonyms ? rowData.synonyms.map(val => <div key={val}>{val}</div>) : null
            }
        },
        {
            title: '',
            field: 'actions',
            sorting: false,
            cellStyle: {
                width: 32
            },
            render: rowData => {
                return <ActionMenuComponent
                    handleEdit={() => this.setState({
                        modalCreate: true,
                        currId: rowData.id
                    })}
                    handleDelete={() => this.setState({
                        removedTitle: rowData.value,
                        modalRemove: true,
                        currId: rowData.id
                    })}
                />
            }
        }
    ]

    componentDidMount() {
        const {setHeaderData, getChannelList} = this.props
        getChannelList && getChannelList();
        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'Channels',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#795548"
                          d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z"/>
                </svg>)),
            },
            // textAddButton: 'Add',
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: (value) => {
                this.tableRef.current && this.tableRef.current.onSearchChange(value)
            }
        })
    }


    componentDidUpdate(prevProps){
        const { setHeaderData, roles } = this.props
        if(JSON.stringify(roles) !== JSON.stringify(prevProps.roles))
            setHeaderData && setHeaderData({
                leftPannelData: {
                    title: 'Channels',
                    logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                        <path fill="#000000"
                              d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z"/>
                    </svg>)),
                },
                LeftPannel: StandartLeftPanner,
                handleAdd: null,
                handleSearch: (value) => {
                    this.tableRef.current && this.tableRef.current.onSearchChange(value)
                }
            })
    }

    render() {
        const {isLoading, listChannels,removeChannelItem,roles} = this.props
        const {modalCreate, modalRemove, currId, removedTitle} = this.state
        return (
            <div className="wrapper-table">
                <Table
                    components={{
                        Toolbar: props => <Toolbar
                            {...props}
                            handleAdd={findPermInArray(roles, permEditChannelsDictionary) ? () => this.setState({
                            modalCreate: true
                        }) : null}/>,
                        Header: Header,
                        Pagination: Pagination
                    }}
                    paginationPosition="toolbar"
                    isLoading={isLoading}
                    tableRef={this.tableRef}
                    columns={this.columns}
                    data={listChannels}
                    options={{
                        showTitle: false,
                        search: true,
                        emptyRowsWhenPaging: false,
                        pageSize: 50,
                        pageSizeOptions: [10, 25, 50],
                    }}
                />
                {findPermInArray(roles, permEditChannelsDictionary) && <Modal
                    open={modalCreate}
                    isFooter={false}
                    Content={() => currId ? <EditCg1Form
                        id={currId}
                        inputs={listChannels.find(item => item.id === currId).synonyms}
                        value={listChannels.find(item => item.id === currId).value}
                        handleCancel={() => this.setState({
                            currId: null,
                            modalCreate: false
                        })}
                    /> : <CreateCg1Form
                        handleCancel={() => this.setState({
                            currId: null,
                            modalCreate: false
                        })}
                    />}
                    handleClose={() => this.setState({
                        currId: null,
                        modalCreate: false
                    })}
                    options={{
                        centered: true
                    }}
                />
                }
                {findPermInArray(roles, permEditChannelsDictionary) && <CustomModalWithChildren
                    open={modalRemove}
                    handleClose={() => this.setState({
                        modalRemove: false,
                        currId: null
                    })}
                    handleCancel={() => this.setState({
                        modalRemove: false,
                        currId: null
                    })}
                    handleConfirm={() => this.setState({
                        modalRemove: false,
                        currId: null
                    }, () => removeChannelItem(currId))}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`delete "${removedTitle}" channel`}
                    />
                </CustomModalWithChildren>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.commodityGroupsReducer.isLoadingChannel,
        listChannels: state.commodityGroupsReducer.listChannels,
        roles: state.rolesReducer.roles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setHeaderData: (data) => dispatch(setData(data)),
        getChannelList: () => dispatch(getChannelList()),
        removeChannelItem: id => dispatch(removeItemFromChannel(id))
    }
}

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(ListCg2));