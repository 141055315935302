export const mapDictionaryInitialValues = {
  name: '',
  color: '#b99aff',
  slot: false,
}

const emptyLocationType = {
  id: 'empty',
  color: '#f2f2f2',
  name: 'Empty',
  slot: false,
  deleted: true,
}

/**
 * generate empty location by coordinates
 * @param x - coordinate
 * @param y - coordiante
 * @returns {{coordinateY: *, coordinateX: *, name: null, pallet: null, locationType: {deleted: boolean, color: string, name: string, id: string, slot: boolean}, id: string}}
 */
export const generateEmptyLocation = (x, y) => {
  return ({
    id: `${x}-${y}`,
    coordinateX: x,
    coordinateY: y,
    name: null,
    pallet: null,
    locationType: emptyLocationType,
  })
}

/**
 * check if locations is near
 * @param currentLoc
 * @param nexLoc
 * @param maxX
 * @returns {boolean}
 */
const checkIfIsNextCoordinate = (currentLoc, nexLoc, maxX) => {
  let isNext = false
  if(currentLoc.coordinateY !== nexLoc.coordinateY){
    if(currentLoc.coordinateY + 1 === nexLoc.coordinateY){
      if(currentLoc.coordinateX === maxX && nexLoc.coordinateX === 1){
        isNext = true
      }
    }
  }else if(currentLoc.coordinateX + 1 === nexLoc.coordinateX){
    isNext = true
  }

  return isNext
}

const fillRangeWithEmptyLocations = (currX, currY, nextX, nextY, maxX) => {
  let res = [];
  for(let i = currY; i <= nextY; i++){
    const startJ = currY === i ? currX + 1 : 1;
    const finishJ = nextY === i ? nextX : maxX + 1;

    for(let j = startJ; j < finishJ; j++){
      res.push(generateEmptyLocation(j, i))
    }
  }

  return res;
}

/**
 * function should fill all empty coordinates
 * @param slots - array of locations
 * @param maxX - size of plan by X coordinate
 * @param maxY - size of plan by Y coordinate
 * @returns {[]} - array of locations with all filed coordinates
 */
export const generateEmptySlots = (slots, maxX, maxY) => {
  let resultArray = [];
  if(slots.length > 0) {
    if (slots[0].coordinateX !== 1 || slots[0].coordinateY !== 1) {
      resultArray = [
        ...resultArray,
        ...fillRangeWithEmptyLocations(0, 1, slots[0].coordinateX, slots[0].coordinateY, maxX)
      ]
    }
    for (let i = 0; i < slots.length; i++) {
      resultArray.push(slots[i])
      const nextEl = slots[i + 1]

      //check if is NOT the last element in array
      if (nextEl) {
        //check if coordinates is NOT near
        if (!checkIfIsNextCoordinate(slots[i], nextEl, maxX)) {
          resultArray = [
            ...resultArray,
            ...fillRangeWithEmptyLocations(slots[i].coordinateX, slots[i].coordinateY,
              nextEl.coordinateX, nextEl.coordinateY, maxX)]
        }
      } else {
        //check if is NOT the last element on grid
        if (!(slots[i].coordinateX === maxX && slots[i].coordinateY === maxY)) {
          resultArray = [
            ...resultArray,
            ...fillRangeWithEmptyLocations(slots[i].coordinateX, slots[i].coordinateY,
              maxX + 1, maxY, maxX)]
        }
      }
    }
  }else{
    return fillRangeWithEmptyLocations(0, 1, maxX + 1, maxY, maxX)
  }

  return resultArray
}

const groupCoordinatesByY = (selectedCoordinates) => {
  let obj = {}

  for(let i = 0; i < selectedCoordinates.length; i++){
    if(obj[selectedCoordinates[i].coordinateY]){
      obj[selectedCoordinates[i].coordinateY] = obj[selectedCoordinates[i].coordinateY]
        .concat([selectedCoordinates[i]])

    }else{
      obj[selectedCoordinates[i].coordinateY] = [selectedCoordinates[i]]
    }
  }

  let arr = [];

  for(let key in obj){
    arr.push({
      y: parseInt(key),
      xArr: obj[key].sort((a, b) => a > b ? -1 : 1)
    })
  }

  return arr
}

export const groupCoordinatesArrayToBlocks = (coordinates) => {
  let res = []

  let arr = groupCoordinatesByY(coordinates)

  while (arr.length) {
    let startPosition = null;
    let finishPosition = null;
    let usedIds = [];

    for(let i = 0; i < arr.length; i++){

      if(i !== 0 && arr[i-1].y + 1 !== arr[i].y){
        break;
      }else if(!startPosition || i === 0) {
          for(let j = 0; j < arr[i].xArr.length; j++){
            if(!startPosition){
              startPosition = {
                x: arr[i].xArr[0].coordinateX,
                y: arr[i].y,
              }
              finishPosition = {
                x: arr[i].xArr[0].coordinateX,
                y: arr[i].y,
              }
              usedIds.push(arr[i].xArr[j].id)
            }else{
              if(arr[i].xArr[j].coordinateX === arr[i].xArr[j-1].coordinateX + 1){
                finishPosition = {
                  x: arr[i].xArr[j].coordinateX,
                  y: arr[i].y,
                }
                usedIds.push(arr[i].xArr[j].id)
              }else{
                break
              }
            }
          }
      }else{
        let permIds = []
        let canUse = true;
        const startArr = arr[i].xArr.find(item => item.coordinateX === startPosition.x)
        if(startArr){
          const startArrPos = arr[i].xArr.indexOf(startArr)

          for(let j = startArrPos; j <= startArrPos + finishPosition.x - startPosition.x; j++){
            if(arr[i].xArr[j] && arr[i].xArr[j].coordinateX === startPosition.x + j - startArrPos){
              permIds.push(arr[i].xArr[j].id)
            }else{
              canUse = false;
              break;
            }
          }
          if(canUse){
            finishPosition.y = arr[i].y
            usedIds = usedIds.concat(permIds)
          }else{
            break
          }
        }else{
          break;
        }
      }
      // for(let j = 0; j < arr[i].xArr.length; j++){
      //   if(!startPosition){
      //     startPosition = {
      //       x: arr[i].xArr[0].coordinateX,
      //       y: arr[i].y,
      //     }
      //     finishPosition = {
      //       x: arr[i].xArr[0].coordinateX,
      //       y: arr[i].y,
      //     }
      //     usedIds.push(arr[i].xArr[j].id)
      //   }else if(i === 0){
      //     if(arr[i].xArr[j].coordinateX === arr[i].xArr[j-1].coordinateX + 1){
      //       finishPosition = {
      //         x: arr[i].xArr[j].coordinateX,
      //         y: arr[i].y,
      //       }
      //     }else{
      //       break
      //     }
      //   }else{
      //
      //   }
      // }
      // break;
    }

    res.push({
      startPosition,
      finishPosition
    })
    //array clearing
    arr = arr.map(item => ({
      ...item,
      xArr: item.xArr.filter(xEl => !usedIds.includes(xEl.id))
    })).filter(item => item.xArr.length !== 0)

    // arr.length = 0;
  }

  return res
}