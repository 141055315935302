import React ,  { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'


class TableAnalysis extends PureComponent{

    render(){
        const { data } = this.props
        return (
             <div className="wrapper-table-analysis">
                <Table size="sm">
                    <thead>
                    <tr>
                        <th>SKU Depth ({data[data.length-1].toFixed(2)})</th>
                        <th>Brand</th>
                        <th>Total Items ({data[data.length-2]})</th>
                    </tr>
                    </thead>
                    <tbody>
                        {data.map(row => {
                            if(typeof row !== 'object') return null
                            const keys = Object.keys(row.values)
                            return <tr key={row.label}>
                                <td>{row.label}</td>
                                <td>{keys.map(key => <div key={key}>{key}</div>)}</td>
                                <td>{row.total}</td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        orderId: state.analysisToolReducer.orderId,
    }
}

export default connect(mapStateToProps,null)(TableAnalysis)