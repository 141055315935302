import React from 'react'
import Collapse from '../collapse'
import PackageHeaderCollapse from './package-collapse-header'
import TableProductsInCollapse from '../../packages/package-item/table-products'


const PackageCollapseItem = ({pack,index,activePackage, set}) => {
    return (
        <Collapse
            data={{
                index: index,
                pack: pack,
                activePackage: activePackage,
                set: set
            }}
            dataBody={{
                products: pack.packageProductSet
            }}
            Toggle={PackageHeaderCollapse}
            Body={TableProductsInCollapse}
        />
    )
}

export default PackageCollapseItem