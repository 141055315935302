import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Table from "../../../reuseComponent/Table/MaterialTable";
import Header from "../../../reuseComponent/Table/table-head";
import Pagination from "../../../reuseComponent/Table/Pagination";
import { setData } from "../../../../actions/admin-header";
import {
  getCg2List,
  removeItemFromCg2
} from "../../../../actions/commodity-groups";
import ActionMenuComponent from "./actions-menu";
import Modal from "../../../reuseComponent/modal";
import CreateCg2Form from "./create";
import EditCg2Form from "./edit";
import { findPermInArray } from "../../../../reuseFunctions/checkPermission";
import { permEditCg1Dictionary } from "../../../../permissions/dictionaries";
import StandartLeftPanner from "../../../reuseComponent/Header/standartLeftPannel";
import Toolbar from "./Toolbar";
import { checkRelations } from "../../../../services/dictionaries";
import { notify } from "../../../../components/reuseComponent/toast";
import DeleteForm from "./formDelete";
import { ConfirmModalContent } from "../../../reuseComponent/confirmModalComponent";
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import withTracker from "../../../reuseComponent/GoogleAnalytics";

class ListCg2 extends PureComponent {
  state = {
    currId: null,
    modalCreate: false,
    modalRemove: false,
    isRelations: false,
    removedTitle: "",
    modalRemoveRelations: false
  };

  tableRef = React.createRef();

  columns = [
    {
      title: "Output",
      field: "value",
      sorting: false
    },
    {
      title: "Inputs",
      field: "inputs",
      sorting: false,
      render: rowData => {
        return rowData.synonyms
          ? rowData.synonyms.map(val => <div key={val}>{val}</div>)
          : null;
      }
    },
    {
      title: "",
      field: "actions",
      sorting: false,
      cellStyle: {
        width: 32
      },
      render: rowData => {
        return (
          <ActionMenuComponent
            handleEdit={() =>
              this.setState({
                modalCreate: true,
                currId: rowData.id
              })
            }
            handleDelete={() => {
              this.setState({
                currId: rowData.id,
                removedTitle: rowData.value
              });
              this.handleCheckeExistRelations(rowData.id);
            }}
          />
        );
      }
    }
  ];

  componentDidMount() {
    const { setHeaderData, getCg2List } = this.props;
    getCg2List && getCg2List();
    setHeaderData &&
      setHeaderData({
        leftPannelData: {
          title: "CG1",
          logo: (
            <svg className="action-icon header-icon" viewBox="0 0 24 24">
              <path
                fill="#795548"
                d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z"
              />
            </svg>
          )
        },
        LeftPannel: StandartLeftPanner,
        handleAdd: null,
        handleSearch: value => {
          this.tableRef.current && this.tableRef.current.onSearchChange(value);
        }
      });
  }

  handleCheckeExistRelations = id => {
    checkRelations(id, "commodity")
      .then(res => {
        if (res.data === true) {
          this.setState({ modalRemoveRelations: true });
        } else {
          this.setState({ modalRemove: true });
        }
      })
      .catch(err => {
        notify("error", "Oops something went wrong");
      });
  };

  render() {
    const { isLoading, listCg2, removeCg2Item, roles } = this.props;
    const {
      modalCreate,
      modalRemove,
      currId,
      removedTitle,
      modalRemoveRelations
    } = this.state;
    return (
      <div className="wrapper-table">
        <Table
          components={{
            Toolbar: props => (
              <Toolbar
                {...props}
                handleAdd={
                  findPermInArray(roles, permEditCg1Dictionary)
                    ? () =>
                        this.setState({
                          modalCreate: true
                        })
                    : null
                }
              />
            ),
            Header: Header,
            Pagination: Pagination
          }}
          paginationPosition="toolbar"
          isLoading={isLoading}
          tableRef={this.tableRef}
          columns={this.columns}
          data={listCg2}
          options={{
            showTitle: false,
            search: true,
            emptyRowsWhenPaging: false,
            pageSize: 50,
            pageSizeOptions: [10, 25, 50]
          }}
        />
        {findPermInArray(roles, permEditCg1Dictionary) && (
          <Modal
            open={modalCreate}
            isFooter={false}
            Content={() =>
              currId ? (
                <EditCg2Form
                  id={currId}
                  inputs={listCg2.find(item => item.id === currId).synonyms}
                  value={listCg2.find(item => item.id === currId).value}
                  handleCancel={() =>
                    this.setState({
                      currId: null,
                      modalCreate: false
                    })
                  }
                />
              ) : (
                <CreateCg2Form
                  handleCancel={() =>
                    this.setState({
                      currId: null,
                      modalCreate: false
                    })
                  }
                />
              )
            }
            handleClose={() =>
              this.setState({
                currId: null,
                modalCreate: false
              })
            }
            options={{
              centered: true
            }}
          />
        )}
        {findPermInArray(roles, permEditCg1Dictionary) && (
          <CustomModalWithChildren
            open={modalRemoveRelations}
            handleClose={() =>
              this.setState({
                modalRemoveRelations: false,
                currId: null
              })
            }
            isFooter={false}
            options={{
              centered: true
            }}
          >
            <DeleteForm
              currId={currId}
              handleCancel={() =>
                this.setState({
                  modalRemoveRelations: false,
                  currId: null
                })
              }
              handleSubmit={values => {
                this.setState(
                  {
                    modalRemoveRelations: false,
                    currId: null
                  },
                  () => removeCg2Item(currId, values.toId.value)
                );
              }}
            />
          </CustomModalWithChildren>
        )}

        {findPermInArray(roles, permEditCg1Dictionary) && (
          <CustomModalWithChildren
            open={modalRemove}
            handleCancel={() =>
              this.setState({
                modalRemove: false,
                currId: null
              })
            }
            handleClose={() =>
              this.setState({
                modalRemove: false,
                currId: null
              })
            }
            handleConfirm={() =>
              this.setState(
                {
                  modalRemove: false,
                  currId: null
                },
                () => removeCg2Item(currId)
              )
            }
            isFooter={true}
            options={{
              centered: true
            }}
          >
            <ConfirmModalContent text={`delete "${removedTitle}" value`} />
          </CustomModalWithChildren>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.commodityGroupsReducer.isLoadingCg2,
    listCg2: state.commodityGroupsReducer.listCg2,
    roles: state.rolesReducer.roles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setHeaderData: data => dispatch(setData(data)),
    getCg2List: () => dispatch(getCg2List()),
    removeCg2Item: (id, toId) => dispatch(removeItemFromCg2(id, toId))
  };
};

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(ListCg2));
