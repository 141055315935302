export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const sortArrayByObjectKey = (array, obj = 'label') => {
    return array && array.sort((a, b) => {
        let textA = a && a[obj] && typeof a[obj] === 'number' ? a[obj] : typeof a[obj] === 'string' ? a[obj].toUpperCase() : '';
        let textB = b && b[obj] && typeof b[obj] === 'number' ? b[obj] : typeof b[obj] === 'string' ? b[obj].toUpperCase() : '';

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
};
