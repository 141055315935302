import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Modal from "../../../reuseComponent/modal";
import HistoryAction from "./history-action";
import DiffModal from "./diff-modal-content";
import "./style.scss";
import {ReactComponent as ChronologyIcon} from "../../../../assets/images/chronology.svg";
import moment from "moment";
import { findPermInArray } from "../../../../reuseFunctions/checkPermission";
import { permTaskChronology, permViewChronology } from "../../../../permissions/productOverview";
import { changeActiveHistoryTab, getHistory } from "../../../../actions/purchase-orders";

class HistoryComponent extends PureComponent {
  state = {
    modalDiff: false,
    currDiff: null
  };

  componentDidMount() {
    const {changeActiveTab} = this.props;
    changeActiveTab && changeActiveTab('TASK');

}

  handleShowDiff = diff => this.setState({ modalDiff: true, currDiff: diff });

  handleCloseDiff = () => this.setState({ modalDiff: false, currDiff: null });

  render() {
    const {activeTab, isLoading, roles,history, refetchTableData, hasOwnTasks} = this.props
    const hasPermToTasks = findPermInArray(roles, permTaskChronology);
    let lastDate = null;
    let lastType = null;

    const { modalDiff, currDiff } = this.state;
    
    return (
      <div className="history-customer-wrapper">
        {history && Array.isArray(history) && history.length > 0 && <h6 className="form-title">
          <ChronologyIcon/>
          Chronology
        </h6>}
        {history &&
          history.map(action => {
            const isShowDate = !lastDate || !lastType || (lastDate && !moment(action.createdAt).isSame(lastDate, 'day'))
            return (
              <HistoryAction
              handleShowDiff={this.handleShowDiff}
              key={action.id} 
              action={action}
              isShowDate={isShowDate}
            />
            )
          })}
        <Modal
          open={modalDiff}
          Content={() => (
            <DiffModal diff={currDiff} handleClose={this.handleCloseDiff} />
          )}
          handleClose={this.handleCloseDiff}
          isFooter={false}
          options={{
            centered: true
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  history: state.customersReducer.history,
  activeTab: state.purchaseOrdersReducer.activeTabHistory,
  isLoading: state.purchaseOrdersReducer.isLoadingHistory,
  roles: state.rolesReducer.roles
});

const mapDispatchToProps = dispatch => {
  return {
    changeActiveTab: tabName => dispatch(changeActiveHistoryTab(tabName)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryComponent);
