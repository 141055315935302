import React from 'react'
import {Card} from 'react-bootstrap'
import moment from "moment"

const ReleaseNotesItem = ({data}) => {
    return (
        <Card className='release-card'>
            <Card.Body>
                <p><strong>Version: {data.version}</strong></p>
                <p><strong>Released</strong>: {moment(data.release).format('DD.MM.YYYY HH:mm')}</p>
                <p><strong>Updates: </strong></p>
                <div dangerouslySetInnerHTML={{__html: data.description}}>
                </div>
            </Card.Body>
        </Card>
    )
};

export default ReleaseNotesItem;