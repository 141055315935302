import React, { PureComponent } from 'react'
import { Modal,Button } from 'react-bootstrap'

class CustomModalWithChildren extends PureComponent{

    static defaultProps = {
        backdropClassName: '',
        classNameModal: '',
        classNameBody: '',
        footerClassName: '',
        modalId : '',
        handleConfirm : () => {},
        open : false,
        handleCancel : () => {},
        Content : () => <div></div>,
        handleClose : () => {},
        isFooter : true,
        confirm: 'Confirm',
        cancel: 'Cancel',
        disableConfirmButton: false,
        disableCancelButton: false,
        withConfirm: true,
        options: {
            centered: true
        }
    }

    render(){
        const {
            handleConfirm,
            open,
            handleCancel,
            handleClose,
            options,
            isFooter,
            modalId,
            classNameBody,
            classNameModal,
            confirm,
            cancel,
            backdropClassName,
            children,
            footerClassName,
            disableConfirmButton,
            disableCancelButton,
            withConfirm
        } = this.props
        return (
            <Modal
                backdropClassName={backdropClassName}
                dialogClassName={classNameModal}
                id={modalId}
                show={open}
                onHide={handleClose}
                {...options}
            >
                <Modal.Body className={classNameBody}>
                    {children}
                </Modal.Body>
                {isFooter ? <Modal.Footer className={footerClassName}>
                    <Button
                        disabled={disableCancelButton}
                        variant="secondary" onClick={handleCancel}>
                        {cancel}
                    </Button>
                    {withConfirm && <Button
                        disabled={disableConfirmButton}
                        variant="primary"
                        onClick={handleConfirm}>
                        {confirm}
                    </Button>}

                </Modal.Footer> : null}

            </Modal>
        )
    }
}

export default CustomModalWithChildren