import React from "react";
import moment from "moment";

const HistoryAction = ({ action, handleShowDiff,isShowDate }) => {
  
  return (
    <div className="history-customer-action">
      <span className="date-action">{isShowDate ? moment(action.createdAt).format('MMM DD, YYYY') : ''}</span>
      <div className="action-name-block">
        <span className="action-name">
          <span className="name-user">
            {action.executor
              ? `${action.executor.name} ${action.executor.secondName} `
              : null}
          </span>
          {action.diff ? (
            <>
              <span
                className="edit-customer"
                onClick={() => handleShowDiff(action.diff)}
              >
                edited customer
              </span>
            </>
          ) : (
            <span dangerouslySetInnerHTML={{ __html: action.text }}></span>
          )}
        </span>
        <span className="time-action"> {moment(action.createdAt, "hh:mm").format("hh:mm")}</span>
      </div>
    </div>
  );
};

export default HistoryAction;
