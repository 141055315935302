export const disabledSelectStyle ={
    multiValueRemove: (styles, { isDisabled }) => ({
        ...styles,
        display: isDisabled ? 'none' : '',
    }),
    multiValueLabel: (styles, {isDisabled}) => ({
        ...styles,
        padding: '3px 6px',
    }),
    dropdownIndicator: (styles, { isDisabled }) => ({
        ...styles,
        display: isDisabled ? 'none' : '',
    }),
    indicatorsContainer: (styles, { isDisabled }) => ({
        ...styles,
        display: isDisabled ? 'none' : '',
    }),
    indicatorSeparator: (styles, { isDisabled }) => ({
        ...styles,
        display: isDisabled ? 'none' : '',
    }),
    // input: (styles, {isDisabled}) => ({
    //     ...styles,
    //     paddingLeft: isDisabled ? '4px': '',
    // })
}