import {RELEASE_NOTES, url} from "../constants";

import adminInstance from "../components/instances/admininstance";
import { notify } from '../components/reuseComponent/toast'
import { convertToFormData } from '../reuseFunctions/objectToFormData'
import moment from "moment";

const loadingNoteStatus = isLoading => ({
    type: RELEASE_NOTES.IS_LOADING_NOTE,
    payload: isLoading
});

const setNoteAction = note => ({
    type: RELEASE_NOTES.SET_NOTE,
    payload: {
        version: note.version,
        description: note.description,
        date: (() => {
            return moment(note.release,'YYYY-MM-DDTHH:mm').format('DD.MM.YYYYTHH:mm')
        })()
    }
});

const setCurrentVersion = version => ({
    type: RELEASE_NOTES.SET_CURRENT_VERSION,
    payload: version
});

export const getNoteById = (id) => {
    return dispatch => {
        dispatch(loadingNoteStatus(true))
        adminInstance.get(`${url}/release_note/${id}`)
            .then(res => {
                dispatch(setNoteAction(res.data))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(loadingNoteStatus(false))
            })
    }
};

const addNoteAction = (note) => ({
    type: RELEASE_NOTES.ADD_RELEASE_NOTE,
    payload: note
})

export const createNote = ({values = {}}) => {
    return dispatch => {
        dispatch(loadingNoteStatus(true))
        adminInstance.post(`${url}/release_note`,convertToFormData(values))
            .then(res => {
                dispatch(addNoteAction(res.data))
                notify('success','Release note successfully created')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(loadingNoteStatus(false))
            })
    }
};

const editNoteAction = (id,newValues) => ({
    type: RELEASE_NOTES.EDIT_RELEASE_NOTE,
    payload: {
        id: id,
        ...newValues
    }
})

export const editNote = ({ values = {},id }) => {
    return dispatch => {
        dispatch(loadingNoteStatus(true))
        adminInstance.put(`${url}/release_note/${id}`,convertToFormData(values))
            .then(res => {
                dispatch(editNoteAction(id,res.data))
                notify('success','Release note successfully edited')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
            .finally(() => {
                dispatch(loadingNoteStatus(false))
            })
    }
};

const removeNoteAction = id => ({
    type: RELEASE_NOTES.REMOVE_RELEASE_NOTE,
    payload: id
})

export const removeNote = (id) => {
    return dispatch => {
        adminInstance.delete(`${url}/release_note/${id}`)
            .then(res => {
                dispatch(removeNoteAction(id))
                notify('success', 'Release note successfully deleted')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
};

export const getCurrentVersion = () => {
    return dispatch => {
        adminInstance.get(`${url}/release_note/selected`)
            .then(res => {
                dispatch(setCurrentVersion(res.data))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
};

export const sendRmNotification = (id) => {
    return dispatch => {
        adminInstance.get(`${url}/release_note/${id}/notify`,{
            params: {
                infoURL: window.location.origin + '/atw/release-notes'
            }
        })
            .then(res => {
                dispatch(setCurrentVersion(res.data))
                notify('success','Notification successfully sent');
            })
            .catch(err => {
                if(err.response && err.response.status === 404)
                    notify('error','Please create notification template')
                else
                    notify('error','Oops something went wrong')
            })
    }
}

const setNotesAction = notes => ({
    type: RELEASE_NOTES.SET_NOTES_LIST,
    payload: notes
})

export const getNotes = (params = {
    limit: 200,
    page: 0
}) => {
    return dispatch => {
        adminInstance.get(`${url}/release_note`,{
            params: params
        })
            .then(res => {
                dispatch(setNotesAction(res.data.notes))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }
}