import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Table from '../../../reuseComponent/Table/MaterialTable'
import Pagination from '../../../reuseComponent/Table/Pagination'
import moment from 'moment'
import { Route } from 'react-router-dom'
import TableHeader from '../../../reuseComponent/Table/table-head'
import adminInstance from '../../../instances/admininstance'
import { url } from '../../../../constants'
import { changeFiltersAction, setData } from '../../../../actions/admin-header'
import Modal from '../../../reuseComponent/modal'
import { createTask, editTask, deleteTask, changeCompleteStatus } from '../../../../services/tasks'
import DatePickerFilter from './datePickerFilter'
import EditTask from '../form/edit'
import { notify } from '../../../reuseComponent/toast'
import Tooltip from '../../../reuseComponent/Tooltips'
import StandartLeftPanel from '../../../reuseComponent/Header/standartLeftPannel'
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'
import { permEditTasks } from '../../../../permissions/tasks'
import { getUsers } from '../../../../actions/users'
import { getDepartments } from '../../../../services/dictionaries'
import TaskResponsibleComponent from './taskResponsibleComponent/taskResponsibleComponent'
import { changeRoutePush } from '../../../../reuseFunctions/changeRoute'
import { history } from '../../../../history'
import DetailsTasks from '../details'
import Toolbar from './Toolbar'
import { ConfirmModalContent } from '../../../reuseComponent/confirmModalComponent';
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import withTracker from "../../../reuseComponent/GoogleAnalytics";
import UndoList from '../undo-component';
import { closeOpenInbound, closeOpenSection, editTaskAction } from '../../../../actions/purchase-orders'
import TabsTickets from '../ticketsTabs';
import StatusChange from './statusChange';
import { getActiveInboxAction } from '../../../../actions/tasks'

export const defaultTaskOption = {
    value: 'custom',
    label: 'Custom ticket'
}

class InboxList extends PureComponent {

    state = {
        createModalOpen: false,
        editModalOpen: false,
        deleteTaskModal: false,
        currTask: null,
        departments: [],
        isCompleteTask: false,
        deletedTask: [],
        currentTask: {},
        isRead: null,
    }

    tableRef = React.createRef();

    toggleStatus = (isRead, id) => {
        adminInstance.put(`${url}/inbox/change_read_status/${id}`)
            .then(res => {
                this.props.updateActiveInbo();
                this.tableRef.current && this.tableRef.current.onQueryChange();
            }).catch(err => console.log('err: ', err));
    };

    onReadAll = () => {
        adminInstance.put(`${url}/inbox/read_all`)
            .then(res => {
                this.props.updateActiveInbo();
                this.tableRef.current && this.tableRef.current.onQueryChange();
            }).catch(err => console.log('err: ', err));
    };

    onUnreadAll = () => {
        adminInstance.put(`${url}/inbox/unread_all`)
            .then(res => {
                this.props.updateActiveInbo();
                this.tableRef.current && this.tableRef.current.onQueryChange();
            }).catch(err => console.log('err: ', err));
    };

    createLink = (data) => {
        if (data && data.link && data.referenceType !== 'Purchase Order') {
            return `${window.location.origin}/atw${data.link}`;
        } else if (data && data.referenceType === 'Purchase Order' && data.referenceId) {
            return `${window.location.origin}/atw/purchase-orders/${data.referenceId}`;
        }
    }

    columns = [
        {
            title: 'Status',
            field: 'action',
            sorting: false,
            headerStyle: {
                textAlign: 'right',
                width: '45px',
            },
            cellStyle: {
                textAlign: 'right'
            },
            render: rowData => <StatusChange {...rowData} toggleStatus={this.toggleStatus} />
        },
        // {
        //     title: 'Status',
        //     field: 'read',
        //     sorting: false,
        //     headerStyle: {
        //         textAlign: 'right'
        //     },
        //     cellStyle: {
        //         textAlign: 'right'
        //     },
        //     render: rowData => rowData.read ? 'Read' : 'Unread',
        // },
        {
            title: 'Date',
            field: 'createdAt',
            sorting: false,
            headerStyle: {
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            // filterComponent: props => {
            //     return <DatePickerFilter
            //         {...props}
            //         maxDate={moment()}
            //         filters={this.props.filters}
            //         filterChange={(column, value) => {
            //             this.tableRef.current.onFilterChange(column, value)
            //         }}
            //     />
            // },
            render: rowData => {
                return rowData.createdAt ?
                    <span>{moment(rowData.createdAt, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm')}</span> : null
            }
        },
        {
            title: 'Activity',
            field: 'activity',
            sorting: false,
            // headerStyle: {
            //     textAlign: 'right'
            // },
            // cellStyle: {
            //     textAlign: 'right'
            // },
            render: rowData => rowData.description ? rowData.description : '',
        },
        {
            title: 'Reference',
            field: 'reference',
            sorting: false,
            render: rowData => {
                return rowData.actionType && rowData.actionType.alias
                    ? <a onClick={e => e.stopPropagation()} rel="noopener noreferrer"
                        href={this.createLink(rowData)}>{rowData.actionText}</a>
                    : null
            }
        },
    ]

    showHideModal = (state, callback) => {
        this.setState({
            ...state
        }, () => callback && callback())
    }

    componentDidUpdate(prevProps, nextProps) {
        // const aliasPrev = prevProps.match.params.alias
        // const alias = this.props.match.params.alias
        // const {changeFilters} = this.props
        // if (aliasPrev !== alias) {
        //     this.tableRef.current && this.tableRef.current.setState(state => ({
        //         query: {
        //             ...state.query,
        //             filters: [],
        //             search: ''
        //         }
        //     }), () => {
        //         changeFilters([])
        //         this.tableRef.current && this.tableRef.current.onQueryChange()
        //     })
        //
        // }

        const { setHeaderData, roles } = this.props
        if (JSON.stringify(roles) !== JSON.stringify(prevProps.roles))
            setHeaderData && setHeaderData({
                leftPannelData: {
                    title: 'Tickets',
                    logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                        <path fill="#FF9800"
                            d="M19,4H18V2H16V4H8V2H6V4H5A2,2 0 0,0 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M5,8V6H19V8H5M10.56,18.46L16.5,12.53L15.43,11.47L10.56,16.34L8.45,14.23L7.39,15.29L10.56,18.46Z" />
                    </svg>)
                    )
                },
                textAddButton: 'Add',
                LeftPannel: StandartLeftPanel,
                handleAdd: findPermInArray(roles, permEditTasks) ? () => {
                    changeRoutePush(`/atw/tasks/tickets/new`, history)
                    this.addEmptyRowToTable()
                } : null,
                handleSearch: (value) => this.tableRef.current.onSearchChange(value),
                tableRef: this.tableRef.current
            })

    }

    addEmptyRowToTable = () => {
        const data = this.tableRef.current ? this.tableRef.current.state.data : []
        if (data.find(task => task.id === 'new') === undefined) {
            const newTask = {
                id: 'new',
            }
            this.tableRef.current && this.tableRef.current.dataManager.setData([newTask, ...data])
            this.tableRef.current.setState({ ...this.tableRef.current.dataManager.getRenderState() })
        }
    }

    removeEmptyRow = () => {
        let data = this.tableRef.current ? this.tableRef.current.state.data : []
        data = data.filter(supplier => supplier.id !== 'new')
        this.tableRef.current && this.tableRef.current.dataManager.setData(data)
        this.tableRef.current.setState({ ...this.tableRef.current.dataManager.getRenderState() })
    }

    componentDidMount() {
        const { setHeaderData, getUsers } = this.props
        setHeaderData && setHeaderData({
            LeftPannel: TabsTickets,
            leftPannelData: {
                alias: 'inbox'
            },
            handleAdd: null,
            handleSearch: (value) => this.tableRef.current.onSearchChange(value),
            tableRef: this.tableRef.current
        });

        getUsers && getUsers();
        getDepartments()
            .then(res => {
                this.setState({
                    departments: res.data.departments
                })
            })
    }

    generateRows = data => {
        return data
    }

    getTasksOfCurrentUser = (query) => {
        const { changeFilters } = this.props
        changeFilters && changeFilters(query.filters)
        return new Promise((resolve, reject) => {
            adminInstance.get(`${url}/tasks`, {
                params: {
                    page: query.page ? query.page : 0,
                    limit: query.pageSize,
                    search: query.search,
                    ...this.convertFiltersToParamsObject(query.filters)
                }
            })
                .then(res => {
                    resolve({
                        data: this.generateRows(res.data.tasks),
                        page: query.page,
                        totalCount: res.data.count
                    })
                })
        })
    }

    convertFiltersToParamsObject = filters => {
        let res = {}
        filters.forEach(filter => {
            res[filter.column.field] = filter.value
        })
        return res
    }

    getInbox = (query) => {
        const { changeFilters } = this.props
        changeFilters && changeFilters(query.filters)
        console.log('query: ', query);
        return new Promise((resolve, reject) => {
            adminInstance.get(`${url}/inbox`, {
                params: {
                    page: query.page ? query.page : 0,
                    limit: query.pageSize,
                    search: query.search,
                    read: this.state.isRead,
                    ...this.convertFiltersToParamsObject(query.filters)
                }
            })
                .then(res => {
                    resolve({
                        data: this.generateRows(res.data.inboxes),
                        page: query.page,
                        totalCount: res.data.count
                    })
                })
        })
    }

    handleCreateTask = (values) => {
        values.poUrl = window.location.origin + '/atw/purchase-orders'
        values.soUrl = window.location.origin + '/atw/sales-orders'
        values.taskUrl = window.location.origin + '/atw/tasks/tickets'
        delete values.responsible
        delete values.order
        this.showHideModal({
            createModalOpen: false
        }, () => {
            createTask(values)
                .then(res => {
                    notify('success', 'Ticket successfully created')
                    this.tableRef.current && this.tableRef.current.onQueryChange()
                    changeRoutePush(`/atw/tasks/tickets/${res.data.task.id}`, history)
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })

    }

    handleEditTask = (values, callback) => {
        const { currTask } = this.state
        values.poUrl = window.location.origin + '/atw/purchase-orders'
        values.soUrl = window.location.origin + '/atw/sales-orders'
        delete values.responsible
        delete values.order
        this.showHideModal({
            editModalOpen: false,
            currTask: null
        }, () => {
            editTask(values, currTask)
                .then(res => {
                    callback && callback()
                    notify('success', 'Ticket successfully edited')
                    this.changeTaskInTable(res.data.task ? res.data.task : {})
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })
    }

    handleDeleteTask = () => {
        const { currTask } = this.state
        const { id } = this.props.match.params
        this.showHideModal({
            deleteTaskModal: false,
            currTask: null
        }, () => {
            deleteTask(currTask)
                .then(res => {
                    if (id && parseInt(id) === parseInt(currTask)) {
                        changeRoutePush(`/atw/tasks/tickets`, history)
                    }
                    let query = {
                        ...this.tableRef.current.state.query,
                        page: 0
                    }
                    this.tableRef.current && this.tableRef.current.onQueryChange(query)
                    notify('success', 'Ticket successfully deleted')
                    // this.tableRef.current && this.tableRef.current.onQueryChange()
                })
        })
    }

    handleRemoveUndoItem = (idTask, callback) => {
        clearTimeout(this.state.deletedTask.find(ticket => ticket.id === idTask).timeout)
        this.setState(state => ({
            deletedTask: state.deletedTask.filter(ticket => ticket.id !== idTask)
        }), () => {
            callback && callback()
        })
    }

    // Accounting
    markCompleteAccountingTask = (poId) => {
        const { closeOpenSection } = this.props;
        closeOpenSection("accounting", poId);
    }

    // Transport
    markCompleteTransportTask = (poId) => {
        const { closeOpenSection } = this.props;
        closeOpenSection("transport", poId);
    }

    // Inbound
    markCompleteInboundTask = (poId) => {
        const { closeOpenInbound } = this.props;
        closeOpenInbound(window.location.origin + '/atw/purchase-orders', poId)
    }

    handleMarkCompleteTask = task => {
        const { editTaskAction } = this.props;
        this.setState({
            currentTask: task,
        });
        changeCompleteStatus(task.id)
            .then(res => {
                if (task.complete) {
                    notify('success', 'Ticket incompleted')
                }
                else {
                    notify('success', 'Ticket completed')
                }
                const { id } = this.props.match.params
                let query = {
                    ...this.tableRef.current.state.query,
                    page: 0
                }
                if (id && parseInt(id) === task.id) {
                    this.setState({
                        isCompleteTask: true,
                    }, () => {
                        this.setState({
                            isCompleteTask: false
                        })
                    })
                }
                if (task.inbound) {
                    this.markCompleteInboundTask(task.order.id);
                } else if (task.transport) {
                    this.markCompleteTransportTask(task.order.id);
                } else if (task.accounting) {
                    this.markCompleteAccountingTask(task.order.id);
                }

                editTaskAction(res.data[1] ? res.data[1] : {});

                if (!task.complete) {
                    this.tableRef.current.onQueryChange(query, () => {
                        this.setState({
                            deletedTask: this.state.deletedTask.concat({
                                ...task,
                                timeout: setTimeout(() => {
                                    this.handleRemoveUndoItem(task.id)
                                }, 10000),
                                actionId: res.data.id,
                            })
                        })
                    })
                }
                this.changeTaskInTable(task)
            })
    }

    handleUndoTask = (idTask, actionId) => {
        this.handleRemoveUndoItem(idTask, () => {
            adminInstance.post(`${url}/tasks/undo_complete/${idTask}`)
                .then(res => {
                    if (this.state.currentTask && this.state.currentTask.inbound) {
                        this.markCompleteInboundTask(this.state.currentTask.order.id);
                    } else if (this.state.currentTask && this.state.currentTask.transport) {
                        this.markCompleteTransportTask(this.state.currentTask.order.id);
                    } else if (this.state.currentTask && this.state.currentTask.accounting) {
                        this.markCompleteAccountingTask(this.state.currentTask.order.id);
                    }
                    this.tableRef.current && this.tableRef.current.onQueryChange()
                })
        })
    }

    changeTaskInTable = task => {
        const columnsData = this.tableRef.current.dataManager.data.map(row => {
            if (row.id === task.id) {
                row = {
                    ...task,
                    complete: !task.complete
                }
            }
            return row
        })

        this.tableRef.current.dataManager.setData(columnsData)
        this.tableRef.current.setState(this.tableRef.current.dataManager.getRenderState())
    }

    removeTaskFromTable = id => {
        const columnsData = this.tableRef.current.dataManager.data.find(row => row.id === id)
        this.tableRef.current.dataManager.setData(columnsData)
        this.tableRef.current.setState({
            ...this.tableRef.current.dataManager.getRenderState(),
            query: {
                ...this.tableRef.current.state.query,
                totalCount: this.tableRef.current.state.query.totalCount - 1
            }
        })

    }

    setStatus = (status) => {
        console.log('status: ', status);
        if (status === 'read') {
            this.setState({
                isRead: true
            })
        } else if (status === 'unread') {
            this.setState({
                isRead: false
            })
        } else {
            this.setState({
                isRead: null
            })
        }
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    render() {
        const { id } = this.props.match.params
        const { editModalOpen, currTask, deleteTaskModal, isCompleteTask, deletedTask } = this.state
        const { roles } = this.props;

        return (
            <div className={`wrap-table-with-details-panel ${!id ? '' : 'with-details'}`}>
                <div className="table-block wrapper-table tasks-table">
                    <Table
                        components={{
                            Toolbar: props => <Toolbar
                                {...props}
                                setStatus={this.setStatus}
                                handleReadAll={this.onReadAll}
                                handleUnreadAll={this.onUnreadAll}
                                handleAdd={findPermInArray(roles, permEditTasks) ? () => {
                                    changeRoutePush(`/atw/tasks/tickets/new`, history)
                                    this.addEmptyRowToTable()
                                } : null}
                            />,
                            Header: TableHeader,
                            Pagination: Pagination
                        }}
                        paginationPosition="toolbar"
                        columns={this.columns}
                        data={this.getInbox}
                        tableRef={this.tableRef}
                        // onRowClick={(event, rowData) => {
                        //     if (rowData.id !== 'new') {
                        //         changeRoutePush(`/atw/tasks/tickets/${rowData.id}`, history)
                        //         this.removeEmptyRow()
                        //     }
                        // }}
                        options={{
                            maxBodyHeight: 'calc(100vh - 130px)',
                            showTitle: false,
                            search: true,
                            emptyRowsWhenPaging: false,
                            pageSize: 50,
                            pageSizeOptions: [10, 25, 50],
                            searchFieldStyle: { display: 'none' },
                            rowStyle: props => {
                                if (parseInt(id) === props.id || id === props.id)
                                    return {
                                        backgroundColor: '#f2f2f2'
                                    }
                                else {
                                    return {}
                                }
                            }
                        }}
                    />
                </div>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {
        filters: state.adminHeaderReducer.filters,
        roles: state.rolesReducer.roles,
        currUser: state.usersReducer.currUser,
        // users: state.usersReducer.users,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(getUsers()),
        setHeaderData: data => dispatch(setData(data)),
        changeFilters: filters => dispatch(changeFiltersAction(filters)),
        closeOpenInbound: (url, poId) => dispatch(closeOpenInbound(url, poId)),
        closeOpenSection: (section, poId) => dispatch(closeOpenSection(section, poId)),
        editTaskAction: data => dispatch(editTaskAction(data)),
        updateActiveInbo: () => getActiveInboxAction(dispatch),
    }
}

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(InboxList))
