import React from 'react'

const InboundCellValue = ({value}) => {
    return (
        <div className="inbound-cell-value">
            {value !== undefined && value !== null
                ? value : <span>No data to display</span>}
        </div>
    )
}



export default InboundCellValue