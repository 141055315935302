import React, {PureComponent} from 'react'

class ToolbarDictionary extends PureComponent {

    render() {
        const {
            pagination,
            paginationPosition
        } = this.props

        return (
            <div className="toolbar-activity">
                {paginationPosition === 'toolbar'
                &&
                <div className="pagination-toolbar">
                    {pagination}
                </div>
                }
            </div>
        )
    }
}


export default ToolbarDictionary