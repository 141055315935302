import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { InstrumentsTable } from './Instruments/InstrumentsTable'
import { CreateInstrumentForm } from './Instruments/CreateInstrumentForm'
import { Button } from 'react-bootstrap/index'
import { mapDictionaryInitialValues } from '../constants'
import { ConfirmModalContent } from '../../../reuseComponent/confirmModalComponent'
import CustomModalWithChildren from '../../../reuseComponent/modal/modalWithChildren'
import { LocationTypeAPI } from '../../../../services/location-type'
import { notify } from '../../../reuseComponent/toast'
import { defaultServerError } from '../../../../reuseFunctions/toasts'
import { RemoveInstrumentWithSelect } from './Instruments/RemoveInstrumentWithSelect'

import { SelectedInstrumentCard } from './Instruments/SelectedInstrumentCard'
import { useSelector } from 'react-redux'
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'
import { permEditMapEditor } from '../../../../permissions/inventory'

const InventoryMapEditorInstruments = ({
                                         plan,
                                         setSelectedInstrument,
                                         selectedInstrument,
                                         setLoading,
                                         updateSlotsLocation,
                                         selectedCoordinates,
                                         maxX,
                                         maxY,
                                         handleChangeCoordinates,
                                         removeSlotCallback,
                                       }) => {

  const [locationTypes, setLocationTypes] = useState([])
  const [createEnabled, setCreateEnabled] = useState(false)
  const [selectedToDelete, setSelectedToDelete] = useState(null)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [openRemoveWithSelect, setOpenRemoveWithSelectModal] = useState(false)
  const [selectedToEdit, setSelectedToEdit] = useState(false)

  const roles = useSelector(state => state.rolesReducer.roles)

  const hasPermToEdit = useMemo(() => {
    return findPermInArray(roles, permEditMapEditor)
  }, [roles])

  const createSuccessCallback = useCallback((locationType) => {
    setLocationTypes(prevState => [...prevState, locationType])
  }, [setLocationTypes])

  const editSuccessCallback = useCallback((locationType) => {
    setLocationTypes(prevState => prevState.map(item => item.id === locationType.id
      ? ({...item, ...locationType}) : item))
    updateSlotsLocation(locationType)
  }, [updateSlotsLocation])

  const handleOpenRemoveModal = useCallback((item) => {
    setSelectedToDelete(item)
    setOpenRemoveModal(true)
  }, [])

  const handleCloseRemoveModal = useCallback(() => {
    setOpenRemoveModal(false)
    setOpenRemoveWithSelectModal(false)
    setSelectedToDelete(mapDictionaryInitialValues)
  }, [])

  const handleRemoveMapDictionary = useCallback((itemToDelete, newItemId) => {
    setLoading(true)
    LocationTypeAPI.removeLocationType(itemToDelete.id, newItemId)
      .then(res => {
        if(res.data.data){
          setLocationTypes(prevState => prevState.filter(item => item.id !== itemToDelete.id))
          handleCloseRemoveModal()
          removeSlotCallback(itemToDelete.id, newItemId)
          notify('success', 'Space successfully deleted')
        }else{
          console.log(res)
          if(res.data.status === 400){
            if(res.data.message === 'Need new location'){
              setOpenRemoveModal(false)
              setOpenRemoveWithSelectModal(true)
            }
          }else{

          }

          defaultServerError()
        }
      })
      .catch(err => {
        defaultServerError()
      })
      .finally(() => setLoading(false))
  }, [handleCloseRemoveModal, setLoading, setLocationTypes, removeSlotCallback])

  useEffect(() => {
    if(plan && plan.locationTypes){
      setLocationTypes(plan.locationTypes)
    }
  }, [plan])

  useEffect(() => {
    if(!hasPermToEdit){
      setOpenRemoveModal(false)
      setOpenRemoveWithSelectModal(false)
    }
  }, [hasPermToEdit])



  return (
    <>
    <div className="inventory-instruments-wrapper">
      <div>
        <div className="title-row">
          <span>Space management</span>
          {/*<CustomTooltip text="Close">*/}
          {/*  <ClearIcon className="standard-svg" onClick={() => changeRoute('/atw/inventory/map', history)}/>*/}
          {/*</CustomTooltip>*/}
        </div>
        {hasPermToEdit && <div className="action-row">
          {createEnabled ? <CreateInstrumentForm
            handleCancel={() => setCreateEnabled(false)}
            setLoading={setLoading}
            plan={plan}
            successCallback={createSuccessCallback}
          /> : <Button onClick={() => setCreateEnabled(true)} className="add-button-customer add-button">
            <svg className="button-svg" viewBox="0 0 24 24">
              <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
            </svg>
            Add space
          </Button>}
        </div>}
        <div className="inventory-instruments-table">
          <InstrumentsTable
            locationTypes={locationTypes}
            handleOpenRemoveModal={handleOpenRemoveModal}
            selectedToEdit={selectedToEdit}
            setSelectedToEdit={setSelectedToEdit}
            editCallback={editSuccessCallback}
            setLoading={setLoading}
            selectedInstrument={selectedInstrument}
            setSelectedInstrument={setSelectedInstrument}
          />
        </div>
      </div>
        {selectedInstrument
          && <SelectedInstrumentCard
          selectedInstrument={selectedInstrument}
          selectedCoordinates={selectedCoordinates}
          maxX={maxX}
          maxY={maxY}
          setSelectedInstrument={setSelectedInstrument}
          handleChangeCoordinates={handleChangeCoordinates}
          />}

      </div>
      <CustomModalWithChildren
        open={openRemoveModal}
        handleClose={handleCloseRemoveModal}
        handleCancel={handleCloseRemoveModal}
        handleConfirm={() => handleRemoveMapDictionary(selectedToDelete)}
      >
        <ConfirmModalContent text={`delete "${selectedToDelete ? selectedToDelete.name : ''}" space`} />
      </CustomModalWithChildren>
      <CustomModalWithChildren
        open={openRemoveModal}
        handleClose={handleCloseRemoveModal}
        handleCancel={handleCloseRemoveModal}
        handleConfirm={() => handleRemoveMapDictionary(selectedToDelete)}
      >
        <ConfirmModalContent text={`delete "${selectedToDelete ? selectedToDelete.name : ''}" space`} />
      </CustomModalWithChildren>
      <CustomModalWithChildren
        open={openRemoveWithSelect}
        isFooter={false}
        handleClose={handleCloseRemoveModal}
      >
        <RemoveInstrumentWithSelect
          handleCancel={handleCloseRemoveModal}
          handleConfirm={(newItemId) => handleRemoveMapDictionary(selectedToDelete, newItemId)}
          locationTypes={locationTypes}
          selectedToDelete={selectedToDelete}
        />
      </CustomModalWithChildren>
    </>
  )
}

export { InventoryMapEditorInstruments }