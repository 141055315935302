import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import Form from '../form'
import { setData } from '../../../../../actions/admin-header'
import { editCategoryInDictionary,getCategoryById } from '../../../../../actions/commodity-groups'
import { notify } from '../../../../reuseComponent/toast'
import { changeRoute } from '../../../../../reuseFunctions/changeRoute'
import { history } from '../../../../../history'
import { getRelatedCategoriesValues } from '../../../../../actions/commodity-groups'
import Preloader from '../../../../reuseComponent/preloader/preloader'
import StandartLeftPanner from "../../../../reuseComponent/Header/standartLeftPannel";


class CreateCategory extends PureComponent{

    state = {
        loading: true,
        values: null
    }

    componentDidMount(){
        const { setHeaderData,getValues } = this.props
        const { id } = this.props.match.params

        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'EDIT CG3',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#000000" d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z" />
                </svg>)),
            },
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: null,
        })
        this.setState({
            loading: true
        },() => {
            getValues && getValues()
            getCategoryById(id)
                .then(res => {
                    console.log(res)
                    this.setState({
                        loading: false,
                        values: res.data
                    })
                })
                .catch(err => {
                    notify('error','Oops something went wrong')
                    this.setState({
                        loading: false
                    })
                })
        })


    }

    handleSubmit = values => {
        const { id } = this.props.match.params
        this.setState({
            loading: true,
            values: values
        },() => {
            editCategoryInDictionary(id,values)
                .then(res => {
                    if(typeof res.data === 'string')
                        notify('errors','Value already exist')
                    else
                        changeRoute('/atw/dictionaries/cg3',history)
                        notify('success','Category successfully edited')
                })
                .catch(err => {
                    notify('error','Oops something went wrong')
                    this.setState({loading: false})
                })

        })

    }

    render(){
        const { loading,values } = this.state
        const { isLoadingValues } = this.props
        return (
            loading || isLoadingValues ? <Preloader /> : <Form
                handleSubmit={this.handleSubmit}
                initialValues={values ? values :{
                    value: '',
                    related: [],
                    inputs: []
                }}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoadingValues: state.commodityGroupsReducer.isLoadingValues
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setHeaderData: (data) => dispatch(setData(data)),
        getValues: () => dispatch(getRelatedCategoriesValues())
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(CreateCategory)