import { url } from '../constants';
import adminInstance from '../components/instances/admininstance'
import { convertToFormData } from '../reuseFunctions/objectToFormData'

export const getArticlesRequest = ({page,limit,search}) => {
    return adminInstance.get(`${url}/articles`,{
        params: {
            page,
            limit,
            search
        }
    })
}

export const createArticleRequest = (data) => {
    const formData = convertToFormData(data)
    return adminInstance.post(`${url}/articles`,formData)
}

export const deleteArticleRequest = (id) => {
    return adminInstance.delete(`${url}/articles/${id}`)
}

export const editArticleRequest = (id,values) => {
    const formData = convertToFormData(values)
    return adminInstance.put(`${url}/articles/${id}`,formData)
}

