import React , { PureComponent } from 'react'
import { Form,Button } from 'react-bootstrap'
import {FieldArray} from 'formik';
import Tooltip from '../../../../reuseComponent/Tooltips';
import {ReactComponent as DeleteIcon} from '../../../../../assets/images/delete.svg';
import {ReactComponent as AddIcon} from '../../../../../assets/images/plus.svg';
import AutosizeInput from "react-input-autosize";


class BodyForm extends PureComponent{
    render(){
        const {
            handleSubmit,
            handleCancel,
            values,
            errors,
            touched,
            handleChange,
            setFieldValue
        } = this.props
        return (
            <Form noValidate onSubmit={handleSubmit} className="cg1-form">
                <Form.Group className="horizontal">
                    <Form.Label>Output</Form.Label>
                    <div className="input-item">
                        <AutosizeInput
                            type="text"
                            placeholder="Enter output"
                            className="auto-resize-wrapper special"
                            name="name"
                            value={values.name}
                            onChange={event => {
                                setFieldValue("name", event.target.value);
                            }}
                            onBlur={e => setFieldValue('name', e.target.value.trim())}
                        />
                        <span className="error-label">{touched.name && errors.name}</span>
                    </div>
                </Form.Group>
                <Form.Group className="horizontal">
                    <Form.Label>Inputs</Form.Label>
                    <div className="input-item">
                    <FieldArray
                        name="inputs"
                        render={arrayHelpers => (
                            <div className="inputs-wrapper">
                                {values.inputs
                                && values.inputs.length > 0
                                && values.inputs.map((input, index) => <div className="input-field-wrapper" key={index}>
                                    <div className="field">
                                        <AutosizeInput
                                        type="text"
                                        placeholder="Enter input"
                                        className="auto-resize-wrapper"
                                        name={`inputs.${index}`}
                                        value={values.inputs[index]}
                                        onChange={handleChange}
                                        />
                                        <span className="error-label">{touched.inputs && touched.inputs[index] &&
                                        errors.inputs && errors.inputs[index]}</span>
                                    </div>
                                    <Tooltip text="Delete">
                                        <DeleteIcon onClick={() => arrayHelpers.remove(index)} className="standart-svg"/>
                                    </Tooltip>
                                </div>)}
                                <Button onClick={() => arrayHelpers.push('')}>
                                    <AddIcon className="standart-svg"/>
                                    Add
                                </Button>

                            </div>
                        )}
                    />
                    </div>
                </Form.Group>
                <Form.Group className="footer-form footer-form-custom">
                    <Button type="button" onClick={handleCancel}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </Form.Group>
            </Form>
        )
    }
}

export default BodyForm