import React from 'react'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

const ReactSummernote = React.forwardRef((props, ref) => {

    const {
        value,
        onChange
    } = props
    return <SunEditor
        ref={ref}
        config={{
            readonly: false
        }}
        setOptions={{
            font : [
                'Arial',
                'Helvetica',
                'Times New Roman',
                'Verdana',
                'Courier New',
                // 'Arial',
                // 'tohoma',
                // 'Courier New,Courier',
                // 'Roboto-light',
                // 'Roboto-medium'
            ],
            buttonList: [
                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
            ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
            ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print']
            ]
        }}
        theme="snow"
        setContents={value}
        onChange={onChange}
        fullPage={true}
    />
})

export default ReactSummernote