import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Formik} from 'formik'
import {getUsers} from '../../../../actions/users'
import {schema} from './schema'
import BodyForm from './bodyForm'
import {getSupllier, createSupplier,clearSupplierAction} from '../../../../actions/suppliers'
import {withRouter} from 'react-router-dom'

class FormSupplier extends PureComponent {


    handleSubmit = values => {
        const {createSupplier,callbackAfterCreation} = this.props
        createSupplier && createSupplier(values,callbackAfterCreation)
    }

    componentDidMount() {
        const {getUsers, users,clearSupplier} = this.props
        getUsers && users.length === 0 && getUsers()
        clearSupplier()
    }

    render() {
        const {loadingUser, users, supplier,cancelCreate} = this.props
        return (
            !loadingUser &&
                <Formik
                    onSubmit={this.handleSubmit}
                    enableReinitialize={true}
                    initialValues={supplier}
                    validationSchema={schema}
                    render={props => <BodyForm
                        {...props}
                        users={users}
                        cancelHandler={cancelCreate}
                    />}
                />
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.usersReducer.users,
        loadingUser: state.usersReducer.loading,
        supplier: state.supplierReducer.currentSupplier
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(getUsers()),
        getSupplier: (id) => dispatch(getSupllier(id)),
        createSupplier: (values,callback) => dispatch(createSupplier(values,callback)),
        clearSupplier: () => dispatch(clearSupplierAction())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormSupplier))