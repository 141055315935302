import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
// import Tooltip from '../../../../../../reuseComponent/Tooltips'
import {findPermInArray} from '../../../../../../../../reuseFunctions/checkPermission'
import {permFinanceInfo} from "../../../../../../../../permissions/productOverview";
import ResponsibleList from '../../../../../list/responsibleComponent'
import {getDetail} from "../../../../../../../../actions/purchase-orders";
import CentralPartBidsHeader from "../central-part";


class LeftPartBidsHeader extends PureComponent {
    renderResponsible = (responsiblePerson) => {
        const { details: {department, orderNumber}, departments, match: {params}, getDetails } = this.props
        if (responsiblePerson) {
            return <div className="resp__wr">
                <ResponsibleList
                departments={departments ? departments : []}
                idPurchase={params.id}
                purchaseOrderNumber={orderNumber}
                department={department}
                responsibleUser={responsiblePerson}
                changeDepartInRow={() => getDetails(params.id)}
                changeRow={() => getDetails(params.id)}
                Spec={() => <><span className="initials styled_span" style={{
                    color: responsiblePerson.initialsColor,
                }}>{`${responsiblePerson.name} ${responsiblePerson.secondName}`}</span> <span
                    className="departament styled_span">{responsiblePerson.department ? responsiblePerson.department.name : null}
                    </span></>}
            />
            </div>
        }
        else if(department){
           return <div className="resp__wr">
               <ResponsibleList
               departments={departments ? departments : []}
               idPurchase={params.id}
               purchaseOrderNumber={orderNumber}
               department={department}
               responsibleUser={responsiblePerson}
               changeDepartInRow={() => getDetails(params.id)}
               changeRow={() => getDetails(params.id)}
               Spec={() => <span className="departament styled_span">{department.name}</span>}
           />
           </div>
        }
        else {
            return null
        }
    }

    render() {
        const {details: {placementDate, responsiblePerson, buyer, offerNumber, department}, roles} = this.props
        return (
            <div className="left-part">
                {/*<div className="order-number placement-date finance-status">*/}
                {/*    */}
                {/*</div>*/}
                <div className="offer-wrapper">
                    <p className="title">
                        Main info
                    </p>
                    <div className="offers-left-center">
                        <div className="offers-statuses">
                            <div className="offers-titles">
                                {placementDate && <p>Placement Date: </p>}
                                <p>PO Status: </p>
                                {buyer && findPermInArray(roles, permFinanceInfo) &&
                                <p>Buyer: </p>
                                }
                                {offerNumber && findPermInArray(roles, permFinanceInfo) && <p>Offer: </p>}
                                {(responsiblePerson || department) &&<p className="resp">Responsible: </p>}
                            </div>
                            <div className="offers-values">
                                {placementDate && <p><strong>{moment(placementDate, 'YYYY.MM.DD').format('MMM DD, YYYY')}</strong></p>}
                                <p>{(this.props.details != null && this.props.details.status && this.props.details.status != undefined && this.props.details.status.name != null) ? this.props.details.status.name : ''}</p>
                                {buyer && findPermInArray(roles, permFinanceInfo) &&
                                <p><span className="responsible-buyer-label">{buyer.name} {buyer.secondName}</span></p>
                                }
                                {offerNumber && findPermInArray(roles, permFinanceInfo) && <p>#{offerNumber}</p>}
                                {this.renderResponsible(responsiblePerson)}
                            </div>
                        </div>
                        <CentralPartBidsHeader/>
                    </div>

                    {/*<p>*/}
                    {/*    {placementDate && <>Placement Date:  <strong>{moment(placementDate, 'YYYY.MM.DD').format('MMM DD, YYYY')}</strong></>}*/}
                    {/*</p>*/}
                    {/*{findPermInArray(roles, permFinanceInfo) &&*/}
                    {/*<p>Payment Status: {financeStatus} {(financeStatus === 'Prepayment' || financeStatus === 'Partly paid') ? prepaidPercentage + '%' : ''}</p>}*/}
                    {/*{buyer && findPermInArray(roles, permFinanceInfo) &&*/}
                    {/*<p>Buyer: <span className="responsible-buyer-label">{buyer.name} {buyer.secondName}</span></p>*/}
                    {/*}*/}

                    {/*{offerNumber && findPermInArray(roles, permFinanceInfo) && <p>Offer: #{offerNumber}</p>}*/}
                    {/*{this.renderResponsible(responsiblePerson)}*/}
                </div>
                {/*<div className="responsible-person">*/}
                {/*    {this.renderResponsible(responsiblePerson)}*/}
                {/*</div>*/}
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        roles: state.rolesReducer.roles
    }
}

const mapDispatchToProps = dispatch => ({
    getDetails: id => dispatch(getDetail(id, false)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftPartBidsHeader))