import React, { PureComponent } from 'react'
import { Formik } from 'formik'
import Preloader from '../../../reuseComponent/preloader/preloader';
import { schema } from './schema'
import BodyForm from './formBody'
import {getRolesList, removeRoleWithNewRoleSet} from "../../../../services/roles";
import {notify} from "../../../reuseComponent/toast";

class RemoveRoleWithNewSelect extends PureComponent{
    state = {
        initialValues: {
            newRoleId: null
        },
        loading: false,
        roles: [],
    }

    componentDidMount() {
        this.setState({loading: true})
        const {roleIdRemove} = this.props;
        getRolesList()
            .then(res => {
                let rolesList = [];
                if(res.data){
                    rolesList = res.data.map(item => ({value: item.id, label: item.Name})).filter(item => item.value !== roleIdRemove);
                }
                this.setState({
                    roles: rolesList,
                    loading: false,
            });
            })
            .catch(err => {
                this.setState({loading: false})
            })
    }

    handleSubmit = (values) => {
        const {roleIdRemove, roleName, getPriviliges, handleClose} = this.props;
        removeRoleWithNewRoleSet(roleIdRemove, values.newRoleId, roleName)
            .then(res => {
                notify('success', 'Role successfully deleted');
                getPriviliges();
                handleClose();
            })
            .catch(err => notify('error', 'Oops something went wrong'))
    };

    render() {
        const {roleIdRemove, handleClose} = this.props;

        const {roles, loading} = this.state;

        return (
            loading ? <Preloader/> :
            <Formik
                initialValues={{newRoleId: ''}}
                validationSchema={schema}
                onSubmit={this.handleSubmit}
                render={props => <BodyForm {...props} roleIdRemove={roleIdRemove} roles={roles} handleClose={handleClose} />}
            />
        );
    }
}

export default RemoveRoleWithNewSelect;