import React, {PureComponent} from 'react'
import {Button} from 'react-bootstrap';
import {connect} from 'react-redux'
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute'
import {history} from '../../../../history'
import EdidForm from '../form/edit'
import CreateForm from '../form/create'
import { editTask, createInboundTask, createTransportTask, createAccountingTask } from '../../../../services/tasks'
import {notify} from '../../../reuseComponent/toast'
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'
import { permEditTasks } from '../../../../permissions/tasks'
import {ReactComponent as DeleteIcon} from '../../../../assets/images/delete.svg';
import {ReactComponent as BackIcon} from '../../../../assets/images/arrow-left.svg';
import { editTaskAction, changeDetailsAction } from '../../../../actions/purchase-orders';

class TaskDetails extends PureComponent {

    handleEditTask = (values, callback) => {
        const { changeTaskInTable, editTaskAction } = this.props
        const { id } = this.props.match.params
        values.poUrl = window.location.origin + '/atw/purchase-orders'
        if (values.inbound) {
            values.inboundDueDate = values.dueDate;
            values.inboundDescription = values.description;
        } else if (values.transport) {
            values.transportDueDate = values.dueDate;
            values.transportDescription = values.description;
        } else if (values.accounting) {
            values.accountingDueDate = values.dueDate;
            values.accountingDescription = values.description;
        }

        editTask(values, id)
            .then(res => {
                callback && callback()
                notify('success', 'Ticket successfully edited')
                changeTaskInTable(res.data.task ? res.data.task : {});
                editTaskAction(res.data.task ? res.data.task: {});
                if (values.inbound) {
                    const id = values.poId;
                    values.userId = values.executorId;
                    values.inboundDueDate = values.dueDate;
                    values.inboundDescription = values.description;
                    createInboundTask(values, id)
                        .then(res => {
                            changeDetailsAction(res.data ? res.data : {});
                        })
                        .catch(err => {
                            notify('error', 'Oops something went wrong')
                        })
                } else if (values.transport) {
                    const id = values.poId;
                    values.transportDueDate = values.dueDate;
                    values.transportDescription = values.description;
                    values.userId = values.executorId;
                    createTransportTask(values, id)
                        .then(res => {
                            changeDetailsAction(res.data ? res.data : {});
                        })
                        .catch(err => {
                            notify('error', 'Oops something went wrong')
                        })
                } else if (values.accounting) {
                    const id = values.poId;
                    values.accountingDueDate = values.dueDate;
                    values.accountingDescription = values.description;
                    values.userId = values.executorId;
                    createAccountingTask(values, id)
                        .then(res => {
                            changeDetailsAction(res.data ? res.data : {});
                        })
                        .catch(err => {

                            notify('error', 'Oops something went wrong')
                        })
                }

            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }

    render() {
        const params = this.props.match.params
        const { roles,cancelCreate,handleCreateTask,isCompleteTask, handleShowRemoveModal } = this.props

        if(!findPermInArray(roles, permEditTasks) && params.id === 'new') {
             changeRoutePush(`/atw/tasks/tickets`, history)
        }

        return (
            <div className="details tasks-details">
                <div className="content-details">
                    <div className="form-header">
                        <div className="left-part">
                            <Button
                                variant="primary"
                                className="standard"
                                onClick={() => {
                                    changeRoutePush(`/atw/tasks/tickets`, history)
                                    cancelCreate()
                                }}
                            >
                                <BackIcon/>
                                Back
                            </Button>
                        </div>
                        <div className="right-part">
                            {findPermInArray(this.props.roles, permEditTasks) && params.id !== 'new' && <Button
                                variant="primary"
                                className="standard"
                                onClick={() => handleShowRemoveModal(params.id)}
                            >
                                <DeleteIcon/>
                                Delete
                            </Button>}
                        </div>
                    </div>
                    {params.id === 'new' ? <CreateForm isDetails handleCancel={() => changeRoutePush(`/atw/tasks/tickets`, history)} handleSubmit={handleCreateTask}/> :
                        <EdidForm
                            isDetails
                            handleCancel={() => changeRoutePush(`/atw/tasks/tickets`, history)}
                            isCompleteTask={isCompleteTask}
                            isView={!findPermInArray(roles, permEditTasks)}
                            handleSubmit={this.handleEditTask}
                            idTask={params.id}
                        />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

const mapDispatchToProps = dispatch => ({
    editTaskAction: obj => dispatch(editTaskAction(obj)),
    changeDetailsAction: po => dispatch(changeDetailsAction(po)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails)