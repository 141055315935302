
import React from 'react'
import AsyncSelect from 'react-select/async'
import './style/style.scss'

const CustomSelect = props => {
    const {
        defaultOptions = [],
        name = '', // name field
        isInvalid = false, // flag to show error
        onChange = () => {}, // handler to change event
        className = '', // custom classes for select
        errorLabel = '', // text of error
        loadOptions = () => {}
    } = props
    return(
        <div className='custom-select-wrapper'>
            <AsyncSelect
                {...props}
                defaultOptions={defaultOptions}
                loadOptions={loadOptions}
                name={name}
                onChange={onChange}
                className={`${className} bootstrap-class ${isInvalid ? 'invalid-select' : null}`}
            />
            <span className="error-select-bootstrap-style">{errorLabel}</span>
        </div>
    )
}

export default CustomSelect