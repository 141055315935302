import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import LeftPartFrom from './left-part/index'
// import CentralPart from './central-part/index'
import RightPart from './right-part/index'
import {getPOStatusesList} from '../../../../../../../actions/purchase-orders'
import {Formik} from 'formik'
import moment from 'moment'
import BodyForm from './form/bodyForm'
import CustomModalWithChildren from "../../../../../../reuseComponent/modal/modalWithChildren";
import {changeEditStatusMainBlock} from '../../../../../../../actions/detailsPo'
import { findPermInArray } from '../../../../../../../reuseFunctions/checkPermission'
import { getUsers } from '../../../../../../../actions/users'
import {ConfirmModalContent} from '../../../../../../reuseComponent/confirmModalComponent';
import {permCompleteInfo, permEditInfo} from "../../../../../../../permissions/productOverview";
import {getDepartments} from "../../../../../../../services/dictionaries";

class BidsHeaderComponent extends PureComponent {

    state = {
        showModalConfirm: false,
        departments: [],
        poStatuses: []
    }

    componentDidMount() {
        let {getUsers,users, getPoStatuses, poStatuses} = this.props
        users.length === 0 && getUsers && getUsers();
        poStatuses.length === 0 && getPoStatuses && getPoStatuses()
        getDepartments()
            .then(res => {
                this.setState({
                    departments: res.data.departments
                })
            })
    }

    componentDidUpdate(prevProps){
        const { roles,editingMode } = this.props
        const prevRoles = prevProps
        if(JSON.stringify(roles) !== JSON.stringify(prevRoles)){
            if(!findPermInArray(roles,permEditInfo)){
                editingMode(false)
            }
        }
    }

    handleSubmit = values => {
        const {handleSubmit} = this.props
        handleSubmit(values)
    }

    showHideModal = (isOpen, callback) => {
        this.setState({
            showModalConfirm: isOpen
        }, () => callback && callback())
    }

    render() {
        const {isEditMain, handleChangePurchase, details, editingMode,roles, poStatuses} = this.props
        const {showModalConfirm, departments} = this.state
        return (
            <div className="header-bids">
                <Formik
                    initialValues={{
                        placementDate: details.placementDate ? moment(details.placementDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
                        comment: details.comment ? details.comment : '',
                        offerNumber: details.offerNumber,
                        buyer: details.buyer ? details.buyer.id : '',
                        poStatus: details.status || ''
                    }}
                    enableReinitialize={true}
                    onSubmit={values => handleChangePurchase(values, () => editingMode(false))}
                    render={props => {
                        return (
                            <>
                                {isEditMain
                                    ? <BodyForm
                                            {...props}
                                            poStatuses={poStatuses.map(poStatus => ({
                                                value: poStatus.id,
                                                label: poStatus.name
                                            }))}
                                        />
                                    :
                                    <>
                                        <LeftPartFrom
                                            departments={departments}
                                        />
                                    </>
                                }
                                {(findPermInArray(roles,permEditInfo) || findPermInArray(roles, permCompleteInfo) )&& <RightPart
                                    showModal={() => this.showHideModal(true)}
                                    initValues={props.initialValues}
                                    values={props.values}
                                />}
                                <CustomModalWithChildren
                                    open={showModalConfirm}
                                    handleClose={() => {
                                        this.showHideModal(false)
                                    }}
                                    handleCancel={() => {
                                        this.showHideModal(false)
                                    }}
                                    handleConfirm={() => this.showHideModal(false,props.handleSubmit)}
                                    options={{
                                        centered: true
                                    }}
                                >
                                    <ConfirmModalContent text='save changes'/>
                                </CustomModalWithChildren>
                            </>
                        )
                    }}
                />

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        isEditMain: state.poDetailsReducer.isEditMain,
        poStatuses: state.purchaseOrdersReducer.statuses,
        details: state.purchaseOrdersReducer.detailsPurchase,
        roles: state.rolesReducer.roles,
        users: state.usersReducer.users
    }
}

const mapDispathToProps = dispatch => {
    return {
        getPoStatuses: () => dispatch(getPOStatusesList()),
        editingMode: isEdit => dispatch(changeEditStatusMainBlock(isEdit)),
        getUsers: () => dispatch(getUsers())
    }
}

export default connect(mapStateToProps, mapDispathToProps)(BidsHeaderComponent)