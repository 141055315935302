import {SUPPLIERS} from '../constants'

const initState = {
    loading: false,
    qualities: [],
    currentSupplier: {
        title: '',
        email: '',
        contacts: [],
        buyersId: '',
        additionalInfo: '',
        address: '',
        warehouseAddress: [],
        camouflaging: '',
        qualities: {}
    }
}

const convertToObject = (array) => {
    let res = {}
    array.forEach(item => res[item.quality] = item.camouflaging)
    return res
}

export const supplierReducer = (state = initState, action) => {
    switch(action.type){
        case SUPPLIERS.IS_LOADING :{
            return {
                ...state,
                loading: action.payload
            }
        }
        case SUPPLIERS.SET_QUALITIES: {
            return {
                ...state,
                qualities: action.payload
            }
        }
        case SUPPLIERS.GET_SUPPLIER: {
            const { payload } = action
            return {
                ...state,
                currentSupplier: {
                    title: payload.title,
                    email: payload.email,
                    contacts: payload.contacts,
                    buyersId: payload.buyer ? payload.buyer.id : null,
                    additionalInfo: payload.additionalInfo ? payload.additionalInfo : '',
                    address: payload.address ? payload.address : '',
                    warehouseAddress: payload.warehouseAddresses,
                    author: payload.author,
                    createdAt: payload.createdAt,
                    camouflaging: payload.camouflaging ? payload.camouflaging : '',
                    qualities: payload.supplierQualities && payload.supplierQualities.length > 0 ? convertToObject(payload.supplierQualities) : {}
                }
            }
        }
        case SUPPLIERS.CLEAR_SUPPLIER: {
            return {
                ...state,
                currentSupplier: initState.currentSupplier,
                loading: initState.loading
            }
        }
        default : {
            return state
        }
    }
}