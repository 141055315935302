import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap'
import { changeTasksData,uploadFileToHistory,uploadInvoiceToHistory,addTaskAction,getHistory } from '../../../../../../../actions/purchase-orders'
import { findPermInArray } from '../../../../../../../reuseFunctions/checkPermission'
import FormikTask from '../../../../../tasks/form/formik-wrapper/index'
import Modal from '../../../../../../reuseComponent/modal/index'
import {createTask} from '../../../../../../../services/tasks';
import {notify} from '../../../../../../reuseComponent/toast/index';
import {
    permFilesChronology,
    permInvoiceChronology,
    permTaskChronology
} from "../../../../../../../permissions/productOverview";
import { withRouter } from 'react-router-dom'
import { getTasksAction } from '../../../../../../../actions/tasks'

class ComposeComponent extends PureComponent {

    state = {
        openCompose: false,
        createModalOpen: false
    }

    fileUpload = React.createRef()
    invoiceUpload = React.createRef()
    historyCompose = React.createRef()

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutSide)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutSide)
    }

    handleClickOutSide = (event) => {
        if (this.historyCompose.current && !this.historyCompose.current.parentNode.contains(event.target)) {
            this.setState({
                openCompose: false
            })
        }
    }

    handleChangeCompose = () => {
        this.setState(state => ({
            openCompose: !state.openCompose
        }))
    }


    handleChangeInput = e => {
        if(e.target.files && e.target.files.length > 0){
            const { uploadFile } = this.props
            uploadFile && uploadFile(e.target.files[0])
        }
    }

    handleUploadInvoice = e => {
        const { refetchTableData } = this.props
        if(e.target.files && e.target.files.length > 0){
            const { uploadInvoice } = this.props
            uploadInvoice && uploadInvoice(e.target.files[0])
                .then(res => {
                    refetchTableData && refetchTableData();
                })

        }
    }

    handleCreateTask = values => {
        const { addTaskAction,activeTab,getHistory} = this.props
        values.poUrl = window.location.origin + '/atw/purchase-orders'
        values.taskUrl = window.location.origin + '/atw/tasks/tickets'

        delete values.responsible
        delete values.order
        this.setState({
            createModalOpen: false
        }, () => {
            createTask(values)
                .then(res => {
                    notify('success', 'Ticket successfully created')
                    addTaskAction(res.data.task ? res.data.task : {})
                    this.props.setActiveTasks();
                    activeTab === '' && getHistory('')
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })
    }


    render() {
        const { openCompose,createModalOpen } = this.state
        const { roles,details } = this.props
        // console.log('render')
        return (
            <>
                {(findPermInArray(roles, permFilesChronology)
                    ||
                    findPermInArray(roles, permTaskChronology)
                    ||
                    findPermInArray(roles, permInvoiceChronology)
                ) && <div className={`history-compose ${openCompose ? 'open' : ''}`}>
                    <Button ref={this.historyCompose} className="button-aily-with-icon info-button"
                            onClick={this.handleChangeCompose}>
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                        </svg>
                        Compose
                    </Button>
                    <ul className="list-compose">
                        {findPermInArray(roles, permFilesChronology) && <li onClick={() => {
                            this.setState({
                                openCompose: false
                            },() => {
                                this.fileUpload.current && this.fileUpload.current.click()
                            })
                        }}>File</li>
                        }
                        {findPermInArray(roles, permInvoiceChronology) && <li onClick={() => {
                            this.setState({
                                openCompose: false
                            },() => {
                                this.invoiceUpload.current && this.invoiceUpload.current.click()
                            })

                        }}>Invoice</li>
                        }
                        {findPermInArray(roles, permTaskChronology) && <li onClick={() => this.setState({
                            openCompose: false,
                            createModalOpen: true
                        })}>
                            Ticket
                        </li>
                        }
                    </ul>

                </div>
                }
                <input type="file" style={{display: 'none'}} ref={this.fileUpload} onChange={this.handleChangeInput}/>
                <input type="file" style={{display: 'none'}} ref={this.invoiceUpload} onChange={this.handleUploadInvoice}/>
                {findPermInArray(roles, permTaskChronology) && <Modal
                    Content={() => <FormikTask
                        createFromPo={true}
                        handleSubmit={this.handleCreateTask}
                        handleCancel={() => this.setState({
                            createModalOpen: false
                        })}
                        initValues={{
                            dueDate: '',
                            poId: details.id,
                            executorId: '',
                            departmentId: '',
                            description: '',
                            title: '',
                            responsible: null,
                            order: null
                        }}
                    />}
                    open={createModalOpen}
                    isFooter={false}
                    handleClose={() => this.setState({
                        createModalOpen: false
                    })}
                    options={{
                        centered: true
                    }}
                    classNameModal="modal-800"
                />
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles,
        details: state.purchaseOrdersReducer.detailsPurchase,
        activeTab: state.purchaseOrdersReducer.activeTabHistory
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeTasksData: data => dispatch(changeTasksData(data)),
        uploadFile: (file) => dispatch(uploadFileToHistory(file)),
        uploadInvoice: file => dispatch(uploadInvoiceToHistory(file)),
        addTaskAction: task => dispatch(addTaskAction(task)),
        getHistory: typeHistory => dispatch(getHistory(typeHistory)),
        setActiveTasks: () => getTasksAction(dispatch),
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ComposeComponent))