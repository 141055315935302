import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {getPallet, sortPallet} from '../../../../services/inventory'
import { Button } from 'react-bootstrap'

import {notify} from '../../../reuseComponent/toast'
// import FirstStep from '../edit/firstStep';
// import SecondStep from '../edit/secondStep';
import ThirdStep from '../edit/thirdStep';
import axios from 'axios';
import {getClosedBoxesByPoWithPallet, closeBox, openBox} from '../../../../services/boxes'
import {moveBoxesBetweenPallet} from '../../../../services/inventory';
import Preloader from '../../../reuseComponent/preloader/preloader'
import {editPallet} from '../../../../services/inventory'
import Modal from '../../../reuseComponent/modal/modalWithChildren'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission'
import {permEditInventory} from '../../../../permissions/inventory'
import * as yup from 'yup'
import {ReactComponent as EditIcon} from '../../../../assets/images/edit.svg'
import {ReactComponent as MoveIcon} from '../../../../assets/images/package-up.svg'
import {ReactComponent as PrinterIcon} from '../../../../assets/images/printer.svg'
import {ReactComponent as ChronoIcon} from '../../../../assets/images/chronology.svg'
import {ReactComponent as InfoIcon} from '../../../../assets/images/info.svg'
import ModalMoveBoxes from '../details/moveBoxesModal'
import BoxCollapse from '../details/boxCollapse'
import {withRouter} from 'react-router-dom'
import {palletLabel, sendDataToPrinter} from '../../../../reuseFunctions/labels';
import {changeRoute} from '../../../../reuseFunctions/changeRoute';
import {history} from '../../../../history';
import {getPalletPrintInfo} from '../../../../services/pallet';
import {defaultServerError} from '../../../../reuseFunctions/toasts';
import * as uniqId from 'uniqid';
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import { removeBox } from '../../../../services/boxes'
// import HistoryList from './historyList'
import {palletChronologyFiledsConverting} from '../../../../constants';
import {getHistory} from '../../../../services/pallet';
import HistoryList from '../../../reuseComponent/history/list';
import isEqual from 'react-fast-compare'

import {ReactComponent as ArrowsSort} from "../../../../assets/images/sortArrows.svg";
import {ReactComponent as ArrowUp} from "../../../../assets/images/arrow-top.svg";
import {ReactComponent as ArrowDown} from "../../../../assets/images/arrow-bottom.svg";
import { PackItem } from '../details/PackItem'
import { formatCoordinatesToView, getLetterByNumber } from '../../../../reuseFunctions/mapUtils'
import { LocationAPI } from '../../../../services/location'
import { send } from 'react-ga'

const schema = yup.object().shape({
    netWeight: yup.number()
      .required('required field')
      .typeError('positive numbers only')
      .positive('positive numbers only'),
    weight: yup.number()
        .required('required field')
        .typeError('positive numbers only')
        .positive('positive numbers only'),
    productName: yup.string().required('required field').typeError('required field'),
    orderNumber: yup.string().required('required field').typeError('required field'),
    statusId: yup.string().required('required field').typeError('required field'),
    packTypeId: yup.string().required('required field').typeError('required field'),
    locationId: yup.string().required('required field').typeError('required field'),
    cg1: yup.array().min(1, 'required field').required('required field').typeError('required field'),
    cg2: yup.array().min(1, 'required field').required('required field').typeError('required field'),
    quality: yup.array().min(1, 'required field').required('required field').typeError('required field'),
})

class EditPallet extends PureComponent {

    indexCreatedBox = 0

    state = {
        isLoading: true,
        isLoadingBoxes: false,
        boxesList: [],
        packages: [],
        poOptions: [], // po or pallets
        isTouched: false,
        modalOpen: false,
        weightError: '',
        netWeightError: '',
        productNameError: '',
        statusIdError: '',
        packTypeIdError: '',
        locationIdError: '',
        cg1Error : '',
        cg2Error: '',
        qualityError: '',
        seasonError: '',
        noteError: '',
        orderNumberError: '',
        salesManagerError: '',
        bidError: '',
        itemsCount: 0,
        selectedBoxes: [],
        modalMove: false,
        modalConfirm: false,
        newBoxes: [],
        modalDelete: false,
        currentBox: null,
        isPalletFromPo: false,
        boxesWithoutPo: [],
        values: {
            boxesId: [],
            poIds: [],
            weight: '',
            netWeight: '',
            salesManager: '',
            season: '',
            note: '',
            orderNumber: '',
            bid: '',
            productName: '',
            statusId: '',
            packTypeId: '',
            locationId: '',
            cg1: [],
            cg2: [],
            quality: [],
        },
        prevValues: {
            boxesId: [],
            poIds: [],
            weight: '',
            netWeight: '',
            salesManager: '',
            season: '',
            note: '',
            orderNumber: '',
            bid: '',
            productName: '',
            statusId: '',
            packTypeId: '',
            locationId: '',
            cg1: [],
            cg2: [],
            quality: [],
        },
        weightPallet: '',
        seasonPallet: '',
        notePallet: '',
        orderNumberPallet: '',
        bidPallet: '',
        netWeightPallet: '',
        salesManagerPallet: '',
        productNamePallet: '',
        qualityPallet: '',
        location: null,
        selectedPallet: null,
        isConfirm: false,
        showInfo:true,
        sortType: null,
        creationType: null,
        migrated: false,
    }

    componentDidMount() {
        const {palletId, plan} = this.props
        this.handleGetPallet(palletId)
    }

    componentDidUpdate(prevProps) {
        const {palletId} = this.props
        if (palletId !== prevProps.palletId) {
            this.setState({
                sortType: null,
            }, () => {
                this.handleGetPallet(palletId)
            })
        }
    }

    handleSelectUnselectBox = boxId => {
        const {selectedBoxes} = this.state
        this.setState({
            selectedBoxes: selectedBoxes.includes(boxId)
                ? selectedBoxes.filter(id => id !== boxId)
                : selectedBoxes.concat(boxId)
        })

    }

    handleOpenDeleteBoxModal = id => this.setState({currentBox: id,modalDelete: true})

    handleHideDeleteBoxModal = () => this.setState({currentBox: null,modalDelete: false})

    handleOpenBoxesMoveModal = () => this.setState({modalMove: true})

    handleHideBoxesMoveModal = () => this.setState({modalMove: false, selectedPallet: null, isConfirm: false})
    
    handleShowInfo = () => this.setState({ showInfo: !this.state.showInfo })

    handleOpenModal = () => this.setState({modalOpen: true})

    handleCloseModal = () => {
        this.indexCreatedBox = 0
        this.setState({modalOpen: false,newBoxes: []})
        const {palletId} = this.props
        this.handleGetPallet(palletId)
    }

    handleChangePallet = selectedPallet => this.setState({selectedPallet})
    generatePoOptions = orders => {
        return orders.map(order => ({
            value: order.id,
            label: <>
                {order.orderNumber}
                <div className="bids-option__wrapper">
                    {order.bids.map(bid => <div className="bid" key={bid.id}>
                        {(bid.commodity || bid.gender) && <>{bid.number}: {bid.commodity} {bid.commodity && bid.gender && ','} {bid.gender}</>}
                    </div>)
                    }
                </div>
            </>
        }))
    }

    handleSubmit = () => {
        const {palletId, refetchData,onCancelCreate } = this.props
        var {
            values,
            prevValues,
            weightError,
            netWeightError,
            productNameError,
            newBoxes,
            locationIdError,
            isPalletFromPo,
            cg1Error,
            noteError,
            orderNumberError,
            cg2Error,
            qualityError,
            itemsCount,
            salesManagerError,
        } = this.state

        const valuesToCompare = {
            netWeight: values.netWeight,
            packTypeId: values.packTypeId,
            statusId: values.statusId,
            weight: values.weight,
            season: values.season,
            note: values.note,
            orderNumber: values.orderNumber,
            bid: values.bid,
            productName: values.productName,
            locationId: values.locationId,
            cg1: values.cg1,
            cg2: values.cg2,
            quality: values.quality,
            salesManager: values.salesManager && values.salesManager.value,
        }
        const prevValuesToCompare = {
            netWeight: prevValues.netWeight,
            packTypeId: prevValues.packTypeId,
            statusId: prevValues.statusId,
            weight: prevValues.weight,
            season: prevValues.season,
            note: prevValues.note,
            orderNumber: prevValues.orderNumber,
            bid: prevValues.bid,
            productName: prevValues.productName,
            locationId: prevValues.locationId,
            cg1: prevValues.cg1,
            cg2: prevValues.cg2,
            quality: prevValues.quality,
            salesManager: prevValues.salesManager,
        }
        let sendData = {
            ...values,
            packTypeId: values.packTypeId,
            productName: values.productName.value,
            season: values.season,
            note: values.note,
            orderNumber: values.orderNumber,
            bid: values.bid,
            countBox: newBoxes.length,
            locationId: values.locationId ? values.locationId.value : "",
            statusId: values.statusId ? values.statusId : null,
            cg1: values.cg1.map(item => item.value).join(','),
            cg2: values.cg2.map(item => item.value).join(','),
            quality: values.quality.value,
            salesManager: values.salesManager ? (values.salesManager.value ? values.salesManager.value : '' ) : ''
        }

        if(itemsCount){
            delete sendData.cg1
            delete sendData.cg2
        }

        let orderNumberValidation = true;
        let salesManagerValidation = true;

        if(sendData.statusId == 1 && !sendData.orderNumber){
            orderNumberValidation = false;
            this.setState({
                orderNumberError:  'Required field'
            })
        }

        if((sendData.statusId == 1 || sendData.statusId == 2)&& !sendData.salesManager){
            salesManagerValidation = false;
            this.setState({
                salesManagerError:  'Required field'
            })
        }

        this.setState({
            isTouched: true
        }, () => {
            if (((values.poIds.length > 0 && isPalletFromPo) || !isPalletFromPo) && values.weight && values.netWeight
              && !netWeightError && !weightError && orderNumberValidation && !productNameError && salesManagerValidation && !locationIdError && (itemsCount ? true : !cg1Error && !cg2Error))
                this.setState({
                    isLoading: true,
                    isTouched: false
                }, () => {
                     editPallet(palletId, sendData)
                        .then(res => {
                            this.setState({
                                modalOpen: false
                            }, () => {
                                this.handleGetPallet(palletId)
                                refetchData()
                                if(!isEqual(valuesToCompare, prevValuesToCompare))
                                    notify('success', 'Pallet successfully edited')
                                    onCancelCreate();
                            })
                        })
                        .catch(err => notify('error', 'Oops something went wrong'))
                        .finally(() => {
                            this.setState({
                                isLoading: false
                            })
                        })
                })
        })
    }


    handleGetPallet = (palletId) => {
        this.indexCreatedBox = 0
        this.setState({
            isLoading: true,
            isLoadingBoxes: false,
            packages: [],
            poOptions: [],
            isTouched: false,
            weightError: '',
            modalMove: false,
            newBoxes: [],
            selectedBoxes: [],
            values: {
                boxesId: [],
                poIds: [],
                weight: '',
                netWeight: '',
                salesManager: '',
                productName: '',
                statusId: '',
                packTypeId: '',
                locationId: '',
                season: '',
                note: '',
                orderNumber: '',
                bid: '',
                cg1: [],
                cg2: [],
                quality: [],
            },
            itemsCount: 0,
            migrated: false,
        }, () => {
            getPallet(palletId)
                .then(res => {
                    if(this.state.sortType){
                        sortPallet(palletId, this.state.sortType)
                            .then(result => {
                                this.setState(prevState => ({
                                    isLoading: false,
                                    isPalletFromPo: res.data.data.orders.length > 0,
                                    poOptions: this.generatePoOptions(res.data.data.orders),
                                    boxesWithoutPo: result.data.filter(box => box.pack === null),
                                    boxesWithPo: result.data.filter(box => box.pack !== null),
                                    migrated: res.data.data.migrated,
                                    values: {
                                        poIds: res.data.data.orders.map(order => order.id),
                                        boxesId: result.data.map(box => box.id),
                                        weight: res.data.data.weight,
                                        season: res.data.data.season,
                                        note: res.data.data.note,
                                        orderNumber: res.data.data.orderNumber,
                                        bid: res.data.data.bid,
                                        netWeight: res.data.data.netWeight,
                                        productName: {label: res.data.data.productName, value: res.data.data.productName},
                                        statusId: res.data.data.palletStatus ? res.data.data.palletStatus.id : '',
                                        packTypeId: res.data.data.palletPackType ? res.data.data.palletPackType.id : '',
                                        locationId: res.data.data.location ?  ({
                                            value: res.data.data.location.id,
                                            label: formatCoordinatesToView(`${res.data.data.location.coordinateX}-${res.data.data.location.coordinateY}`)
                                        }): '',
                                        cg1: res.data.data.cg1.map(item => ({
                                            value: item,
                                            label: item,
                                        })),
                                        cg2: res.data.data.cg2.map(item => ({
                                            value: item,
                                            label: item,
                                        })),
                                        salesManager: res.data.data.salesManager && (res.data.data.salesManager.name + ' ' + res.data.data.salesManager.secondName),
                                        quality: {label: res.data.data.quality, value: res.data.data.quality}
                                    },
                                    // freeLocations: res.data.data.location ? [{
                                    //     value: res.data.data.location.id,
                                    //     label: formatCoordinatesToView(`${res.data.data.location.coordinateX}-${res.data.data.location.coordinateY}`,
                                    //       this.props.plan.maxX, this.props.plan.maxY)[0]
                                    // },
                                    //     ...prevState.freeLocations.filter(item => item.value !==  res.data.data.location.id)] : prevState.freeLocations,
                                    prevValues: {
                                        poIds: res.data.data.orders.map(order => order.id),
                                        boxesId: result.data.map(box => box.id),
                                        weight: res.data.data.weight,
                                        netWeight: res.data.data.netWeight,
                                        season: res.data.data.season,
                                        note: res.data.data.note,
                                        orderNumber: res.data.data.orderNumber,
                                        bid: res.data.data.bid,
                                        productName: {label: res.data.data.productName, value: res.data.data.productName},
                                        statusId: res.data.data.palletStatus ? res.data.data.palletStatus.id : '',
                                        packTypeId: res.data.data.palletPackType ? res.data.data.palletPackType.id : '',
                                        locationId: res.data.data.location ?  ({
                                            value: res.data.data.location.id,
                                            label: formatCoordinatesToView(`${res.data.data.location.coordinateX}-${res.data.data.location.coordinateY}`)
                                        }): '',
                                        cg1: res.data.data.cg1.map(item => ({
                                            value: item,
                                            label: item,
                                        })),
                                        cg2: res.data.data.cg2.map(item => ({
                                            value: item,
                                            label: item,
                                        })),
                                        salesManager: res.data.data.salesManager && (res.data.data.salesManager.name + ' ' + res.data.data.salesManager.secondName),
                                        quality: {label: res.data.data.quality, value: res.data.data.quality}
                                    },
                                    weightPallet: res.data.data.weight,
                                    seasonPallet: res.data.data.season,
                                    notePallet: res.data.data.note,
                                    orderNumberPallet: res.data.data.orderNumber,
                                    qualityPallet: res.data.data.quality,
                                    netWeightPallet: res.data.data.netWeight,
                                    salesManagerPallet: res.data.data.salesManager && (res.data.data.salesManager.name + ' ' + res.data.data.salesManager.secondName),
                                    productNamePallet: res.data.data.productName,
                                    location: res.data.data.location,
                                    boxesList: result.data,
                                    creationType: res.data.data.creationType,
                                    itemsCount: res.data.data.boxes.reduce((sum, box) => sum + (box.poInboundType === 'Totals' ? box.item : box.productsCount), 0),
                                    bidPallet: res.data.data.bid !== "invalid" ? res.data.data.bid : res.data.data.bids.map(b => b.number).join(', ')

                                }), () => {
                                    this.handleGetAvailableBoxesByPos()
                                })
                            })
                    }
                    this.setState(prevState => ({
                        isLoading: false,
                        isPalletFromPo: res.data.data.orders.length > 0,
                        poOptions: this.generatePoOptions(res.data.data.orders),
                        boxesWithoutPo: res.data.data.boxes.filter(box => box.pack === null),
                        boxesWithPo: res.data.data.boxes.filter(box => box.pack !== null),
                        values: {
                            poIds: res.data.data.orders.map(order => order.id),
                            boxesId: res.data.data.boxes.map(box => box.id),
                            weight: res.data.data.weight,
                            netWeight: res.data.data.netWeight,
                            season: res.data.data.season,
                            note: res.data.data.note,
                            orderNumber: res.data.data.orderNumber,
                            bid: res.data.data.bid,
                            productName: {label: res.data.data.productName, value: res.data.data.productName},
                            statusId: res.data.data.palletStatus ? res.data.data.palletStatus.id : '',
                            packTypeId: res.data.data.palletPackType ? res.data.data.palletPackType.id : '',
                            locationId: res.data.data.location ?  ({
                                value: res.data.data.location.id,
                                label: formatCoordinatesToView(`${res.data.data.location.coordinateX}-${res.data.data.location.coordinateY}`)
                            }): '',
                            cg1: res.data.data.cg1.map(item => ({
                                value: item,
                                label: item,
                            })),
                            cg2: res.data.data.cg2.map(item => ({
                                value: item,
                                label: item,
                            })),
                            salesManager: res.data.data.salesManager && (res.data.data.salesManager.name + ' ' + res.data.data.salesManager.secondName),
                            quality: {label: res.data.data.quality, value: res.data.data.quality}
                        },
                        prevValues: {
                            poIds: res.data.data.orders.map(order => order.id),
                            boxesId: res.data.data.boxes.map(box => box.id),
                            weight: res.data.data.weight,
                            season: res.data.data.season,
                            note: res.data.data.note,
                            orderNumber: res.data.data.orderNumber,
                            bid: res.data.data.bid,
                            netWeight: res.data.data.netWeight,
                            productName: {label: res.data.data.productName, value: res.data.data.productName},
                            statusId: res.data.data.palletStatus ? res.data.data.palletStatus.id : '',
                            packTypeId: res.data.data.palletPackType ? res.data.data.palletPackType.id : '',
                            locationId: res.data.data.location ?  ({
                                value: res.data.data.location.id,
                                label: formatCoordinatesToView(`${res.data.data.location.coordinateX}-${res.data.data.location.coordinateY}`)
                            }): '',
                            cg1: res.data.data.cg1.map(item => ({
                                value: item,
                                label: item,
                            })),
                            cg2: res.data.data.cg2.map(item => ({
                                value: item,
                                label: item,
                            })),
                            salesManager: res.data.data.salesManager && (res.data.data.salesManager.name + ' ' + res.data.data.salesManager.secondName),
                            quality: {label: res.data.data.quality, value: res.data.data.quality},
                        },
                        itemsCount: res.data.data.boxes.reduce((sum, box) => sum + (box.poInboundType === 'Totals' ? box.item : box.productsCount), 0),
                        weightPallet: res.data.data.weight,
                        netWeightPallet: res.data.data.netWeight,
                        salesManager: res.data.data.salesManager && (res.data.data.salesManager.name + ' ' + res.data.data.salesManager.secondName),
                        seasonPallet: res.data.data.season,
                        notePallet: res.data.data.note,
                        migrated: res.data.data.migrated,
                        orderNumberPallet: res.data.data.orderNumber,
                        salesManagerPallet: res.data.data.salesManager && (res.data.data.salesManager.name + ' ' + res.data.data.salesManager.secondName),
                        productNamePallet: res.data.data.productName,
                        location: res.data.data.location,
                        boxesList: res.data.data.boxes,
                        creationType: res.data.data.creationType,
                        season: res.data.data.season,
                        note: res.data.data.note,
                        orderNumber: res.data.data.orderNumber,
                        bid: res.data.data.bid,
                        qualityPallet: res.data.data.quality,
                        bidPallet: res.data.data.bid !== "invalid" ? res.data.data.bid : res.data.data.bids.map(b => b.number).join(', ')
                    }), () => {
                        this.handleGetAvailableBoxesByPos()
                    })
                })
                .catch(err => {
                    changeRoute('/atw/inventory/list', history)
                    notify('error', 'Pallet does not exist')
                })
        })
    }

    replaceBoxes = boxesId => {
        this.setState({
            values: {
                ...this.state.values,
                boxesId: boxesId
            }
        })
    }

    handleChangePoValues = options => {
        this.setState({
            poOptions: options ? options : [],
            values: {
                ...this.state.values,
                poIds: options ? options.map(option => option.value) : []
            }
        }, this.handleGetAvailableBoxesByPos)
    }

    handleGetAvailableBoxesByPos = () => {
        const {values,isPalletFromPo} = this.state
        const {palletId} = this.props
        const self = this
        this.setState({
            isLoadingBoxes: true
        }, () => {
            if(isPalletFromPo){
                axios.all(values.poIds.map(id => getClosedBoxesByPoWithPallet(id, palletId)))
                    .then(axios.spread(function () {
                        const args = Array.from(arguments).filter(res => res.data.data.length > 0).map(res => res.data.data).flat(1)
                        // if (values.boxesId.length > 0) {
                        //     let resBoxes = values.boxesId.filter(boxId => {
                        //         let isExist = false
                        //         args.forEach(pack => {
                        //             pack.boxes.forEach(box => {
                        //                 if (box.id === boxId)
                        //                     isExist = true
                        //             })
                        //         })
                        //         return isExist
                        //     })
                        //     self.replaceBoxes(resBoxes)
                        // }
                        self.setState({
                            isLoadingBoxes: false,
                            packages: args,
                            isLoading: false
                        })
                    }))
                    .catch(err => notify('error', 'Oops something went wrong'))
            }
            else{
                axios.all(values.poIds.map(id => getPallet(id)))
                    .then(axios.spread(function(){
                        const args = Array.from(arguments).map(res => res.data.data)
                        // if (values.boxesId.length > 0) {
                        //     let resBoxes = values.boxesId.filter(boxId => {
                        //         let isExist = false
                        //         args.forEach(pack => {
                        //             pack.boxes.forEach(box => {
                        //                 if (box.id === boxId)
                        //                     isExist = true
                        //             })
                        //         })
                        //         return isExist
                        //     })
                        //     self.replaceBoxes(resBoxes)
                        // }

                        self.setState({
                            isLoadingBoxes: false,
                            packages: args,
                            isLoading: false
                        })
                    }))
            }
        })

    }

    handleChangeBoxes = boxId => {
        this.setState({
            values: {
                ...this.state.values,
                boxesId: this.state.values.boxesId.includes(boxId)
                    ? this.state.values.boxesId.filter(bId => bId !== boxId)
                    : this.state.values.boxesId.concat(boxId)
            }
        }, () => {
            if (this.state.values.boxesId.length > 10)
                notify('warning', 'Over 10 packs selected!')
        })
    }

    handleChange = (fieldName, e) => {
        const target = e.target
        schema.validate({
            'netWeight': fieldName === 'netWeight' ? target.value : this.state.values.netWeight,
            'weight': fieldName === 'weight' ? target.value : this.state.values.weight,
            'productName': fieldName === 'productName' ? e.value : this.state.values.productName,
            'statusId': fieldName === 'statusId' ? e.value : this.state.values.statusId,
            'packTypeId': fieldName === 'statusId' ? e.value : this.state.values.packTypeId,
            'locationId': fieldName === 'locationId' ? e.value : this.state.values.locationId,
            'cg1': fieldName === 'cg1' ? e : this.state.values.cg1,
            'cg2': fieldName === 'cg2' ? e :  this.state.values.cg1,
            'quality': fieldName === 'quality' ? e :  this.state.values.quality,
            'season': fieldName === 'season' ? e :  this.state.values.season,
            'note': fieldName === 'note' ? e :  this.state.values.note,
            'orderNumber': fieldName === 'orderNumber' ? e :  this.state.values.orderNumber,
            'bid': fieldName === 'bid' ? e :  this.state.values.bid,
            'salesManager': fieldName === 'salesManager' ? e.value :  this.state.values.salesManager,
        })
        .then(res => {
            this.setState({
                netWeightError: '',
                weightError: '',
                productNameError: '',
                statusIdError: '',
                packTypeIdError: '',
                locationIdError: '',
                cg1Error: '',
                cg2Error: '',
                qualityError: '',
                seasonError: '',
                noteError: '',
                orderNumberError: '',
                bidError: '',
                salesManager: '',
            })
        })
        .catch(err => {
           
            this.setState({
                [`${err.path}Error`]: err.errors[0]
                // [err.path === 'weight' ? 'weightError' : (err.path === 'netWeight' ? 'netWeightError' : 'productNameError')]: err.errors[0]
            })
        })
        this.setState({
            values: {
                ...this.state.values,
                [fieldName]: (fieldName !== 'productName' && fieldName !== 'cg1' && fieldName !== 'cg2' && fieldName !== 'quality')
                             ? ((fieldName !== 'statusId' && fieldName !== 'packTypeId' && fieldName !== 'locationId' && fieldName !== 'salesManager') ? target.value : e) : e
            }
        })
    }

    handleMoveBoxes = (pallet) => {
        const {selectedBoxes} = this.state
        this.setState({
            isLoading: true,
            selectedBoxes: [],
        }, () => {
            moveBoxesBetweenPallet(selectedBoxes, pallet)
                .then(res => {
                    notify('success', 'Packs successfully moved')
                    const {palletId, refetchData} = this.props
                    this.handleGetPallet(palletId)
                    refetchData()
                    this.setState({modalConfirm: false, selectedPallet: null, isConfirm: false})
                })
                .catch(err => {
                    this.setState({modalConfirm: false})
                    notify('error', 'Oops something went wrong')
                })
        })
    }

    handleOpenCloseBox = (boxId, open) => {
        const promise = open ? openBox(boxId) : closeBox(boxId)
        promise
            .then(res => {
                notify('success', `Box successfully ${open ? 'opened' : 'closed'}`)
                this.setState({
                    boxesList: this.state.boxesList.map(box => {
                        if (box.id === boxId)
                            return {
                                ...box,
                                closed: !open
                            }
                        return box
                    })
                })
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }

    printPalletLabel = () => {
        const {printer, palletId} = this.props;

        getPalletPrintInfo(palletId)
            .then(res => {
                const palletInfo = res.data.data;

                sendDataToPrinter(printer, palletLabel({ //id, count, cg1, cg2, productName, pos, boxCount, weight
                    id: palletInfo.id,
                    count: palletInfo.itemsCount,
                    cg1: palletInfo.cg1,
                    cg2: palletInfo.cg2,
                    productName: palletInfo.productName,
                    pos: palletInfo.bids.map(bid => bid.number),
                    boxCount: palletInfo.boxCount,
                    weight: palletInfo.netWeight
                }))
            })
            .catch(err => defaultServerError())
    }

    handleCreateNewBox = () => {
        this.indexCreatedBox++
        this.setState(state => ({
            newBoxes: state.newBoxes.concat({
                id: uniqId(),
                name: `${this.indexCreatedBox}`,
            })
        }))
    }

    handleRemoveNewBox = (id) => {
        this.setState(state => ({
            newBoxes: state.newBoxes.filter(box => box.id !== id)
        }))
    }

    handleDeleteBox = () => {
        const { currentBox } = this.state
        const { palletId } = this.props
        this.handleHideDeleteBoxModal()
        removeBox(currentBox)
            .then(res => {
                this.handleGetPallet(palletId)
                notify('success','Box successfully deleted')
            })
            .catch(err => defaultServerError())
    }

    handleSort = () => {
        this.setState(prevState => ({
            sortType: prevState.sortType ? (prevState.sortType === 'desc' ? 'asc' : null) : 'desc'
        }), () => {
            if(this.state.sortType){
                sortPallet(this.props.palletId, this.state.sortType)
                    .then(res => {
                        if(res){
                            this.setState(prevState => ({
                                boxesWithoutPo: res.data.filter(box => box.pack === null),
                                boxesWithPo: res.data.filter(box => box.pack !== null),
                                values: {
                                    ...prevState.values,
                                    boxesId: res.data.map(box => box.id),
                                },
                                boxesList: res.data
                            }), () => {
                                this.handleGetAvailableBoxesByPos()
                            })
                        }
                    })
            }else{
                getPallet(this.props.palletId)
                    .then(res => {
                        this.setState(prevState => ({
                            boxesWithoutPo: res.data.data.boxes.filter(box => box.pack === null),
                            boxesWithPo: res.data.data.boxes.filter(box => box.pack !== null),
                            values: {
                                ...prevState.values,
                                boxesId: res.data.data.boxes.map(box => box.id),
                            },
                            boxesList: res.data.data.boxes
                        }), () => {
                            this.handleGetAvailableBoxesByPos()
                        })
                    })
            }

        })
    }

    render() {
        const {
            values,
            // poOptions,
            // packages,
            isLoading,
            // isLoadingBoxes,
            isTouched,
            modalOpen,
            weightPallet,
            seasonPallet,
            notePallet,
            orderNumberPallet,
            bidPallet,
            netWeightPallet,
            salesManagerPallet,
            productNamePallet,
            qualityPallet,
            weightError,
            boxesList,
            selectedBoxes,
            modalMove,
            // newBoxes,
            selectedPallet,
            isConfirm,
            modalDelete,
            // isPalletFromPo,
            // boxesWithoutPo,
            // boxesWithPo,
            showInfo,
            sortType,
            creationType,
            netWeightError,
            statusIdError,
            packTypeIdError,
            locationIdError,
            location,
            cg1Error,
            cg2Error,
            qualityError,
            orderNumberError,
            itemsCount,
            migrated,
        } = this.state
        const {roles, palletId, printer,refetchData, plan, onCancelCreate} = this.props
        const historyState = this.props.location.state
        const hasPermToEdit = findPermInArray(roles, permEditInventory)
        // const selectedBoxesIds = selectedBoxes.join(', ')
        return (
            isLoading
                ? <Preloader/>
                :
                <div className="view-pallet">
                    <div className="all-actions">
                        <Button className="btn-back" onClick={onCancelCreate}>
                            <svg viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                                />
                            </svg>
                            Back
                        </Button>
                        <div className="actions-pallet">
                            <Button onClick={() => this.handleSort()}>
                                {sortType ? (sortType === 'desc' ? <ArrowDown className="standart-svg"/> : <ArrowUp className="standart-svg"/>) : <ArrowsSort className="standart-svg"/>}
                                Sort
                            </Button>
                            {selectedBoxes.length > 0 && <Button onClick={this.handleOpenBoxesMoveModal}>
                                <MoveIcon className="standart-svg"/> Move Boxes ({selectedBoxes.length})
                            </Button>}
                            <Button onClick={() => this.handleShowInfo()}>
                                {showInfo ? <ChronoIcon className="standart-svg"/> : <InfoIcon className="standart-svg"/> }
                                {showInfo ? 'View сhronology' : 'View info' }
                            </Button>
                            <Button
                                onClick={() => {
                                    this.printPalletLabel()
                                }}
                                disabled={!printer || !boxesList.every(item => item.closed)}
                            >

                                <PrinterIcon className="standart-svg"/> Print label
                            </Button>
                            {hasPermToEdit && <Button onClick={this.handleOpenModal}>
                                <EditIcon className="standart-svg"/> Edit
                            </Button>}
                        </div>
                    </div>
                    <div className="header-pallet">
                        <div className="info-pallet-wrapper">
                            <div className="info-pallet-col">
                                <span className="info-pallet-title"><b>Net weight, kg:</b> {netWeightPallet}</span><br></br>
                                <span className="info-pallet-title"><b>Gross weight, kg:</b> {weightPallet}</span><br></br>
                                <span className="info-pallet-title"><b>Product name:</b> {productNamePallet}</span><br></br>
                                <span className="info-pallet-title"><b>Location:</b> {location ? `${getLetterByNumber(location.coordinateX)}${location.coordinateY}` : ''}</span><br></br>
                                <span className="info-pallet-title"><b>Season:</b> {seasonPallet}</span><br></br>
                                <span className="info-pallet-title"><b>Quality:</b> {qualityPallet}</span><br></br>
                                <span className="info-pallet-title"><b>BID:</b> {bidPallet}</span><br></br>
                                <span className="info-pallet-title"><b>Note:</b> {notePallet}</span><br></br>
                                <span className="info-pallet-title"><b>Order#:</b> {orderNumberPallet}</span><br></br>
                                <span className="info-pallet-title"><b>Sales Manager:</b> {salesManagerPallet}</span><br></br>
                            </div>
                        </div>
                        <div className="info-pallet-wrapper small">
                            <div className="info-pallet-col">
                                <span className="info-pallet-title"><b>Net weight, kg:</b> {netWeightPallet}</span><br></br>
                                <span className="info-pallet-title"><b>Gross weight, kg:</b> {weightPallet}</span><br></br>
                                <span className="info-pallet-title"><b>Product name:</b> {productNamePallet}</span><br></br>
                                <span className="info-pallet-title"><b>Location:</b> {location ? `${getLetterByNumber(location.coordinateX)}${location.coordinateY}` : ''}</span><br></br>
                                <span className="info-pallet-title"><b>Season:</b> {seasonPallet}</span><br></br>
                                <span className="info-pallet-title"><b>Quality:</b> {qualityPallet}</span><br></br>
                                <span className="info-pallet-title"><b>BID:</b> {bidPallet}</span><br></br>
                                <span className="info-pallet-title"><b>Note:</b> {notePallet}</span><br></br>
                                <span className="info-pallet-title"><b>Order#:</b> {orderNumberPallet}</span><br></br>
                                <span className="info-pallet-title"><b>Sales Manager:</b> {salesManagerPallet}</span><br></br>
                            </div>
                        </div>
                    </div>

                    {
                        showInfo ? <div className="list-boxes">
                            {boxesList.map(box => box.poInboundType !== 'Totals' ? <BoxCollapse
                                migrated={migrated}
                                key={box.id}
                                box={box}
                                method={creationType}
                                removeBox={e => {
                                    e.stopPropagation()
                                    this.handleOpenDeleteBoxModal(box.id)
                                }}
                                callbackMoving={() => this.handleGetPallet(palletId)}
                                refetchData={refetchData}
                                handleOpenCloseBox={this.handleOpenCloseBox}
                                isScanned={historyState && historyState.scannedBox && historyState.scannedBox === box.id}
                                isSelected={selectedBoxes.includes(box.id)}
                                handleSelect={this.handleSelectUnselectBox}
                            /> : <PackItem
                              key={box.id}
                              box={box}
                              removeBox={e => {
                                  e.stopPropagation()
                                  this.handleOpenDeleteBoxModal(box.id)
                              }}
                              method={creationType}
                              isScanned={historyState && historyState.scannedBox && historyState.scannedBox === box.id}
                              isSelected={selectedBoxes.includes(box.id)}
                              handleSelect={this.handleSelectUnselectBox}
                            />)}
                            {/*<Button onClick={this.handleCreateBox}>*/}
                            {/*<AddIcon className="standart-svg"/> Create box*/}
                            {/*</Button>*/}
                        </div>
                        : <HistoryList 
                            id={palletId} 
                            getHistory={getHistory}
                            chronologyFiledsConverting={palletChronologyFiledsConverting} />
                    }
                    <Modal
                        open={modalMove}
                        // handleClose={this.handleHideBoxesMoveModal}
                        isFooter={isConfirm}
                        handleConfirm={() => this.handleMoveBoxes(selectedPallet)}
                        handleCancel={this.handleHideBoxesMoveModal}
                        options={{
                            centered: true
                        }}
                    >
                        {modalMove && <ModalMoveBoxes
                            refetchTable={refetchData}
                            palletId={palletId}
                            selectedPallet={selectedPallet}
                            selectedBoxes={selectedBoxes}
                            handleConfirm={(selectedPallet) => this.setState({selectedPallet, isConfirm: true})}
                            handleChangePallet={this.handleChangePallet}
                            handleClose={this.handleHideBoxesMoveModal}
                            isConfirm={isConfirm}
                        />}
                    </Modal>
                    {hasPermToEdit && <Modal
                        open={modalDelete}
                        isFooter={true}
                        handleConfirm={this.handleDeleteBox}
                        handleClose={this.handleHideDeleteBoxModal}
                        handleCancel={this.handleHideDeleteBoxModal}
                        options={{
                            centered: true
                        }}
                    >
                        <ConfirmModalContent text="delete pack"/>
                    </Modal>}
                    {hasPermToEdit && <Modal
                        open={modalOpen}
                        isFooter={false}
                        // handleClose={this.handleCloseModal}
                        options={{
                            centered: true
                        }}
                    >
                        <div className="edit-pallet__wrapper">
                            {/*<FirstStep*/}
                            {/*    isPalletFromPo={isPalletFromPo}*/}
                            {/*    isTouched={isTouched}*/}
                            {/*    pos={poOptions}*/}
                            {/*    handleChange={this.handleChangePoValues}*/}
                            {/*/>*/}
                            {/*<SecondStep*/}
                            {/*    boxesWithPo={boxesWithPo}*/}
                            {/*    boxesWithoutPo={boxesWithoutPo}*/}
                            {/*    isPalletFromPo={isPalletFromPo}*/}
                            {/*    createBox={this.handleCreateNewBox}*/}
                            {/*    removeBox={this.handleRemoveNewBox}*/}
                            {/*    newBoxes={newBoxes}*/}
                            {/*    isTouched={isTouched}*/}
                            {/*    isLoading={isLoadingBoxes}*/}
                            {/*    packages={packages}*/}
                            {/*    boxesId={values.boxesId}*/}
                            {/*    handleChange={this.handleChangeBoxes}*/}
                            {/*/>*/}
                            <ThirdStep
                                weightError={weightError}
                                netWeightError={netWeightError}
                                statusIdError={statusIdError}
                                packTypeIdError={packTypeIdError}
                                locationIdError={locationIdError}
                                isTouched={isTouched}
                                weight={values.weight}
                                netWeight={values.netWeight}
                                productName={values.productName}
                                statusId={values.statusId}
                                packTypeId={values.packTypeId}
                                locationId={values.locationId}
                                handleChange={this.handleChange}
                                handleChangeNetWeight={this.handleChangeNetWeight}
                                cg1={values.cg1}
                                cg2={values.cg2}
                                salesManager={values.salesManager}
                                quality={values.quality.value}
                                itemsCount={itemsCount}
                                cg1Error={cg1Error}
                                cg2Error={cg2Error}
                                orderNumberError={orderNumberError}
                                orderNumberError={qualityError}
                                season={values.season}
                                note={values.note}
                                orderNumber={values.orderNumber}
                                bid={values.bid}
                            />
                            <div className="footer-form footer-form-custom">
                                <Button className="cancel"
                                        onClick={this.handleCloseModal}>Cancel</Button>
                                <Button disabled={!values.locationId} onClick={this.handleSubmit}>Save</Button>
                            </div>
                        </div>
                    </Modal>
                    }
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles,
        printer: state.printersReducer.selected_device,
        plan: state.inventoryReducer.plan,
    }
}

export default withRouter(connect(mapStateToProps, null)(EditPallet))