import React from 'react'
import { Button } from 'react-bootstrap'
import { InventoryMapFilters } from './InventoryMapFilters'
import { ReactComponent as SettingsIcon } from '../../../../assets/images/settings.svg'
import { changeRoute } from '../../../../reuseFunctions/changeRoute'
import { history } from '../../../../history'

const InventoryMapToolbar = ({handleOpenCreateModal, pagination, setPagination, filterData}) => {
  return (
    <div className="map-toolbar">
      <div className="left-part">
        {/*<Button onClick={() => changeRoute('/atw/inventory/map-editor', history)} className="add-button-customer add-button">*/}
        {/*  <SettingsIcon className="button-svg"/>*/}
        {/*  Spaces*/}
        {/*</Button>*/}
        {handleOpenCreateModal && <Button onClick={handleOpenCreateModal} className="add-button-customer add-button">
          <svg className="button-svg" viewBox="0 0 24 24">
            <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
          </svg>
          Add pallet
        </Button>}
      </div>
      <div className="actions">
        <InventoryMapFilters pagination={pagination} setPagination={setPagination} filterData={filterData}/>
      </div>
    </div>
  )
}

export { InventoryMapToolbar }