import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Button, Form, Row, Col} from 'react-bootstrap'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import Select from '../../../../reuseComponent/Select/select-form'
import AsyncSelect from '../../../../reuseComponent/Select/async-select'
import {getDepartments} from '../../../../../services/dictionaries'
import {getUsers} from '../../../../../actions/users'
import adminInstance from '../../../../instances/admininstance'
import {url} from '../../../../../constants'
import DatePicker from '../../../../reuseComponent/DatePicker/datepicker-with-children'
import {getTasksTemplates} from '../../../../../services/tasks'
import {defaultTaskOption} from '../../list'
import moment from 'moment'
import AutosizeInput from "react-input-autosize";
import {ReactComponent as InfoIcon} from "../../../../../assets/images/info.svg";
import {disabledSelectStyle} from "../../../../reuseComponent/Select/disabledSelectStyling";


const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color',
]

class BodyForm extends PureComponent {

    state = {
        departments: [],
        templates: []
    }

    checkIfDataChange = () => {
        const {values, initialValues} = this.props
        let res = false
        Object.keys(initialValues).forEach(key => {
            try {
                if (JSON.stringify(values[key]) !== JSON.stringify(initialValues[key])) {
                    res = true
                }
            }
            catch (err) {

            }
        })
        return res
    }

    generateQueryString = (inputString) => {
        return `?page=0&limit=10&search=s:${inputString};`
    }

    getPurchaseOrders = (inputValue, callback) => {
        adminInstance.get(`${url}/purchase_order/offers`,{
            params: {
                page: 0,
                limit: 25,
                status: '1,2',
                search: inputValue
            }
        })
            .then(res => {
                callback(res.data.purchaseOrders.map(item => ({
                    value: item.id,
                    label: 'PO#' + item.orderNumber
                })))
            })
    }

    getTasksTemplateRequest = () => {
        getTasksTemplates({
            limit: 500,
            page: 0
        })
            .then(res => {
                this.setState({
                    templates: res.data.templates
                })
            })
    }

    handleChangeTemplate = template => {
        const {setValues, values, setFieldValue} = this.props
        if (template.value !== 'custom')
            setValues && setValues({
                ...values,
                taskTemplate: template,
                description: template.description,
                title: template.title,
                responsible: template.executor ? {
                    value: template.executor.id,
                    label: `${template.executor.name} ${template.executor.secondName}`
                } : template.department ? {
                    value: template.department.id,
                    label: template.department.name
                } : null,
                executorId: template.executor ? template.executor.id : '',
                departmentId: template.department ? template.department.id : ''
            })
        else {
            setFieldValue('taskTemplate', template)
        }

    }

    componentDidMount() {
        const {getUsers, users} = this.props
        users.length === 0 && getUsers && getUsers()
        this.getTasksTemplateRequest()
        getDepartments(0, 100)
            .then(res => {
                this.setState({
                    departments: res.data.departments.map(dep => ({
                        value: dep.id,
                        label: dep.name,
                        type: 'Department'
                    }))
                })
            })
    }

    render() {
        const {
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            handleCancel,
            setFieldValue,
            users,
            setValues,
            createFromPo,
            onlyChangeResponsive,
            isView,
            isDetails,
            initialValues,
        } = this.props
        const {departments} = this.state
        const {templates} = this.state
        return <Form className="task-form tasks-form" noValidate onSubmit={handleSubmit} >
            {!onlyChangeResponsive ? <>
                <Row>
                    <Col xs={12}>
                        <h6 className="form-title">
                            <InfoIcon/>
                            Main info
                        </h6>
                    </Col>
                    {
                        !initialValues.inbound && !initialValues.transport && !initialValues.accounting && (
                            <Col xs={6}>
                                <Form.Group className="task-template horizontal">
                                    <Form.Label>Ticket Template</Form.Label>
                                    <div className="input-item">
                                        <Select
                                            value={values.taskTemplate}
                                            isDisabled={!!isView}
                                            options={[defaultTaskOption, ...templates.map(temp => ({
                                                ...temp,
                                                value: temp.id,
                                                label: temp.title
                                            }))]}
                                            styles={!!isView ? disabledSelectStyle : {}}
                                            placeholder={!!isView ? '' : 'Select task template'}
                                            onChange={this.handleChangeTemplate}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        )
                    }
                    {!createFromPo && !initialValues.inbound && !initialValues.transport && !initialValues.accounting && <Col xs={6}>
                        <Form.Group className="responsible-form-group horizontal">
                            <Form.Label>Related element</Form.Label>
                            <div className="input-item">
                                <AsyncSelect
                                    isDisabled={!!isView}
                                    value={values.order}
                                    isInvalid={errors.poId && touched.poId}
                                    errorLabel={errors.poId}
                                    loadOptions={this.getPurchaseOrders}
                                    styles={!!isView ? disabledSelectStyle : {}}
                                    placeholder={!!isView ? '' : 'Select related element'}
                                    defaultOptions={true}
                                    onChange={obj => {
                                        setValues({
                                            ...values,
                                            order: obj,
                                            poId: obj.value
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </Col>}
                    <Col xs={6}>
                        <Form.Group className="title-form-group horizontal">
                            <Form.Label>Title</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    disabled={!!isView || initialValues.inbound || initialValues.transport || initialValues.accounting}
                                    placeholder={!!isView || initialValues.inbound || initialValues.transport || initialValues.accounting ? '' : 'Enter title'}
                                    className="auto-resize-wrapper"
                                    value={values.title}
                                    name='title'
                                    onChange={handleChange}
                                />
                                <span className="error-label">{touched.title && errors.title}</span>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group className="responsible-form-group horizontal">
                            <Form.Label>Responsible</Form.Label>
                            <div className="input-item">
                                <Select
                                    isDisabled={!!isView}
                                    isInvalid={touched.responsible && errors.responsible}
                                    errorLabel={errors.responsible}
                                    value={values.responsible}
                                    onChange={obj => {
                                        if (obj.type === 'User')
                                            setValues({
                                                ...values,
                                                executorId: obj.value,
                                                departmentId: '',
                                                responsible: obj
                                            })
                                        else {
                                            setValues({
                                                ...values,
                                                executorId: '',
                                                departmentId: obj.value,
                                                responsible: obj
                                            })
                                        }
                                    }}
                                    styles={!!isView ? disabledSelectStyle : {}}
                                    placeholder={!!isView ? '' : 'Select responsible'}
                                    options={users.concat(departments)}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group className="horizontal">
                            <Form.Label>Due date</Form.Label>
                            <div className="input-item">
                                <div className="wrapper-calendar-asana">
                                    {!!isView
                                        ?
                                        <div className="react-bootstrap-daterangepicker-container">
                                            <svg viewBox="0 0 24 24">
                                                <path fill="#000000"
                                                      d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z"/>
                                            </svg>
                                            <Form.Control
                                                isInvalid={errors.dueDate && touched.dueDate}
                                                readOnly={true}
                                                value={values.dueDate ? values.dueDate : 'No due date'}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.dueDate}</Form.Control.Feedback>

                                        </div>
                                        : <DatePicker
                                            minDate={moment()}
                                            startDate={values.dueDate}
                                            onApply={(event, picker) => {
                                                setFieldValue('dueDate', picker.startDate.format('DD.MM.YYYY'))
                                            }}
                                        >
                                            <>
                                                <svg viewBox="0 0 24 24">
                                                    <path fill="#000000"
                                                          d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z"/>
                                                </svg>
                                                <Form.Control
                                                    isInvalid={errors.dueDate && touched.dueDate}
                                                    readOnly={true}
                                                    value={values.dueDate ? values.dueDate : 'No due date'}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.dueDate}</Form.Control.Feedback>

                                            </>
                                        </DatePicker>
                                    }
                                    {values.dueDate && !!!isView && <svg onClick={e => {
                                        setFieldValue('dueDate', '')
                                        document.body.click()
                                    }} className="circle-close" viewBox="0 0 24 24">
                                        <path fill="#000000"
                                              d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/>
                                    </svg>
                                    }
                                </div>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group className="horizontal">
                            <Form.Label>Description</Form.Label>
                            <div className="input-item">
                                <ReactQuill
                                    readOnly={!!isView}
                                    theme="snow"
                                    onChange={value => setFieldValue('description', value)}
                                    formats={formats}
                                    value={values.description}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                </> :
                <Form.Group>
                    <Form.Label>Responsible</Form.Label>
                    <Select
                        isDisabled={!!isView}
                        isInvalid={touched.responsible && errors.responsible}
                        errorLabel={errors.responsible}
                        value={values.responsible}
                        onChange={obj => {
                            if (obj.type === 'User')
                                setValues({
                                    ...values,
                                    executorId: obj.value,
                                    departmentId: '',
                                    responsible: obj
                                })
                            else {
                                setValues({
                                    ...values,
                                    executorId: '',
                                    departmentId: obj.value,
                                    responsible: obj
                                })
                            }
                        }}
                        options={users.concat(departments)}
                    />
                </Form.Group>
            }
            <Form.Group style={isDetails ? {margin: '10px 0'} : {}} className="footer-form footer-form-custom">

                {(this.checkIfDataChange() || !isDetails) && <Button variant="secondary"
                                         onClick={handleCancel}>Cancel</Button>}
                {!!!isView && this.checkIfDataChange() && <Button variant="primary" type="submit">Save</Button>}
            </Form.Group>
        </Form>
    }
}

const mapStateToProps = state => {
    return {
        users: state.usersReducer.users.map(user => ({
            value: user.id,
            label: `${user.name} ${user.secondName}`,
            type: 'User'
        }))
    }
}

export default connect(mapStateToProps, {
    getUsers
})(BodyForm)