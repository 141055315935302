import React, {PureComponent} from 'react'
import { Switch, Route } from 'react-router-dom'

import ReleaseNotesList from "./list"
import './style.scss'

class NotificationsModule extends PureComponent{
    render(){
        return(
            <Switch>
                <Route exact path={`/atw/private/release-notes`} component={ReleaseNotesList}/>
            </Switch>
        )
    }
}

export default NotificationsModule;