import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import Form from '../form'
import { createQuality } from '../../../../../actions/commodity-groups'


class CreateForm extends PureComponent{

    handleSubmit = values => {
        const { handleCancel,createQuality } = this.props
        // handleCancel && handleCancel ()
        createQuality && createQuality(values,handleCancel)

    }

    render(){
        const { handleCancel } = this.props
        return (
            <Form
                initValues={{
                    value: '',
                    inputs: []
                }}
                handleSubmit={this.handleSubmit}
                handleCancel={handleCancel}
            />
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createQuality: (values,callback) => dispatch(createQuality(values,callback))
    }
}

export default connect(null,mapDispatchToProps)(CreateForm)