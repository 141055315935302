import React, { useCallback, useMemo } from 'react'
import { InventoryMapFilter } from './InventoryMapFilter'
// import { getPaginatableSimpleParameter } from '../../../../services/dictionaries'
// import { getBrands } from '../../../../services/brands'
// import adminInstance from '../../../instances/admininstance'
// import adminsInstance from '../../../instances/admininstance'
// import { url } from '../../../../constants'
import CustomTooltip from '../../../reuseComponent/Tooltips'
import { ReactComponent as ClearIcon } from '../../../../assets/images/clear.svg'

const InventoryMapFilters = ({pagination, setPagination, filterData}) => {

  const hasActiveFilters = useMemo(() => {
    return pagination.cg1.length > 0 || pagination.cg2.length > 0 || pagination.cg3.length > 0
      || pagination.brand.length > 0 || pagination.palletStatus.length > 0 || pagination.productName.length > 0
      || pagination.quality.length > 0 || pagination.season.length > 0
  }, [pagination])

  const handleChangePaginationValue = useCallback((field, value) => {
    setPagination(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }, [setPagination])

  const handleClearFilters = useCallback(() => {
    setPagination(prevState => ({
      ...prevState,
      brand: [],
      cg1: [],
      cg2: [],
      cg3: [],
      palletStatus: [],
      productName: [],
      quality: [],
      season: [],
    }))
  }, [setPagination])

  // const loadCg1Options = useCallback((inputValue) => {
  //   return new Promise(resolve => {
  //     getPaginatableSimpleParameter("commodity", 0, 25, inputValue).then(
  //       resp => {
  //         resolve(resp.data.parameters.map(item => ({
  //           value: item.value,
  //           label: item.value,
  //         })))
  //       }
  //     );
  //   });
  // }, [])
  //
  // const loadCg2Options = useCallback((inputValue) => {
  //   return new Promise(resolve => {
  //     getPaginatableSimpleParameter("gender", 0, 25, inputValue).then(
  //       resp => {
  //         resolve(resp.data.parameters.map(item => ({
  //           value: item.value,
  //           label: item.value,
  //         })))
  //       }
  //     );
  //   });
  // }, [])
  //
  // const loadCg3Options = useCallback((inputValue) => {
  //   return new Promise(resolve => {
  //     getPaginatableSimpleParameter("category", 0, 25, inputValue).then(
  //       resp => {
  //         resolve(resp.data.parameters.map(item => ({
  //           value: item.value,
  //           label: item.value,
  //         })))
  //       }
  //     );
  //   });
  // }, [])
  //
  // const loadBrandOptions = useCallback((inputValue) => {
  //   return new Promise(resolve => {
  //     getBrands({
  //       page: 0,
  //       limit: 25,
  //       search: inputValue
  //     }).then(
  //       resp => {
  //         resolve(resp.data.brands.map(item => ({
  //           value: item.name,
  //           label: item.name
  //         })))
  //       }
  //     );
  //   });
  // }, [])
  //
  // const loadQualityOptions = useCallback((inputValue) => {
  //   return new Promise(resolve => {
  //     getPaginatableSimpleParameter("quality", 0, 25, inputValue).then(
  //       resp => {
  //         resolve(resp.data.parameters.map(item => ({
  //           value: item.value,
  //           label: item.value,
  //         })))
  //       }
  //     );
  //   });
  // }, [])
  //
  // const loadPalletStatusOptions = useCallback((inputValue) => {
  //   return new Promise(resolve => {
  //     adminsInstance.get(`${url}/pallet/status`)
  //       .then(res => {
  //         resolve(res.data.map(item => ({
  //           value: item.id,
  //           label: item.name
  //         })))
  //       })
  //   });
  // }, [])

  // const loadProductNameOptions = useCallback((inputValue) => {
  //   return new Promise(resolve => {
  //     getPaginatableSimpleParameter("productName", 0, 25, inputValue).then(
  //       resp => {
  //         resolve(resp.data.parameters.map(item => ({
  //           value: item.value,
  //           label: item.value,
  //         })))
  //       }
  //     );
  //   });
  // }, [])
  return (
    <div className="inventory-filters-wrapper">
      Filters:
      {filterData ? <>
        <InventoryMapFilter
          name="CG1"
          placeholder="Select CG1"
          value={pagination.cg1}
          setValue={(value) => handleChangePaginationValue('cg1', value)}
          options={filterData.cg1}
          // getOptions={loadCg1Options}
        />
        <InventoryMapFilter
          name="CG2"
          placeholder="Select CG2"
          value={pagination.cg2}
          setValue={(value) => handleChangePaginationValue('cg2', value)}
          options={filterData.cg2}
          // getOptions={loadCg2Options}
        />
        <InventoryMapFilter
          name="CG3"
          placeholder="Select CG3"
          value={pagination.cg3}
          setValue={(value) => handleChangePaginationValue('cg3', value)}
          options={filterData.cg3}
          // getOptions={loadCg3Options}
        />
        <InventoryMapFilter
          name="Brand"
          placeholder="Select brand"
          value={pagination.brand}
          setValue={(value) => handleChangePaginationValue('brand', value)}
          options={filterData.brand}
          // getOptions={loadBrandOptions}
        />
        <InventoryMapFilter
          name="Quality"
          placeholder="Select quality"
          value={pagination.quality}
          setValue={(value) => handleChangePaginationValue('quality', value)}
          options={filterData.quality}
          // getOptions={loadQualityOptions}
        />
        <InventoryMapFilter
          name="Status"
          placeholder="Select status"
          withoutLoading={true}
          value={pagination.palletStatus}
          setValue={(value) => {
            handleChangePaginationValue('palletStatus', value)
          }}
          options={filterData.palletStatus}
          // getOptions={loadPalletStatusOptions}
        />
        <InventoryMapFilter
          name="Season"
          placeholder="Select season"
          withoutLoading={true}
          value={pagination.season}
          setValue={(value) => handleChangePaginationValue('season', value)}
          options={filterData.season}
          // getOptions={loadPalletStatusOptions}
        />
        <InventoryMapFilter
          name="Product Name"
          placeholder="Select product name"
          value={pagination.productName}
          setValue={(value) => handleChangePaginationValue('productName', value)}
          options={filterData.productName}
          // getOptions={loadProductNameOptions}
        />
        {hasActiveFilters && <CustomTooltip text="Reset filters">
          <ClearIcon style={{cursor: 'pointer', marginLeft: '10px'}} className="standard-svg" onClick={handleClearFilters} />
        </CustomTooltip>}
      </> : null
      }
    </div>
  )
}

export { InventoryMapFilters }
