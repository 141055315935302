import {CUSTOMERS} from '../constants';

const initialValues = {
    customer: {
        address: '',
        busModel: [],
        companyName: '',
        contacts: [],
        email: '',
        phoneNumber: '',
        qualityIds: [],
        spokenLanguage: '',
        country: '',
        categIds: [],
        taxId: '',
        countriesOperate: '',
        zip: '',
        city: '',
        demandCategory: [],
        website: '',
        notes: ''
    },
    request: {
        status: "",
        shortDescription: "",
        typeId: "",
        title: "",
        files: [],
        priority: '',
        comment: [{ text: "" }],
        author: { name: "", secondName: "" }
    },
    customersStatus: null,
    customersResponsible: null,
    activeTabHistory: "CHRONOLOGY",
    history: [],
    isLoadingCustomer: false,
    isLoadingCustomersStatuses: false,
    isLoadingCountries: false,
    countries: [],
    statuses: [],
};

export const customersReducer = (state = initialValues, action) => {
    switch (action.type) {
        case CUSTOMERS.SET_HISTORY_CUSTOMER: {
            return {
              ...state,
              history: action.payload
            };
          }
        case CUSTOMERS.SET_CUSTOMER_REQUEST: {
            return {
              ...state,
              request: action.payload
            };
          }
        case CUSTOMERS.CLEAR_REQUEST: {
            return {
                ...state,
                request: {
                status: "",
                shortDescription: "",
                typeId: "",
                title: "",
                // purpose: "",
                priority: "",
                files: [],
                comment: [{ text: "" }]
                }
            };
        }
        case CUSTOMERS.ADD_HISTORY_CUSTOMERS_ITEM: {
            return {
              ...state,
              history: [...state.history, action.payload]
            };
          }
        case CUSTOMERS.ADD_HISTORY_FILE: {
            return {
              ...state,
              history: [...state.history, ...action.payload]
            };
        }
        case CUSTOMERS.CHANGE_CUSTOMER_HISTORY_TAB: {
            return {
                ...state,
                activeTabHistory: action.payload
            };
        }
        case CUSTOMERS.SET_CUSTOMER: {
            return {
                ...state,
                customer: action.payload
            };
        }
        case CUSTOMERS.SET_LOADING_CUSTOMERS_STATUSES: {
            return {
                ...state,
                isLoadingCustomersStatuses: action.payload
            }
        }
        case CUSTOMERS.SET_CUSTOMERS_STATUSES: {
            return {
                ...state,
                statuses: action.payload,
            }
        }
        case CUSTOMERS.IS_LOADING_CUSTOMER: {
            return {
                ...state,
                isLoadingCustomer: action.payload
            };
        }
        case CUSTOMERS.CLEAR_CUSTOMER: {
            return {
                ...state,
                customer: {...initialValues.customer}
            };
        }
        case CUSTOMERS.SET_CUSTOMER_HISTORY: {
            return {
                ...state,
                history: action.payload
            };
        }
        case CUSTOMERS.IS_LOADING_COUNTRIES: {
            return {
                ...state,
                isLoadingCountries: action.payload,
            }
        }
        case CUSTOMERS.SET_COUNTRIES: {
            return {
                ...state,
                countries: action.payload,
            }
        }
        case CUSTOMERS.SET_CUSTOMERS_RESPONSIBLE: {
            return {
                ...state,
                customersResponsible: action.payload
            }
        }
        case CUSTOMERS.SET_CUSTOMERS_STATUS: {
            return {
                ...state,
                customersStatus: action.payload
            }
        }
        default: {
            return state;
        }
    }
};
