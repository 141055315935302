import React , { PureComponent } from 'react'
import { ReactComponent as ArrowDownIcon } from '../../../../../../assets/images/arrow-border-bottom.svg'

class WorkPlacesDropdown extends PureComponent{

    state = {
        open: false
    }

    listRef = React.createRef()

    handleClickOutside = (event) => {
        if (this.listRef.current && !this.listRef.current.contains(event.target)) {
            this.setState({
                open: false
            })
        }
    }

    componentDidUpdate(){
        document.addEventListener('click',this.handleClickOutside)
    }

    handleOpenHideList = () => {
        this.setState(state => ({
            open: !state.open
        }))
    }

    render(){
        const { open } = this.state
        const { workPlaces,activeWorkPlace,setActiveWorkPlace } = this.props
        return <div className={`wrapper-workplace-list ${open ? 'open' : ''} ${!activeWorkPlace ? 'empty-workplace' : ''}`} ref={this.listRef}>
            <span className="name-active-workplace" onClick={this.handleOpenHideList}>
                <ArrowDownIcon className="standart-svg"/>
                {activeWorkPlace ? activeWorkPlace.name : 'Select workplace'}

            </span>
            <ul>
                {workPlaces.map(workPlace => {
                    const isActive = activeWorkPlace && activeWorkPlace.id === workPlace.id
                    return <li
                        onClick={() => {
                            this.handleOpenHideList()
                            setActiveWorkPlace(workPlace)
                        }}
                        key={workPlace.id}
                        className={isActive ? 'active' : ''}
                    >
                        {workPlace.name}
                    </li>
                })}
            </ul>
        </div>
    }
}

export default WorkPlacesDropdown