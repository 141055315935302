import adminInstance from '../components/instances/admininstance'
import { url } from '../constants'

const getPlans = (params) => {
  return adminInstance.get(`${url}/plan`, {
    params
  })
}

const getPlanById = (planId) => {
  return adminInstance.get(`${url}/plan/${planId}`)
}

const createPlan = () => {
  return adminInstance.post(`${url}/plan`)
}

const updatePlan = (planId) => {
  return adminInstance.put(`${url}/plan/${planId}`)
}

const removePlan = (planId) => {
  return adminInstance.delete(`${url}/plan/${planId}`)
}

const changePalletLocation = (palletId, locationId) => {
  return adminInstance.put(`${url}/plan/change/pallet/${palletId}/location`, null, {
    params: {
      locationId
    }
  })
}
export const PlanAPI = {
  getPlans,
  getPlanById,
  createPlan,
  updatePlan,
  removePlan,
  changePalletLocation
}