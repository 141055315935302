import React, { PureComponent } from 'react'
import {ToastContainer,toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import './toast.scss'

export default class Toast extends PureComponent{

    render(){
        return(
                <ToastContainer
                    position="top-right"
                    hideProgressBar={true}
                    newestOnTop={true}
                    className="toast-container"
                    toastClassName = 'my-toast'
                    autoClose={4000}
                    closeButton = {false}
                    {...this.props}
                />
        )
    }
}

export const notify = (type = 'error',msg = 'Ошибка') => {
    switch (type) {
        case 'info':
            toast.info(msg);
            break;
        case 'success':
            toast.success(msg);
            break;
        case 'warning':
            toast.warn(msg);
            break;
        case 'error':
            toast.error(msg);
            break;
        default:
            break;
    }
}
