import React, {PureComponent} from 'react'
import {fields, input, select, price, integer} from '../conditions'
import Select from '../../../../../reuseComponent/Select/select-form'
import {Form} from 'react-bootstrap'
import {formatInteger, formatPrice} from '../../../../../../reuseFunctions/formatValue'
import Tooltip from '../../../../../reuseComponent/Tooltips'


const fieldsOptions = Object.keys(fields).map(key => ({
    value: key,
    label: fields[key].labelField
}))

const checkIfInvalid = (errors, touched, index, field) => {
    return errors.conditions && errors.conditions[index] && errors.conditions[index][field]
        && touched.conditions && touched.conditions[index] && touched.conditions[index][field]
}

const getErrorText = (errors, touched, index, field) => {
    return checkIfInvalid(errors, touched, index, field) ? errors.conditions[index][field] : ''
}

class ConditionRow extends PureComponent {
    render() {
        const {
            condition,
            index,
            removeCondition,
            setFieldValue,
            errors,
            touched
        } = this.props
        const field = condition.field ? fields[condition.field] : null
        const separatorOptions = condition.field && field ? field.conditions.map(condition => ({
            label: condition.label,
            value: condition.label,
            withValue: condition.withValue
        })) : null
        return (
            <div className="condition-row">
                <div className="condition-row-cell">
                    <Form.Group>
                        <Form.Label>Field</Form.Label>
                        <Select
                            isInvalid={checkIfInvalid(errors, touched, index, 'field')}
                            errorLabel={getErrorText(errors, touched, index, 'field')}
                            name={`conditions[${index}].field`}
                            options={fieldsOptions}
                            value={condition.field ? fieldsOptions.find(option => option.value === condition.field) : null}
                            onChange={obj => {
                                const field = fields[obj.value]
                                const isCriteria = field.conditions.find(condition => condition.label === 'is')
                                // console.log(isCriteria)
                                setFieldValue(`conditions[${index}].field`, obj.value)
                                setFieldValue(`conditions[${index}].separator`, field.typeValue === select ? {
                                    label: field.conditions[0].label,
                                    value: field.conditions[0].label,
                                    withValue: field.conditions[0].withValue
                                } : isCriteria ? {
                                    label: isCriteria.label,
                                    value: isCriteria.label,
                                    withValue: isCriteria.withValue
                                } : '')
                                setFieldValue(`conditions[${index}].value`, '')
                            }}
                        />
                    </Form.Group>
                </div>
                <div className="condition-row-cell">
                    {condition.field && separatorOptions && <Form.Group>
                        <Form.Label>Condition</Form.Label>
                        <Select
                            isInvalid={checkIfInvalid(errors, touched, index, 'separator')}
                            errorLabel={getErrorText(errors, touched, index, 'separator')}
                            name={`conditions[${index}].separator`}
                            options={separatorOptions}
                            value={condition.separator
                                ? separatorOptions.find(option => option.value === condition.separator.value)
                                : null
                            }
                            onChange={obj => {
                                setFieldValue(`conditions[${index}].separator`, obj)
                                setFieldValue(`conditions[${index}].value`, '')
                            }}
                        />
                    </Form.Group>
                    }
                </div>
                <div className="condition-row-cell">
                    {condition.field && condition.separator && condition.separator.withValue && field && <Form.Group>
                        <Form.Label>Value</Form.Label>
                        {field.typeValue === input
                        && <>
                            <Form.Control
                                value={condition.value}
                                name={`conditions[${index}].value`}
                                isInvalid={checkIfInvalid(errors, touched, index, 'value')}
                                onChange={e => setFieldValue(`conditions[${index}].value`, e.target.value)}
                            />
                            <Form.Control.Feedback
                                type="invalid">{getErrorText(errors, touched, index, 'value')}</Form.Control.Feedback>
                        </>}
                        {field.typeValue === integer
                        && <>
                            <Form.Control
                                value={condition.value}
                                name={`conditions[${index}].value`}
                                isInvalid={checkIfInvalid(errors, touched, index, 'value')}
                                onChange={e => setFieldValue(`conditions[${index}].value`, e.target.value)}
                                onBlur={e => setFieldValue(`conditions[${index}].value`, formatInteger(e.target.value))}
                            />
                            <Form.Control.Feedback
                                type="invalid">{getErrorText(errors, touched, index, 'value')}</Form.Control.Feedback>
                        </>}
                        {field.typeValue === price
                        && <>
                            <Form.Control
                                value={condition.value}
                                name={`conditions[${index}].value`}
                                isInvalid={checkIfInvalid(errors, touched, index, 'value')}
                                onChange={e => setFieldValue(`conditions[${index}].value`, e.target.value)}
                                onBlur={e => setFieldValue(`conditions[${index}].value`, formatPrice(e.target.value))}
                            />
                            <Form.Control.Feedback
                                type="invalid">{getErrorText(errors, touched, index, 'value')}</Form.Control.Feedback>
                        </>}
                        {field.typeValue === select
                        &&
                        <Select
                            value={{
                                value: condition.value,
                                label: condition.value
                            }}
                            isInvalid={checkIfInvalid(errors, touched, index, 'value')}
                            errorLabel={getErrorText(errors, touched, index, 'value')}
                            name={`conditions[${index}].value`}
                            options={this.props[condition.field] ? this.props[condition.field].map(item => ({
                                value: item,
                                label: item
                            })) : []}
                            onChange={obj => setFieldValue(`conditions[${index}].value`, obj.value)}
                        />
                        }
                    </Form.Group>
                    }
                </div>
                <div className="condition-row-cell actions-cell">
                    {index !== 0 && <Tooltip
                        text="Delete condition"
                    >
                        <button onClick={removeCondition} className="remove-condition-row-button">
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000"
                                      d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
                            </svg>
                        </button>
                    </Tooltip>
                    }
                </div>

            </div>
        )
    }
}

export default ConditionRow