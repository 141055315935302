import React , { PureComponent } from 'react'
import Modal from '../../../reuseComponent/modal'
import HistoryAction from './history-action'
import DiffModal from './diff-modal-content'
import {ReactComponent as ChronologyIcon} from "../../../../assets/images/chronology.svg";
import Comment from './comment-editor/comment-editor'
import UploadFile from "../../serviceDesk/history/upload-file";
import HistorList from "./list";
import Tabs from "./tabs";
import {getActiveInboxAction, getTasksAction, setHistory} from "../../../../actions/tasks";
import {connect} from "react-redux";
import {getRolesAct} from "../../../../actions/roles";
import {getCurrentUser, refreshRequestUser, setUsersCustomersCount} from "../../../../actions/users";

class HistoryComponent extends PureComponent{

    state = {
        modalDiff: false,
        currDiff: null
    }

    handleShowDiff = (diff) => this.setState({modalDiff: true, currDiff: diff})

    handleCloseDiff = () => this.setState({modalDiff: false, currDiff: null})

    componentDidMount() {
        this.props.setHistoryList(this.props.history);
    }

    render(){
        var { history } = this.props
        const { modalDiff, currDiff } = this.state
        return (
            <div className="history-task-wrapper">

                <div className="history-block">
                    <div className="title-history">
                        <Tabs />
                    </div>
                    <HistorList history={ history }/>
                </div>

                <Modal
                    open={modalDiff}
                    Content={() => <DiffModal
                        diff={currDiff}
                        handleClose={this.handleCloseDiff}
                    />}
                    handleClose={this.handleCloseDiff}
                    isFooter={false}
                    options={{
                        centered: true
                    }}
                />

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setHistoryList: history => dispatch(setHistory(history))
    }
}

export default connect(null, mapDispatchToProps)(HistoryComponent)