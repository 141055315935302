import React, { PureComponent } from 'react'
import { Switch } from 'react-router-dom'
import ListRoles from './list'
import ProtectedRoute from '../../reuseComponent/Routes/protected-route'
import {permEditRoles, permViewRoles} from "../../../permissions/settings";

class RolesModule extends PureComponent{
    render(){
        return (
                <Switch>
                    <ProtectedRoute exact path="/atw/settings/roles" permission={[permViewRoles, permEditRoles]} component={ListRoles} />
                </Switch>
        )
    }
}

export default RolesModule