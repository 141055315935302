import * as yup from 'yup'

export const schema = yup.object().shape({
    availability: yup.string().required('required field'),
    comment: yup.string(),
    deliveryTime: yup.string(),
    file: yup.mixed()
        .typeError('must be image')
        .required('required field'),
    moq: yup.number()
        .required('required field')
        .typeError('must be positive digits')
        .integer('must be positive digits')
        .positive('must be positive digits'),
    palletsIds: yup.array(),
    price: yup.number()
        .required('required field')
        .typeError('must be positive digits')
        .positive('must be positive digits'),
    title: yup.string().required('required field'),
    unit: yup.string().required('required field'),
    viewsCount: yup.number()
        .required('required field')
        .typeError('must be positive digits')
        .integer('must be positive digits')
        .positive('must be positive digits'),
})