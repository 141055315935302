import React, { Component } from "react";
import { connect } from "react-redux";
import Tooltip from "../../../../../../reuseComponent/Tooltips/index";
import { Button } from "react-bootstrap";
import { notify } from "../../../../../../reuseComponent/toast/index";
import { convertToFormDataWithSingleArray } from "../../../../../../../reuseFunctions/objectToFormData";
import adminInstance from "../../../../../../instances/admininstance";
import { url } from "../../../../../../../constants";
import { findPermInArray } from "../../../../../../../reuseFunctions/checkPermission";
import { permComposeDeliveryNotes } from "../../../../../../../permissions/productOverview";
import { getHistory, addDeliveryNotes, removeDeliveryNotes } from "../../../../../../../actions/purchase-orders";
import CustomModalWithChildren from "../../../../../../reuseComponent/modal/modalWithChildren";
import {ConfirmModalContent} from "../../../../../../reuseComponent/confirmModalComponent";

import "./style.scss";

const _validFileExtensions = [".pdf", ".jpg", ".tiff", ".xls", ".doc", ".docx"];

const validateTypeFile = (files, key = "format") => {
  let isValidFiles = true;
  files.forEach(file => {
    const fileName = file.name;
    const fileSize = file.size / 1024 / 1024;
    let isValidTypeFile = false;
    _validFileExtensions.forEach(type => {
      if (key === "format") {
        if (fileName.indexOf(type) >= 0) {
          isValidTypeFile = true;
        }
      }
      if (key === "size") {
        if (fileSize <= 2) {
          isValidTypeFile = true;
        }
      }
    });
    if (!isValidTypeFile) isValidFiles = false;
  });

  return isValidFiles;
};

class TransportFiles extends Component {
  state = {
    isOpenDropdown: false,
    fileToRemove: null,
    modalRemove: false,
    files: []
  };
  fileRef = React.createRef();
  dropdownWrapperRef = React.createRef();

  handleDeleteFile = id => {
    adminInstance
      .delete(`${url}/purchase_order/${id}/file`)
      .then(resp => {
        notify("success", "Delivery note successfully deleted");
        this.props.removeDeliveryNotes(id)
        this.setState({
          modalRemove: false
        })
        this.closeList();
        this.props.getHistory();
      })
      .catch(err => {
        notify("error", "Failed to delete delivery note");
      });
  };

  handleAddTransportNote = files => {
    const { details, addDeliveryNotes } = this.props;
    if (!validateTypeFile(files, "format")) {
      notify("error", "Invalid file format");
    } else if (!validateTypeFile(files, "size")) {
      notify("error", "File is more than 2MB");
    } else {
      adminInstance
        .post(
          `${url}/purchase_order/${details.id}/add_transport_note`,
          convertToFormDataWithSingleArray(
            {
              files,
              poUrl: window.location.origin + "/atw/purchase-orders"
            },
            true
          )
        )
        .then(resp => {
          notify(
            "success",
            `Delivery ${
              details.transportFile.length > 1 ? "notes" : "note"
            } successfully attached`
          );
          this.props.getHistory();
          const formatedFiles = resp.data.map(item => ({id: item.file.id, realName: item.file.realName, fileUrl: item.file.fileUrl}))
          addDeliveryNotes(formatedFiles)
        })
        .catch(err => {
          notify("error", "Failed to add delivery note");
        });
    }
  };

  closeList = () => this.setState({ isOpenDropdown: false });

  handleCloseDropdown = e => {
    if (
      this.dropdownWrapperRef.current &&
      !this.dropdownWrapperRef.current.contains(e.target)
    ) {
      this.closeList();
    }
  };

  handleOpenDropdown = () => this.setState({ isOpenDropdown: true });

  componentDidMount() {
    document.addEventListener("click", this.handleCloseDropdown);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleCloseDropdown);
  }

  render() {
    const { isOpenDropdown, modalRemove, fileToRemove } = this.state;
    const { details, roles } = this.props;
    return (
      <div ref={this.dropdownWrapperRef}>
        {(!details.transportCompleted || details.transportFile.length > 0) && (findPermInArray(roles, permComposeDeliveryNotes) || details.transportFile.length > 0) && (
          <Button style={{display: "none"}}
            type="button"
            onClick={this.handleOpenDropdown}
            className="with-icon"
          >
            <svg
              className="add-file"
              enableBackground="new 0 0 512 512"
              height="24"
              viewBox="0 0 512 512"
              width="24"
            >
              <path d="m226 512c-49.626 0-90-40.374-90-90v-302c0-66.168 53.832-120 120-120s120 53.832 120 120v302h-30v-302c0-49.626-40.374-90-90-90s-90 40.374-90 90v302c0 33.084 26.916 60 60 60s60-26.916 60-60v-242c0-16.542-13.458-30-30-30s-30 13.458-30 30v242h-30v-242c0-33.084 26.916-60 60-60s60 26.916 60 60v242c0 49.626-40.374 90-90 90z" />
            </svg>
            Delivery note ({details.transportFile.length})
          </Button>
        )}

        {isOpenDropdown && (
          <ul
            className={`files-list ${isOpenDropdown ? "open" : ""}`}
            ref={this.dropdownRef}
          >
            {details.transportFile.map(item => (
              <li key={item.id}>
                <a href={item.fileUrl} onClick={this.closeList}>
                  <svg className="file-icon" viewBox="0 0 24 24">
                    <path
                      fill="#B0BEC5"
                      d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z"
                    />
                  </svg>
                  <span>{item.realName}</span>
                </a>
                {!details.transportCompleted &&
                  findPermInArray(roles, permComposeDeliveryNotes) && (
                    <Tooltip text="Delete">
                      <svg
                        className="icon"
                        onClick={() => {
                          this.setState({
                            modalRemove: true,
                            fileToRemove: {
                              id: item.id,
                              name: item.realName
                            }
                          })
                        }}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#B0BEC5"
                          d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                        />
                      </svg>
                    </Tooltip>
                  )}
              </li>
            ))}
            {!details.transportCompleted &&
              findPermInArray(roles, permComposeDeliveryNotes) && (
                <Tooltip text="Supported file formats .pdf, .jpg, .tiff, .xls, .doc, .docx">
                  <li
                    className={`add-new-files ${details.transportFile.length === 0 ? 'without-border' : ''}`}
                    onClick={() => this.fileRef.current.click()}
                  >
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="#000000"
                        d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z"
                      />
                    </svg>
                    <input
                      multiple
                      ref={this.fileRef}
                      type="file"
                      className="file-input"
                      onChange={e => {
                        const formattedFiles = Object.values(e.target.files);
                        this.handleAddTransportNote(formattedFiles);
                        this.closeList();
                      }}
                    />
                    Attach files
                  </li>
                </Tooltip>
              )}
          </ul>
        )}
        <CustomModalWithChildren
            open={modalRemove}
            handleClose={() => this.setState({
              modalRemove: false
            })}
            handleCancel={() => this.setState({
              modalRemove: false
            })}
            handleConfirm={() => {
              this.handleDeleteFile(fileToRemove.id)
            }}
            options={{
              centered: true
            }}
        >
          <ConfirmModalContent text={`delete "${fileToRemove ? fileToRemove.name : ''}" Delivery note`}/>
        </CustomModalWithChildren>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    details: state.purchaseOrdersReducer.detailsPurchase,
    roles: state.rolesReducer.roles
  };
};

export default connect(mapStateToProps, { getHistory, addDeliveryNotes, removeDeliveryNotes })(TransportFiles);
