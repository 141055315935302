import React , { PureComponent } from 'react'
import { Switch} from 'react-router-dom'
import List from './list'
// import Form from './createEdit'
import ProtectedRoute from "../../reuseComponent/Routes/protected-route";

import {permViewSuppliers, permEditSuppliers} from "../../../permissions/suppliers";

class SuppliersModule extends PureComponent{
    render(){
        return (
            <Switch>
                <ProtectedRoute permission={[
                    permViewSuppliers,
                    permEditSuppliers
                ]} exact path={["/atw/suppliers/:id" , "/atw/suppliers"]} component={List} />
                {/*<ProtectedRoute permission={permEditSuppliers} exact path="/atw/suppliers/create" component={Form} />*/}
                {/*<ProtectedRoute permission={permEditSuppliers} exact path="/atw/suppliers/edit/:id" component={Form} />*/}
            </Switch>
        )
    }
}

export default SuppliersModule