import { TYPES } from '../components/admin/inventory/InventoryMap/constants'

const letterToNumber = new Map([
  ['A', 1],
  ['B', 2],
  ['C', 3],
  ['D', 4],
  ['E', 5],
  ['F', 6],
  ['G', 7],
  ['H', 8],
  ['I', 9],
  ['J', 10],
  ['K', 11],
  ['L', 12],
  ['M', 13],
  ['N', 14],
  ['O', 15],
  ['P', 16],
  ['Q', 17],
  ['R', 18],
  ['S', 19],
  ['T', 20],
  ['U', 21],
  ['V', 22],
  ['W', 23],
  ['X', 24],
  ['Y', 25],
  ['Z', 26],
])

const numberToLetter = new Map([
  [1, 'A'],
  [2, 'B'],
  [3, 'C'],
  [4, 'D'],
  [5, 'E'],
  [6, 'F'],
  [7, 'G'],
  [8, 'H'],
  [9, 'I'],
  [10, 'J'],
  [11, 'K'],
  [12, 'L'],
  [13, 'M'],
  [14, 'N'],
  [15, 'O'],
  [16, 'P'],
  [17, 'Q'],
  [18, 'R'],
  [19, 'S'],
  [20, 'T'],
  [21, 'U'],
  [22, 'V'],
  [23, 'W'],
  [24, 'X'],
  [25, 'Y'],
  [26, 'Z'],
])

const maxNumber = 26;

export const getNumberByLetter = (letter) => {
  // let letters = letter.split('')
  // return letterToNumber.get(letters[1]) + (letterToNumber.get(letters[0])-1)*26
  return letter
    .split('')
    .map(item => letterToNumber.get(item))
    .reverse()
    .reduce((sum, current, index) => sum + current*Math.pow(maxNumber, index), 0)
}

export const getLetterByNumber = (number) => {
  const numb = parseInt(number)
  if(numb < 27){
    return  `${numberToLetter.get(numb)}`
  }else{
    const first = Math.floor((numb-1)/26)
    const second = numb % 26 ? numb % 26 : 26
    return `${numberToLetter.get(first)}${numberToLetter.get(second)}`
  }
}

const groupCoordinateRegExp = new RegExp(/^[A-Z]+[0-9]+:[A-Z]+[0-9]+$/, 'g')
const coordinateRegExp = new RegExp(/^[A-Z]+[0-9]+$/, 'g')
const letterRegExp = new RegExp(/^[A-Z]+/, 'g')
const numberRegExp = new RegExp(/[0-9]+$/, 'g')

const formatCoordinateToSend = (coord, maxX, maxY) => {
  let x = coord.match(letterRegExp)[0];
  let y = parseInt(coord.match(numberRegExp)[0], 10);

  if(y > maxY || x > maxX || x < 1 || y < 1){
    throw new Error('Coordinates out of map range')
  }
  return `${getNumberByLetter(x)}-${y}`;
};

export const formatCoordinateToView = (coord, maxX, maxY) => {
  let arr = coord.split('-')
  const x = getLetterByNumber(parseInt(arr[0]))
  const y = parseInt(arr[1])

  return `${x}${y}`
}

export const formatCoordinatesToSend = (coordinates, maxX, maxY) => {

  let newStr = coordinates.map(item => {
    if(item.match(groupCoordinateRegExp)){
      // console.log('group', item)
      let arr = item.split(':')
      const first = formatCoordinateToSend(arr[0], maxX, maxY);
      const second = formatCoordinateToSend(arr[1], maxX, maxY);
      const x1 = getNumberByLetter(arr[0].match(letterRegExp)[0]);
      const y1 = parseInt(arr[0].match(numberRegExp)[0], 10) + 1;
      const x2 = getNumberByLetter(arr[1].match(letterRegExp)[0]);
      const y2 = parseInt(arr[1].match(numberRegExp)[0], 10) + 1;

      if(x2 < x1 || y2 < y1){
        throw new Error('Invalid coordinates format')
      }

      return `${first}:${second}`
    }else if(item.match(coordinateRegExp)){
      return formatCoordinateToSend(item, maxX, maxY)
    }else{
      throw new Error('Invalid coordinates format')
    }
    return item
  }).join(',')

  return newStr;
}

export const formatCoordinatesToView = (coordinatesString, maxX, maxY) => {
  const coordinates = coordinatesString.split(',')

  return coordinates.map(item => {
    if(item.indexOf(':') !== -1){
      let arr = item.split(':')
      return `${formatCoordinateToView(arr[0], maxX, maxY)}:${formatCoordinateToView(arr[1], maxX, maxY)}`
    }else{
      return formatCoordinateToView(item, maxX, maxY)
    }
  })
}

export const generateCoordinates = (mX, mY) => {
  let arrX = [];
  let arrX1 = [];
  let arrY = [];
  let arrY1 = [];

  arrX.push({
    id: `00`,
    locationType: {
      id: 'Y',
      color: '#f2f2f2',
      slot: false,
    },
    coordinateX: 0,
    coordinateY: 0,
    pallet: null,
    type: TYPES.EMPTY_LEGEND,
  })

  arrX1.push({
    id: `0Max`,
    locationType: {
      id: 'Y',
      color: '#f2f2f2',
      slot: false,
    },
    coordinateX: 0,
    coordinateY: 0,
    pallet: null,
    type: TYPES.EMPTY_LEGEND,
  })

  for(let i = 1; i <= mX; i++){
    arrX.push({
      id: `XT${i}`,
      locationType: {
        id: 'X',
        color: '#f2f2f2',
        slot: false,
      },
      coordinateX: i,
      coordinateY: 0,
      pallet: null,
      type: TYPES.COORD_X,
      text: getLetterByNumber(i)
    })
    arrX1.push({
      id: `XB${i}`,
      locationType: {
        id: 'X',
        color: '#f2f2f2',
        slot: false,
      },
      coordinateX: i,
      coordinateY: 0,
      pallet: null,
      type: TYPES.COORD_X,
      text: getLetterByNumber(i)
    })
  }

  arrX.push({
    id: `Max0`,
    locationType: {
      id: 'Y',
      color: '#f2f2f2',
      slot: false,
    },
    coordinateX: mX+1,
    coordinateY: 0,
    pallet: null,
    type: TYPES.EMPTY_LEGEND,
  })

  arrX1.push({
    id: `MaxMax`,
    locationType: {
      id: 'Y',
      color: '#f2f2f2',
      slot: false,
    },
    coordinateX: mX+1,
    coordinateY: 0,
    pallet: null,
    type: TYPES.EMPTY_LEGEND,
  })

  for(let i = 1; i <= mY; i ++){
    arrY.push({
      id: `YT${i}`,
      locationType: {
        id: 'Y',
        color: '#f2f2f2',
        slot: false,
      },
      coordinateX: 0,
      coordinateY: i-1,
      pallet: null,
      type: TYPES.COORD_Y,
      text: i
    })
    arrY1.push({
      id: `YB${i}`,
      locationType: {
        id: 'Y',
        color: '#f2f2f2',
        slot: false,
      },
      coordinateX: 0,
      coordinateY: i - 1,
      pallet: null,
      type: TYPES.COORD_Y,
      text: i
    })
  }

  return ({
    top: arrX,
    bottom: arrX1,
    left: arrY,
    right: arrY1,
  })

  //   {
  //     id: `00`,
  //     locationType: {
  //       id: 'Y',
  //       color: '#f2f2f2',
  //       slot: false,
  //     },
  //     coordinateX: 0,
  //     coordinateY: 0,
  //     pallet: null,
  //     type: TYPES.EMPTY_LEGEND,
  //   },
  //   {
  //     id: `0Max`,
  //     locationType: {
  //       id: 'Y',
  //       color: '#f2f2f2',
  //       slot: false,
  //     },
  //     coordinateX: 0,
  //     coordinateY: mY+1,
  //     pallet: null,
  //     type: TYPES.EMPTY_LEGEND,
  //   },
  //   {
  //     id: `Max0`,
  //     locationType: {
  //       id: 'Y',
  //       color: '#f2f2f2',
  //       slot: false,
  //     },
  //     coordinateX: mX+1,
  //     coordinateY: 0,
  //     pallet: null,
  //     type: TYPES.EMPTY_LEGEND,
  //   },
  //   {
  //     id: `MaxMax`,
  //     locationType: {
  //       id: 'Y',
  //       color: '#f2f2f2',
  //       slot: false,
  //     },
  //     coordinateX: mX+1,
  //     coordinateY: mY+1,
  //     pallet: null,
  //     type: TYPES.EMPTY_LEGEND,
  //   }
  // ])
}