import React, { useState, useCallback, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {connect} from 'react-redux';
import Preloader from '../../../../../reuseComponent/preloader/preloader'
import BoxItem from './boxItem'
import EditProductForm from '../../scanComponent/edit'
import Modal from '../../../../../reuseComponent/modal/modalWithChildren'
import {findPermInArray} from '../../../../../../reuseFunctions/checkPermission'
import { permEditInbounding } from '../../../../../../permissions/productOverview'
import { PackItem } from './PackItem'
import { EditBoxForm } from './EditBoxForm'
import { getProductsFromBox } from '../../../../../../services/products';
import {setup} from "../../../../../../actions/printerActions";

const BoxesList = ({boxes, loadingBoxes, details, printerSetup}) => {
    const [isShowModal, setModalState] = useState(false)
    const [activeProduct,setActiveProduct] = useState(null)
    const [box, setBoxId] = useState(null)
    const roles = useSelector(state => state.rolesReducer.roles)
    const [selectedBox, setSelectedBox] = useState(null)
    const [modalBoxCount, setModalBoxCount] = useState(false);
    const [boxesList, setBoxes] = useState([]);

    const activePackage = useSelector(state => state.inboundingReportReducer.activePackage)

    const dispatch = useDispatch()

    const handleShowEditModal = (product, box) => {
        setActiveProduct(product)
        setBoxId(box)
        setModalState(true)
    }

    const handleCloseEditModal = (newValues) => {
        setActiveProduct(null);
        if (newValues && newValues.boxId) {
            getProductsFromBox(newValues.boxId).then(res => {
                setBoxes(res.data);
            });
        } else {
            setBoxId(null);
        }
        setModalState(false)
    }

    const handleShowModalBoxCount = useCallback((box) => {
        setSelectedBox(box)
        setModalBoxCount(true)
    }, [])

    const handleCloseModalBoxCount = useCallback(() => {
        setModalBoxCount(false)
        setSelectedBox(null)
    }, [])

    useEffect(() => {
        setBoxes(boxes);
    }, [boxes])

    useEffect(() => {
        printerSetup && printerSetup();
    }, [printerSetup]);

    return (
        <div className="boxes__wrapper">
            {
                loadingBoxes ? 
                    <Preloader/> : 
                        <>
                            {
                                boxesList.length > 0 ?
                                    boxesList.map(box => (details.inboundType === 'Totals' || !!activePackage.total) ? 
                                        <PackItem
                                            box={box}
                                            key={box.id}
                                            openEditModal={handleShowModalBoxCount}
                                        /> : 
                                        <BoxItem
                                            handleShowEditModal={handleShowEditModal}
                                            key={box.id}
                                            box={box}
                                        />) : (
                                        activePackage && Object.keys(activePackage).length > 0 ? 
                                            <h5 className="no-articles">No articles added...</h5> : 
                                            null
                                        )
                            }
                        </>
            }
            {findPermInArray(roles, permEditInbounding) && <Modal
                open={isShowModal}
                backdropClassName="edit-product-modal-backdrop"
                isFooter={false}
                options={{
                    centered: true,
                    animation: false
                }}
            >
                <EditProductForm
                    box={box}
                    product={activeProduct}
                    handleClose={(newValues) => handleCloseEditModal(newValues)}
                />
            </Modal>}
            <Modal
              open={modalBoxCount}
              handleClose={handleCloseModalBoxCount}
              isFooter={false}
            >
                <EditBoxForm
                  box={selectedBox}
                  handleCancel={handleCloseModalBoxCount}
                />
            </Modal>
        </div>
    )
};

const mapStateToProps = state => ({
    loadingBoxes: state.inboundingReportReducer.loadingBoxes,
    boxes: state.inboundingReportReducer.boxes,
    details: state.purchaseOrdersReducer.detailsPurchase,
})

const mapDispatchToProps = dispatch => ({
    printerSetup: () => dispatch(setup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BoxesList);