import React , { PureComponent } from 'react'
import { Html5Table as WindowTable } from 'window-table'

const CellProduct = ({row,column}) => {
    return row.product[column.key]
}

const columns = [{
    key: 'ean',
    width: 45,
    title: 'EAN',
    Component:CellProduct
},{
    key: 'styleNumber',
    width: 40,
    title: 'SKU',
    Component:CellProduct
},{
    key: 'size',
    width: 20,
    title: 'SIZE',
    Component:CellProduct
},{
    key: 'cg2',
    width: 30,
    title: 'CG2',
    Component:CellProduct
},{
    key: 'cg3',
    width: 40,
    title: 'CG3',
    Component:CellProduct
},{
    key: 'cg4',
    width: 40,
    title: 'CG4',
    Component:CellProduct
},{
    key: 'season',
    width: 40,
    title: 'Season',
    Component:CellProduct
},{
    key: 'brand',
    width: 60,
    title: 'Brand',
    Component:CellProduct
},{
    key: 'rrp',
    width: 30,
    title: 'RRP',
    Component:CellProduct
},{
    key: 'items',
    width: 20,
    title: 'Items',
}]


class RestTable extends PureComponent{
    render(){

        return (
            <WindowTable
                data={this.props.products}
                className="table table-sm"
                columns={columns}
            />
        )
    }
}

export default RestTable