import {  NOTIFICATIONS } from '../constants'


const initState = {
    isLoadingNotification : false,
    isLoadingEvents: false,
    events: [],
    notification: {},
    variables: [],
    editor: {},
}

export const notificationsReducer = (state = initState,action) => {
    const { type,payload } = action
    switch (type) {
        case NOTIFICATIONS.IS_LOADING_NOTIFICATION: {
            return {
                ...state,
                isLoadingNotification: payload
            }
        }
        case NOTIFICATIONS.IS_LOADING_EVENTS: {
            return {
                ...state,
                isLoadingEvents: payload
            }
        }
        case NOTIFICATIONS.SET_NOTIFICATION: {
            return {
                ...state,
                notification: payload
            }
        }
        case NOTIFICATIONS.SET_EVENTS: {
            return {
                ...state,
                events: payload
            }
        }
        case NOTIFICATIONS.SET_VARIABLES: {
            return {
                ...state,
                variables: payload
            }
        }
        case NOTIFICATIONS.SET_EDITOR: {
            return {
                ...state,
                editor: payload,
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}