import { PO_DETAILS } from '../constants'

let id = 0

const initState = {
    modalCreateBid: false,
    modalEditBid: false,
    modalRemoveBid: false,
    bidToEdit: null,
    isEditMain: false,
    isLoadingBidsValues: false,
    isEditInbound: false,
    unconfirmedBids: [],
    inboundReport: {
        over: [],
        unordered: [],
        ordered: [],
        under: []
    },
    valuesOptions: {

    }

}

export const poDetailsReducer = (state = initState,action) => {
    const { type,payload } = action
    switch(type){
        case PO_DETAILS.SET_INBOUND_INFO: {
            return {
                ...state,
                inboundReport: payload
            }
        }
        case PO_DETAILS.IS_EDIT_INBOUND:{
            return {
                ...state,
                isEditInbound: payload
            }
        }
        case PO_DETAILS.REMOVE_UNCONFIRMED_BID: {
            return {
                ...state,
                unconfirmedBids: state.unconfirmedBids.filter(bid => parseInt(bid.id) !== parseInt(payload))
            }
        }
        case PO_DETAILS.ADD_UNCONFIRMED_BID: {
            id += 1
            return {
                ...state,
                unconfirmedBids: state.unconfirmedBids.concat({
                    id: id
                })
            }
        }
        case PO_DETAILS.CHANGE_BIDS_VALUES_FORM: {
            return {
                ...state,
                valuesOptions: {
                    ...payload
                }
            }
        }
        case PO_DETAILS.IS_LOADING_BIDS_INFO: {
            return {
                ...state,
                isLoadingBidsValues: payload
            }
        }
        case PO_DETAILS.DPO_EDIT_BIDS: {
            return {
                ...state,
                isEditMain: payload,
                unconfirmedBids: [],
            }
        }
        case PO_DETAILS.DPO_MULTIPLE_CHANGES_STATE: {
            return {
                ...state,
                ...payload
            }
        }
        case PO_DETAILS.RESET_PO_DETAILS_STATE: {
            return {
                ...initState
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}