import React, {PureComponent} from 'react'
import {Button, Card, Form, Row, Col} from 'react-bootstrap'
import { connect } from 'react-redux'

// import NotificationEditorClass from '../../../reuseComponent/CKEDITOR/notificationCKEDITOR'
// import QuillEditor from '../../../reuseComponent/QuillEditor'
// import Editor from '../../../reuseComponent/CKEDITOR'
import SummerNoteEditor from '../../../reuseComponent/SummerNoteEditor'
import Select from "../../../reuseComponent/Select/select-form"
import {getVariables, setEditor} from "../../../../actions/notifications"
import {history} from "../../../../history"
import {FieldArray} from "formik"
import ToolTip from "../../../reuseComponent/Tooltips"


class NotificationFormBody extends PureComponent {

    editor = React.createRef()

    componentDidMount(){
        if(this.props.values.event){
            this.props.getVariables({event: this.props.values.event})
        }
    }

    handleAddVariable = (value) => {
        // console.log(this.editor.current.editor,this.editor.current)
        // console.log(this.editor.current)
        this.editor.current.editor.insertHTML(value)
        this.props.setFieldValue('content', this.editor.current.editor.getContents());
        // const selection = this.editor.current.editor.getSelection(true)
        // if(this.editor.current.editor){
        //     const editor = this.editor.current.editor
        //     editor.insertText(selection.index,value)
        // }


        // this.props.editor.model.change( writer => {
        //     const insertPosition = this.props.editor.model.document.selection.getFirstPosition();
        //
        //     writer.insertText( value, insertPosition );
        // } );
    };

    render() {
        const {
            handleSubmit,
            handleChange,
            errors,
            touched,
            setFieldValue,
            values,
            events,
            variables,
            getVariables,
            initialValues
            // setEditor,
        } = this.props;
        let eventsList = [];

        if(values.eventObj){
            eventsList.push({value: values.eventObj.id,label: values.eventObj.displayName})
        }

        events.forEach(item => {
            eventsList.push({value: item.id, label: item.displayName})
        });

        return (
            <Card>
                <Card.Body className="">
                    <Form onSubmit={handleSubmit} noValidate>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={touched.name && errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                                type="text"
                                name="subject"
                                value={values.subject}
                                onChange={handleChange}
                                isInvalid={touched.subject && errors.subject}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.subject}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Event</Form.Label>
                            <Select
                                name="event"
                                isInvalid={errors.event && touched.event}
                                errorLabel={errors.event}
                                value={eventsList.find(item => item.value === values.event)}
                                onChange={status => {
                                    setFieldValue('event', status.value);
                                    getVariables({event: status.value});
                                }}
                                options={eventsList}
                            />
                            <Form.Control.Feedback type="invalid">{errors.event}</Form.Control.Feedback>
                        </Form.Group>
                        <Row>
                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label>Content</Form.Label>
                                    {/*<Editor*/}
                                        {/*onInit={(editor) => {setEditor(editor)}}*/}
                                        {/*value={values.content}*/}
                                        {/*onChange={(event,editor) => {*/}
                                            {/*setFieldValue('content',editor.getData())*/}
                                        {/*}}*/}
                                        {/*config={{*/}
                                            {/*toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList','fontFamily']*/}
                                        {/*}}*/}
                                    {/*/>*/}
                                    <SummerNoteEditor
                                        ref={this.editor}
                                        value={initialValues.content}
                                        onChange={(value) => {
                                            setFieldValue('content',value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="variables-block">
                                {variables.map(item => <Button
                                    key={item.id}
                                    onClick={() => this.handleAddVariable(item.variable)}
                                    variant="primary">{item.displayName}</Button>)}
                            </Col>
                        </Row>
                        <FieldArray
                            name="email"
                            render={arrayHelpers => (
                                <div>
                                    <p className={'form-label'}>Receivers</p>
                                    {values.email && values.email.length > 0 ? (
                                        <>
                                        {values.email.map((item, index) => (
                                            <Form.Group key={index}>
                                                <div className="dynamic-fields">
                                            <Form.Control
                                                type="text"
                                                name={`email.${index}`}
                                                value={values.email[index]}
                                                onChange={handleChange}
                                                isInvalid={touched.email && touched.email[index] && errors.email && errors.email[index]}
                                            />
                                                <ToolTip
                                                    text="Delete"
                                                >
                                                    <svg onClick={() => arrayHelpers.remove(index)}
                                                     style={{width: '20px', height: '20px', cursor: 'pointer'}} viewBox="0 0 24 24">
                                                        <path
                                                        fill="#B0BEC5"
                                                        d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"
                                                        ></path>
                                                    </svg>
                                                </ToolTip>
                                                </div>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email && errors.email[index]}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        ))}
                                        <Button variant="secondary" style={{marginBottom: '10px'}}
                                                onClick={() => arrayHelpers.insert(values.email.length, '')}>Add</Button>
                                        </>
                                    ) : <Button variant="secondary" style={{marginBottom: '10px'}}
                                                onClick={() => arrayHelpers.push('')}>Add</Button>}
                                </div>
                            )}

                        />
                        <Form.Group className="footer-form footer-form-custom">
                            <Button variant="secondary"
                                    onClick={() => history.replace(`/atw/settings/notifications`)}>Cancel</Button>
                            <Button variant="primary" type="submit">Save</Button>        
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        )
    }
};

const mapStateToProps = state => ({
    variables: state.notificationsReducer.variables,
    editor: state.notificationsReducer.editor,
});

const mapDispatchToProps = dispatch => ({
    getVariables: ev => dispatch(getVariables(ev)),
    setEditor: editor => dispatch(setEditor(editor))
});

export default connect(mapStateToProps,mapDispatchToProps)(NotificationFormBody);
