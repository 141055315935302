import React from 'react'
import DateRangePicker from '../../../../reuseComponent/date-range-picker/index'


const DatePickerFilter = props => {
    const {filters,column,filterChange,maxDate} = props
    const activeFilter = filters.find(filter => filter.column.tableData.id === column.tableData.id)
    return (
        <div className="filter-wrap">
            <DateRangePicker
                singleDatePicker={false}
                onApply={(event, picker) => filterChange && filterChange(column.tableData.id, `${picker.startDate.format('DD.MM.YYYY')}-${picker.endDate.format('DD.MM.YYYY')}`)}
                startDate={activeFilter ? activeFilter.value.split('-')[0] : null}
                endDate={activeFilter ? activeFilter.value.split('-')[1] : null}
                options={{
                    maxDate: maxDate
                }}
            >

                <svg viewBox="0 0 24 24">
                    <path fill="#000000" d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"/>
                </svg>
            </DateRangePicker>
        </div>
    )
}

export default DatePickerFilter