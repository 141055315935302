import React from 'react'
import {createBid, addUnconfirmedBid} from '../../../../../../../actions/detailsPo'
import {setDetailsPurchaseAction} from '../../../../../../../actions/purchase-orders'
import {connect} from 'react-redux'
import { findPermInArray } from '../../../../../../../reuseFunctions/checkPermission'
import { permEditInfo } from '../../../../../../../permissions/productOverview'
import {Button} from 'react-bootstrap'

const DividerRow = props => {
    const {addBid, roles, details, migrated} = props;
    return (
        <tr className="divider-row">
            <td style={{
                paddingLeft: 0,
                minWidth: '87px'
            }}>
                {findPermInArray(roles, permEditInfo) &&
                <Button variant="primary"
                        className="button-with-border left-icon info-button"
                        disabled={migrated}
                        onClick={addBid}>
                    <svg viewBox="0 0 24 24">
                        <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                    </svg>
                    Add BID
                </Button>
                }
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    )
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        roles: state.rolesReducer.roles
    }
}

const mapDisaptchToProps = dispatch => {
    return {
        // changeBidState: state => dispatch(changeState(state)),
        createBid: obj => dispatch(createBid(obj)),
        changeDetails: obj => dispatch(setDetailsPurchaseAction(obj)),
        addBid: () => dispatch(addUnconfirmedBid())
    }
}

export default connect(mapStateToProps, mapDisaptchToProps)(DividerRow)