export const permViewCustomers = {
    access: 'view_customers',
    part: 'customers'
};

export const permEditCustomers = {
    access: 'edit_customers',
    part: 'customers'
};

export const permViewAllCustomers = {
    access: 'view_all_customers',
    part: 'customers'
}

export const permRemoveCustomers = {
    access: 'delete_customers',
    part: 'customers'
}

export const permCreateCustomers = {
    access: 'create_customers',
    part: 'customers'
};

export const permExportCustomers = {
    access: 'export_customers',
    part: 'customers'
};

export const permReallocationCustomers = {
    access: 'reallocation_customer',
    part: 'customers'
};
