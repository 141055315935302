import React from 'react'
import {Card} from 'react-bootstrap'
import moment from "moment"
import ToolTip from "../../../reuseComponent/Tooltips"

const ReleaseNotesItem = ({data, handleEdit, handleDelete, handleSend}) => {
    return (
        <Card className='release-card'>
            <Card.Body>
                <p><strong>Version: {data.version}</strong>
                    <ToolTip
                        text="Send"
                    >
                        <svg
                            onClick={handleSend}
                            viewBox="0 0 24 24">
                            <path fill="#616161" d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
                        </svg>
                    </ToolTip>
                    <ToolTip
                        text="Edit"
                    >
                        <svg
                            onClick={handleEdit}
                            viewBox="0 0 24 24">
                            <path fill="#616161" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                        </svg>
                    </ToolTip>
                    <ToolTip
                        text="Delete"
                    >
                        <svg
                            onClick={handleDelete}
                            viewBox="0 0 24 24">
                            <path fill="#616161" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                        </svg>
                    </ToolTip>
                </p>
                <p><strong>Released</strong>: {moment(data.release).format('DD.MM.YYYY HH:mm')}</p>
                <p><strong>Updates: </strong></p>
                <div dangerouslySetInnerHTML={{__html: data.description}}>
                </div>
            </Card.Body>
        </Card>
    )
};

export default ReleaseNotesItem;