import adminInstance from "../components/instances/admininstance";
import {url} from '../constants'

export const getPalletPrintInfo = id =>
    adminInstance.get(`${url}/pallet/${id}/info`);

export const getHistory = (id, type = '') => {
    return adminInstance.get(`${url}/pallet/${id}/history`, {
        params: { type: type }
    });
}

export const getPalletByIdService = id =>
  adminInstance.get(`${url}/pallet/${id}`)

export const changePalletLocation = (id, locationId) => {
    return adminInstance.get(`${url}/pallet/${id}/change-location`, {
        params: {
            locationId
        }
    })
}