import React from 'react'
import {connect} from 'react-redux'
import {Formik} from 'formik'
import BodyForm from './bodyForm'
import { schema } from './schema'


const FormCommodity = props => {

    const {handleSubmit, initialValues} = props
    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={schema}
            component={BodyForm}
        />
    )
}

export default connect(null, null)(FormCommodity)