import React, {PureComponent} from 'react';
import {connect} from 'react-redux'
import EditPallet from '../edit'

class PalletDetails extends PureComponent {

    componentDidMount() {

    }

    render() {
        const {id} = this.props.match.params;
        const {cancelCreate, refetchData} = this.props;
        return (
            <div className="details customer-details">
                <div className="content-details inventory-details">
                    <EditPallet
                        onCancelCreate={cancelCreate}
                        palletId={id}
                        refetchData={refetchData}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps, null)(PalletDetails);
