import React, {useState,useMemo, useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {Collapse} from 'react-bootstrap';
import {withRouter} from 'react-router-dom'

import Tooltip from "../../../../../reuseComponent/Tooltips";
import Preload from "../../../../../reuseComponent/preloader/preloader";
import TableProducts from "./tableProduct";
import {ReactComponent as OpenedBox} from "../.././../../../../assets/images/opened_box.svg";
import {ReactComponent as ClosedBox} from "../.././../../../../assets/images/closed_box.svg";
import {ReactComponent as MenuRigth} from "../.././../../../../assets/images/menu-rigth.svg";
import {ReactComponent as PrinterIcon} from "../.././../../../../assets/images/printer.svg";
import {ReactComponent as Items} from "../.././../../../../assets/images/item.svg"
import {getProducts} from "../../../../../../actions/inboundingReport";
import Modal from "../../../../../reuseComponent/modal/modalWithChildren";
import {closeBox, openBox, getProductsFromBox} from "../../../../../../services/products";
import {notify} from "../../../../../reuseComponent/toast";
import {checkPackageName} from '../../../../../../reuseFunctions/checkPackageName'
import { checkSearchReturnOneResult } from '../countResult'
import {findPermInArray} from '../../../../../../reuseFunctions/checkPermission'
import { permOpenCloseInbounding } from '../../../../../../permissions/productOverview'
import {boxLabel, sendDataToPrinter} from "../../../../../../reuseFunctions/labels";
import {defaultServerError} from "../../../../../../reuseFunctions/toasts";


const BoxItem = ({box, handleShowEditModal, match}) => {

    const [opened, setOpened] = useState(false);
    const [isShowModal, setShowModal] = useState(false);
    const activePackage = useSelector(state => state.inboundingReportReducer.activePackage)
    const details = useSelector(state => state.purchaseOrdersReducer.detailsPurchase)
    const searchedPackages = useSelector(state => state.inboundingReportReducer.searchedPackages)
    const roles = useSelector(state => state.rolesReducer.roles)
    const printer = useSelector(state => state.printersReducer.selected_device)
    const dispatch = useDispatch()

    // получаем список подходящих продуктов
    const productsOfSearchedPackages = useMemo(() => {
        let products = []
        const activeSearchedPackage = searchedPackages.find(sPackage => sPackage.id === activePackage.id)
        if(activeSearchedPackage){
            const activeSearchedBox = activeSearchedPackage.boxes.find(searchedPackBox => searchedPackBox.id === box.id)
            if(activeSearchedBox){
                products = activeSearchedBox.products
                if(checkSearchReturnOneResult(searchedPackages)){
                    setOpened(true)
                    dispatch(getProducts(box.id))
                }

            }

        }
        return products
    },[activePackage,searchedPackages,box.id,dispatch])

    const handleOpenCloseClick = () => {
        if(!opened){
            dispatch(getProducts(box.id))
        }
        setOpened(!opened);
    }

    const closeOpenBox = () => {
        let promise = box.closed ? openBox(box.id) : closeBox(box.id)
        setShowModal(false);
        promise.then(res => {
            if(box.closed){
                notify('success','Box successfully opened')
            }
            else{
                notify('success','Box successfully closed')
            }
        })
    }

    const printBoxLabel = () => {
        getProductsFromBox(box.id)
            .then(res => {
                const products = res.data;
                sendDataToPrinter(printer, boxLabel(box, products))
            })
            .catch(err => {
                console.log(err);
                defaultServerError()
            })
    }
    return (
        <div className="box__row">
            <div className="row__header">
                <div
                    className={`toggle ${opened ? 'opened' : ''}`}
                    onClick={handleOpenCloseClick}
                >
                    <MenuRigth/>
                </div>
                <div
                    className={`info ${productsOfSearchedPackages.length > 0 ? 'is-searched' : ''} ${opened ? 'opened' : ''}`}
                    onClick={handleOpenCloseClick}
                >
                    <div className="left">
                        {box.closed ? <div className="closed-box package-icon">
                            <Tooltip text="Closed box">
                                <ClosedBox/>
                            </Tooltip>
                        </div> : <div className="opened-box package-icon">
                            <Tooltip text="Opened box">
                                <OpenedBox/>
                            </Tooltip>
                        </div>}
                        {checkPackageName(activePackage)} {'>'} {box.workplace ? box.workplace : 'Undefined workplace'} {'>'} <strong className={`${box.closed ? 'closed-box' : ''}`}>Box{box.name}</strong>
                    </div>
                    <div className="right">
                        <button disabled={!printer || !box.closed}
                                className="btn btn-primary"
                                onClick={e => {
                                    e.stopPropagation();
                                    printBoxLabel()
                                }}
                        >

                            <PrinterIcon className="printer__icon"/>
                            Print label
                        </button>
                        {findPermInArray(roles, permOpenCloseInbounding) && <button
                            disabled={details.completeInbound}
                            className="btn btn-primary"
                            onClick={e => {
                                e.stopPropagation()
                                !details.completeInbound && setShowModal(true)
                            }}
                        >
                            {box.closed ? 'Open' : 'Close'} box
                        </button>}
                        <div className="items package-icon">
                            <Tooltip text="Items">
                                <Items/>
                            </Tooltip>
                        </div>
                        <span className="items-count">
                            {box.productsCount}
                        </span>
                    </div>
                </div>
            </div>
            <Collapse in={opened}>
                <div>
                    <div className="row__details">
                        <div className="details__line">
                            <div className="line"></div>
                        </div>
                        <div className="details__table">
                            {box.isLoading ? <Preload/> : <TableProducts
                                searchedProducts={productsOfSearchedPackages}
                                handleShowEditModal={handleShowEditModal}
                                box={box}
                            />}
                        </div>
                    </div>
                </div>
            </Collapse>
            {findPermInArray(roles, permOpenCloseInbounding) && <Modal
                open={isShowModal}
                handleConfirm={closeOpenBox}
                handleCancel={() => setShowModal(false)}
                options={{
                    centered: true
                }}
            >
                <h6 className="center-h">
                    <span role="img" aria-label="think">🤔</span> Sure you want to {box.closed ? 'open' : 'close'} Box-{box.name}?
                </h6>
            </Modal>}
        </div>
    )
}

export default withRouter(BoxItem)