import React, {PureComponent} from 'react'
import {Collapse} from 'react-bootstrap';
import {ReactComponent as MenuRigth} from '../../../../assets/images/menu-rigth.svg'
import {ReactComponent as BoxIcon} from '../../../../assets/images/box.svg'
import {ReactComponent as CircleIcon} from '../../../../assets/images/empty-circle.svg'
import {ReactComponent as CheckCircleIcon} from '../../../../assets/images/check-circle.svg'
import {ReactComponent as ItemIcon} from '../../../../assets/images/item.svg'
import Tooltip from '../../../reuseComponent/Tooltips'
import WorkplaceCollapse from "./workplaceCollapse";
import { inboundMethod } from './choseMethodCreate'


class PackageCollapse extends PureComponent {

    state = {
        open: false,
        workplaces: [],
        boxesForTotal: [],
    }

    //
    groupByWorkplace = (pack) => {

        let res = new Map;

        const noWorkplace = 'Without workplace';

        //group by workplace
        for(let i = 0; i < pack.boxes.length; i++){
            let wr = pack.boxes[i].workplace;
            if(wr){
                if(res.get(wr)){
                    res.set(wr, {
                        workplace: wr,
                        boxes: [...res.get(wr).boxes, pack.boxes[i]]
                    })
                }else{
                    res.set(wr, {
                        workplace: wr,
                        boxes: [pack.boxes[i]]
                    })
                }
            }else{
                if(res.get(noWorkplace)){
                    res.set(noWorkplace, {
                        workplace: noWorkplace,
                        boxes: [...res.get(noWorkplace).boxes, pack.boxes[i]]
                    })
                }else{
                    res.set(noWorkplace, {
                        workplace: noWorkplace,
                        boxes: [pack.boxes[i]]
                    })
                }
            }
        }

        //convert to array
        let resArr = [];

        for(let val of res.values()){
            resArr.push(val)
        }

        resArr = resArr.map(workplace => ({
            ...workplace,
            boxes: workplace.boxes.sort((a,b) => {
                return parseInt(a.name) > parseInt(b.name) ? 1 : -1;
            })
        }))
            .sort((a,b) => {
                return a.workplace > b.workplace ? 1 : -1;
            });

        this.setState({
            workplaces: resArr
        })
    };

    componentDidMount(){
        const { pack } = this.props;
        this.groupByWorkplace(pack)
        if(pack && pack.boxes && pack.boxes.length > 0 && pack.boxes[0] && pack.boxes[0].poInboundType === 'Totals'){
            this.setState({
                boxesForTotal: pack.boxes.sort((a, b) => parseInt(a.name) < parseInt(b.name) ? -1 : 1)
            })
        }
    }

    handleOpenCloseCollapse = () => {
        this.setState(state => ({
            open: !state.open
        }))
    }

    render() {

        const {open, workplaces, boxesForTotal} = this.state
        const {pack, handleChange, boxesId,isPallets, method} = this.props

        return (
            <div className="package__row">
                <div className="row__header" onClick={this.handleOpenCloseCollapse}>
                    <div
                        className={`toggle ${open ? 'opened' : ''}`}
                        // onClick={this.handleOpenCloseCollapse}
                    >
                        <MenuRigth/>
                    </div>
                    <div className={`info__header ${open ? 'opened' : ''}`}>
                        {isPallets && <>
                        {pack.boxes && pack.boxes[0] && pack.boxes[0].poInboundType === 'Totals' ? `${pack.orderNumber} ${pack.orderNumber ? '>'  :''} ${pack.shortName}` :
                        `${pack.orderNumber} ${pack.orderNumber ? '>' : ''} ${pack.shortName} ${pack.fullName ? `(${pack.fullName})` : ''}`
                        }
                        </>
                        }
                        {!isPallets && <>
                            Pallet #{pack.id}
                        </>}
                    </div>
                </div>
                <Collapse in={open}>
                    <div>
                        <div className="row__content">
                            <div className="details__line">
                                <div className="line"></div>
                            </div>
                            {(method !== inboundMethod) ?  <div className="content">
                                  {pack.boxes.length > 0 ? pack.boxes.map(box => {
                                      const isSelected = boxesId.includes(box.id)
                                      return <div
                                        className={`box__wrap ${ isSelected ? 'selected' : ''}`}
                                        key={box.id}
                                        onClick={() => handleChange(box)}
                                      >
                                          {pack.boxes && pack.boxes[0] && pack.boxes[0].poInboundType === 'Totals' ?
                                            <div className="name-box">Pack#{box.name}</div> :
                                          <div className="name-box">{box.pack ? `${box.pack.orderNumber} > ${box.pack.shortName} > ` : null} #{box.id}</div>}
                                          <div className="items-count">
                                              <Tooltip text="Items">
                                                  <ItemIcon className="standart-svg"/>
                                              </Tooltip>
                                              {pack.boxes && pack.boxes[0] && pack.boxes[0].poInboundType === 'Totals' ? box.item : box.productsCount}
                                              <div className="icon-wrap">
                                                  {isSelected
                                                    ? <CheckCircleIcon className="standart-svg"/>
                                                    : <CircleIcon className="standart-svg"/>}
                                              </div>
                                          </div>
                                          <div className="box-bg">
                                              <BoxIcon/>
                                          </div>
                                      </div>
                                  }) : <p className="no-data-to-display">No data to display</p>}
                              </div>
                              : (method === inboundMethod && pack.boxes && pack.boxes[0] && pack.boxes[0].poInboundType === 'Totals' ?
                                <div className="content">
                                    {boxesForTotal.length > 0 ? boxesForTotal.map(box => {
                                        const isSelected = boxesId.includes(box.id)
                                        return <div
                                          className={`box__wrap ${ isSelected ? 'selected' : ''}`}
                                          key={box.id}
                                          onClick={() => handleChange(box)}
                                        >
                                              <div className="name-box">Pack#{box.name}</div>
                                            <div className="items-count">
                                                <Tooltip text="Items">
                                                    <ItemIcon className="standart-svg"/>
                                                </Tooltip>
                                                {box.item}
                                                <div className="icon-wrap">
                                                    {isSelected
                                                      ? <CheckCircleIcon className="standart-svg"/>
                                                      : <CircleIcon className="standart-svg"/>}
                                                </div>
                                            </div>
                                            <div className="box-bg">
                                                <BoxIcon/>
                                            </div>
                                        </div>
                                    }) : <p className="no-data-to-display">No data to display</p>}
                                </div>
                                : <div className="content with-column-view">
                                {pack.boxes.length > 0 ? workplaces.map(item =>
                                  <WorkplaceCollapse
                                    key={item.workplace}
                                    workplace={item}
                                    handleChange={handleChange}
                                    boxesId={boxesId}
                                  />
                                ) : <p className="no-data-to-display">No data to display</p>}
                                </div>)
                            }
                        </div>
                    </div>
                </Collapse>
            </div>
        )
    }
}

export default PackageCollapse

