import React from 'react'
import Tooltip from '../../../reuseComponent/Tooltips';
import {Form} from 'react-bootstrap';
import TextareaAutosize from "react-textarea-autosize";
import {ReactComponent as DeleteIcon} from '../../../../assets/images/delete.svg'
// import TextareaAutosize from "react-textarea-autosize";

const checkIfErrorExist = (errors,touched,index) => {
    return errors.warehouseAddress
        && errors.warehouseAddress[index]
        && errors.warehouseAddress[index]
        && touched.warehouseAddress
        && touched.warehouseAddress[index]
        && touched.warehouseAddress[index]
}

const getErrorLabel = (errors,touched,index) => {
    return errors.warehouseAddress
    && errors.warehouseAddress[index]
    && errors.warehouseAddress[index] ? errors.warehouseAddress[index] : ''
}

const WareHouseRow = props => {

    const {
        index,
        values,
        setFieldValue,
        touched,
        errors,
        arrayHelpers,
        isView
    } = props

    return (
        <div className="address-row">
            <Form.Group className="supplier-element-form-wrapper horizontal">
                <div className="input-item">
                    <TextareaAutosize
                        disabled={isView}
                        style={{resize: 'none', height: 'auto'}}
                        minRows={2}
                        placeholder="Enter address"
                        type="text"
                        className="auto-resize-wrapper"
                        as="textarea"
                        name={`warehouseAddress[${index}]`}
                        value={values['warehouseAddress'][index]}
                        onChange={e => setFieldValue(`warehouseAddress[${index}]`, e.target.value)}
                    />
                    <span className="error-label">{checkIfErrorExist(errors,touched,index) && getErrorLabel(errors,touched,index)}</span>
                </div>
            </Form.Group>
            {!isView && <Tooltip
                text="Delete"
            >
                <DeleteIcon
                    className="delete-supplier-icon"
                    onClick={() => arrayHelpers.remove(index)}
                />
                {/*<svg*/}
                {/*    className="delete-supplier-icon"*/}
                {/*    onClick={() => arrayHelpers.remove(index)}*/}
                {/*    viewBox="0 0 24 24"*/}
                {/*>*/}
                {/*    <path fill="#000000"*/}
                {/*          d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>*/}
                {/*</svg>*/}
            </Tooltip>
            }
        </div>

    )
}

export default WareHouseRow