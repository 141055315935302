import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'

const NewVersionDialog = ({onClick,type}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!onClick) {
            const timer = setTimeout(() => {
                dispatch({ type });
            }, 6000);
            return () => clearTimeout(timer);
        }
    }, [onClick,type,dispatch]);
    return (
        <div className="new-version-wrapper">
            {/*<svg className="close-icon" onClick={() => dispatch({type})} viewBox="0 0 24 24">*/}
            {/*    <path fill="#000000" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />*/}
            {/*</svg>*/}
            <p>New version available</p>
            <Button  onClick={onClick}>
                <svg className="info-icon" viewBox="0 0 24 24">
                    <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
                </svg>
                Update</Button>
        </div>
    )
}

export default NewVersionDialog