import React, { PureComponent } from "react";
import FormServiceDesk from "../form";
import CreateForm from "../form/create";
import HistoryServiceDesk from "../history";

class RequestDetails extends PureComponent {
  render() {
    const { id } = this.props.match.params;
    const {
      cancelCreate,
      callbackAfterCreation,
      refetchData,
      showConfirm,
      setCurrentRequest,
      showConfirmAsana,
      handleDeleteRequest
    } = this.props;
    return (
      <div className="details service-desk-details">
        <div className="content-details">
          {id === "new" && (
            <CreateForm
              cancelCreate={cancelCreate}
              callbackAfterCreation={callbackAfterCreation}
              showConfirm={showConfirm}
              setCurrentRequest={setCurrentRequest}
              handleDeleteReques={handleDeleteRequest}
            />
          )}

          {id !== "new" && (
            <>
              <FormServiceDesk
                cancelCreate={cancelCreate}
                // isView={id === "new" ? false : true}
                callbackAfterCreation={callbackAfterCreation}
                showConfirm={showConfirm}
                setCurrentRequest={setCurrentRequest}
                showConfirmAsana={showConfirmAsana}
                handleDeleteRequest={handleDeleteRequest}
              />
              <HistoryServiceDesk refetchTableData={refetchData} />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default RequestDetails;
