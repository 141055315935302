import React, {PureComponent} from 'react'
import {connect} from 'react-redux';
import {setData} from "../../../actions/admin-header";

class error404 extends PureComponent{

    componentDidMount() {
        const {setHeaderData} = this.props;

        setHeaderData && setHeaderData({
            LeftPannel: null,
        })
    }

    render(){
        const {match} = this.props;
        return (
            <>
            <h1 className="center-h">Access denied</h1>
            {match && match.params && match.params.message && <h1 className="center-h">{match.params.message}</h1>}
            </>
        )
    }
}

const mapDipatchToProps = dispatch => ({
    setHeaderData: data => dispatch(setData(data)),
});

export default connect(null, mapDipatchToProps)(error404);