import React , { PureComponent,createRef } from 'react'
import { connect } from 'react-redux'
import {getOpenBoxesByWorkplace} from '../../../../../../services/products'
import { Form,Button } from 'react-bootstrap'
import Select from '../../../../../reuseComponent/Select/select-form'
import { formatIntegerPlus } from '../../../../../../reuseFunctions/formatValue'
import Preloader from '../../../../../reuseComponent/preloader/preloader'
import {checkPackageName} from '../../../../../../reuseFunctions/checkPackageName'


class BodyForm extends PureComponent{

    state = {
        packages: [],
        boxes: []
    }

    formRef = createRef()

    componentDidMount(){
        const { values } = this.props
        this.getOpenBoxes(values.packageId)
    }

    submitCloseOptions = (closeBox) => {
        const { values,submitForm,setFormikState } = this.props
        setFormikState({
            values: {
                ...values,
                closeBox: closeBox
            }
        },submitForm)
    }


    getOpenBoxes = (idPackage) => {
        const { setFieldValue,activeWorkPlace } = this.props
        if(idPackage)
            getOpenBoxesByWorkplace(idPackage,activeWorkPlace.id)
                .then(res => {
                    this.setState({
                        isLoading: false,
                        boxes: res.data.boxes.length > 0 ? res.data.boxes.map(box => ({
                            label: `BOX-${box.name}`,
                            value: box.id,
                            name: box.name
                        })).concat({
                            label: 'New box',
                            value: 0
                        }) : [{
                            label: 'New box',
                            value: 0
                        }]
                    })
                    // console.log(res.data,res.data.boxes.find(box => box.id === res.data.last_box))
                    let resBox = ''
                    if(res.data.last_box !== null && res.data.boxes.length > 0){
                        if(res.data.boxes.find(box => box.id === res.data.last_box))
                            resBox = res.data.last_box
                        else
                            resBox = ''
                    }
                    else if(res.data.boxes.length === 0)
                        resBox = 0
                    else resBox = ''
                    setFieldValue('boxId',resBox)
                })
        else{
            this.setState({
                isLoading: false
            })
            setFieldValue('boxId','')
        }
    }

    render(){
        const {
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
            handleClose,
            initialValues,
            packages,
            packageItem
        } = this.props
        const { boxes,isLoading } = this.state
        return (
            <Form ref={this.formRef} className="put-article-form" onSubmit={handleSubmit}>
                {isLoading && <Preloader />}
                {!initialValues.packageId ? <div className="notification">
                        <p className="no-package">
                            <svg viewBox="0 0 24 24">
                                <path fill="currentColor" d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z"/>
                            </svg>
                            No package provided for this article!
                        </p>
                        <p className="check-text">
                            Please check if article is <strong>OVERDELIVERED</strong> or listed in <strong>REST</strong>
                        </p>
                    </div>
                    :
                    <div className="schema-to-fill">
                        Packages and box to put into
                    </div>
                }
                <div className="wrapper-form">
                    <Form.Group>
                        <Select
                            isDisabled={true}
                            className="packages-select"
                            name="packageId"
                            isInvalid={errors.packageId && touched.packageId}
                            errorLabel={errors.packageId}
                            options={packages}
                            placeholder="Select package"
                            value={{
                                label: checkPackageName(packageItem),
                                value: packageItem.id
                            }}
                            onChange={valObj => {
                                setFieldValue('packageId',valObj.value)
                                setFieldValue('boxId','')
                                this.getOpenBoxes(valObj.value)
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Select
                            className="box-select"
                            isDisabled={!values.packageId}
                            name="boxId"
                            isInvalid={errors.boxId && touched.boxId}
                            errorLabel={errors.boxId}
                            placeholder="Select box"
                            onChange={valObj => {
                                setFieldValue('boxId',valObj.value)
                                if(valObj.value === 0 && boxes.filter(box => box.value !== 0).length > 0)
                                    setFieldValue('isShowConfirm',true)
                            }}
                            // options={boxes.find(box => box.value === 0) || !values.packageId ? boxes : boxes.concat({
                            //     label: 'New box',
                            //     value: 0
                            // })}
                            options={boxes}
                            value={boxes.find(box => box.value === values.boxId)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            name="items"
                            placeholder="Quantity"
                            onChange={e => setFieldValue('items',e.target.value)}
                            onBlur={e => setFieldValue('items',formatIntegerPlus(e.target.value))}
                            value={values.items}
                            isInvalid={errors.items && touched.items}
                        />
                        <Form.Control.Feedback type="invalid">{errors.items}</Form.Control.Feedback>
                    </Form.Group>
                </div>

                <Form.Group className="footer-form footer-form-custom">
                    <Button
                        type="button"
                        className="cancel-stowe-btn"
                        onClick={handleClose}
                        >
                        Cancel
                    </Button>
                    <Button
                        disabled={!values.packageId
                        || values.boxId === ''
                        || values.boxId === null
                        || values.boxId === undefined
                        || !values.items}
                        type="button"
                        className="stowe-btn"
                        onClick={() => this.submitCloseOptions(false)}
                    >

                        Put → <svg viewBox="0 0 24 24">
                        <path fill="currentColor" d="M2,10.96C1.5,10.68 1.35,10.07 1.63,9.59L3.13,7C3.24,6.8 3.41,6.66 3.6,6.58L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.66,6.72 20.82,6.88 20.91,7.08L22.36,9.6C22.64,10.08 22.47,10.69 22,10.96L21,11.54V16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V10.96C2.7,11.13 2.32,11.14 2,10.96M12,4.15V4.15L12,10.85V10.85L17.96,7.5L12,4.15M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V12.69L14,15.59C13.67,15.77 13.3,15.76 13,15.6V19.29L19,15.91M13.85,13.36L20.13,9.73L19.55,8.72L13.27,12.35L13.85,13.36Z" />
                    </svg>
                    </Button>
                    {/*<Button*/}
                        {/*type="button"*/}
                        {/*className="stowe-and-close-btn"*/}
                        {/*onClick={() => this.submitCloseOptions(true)}*/}
                    {/*>*/}
                        {/*<svg viewBox="0 0 24 24">*/}
                            {/*<path fill="currentColor" d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z" />*/}
                        {/*</svg>*/}
                        {/*Stowe and close box*/}
                    {/*</Button>*/}
                </Form.Group>
            </Form>
        )
    }
}

const mapStateToProps = state => {
    return {
        packages: state.stowingReducer.packages.filter(item => !item.total).map(pack => ({
            value: pack.id,
            label: checkPackageName(pack)
        }))
    }
}

export default connect(mapStateToProps,null)(BodyForm)