import React, {PureComponent} from 'react'
import {Button} from 'react-bootstrap';

class ToolbarDictionary extends PureComponent {

    render() {
        const {
            pagination,
            paginationPosition,
            handleAdd
        } = this.props

        return (
            <div className="toolbar-dictionaries">
                <div className="actions-dictionary-toolbar">
                    {handleAdd && <Button onClick={handleAdd} className="add-button-dictionary">
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                        </svg>
                        Add
                    </Button>
                    }
                </div>
                {paginationPosition === 'toolbar'
                &&
                <div className="pagination-toolbar">
                    {pagination}
                </div>
                }
            </div>
        )
    }
}


export default ToolbarDictionary