import React, { useMemo } from 'react'
import { Button } from 'react-bootstrap'
import { formatCoordinatesToView } from '../../../../../reuseFunctions/mapUtils'
import { Form } from 'react-bootstrap/index'
import { groupCoordinatesArrayToBlocks } from '../constants'


const SelectedInstrumentCard = ({
                                  selectedInstrument,
                                  setSelectedInstrument,
                                  selectedCoordinates,
                                  maxX,
                                  maxY,
                                  handleChangeCoordinates,
                                }) => {
  const coordiantesString = useMemo(() => {
    let res = ''

    res = groupCoordinatesArrayToBlocks(selectedCoordinates).map(item => {
      if(item.startPosition.x !== item.finishPosition.x || item.startPosition.y !== item.finishPosition.y){
        return `${item.startPosition.x}-${item.startPosition.y}:${item.finishPosition.x}-${item.finishPosition.y}`
      }else{
        return `${item.startPosition.x}-${item.startPosition.y}`
      }
    }).join(',')
    res = formatCoordinatesToView(res, maxX, maxY).join(', ')
    //sort

    // console.log(formatCoordinatesToView(res, maxX, maxY).join(', '))
    return res
  }, [selectedCoordinates,maxX, maxY])

  return (
    <div className="selected-instrument-card">
      <div className="instrument-row">
        Selected space: {selectedInstrument.name}
        <div className="color-block" style={{backgroundColor: selectedInstrument.color}}></div>
      </div>
      {selectedCoordinates.length > 0 && <>
      <div className="coordiantes-row">
        Coordinates: <span>
        {/*{formatCoordinatesToView(selectedCoordinates*/}
        {/*    .map(item => `${item.coordinateX}-${item.coordinateY}`).join(',')*/}
        {/*  ,maxX, maxY).join(', ')}*/}
        {coordiantesString}
      </span>
      </div>
      <div className="form-row">
        <Form.Group className="footer-form footer-form-custom">
          <Button variant="secondary" type="button" onClick={() => setSelectedInstrument(selectedInstrument)}>Cancel</Button>
          <Button variant="primary" type="button" onClick={() => handleChangeCoordinates(selectedCoordinates)}>Save</Button>
        </Form.Group>
      </div>
      </>}
    </div>
  )
}

export { SelectedInstrumentCard }
