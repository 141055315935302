import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { uploadFileToSDHistory } from "../../../../../actions/serviceDesk";
import { withRouter } from "react-router-dom";
import Tooltip from "../../../../reuseComponent/Tooltips";

class UploadFile extends PureComponent {
  fileUpload = React.createRef();

  handleChangeInput = e => {
    if (e.target.files && e.target.files.length > 0) {
      const { uploadFileToSDHistory } = this.props;
      const files = Object.values(e.target.files)
      uploadFileToSDHistory && uploadFileToSDHistory(files);
    }
  };

  render() {
    return (
      <>
        <Tooltip text="Attach file">
          <svg
            className="icon"
            enableBackground="new 0 0 512 512"
            height="24"
            viewBox="0 0 512 512"
            width="24"
            onClick={() => {
              this.fileUpload.current && this.fileUpload.current.click();
            }}
          >
            <path d="m226 512c-49.626 0-90-40.374-90-90v-302c0-66.168 53.832-120 120-120s120 53.832 120 120v302h-30v-302c0-49.626-40.374-90-90-90s-90 40.374-90 90v302c0 33.084 26.916 60 60 60s60-26.916 60-60v-242c0-16.542-13.458-30-30-30s-30 13.458-30 30v242h-30v-242c0-33.084 26.916-60 60-60s60 26.916 60 60v242c0 49.626-40.374 90-90 90z" />
          </svg>
        </Tooltip>
        <input
          multiple
          type="file"
          style={{ display: "none" }}
          ref={this.fileUpload}
          onChange={this.handleChangeInput}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    uploadFileToSDHistory: file => dispatch(uploadFileToSDHistory(file))
  };
};

export default withRouter(connect(null, mapDispatchToProps)(UploadFile));
