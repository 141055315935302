import React from 'react'
import moment from 'moment'


const ActionHistory = props => {
    const {
        action,
        isShowDate,
        showDiff
    } = props
    return (
        <div className="history-item action">
            {isShowDate && <div className="date-history-action">
                {moment(action.createdAt).format('MMM DD, YYYY')}
            </div>
            }
            <div className="row-action">
                <span className="user-name-action">{action.user.name} {action.user.secondName}</span>
                <span onClick={() => {
                    if(action.diff)
                        showDiff(true,action.diff)
                }} className={`action-name ${action.diff ? 'with-diff' : ''}`}>{action.text}</span>
                <span className="time-action">{moment(action.createdAt,'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}</span>
            </div>
        </div>
    )
}

export default ActionHistory