import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Button, Form} from 'react-bootstrap'
import ConditionRow from './condition-row'
import {
    changeConditionSetAction,
    editSet,
    addConditionToSet,
    changeActiveSet,
    removeSet
} from '../../../../../../actions/dividing'
import {formatInteger} from '../../../../../../reuseFunctions/formatValue'
import Tooltip from '../../../../../reuseComponent/Tooltips'
import * as uniqId  from 'uniqid'
import { findPermInArray } from '../../../../../../reuseFunctions/checkPermission'
import {permManageDividing} from "../../../../../../permissions/productOverview";
// import PackageIcon from '../../../../../../assets/images/packageIcon.png';
import { ReactComponent as PackageIcon } from '../../../../../../assets/images/packagesIcon.svg'


class ConditionSet extends PureComponent {

    state = {
        ipp: this.props.set.ipp
    }

    getObjectUsedFieldValues = () => {
        const { set } = this.props
        let usedValues = {}
        set.conditions.forEach(condition => {
            if(!usedValues[condition.fieldAlias]){
                usedValues[condition.fieldAlias] = condition.values.map(valObj => valObj.value)
            }
            else {
                condition.values.forEach(valObj => {
                    if(!usedValues[condition.fieldAlias].includes(valObj.value)){
                        usedValues[condition.fieldAlias] = [...usedValues[condition.fieldAlias],valObj.value]
                    }
                })

            }
        })
        return usedValues
        // console.log(usedValues)
    }

    render() {
        const { ipp } = this.state
        const {
            set,
            editSet,
            addConditionToSet,
            changeActiveSet,
            activeSet,
            removeSet,
            status,
            roles,
            details
        } = this.props
        return (
            <div className={`wrap-condition-set ${activeSet === set.id ? 'active' : ''}`} onClick={() => changeActiveSet(set.id)}>
                <div className="condition-row header-row">
                    <div className="name-set">
                        {set.name}
                    </div>
                    <div className="items-per-package">
                        IPP: <Form.Control
                        disabled={status || !findPermInArray(roles,permManageDividing) || details.completeInbound}
                        isInvalid={parseInt(ipp) === 0}
                        onChange={e => this.setState({
                            ipp: e.target.value
                        })}
                        onBlur={e => {
                            const formatedValue = formatInteger(e.target.value)
                            if(formatedValue > 0 && formatedValue !== set.ipp)
                                editSet({
                                    setId: set.id,
                                    ipp: formatedValue
                                })
                            else{
                                this.setState({
                                    ipp: formatedValue
                                })
                            }
                        }}
                        value={ipp}
                    />
                    </div>
                    <div className="packages">
                        <Tooltip text="Packages">
                            {/*<img src={PackageIcon} className="package-icon" alt="package"/>*/}
                            <PackageIcon style={{width: 16, marginTop: '-3px'}} className="standard-svg" />
                        </Tooltip> {set.packages ? set.packages.length : 0}
                        {/*<Tooltip*/}
                            {/*text="Packages"*/}
                        {/*>*/}
                            {/*<span role="img" aria-label="package">📦</span>*/}
                        {/*</Tooltip> {set.packages ? set.packages.length : 0}*/}
                    </div>
                    <div className="total-rrp">
                        € {set.totalRRP.toFixed(2)}
                    </div>
                    <div className="total-items">
                        <Tooltip
                            text="Total items"
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M20.5,11H19V7C19,5.89 18.1,5 17,5H13V3.5A2.5,2.5 0 0,0 10.5,1A2.5,2.5 0 0,0 8,3.5V5H4A2,2 0 0,0 2,7V10.8H3.5C5,10.8 6.2,12 6.2,13.5C6.2,15 5,16.2 3.5,16.2H2V20A2,2 0 0,0 4,22H7.8V20.5C7.8,19 9,17.8 10.5,17.8C12,17.8 13.2,19 13.2,20.5V22H17A2,2 0 0,0 19,20V16H20.5A2.5,2.5 0 0,0 23,13.5A2.5,2.5 0 0,0 20.5,11Z"></path></svg>
                        </Tooltip>
                        {set.totalCount}
                    </div>
                    {findPermInArray(roles,permManageDividing) && !details.completeInbound && !status && <div className="remove-set-button" onClick={() => removeSet(set.id)}>
                    <Tooltip
                        text="Delete set"
                    >
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
                        </svg>
                    </Tooltip>
                </div>
                }
                </div>
                {set.conditions ? set.conditions.map(condition => <ConditionRow
                    usedValues={this.getObjectUsedFieldValues()}
                    countConditions={set.conditions.reduce((acc,condition) => {
                        if(condition.id.toString().indexOf('condition') < 0)
                            return acc + 1
                        return acc
                    },0)}
                    set={set}
                    ipp={set.ipp}
                    condition={condition}
                    key={condition.id}
                    setId={set.id}
                />) : null}
                {set.ipp > 0
                    && ipp > 0
                && activeSet === set.id
                && !details.completeInbound
                && findPermInArray(roles,permManageDividing) && !status && <Button onClick={() => addConditionToSet({setId: set.id, idCondition: uniqId('condition-')})}
                        className="with-icon">
                    <svg viewBox="0 0 24 24">
                        <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                    </svg>
                    Add Condition</Button>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeSet: state.dividingReducer.activeSet,
        status: state.dividingReducer.dividingStatus,
        roles: state.rolesReducer.roles,
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeSet: (id, data) => dispatch(changeConditionSetAction(id, data)),
        editSet: data => dispatch(editSet(data)),
        addConditionToSet: data => dispatch(addConditionToSet(data)),
        changeActiveSet: id => dispatch(changeActiveSet(id)),
        removeSet: id => dispatch(removeSet(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConditionSet)