import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Form, Col, Button, Row} from 'react-bootstrap'
import Select from '../../../../../reuseComponent/Select/select-form'
import {getOpenBoxesByWorkplace} from '../../../../../../services/products'
import {getBrands} from '../../../../../../services/brands'
import AsyncSelect from '../../../../../reuseComponent/Select/async-select'
import adminInstance from '../../../../../instances/admininstance'
import {url} from '../../../../../../constants'
import axios from 'axios'
import {formatIntegerPlus, formatNumber, formatPrice} from '../../../../../../reuseFunctions/formatValue'
import Preloader from '../../../../../reuseComponent/preloader/preloader'
import {checkPackageName} from '../../../../../../reuseFunctions/checkPackageName'
import { getQualities } from '../../../../../../actions/detailsPo'
import { getPackagesRequest } from '../../../../../../actions/stowing'

class FormBody extends PureComponent {
    
    state = {
        brands: [],
        isLoading: true,
        packageItem: this.props.packageItem ? {
            label: checkPackageName(this.props.packageItem),
            value: this.props.packageItem.id
        } : null,
        simpleParameters: {
            cg1: [],
            cg2: [],
            cg3: [],
            bidId: [],
        }
    }


    componentDidMount() {
        const { getQualities,getPackages,details, values } = this.props

        // const { packageItem } = this.state
        getQualities && getQualities()
        const promises = [
            this.getValuesByAlias('commodity'),
            this.getValuesByAlias('gender')
        ]
        if(values.cg1 && values.cg2){
            promises.push(adminInstance.get(`${url}/simple_parameter/category/related_params`, {
                params: {
                    param1: values.cg1,
                    param2: values.cg2
                }
            }))
        }

        

        axios.all(promises)
            .then(axios.spread((cg1Res, cg2Res, cg3Res) => {
                this.setState({
                    isLoading: false,
                    simpleParameters: {
                        cg1: cg1Res.data.map(item => ({
                            label: item.value,
                            value: item.value,
                            id: item.id
                        })),
                        cg2: cg2Res.data.map(item => ({
                            label: item.value,
                            value: item.value,
                            id: item.id
                        })),
                        cg3: cg3Res ? cg3Res.data.map(category => ({
                            value: category.value,
                            label: category.value,
                            id: category.id
                        })) : [],
                        bidId: details && details.bids && details.bids.map(bid => ({
                            label: bid.number,
                            value: bid.number,
                            id: bid.id 
                        }))
                    }
                },() => {

                })
            }))
    }

    

    getValuesByAlias = alias => {
        return adminInstance.get(`${url}/simple_parameter/${alias}`)
    }

    getRelatedParams = (param1, param2) => {
        adminInstance.get(`${url}/simple_parameter/category/related_params`, {
            params: {
                param1: param1,
                param2: param2
            }
        })
            .then(res => {
                const { setFieldValue,values } = this.props
                this.setState({
                    simpleParameters: {
                        ...this.state.simpleParameters,
                        cg3: res.data.map(category => ({
                            value: category.value,
                            label: category.value,
                            id: category.id
                        }))

                    }
                },() => {
                    if(values.cg3 && !this.state.simpleParameters.cg3.find(val => val === values.cg3)){
                        setFieldValue('cg3','')
                    }
                })
            })
    }

    getBrands = (inputValue, callback) => {
        getBrands({
            limit: 20,
            page: 0,
            search: inputValue
        })
            .then(res => {
                callback(res.data.brands.map(item => ({
                    value: item.name,
                    label: item.name
                })))
            })
    }

    submitCloseOptions = (closeBox) => {
        const { values,submitForm,setFormikState } = this.props
        setFormikState({
            values: {
                ...values,
                closeBox: closeBox
            }
        },submitForm)
    }

   
    findSelectedBid = (valObj) => {
        const { details } = this.props;
        return details.bids.find(bid => bid.number === valObj.value);    
    }

    render() {
        const {
            values,
            setFieldValue,
            handleChange,
            errors,
            touched,
            handleSubmit,
            handleClose,
            packages,
            valuesOptions,
            isLoadingPackages,
            details,
            box
        } = this.props

        const {simpleParameters,isLoading,packageItem} = this.state
        values.boxId = box.id;
        const cg1Option = simpleParameters.cg1.find(option => option.value === values.cg1)
        const cg2Option = simpleParameters.cg2.find(option => option.value === values.cg2)
        const cg3Option = simpleParameters.cg3.find(option => option.value === values.cg3)
        const bidsOption = values.bid == null ? null : ((simpleParameters && simpleParameters.bidId) ? simpleParameters.bidId.find(option => option.value === values.bid.number) : null)
        

        return (
            <Form noValidate className="create-article-form" onSubmit={handleSubmit}>
                {(isLoading || isLoadingPackages) && <Preloader />}

                <Row>
                    <Col xs="12">
                        <p className="are-you-sure">Article Creation</p>
                    </Col>
                </Row>

                <Row>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>EAN</Form.Label>
                            <Form.Control
                                name="ean"
                                value={values.ean}
                                onChange={handleChange}
                                isInvalid={errors.ean && touched.ean}
                            />
                            <Form.Control.Feedback type="invalid">{errors.ean}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs="6"><Form.Group>
                            <Form.Label>SKU</Form.Label>
                            <Form.Control
                                name="styleNumber"
                                value={values.styleNumber}
                                onChange={handleChange}
                                isInvalid={errors.styleNumber && touched.styleNumber}
                            />
                            <Form.Control.Feedback type="invalid">{errors.styleNumber}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs="6"><Form.Group>
                        <Form.Label>Box#</Form.Label>
                        <Form.Control
                            name="box"
                            value={box.id}
                            disabled = {true}
                        />
                    </Form.Group>
                    </Col>

                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>BID</Form.Label>

                            <Form.Control
                                name="bid"
                                value={values.bid}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG1</Form.Label>
                            <Select
                                options={simpleParameters.cg1}
                                value={cg1Option}
                                errorLabel={errors.cg1}
                                isInvalid={errors.cg1 && touched.cg1}
                                className="required-select"
                                onChange={valObj => {
                                    setFieldValue('cg1', valObj.value)
                                    if (cg2Option) {
                                        this.getRelatedParams(valObj.id, cg2Option.id)
                                    }
                                    else {
                                        this.setState(state => ({
                                            simpleParameters: {
                                                ...state.simpleParameters,
                                                cg3: []
                                            }
                                        }))
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG2</Form.Label>
                            <Select
                                options={simpleParameters.cg2}
                                value={cg2Option}
                                className="required-select"
                                errorLabel={errors.cg2}
                                isInvalid={errors.cg2 && touched.cg2}
                                onChange={valObj => {
                                    setFieldValue('cg2', valObj.value)
                                    if (cg1Option) {
                                        this.getRelatedParams(cg1Option.id, valObj.id)
                                    }
                                    else {
                                        this.setState(state => ({
                                            simpleParameters: {
                                                ...state.simpleParameters,
                                                cg3: []
                                            }
                                        }))
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>CG3</Form.Label>
                            <Select
                                options={simpleParameters.cg3}
                                value={cg3Option}
                                errorLabel={errors.cg3}
                                isInvalid={errors.cg3 && touched.cg3}
                                onChange={valObj => {
                                    setFieldValue('cg3', valObj.value)
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Brand</Form.Label>
                            <AsyncSelect
                                name="brand"
                                loadOptions={this.getBrands}
                                defaultOptions={true}
                                isInvalid={errors.brand && touched.brand}
                                errorLabel={errors.brand}
                                onChange={obj => setFieldValue('brand', obj.value)}
                                value={values.brand ? {
                                    value: values.brand,
                                    label: values.brand
                                } : null}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Season</Form.Label>
                            <Form.Control
                                name="season"
                                onChange={handleChange}
                                isInvalid={errors.season && touched.season}
                                value={values.season}
                            />
                            <Form.Control.Feedback type="invalid">{errors.season}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Size</Form.Label>
                            <Form.Control
                                name="size"
                                onChange={handleChange}
                                isInvalid={errors.size && touched.size}
                                value={values.size}
                            />
                            <Form.Control.Feedback type="invalid">{errors.size}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control
                                name="incomeStock"
                                onChange={handleChange}
                                value={values.incomeStock}
                                onBlur={e => setFieldValue('incomeStock',formatIntegerPlus(e.target.value))}
                                isInvalid={errors.incomeStock && touched.incomeStock}

                            />
                            <Form.Control.Feedback type="invalid">{errors.incomeStock}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>Quality</Form.Label>
                            <Select
                                name="quality"
                                value={values.quality ? {
                                    label: values.quality,
                                    value: values.quality
                                } : null}
                                onChange={obj => setFieldValue('quality', obj.value)}
                                options={valuesOptions.qualities}
                                isInvalid={errors.quality && touched.quality}
                            />
                            <Form.Control.Feedback type="invalid">{errors.quality}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="6">
                        <Form.Group>
                            <Form.Label>RRP</Form.Label>
                            <Form.Control
                                name="rrp"
                                onChange={e => {
                                    setFieldValue('rrp', e.target.value)
                                }}
                                value={values.rrp}
                                isInvalid={errors.rrp && touched.rrp}
                            />
                            <Form.Control.Feedback type="invalid">{errors.rrp}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    
                    <Col xs="12">
                        <Form.Group className="footer-form footer-form-custom">
                            <Button type="button" onClick={handleClose} className='cancel-stowe-btn'>Cancel</Button>
                            <Button
                                type="submit"
                                    // disabled={values.boxId === '' || values.boxId === undefined || values.boxId === null}
                                className="stowe-btn">Put → <svg viewBox="0 0 24 24">
                        <path fill="currentColor" d="M2,10.96C1.5,10.68 1.35,10.07 1.63,9.59L3.13,7C3.24,6.8 3.41,6.66 3.6,6.58L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.66,6.72 20.82,6.88 20.91,7.08L22.36,9.6C22.64,10.08 22.47,10.69 22,10.96L21,11.54V16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V10.96C2.7,11.13 2.32,11.14 2,10.96M12,4.15V4.15L12,10.85V10.85L17.96,7.5L12,4.15M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V12.69L14,15.59C13.67,15.77 13.3,15.76 13,15.6V19.29L19,15.91M13.85,13.36L20.13,9.73L19.55,8.72L13.27,12.35L13.85,13.36Z" />
                    </svg></Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoadingPackages: state.stowingReducer.isLoadingPackages,
        packages: state.purchaseOrdersReducer.detailsPurchase.inboundType === 'Custom' ?
          state.stowingReducer.packages.filter(item => item.custom).map(pack => ({
              value: pack.id,
              label: checkPackageName(pack)
          }))
          :
          state.stowingReducer.packages.filter(pack => !pack.total).map(pack => ({
            value: pack.id,
            label: checkPackageName(pack)
        })),
        valuesOptions: state.poDetailsReducer.valuesOptions,
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

export default connect(mapStateToProps, {
    getQualities
})(FormBody)