import React, { PureComponent } from 'react'
import {Form} from 'react-bootstrap'
import {Button} from 'react-bootstrap'


class ReasonToReapload extends PureComponent{

    state = {
        reason: '',
        isSubmit: false
    }

    render(){
        const { reason,isSubmit } = this.state
        const {handleCancel,handleConfirm} = this.props
        return <div className="confirmUploadBlock">
            <h3 className="title-text">Please indicate the reason of product list re-importing</h3>
            <div className="reason-input">
                <Form.Control
                    as="textarea"
                    value={reason}
                    onChange={e => this.setState({
                        reason: e.target.value
                    })}
                    isInvalid={isSubmit && !reason}
                />
                <Form.Control.Feedback type="invalid">reqired field</Form.Control.Feedback>
            </div>
            <div className="actions footer-form-custom">
                <Button onClick={handleCancel} variant="secondary">Cancel</Button>
                <Button onClick={() => {
                    if(reason)
                        handleConfirm(reason)
                    else{
                        this.setState({
                            isSubmit: true
                        })
                    }
                }} variant="primary">Confirm</Button>
            </div>
        </div>
    }
}


export default ReasonToReapload