import React from 'react'
import {Button, Form} from 'react-bootstrap'
import {FieldArray} from 'formik';
import Tooltip from '../../../../reuseComponent/Tooltips';
import {ReactComponent as DeleteIcon} from '../../../../../assets/images/delete.svg';
import {ReactComponent as AddIcon} from '../../../../../assets/images/plus.svg';
import AutosizeInput from "react-input-autosize";

const BodyForm = props => {
    const {
        handleSubmit,
        values,
        touched,
        errors,
        handleCancel,
        setFieldValue
    } = props
    return (
        <Form noValidate onSubmit={handleSubmit}  className="quality-form">
            <Form.Group className="horizontal">
                <Form.Label>Output</Form.Label>
                <div className="input-item">
                    <AutosizeInput
                        type="text"
                        placeholder="Enter output"
                        className="auto-resize-wrapper special"
                        name="value"
                        value={values.value}
                        onChange={event => {
                            setFieldValue("value", event.target.value);
                        }}
                        onBlur={e => setFieldValue('value', e.target.value.trim())}
                    />
                    <span className="error-label">{touched.value && errors.value}</span>
                </div>
            </Form.Group>
            <Form.Group className="footer-form footer-form-custom">
                <Button variant="secondary"
                        onClick={handleCancel}>Cancel</Button>
                <Button variant="primary" type="submit">Save</Button>
            </Form.Group>
        </Form>
    )
}

export default BodyForm