import * as yup from 'yup'

export const schema = yup.object().shape({
    value: yup.string().required('Required field'),
    related: yup.array()
        .of(
            yup.object().shape({
                gender: yup.string().required('Required field'),
                commodity: yup.string().required('Required field'),
            })
        )
        .required('Must be at least one value')
        .test('is uniq', 'Must be unique condition', function (value) {
            const related = [...this.parent.related]
            let checkIfUniq = true
            related.forEach((val,index) => {
                related.forEach((dupVal,ind) => {
                    if(ind !== index){
                        if(val.gender === dupVal.gender && val.commodity === dupVal.commodity)
                            checkIfUniq = false
                    }
                })
            })
            return checkIfUniq
        }),
    inputs: yup.array()
        .of(
            yup.string().required('Required field')
        )
})