import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Table from '../../../reuseComponent/Table/MaterialTable'
import Pagination from '../../../reuseComponent/Table/Pagination'
import adminInstance from '../../../instances/admininstance'
import {url} from '../../../../constants'
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute'
import { Route} from 'react-router-dom'
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import {notify} from '../../../reuseComponent/toast'
import {clearSupplierAction} from '../../../../actions/suppliers'
import {setData} from '../../../../actions/admin-header'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission'
import {permEditSuppliers} from '../../../../permissions/suppliers'
import StandartLeftPanner from '../../../reuseComponent/Header/standartLeftPannel'
import Header from '../../../reuseComponent/Table/table-head'
import {history} from '../../../../history'
import DetailsSupplier from '../details'
// import IconButtonMaterial from '../../../reuseComponent/Buttons/ButtonWithMaterialIcon';
import { setSupplierAction } from '../../../../actions/suppliers'
import Toolbar from './Toolbar'
import Container from '../../../reuseComponent/Table/container'
import { setQualitiesAction } from '../../../../actions/suppliers'
import { ConfirmModalContent } from '../../../reuseComponent/confirmModalComponent'
import withTracker from "../../../reuseComponent/GoogleAnalytics";

class ListSuppliers extends PureComponent {

    state = {
        modalRemoveSupplier: false,
        removedTitle: '',
        currentSupplier: ''
    }

    tableRef = React.createRef()

    addEmptyRowToTable = () => {
        const data = this.tableRef.current ? this.tableRef.current.state.data : []
        if(data.find(supplier => supplier.id === 'new') === undefined){
            const newSupplier = {
                id: 'new',
                title: 'New supplier',
                contacts: [],
                warehouseAddresses: [],
                additionalInfo: ''
            }
            this.tableRef.current && this.tableRef.current.dataManager.setData([newSupplier,...data])
            this.tableRef.current.setState({...this.tableRef.current.dataManager.getRenderState()})
        }
    }

    removeEmptyRow = () => {
        let data = this.tableRef.current ? this.tableRef.current.state.data : []
        data = data.filter(supplier => supplier.id !== 'new')
        this.tableRef.current && this.tableRef.current.dataManager.setData(data)
        this.tableRef.current.setState({...this.tableRef.current.dataManager.getRenderState()})
    }

    cancelHander = () => {
        changeRoutePush('/atw/suppliers', history)
        this.removeEmptyRow()
    }

    componentDidMount() {
        const {clearSupplier} = this.props
        clearSupplier && clearSupplier()
        this.props.setHeaderData({
            leftPannelData: {
                title: 'Suppliers',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#D81B60"
                          d="M12,3A4,4 0 0,1 16,7A4,4 0 0,1 12,11A4,4 0 0,1 8,7A4,4 0 0,1 12,3M16,13.54C16,14.6 15.72,17.07 13.81,19.83L13,15L13.94,13.12C13.32,13.05 12.67,13 12,13C11.33,13 10.68,13.05 10.06,13.12L11,15L10.19,19.83C8.28,17.07 8,14.6 8,13.54C5.61,14.24 4,15.5 4,17V21H10L11.09,21H12.91L14,21H20V17C20,15.5 18.4,14.24 16,13.54Z"/>
                </svg>)),
            },
            textAddButton: 'Add',
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: (value) => this.tableRef.current.onSearchChange(value)
        })
    }

    componentWillUnmount() {
        this.props.setHeaderData({title: null, handleAdd: null, handleSearch: null})
    }

    handleDeleteSupplier = () => {
        const idSupp = this.state.currentSupplier
        const { id } = this.props.match.params
        this.setState({
            modalRemoveSupplier: false,
            currentSupplier: false
        }, () => {
            adminInstance.delete(`${url}/supplier/${idSupp}`)
                .then(res => {
                    if (id && parseInt(id) === parseInt(idSupp)) {
                        changeRoutePush(`/atw/suppliers`, history)
                    }
                    let query = {
                        ...this.tableRef.current.state.query,
                        page: 0
                    }
                    this.tableRef.current && this.tableRef.current.onQueryChange(query)
                    notify('success', 'Supplier successful deleted')
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })

    }

    callbackAfterCreation = data => {
        const { setSupplier } = this.props
        this.tableRef.current && this.tableRef.current.onQueryChange()
        changeRoutePush(`/atw/suppliers/${data.id}`,history)
        setSupplier(data)
    }

    showHideModal = (modalName, open, callback) => {
        this.setState({
            [modalName]: open
        }, () => callback && callback())

    }

    generateCols = () => {
        return [
            {
                title: 'ID',
                field: 'uniqNumber',
                sorting: false
            },
            {
                title: 'Company',
                field: 'title',
                sorting: false,
                render: rowData => {
                    return <>
                            <div className="supplier-company-name">{rowData.title}</div>
                        <div className="supplier-email">{rowData.email}</div>
                        </>
                }
            },
            // {
            //     title: '',
            //     field: 'actions',
            //     sorting: false,
            //     render: rowData => {
            //         return (
            //             findPermInArray(this.props.roles, permEditSuppliers) && rowData.id !== 'new' ? <IconButtonMaterial
            //                     customClass="show-on-hover"
            //                     title="Delete"
            //                     onClick={e => {
            //                         e.stopPropagation()
            //                         this.setState({
            //                             removedTitle: rowData.title,
            //                             currentSupplier: rowData.supplier.id,
            //                             modalRemoveSupplier: true
            //                         })
            //                     }}>
            //                     <svg viewBox="0 0 24 24">
            //                         <path fill="#000000"
            //                               d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
            //                     </svg>
            //                 </IconButtonMaterial>
            //                 : null
            //         )
            //     }
            // }
        ]
    }

    generateRows = data => {
        return data.map(supplier => {
            return {
                id: supplier.id,
                uniqNumber: supplier.uniqNumber,
                supplier: supplier,
                title: supplier.title,
                email: supplier.email,
                created: supplier.createdAt,
                contacts: supplier.contacts,
                author: supplier.author ? `${supplier.author.name} ${supplier.author.secondName}` : null,
                buyer: supplier.buyer ? `${supplier.buyer.name} ${supplier.buyer.secondName}` : null,
                additionalInfo: supplier.additionalInfo ? supplier.additionalInfo : '',
                address: supplier.address,
                warehouseAddresses: supplier.warehouseAddresses
            }

        })
    }

    getSuppliersForTable = query => {
        return new Promise((resolve, reject) => {
            adminInstance.get(`${url}/supplier`, {
                params: {
                    page: query.page ? query.page : 0,
                    limit: query.pageSize,
                    search: query.search
                }
            })
                .then(res => {
                    const { id } = this.props.match.params
                    const { setQuality } = this.props
                    setQuality(res.data.quality.map(q => q.value))
                    const newSupplier = {
                        id: 'new',
                        title: 'New supplier',
                        contacts: [],
                        warehouseAddresses: [],
                        additionalInfo: ''
                    }
                    if(id === undefined && res.data.suppliers.length > 0)
                        changeRoutePush(`/atw/suppliers/${res.data.suppliers[0].id}`,history)
                    resolve({
                        data: id === 'new'
                            ? this.generateRows([newSupplier,...res.data.suppliers])
                            : this.generateRows(res.data.suppliers),
                        page: query.page,
                        totalCount: res.data.count
                    })
                })
        })
    }

    render() {
        const {modalRemoveSupplier, removedTitle} = this.state
        const { roles,clearSupplier } = this.props
        const {id} = this.props.match.params
        return <div className={`wrap-table-with-details-panel with-details suppliers-wrap`}>
            <div className="table-block wrapper-table suppliers-table">
                <Table
                    components={{
                        Container: Container,
                        Toolbar: props => <Toolbar
                            {...props}
                            handleAdd={findPermInArray(roles, permEditSuppliers) ? () => {
                                clearSupplier()
                                changeRoutePush('/atw/suppliers/new', this.props.history)
                                this.addEmptyRowToTable()
                            } : null}
                        />,
                        Header: props => <Header {...props}/>,
                        Pagination: Pagination
                    }}
                    paginationPosition="toolbar"
                    tableRef={this.tableRef}
                    columns={this.generateCols()}
                    data={this.getSuppliersForTable}
                    title="Suppliers"
                    onRowClick={(event, rowData) => {
                        event.preventDefault()
                        if(rowData.id !== 'new'){
                            changeRoutePush(`/atw/suppliers/${rowData.id}`, history)
                            this.removeEmptyRow()
                        }

                    }}
                    options={{
                        showTitle: false,
                        search: true,
                        emptyRowsWhenPaging: false,
                        pageSize: 50,
                        pageSizeOptions: [10, 25, 50],
                        searchFieldStyle: {display: 'none'},
                        rowStyle: props => {
                            if (parseInt(id) === props.id || id === props.id)
                                return {
                                    backgroundColor: '#f2f2f2'
                                }
                            else {
                                return {}
                            }
                        }
                    }}
                />
            </div>
            <Route
                exact
                path={["/atw/suppliers/:id", "/atw/suppliers"]}
                // path={["/atw/suppliers/:id"]}
                render={props => {
                    return <DetailsSupplier
                        {...props}
                        handleShowRemoveModal={(id, title) => {
                            this.setState({
                                removedTitle: title,
                                currentSupplier: id,
                                modalRemoveSupplier: true
                            })
                        }}
                        callbackAfterCreation={this.callbackAfterCreation}
                        cancelCreate={this.cancelHander}
                    />
                }}
            />
            <CustomModalWithChildren
                open={modalRemoveSupplier}
                handleClose={e => this.showHideModal('modalRemoveSupplier', false)}
                handleCancel={e => this.showHideModal('modalRemoveSupplier', false)}
                handleConfirm={this.handleDeleteSupplier}
                options={{
                    centered: true
                }}
            >
                <ConfirmModalContent text={`delete "${removedTitle.trim()}" supplier`}/>
            </CustomModalWithChildren>
        </div>
    }
}

const mapStateToProps = state => ({
    roles: state.rolesReducer.roles
});

const mapDispatchToProps = dispatch => {
    return {
        clearSupplier: () => dispatch(clearSupplierAction()),
        setHeaderData: (data) => dispatch(setData(data)),
        setSupplier: (data) => dispatch(setSupplierAction(data)),
        setQuality: (qualities) => dispatch(setQualitiesAction(qualities))
    }
}

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(ListSuppliers))
