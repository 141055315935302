import React from 'react'
import IconButton from './CustoToggleWithIconButton'
import CustomDropMenu from './CustomDropMenu'
import { Dropdown } from 'react-bootstrap'

export default class DropDownMenu extends React.PureComponent{
    static defaultProps = {
        id : 0,
        ToggleButton : IconButton,
        CustomMenu : CustomDropMenu,
        ContentMenu : '',
        stopPropagation: false,
    }

    render() {
        const { id,CustomMenu,ToggleButton,ContentMenu, stopPropagation } = this.props
        return(
            <Dropdown className="actions-dropdown-menu">
                <div onClick={e => {
                    if(stopPropagation)
                        e.stopPropagation()
                }}>
                <Dropdown.Toggle stopPropagation={stopPropagation} as={ToggleButton} id={`dropdown-custom-components-${id}`}></Dropdown.Toggle>
                </div>
                <Dropdown.Menu className="actions-dropdown-body" as={CustomMenu}>
                    <ContentMenu/>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}


