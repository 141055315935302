import React from 'react'
import { Formik } from 'formik'
import { schema } from '../schema'
import BodyForm from '../bodyForm'

const FormikWrapper = props => {
    const {
        handleSubmit,
        handleCancel,
        initValues,
        createFromPo,
        onlyChangeResponsive,
        isView,
        isDetails
    } = props
    return (
        <Formik
            initialValues={initValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
            render={formikProps => {
                return <BodyForm
                    {...formikProps}
                    isView={isView}
                    createFromPo={createFromPo}
                    handleCancel={handleCancel}
                    onlyChangeResponsive={onlyChangeResponsive}
                    isDetails={isDetails}
                />
            }}
        />
    )
}

export default FormikWrapper