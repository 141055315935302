import {RELEASE_NOTES} from "../constants";

const initState = {
    isLoadingNote : false,
    note: {
        version: '',
        description: '',
        // description: letterTemplate,
        date: ''
    },
    currentVersion: '',
    notes: []
};

export const releaseNotesReducer = (state = initState, action) => {
    const { type, payload } = action
    switch(type){
        case RELEASE_NOTES.EDIT_RELEASE_NOTE: {
            return {
                ...state,
                notes: state.notes.map(note => note.id === payload.id ? {
                    ...note,
                    ...payload
                } : note)
            }
        }
        case RELEASE_NOTES.REMOVE_RELEASE_NOTE: {
            return {
                ...state,
                notes: state.notes.filter(note => note.id !== payload)
            }
        }
        case RELEASE_NOTES.ADD_RELEASE_NOTE: {
            return {
                ...state,
                notes: [payload,...state.notes]
            }
        }
        case RELEASE_NOTES.SET_NOTES_LIST: {
            return {
                ...state,
                notes: payload
            }
        }
        case RELEASE_NOTES.IS_LOADING_NOTE:{
            return {
                ...state,
                isLoadingNote: payload
            }
        }
        case RELEASE_NOTES.SET_NOTE: {
            return {
                ...state,
                note: payload,
            }
        }
        case RELEASE_NOTES.SET_CURRENT_VERSION: {
            return {
                ...state,
                currentVersion: payload
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
};