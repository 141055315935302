import adminInstance from '../components/instances/admininstance'
import { url } from '../constants'
import { convertToFormData } from '../reuseFunctions/objectToFormData'

const getLocationType = (locationTypeId) => {
  return adminInstance.get(`${url}/location-type/${locationTypeId}`)
}

const createLocationType = (planId, values) => {
  return adminInstance.post(`${url}/location-type`, convertToFormData(values), {
    params: {
      planId
    }
  })
}

const updateLocationType = (locationTypeId, values) => {
  return adminInstance.put(`${url}/location-type/${locationTypeId}`, convertToFormData(values))
}

const removeLocationType = (locationTypeId, newLocationType) => {
  console.log(newLocationType)
  return adminInstance.delete(`${url}/location-type/${locationTypeId}`, {
    params: {
      newLocationType
    }
  })
}

export const LocationTypeAPI = {
  getLocationType,
  createLocationType,
  updateLocationType,
  removeLocationType
}