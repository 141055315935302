import React, {PureComponent} from 'react'
import {Row, Col, Nav} from 'react-bootstrap'
import adminInstance from '../../../instances/admininstance'
import {url} from "../../../../constants"
import Preloader from '../../../reuseComponent/preloader/preloader'
import {notify} from "../../../reuseComponent/toast"
import {convertToFormData} from "../../../../reuseFunctions/objectToFormData"
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'
import { connect } from 'react-redux'
import {setData} from "../../../../actions/admin-header";
import ModalWithInput from '../../../reuseComponent/modal/modalWithInputConfirm'
import PartlyPrivileges from '../partly-privileges-component'
import {permEditRoles} from "../../../../permissions/settings";
import ModalWithSelect from '../remove';
import Modal from '../../../reuseComponent/modal';
import TabsSettings from "../../settings/tabs";
import withTracker from "../../../reuseComponent/GoogleAnalytics";

class ListRoles extends PureComponent {

    state = {
        loading: true,
        parents: [],
        openRemoveModal: false,
        roleIdRemove: null,
        purchaseOrderPrivileges: [],
        activeTab: '',
        removeModalWithSelect: false,
        roleName: '',
    }


    showHideModal = (status,id) => {
        this.setState({
            openRemoveModal : status,
            roleIdRemove: id
        })
    }

    handleDelete = ({inputText}) => {
        const { roleIdRemove } = this.state
        this.setState({
            openRemoveModal: false
        },() => {
            adminInstance.delete(`${url}/role/${roleIdRemove}`,{
                params: {
                    roleName: inputText
                }
            })
                .then(() => {
                    this.getPrivileges()
                    notify('success','Role successfully deleted')
                })
                .catch(err => {
                    if(err.response && err.response.status === 400){
                        this.setState({roleName: inputText, removeModalWithSelect: true})
                    }else{
                        notify('error','Oops something went wrong')
                    }
                })
        })

    }

    handleChange = async (name, value, id) => {
        let formData = new FormData()
        formData.append(name, value)
        let promise = await adminInstance.put(`${url}/role/${id}`, formData)
        await this.getPrivileges()
        return promise
    }

    handleCreateRole = () => {
        const {parents} = this.state;
        let roles = parents && parents[0] && parents[0].roles ? parents[0].roles : [];
        let roleId = roles ? Math.max(...roles.map(item => item.id))+1 : 1;
        const data = convertToFormData({name : `!${roleId} - New Role`})
        adminInstance.post(`${url}/role`,data)
            .then(() => {
                this.getPrivileges();
                notify('success','Role successfully added')
            })
            .catch(err => console.log(err))
    }

    handleChangeRole = (obj) => {
        const data = convertToFormData(obj)
        adminInstance.post(`${url}/role/privileges/change`, data)
            .then(res => {
                this.getPrivileges()
                notify('success','Data saved successfully')

            })
            .catch(err => console.log(err))
    }

    handleRoleNameValidate = (roles) => (name, id) => {

        let hasErorr = '';

        roles.forEach(item => {
            if(item.name === name && item.id !== id){
                hasErorr =  'Role is already exists';
            }
        });

        return hasErorr;
    };

    getPrivileges = (callback) =>{
        adminInstance.get(`${url}/role/privileges`)
            .then(res => {

                this.setState({
                    parents: res.data[0].parents,
                }, () => callback && callback(res.data[0].parents))
            })
            .catch(err => console.log(err))
            .finally(() => this.setState({
                loading: false
            }))
    }


    componentDidMount() {

        this.getPrivileges((parents) => {
            const newParrents = parents.filter(item => item.isShow);
            if(newParrents && newParrents[0]){
                this.setState({activeTab: newParrents[0].name})
            }
        });

        this.props.setHeaderData({
            LeftPannel: TabsSettings,
            leftPannelData: {
                alias: 'roles'
            },
            handleAdd: null,
            handleSearch: null
        })
    }

    componentWillUnmount() {
        this.props.setHeaderData({title: null, handleAdd: null, handleSearch: null, CustomLeftPannel: null})
    }

    getContentCollapseRole = (parent = {}) => {

        const {roles} = parent
        return parent.isPartly
            ? <PartlyPrivileges
                handleRemove={id => this.showHideModal(true, id)}
                handleChange={this.handleChange}
                handleCreate={this.handleCreateRole}
                handleValidate={this.handleRoleNameValidate(roles)}
                alias={parent.id}
                rolesList={roles}
            />
            : null
    }

    render() {
        const {loading, parents,openRemoveModal,roleIdRemove, activeTab, removeModalWithSelect, roleName} = this.state

        let roleObj = null
        if(parents.length > 0){
            roleObj = parents[0].roles.find(role => role.id === roleIdRemove)
        }
        const { roles } = this.props
        return (
            <Row>
                {!loading ? <Col xs="12">
                        {parents.length > 0 &&
                        <Nav variant="tabs" className="roles-tabs" activeKey={activeTab}>
                            {parents.map(item => item.isShow ? <Nav.Item key={item.id}>
                                    <Nav.Link eventKey={item.name} onSelect={() => this.setState({activeTab: item.name})}>
                                        {item.name}
                                    </Nav.Link>
                                </Nav.Item> : null
                            )}
                        </Nav>
                        }
                        {activeTab && this.getContentCollapseRole(parents.find(item => item.name === activeTab))}
                        {findPermInArray(roles, permEditRoles) && <ModalWithInput
                            textToCompare={roleObj ? roleObj.name : ''}
                            open={openRemoveModal}
                            TextComponent={() => <>
                                <div className="wrap-text">
                                    <p>You're going to delete role. Deleted role can not be restored! Are you
                                        ABSOLUTELY sure?</p>
                                    <p>The action can lead to data loss. Please enter the <span
                                        className="text-to-repeat-for-delete">{roleObj ? roleObj.name : ''}</span> to
                                        confirm deleting</p>
                                </div>
                            </>}
                            withReason={false}
                            handleConfirm={this.handleDelete}
                            handleCancel={e => this.showHideModal(false, null)}
                            handleClose={e => this.showHideModal(false, null)}
                            options={{
                                centered: true
                            }}
                        />
                        }
                        {findPermInArray(roles, permEditRoles) && <Modal
                            open={removeModalWithSelect}
                            isFooter={false}
                            handleClose={e => this.setState({removeModalWithSelect: false, roleName: ''})}
                            Content={() => <ModalWithSelect
                                roleIdRemove={roleIdRemove}
                                roleName={roleName}
                                getPriviliges={this.getPrivileges}
                                handleClose={() => this.setState({removeModalWithSelect: false, roleName: ''})}
                            />}
                            options={{
                                centered: true
                            }}
                        />
                        }
                    </Col>
                    : <Preloader/>
                }
            </Row>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

const mapDispatchToProps = dispatch => ({
    setHeaderData: (data) => dispatch(setData(data))
});

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(ListRoles));