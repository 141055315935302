import {ADMIN_HEADER} from '../constants';

export const setData = (data) => {
    return {
    type: ADMIN_HEADER.SET_DATA,
    payload: {...data}
}}

export const resetHeaderStateAction = () => ({
    type: ADMIN_HEADER.RESET_STATE
})

export const changeFiltersAction = filters => {
    return {
        type: ADMIN_HEADER.CHANGE_FILTERS,
        payload: filters
    }
}

export const setSearchText = searchText => {
    return {
        type: ADMIN_HEADER.SET_SEARCH_TEXT,
        payload: {searchText}
    }
}