import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import ReleaseNotesItem from './note-item'
import { setData } from '../../../../actions/admin-header'
import { getNotes,getCurrentVersion } from '../../../../actions/releaseNotes'
import StandartLeftPanner from "../../../reuseComponent/Header/standartLeftPannel";

class ViewReleaseNotesComponent extends PureComponent{

    componentDidMount(){
        const { setHeaderData,getNotes,getCurrentVersion } = this.props
        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'About WCS',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#000000" d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
                </svg>)),
            },
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: null,
        })
        getNotes && getNotes()
        getCurrentVersion && getCurrentVersion()
    }

    render(){
        const {
            notes,
            currentVersion
        } = this.props
        return (
            <div>
                <h6>Current version: {currentVersion ? currentVersion.version : null}</h6>
                {notes.map(item =>
                    <ReleaseNotesItem
                        key={item.id}
                        data={item}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        notes: state.releaseNotesReducer.notes,
        currentVersion: state.releaseNotesReducer.currentVersion
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setHeaderData: (data) => dispatch(setData(data)),
        getCurrentVersion : () => dispatch(getCurrentVersion()),
        getNotes: () => dispatch(getNotes())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ViewReleaseNotesComponent)