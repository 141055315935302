import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'

import { editBox } from '../../../../../../services/boxes'
import { EditBoxFormBody } from './EditBoxFormBody'
import { notify } from '../../../../../reuseComponent/toast'
import { boxSchema } from './boxSchema'
import { updateBoxCount} from '../../../../../../actions/inboundingReport'
import { defaultServerError } from '../../../../../../reuseFunctions/toasts'

const EditBoxForm = ({box, handleCancel}) => {

  const activePackage = useSelector(state => state.inboundingReportReducer.activePackage)
  const details = useSelector(state => state.purchaseOrdersReducer.detailsPurchase)

  const dispatch = useDispatch();

  const handleSubmit = useCallback((values) => {

    editBox(box.id, parseInt(values.item), values.bid.id)
      .then(res => {
        const selectedBid = details.bids.filter(bid => bid.id === values.bid.id)[0]
        handleCancel()
        notify('success', 'Quantity successfully changed')
        dispatch(updateBoxCount({
            id: activePackage.id,
            actual_items: activePackage.actual_items ? activePackage.actual_items - parseInt(box.item) + parseInt(values.item) : parseInt(values.item)
          },
          {
            id: box.id,
            item: parseInt(values.item)
          },
          {
            bid: selectedBid
          }
        ))
      })
      .catch(err => {
        console.log(err)
        defaultServerError()
      })
  }, [dispatch, box, handleCancel, activePackage])

  const bidId = details.bids.map(bid => ({
    label: bid.number,
    value: bid.number,
    id: bid.id 
  }));

  const selectedBid = {
    id: box && box.bid ? box.bid.id : '',
    value: box && box.bid ? box.bid : '',
    label: box && box.bid ? box.bid : '',
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{...box, bid: box && box.bid ? selectedBid.number : {},  actualQuantity: details.actualQuantity, actual_items: activePackage.actual_items, prevCount: box && box.item ? box.item : 0}}
      validationSchema={boxSchema}
      render={props => <EditBoxFormBody {...props} bidId={bidId} handleCancel={handleCancel} />}
    />
  )
}

export {
  EditBoxForm,
}