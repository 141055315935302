import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { changeTasksData } from '../../../../../../../../actions/purchase-orders'

const TaskAction = props => {
    const {
        action,
        isShowDate,
        // handleEdit
    } = props
    return (
        <div className={`history-item action task-action ${isShowDate ? 'border-bottom': ''}`}>
            {isShowDate && <div className="date-history-action">
                {moment(action.createdAt).format('MMM DD, YYYY')}
            </div>
            }
            <div className="row-action">
                <svg className="absolute-type-action-icon" viewBox="0 0 24 24">
                    <path fill="#000000" d="M19,19H5V8H19M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M16.53,11.06L15.47,10L10.59,14.88L8.47,12.76L7.41,13.82L10.59,17L16.53,11.06Z" />
                </svg>
                <span className="user-name-action">{action.user.name} {action.user.secondName}</span>
                <span className={`action-name`} dangerouslySetInnerHTML={{__html: action.text}}></span>
                {/* <span className="task-name" >#{action.task ? action.task.id : null}</span> */}
                <span className="time-action">{moment(action.createdAt,'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}</span>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        changeTasksData: data => dispatch(changeTasksData(data))
    }
}

export default connect(null,mapDispatchToProps)(TaskAction)