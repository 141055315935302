import React from 'react'
import { useSelector } from 'react-redux'
import { Nav } from 'react-bootstrap/index'

import { changeRoutePush } from '../../../reuseFunctions/changeRoute'
import { history } from '../../../history'
import Tooltip from '../../reuseComponent/Tooltips'
import { INVENTORY_TABS } from './constants'

import {ReactComponent as ListIcon} from '../../../assets/images/ListIcon.svg'
import {ReactComponent as MapIcon} from '../../../assets/images/MapIcon.svg'
import {ReactComponent as SettingsIcon} from '../../../assets/images/settings.svg'
import {ReactComponent as ArchiveIcon} from '../../../assets/images/archive.svg'
import { findPermInArray } from '../../../reuseFunctions/checkPermission'
import { permViewMapEditor } from '../../../permissions/inventory'

const InventoryTabs = () => {

  const alias = useSelector(state => state.adminHeaderReducer.leftPannelData.alias)
  const roles = useSelector(state => state.rolesReducer.roles)

  return (
    <Nav className="settings-tabs inventory-tabs align-items-center" variant="tabs" activeKey={alias}>
      <Nav.Item>
        <Nav.Link onClick={() => changeRoutePush('/atw/inventory/map', history)} eventKey={INVENTORY_TABS.MAP}>
          {alias === INVENTORY_TABS.MAP ? <>
            <MapIcon className="action-icon header-icon" />
            <h3 className="title-text m-10">Map</h3>
          </> : <Tooltip
            text="Map"
            placement="bottom"
          >
            <MapIcon className="action-icon header-icon" />
          </Tooltip>}
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link onClick={() => changeRoutePush('/atw/inventory/list', history)} eventKey={INVENTORY_TABS.LIST}>
          {alias === INVENTORY_TABS.LIST ? <>
            <ListIcon className="action-icon header-icon" />
            <h3 className="title-text m-10">List view</h3>
          </> : <Tooltip
            text="List view"
            placement="bottom"
          >
            <ListIcon className="action-icon header-icon" />
          </Tooltip>}
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link onClick={() => changeRoutePush('/atw/inventory/archive', history)} eventKey={INVENTORY_TABS.ARCHIVE}>
          {alias === INVENTORY_TABS.ARCHIVE ? <>
            <ArchiveIcon className="action-icon header-icon" />
            <h3 className="title-text m-10">Archive</h3>
          </> : <Tooltip
            text="Archive"
            placement="bottom"
          >
            <ArchiveIcon className="action-icon header-icon" />
          </Tooltip>}
        </Nav.Link>
      </Nav.Item>

      {findPermInArray(roles, permViewMapEditor) && <Nav.Item>
        <Nav.Link onClick={() => changeRoutePush('/atw/inventory/map-editor', history)} eventKey={INVENTORY_TABS.DICTIONARY}>
          {alias === INVENTORY_TABS.DICTIONARY ? <>
            <SettingsIcon className="action-icon header-icon" />
            <h3 className="title-text m-10">Spaces mgmt</h3>
          </> : <Tooltip
            text="Spaces mgmt"
            placement="bottom"
          >
            <SettingsIcon className="action-icon header-icon" />
          </Tooltip>}
        </Nav.Link>
      </Nav.Item>}
    </Nav>
  )
}

export { InventoryTabs }
