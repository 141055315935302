import React, { PureComponent } from 'react'
import {Bar} from 'react-chartjs-2'
import { connect } from 'react-redux'
import adminInstanse from '../../../../../instances/admininstance'
import {url} from '../../../../../../constants'
import {notify} from '../../../../../reuseComponent/toast'
import Preloader from '../../../../../reuseComponent/preloader/preloader'


class BarChart extends PureComponent{

    state = {
        data: {},
        isLoading: this.props.snapshot ? false : true
    }

    handleGetData = () => {
        const { details , field,snapshot } = this.props
        if(snapshot){
            const json = JSON.parse(snapshot.charts)
            let data = null
            if(field === 'cg1'){
                data = json.totalCg1
            }
            else if(field === 'cg2'){
                data = json.totalCg2
            }
            else{
                data = json.totalSeason
            }
            this.setState({
                data: {
                    labels: data.map(item => item.label),
                    datasets: [{
                        label: '',
                        backgroundColor: 'rgba(255,99,132,0.2)',
                        borderColor: 'rgba(255,99,132,1)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                        hoverBorderColor: 'rgba(255,99,132,1)',
                        data: data.map(item => item.value)
                    }]
                }
            })
        }
        else{
            adminInstanse.get(`${url}/analytics/total/${details.id}/${field}`,{
                params: {
                    field: field
                }
            })
                .then(res => {
                    this.setState({
                        isLoading: false,
                        data: {
                            labels: res.data.map(item => item.label),
                            datasets: [{
                                label: '',
                                backgroundColor: 'rgba(255,99,132,0.2)',
                                borderColor: 'rgba(255,99,132,1)',
                                borderWidth: 1,
                                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                hoverBorderColor: 'rgba(255,99,132,1)',
                                data: res.data.map(item => item.value)
                            }]
                        }
                    })
                })
                .catch(err => {
                    console.log(err)
                    notify('error','Oops something went wrong')
                })
        }

    }

    componentDidMount() {
        this.handleGetData()
    }

    render(){
        const { data,isLoading } = this.state
        const { field } = this.props

        return (
            isLoading ? <Preloader /> : <div className="chart-wrapper">
                <h5>Total by {field}</h5>
                <Bar
                    data={data}
                />
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        snapshot: state.purchaseOrdersReducer.snapshot
    }
}

export default connect(mapStateToProps,null)(BarChart)