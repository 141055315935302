import React, {useState, useEffect, useCallback, useRef} from 'react'

const TotalDropDown = (props) => {

    const {options = []} = props
    const listRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [activeValue, setActiveValue] = useState(options.length > 0 ? options[0] : {
        label: 'No data to display',
        value: null
    })

    const handleOpenHide = useCallback(() => {
        setOpen(!open)
    }, [open])

    const handleClickOutside = useCallback((event) => {
        if (listRef.current && !listRef.current.contains(event.target)) {
            setOpen(false)
        }
    }, [])

    useEffect(() => {
        const findActive = options.find(option => option.key === activeValue.key)
        setActiveValue(findActive ? findActive : {
            label: 'No data to display',
            value: null
        })
    }, [options,activeValue])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [handleClickOutside])

    return (
        <div className="total-dropdown__wrapper" ref={listRef}>
            <button onClick={handleOpenHide}>
                <svg viewBox="0 0 20 20">
                    <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                </svg>
                <strong>{activeValue.label}</strong>
                {activeValue.value}
            </button>
            {open && <ul>
                {options.map(option => <li
                    className={`${option.key === activeValue.key ? 'active' : ''}`}
                    key={option.key}
                    onClick={() => {
                        handleOpenHide()
                        setActiveValue(option)
                    }}
                >
                    <strong>{option.label}</strong> {option.value}
                </li>)}
            </ul>
            }
        </div>
    )
}

export {TotalDropDown}