import {TASKS} from '../constants'
import adminInstance from '../components/instances/admininstance'
import { url } from '../constants'
import {convertToFormData} from "../reuseFunctions/objectToFormData";
import { notify } from "../components/reuseComponent/toast";

export const setActiveTasks = (count) => ({
    type: TASKS.SET_ACTIVE_TASKS,
    activeTasks: count,
});

export const setActiveInbox = (count) => ({
    type: TASKS.SET_ACTIVE_INBOX,
    activeInbox: count,
});

export const getTasksAction = async (dispatch) => {
    try {
        const res = await adminInstance.get(`${url}/tasks/count/active`);
        dispatch(setActiveTasks(res.data));
    } catch(err) {
        console.log('err: ', err);
    }
}

export const getActiveInboxAction = async (dispatch) => {
    try {
        const res = await adminInstance.get(`${url}/inbox/count_unread`);
        dispatch(setActiveInbox(res.data));
    } catch(err) {
        console.log('err: ', err);
    }
}


export const addCommentAction = (result) => ({
    type: TASKS.ADD_COMMENT,
    payload: result
});

export const addComment = ({userIds, description, ticketId}) => {
    return dispatch => {
        const data = {
            userIds,
            description,
            ticketId
        };
        adminInstance.post(`${url}/tasks/comment`, convertToFormData(data))
            .then(res => {
                dispatch(addCommentAction(res.data));
                notify('success', 'Comment successfully added')
            })
            .catch(err => {
                console.log(err);
                notify('error', 'Oops something went wrong');
            })
    }
};

export const changeActiveHistoryTab = tabName => ({
    type: TASKS.CHANGE_ACTIVE_HISTORY_TAB,
    payload: tabName
});


export const setHistory = history => ({
    type: TASKS.SET_HISTORY,
    payload: history
});


export const setSelectedTask = task => ({
    type: TASKS.SET_SELECTED_TASK,
    payload: task
});