import {ANALYSIS_TOOL} from "../constants";

const initualState = {
    isVisibleUploadSchema: false,
    isLoading: false,
    orderId: null,
    activeTab: 'product-list',
    productsErrors: null,
    conditionsFilter: [],
    isImporting: false
};

export const analysisToolReducer = (state = initualState, action) => {
    const {type, payload} = action;

    switch (type) {
        case ANALYSIS_TOOL.IS_IMPORT_FILE_ANALYSIS: {
            return {
                ...state,
                isImporting: payload
            }
        }
        case ANALYSIS_TOOL.SHOW_HIDE_UPLOADING_SCHEMA_ANALYSIS:{
            return {
                ...state,
                isVisibleUploadSchema: payload,
            }
        }
        case ANALYSIS_TOOL.IS_LOADING_ANALYSIS: {
            return {
                ...state,
                isLoading: payload,
            }
        }
        case ANALYSIS_TOOL.SET_ORDER_ID: {
            return {
                ...state,
                orderId: payload,
            }
        }
        case ANALYSIS_TOOL.SET_ACTIVE_TAB_ANALYSIS:{
            return {
                ...state,
                activeTab: payload
            }
        }
        case ANALYSIS_TOOL.SET_ERRORS_COUNT_ANALYSIS: {
            return {
                ...state,
                productsErrors: payload,
            }
        }
        case ANALYSIS_TOOL.SET_FILTER_CONDITIONS_ANALYSIS: {
            return {
                ...state,
                conditionsFilter: payload
            }
        }
        case ANALYSIS_TOOL.SET_PRODUCTS_ANALYSIS: {
            return {
                ...state,
                products: payload,
            }
        }
        default: {
            return state;
        }
    }
};