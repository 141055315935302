import React, { useState,useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { searchProductsInInboundReport } from '../../../../../../services/products'
import { setSearchPackages,setActivePackage,getBoxByPackage } from '../../../../../../actions/inboundingReport'
import { checkSearchReturnOneResult } from '../countResult'
import { notify } from '../../../../../reuseComponent/toast'

const SearchInboundReportComponent = () => {

    const [search,setSearch] = useState('')

    const orderId = useSelector(state => state.purchaseOrdersReducer.detailsPurchase.id)
    const activePackage = useSelector(state => state.inboundingReportReducer.activePackage)

    const dispatch = useDispatch()
    // eslint disable-next-line
    useEffect(() => {
        if(search.length > 7 && search.length < 14)
            searchProductsInInboundReport({
                orderId,
                ean: search
            })
                .then(res => {
                    dispatch(setSearchPackages(res.data))
                    const isOneProductFind = checkSearchReturnOneResult(res.data)
                    if(isOneProductFind){
                        const pack = res.data[res.data.length - 1]
                        if(activePackage.id !== pack.id){
                            dispatch(setActivePackage(pack.id))
                            dispatch(getBoxByPackage(pack.id))
                        }
                    }
                    else{
                        notify('success',`Articles found: ${res.data.reduce((acc,pack) => {
                            let res = 0
                            pack.boxes.forEach(box => {
                                res += box.products.length
                            })
                            return acc + res
                        },0)}`)
                    }
                })
        else
            dispatch(setSearchPackages([]))
        // eslint-disable-next-line
    },[search,dispatch,orderId])

    const handleChange = e => {
        setSearch(e.target.value)
    }

    return (
        <div className="inbound-report-search__wrapper">
            <input
                className="search-inbound-report search-field"
                value={search}
                placeholder="Search by EAN…"
                onChange={handleChange}
            />
            {((search.length > 0 && search.length < 8) || search.length > 13) && <span className="error">Please enter 8 - 13 digits to search</span>}
        </div>
    )
}

export { SearchInboundReportComponent }