
import React, { PureComponent } from 'react'
import { Modal,Form,Button } from 'react-bootstrap'

class CustomModal extends PureComponent{

    state = {
        inputText: '',
        commentToDelete: ''
    }


    static defaultProps = {
        backdropClassName: '',
        classNameModal: '',
        classNameBody: '',
        modalId : '',
        handleConfirm : () => {},
        open : false,
        handleCancel : () => {},
        Content : () => <div></div>,
        handleClose : () => {},
        isFooter : true,
        confirm: 'Confirm',
        cancel: 'Cancel',
        withReason: false,
        options: {

        }
    }

    handleChangeModalInput = (field,value) => {
        this.setState({
            [field] : value
        })
    }

    render(){
        const {
            open,
            handleClose,
            options,
            modalId,
            classNameBody,
            classNameModal,
            backdropClassName,
            TextComponent,
            textToCompare,
            withReason,
            handleConfirm,
            handleCancel
        } = this.props

        const {
            inputText,
            commentToDelete
        } = this.state
        return (
            <Modal
                backdropClassName={backdropClassName}
                dialogClassName={classNameModal}
                id={modalId}
                show={open}
                onHide={() => {
                    this.setState({
                        inputText : '',
                        commentToDelete : ''
                    },handleClose)
                }}
                {...options}
            >
                <Modal.Body className={classNameBody}>
                    <TextComponent/>
                    <Form.Group>
                        {/*<Form.Label></Form.Label>*/}
                        <Form.Control
                            value={inputText}
                            onChange={e => this.handleChangeModalInput('inputText',e.target.value)}
                        />
                    </Form.Group>
                    {withReason && <Form.Group>
                        <Form.Label>Reason to delete</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={commentToDelete}
                            onChange={e => this.handleChangeModalInput('commentToDelete', e.target.value)}
                        />
                    </Form.Group>
                    }
                    <div className="footer-modal-delete footer-form-custom">
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                inputText : '',
                                commentToDelete : ''
                            },handleCancel)
                        }}>Cancel</Button>
                        <Button variant="primary" disabled={inputText !== textToCompare || (commentToDelete === '' && withReason)} onClick={() => {
                            const { inputText,commentToDelete } = this.state
                            this.setState({
                                inputText: '',
                                commentToDelete: ''
                            },() => handleConfirm({
                                inputText: inputText,
                                commentToDelete: commentToDelete
                            }))

                        }}>Confirm</Button>
                    </div>
                </Modal.Body>

            </Modal>
        )
    }
}

export default CustomModal