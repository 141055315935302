import { STOWING } from '../constants'

const initState = {
    packages: [],
    boxes: [],
    activePackage: null,
    isLoadingPackages: false,
    isLoadingBoxes: false,
    putInfo: null,
    isChangeDividing: true
}

export const stowingReducer = (state = initState,action) => {
    const { type,payload } = action
    switch(type){
        case STOWING.IS_CHANGE_DIVIDING: {
            return {
                ...state,
                isChangeDividing: payload
            }
        }
        case STOWING.IS_PUT_PRODUCT: {
            return {
                ...state,
                putInfo: payload
            }
        }
        case STOWING.CLEAR_STOWING_DATA: {
            return {
                ...initState
            }
        }
        case STOWING.SET_ACTIVE_STOWED_PACKAGE: {
            return {
                ...state,
                activePackage: payload
            }
        }
        case STOWING.SET_LOADING_BOXES: {
            return {
                ...state,
                isLoadingBoxes: payload
            }
        }
        case STOWING.SET_LOADING_PACKAGES: {
            return {
                ...state,
                isLoadingPackages: payload
            }
        }
        case STOWING.SET_STOWING_BOXES: {
            return {
                ...state,
                boxes: payload
            }
        }
        case STOWING.SET_STOWING_PACKAGES: {
            return {
                ...state,
                packages: payload
            }
        }
        default : {
            return state
        }
    }
}