import React, {useState, useEffect,useCallback,useMemo} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { Button } from 'react-bootstrap'
import uniqId  from 'uniqid'
import {getArticlesRequest,deleteArticleRequest,editArticleRequest} from '../../../../services/articles'
import {setData} from '../../../../actions/admin-header'
import StandartLeftPanner from '../../../reuseComponent/Header/standartLeftPannel'
import {Pagination} from './Pagination'
import { List } from './List'
import Modal from '../../../reuseComponent/modal/modalWithChildren'
import { ConfirmModalContent } from '../../../reuseComponent/confirmModalComponent'
import Preloader from '../../../reuseComponent/preloader/preloader'
import { notify } from '../../../reuseComponent/toast'
import { createArticleRequest } from '../../../../services/articles'
import { permEditArticles } from '../../../../permissions/dictionaries'
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'
import withTracker from "../../../reuseComponent/GoogleAnalytics";

const Articles = withTracker(() => {
    const [isLoading,setLoading] = useState(true)
    const [activeArticle,setArticle] = useState(null)
    const [openDeleteModal,setOpenDeleteModal] = useState(false)
    const [removedTitle, setRemovedTitle] = useState('');
    const [articles, setArticles] = useState([])
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(25)
    const [search, setSearch] = useState('')
    const [count, setCount] = useState(0)
    const dispatch = useDispatch()
    const roles = useSelector(state => state.rolesReducer.roles)

    const addEmptyArticle = useCallback(() => {
        setArticles([{
            id: uniqId('new-article-'),
            ean: '',
            description: ''
        },...articles])
    },[articles])

    const handleShowModal = useCallback((article) => {
        setArticle(article)
        setRemovedTitle(article.ean);
        setOpenDeleteModal(true)
    },[])

    const handleHideModal = useCallback(() => {
        setOpenDeleteModal(false)
    },[])

    const handleConfirmModal = useCallback(() => {
        setLoading(true)
        setOpenDeleteModal(false)
        deleteArticleRequest(activeArticle.id)
            .then(res => {
                setLoading(false)
                setArticles(articles.filter(article => article.id !== activeArticle.id))
                setCount(count - 1)
                notify('success','Article successfully deleted')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })


    },[activeArticle,articles,count])

    const handleRemoveArticleFromList = useCallback((id) => {
        setArticles(articles.filter(article => article.id !== id))
    },[articles])

    const handleCreateArticle = useCallback((newIdArticle,values) => {
        setLoading(true)
        createArticleRequest(values)
            .then(res => {
                setArticles(articles.map(article => {
                    if(article.id === newIdArticle)
                        return res.data
                    return article
                }))
                setLoading(false)
                setCount(count + 1)
                notify('success','Article successfully created')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    },[articles,count])

    const handleEditArticle = useCallback((newIdArticle,values,callback) => {
        setLoading(true)
        editArticleRequest(newIdArticle,values)
            .then(res => {
                setArticles(articles.map(article => {
                    if(article.id === newIdArticle)
                        return res.data
                    return article
                }))
                setLoading(false)
                callback && callback()
                notify('success','Article successfully edited')
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    },[articles])

    useEffect(() => {
        dispatch(setData({
            leftPannelData: {
                title: 'Articles',
                logo: <svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#795548"
                          d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z"/>
                </svg>,
            },
            LeftPannel: StandartLeftPanner,
            handleSearch: value => setSearch(value)
        }))
    }, [dispatch])

    useEffect(() => {
        setLoading(true)
        getArticlesRequest({
            search: search,
            limit: limit,
            page: page
        })
            .then(res => {
                setLoading(false)
                setCount(res.data.count)
                setArticles(res.data.articles)
            })
    }, [page, limit, search])
    const renderArticles = useMemo(() => {
        return articles.filter(article => {
            if(article.id.toString().indexOf('new-article') < 0)
                return true
            else if(findPermInArray(roles,permEditArticles))
                return true
            return false
        })
    },[articles,roles])
    return (
        <div className="articles-dictionary__wrapper">
            {isLoading && <Preloader />}
            <div className="aritcles-dictionary__header">
                {findPermInArray(roles, permEditArticles) ? <Button className="with-icon" onClick={addEmptyArticle}>
                    <svg viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                    </svg>
                    Add
                </Button>
                    : <span></span>
                }
                <Pagination
                    changePage={setPage}
                    pageSizes={[10, 25, 50]}
                    limit={limit}
                    page={page}
                    count={count}
                    changeLimit={setLimit}
                />
            </div>
            <List
                handleEdit={handleEditArticle}
                handleCreate={handleCreateArticle}
                articles={renderArticles}
                showDeleteModal={handleShowModal}
                removeArticle={handleRemoveArticleFromList}
            />
            <Modal
                open={openDeleteModal}
                handleConfirm={handleConfirmModal}
                handleClose={handleHideModal}
                handleCancel={handleHideModal}
                options={{
                    centered: true,
                    animation: false
                }}
            >
                <ConfirmModalContent text={`delete "${removedTitle}" article`}/>
            </Modal>
        </div>
    )
})

export {Articles}