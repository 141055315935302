import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Tooltip from "../../../reuseComponent/Tooltips";
import {Form} from "react-bootstrap";
import {editTaskAction} from "../../../../actions/purchase-orders";
import {changeTaskExecutor} from "../../../../services/tasks";
// import {url} from "../../../../constants";

class TaskAssignDropdown extends PureComponent{
    state = {
        showHide: false,
        search: '',
    }

    refNode = React.createRef()
    searchFieldRef = React.createRef()

    showHideModal = (modalName, open) => {
        this.setState({
            [modalName]: open
        })
    }

    handleSearch = e => {
        this.setState({
            search: e.target.value,
        })
    }

    handleChangeTaskExecutorOrDepartment = (executorId, departmentId = null) => {
        const {task, tableRef} = this.props;

        const sendData = {
            poUrl: window.location.origin + '/atw/purchase-orders',
            taskUrl: window.location.origin + '/atw/tasks/tickets'
        }


        if(executorId){
            sendData.executorId = executorId
        }else if(departmentId){
            sendData.departmentId = departmentId
        }

        changeTaskExecutor(task.id, sendData)
            .then(res => {
                if(res.data && res.data[1]){
                    // editTaskAction(res.data[1])
                    // tableRef={this.tableRef}
                    const newTask = res.data[1];
                    if(tableRef.current) {
                        const data = tableRef.current.state.data;

                        const newData = data.map(item => item.id !== newTask.id ? item : {...newTask});

                        tableRef.current.dataManager.setData(newData);
                        tableRef.current.setState({...tableRef.current.dataManager.getRenderState()})
                    }
                    const isShow = this.state.showHide;
                    this.setState(state => ({
                        showHide: false,
                        search: '',
                    }), () => {
                        if (isShow)
                            document.removeEventListener('keyup', this.handlePressArrows)
                        else
                            document.addEventListener('keyup', this.handlePressArrows)
                        this.searchFieldRef.current && this.searchFieldRef.current.focus()
                    })
                    this.showHide()
                }
            })
            .catch(err => console.log(err))
    }

    showHide = e => {
        e.stopPropagation()
        const isShow = this.state.showHide
        this.setState(state => ({
            showHide: !state.showHide,
            search: '',
        }), () => {
            if (isShow)
                document.removeEventListener('keyup', this.handlePressArrows)
            else
                document.addEventListener('keyup', this.handlePressArrows)
            this.searchFieldRef.current && this.searchFieldRef.current.focus()
        })
    }

    handleClickOutSide = (event) => {
        if (this.refNode.current && !this.refNode.current.contains(event.target)) {
            this.setState({
                showHide: false,
                search: '',
            }, () => document.removeEventListener('keyup', this.handlePressArrows))
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutSide)
        // console.log(this.props.task);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutSide)
    }

    render(){

        const {task, departments, users, isExecuror} = this.props;
        const {
            search,
            showHide,
        } = this.state;

        const filteredUsers = users.filter(user => {
            const compareString = user.name + user.secondName
            return compareString.toLowerCase().indexOf(search.toLowerCase()) >= 0
        })
        const filteredDepartments = departments.filter(dep => {
            return dep.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        })

        return (
            <div className="wrapper-department-column" ref={this.refNode}>
                <div className="responsible-wrapper task-responsible-wrapper" onClick={this.showHide}>
                    {isExecuror ? <>
                        {task.executor.department && <Tooltip text={task.executor.department.name}>
                        <span
                            style={{marginRight: '5px'}}
                            className="icon-department"
                            dangerouslySetInnerHTML={{__html: task.executor.department.icon }}>
                        </span>
                    </Tooltip>}
                    <Tooltip
                        text={`${task.executor.name} ${task.executor.secondName}`}
                    >
                                <span
                                    className={`executor-value canEdit`}
                                    style={{
                                        color: task.executor.initialsColor
                                    }}
                                >
                                    {task.executor.initials}
                                </span>
                    </Tooltip></> : <Tooltip text={task.department.name}>
                            <span className="icon-department" dangerouslySetInnerHTML={{__html: task.department.icon }}></span>
                        </Tooltip>}
                    <ul className={`list-users-responsible ${showHide ? '' : 'hide'}`}>
                        <li className="search-li">
                            <Form.Control
                                onFocus={() => this.setState({
                                    selectedUser: null
                                })}
                                ref={this.searchFieldRef}
                                placeholder="Search..."
                                onChange={this.handleSearch}
                                value={search}
                            />
                        </li>
                        {filteredUsers.map((user, index) => {
                            const compareString = user.name + user.secondName
                            return compareString.toLowerCase().indexOf(search.toLowerCase()) >= 0 ?
                                <li onClick={(e) => {
                                    e.stopPropagation()
                                    this.handleChangeTaskExecutorOrDepartment(user.id);
                                    // if (!responsibleUser && !department)
                                    //     this.changeResponsible(idPurchase, user)
                                    // else
                                    //     this.setState({
                                    //         currUser: user,
                                    //         confirmModal: true
                                    //     })
                                }} key={user.id}
                                    // className={selectedUser === index ? 'selected-li' : ''}
                                >
                                    <div>{user.name} {user.secondName}</div>
                                </li> : null
                        })}
                        {filteredDepartments.map((dep, index) => {
                            return <li
                                // className={selectedDepartment === index ? 'selected-li' : ''}
                                key={dep.id}
                                onClick={e => {
                                    e.stopPropagation()
                                    this.handleChangeTaskExecutorOrDepartment(null, dep.id);
                                    // if (!responsibleUser && !department)
                                    //     this.changeDepartment(idPurchase, dep)
                                    // else
                                    //     this.setState({
                                    //         currDepartment: dep,
                                    //         confirmChangeDepartment: true
                                    //     })
                                }}
                            >
                                <div>{dep.name}</div>
                            </li>
                        })}
                    </ul>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    users: state.usersReducer.users
});

const mapDispatchToProps = dispatch => ({
    editTaskAction: obj => dispatch(editTaskAction(obj)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskAssignDropdown);