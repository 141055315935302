import React from 'react'
import Modal from '../../modal/modalWithChildren'

const ModalFilter = props => {
    const {
        open,
        handleClose,
        Content,
        children
    } = props
    return (
        <Modal
            classNameModal="po-filter-modal"
            Content={Content}
            open={open}
            isFooter={false}
            handleClose={handleClose}
            options={{
                enforceFocus: false,
                centered: true,
                animation: false
            }}
        >
            {children}
        </Modal>
    )
}

export default ModalFilter