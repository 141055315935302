import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import RowBidView from './row-bid-view'
import RowBidEdit from './edited-row'
import {findPermInArray} from '../../../../../../../../reuseFunctions/checkPermission';
import {permEditInfo} from '../../../../../../../../permissions/productOverview';


class RowBid extends PureComponent{

    state = {
        isEdit: false,
        inboundBegan: false,
        inboundBeganAndProgress: false
    }

    static getDerivedStateFromProps(nextProps,prevState){
        return {
            isEditMain: nextProps.isEditMain,
            isEdit: (nextProps.isEditMain !== prevState.isEditMain && !nextProps.isEditMain) || !findPermInArray(nextProps.roles, permEditInfo) ? false : prevState.isEdit,

        }
    }

    handleChangeMode = (isEdit, inboundBegan, inboundBeganAndProgress) => {
        this.setState({
            isEdit: isEdit,
            inboundBegan: inboundBegan,
            inboundBeganAndProgress: inboundBeganAndProgress,
        })
    }

    render(){
        const { bid, refetchData, bidList } = this.props
        const { isEdit, inboundBegan, inboundBeganAndProgress } = this.state;
        return (
            !isEdit ? <RowBidView bid={bid} bidList={bidList} handleChange={(inboundBegan, inboundBeganAndProgress) => {
                this.handleChangeMode(true, inboundBegan, inboundBeganAndProgress)
            }}/> : <RowBidEdit
                refetchData={refetchData}
                isEdit={true}
                inboundBegan={inboundBegan}
                inboundBeganAndProgress={inboundBeganAndProgress}
                values={bid}
                handleCancel={() => this.handleChangeMode(false)}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        isEditMain : state.poDetailsReducer.isEditMain,
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps,null)(RowBid)