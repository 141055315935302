import React, { PureComponent } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import Toobar from "./toolbar";
import { addSDComment } from "../../../../../actions/serviceDesk";
import { notify } from "../../../../reuseComponent/toast";

class CommentEditor extends PureComponent {
  state = {
    value: this.props.value ? this.props.value : "",
    isFocus: false
  };

  refQuill = React.createRef();

  static defaultProps = {
    isEditing: false,
    commentId: null,
    value: null,
    handleCancel: () => {}
  };

  static modules = {
    toolbar: {
      container: "#toolbar"
    }
  };

  static formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color"
  ];

  handleComment = e => {
    if ((e.key === 'Enter' && e.metaKey) || (e.key === 'Enter' && e.ctrlKey) || e.type === 'click') {
      const { value } = this.state;
      const { request, addSDComment, isEditing } = this.props;
      if (value.trim()) {
        this.refQuill.current && this.refQuill.current.blur();
        if (isEditing) {
        } else {
          this.setState(
            {
              value: ""
            },
            () => {
              addSDComment &&
                request &&
                addSDComment({
                  id: request.id,
                  text: value
                });
            }
          );
        }
      } else {
        notify("warning", "Comment can not be empty");
      }
    }
  }

    handleChange = value => {
      this.setState({
        value: value
      });
    };

  componentDidMount() {
    document.addEventListener("keydown", this.handleComment);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleComment);
  }

  render() {
    const { value, isFocus } = this.state;
    const { isEditing, handleCancel, commentId } = this.props;
    const toolBarId = `toolbar-${commentId ? commentId : "create"}`;
    return (
      <div
        className={`wrap-comment ${isFocus || isEditing ? "with-border" : ""}`}
      >
        <ReactQuill
          ref={this.refQuill}
          theme="snow"
          value={value}
          style={{
            height: isFocus || isEditing ? "100%" : 28
          }}
          onChange={this.handleChange}
          placeholder="Type to comment"
          modules={{
            toolbar: {
              container: "#" + toolBarId
            }
          }}
          formats={CommentEditor.formats}
          onFocus={() =>
            this.setState({
              isFocus: true
            })
          }
          onBlur={() =>
            this.setState({
              isFocus: false
            })
          }
        />
        <Toobar
          id={toolBarId}
          handleCancel={handleCancel}
          isEditing={isEditing}
          style={{ display: isFocus || isEditing ? "block" : "none" }}
          handleSave={this.handleComment}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    request: state.serviceDeskReducer.request
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addSDComment: values => dispatch(addSDComment(values))
    // editComment: values => dispatch(editComment(values))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentEditor);
