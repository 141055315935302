import React, {PureComponent} from 'react'
import MaterialCheckBox from '@material/react-checkbox'
import '@material/react-checkbox/dist/checkbox.css'


class CheckBox extends PureComponent {

    static defaultProps = {
        id: 'my-checkbox',
        checked: false,
        indeterminate: false,
        handleChange: () => {},
        options: {},
        text: ''
    }

    render() {
        const {
            checked,
            indeterminate,
            handleChange,
            options,
            id,
            text
        } = this.props
        return (
            <>
                <MaterialCheckBox
                    nativeControlId={id}
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={handleChange}
                    {...options}
                />
                {text && <label htmlFor={id}>{text}</label>}
            </>
        )
    }
}

export default CheckBox