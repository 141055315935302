import * as yup from 'yup'
import validator from 'validator'


export const schema = yup.object().shape({
    ean: yup.string().test('change length','must be a number between 8-14',value => {
        return value === undefined  || (value.length > 7 && value.length < 15 && validator.isNumeric(value))
    }).required('required field'),
    warehousing: yup.string().required('required field'),
    brand: yup.string(),
    boxId: yup.string().required('required field'),
    // bidId: yup.string().required('required field'),
    styleNumber: yup.string(),
    size: yup.string(),
    season: yup.string(),
    cg1: yup.string().required('required field'),
    cg2: yup.string().required('required field'),
    cg3: yup.string(),
    incomeStock: yup.number().moreThan( 0,"Can't be less than 1").required('required field'),
    rrp: yup.string().test('change length','must be a number',value => {
        const result = value === undefined || value.length === 0 || (value.length > 0) && validator.isFloat(value.replace(',','.').trim())
        return result
    })
})