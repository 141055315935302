import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Tooltip from '../../../../../reuseComponent/Tooltips';
import {notify} from '../../../../../reuseComponent/toast';
import AutosizeInput from 'react-input-autosize';
import {editPackageName} from '../../../../../../services/dividing';
import {changePackageNameAction} from '../../../../../../actions/dividing';
import {findPermInArray} from '../../../../../../reuseFunctions/checkPermission'
import {permEditPackageName} from '../../../../../../permissions/productOverview'

class RenameInput extends PureComponent {
    state = {
        isEdit: false,
        packageName: this.props.pack.fullName
    };

    handleChangeEdit = e => {
        e.stopPropagation();
        this.setState({
            isEdit: true
        });
    };

    handleCheckDuplicateName = () => {
        const {setObject} = this.props;
        const {packageName} = this.state;
        let isDuplicate = false;
        setObject.packages &&
        setObject.packages.forEach(pack => {
            if (pack.fullName === packageName) {
                isDuplicate = true;
            }
        });
        return isDuplicate;
    };
    handleCancel = e => {
        e.stopPropagation();
        this.setState({packageName: this.props.pack.fullName, isEdit: false});
    };
    handleSaveName = (e, id) => {
        e.stopPropagation();
        if (this.handleCheckDuplicateName()) {
            notify('error', 'Package name already exists');
            this.setState({isEdit: false, packageName: this.props.pack.fullName});
            return;
        }
        const {packageName} = this.state;
        const {setObject} = this.props;
        if (packageName === '') {
            notify('error', 'Package name can not be empty');
            this.setState({isEdit: false, packageName: this.props.pack.fullName});
            return;
        }

        editPackageName(id, packageName)
            .then(res => {
                notify('success', 'Package name successfully changed');
                this.setState({isEdit: false});
                this.props.changePackageNameAction(setObject.id, id, packageName);
            })
            .catch(err => {
                this.setState({isEdit: false});
                notify('error', 'Oops something went wrong');
            });
    };

    render() {
        const {isEdit, packageName} = this.state;
        const {pack, roles,details} = this.props;
        return (
            <>
                <AutosizeInput
                    disabled={!isEdit || !findPermInArray(roles, permEditPackageName)}
                    onClick={e => e.stopPropagation()}
                    inputClassName={`package-input ${isEdit ? 'package-input-edit' : ''}`}
                    className="auto-resize-wrapper auto-resize-package-name"
                    value={packageName}
                    onChange={event => {
                        this.setState({packageName: event.target.value});
                    }}
                />
                ({pack.shortName})
                 {isEdit ? (
                     <>
                         {packageName !== this.props.pack.fullName && (
                             <Tooltip text="Save">
                                 <svg
                                     className="action-icon"
                                     onClick={e => this.handleSaveName(e, pack.id)}
                                     viewBox="0 0 24 24"
                                 >
                                     <path
                                         fill="#000000"
                                         d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"
                                     ></path>
                                 </svg>
                             </Tooltip>
                         )}
                         <Tooltip text="Cancel">
                             <svg
                                 className="action-icon"
                                 onClick={this.handleCancel}
                                 viewBox="0 0 24 24"
                             >
                                 <path
                                     fill="#000000"
                                     d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                                 ></path>
                             </svg>
                         </Tooltip>
                     </>
                 ) : (
                     findPermInArray(roles, permEditPackageName) && !details.completeInbound && <Tooltip text="Edit">
                     <svg
                         onClick={this.handleChangeEdit}
                         className="action-icon edit-icon"
                         viewBox="0 0 24 24"
                     >
                         <path
                             fill="#000000"
                             d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                         ></path>
                     </svg>
                 </Tooltip>
                 )}
            </>
        );
    }
}
const mapStateToProps = state => ({
    roles: state.rolesReducer.roles,
    details: state.purchaseOrdersReducer.detailsPurchase
})

export default connect(mapStateToProps, {changePackageNameAction})(RenameInput);
