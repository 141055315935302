import * as yup from 'yup'

export const schema = yup.object().shape({
    referenceNumber: yup.string(),
    pickupAddress: yup.string(),
    pickupDate: yup.mixed(),
    pickupContacts: yup.string(),
    addressNote: yup.string(),
    addressPrice: yup.string(),
    transportShippingCompany: yup.string()
})