import React from 'react'
import { Form, Button } from 'react-bootstrap'
import Select from '../../../../../reuseComponent/Select/select-form'

const EditBoxFormBody = ({values, touched, setFieldValue, errors, handleChange, handleSubmit, handleCancel, bidId}) => {
  return (
    <Form
      noValidate
      onSubmit={handleSubmit}
    >
      <Form.Group>
        <Form.Label>Quantity of Pack{values.name}</Form.Label>
        <Form.Control
          name="item"
          onChange={handleChange}
          isInvalid={errors.item && touched.item}
          value={values.item}
        />
        <Form.Control.Feedback type="invalid">{errors.item}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>BID</Form.Label>
        <Select
            options={bidId}
            value={values.bid}
            name="bid"
            errorLabel={errors.bid}
            isInvalid={errors.bid && touched.bid}
            onChange={valObj => {
                setFieldValue('bid', valObj)
            }}
        />
      </Form.Group>

      <Form.Group className="footer-form footer-form-custom">
        <Button type="button" onClick={handleCancel}>Cancel</Button>
        <Button type="submit">Save</Button>
      </Form.Group>
    </Form>
  )
}

export {
  EditBoxFormBody
}