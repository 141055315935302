import * as yup from 'yup'

export const schema = yup.object().shape({
    username: yup
        .string()
        .email('email is not valid')
        .required('email is a required field'),
    name: yup.string()
        .required('first name is a required field'),
    password : yup.string()
        .required('password is a required field')
        .min(6,'password must be at least 6 characters'),
    roleId: yup.string().required(),
    confirm_password : yup.string()
        .required('confirm password is a required field')
        .oneOf([yup.ref('password'),null],'The passwords you entered do not match.')
        .min(6,'confirm password must be at least 6 characters'),
    secondName: yup.string()
        .required('last name is a required field')
})

