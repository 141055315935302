import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap'
import {Nav} from 'react-bootstrap'
import axios from 'axios'
import {
    getScanningHistory,
    getLastScanned,
    scanProduct, checkScanProduct,
} from '../../../../../services/products'
import Modal from '../../../../reuseComponent/modal/modalWithChildren'
import CreateProduct from './createProduct'
import {TableProducts} from './tableProducts'
import Preloader from '../../../../reuseComponent/preloader/preloader'
import {notify} from '../../../../reuseComponent/toast'
import PutForm from './putProduct'
import {LastArticle} from './lastArticle'
import {getPackagesRequest} from '../../../../../actions/stowing'
import {Pagination} from './Pagination'
import Chip from '../../../../reuseComponent/chips/chip'
import { decoratorSocket,client } from '../../../../../socketClient'
import { getActiveWorkPlace,setActiveWorkPlace } from '../../../../../services/purchaseOrders'
import { WorkPlacesApi } from '../../../../../services/workPlaces'
import {defaultServerError} from '../../../../../reuseFunctions/toasts';
import WorkPlacesDropdown from './workPlacesDropdown'
import {ConfirmModalContent} from '../../../../reuseComponent/confirmModalComponent';

const initPagination = {
    page: 0,
    limit: 25,
}

const generateFilters = filters => {
    let res = ''
    Object.keys(filters).forEach(key => {
        if(Array.isArray(filters[key])){
            res+=`${key}>:${filters[key][0].format('DD.MM.YYYY HH.mm')};${key}<:${filters[key][1].format('DD.MM.YYYY HH.mm')}`
        }
    })
    return res
}


class ScanComponent extends PureComponent {

    inputStart = null
    inputStop = null
    countPressedKeys = 0
    timing = null
    scannedEan = ''
    timeOut = null
    subscribeHistory = null
    isScanning = false
    isConfirmed = false

    state = {
        search: '',
        status: null,
        isShowModal: false,
        packageItem: null,
        productItem: null,
        scannedEan: '',
        lastScannedProduct: null,
        scannedHistory: [],
        openedBoxes: [],
        isLoadingLastArticle: true,
        isLoadingHistory: true,
        isFocus: false,
        duplicatedModal: false,
        activeTab: 'my-history',
        pagination: initPagination,
        count: 0,
        filters: {},
        isEnteredManually: false,
        manuallyEan: '',
        workPlaces: [],
        isLoadingWorkPlaces: true,
        activeWorkPlace: null,
        modalChangeWorkPlace: false,
        selectedWorkPlace: null,
        modalQualityCheck: false,
        qualities: [],
        modalSelectQuality: false,
        selectedQuality: null,
        productDetails: null
    }

    handleConnectToSocket = () => {
        const { details: {id} } = this.props
        this.subscribeHistory = client.subscribe(`/app_log/${id}`, data => {
            const { activeTab } = this.state
            const d = JSON.parse(data.body)
            const { scannedHistory,pagination } = this.state
            if(activeTab !== 'my-history'){
                if(scannedHistory.length === pagination.length){
                    scannedHistory.splice(scannedHistory.length - 1,1)

                }
                this.setState({
                    scannedHistory: [{
                        ...d,
                        user: {
                            name: d.user
                        }
                    },...scannedHistory],
                    count: this.state.count + 1
                })
            }

        })
    }

    componentDidMount() {
        const {details} = this.props
        if (!details.completeInbound)
            document.addEventListener('keypress', this.handleKeyPress)
        this.handleGetHistoryInfo(this.state.pagination)
        decoratorSocket(this.handleConnectToSocket)

        axios.all([WorkPlacesApi.getAllWorkPlaces(),getActiveWorkPlace(details.id)])
          .then(axios.spread((workPlacesRes,activeWorkPlaceRes) => {
              this.setState({
                  isLoadingWorkPlaces: false,
                  workPlaces: workPlacesRes.data.data.content,
                  activeWorkPlace: activeWorkPlaceRes.data.data ? activeWorkPlaceRes.data.data.workPlace : null
              })
          }))
          .catch(err => defaultServerError())
    }

    componentWillUnmount(){
        document.removeEventListener('keypress', this.handleKeyPress)
        this.subscribeHistory && this.subscribeHistory.unsubscribe()
    }

    handleGetHistoryInfo = () => {
        const {details: {id}} = this.props
        const { search,filters } = this.state
        const params = {
            ...this.state.pagination,
            userActivity: this.state.activeTab === 'my-history',
            search: `s:${search};${generateFilters(filters)}`
        }
        this.setState({
            isLoadingHistory: true
        }, () => {
            getScanningHistory(id, params)
              .then(historyRes => {
                  this.setState({
                      count: historyRes.data.data.totalElements,
                      isLoadingHistory: false,
                      scannedHistory: historyRes.data.data.content
                  }, () => this.handleGetLastScanned())
              })
              .catch(err => notify('error', 'Oops something went wrong'))
        })

    }

    handleGetLastScanned = () => {
        const {details: {id}} = this.props
        this.setState({
            isLoadingLastArticle: true
        }, () => {
            getLastScanned(id)
              .then(res => {
                  this.setState({
                      isLoadingLastArticle: false,
                      lastScannedProduct: res.data.data,
                      openedBoxes: res.data.opened_boxes,
                  })
              })
        })
    }

    scanRequest = (quality) => {
        const { isShowModal, activeTab } = this.state
        this.isScanning = true
        const {details: {id, inboundType}} = this.props

        let scannedEan = ''
        if (!this.isConfirmed){
            if(this.scannedEan.trim().length === 12 && !this.state.manuallyEan){
                this.scannedEan = "0" + this.scannedEan.toString()
            }
            scannedEan = this.scannedEan

        }
        else
            scannedEan = this.state.scannedEan

        id && !isShowModal && scanProduct({
            ean: scannedEan ? scannedEan : '3',
            orderId: id,
            isConfirmed: this.isConfirmed,
            quality: quality
        })
          .then(res => {
              // console.log('get response')
              if(inboundType === 'Deep'){
                  if(res.data.qualities && this.props.details.qualityCheck){
                      this.setState({
                          modalSelectQuality: true,
                          qualities: res.data.qualities,
                      })
                  }else{
                      this.isScanning = false
                      this.clearData()
                      if (res.data.status === 'duplicated') {
                          this.setState({
                              isFocus: false,
                              search: '',
                              status: res.data.status,
                              manuallyEan: '',
                              isEnteredManually: false,
                              scannedEan: scannedEan,
                              duplicatedModal: true,
                              productDetails: null,
                          })
                      }
                      else if (res.data.status === 'unordered') {
                          this.setState({
                              isFocus: false,
                              search: '',
                              status: res.data.status,
                              scannedEan: scannedEan,
                              packageItem: res.data.package,
                              manuallyEan: '',
                              isEnteredManually: false,
                              productItem: null,
                              isShowModal: true,
                              productDetails: null,
                              selectedQuality: quality ? quality : '',
                          })
                      }
                      else if (res.data.status === 'overdelivered') {
                          this.setState({
                              isFocus: false,
                              search: '',
                              status: res.data.status,
                              scannedEan: scannedEan,
                              manuallyEan: '',
                              isEnteredManually: false,
                              packageItem: res.data.package,
                              productItem: res.data.data.product,
                              isShowModal: true,
                              productDetails: null,
                              selectedQuality: quality ? quality : '',
                          })
                      }
                      else {
                          this.setState({
                              isFocus: false,
                              isEnteredManually: false,
                              manuallyEan: '',
                              search: '',
                              scannedEan: '',
                              packageItem: null,
                              productItem: null,
                              isShowModal: false,
                              scannedHistory: [...this.state.scannedHistory],
                              productDetails: null,
                              selectedQuality: '',
                          }, () => {
                              activeTab === 'my-history' && this.handleGetHistoryInfo()
                              notify('success', `Article ${scannedEan} stowed`)
                          })
                      }
                  }
              }else{
                  this.isScanning = false
                  this.clearData()
                  this.setState({
                      isFocus: false,
                      search: '',
                      status: res.data.status,
                      scannedEan: scannedEan,
                      packageItem: null,
                      manuallyEan: '',
                      isEnteredManually: false,
                      productItem: null,
                      isShowModal: true,
                      productDetails: res.data.data,
                  })
              }
          })
          .catch(err => notify('error', 'Oops something went wrong'))
          .finally(() => {
              // this.clearData()
              // setTimeout(() => {
              //     this.isScanning = false
              // },500)

          })
    }

    handleScanProduct = (isConfirmed = false) => {
        const { activeWorkPlace } = this.state
        this.isScanning = true
        this.isConfirmed = isConfirmed
        const {details: { qualityCheck}} = this.props
        // let scannedEan = ''
        // // console.log(this.scannedEan,this.scannedEan.length)
        // if (!isConfirmed){
        //     if(this.scannedEan.trim().length === 12 && !this.state.manuallyEan){
        //         this.scannedEan = "0" + this.scannedEan.toString()
        //     }
        //     scannedEan = this.scannedEan
        //
        // }
        // else
        //     scannedEan = this.state.scannedEan
        if(activeWorkPlace){
            // this.isScanning = false
            // this.clearData()
            // if(qualityCheck){
            if(this.props && this.props.details && this.props.details.qualityCheck){

                const { details: {id}} = this.props

                let scannedEan = ''
                if (!this.isConfirmed){
                    if(this.scannedEan.trim().length === 12 && !this.state.manuallyEan){
                        this.scannedEan = "0" + this.scannedEan.toString()
                    }
                    scannedEan = this.scannedEan

                }
                else
                    scannedEan = this.state.scannedEan

                checkScanProduct({
                    ean: scannedEan ? scannedEan : '3',
                    orderId: id,
                })
                  .then(res => {
                      if(res.data.data){
                          this.setState({
                              modalQualityCheck: true
                          })
                      }else{
                          this.scanRequest()
                      }
                  })

                // this.setState({
                //   modalQualityCheck: true
                // })
            }else{
                this.scanRequest(null)
                this.setState({
                    modalSelectQuality: false,
                })
            }
        }
        else{
            notify('error','Please select your workplace')
        }

    }


    showModal = () => this.setState({isShowModal: true})

    hideModal = () => {
        this.handleCloseManually()
        this.setState({isShowModal: false, productItem: null, packageItem: null, search: ''})
    }

    showDuplicatedModal = () => this.setState({duplicatedModal: true})

    hideDuplicatedModal = () => {
        this.handleCloseManually()
        this.setState({
            duplicatedModal: false,
            productItem: null,
            packageItem: null,
            search: ''
        })}

    handleChangeSearch = e => {
        e.persist()
        clearTimeout(this.timeOut)
        this.setState(state => ({
            search: e.target.value
        }),() => {
            this.timeOut = setTimeout(() => {
                this.handleGetHistoryInfo()
            },500)
        })
    }


    handleKeyPress = e => {
        const {details} = this.props
        if (this.timing) {
            clearTimeout(this.timing);
        }
        if (!this.state.isFocus) {
            this.scannedEan += String.fromCharCode(e.charCode)
            this.inputStop = performance.now()
            this.countPressedKeys += 1
            if (!this.inputStart)
                this.inputStart = this.inputStop

            this.timing = setTimeout(this.inputTimeoutHandler, 100)
        }
        else if (e.keyCode === 13 && e.target.classList.value.indexOf('search-field') < 0) {
            if (this.state.manuallyEan.length > 7 && this.state.manuallyEan.length <= 13) {
                this.scannedEan = this.state.manuallyEan
                if (!details.completeInbound) {
                    this.handleScanProduct()
                }
            }
            else {
                notify('error', `Incorrect EAN format. Please enter 8 - 13 digits`)
            }
        }

    }

    isScannerInput = () => {
        const val = this.scannedEan
        return (((this.inputStop - this.inputStart) / val.length) < 50);
    }

    clearData = () => {
        this.inputStart = null
        this.inputStop = null
        this.countPressedKeys = 0
        this.scannedEan = ''
    }

    inputTimeoutHandler = () => {
        clearTimeout(this.timing);
        if (this.isScannerInput() && this.countPressedKeys > 7 && this.isScanning !== true)
            this.handleScanProduct()
        else
            this.clearData()
    }

    handleChangeMode = () => {
        this.setState(state => ({
            scanMode: !state.scanMode
        }))
    }

    changeBoxCallback = (boxName) => {
        const {lastScannedProduct} = this.state
        this.setState({
            scannedHistory: this.state.scannedHistory.map(item => {
                if (item.id === lastScannedProduct.id) {
                    return {
                        ...lastScannedProduct,
                        stowedBoxName: boxName
                    }
                }
                return item
            })
        })
    }

    handleChangeTab = eventKey => {
        this.setState({
            activeTab: eventKey
        },this.handleGetHistoryInfo)
    }

    handleChangeFilters = filters => {
        this.setState({
            filters: {...filters}
        },this.handleGetHistoryInfo)
    }

    handleChangePagination = (params) => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                ...params
            }
        },this.handleGetHistoryInfo)
    }

    handleCheckValidManuallyValue = value => {
        // eslint-disable-next-line
        let validValue = value.replace(/[a-zA-Z]|[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').trim()
        return validValue
    }



    handleOpenCreateArticleForm = () => {
        this.setState({
            isShowModal: true,
            status: 'unordered',
            packageItem: null,
            productDetails: null,
            scannedEan: '',
        })
    }
    handleOpenManually = () => this.setState({isEnteredManually: true})

    handleCloseManually = () => this.setState({isEnteredManually: false,manuallyEan: ''})

    handleSetActiveWorkPlace = () => {
        const { details } = this.props
        const { selectedWorkPlace } = this.state
        this.setState({
            isLoadingWorkPlaces: true,
            modalChangeWorkPlace: false,
            selectedWorkPlace: null
        },() => {
            setActiveWorkPlace(details.id,selectedWorkPlace.id)
              .then(res => {
                  this.setState({
                      isLoadingWorkPlaces: false,
                      activeWorkPlace: selectedWorkPlace,
                      selectedWorkPlace: null
                  })
                  notify('success','Workplace successfully changed')
                  // console.log(res.data)
              })
              .catch(err => defaultServerError())

        })
    }

    handleOpenChangeWorkPlaceModal = workPlace => {
        this.setState({
            modalChangeWorkPlace: true,
            selectedWorkPlace: workPlace
        })
    }

    handleCloseChangeWorkPlaceModal = () => {
        this.setState({
            modalChangeWorkPlace: false,
            selectedWorkPlace: null
        })
    }

    render() {
        const {
            search,
            isShowModal,
            scannedEan,
            scannedHistory,
            productItem,
            packageItem,
            openedBoxes,
            lastScannedProduct,
            status,
            duplicatedModal,
            activeTab,
            isLoadingHistory,
            pagination,
            count,
            filters,
            isEnteredManually,
            manuallyEan,
            isLoadingWorkPlaces,
            workPlaces,
            activeWorkPlace,
            modalChangeWorkPlace,
            selectedWorkPlace,
            modalQualityCheck,
            productDetails,
            modalSelectQuality,
            qualities,
            selectedQuality,
        } = this.state
        const {isLoadingPackages, details,isConnectedToSocket} = this.props
        const searchChips = search.split(',')
        return (
          <div className="inbounding-wrapper">
              {(isLoadingPackages || isLoadingHistory || !isConnectedToSocket || isLoadingWorkPlaces) && <Preloader/>}
              <div className="header-inbounding">
                  <div className="scanned-info">
                      {!lastScannedProduct ? <>
                          No article scanned yet
                      </> : <LastArticle
                        changeBoxCallback={this.changeBoxCallback}
                        actionHistory={lastScannedProduct}
                        openedBoxes={openedBoxes}
                      />}

                  </div>
                  <WorkPlacesDropdown
                    setActiveWorkPlace={this.handleOpenChangeWorkPlaceModal}
                    workPlaces={workPlaces}
                    activeWorkPlace={activeWorkPlace}
                  />
                  <div className="enter-manually-wrapper">
                      {isEnteredManually ? <>
                          <input
                            value={manuallyEan}
                            disabled={details.completeInbound}
                            onChange={e => {
                                let validValue = this.handleCheckValidManuallyValue(e.target.value)
                                this.setState({manuallyEan: validValue})
                            }
                            }
                            onFocus={() => this.setState({
                                isFocus: true
                            })}
                            // onKeyDown={e => e.key === 'Enter' && manuallyEan.length === 13 ? this.handleCloseManually() : null}
                            onBlur={() => this.setState({
                                isFocus: false
                            })}
                            className={`inbound-report-scan-field ${details.completeInbound ? 'search-inactive' : ''}`}
                            placeholder={`Type EAN...`}
                          />
                          <Button className='cancel-manually' onClick={this.handleCloseManually}>Cancel</Button>
                      </> :   <Button className="enter-manually-btn" disabled={details.completeInbound || !activeWorkPlace} onClick={this.handleOpenManually} type="button">Enter EAN manually</Button>}
                      {!isEnteredManually && <Button disabled={details.completeInbound || !activeWorkPlace} onClick={this.handleOpenCreateArticleForm} type="button">Create article</Button>}
                  </div>
              </div>
              <div className="product-list__wrapper">
                  <div className="tabs-inbounding">
                      <Nav
                        variant="tabs"
                        onSelect={this.handleChangeTab}
                        activeKey={activeTab}
                      >
                          <Nav.Item>
                              <Nav.Link eventKey="my-history">My history</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                              <Nav.Link eventKey="general-history">General history</Nav.Link>
                          </Nav.Item>
                      </Nav>
                      <input
                        onFocus={() => this.setState({
                            isFocus: true
                        })}
                        onBlur={() => this.setState({
                            isFocus: false
                        })}
                        // onKeyPress={e => e.stopPropagation()}
                        type="text"
                        value={search}
                        onChange={this.handleChangeSearch}
                        className={`search-field search-scanned`}
                        placeholder={`Search...`}
                      />
                      <Pagination
                        pageSizes={[25, 50, 100, 200, 500]}
                        count={count}
                        changeLimit={(limit) => {
                            this.handleChangePagination(limit < count ? {
                                ...this.state.pagination,
                                limit: limit
                            } : {
                                page: 0,
                                limit: limit
                            })
                        }}
                        changePage={page => {
                            this.handleChangePagination({
                                ...this.state.pagination,
                                page: page
                            })
                        }}
                        {...pagination}
                      />
                  </div>
                  <div className="product-list">
                      <div className="chips">
                          <div className="search-chips">
                              {search.trim().length > 0 && searchChips.map((item,index) => {
                                  return <Chip
                                    key={index}
                                    text={item.trim()}
                                    handleDeleteChip={() => {
                                        searchChips.splice(index,1)
                                        this.handleChangeSearch({
                                            persist: () => {},
                                            target: {
                                                value: searchChips.join(',')
                                            }
                                        })
                                    }}
                                  />
                              })}
                          </div>
                          <div className="filters-chips">
                              {filters.createdAt && <Chip
                                text={`Date: ${filters.createdAt[0].format('DD.MM.YYYY HH:mm')}-${filters.createdAt[1].format('DD.MM.YYYY HH:mm')}`}
                                handleDeleteChip={() => {
                                    this.handleChangeFilters({})
                                }}
                              />}
                          </div>
                      </div>
                      {!activeWorkPlace && !isLoadingWorkPlaces && <div
                        style={{
                            height: 'calc(100% - 46px)',
                            top: 46
                        }}
                        className="notif-to-select-workplace">
                          Please select workplace to start inbounding!
                      </div>
                      }
                      <TableProducts
                        filters={filters}
                        handleChangeFilters={this.handleChangeFilters}
                        activeTab={activeTab}
                        history={scannedHistory}
                      />
                  </div>
              </div>
              <Modal
                open={modalChangeWorkPlace}
                options={{
                    centered: true
                }}
                handleClose={this.handleCloseChangeWorkPlaceModal}
                handleCancel={this.handleCloseChangeWorkPlaceModal}
                handleConfirm={this.handleSetActiveWorkPlace}
              >
                  <ConfirmModalContent text={`change your workplace to "${selectedWorkPlace ? selectedWorkPlace.name : null}"`}/>
              </Modal>
              <Modal
                open={isShowModal}
                isFooter={false}
                backdropClassName="create-product-modal-backdrop"
                options={{
                    centered: true,
                    animation: false
                }}
              >
                  {(status == null || status === 'unordered' || status === 'general') && <CreateProduct
                    activeWorkPlace={activeWorkPlace}
                    packageItem={packageItem}
                    selectedQuality={selectedQuality}
                    putCallback={activeTab === 'my-history' ? this.handleGetHistoryInfo : this.handleGetLastScanned}
                    handleClose={this.hideModal}
                    ean={scannedEan}
                    productDetails={status === 'general' && details.inboundType === 'Custom' ? productDetails : null}
                  />
                  }
                  {status === 'overdelivered' && <PutForm
                    activeWorkPlace={activeWorkPlace}
                    productItem={productItem}
                    packageItem={packageItem}
                    handleClose={this.hideModal}
                    putCallback={activeTab === 'my-history' ? this.handleGetHistoryInfo :  this.handleGetLastScanned}
                  />
                  }
              </Modal>
              <Modal
                classNameModal="opened-box-exist-confirm-modal"
                classNameBody="opened-box-exist-confirm"
                open={duplicatedModal}
                handleCancel={this.hideDuplicatedModal}
                handleConfirm={() => {
                    this.handleScanProduct(true)
                    this.hideDuplicatedModal()
                }}
                options={{
                    centered: true
                }}
              >
                  {status === 'duplicated' &&
                  <h6 className="center-h">EAN {lastScannedProduct.product.ean} is already added
                      to {lastScannedProduct.stowedBoxName},
                      are you sure you want to add one more?</h6>}
              </Modal>
              <Modal
                  open={modalQualityCheck && details.qualityCheck}
                handleClose={() => {
                    this.setState({
                        modalSelectQuality: false
                    })
                }}
                handleCancel={() => {
                    this.setState({
                        modalSelectQuality: false
                    })
                }}
                withConfirm={false}
              >
                  <p><span className="warn-text">Select quality</span></p>
                  <div className="quality-buttons-wrapper">
                      {qualities.map(item => <Button
                        variant="primary"
                        onClick={() => {
                            this.scanRequest(item)
                            this.setState({
                                modalSelectQuality: false,
                            })
                        }}
                      >
                          {item}
                      </Button>)}

                  </div>
              </Modal>
              <Modal
                open={modalQualityCheck && details.qualityCheck}
                handleClose={() => {
                    this.setState({
                        modalQualityCheck: false,
                        qualities: [],
                    })
                }}
                handleCancel={() => {
                    this.setState({
                        modalQualityCheck: false,
                        qualities: []
                    })
                }}
                withConfirm={false}
              >
                  <p><span className="warn-text">Select quality</span></p>
                  <div className="quality-buttons-wrapper">
                      <Button
                        variant="primary"
                        onClick={() => {
                            this.scanRequest('A')
                            this.setState({
                                modalQualityCheck: false
                            })
                        }}
                      >
                          A
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                            this.scanRequest('B')
                            this.setState({
                                modalQualityCheck: false
                            })
                        }}
                      >
                          B
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                            this.scanRequest('C')
                            this.setState({
                                modalQualityCheck: false
                            })
                        }}
                      >
                          C
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                            this.scanRequest('D')
                            this.setState({
                                modalQualityCheck: false
                            })
                        }}
                      >
                          D
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                            this.scanRequest('2nd Hand')
                            this.setState({
                                modalQualityCheck: false
                            })
                        }}
                      >
                          2nd Hand
                      </Button>
                  </div>
              </Modal>
          </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        isLoadingPackages: state.stowingReducer.isLoadingPackages,
        isChangeDividing: state.stowingReducer.isChangeDividing,
        isConnectedToSocket: state.adminGlobal.isSocketConnected
    }
}

export default connect(mapStateToProps, {
    getPackages: getPackagesRequest
})(ScanComponent)