import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import EditSupplier from '../createEdit/edit'
import CreateSupplier from '../createEdit/create'
import { permEditSuppliers } from '../../../../permissions/suppliers'
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'

class TaskDetails extends PureComponent {

    render() {
        const { roles,cancelCreate, handleShowRemoveModal } = this.props
        const id = this.props.match.params.id
        return (
            <div className="details suppliers-details">
                <div className="content-details">
                    {id === 'new' && findPermInArray(roles,permEditSuppliers)  && <CreateSupplier
                        cancelCreate={cancelCreate}
                        callbackAfterCreation={this.props.callbackAfterCreation} isView={false}
                    />}
                    {id !== 'new' && id !== undefined && <EditSupplier
                            handleShowRemoveModal={handleShowRemoveModal}
                            isView={!findPermInArray(roles,permEditSuppliers)}
                            callbackAfterEdit={this.props.callbackAfterCreation}
                        />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps, null)(TaskDetails)