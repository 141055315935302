import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import Collapse from './collapse/index'
import HeaderSetCollapse from './header-collapse-set'
import PackageSetCollapse from './package-collapse-item'
import { combinePackage } from '../../../../../../actions/dividing'


window['__react-beautiful-dnd-debug-timings-hook__'] = true

class AllSetsPackagesComponent extends PureComponent {

    state = {
        activePackage: null
    }

    handleDragEnd = ({...rest}) => {
        if(rest.combine && rest.combine.draggableId && rest.draggableId){
            const { combinePackage } = this.props
            combinePackage && combinePackage(rest.draggableId,rest.combine.draggableId)
        }
    }

    handleDragUpdate = ({...rest}) => {
        // console.log(rest)
        if(rest.combine && rest.combine.draggableId){
            this.setState(state => ({
                activePackage: rest.combine.draggableId
            }))
        }
        else {
            this.setState(state => ({
                activePackage: null
            }))
        }
    }

    render() {
        const {sets} = this.props
        const { activePackage } = this.state
        return (
            <DragDropContext
                onDragEnd={this.handleDragEnd}
                onDragUpdate={this.handleDragUpdate}
            >
                <div className="all-packages-wrapper">
                    {sets.filter(set => !set.rest && !set.unordered && !set.overdelivered && !set.custom).map(set =>
                        <Droppable getContainerForClone={() => <div style={{height: '1px'}}></div>} isCombineEnabled key={set.id} droppableId={set.id}>
                            {(provided) => {
                                // console.log(provided.placeholder)
                                return(
                                    <div  ref={provided.innerRef} {...provided.droppableProps}>
                                        <Collapse
                                            data={{
                                                set: set
                                            }}
                                            dataBody={{
                                                set: set,
                                                activePackage: activePackage
                                            }}
                                            Toggle={HeaderSetCollapse}
                                            Body={ListPackages}
                                        />
                                        {provided.placeholder}
                                    </div>
                                )
                            }}
                        </Droppable>
                    )}
                </div>
            </DragDropContext>
        )
    }
}

const ListPackages = ({set,activePackage}) => {
    return <>
        {set.packages ? set.packages.map((pack,index) =>
            <PackageSetCollapse
                index={index}
                key={pack.id}
                activePackage={activePackage}
                pack={pack}
                set={set}
            />
        ) : null}

    </>
}

const mapStateToProps = state => {
    return {
        sets: state.dividingReducer.conditionSets
    }
}

const mapDispatchToProps = dispatch => {
    return {
        combinePackage: (packageFrom,packageTo) => dispatch(combinePackage(packageFrom,packageTo))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllSetsPackagesComponent)
