import reducer from './reducers'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose

const enhancer = composeEnhancers(
    applyMiddleware(thunk)
)

const store = createStore(reducer,enhancer)

// window.store = store // only for dev

export default store