import React, { PureComponent } from 'react'
// import './style.scss'
import ToolTip from '../../Tooltips'

class IconButton extends PureComponent{
    render(){
        const { title,customClass = '',onClick = () => {} } = this.props
        return(
            title ?
                    <ToolTip
                        text={title}
                    >
                        <button type="button" className={`icon-button-material ${customClass}`} onClick={onClick}>
                            {this.props.children}
                        </button>
                    </ToolTip>
                :
                <button onClick={onClick}>
                    {this.props.children}
                </button>
        )
    }

}

export default IconButton