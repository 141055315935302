import * as yup from 'yup'

export const schema = yup.object().shape({
    title: yup.string().required('Title is a required field'),
    email: yup.string(),
        // .required('Email is a required field')
        // .email('Invalid email'),
    contacts: yup.array()
        .of(
            yup.object().shape({
                name: yup.string().required('Required field'),
                secondName: yup.string().required('Required field'),
                phone: yup.number().required('Required field').typeError('Must be a number'),
                email: yup.string().required('Required field').email('Invalid email format'),
                position: yup.string().required('Required field').typeError('Required field')
            })
        ),
    warehouseAddress: yup.array().of(yup.string().required('Required field')),
    buyersId: yup.string().required('Buyer is a required field'),
    additionalInfo: yup.string(),
    address: yup.mixed(),
    camouflaging: yup.string(),
})