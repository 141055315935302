import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import TaskAssignDropdown from '../taskAssignDropdown'
import {findPermInArray} from '../../../../../reuseFunctions/checkPermission'
import {permEditTasks} from '../../../../../permissions/tasks'
import Tooltip from '../../../../reuseComponent/Tooltips'

class TaskResponsibleComponent extends PureComponent {
    render() {
        const {
            rowData,
            hasPermToComplete,
            departments,
            tableRef,
            roles,
        } = this.props;

        if (rowData.executor && rowData.executor.name) {
            return <span className='executor-wrapper'>
                {hasPermToComplete ?
                    <TaskAssignDropdown
                        departments={departments}
                        task={rowData}
                        isExecuror={rowData.executor}
                        tableRef={tableRef}
                    />
                    : <>
                        {rowData.executor.department && <Tooltip text={rowData.executor.department.name}>
                            <span style={{ marginRight: '5px' }} className="icon-department"
                                dangerouslySetInnerHTML={{ __html: rowData.executor.department.icon }}></span>
                        </Tooltip>}
                        <Tooltip
                            text={`${rowData.executor.name} ${rowData.executor.secondName}`}
                        >
                            <span className={`executor-value`} style={{
                                color: rowData.executor.initialsColor
                            }}>{rowData.executor.initials}</span>
                        </Tooltip>
                    </>}
            </span>
        } else {
            return <span>{rowData.department && (findPermInArray(roles, permEditTasks) ? <TaskAssignDropdown
                departments={departments}
                task={rowData}
                isExecuror={false}
                tableRef={tableRef}
            /> : <Tooltip text={rowData.department.name}>
                    <span className="icon-department"
                        dangerouslySetInnerHTML={{ __html: rowData.department.icon }}></span>
                </Tooltip>)
            }</span>
        }
    }
}

TaskResponsibleComponent.propTypes = {
    rowData: PropTypes.object.isRequired,
    departments: PropTypes.arrayOf(PropTypes.object),
    hasPermToComplete: PropTypes.bool.isRequired,
    tableRef: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
}

export default TaskResponsibleComponent;
