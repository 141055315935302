import React, { useState, useEffect, useCallback, useRef } from "react";
import { ReactComponent as SkipPrevIcon } from "../../../assets/images/skip-prev.svg";
import { ReactComponent as SkipNextIcon } from "../../../assets/images/skip-next.svg";
import { ReactComponent as PrevIcon } from "../../../assets/images/prev.svg";
import { ReactComponent as NextIcon } from "../../../assets/images/next.svg";
import { ReactComponent as BottomArrow } from "../../../assets/images/triangle-bottom-arrow.svg";
import Tooltip from "../Tooltips";
import "./Pagination.scss";

const Pagination = ({
  rowsPerPageOptions,
  rowsPerPage,
  page,
  onChangeRowsPerPage,
  count,
  onChangePage
}) => {
  const [isOpenLimitList, setOpenLimitList] = useState(false);
  const listRef = useRef(null);

  const handleClickOutSide = useCallback(event => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setOpenLimitList(false);
    }
  }, []);

  const handleShowHideList = useCallback(() => {
    setOpenLimitList(!isOpenLimitList);
  }, [isOpenLimitList]);

  const handleSkipNext = useCallback((e) => {
    onChangePage(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }, [count, rowsPerPage, onChangePage]);

  const handleNext = useCallback((e) => {
    onChangePage(e,page + 1);
  }, [page, onChangePage]);

  const handlePrev = useCallback((e) => {
    onChangePage(e, page - 1);
  }, [page, onChangePage]);

  const handleSkipPrev = useCallback((e) => {
    onChangePage(e, 0);
  }, [onChangePage]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide);
    return () => {
      document.removeEventListener("click", handleClickOutSide);
    };
  });
  return (
    <div className="pagination article-pagination">
      <div className="limit-list" ref={listRef}>
        <button className="page-size-btn" onClick={handleShowHideList}>
          {rowsPerPage} rows <BottomArrow />
        </button>
        {isOpenLimitList && (
          <ul>
            {rowsPerPageOptions.map(pageSize => (
              <li
                onClick={(e) => {
                  setOpenLimitList(false);
                  onChangeRowsPerPage(e);
                }}
                key={pageSize}
              >
                {pageSize}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="pagination__actions">
        <button
          onClick={handleSkipPrev}
          className="skip-prev btn-pagination"
          disabled={page === 0}
          style={
            page === 0
              ? {
                  pointerEvents: "none"
                }
              : null
          }
        >
          <Tooltip text="First page">
            <SkipPrevIcon />
          </Tooltip>
        </button>
        <button
          onClick={handlePrev}
          className="prev btn-pagination"
          disabled={page === 0}
          style={
            page === 0
              ? {
                  pointerEvents: "none"
                }
              : null
          }
        >
          <Tooltip text="Previous page">
            <PrevIcon />
          </Tooltip>
        </button>
        <div className="pagination__info">
          {count > 0 ? page * rowsPerPage + 1 : 0}-
          {Math.min((page + 1) * rowsPerPage, count)} of {count}
        </div>
        <button
          onClick={handleNext}
          className="next btn-pagination"
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          style={
            page >= Math.ceil(count / rowsPerPage) - 1
              ? {
                  pointerEvents: "none"
                }
              : null
          }
        >
          <Tooltip text="Next page">
            <NextIcon />
          </Tooltip>
        </button>
        <button
          onClick={handleSkipNext}
          className="skip-next btn-pagination"
          style={
            page >= Math.ceil(count / rowsPerPage) - 1
              ? {
                  pointerEvents: "none"
                }
              : null
          }
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <Tooltip text="Last page">
            <SkipNextIcon />
          </Tooltip>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
