import { PO_SMART_FILTER } from '../constants'

const initState = {
    filters: {}
}

export const poSmartFiltersReducer = (state = initState,action) => {
    const { type,payload } = action
    switch(type){
        case PO_SMART_FILTER.SET_PO_FILTERS: {
            return {
                ...state,
                filters: payload
            }
        }
        default: {
            return state
        }
    }
}