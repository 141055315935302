import { url } from '../constants'
import adminInstance from '../components/instances/admininstance'

const convertValuesWithArray = values => {
    const formData = new FormData()
    Object.entries(values).forEach(([key,value]) => {
        if(Array.isArray(value)){
            value.forEach(val => {
                formData.append(`${key}`,val)
            })
        }
        else
            formData.append(key,value)
    })
    return formData
}

export const getCloseBoxesByPoId = (poId) => {
    return adminInstance.get(`${url}/box/get_by_po/${poId}`)
}

export const getClosedBoxesByPoWithPallet = (poId,palletId) => {
    return adminInstance.get(`${url}/box/get_closed_by_po/${poId}`,{
        params: {
            palletId: palletId
        }
    })
}

export const getProductsFromBox = boxId => {
    return adminInstance.get(`${url}/box/${boxId}/products`)
}

export const getBoxByBarcode = (id) => {
    return adminInstance.get(`${url}/box/scan/${id}`)
}

export const openBox = (boxId) => {
    return adminInstance.put(`${url}/box/${boxId}/open`)
}

export const closeBox = (boxId) => {
    return adminInstance.put(`${url}/box/${boxId}/close`)
}

export const moveArticles = (values) => {
    return adminInstance.post(`${url}/box/moving_article/`,convertValuesWithArray(values))
}

export const createBoxIntoPallet = (palletId) => {
    return adminInstance.post(`${url}/box/create_from_pallet/${palletId}`)
}

export const getBoxInfoService = id =>
    adminInstance.get(`${url}/box/${id}/info`)

export const removeBox = (id) => {
    return adminInstance.delete(`${url}/box/${id}`)
}

export const editBox = (boxId, count, bidId) => {
    return adminInstance.put(`${url}/box/${boxId}`, {}, {
        params: {
            itemProduct: count,
            bidId: bidId,
        }
    })
}