import React from 'react'
import { Switch } from 'react-router-dom'
import List from './list'
import Create from './create'
import Edit from './edit'
import ProtectedRoute from "../../../reuseComponent/Routes/protected-route";
import {permEditCg3Dictionary, permViewCg3Dictionary} from "../../../../permissions/dictionaries";
import withTracker from "../../../reuseComponent/GoogleAnalytics";

const Categories = () => {
    return (
        <Switch>
            <ProtectedRoute
                permission={permViewCg3Dictionary}
                exact path="/atw/dictionaries/cg3"
                component={List}
            />
            <ProtectedRoute
                permission={[permEditCg3Dictionary]}
                exact
                path="/atw/dictionaries/cg3/create"
                component={Create}
            />
            <ProtectedRoute
                permission={[permEditCg3Dictionary]}
                exact
                path="/atw/dictionaries/cg3/edit/:id"
                component={Edit} />
        </Switch>
    )
}

export default withTracker(Categories)