import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Table, Button } from "react-bootstrap";
import ReceivedRow from "./received-row";
import ResultRow from "./result-row";
import { checkIfObjectEmptyValues } from "../../../../../../../reuseFunctions/objectFunctions";
import { permEditInbound } from "../../../../../../../permissions/productOverview";
import { findPermInArray } from "../../../../../../../reuseFunctions/checkPermission";
import { isEditInboundAction } from "../../../../../../../actions/detailsPo";
import Tooltip from "../../../../../../reuseComponent/Tooltips/index";
import {
  inboundQuantitySchema,
  inboundPackSchema,
  inboundArrivalDateSchema,
  inboundPalletsSchema,
  inboundGrossKgSchema,
  inboundNetKgSchema
} from './schema'

class TableReceived extends PureComponent {

  state = {
    arrivalDateError: '',
    quantityError: '',
    packsError: '',
    palletsError: '',
    grossKgError: '',
    netKgError: '',
    touched: false,
  }

  handleValidateBeforeSave = () => {
    const { handleSubmit, values } = this.props
    const { packsError, arrivalDateError} = this.state
    if(packsError || arrivalDateError){
      this.setState({
        touched: true
      })
    }else{
      Promise.all([
        inboundArrivalDateSchema.validate({actualArrivalDate: values.actualArrivalDate}),
        inboundQuantitySchema.validate({actualQuantity: parseInt(values.actualQuantity)}),
        inboundPackSchema.validate({actualPackagesCount: parseInt(values.actualPackagesCount)}),
        inboundPalletsSchema.validate({actualPalletsCount: parseInt(values.actualPalletsCount)}),
        inboundGrossKgSchema.validate({actualGrossWeight: parseInt(values.actualGrossWeight)}),
        inboundNetKgSchema.validate({actualNetWeight: parseInt(values.actualNetWeight)}),
      ])
        .then(res => {
          this.setState({
            touched: false
          })
          handleSubmit()
        })
        .catch(err => {
          this.setState({
            touched: true,
            [err.path === 'actualPackagesCount' ? 'packsError' : (
                err.path === 'actualQuantity' ? 'quantityError' : (
                    err.path === 'actualPalletsCount' ? 'palletsError' : (
                        err.path === 'actualGrossWeight' ? 'grossKgError' : (
                            err.path === 'actualNetWeight' ? 'netKgError' : 'arrivalDateError'))))]: err.errors[0]
          })
        })
    }
  }

  handleChangeArrivalDate = (name, value) => {
    const { changeValues} = this.props;
      inboundArrivalDateSchema.validate({actualArrivalDate: value})
        .then(res => {
          this.setState({
            arrivalDateError: ''
          }, () => changeValues(name, value))
        })
        .catch(err => {
          // console.log(err)
          this.setState({
            arrivalDateError: err.errors[0],
          }, () => {
            changeValues(name, value)
          })
        })
  }

  handleChangeQuantity = (name, value) => {
    const { changeValues} = this.props;
    inboundQuantitySchema.validate({actualQuantity: parseInt(value)})
      .then(res => {
        this.setState({
          quantityError: ''
        }, () => changeValues(name, value))
      })
      .catch(err => {
        // console.log(err)
        this.setState({
          quantityError: err.errors[0],
        }, () => {
          changeValues(name, value)
        })
      })
  }

  handleChangePallets = (name, value) => {
    const { changeValues} = this.props;
    inboundPalletsSchema.validate({actualPalletsCount: parseInt(value)})
        .then(res => {
          this.setState({
            palletsError: ''
          }, () => changeValues(name, value))
        })
        .catch(err => {
          // console.log(err)
          this.setState({
            palletsError: err.errors[0],
          }, () => {
            changeValues(name, value)
          })
        })
  }

  handleChangeGrossKg = (name, value) => {
    const { changeValues} = this.props;
    inboundGrossKgSchema.validate({actualGrossWeight: value})
        .then(res => {
          this.setState({
            grossKgError: ''
          }, () => changeValues(name, value))
        })
        .catch(err => {
          // console.log(err)
          this.setState({
            grossKgError: err.errors[0],
          }, () => {
            changeValues(name, value)
          })
        })
  }

  handleChangeNetKg = (name, value) => {
    const { changeValues} = this.props;
    inboundNetKgSchema.validate({actualNetWeight: value})
        .then(res => {
          this.setState({
            netKgError: ''
          }, () => changeValues(name, value))
        })
        .catch(err => {
          // console.log(err)
          this.setState({
            netKgError: err.errors[0],
          }, () => {
            changeValues(name, value)
          })
        })
  }

  handleChangePackCount = (name, value) => {
    const { changeValues } = this.props;
    inboundPackSchema.validate({actualPackagesCount: parseInt(value)})
      .then(res => {
        changeValues(name, value)
        this.setState({
          packsError: ''
        }, () => changeValues(name, value))
      })
      .catch(err => {
        // console.log(err)
        this.setState({
          packsError: err.errors[0],
        }, () => {
          changeValues(name, value)
        })
      })
  }

  componentDidUpdate(prevProps){
    if(!this.props.isEditInbound){
      this.setState({
        touched: false,
        quantityError: '',
        packsError: '',
        palletsError: '',
        grossKgError: '',
        netKgError: '',
      })
    }
  }

  render() {
    const {
      changeValues,
      values,
      isEditInbound,
      detailsWrapper,
      handleChangeMode,
      setEditStatus,
      roles,
      details
    } = this.props;
    const { packsError, quantityError, arrivalDateError, palletsError, grossKgError, netKgError, touched} = this.state;

    return (
      <div className="wrapper-received-table">
        <Table size="sm" responsive>
          <thead>
            <tr>
              <th>Received</th>
              <th>Quantity</th>
              <th>Packs</th>
              <th>Pallets</th>
              <th>Gross kg</th>
              <th>Net kg</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className="received-row">
              {!checkIfObjectEmptyValues(values, ["", null, undefined, "{}"]) ||
              isEditInbound ? (
                <>
                  <ReceivedRow
                    changeValues={changeValues}
                    values={values}
                    detailsWrapper={detailsWrapper}
                    quantityError={quantityError}
                    packsError={packsError}
                    palletsError={palletsError}
                    grossKgError={grossKgError}
                    netKgError={netKgError}
                    arrivalDateError={arrivalDateError}
                    handleChangeQuantity={this.handleChangeQuantity}
                    handleChangePackCount={this.handleChangePackCount}
                    handleChangePallets={this.handleChangePallets}
                    handleChangeGrossKg={this.handleChangeGrossKg}
                    handleChangeNetKg={this.handleChangeNetKg}
                    handleChangeArrivalDate={this.handleChangeArrivalDate}
                    touched={touched}
                  />
                </>
              ) : (
                <>
                  <td style={{paddingLeft: '0'}}>
                    {findPermInArray(roles, permEditInbound) &&
                      !details.completeInbound && details.inboundType === 'Totals' && (
                      <Button
                            variant="primary"
                          className="standard"
                          onClick={() =>
                            handleChangeMode(() => setEditStatus(true))
                          }
                        >
                          <svg viewBox="0 0 24 24">
                            <path
                              fill="#000000"
                              d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                            />
                          </svg>
                          Add
                        </Button>
                      )}
                  </td>
                  <td colSpan={5}>
                    <div className="no-data-to-display">
                      No data to display...
                    </div>
                  </td>
                </>
              )}
              <td style={isEditInbound ? {textAlign: 'right'} : {paddingRight: '0', textAlign: 'right'}}>
                {findPermInArray(roles, permEditInbound) &&
                  !details.completeInbound && (
                    <>
                      {!isEditInbound && !checkIfObjectEmptyValues(values, ["", null, undefined, "{}"]) && details.inboundType === 'Totals' && (
                        <Button
                            variant="primary"
                          className="standard with-margin"
                          onClick={() =>
                            handleChangeMode(() => setEditStatus(true))
                          }
                        >
                          <svg viewBox="0 0 24 24">
                            <path
                              fill="#000000"
                              d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                            />
                          </svg>
                          Edit
                        </Button>
                      )}

                      {isEditInbound && (
                        <div className="action-cell">
                          {!checkIfObjectEmptyValues(values, ["", null, undefined, "{}"]) && <button className="action-row-button">
                            <Tooltip text="Save">
                              <svg
                                viewBox="0 0 24 24"
                                onClick={this.handleValidateBeforeSave}
                              >
                                <path
                                  fill="#000000"
                                  d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"
                                />
                              </svg>
                            </Tooltip>
                          </button>}

                          <button className="action-row-button">
                            <Tooltip text="Cancel">
                              <svg
                                viewBox="0 0 24 24"
                                onClick={() =>
                                  handleChangeMode(() => setEditStatus(false))
                                }
                              >
                                <path
                                  fill="#000000"
                                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                                />
                              </svg>
                            </Tooltip>
                          </button>
                        </div>
                      )}
                    </>
                  )}
              </td>
            </tr>
            {!checkIfObjectEmptyValues(values, ["", null, undefined, "{}"]) ||
            isEditInbound ? (
              <ResultRow />
            ) : null}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isEditInbound: state.poDetailsReducer.isEditInbound,
    roles: state.rolesReducer.roles,
    details: state.purchaseOrdersReducer.detailsPurchase
  };
};
const mapDispatchToProps = dispatch => ({
  setEditStatus: isEdit => dispatch(isEditInboundAction(isEdit))
});

export default connect(mapStateToProps, mapDispatchToProps)(TableReceived);
