import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import BodyForm from "./bodyForm";
import { schema, schemaCustomAmountPackage } from './schema'
import {
  fetchCreateCustomPackage,
  fetchCreateCustomPackageWithAmountOfPacks,
} from "../../../../../../actions/inboundingReport";
import { notify } from "../../../../../reuseComponent/toast";

class Form extends PureComponent {

  state = {
    buttonPressed: false,
    isPacks: false,
  }

  initValues = {
    title: "",
    description: "",
    countPack: 1,
  };

  handleSubmit = values => {
    this.setState({
      buttonPressed: true
    }, () => {
      const {
        fetchCreateCustomPackage,
        fetchCreateCustomPackageWithAmountOfPacks,
        handleCancel,
        packages,
        checkExistTitle
      } = this.props;
      const { isPacks } = this.state
      if (!checkExistTitle(packages, values.title)) {
        if(isPacks){
          fetchCreateCustomPackageWithAmountOfPacks(values, () => {
            handleCancel()
            this.setState({
              buttonPressed: false
            })
          });
        }else{
          fetchCreateCustomPackage(values, () => {
            handleCancel()
            this.setState({
              buttonPressed: false
            })
          });
        }
      } else {
        notify("error", "Package name already exists");
        handleCancel();
        this.setState({
          buttonPressed: false
        })
      }
    })
  };

  updatePackType = (isPacks) => {
    this.setState({
      isPacks
    })
  }

  render() {
    const { handleCancel, packages } = this.props;
    const { buttonPressed, isPacks } = this.state;
    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={{...this.initValues, packages}}
        validationSchema={isPacks ? schemaCustomAmountPackage : schema}
        render={props => <BodyForm
          {...props}
          buttonPressed={buttonPressed}
          handleCancel={handleCancel}
          isPacks={isPacks}
          updatePackType={this.updatePackType}
          isCreate
        />}
      />
    );
  }
}
const mapStateToProps = state => ({
  packages: state.inboundingReportReducer.packages,
});

export default connect(mapStateToProps, { fetchCreateCustomPackage, fetchCreateCustomPackageWithAmountOfPacks })(Form);
