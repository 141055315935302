import React, {PureComponent} from 'react'
import {Form, Button} from 'react-bootstrap'

class ModalBodyWithReason extends PureComponent {

    state = {
        reason: '',
        touch: false
    }

    render() {
        const {reason, touch} = this.state
        const {handleSubmit, handleCancel} = this.props
        console.log(reason)
        return (
            <>

                <h6 className="center-h">Please enter the reason and confirm canceling</h6>
                <Form.Group>
                    <Form.Label>Reason</Form.Label>
                    <Form.Control
                        as="textarea"
                        isInvalid={!reason && touch}
                        value={reason}
                        onChange={e => this.setState({
                            reason: e.target.value
                        })}
                    />
                    <Form.Control.Feedback type="invalid">required field</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="footer-form footer-form-custom">
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={() => {
                        if (reason) {
                            this.setState({
                                reason: '',
                                touch: false
                            }, () => handleSubmit(reason))
                        }
                        else {
                            this.setState({
                                touch: true
                            })
                        }
                    }}>Confirm</Button>
                </Form.Group>
            </>
        )
    }
}

export default ModalBodyWithReason