import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Table} from 'react-bootstrap'
import * as moment from 'moment-timezone'
import DateRangePicker from '../../../../../reuseComponent/date-range-picker';

class TableProductsComponent extends PureComponent {


    render() {
        const {history = [], activeTab, handleChangeFilters, filters} = this.props
        const isGeneralHistory = activeTab === 'general-history'
        const [startDate,endDate] = filters.createdAt ? filters.createdAt : []
        return (
            <Table size="sm" className="inbound-table">
                <thead>
                <tr>
                    <th>
                        <DateRangePicker
                            singleDatePicker={false}
                            onApply={(event, picker) => {
                                handleChangeFilters({
                                    createdAt: [picker.startDate, picker.endDate]
                                })
                            }}
                            options={{
                                timePicker24Hour: true,
                                timePicker: true
                            }}
                            maxDate={moment(moment()).tz('Europe/Berlin')}
                            startDate={startDate ? startDate : null}
                            endDate={endDate ? endDate : null}
                        >
                            <svg className="standart-svg filter-icon" viewBox="0 0 24 24">
                                <path fill="#000000" d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"/>
                            </svg>
                        </DateRangePicker> Date
                    </th>
                    {isGeneralHistory && <th>
                        User
                    </th>
                    }
                    <th>
                        Action
                    </th>
                </tr>
                </thead>
                <tbody>
                {history.length > 0 ? history.map(action => {
                    return <tr key={action.text+action.createdAt}>
                        <td>
                            {moment(action.createdAt, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm')}
                        </td>
                        {isGeneralHistory && <td>
                            {action.user && action.user.name}
                        </td>}
                        <td dangerouslySetInnerHTML={{__html: action.text}}></td>
                    </tr>
                }) : <tr className="no-data-to-display-row">
                    <td colSpan={isGeneralHistory ? '3' : '2'}>
                        No data to display...
                    </td>
                </tr>}
                </tbody>
            </Table>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

const TableProducts = connect(mapStateToProps, null)(TableProductsComponent)

export {TableProducts}