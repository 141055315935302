import React from 'react'
import { Switch } from 'react-router-dom'
import ListBrands from './list'
import ProtectedRoute from '../../../reuseComponent/Routes/protected-route'
import { permViewBrandsDictionary,permEditBrandsDictionary } from '../../../../permissions/dictionaries'


export const BrandsModule = () => {
    return (
        <Switch>
            <ProtectedRoute permission={[permViewBrandsDictionary,permEditBrandsDictionary]} exact path="/atw/dictionaries/brands" component={ListBrands} />
        </Switch>
    )
}

export default BrandsModule