export const convertToFormData = (object) => {
    let formData = new FormData();
    if(typeof object === 'object'){
        Object.entries(object).map(([key,value]) => {
           return formData.append(key,value)
        })
    }
    return formData
}

export const convertToFormDataWithSingleArray = (object, isFiles = false) => {
    let formData = new FormData();
    if(typeof object === 'object'){
        Object.entries(object).forEach(([key,value]) => {
            if(typeof value === 'object'){
                value.forEach(val => {
                    if(isFiles) {
                        formData.append(key,val)
                    } else {
                        formData.append(key,val.value)
                    }
                })
            }
            else{
                formData.append(key, value);
            }
        })
    }
    return formData
}

export const converToFormDataWithJson = (object) => {
    let formData = new FormData()
    if(typeof object  === 'object'){
        Object.entries(object).map(([key,value]) => {
            return formData.append(key,typeof value === 'object' ? JSON.stringify(value) : value)
        })
    }
    return formData
}


export const convertToFormDataWithArray = (object) => {
    let formData = new FormData()
    if(typeof object  === 'object'){
        Object.entries(object).forEach(([key,value]) => {
            if(typeof value === 'object' && value !== null){
                if(value.length > 0 && typeof value[0] === 'object')
                    formData.append(key,JSON.stringify(value))
                else
                    value.forEach((val) => {
                        formData.append(`${key}`,val)
                    })
            }
            else{
                formData.append(key, value)
            }
        })
    }
    return formData
}