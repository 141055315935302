import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {changeFiltersAction, setData} from '../../../../actions/admin-header';
import Table from '../../../reuseComponent/Table/MaterialTable';
import Pagination from '../../../reuseComponent/Table/Pagination'
import Header from '../../../reuseComponent/Table/table-head';
import adminInstance from '../../../instances/admininstance';
import Container from '../../../reuseComponent/Table/container';
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute';
import {history} from '../../../../history';
import {url} from '../../../../constants';
import Toolbar from './Toolbar';
import StandartLeftPanner from '../../../reuseComponent/Header/standartLeftPannel';
import Details from '../details';
import {findPermInArray} from '../../../../reuseFunctions/checkPermission'
import {
    permCreateCustomers,
    permRemoveCustomers,
    permViewAllCustomers,
    permReallocationCustomers,
} from '../../../../permissions/customers'
import Modall from '../../../reuseComponent/modal';
import CustomModalWithChildren from '../../../reuseComponent/modal/modalWithChildren';
import RejectModal from './rejectModal'
import {notify} from '../../../reuseComponent/toast';
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import CheckBox from '../../../reuseComponent/checkbox';
import DateRangePicker from '../../../reuseComponent/date-range-picker';
import moment from 'moment';
import ModalWithInput from '../../../reuseComponent/modal/modalWithInputConfirm';
import {
    acceptCustomerService, allocateCustomerService, bulkAllocateCustomerService,
    deactivateCustomerService,
    getCustomersList,
    rejectCustomerService
} from '../../../../services/customers';
import {setCustomersResponsible, setCustomersStatus} from '../../../../actions/customers';
import {getUsers} from '../../../../actions/users';
import ResponsibleCompoent from './responsibleComponent'
import {generateSearchString} from '../../../../reuseFunctions/queries';
import DownloadXlsxCustomersComponent from './ExportCustomers';
import {defaultServerError} from '../../../../reuseFunctions/toasts';
import axios from 'axios';
import withTracker from '../../../reuseComponent/GoogleAnalytics';
import Tooltip from '../../../reuseComponent/Tooltips'
import {ReactComponent as AcountSearch} from '../../../../assets/images/acount-search.svg';
import {ReactComponent as AcountCheck} from '../../../../assets/images/acount-check.svg';
import {ReactComponent as AcountCancel} from '../../../../assets/images/acount-cancel.svg';
import {ReactComponent as AccountOff} from '../../../../assets/images/account-off.svg';
import {getFilters} from "../../../../actions/smart-filter";

class CustomersModule extends PureComponent {
    state = {
        currentStatus: '',
        currentCustomer: '',
        currUser: null,
        customer: null,
        modalRemoveCustomer: false,
        isBulkEdit: false,
        customers: [],
        isAllChecked: false,
        modalAccept: false,
        modalReject: false,
        modalDisable: false,
        modalExport: false,
        modalAllocate: false,
        modalBulkAllocate: false,
        isExporting: false,
        disableModalButton: false,
        resLength: 0,
    };

    tableRef = React.createRef();

    columsWithDetails = [{
        title: 'Status',
        field: 'status',
        render: rowData => rowData.customersStatus ? <>
                <Tooltip text={rowData.customersStatus.name}>
                    <span>
                        {rowData.customersStatus.alias === 'new' && <AcountSearch/>}
                        {rowData.customersStatus.alias === 'activated' && <AcountCheck/>}
                        {rowData.customersStatus.alias === 'rejected' && <AcountCancel/>}
                        {rowData.customersStatus.alias === 'deactivated' && <AccountOff/>}
                    </span>
                </Tooltip>
            </>
            : ''
    }, {
        title: 'Company',
        field: 'companyName',
        render: rowData => <>
            <div>
                {rowData.companyName ? rowData.companyName : rowData.customersContacts[0] ? `${rowData.customersContacts[0].name} ${rowData.customersContacts[0].secondName}` : null}
            </div>
            <div className="email-customer-list">
                {rowData.email}
            </div>
        </>
    }, {
        title: 'City',
        field: 'city',
        render: rowData => {
            return <div>
                {rowData.city ? `${rowData.city},` : null} {rowData.country}
            </div>
        }
    }]

    hasPermToAllocate = (user, permission = permViewAllCustomers) => {
        const {roles, currUser} = this.props;
        return findPermInArray(roles, permission)
            || (user ? user.username === currUser.username : false)
    };

    generateColumns = () => {
        const {isAllChecked, customers, isBulkEdit} = this.state
        const {id} = this.props.match.params
        let selectedAllRows = false
        if (this.tableRef.current)
            selectedAllRows = customers.length === this.tableRef.current.state.query.totalCount && this.tableRef.current.state.query.totalCount !== 0

        return id === undefined ? [

            {
                hidden: !isBulkEdit,
                title: (() => <CheckBox
                    options={{
                        className: 'checkbox-material'
                    }}
                    id="select-all-checkbox"
                    checked={isAllChecked || selectedAllRows}
                    indeterminate={!isAllChecked && !selectedAllRows && customers.length > 0}
                    handleChange={e => {
                        const checked = e.target.checked
                        this.setState(state => ({
                            isAllChecked: checked,
                            customers: !checked ? [] : this.tableRef.current.state.data.map(item => item.id)
                        }))
                    }}
                />)(),
                field: 'bulk-edit-field',
                sorting: false,
                headerStyle: {
                    padding: '0 20px 0 10px',
                    textAlign: 'center',
                    width: '40px',
                },
                cellStyle: {
                    padding: '0 20px 0 10px',
                    textAlign: 'center'
                },
                render: rowData => {
                    const isChecked = customers.includes(rowData.id)
                    return <div onClick={e => e.stopPropagation()}><CheckBox
                        options={{
                            className: 'checkbox-material'
                        }}
                        id={`bulk-checkbox-${rowData.id}`}
                        checked={isChecked || (isAllChecked)}
                        handleChange={(e) => {
                            this.handleChange(rowData.id, isChecked)
                        }}
                    /></div>
                }
            },
            {
                title: 'Status',
                field: 'customersStatus',
                sorting: false,
                render: rowData => rowData.customersStatus ? <>
                        <Tooltip text={rowData.customersStatus.name}>
                            <span>
                                {rowData.customersStatus.alias === 'new' && <AcountSearch/>}
                                {rowData.customersStatus.alias === 'activated' && <AcountCheck/>}
                                {rowData.customersStatus.alias === 'rejected' && <AcountCancel/>}
                                {rowData.customersStatus.alias === 'deactivated' && <AccountOff/>}
                            </span>
                        </Tooltip>
                    </>
                    : ''
            },
            {
                title: 'Name',
                field: 'customerContacts',
                sorting: false,
                render: (rowData) => rowData.customersContacts && rowData.customersContacts[0] ?
                    `${rowData.customersContacts[0].name} ${rowData.customersContacts[0].secondName}` : ''
            },
            {
                title: 'Registered',
                field: 'createdAt',
                sorting: false,
                headerStyle: {
                    textAlign: 'right'
                },
                cellStyle: {
                    textAlign: 'right',
                    minWidth: 130
                },
                filterComponent: (props) => {
                    const {filters} = this.props
                    const {column} = props
                    const activeFilter = filters.find(filter => filter.column.tableData.id === column.tableData.id)
                    return <div className="filter-wrap">
                        <DateRangePicker
                            singleDatePicker={false}
                            onApply={(event, picker) => this.tableRef.current.onFilterChange(column.tableData.id, `${picker.startDate.format('DD.MM.YYYY 00:00')}-${picker.endDate.format('DD.MM.YYYY 23:59')}`)}
                            maxDate={moment()}
                            startDate={activeFilter ? activeFilter.value.split('-')[0] : null}
                            endDate={activeFilter ? activeFilter.value.split('-')[1] : null}
                        >
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000" d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"/>
                            </svg>
                        </DateRangePicker>
                    </div>
                },
                render: rowData => rowData.createdAt ?
                    <span>
                    {moment(rowData.createdAt, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm')}
                </span> : null
            },
            {
                title: 'Email',
                field: 'email',
                sorting: false,
                render: (rowData) => rowData.customersContacts && rowData.customersContacts[0] ?
                    rowData.customersContacts[0].email : ''
            },
            {
                title: 'Accepted',
                field: 'accepted',
                sorting: false,
                headerStyle: {
                    textAlign: 'right'
                },
                cellStyle: {
                    textAlign: 'right',
                    minWidth: 130
                },
                filterComponent: (props) => {
                    const {filters} = this.props;
                    const {column} = props;
                    const activeFilter = filters.find(filter => filter.column.tableData.id === column.tableData.id);
                    return <div className="filter-wrap">
                        <DateRangePicker
                            singleDatePicker={false}
                            onApply={(event, picker) => this.tableRef.current.onFilterChange(column.tableData.id, `${picker.startDate.format('DD.MM.YYYY 00:00')}-${picker.endDate.format('DD.MM.YYYY 23:59')}`)}
                            maxDate={moment()}
                            startDate={activeFilter ? activeFilter.value.split('-')[0] : null}
                            endDate={activeFilter ? activeFilter.value.split('-')[1] : null}
                        >
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000" d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"/>
                            </svg>
                        </DateRangePicker>
                    </div>
                },
                render: rowData => rowData.accepted ?
                    <span>
                    {moment(rowData.accepted, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm')}
                </span> : null
            },
            {
                title: 'RefType',
                field: 'refType',
                sorting: false,
            },
            {
                title: 'Company',
                field: 'companyName',
                sorting: false,
            },
            {
                title: 'Country',
                field: 'country',
                sorting: false,
            },
            {
                title: 'Responsible',
                field: 'user',
                sorting: false,
                render: rowData => {
                    return (
                        <ResponsibleCompoent
                            responsibleUser={rowData.user}
                            customer={{
                                id: rowData.id,
                                companyName: rowData.companyName,
                            }}
                            changeRow={this.changeResponsibleInTable}
                            handleAllocate={(currUser, customer) => {
                                this.setState({
                                    modalAllocate: true,
                                    customer,
                                    currUser,
                                })
                            }
                            }
                        />
                    )
                }
            },

        ] : [{
            hidden: !isBulkEdit,
            title: (() => <CheckBox
                options={{
                    className: 'checkbox-material'
                }}
                id="select-all-checkbox"
                checked={isAllChecked || selectedAllRows}
                indeterminate={!isAllChecked && !selectedAllRows && customers.length > 0}
                handleChange={e => {
                    const checked = e.target.checked
                    this.setState(state => ({
                        isAllChecked: checked,
                        customers: !checked ? [] : this.tableRef.current.state.data.map(item => item.id)
                    }))
                }}
            />)(),
            field: 'bulk-edit-field',
            sorting: false,
            headerStyle: {
                padding: '0 20px 0 10px',
                textAlign: 'center',
                width: '40px',
            },
            cellStyle: {
                padding: '0 20px 0 10px',
                textAlign: 'center'
            },
            render: rowData => {
                const isChecked = customers.includes(rowData.id)
                return <div onClick={e => e.stopPropagation()}><CheckBox
                    options={{
                        className: 'checkbox-material'
                    }}
                    id={`bulk-checkbox-${rowData.id}`}
                    checked={isChecked || (isAllChecked)}
                    handleChange={(e) => {
                        this.handleChange(rowData.id, isChecked)
                    }}
                /></div>
            }
        },...this.columsWithDetails];
    };

    updateTableAfterBulk = () => {
        this.tableRef.current.onQueryChange()
        this.setState({
            isBulkEdit: false,
            customers: [],
            isAllChecked: false
        })
    }

    changeResponsibleInTable = (id, user) => {
        if (this.tableRef.current) {
            const data = this.tableRef.current.state.data;
            const newData = data.map(item => item.id !== id ? item : {...item, user});

            this.tableRef.current.dataManager.setData(newData);
            this.tableRef.current.setState({...this.tableRef.current.dataManager.getRenderState()})
        }
    };

    removeEmptyRow = () => {
        let data = this.tableRef.current ? this.tableRef.current.state.data : [];
        data = data.filter(customer => customer.id !== 'new');
        this.tableRef.current && this.tableRef.current.dataManager.setData(data);
        this.tableRef.current.setState({
            ...this.tableRef.current.dataManager.getRenderState()
        });
    };

    cancelHander = () => {
        changeRoutePush('/atw/customer', history);
        this.removeEmptyRow();
    };

    handleDeleteCustomer = () => {
        const idCustomer = this.state.currentCustomer.id;
        const username = this.state.currentCustomer.email;
        const {id} = this.props.match.params;
        this.setState(
            {
                modalRemoveCustomer: false,
                currentCustomer: false
            },
            () => {
                adminInstance
                    .delete(`${url}/customer/${idCustomer}`, {
                        params: {
                            username
                        }
                    })
                    .then(res => {
                        if (id && parseInt(id) === parseInt(idCustomer)) {
                            changeRoutePush(`/atw/customer`, history);
                        }
                        let query = {
                            ...this.tableRef.current.state.query,
                            page: 0
                        };
                        this.tableRef.current && this.tableRef.current.onQueryChange(query);
                        notify('success', 'Customer successfully deleted');
                    })
                    .catch(err => {
                        notify('error', 'Oops something went wrong');
                    });
            }
        );
    };

    addEmptyRowToTable = () => {
        const data = this.tableRef.current ? this.tableRef.current.state.data : [];
        if (data.find(customer => customer.id === 'new') === undefined) {
            const newCustomer = {
                id: 'new',
                companyName: 'New customer',
                busModel: '',
                qualityInterest: '',
                monthlyCountity: ''
            };
            this.tableRef.current &&
            this.tableRef.current.dataManager.setData([newCustomer, ...data]);
            this.tableRef.current.setState({
                ...this.tableRef.current.dataManager.getRenderState()
            });
        }
    };

    callbackAfterCreation = data => {
        this.tableRef.current && this.tableRef.current.onQueryChange();
        changeRoutePush(`/atw/customer/${data.id}`, history);
    };

    acceptCustomer = (id, callback) => {
        const {setCustomersStatus} = this.props;
        this.setState({
            disableModalButton: true
        }, () => {
            acceptCustomerService(id, `${window.location.origin}/atw/customer/${id}`)
                .then(res => {
                    if (res && this.tableRef.current) {
                        notify('success', 'Status successfully changed to "Accepted"');
                        this.setState({
                            modalAccept: false
                        });
                        const data = this.tableRef.current.state.data;

                        const newStatus = res.data;
                        setCustomersStatus(newStatus);

                        const newData = data.map(item => item.id !== id ? item : {...item, customersStatus: newStatus});
                        this.tableRef.current.dataManager.setData(newData);
                        this.tableRef.current.setState({...this.tableRef.current.dataManager.getRenderState()})
                        callback && callback();
                    }
                })
                .catch(err => {
                    this.setState({
                        disableModalButton: false
                    });

                    if(err && err.response && err.response.status === 400 && err.response.data === 'Customer already accepted'){
                        notify('error', 'Customer already accepted')
                    }else if(err && err.response && (err.response.status === 403 || err.response.status === 400)){
                        changeRoutePush('/atw/access_denied/You cannot accept unallocated you customer.', history)
                    }else{
                        defaultServerError()
                    }
                })
                // .finally(() => {
                //     this.setState({
                //         disableModalButton: false
                //     });
                // })
        });
  }

    rejectCustomer = (id, reason, callback) => {
        const {setCustomersStatus} = this.props;
        this.setState({
            disableModalButton: true
        }, () => {
            rejectCustomerService(id, `${window.location.origin}/atw/customer/${id}`, reason)
                .then(res => {
                    if (res && this.tableRef.current) {
                        notify('success', 'Status successfully changed to "Rejected"');
                        this.setState({
                            modalReject: false
                        });
                        const data = this.tableRef.current.state.data;

                        const newStatus = res.data;
                        setCustomersStatus(newStatus);

                        const newData = data.map(item => item.id !== id ? item : {...item, customersStatus: newStatus});

                        this.tableRef.current.dataManager.setData(newData);
                        this.tableRef.current.setState({...this.tableRef.current.dataManager.getRenderState()})
                        callback && callback();
                    }
                })
                .catch(err => {
                    if(err && err.response && err.response.status === 400 && err.response.data === 'Customer already rejected'){
                        notify('error', 'Customer already rejected')
                    }else if(err && err.response && (err.response.status === 403 || err.response.status === 400)){
                        changeRoutePush('/atw/access_denied/You cannot reject unallocated you customer.', history)
                    }else{
                        defaultServerError()
                    }

                    this.setState({
                        disableModalButton: false
                    });
                })
                // .finally(() => {
                //     this.setState({
                //         disableModalButton: false
                //     });
                // })
        });
    }

    deactivateCustomer = (id) => {
        const {setCustomersStatus} = this.props;
        this.setState({
            disableModalButton: true
        }, () => {
        deactivateCustomerService(id)
            .then(res => {
                if (res && this.tableRef.current) {
                    notify('success', 'Status successfully changed to "Deactivated"');
                    this.setState({
                        modalDisable: false
                    });
                    const data = this.tableRef.current.state.data;

                    const newStatus = res.data;
                    setCustomersStatus(newStatus);

                    const newData = data.map(item => item.id !== id ? item : {
                        ...item,
                        customersStatus: newStatus
                    });

                    this.tableRef.current.dataManager.setData(newData);
                    this.tableRef.current.setState({...this.tableRef.current.dataManager.getRenderState()})
                }
            })
            .catch(err => {
                defaultServerError()
                this.setState({
                    disableModalButton: false
                });
            })
            // .finally(() => {
            //     this.setState({
            //         disableModalButton: false
            //     });
            // })
        });
    }

    componentDidMount() {
        const {setHeaderData, getUsers, getFilters} = this.props;
        const {params} = this.props.match;
        setHeaderData({
            leftPannelData: {
                title: 'Customers',
                logo: (
                    <svg
                        className="action-icon header-icon"
                        viewBox="0 -45 445.98997 445"
                    >
                        <path
                            fill="#00BFA5"
                            d="m387.832031 55.402344h1.425781c11.589844-.011719 20.984376-9.40625 21-21v-12.90625c-.015624-11.59375-9.410156-20.988282-21-21h-1.425781c-11.59375.011718-20.988281 9.40625-21 21v12.90625c.011719 11.59375 9.40625 20.988281 21 21zm0 0"
                        />
                        <path
                            fill="#00BFA5"
                            d="m423.164062 94.667969h-3.40625l-9.421874 14.789062c-1.996094 3.128907-6.082032 4.160157-9.320313 2.347657l-11.808594-6.597657-10.90625 6.476563c-1.601562.972656-3.53125 1.265625-5.351562.804687-1.816407-.460937-3.375-1.628906-4.324219-3.25l-8.527344-14.566406h-4.160156c-6.457031 0-12.613281 2.730469-16.941406 7.519531-4.328125 4.789063-6.429688 11.1875-5.777344 17.609375.023438.234375.035156.472657.035156.707031v74.367188l22.546875 30.753906c.816407 1.113282 1.285157 2.445313 1.347657 3.824219l5.148437 115.21875c.152344 5.5 4.664063 9.871094 10.164063 9.859375h34.335937c5.480469.019531 9.980469-4.320312 10.160156-9.796875l5.148438-115.277344c.058593-1.371093.523437-2.695312 1.332031-3.808593l22.402344-30.769532v-74.371094c0-.234374.011718-.472656.035156-.707031.648438-6.421875-1.449219-12.816406-5.777344-17.605469-4.328125-4.789062-10.480468-7.523437-16.933594-7.527343zm0 0"
                        />
                        <path
                            fill="#00BFA5"
                            d="m221.28125 55.402344h1.421875c11.59375-.011719 20.988281-9.40625 21-21v-12.90625c-.011719-11.59375-9.40625-20.988282-21-21h-1.421875c-11.59375.011718-20.988281 9.40625-21 21v12.90625c.011719 11.59375 9.40625 20.988281 21 21zm0 0"
                        />
                        <path
                            fill="#00BFA5"
                            d="m256.613281 94.667969h-3.410156l-9.421875 14.789062c-1.992188 3.128907-6.082031 4.160157-9.320312 2.347657l-11.804688-6.597657-10.902344 6.472657c-1.121094.699218-2.417968 1.050781-3.738281 1.019531-2.449219-.035157-4.703125-1.347657-5.941406-3.460938l-8.523438-14.5625h-4.164062c-6.457031 0-12.609375 2.730469-16.941407 7.519531-4.328124 4.789063-6.429687 11.1875-5.777343 17.613282.023437.234375.035156.46875.035156.703125v74.371093l22.542969 30.75c.820312 1.113282 1.289062 2.445313 1.351562 3.828126l5.140625 115.214843c.15625 5.5 4.664063 9.875 10.167969 9.863281h34.335938c5.480468.015626 9.984374-4.324218 10.164062-9.800781l5.144531-115.277343c.0625-1.371094.527344-2.695313 1.335938-3.808594l22.398437-30.769532v-74.375c0-.234374.011719-.472656.035156-.707031.652344-6.421875-1.449218-12.816406-5.773437-17.605469-4.328125-4.789062-10.480469-7.523437-16.9375-7.527343zm0 0"
                        />
                        <path
                            fill="#00BFA5"
                            d="m54.730469 55.402344h1.421875c11.59375-.011719 20.988281-9.40625 21-21v-12.90625c-.011719-11.59375-9.40625-20.988282-21-21h-1.421875c-11.59375.011718-20.988281 9.40625-21 21v12.90625c.011719 11.59375 9.40625 20.988281 21 21zm0 0"
                        />
                        <path
                            fill="#00BFA5"
                            d="m90.0625 94.667969h-3.410156l-9.421875 14.789062c-1.992188 3.128907-6.082031 4.160157-9.320313 2.347657l-11.804687-6.597657-10.902344 6.472657c-1.121094.699218-2.417969 1.054687-3.738281 1.019531-2.449219-.035157-4.703125-1.347657-5.941406-3.460938l-8.527344-14.566406h-4.160156c-6.457032 0-12.613282 2.730469-16.941407 7.519531-4.328125 4.789063-6.429687 11.1875-5.777343 17.609375.023437.234375.035156.472657.035156.707031v74.367188l22.542968 30.753906c.816407 1.113282 1.285157 2.445313 1.347657 3.824219l5.144531 115.21875c.15625 5.5 4.664062 9.871094 10.164062 9.859375h34.34375c5.480469.019531 9.984376-4.320312 10.164063-9.796875l5.144531-115.277344c.0625-1.371093.527344-2.695312 1.335938-3.808593l22.398437-30.769532v-74.371094c0-.234374.011719-.472656.035157-.707031.652343-6.421875-1.445313-12.820312-5.773438-17.609375-4.328125-4.789062-10.480469-7.519531-16.9375-7.523437zm0 0"
                        />
                    </svg>
                )
            },
            Actions: () => <DownloadXlsxCustomersComponent
                handleConfirmExport={() => this.setState({modalExport: true})
                }/>,
            // textAddButton: "Add",
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: value => this.tableRef.current.onSearchChange(value)
        });

        getUsers && getUsers();
        getFilters && getFilters('customers');

        if (params.type) {
            if (params.type === 'allocate') {
                this.acceptCustomer(params.id)
            } else if (params.type === 'reject') {
                this.rejectCustomer(params.id, '');
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {roles} = this.props;
        const {currentCustomer} = this.state;
        if (prevProps.roles.length !== roles.length) {
            const hasPermToAllocate = this.hasPermToAllocate(currentCustomer ? currentCustomer.allocatedTo : null);
            this.setState({
                modalRemoveCustomer: findPermInArray(roles, permRemoveCustomers) ?
                    prevState.modalRemoveCustomer : false,
                modalAccept: hasPermToAllocate ? prevState.modalAccept : false,
                modalReject: hasPermToAllocate ? prevState.modalReject : false,
                modalDisable: hasPermToAllocate ? prevState.modalDisable : false,
                hasPermReallocationCustomers: findPermInArray(roles, permReallocationCustomers),
            })

            if (this.tableRef.current && findPermInArray(roles, permViewAllCustomers) !== findPermInArray(prevProps.roles, permViewAllCustomers)) {
                this.tableRef.current.onQueryChange()
            }
        }
    }

    componentWillUnmount() {
        this.props.setHeaderData({
            title: null,
            handleAdd: null,
            handleSearch: null,
            Actions: null,
        });
    }

    generateRows = data => {
        return data.map(customer => {
            return {
                id: customer.id,
                createdAt: customer.createdAt,
                companyName: customer.companyName,
                phoneNumber: customer.phoneNumber,
                email: customer.email,
                busModel: customer.busModel,
                qualityInterest: customer.qualityInterest,
                monthlyCountity: customer.monthlyCountity,
                customersContacts: customer.customersContacts,
                customersStatus: customer.customersStatus,
                user: customer.user,
                accepted: customer.accepted,
                country: customer.country,
                refType: customer.refType,
                city: customer.city
            };
        });
    };

    getCustomersList = query => {
        const {changeFilters, roles} = this.props
        changeFilters && changeFilters(query.filters)
        return new Promise(resolve => {
            getCustomersList({
                page: query.page ? query.page : 0,
                limit: query.pageSize,
                search: generateSearchString({
                    search: query.search,
                    filters: query.filters
                })
            }, findPermInArray(roles, permViewAllCustomers))
                .then(res => {
                    const {id} = this.props.match.params;
                    const newCustomer = {
                        id: 'new',
                        companyName: 'New customer',
                        country: '',
                        phoneNumber: '',
                        email: '',
                        busModel: '',
                        qualityInterest: '',
                        monthlyCountity: ''
                    };

                    this.setState({
                        resLength: res.data.totalElements
                    });

                    resolve({
                        data:
                            id === 'new' ? this.generateRows([newCustomer, ...res.data.content]) :
                                this.generateRows(res.data.content),
                        page: query.page,
                        totalCount: res.data.totalElements
                    });
                });
        })
    };

    activeDisableBulkMode = () => {
        if (this.state.isBulkEdit) {
            this.setState(prevProps => ({
                isBulkEdit: !prevProps.isBulkEdit,
                customers: [],
                isAllChecked: false,
            }))
        } else {
            this.setState(prevProps => ({
                isBulkEdit: !prevProps.isBulkEdit
            }))
        }

    };

    handleChange = (id, isChecked) => {
        const {customers} = this.state
        if (isChecked)
            this.setState({
                customers: customers.filter(prId => prId !== id),
                isAllChecked: false
            })
        else
            this.setState({
                customers: customers.concat(id),
                isAllChecked: false
            })
    }

    changeResponsible = (comment) => {
        const {setCustomersResponsible} = this.props;
        const {params} = this.props.match;
        this.setState({
            modalAllocate: false,
        }, () => {
            const userId = this.state.currUser.id;
            const {customer} = this.state;

            allocateCustomerService(customer.id, {
                acceptUrl: `${window.location.origin}/atw/customer/${customer.id}/allocate`,
                customerUrl: `${window.location.origin}/atw/customer/${customer.id}`,
                reallocateUrl: `${window.location.origin}/atw/customer/${customer.id}`,
                rejectUrl: `${window.location.origin}/atw/customer/${customer.id}/reject`,
                userTo: userId,
                comment: comment,
            })
                .then(res => {
                    if (res.data) {
                        if (customer.id === parseInt(params.id))
                            setCustomersResponsible(this.state.currUser);
                        notify('success', 'Responsible successfully allocated');
                        this.changeResponsibleInTable(customer.id, this.state.currUser)
                        this.tableRef.current.onQueryChange();
                    }
                })
                .catch(err => {
                    defaultServerError()
                })
        })
    };

    changeResponsibleInBulk = (comment) => {

        const customersTem = this.state.customers;
        const isAllCheckedTemp = this.state.isAllChecked
        this.setState({
            modalBulkAllocate: false,
            isBulkEdit: false,
            customers: [],
            isAllChecked: false,
        }, () => {
            const userId = this.state.currUser.id;
            bulkAllocateCustomerService({
                all: isAllCheckedTemp,
                comment: comment,
                ids: customersTem,
                userTo: userId,
            })
                .then(res => {
                    notify('success', 'Responsible successfully allocated');
                    this.tableRef.current.onQueryChange();
                })
        })
    }

    handleExportCustomers = () => {
        if (!this.state.isExporting) {
            const query = this.tableRef.current ? this.tableRef.current.state.query : {}
            const user = JSON.parse(window.localStorage.getItem('userWCS'))
            this.setState({
                modalExport: false,
                isExporting: true
            }, () => {
                axios({
                    url: `${url}/customer/export`,
                    params: {
                        search: generateSearchString(query)
                    },
                    method: 'post',
                    responseType: 'blob',
                    headers: {
                        'Authorization': `Bearer ${user.access_token}`
                    },
                })
                    .then(res => {
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        const currDate = moment().format('YYYY-MM-DDTHH:mm')
                        link.setAttribute('download', `Customers_${currDate}.xls`);
                        document.body.appendChild(link);
                        link.click();
                        this.setState({
                            isExporting: false
                        })
                    })
                    .catch(err => {
                        if (err && err.response && err.response.status === 400) {
                            notify('success', 'Too many records, we\'ll send you file to email, when process will be finished')
                        } else {
                            defaultServerError();
                        }
                    })
            })
        }
    }

    handleShowRemoveModal = (id, email) => {
        this.setState({
            currentCustomer: {
                id: id,
                email: email
            },
            modalRemoveCustomer: true
        });
    }

    handleShowAllocateModal = (id, companyName, user) => {
        this.setState({
            modalAccept: true,
            currentCustomer: {
                id: id,
                companyName: companyName,
                allocatedTo: user,
            },
            disableModalButton: false,
        })
    }

    handleShowRejectModal = (id, companyName, user) => {
        this.setState({
            modalReject: true,
            currentCustomer: {
                id: id,
                companyName: companyName,
                allocatedTo: user,
            },
            disableModalButton: false,
        })
    }

    handleShowDeactivateModal = (id, companyName, user) => {
        this.setState({
            modalDisable: true,
            currentCustomer: {
                id: id,
                companyName: companyName,
                allocatedTo: user,
            },
            disableModalButton: false,
        })
    }

    render() {
        const {id} = this.props.match.params;
        const {loadingUsers} = this.props;
        const {
            isBulkEdit,
            modalRemoveCustomer,
            currentCustomer,
            modalAccept,
            modalDisable,
            modalReject,
            modalExport,
            isExporting,
            customers,
            isAllChecked,
            resLength,
            modalAllocate,
            currUser,
            customer,
            modalBulkAllocate,
            disableModalButton
        } = this.state;
        return (
            <>
                <div
                    className={`wrap-table-with-details-panel customer-table ${
                        !id ? '' : 'with-details'
                        }`}
                >
                    <div className="table-block wrapper-table customers-table">
                        <Table
                            components={{
                                Header: Header,
                                Container: Container,
                                Pagination: Pagination,
                                Toolbar: props => (
                                    <Toolbar
                                        {...props}
                                        isBulkEdit={isBulkEdit}
                                        activeDisableBulkMode={this.activeDisableBulkMode}
                                        handleBulkAllocate={(currUser) => {
                                            this.setState({
                                                modalBulkAllocate: true,
                                                currUser,
                                            })
                                        }}
                                        updateTableAfterBulk={this.updateTableAfterBulk}
                                        customers={customers}
                                        isAllChecked={isAllChecked}
                                        resLength={resLength}
                                        handleAdd={findPermInArray(this.props.roles, permCreateCustomers) ? () => {
                                            changeRoutePush('/atw/customer/new', this.props.history);
                                            this.addEmptyRowToTable();
                                        } : null}
                                    />
                                )
                            }}
                            isLoading={loadingUsers}
                            tableRef={this.tableRef}
                            textLoading={isExporting ? 'Downloading' : 'Loading'}
                            paginationPosition="toolbar"
                            columns={this.generateColumns()}
                            data={this.getCustomersList}
                            onRowClick={(event, rowData) => {
                                event.preventDefault();
                                if (rowData.id !== 'new') {
                                    changeRoutePush(`/atw/customer/${rowData.id}`, history);
                                    this.removeEmptyRow();
                                }
                            }}
                            options={{
                                maxBodyHeight: 'calc(100vh - 130px)',
                                search: true,
                                searchFieldStyle: {display: 'none'},
                                emptyRowsWhenPaging: false,
                                pageSize: 50,
                                pageSizeOptions: [10, 25, 50],
                                rowStyle: props => {
                                    const style = {};
                                    if (parseInt(id) === props.id || id === props.id)
                                        style.backgroundColor = '#f2f2f2';

                                    if (props.customersStatus && props.customersStatus.alias === 'deactivated')
                                        style.color = '#B0BEC5';

                                    return style;
                                }
                            }}
                        />
                    </div>
                    <Route
                        exact
                        path={['/atw/customer/:id', '/atw/customer/:id/:type']}
                        render={props => (
                            <Details
                                {...props}
                                handleShowRemoveModal={this.handleShowRemoveModal}
                                handleShowAllocateModal={this.handleShowAllocateModal}
                                handleShowRejectModal={this.handleShowRejectModal}
                                handleShowDeactivateModal={this.handleShowDeactivateModal}
                                acceptCustomer={this.acceptCustomer}
                                rejectCustomer={this.rejectCustomer}
                                changeRow={this.changeResponsibleInTable}
                                handleAllocate={(currUser, customer) => {
                                    this.setState({
                                        modalAllocate: true,
                                        customer,
                                        currUser,
                                    })
                                }}
                                cancelCreate={this.cancelHander}
                                callbackAfterCreation={this.callbackAfterCreation}
                                refetchData={() => this.tableRef.current.onQueryChange()}
                            />
                        )}
                    />
                </div>
                <Modall
                    open={modalRemoveCustomer}
                    Content={ConfirmModalContent}
                    handleClose={e => this.showHideModal('modalRemoveCustomer', false)}
                    handleCancel={e => this.showHideModal('modalRemoveCustomer', false)}
                    handleConfirm={this.handleDeleteCustomer}
                    options={{
                        centered: true
                    }}
                />

                <ModalWithInput
                    textToCompare={currentCustomer ? currentCustomer.email : ''}
                    open={modalRemoveCustomer}
                    TextComponent={() => <>
                        <div className="wrap-text">
                            <p>You're going to delete customer. Deleted customer can not be restored! Are you ABSOLUTELY
                                sure?</p>
                            <p>The action can lead to data loss. Please enter the <span
                                className="text-to-repeat-for-delete">{currentCustomer ? currentCustomer.email : ''}</span> to
                                confirm deleting</p>
                        </div>
                    </>}
                    withReason={false}
                    handleConfirm={this.handleDeleteCustomer}
                    handleCancel={e => this.setState({
                        modalRemoveCustomer: false
                    })}
                    handleClose={e => this.setState({
                        modalRemoveCustomer: false
                    })}
                    options={{
                        centered: true
                    }}
                />
                <CustomModalWithChildren
                    open={modalAccept}
                    disableConfirmButton={disableModalButton}
                    disableCancelButton={disableModalButton}
                    handleClose={!disableModalButton ? () => this.setState({
                        modalAccept: false,
                    }) : () => {}}
                    handleCancel={() => this.setState({
                        modalAccept: false,
                    })}
                    handleConfirm={() => this.acceptCustomer(currentCustomer.id)}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent text={`accept "${currentCustomer.companyName}" customer`}/>
                </CustomModalWithChildren>

                <CustomModalWithChildren
                    open={modalDisable}
                    disableConfirmButton={disableModalButton}
                    disableCancelButton={disableModalButton}
                    handleClose={!disableModalButton ? () => this.setState({
                        modalDisable: false,
                    }) : () => {}}
                    handleCancel={() => this.setState({
                        modalDisable: false,
                    })}
                    handleConfirm={() => this.deactivateCustomer(currentCustomer.id)}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent text={`deactivate "${currentCustomer.companyName}" customer`}/>
                </CustomModalWithChildren>
                <CustomModalWithChildren
                    open={modalReject}
                    handleClose={!disableModalButton ? () => this.setState({
                        modalReject: false,
                    }) : () => {}}
                    isFooter={false}
                    options={{
                        centered: true
                    }}
                >
                    <RejectModal
                        disableConfirmButton={disableModalButton}
                        disableCancelButton={disableModalButton}
                        commentTitle="Reason to reject"
                        handleCancel={() => this.setState({
                            modalReject: false,
                        })}
                        handleConfirm={(reason) => this.rejectCustomer(currentCustomer.id, reason)}
                    >
                        <ConfirmModalContent
                            text={`reject "${currentCustomer ? currentCustomer.companyName : ''}" customer`}/>
                    </RejectModal>
                </CustomModalWithChildren>
                <CustomModalWithChildren
                    open={modalExport}
                    handleClose={() => this.setState({
                        modalExport: false,
                    })}
                    handleCancel={() => this.setState({
                        modalExport: false,
                    })}
                    handleConfirm={() => this.handleExportCustomers()}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text="export Customers data"
                    />
                </CustomModalWithChildren>
                <CustomModalWithChildren
                    open={modalAllocate}
                    handleClose={e => this.setState({
                        modalAllocate: false
                    })}
                    options={{
                        centered: true
                    }}
                    isFooter={false}
                >
                    <RejectModal
                        commentTitle="Comment"
                        handleCancel={e => this.setState({
                            modalAllocate: false
                        })}
                        handleConfirm={(reason) => this.changeResponsible(reason)}
                    >
                        <ConfirmModalContent
                            text={`allocate "${customer ? customer.companyName : ''}" customer to "${currUser ?
                                currUser.name + ' ' + currUser.secondName : ''}"`}
                        />
                    </RejectModal>
                </CustomModalWithChildren>
                <CustomModalWithChildren
                    open={modalBulkAllocate}
                    handleClose={e => this.setState({
                        modalBulkAllocate: false
                    })}
                    options={{
                        centered: true
                    }}
                    isFooter={false}
                >
                    <RejectModal
                        commentTitle="Comment"
                        handleCancel={e => this.setState({
                            modalBulkAllocate: false
                        })}
                        handleConfirm={(reason) => this.changeResponsibleInBulk(reason)}
                    >
                        <ConfirmModalContent
                            text={`allocate all selected customers to "${currUser ?
                                currUser.name + ' ' + currUser.secondName : ''}"`}
                        />
                    </RejectModal>
                </CustomModalWithChildren>
            </>
        );
    }
}

const mapStateToProps = state => ({
    roles: state.rolesReducer.roles,
    filters: state.adminHeaderReducer.filters,
    loadingUsers: state.usersReducer.loading,
    currUser: state.usersReducer.currUser.userDetails
})

const mapDispatchToProps = dispatch => ({
    setHeaderData: obj => dispatch(setData(obj)),
    getUsers: () => dispatch(getUsers()),
    changeFilters: filters => dispatch(changeFiltersAction(filters)),
    getFilters: alias => dispatch(getFilters(alias)),
    setCustomersStatus: status => dispatch(setCustomersStatus(status)),
    setCustomersResponsible: responsible => dispatch(setCustomersResponsible(responsible)),
})

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(CustomersModule));
