import React, { PureComponent } from 'react'
import IconButton from "../Buttons/iconButton";

export default class IconToggleDropDown extends PureComponent{

    constructor(props,context){
        super(props,context)
        this.handlerClick = this.handlerClick.bind(this)
    }

    handlerClick = (e) => {
        e.preventDefault()
        this.props.onClick(e)
    }

    render(){
        return(
            <IconButton
                handlerClick={this.handlerClick}
                iconName="fa-ellipsis-v"
                size = 'fa-sm'
            />
        )
    }
}