import adminsInstance from "../components/instances/admininstance";
import { url } from "../constants";
import {
  convertToFormData,
  convertToFormDataWithArray
} from "../reuseFunctions/objectToFormData";
import { selectValueToString } from "../reuseFunctions/objectToString";

export const getCustomer = id => {
  return adminsInstance.get(`${url}/customer/${id}`);
};

export const fetchCreateCustomer = values => {
  const data = convertToFormDataWithArray({
    ...values,
    busModel: selectValueToString(values.busModel, 'label'),
    categIds: selectValueToString(values.categIds, "value"),
    demandCategory: selectValueToString(values.demandCategory, 'label'),
    qualityIds: selectValueToString(values.qualityIds, "value")
  });
  return adminsInstance.post(`${url}/customer`, data);
};

export const fetchEditCustomer = values => {
  const {
    createdAt,
    qualityInterest,
    demandCategory,
    customersContacts,
      customersStatus,
      user,
    history,
      refType,
      referrer,
      companyInfo,
    ...requestData
  } = values;
  const data = convertToFormDataWithArray({
    ...requestData,
    busModel: selectValueToString(values.busModel, 'label'),
    qualityIds: selectValueToString(values.qualityIds, 'value'),
    demandCategory: selectValueToString(values.demandCategory, 'label')
  });
  return adminsInstance.put(`${url}/customer/${values.id}`, data);
};
export const getCustomerRequest = id => {
  return adminsInstance.get(`${url}/customers/${id}`)
}

export const getCustomerStatusesService = () =>
    adminsInstance.get(`${url}/customer/statuses`);

export const acceptCustomerService = (id, customerUrl)  =>
    adminsInstance.post(`${url}/customer/accepted/${id}`, convertToFormData({customerUrl}));

export const rejectCustomerService = (id, customerUrl, reason)  =>
    adminsInstance.post(`${url}/customer/rejected/${id}`, convertToFormData({customerUrl, reason}));

export const deactivateCustomerService = id =>
    adminsInstance.post(`${url}/customer/deactivated/${id}`);

export const allocateCustomerService = (id, data) =>
    adminsInstance.post(`${url}/customer/allocation/${id}`, convertToFormData(data));

export const bulkAllocateCustomerService = (data) =>
    adminsInstance.post(`${url}/customer/bulk_allocation`, convertToFormData(data));

export const getCountriesService = () =>
  adminsInstance.get(`${url}/customer/get_country_list`)

export const getCustomersList = (params, viewAll) =>
    adminsInstance
        .get(`${url}/customer${viewAll ? '' : '/my_client'}`, {
          params
        });

export const exportCustomersService = search =>
    adminsInstance.post(`${url}/customer/export`, convertToFormData({search}));

export const checkExistingEmail = username =>
    adminsInstance.get(`${url}/customer/check_existing`, {
      params: {
        username
      }
    })
