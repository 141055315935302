import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Formik} from 'formik'
import {schema} from './schema'
import BodyForm from './bodyForm'
import {putProductIntoBox} from '../../../../../../services/products'
import {notify} from '../../../../../reuseComponent/toast'
import Modal from '../../../../../reuseComponent/modal/modalWithChildren'


class PutProductModal extends PureComponent {

    state = {
        isShowModal: false,
        values: {}
    }

    handleShowModal = (values) => this.setState({isShowModal: true,values: values})

    handleHideModal = () => this.setState({isShowModal: false})

    handleSubmit = values => {
        if(values.isShowConfirm){
            this.handleShowModal(values)
        }
        else{
            this.handlePutProductIntoBox(values)
        }
    }

    handleConfirm = () => {
        const { values } = this.state
        this.setState({
            isShowModal: false,
            values: {}
        },() => this.handlePutProductIntoBox(values))

    }

    handlePutProductIntoBox = (values) => {
        const {handleClose, productItem,putCallback} = this.props
        handleClose()
        putProductIntoBox(values)
            .then(res => {
                putCallback && putCallback()
                notify('success', `Article ${productItem.ean} stowed`)
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }

    render() {
        const {productItem, packageItem, handleClose,activeWorkPlace} = this.props
        const { isShowModal }  = this.state
        return (
            <>
                <Formik
                    onSubmit={this.handleSubmit}
                    validationSchema={schema}
                    initialValues={{
                        productId: productItem.id,
                        packageId: packageItem && packageItem.shortName !== 'REST' ? packageItem.id : '',
                        items: '1',
                        closeBox: false,
                        boxId: '',
                        isShowConfirm: false
                    }}
                    render={props => <BodyForm
                        {...props}
                        activeWorkPlace={activeWorkPlace}
                        packageItem={packageItem}
                        handleClose={handleClose}
                    />}
                />
                <Modal
                    classNameModal="opened-box-exist-confirm-modal"
                    classNameBody="opened-box-exist-confirm"
                    open={isShowModal}
                    handleCancel={this.handleHideModal}
                    handleConfirm={this.handleConfirm}
                    options={{
                        centered: true
                    }}
                >
                    <h6 className="center-h">Opened boxes already exist, are you sure you want to create a new one?</h6>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        activePackage: state.stowingReducer.activePackage,
        details: state.purchaseOrdersReducer.detailsPurchase,

    }
}

export default connect(mapStateToProps, null)(PutProductModal)