import React, {PureComponent} from 'react'
import {Form} from 'react-bootstrap'
import Select from '../../../../../../reuseComponent/Select/select-form'
import {getIn} from 'formik'
import Tooltip from '../../../../../../reuseComponent/Tooltips'
import DatePicker from '../../../../../../reuseComponent/DatePicker/datepicker-with-children'
import {formatPrice} from '../../../../../../../reuseFunctions/formatValue'


class ConditionRow extends PureComponent {

    state = {
        fieldObject: this.props.fields.find(field => field.id === this.props.condition.fieldNameForm.value)
    }

    renderValueComponent = () => {
        const {fieldObject} = this.state
        const {name, values, setValues, index, errors, touched, condition} = this.props
        if (fieldObject.type.alias === 'text') {
            return <>
                <Form.Control
                    isInvalid={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
                    name={`${name}[${index}].value`}
                    onChange={e => {
                        values[name][index].fieldValueForm = e.target.value
                        values[name][index].value = e.target.value
                        setValues(values)
                    }}
                    value={values[name][index].value}
                />
                <Form.Control.Feedback type="invalid">{getIn(errors, `${name}[${index}].value`)}</Form.Control.Feedback>
            </>
        }
        else if (fieldObject.type.alias === 'date') {
            return <div className="wrapper-calendar-asana">
                <DatePicker
                    drops={name === 'orCriterias' ? 'up' : 'down'}
                    timePicker={true}
                    timePicker24Hour={true}
                    onApply={(event, picker) => {
                        values[name][index].fieldValueForm = picker.startDate.format('DD.MM.YYYY HH:mm')
                        values[name][index].value = picker.startDate.format('DD.MM.YYYY HH:mm')
                        setValues(values)
                    }}
                    parentEl='.modal'
                >
                    <>
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z"/>
                        </svg>
                        <Form.Control
                            readOnly={true}
                            isInvalid={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
                            name={`${name}[${index}].value`}
                            value={values[name][index].value ? values[name][index].value : 'No value'}
                        />
                        <Form.Control.Feedback type="invalid">{getIn(errors, `${name}[${index}].value`)}</Form.Control.Feedback>

                    </>
                </DatePicker>
                {condition.fieldValueForm && <svg onClick={e => {
                    values[name][index].fieldValueForm = ''
                    values[name][index].value = ''
                    setValues(values)
                    // setFieldValue('dueDate', '')
                    document.body.click()
                }} className="circle-close" viewBox="0 0 24 24">
                    <path fill="#000000"
                          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/>
                </svg>
                }
            </div>
        }
        else if (fieldObject.type.alias === 'option') {
            return <Select
                onChange={obj => {
                    values[name][index].fieldValueForm = obj
                    values[name][index].value = obj.value
                    setValues(values)
                }}
                isInvalid={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
                errorLabel={getIn(errors, `${name}[${index}].value`)}
                value={{
                    label: values[name][index].value,
                    value: values[name][index].value
                }}
                options={fieldObject.fieldVals.map(val => ({
                    label: val,
                    value: val
                }))}
            />
        }
        else if (fieldObject.type.alias === 'number') {
            return <>
                <Form.Control
                    isInvalid={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
                    name={`${name}[${index}].value`}
                    onChange={e => {
                        values[name][index].fieldValueForm = e.target.value
                        values[name][index].value = e.target.value
                        setValues(values)
                    }}
                    onBlur={e => {
                        values[name][index].fieldValueForm = formatPrice(e.target.value)
                        values[name][index].value = formatPrice(e.target.value)
                        setValues(values)
                    }}
                    value={values[name][index].value}
                />
                <Form.Control.Feedback type="invalid">{getIn(errors, `${name}[${index}].value`)}</Form.Control.Feedback>
            </>
        }
        return null

    }

    render() {
        const {
            condition,
            fields,
            removeCondition,
            index,
            setValues,
            values,
            name,
            errors,
            touched
        } = this.props
        const {fieldObject} = this.state
        return (
            <div className="condition-row">
                <div className="field-condition-row">
                    <Form.Group>
                        <Form.Label>Field</Form.Label>
                        <Select
                            isInvalid={getIn(errors, `${name}[${index}].field`) && getIn(touched, `${name}[${index}].field`)}
                            errorLabel={getIn(errors, `${name}[${index}].field`)}
                            name={`${name}[${index}].field`}
                            value={condition.fieldNameForm ? condition.fieldNameForm : null}
                            onChange={obj => this.setState({
                                fieldObject: fields.find(field => field.id === obj.value)
                            }, () => {
                                const fieldObject = fields.find(field => field.id === obj.value)
                                const isCriteria = fieldObject.type.criteriaList.find(criteria => {
                                    return criteria.name === 'is'
                                })

                                values[name][index].field = obj.value
                                values[name][index].fieldNameForm = obj
                                values[name][index].value = ''
                                values[name][index].fieldValueForm = ''
                                values[name][index].criteria = isCriteria ? isCriteria.id : ''
                                values[name][index].fieldCriteriaNameForm = isCriteria ? {
                                    label: isCriteria.name,
                                    value : isCriteria.id,
                                    alias: isCriteria.alias
                                } : ''
                                setValues(values)
                            })}
                            options={fields.map(field => ({
                                value: field.id,
                                label: field.name
                            }))}
                        />
                    </Form.Group>
                </div>
                <div className="criteria-condition-row">
                    {fieldObject && <Form.Group>
                        <Form.Label>Criteria</Form.Label>
                        <Select
                            isInvalid={getIn(errors, `${name}[${index}].criteria`) && getIn(touched, `${name}[${index}].criteria`)}
                            errorLabel={getIn(errors, `${name}[${index}].criteria`)}
                            name={`${name}[${index}].criteria`}
                            options={fieldObject.type.criteriaList.map(criteria => ({
                                value: criteria.id,
                                label: criteria.name,
                                alias: criteria.alias
                            }))}
                            value={condition.fieldCriteriaNameForm ? condition.fieldCriteriaNameForm : null}
                            onChange={obj => {
                                values[name][index].value = ''
                                values[name][index].fieldValueForm = ''
                                values[name][index].criteria = obj.value
                                values[name][index].fieldCriteriaNameForm = obj
                                setValues(values)
                            }}
                        />
                    </Form.Group>}
                </div>
                <div className="value-condition-row">
                    {fieldObject
                    && condition.fieldCriteriaNameForm
                    && condition.fieldCriteriaNameForm.alias !== 'not_empty'
                    && condition.fieldCriteriaNameForm.alias !== 'empty' && <Form.Group>
                        <Form.Label>Value</Form.Label>
                        {this.renderValueComponent()}
                    </Form.Group>}
                </div>
                <div className="remove-condition-row">
                    <Tooltip text="Delete condition">
                        <button onClick={removeCondition} type="button">
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000"
                                      d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
                            </svg>
                        </button>
                    </Tooltip>
                </div>
            </div>
        )
    }
}

export default ConditionRow