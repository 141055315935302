import * as yup from 'yup'

export const schema = yup.object().shape({
    dueDate: yup.string().required('required field'),
    poId: yup.string(),
    executorId: yup.string(),
    departmentId: yup.string(),
    description: yup.string(),
    title: yup.string().required('required field'),
    responsible: yup.mixed().required('required field')
})