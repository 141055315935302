import React from 'react'
import {Button, Form, Col, Row} from 'react-bootstrap'
// import Editor from "../../../reuseComponent/CKEDITOR"
import SingleDatePicker from "../../../reuseComponent/DatePicker/datepicker-with-children"
import SummerNoteEditor from '../../../reuseComponent/SummerNoteEditor';

// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';

const StatusFormBody = props => {
    const {
        handleSubmit,
        handleChange,
        errors,
        touched,
        handleClose,
        setFieldValue,
        values,
        initialValues
    } = props;

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Row>
                <Col md={6}>
            <Form.Group>
                <Form.Label>Version</Form.Label>
                <Form.Control
                    type="text"
                    name="version"
                    value={values.version}
                    onChange={handleChange}
                    isInvalid={touched.version && errors.version}
                />
                <Form.Control.Feedback type="invalid">{errors.version}</Form.Control.Feedback>
            </Form.Group>
                </Col>

            <Col xs={12} md={6}>
                <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <SingleDatePicker
                        timePicker={true}
                        timePicker24Hour={true}
                        onApply={(event,picker) => {
                            setFieldValue('date',picker.startDate.format('DD.MM.YYYYTHH:mm'))
                        }}
                        startDate={values.date ? values.date : null}
                    >
                        <div className="date-picker-input">
                            {!values.date && <svg viewBox="0 0 24 24">
                                <path fill="#000000"
                                      d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z"/>
                            </svg>
                            }
                            <Form.Control
                                value={values.date ? values.date.replace('T',' ') : ''}
                                readOnly={true}
                                name="date"
                                isInvalid={touched.date && errors.date}
                            />
                            <Form.Control.Feedback type="invalid">{errors.date}</Form.Control.Feedback>
                        </div>

                    </SingleDatePicker>
                </Form.Group>
            </Col>
            </Row>
            <Form.Group className={touched.description && errors.description ? 'error-group' : ''}>
                <Form.Label>Description</Form.Label>
                {/*<Editor*/}
                    {/*value={values.description}*/}
                    {/*onChange={(event,editor) => {*/}
                        {/*setFieldValue('description',editor.getData())*/}
                    {/*}}*/}
                    {/*config={{*/}
                        {/*// plugins: [Bold, Italic],*/}
                        {/*// toolbar: ['bold', 'italic'],*/}
                        {/*toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'redo', 'undo',],*/}
                        {/*// image: {*/}
                        {/*//     upload: {*/}
                        {/*//         types: ['png', 'jpeg']*/}
                        {/*//     }*/}
                        {/*// }*/}
                    {/*}}*/}
                {/*/>*/}
                <SummerNoteEditor
                    // ref={this.editor}
                    value={initialValues.description}
                    onChange={(value) => {
                        // console.log(value)
                        setFieldValue('description',value)
                    }}
                />
                {touched.description && errors.description && <span>{errors.description}</span>}
            </Form.Group>
            <Form.Group className="footer-form footer-form-custom">
                <Button variant="secondary"
                        onClick={() => handleClose()}>Cancel</Button>
                <Button variant="primary" type="submit">Save</Button>        
            </Form.Group>
        </Form>

    )
}

export default StatusFormBody
