import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import Form from '../form'
import { editProductNamesItemRequest } from '../../../../../actions/commodity-groups'


class EditForm extends PureComponent{

    handleSubmit = values => {
        const { handleCancel,editProductName,id } = this.props
        // handleCancel && handleCancel ()
        id && editProductName && editProductName(id,values,handleCancel)

    }

    render(){
        const { handleCancel,value } = this.props
        return (
            <Form
                initValues={{
                    value: value
                }}
                handleSubmit={this.handleSubmit}
                handleCancel={handleCancel}
            />
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editProductName: (id,values,callback) => dispatch(editProductNamesItemRequest(id,values,callback))
    }
}

export default connect(null,mapDispatchToProps)(EditForm)