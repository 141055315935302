import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import { checkIfObjectEmptyValues } from '../../../../../../reuseFunctions/objectFunctions'
import { formatPrice, thousandSeparator } from '../../../../../../reuseFunctions/formatValue'

class TransportView extends PureComponent {
    render() {
        const {details, edit} = this.props
        return (
            <div className="transport-info-view-wrapper">
                {!(checkIfObjectEmptyValues({
                    pickupDate: details.pickupDate,
                    targetArrivalDate: details.targetArrivalDate,
                    pickupAddress: details.pickupAddress,
                    pickupContacts: details.pickupContacts,
                    addressPrice: details.addressPrice,
                    addressNote: details.addressNote,
                    referenceNumber: details.referenceNumber,
                    transportShippingCompany: details.transportShippingCompany
                })) ? <div className="transport-info">
                        <div className="body-transport-info">
                            {!edit && details.referenceNumber && 
                            <div className="row-form">
                                <div className="label">Ref. ID:</div>
                                <div className="value">
                                    <strong>{details.referenceNumber}</strong>
                                </div>
                            </div>
                            }
                            {details.pickupDate && <div className="row-form">
                                <div className="label">Pickup Date:</div>
                                <div className="value">
                                    {moment(details.pickupDate).format('MMMM, DD')}
                                </div>
                            </div>
                            }
                            {details.targetArrivalDate && <div className="row-form">
                                <div className="label">Planned Arrival date:</div>
                                <div className="value">
                                    {moment(details.targetArrivalDate).format('MMMM, DD')}
                                </div>
                            </div>}
                            {details.pickupAddress && <div className="row-form">
                                <div className="label">Pickup Address:</div>
                                <div className="value">
                                    {details.pickupAddress}
                                </div>
                            </div>
                            }
                            {details.pickupContacts
                            && typeof details.pickupContacts === 'object'
                            && <div className="row-form">
                                <div className="label">Contact:</div>
                                <div className="value">
                                    <div className="wrap-contact-row">
                                        <div
                                            className="name-contact">{details.pickupContacts.name} {details.pickupContacts.secondName}</div>
                                        <div className="name-contact">{details.pickupContacts.position} </div>
                                        <div className="phone-contact"> {details.pickupContacts.phone}</div>
                                        <div className="email-contact"> {details.pickupContacts.email}</div>
                                    </div>

                                </div>
                            </div>
                            }
                            {details.transportShippingCompany && <div className="row-form">
                                <div className="label">Shipping Company:</div>
                                <div className="value">
                                    {details.transportShippingCompany}
                                </div>
                            </div>
                            }
                            {details.addressPrice !== null && <div className="row-form">
                                <div className="label">Price:</div>
                                <div className="value">
                                    <strong>{`€ ${thousandSeparator(formatPrice(details.addressPrice))}`}</strong>
                                </div>
                            </div>
                            }
                            {details.addressNote && <div className="row-form">
                                <div className="label">Note:</div>
                                <div className="value">
                                    {details.addressNote}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    : <div className="no-data-to-display">
                        No data to display...
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

export default connect(mapStateToProps, null)(TransportView)