import React, { PureComponent } from 'react'
import {Card, Row, Col, Form, Button} from 'react-bootstrap'
import { Formik } from 'formik'
import validator from 'validator'
import {changeRoute} from '../../reuseFunctions/changeRoute'
import { history } from '../../history'
// import { urlForgotPassword } from '../../constants'
import { url } from '../../constants'
import axios from 'axios'
import { convertToFormData } from '../../reuseFunctions/objectToFormData'
import Preloader from '../reuseComponent/preloader/preloader'
import { notify } from '../reuseComponent/toast'


const validateForm = values => {
    let errors = {}
    if(values.email === '')
        errors.email = 'required field'
    else if(!validator.isEmail(values.email))
        errors.email = 'incorrect email format'

    return errors
}


class ResetPassword extends PureComponent{

    state = {
        loading: false,
        formSubmit: false,
        initialValues: {
            email: ''
        }
    }

    handleSubmit = values => {
        values.link = window.location.origin + '/reset-password/confirm/'
        let data = convertToFormData(values)
        this.setState({
            loading: true,
            initialValues: values
        })
        axios.post(`${url}/user/reset_password`,data)
            .then(res => {
                this.setState({
                    formSubmit: true
                })
                // notify('success','The letter was sent to your email')
            })
            .catch(err => {
                notify('error','User with this email is not in the system')
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
    }

    render(){
        const { loading,formSubmit,initialValues } = this.state
        return(
            <Row className="rowForm">
                {!loading ? <Col md="6">
                        <Card>
                            <Card.Body>
                                {!formSubmit ?
                                    <Formik
                                        validate={validateForm}
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                    >
                                        {
                                            props => {
                                                return (
                                                    <Form noValidate onSubmit={props.handleSubmit}>
                                                        <Form.Group as={Col} xs="12" className="without-padding">
                                                            <Form.Label>Your email</Form.Label>
                                                            <Form.Control
                                                                onChange={props.handleChange}
                                                                value={props.values.email}
                                                                name="email"
                                                                type="email"
                                                                isInvalid={props.touched.email && props.errors.email}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {props.errors.email}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group className="footer-form footer-form-custom">
                                                            <Button variant="secondary"
                                                                    onClick={(e) => changeRoute('/login', history)}>Cancel</Button>
                                                                    <Button variant="primary" type="submit">Send</Button>
                                                        </Form.Group>
                                                    </Form>
                                                )
                                            }
                                        }
                                    </Formik>
                                    : <h4 style={{textAlign: 'center'}} className="center-text">The letter was sent to your email</h4>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    : <Preloader/>
                }
            </Row>
        )
    }
}

export default ResetPassword