export const permViewRoles = {
    access: 'view_roles',
    part: 'roles'
};

export const permEditRoles = {
    access: 'edit_roles',
    part: 'roles'
};

export const permViewNotifications = {
    access: 'view_notifications',
    part: 'notification'
};

export const permEditNotifications = {
    access: 'edit_notifications',
    part: 'notification'
};

export const permViewUsers = {
    access: 'view_users',
    part: 'users'
};

export const permEditUsers = {
    access: 'edit_users',
    part: 'users'
};

export const permViewHistory = {
    access: 'view_history',
    part: 'history'
};

export const permViewDepartmentsDictionary = {
    access: 'view_departments',
    part: 'departments'
};

export const permEditDepartmentsDictionary = {
    access: 'edit_departments',
    part: 'departments'
};

export const permSendNotifications = {
    access: 'send_email',
    part: 'notification'
};
