import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import DropdownMenu from '../../../../../../reuseComponent/DropDown/DropDownWithChildren/index'
import IconButtonMaterial from '../../../../../../reuseComponent/Buttons/ButtonWithMaterialIcon/index'
import {DropdownItem} from 'react-bootstrap'
import { removeComment } from '../../../../../../../actions/purchase-orders'
import CommentEditor from '../comment-editor/index'
import {findPermInArray} from "../../../../../../../reuseFunctions/checkPermission";
import {permEditChronology} from "../../../../../../../permissions/productOverview";


class CommentComponent extends PureComponent {

    state = {
        isEdit: false
    }

    handleChangeMode = () => {
        this.setState({
            isEdit: !this.state.isEdit
        })
    }
    render() {

        const {comment, userInfo,removeComment,details : { id }, roles, currUser} = this.props
        const {isEdit} = this.state
        
        const userId = currUser && currUser.info && currUser.info.id ? currUser.info.id : -1;
        const isCreatedByUser = comment.user.id === userId;
        return (
            !isEdit ? 
                <div className="history-item comment">
                    <div className="date-block">
                        {this.props.isShowDate && <span className="date">{moment(comment.createdAt).format('MMM DD, YYYY')}</span>}
                    </div>
                    <div className="comment-block">
                        <div className="comment-block-user">
                            <svg className="absolute-type-action-icon" viewBox="0 0 24 24">
                                <path fill="#000000" d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M17,11H15V9H17V11M13,11H11V9H13V11M9,11H7V9H9V11Z" />
                            </svg>
                            <span className="user-name-action">{comment.user.name} {comment.user.secondName}</span>
                            <span className="comment-content" dangerouslySetInnerHTML={{__html: comment.text}}/>
                        </div>
                        <span className="date-block">
                            <span className="date-history-action edit-comment">
                                    <span className="time">
                                        {userInfo.info.id === comment.user.id && <DropdownMenu
                                            ToggleButton={IconButtonMaterial}
                                            ToggleContent={() => <svg viewBox="0 0 24 24">
                                                <path fill="#000000" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                                            </svg>}
                                        >
                                            {(findPermInArray(roles, permEditChronology) || isCreatedByUser) &&
                                            <>
                                                <DropdownItem onClick={this.handleChangeMode}>Edit</DropdownItem>
                                                {findPermInArray(roles, permEditChronology) &&
                                                <DropdownItem onClick={() => removeComment({
                                                    commentId: comment.id,
                                                    id: id
                                                })}>Delete</DropdownItem>}
                                            </>
                                            }
                                        </DropdownMenu>
                                        }
                                    </span>
                                </span>
                                <span className="comment-time">{moment(comment.createdAt,'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}</span>
                            </span>
                        </div>
                    </div>
                    : <CommentEditor
                        handleCancel={this.handleChangeMode}
                        isEditing={true}
                        commentId={comment.id}
                        value={comment.text}
                    />
        )
    }

}

const mapStateToProps = state => {
    return {
        userInfo: state.usersReducer.currUser,
        details: state.purchaseOrdersReducer.detailsPurchase,
        roles: state.rolesReducer.roles,
        currUser: state.usersReducer.currUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removeComment: values => dispatch(removeComment(values))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentComponent)