import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {Button, DropdownItem} from "react-bootstrap";
import TransportView from "./transportView";
import CreateContactModal from "./createContact/index";
import { closeOpenSection, addTaskAction, changeDetailsAction, editTaskAction } from "../../../../../../actions/purchase-orders";
import { Formik } from "formik";
import moment from "moment";
import { schema } from "./transportInfoForm/schema";
import BodyForm from "./transportInfoForm/bodyForm";
import { findPermInArray } from "../../../../../../reuseFunctions/checkPermission";
import {
  permCompleteTransport,
  permEditTransport, permTaskChronology
} from "../../../../../../permissions/productOverview";
import TransportFiles from "./transportFiles/index";
import FormikWrapper from './createResponsible';
import Modal from '../../../../../reuseComponent/modal/index'
import { createTask, createTransportTask, changeCompleteStatus } from '../../../../../../services/tasks';
import { notify } from '../../../../../reuseComponent/toast/index';
import Tooltip from '../../../../../reuseComponent/Tooltips/index';
import { getTasksAction } from '../../../../../../actions/tasks';
import DropdownMenu from '../../../../../reuseComponent/DropDown/DropDownWithChildren';
import IconButtonMaterial from '../../../../../reuseComponent/Buttons/ButtonWithMaterialIcon';

class TransportInfo extends PureComponent {

  state = {
    edit: false,
    createModalOpen: false,
  };

  handleClickEdit = isEdit => {
    this.setState({
      edit: isEdit
    });
  };

  handleCreateTask = values => {
    const { addTaskAction, activeTab, getHistory, details, changeDetailsAction } = this.props
    values.poUrl = window.location.origin + '/atw/purchase-orders'
    values.taskUrl = window.location.origin + '/atw/tasks/tickets'
    values.title = `Responsibility for Transport of PO ${details.orderNumber}`;
    values.transport = true;
    values.transportDueDate = values.dueDate;
    values.transportDescription = values.description;

    delete values.responsible
    delete values.order
    this.setState({
      createModalOpen: false
    }, () => {
      createTask(values)
        .then(res => {
          addTaskAction(res.data.task ? res.data.task : {});
          const id = details.id;
          values.transportDueDate = values.dueDate;
          values.transportDescription = values.description;
          values.userId = values.executorId;
          createTransportTask(values, id)
            .then(res => {
              notify('success', 'Ticket successfully created');
              changeDetailsAction(res.data ? res.data : {});
              this.props.setActiveTasks();

              activeTab === '' && getHistory('')
            })
            .catch(err => {
              notify('error', 'Oops something went wrong')
            })
        })
        .catch(err => {
        })
    })
  }

  render() {
    const { edit, createModalOpen } = this.state;

    const { handleSubmit, details, closeOpenSection, roles, tasksList, editTask, deleteTask } = this.props;
    const hasPermToEdit = (findPermInArray(roles, permTaskChronology));
    const task = tasksList.filter(task => task.transport === true)[0];

    const getDueDate = () => {
      const transportTask = tasksList && tasksList.filter(task => task.transport === true)[0];
      if (
          transportTask &&
          transportTask.dueDate &&
          moment(transportTask.dueDate).isValid()
      ) {
          return moment(transportTask.dueDate).format('MMM D');
      }

      return "No due date";
    }

    const handleMarkCompleteTask = (task) => {
      const { editTaskAction } = this.props;
      changeCompleteStatus(task && task.id ? task.id : null)
          .then(res => {
              editTaskAction(res.data[1] ? res.data[1] : {});
          })
    }

    const markComplete = () => {
      const transportTask = tasksList && tasksList.filter(task => task.transport === true)[0];
      handleMarkCompleteTask(transportTask);
      closeOpenSection("transport");
    }

    return (
      <>
        <Formik
          onSubmit={values =>
            handleSubmit(values, () => this.handleClickEdit(false))
          }
          initialValues={{
            pickupContacts:
              details.pickupContacts && typeof details.pickupContacts === "object"
                ? details.pickupContacts.id
                : "",
            referenceNumber: details.referenceNumber || "",
            pickupDate: details.pickupDate
              ? moment(details.pickupDate).format("DD.MM.YYYY")
              : "",
            targetArrivalDate: details.targetArrivalDate
              ? moment(details.targetArrivalDate).format("DD.MM.YYYY")
              : "",
            pickupAddress: details.pickupAddress || "",
            transportShippingCompany: details.transportShippingCompany || "",
            addressNote: details.addressNote ? details.addressNote : "",
            addressPrice:
              details.addressPrice !== null ? details.addressPrice : ""
          }}
          enableReinitialize={true}
          validationSchema={schema}
          render={props => (
            <div className="transport-block">
              <div className="title-transport">
                <div className="name-block">
                  <p className="title">Transport</p>
                </div>

                <div className="action-transport">
                  {task != null && task != undefined && task.executor && task.executor.name ?
                    <div className="d-flex align-center">
                      <span className="deadline-value">
                        <svg viewBox="0 0 24 24">
                            <path fill="#000000"
                                  d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z"/>
                        </svg>
                        {
                          getDueDate()
                        }
                      </span>
                      {task.executor.department && <Tooltip text={task.executor.department.name}>
                        <span className="icon-department"
                          dangerouslySetInnerHTML={{ __html: task.executor.department.icon }}></span>
                      </Tooltip>}
                      <Tooltip
                        text={`${task.executor.name} ${task.executor.secondName}`}
                      >
                        <span className={`executor-value`} style={{
                          color: task.executor.initialsColor
                        }}>{task.executor.initials}</span>
                      </Tooltip>
                    </div> : (
                      <Button
                        type="button"
                        onClick={() => this.setState({
                          createModalOpen: true
                        })}
                        className={'with-icon'}
                      >
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="#FF9800"
                            d="M19,4H18V2H16V4H8V2H6V4H5A2,2 0 0,0 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M5,8V6H19V8H5M10.56,18.46L16.5,12.53L15.43,11.47L10.56,16.34L8.45,14.23L7.39,15.29L10.56,18.46Z"
                          />
                        </svg>
                    Responsible
                      </Button>
                    )
                  }

                  {task != null && task != undefined && task.executor != null && hasPermToEdit &&
                      <div className="margin-left-center">
                  <DropdownMenu

                      ToggleContent={() => <svg
                                                viewBox="0 0 24 24">
                        <path fill="#000000" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                      </svg>}
                  >
                    <>

                      <DropdownItem onClick={() => editTask(task.id, false)}>Edit</DropdownItem>
                      <DropdownItem onClick={() => deleteTask(task.id, task.title)}>Delete</DropdownItem>
                    </>
                  </DropdownMenu>
                      </div>
                  }

                  {findPermInArray(roles, permCompleteTransport) && !edit && details.responsibleTransportPerson != null &&(
                    <Button
                      type="button"
                      onClick={() => markComplete()}
                      className={`with-icon ${details.transportCompleted ? "complete" : ""
                        }`}
                    >
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="#000000"
                          d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                        />
                      </svg>
                      {details.transportCompleted ? "Completed" : "Mark Complete"}
                    </Button>
                  )}
                  {findPermInArray(roles, permEditTransport) &&
                    !details.transportCompleted && (
                      <>
                        {edit && (
                          <Button
                            onClick={props.handleSubmit}
                            type="button"
                            className="with-icon"
                          >
                            <svg viewBox="0 0 24 24">
                              <path
                                fill="#000000"
                                d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"
                              />
                            </svg>
                            Save
                          </Button>
                        )}
                        <Button
                          type="button"
                          className="with-icon"
                          onClick={() => {
                            props.setValues(props.initialValues);
                            this.handleClickEdit(!edit);
                          }}
                        >
                          {edit ? (
                            <svg viewBox="0 0 24 24">
                              <path
                                fill="#000000"
                                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                              />
                            </svg>
                          ) : (
                              <svg viewBox="0 0 24 24">
                                <path
                                  fill="#000000"
                                  d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                                />
                              </svg>
                            )}
                          {edit ? "Cancel" : "Edit"}
                        </Button>
                      </>
                    )}
                  <div className="add-transport-file">
                    <TransportFiles />
                  </div>
                </div>
              </div>
              {!edit ? <TransportView edit={edit} /> : <BodyForm {...props} />}
              <CreateContactModal
                setContactId={id => props.setFieldValue("pickupContacts", id)}
              />
            </div>
          )}
        />
        <Modal
          Content={() => <FormikWrapper
            createFromPo={true}
            handleSubmit={this.handleCreateTask}
            handleCancel={() => this.setState({
              createModalOpen: false
            })}
            initValues={{
              dueDate: '',
              poId: details.id,
              executorId: '',
              departmentId: '',
              description: '',
              title: '',
              responsible: null,
              order: null
            }}
          />}
          open={createModalOpen}
          isFooter={false}
          handleClose={() => this.setState({
            createModalOpen: false
          })}
          options={{
            centered: true
          }}
          classNameModal="modal-800"
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    details: state.purchaseOrdersReducer.detailsPurchase,
    roles: state.rolesReducer.roles,
    tasksList : state.purchaseOrdersReducer.tasksList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeOpenSection: section => dispatch(closeOpenSection(section)),
    addTaskAction: task => dispatch(addTaskAction(task)),
    changeDetailsAction: po => dispatch(changeDetailsAction(po)),
    editTaskAction: data => dispatch(editTaskAction(data)),
    setActiveTasks: () => getTasksAction(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransportInfo);
