export const permViewInventory = {
    access: 'view_inventory',
    part: 'inventory'
}

export const permEditInventory = {
    access: 'edit_inventory',
    part: 'inventory'
}

export const permViewMapEditor = {
    access: 'edit_map',
    part: 'inventory'
}

export const permEditMapEditor = {
    access: 'edit_map',
    part: 'inventory'
}