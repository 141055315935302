import React, { PureComponent } from 'react'
import { Switch} from 'react-router-dom'
import ListUsers from './List'
import ProtectedRoute from '../../reuseComponent/Routes/protected-route'
import { permViewUsers,permEditUsers } from '../../../permissions/users'
// import UserEdit from './edit'

export default class UserModule extends PureComponent{

    render() {
        return (
                <Switch>
                    <ProtectedRoute
                        exact
                        path={["/atw/settings/users/:id" , "/atw/settings/users"]}
                        permission={[permViewUsers, permEditUsers]} component={ListUsers}
                    />
                </Switch>
        )
    }
}