import {INBOUNDING_REPORT} from "../constants";
import {
    getPackages,
    getBoxes,
    getProductsFromBox,
    createCustomPackage,
    editCustomPackage,
    removeCustomPackage, createCustomPackageWithAmountOfPacks, editCustomPackageWithAmountOfPacks,
} from '../services/products'
import {notify} from "../components/reuseComponent/toast";

const setLoadingPackagesAction = loading => ({
    type: INBOUNDING_REPORT.SET_LOADING_PACKAGES_INBOUNDING,
    payload: loading,
});

const setLoadingBoxesAction = loading => ({
    type: INBOUNDING_REPORT.SET_LOADING_BOXES_INBOUNDING,
    payload: loading,
});

const setLoadingProductsAction = (loading, boxId) => ({
    type: INBOUNDING_REPORT.SET_LOADING_PRODUCTS_INBOUNDING,
    payload: {
        loading, boxId
    },
});

export const setPackagesAction = packages => ({
    type: INBOUNDING_REPORT.SET_PACKAGES_INBOUNDING,
    payload: packages,
});


const setBoxesAction = boxes => ({
    type: INBOUNDING_REPORT.SET_BOXES_INBOUNDING,
    payload: boxes,
});

export const setActivePackage = pack => ({
    type: INBOUNDING_REPORT.SET_ACTIVE_PACKAGE_INBOUNDING,
    payload: pack,
})

const setProducts = (products, boxId) => ({
    type: INBOUNDING_REPORT.SET_PRODUCTS_INBOUNDING,
    payload: {
        boxId,
        products,
    },
});

const updateInboundReportAction = (report, packages, boxes) => ({
    type: INBOUNDING_REPORT.UPDATE_REPORT,
    payload: {
        report,
        packages,
        boxes
    }
})

export const updateInboundReport = (report, packages) => (dispatch, getState) => {

    const packagesOld = getState().inboundingReportReducer.packages;
    const boxesOld = getState().inboundingReportReducer.boxes;
    const activePackage = getState().inboundingReportReducer.activePackage;
    let packagesNew = [];

    const newActivePackage = activePackage ? packages.find(item => item.id === activePackage.id) : null;
    const boxes = newActivePackage ? newActivePackage.boxes : null;

    //go for array of prevPackages, if found in new chage it
    packagesOld.forEach(pack => {
        const found = packages.find(item => {
            return item.id === pack.id});
        if(found){
            const {boxes, ...newPackage} = found;
            packagesNew.push(newPackage)
        }else{
            packagesNew.push(pack);
        }
    });


    //ebychuy vyser, dannye to soketam ne taie kak po requestam
    //generating new boxes array
    let newBoxes = [];

    if(newActivePackage){
        //check new boxes array and if new box has't products or product length === 0 we skip it
        boxes.forEach(box => {
            const found = boxesOld.find(item => item.id === box.id)
            if(found){
                if(box.products === undefined || box.products.length === 0){
                    newBoxes.push({...found, closed: box.closed, productsCount: box.productsCount});
                }else{

                    const newProducts = [];

                    const reallyNewProducts = []
                    //find new products
                    box.products.forEach(b => {
                        const newProd = found.products.find(product => b.product.id === product.product.id);
                        if(!newProd && b.itemsCount !== 0){
                            reallyNewProducts.push(b);
                        }
                    })

                    //we have changed products, if count === 0 product was removed
                    found.products.forEach(product => {
                        const prodFound = box.products.find(b => b.product.id === product.product.id);
                        if(prodFound){
                            if(prodFound.itemsCount !== 0) {
                              newProducts.push(prodFound);
                            }
                        }else{
                            newProducts.push(product);
                        }
                    })
                    newBoxes.push({...found, closed: box.closed, productsCount: box.productsCount,
                        products: [...newProducts, ...reallyNewProducts]});
                }
            }
        })
    }

    dispatch(updateInboundReportAction(report, packagesNew, newActivePackage ? newBoxes : null))
};

export const fetchCreateCustomPackage = (values, callback) => (dispatch, getState) => {
    const details = getState().purchaseOrdersReducer.detailsPurchase
    createCustomPackage(details.id, values).then(res => {
        getPackages(details.id)
        .then(res => {
            dispatch(setPackagesAction(res.data))
        })
        .catch(err => notify('error','Oops something went wrong'))
        .finally(() => dispatch(setLoadingPackagesAction(false)))
        callback()
        notify('success', 'Package successfully created')
    }).catch(err => {
        notify('error','Oops something went wrong')
        callback()
    })
}

export const fetchCreateCustomPackageWithAmountOfPacks = (values, callback) => (dispatch, getState) => {
    const details = getState().purchaseOrdersReducer.detailsPurchase
    createCustomPackageWithAmountOfPacks(details.id, values).then(res => {
        getPackages(details.id)
          .then(res => {
              dispatch(setPackagesAction(res.data))
          })
          .catch(err => notify('error','Oops something went wrong'))
          .finally(() => dispatch(setLoadingPackagesAction(false)))
        callback()
        notify('success', 'Package successfully created')
    }).catch(err => {
        notify('error','Oops something went wrong')
        callback()
    })
}

export const fetchEditCustomPackage = (packId, values, callback) => (dispatch, getState) => {
    const details = getState().purchaseOrdersReducer.detailsPurchase 
    editCustomPackage(details.id, packId, values).then(res => {
        getPackages(details.id)
        .then(res => {
            dispatch(setPackagesAction(res.data))
        })
        .catch(err => notify('error','Oops something went wrong'))
        .finally(() => dispatch(setLoadingPackagesAction(false)))
        callback()
        notify('success', 'Package successfully edited')
    }).catch(err => {
        notify('error','Oops something went wrong')
        callback()
    })
}

export const fetchEditCustomPackageWithAmountOfPacks = (packId, values, callback) => (dispatch, getState) => {
    const details = getState().purchaseOrdersReducer.detailsPurchase
    editCustomPackageWithAmountOfPacks(details.id, packId, values).then(res => {
        getPackages(details.id)
          .then(res => {
              dispatch(setPackagesAction(res.data))
          })
          .catch(err => notify('error','Oops something went wrong'))
          .finally(() => dispatch(setLoadingPackagesAction(false)))
        callback()
        notify('success', 'Package successfully edited')
    }).catch(err => {
        notify('error','Oops something went wrong')
        callback()
    })
}

export const fetchDeleteCustomPackage = (packId, callback) => (dispatch, getState) => {
    const details = getState().purchaseOrdersReducer.detailsPurchase
    removeCustomPackage(packId)
      .then(res => {
          getPackages(details.id)
            .then(res => {
                dispatch(setPackagesAction(res.data))
            })
            .catch(err => notify('error','Oops something went wrong'))
            .finally(() => dispatch(setLoadingPackagesAction(false)))
          callback()
          notify('success', 'Package successfully deleted')
      })
      .catch(err => {
        notify('error','Oops something went wrong')
        callback()
    })
}

export const getPackagesRed = id => dispatch => {
    dispatch(setLoadingPackagesAction(true))
    getPackages(id)
        .then(res => {
            console.log(res.data)
            dispatch(setPackagesAction(res.data))
        })
        .catch(err => notify('error','Oops something went wrong'))
        .finally(() => dispatch(setLoadingPackagesAction(false)))
}

export const getBoxByPackage = packageId => (dispatch, getState) => {
    dispatch(setLoadingBoxesAction(true));
    const activePackage = getState().inboundingReportReducer.packages.find(item => item.id === packageId);
    dispatch(setActivePackage(activePackage))
    getBoxes(packageId)
        .then(res => {
            dispatch(setBoxesAction(res.data.map(item => ({...item, isLoading: false, products: []}))))

        })
        .catch(err => notify('error','Oops something went wrong'))
        .finally(() => dispatch(setLoadingBoxesAction(false)))
}

export const getProducts = boxId => dispatch => {
    dispatch(setLoadingProductsAction(true, boxId))
    getProductsFromBox(boxId)
        .then(res => {
            console.log('setProducts',res.data)
            dispatch(setProducts(res.data, boxId));
        })
        .catch(err => notify('error','Oops something went wrong'))
        .finally(() => dispatch(setLoadingProductsAction(false, boxId)))
};

export const clearInboundReport  = () => ({
    type: INBOUNDING_REPORT.CLEAR_INBOUND_REPORT_REDUCER
})

export const setSearchPackages = (packages) => ({
    type: INBOUNDING_REPORT.SET_SEARCHED_PACKAGES,
    payload: packages
})

export const updateBoxCount = (pack, box, bid) => ({
    type: INBOUNDING_REPORT.UPDATE_BOX_ITEM_COUNT,
    payload: {
        pack,
        box,
        bid,
    }
})