import React from 'react';
import { useSelector } from 'react-redux';

const StandartLeftPanner = ({title, logo}) => {
    const activeTickets = useSelector(state => state.ticketsReducer.activeTickets);

    return (
        <div className="header-wrapper">
            {logo}
            <h3 className="title-text m-10">
                {title}
                {
                    activeTickets && activeTickets > 0 && title === 'Tickets' ? (
                        <div className="badge badge-header">{activeTickets}</div>
                    ) : null
                }
            </h3>
        </div>
    )
}

export default StandartLeftPanner;