import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import RestComponent from './rest-component'
import {getDividing, getFieldsForCondition,getProducts,setConditionSetsAndFieldsAction} from '../../../../../actions/dividing'
import ConditionSet from './condition-set'
import PackagesComponent from './packages'
import Preloader from '../../../../reuseComponent/preloader/preloader'
import AllComponent from './all-component'


class DividingModule extends PureComponent {

    componentDidMount() {
        const {getDividing, details, getFields,getProducts,snapshot,setSets} = this.props
        // console.log(JSON.parse(snapshot.dividing))
        if(snapshot){
            setSets(JSON.parse(snapshot.dividing))
        }
        else{
            getDividing && getDividing(details.id)
        }
        getFields && getFields()
        getProducts && getProducts()
        // getStatus && getStatus()
    }

    render() {
        const {conditionSets,isLoading,isProductsExist,isChangingSet,isLoadingDividngResult,isExporting} = this.props
        const rest = conditionSets.find(set => set.rest)
        return (
            <>
                <div className="wrap-dividing">
                    {(isLoading || isChangingSet || isLoadingDividngResult || isExporting) && <Preloader text={isLoading ?
                        'Loading'
                        : isLoadingDividngResult ? 'Dividing'
                        : isExporting ? 'Downloading' : 'Loading'}/>}
                    {isProductsExist ? <>
                            <div className="dividing-row header-row">
                                <div className="left-dividing-part">
                                    Condition
                                </div>
                                <div className="right-dividing-part">
                                    Dividing
                                </div>
                            </div>
                            <div className="dividing-row body-row">
                                <div className="left-dividing-part">
                                    <AllComponent />
                                    {rest && <RestComponent rest={rest}/>}
                                    {conditionSets
                                        .filter(set => !set.unordered && !set.overdelivered && !set.custom)
                                        .map(set => !set.rest ? <ConditionSet key={set.id} set={set}/> : null)}
                                </div>
                                <div className="right-dividing-part">
                                    <PackagesComponent/>
                                </div>
                            </div>
                        </>
                        : <h5>No records to display</h5>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoadingDividngResult: state.dividingReducer.isLoadingDividngResult,
        isChangingSet: state.dividingReducer.isChangingSet,
        isLoading:  state.dividingReducer.isLoadingProducts
            || state.dividingReducer.isLoadingStatus,
        details: state.purchaseOrdersReducer.detailsPurchase,
        conditionSets: state.dividingReducer.conditionSets,
        isProductsExist: state.dividingReducer.isProductsExist,
        roles: state.rolesReducer.roles,
        snapshot: state.purchaseOrdersReducer.snapshot
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDividing: poId => dispatch(getDividing(poId)),
        getFields: () => dispatch(getFieldsForCondition()),
        getProducts: () => dispatch(getProducts()),
        setSets: sets => dispatch(setConditionSetsAndFieldsAction(sets))
        // getStatus: () => dispatch(getDividingStatus())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DividingModule)