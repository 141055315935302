import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { FieldArray } from "formik";
import Preloader from "../../preloader/preloader";
import { getFieldsForFilterByAlias } from "../../../../services/smart-filter";
import ConditionRow from "./condition-row";
import uniqId from "uniqid";

class FormBody extends PureComponent {
  state = {
    isLoading: true,
    fields: [],
  };

  componentDidMount() {
    const promise = getFieldsForFilterByAlias(this.props.alias);
    promise.then(res => {
      this.setState({
        isLoading: false,
        fields: res.data,
      });
    });
  }

  render() {
    const { isLoading, fields } = this.state;
    const {
      handleSubmit,
      handleChange,
      values,
      errors,
      touched,
      setValues,
      setFieldValue,
      handleCancel,
      isServiceDesk, isCustomers
    } = this.props;

    return isLoading || this.props.isLoading ? (
      <Preloader />
    ) : (
      <Form className="smart-filter-po-form" noValidate onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            name="name"
            isInvalid={errors.name && touched.name}
            onChange={handleChange}
            value={values.name ? values.name : ""}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>"AND" Conditions</Form.Label>
          <FieldArray
            name="criterias"
            render={arrayHelpers => (
              <div className="list-conditions">
                {values.criterias.map((criteria, index) => {
                  return (
                    <ConditionRow
                      key={criteria.id}
                      condition={criteria}
                      fields={fields}
                      errors={errors}
                      touched={touched}
                      removeCondition={() => arrayHelpers.remove(index)}
                      index={index}
                      name="criterias"
                      values={values}
                      setFieldValue={setFieldValue}
                      setValues={setValues}
                      isServiceDesk={isServiceDesk}
                      isCustomers={isCustomers}
                    />
                  );
                
                })}
                {isServiceDesk && values.criterias.length < fields.length && (
                  <button
                    type="button"
                    className="add-condition-button"
                    onClick={() => {
                      arrayHelpers.push({
                        value: "",
                        criteria: "",
                        field: "",
                        fieldNameForm: "",
                        fieldCriteriaNameForm: "",
                        fieldValueForm: "",
                        id: uniqId()
                      });
                    }}
                  >
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="#000000"
                        d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z"
                      />
                    </svg>{" "}
                    Add condition
                  </button>
                )}
                {!isServiceDesk && (
                  <button
                    type="button"
                    className="add-condition-button"
                    onClick={() =>
                      arrayHelpers.push({
                        value: "",
                        criteria: "",
                        field: "",
                        fieldNameForm: "",
                        fieldCriteriaNameForm: "",
                        fieldValueForm: "",
                        id: uniqId()
                      })
                    }
                  >
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="#000000"
                        d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z"
                      />
                    </svg>{" "}
                    Add condition
                  </button>
                )}
              </div>
            )}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>"OR" Conditions</Form.Label>
          <FieldArray
            name="orCriterias"
            render={arrayHelpers => (
              <div className="list-conditions">
                {values.orCriterias.map((criteria, index) => (
                  <ConditionRow
                    key={criteria.id}
                    condition={criteria}
                    fields={fields}
                    errors={errors}
                    touched={touched}
                    removeCondition={() => arrayHelpers.remove(index)}
                    index={index}
                    name="orCriterias"
                    values={values}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                  />
                ))}
                <button
                  type="button"
                  className="add-condition-button"
                  onClick={() =>
                    arrayHelpers.push({
                      value: "",
                      criteria: "",
                      field: "",
                      fieldNameForm: "",
                      fieldCriteriaNameForm: "",
                      fieldValueForm: "",
                      id: uniqId()
                    })
                  }
                >
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="#000000"
                      d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z"
                    />
                  </svg>{" "}
                  Add Condition
                </button>
              </div>
            )}
          />
        </Form.Group>
        <Form.Group className='form-group-error'>
          {errors.criterias &&
            touched.criterias &&
            typeof errors.criterias === "string" && (
              <span
                style={{
                  color: "red"
                }}
              >
                {errors.criterias}
              </span>
            )}
        </Form.Group>
        <Form.Group className="footer-form footer-form-custom">
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Form.Group>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.usersReducer.currUser,
})

export default connect(mapStateToProps, null)(FormBody);
