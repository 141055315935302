import { url } from '../constants';
import  adminInstance from '../components/instances/admininstance';
import {convertToFormData} from "../reuseFunctions/objectToFormData";
import qs from 'qs';

export const getPaginatableSimpleParameter = (alias, page, limit, search) => {
    return adminInstance(`${url}/simple_parameter/${alias}/filtered_and_paginatable`, {
            params: {
                page: page ? page : 0,
                limit: limit,
                search: search
            }
    })
};

export const getPaginatableFewSimpleParameters = ( aliases, page, limit, search) => {
    return adminInstance(`${url}/simple_parameter/get_several_dictionary`, {
        params: {
            aliases: aliases,
            page: page ? page : 0,
            limit: limit,
            search: search
        },
        paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: 'repeat' }),
    })
}

export const checkRelations = (id, alias) => {
    return adminInstance.get(`${url}/simple_parameter/exist-relations/${alias}/${id}`)
}

export const removeDictionaryValue = (alias, id) => {
    return adminInstance.delete(`${url}/simple_parameter/${alias}/${id}`);
}

export const getDepartments = (page = 0,limit = 10) => {
    return adminInstance.get(`${url}/department`,{
        params: {
            page: page,
            limit: limit,
        }
    })
}

export const getTaskTemplateById = id => adminInstance.get(`${url}/template/task/${id}`);

export const createTaskTemplate = (values) =>
    adminInstance.post(`${url}/template/task`, convertToFormData(values));

export const editTaskTemplate = (id, values) =>
    adminInstance.put(`${url}/template/task/${id}`, convertToFormData(values));

export const removeTaskTemplate = id =>
    adminInstance.delete(`${url}/template/task/${id}`);

export const updateTaskTemplateField = (id, key, value) =>
    adminInstance.put(`${url}/template/task/${id}/edit_field`, convertToFormData({key, value}));