import React, { useState, useCallback, useEffect, useMemo } from 'react'

import ThirdStep from '../../edit/thirdStep'
import { Button } from 'react-bootstrap/index'
import * as yup from 'yup'
import isEqual from "react-fast-compare"
import { notify } from '../../../../reuseComponent/toast'
import { formatCoordinatesToView } from '../../../../../reuseFunctions/mapUtils'
import { changeRoutePush } from '../../../../../reuseFunctions/changeRoute'

const schema = yup.object().shape({
  netWeight: yup.number()
    .required('required field')
    .typeError('positive numbers only')
    .positive('positive numbers only'),
  weight: yup.number()
    .required('required field')
    .typeError('positive numbers only')
    .positive('positive numbers only'),
  productName: yup.string().required('required field').typeError('required field'),
  statusId: yup.string().required('required field').typeError('required field'),
  packTypeId: yup.string().required('required field').typeError('required field'),
  locationId: yup.string().required('required field').typeError('required field'),
  cg1: yup.array().min(1, 'required field').required('required field').typeError('required field'),
  cg2: yup.array().min(1, 'required field').required('required field').typeError('required field'),
})

const EditPallet = ({handleClose, handleEditPallet, pallet}) => {

  const [values, setValues] = useState({
    weight: '',
    netWeight: '',
    productName: '',
    statusId: '',
    packTypeId: '',
    locationId: '',
    cg1: [],
    cg2: [],
    salesManager: '',
    note: '',
    orderNumber: '',
  })
  const [errors, setErrors] = useState({
    weightError: '',
    netWeightError: '',
    statusIdError: '',
    packTypeIdError: '',
    productNameError: '',
    locationIdError: '',
    cg1Error: '',
    cg2Error: '',
    salesManagerError: '',
    noteError: '',
    orderNumberError: '',
  })
  const [isTouched, setIsTouched] = useState(false)

  const itemsCount = useMemo(() => {
    let res = 0;
    res = pallet.boxes.reduce((sum, box) => sum + (box.poInboundType === 'Totals' ? box.item : box.productsCount), 0)
    return res
  }, [pallet])

  const handleChange = useCallback((fieldName, e) => {
    //todo
    const target = e.target
    schema.validate({
      'netWeight': fieldName === 'netWeight' ? target.value : values.netWeight,
      'weight': fieldName === 'weight' ? target.value : values.weight,
      'productName': fieldName === 'productName' ? e.value : values.productName,
      'statusId': fieldName === 'statusId' ? e.value : values.statusId,
      'packTypeId': fieldName === 'statusId' ? e.value : values.packTypeId,
      'locationId': fieldName === 'locationId' ? e.value : values.locationId,
      'cg1': fieldName === 'cg1' ? e : values.cg1,
      'cg2': fieldName === 'cg2' ? e : values.cg2,
    })
      .then(res => {
        setErrors({
          weightError: '',
          netWeightError: '',
          statusIdError: '',
          packTypeIdError: '',
          productNameError: '',
          locationIdError: '',
          cg1Error: '',
          cg2Error: '',
          salesManagerError: '',
          noteError: '',
          orderNumberError: '',
        })
      })
      .catch(err => {
        setErrors(prevState => ({
          ...prevState,
          [`${err.path}Error`]: err.errors[0]
        }))
      })
    setValues(prevState => ({
      ...prevState,
      [fieldName]: (fieldName !== 'statusId' && fieldName !== 'productName' && fieldName !== 'locationId' && fieldName !== 'cg1' && fieldName !== 'cg2')
                   ? ((fieldName !== 'packTypeId' && fieldName !== 'quality' && fieldName !== 'salesManager') ? target.value : (e ? (fieldName == 'salesManager' ? e : e.value) : e)) : e
    }))
  }, [values])

  useEffect(() =>{
    setValues({
                bid: pallet.bid,
                season: pallet.season,
                quality: pallet.quality,
                weight: pallet.weight,
                netWeight: pallet.netWeight,
                productName: {label: pallet.productName, value: pallet.productName},
                statusId: pallet.palletStatus ? pallet.palletStatus.id : '',
                packTypeId: pallet.palletPackType ? pallet.palletPackType.id : '',
                locationId: pallet.location ? ({
                  value: pallet.location.id,
                  label: formatCoordinatesToView(`${pallet.location.coordinateX}-${pallet.location.coordinateY}`)
                }) : null,
                cg1: pallet.cg1.map(item => ({
                  value: item,
                  label: item,
                })),
                cg2: pallet.cg2.map(item => ({
                  value: item,
                  label: item,
                })),
                note: pallet.note,
                orderNumber: pallet.orderNumber,
                salesManager: pallet.salesManager && (pallet.salesManager.name + ' ' + pallet.salesManager.secondName),
              })
  }, [pallet])

  const handleSubmit = useCallback((values) => {
    const valuesToCompare = {
      netWeight: values.netWeight,
      packTypeId: values.packTypeId,
      statusId: values.statusId,
      weight: values.weight,
      productName: values.productName.value,
      locationId: values.locationId,
      cg1: values.cg1,
      cg2: values.cg2,
      bid: values.bid,
      season: values.season,
      quality: values.quality,
      note: values.note,
      orderNumber: values.orderNumber,
      salesManager: pallet.salesManager && (pallet.salesManager.name + ' ' + pallet.salesManager.secondName),
    }
    const prevValuesToCompare = {
      netWeight: pallet.netWeight,
      packTypeId: pallet.packTypeId,
      statusId: pallet.statusId,
      weight: pallet.weight,
      productName: pallet.productName,
      locationId: pallet.location,
      cg1: pallet.cg1.map(item => ({
        value: item,
        label: item,
      })),
      cg2: pallet.cg2.map(item => ({
        value: item,
        label: item,
      })),
      bid: pallet.bid,
      season: pallet.season,
      quality: pallet.quality,
      note: pallet.note,
      orderNumber: pallet.orderNumber,
      salesManager: pallet.salesManager && (pallet.salesManager.name + ' ' + pallet.salesManager.secondName),
    }

      let orderNumberValidation = true;
      let salesManagerValidation = true;

      if(values.statusId == 1 && !values.orderNumber){
          orderNumberValidation = false;
      }

      if((values.statusId == 1 || values.statusId == 2)&& !values.salesManager){
          salesManagerValidation = false;
      }

    if( orderNumberValidation && salesManagerValidation && values.weight && values.netWeight && !errors.netWeightError && !errors.weightError
      && !errors.productNameError && !errors.locationIdError && (itemsCount ? true : !errors.cg1Error && !errors.cg2Error)){
      setIsTouched(false)

      let sendData = {
        ...values,
        locationId: values.locationId.value,
        productName: values.productName.value,
        boxesId: pallet.boxes.map(box => box.id),
        statusId: values.statusId ? values.statusId : null,
        cg1: itemsCount ? null : values.cg1.map(item => item.value).join(','),
        cg2: itemsCount ? null : values.cg2.map(item => item.value).join(','),
      }

      if(itemsCount){
        delete sendData.cg1
        delete sendData.cg2
      }

      handleEditPallet(sendData
      , () => {
        if(!isEqual(valuesToCompare, prevValuesToCompare))
          notify('success', 'Pallet successfully edited')
      })
    }else{
      setIsTouched(true)
    }
  }, [pallet, errors, handleEditPallet, itemsCount])

  return (
    <>
      <ThirdStep
        weightError={errors.weightError}
        netWeightError={errors.netWeightError}
        statusIdError={errors.statusIdError}
        packTypeIdError={errors.packTypeIdError}
        locationIdError={errors.locationIdError}
        isTouched={isTouched}
        weight={values.weight}
        netWeight={values.netWeight}
        productName={values.productName}
        statusId={values.statusId}
        locationId={values.locationId}
        packTypeId={values.packTypeId}
        handleChange={handleChange}
        itemsCount={itemsCount}
        cg1={values.cg1}
        cg2={values.cg2}
        cg1Error={errors.cg1Error}
        cg2Error={errors.cg2Error}
        bid={values.bid}
        season={values.season}
        quality={values.quality}
        note= {values.note}
        orderNumber= {values.orderNumber}
        salesManager= {values.salesManager}
      />
      <div className="footer-form footer-form-custom">
        <Button className="cancel"
                onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(values)}>Save</Button>
      </div>
    </>
  )
}

export { EditPallet }