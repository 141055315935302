import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";
// import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";

const  RejectModal = ({
    commentTitle,
    handleConfirm,
    handleCancel,
    children,
      disableConfirmButton,
      disableCancelButton,
                            }) => {
    const [reason, setReason] = useState('');
    const [touched, setTouched] = useState(false);
    return (
        <>
            {children}
            <Form.Group className="reject-modal">
                <Form.Label>{commentTitle}</Form.Label>
                <Form.Control
                    as="textarea"
                    value={reason}
                    onChange={e => {
                        setReason(e.target.value);
                        setTouched(true);
                    }}
                    onBlur={e => setReason(reason.trim())}
                    isInvalid={touched && !reason}
                />
                <Form.Control.Feedback type="invalid">
                    Required field
                </Form.Control.Feedback>
            </Form.Group>
            <div className="footer-modal-delete footer-form-custom">
                <Button
                    disabled={disableCancelButton}
                    variant="secondary"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    disabled={disableConfirmButton}
                    variant="primary"
                    onClick={() => {
                        setTouched(true);
                        if(reason)
                            handleConfirm(reason)
                    }}
                >
                    Confirm
                </Button>
            </div>
        </>
    )
}

RejectModal.defaultProps = {
    disableConfirmButton: false,
    disableCancelButton: false,
}

export default RejectModal