import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import classNames from 'classnames'

import { setData } from '../../../../actions/admin-header'
import { InventoryTabs } from '../InventoryTabs'
import { INVENTORY_TABS } from '../constants'
import { PlanAPI } from '../../../../services/plan'
import { defaultServerError } from '../../../../reuseFunctions/toasts'
import { LocationAPI } from '../../../../services/location'
import { InventoryMapMap } from './InventoryMapMap'
// import InventoryMapMap from './InventoryMapMap'
import { InventoryMapToolbar } from './InventoryMapToolbar'
import { EditPallet } from './PalletDetail/EditPallet'
import { history } from '../../../../history'

import './style.scss'
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'
import { permEditInventory } from '../../../../permissions/inventory'
import Modal from '../../../reuseComponent/modal/modalWithChildren'
import CreatePallet from '../create'
import adminsInstance from '../../../instances/admininstance'
import { url } from '../../../../constants'
import { setPackTypesListAction, setPlanAction, setStatusesListAction } from '../../../../actions/inventory'
import { PalletDetailModal } from './PalletDetail/PalletDetailModal'
import { changePalletLocation, getPalletByIdService } from '../../../../services/pallet'
import { notify } from '../../../reuseComponent/toast'
import { formatCoordinatesToView, generateCoordinates } from '../../../../reuseFunctions/mapUtils'
import { deletePallet, editPallet } from '../../../../services/inventory'
import { ConfirmModalWithText } from '../../../reuseComponent/confirmModalComponent/ConfirmModalWithText'
import { checkIfPaginationIsEmpty, defaultFilterData } from './constants'
import { PreloaderWithText } from '../../../reuseComponent/PreloaderWithText/PreloaderWithText'
import { changeRoute, changeRoutePush } from '../../../../reuseFunctions/changeRoute'
import {Link} from "react-router-dom";
import {listenScanning} from '../scanning';

const InventoryMap = () => {

  const [plan, setPlan] = useState(null)
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState([])
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [coordinatesArray, setCoordinatesArray] = useState(null)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [openDetailModal, setOpenDetailModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [openMoveModal, setOpenMoveModal] = useState(false)
  const [selectedPallet, setSelectedPallet] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [locationIdForCreate, setLocationIdForCreate] = useState(null)
  const [draggingCoordinates, setDraggingCoordinates] = useState(null)
  const [scale, setScale] = useState(1)
  const [pagination, setPagination] = useState(defaultFilterData)
  const [filterData, setFilterData] = useState(null)
  const [openHover, setOpenHover] = useState(false);
  const [hoverSlotData, setHoverSlotData] = useState({id: null});
  const [scrollObj, setScrollObj] = useState({ top: 0, left: 0});

  const isActivatedSearch = useMemo(() => {
    return !checkIfPaginationIsEmpty(pagination)
  }, [pagination])

  const statuses = useSelector(state => state.inventoryReducer.statuses)
  const roles = useSelector((state) => state.rolesReducer.roles)

  const dispatch = useDispatch()

  const containerRef = useRef(null)

  const hasPermToEdit = useMemo(() => {
    return findPermInArray(roles, permEditInventory)
  }, [roles])
  const handleOpenCreateModal = useCallback((locationId) => {
    setLocationIdForCreate(locationId)
    setOpenModalCreate(true)
  }, [])

  const updatePaginationData = useCallback((data) => {
    setFilterData({
      brand: data.brand.map(item => ({
        value: item,
        label: item,
      })),
      cg1: data.cg1.map(item => ({
        value: item,
        label: item,
      })),
      cg2: data.cg2.map(item => ({
        value: item,
        label: item,
      })),
      cg3: data.cg3.map(item => ({
        value: item,
        label: item,
      })),
      productName: data.productName.map(item => ({
        value: item,
        label: item,
      })),
      quality: data.quality.map(item => ({
        value: item,
        label: item,
      })),
      season: data.season.map(item => ({
        value: item,
        label: item,
      })),
      palletStatus: data.palletStatus.map(item => ({
        value: item,
        label: item,
      })),
    })
    let pagin = {
      term: pagination.term,
      termCondition: pagination.termCondition,
    }

    const statusIds = data.palletStatus.map(item => item.id)

    pagin.brand = pagination.brand.filter(item => data.brand.includes(item))
    pagin.cg1 = pagination.cg1.filter(item => data.cg1.includes(item))
    pagin.cg2 = pagination.cg2.filter(item => data.cg2.includes(item))
    pagin.cg3 = pagination.cg3.filter(item => data.cg3.includes(item))
    pagin.season = pagination.season.filter(item => data.season.includes(item))
    pagin.quality = pagination.quality.filter(item => data.quality.includes(item))
    pagin.productName = pagination.productName.filter(item => data.productName.includes(item))
    pagin.palletStatus = pagination.palletStatus.filter(item => data.palletStatus.includes(item))

    setPagination(pagin)
  }, [pagination])

  const createPalletCallback = useCallback((data) => {
    LocationAPI.getLocationById(data.location.id)
      .then(res => {
        if(res.data.data){
          setLocation(prevState => prevState.map(item => item.id === res.data.data.id
            ? ({...item, ...res.data.data}) : item))
          setLocationIdForCreate(null)
          setOpenModalCreate(false)
          notify('success', 'Pallet successfully created')
          PlanAPI.getPlanById(plan.id)
            .then(planRes => {
              updatePaginationData(planRes.data.filters)
            })
        }
      })
      .catch(err => {
        defaultServerError()
      })
  }, [plan, updatePaginationData])

  const handleOpenDetailModal = useCallback((pallet, boxId, callback) => {
    setLoading(true)
    getPalletByIdService(pallet.id)
      .then(palletRes => {
        setSelectedPallet({
          ...pallet,
          ...palletRes.data.data,
        })

        callback && callback()
        setOpenEditModal(false)
        if (boxId) {
          changeRoutePush(`/atw/inventory/map/${pallet.id}`, history, {
            scannedBox: boxId
          })
        } else {
          changeRoute(`/atw/inventory/map/${pallet.id}`, history)
        }
        setOpenDetailModal(true)
      })
      .catch(err => {
        defaultServerError()
        console.log(err)
      })
      .finally(() => setLoading(false))
  }, [plan])

  const handleCloseDetailModal = useCallback(() => {
    setOpenDetailModal(false)
    setOpenEditModal(false)
    setSelectedPallet(null)
    changeRoute('/atw/inventory/map', history)
  },[])

  const handleEditPallet = useCallback((values, callback) => {
    setLoading(true)
    editPallet(selectedPallet.id, values)
      .then(res => {
        const editedPallet = {...selectedPallet}
        if(selectedPallet.palletStatus.id !== values.statusId){
          editedPallet.palletStatus = statuses.find(item => item.id === values.statusId)
        }
        if(selectedPallet.location.id !== values.locationId){
          setLocation(prevState => prevState.map(item => {
            if(item.id === selectedPallet.location.id){
              return ({
                ...item,
                pallet: null,
              })
            }
            if(item.id === values.locationId){
              return ({
                ...item,
                pallet: {
                  ...editedPallet,
                  location: {
                    id: item.id,
                    coordinateX: item.coordinateX,
                    coordinateY: item.coordinateY,
                  }
                }
              })
            }
            return item
          }))
        }else if(selectedPallet.palletStatus.id !== values.statusId){
          setLocation(prevState => prevState.map(item => {
            if(item.id === values.locationId){
              return ({
                ...item,
                pallet: {
                  ...editedPallet,
                }
              })
            }
            return item
          }))
        }
        handleOpenDetailModal(selectedPallet)
        callback && callback()
        PlanAPI.getPlanById(plan.id)
          .then(planRes => {
            updatePaginationData(planRes.data.filters)
          })
          .catch(defaultServerError)
      })
      .catch(err => {
        console.log(err)
        defaultServerError(err)
        setLoading(false)
      })
  }, [statuses, plan, handleOpenDetailModal, selectedPallet, updatePaginationData])

  const handleOpenHoverModal = useCallback((open, id, slot, coord) => {
    setOpenHover(open);
    if (hoverSlotData.id !== id) {
      setHoverSlotData({id: id, data:{...slot}, coord: {...coord}});
    }
  });

  const handleOpenMoveModal = useCallback((x, y) => {

    const findedLocation = location.find(item => item.coordinateX === x - 1 && item.coordinateY === y)

    if(!findedLocation || findedLocation.id === selectedPallet.location.id){
      setSelectedPallet(null)
    }else{
      setSelectedLocation(findedLocation)
      setOpenMoveModal(true)
    }
  }, [location, selectedPallet])

  const handleCloseMoveModal = useCallback(() => {
    setOpenMoveModal(false)
    setSelectedLocation(null)
    setSelectedPallet(null)
  },[])

  const handleChangePalletPosition = useCallback((pallet, newLocation) => {
    setLoading(true)
    changePalletLocation(pallet.id, newLocation.id)
      .then(res => {

        setLocation(prevState => {
          const currentItemSearched = prevState.find(item => item.id === pallet.location.id).searched

           return  prevState.map(item => {
            if(item.id === pallet.location.id){
              return ({
                ...item,
                pallet: null,
                searched: false,
              })
            }
            if(item.id === newLocation.id){
             
              return ({
                ...item,
                pallet: {
                  ...pallet,
                  location: {
                    id: item.id,
                    coordinateX: item.coordinateX,
                    coordinateY: item.coordinateY,
                  },
                },
                searched: currentItemSearched
              })
            }
            return item
          })
        })
        handleCloseMoveModal()
        notify('success', `Pallet successfully moved to 
        ${formatCoordinatesToView(`${newLocation.coordinateX}-${newLocation.coordinateY}`, plan.maxX, plan.maxY)[0]}`)
      })
      .catch(err => {
        notify('error', 'Failed to move pallet')
      })
      .finally(() => setLoading(false))
  }, [plan, handleCloseMoveModal])

  const handleSearch = useCallback((criterias, search) => {
    if(criterias && criterias.length == 5){
      criterias[5] = "bid"
    } else if(criterias && criterias.length == 6){
      criterias[6] = "bid"
    }
    setPagination(prevState => ({
      ...prevState,
      term: search,
      termCondition: criterias,
    }))
  }, [])

  const handleRemovePallet = useCallback((pallet) => {
    setLoading(true)
    deletePallet(pallet.id)
      .then(res => {
        setOpenRemoveModal(false)
        handleCloseDetailModal()
        notify('success','Pallet successfully deleted')
        setLocation(prevState => prevState
            .map(location => location.id === pallet.location.id ? ({...location, pallet: null}) : location)
        )
      })
      .catch(defaultServerError)
      .finally(() => setLoading(false))
  }, [handleCloseDetailModal])

  //update on pagination change
  useEffect(() => {
    if(plan && plan.id){
      if(!checkIfPaginationIsEmpty(pagination)){
        setLoading(true)
        const paginationData = {}
        if(pagination.brand.length > 0){
          paginationData.brand = pagination.brand.join(',')
        }
        if(pagination.cg1.length > 0){
          paginationData.cg1 = pagination.cg1.join(',')
        }
        if(pagination.cg2.length > 0){
          paginationData.cg2 = pagination.cg2.join(',')
        }
        if(pagination.cg3.length > 0){
          paginationData.cg3 = pagination.cg3.join(',')
        }
        if(pagination.productName.length > 0){
          paginationData.productName = pagination.productName.join(',')
        }
        if(pagination.quality.length > 0){
          paginationData.quality = pagination.quality.join(',')
        }
        if(pagination.season.length > 0){
          paginationData.season = pagination.season.join(',')
        }
        if(pagination.palletStatus.length > 0){
          paginationData.palletStatus = pagination.palletStatus.join(',')
        }
        if(pagination.term){
          paginationData.term = pagination.term
          paginationData.termCondition = pagination.termCondition
        }

        LocationAPI.getLocationByPlan(plan.id, paginationData)
          .then(res => {
            let arr = res.data.data.map(item => item.id)
            setLocation(prevState => prevState.map(item => ({...item, searched: arr.includes(item.id)})))
          })
          .catch(defaultServerError)
          .finally(() => setLoading(false))
      }else{
        setLocation(prevState => prevState.map(item => ({...item, searched: false})))
      }
    }
  }, [pagination, plan])

  //set header
  useEffect(() => {
    dispatch(setData({
      LeftPannel: InventoryTabs,
      leftPannelData: {
        alias: INVENTORY_TABS.MAP
      },
      textAddButton: 'Add',
      handleAdd: null,
      handleSearch: handleSearch,
      handleChangeSearchCriterias: handleSearch,
    }))

    return () => {
      dispatch(setData({
        LeftPannel: null,
        leftPannelData: null,
        textAddButton: null,
        handleAdd: null,
        handleSearch: null,
        handleChangeSearchCriterias: null,
      }))
    }
  }, [dispatch, handleSearch])

  //get statuses and pack types
  useEffect(() => {
    axios.all([adminsInstance.get(`${url}/pallet/status`), adminsInstance.get(`${url}/pallet/pack-type`)])
      .then(axios.spread((statusRes, packTypeRes) => {

        if(statusRes.data){
          dispatch(setStatusesListAction(statusRes.data))
        }

        if(packTypeRes){
          dispatch(setPackTypesListAction(packTypeRes.data))
        }
      }))
  }, [dispatch])

  //perm update
  useEffect(() => {
    if(!findPermInArray(roles, permEditInventory)){
      setOpenModalCreate(false)
    }
  }, [roles])

  //init data
  useEffect(() => {

    setLoading(true)
    PlanAPI.getPlans({
      page: 0,
      limit: 20,
    })
      .then(res => {
        if(res.data.data){
          if(res.data.data.content.length > 0){
            setPlan(res.data.data.content[res.data.data.content.length - 1])
            dispatch(setPlanAction(res.data.data.content[res.data.data.content.length - 1]))
          axios.all([PlanAPI.getPlanById(res.data.data.content[res.data.data.content.length - 1].id),
            LocationAPI.getLocationByPlan(res.data.data.content[res.data.data.content.length - 1].id)
          ])
            .then(axios.spread((planResult, locationResult) => {
              if(planResult.data.data){
                setFilterData({
                  brand: planResult.data.filters.brand.map(item => ({
                    value: item,
                    label: item,
                  })),
                  cg1: planResult.data.filters.cg1.map(item => ({
                    value: item,
                    label: item,
                  })),
                  cg2: planResult.data.filters.cg2.map(item => ({
                    value: item,
                    label: item,
                  })),
                  cg3: planResult.data.filters.cg3.map(item => ({
                    value: item,
                    label: item,
                  })),
                  productName: planResult.data.filters.productName.map(item => ({
                    value: item,
                    label: item,
                  })),
                  quality: planResult.data.filters.quality.map(item => ({
                    value: item,
                    label: item,
                  })),
                  season: planResult.data.filters.season.map(item => ({
                    value: item,
                    label: item,
                  })),
                  palletStatus: planResult.data.filters.palletStatus.map(item => ({
                    value: item,
                    label: item,
                  })),
                })
                setPlan(planResult.data.plan)
                setWidth(containerRef.current.offsetWidth)
                setHeight(containerRef.current.offsetHeight - 50)
                setCoordinatesArray(generateCoordinates(planResult.data.plan.maxX, planResult.data.plan.maxY))
              }
              if(locationResult.data.data){
                setLocation(locationResult.data.data)
                // const items = locationResult.data.data.filter(item => item.coordinateX === 30 && item.coordinateY === 6)
                // console.log(items)
                // const item = locationResult.data.data.find(item => item.id === 625)
                // console.log(item)

              }
            }))
              .catch(err => console.log(err))
            .finally(() => {
              setLoading(false)
            })
          }
        }else{
          defaultServerError()
          setLoading(false)
        }
      })
      .catch(err => {
        defaultServerError()
        setLoading(false)

      })

  }, [])

  useEffect(() => {
    if(!hasPermToEdit){
      setOpenEditModal(false);
      setOpenMoveModal(false);
      setOpenRemoveModal(false);
      setOpenModalCreate(false);
    }
  }, [hasPermToEdit])

  useEffect(() => {
    const scanningListener = listenScanning('map', () => loading, setLoading, (palletId, boxId) => {
      handleOpenDetailModal({
        id: palletId
      }, boxId);
    });

    return function cleanup() {
      scanningListener();
    }
  }, [loading, handleOpenDetailModal])

  const inventoryMap = useMemo(() =>
      <InventoryMapMap
          width={width}
          height={height}
          maxX={plan ? plan.maxX : null}
          maxY={plan ? plan.maxY : null}
          map={location}
          coordinatesArray={coordinatesArray}
          handleOpenCreateModal={handleOpenCreateModal}
          handleOpenDetailModal={handleOpenDetailModal}
          handleOpenMoveModal={handleOpenMoveModal}
          handleOpenHoverModal={handleOpenHoverModal}
          handleScrollObj={setScrollObj}
          setSelectedPallet={setSelectedPallet}
          draggingCoordinates={draggingCoordinates}
          setDraggingCoordinates={setDraggingCoordinates}
          setLocation={setLocation}
          selectedPallet={selectedPallet}
          scale={scale}
          setScale={setScale}
          isActivatedSearch={isActivatedSearch}
      />, [width, height, plan, location, coordinatesArray, draggingCoordinates, selectedPallet, scale, isActivatedSearch]);

  return (
    <>
      {loading && <PreloaderWithText text="Loading"/>}
      <InventoryMapToolbar
        handleOpenCreateModal={findPermInArray(roles, permEditInventory)
          ? () => setOpenModalCreate(true) : null}
        pagination={pagination}
        setPagination={setPagination}
        filterData={filterData}
      />
      <div ref={containerRef} style={{flex: 1}}>
        {inventoryMap}
      </div>
      {findPermInArray(roles, permEditInventory) && <Modal
        open={openModalCreate}
        isFooter={false}
        // handleClose={this.handleHideModalCreate}
        options={{
          centered: true,
          enforceFocus: false,
          autoFocus: false
        }}
      >
        <CreatePallet
          locationId={locationIdForCreate ? locationIdForCreate : null}
          successCallback={createPalletCallback}
          handleCancel={() => setOpenModalCreate(false)}
        />
      </Modal>
      }
      <Modal
        open={openDetailModal}
        isFooter={false}
        handleClose={handleCloseDetailModal}
        classNameModal={classNames({"modal-70": !openEditModal})}
        options={{
          centered: true,
          enforceFocus: false,
          autoFocus: false
        }}
      >
        {openEditModal ? <EditPallet
          handleClose={handleCloseDetailModal}
          handleEditPallet={handleEditPallet}
          pallet={selectedPallet}
        /> : <PalletDetailModal
          createPalletCallback={createPalletCallback}
          pallet={selectedPallet}
          setPallet={setSelectedPallet}
          setLoading={setLoading}
          handleClose={handleCloseDetailModal}
          handleOpenDetailModal={handleOpenDetailModal}
          handleOpenEditModal={() => setOpenEditModal(true)}
          handleOpenRemoveModal={() => setOpenRemoveModal(true)}
        />}
      </Modal>
      <Modal
        open={openMoveModal}
        isFooter={true}
        handleClose={handleCloseMoveModal}
        handleCancel={handleCloseMoveModal}
        handleConfirm={() => handleChangePalletPosition(selectedPallet, selectedLocation)}
        options={{
          centered: true,
          enforceFocus: false,
          autoFocus: false
        }}
        >
        <ConfirmModalWithText text={`move Pallet #${selectedPallet ? selectedPallet.id : ''} to 
        ${selectedLocation ? formatCoordinatesToView(`${selectedLocation.coordinateX}-${selectedLocation.coordinateY}`, plan.maxX, plan.maxY)[0] : ''}`}/>
      </Modal>
      <Modal
        open={openRemoveModal}
        handleClose={() => setOpenRemoveModal(false)}
        handleCancel={() => setOpenRemoveModal(false)}
        handleConfirm={() => handleRemovePallet(selectedPallet)}
        options={{
          centered: true,
          enforceFocus: false,
          autoFocus: false
        }}
        >
        <ConfirmModalWithText text={`delete pallet #${selectedPallet ? selectedPallet.id : ''}`} />
      </Modal>
      {openHover && hoverSlotData.id &&
        <div className="hovered-tooltip"
                         style={{
                           left: (hoverSlotData.coord.left* scale - scrollObj.left) ,
                           top: hoverSlotData.coord.top ? hoverSlotData.coord.top + 150 - scrollObj.top : null
                         }}>
        <div className="tooltip-row">
          <div>#{hoverSlotData.data.pallet.id}</div>
        </div>
        {hoverSlotData.data.pallet.palletStatus && <div className="tooltip-row">
          <div className="status-block">
            <div className="status__wrapper" dangerouslySetInnerHTML={{__html: hoverSlotData.data.pallet.palletStatus.icon}}></div>
            {hoverSlotData.data.pallet.palletStatus.name}
          </div>
        </div>}
        {hoverSlotData.data.pallet.orders && hoverSlotData.data.pallet.orders.length > 0 && <div className="tooltip-row po-row">
          <div className="detail-label">PO#: </div>
          <div className="detail-val">
            {hoverSlotData.data.pallet.orders
            && hoverSlotData.data.pallet.orders.map(order => <div className="link-po" key={order.id}>
              <Link
                  onClick={e => e.stopPropagation()}
                  target="_blank"
                  to={`/atw/purchase-orders/${order.id}`}
              >{order.orderNumber}</Link>
            </div>)}
          </div>
        </div>}
      </div>}
    </>
  )
}

export { InventoryMap }
