import {SIZES_DICTIONARY} from '../constants'

const initState = {
    isLoading: false,
    isLoadingSimpleParams: false,
    isLoadingSizeTypes: false,
    sizeSets: [],
    sizeTypes: [],
    commodities: [],
    genders: [],
    activeSet: null
}

export const sizeDictionaryReducer = (state = initState, action) => {
    const {type, payload} = action
    switch (type) {
        case SIZES_DICTIONARY.REMOVE_SIZE_ROW: {
            return {
                ...state,
                sizeSets: state.sizeSets.map(set => {
                    set.sizeRow = set.sizeRow.filter(sizeRow => sizeRow.id !== payload)
                    return {
                        ...set
                    }
                })
            }
        }
        case SIZES_DICTIONARY.EDIT_VALUE_OF_ROW_SIZE_SET: {
            return {
                ...state,
                sizeSets: state.sizeSets.map(set => {
                    set.sizeRow = set.sizeRow.map(sizeRow => {
                        sizeRow.sizeValues = sizeRow.sizeValues.map(value => {
                            if(value.id === payload.valueId)
                                return {
                                    ...payload.valueObj
                                }
                            return {
                                ...value
                            }
                        })
                        return {
                            ...sizeRow
                        }
                    })
                    return {
                        ...set
                    }
                })
            }
        }
        case SIZES_DICTIONARY.ADD_NEW_VALUE_TO_ROW_SIZE_SET: {
            return {
                ...state,
                sizeSets: state.sizeSets.map(set => {
                    set.sizeRow = set.sizeRow.map(sizeRow => {
                        if(sizeRow.id === payload.rowId){
                            sizeRow.sizeValues = sizeRow.sizeValues.concat(payload.valueObj)
                        }
                        return sizeRow
                    })
                    return {
                        ...set
                    }
                })
            }
        }
        case SIZES_DICTIONARY.ADD_ROW_TO_SIZE_SET:{
            return {
                ...state,
                sizeSets: state.sizeSets.map(set => {
                    if(set.id === payload.setId){
                        return {
                            ...set,
                            sizeRow: [{
                                ...payload.sizeRow,
                                sizeValues: []
                            },...set.sizeRow]
                        }
                    }
                    else{
                        return {
                            ...set
                        }
                    }
                })
            }
        }
        case SIZES_DICTIONARY.REMOVE_SIZE_TYPE: {
            return {
                ...state,
                sizeTypes: state.sizeTypes.filter(type => type.id !== payload)
            }
        }
        case SIZES_DICTIONARY.EDIT_SIZE_TYPE: {
            return {
                ...state,
                sizeTypes: state.sizeTypes.map(type => {
                    if(type.id === payload.id){
                        return {
                            ...payload.data
                        }
                    }
                    return type
                })
            }
        }
        case SIZES_DICTIONARY.ADD_NEW_SIZE_TYPE: {
            return {
                ...state,
                sizeTypes: state.sizeTypes.concat(payload)
            }
        }
        case SIZES_DICTIONARY.SET_SIZE_TYPES:{
            return {
                ...state,
                sizeTypes: [...payload]
            }
        }
        case SIZES_DICTIONARY.IS_LOADING_SIZE_TYPES:{
            return {
                ...state,
                isLoadingSizeTypes: payload
            }
        }
        case SIZES_DICTIONARY.SET_ACTIVE_SIZE_SET: {
            return {
                ...state,
                activeSet: payload
            }
        }
        case SIZES_DICTIONARY.EDIT_SIZE_SET: {
            return {
                ...state,
                sizeSets: state.sizeSets.map(set => {
                    if (set.id === payload.id)
                        return {
                            ...set,
                            ...payload.values
                        }
                    else
                        return {
                            ...set
                        }
                })
            }
        }
        case SIZES_DICTIONARY.REMOVE_SIZE_SET: {
            return {
                ...state,
                sizeSets: state.sizeSets.filter(set => set.id !== payload)
            }
        }
        case SIZES_DICTIONARY.ADD_SIZE_SET: {
            return {
                ...state,
                sizeSets: [payload,...state.sizeSets]
            }
        }
        case SIZES_DICTIONARY.SET_SIMPLE_PARAMS_FOR_SIZES: {
            return {
                ...state,
                commodities: payload.commodities,
                genders: payload.genders
            }
        }
        case SIZES_DICTIONARY.SET_SIZES_SETS: {
            return {
                ...state,
                sizeSets: payload
            }
        }
        case SIZES_DICTIONARY.IS_LOADING_SIZES: {
            return {
                ...state,
                isLoading: payload
            }
        }
        default : {
            return state
        }
    }
}