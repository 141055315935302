import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Table from '../../reuseComponent/Table/MaterialTable'
import Pagination from '../../reuseComponent/Table/Pagination'
import ContainerTable from '../../reuseComponent/Table/container'
import Header from '../../reuseComponent/Table/table-head'
import adminInstanse from '../../instances/admininstance'
import {url} from '../../../constants'
import {generateQueryStringWithFilters} from '../../../reuseFunctions/queries'
import {notify} from '../../reuseComponent/toast'
import CheckBox from '../../reuseComponent/checkbox'
import ProductTableCell from './product-table-cell'
import {setFilterConditions, setProductsErrors, setShowHideUploadSchema} from '../../../actions/analysisTool'
import {setData} from '../../../actions/admin-header'
import ToolTip from '../../reuseComponent/Tooltips'
import Toolbar from './Toolbar'
import moment from 'moment'
import {ConfirmModalContent} from '../../reuseComponent/confirmModalComponent';
import CustomModalWithChildren from "../../reuseComponent/modal/modalWithChildren";

class TableProducts extends PureComponent {

    state = {
        products: [],
        isAllChecked: false,
        modalBulkEdit: false,
        modalSmartFilter: false,
        isBulkEdit: false,
        confirmExportXlsModal: false,
        isLoadingProcess: false,
        textLoading: 'Loading',
        cg1: [],
        cg2: [],
        cg3: [],
        cg4: [],
        sizeType: [],
        count: 0
    }

    changeActionsView = (condition) => {
        const {setHeaderData, tableRef} = this.props;

        setHeaderData && setHeaderData({
            handleSearch: (value) => {
                tableRef.current && tableRef.current.onSearchChange(value)
            },
            tableRef: tableRef,
            Actions: () => {
                return <>
                    {condition && <>
                        <ToolTip
                            text="Export to xls"
                            placement="bottom"
                        >
                            <button onClick={() => {
                                if(!this.state.isLoadingProcess)
                                    this.setState({
                                        confirmExportXlsModal: true
                                    })
                            }} className="menu-button">
                                <svg className="action-icon header-icon" viewBox="0 0 24 24">
                                    <path fill="#000000" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"/>
                                </svg>
                            </button>
                        </ToolTip>
                    </>
                    }
                </>
            }
        });
    }

    handleClickEdit = () => {
        this.setState({
            modalBulkEdit: true
        })
    }

    activeDisableBulkMode = () => {
        this.setState(state => ({
            isBulkEdit: !state.isBulkEdit,
            products: [],
            isAllChecked: false
        }))
    }

    handleRevalidate = () => {
        const {tableRef, orderId} = this.props
        adminInstanse.post(`${url}/purchase_order/${orderId}/revalidate`)
            .then(res => {
                tableRef.current && tableRef.current.onQueryChange()
            })
    }

    handleExportToXls = () => {
        const search = generateQueryStringWithFilters({
            search: this.props.tableRef && this.props.tableRef.current
                ? this.props.tableRef.current.state.query.search
                : '',
            conditions: this.props.conditions
        })
        this.setState({
            confirmExportXlsModal: false,
            isLoadingProcess: true,
            textLoading: 'Downloading'
        }, () => {
            adminInstanse({
                url: `${url}/analysis_tool/export`,
                method: 'GET',
                responseType: 'blob',
                params: {
                    search: search
                }
            })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const currDate = moment().format('YYYY-MM-DDTHH:mm')
                    link.setAttribute('download', `Analysis_Tool_${currDate}.xls`);
                    document.body.appendChild(link);
                    link.click();
                    this.setState({
                        isLoadingProcess: false,
                        textLoading: 'Loading'
                    })
                })
        })


    }

    handleChange = (id, isChecked) => {
        const {products} = this.state
        if (isChecked)
            this.setState({
                products: products.filter(prId => prId !== id),
                isAllChecked: false
            })
        else
            this.setState({
                products: products.concat(id),
                isAllChecked: false
            })
    }


    componentDidMount() {
        this.changeActionsView(true)
    }

    componentWillUnmount() {
        const {setHeaderData, setFilter} = this.props;

        setHeaderData && setHeaderData({
            handleSearch: null,
            Actions: null,
        });

        setFilter && setFilter([])
    }


    renderColumns = () => {
        const {tableRef} = this.props
        const {isAllChecked, products, isBulkEdit} = this.state
        let selectedAllRows = false
        if (tableRef.current)
            selectedAllRows = products.length === tableRef.current.state.query.totalCount && tableRef.current.state.query.totalCount !== 0
        return [
            {
                hidden: !isBulkEdit,
                title: (() => <CheckBox
                    options={{
                        className: 'checkbox-material'
                    }}
                    id="select-all-checkbox"
                    checked={isAllChecked || selectedAllRows}
                    indeterminate={!isAllChecked && !selectedAllRows && products.length > 0}
                    handleChange={e => {
                        const checked = e.target.checked
                        this.setState(state => ({
                            isAllChecked: checked,
                            products: !checked ? [] : tableRef.current.state.data.map(item => item.id)
                        }))
                    }}
                />)(),
                field: 'bulk-edit-field',
                sorting: false,
                render: rowData => {
                    const isChecked = products.includes(rowData.id)
                    return <CheckBox
                        options={{
                            className: 'checkbox-material'
                        }}
                        id={`bulk-checkbox-${rowData.id}`}
                        checked={isChecked || isAllChecked}
                        handleChange={() => this.handleChange(rowData.id, isChecked)}
                    />
                }
            },
            {
                title: 'EAN',
                field: 'ean',
                sorting: false,
            },
            {
                title: 'SKU',
                field: 'styleNumber',
                sorting: false,
                headerStyle: {
                    textAlign: 'right'
                },
                cellStyle: {
                    textAlign: 'right'
                }
            },
            {
                title: 'CG1',
                field: 'cg1',
                sorting: false
            },
            {
                title: 'CG2',
                field: 'cg2',
                sorting: false
            },
            {
                title: 'CG3',
                field: 'cg3',
                sorting: false
            },
            {
                title: 'CG4',
                field: 'cg4',
                sorting: false
            },
            {
                title: 'Size',
                field: 'size',
                sorting: false
            },
            {
                title: 'Size Type',
                field: 'sizeType',
                sorting: false
            },
            {
                title: 'Season',
                field: 'season',
                sorting: false
            },
            {
                title: 'Brand',
                field: 'brand',
                sorting: false
            },
            {
                title: 'Channel',
                field: 'channel',
                sorting: false
            },
            {
                title: 'Quality',
                field: 'quality',
                sorting: false
            },
            {
                title: 'RRP',
                field: 'rrp',
                sorting: false,
                headerStyle: {
                    textAlign: 'right'
                },
                cellStyle: {
                    textAlign: 'right'
                }
            },

            {
                title: 'Items',
                field: 'incomeStock',
                sorting: false,
                headerStyle: {
                    textAlign: 'right'
                },
                cellStyle: {
                    textAlign: 'right'
                }
            },
        ]
    }

    getProducts = query => {
        const {setProductsErrors, conditions, orderId, handleBlockTabs} = this.props
        return new Promise((resolve, reject) => {
            adminInstanse.get(`${url}/product/list/${orderId}`, {
                params: {
                    page: query.page ? query.page : 0,
                    limit: query.pageSize,
                    search: generateQueryStringWithFilters({
                        search: query.search,
                        conditions: conditions
                    })
                }
            })
                .then(res => {
                    this.setState({
                        cg1: res.data.cg1 ? res.data.cg1 : [],
                        cg2: res.data.cg2 ? res.data.cg2 : [],
                        cg3: res.data.cg3 ? res.data.cg3 : [],
                        cg4: res.data.cg4 ? res.data.cg4 : [],
                        quality: res.data.quality ? res.data.quality : [],
                        sizeType: res.data.sizeType ? res.data.sizeType : [],
                        count: res.data.count ? res.data.count : 0
                    },() => {
                        this.changeActionsView(res.data.products.length > 0)
                    })
                    handleBlockTabs(res.data.count)
                    setProductsErrors(res.data.errorsCount)
                    resolve({
                        data: this.generateRows(res.data.products),
                        page: query.page,
                        totalCount: res.data.count
                    })
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })
    }

    generateRows = data => {
        return data.map(product => ({
            id: product.id,
            ean: product.ean,
            styleNumber: product.styleNumber,
            cg1: product.cg1,
            cg2: product.cg2,
            cg3: product.cg3,
            cg4: product.cg4,
            season: product.season,
            brand: product.brand,
            channel: product.channel,
            size: product.size,
            rrp: product.rrp,
            buingPrice: product.buingPrice,
            incomeStock: product.incomeStock,
            actualCount: product.actualCount,
            sizeType: product.sizeType,
            errors: JSON.parse(product.errors),
            quality: product.quality
        }))
    }

    showSmartFilterModal = () => {
        this.setState({
            modalSmartFilter: true
        })
    }

    hideSmartFilterModal = () => {
        this.setState({
            modalSmartFilter: false
        })
    }

    handleConfirmSmartFilter = (data) => {
        const {setFilter} = this.props
        this.setState({
            modalSmartFilter: false
        }, () => {
            setFilter(data)
                .then(() => {
                    if (this.props.tableRef.current)
                        this.props.tableRef.current.onQueryChange()
                })

        })
    }

    hideBulkEdit = () => this.setState({
        modalBulkEdit: false
    })

    callbackFormBulk = (values) => {
        this.setState({
            isBulkEdit: false,
            products: [],
            isAllChecked: false,
            modalBulkEdit: false,
            isLoadingProcess: false
        }, () => {
            if (this.props.tableRef.current) {
                const tableRef = this.props.tableRef.current
                tableRef.onQueryChange()
            }
        })
    }

    callbackSetFilter = () => {
        this.setState({
            isLoadingProcess: false
        },() => {
            if (this.props.tableRef.current)
                this.props.tableRef.current.onQueryChange()
        })

    }

    render() {
        const {conditions} = this.props
        const {confirmExportXlsModal,isLoadingProcess,textLoading} = this.state
        return (
            <>
                <div className="wrapper-table products-table table-analysis">
                    <Table
                        components={{
                            Cell: ProductTableCell,
                            Container: ContainerTable,
                            Pagination: Pagination,
                            Toolbar: props => <Toolbar
                                {...props}
                                {...this.state}
                                {...this.props}
                                startRemoving={() => this.setState({
                                    isLoadingProcess: true
                                })}
                                handleChangeConvertMode={this.props.handleChangeConvertMode}
                                tableRef={this.props.tableRef}
                                handleClickEdit={this.handleClickEdit}
                                activeDisableBulkMode={this.activeDisableBulkMode}
                                showSmartFilterModal={this.showSmartFilterModal}
                                handleRevalidate={this.handleRevalidate}
                                hideSmartFilterModal={this.hideSmartFilterModal}
                                handleConfirmSmartFilter={this.handleConfirmSmartFilter}
                                hideBulkEdit={this.hideBulkEdit}
                                callbackFormBulk={this.callbackFormBulk}
                                search={generateQueryStringWithFilters({
                                    search: this.props.tableRef && this.props.tableRef.current
                                        ? this.props.tableRef.current.state.query.search
                                        : '',
                                    conditions: conditions
                                })}
                                callbackSetFilter={this.callbackSetFilter}
                            />,
                            Header: props => <Header {...props}/>
                        }}
                        textLoading={textLoading}
                        isLoading={isLoadingProcess}
                        paginationPosition="toolbar"
                        tableRef={this.props.tableRef}
                        columns={this.renderColumns()}
                        data={this.getProducts}
                        title=""
                        options={{
                            maxBodyHeight: 'calc(90vh - 80px)',
                            debounceInterval: 500,
                            search: true,
                            emptyRowsWhenPaging: false,
                            pageSize: 100,
                            pageSizeOptions: [10, 25, 50, 100, 200, 500],
                        }}
                    />
                </div>
                <CustomModalWithChildren
                    open={confirmExportXlsModal}
                    Content={ConfirmModalContent}
                    handleConfirm={this.handleExportToXls}
                    handleCancel={() => {
                        this.setState({
                            confirmExportXlsModal: false
                        })
                    }}
                    handleClose={() => {
                        this.setState({
                            confirmExportXlsModal: false
                        })
                    }}
                    options={{
                        centered: true,
                        // animation: false
                    }}
                >
                    <ConfirmModalContent
                        text="export Analysis tool data"
                    />
                </CustomModalWithChildren>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        productsErrors: state.analysisToolReducer.productsErrors,
        conditions: state.analysisToolReducer.conditionsFilter,
        orderId: state.analysisToolReducer.orderId,
        roles: state.rolesReducer.roles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setProductsErrors: count => dispatch(setProductsErrors(count)),
        setFilter: conditions => dispatch(setFilterConditions(conditions)),
        setHeaderData: data => dispatch(setData(data)),
        setShowHideUploadSchema: isVisible => dispatch(setShowHideUploadSchema(isVisible)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableProducts)