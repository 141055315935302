import React from 'react'
import {connect} from 'react-redux'
import {Form, Button} from 'react-bootstrap'
import { notify } from '../../../reuseComponent/toast'

import {setShowHideUploadSchema} from "../../../../actions/analysisTool";

const _validFileExtensions = ['.csv', '.xls', '.xlsx']

const validateTypeFile = (file) => {
    const fileName = file.name
    let isValidType = false
    _validFileExtensions.forEach(type => {
        if (fileName.indexOf(type) >= 0) {
            isValidType = true
        }
    })
    return isValidType
}

const handleChangeFile = (e, setFieldValue, setFieldError, setFieldTouched) => {
    if (e.target.files && e.target.files[0]) {
        if (validateTypeFile(e.target.files[0]))
            setFieldValue('file', e.target.files[0])
        else {
            // setFieldValue('file','')
            setFieldError('file', 'Invalid file type')
            setFieldTouched('file', true)
            notify('error','Invalid file format')
        }

    }

}

const BodyForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        showHideUploadingSchema,
        setFieldValue,
        setFieldError,
        setFieldTouched
    } = props
    return (
        <Form noValidate onSubmit={handleSubmit}>
            <div className="title-schema">
                <svg viewBox="0 0 24 24">
                    <path fill="#000000" d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z" />
                </svg>
                <h6 className="center-h"> Please ensure uploading <strong>xls</strong> file contains spreadsheet <strong>“MASTERLIST”</strong><br/>
                    and data ordered according to following structure:</h6>
            </div>

            <div className="row-columns">
                <Form.Group>
                    <Form.Label>EAN</Form.Label>
                    <Form.Control
                        name="eanColumn"
                        value={values.eanColumn}
                        onChange={handleChange}
                        isInvalid={errors.eanColumn && touched.eanColumn}
                    />
                    <Form.Control.Feedback type="invalid">{errors.eanColumn}</Form.Control.Feedback>
                </Form.Group>


                <Form.Group>
                    <Form.Label>SKU</Form.Label>
                    <Form.Control
                        name="skuColumn"
                        value={values.skuColumn}
                        onChange={handleChange}
                        isInvalid={errors.skuColumn && touched.skuColumn}
                    />
                    <Form.Control.Feedback type="invalid">{errors.skuColumn}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Size</Form.Label>
                    <Form.Control
                        name="sizeColumn"
                        value={values.sizeColumn}
                        onChange={handleChange}
                        isInvalid={errors.sizeColumn && touched.sizeColumn}
                    />
                    <Form.Control.Feedback type="invalid">{errors.sizeColumn}</Form.Control.Feedback>
                </Form.Group>
            </div>

            <div className="row-columns">
                <Form.Group>
                    <Form.Label>CG1</Form.Label>
                    <Form.Control
                        name="cg1Column"
                        value={values.cg1Column}
                        onChange={handleChange}
                        isInvalid={errors.cg1Column && touched.cg1Column}
                    />
                    <Form.Control.Feedback type="invalid">{errors.cg1Column}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>CG2</Form.Label>
                    <Form.Control
                        name="cg2Column"
                        value={values.cg2Column}
                        onChange={handleChange}
                        isInvalid={errors.cg2Column && touched.cg2Column}
                    />
                    <Form.Control.Feedback type="invalid">{errors.cg2Column}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>CG3</Form.Label>
                    <Form.Control
                        name="cg3Column"
                        value={values.cg3Column}
                        onChange={handleChange}
                        isInvalid={errors.cg3Column && touched.cg3Column}
                    />
                    <Form.Control.Feedback type="invalid">{errors.cg3Column}</Form.Control.Feedback>
                </Form.Group>
            </div>
            <div className="row-columns">
                <Form.Group>
                    <Form.Label>CG4</Form.Label>
                    <Form.Control
                        name="cg4Column"
                        value={values.cg4Column}
                        onChange={handleChange}
                        isInvalid={errors.cg4Column && touched.cg4Column}
                    />
                    <Form.Control.Feedback type="invalid">{errors.cg4Column}</Form.Control.Feedback>
                </Form.Group>


                <Form.Group>
                    <Form.Label>Season</Form.Label>
                    <Form.Control
                        name="seasonColumn"
                        value={values.seasonColumn}
                        onChange={handleChange}
                        isInvalid={errors.seasonColumn && touched.seasonColumn}
                    />
                    <Form.Control.Feedback type="invalid">{errors.seasonColumn}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Brand</Form.Label>
                    <Form.Control
                        name="brandColumn"
                        value={values.brandColumn}
                        onChange={handleChange}
                        isInvalid={errors.brandColumn && touched.brandColumn}
                    />
                    <Form.Control.Feedback type="invalid">{errors.brandColumn}</Form.Control.Feedback>
                </Form.Group>
            </div>

            <div className="row-columns">
                <Form.Group>
                    <Form.Label>RRP</Form.Label>
                    <Form.Control
                        name="rrpColumn"
                        value={values.rrpColumn}
                        onChange={handleChange}
                        isInvalid={errors.rrpColumn && touched.rrpColumn}
                    />
                    <Form.Control.Feedback type="invalid">{errors.rrpColumn}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Total Items</Form.Label>
                    <Form.Control
                        name="incomeColumn"
                        value={values.incomeColumn}
                        onChange={handleChange}
                        isInvalid={errors.incomeColumn && touched.incomeColumn}
                    />
                    <Form.Control.Feedback type="invalid">{errors.incomeColumn}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Size Type</Form.Label>
                    <Form.Control
                        name="sizeType"
                        value={values.sizeType}
                        onChange={handleChange}
                        isInvalid={errors.sizeType && touched.sizeType}
                    />
                    <Form.Control.Feedback type="invalid">{errors.sizeType}</Form.Control.Feedback>
                </Form.Group>
            </div>

            <div className="row-columns">
                <Form.Group>
                    <Form.Label>Channel</Form.Label>
                    <Form.Control
                        name="channelColumn"
                        value={values.channelColumn}
                        onChange={handleChange}
                        isInvalid={errors.channelColumn && touched.channelColumn}
                    />
                    <Form.Control.Feedback type="invalid">{errors.channelColumn}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Quality</Form.Label>
                    <Form.Control
                        name="qualityColumn"
                        value={values.qualityColumn}
                        onChange={handleChange}
                        isInvalid={errors.qualityColumn && touched.qualityColumn}
                    />
                    <Form.Control.Feedback type="invalid">{errors.qualityColumn}</Form.Control.Feedback>
                </Form.Group>
            </div>

            <Form.Group>
                <Form.Label>File</Form.Label>
                <div className="input-group">
                    <div className="custom-file">
                        <label className="custom-file-label" htmlFor="inputGroupFile01">
                            {values.file ? values.file.name : 'Select xls file'}
                        </label>
                    </div>
                    <Form.Control
                        id="inputGroupFile01"
                        style={{display: 'none'}}
                        type="file"
                        name="file"
                        onChange={e => handleChangeFile(e, setFieldValue, setFieldError, setFieldTouched)}
                        isInvalid={errors.file}
                    />
                    <Form.Control.Feedback type="invalid">{errors.file}</Form.Control.Feedback>
                </div>
            </Form.Group>

            <Form.Group className="footer-form footer-form-custom">
                <Button type="button" onClick={() => showHideUploadingSchema(false)}>Cancel</Button>
                <Button type="submit">Import</Button>
            </Form.Group>
        </Form>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        showHideUploadingSchema: isShow => dispatch(setShowHideUploadSchema(isShow))
    }
}

export default connect(null, mapDispatchToProps)(BodyForm)