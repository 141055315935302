import * as yup from "yup";
import {checkExistingEmail} from "../../../../services/customers";

export const schema = yup.object().shape({
  companyName: yup.string().required("Required field"),
  taxId: yup.string().required("Required field"),
  phoneNumber: yup
    .number()
    .required("Required field")
    .typeError("Must be a number"),
  email: yup
    .string()
    .required("Required field")
    .email("Invalid email")
      .test('unique email', 'Customer with current email already exists', async function(val){
          const {isCreate} = this.parent;
          let isValid = false

          // let user = JSON.parse(window.localStorage.getItem('userWCS'))
          // const isAdmin =  user.role === 'admin';

          // if(isCreate || isAdmin) {
          if(isCreate ) {
              await checkExistingEmail(val)
                  .then(res => {
                      isValid = true
                  })
                  .catch(err => {
                      isValid = false
                  })
          }else{
              isValid = true;
          }
          return isValid;
      }),
  address: yup.string().required("Required field"),
  busModel: yup.string().nullable().required("Required field"),
    demandCategory: yup.string().nullable().required("Required field"),
  qualityIds: yup.string().nullable().required("Required field"),
  spokenLanguage: yup.string().typeError("Must be a string").required('Required field'),
  country: yup.string().typeError("Must be a string").required('Required field'),
    city: yup.string().typeError("Must be a string"),
    zip: yup
        .number()
        .nullable()
        .typeError("Must be a number"),
    // monthlyQuantity: yup
    // .number()
    // .integer("Must be an integer")
    // .required("Required field")
    // .typeError("Must be an integer"),
    isCreate: yup.bool(),
    countriesOperate: yup.string().typeError("Must be a string"),
    contacts: yup.array().of(
        yup.object().shape({
            name: yup.string().required("Required field"),
            secondName: yup.string().required("Required field"),
            phone: yup
                .number()
                .required("Required field")
                .typeError("Must be a number"),
            email: yup
                .string()
                .required("Required field")
                .email("Invalid email"),
            position: yup
                .mixed()
                // .required("Required field")
                // .typeError("Required field")
        })
    )
        .required('Required field')
});

