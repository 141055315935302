import { SMART_FILTER } from "../constants";
import {
  getFiltersList
} from "../services/smart-filter";
import { notify } from "../components/reuseComponent/toast";

const setFiltersAction = filters => ({
  type: SMART_FILTER.SET_FILTERS,
  payload: filters
});

const setActiveFilter = activeFilter => ({
  type: SMART_FILTER.SET_ACTIVE_FILTER,
  payload: activeFilter
});

export const getFilters = alias => dispatch => {
  getFiltersList(alias)
    .then(res => {
      dispatch(setFiltersAction(res.data.userFilters));
      if (alias === "offers")
        dispatch(setActiveFilter(res.data.activeOfferFilter));
      else if (alias === "order")
        dispatch(setActiveFilter(res.data.activeFilter));
      else if (alias === "supplier")
        dispatch(setActiveFilter(res.data.activeSupplierFilter));
      else if (alias === "tasks")
        dispatch(setActiveFilter(res.data.activeTasksFilter));
      else if (alias === "sdRequest") {
        dispatch(setActiveFilter(res.data.activeSDRequestFilter))
      } else if (alias === "customers")
        dispatch(setActiveFilter(res.data.activeCustomerFilter));
    })
    .catch(err => {
      notify("error", "Oops something went wrong");
    });
};
