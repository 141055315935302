import React, { PureComponent } from 'react'
import {Pie} from 'react-chartjs-2'
import { connect } from 'react-redux'
import adminInstanse from '../../../../../instances/admininstance'
import {url} from '../../../../../../constants'
import {notify} from '../../../../../reuseComponent/toast'
import Preloader from '../../../../../reuseComponent/preloader/preloader'
import { colors } from '../colors'
import Select from '../../../../../reuseComponent/Select/select-form'


const options = [{
    value: 5,
    label: 5
},{
    value: 10,
    label: 10
},{
    value: 15,
    label: 15
}]

class PieChart extends PureComponent{

    state = {
        data: {},
        isLoading: this.props.snapshot ? false : true,
        num: 5
    }

    handleGetData = () => {
        const { details , field,snapshot } = this.props
        const { num } = this.state
        if(snapshot){
            const array = JSON.parse(snapshot.charts)[`${field}Top${num}`]
            this.setState({
                isLoading: false,
                data: [{
                    label: array.map(item => item.label),
                    datasets: [{
                        data: array.map(item => item.value),
                        backgroundColor: colors,
                        hoverBackgroundColor: colors
                    }]
                }]
            })
        }
        else{
            adminInstanse.get(`${url}/analytics/top/${details.id}/${field}`,{
                params: {
                    field: field,
                    n: num
                }
            })
                .then(res => {
                    this.setState({
                        isLoading: false,
                        data: {
                            labels: res.data.map(item => item.label),
                            datasets: [{
                                data: res.data.map(item => item.value),
                                backgroundColor: colors,
                                hoverBackgroundColor: colors
                            }],

                        }
                    })
                })
                .catch(err => {
                    console.log(err)
                    notify('error','Oops something went wrong')
                })
        }

    }

    componentDidMount() {
        this.handleGetData()
    }

    handleChangeSelect = (obj) => {
        this.setState({
            num: obj.value,
            // isLoading: true
        },this.handleGetData)
    }

    render(){
        const { data,isLoading,num } = this.state
        const { field } = this.props
        return (
            isLoading ? <Preloader /> : <div className="chart-wrapper">
                <h5>Top <Select
                    value={{
                        value: num,
                        label: num
                    }}
                    options={options}
                    onChange={this.handleChangeSelect}
                /> of {field}</h5>
                <Pie
                    data={data}
                />
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase,
        // snapshot: state.purchaseOrdersReducer.snapshot
    }
}

export default connect(mapStateToProps,null)(PieChart)