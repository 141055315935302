import { INVENTORY } from '../constants'

export const setStatusesListAction = list => ({
  type: INVENTORY.SET_INVENTORY_STATUS_LIST,
  payload: list
})

export const setPackTypesListAction = list => ({
  type: INVENTORY.SET_INVENTORY_PACK_TYPE_LIST,
  payload: list
})

export const setPlanAction = plan => ({
  type: INVENTORY.SET_PLAN,
  payload: plan,
})