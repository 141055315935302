import React, {PureComponent} from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {findPermInArray} from '../../../reuseFunctions/checkPermission'


class ProtectedRoute extends PureComponent {

    checkMultiplPerm = () => {
        const {permission: perm, roles} = this.props;

        if(Array.isArray(perm)){
            for(let i=0; i<perm.length; i++){
                if(findPermInArray(roles, perm[i])){
                    return true;
                }
            }
            return false;
        }else{
            return findPermInArray(roles, perm)
        }
    }

    render() {
        const {component: Component, permission: perm,roles, ...rest} = this.props

        return (
            this.checkMultiplPerm() ?
                <Route {...rest} component={Component}/>
                :  <Redirect to={{pathname: '/atw/access_denied', state: {protected: 'error'}}}/>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

export default connect(mapStateToProps, null)(ProtectedRoute)