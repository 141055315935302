import {ROLES} from '../constants'
import adminInstance from '../components/instances/admininstance'
import {url} from '../constants'

export const getRolesAct = (dispatch) => {
    adminInstance.get(`${url}/user/privileges`)
        .then(res => {
            try{
                let user = JSON.parse(window.localStorage.getItem('userWCS'))
                let newUser = {
                    ...user,
                    role: res.data.role.alias
                }
                window.localStorage.removeItem('userWCS')
                window.localStorage.setItem('userWCS', JSON.stringify(newUser))
            }
            catch(err){
                console.log(err)
            }
            dispatch(setRoles(res.data.privileges.concat(res.data.parts),res.data.role.alias))
        })
        .catch(err => {
            console.log(err.response,'error')
        })
}

export const setRoles = (roles, userRole) => ({
    type: ROLES.SET_ROLES,
    roles: roles,
    userRole: userRole,
    isLoaded: true
})