import React from 'react'
import { connect } from 'react-redux'
import BidsHeader from './header-bids-info/index'
import BidsTable from './bids-table/index'

const BIDsComponent = props => {
    return (
        <div className="bids-info-wrapper">
            <BidsHeader handleChangePurchase={props.handleChangePurchase}/>
            <BidsTable refetchData={props.refetchData} handleChangePurchase={props.handleChangePurchase}/>
        </div>
    )
}

export default connect(null,null)(BIDsComponent)