import React from "react";
import { connect } from "react-redux";
import DropDownMenu from "../../../../reuseComponent/DropDown";

const ActionsMenuComponent = ({
  handleOpenConfirm,
  handleOpenPutModal,
  details,
  productsErrors,
  status,
  errorsObj
}) => {
  return (
    productsErrors > 0 &&
    !status &&
    !details.completeInbound && errorsObj && errorsObj.field && errorsObj.field != ('size') && (
      <DropDownMenu
        ContentMenu={() => {
          return (
            <ul className="list-unstyled no-margin menu-table-actions">
              <li>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    handleOpenConfirm(errorsObj.field);
                  }}
                >
                  Add to dictionary values
                </a>
              </li>
              <li>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    handleOpenPutModal();
                  }}
                >
                  Add to dictionary inputs
                </a>
              </li>
            </ul>
          );
        }}
      ></DropDownMenu>
    )
  );
};

const mapStateToProps = state => ({
  details: state.purchaseOrdersReducer.detailsPurchase,
  productsErrors: state.purchaseOrdersReducer.productsErrors,
  status: state.dividingReducer.dividingStatus
});

export default connect(mapStateToProps, null)(ActionsMenuComponent);
