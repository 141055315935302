import React from 'react'
import Select from 'react-select'
import './style/style.scss'

const CustomSelect = props => {
    const {
        options = [], //options of select, see react-select on npm
        name = '', // name field
        isInvalid = false, // flag to show error
        onChange = () => {}, // handler to change event
        className = '', // custom classes for select
        errorLabel = '' // text of error
    } = props
    return(
        <div className='custom-select-wrapper'>
            <Select
                {...props}
                options={options}
                name={name}
                onChange={onChange}
                className={`${className} bootstrap-class ${isInvalid ? 'invalid-select' : ''} ${props.isDisabled ? 'disabled' : ''}`}
            />
            <span className="error-select-bootstrap-style">{errorLabel}</span>
        </div>
    )
}

export default CustomSelect