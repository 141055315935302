import { INVENTORY } from '../constants'

const initialState = {
  statuses: [],
  packTypes: [],
  plan: null,
}

export const inventoryReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch(type){
    case INVENTORY.SET_INVENTORY_PACK_TYPE_LIST: {
      return {
        ...state,
        packTypes: payload
      }
    }
    case INVENTORY.SET_INVENTORY_STATUS_LIST: {
      return {
        ...state,
        statuses: payload
      }
    }
    case INVENTORY.SET_PLAN: {
      return {
        ...state,
        plan: payload
      }
    }
    default:
      return state;
  }
}