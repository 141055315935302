import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import Form from '../form'
import { editCg2ItemRequest } from '../../../../../actions/commodity-groups'


class CreateForm extends PureComponent{

    handleSubmit = values => {
        const { handleCancel,editCg2,id } = this.props
        // handleCancel && handleCancel ()
        id && editCg2 && editCg2(id,values,handleCancel)

    }

    render(){
        const { handleCancel,value,inputs } = this.props
        return (
            <Form
                initValues={{
                    value: value,
                    inputs: inputs
                }}
                handleSubmit={this.handleSubmit}
                handleCancel={handleCancel}
            />
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editCg2: (id,values,handleCancel) => dispatch(editCg2ItemRequest(id,values,handleCancel))
    }
}

export default connect(null,mapDispatchToProps)(CreateForm)