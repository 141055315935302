import adminInstance from '../components/instances/admininstance'
import {url} from '../constants'
import {DIVIDING} from '../constants'
import {notify} from '../components/reuseComponent/toast'
import {convertToFormData, convertToFormDataWithSingleArray} from '../reuseFunctions/objectToFormData'
import React from 'react'
import {setDetailsPurchaseAction} from './purchase-orders'
import { isChangeDividingStatus,setActivePackage,setBoxesAction } from './stowing'

const isLoadingDividing = isLoading => ({
    type: DIVIDING.IS_LOADING_DIVIDING_RESULTS,
    payload: isLoading
})

export const setConditionSetsAndFieldsAction = conditionSets => ({
    type: DIVIDING.SET_CONDITIONS_SETS,
    payload: conditionSets
})

export const getDividing = idPo => {
    return dispatch => {
        dispatch(isLoadingDividing(true))
        adminInstance.get(`${url}/dividing`, {
            params: {
                poId: idPo
            }
        })
            .then(res => {
                // console.log(typeof res.data)
                // console.log(JSON.parse(res.data))
                adminInstance.get(`${url}/purchase_order/${idPo}`)
                    .then(res => {
                        dispatch(setDetailsPurchaseAction(res.data))
                    })
                // console.log(res.data)
                dispatch(setConditionSetsAndFieldsAction(res.data))
                dispatch(isLoadingDividing(false))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
                dispatch(isLoadingDividing(false))
            })
    }
}

const addConditionSet = condition => ({
    type: DIVIDING.ADD_CONDITION_SET,
    payload: condition
})

export const createConditionsSet = idPo => {
    return dispatch => {
        adminInstance.post(`${url}/dividing/set`, convertToFormData({
            poId: idPo,
            ipp: 150
        }))
            .then(res => {
                dispatch(addConditionSet(res.data))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }
}

const setFieldsForCondition = fields => ({
    type: DIVIDING.SET_FIELDS_FOR_CONDITION,
    payload: fields
})

export const getFieldsForCondition = () => {
    return dispatch => {
        adminInstance.get(`${url}/dividing/condition/fields`)
            .then(res => {
                dispatch(setFieldsForCondition(res.data))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }
}

export const getValuesForField = (field, id,setId) => {
    return adminInstance.get(`${url}/dividing/condition/values`, {
        params: {
            fieldAlias: field,
            poId: id,
            dSetId: setId ? setId : ''
        }
    })
}

export const changeConditionSetAction = (id, data) => ({
    type: DIVIDING.CHANGE_CONDITION_SET_DATA,
    payload: data,
    id: id
})

export const changePackageNameAction = (setId, packId, name) => ({
    type: DIVIDING.CHANGE_PACKAGE_NAME,
    payload: name,
    setId,
    packId
})

export const editSet = ({setId, ipp}) => {
    return (dispatch, getState) => {
        dispatch(isLoadingDividing(true))
        const state = getState()
        adminInstance.put(`${url}/dividing/set/${setId}`, convertToFormData({
            ipp
        }))
            .then(res => {
                dispatch(getDividing(state.purchaseOrdersReducer.detailsPurchase.id))
                notify('success', 'Set successfully edited')
            })
            .catch(err => {
                console.log(err.response)
                if(err.response && typeof err.response.data === 'object')
                    notifyPackagesUsedByOffers(err.response.data)
                else
                    notify('error', 'Oops something went wrong')
                dispatch(isLoadingDividing(false))
            })
    }
}

export const addConditionToSet = ({setId, idCondition}) => ({
    type: DIVIDING.ADD_CONDITION_TO_SET,
    payload: idCondition,
    setId: setId
})

export const editConditionInSet = ({setId, data}) => ({
    type: DIVIDING.EDIT_CONDITION_IN_SET,
    setId: setId,
    payload: data
})

export const saveCondition = ({setId, values, fieldName, fieldAlias}) => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(isLoadingDividing(true))
        adminInstance.post(`${url}/dividing/set/${setId}/condition`, convertToFormDataWithSingleArray({
            fieldAlias: fieldAlias,
            values: values,
            fieldName: fieldName
        }))
            .then(res => {
                dispatch(getDividing(state.purchaseOrdersReducer.detailsPurchase.id))
                notify('success', 'Condition successfully added')
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
                dispatch(isLoadingDividing(false))
            })
    }
}

export const changeActiveSet = (setId) => dispatch => {
    // dispatch(isChangingSetLoading(true))
    dispatch(changeActiveSetAction(setId))
}

const changeActiveSetAction = (setId) => ({
    type: DIVIDING.CHANGE_ACTIVE_SET,
    payload: setId
})

export const isChangingSetLoading = (loading) => ({
    type: DIVIDING.IS_CHANGING_SET_LOADING,
    payload: loading
})

export const removeSet = (setId) => {
    return (dispatch, getState) => {
        dispatch(isLoadingDividing(true))
        const state = getState()
        adminInstance.delete(`${url}/dividing/set/${setId}`)
            .then(res => {
                dispatch(getDividing(state.purchaseOrdersReducer.detailsPurchase.id))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
                dispatch(isLoadingDividing(false))
            })
    }
}

export const removeConditionAction = (setId, conditionId) => ({
    type: DIVIDING.REMOVE_CONDITION,
    payload: conditionId,
    setId: setId
})

export const removeCondition = (setId, conditionId) => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(isLoadingDividing(true))
        adminInstance.delete(`${url}/dividing/set/${setId}/condition/${conditionId}`)
            .then(res => {
                dispatch(getDividing(state.purchaseOrdersReducer.detailsPurchase.id))
                notify('success', 'Condition successfully removed')
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
                dispatch(isLoadingDividing(false))
            })
    }
}

export const editCondition = ({conditionId, setId, fieldAlias, fieldName, values}) => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(isLoadingDividing(true))
        adminInstance.put(`${url}/dividing/set/${setId}/condition/${conditionId}`, convertToFormDataWithSingleArray({
            fieldAlias: fieldAlias,
            values: values,
            fieldName: fieldName
        }))
            .then(res => {
                dispatch(getDividing(state.purchaseOrdersReducer.detailsPurchase.id))
                notify('success', 'Condition successfully added')
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
                dispatch(isLoadingDividing(false))
            })
    }
}

const isLoadingProducts = isLoading => ({
    type: DIVIDING.IS_LOADING_PRODUCTS,
    payload: isLoading
})

const setProductsExistStatusAction = isExist => ({
    type: DIVIDING.IS_PRODUCTS_EXIST,
    payload: isExist
})

export const getProducts = () => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(isLoadingProducts(true))
        adminInstance.get(`${url}/product/list/${state.purchaseOrdersReducer.detailsPurchase.id}`, {
            params: {
                page: 0,
                limit: 10,
                search: 's:'
            }
        })
            .then(res => {
                if (res.data.products.length > 0)
                    dispatch(setProductsExistStatusAction(true))
                else {
                    dispatch(setProductsExistStatusAction(false))
                }
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
            .finally(() => {
                dispatch(isLoadingProducts(false))
            })
    }
}


const isLoadingStatusAction = isLoading => ({
    type: DIVIDING.IS_LOADING_STATUS,
    payload: isLoading
})

const setStatusAction = status => ({
    type: DIVIDING.SET_DIVIDING_STATUS,
    payload: status
})

export const getDividingStatus = (id) => {
    return (dispatch, getState) => {
        const idPo = id ? id : getState().purchaseOrdersReducer.detailsPurchase.id
        dispatch(isLoadingStatusAction(true))
        adminInstance.get(`${url}/dividing/status`, {
            params: {
                poId: idPo
            }
        })
            .then(res => {
                dispatch(setStatusAction(res.data))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong, can not get status')
            })
            .finally(() => {
                dispatch(isLoadingStatusAction(false))
            })
    }
}

export const changeDividingStatus = (getAtEnd) => {
    return (dispatch, getState) => {
        const idPo = getState().purchaseOrdersReducer.detailsPurchase.id
        const status = getState().dividingReducer.dividingStatus
        dispatch(isLoadingStatusAction(true))
        const formData = new FormData()
        formData.append('urlToPO',window.location.origin + '/atw/purchase-orders')
        adminInstance.put(`${url}/dividing/status`, formData, {
            params: {
                poId: idPo,
            }
        })
            .then(res => {
                dispatch(setBoxesAction([]))
                dispatch(setActivePackage(null))
                dispatch(isChangeDividingStatus(true))
                dispatch(setStatusAction(!status))
                if(getAtEnd){
                    dispatch(getDividing(getAtEnd))
                }
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
            .finally(() => {
                dispatch(isLoadingStatusAction(false))
            })
    }
}

export const resetDividingStateAction = state => ({
    type: DIVIDING.RESET_DIVIDING_STATE,
    payload: state
})

export const combinePackage = (packageIdFrom,packageIdTo) => {
    return (dispatch,getState) => {
        dispatch(isLoadingDividing(true))
        const state = getState()
        adminInstance.put(`${url}/package/combine_package`,convertToFormData({
            packageIdFrom : packageIdFrom,
            packageIdTo: packageIdTo
        }))
            .then(res => {
                notify('success','Packages successfully combined')
                dispatch(getDividing(state.purchaseOrdersReducer.detailsPurchase.id))
            })
            .catch(err => {
                notify('error','Oops something went wrong')
            })
    }

}

const notifyPackagesUsedByOffers = (offers) => {
    return notify('error',<div className="packages-used">
        <p>Packages from this set already used in some offers:</p>
        {offers.map(offer => <p key={offer}>{offer}</p>)}
    </div>)
}

