import React from 'react'
import {Form} from "react-bootstrap";

const ContactRow = props => {
    const {
        index,
        values
    } = props
    return (
        <div className="contact-customer-block">
            {(values['contacts'][index].name || values['contacts'][index].secondName) && <Form.Group className="horizontal customer-element-form-wrapper">
                <Form.Label>Name</Form.Label>
                <div className="input-item">
                    {values['contacts'][index].name} {values['contacts'][index].secondName}
                </div>
            </Form.Group>
            }
            {values['contacts'][index].phone && <Form.Group className="horizontal customer-element-form-wrapper">
                <Form.Label>Phone number</Form.Label>
                <div className="input-item">
                    {values['contacts'][index].phone}
                </div>
            </Form.Group>
            }
            {values['contacts'][index].email && <Form.Group className="horizontal customer-element-form-wrapper">
                <Form.Label>Email</Form.Label>
                <div className="input-item">
                    {values['contacts'][index].email}
                </div>
            </Form.Group>
            }
            {values.contacts[index].position && <Form.Group className="horizontal customer-element-form-wrapper">
                <Form.Label>Position</Form.Label>
                <div className="input-item">
                    {values.contacts[index].position}
                </div>
            </Form.Group>
            }
        </div>
    )
}

export default ContactRow