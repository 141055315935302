import React, { useCallback } from 'react'
import { Formik } from 'formik'

import { CreateInstrumentFormBody } from './CreateInstrumentFormBody'
import { mapDictionaryInitialValues } from '../constants'
import { mapDictionarySchema } from './schema'
import { LocationTypeAPI } from '../../../../../services/location-type'
import { defaultServerError } from '../../../../../reuseFunctions/toasts'
import { notify } from '../../../../reuseComponent/toast'

const CreateInstrumentForm = ({
                                handleCancel,
                                setLoading,
                                successCallback,
                                plan,
                              }) => {

  const handleSubmit = useCallback((values) => {
    setLoading(true)
    LocationTypeAPI.createLocationType(plan.id, values)
      .then(res => {
        if(res.data.data){
          successCallback(res.data.data)
          handleCancel()
          notify('success', 'Space successfully created')
        }else{
          defaultServerError()
        }
      })
      .catch(defaultServerError)
      .finally(() => setLoading(false))
  }, [plan, successCallback, handleCancel, setLoading])

  return (
    <Formik
      initialValues={mapDictionaryInitialValues}
      onSubmit={handleSubmit}
      validationSchema={mapDictionarySchema}
    >
      {props => <CreateInstrumentFormBody {...props} handleCancel={handleCancel}/>}
    </Formik>
  )
}

export { CreateInstrumentForm }