import * as yup from 'yup'

export const schema = yup.object().shape({
    conditions: yup.array()
        // .min(1,'There must be at least one condition')
        .of(
            yup.object({
                id: yup.string(),
                field: yup.string(),
                separator: yup.mixed(),
                value: yup.string(),
            }).shape({
                id: yup.string(),
                separator: yup.mixed()
                    .test('isObject','required field',value => typeof value === 'object'),
                field: yup.string().test('isExist','required field', value => {
                    return value ? true : false
                }),
                value: yup.string().when(['field','separator'], {
                    is: (field,separator) => field && typeof separator === 'object' && separator.withValue,
                    then: yup.string().required('required field'),
                    otherwise: yup.string(),
                })
            })
        )
})