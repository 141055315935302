import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import { Button } from 'react-bootstrap'
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute'
import { history } from '../../../../history'
import { OfferApi } from '../../../../services/offers'
import Preloader from '../../../reuseComponent/preloader/preloader'
import {defaultServerError} from '../../../../reuseFunctions/toasts'
import { ReactComponent as EditIcon } from '../../../../assets/images/edit.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg'
import { ReactComponent as PdfIcon } from '../../../../assets/images/pdf.svg'
import { ReactComponent as ClearIcon } from '../../../../assets/images/clear.svg'
import { ReactComponent as ChronoIcon } from '../../../../assets/images/chronology.svg'
import { ReactComponent as InfoIcon } from '../../../../assets/images/info.svg'
import OfferInfo  from './infoOffer'
import PalletCollapse from '../create/pallet-collapse'
import EditOfferForm from '../edit';
import Modal from '../../../reuseComponent/modal/modalWithChildren';
import {setReservationData} from "../../../../actions/offersActions";
import moment from "moment";
import SingleDatePicker from "../../../reuseComponent/DatePicker/datepicker-with-children";
import {getHistory} from '../../../../services/offers';
import HistoryList from '../../../reuseComponent/history/list';
import {offersChronologyFiledsConverting} from "../../../../constants";

class TaskDetails extends PureComponent {

    state = {
        isLoading: true,
        offer: null,
        modalEditOffer: false,
        showInfo:true
    }

    componentDidMount(){
        this.handleGetOfferInfo()
    }

    componentDidUpdate(prevProps){
        if(this.props.match.params.id !== prevProps.match.params.id)
            this.handleGetOfferInfo()
    }

    handleShowEditOfferModal = () =>
        this.setState({modalEditOffer: true});

    handleHideEditOfferModal = () =>
        this.setState({modalEditOffer: false});
    
    handleShowInfo = () => {
        this.setState({ showInfo: !this.state.showInfo });
    }

    handleGetOfferInfo = () => {
        const { id } = this.props.match.params
        const {setReservationData} = this.props;
        this.setState({
            isLoading: true
        },() => {
            OfferApi.getOffer(id)
                .then(res => {
                    setReservationData({
                        reservationEndDate: res.data.reservationEndDate,
                        reservationStartDate: res.data.reservationStartDate,
                        usersReservation: res.data.usersReservation,
                        previousUserReservation: res.data.previousUserReservation,
                    });
                    this.setState({
                        isLoading: false,
                        offer: res.data
                    })
                })
                .catch(err => defaultServerError())
        })
    }

    render() {

        const {
            updateListOffers,
            handleExportToPdf,
            handleShowRemoveOfferModal,
            handleShowCancelReservation,
            reservationData,
            handleShowConfirmReservation
        } = this.props
        const { isLoading,offer,modalEditOffer,showInfo } = this.state
        const { id } = this.props.match.params;
        // console.log(offer)
        const isBookedUser = reservationData &&
            reservationData.previousUserReservation &&
            reservationData.previousUserReservation.id === this.props.userInfo.info.id;

        return (
            <div className="details offers-details">
                {/*<Tooltip*/}
                {/*    text="Close"*/}
                {/*>*/}
                {/*    <div className="close-details-block" onClick={() => {*/}
                {/*        changeRoutePush(`/atw/offers`, history)*/}
                {/*    }}>*/}
                {/*        <CloseIcon className="standart-svg"/>*/}
                {/*    </div>*/}
                {/*</Tooltip>*/}
                <div className="content-details">
                    {isLoading ? <Preloader/> : <>
                            <div className="offer-details-title">
                                <div>
                                    <Button onClick={() => changeRoutePush(`/atw/offers`, history)}>
                                    <svg className="standart-svg" viewBox="0 0 24 24">
                                        <path
                                            fill="currentColor"
                                            d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                                        />
                                    </svg>
                                    Back
                                    </Button>
                                </div>
                                <div>
                                <Button onClick={() => this.handleShowInfo()}>
                                        { showInfo ? <ChronoIcon className="standart-svg"/> : <InfoIcon className="standart-svg"/> }
                                        { showInfo ? 'View сhronology' : 'View info' }
                                </Button>
                                    {(!reservationData || !reservationData.usersReservation) && !isBookedUser && <Button>
                                        <SingleDatePicker
                                            startDate={moment()}
                                            minDate={moment()}
                                            maxDate={moment().add(13, 'day')}
                                            onApply={(event, picker) => {
                                                handleShowConfirmReservation(
                                                    parseInt(id),
                                                    moment(picker.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
                                                );
                                            }}
                                        >
                                            {/*<PdfIcon className="standart-svg"/>*/}
                                            <svg className="standart-svg" viewBox="0 0 24 24">
                                                <path
                                                    fill="#000000"
                                                    d="M19,4H18V2H16V4H8V2H6V4H5C3.89,4 3,4.9 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z"
                                                />
                                            </svg>
                                            Reserve offer
                                        </SingleDatePicker>
                                    </Button>}
                                    {reservationData && reservationData.usersReservation && <Button
                                        onClick={() => handleShowCancelReservation(parseInt(id))}>
                                        <ClearIcon className="standart-svg"/>
                                        Cancel reservation
                                    </Button>}
                                    <Button onClick={() => handleExportToPdf(id)}>
                                        <PdfIcon className="standart-svg"/> Export to PDF
                                    </Button>
                                    <Button onClick={this.handleShowEditOfferModal}>
                                        <EditIcon className="standart-svg"/> Edit
                                    </Button>
                                    <Button onClick={() => handleShowRemoveOfferModal(id)}>
                                        <DeleteIcon className="standart-svg"/> Delete
                                    </Button>
                                </div>
                            </div>
                            {
                                showInfo ? 
                                <div className="offer-data-wrapper">
                                    <OfferInfo offer={offer}    />
                                    <div className="pallets-wrapper">
                                        {offer.pallets && offer.pallets.length > 0 ? offer.pallets.map(pallet => {
                                            return <PalletCollapse
                                                key={pallet.id}
                                                pallet={pallet}
                                                isView={true}
                                            />
                                        }) : <h6 className="no-data-to-display"> No pallets to display</h6>}
                                    </div>
                                </div> 
                                : <HistoryList 
                                    id={id} 
                                    getHistory={getHistory} 
                                    chronologyFiledsConverting={offersChronologyFiledsConverting} />
                            }
                        </>}
                </div>
                <Modal
                    classNameModal="offer-create-modal"
                    open={modalEditOffer}
                    isFooter={false}
                    options={{
                        centered: true,
                        animation: false
                    }}
                >
                    <EditOfferForm
                        idOffer={id}
                        updateListOffers={updateListOffers}
                        updateOffer={this.handleGetOfferInfo}
                        handleCancel={this.handleHideEditOfferModal}
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles,
        reservationData: state.offersReducer.reservationData,
        userInfo: state.usersReducer.currUser
    }
}

const mapDispatchToProps = dispatch => ({
    setReservationData: data => dispatch(setReservationData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails)