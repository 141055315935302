import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import Preloader from '../../../reuseComponent/preloader/preloader'
import { schema } from './schema'
import BodyForm from './bodyForm'
import { getDepartmentById, createDepartment,editDepartment } from '../../../../actions/departments'
import { notify } from '../../../reuseComponent/toast'

class FormDepartment extends PureComponent{

    componentDidMount(){
        const { idDepartment,getDepartment } = this.props
        idDepartment && getDepartment && getDepartment(idDepartment)
    }

    handleSubmit = values => {
        const { idDepartment,createDepartment,editDepartment,handleClose,refetchTable } = this.props
        if(idDepartment)
            editDepartment({
                idDepartment,
                values,
                callback: (res) => {
                    notify('success','Department successfully edited')
                    handleClose()
                    refetchTable()
                }
            })
        else
            createDepartment({
                values,
                callback: res => {
                    notify('success','Department successfully created')
                    handleClose()
                    refetchTable()
                }
            })
    }

    render(){
        const { isLoading,handleClose,department } = this.props
        return (
            isLoading ? <Preloader /> : <Formik
                initialValues={department}
                // enableReinitialize={true}
                validationSchema={schema}
                onSubmit={this.handleSubmit}
                render={props => <BodyForm {...props} handleClose={handleClose} />}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.departmentsReducer.isLoadingDepartment,
        department: state.departmentsReducer.department
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDepartment: values => dispatch(getDepartmentById(values)),
        createDepartment: obj => dispatch(createDepartment(obj)),
        editDepartment: obj => dispatch(editDepartment(obj))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(FormDepartment)