import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {setData} from '../../../../actions/admin-header'
import {
    getSizesSetsRequest,
    getAllSimpleParams,
    addSizeSetAction,
    setActiveSetAction,
    removeSetRequest,
    setSizesSetsAction
} from '../../../../actions/size-dictionary'
import Preloader from '../../../reuseComponent/preloader/preloader'
import {Button} from 'react-bootstrap'
import * as uniqId from 'uniqid'
import SetRow from './set-row'
import SizeTypesComponent from './types-component'
import CustomModalWithChildren from "../../../reuseComponent/modal/modalWithChildren";
import {findPermInArray} from "../../../../reuseFunctions/checkPermission";
import {permEditSizesDictionary} from "../../../../permissions/dictionaries";
import StandartLeftPanner from "../../../reuseComponent/Header/standartLeftPannel";
import {ConfirmModalContent} from '../../../reuseComponent/confirmModalComponent';
import withTracker from "../../../reuseComponent/GoogleAnalytics";

class SizesDictionary extends PureComponent {

    state = {
        modalRemoveSet: false,
        currSet: null
    }

    wrapSetsRef = React.createRef()

    handleDeleteSet = () => {
        const { currSet } = this.state
        const { removeSetRequest } = this.props
        this.setState({
            modalRemoveSet: false,
            currSet: null
        },() => removeSetRequest(currSet))

    }

    componentDidMount() {
        const {setHeaderData, getSizes, getGendersCommodities} = this.props
        setHeaderData && setHeaderData({
            leftPannelData: {
                title: 'Sizes',
                logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                    <path fill="#795548"
                          d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z"/>
                </svg>)),
            },
            textAddButton: 'Add',
            LeftPannel: StandartLeftPanner,
            handleAdd: null,
            handleSearch: null
        })
        getSizes && getSizes()
        getGendersCommodities && getGendersCommodities()
    }

    componentDidUpdate(prevProps){
        const { sizeSets,roles,setSizesSets } = this.props
        const prevRoles = prevProps.roles
        if(JSON.stringify(roles) !== JSON.stringify(prevRoles)){
            if(!findPermInArray(roles,permEditSizesDictionary)){
                setSizesSets(sizeSets.filter(sizeSet => typeof sizeSet.id !== 'string' && sizeSet.id.toString().indexOf('set-id-') < 0))
            }
        }
    }

    render() {
        const {isLoading, addSizeSet, sizeSets, setActiveSizeSet, roles} = this.props
        const { modalRemoveSet } = this.state
        return (
            <div className="sizes-wrapper">
                {/*<div className="header-sizes-wrapper">*/}
                    {/*<div className="sets-wrapper" ref={this.wrapSetsRef}>*/}
                        {/**/}
                    {/*</div>*/}
                    {/*<div className="types-wrapper">*/}
                        {/*<div className="title-types-wrapper">*/}
                            {/*<h6>Types</h6>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
                <div className="body-sizes-wrapper">
                    <div className="sets-wrapper" ref={this.wrapSetsRef}>
                        <div className="title-sets-wrapper">
                            <h6>{findPermInArray(roles, permEditSizesDictionary) &&
                            <Button className="button-with-icon" onClick={() => {
                                addSizeSet({
                                    id: uniqId('set-id-'),
                                    commodityId: '',
                                    genderId: '',
                                    brand: ''
                                })
                                if(this.wrapSetsRef.current)
                                    this.wrapSetsRef.current.scrollIntoView()
                            }}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                                </svg>
                                Add Set
                            </Button>}</h6>
                        </div>
                        {isLoading
                            ? <Preloader/>
                            : <>
                                <div className="set-row header-row">
                                    <div className="column-set-row"><span style={{paddingLeft: '32px'}}>CG1</span></div>
                                    <div className="column-set-row" ><span style={{paddingLeft: '28px'}}>CG2</span></div>
                                    <div className="column-set-row" ><span style={{paddingLeft: '8px'}}>Brand</span></div>
                                    <div className="column-set-row actions-set"></div>
                                </div>
                                {sizeSets.map(set => <SetRow
                                    handleDeleteSet={() => {
                                        this.setState({
                                            modalRemoveSet: true,
                                            currSet: set.id
                                        })
                                    }}
                                    onClick={() => setActiveSizeSet(set.id)}
                                    key={set.id}
                                    set={set}
                                    isCreate={typeof set.id === 'string' && set.id.indexOf('set-id-') >= 0}
                                />)}

                            </>
                        }
                    </div>
                    <SizeTypesComponent/>
                </div>
                {findPermInArray(roles, permEditSizesDictionary) && <CustomModalWithChildren
                    open={modalRemoveSet}
                    handleConfirm={this.handleDeleteSet}
                    handleClose={() => {
                        this.setState({
                            modalRemoveSet: false,
                            currSet: null
                        })
                    }}
                    handleCancel={() => {
                        this.setState({
                            modalRemoveSet: false,
                            currSet: null
                        })
                    }}
                    options={{
                        centered: true
                    }}
                >
                    <ConfirmModalContent
                        text={`delete size set`}
                    />
                </CustomModalWithChildren>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.sizeDictionaryReducer.isLoading || state.sizeDictionaryReducer.isLoadingSimpleParams,
        sizeSets: state.sizeDictionaryReducer.sizeSets,
        roles: state.rolesReducer.roles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setHeaderData: data => dispatch(setData(data)),
        getSizes: () => dispatch(getSizesSetsRequest()),
        getGendersCommodities: () => dispatch(getAllSimpleParams()),
        addSizeSet: set => dispatch(addSizeSetAction(set)),
        setActiveSizeSet: id => dispatch(setActiveSetAction(id)),
        removeSetRequest: id => dispatch(removeSetRequest(id)),
        setSizesSets: sets => dispatch(setSizesSetsAction(sets))
    }
}

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(SizesDictionary))