import { CUSTOMERS } from "../constants";

// import adminsInstance from "../components/instances/admininstance";
// import { url } from "../constants";
import { notify } from "../components/reuseComponent/toast";
import { selectStringToArr } from "../reuseFunctions/objectToString";
import {
    fetchCreateCustomer,
    fetchEditCustomer, getCountriesService,
    getCustomer, getCustomerRequest, getCustomerStatusesService
} from "../services/customers";
import {defaultServerError} from "../reuseFunctions/toasts";
import {changeRoutePush} from "../reuseFunctions/changeRoute";
import {history} from "../history";
import { convertToFormData } from "../reuseFunctions/objectToFormData";
import adminsInstance from "../components/instances/admininstance";
import { url } from "../constants";

export const setCustomerAction = customer => {
    console.log('set customer data');
    return ({
        type: CUSTOMERS.SET_CUSTOMER,
        payload: customer
    });
}
const setLoadingCustomerAction = type => ({
  type: CUSTOMERS.IS_LOADING_CUSTOMER,
  payload: type
});

export const setCustomerHistoryAction = history => ({
  type: CUSTOMERS.SET_CUSTOMER_HISTORY,
  payload: history
});

export const setCustomerRequestAction = request => ({
  type: CUSTOMERS.SET_CUSTOMER_REQUEST,
  payload: request
});

export const changeActiveCustomersHistoryTab = tabname => ({
  type: CUSTOMERS.CHANGE_CUSTOMER_HISTORY_TAB,
  payload: tabname
});
export const uploadFileToHistory = file => ({
  type: CUSTOMERS.ADD_HISTORY_FILE,
  payload: file
});

export const clearCustomer = () => ({
  type: CUSTOMERS.CLEAR_CUSTOMER
});

const setLoadingCustomersStatusesAction = loading => ({
    type: CUSTOMERS.SET_LOADING_CUSTOMERS_STATUSES,
    payload: loading,
});

const setCustomersStatuses = statuses => ({
    type: CUSTOMERS.SET_CUSTOMERS_STATUSES,
    payload: statuses,
});

const setLoadingCountries = loading => ({
    type: CUSTOMERS.IS_LOADING_COUNTRIES,
    payload: loading
});

const setCountries = countries => ({
    type: CUSTOMERS.SET_COUNTRIES,
    payload: countries
});

export const setCustomerHistory = history => ({
  type: CUSTOMERS.SET_HISTORY_CUSTOMER,
  payload: history
});

const addHistoryCustomersItem = historyItem => ({
  type: CUSTOMERS.ADD_HISTORY_CUSTOMERS_ITEM,
  payload: historyItem
});

export const getCustomerCurrentRequest = id => dispatch => {
  dispatch(setLoadingCustomerAction(true))
  getCustomerRequest(id).then(res => {
    const formatedData = {
      ...res.data,
      typeId: res.data.type.id,
      priorityId: res.data.priority
    };

    dispatch(setCustomerRequestAction(formatedData));
    dispatch(setCustomerHistory(formatedData.history));
    dispatch(setLoadingCustomerAction(false))
  });
};


export const addCustomersComment = ({ id, text }) => {
  return dispatch => {
    const data = {
      reqUrl: window.location.origin + "/atw/customers",
      comment: text
    };
    dispatch(setLoadingCustomersStatusesAction(true));
    adminsInstance
      .post(`${url}/customers/${id}/add_comment`, convertToFormData(data))
      .then(res => {
        notify("success", "Сomment successfully left");
        dispatch(addHistoryCustomersItem(res.data));
      })
      .catch(err => {
        console.log(err);
        notify("error", "Oops something went wrong");
      })
      .finally(() => {
        dispatch(setLoadingCustomersStatusesAction(false));
      });
  };
};


export const getCustomersStatuses = () => dispatch => {
    dispatch(setLoadingCustomersStatusesAction(true));
    console.log('load customers statuses');
    getCustomerStatusesService()
        .then(res => {
            dispatch(setLoadingCustomersStatusesAction(false));
            dispatch(setCustomersStatuses(res.data));
            console.log('finish loading customers statuses');
        })
        .catch(err => defaultServerError())
}

export const getCurrentCustomer = (id, type, callback) => dispatch => {
  dispatch(setLoadingCustomerAction(true));
  getCustomer(id).then(res => {
    const { demandCategory, qualityInterest } = res.data;

    const formatedData = {
      ...res.data,
        // zip: res.data.zip ? res.data.zip : '',
        city: res.data.city ? res.data.city : '',
      website: res.data.website ? res.data.website : '',
        notes: res.data.notes ? res.data.notes : '',
      busModel: selectStringToArr(res.data.busModel),
      contacts: res.data.customersContacts ? res.data.customersContacts.map(item => ({
          ...item,
          position: item.position ? item.position : ''
        })) : [],
        demandCategory: demandCategory ? demandCategory.split(', ').map(item => ({
            value: item,
            label: item,
        })) : [],
      qualityIds: qualityInterest.map(item => ({
        value: item.id,
        label: item.value
      }))
    };
    dispatch(setCustomerAction(formatedData));
      dispatch(setCustomersStatus(res.data.customersStatus));
      dispatch(setCustomersResponsible(res.data.user))
    dispatch(setCustomerHistoryAction(formatedData.history));
    dispatch(setLoadingCustomerAction(false));
    callback && callback();
  })
      .catch(err => {
          if(err && err.response && err.response.status === 400){
              if(type && type === 'allocate'){
                notify('error', 'You cannot accept unallocated you customer.');
                changeRoutePush('/atw/customer', history);
                return null;
              }else if(type && type === 'reject'){
                notify('error', 'You cannot reject unallocated you customer.');
                changeRoutePush('/atw/customer', history);
                return null;
              }else{
                notify('error', 'No access.');
                changeRoutePush('/atw/customer', history);
                return null;
              }
          }else{
              defaultServerError()
          }
      })
};

export const createCustomer = (values, callback) => dispatch => {
  dispatch(setLoadingCustomerAction(true));

  fetchCreateCustomer(values)
    .then(res => {
      dispatch(setLoadingCustomerAction(false));
      notify("success", "Customer successfully created");
      callback(res.data);
      const formatedData = {
        ...res.data,
        categIds: selectStringToArr(res.data.categIds),
        qualityIds: selectStringToArr(res.data.qualityIds),
          demandCategory: res.data.demandCategory ? res.data.demandCategory.split(', ').map(item => ({
              value: item,
              label: item,
          })) : [],
      };
      dispatch(setCustomerAction(formatedData));
      dispatch(setCustomerHistoryAction(formatedData.history));
      getCurrentCustomer(formatedData.id);
    })
    .catch(err => {
      dispatch(setLoadingCustomerAction(false));
      notify("error", "Oops something went wrong");
    });
};

export const editCustomer = (values, callback) => dispatch => {
  dispatch(setLoadingCustomerAction(true));
  fetchEditCustomer(values)
    .then(res => {
      dispatch(setLoadingCustomerAction(false));

      const { demandCategory, qualityInterest } = res.data;

      const formatedData = {
        ...res.data,
        busModel: selectStringToArr(res.data.busModel),
        contacts: [...res.data.customersContacts],
          demandCategory: demandCategory ? demandCategory.split(', ').map(item => ({
              value: item,
              label: item,
          })) : [],
        qualityIds: qualityInterest.map(item => ({
          value: item.id,
          label: item.value
        }))
          // demandCategory: demandCategory.map(item => item.value),
          // qualityIds: qualityInterest.map(item => item.value),
      };
      delete formatedData.customersStatus;
      callback && callback(formatedData);
      dispatch(setCustomerAction(formatedData));
      dispatch(setCustomerHistoryAction(formatedData.history));
      notify("success", "Customer successfully edited");
    })
    .catch(err => {
      dispatch(setLoadingCustomerAction(false));
      notify("error", "Oops something went wrong");
    });
};

export const getCountries = () => dispatch => {
    dispatch(setLoadingCountries(true))

    getCountriesService()
        .then(res => {
            let countries = [];
            if(res.data){
                for(const key in res.data){
                    countries.push(res.data[key])
                }
            }
            dispatch(setCountries(countries));
            dispatch(setLoadingCountries(false))
        })
        .catch(err => defaultServerError())
}

export const setCustomersResponsible = responsible => ({
    type: CUSTOMERS.SET_CUSTOMERS_RESPONSIBLE,
    payload: responsible
});

export const setCustomersStatus = status => {
    return  ({
        type: CUSTOMERS.SET_CUSTOMERS_STATUS,
        payload: status,
    });
}
