import React, {PureComponent} from 'react'
import {Form} from 'react-bootstrap'
import FirstStep from './firstStep'
import SecondStep from './secondStep'
import Preloader from '../../../reuseComponent/preloader/preloader'
import * as uniqId from 'uniqid';
import { OfferApi } from '../../../../services/offers';
import {defaultServerError} from '../../../../reuseFunctions/toasts';
import {getPalletForOffers} from '../../../../services/inventory';
import axios from 'axios'

const emptyCondition = {
    field: '',
    values: []
}

class OfferCreateForm extends PureComponent {

    state = {
        step: 1,
        sets: {},
        activeSet: null,
        src: this.props.src ? this.props.src : null,
        fields: [],
        pallets: [],
        selectedPallets: this.props.pallets ? this.props.pallets : []
    }

    componentDidMount(){
        const { setLoading } = this.props
        setLoading(true)
        axios.all([OfferApi.getFields(),OfferApi.getOfferConditions()])
            .then(axios.spread((res,resConditions) => {
                this.setState({
                    sets: resConditions.data.data ? JSON.parse(resConditions.data.data) : {},
                    fields: res.data.data.map(field => ({
                        value: field.alias,
                        label: field.name,
                        values: field.values.map(val => ({
                            label: val,
                            value: val
                        }))
                    }))
                })
            }))
            .catch(err => defaultServerError())
            .finally(() => setLoading(false))

    }

    generateFilterString = (conditions) => {
        let filterString = ''
        conditions.forEach(val => {
            if(val.field && val.values && val.values.length > 0){
                filterString +=`${val.field}:${val.values.map(val => val.value).join(',')};`
            }
        })
        return filterString
    }

    handleGetPalletBySet = (setId) => {
        // console.log(setId)
        const conditions = this.state.sets[setId]
        const { setLoading } = this.props
        setLoading(true)
        const params = {
            page: 0,
            limit: 300,
            filter: this.generateFilterString(conditions)
        }
        getPalletForOffers(params)
            .then(res => {
                this.setState({
                    pallets: res.data.data.content
                })
                setLoading(false)
            })
    }

    handleAddSet = () => {
        const {sets} = this.state
        const id = uniqId()
        this.setState(state => ({
            sets: {
                ...sets,
                [id] : []
            },
            pallets: [],
            activeSet: id
        }))
    }

    handleRemoveSet = id => {
        const {sets,activeSet} = this.state
        delete sets[id]
        this.setState(state => ({
            sets: {...sets},
            activeSet: activeSet === id ? null : state.activeSet,
            pallets: activeSet === id ? [] : state.pallets
        }))
    }

    handleAddCondition = (id) => {
        const {sets} = this.state
        const newCondition = {
            ...emptyCondition,
            id: uniqId()
        }
        sets[id] = sets[id].concat(newCondition)
        this.setState(state => ({
            sets: {...sets}
        }))
    }

    handleRemoveCondition = (idSet,id) => {
        const {sets} = this.state
        const condition = sets[idSet].find(cond => cond.id === id)
        sets[idSet] = sets[idSet].filter(condition => condition.id !== id)
        this.setState({
            sets: {...sets}
        },() => {
            if(condition.field && condition.values && condition.values.length > 0){
                this.handleGetPalletBySet(idSet)
            }
        })
    }

    handleChangeCondition = (idSet,id,field,value) => {
        const {sets} = this.state
        const condition = sets[idSet].find(cond => cond.id === id)
        condition[field] = value
        this.setState({
            sets: {...sets}
        },() => {
            if(condition.field && condition.values && condition.values.length > 0){
                this.handleGetPalletBySet(idSet)
            }
        })
    }

    handleChangeStep = (step) => {
        const { setFieldValue} = this.props
        this.setState({
            step: step
        },() => {
            setFieldValue && setFieldValue('sets',this.state.sets)
        })
    }


    handleSetImage = src => this.setState({src: src})

    handleSelectSet = set => {
        const { activeSet } = this.state
        if((activeSet && activeSet !== set) || !activeSet)
            this.setState({
                activeSet: set
            },() => this.handleGetPalletBySet(set))
    }

    handleChangeSelectedPallet = (palletId) => {
        const { setFieldValue,values } = this.props
        const { pallets } = this.state
        if(values.palletsIds.includes(palletId)){
            setFieldValue('palletsIds',values.palletsIds.filter(id => id !== palletId))
            this.setState(state => ({
                selectedPallets: state.selectedPallets.filter(pallet => pallet.id !== palletId)
            }))
        }
        else{
            setFieldValue('palletsIds',values.palletsIds.concat(palletId))
            this.setState(state => ({
                selectedPallets: state.selectedPallets.concat(pallets.find(pallet => pallet.id === palletId))
            }))
        }
    }

    showSelectedPallet = () => {
        this.setState(state => ({
            pallets: state.selectedPallets,
            activeSet: null
        }))
    }

    render() {
        const {step,sets,src,fields,activeSet,pallets} = this.state
        // console.log(sets)
        const {
            values,
            touched,
            errors,
            setFieldValue,
            handleSubmit,
            handleCancel,
            isLoading,
            setLoading
        } = this.props
        return (
            <Form onSubmit={e => handleSubmit(e,sets)}>
                <div className="create-offer-wrapper">
                    {isLoading && <Preloader/>}
                    {step === 1 && <FirstStep
                        pallets={pallets}
                        activeSet={activeSet}
                        fields={fields}
                        sets={sets}
                        showSelectedPallet={this.showSelectedPallet}
                        selectSet={this.handleSelectSet}
                        setFieldValue={this.handleChangeCondition}
                        selectUnselectPallet={this.handleChangeSelectedPallet}
                        selectedIdsPallets={values.palletsIds}
                        handleAddCondition={this.handleAddCondition}
                        handleRemoveCondition={this.handleRemoveCondition}
                        handleAddSet={this.handleAddSet}
                        handleRemoveSet={this.handleRemoveSet}
                        changeStep={this.handleChangeStep}
                        setLoading={setLoading}
                        handleCancel={handleCancel}
                    />}
                    {step === 2 && <SecondStep
                        src={src}
                        setImage={this.handleSetImage}
                        changeStep={this.handleChangeStep}
                        setLoading={setLoading}
                        handleCancel={handleCancel}
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                    />}
                </div>
            </Form>
        )
    }
}

export default OfferCreateForm