export const oldletterTemplate = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
</head>
<body>
<table bgcolor="#ffffff" border="0" cellpadding="0" cellspacing="0" width="100%">
    <tr>
        <td>
            <table bgcolor="#ffffff" align="center" border="0" cellpadding="0" cellspacing="0" width="600" 
            style="border-collapse: collapse; color:#616161; font-size:12px">
                <tr>
                    <td height="20"></td>
                </tr>
                <tr>
                    <td width="20"></td>
                    <td align="left" width="32" height="24" bgcolor="#ffffff" 
                    >
                        <img src="${window.location.origin}/wcs-small-logo.png" alt="WCS"  width="50" height="42" style="display: block; width: 50px;" />
                    </td>
                    <td width="20"></td>
                </tr>
                <tr>
                    <td height="20"></td>
                </tr>
                <tr>
                    <td width="20"></td>
                    <td id="content">
                        Text
                    </td>
                    <td width="20"></td>
                </tr>
                <tr>
                    <td height="20"></td>
                </tr>
                <tr>
                    <td width="20"></td>
                    <td align="center">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="560">
                            <tr>
                                <td height="1" bgcolor="#CCCCCC"></td>
                            </tr>
                        </table>
                    </td>
                    <td width="20"></td>
                </tr>
                <tr>
                    <td height="20"></td>
                </tr>
                <tr>
                    <td width="20"></td>
                    <td align="center">
                    <div style="text-align: justify;"><span style="color: rgb(189, 189, 189);">
                    This is automised notification and the reply will not be delivered.
                    <br>
                    If this message doesn't concern you then you can safely ignore it.</span></div>
                    </td>
                    <td width="20"></td>
                </tr>
                <tr>
                    <td height="20"></td>
                </tr>
            </table>
        </td>
    </tr>
</table>
</body>
</html>
`

export const letterTemplate = `   <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
</head>
<body>
<table bgcolor="#ffffff" align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse; color: rgb(97, 97, 97); font-size: 12px; width: 100%;">
    <tbody>
        <tr>
            <td>
                <div>
                    <table bgcolor="#ffffff" align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse; color: rgb(97, 97, 97); font-size: 12px; width: auto;">
                        <tbody>
                            <tr>
                                <td height="20"></td>
                            </tr>
                            <tr>
                                <td width="20"></td>
                                <td align="left">
                                    <div class="se-component se-image-container __se__float-none" contenteditable="false">
                                        <figure style="margin: 0px;">
                                            <img src="${window.location.origin}/wcs-small-logo.png" alt="WCS" width="44" height="50" style="display: block;" data-proportion="true" data-align="none" data-file-name="wcs-small-logo.png" data-file-size="0" origin-size="0,0" data-origin="," data-size="," data-rotate="" data-rotatex="" data-rotatey="" data-percentage="auto,auto" data-index="1">









                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         </figure>
</div>
                                </td>
                                <td width="20"></td>
                            </tr>
                            <tr>
                                <td height="20"></td>
                            </tr>
                            <tr>
                                <td width="20"></td>
                                <td id="content">
                                    Text
                                </td>
                            </tr>
                            <tr>
                                <td height="20"></td>
                            </tr>
                            <tr>
                                <td width="20"></td>
                                <td align="center">
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="560">
                                        <tbody>
                                            <tr>
                                                <td height="1" bgcolor="#CCCCCC"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td width="20"></td>
                            </tr>
                            <tr>
                                <td height="20"></td>
                            </tr>
                            <tr>
                                <td width="20"></td>
                                <td align="center">
                                    <div style="text-align: justify;"><span style="color: rgb(189, 189, 189);">
                    This is automised notification and the reply will not be delivered.&nbsp;<br>

If this message doesn't concern you then you can safely ignore it.</span></div>
                                </td>
                                <td width="20"></td>
                            </tr>
                            <tr>
                                <td height="20"></td>
                            </tr>
                        </tbody>
                    </table>
</div>
            </td>
        </tr>
    </tbody>
</table>
<p></p>

</body>
</html>`