import React, { PureComponent } from 'react'
import {Row, Col, Form, Card, Button} from 'react-bootstrap'
import { Formik } from 'formik'
import {changeRoute} from '../../reuseFunctions/changeRoute'
import {history} from '../../history'
import axios from 'axios'
import Preloader from '../reuseComponent/preloader/preloader'
import { url } from '../../constants'
import { notify } from '../reuseComponent/toast'


const validateForm = values => {
    let errors = {}
    if(values.firstPass === '')
        errors.firstPass = 'required field'
    else if(values.firstPass.length < 6)
        errors.firstPass = 'password must be no shorter than 6 characters'
    if(values.secondPass === '')
        errors.secondPass = 'required field'
    else if(values.secondPass.length < 6)
        errors.secondPass = 'password must be no shorter than 6 characters'
    else if(values.secondPass !== values.firstPass)
        errors.secondPass = 'passwords do not match'

    return errors
}

class ConfirmPasswordForm extends PureComponent{

    state = {
        loading: false,
        initialValues: {
            firstPass: '',
            secondPass: ''
        }
    }

    handleSubmit = values => {
        values.token = this.props.match.params.token

        const data = new FormData()
        data.append('password',values.secondPass)
        this.setState({
            loading: true,
            initialValues: values
        },() => {
            axios.post(`${url}/user/reset_password/${values.token}`,data)
                .then(res => {
                    notify('success','Password has been successfully changed')
                    history.push('/login')
                })
                .catch(err => {
                    notify('error','Inactive Token')
                    this.setState({
                        loading: false
                    })
                })

        })
    }

    render(){
        const { loading,initialValues } = this.state
        return(
            <Row className="rowForm">
                {!loading ?
                    <Col md="6">
                        <Card>
                            <Card.Body>
                                <Formik
                                    validate={validateForm}
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                >
                                    {
                                        props => {
                                            return (
                                                <Form noValidate onSubmit={props.handleSubmit}>
                                                    <Form.Group as={Col} xs="12" className="without-padding">
                                                        <Form.Label>New password</Form.Label>
                                                        <Form.Control
                                                            onChange={props.handleChange}
                                                            value={props.values.firstPass}
                                                            name="firstPass"
                                                            type="password"
                                                            isInvalid={props.touched.firstPass && props.errors.firstPass}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {props.errors.firstPass}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} xs="12" className="without-padding">
                                                        <Form.Label>Confirm password</Form.Label>
                                                        <Form.Control
                                                            onChange={props.handleChange}
                                                            value={props.values.secondPass}
                                                            name="secondPass"
                                                            type="password"
                                                            isInvalid={props.touched.secondPass && props.errors.secondPass}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {props.errors.secondPass}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group className="footer-form">
                                                        <Button variant="primary" type="submit">Confirm</Button>
                                                        <Button variant="secondary"
                                                                onClick={(e) => changeRoute('/login', history)}>Cancel</Button>
                                                    </Form.Group>
                                                </Form>
                                            )
                                        }
                                    }
                                </Formik>
                            </Card.Body>
                        </Card>
                    </Col>
                    : <Preloader/>
                }
            </Row>
        )
    }
}

export default ConfirmPasswordForm