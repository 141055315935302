import React, { PureComponent } from "react";
import { Button, Form } from "react-bootstrap";
// import {FieldArray} from 'formik'
// import {ReactComponent as DeleteIcon} from '../../../../../assets/images/delete.svg'
// import {ReactComponent as AddIcon} from '../../../../../assets/images/plus.svg'
// import Tooltip from '../../../../reuseComponent/Tooltips'
import AsyncSelect from "../../../../reuseComponent/Select/async-select";
import { getPaginatableSimpleParameter } from "../../../../../services/dictionaries";

class BodyForm extends PureComponent {
  filterDataForOptions = data => {
    return data.map(item => ({
      value: item.id,
      label: item.value
    }));
  };

  getCg1OptionsList = inputValue => {
    return new Promise(resolve => {
      getPaginatableSimpleParameter("commodity", 0, 25, inputValue).then(
        resp => {
          const data = resp.data.parameters.filter(
            item => item.id !== this.props.currId
          );
          resolve(this.filterDataForOptions(data));
        }
      );
    });
  };

  render() {
    const {
      handleSubmit,
      values,
      touched,
      errors,
      handleCancel,
      setFieldValue,
    } = this.props;
    return (
      <Form
        noValidate
        onSubmit={handleSubmit}
        className="delete-relations-form"
      >
        <p className="warning-form-item">Current CG1 has dependencies! Please select other CG1 in order to re-link them to:</p>
        <Form.Group>
          <Form.Label>CG1</Form.Label>
          <AsyncSelect
            name="toId"
            cacheOptions
            value={values.toId}
            onChange={obj => {
              setFieldValue("toId", obj);
            }}
            isInvalid={touched.toId && errors.toId}
            errorLabel={errors.toId}
            defaultOptions
            loadOptions={this.getCg1OptionsList}
          />
        </Form.Group>
        <Form.Group className="footer-form footer-form-custom">
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Form.Group>
      </Form>
    );
  }
}

export default BodyForm;
