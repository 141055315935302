import React, { Component } from "react";
import { connect } from "react-redux";
import { findPermInArray } from "../../../reuseFunctions/checkPermission";
import { Row, Col, Popover, OverlayTrigger, Button } from "react-bootstrap";
import Chip from "../chips/chip";
import Tooltip from "../Tooltips/index";
import { NavLink } from "react-router-dom";
import { toCapitalize } from "../../../reuseFunctions/formatValue";
import { withRouter } from "react-router-dom";
import { permViewAllCustomers } from "../../../permissions/customers";
import {
    permEditBrandsDictionary,
    permEditCg1Dictionary,
    permEditCg2Dictionary,
    permEditCg3Dictionary,
    permEditChannelsDictionary,
    permEditSizesDictionary,
    permViewBrandsDictionary,
    permViewCg1Dictionary,
    permViewCg2Dictionary,
    permViewCg3Dictionary,
    permViewChannelsDictionary,
    permViewProductNamesDictionary,
    permEditProductNamesDictionary,
    permViewSizesDictionary,
    permViewTaskTemplateDictionary,
    permViewArticles,
    permEditArticles, permViewWorkPlaces, permEditWorkPlaces
} from '../../../permissions/dictionaries';
import {
	permEditRoles,
	permEditUsers,
	permViewHistory,
	permViewNotifications,
	permViewRoles,
	permViewUsers
} from "../../../permissions/settings";
import { permEditTasks, permViewTasks } from "../../../permissions/tasks";
import {
	permEditSuppliers,
	permViewSuppliers
} from "../../../permissions/suppliers";
import { fields } from "../../admin/purchaseOrders/products/smart-filter/conditions";
import { setFilterConditions } from "../../../actions/analysisTool";
import {
	permEditInventory,
	permViewInventory
} from "../../../permissions/inventory";
import {setSearchText} from "../../../actions/admin-header";

let timeout = null;

class AdminHeader extends Component {
	// static getDerivedStateFromProps(props, state) {
	//     if (state.location !== props.location.pathname) {
	//         if (
	//             state.location &&
	//             props.location.pathname.indexOf('/atw/purchase-orders') >= 0 &&
	//             state.location.indexOf('/atw/purchase-orders') >= 0
	//         )
	//             return {
	//                 location: props.location.pathname
	//             };
	//         return {
	//             value: '',
	//             location: props.location.pathname
	//         };
	//     }

	//     if (props.headerData && props.headerData.clearSearch) {
	//         return {value: ''};
	//     }

	//     return {
	//         ...state,
	//         location: props.location.pathname
	//     };
	// }

	state = {
		isShow: false,
		searchCriterias: ['palletId', 'boxId', 'styleNumber', 'ean', 'orderNumber']
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.location.pathname === this.props.location.pathname)
			return;

		const pathArray = prevProps.location.pathname.split("/");
		const formatedPath = pathArray
			.filter((item, index) => index !== pathArray.length - 1)
			.join("/");
		const lastIsNum = isNaN(+pathArray[pathArray.length - 1])
			? prevProps.location.pathname
			: formatedPath;

		if (!this.props.location.pathname.includes(lastIsNum)) {
			this.setState({
				location: this.props.location.pathname
			});
		}
	}

	componentDidMount() {
		document.addEventListener("click", this.handleClickOutside);
	}
	componentWillUnmount() {
		document.removeEventListener("click", this.handleClickOutside);
	}

	popoverRef = React.createRef();
	dropdownRef = React.createRef();

	handleHideDropdown = () => this.setState({ isShow: false });
	handleClickOutside = e => {
		if (
			this.dropdownRef.current &&
			!this.dropdownRef.current.contains(e.target)
		) {
			this.handleHideDropdown();
		}
	};

	handleRemoveUserFromStorage = () => {
		window.localStorage.removeItem("userWCS");
	};

	handleDelete = text => {
		let arrayChips = this.props.searchText.split(",");
		const tmp = arrayChips
			.filter(item => item !== text)
			.map(item => item.trim())
			.join(",");
		this.props.setSearchText(tmp);
		this.props.headerData.handleSearch(tmp);
	};

	handleDeleteFilter = id => {
		const { onFilterChange } = this.props.tableRef;
		onFilterChange && onFilterChange(id, null);
	};

	handleDeleteItemFromMultipleFilter = (id, value) => {
		const { filters } = this.props;
		const currFilter = filters.find(
			filter => filter.column.tableData.id === id
		);
		let res = [];
		if (currFilter) {
			res = currFilter.value.filter(item => item.value !== value);
			const { onFilterChange } = this.props.tableRef;
			onFilterChange && onFilterChange(id, res);
		}
	};

	handleAddBtn = () => {
		this.props.headerData.handleAdd();
	};

	// handleChangeSearch = value => {
	// 	// const value = e.target.value;
	// 	this.setState(
	// 		{
	// 			value
	// 		},
	// 		() => {
	// 			clearTimeout(timeout);
	// 			timeout = setTimeout(() => {
	// 				// console.log(this.state.value);
	// 				this.props.headerData.handleSearch(this.state.value);
	// 			});
	// 		}
	// 	);
	// };

	handleChangeSearch = e => {

		const value = e.target.value;
		const { searchCriterias } = this.state;
		this.props.setSearchText(value);
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			this.props.headerData.handleChangeSearchCriterias ? this.props.headerData.handleChangeSearchCriterias(searchCriterias, value) :
			this.props.headerData.handleSearch(value);
		}, 500);
	};

	handleChangeSearchCriterias = val => {

		const value = this.props.searchText;

		this.setState({
			searchCriterias: val
		}, () => {
			this.props.headerData.handleChangeSearchCriterias(this.state.searchCriterias, value)
		})
	}

	handleMenuItemClick = () => {
		this.popoverRef.current.state.show = false;
		this.props.setSearchText("");
	};

	getDictionariesAvailable = () => {
		const { roles } = this.props;

		return (
			findPermInArray(roles, permViewCg1Dictionary) ||
			findPermInArray(roles, permEditCg1Dictionary) ||
			findPermInArray(roles, permViewCg2Dictionary) ||
			findPermInArray(roles, permEditCg2Dictionary) ||
			findPermInArray(roles, permViewCg3Dictionary) ||
			findPermInArray(roles, permEditCg3Dictionary) ||
			findPermInArray(roles, permViewSizesDictionary) ||
			findPermInArray(roles, permEditSizesDictionary) ||
			findPermInArray(roles, permViewChannelsDictionary) ||
			findPermInArray(roles, permEditChannelsDictionary) ||
			findPermInArray(roles, permViewTaskTemplateDictionary) ||
			findPermInArray(roles, permViewArticles) ||
			findPermInArray(roles, permEditArticles) ||
			findPermInArray(roles, permViewProductNamesDictionary) ||
			findPermInArray(roles, permEditProductNamesDictionary) ||
			findPermInArray(roles, permViewWorkPlaces) ||
			findPermInArray(roles, permEditWorkPlaces)
		);
	};

	getDictionariesFirstAvailableTab = () => {
		const { roles } = this.props;
		if (
			findPermInArray(roles, permViewCg1Dictionary) ||
			findPermInArray(roles, permEditCg1Dictionary)
		) {
			return "/atw/dictionaries/cg1";
		} else if (
			findPermInArray(roles, permViewCg2Dictionary) ||
			findPermInArray(roles, permEditCg2Dictionary)
		) {
			return "/atw/dictionaries/cg2";
		} else if (
			findPermInArray(roles, permViewCg3Dictionary) ||
			findPermInArray(roles, permEditCg3Dictionary)
		) {
			return "/atw/dictionaries/cg3";
		} else if (
			findPermInArray(roles, permViewSizesDictionary) ||
			findPermInArray(roles, permEditSizesDictionary)
		) {
			return "/atw/dictionaries/sizes";
		} else if (
			findPermInArray(roles, permEditBrandsDictionary) ||
			findPermInArray(roles, permViewBrandsDictionary)
		) {
			return "/atw/dictionaries/brands";
		} else if (
			findPermInArray(roles, permViewChannelsDictionary) ||
			findPermInArray(roles, permEditChannelsDictionary)
		) {
			return "/atw/dictionaries/channels";
		} else if (findPermInArray(roles, permViewTaskTemplateDictionary)) {
			return "/atw/dictionaries/task-templates";
		} else if (
			findPermInArray(roles, permViewArticles) ||
			findPermInArray(roles, permEditArticles)
		)
			return "/atw/dictionaries/articles";
		else if(
            findPermInArray(roles,permEditWorkPlaces) ||
            findPermInArray(roles,permViewWorkPlaces)
		)
			return "/atw/dictionaries/work-places"
	};

	getTasksFirstAvailableTab = () => {
		const { roles } = this.props;
		if (
			findPermInArray(roles, permViewTasks) ||
			findPermInArray(roles, permEditTasks)
		) {
			return "/atw/tasks/tickets/incomplete";
		} else {
			return "/atw/tasks/tickets/my-tasks";
		}
	};

	getSettingsAvailableTabLink = () => {
		const { roles } = this.props;
		if (
			findPermInArray(this.props.roles, permViewUsers) ||
			findPermInArray(this.props.roles, permEditUsers)
		) {
			return "/atw/settings/users";
		} else if (
			findPermInArray(roles, permViewRoles) ||
			findPermInArray(roles, permEditRoles)
		) {
			return "/atw/settings/roles";
		} else if (findPermInArray(roles, permViewHistory)) {
			return "/atw/settings/activity";
		} else if (findPermInArray(roles, permViewNotifications)) {
			return "/atw/settings/notifications";
		} else {
			return "/atw/settings/app-activity";
		}
	};

	generateMenu = (activeTickets) => (
		<Popover id="popover-basic">
			<Row className="menu-popover">
				{(findPermInArray(this.props.roles, permViewSuppliers) ||
					findPermInArray(this.props.roles, permEditSuppliers)) && (
					<Col xs="4">
						<NavLink
							onClick={() => this.handleMenuItemClick()}
							className="menu-item"
							to="/atw/suppliers"
						>
							<svg viewBox="0 0 24 24">
								<path
									fill="#D81B60"
									d="M12,3A4,4 0 0,1 16,7A4,4 0 0,1 12,11A4,4 0 0,1 8,7A4,4 0 0,1 12,3M16,13.54C16,14.6 15.72,17.07 13.81,19.83L13,15L13.94,13.12C13.32,13.05 12.67,13 12,13C11.33,13 10.68,13.05 10.06,13.12L11,15L10.19,19.83C8.28,17.07 8,14.6 8,13.54C5.61,14.24 4,15.5 4,17V21H10L11.09,21H12.91L14,21H20V17C20,15.5 18.4,14.24 16,13.54Z"
								/>
							</svg>
							<p>Suppliers</p>
						</NavLink>
					</Col>
				)}

				<Col xs="4">
					<NavLink
						onClick={() => this.handleMenuItemClick()}
						className="menu-item"
						to="/atw/purchase-orders"
					>
						<svg viewBox="0 0 24 24">
							<path
								fill="#1565C0"
								d="M19,3H14.82C14.25,1.44 12.53,0.64 11,1.2C10.14,1.5 9.5,2.16 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M7,7H17V5H19V19H5V5H7V7M17,11H7V9H17V11M15,15H7V13H15V15Z"
							/>
						</svg>
						<p>POs</p>
					</NavLink>
				</Col>
				<Col xs="4">
					<NavLink
						onClick={() => this.handleMenuItemClick()}
						className="menu-item"
						to="/atw/tasks/tickets"
					>
						<svg viewBox="0 0 24 24">
							<path
								fill="#FF9800"
								d="M19,4H18V2H16V4H8V2H6V4H5A2,2 0 0,0 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M5,8V6H19V8H5M10.56,18.46L16.5,12.53L15.43,11.47L10.56,16.34L8.45,14.23L7.39,15.29L10.56,18.46Z"
							/>
						</svg>
						<p>Tickets</p>
						{
							activeTickets && activeTickets > 0 ? (
								<div className="badge badge-default">{activeTickets}</div>
							) : null
						}
					</NavLink>
				</Col>
				<Col xs="4">
					<NavLink
						onClick={() => this.handleMenuItemClick()}
						className="menu-item"
						to="/atw/offers"
					>
						<svg viewBox="0 0 24 24">
							<path
								fill="#673AB7"
								d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z"
							/>
						</svg>
						<p>Offers</p>
					</NavLink>
				</Col>
				{/*<Col xs="4">*/}
				{/*	<NavLink*/}
				{/*		onClick={() => this.handleMenuItemClick()}*/}
				{/*		className="menu-item"*/}
				{/*		to="/atw/sales-orders"*/}
				{/*	>*/}
				{/*		<SaleIcon />*/}
				{/*		<p>SOs</p>*/}
				{/*	</NavLink>*/}
				{/*</Col>*/}
				{this.getDictionariesFirstAvailableTab() && (
					<Col xs="4">
						<NavLink
							onClick={() => this.handleMenuItemClick()}
							className="menu-item"
							to={this.getDictionariesFirstAvailableTab()}
						>
							<svg viewBox="0 0 24 24">
								<path
									fill="#795548"
									d="M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,12L17.5,10.5L15,12V4H20V12Z"
								/>
							</svg>
							<p>Dictionaries</p>
						</NavLink>
					</Col>
				)}
				<Col xs="4">
					<NavLink
						onClick={() => this.handleMenuItemClick()}
						className="menu-item"
						to="/atw/analysis-tool"
					>
						<svg viewBox="0 0 24 24">
							<path
								fill="#CDDC39"
								d="M11,2V22C5.9,21.5 2,17.2 2,12C2,6.8 5.9,2.5 11,2M13,2V11H22C21.5,6.2 17.8,2.5 13,2M13,13V22C17.7,21.5 21.5,17.8 22,13H13Z"
							/>
						</svg>
						<p>Analysis tool</p>
					</NavLink>
				</Col>
				<Col xs="4">
					<NavLink
						onClick={() => this.handleMenuItemClick()}
						className="menu-item"
						to="/atw/service_desk"
					>
						<svg
							className="action-icon header-icon"
							viewBox="0 0 24 24"
						>
							<path
								fill="#00a9ea"
								d="M18.72,14.76C19.07,13.91 19.26,13 19.26,12C19.26,11.28 19.15,10.59 18.96,9.95C18.31,10.1 17.63,10.18 16.92,10.18C13.86,10.18 11.15,8.67 9.5,6.34C8.61,8.5 6.91,10.26 4.77,11.22C4.73,11.47 4.73,11.74 4.73,12A7.27,7.27 0 0,0 12,19.27C13.05,19.27 14.06,19.04 14.97,18.63C15.54,19.72 15.8,20.26 15.78,20.26C14.14,20.81 12.87,21.08 12,21.08C9.58,21.08 7.27,20.13 5.57,18.42C4.53,17.38 3.76,16.11 3.33,14.73H2V10.18H3.09C3.93,6.04 7.6,2.92 12,2.92C14.4,2.92 16.71,3.87 18.42,5.58C19.69,6.84 20.54,8.45 20.89,10.18H22V14.67H22V14.69L22,14.73H21.94L18.38,18L13.08,17.4V15.73H17.91L18.72,14.76M9.27,11.77C9.57,11.77 9.86,11.89 10.07,12.11C10.28,12.32 10.4,12.61 10.4,12.91C10.4,13.21 10.28,13.5 10.07,13.71C9.86,13.92 9.57,14.04 9.27,14.04C8.64,14.04 8.13,13.54 8.13,12.91C8.13,12.28 8.64,11.77 9.27,11.77M14.72,11.77C15.35,11.77 15.85,12.28 15.85,12.91C15.85,13.54 15.35,14.04 14.72,14.04C14.09,14.04 13.58,13.54 13.58,12.91A1.14,1.14 0 0,1 14.72,11.77Z"
							/>
						</svg>
						<p>SD</p>
					</NavLink>
				</Col>
				{(
					<Col xs="4">
						<NavLink
							onClick={() => this.handleMenuItemClick()}
							className="menu-item"
							to="/atw/customer"
						>
							<svg viewBox="0 -45 445.98997 445">
								<path
									fill="#00BFA5"
									d="m387.832031 55.402344h1.425781c11.589844-.011719 20.984376-9.40625 21-21v-12.90625c-.015624-11.59375-9.410156-20.988282-21-21h-1.425781c-11.59375.011718-20.988281 9.40625-21 21v12.90625c.011719 11.59375 9.40625 20.988281 21 21zm0 0"
								/>
								<path
									fill="#00BFA5"
									d="m423.164062 94.667969h-3.40625l-9.421874 14.789062c-1.996094 3.128907-6.082032 4.160157-9.320313 2.347657l-11.808594-6.597657-10.90625 6.476563c-1.601562.972656-3.53125 1.265625-5.351562.804687-1.816407-.460937-3.375-1.628906-4.324219-3.25l-8.527344-14.566406h-4.160156c-6.457031 0-12.613281 2.730469-16.941406 7.519531-4.328125 4.789063-6.429688 11.1875-5.777344 17.609375.023438.234375.035156.472657.035156.707031v74.367188l22.546875 30.753906c.816407 1.113282 1.285157 2.445313 1.347657 3.824219l5.148437 115.21875c.152344 5.5 4.664063 9.871094 10.164063 9.859375h34.335937c5.480469.019531 9.980469-4.320312 10.160156-9.796875l5.148438-115.277344c.058593-1.371093.523437-2.695312 1.332031-3.808593l22.402344-30.769532v-74.371094c0-.234374.011718-.472656.035156-.707031.648438-6.421875-1.449219-12.816406-5.777344-17.605469-4.328125-4.789062-10.480468-7.523437-16.933594-7.527343zm0 0"
								/>
								<path
									fill="#00BFA5"
									d="m221.28125 55.402344h1.421875c11.59375-.011719 20.988281-9.40625 21-21v-12.90625c-.011719-11.59375-9.40625-20.988282-21-21h-1.421875c-11.59375.011718-20.988281 9.40625-21 21v12.90625c.011719 11.59375 9.40625 20.988281 21 21zm0 0"
								/>
								<path
									fill="#00BFA5"
									d="m256.613281 94.667969h-3.410156l-9.421875 14.789062c-1.992188 3.128907-6.082031 4.160157-9.320312 2.347657l-11.804688-6.597657-10.902344 6.472657c-1.121094.699218-2.417968 1.050781-3.738281 1.019531-2.449219-.035157-4.703125-1.347657-5.941406-3.460938l-8.523438-14.5625h-4.164062c-6.457031 0-12.609375 2.730469-16.941407 7.519531-4.328124 4.789063-6.429687 11.1875-5.777343 17.613282.023437.234375.035156.46875.035156.703125v74.371093l22.542969 30.75c.820312 1.113282 1.289062 2.445313 1.351562 3.828126l5.140625 115.214843c.15625 5.5 4.664063 9.875 10.167969 9.863281h34.335938c5.480468.015626 9.984374-4.324218 10.164062-9.800781l5.144531-115.277343c.0625-1.371094.527344-2.695313 1.335938-3.808594l22.398437-30.769532v-74.375c0-.234374.011719-.472656.035156-.707031.652344-6.421875-1.449218-12.816406-5.773437-17.605469-4.328125-4.789062-10.480469-7.523437-16.9375-7.527343zm0 0"
								/>
								<path
									fill="#00BFA5"
									d="m54.730469 55.402344h1.421875c11.59375-.011719 20.988281-9.40625 21-21v-12.90625c-.011719-11.59375-9.40625-20.988282-21-21h-1.421875c-11.59375.011718-20.988281 9.40625-21 21v12.90625c.011719 11.59375 9.40625 20.988281 21 21zm0 0"
								/>
								<path
									fill="#00BFA5"
									d="m90.0625 94.667969h-3.410156l-9.421875 14.789062c-1.992188 3.128907-6.082031 4.160157-9.320313 2.347657l-11.804687-6.597657-10.902344 6.472657c-1.121094.699218-2.417969 1.054687-3.738281 1.019531-2.449219-.035157-4.703125-1.347657-5.941406-3.460938l-8.527344-14.566406h-4.160156c-6.457032 0-12.613282 2.730469-16.941407 7.519531-4.328125 4.789063-6.429687 11.1875-5.777343 17.609375.023437.234375.035156.472657.035156.707031v74.367188l22.542968 30.753906c.816407 1.113282 1.285157 2.445313 1.347657 3.824219l5.144531 115.21875c.15625 5.5 4.664062 9.871094 10.164062 9.859375h34.34375c5.480469.019531 9.984376-4.320312 10.164063-9.796875l5.144531-115.277344c.0625-1.371093.527344-2.695312 1.335938-3.808593l22.398437-30.769532v-74.371094c0-.234374.011719-.472656.035157-.707031.652343-6.421875-1.445313-12.820312-5.773438-17.609375-4.328125-4.789062-10.480469-7.519531-16.9375-7.523437zm0 0"
								/>
							</svg>
							<p>Customers</p>
						</NavLink>
					</Col>
				)}
				{(findPermInArray(this.props.roles, permEditInventory) ||
					findPermInArray(this.props.roles, permViewInventory)) && (
					<Col xs="4">
						<NavLink
							onClick={() => this.handleMenuItemClick()}
							className="menu-item"
							to="/atw/inventory/list/"
						>
							<svg viewBox="0 0 24 24">
								<path
									fill="#607D8B"
									d="M18,15A3,3 0 0,1 21,18A3,3 0 0,1 18,21C16.69,21 15.58,20.17 15.17,19H14V17H15.17C15.58,15.83 16.69,15 18,15M18,17A1,1 0 0,0 17,18A1,1 0 0,0 18,19A1,1 0 0,0 19,18A1,1 0 0,0 18,17M18,8A1.43,1.43 0 0,0 19.43,6.57C19.43,5.78 18.79,5.14 18,5.14C17.21,5.14 16.57,5.78 16.57,6.57A1.43,1.43 0 0,0 18,8M18,2.57A4,4 0 0,1 22,6.57C22,9.56 18,14 18,14C18,14 14,9.56 14,6.57A4,4 0 0,1 18,2.57M8.83,17H10V19H8.83C8.42,20.17 7.31,21 6,21A3,3 0 0,1 3,18C3,16.69 3.83,15.58 5,15.17V14H7V15.17C7.85,15.47 8.53,16.15 8.83,17M6,17A1,1 0 0,0 5,18A1,1 0 0,0 6,19A1,1 0 0,0 7,18A1,1 0 0,0 6,17M6,3A3,3 0 0,1 9,6C9,7.31 8.17,8.42 7,8.83V10H5V8.83C3.83,8.42 3,7.31 3,6A3,3 0 0,1 6,3M6,5A1,1 0 0,0 5,6A1,1 0 0,0 6,7A1,1 0 0,0 7,6A1,1 0 0,0 6,5M11,19V17H13V19H11M7,13H5V11H7V13Z"
								/>
							</svg>
							<p>Inventory</p>
						</NavLink>
					</Col>
				)}
				{this.getSettingsAvailableTabLink() && (
						<Col xs="4">
							<NavLink
								onClick={() => this.handleMenuItemClick()}
								className="menu-item"
								to={this.getSettingsAvailableTabLink()}
							>
								<svg viewBox="0 0 24 24">
									<path
										fill="#616161"
										d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z"
									/>
								</svg>
								<p>Settings</p>
							</NavLink>
						</Col>
					)}
			</Row>
		</Popover>
	);

	render() {
		const {
			userName,
			filters,
			Actions,
			conditions,
			setFilter,
			user,
			activeTickets,
			searchText
		} = this.props;
		const {
			handleSearch,
			handleAdd,
			LeftPannel,
			leftPannelData,
			tableRef,
			textAddButton = "Add",
			handleChangeSearchCriterias,
		} = this.props.headerData;
		const { isShow, searchCriterias } = this.state;

		const chips = searchText.split(",");

		return (
			LeftPannel ? (
				<Row className="admin-header" style={{minHeight: handleChangeSearchCriterias ? '68px' : chips.length > 0 && chips[0] ? (filters.length > 0 ? '106px' : '68px') : (filters.length > 0) ? '68px' : '30px'}}>
					<Col className="left-pannel" md="3" lg="5">
						<LeftPannel {...leftPannelData} />
					</Col>
					<Col className="search-pannel" md="6" lg="3">
						{handleSearch && (
							<React.Fragment>
								{/*<ChipsInput*/}
								{/*	handleChange={(chips, searchValue) => this.handleChangeSearch([...chips, searchValue].join(', '))}*/}
								{/*/>*/}
								<input
									onChange={e => this.handleChangeSearch(e)}
									className="search"
									placeholder="Search..."
									value={searchText}
								/>
								{handleChangeSearchCriterias && <div className="search-criterias-wrap">
									<Button variant={'primary'} className={searchCriterias.length > 1 ? 'header-tab-button' : 'header-tab-button cancel'}
													onClick={() => this.handleChangeSearchCriterias(['palletId', 'boxId', 'styleNumber', 'ean', 'orderNumber', 'archived'])}
									>All</Button>
									<Button variant={'primary'} className={searchCriterias.length === 1 && searchCriterias.includes('palletId') ?'header-tab-button' : 'header-tab-button cancel'}
													onClick={() => this.handleChangeSearchCriterias(['palletId'])}
									>Pallet#</Button>
									<Button variant={'primary'} className={searchCriterias.length === 1 && searchCriterias.includes('boxId') ? 'header-tab-button' : 'header-tab-button cancel'}
													onClick={() => this.handleChangeSearchCriterias(['boxId'])}
									>Box#</Button>
									<Button variant={'primary'} className={searchCriterias.length === 1 && searchCriterias.includes('styleNumber') ? 'header-tab-button' : 'header-tab-button cancel'}
													onClick={() => this.handleChangeSearchCriterias(['styleNumber'])}
									>SKU#</Button>
									<Button variant={'primary'} className={searchCriterias.length === 1 && searchCriterias.includes('ean') ? 'header-tab-button' : 'header-tab-button cancel'}
													onClick={() => this.handleChangeSearchCriterias(['ean'])}
									>EAN#</Button>
									<Button variant={'primary'} className={searchCriterias.length === 1 && searchCriterias.includes('orderNumber') ? 'header-tab-button' : 'header-tab-button cancel'}
													onClick={() => this.handleChangeSearchCriterias(['orderNumber'])}
									>PO#</Button>
									<Button variant={'primary'} className={searchCriterias.length === 1 && searchCriterias.includes('bidNumber') ? 'header-tab-button' : 'header-tab-button cancel'}
											onClick={() => this.handleChangeSearchCriterias(['bidNumber'])}
									>BID#</Button>
								</div>}
								{!handleChangeSearchCriterias && chips.length > 0 && chips[0] ? (
									<div className="search-wrap">
										{chips.map(item =>
											item !== "" ? (
												<Chip
													handleDeleteChip={
														this.handleDelete
													}
													key={item}
													text={item}
												/>
											) : null
										)}
									</div>
								) : null}
								{filters.length > 0 ? (
									<div className="filters-wrap">
										{filters.map(filter => {
											if (
												typeof filter.value === "string"
											)
												return (
													<Chip
														handleDeleteChip={() =>
															this.handleDeleteFilter(
																filter.column
																	.tableData
																	.id
															)
														}
														key={
															filter.column
																.tableData.id
														}
														text={`${filter.column.title}: ${filter.value}`}
													/>
												);
											else
												return filter.value.map(
													item => (
														<Chip
															key={item.label}
															text={`${toCapitalize(
																filter.column
																	.field
															)}: ${item.label}`}
															handleDeleteChip={() =>
																this.handleDeleteItemFromMultipleFilter(
																	filter
																		.column
																		.tableData
																		.id,
																	item.value
																)
															}
														/>
													)
												);
										})}
									</div>
								) : null}
								{conditions && conditions.length > 0 ? (
									<div className="filters-wrap">
										{conditions.map(condition => {
											return (
												<Chip
													handleDeleteChip={() =>
														setFilter(
															conditions.filter(
																cond =>
																	cond.id !==
																	condition.id
															)
														).then(() => {
															tableRef.current &&
																tableRef.current.onQueryChange();
														})
													}
													key={condition.id}
													text={`${
														fields[condition.field]
															.labelField
													} ${
														condition.separator
															.label
													} ${condition.value}`}
												/>
											);
										})}
									</div>
								) : null}
							</React.Fragment>
						)}
					</Col>
					<Col className="right-pannel" md="3" lg="4">
						<div className="actions-wrapper">
							<div className="actions-header">
								{Actions && <Actions />}
							</div>
							{handleAdd && (
								<Tooltip
									text={textAddButton}
									placement="bottom"
								>
									<button
										onClick={() => this.handleAddBtn()}
										className="menu-button"
									>
										<svg
											className="action-icon header-icon"
											viewBox="0 0 24 24"
										>
											<path
												fill="#616161"
												d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
											/>
										</svg>
									</button>
								</Tooltip>
							)}
							<OverlayTrigger
								ref={this.popoverRef}
								trigger="click"
								rootClose={true}
								placement="bottom"
								overlay={this.generateMenu(activeTickets)}
							>
								<button className="menu-button">
									<svg
										className="action-icon header-icon"
										viewBox="0 0 24 24"
									>
										<path
											fill="#616161"
											d="M16,20H20V16H16M16,14H20V10H16M10,8H14V4H10M16,8H20V4H16M10,14H14V10H10M4,14H8V10H4M4,20H8V16H4M10,20H14V16H10M4,8H8V4H4V8Z"
										/>
									</svg>
								</button>
							</OverlayTrigger>
							<div
								className="author-wrapper"
								ref={this.dropdownRef}
								onClick={() => this.setState({ isShow: true })}
							>
								<span className="author-title title-text">
									{userName}
								</span>
								<svg viewBox="0 0 24 24">
									<path
										fill="#000000"
										d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
									></path>
								</svg>
								{isShow && (
									<ul className="dropdown-list">
										<NavLink
											onClick={e => {
												e.stopPropagation();
												this.handleHideDropdown();
											}}
											to={`/atw/profile/${user.info.id}`}
										>
											Profile settings
										</NavLink>
										<NavLink
											onClick={(e) => {
                                                e.stopPropagation()
                                                this.handleHideDropdown()
                                            }
											}
											to="/atw/release-notes"
										>
											About WCS
										</NavLink>
										<NavLink
											onClick={
												this.handleRemoveUserFromStorage
											}
											to="/login"
										>
											Logout
										</NavLink>
									</ul>
								)}
							</div>
						</div>
					</Col>
				</Row>
			) : (
				<Row className="admin-header" style={{minHeight: handleChangeSearchCriterias ? '68px' : chips.length > 0 && chips[0] ? (filters.length > 0 ? '106px' : '68px') : (filters.length > 0) ? '68px' : '30px'}}>
					<Col className="right-pannel" md="9" lg="8"></Col>
					<Col className="right-pannel" md="3" lg="4">
						<div className="actions-wrapper">
							<div
								className="author-wrapper"
								ref={this.dropdownRef}
								onClick={() => this.setState({ isShow: true })}
							>
								<span className="author-title title-text">
									{userName}
								</span>
								<svg viewBox="0 0 24 24">
									<path
										fill="#000000"
										d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
									></path>
								</svg>
								{isShow && (
									<ul className="dropdown-list">
										<NavLink
											onClick={e => {
												e.stopPropagation();
												this.handleHideDropdown();
											}}
											to={`/atw/profile/${user.info.id}`}
										>
											Profile settings
										</NavLink>
										<NavLink
											onClick={(e) => {
                                                e.stopPropagation()
                                                this.handleHideDropdown()
                                            }
											}
											to="/atw/release-notes"
										>
											About WCS
										</NavLink>
										<NavLink
											onClick={
												this.handleRemoveUserFromStorage
											}
											to="/login"
										>
											Logout
										</NavLink>
									</ul>
								)}
							</div>
						</div>
					</Col>
				</Row>
			)
		);
	}
}

const mapStateToProps = state => {
	return {
		roles: state.rolesReducer.roles,
		userName: `${state.usersReducer.currUser.info.name} ${state.usersReducer.currUser.info.secondName}`,
		headerData: state.adminHeaderReducer,
		searchText: state.adminHeaderReducer.searchText,
		filters: state.adminHeaderReducer.filters,
		tableRef: state.adminHeaderReducer.tableRef,
		Actions: state.adminHeaderReducer.Actions,
		conditions: state.analysisToolReducer.conditionsFilter,
		user: state.usersReducer.currUser,
		activeTickets: state.ticketsReducer.activeTickets,
	};
};

const mapDispatchProps = dispatch => ({
	setFilter: conditions => dispatch(setFilterConditions(conditions)),
	setSearchText: searchText => dispatch(setSearchText(searchText)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchProps)(AdminHeader)
);
