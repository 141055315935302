import React, {PureComponent} from 'react'
import {Button, Form, Col, Row} from 'react-bootstrap'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Select from '../../../reuseComponent/Select/select-form'
import AsyncSelect from '../../../reuseComponent/Select/async-select'
import adminInstance from '../../../instances/admininstance';
import {url} from '../../../../constants'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission'
import {permEditUsers} from '../../../../permissions/settings'
import CheckBox from '../../../reuseComponent/checkbox'
import {notify} from '../../../reuseComponent/toast'
import moment from 'moment'
import AutosizeInput from "react-input-autosize";
import {disabledSelectStyle} from "../../../reuseComponent/Select/disabledSelectStyling";

const userAlias = 'user';
const departmentAlias = 'department';
const inboxAlias = 'inbox';
const refucedNotif = 'System updates';

class BodyEditForm extends PureComponent {

    state = {
        defaultDepartments: [],
        notifications: [],
        isChange: false
    }

    static defaultProps = {
        isChangeHeader: true,
    }

    componentDidMount() {
        this.getNotificationsSettings()
        this.getOptions('')
            .then(res => {
                this.setState({
                    defaultDepartments: res
                })
            })
    }

    checkIfDataChange = () => {
        const {values, initialValues} = this.props
        let res = false
        // res = !isEqual(values, initialValues);
        Object.keys(initialValues).forEach(key => {
            try {
                if (JSON.stringify(values[key]) !== JSON.stringify(initialValues[key])) {
                    res = true
                }
            } catch (err) {

            }
        })
        return res
    }

    getOptions(inputValue) {
        let promise = adminInstance.get(`${url}/department`, {
            params: {
                limit: 20,
                page: 0,
                search: inputValue
            }
        })
            .then(res => {
                let options = res.data.departments.map(sup => ({
                    value: sup.id,
                    label: sup.name
                }))
                return options
            })
        return promise
    }

    getNotificationsSettings = () => {
        const {userInfo, values} = this.props
        adminInstance.get(`${url}/user/setting/notifications`, {
            params: {
                userId: userInfo.info.id === values.id ? undefined : values.id
            }
        })
            .then(res => {
                this.setState({
                    notifications: res.data.data
                })
            })
    }

    handleChange = (event, index, alias) => {
        const {notifications} = this.state
        if (alias === userAlias)
            notifications[index].userSubscribe = event.target.checked
        else if (alias === departmentAlias)
            notifications[index].departmentSubscribe = event.target.checked
        else if (alias === inboxAlias)
            notifications[index].inboxSubscribe = event.target.checked
        this.setState({
            notifications: [...notifications],
            isChange: true
        })
    }

    handleSaveNotifications = () => {
        const {notifications} = this.state
        const {userInfo, values} = this.props
        const formData = new FormData()
        const filtredNotifications = notifications.filter(notif => notif.userSubscribe)
        const filterDepartmentNotification = notifications.filter(notif => notif.departmentSubscribe && notif.name !== refucedNotif)
        const filterInboxNotification = notifications.filter(notif => notif.inboxSubscribe && notif.name !== refucedNotif)
        if (filtredNotifications.length > 0)
            filtredNotifications.forEach(notif => formData.append('notificationIds', notif.id))
        else
            formData.append('notificationIds', '')

        if (filterDepartmentNotification.length > 0)
            filterDepartmentNotification.forEach(notif => formData.append('departmentNotificationIds', notif.id))
        else
            formData.append('departmentNotificationIds', '')

        if (filterInboxNotification.length > 0)
            filterInboxNotification.forEach(notif => formData.append('inboxNotificationIds', notif.id))
        else
            formData.append('inboxNotificationIds', '')

        this.setState({
            isChange: false
        }, () => {
            adminInstance.put(`${url}/user/setting/notifications`, formData, {
                params: {
                    userId: userInfo.info.id === values.id ? undefined : values.id
                }
            })
                .then(res => {
                    notify('success', 'Notification settings saved')
                })
                .catch(err => {
                    notify('error', 'Oops something went wrong')
                })
        })

    }

    checkIfAllSelected = alias => {
        const { notifications } = this.state;
        if (
            alias === userAlias &&
            notifications.length > 0 &&
            notifications.filter(notif => notif.userSubscribe).length ===
            notifications.length
        ) {
            return true;
        } else if (
            alias === departmentAlias &&
            notifications.length > 0 &&
            notifications.filter(notif => notif.departmentSubscribe).length ===
            notifications.filter(notif => notif.name !== refucedNotif && notif.departmentSubscribe !== undefined)
                .length
        ) {
            return true;
        } else if (
            alias === inboxAlias &&
            notifications.length > 0 &&
            notifications.filter(notif => notif.inboxSubscribe).length ===
            notifications.filter(notif => notif.name !== refucedNotif && notif.inboxSubscribe !== undefined)
                .length
        ) {
            return true;
        }
        else return false;
    };

    handleSelectUnselectAll = (checked, alias) => {
        const { notifications } = this.state;
        this.setState({
            isChange: true,
            notifications: notifications.map(notif => {
                if (alias === userAlias) notif.userSubscribe = checked;
                else if (alias === departmentAlias && notif.departmentSubscribe !== undefined)
                    notif.departmentSubscribe = checked;
                else if (alias === inboxAlias && notif.inboxSubscribe !== undefined)
                    notif.inboxSubscribe = checked;
                return notif;
            })
        });
    };

    render() {
        const {defaultDepartments, notifications, isChange} = this.state
        const {
            handleSubmit,
            handleChange,
            touched,
            values,
            errors,
            roles,
            setFieldValue,
            userInfo,
            setValues,
            rolesPermissions,
            responsibleLanguages,
            responsibleCountries,
        } = this.props
        //const isView = !findPermInArray(rolesPermissions, permEditUsers)
        const isView = false;

        console.log('notifications: ', notifications);
        return (
            <>
                <Form className="user-profile-form" noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col xs="6">
                            <Form.Group className="horizontal">
                                <Form.Label>First Name</Form.Label>
                                <div className="input-item">
                                    <AutosizeInput
                                        disabled={isView}
                                        type="text"
                                        placeholder={!isView ? 'Enter first name' : ''}
                                        name="name"
                                        className="auto-resize-wrapper"
                                        value={values.name}
                                        onChange={handleChange}
                                        // isInvalid={touched.name && errors.name}
                                    />
                                    <span className="error-label">{touched.name && errors.name}</span>
                                    {/*<Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>*/}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="horizontal">
                                <Form.Label>Last Name</Form.Label>
                                <div className="input-item">
                                    <AutosizeInput
                                        disabled={isView}
                                        type="text"
                                        placeholder={!isView ? 'Enter last name' : ''}
                                        className="auto-resize-wrapper"
                                        name="secondName"
                                        value={values.secondName}
                                        onChange={handleChange}
                                    />
                                    <span className="error-label">{touched.secondName && errors.secondName}</span>
                                </div>
                            </Form.Group>
                        </Col>
                        {(values.id !== userInfo.info.id && (userInfo.role.alias === 'admin' || findPermInArray(rolesPermissions, permEditUsers))) &&
                        <Col xs={6}>
                            <Form.Group className="horizontal">
                                <Form.Label className="with-select">Role</Form.Label>
                                <div className="input-item">
                                    <Select
                                        isDisabled={isView}
                                        name="roleId"
                                        isInvalid={errors.roleId && touched.roleId}
                                        errorLabel={errors.roleId}
                                        styles={isView ? disabledSelectStyle : {}}
                                        value={roles.find(rl => rl.value === values.roleId)}
                                        onChange={role => {
                                            setFieldValue('roleId', role.value)
                                        }}
                                        options={roles}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        }
                        <Col xs={6}>
                            <Form.Group className="horizontal">
                                <Form.Label className="with-select">Department</Form.Label>
                                <div className="input-item">
                                <AsyncSelect
                                    isDisabled={isView}
                                    defaultOptions={defaultDepartments}
                                    name="departmentId"
                                    cacheOptions
                                    styles={isView ? disabledSelectStyle : {}}
                                    value={values.department}
                                    onChange={obj => {
                                        setValues({
                                            ...values,
                                            department: obj,
                                            departmentId: obj.value
                                        })
                                        // setFieldValue('supplierId', obj.value)
                                    }}
                                    isInvalid={touched.departmentId && errors.departmentId}
                                    errorLabel={errors.departmentId}
                                    loadOptions={this.getOptions}
                                />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="horizontal">
                                <Form.Label>Initials</Form.Label>
                                <div className="input-item">
                                    <AutosizeInput
                                        disabled={isView}
                                        type="text"
                                        className="auto-resize-wrapper"
                                        name="initials"
                                        placeholder={!isView ? 'Enter initials' : ''}
                                        value={values.initials}
                                        onChange={handleChange}
                                        isInvalid={
                                            touched.initials && errors.initials
                                        }
                                    />
                                    <span className="error-label">{touched.initials && errors.initials}</span>

                                    {/*<Form.Control.Feedback type="invalid">*/}
                                    {/*    {errors.initials}*/}
                                    {/*</Form.Control.Feedback>*/}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="horizontal">
                                <Form.Label className="with-select">Initials Color</Form.Label>
                                <div className="input-item color-item">
                                    <Form.Control
                                        type="color"
                                        name="initialsColor"
                                        disabled={isView}
                                        value={values.initialsColor}
                                        onChange={handleChange}
                                        isInvalid={
                                            touched.initialsColor &&
                                            errors.initialsColor
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.initialsColor}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className="horizontal">
                                <Form.Label className="with-select">Countries for Auto-allocation</Form.Label>
                                <div className="input-item">
                                    <Select
                                        isDisabled={isView}
                                        name="responsibleCountry"
                                        isMulti={true}
                                        options={values.responsibleCountry &&
                                        values.responsibleCountry.find(item => item.value === 'All') ? [] : responsibleCountries}
                                        placeholder={!isView ? 'Select country' : ''}
                                        styles={isView ? disabledSelectStyle : {}}
                                        isInvalid={touched.responsibleCountry && errors.responsibleCountry}
                                        errorLabel={errors.responsibleCountry}
                                        onChange={obj => {
                                            if(obj && obj.find(item => item.value === 'All')){
                                                setFieldValue(
                                                    "responsibleCountry",
                                                    [{value: 'All', label: 'All'}]
                                                );
                                            }
                                            else
                                                setFieldValue(
                                                    "responsibleCountry",
                                                    obj ? obj : []
                                                );
                                        }}
                                        value={values.responsibleCountry}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className="horizontal">
                                <Form.Label className="with-select">Spoken languages for Auto-allocation</Form.Label>
                                <div className="input-item">
                                    <Select
                                        isDisabled={isView}
                                        name="languages"
                                        isMulti={true}
                                        placeholder={!isView ? 'Select languages' : ''}
                                        styles={isView ? disabledSelectStyle : {}}
                                        options={responsibleLanguages}
                                        isInvalid={touched.languages && errors.languages}
                                        errorLabel={errors.languages}
                                        onChange={obj => {
                                            setFieldValue('languages', obj ? obj : [])
                                        }}
                                        value={values.languages}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="footer-form">
                        {!isView && this.checkIfDataChange() && <Button variant="primary" type="submit">Save</Button>}
                        {/*<Button variant="secondary"*/}
                        {/*onClick={() => history.replace('/atw/settings/users')}>Cancel</Button>*/}
                    </Form.Group>
                    {values.author && values.invited && <Form.Group>
                        <p className="p-right">Invited {moment(values.invited, 'YYYY-MM-DDTHH:mm').format('MMM DD, YYYY')} by {values.author.fullName}</p>
                    </Form.Group>
                    }

                </Form>
                <div className="user-notifications">
                    <div className="header-notifications notification-row">
                        <div className="col-notif f1">
                            <svg viewBox="0 0 24 24">
                                <path fill="currentColor"
                                      d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"/>
                            </svg>
                            Notifications
                        </div>
                        <div className="col-notif f1">
                            To me
                        </div>
                        <div className="col-notif f1">
                            To my Department
                        </div>
                        <div className="col-notif f1">
                            Inbox
                        </div>
                    </div>

                    <div className="notifications-list">
                        <div className="notification-row">
                            <div className="col-notif f1">
                                Select All/Unselect All
                            </div>
                            <div className="col-notif f1">
                                <CheckBox
                                    options={{
                                        className: 'checkbox-material',
                                        disabled: isView
                                    }}
                                    handleChange={(ev) => (!isView || userInfo.info.id === values.id) && this.handleSelectUnselectAll(ev.target.checked, userAlias)}
                                    checked={this.checkIfAllSelected(userAlias)}/>
                            </div>
                            <div className="col-notif f1">
                                <CheckBox
                                    options={{
                                        className: 'checkbox-material',
                                        disabled: isView
                                    }}
                                    handleChange={(ev) => (!isView || userInfo.info.id === values.id) && this.handleSelectUnselectAll(ev.target.checked, departmentAlias)}
                                    checked={this.checkIfAllSelected(departmentAlias)}/>
                            </div>
                            <div className="col-notif f1">
                                <CheckBox
                                    options={{
                                        className: 'checkbox-material',
                                        disabled: isView
                                    }}
                                    handleChange={(ev) => (!isView || userInfo.info.id === values.id) && this.handleSelectUnselectAll(ev.target.checked, inboxAlias)}
                                    checked={this.checkIfAllSelected(inboxAlias)}/>
                            </div>
                        </div>
                        {notifications.map((notif, index) => {
                            return <div className="notification-row" key={notif.id}>
                                <div className="col-notif f1">
                                    {notif.name}
                                </div>

                                <div className="col-notif f1">
                                    <CheckBox
                                        options={{
                                            className: 'checkbox-material',
                                            disabled: isView
                                        }}
                                        handleChange={(ev) => (!isView || userInfo.info.id === values.id) && this.handleChange(ev, index, userAlias)}
                                        checked={notif.userSubscribe}/>
                                </div>

                                <div className="col-notif f1">
                                    {(notif.name !== refucedNotif && notif.departmentSubscribe !== undefined)  && (
                                        <CheckBox
                                            options={{
                                                className:
                                                    "checkbox-material",
                                                disabled:
                                                isView
                                            }}
                                            handleChange={ev =>
                                                (!isView ||
                                                    userInfo.info.id ===
                                                    values.id) &&
                                                this.handleChange(
                                                    ev,
                                                    index,
                                                    departmentAlias
                                                )
                                            }
                                            checked={
                                                notif.departmentSubscribe
                                            }
                                        />
                                    )}
                                </div>

                                <div className="col-notif f1">
                                    {(notif.name !== refucedNotif && notif.inboxSubscribe !== undefined)  && (
                                        <CheckBox
                                            options={{
                                                className:
                                                    "checkbox-material",
                                                disabled:
                                                isView
                                            }}
                                            handleChange={ev =>
                                                (!isView ||
                                                    userInfo.info.id ===
                                                    values.id) &&
                                                this.handleChange(
                                                    ev,
                                                    index,
                                                    inboxAlias
                                                )
                                            }
                                            checked={
                                                notif.inboxSubscribe
                                            }
                                        />
                                    )}
                                </div>

                                {/* <div className="col-notif f1">
                                    <CheckBox
                                        options={{
                                            className: 'checkbox-material',
                                            disabled: isView
                                        }}
                                        handleChange={(ev) => (!isView || userInfo.info.id === values.id) && this.handleChange(ev, index, inboxAlias)}
                                        checked={notif.inboxSubscribe}/>
                                </div> */}

                            </div>
                        })}
                    </div>
                    <div className="footer-notifications">
                        {(isChange && !isView) &&
                        <Button onClick={this.handleSaveNotifications} variant="primary" type="button">Save</Button>}
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.usersReducer.currUser,
        rolesPermissions: state.rolesReducer.roles,
        responsibleLanguages: state.usersReducer.languages.map(item => ({value: item.id, label: item.name})),
        responsibleCountries: [{value: 'All', label: 'All'} ,...state.customersReducer.countries.map(item => ({
            value: item,
            label: item
        }))],
        roles: state.usersReducer.roles.map(item => {
            return {
                value: item.id,
                label: item.Name
            }
        }),
    }
}

export default withRouter(connect(mapStateToProps, null)(BodyEditForm))