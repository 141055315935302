import React, {useState, useEffect, useCallback, useRef} from 'react'
import {ReactComponent as SkipPrevIcon} from '../../../../../assets/images/skip-prev.svg'
import {ReactComponent as SkipNextIcon} from '../../../../../assets/images/skip-next.svg'
import {ReactComponent as PrevIcon} from '../../../../../assets/images/prev.svg'
import {ReactComponent as NextIcon} from '../../../../../assets/images/next.svg'
import {ReactComponent as BottomArrow} from '../../../../../assets/images/triangle-bottom-arrow.svg'
import Tooltip from '../../../../reuseComponent/Tooltips'


const Pagination = ({pageSizes = [10, 25, 50], limit, page, changeLimit, count,changePage}) => {

    const [isOpenLimitList, setOpenLimitList] = useState(false)
    const listRef = useRef(null)

    const handleClickOutSide = useCallback((event) => {
        if (listRef.current && !listRef.current.contains(event.target)) {
            setOpenLimitList(false)
        }
    }, [])

    const handleShowHideList = useCallback(() => {
        setOpenLimitList(!isOpenLimitList)
    }, [isOpenLimitList])

    const handleSkipNext = useCallback(() => {
        changePage(Math.max(0, Math.ceil(count / limit) - 1));
    },[count,limit,changePage])

    const handleNext = useCallback(() => {
        changePage(page + 1)
    },[page,changePage])

    const handlePrev = useCallback(() => {
        changePage(page - 1)
    },[page,changePage])

    const handleSkipPrev = useCallback(() => {
        changePage(0)
    },[changePage])

    useEffect(() => {
        document.addEventListener('click', handleClickOutSide)
        return () => {
            document.removeEventListener('click', handleClickOutSide)
        }
    })

    return (
        <div className="pagination scanned-history-component">
            <div className="limit-list" ref={listRef}>
                <button
                    className="page-size-btn"
                    onClick={handleShowHideList}
                >
                    {limit} rows <BottomArrow/>
                </button>
                {isOpenLimitList && <ul>
                    {pageSizes.map(pageSize => <li onClick={() => {
                        setOpenLimitList(false)
                        changeLimit(pageSize)
                    }} key={pageSize}>{pageSize}</li>)}
                </ul>
                }
            </div>
            <div className="pagination__actions">
                <button
                    onClick={handleSkipPrev}
                    className="skip-prev btn-pagination"
                    disabled={page === 0}
                    style={page === 0 ? {
                        pointerEvents: 'none'
                    } : null}
                >
                    <Tooltip text="First page">
                        <SkipPrevIcon/>
                    </Tooltip>
                </button>
                <button
                    onClick={handlePrev}
                    className="prev btn-pagination"
                    disabled={page === 0}
                    style={page === 0 ? {
                        pointerEvents: 'none'
                    } : null}
                >
                    <Tooltip text="Previous page">
                        <PrevIcon/>
                    </Tooltip>
                </button>
                <div className="pagination__info">
                    {count > 0 ? page * limit + 1 : 0}
                    -{Math.min((page + 1) * limit, count)} of {count}
                </div>
                <button
                    onClick={handleNext}
                    className="next btn-pagination"
                    disabled={page >= Math.ceil(count / limit) - 1}
                    style={page >= Math.ceil(count / limit) - 1? {
                        pointerEvents: 'none'
                    } : null}
                >
                    <Tooltip text="Next page">
                        <NextIcon/>
                    </Tooltip>
                </button>
                <button
                    onClick={handleSkipNext}
                    className="skip-next btn-pagination"
                    style={page >= Math.ceil(count / limit) - 1? {
                        pointerEvents: 'none'
                    } : null}
                    disabled={page >= Math.ceil(count / limit) - 1}
                >
                    <Tooltip text="Last page">
                        <SkipNextIcon/>
                    </Tooltip>
                </button>
            </div>
        </div>
    )
}

export {Pagination}