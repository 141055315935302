import React from 'react'
import {connect} from 'react-redux'

import {changeActiveHistoryTab, getHistory,getTasksAction} from '../../../../../../../actions/purchase-orders'
import {findPermInArray} from '../../../../../../../reuseFunctions/checkPermission'
import {permFilesChronology, permTaskChronology,permInvoiceChronology,permViewChronology} from "../../../../../../../permissions/productOverview";

const Tabs = props => {

    const checkIfHasPermToTasks = () => {
        const { roles } = props
        return findPermInArray(roles,permViewChronology) && findPermInArray(roles,permTaskChronology)
    }

    const {activeTab, changeActiveTab, getHistory, roles,getTasks,details,hasOwnTasks, userInfo} = props

    const hasPermToTaks = findPermInArray(roles, permTaskChronology);
    const isAdmin = userInfo.role.alias === 'admin';
    return (
        <div className="tabs">
            {(hasOwnTasks || isAdmin) && <button className={`tab-button ${activeTab === 'TASK'  ? 'active' : ''}`}
                           onClick={() => {
                               changeActiveTab('TASK')
                               getTasks({
                                   poId: details.id,
                                   currUser: !checkIfHasPermToTasks(),
                               },false)
                           }}>Tickets
            </button>
            }
            {(findPermInArray(roles, permTaskChronology) || 
      findPermInArray(roles, permFilesChronology) ||
      findPermInArray(roles, permInvoiceChronology)) &&

            <button className={`tab-button ${activeTab === '' || (!hasPermToTaks && activeTab === '') ? 'active' : ''}`}
                    onClick={() => {
                        changeActiveTab('')
                        getHistory('')
                    }}>Chronology
            </button>
            }
            {(findPermInArray(roles, permTaskChronology) || 
      findPermInArray(roles, permFilesChronology) ||
      findPermInArray(roles, permInvoiceChronology)) &&
            <button className={`tab-button ${activeTab === 'COMMENT' ? 'active' : ''}`}
                    onClick={() => {
                        changeActiveTab('COMMENT')
                        getHistory('COMMENT')
                    }}>Comments
            </button>
            }
            {(findPermInArray(roles, permFilesChronology) || findPermInArray(roles,permInvoiceChronology) || findPermInArray(roles, permInvoiceChronology)) && <button className={`tab-button ${activeTab === 'FILE' ? 'active' : ''}`}
                           onClick={() => {
                               changeActiveTab('FILE')
                               getHistory('FILE')
                           }}>Files
            </button>
            }

        </div>
    )
}

const mapStateToProps = state => {
    return {
        activeTab: state.purchaseOrdersReducer.activeTabHistory,
        roles: state.rolesReducer.roles,
        details: state.purchaseOrdersReducer.detailsPurchase,
        userInfo: state.usersReducer.currUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeActiveTab: tabName => dispatch(changeActiveHistoryTab(tabName)),
        getHistory: type => dispatch(getHistory(type)),
        getTasks: (obj,withPreloader) => dispatch(getTasksAction(obj,withPreloader))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Tabs)