import { Form,Col,Button,Card } from 'react-bootstrap'
import React from "react"
import { Link } from 'react-router-dom'

export const BodyForm = (props) => {
    return (
        <Form id="login-form" noValidate onSubmit={props.handleSubmit}>
            <Card.Body className="mx-4">
                {/*<div className="text-center">*/}
                    {/*<h3 className="dark-grey-text mb-5">*/}
                        {/*<strong className="title-text">Sign in</strong>*/}
                    {/*</h3>*/}
                {/*</div>*/}
                <Form.Group as={Col} xs="12" className="without-padding">
                    <Form.Label>Your email</Form.Label>
                    <Form.Control
                        onChange={props.handleChange}
                        value={props.values.username}
                        name="username"
                        type="email"
                        isInvalid={props.touched.username && props.errors.username}
                    />
                    <Form.Control.Feedback type="invalid">
                        {props.errors.username}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} size="12" className="without-padding">
                    <Form.Label>Your password</Form.Label>
                    <Form.Control
                        onChange={props.handleChange}
                        value={props.values.password}
                        name="password"
                        type="password"
                        isInvalid={props.touched.password && props.errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                        {props.errors.password}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="text-center md-form mb-3">
                    <Button
                        type="submit"
                        variant="primary"
                    >
                        Sign in
                    </Button>
                </div>
                <p className="d-flex justify-content-end forgot-password">

                    <Link to="/reset-password" className="blue-text ml-1">
                        Forgot
                        Password?
                    </Link>
                </p>
            </Card.Body>
        </Form>
    )
}
