import { SERVICE_WORKER } from '../constants'

const initState = {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null
}

export const serviceWorkerReducer = (state = initState,action) => {
    const { type,payload } = action
    switch(type){
        case SERVICE_WORKER.SW_INIT: {
            return {
                ...state,
                serviceWorkerInitialized: true
            }
        }
        case SERVICE_WORKER.SW_UPDATE: {
            return {
                ...state,
                serviceWorkerUpdated: !state.serviceWorkerUpdated,
                serviceWorkerRegistration: payload
            }
        }
        default: {
            return state
        }
    }
}