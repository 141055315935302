import React, {PureComponent} from 'react'
import { connect } from 'react-redux'
import { putProductToNewBox } from '../../../../../../services/products'
import { notify } from '../../../../../reuseComponent/toast'

class LastArticleComponent extends PureComponent {

    state = {
        selectedBox: {
            label: `${this.props.actionHistory.stowedBoxName}`,
            value: this.props.actionHistory.boxId
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.actionHistory.boxId !== this.props.actionHistory.boxId){
            this.setState({
                selectedBox: {
                    label: `${this.props.actionHistory.stowedBoxName}`,
                    value: this.props.actionHistory.boxId
                }
            })
        }
    }

    handleChange = obj => {
        const  { actionHistory,changeBoxCallback } = this.props
        this.setState({
            selectedBox: obj
        },() => {
            putProductToNewBox({
                idHistoryItem: actionHistory.id,
                newBoxId: obj.value
            })
                .then(res => {
                    changeBoxCallback && changeBoxCallback(`Box ${obj.name}`)
                    notify('success',`Article successfully moved to ${obj.label}`)
                })
                .catch(err => {
                    notify('error','Oops something went wrong')
                })
        })
    }

    render() {
        const { actionHistory } = this.props
        // const { selectedBox } = this.state
        return (
            // <>
            //     Article <strong>{actionHistory.product.ean}</strong> to <strong>{actionHistory.stowedPackageName} {actionHistory.stowedPackageFullName && `(${actionHistory.stowedPackageFullName})`}</strong> > {/*{!details.completeInbound && openedBoxes.length > 0 ? <Select
            //         value={selectedBox}
            //         onChange={this.handleChange}
            //         options={openedBoxes.map(box => ({
            //             label: `Box ${box.name}`,
            //             value: box.id,
            //             name: box.name
            //         }))}
            //     /> :*/ selectedBox.label}
            // </>
            <div dangerouslySetInnerHTML={{__html: actionHistory.text}}>
                {/*{actionHistory.text}*/}
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

const LastArticle = connect(mapStateToProps,null)(LastArticleComponent)

export { LastArticle }