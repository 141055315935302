import React, { PureComponent } from 'react'

export default class CustomDropMenu extends PureComponent{

    static defaultProps = {
        className : ''
    }

    render(){
        const {
            className,
            style
        } = this.props
        return(
            <div style={style} className={`dropdown-menu ${className}`}>
                {this.props.children}
            </div>
        )
    }
}