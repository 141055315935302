import React, {PureComponent} from 'react'
import {Button} from 'react-bootstrap'
import axios from 'axios'
import {getCloseBoxesByPoId} from '../../../../services/boxes'
import {notify} from '../../../reuseComponent/toast'
import Preloader from '../../../reuseComponent/preloader/preloader'
import {ReactComponent as ArrowRightIcon} from '../../../../assets/images/arrow-right.svg'
import {ReactComponent as ArrowLeftIcon} from '../../../../assets/images/arrow-left.svg'
import {ReactComponent as PlusIcon} from '../../../../assets/images/plus.svg'
import PackageCollapse from './packageCollapse'
import Tooltip from '../../../reuseComponent/Tooltips';
import {ReactComponent as ClearIcon} from '../../../../assets/images/clear.svg';
import {ReactComponent as BoxIcon} from '../../../../assets/images/box.svg';
import { customPalletMethod, inboundMethod, inventoryMethod } from './choseMethodCreate'
import { getPallet } from '../../../../services/inventory'

class SecondStepCreatePallet extends PureComponent {

    state = {
        isLoading: this.props.method !== customPalletMethod,
        packages: []
    }

    componentDidMount() {
        const {replaceBoxes, boxesId, method} = this.props
        if (method !== customPalletMethod) {
            const setState = (values) => this.setState(values)
            const {ids} = this.props
            if(method === inboundMethod) {
                axios.all(ids.map(id => getCloseBoxesByPoId(id)))
                  .then(axios.spread(function () {
                      const args = Array.from(arguments).filter(res => res.data.length > 0).map(res => res.data).flat(1)
                      if (boxesId.length > 0) {
                          let resBoxes = boxesId.filter(boxId => {
                              let isExist = false
                              args.forEach(pack => {
                                  pack.boxes.forEach(box => {
                                      if (box.id === boxId)
                                          isExist = true
                                  })
                              })
                              return isExist
                          })
                          replaceBoxes(resBoxes)
                      }

                      setState({
                          packages: args,
                          isLoading: false
                      })
                  }))
                  .catch(err => notify('error', 'Oops something went wrong'))
            }else{
                axios.all(ids.map(id => getPallet(id)))
                    .then(axios.spread(function(){
                        const args = Array.from(arguments).map(res => res.data.data)
                        if (boxesId.length > 0) {
                            let resBoxes = boxesId.filter(boxId => {
                                let isExist = false
                                args.forEach(pack => {
                                    pack.boxes.forEach(box => {
                                        if (box.id === boxId)
                                            isExist = true
                                    })
                                })
                                return isExist
                            })
                            replaceBoxes(resBoxes)
                        }
                        setState({
                            packages: args,
                            isLoading: false
                        })
                    }))
            }
        }
    }

    render() {
        const {changeStep, boxesId, handleCancel, handleChange, createBox, removeBox, newBoxes, method, isCustom} = this.props
        const {isLoading, packages} = this.state

        return (
            <div className="step second-step">
                {isLoading ? <Preloader/> :
                    <>
                        <div className="title-step">
                            {/*<span>{method === 'customPallet' ? 'Step 1' : 'Step 2'}</span>*/}
                            <span>Step 2</span>

                            <div className="footer-step">
                                {/*{method !== customPalletMethod && <Button onClick={() => changeStep(1)}>*/}
                                {/*    <ArrowLeftIcon className="standart-svg"/> Back*/}
                                {/*</Button>*/}
                                {/*}*/}
                                {!isCustom && <Button onClick={() => changeStep(1)}>
                                    <ArrowLeftIcon className="standart-svg"/> Back
                                </Button>}
                                <Button className="cancel" onClick={handleCancel}>Cancel</Button>
                                <Button
                                    onClick={() => changeStep(3)}
                                    disabled={method !== customPalletMethod ?
                                        boxesId.length === 0
                                        : newBoxes.length === 0}
                                >
                                    <ArrowRightIcon className="standart-svg"/> Next
                                </Button>
                            </div>
                        </div>
                        <div className="subtitle-step title-step-label">
                            {method !== customPalletMethod ? 'Select packs' : 'Add packs'}
                        </div>

                        <div className="content-step">
                            {method !== customPalletMethod &&
                            <>
                                {packages.length > 0
                                && packages
                                    .filter(pack => pack.boxes.length > 0)
                                    .map(pack => <PackageCollapse
                                        isPallets={method === inboundMethod}
                                        method={method}
                                        key={method === inboundMethod ? pack.orderNumber + pack.shortName : pack.id}
                                        pack={pack}
                                        boxesId={boxesId}
                                        handleChange={handleChange}

                                    />)}
                                {/*{packages.length === 0 && <p className="no-available-boxes">No data to display</p>}*/}
                            </>
                            }
                            <div className="boxes-wrapper">
                                {newBoxes.map(box => (
                                    <div
                                        className={`box__wrap`}
                                        key={box.id}
                                        // onClick={() => handleChange(box.id)}
                                    >
                                        <div className="icon-wrap icon-delete">
                                            <Tooltip text="Delete">
                                                <ClearIcon onClick={() => removeBox(box.id)} className="standart-svg"/>
                                            </Tooltip>
                                        </div>
                                        <div className="name-box">{box.name}</div>
                                        <div className="items-count">

                                        </div>
                                        <div className="box-bg">
                                            <BoxIcon/>
                                        </div>
                                    </div>
                                ))}
                                <Button onClick={createBox} className='add-box-btn'>
                                    <PlusIcon className="standart-svg"/>
                                    Add pack
                                </Button>
                            </div>

                            {/*<Button >*/}
                            {/*<AddIcon className="standart-svg"/> Create box*/}
                            {/*</Button>*/}
                        </div>


                    </>
                }
            </div>
        )
    }
}

export default SecondStepCreatePallet