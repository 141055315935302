import {USERS} from '../constants'
import adminInstance from '../components/instances/admininstance'
import {history} from '../history'
import {url} from '../constants'
import {convertToFormData} from '../reuseFunctions/objectToFormData'
import axios from 'axios'
import {notify} from '../components/reuseComponent/toast'
import {getLanguagesListService, getUsersForAllocationService} from "../services/users";
import {defaultServerError} from "../reuseFunctions/toasts";


export const loadingAct = (loading) => ({
    type: USERS.IS_LOADING_USER,
    payload: loading
})

const setloadingLanguagesList = loading => ({
    type: USERS.IS_LOADING_LANGUAGES_LIST,
    payload: loading
});

const setLoadingUsersForAllocationAction = loading => ({
    type: USERS.IS_LOADING_USERS_FOR_ALLOCATION,
    payload: loading
});

const setUsersActions = users => ({
    type: USERS.SET_USERS,
    payload: users
})

const setLanguagesListAciton = languages => ({
    type: USERS.SET_LANGUAGES_LIST,
    payload: languages
});

export const setUsersCustomersCount = count => ({
    type: USERS.SET_USER_CUSTOMERS_COUNT,
    payload: count
});

export const getLanguagesList = () => dispatch => {
    dispatch(setloadingLanguagesList(true))

    getLanguagesListService()
        .then(res => {
            dispatch(setLanguagesListAciton(res.data))
            dispatch(setloadingLanguagesList(false))
        })
        .catch(err => defaultServerError())

}

export const getUsersForAllocation = () => dispatch => {
    dispatch(setLoadingUsersForAllocationAction(true))
    getUsersForAllocationService()
        .then(res => {
            console.log(res.data);
            console.log(JSON.parse(res.data));
            // dispatch(setUsersForAllocation(res.data && typeof res.data === 'string' ? JSON.parse(res.data) : res.data));
            dispatch(setLoadingUsersForAllocationAction(false))
        })
        .catch(err => defaultServerError())
}

export const getUsers = () => {
    return dispatch => {
        dispatch(loadingAct(true))
        adminInstance.get(`${url}/user`)
            .then(res => {
                dispatch(setUsersActions(res.data.users))
                dispatch(loadingAct(false))
            })
            .catch(err => console.log(err))
    }
}

export const getCurrentUser = () => {
    return (dispatch) => {
        dispatch({
            type: USERS.CURR_USER_REQUEST,
            payload: true
        })
        axios.all([adminInstance.get(`${url}/user/privileges`), adminInstance.get(`${url}/user/details`)])
            .then(axios.spread((resRoles, resUser) => {
                dispatch({
                    type: USERS.CURR_USER_RECEIVED,
                    payload: {
                        user: {
                            ...resRoles.data,
                            userDetails: resUser.data
                        },
                        loading: false
                    }
                })
            }))
            .catch(err => {
                history.push('/login')
            })

    }
}

export const refreshRequestUser = (user) => {
    return dispatch => {
        dispatch({
            type: USERS.REQUEST_REFRESH_TOKEN,
            payload: true
        })
        const hash = window.btoa('wcs:3A37A940F361D2148CCCF2D5C7C6B605')
        let data = {
            grant_type: 'refresh_token',
            refresh_token: user.refresh_token
        }
        data = convertToFormData(data)
        return axios.post(`${url}/oauth/token`, data, {
            headers: {
                'Authorization': `Basic ${hash}`
            }
        })
    }
}

const loadingUserByIdAction = isLoading => ({
    type: USERS.IS_LOADING_USER_BY_ID,
    payload: isLoading
})

const setUserById = user => ({
    type: USERS.SET_USER_BY_ID,
    payload: user
})

export const getUserById = (id) => {
    return dispatch => {
        dispatch(loadingUserByIdAction(true))
        adminInstance.get(`${url}/user/${id}`)
            .then(res => {
                dispatch(setUserById(res.data))
                dispatch(loadingUserByIdAction(false))
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
                dispatch(loadingUserByIdAction(false))
            })

    }
}

export const editUserById = (id, values) => {
    return dispatch => {
        dispatch(loadingUserByIdAction(true))
        return new Promise((resolve, reject) => {
            adminInstance.put(`${url}/user/${id}`, values)
                .then(res => {
                    notify('success', 'User successfully edited')
                    dispatch(loadingUserByIdAction(false))
                    // history.push('/atw/settings/users')
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response && err.response.status === 400) {
                        notify('error', 'Initials already exist')
                    }
                    else {
                        notify('error', 'Oops something went wrong')
                    }
                    dispatch(loadingUserByIdAction(false))

                })
        })

    }
}

const loadingRolesAction = isLoading => ({
    type: USERS.IS_LOADING_ROLES,
    payload: isLoading
})

const setRolesAction = roles => ({
    type: USERS.SET_ROLES_USERS,
    payload: roles
})

export const getRolesAction = () => {
    return dispatch => {
        dispatch(loadingRolesAction(true))
        adminInstance.get(`${url}/role`)
            .then(res => {
                dispatch(setRolesAction(res.data))
                dispatch(loadingRolesAction(false))
            })
            .catch(err => {
                dispatch(loadingRolesAction(false))
                notify('error', 'Oops something went wrong')
            })
    }
}



const setPoOverview = overview => ({
    type: USERS.SET_PO_OVERVIEW,
    payload: overview
})

export const getPoOverview = () => dispatch => {
    adminInstance.get(`${url}/user/setting`)
        .then(res => {
            dispatch(setPoOverview(res.data))
        })
        .catch(err => {
            console.log(err)
        })
}