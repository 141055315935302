import React , { PureComponent } from 'react'
import { connect } from 'react-redux'
import ToolTip from '../../../../reuseComponent/Tooltips'

class StatusComponent extends PureComponent{

    state = {
        isShow: false
    }

    refNode = React.createRef()

    handleClickOutSide = (event) => {
        if (this.refNode.current && !this.refNode.current.contains(event.target)) {
            this.setState({
                isShow: false
            })
        }
    }

    componentDidMount(){
        document.addEventListener('click',this.handleClickOutSide)
    }

    componentWillUnmount(){
        document.removeEventListener('click',this.handleClickOutSide)
    }

    render(){
        const { isShow } = this.state
        const { activeStatus,statuses,showConfirm,showConfirmWithReason } = this.props
        return (
            <div className={`wrap-statuses ${isShow ? 'open' : ''}`} ref={this.refNode}>
                <ToolTip text={activeStatus ? activeStatus.name : ''}>
                   <span
                       onClick={e => {
                           e.stopPropagation()
                           this.setState({
                               isShow: true
                           })
                       }}
                       className="active-status"
                       dangerouslySetInnerHTML={{__html: activeStatus ? activeStatus.icon : ''}}
                   >
                   </span>
                </ToolTip>
                <ul className="list-statuses">
                    {statuses.map(status => {
                        return <li onClick={e => {
                            e.stopPropagation()
                            if(activeStatus && activeStatus.id !== status.id && status.alias !== 'canceled')
                                showConfirm(status)
                            else if(activeStatus && activeStatus.id !== status.id && status.alias === 'canceled')
                                showConfirmWithReason(status)
                            else
                                this.setState({
                                    isShow: false
                                })
                        }} key={status.id}>
                            <span className="status-icon"></span>
                            <span className="status-name">{status.name}</span>
                        </li>
                    })}
                </ul>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        statuses: state.purchaseOrdersReducer.statuses
    }
}

export default connect(mapStateToProps,null)(StatusComponent)
