import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {Router, Route} from 'react-router-dom'
import {Provider} from 'react-redux'
import {history} from './history'
import store from './store'
import * as serviceWorker from './serviceWorker'
import { SERVICE_WORKER } from './constants'

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Route path="/" component={App}/>
        </Router>
    </Provider>
    , document.getElementById('root'));

serviceWorker.register({
    onSuccess: () => store.dispatch({type: SERVICE_WORKER.SW_INIT}),
    onUpdate: (registration) => store.dispatch({type: SERVICE_WORKER.SW_UPDATE,payload: registration})
})

