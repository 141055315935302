import React, {PureComponent} from 'react'
import Preloader from '../../../../reuseComponent/preloader/preloader'
import {getTaskById} from '../../../../../services/tasks'
import FormikWrapper from '../formik-wrapper'
import moment from 'moment'
import {defaultTaskOption} from '../../list'
import TaskHistory from '../../history'


class EditTask extends PureComponent {

    state = {
        isLoading: true,
        task: {},
        history: []
    }

    componentDidUpdate(prevProps) {
        if (this.props.idTask !== prevProps.idTask || this.props.isCompleteTask)
            this.setState({
                isLoading: true
            }, this.getTaskInfo)
    }

    componentDidMount() {
        this.getTaskInfo()
    }

    getTaskInfo = () => {
        this.setState({
            isLoading: true
        },() => {
            const {idTask} = this.props
            idTask
            && getTaskById(idTask)
                .then(res => {
                    this.setState({
                        isLoading: false,
                        history: res.data.history,
                        task: {
                            taskTemplate: defaultTaskOption,
                            title: res.data.title,
                            dueDate: res.data.dueDate ? moment(res.data.dueDate).format('DD.MM.YYYY') : '',
                            responsible: res.data.executor ? {
                                value: res.data.executor.id,
                                label: `${res.data.executor.name} ${res.data.executor.secondName}`,
                                type: 'User'
                            } : res.data.department ? {
                                value: res.data.department.id,
                                label: res.data.department.name,
                                type: 'Department'
                            } : null,
                            order: res.data.order ? {
                                value: res.data.order.id,
                                label: 'PO#' + res.data.order.orderNumber
                            } : null,
                            poId: res.data.order ? res.data.order.id : '',
                            description: res.data.description,
                            executorId: res.data.executor ? res.data.executor.id : '',
                            departmentId: res.data.department ? res.data.department.id : '',
                            inbound: res.data.inbound,
                            transport: res.data.transport,
                            accounting: res.data.accounting,
                        }
                    })
                })
        })

    }

    render() {

        const {isLoading, task, history} = this.state
        const {handleCancel, handleSubmit, createFromPo, onlyChangeResponsive, isView, isDetails = false} = this.props
        return (
            isLoading
                ? <Preloader/>
                : <>
                    <FormikWrapper
                        isView={isView}
                        createFromPo={createFromPo}
                        initValues={task}
                        isDetails={isDetails}
                        handleCancel={handleCancel}
                        onlyChangeResponsive={onlyChangeResponsive}
                        handleSubmit={values => createFromPo ? handleSubmit(values) : handleSubmit(values,this.getTaskInfo)}
                    />
                    {!createFromPo && <TaskHistory
                        history={history}
                    />
                    }
                </>
        )
    }
}

export default EditTask