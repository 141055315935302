import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import Select from '../../../../../reuseComponent/Select/select-form'
import Tooltip from '../../../../../reuseComponent/Tooltips'

const checkIfErrorExist = (errors,touched,index,field) => {
    return errors.related
        && errors.related[index]
        && errors.related[index][field]
        && touched.related
        && touched.related[index]
        && touched.related[index][field]
}

const getErrorLabel = (errors,touched,index,field) => {
    return errors.related
        && errors.related[index]
        && errors.related[index][field] ? errors.related[index][field] : ''
}

const RelatedRow = ({gendersValues,commoditiesValues,removeRow,index,setFieldValue,related,errors,touched}) => {
    return (
        <div className="related-row">
            <Form.Group className="horizontal">
                <Form.Label className="with-select">Commodity</Form.Label>
                <div className="input-item">
                <Select
                    name="commodity"
                    options={commoditiesValues}
                    value={commoditiesValues.find(commodity => parseInt(related[index].commodity) === commodity.value)}
                    onChange={obj => setFieldValue(`related[${index}].commodity`,obj.value)}
                    isInvalid={checkIfErrorExist(errors,touched,index,'commodity')}
                    errorLabel={getErrorLabel(errors,touched,index,'commodity')}
                />
                </div>
            </Form.Group>
            <Form.Group className="horizontal">
                <Form.Label className="with-select">Gender</Form.Label>
                <div className="input-item">
                <Select
                    name="gender"
                    value={gendersValues.find(gender => parseInt(related[index].gender) === gender.value)}
                    onChange={obj => setFieldValue(`related[${index}].gender`,obj.value)}
                    options={gendersValues}
                    isInvalid={checkIfErrorExist(errors,touched,index,'gender')}
                    errorLabel={getErrorLabel(errors,touched,index,'gender')}
                />
                </div>
            </Form.Group>
            <Tooltip text='Delete condition'>
                <div className="remove-row" onClick={() => removeRow(index)}>
                    <svg viewBox="0 0 24 24">
                        <path fill="#000000" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
                    </svg>
                </div>
            </Tooltip>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        gendersValues: state.commodityGroupsReducer.genders,
        commoditiesValues: state.commodityGroupsReducer.commodities
    }
}

export default connect(mapStateToProps,null)(RelatedRow)