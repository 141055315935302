import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Formik} from 'formik'
import {schema} from './schema'
import {editProductInBox, getOpenBoxes,checkEditProduct} from '../../../../../../services/products'
import {getPackagesRequest} from '../../../../../../actions/stowing'
import { getBoxByPackage } from "../../../../../../actions/inboundingReport";
import FormBody from './bodyForm'
import {notify} from '../../../../../reuseComponent/toast'
import Modal from '../../../../../reuseComponent/modal/modalWithChildren'
import { Button } from 'react-bootstrap'
import {checkPackageName} from '../../../../../../reuseFunctions/checkPackageName'
import {findPermInArray} from '../../../../../../reuseFunctions/checkPermission'
import { permEditInbounding } from '../../../../../../permissions/productOverview'
import { formatNumber } from '../../../../../../reuseFunctions/formatValue'
import { getProducts } from '../../../../../../actions/dividing'


class EditForm extends PureComponent {

    state = {
        values: [],
        showModal: false,
        modalInfo: {}
    }

    generateTextToConfirmModal = async (values) => {
        const {newBoxId, newPackage, items,workplace} = values
        const {box, activePackage : {id : packageId}, product: {itemsCount}, details: {inboundType},
            packages} = this.props
        const resObject = {
            text: '',
            withConfirm: true,
            overSteppedText: ''
        }
        // console.log(box.id,newBoxId,packageId,newPackage)
        if(inboundType === 'Deep'){
            await checkEditProduct(values)
              .then(res => {
                  if(res.data.status !== 200){
                      resObject.overSteppedText = 'You are going to put more items than required in this Package.'
                  }
              })
        }
        if (box.id !== newBoxId || packageId !== newPackage) {
            if (items > itemsCount) {
                resObject.withConfirm = false
                resObject.text = "Can't move more items than available!"
                return resObject
            }
            let boxes = []
            await this.getOpenBoxes(newPackage).then(res => {
                boxes = res
            })
            const findPackage = packages.find(pack => pack.value === newPackage)
            const findBox = newBoxId > 0 && boxes.find(box => box.value === newBoxId);
            // console.log(findBox)
            resObject.text = `Sure you want to move ${items} items to ${findPackage ? findPackage.label : ''}-${workplace}-${findBox ? findBox.label : 'New box'}?`
        }
        else if (items !== itemsCount) {
            resObject.text = 'Sure you want to change quantity of Items?'
        }
        return resObject

    }

    getOpenBoxes = async (idPackage) => {
        let res = await getOpenBoxes(idPackage)
        return Promise.resolve(res.data.boxes.length > 0
            ? res.data.boxes
                .map(box => ({
                    label: `BOX-${box.name}`,
                    value: box.id,
                    name: box.name,
                    workplace: box.workplace
                })) : [{
                    label: `New box`,
                    value: 0,
                }])
    }

    handleConfirm = () => {
        const {getPackages, activePackage, getBoxes, details: {id}, handleClose} = this.props
        const {values} = this.state;
        handleClose()
        const newValue = {
            ...values,
            rrp: values.rrp === '' ? 0 : parseFloat(formatNumber(values.rrp.replace(',','.')))
        }
        editProductInBox(newValue)
            .then(res => {
                getPackages(id)
                if (parseInt(values.packageId) === parseInt(activePackage.id)) {
                    getBoxes(activePackage.id)
                }
                notify('success', 'Product successfully edited')
            })
            .catch(err => {
                notify('error', 'Oops something went wrong')
            })
    }

    handleSubmit = (values) => {
        const {activePackage,getBoxes,getPackages,box, handleClose,product,details: { id }} = this.props
        // delete values.ean
        // delete values.styleNumber
        const newValue = {
            ...values,
            rrp: values.rrp === '' ? 0 : parseFloat(formatNumber(values.rrp.replace(',','.')))
        }
        this.generateTextToConfirmModal(newValue).then(res => {
            if (!res.text){
                handleClose(newValue)
                editProductInBox(newValue)
                    .then(res => {
                        getPackages(id)
                        if (parseInt(values.packageId) === parseInt(activePackage.id)) {
                            getBoxes(activePackage.id)
                        }
                        notify('success', 'Product successfully edited')
                    })
                    .catch(err => {
                        notify('error', 'Oops something went wrong')
                    })
            }
            else
                this.setState({
                    values: values,
                    showModal: true,
                    modalInfo: res
                })
        })

    }

    handleHideModal = () => this.setState({values: {}, showModal: false, modalInfo: {}})

    render() {
        const {showModal, modalInfo} = this.state
        const {product, box, activePackage, handleClose, roles} = this.props
        return (
            <>
                {product && <Formik
                    onSubmit={this.handleSubmit}
                    validationSchema={schema}
                    initialValues={{
                        ean: product.product.ean,
                        styleNumber: product.product.styleNumber ? product.product.styleNumber : '',
                        boxId: box.id,
                        oldWorkplaceId: box.wpId,
                        brand: product.product.brand ? product.product.brand : '',
                        cg1: product.product.cg1 ? product.product.cg1 : '',
                        cg2: product.product.cg2 ? product.product.cg2 : '',
                        cg3: product.product.cg3 ? product.product.cg3 : '',
                        bid: product.product.bid ? product.product.bid : '',
                        items: product.itemsCount,
                        newBoxId: box.id,
                        newPackage: activePackage.id,
                        packageId: activePackage.id,
                        productId: product.product.id,
                        season: product.product.season ? product.product.season : '',
                        size: product.product.size ? product.product.size : '',
                        quality: product.product.quality ? product.product.quality : '',
                        workplaceId: box.wpId,
                        workplace: box.workplace,
                        confirmOverstepped: true,
                        rrp: product.product.rrp ? String(product.product.rrp).replace('.',',').trim() : ''
                    }}
                    render={props => <FormBody
                        {...props}
                        handleClose={handleClose}
                    />}
                />
                }
                {findPermInArray(roles, permEditInbounding) && <Modal
                    classNameModal="confirm-modal-edit-products"
                    open={showModal}
                    handleClose={this.handleHideModal}
                    isFooter={false}
                    options={{
                        centered: true
                    }}
                >
                    <>
                        <div>
                            {modalInfo.overSteppedText && modalInfo.withConfirm && <h6 className="center-h">{modalInfo.overSteppedText}</h6>}
                            <h6 className="center-h">{modalInfo.text}</h6>
                        </div>
                        <div className="form-group footer-form footer-form-custom">
                            <Button onClick={this.handleHideModal}>Cancel</Button>
                            {modalInfo.withConfirm && <Button onClick={this.handleConfirm}>Confirm</Button>}
                        </div>
                    </>
                </Modal>}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        activePackage: state.inboundingReportReducer.activePackage,
        roles: state.rolesReducer.roles,
        details: state.purchaseOrdersReducer.detailsPurchase,
        packages: state.inboundingReportReducer.packages.map(pack => ({
            value: pack.id,
            label: checkPackageName(pack)
        })),
    }
}

export default connect(mapStateToProps, {
    getPackages: getPackagesRequest,
    getBoxes: getBoxByPackage,
})(EditForm)