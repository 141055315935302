import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Form,Card,Button } from 'react-bootstrap'
import { FieldArray } from 'formik'
import { changeRoute } from '../../../../../reuseFunctions/changeRoute'
import { history } from '../../../../../history'
import RelatedRow from './related-row'
import Tooltip from '../../../../reuseComponent/Tooltips';
import {ReactComponent as DeleteIcon} from '../../../../../assets/images/delete.svg';
import {ReactComponent as AddIcon} from '../../../../../assets/images/plus.svg';
import AutosizeInput from "react-input-autosize";


class BodyForm extends PureComponent{
    render(){
        const {
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue
        } = this.props
        return(
            <Card>
                <Card.Body>
                    <Form noValidate onSubmit={handleSubmit} className="cg3-form">
                        <Form.Group className="horizontal">
                            <Form.Label>Title</Form.Label>
                            <div className="input-item">
                                <AutosizeInput
                                    type="text"
                                    placeholder="Enter title"
                                    className="auto-resize-wrapper"
                                    name="value"
                                    value={values.value}
                                    onChange={event => {
                                        setFieldValue("value", event.target.value);
                                    }}
                                    onBlur={e => setFieldValue('value', e.target.value.trim())}
                                />
                                <span className="error-label">{touched.value && errors.value}</span>
                            </div>
                        </Form.Group>
                        <Form.Group className="horizontal">
                            <Form.Label>Inputs</Form.Label>
                            <div className="input-item">
                            <FieldArray
                                name="inputs"
                                render={arrayHelpers => (
                                    <div className="inputs-wrapper">
                                        {values.inputs
                                        && values.inputs.length > 0
                                        && values.inputs.map((input, index) => <div className="input-field-wrapper" key={index}>
                                            <div className="field">
                                                <AutosizeInput
                                                    type="text"
                                                    placeholder="Enter input"
                                                    className="auto-resize-wrapper"
                                                    name={`inputs.${index}`}
                                                    value={values.inputs[index]}
                                                    onChange={handleChange}
                                                />
                                                <span className="error-label">{touched.inputs && touched.inputs[index] &&
                                                errors.inputs && errors.inputs[index]}</span>
                                                {/*<Form.Control*/}
                                                {/*    name={`inputs.${index}`}*/}
                                                {/*    value={values.inputs[index]}*/}
                                                {/*    onChange={handleChange}*/}
                                                {/*    isInvalid={errors.inputs && errors.inputs[index] && touched.inputs && touched.inputs[index]}*/}
                                                {/*/>*/}
                                                {/*<Form.Control.Feedback type="invalid">{errors.inputs && errors.inputs[index]}</Form.Control.Feedback>*/}
                                            </div>
                                            <Tooltip text="Delete">
                                                <DeleteIcon onClick={() => arrayHelpers.remove(index)} className="standart-svg"/>
                                            </Tooltip>
                                        </div>)}
                                        <Button onClick={() => arrayHelpers.push('')}>
                                            <AddIcon className="standart-svg"/>
                                            Add
                                        </Button>

                                    </div>
                                )}
                            />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="standard-label">Conditions</Form.Label>
                            <FieldArray
                                name="related"
                                render={arrayHelpers => {
                                    return <div className="wrapper-related-params">
                                        {values.related && values.related.length > 0 &&
                                            values.related.map((related,index) => <RelatedRow
                                                key={index}
                                                index={index}
                                                removeRow={arrayHelpers.remove}
                                                setFieldValue={setFieldValue}
                                                related={values.related}
                                                touched={touched}
                                                errors={errors}
                                            />)
                                        }
                                        <Button variant="primary" type="button"
                                                onClick={() => arrayHelpers.push({
                                                    gender: '',
                                                    commodity: ''
                                                })}>
                                            <AddIcon className="standart-svg"/>
                                            Add
                                        </Button>

                                    </div>
                                }}
                            />
                            {touched.related && typeof errors.related === 'string' && <span className="error-label">{errors.related}</span>}
                        </Form.Group>
                        <Form.Group className="footer-form footer-form-custom">
                            <Button variant="secondary"
                                    onClick={() => changeRoute('/atw/dictionaries/cg3',history)}>Cancel</Button>
                            <Button variant="primary" type="submit">Save</Button>        
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        )
    }
}

export default connect(null,null)(BodyForm)