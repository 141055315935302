import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { PALLET_PLACE_STROKE } from './constants'
import { findPermInArray } from '../../../../reuseFunctions/checkPermission'
import { permEditInventory } from '../../../../permissions/inventory'
import { formatCoordinatesToView } from '../../../../reuseFunctions/mapUtils'
import { SlotItemIcon } from './SlotItemIcon'
import {Link} from "react-router-dom";

const SlotItem = ({
                    slotItem,
                    sizeX,
                    sizeY,
                    handleOpenDetailModal,
                    handleOpenCreateModal,
                    handleOpenMoveModal,
                    handleOpenHoverModal,
                    setSelectedPallet,
                    width,
                    height,
                    setDraggingCoordinates,
                    scale,
                    maxX,
                    maxY,
                    isActivatedSearch
                  }) => {

  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    active: false,
    offset: {
    }
  })

  const positionRef = useRef(null)

  const roles = useSelector(state => state.rolesReducer.roles)

  const hasPermToEdit = useMemo(() => {
    return findPermInArray(roles, permEditInventory)
  }, [roles])

  const handlePointerDown = useCallback(e => {
    handleOpenHoverModal(false);
    const el = e.target;
    const bbox = e.target.getBoundingClientRect();
    const x = e.clientX - bbox.left;
    const y = e.clientY - bbox.top;

    if(hasPermToEdit){
      el.setPointerCapture(e.pointerId);
      setPosition({
        ...position,
        active: true,
        offset: {
          x,
          y
        }
      });
    }

    setSelectedPallet({
      ...slotItem.pallet,
      location: {
        id: slotItem.id,
        coordinateX: slotItem.coordinateX,
        coordinateY: slotItem.coordinateY,
      }
    })
  }, [hasPermToEdit, setSelectedPallet, slotItem, position]);

  const handlePointerMove = useCallback(e => {
    if (position.active) {
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      const y = e.clientY - bbox.top;
      const newX = position.x - (position.offset.x - x)/scale;
      const newY = position.y - (position.offset.y - y)/scale;

      if(newX > sizeX && newX < width - sizeX && newY > sizeY && newY < height - sizeY)
        setPosition({
          ...position,
          x: (Math.floor((newX - sizeX/2)/sizeX) + 1) * sizeX + 1,
          y: (Math.floor((newY - sizeY/2)/sizeY) + 1) * sizeY + 1,
        });
        positionRef.current = {
          x: (Math.floor((newX - sizeX/2)/sizeX) + 1) * sizeX + 1,
          y: (Math.floor((newY - sizeY/2)/sizeY) + 1) * sizeY + 1,
        }
        setDraggingCoordinates({
          x: (Math.floor((newX - sizeX/2)/sizeX) + 1) * sizeX + 1,
          y: (Math.floor((newY - sizeY/2)/sizeY) + 1) * sizeY + 1,
        })
    }else{
      handleOpenHoverModal(
          true,
          slotItem.pallet ? slotItem.pallet.id : null,
          slotItem,
          {
            left: (slotItem.coordinateX*sizeX + 1),
            top: ((slotItem.coordinateY)*sizeY + 1)*scale,
          }
      );
    }
  }, [slotItem, position, width, height, sizeY, sizeX, setDraggingCoordinates,scale]);

  const handlePointerUp = useCallback(e => {
    if (!slotItem.pallet || !positionRef.current || (positionRef.current
      && positionRef.current.x === (slotItem.coordinateX-1)*sizeX + 1
      && positionRef.current.y === (slotItem.coordinateY-1)*sizeY + 1)){
      setDraggingCoordinates(null)
      setPosition({
        ...position,
        x: (slotItem.coordinateX-1)*sizeX + 1,
        y: (slotItem.coordinateY-1)*sizeY + 1,
        active: false
      });
      if (slotItem.locationType.slot) {
        if (slotItem.pallet && slotItem.pallet.id) {
          handleOpenDetailModal(slotItem.pallet)
        } else {
          if(hasPermToEdit) {
            handleOpenCreateModal({
              value: slotItem.id,
              label: formatCoordinatesToView(`${slotItem.coordinateX}-${slotItem.coordinateY}`)
            })
          }
        }
      }
    }else{
      setDraggingCoordinates(null)
      handleOpenMoveModal(Math.floor(positionRef.current.x/sizeX + 2)  , Math.floor(positionRef.current.y/sizeY) + 1)

      setPosition({
        ...position,
        x: (slotItem.coordinateX-1)*sizeX + 1,
        y: (slotItem.coordinateY-1)*sizeY + 1,
        active: false
      });
      positionRef.current = null;
    }
  }, [hasPermToEdit, handleOpenCreateModal, handleOpenDetailModal, handleOpenMoveModal, position, slotItem, setDraggingCoordinates, sizeX, sizeY]);

  useEffect(() => {
    if(!hasPermToEdit){
      setDraggingCoordinates(null)
      setPosition({
        ...position,
        x: (slotItem.coordinateX-1)*sizeX + 1,
        y: (slotItem.coordinateY-1)*sizeY + 1,
        active: false
      });
      positionRef.current = null;
    }
  }, [hasPermToEdit, setDraggingCoordinates])

  useEffect(() => {
    setPosition({
      x: slotItem.locationType.slot ? (slotItem.coordinateX-1)*sizeX + 1 : (slotItem.coordinateX-1)*sizeX,
      y: slotItem.locationType.slot ? (slotItem.coordinateY-1)*sizeY + 1 : (slotItem.coordinateY-1)*sizeY,
      active: false,
      offset: {
      }
    })
  }, [slotItem, sizeX, sizeY])
  return (
    <>
    <g
      className={classNames("slot", {"with-active": hasPermToEdit ? slotItem.locationType.slot : slotItem.pallet, "is-dragging": position.active, "searched": !!slotItem.searched})}
      onPointerDown={e => slotItem.pallet ? handlePointerDown(e) : () => {}}
      onPointerUp={e => slotItem.locationType.slot ? handlePointerUp(e) : () => {}}
      onPointerMove={e => slotItem.pallet  ? handlePointerMove(e) : () => {}}
      onPointerOut={e => {
        handleOpenHoverModal(false)
      }}
    >
      <rect
        x={position.x*scale}
        y={position.y*scale}
        width={slotItem.locationType.slot ? (sizeX - 2)*scale : sizeX*scale}
        height={slotItem.locationType.slot ? (sizeY - 2)*scale : sizeY*scale}
        fill={function (){
          if(!slotItem.locationType.slot || !slotItem.pallet){
            return slotItem.locationType.color
          }else{
            if(!isActivatedSearch || (isActivatedSearch && slotItem.searched)){
              switch (slotItem.pallet.palletStatus.name){
                case 'In Progress': {
                  return PALLET_PLACE_STROKE.IN_PROGRESS
                }
                case 'Sold': {
                  return PALLET_PLACE_STROKE.SOLD
                }
                case 'Pick': {
                  return PALLET_PLACE_STROKE.PICK
                }
                case 'Reserved': {
                  return PALLET_PLACE_STROKE.RESERVED
                }
                default: {
                  return slotItem.locationType.color
                }
              }
            }else{
              return slotItem.locationType.color
            }
          }
        }()}
        strokeWidth={slotItem.locationType.slot ? 1*scale : 0.5*scale}
        opacity={position.active ? 0 : (!slotItem.searched && isActivatedSearch) ? 0.5 : 1}
        stroke={function (){
          if(!slotItem.locationType.slot || !slotItem.pallet){
            return slotItem.locationType.color
          }else{
            if(!isActivatedSearch || (isActivatedSearch && slotItem.searched)) {
              switch (slotItem.pallet.palletStatus.name) {
                case 'In Progress': {
                  return PALLET_PLACE_STROKE.IN_PROGRESS
                }
                case 'Sold': {
                  return PALLET_PLACE_STROKE.SOLD
                }
                case 'Pick': {
                  return PALLET_PLACE_STROKE.PICK
                }
                case 'Reserved': {
                  return PALLET_PLACE_STROKE.RESERVED
                }
                default: {
                  return slotItem.locationType.color
                }
              }
            }else{
              return slotItem.locationType.color
            }
          }
        }()}
      />
      {(!isActivatedSearch || (isActivatedSearch && slotItem.searched)) && slotItem.pallet
      && slotItem.pallet.palletStatus
      && <>
        <svg
          x={(position.x + sizeX/8)*scale}
          y={(position.y + sizeY/8)*scale}
          width={(sizeX - 2)*scale/4*3}
          height={(sizeY - 2)*scale/4*3}
          viewBox="0 0 24 24">
            <SlotItemIcon
              statusName={slotItem.pallet.palletStatus.name}
              locationTypeColor={slotItem.locationType.color}
            />
        </svg>
      </>}
    </g>
      {position.active &&
      <g
        className={classNames("slot draggable", {"with-active": slotItem.locationType.slot})}
      >
        <rect
          x={((slotItem.coordinateX-1)*sizeX + 1)*scale}
          y={((slotItem.coordinateY-1)*sizeY + 1)*scale}
          width={(sizeX - 2)*scale}
          height={(sizeY - 2)*scale}
          fill="#3498DB"
        />
        {slotItem.pallet && slotItem.pallet.palletStatus && <svg
          x={((slotItem.coordinateX-1)*sizeX + 1 + sizeX/8)*scale}
          y={((slotItem.coordinateY-1)*sizeY + 1 + sizeY/8)*scale}
          width={(sizeX - 2)*scale/4*3}
          height={(sizeY - 2)*scale/4*3}
          viewBox="0 0 24 24">
          <SlotItemIcon
            statusName={slotItem.pallet.palletStatus.name}
          />
          {/*<path fill={PALLET_PLACE_STROKE.PRIMARY} d="M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M20,16H8V4H20V16M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16M18.53,8.06L17.47,7L12.59,11.88L10.47,9.76L9.41,10.82L12.59,14L18.53,8.06Z" />*/}
        </svg>}
      </g>
      }
    </>
  )
}

export { SlotItem }