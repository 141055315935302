import React, {PureComponent} from 'react'
import {Form} from 'react-bootstrap'
import Select from '../../../reuseComponent/Select/async-select'
import {getPosForPallets} from '../../../../services/purchaseOrders'
import {getPalletsList} from '../../../../services/inventory';
import {Button} from 'react-bootstrap'
import {ReactComponent as ArrowRightIcon} from '../../../../assets/images/arrow-right.svg'
import { customPalletMethod, inboundMethod, inventoryMethod } from './choseMethodCreate'
import classNames from 'classnames'

class FirstStepCreatePallet extends PureComponent {

    state = {
        isMenuOpen: false
    }

    generatePoOptions = orders => {
        return orders.map(order => ({
            value: order.id,
            label: <>
                {order.orderNumber}
                <div className="bids-option__wrapper">
                    {order.bids.map(bid => <div className="bid" key={bid.id}>
                        {(bid.commodity || bid.gender) && <>{bid.number}: {bid.commodity} {bid.commodity && bid.gender && ','} {bid.gender}</>}
                    </div>)
                    }
                </div>
            </>
        }))
    }

    generatePalletsOptions = pallets => {
        return pallets.map(pallet => ({
            value: pallet.id,
            label: <>
                Pallet #{pallet.id}
                <div className="bids-option__wrapper">
                    {pallet.info.brands && pallet.info.brands.length > 0 && <div className="bid">
                        <strong>Brands: </strong> {pallet.info.brands.join(',')}
                    </div>
                    }
                    {pallet.info.cg1 && pallet.info.cg1.length > 0 && <div className="bid">
                        <strong>CG1: </strong> {pallet.info.cg1.join(',')}
                    </div>
                    }
                    {pallet.info.cg2 && pallet.info.cg2.length > 0 && <div className="bid">
                        <strong>CG2: </strong> {pallet.info.cg2.join(',')}
                    </div>
                    }
                    {pallet.info.cg3 && pallet.info.cg3.length > 0 && <div className="bid">
                        <strong>CG3: </strong> {pallet.info.cg3.join(',')}
                    </div>
                    }
                </div>
            </>
        }))
    }

    getPosList = inputValue => {
        return new Promise(resolve => {
             getPosForPallets({
                page: 0,
                limit: 25,
                status: '1,2',
                search: inputValue
            })
              .then(res => {
                  resolve(this.generatePoOptions(res.data.data.content))
              })
        })
    }

    getPalletsList = inputValue => {
        return new Promise(resolve => {
           getPalletsList({
                page: 0,
                limit: 40,
                search: `s:${inputValue}`
            })
              .then(res => {
                  resolve(this.generatePalletsOptions(res.data.data.content))
              })
        })
    }

    // getOptionsList = inputValue => {
    //     return new Promise(resolve => {
    //         const {method} = this.props
    //         const isInbound = method === inboundMethod
    //         isInbound && getPosForPallets({
    //             page: 0,
    //             limit: 25,
    //             status: '1,2',
    //             search: inputValue
    //         })
    //             .then(res => {
    //                 resolve(this.generatePoOptions(res.data.data.content))
    //             })
    //         !isInbound && getPalletsList({
    //             page: 0,
    //             limit: 40,
    //             search: `s:${inputValue}`
    //         })
    //             .then(res => {
    //                 resolve(this.generatePalletsOptions(res.data.data.content))
    //             })
    //     })
    // }

    render() {
        const {handleCancel, handleChange, pos, changeStep, method, chooseMethod} = this.props
        const {isMenuOpen} = this.state
        const isInbound = method === inboundMethod
        const isInventory = method === inventoryMethod
        const isCustom = method === customPalletMethod
        return (
            <div className={`step first-step ${!isInbound ? 'with-header-fix' : ''}`}>
                <div className="title-step">
                    <span>Step 1</span>
                    <div className="footer-step">
                        <Button className="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            onClick={() => changeStep(2)}
                            disabled={isCustom ? false : pos.length === 0}
                        >
                            <ArrowRightIcon className="standart-svg"/> Next
                        </Button>
                    </div>
                </div>
                <div className="method-buttons-wrapper">
                    <Button
                      variant="primary"
                      className={classNames("header-tab-button", {"cancel": method !== inboundMethod})}
                      onClick={() => chooseMethod(inboundMethod)}
                    >
                        Inbound
                    </Button>
                    <Button
                      variant="primary"
                      className={classNames("header-tab-button", {"cancel": method !== inventoryMethod})}
                      onClick={() => chooseMethod(inventoryMethod)}
                    >
                        Inventory
                    </Button>
                    <Button
                      variant="primary"
                      className={classNames("header-tab-button", {"cancel": method !== customPalletMethod})}
                      onClick={() => chooseMethod(customPalletMethod)}
                    >
                        Custom Pallet
                    </Button>
                </div>

                {isInbound && <div className="subtitle-step">
                    Note! Only inbounded POs are available
                </div>}

                {/*<div className="content-step">*/}
                {isInbound && <Form.Group className="horizontal">
                    <Form.Label className="with-multiple-select">{isInbound ? 'POs' : 'Pallets'}</Form.Label>
                    <div className="input-item">
                    <Select
                        onFocus={() => this.setState({
                            isMenuOpen: true
                        })}
                        onBlur={() => {
                            this.setState({
                                isMenuOpen: false
                            })
                        }}
                        blurInputOnSelect={false}
                        isFocused={true}
                        isMulti={true}
                        menuIsOpen={isMenuOpen}
                        defaultOptions={true}
                        value={pos}
                        onChange={handleChange}
                        loadOptions={isInbound ? this.getPosList : this.getPalletsList}
                    />
                    </div>
                </Form.Group>}
                {isInventory && <Form.Group className="horizontal">
                    <Form.Label className="with-multiple-select">{isInbound ? 'POs' : 'Pallets'}</Form.Label>
                    <div className="input-item">
                        <Select
                          onFocus={() => this.setState({
                              isMenuOpen: true
                          })}
                          onBlur={() => {
                              this.setState({
                                  isMenuOpen: false
                              })
                          }}
                          blurInputOnSelect={false}
                          isFocused={true}
                          isMulti={true}
                          menuIsOpen={isMenuOpen}
                          defaultOptions={true}
                          value={pos}
                          onChange={handleChange}
                          loadOptions={isInbound ? this.getPosList : this.getPalletsList}
                        />
                    </div>
                </Form.Group>}
                {/*</div>*/}
            </div>
        )
    }
}

export default FirstStepCreatePallet