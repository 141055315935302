import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Preloader from "../../../reuseComponent/preloader/preloader";
import ActionComponent from "./action";
import moment from "moment";
import CommentComponent from "./comment-item/index";
import FileItemComponent from "./file-item";

class HistoryList extends PureComponent {
  state = {
    modalDiff: false,
    diff: null,
    modalRemoveFile: false,
    currFile: null
  };

  handleShowRemoveFileModal = action =>
    this.setState({ modalRemoveFile: true, currFile: action });

  handleHideRemoveFileModal = () =>
    this.setState({ modalRemoveFile: false, currFile: null });

  showHideModal = (open, diff, callback) => {
    this.setState(
      {
        modalDiff: open,
        diff: diff
      },
      () => callback && callback()
    );
  };

  checkIfContainsType = type => {
    const types = ["ACTION", "FILE", "COMMENT"];
    return types.includes(type);
  };

  getFilteredHistory = (history, activeType) => {
    switch (activeType) {
      case "COMMENT": {
        return history.filter(item => item.type === "COMMENT");
      }
      case "FILE": {
        return history.filter(item => item.type === "FILE");
      }
      default: {
        return history;
      }
    }
  };

  render() {
    const { isLoading, history, request, activeTab } = this.props;
    let lastDate = null;
    let lastType = null;

    
    const filteredHistory = this.getFilteredHistory(history, activeTab);
    console.log('filteredHistoryfilteredHistory',filteredHistory)
    return (
      <>
        <div className="list-history">
          {isLoading ? (
            <Preloader />
          ) : (
            filteredHistory.map((item, index) => {
              const isShowDate =
                !lastDate ||
                !lastType ||
                (this.checkIfContainsType(item.type) &&
                  lastDate &&
                  !moment(item.createdAt).isSame(lastDate, "day"));
              lastDate = item.createdAt;
              lastType = item.type;

              switch (item.type) {
                case "FILE": {
                  return (
                    <div key={index}>
                      <FileItemComponent
                        handleShowRemoveModal={this.handleShowRemoveFileModal}
                        action={item}
                        isShowDate={isShowDate}
                        key={item.createdAt + item.text}
                        request={request}
                      />
                    </div>
                  );
                }
                case "ACTION": {
                  return (
                    <ActionComponent
                      showDiff={this.showHideModal}
                      key={item.createdAt + item.text}
                      action={item}
                      isShowDate={isShowDate}
                      request={request}
                    />
                  );
                }
                case "COMMENT": {
                  return (
                    item.text && (
                      <CommentComponent
                        key={item.createdAt + item.text}
                        comment={item}
                        request={request}
                        isShowDate={isShowDate}
                      />
                    )
                  );
                }
                default: {
                  return null;
                }
              }
            })
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    history: state.serviceDeskReducer.history,
    request: state.serviceDeskReducer.request,
    comment: state.serviceDeskReducer.comment,
    isLoading: state.serviceDeskReducer.isLoadingHistory,
    activeTab: state.serviceDeskReducer.activeTabHistory
  };
};

export default connect(mapStateToProps, null)(HistoryList);
