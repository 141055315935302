import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { schema } from './schema'
import BodyForm from './bodyForm'
import Preloader from '../../../reuseComponent/preloader/preloader';
import {setData} from "../../../../actions/admin-header";
import {getNotificationById, getEvents, createNotification, editNotification} from "../../../../actions/notifications";
import {letterTemplate} from "../../../../reuseFunctions/letters";

class NotificationsForm extends PureComponent{

    state={
        initValues: {
            name: '',
            subject: '',
            event: '',
            // content: 'Text',
            content: letterTemplate,
            email: [],
        }
    };

    componentDidMount(){
        const { id } = this.props.match.params;
        const {getEvents, getNotificationById} = this.props;
        this.props.setHeaderData({
            title: id ? 'Edit Notification' : 'Create Notification',
            logo: ((<svg className="action-icon header-icon" viewBox="0 0 24 24">
                <path fill="#000000" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
            </svg>)),
            handleAdd: null,
            handleSearch: null,
        });

        if(id) {
            getNotificationById && getNotificationById(id);
        }

        getEvents && getEvents();
    }

    handleSubmit = values => {
        const { id } = this.props.match.params;
        const {createNotification, editNotification} = this.props;
        if(id){
            editNotification({values, id});
        }else{
            createNotification({values});
        }
    };

    render(){
        const { id } = this.props.match.params;
        const { loading, events, notification} = this.props;
        return (!loading ? <Formik
                initialValues={id ? notification : this.state.initValues}
                validationSchema={schema}
                onSubmit={this.handleSubmit}
                render={props => <BodyForm
                    {...props}
                    events={events}
                />}
            /> : <Preloader/>
        )
    }
}

const mapStateToProps=  state => ({
    loading: state.notificationsReducer.isLoadingNotification || state.notificationsReducer.isLoadingEvents,
    events: state.notificationsReducer.events,
    notification: state.notificationsReducer.notification,
});

const mapDispatchToProps = dispatch => {
    return {
        setHeaderData: obj => dispatch(setData(obj)),
        getNotificationById: id => dispatch(getNotificationById(id)),
        getEvents: () => dispatch(getEvents()),
        createNotification: obj => dispatch(createNotification(obj)),
        editNotification: obj => dispatch(editNotification(obj)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(NotificationsForm)