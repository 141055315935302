import React, {PureComponent} from 'react'
import MaterialTable from '../../../reuseComponent/Table/MaterialTable'
import {connect} from 'react-redux'
import {findPermInArray} from '../../../../reuseFunctions/checkPermission'
import Switch from '../../../reuseComponent/Switcher'
import {setData} from '../../../../actions/admin-header'
import {withRouter, Route} from 'react-router-dom'
import {permDeleteUsers, permInviteUsers, permActDeactUsers, permEditUsers} from '../../../../permissions/users'
import adminInstance from '../../../instances/admininstance';
import {url} from '../../../../constants'
import {changeRoutePush} from '../../../../reuseFunctions/changeRoute'
import Header from '../../../reuseComponent/Table/table-head'
import Pagination from '../../../reuseComponent/Table/Pagination'
import TabsSettings from '../../settings/tabs'
import {history} from '../../../../history'
import UsersDetails from '../details'
import IconButtonMaterial from '../../../reuseComponent/Buttons/ButtonWithMaterialIcon'
import Toolbar from './Toolbar'
import Container from '../../../reuseComponent/Table/container'
import moment from 'moment'


class TableUsers extends PureComponent {

    getColumns = () => [
        {
            title: 'Invited',
            field: 'invited',
            sorting: false,
            render: rowData => {
                return rowData.invited ? moment(rowData.invited,'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm') : null
            }
        },
        {
            title: 'Email',
            field: 'email',
            sorting: true
        },
        {
            title: 'First Name',
            field: 'firstName',
            sorting: true
        },
        {
            title: 'Last Name',
            field: 'lastName',
            sorting: true
        },
        {
            'title': 'Role',
            'field': 'role',
            'sorting': true
        },
        {
            title: 'Department',
            field: 'department',
            sorting: false,
            render: rowData => {
                return rowData.department ? rowData.department.name : null
            }
        },

        {
            'title': 'Status',
            'field': 'status',
            'sorting': true,
            render: rowData => {
                const {handleChangeStatus} = this.props
                return <Switch
                    disabled={!findPermInArray(this.props.roles, permActDeactUsers)}
                    checked={rowData.status}
                    onChange={() => {
                        handleChangeStatus(rowData.id)
                    }}/>
            }
        },
        {
            'title': 'Availability',
            'field': 'inOffice',
            'sorting': true,
            render: rowData => {
                const {handleSetUserOfficeStatus} = this.props
                return <Switch
                    disabled={!findPermInArray(this.props.roles, permEditUsers)}
                    checked={rowData.inOffice}
                    onChange={() => {
                        handleSetUserOfficeStatus(rowData.id)
                    }}/>
            }
        },
        {
            'title': '',
            'field': 'actions',
            'sorting': false,
            'cellStyle': {
                width: 32
            },
            render: rowData => {
                const {handleShowRemoveModal} = this.props
                return <div className='icons-cell-item'>
                    {findPermInArray(this.props.roles, permDeleteUsers) ? <IconButtonMaterial
                customClass="show-on-hover"
                title="Delete"
                onClick={e => {
                    e.stopPropagation()
                    handleShowRemoveModal(e, rowData.id)
                }}>
                <svg viewBox="0 0 24 24">
                    <path fill="#000000"
                          d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"/>
                </svg>
            </IconButtonMaterial> : null}
            </div>
            }
        }
    ]

    // tableRef = React.createRef()

    componentDidMount() {
        this.props.setHeaderData({
            LeftPannel: TabsSettings,
            leftPannelData: {
                alias: 'users'
            },
            textAddButton: 'Invite',
            handleAdd: null,
            handleSearch: (value) => this.props.tableRef.current.onSearchChange(value)
        })
    }

    componentWillUnmount() {
        this.props.setHeaderData({title: null, handleAdd: null, handleSearch: null, CustomLeftPannel: null})
    }

    getRows = (users) => users.map(user => {
        return {
            id: user.id,
            'firstName': user.name,
            'lastName': user.secondName,
            'email': user.username,
            'role': user.role ? user.role.Name : '',
            'status': user.enabled,
            department: user.department,
            invited: user.invited,
            author: user.author,
            inOffice: user.inOffice
        }
    });

    getUsers = (query) => {
        const {page, pageSize, search} = query
        return new Promise((resolve, reject) => {
            adminInstance.get(`${url}/user`, {
                params: {
                    page: page ? page : 0,
                    limit: pageSize,
                    search: search
                }
            })
                .then(res => {
                    // console.log(res.data);
                    resolve({
                        totalCount: res.data.count,
                        data: this.getRows(res.data.users),
                        page: query.page
                    })
                })
        })
    }

    changeUserInTable = user => {
        const columnsData = this.props.tableRef.current.dataManager.data.map(row => {
            if (row.id === user.id) {
                row = {
                    id: user.id,
                    firstName: user.name,
                    lastName: user.secondName,
                    email: user.username,
                    role: user.role ? user.role.Name : '',
                    status: user.enabled,
                    invited: user.invited,
                    department: user.department,
                    inOffice: user.inOffice,
                }
            }
            return row
        })

        this.props.tableRef.current.dataManager.setData(columnsData)
        this.props.tableRef.current.setState(this.props.tableRef.current.dataManager.getRenderState())
    }


    render() {
        const {
                tableRef,
            } = this.props,
            columns = this.getColumns();
        const {id} = this.props.match.params
        return (
            <div className={`wrap-table-with-details-panel ${!id ? '' : 'with-details'}`}>
                <div className="table-block wrapper-table users-table">
                    <MaterialTable
                        components={{
                            Container: Container,
                            Toolbar: props => <Toolbar
                                {...props}
                                handleAdd={findPermInArray(this.props.roles, permInviteUsers) ? () => this.props.handleClick() : null}
                            />,
                            Header: Header,
                            Pagination: Pagination
                            
                        }}
                        paginationPosition="toolbar"
                        columns={columns}
                        data={this.getUsers}
                        title="Users"
                        tableRef={tableRef}
                        onRowClick={(event, rowData) => {
                            changeRoutePush(`/atw/settings/users/${rowData.id}`, history)
                        }}
                        options={{
                            maxBodyHeight: 'calc(100vh - 130px)',
                            search: false,
                            emptyRowsWhenPaging: false,
                            pageSize: 50,
                            pageSizeOptions: [10, 25, 50],
                            rowStyle: props => {
                                if (parseInt(id) === props.id || id === props.id)
                                    return {
                                        backgroundColor: '#f2f2f2'
                                    }
                                else {
                                    return {}
                                }
                            }
                        }}
                    />
                </div>
                <Route
                    exact
                    path="/atw/settings/users/:id"
                    render={props => <UsersDetails
                        {...props}
                        changeUserInTable={this.changeUserInTable}
                    />}
                />
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.rolesReducer.roles
    }
}

const mapDispatchToProps = dispatch => ({
    setHeaderData: (data) => dispatch(setData(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TableUsers))


