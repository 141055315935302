import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Modal from '../../../../../../reuseComponent/modal/index'
import Form from './form'
import { showHideCreateContact } from '../../../../../../../actions/purchase-orders'

class ModalCreateContact extends PureComponent{
    render(){
        const { isShow,openHideContact,setContactId } = this.props
        return (
            <Modal
                open={isShow}
                isFooter={false}
                handleClose={() => openHideContact(false)}
                Content={() => <Form setContactId={setContactId}/>}
                options={{
                    centered: true,
                    animation: false
                }}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        isShow : state.purchaseOrdersReducer.isShowCreateContact
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openHideContact : isShow => dispatch(showHideCreateContact(isShow))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ModalCreateContact)