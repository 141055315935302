import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import {changeState,removeBid} from '../../../../../../../actions/detailsPo'
import { setDetailsPurchaseAction } from '../../../../../../../actions/purchase-orders'
import { notify } from '../../../../../../reuseComponent/toast/index'
import {calculateTotalBids, totalBidsToStore} from '../../../../../../../reuseFunctions/calculateTotalBids'
import {ConfirmModalContent} from '../../../../../../reuseComponent/confirmModalComponent/index';
import CustomModalWithChildren from "../../../../../../reuseComponent/modal/modalWithChildren";
import {ReactComponent as CautionIcon} from '../../../../../../../assets/images/caution.svg';


class RemoveBidModal extends PureComponent{

    handleConfirm = () => {
        const { bidToEdit,details,changeDetails,removeBid,changeBidState } = this.props
        const filtredBids = details.bids.filter(bid => bid.id !== bidToEdit)
        const total = calculateTotalBids(filtredBids)
        const data = {
            idBid: bidToEdit,
            idPurchase: details.id,
            ...total
        }
        removeBid({
            values: data,
            resolve: data => {
                changeDetails({
                    bids: filtredBids,
                    ...totalBidsToStore(total)
                })
                changeBidState({
                    modalRemoveBid: false,
                    bidToEditL: null
                })
                notify('success','BID successfully deleted')
            },
            reject: () => {
                notify('error','Oops something went wrong')
            }
        })
    }

    render(){
        const { modalRemoveBid,changeBidState, details } = this.props
        
        return (
            <CustomModalWithChildren
                open={modalRemoveBid}
                handleClose={() => changeBidState({
                    modalRemoveBid: false,
                    bidToEdit: null
                })}
                handleConfirm={this.handleConfirm}
                handleCancel={() => changeBidState({
                    modalRemoveBid: false,
                    bidToEdit: null
                })}
                options={{
                    centered: true
                }}
            >
                {
                    details && details.bids && details.bids.length === 1 ? (
                        <div className="caution-modal-content">
                            <p><CautionIcon /><span className="caution">Caution!</span></p>
                            <h6 className="center-h">
                                BID deletion leads to data loss with no ability to restore, are you sure you want to delete?
                            </h6>
                        </div>
                    ) : (
                        <ConfirmModalContent
                            text={`delete #${details.orderNumber} BID`}
                        />
                    )
                }
            </CustomModalWithChildren>
        )
    }
}

const mapStateToProps = state => {
    return {
        modalRemoveBid: state.poDetailsReducer.modalRemoveBid,
        bidToEdit: state.poDetailsReducer.bidToEdit,
        details: state.purchaseOrdersReducer.detailsPurchase
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeBidState: state => dispatch(changeState(state)),
        removeBid: obj => dispatch(removeBid(obj)),
        changeDetails: state => dispatch(setDetailsPurchaseAction(state))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RemoveBidModal)