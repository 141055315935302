import React, { useMemo, useRef, useEffect, useState, useCallback } from 'react'
import { LegendItem } from './LegendItem'
import { SlotItem } from './SlotItem'
import { PALLET_PLACE_STROKE } from './constants'
import { ScalePanel } from './ScalePanel'
import { usePrevious } from '../../../../hooks'
import { SlotItemIcon } from './SlotItemIcon'

const step = 0.25;

let timeout = null;

const ctrlKey = 17
const cmdKey = 91
const rightcmdKey = 93
const tabKey = 8

const InventoryMapMap = ({
                           width,
                           height,
                           maxX,
                           maxY,
                           map,
                           coordinatesArray,
                           handleOpenCreateModal,
                           handleOpenDetailModal,
                           handleOpenMoveModal,
                           handleOpenHoverModal,
                           handleScrollObj,
                           setSelectedPallet,
                           draggingCoordinates,
                           selectedPallet,
                           setDraggingCoordinates,
                           setLocation,
                           scale,
                           setScale,
                           isActivatedSearch
                         }) => {

  const [moveX, setMoveX] = useState(null)
  const [moveY, setMoveY] = useState(null)
  const [cannotBePlaced, setCannotBePlaced] = useState(false)
  const wrapperRef = useRef(null)
  const prevDraggingCoordinates = usePrevious(draggingCoordinates)
  const mousePosition = {x: null, y: null};
  const [isCtrl, setIsCtrl] = useState(false)

  const svgRef = useRef(null)
  const sizeX = useMemo(() => {
    const sXByX = width / (maxX + 2);
      return sXByX
  }, [width, maxX, maxY, height])

  const sizeY = useMemo(() => {
    // const sYByX = Math.floor(width / (maxX + 2)/1.3);
    const sYByX = width / (maxX + 2)/1.3

    // const sYByY =  Math.floor(height / (maxY + 2));

    // if((maxY+2)*sYByX > height){
    //   return sYByY
    // }else{
      return sYByX
    // }
  }, [width, maxX, maxY, height])

  const sizeYByY = useMemo(() => {
    // const sYByX = Math.floor(width / (maxX + 2)/1.3);
    const sYByX = width / (maxX + 2)/1.3;

    // const sYByY =  Math.floor(height / (maxY + 2));
    const sYByY =  height / (maxY + 2);

    if((maxY+2)*sYByX > height){
      return sYByY
    }else{
    return sYByX
    }
  }, [width, maxX, maxY, height])

  const positionRef = useRef(null)

  useEffect(() => {
    positionRef.current = mousePosition
  }, [mousePosition])

  useEffect(() => {
    if (draggingCoordinates && prevDraggingCoordinates
      && (draggingCoordinates.x !== prevDraggingCoordinates.x || draggingCoordinates.y !== prevDraggingCoordinates.y)) {
      const offsetTop = wrapperRef.current.scrollTop
      const offsetLeft = wrapperRef.current.scrollLeft
      const width = (maxX + 2) * sizeX;

      const height = (maxY + 2) * sizeYByY;

      if (draggingCoordinates.x > prevDraggingCoordinates.x) {
        if (width * 3 / 4 < draggingCoordinates.x * scale - offsetLeft) {
          setMoveX(1)
        }
      } else if (draggingCoordinates.x < prevDraggingCoordinates.x) {
        if (width / 4 > draggingCoordinates.x * scale - offsetLeft) {
          setMoveX(-1)
        } else {
          setMoveX(null)
        }


        if (draggingCoordinates.y > prevDraggingCoordinates.y
          && height * 5 / 8 < draggingCoordinates.y * scale - offsetTop) {
          setMoveY(1)
        }
      } else if (draggingCoordinates.y < prevDraggingCoordinates.y) {
        if (height / 4 > draggingCoordinates.y * scale - offsetTop) {
          setMoveY(-1)
        } else {
          setMoveY(null)
        }
      }
    }
  }, [draggingCoordinates, scale, prevDraggingCoordinates, maxX, maxY, sizeYByY, sizeX])

  useEffect(() => {
    let interval = null;
    if(moveX && moveX > 0){
      interval = setInterval(() => {
        wrapperRef.current.scrollLeft = wrapperRef.current.scrollLeft + sizeX
      }, 100)
    }else if(moveX < 0){
      interval = setInterval(() => {
        wrapperRef.current.scrollLeft = wrapperRef.current.scrollLeft - sizeX
      }, 100)
    }

    return () => {
      clearInterval(interval)
    }
  }, [moveX, sizeX])

  useEffect(() => {
    let interval = null;
    if(moveY && moveY > 0){
      interval = setInterval(() => {
        wrapperRef.current.scrollTop = wrapperRef.current.scrollTop + sizeY
      }, 100)
    }else if(moveY < 0){
      interval = setInterval(() => {
        wrapperRef.current.scrollTop = wrapperRef.current.scrollTop - sizeY
      }, 100)
    }
    return () => {
      clearInterval(interval)
    }
  }, [moveY, sizeY])

  useEffect(() => {
    if(!selectedPallet){
      setMoveY(null)
      setMoveX(null)
    }
  }, [selectedPallet])

  useEffect(() => {
    if(draggingCoordinates){
      const x = Math.floor(draggingCoordinates.x/sizeX) + 1
      const y = Math.floor(draggingCoordinates.y/sizeY) + 1
      const item = map.find(item => item.coordinateX === x && item.coordinateY === y)
      if(item && !item.pallet && item.locationType.slot){
        setCannotBePlaced(false)
      }else{
        setCannotBePlaced(true)
      }
    }else{
      setCannotBePlaced(false)
    }
  }, [draggingCoordinates, sizeX, sizeY, map])

  const handleMouseMove = useCallback((e) => {
    const bbox = svgRef.current.getBoundingClientRect();
    const x = e.clientX - bbox.left;
    const y = e.clientY - bbox.top;
    mousePosition.x = Math.floor(x/sizeX/scale) + 1;
    mousePosition.y = Math.floor(y/sizeY/scale);
  }, [sizeX, sizeY, scale])

  const handleScroll = useCallback((e) => {
    handleScrollObj({ top: e.target.scrollTop, left:  e.target.scrollLeft });
  }, [handleScrollObj])

  const handleKeyDown = useCallback((e) => {
    if(e.keyCode === ctrlKey || e.keyCode === cmdKey || e.keyCode === rightcmdKey){
      setIsCtrl(true)
    }
    if(e.keyCode === tabKey){
      setIsCtrl(false)
    }
  }, [])

  const handleKeyUp = useCallback((e) => {
    if(e.keyCode === ctrlKey || e.keyCode === cmdKey || e.keyCode === rightcmdKey){
      setIsCtrl(false)
    }
  }, [])

  const handleChangeVisibility = useCallback(() => {
    setIsCtrl(false)
  }, [])

  useEffect(() => {
      document.addEventListener('keydown', handleKeyDown)
      document.addEventListener('keyup', handleKeyUp)
      window.addEventListener('blur', handleChangeVisibility)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('blur', handleChangeVisibility)
    }
  }, [handleKeyUp, handleKeyDown, handleChangeVisibility])

  return (
    <>
      <div style={{
      width: maxX && sizeX ? (maxX+2)*sizeX + 7 : 0,
      height: maxY && sizeYByY ? (maxY+2)*sizeYByY + 7 : 0,
      overflow: isCtrl ? 'hidden' : 'auto',
      padding: '0 7px 0 0',
        position: 'relative'
    }}
           ref={wrapperRef}
           onScroll={handleScroll}
    >
      <svg className="legend-svg top-legend" width={maxX && sizeX ? scale*(maxX+2)*sizeX : 0} height={ sizeY ? sizeY*scale : 0}>
        {coordinatesArray && coordinatesArray.top && coordinatesArray.top.map(item => <LegendItem key={item.id} legendItem={item} sizeX={sizeX} sizeY={sizeY} scale={scale} isSelected={mousePosition && mousePosition.x === item.coordinateX}/>)}
      </svg>
      <div style={{display: 'flex', width: maxX && sizeX ? scale*(maxX+2)*sizeX : 0, position: 'relative', backgroundColor: '#f2f2f2'}}>
        <svg className="legend-svg left-legend" width={sizeX ? sizeX*scale : 0} height={ maxY && sizeY ? scale*(maxY)*sizeY : 0} style={{minWidth: sizeX ? sizeX*scale : 0}}>
          {coordinatesArray && coordinatesArray.left && coordinatesArray.left.map(item => <LegendItem key={item.id} legendItem={item} sizeX={sizeX} sizeY={sizeY} scale={scale} isSelected={mousePosition && mousePosition.y === item.coordinateY}/>)}
        </svg>
        <svg
          ref={svgRef}
          width={maxX && sizeX ? scale*(maxX)*sizeX : 0}
          height={ maxY && sizeY ? scale*(maxY)*sizeY : 0}
          onMouseMove={handleMouseMove}
          onMouseOut={e => {
            mousePosition.x = null;
            mousePosition.y = null;
          }}
        >
          {isActivatedSearch && <rect x={0} y={0} width="100%" height="100%" fill="#dddddd"/>}
          {map.map(item => <SlotItem
            key={item.id}
            slotItem={item}
            sizeY={sizeY}
            sizeX={sizeX}
            handleOpenCreateModal={handleOpenCreateModal}
            handleOpenDetailModal={handleOpenDetailModal}
            handleOpenMoveModal={handleOpenMoveModal}
            handleOpenHoverModal={handleOpenHoverModal}
            setSelectedPallet={setSelectedPallet}
            width={(maxX+2)*sizeX}
            height={(maxY+2)*sizeY}
            maxX={maxX}
            maxY={maxY}
            setDraggingCoordinates={setDraggingCoordinates}
            setLocation={setLocation}
            scale={scale}
            isActivatedSearch={isActivatedSearch}
          /> )}
          {selectedPallet && draggingCoordinates
          && <g
            className={"slot with-active is-drag-view"}
          >
            <rect
              x={draggingCoordinates.x*scale}
              y={draggingCoordinates.y*scale}
              width={(sizeX - 2)*scale}
              height={(sizeY - 2)*scale}
              fill="#3498DB"
              opacity={0.6}
            />
            <svg
              x={(draggingCoordinates.x + sizeX/8)*scale}
              y={(draggingCoordinates.y + sizeY/8)*scale}
              width={(sizeX - 2)*scale/4*3}
              height={(sizeY - 2)*scale/4*3}
              viewBox="0 0 24 24">
              <SlotItemIcon
                statusName={selectedPallet.palletStatus.name}
              />
            </svg>
            {cannotBePlaced && <g>
              <line
                x1={draggingCoordinates.x*scale}
                y1={draggingCoordinates.y*scale}
                x2={(draggingCoordinates.x + sizeX - 2)*scale}
                y2={(draggingCoordinates.y + sizeY - 2)*scale}
                stroke={PALLET_PLACE_STROKE.DEFAULT}
                strokeWidth={1}
              />
              <line
                x1={draggingCoordinates.x*scale}
                y1={(draggingCoordinates.y + sizeY - 2)*scale}
                x2={(draggingCoordinates.x + sizeX - 2)*scale}
                y2={draggingCoordinates.y*scale}
                stroke={PALLET_PLACE_STROKE.DEFAULT}
                strokeWidth={1}
              />
            </g>}
          </g>}
        </svg>
        <svg className="legend-svg right-legend" width={sizeX ? sizeX*scale : 0} height={ maxY && sizeY ? scale*(maxY)*sizeY : 0} style={{minWidth: sizeX ? sizeX*scale : 0, right: `-${7}px`}}>
          {coordinatesArray && coordinatesArray.right && coordinatesArray.right.map(item => <LegendItem key={item.id} legendItem={item} sizeX={sizeX} sizeY={sizeY} scale={scale} isSelected={mousePosition && mousePosition.y === item.coordinateY}/>)}
        </svg>
      </div>
      <svg className="legend-svg bottom-legend" width={maxX && sizeX*scale ? scale*(maxX+2)*sizeX : 0} height={ sizeY ? sizeY*scale : 0} style={{minWidth: sizeX ? sizeX*scale : 0}}>
        {coordinatesArray && coordinatesArray.bottom && coordinatesArray.bottom.map(item => <LegendItem key={item.id} legendItem={item} sizeX={sizeX} sizeY={sizeY} scale={scale} isSelected={mousePosition && mousePosition.x === item.coordinateX}/>)}
      </svg>
    </div>
      {maxX && <ScalePanel scale={scale} setScale={setScale} width={maxX && sizeX ? (maxX+2)*sizeX : 0}/>}
      </>
  )
}

export { InventoryMapMap }